import { v4 as uuid } from 'uuid';

import getLink from '../getLink';
import migrateBlock from '../migrateBlock';

export default function LayoutBlockMigrator(oldBlock: any) {
  let children = [];

  if (oldBlock.backgroundImage?._id) {
    children.push({
      _id: uuid(),
      blockId: '4VdpZFxzz9Xdj8XOEn5TUR',
      blockVersion: 0,
      name: 'Image',
      properties: {
        image: oldBlock.backgroundImage._id,
        height: Math.round((oldBlock.height || 0) / 10),
        fullWidth: 'Full Width',
        resizeMode: 'Cover',
      },
      link: getLink(oldBlock.link),
    });
  }

  if (oldBlock.blocks) {
    children = [...children, ...oldBlock.blocks.map(migrateBlock)];
  }

  return {
    _id: uuid(),
    name: oldBlock.name || oldBlock._type,
    blockId: '2wECcIaKSKjzQNKikhIeO7',
    lock: [],
    properties: {
      children,
    },
    link: getLink(oldBlock.link),
    blockVersion: 0,
  };
}

/**
block: {
  _id: uuid(),
    name: event.title,
    blockId: '2wECcIaKSKjzQNKikhIeO7',
    lock: ['All'],
    properties: {
    children: [
      {
        _id: uuid(),
        blockId: '3IHP56bNukaLFqlDqyFkFI',
        lock: ['All'],
        properties: {
          size: 'Medium',
          images: [
            backgroundImage,
            '0gRp2pufKht2Xzo1Ltl4y1',
            '5qxQ9T1MxzwPXI1uVSLFBr',
          ],
        },
        blockVersion: 0,
      },
      {
        _id: uuid(),
        blockId: '4jF2Svml808ngPVpgEep1O',
        lock: ['All'],
        properties: {
          size: 'Medium',
          header: event.title,
          textAlign: 'left',
        },
        blockVersion: 0,
      },
      {
        _id: uuid(),
        blockId: '5Jgi7itG5AYiTK7HC4Ojbp',
        lock: ['All'],
        properties: {
          scroll: false,
          children: [
            {
              _id: uuid(),
              blockId: '4VdpZFxzz9Xdj8XOEn5TUR',
              lock: ['All'],
              properties: {
                image: event.teacher.image,
                height: 7,
                fullWidth: 'Circle',
                resizeMode: 'Cover',
              },
              blockVersion: 0,
            },
            {
              _id: uuid(),
              blockId: '3MySOQd6QCKAv9HiktQe1w',
              lock: ['All'],
              properties: {
                // todo: API doesn't seem to contain the teachers role
                text: '',
                header: event.teacher.firstName,
                textAlign: 'left',
              },
              blockVersion: 0,
            },
            {
              _id: '5fbd7549-4897-48cb-8a83-03639ddc4cd8',
              blockId: '5Jgi7itG5AYiTK7HC4Ojbp',
              properties: {
                scroll: false,
                children: [],
                verticalPadding: 0,
                horizontalPadding: 0,
              },
              blockVersion: 0,
            },
          ],
          verticalPadding: 0,
          horizontalPadding: 0,
        },
        blockVersion: 0,
      },
      {
        _id: uuid(),
        blockId: '5OwQXXF24XxWE3JpJ7avbl',
        lock: ['All'],
        properties: {
          icon: {
            set: 'Feather',
            name: 'calendar',
            size: 19,
            color: 'rgba(0,0,0,1)',
          },
          text: format(startDate, 'EEEE MMM do'),
        },
        blockVersion: 0,
      },
      {
        _id: uuid(),
        blockId: '5OwQXXF24XxWE3JpJ7avbl',
        lock: ['All'],
        properties: {
          icon: { set: 'Material', name: 'alarm', size: 20 },
          text: event.dateTime.time,
        },
        blockVersion: 0,
      },
      {
        _id: uuid(),
        blockId: '5OwQXXF24XxWE3JpJ7avbl',
        lock: ['All'],
        properties: {
          icon: {
            set: 'Feather',
            name: 'navigation',
            size: 19,
            color: 'rgba(0,0,0,1)',
          },
          text: `${event.venue.name} - ${event.venue.extraInformation}\n${event.location}`,
        },
        blockVersion: 0,
      },
      {
        _id: uuid(),
        lock: ['All'],
        blockId: '3MySOQd6QCKAv9HiktQe1w',
        properties: {
          text: event.description,
          header: 'Event Description',
          textAlign: 'left',
        },
        blockVersion: 0,
      },
      {
        _id: uuid(),
        lock: ['All'],
        name: 'Required Interactive Block',
        style: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: '2em',
          paddingLeft: '2em',
          paddingRight: '2em',
          flexDirection: 'row',
          paddingBottom: '2em',
        },
        children: [
          {
            _id: uuid(),
            style: { flex: 1 },
            primitive: 'ButtonBlock',
            subscribe: { submit: { _bind: true, publish: 'submit' } },
            properties: { text: 'RSVP', isSubmit: true },
          },
        ],
        primitive: 'ViewBlock',
        publishes: { submit: { type: 'Event' } },
      },
    ],
  },
  blockVersion: 0,
},

 */
