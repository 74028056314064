import { useState, useEffect } from 'react';

import * as yup from 'yup';

import { createShapeFromProperties } from '../../../properties';
import { ContentType } from '../../../types/content/Content';
import definition from './definition';
import { fetchWorkorderFormData } from './requestHelpers';

export const validationShape = createShapeFromProperties(
  definition.interactionData
);

async function getFormData(content: any, storedToken: any) {
  const endpoint = content.integration.settings.baseUrl;
  const response = await fetchWorkorderFormData(
    endpoint,
    storedToken.tenx,
    storedToken.user,
    content.integration.settings.buildingId
  );

  const floors = response.floors;

  const spaceIds: any[] = [];
  floors.forEach(({ name: floorName, suites }: any) => {
    if (suites) {
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'spaceId' implicitly has an 'any' ... Remove this comment to see the full error message
      suites.forEach(({ id: spaceId }) => {
        spaceIds.push({
          value: spaceId,
          label: `${floorName}: ${spaceId}`,
        });
      });
    }
  });

  const requestTypes: any[] = [];

  response.issue_types.forEach(({ id, name, primary_type }: any) =>
    requestTypes.push({
      value: id,
      label: name,
      primary_type,
    })
  );

  return {
    spaces: spaceIds,
    requestTypes,
    floors,
  };
}

function useFormData(content: ContentType) {
  const [formData, setFormData] = useState({
    spaces: [] as any[],
    requestTypes: [] as any[],
    floors: [] as any[],
  });
  const [token, setToken] = useState<
    { tenx: string; user: string } | undefined
  >({ tenx: '', user: '' });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token?.user) {
      return;
    }

    async function handleFormData() {
      try {
        const response = await getFormData(content, token);
        setFormData(response);
      } catch {
        // do nothing
      } finally {
        setLoading(false);
      }
    }

    handleFormData();
  }, [token?.user, content]);

  return {
    ...formData,
    setToken(value: any) {
      setToken(value);
      setLoading(true);
    },
    loading,
  };
}

export default function useWorkOrder({ content }: any) {
  const [workOrder, setWorkOrder] = useState({
    spaceId: '',
    requestTypeId: '',
    description: '',
  });
  const [validation, setValidation] = useState<Error | null>(null);

  function updateWorkOrder(props: any) {
    setWorkOrder(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  function validate() {
    try {
      workOrder.description = workOrder.description.trim();
      yup.object().shape(validationShape).validateSync(workOrder);
      setValidation(null);
      return true;
    } catch (err) {
      setValidation(err);
      return false;
    }
  }

  const formDataHook = useFormData(content);

  return {
    workOrder,
    updateWorkOrder,
    validate,
    validation,
    setToken: formDataHook.setToken,
    spaces: formDataHook.spaces,
    floors: formDataHook.floors,
    requestTypes: formDataHook.requestTypes,
    loading: formDataHook.loading,
  };
}
