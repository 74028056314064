import { Password } from '../../../properties/baseTypes';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';

const EntrataDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Entrata,
  hasCustomUI: false,
  friendlyName: IntegrationProviderEnum.Entrata,
  description: `Defines the properties required for Entrata integration.`,
  category: IntegrationCategoryEnum.Accounting,
  platforms: [],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    url: {
      friendlyName: 'Base URL',
      type: 'Url',
      description: 'Base URL',
      validators: [{ name: 'Required', value: true }],
    },
    username: {
      friendlyName: 'Username',
      type: 'String',
      description: 'Entrata Username',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    password: {
      friendlyName: 'Password',
      type: Password.name,
      description: 'Entrata Password',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    propertyId: {
      friendlyName: 'Property ID',
      type: 'String',
      description: 'Entrata property ID',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    syncPastUsersEnabled: {
      friendlyName: 'Sync Past Residents',
      type: 'Boolean',
      default: false,
    },
    daysPostMoveOut: {
      friendlyName: 'Import Past Residents: Enter days post Move-Out Date',
      type: Number.name,
      default: undefined,
      validators: [],
    },
    syncFutureUsersEnabled: {
      friendlyName: 'Sync Future Residents',
      type: 'Boolean',
      default: false,
    },
    daysPriorMoveIn: {
      friendlyName: 'Import Future Residents: Enter days prior to Move-In Date',
      type: Number.name,
      default: undefined,
      validators: [],
    },
    workOrdersEnabled: {
      friendlyName: 'Work orders',
      type: 'Boolean',
      default: false,
    },
  },
};

export default EntrataDefinition;
