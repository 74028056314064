import gql from 'graphql-tag';

export default gql`
  mutation addContentTemplateToLibraryMutation(
    $libraryItem: LibraryItemInput!
  ) {
    addContentTemplateToLibrary(libraryItem: $libraryItem) {
      _id
    }
  }
`;
