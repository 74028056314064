import React from 'react';

import { usePublicUserQuery } from 'lane-shared/hooks';

import styles from 'lane-web/src/components/lane/UserContentInteractionCard.scss';
import UserThumbnail from 'lane-web/src/components/lane/UserThumbnail';

export default function GuestOrUserThumbnail({ guest }: any) {
  const { user } = usePublicUserQuery({ userId: guest?.user?._id || null });
  const data = user || guest;

  return (
    <UserThumbnail
      key={data._id}
      style={{ cursor: 'default' }}
      className={styles.guest}
      user={data}
      labelPosition="right"
    />
  );
}
