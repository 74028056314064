import { useEffect, useMemo, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { LaneType } from 'common-types';
import { ContentCategoryEnum } from '../../types/content/ContentCategoryEnum';
import {
  ContentGroupByEnum,
  SearchOptions,
} from '../../types/filters/SearchOptions';
import { useMultiLanguage } from '../useMultiLanguage';
import getSectionQuery, { GetSectionQueryResult } from './getSectionQuery';
import groupResults from './groupResults';
import searchSectionContentQuery from './searchSectionContentQuery';
import {
  UseSectionQueryProps,
  UseSectionQueryReturn,
  SectionType,
  SectionChannelType,
} from './types';
import useSectionSearchOptions from './useSectionSearchOptions';

// TODO: fix type
const emptyResults: any[] = [];

const DEBOUNCE_TIME = 250;

export default function useSectionQuery({
  autoHide,
  autoSearch = true,
  sectionId,
  location,
  pollInterval,
  defaultSearchOptions,
  searchOptionsOverride,
}: UseSectionQueryProps): UseSectionQueryReturn {
  const [section, setSection] = useState<SectionType | null>(null);
  const [channels, setChannels] = useState<SectionChannelType[]>([]);
  const { translate } = useMultiLanguage();
  const {
    initialSearchOptions,
    searchOptions,
    editingSearchOptions,
    hasChannelSelector,
    hasChannelLocationsSelector,
    selectedChannel,
    updateSelectedChannel,
    hasSearch,
    hasFilters,
    updateSearchOptions,
    resetSearchOptions,
    applySearchOptions,
    isSearchOptionsReady,
    hasEventDateSelector,
  } = useSectionSearchOptions({
    autoApply: autoSearch,
    defaultSearchOptions,
    sectionId,
    section,
    channels,
    location,
  });

  // get the base section info
  const sectionResults = useQuery<GetSectionQueryResult>(getSectionQuery, {
    skip: !sectionId,
    fetchPolicy: 'cache-and-network',
    variables: {
      sectionId,
    },
  });

  useEffect(() => {
    if (sectionResults?.data?.section) {
      setSection(sectionResults.data.section);
    }
  }, [sectionResults?.data?.section]);

  useEffect(() => {
    if (sectionResults?.data?.section?._id) {
      // clear selected channel when the section changes.
      updateSelectedChannel(null);
    }
  }, [sectionResults?.data?.section?._id]);

  // debounce so we aren't constantly trying to re-query
  const [debouncedSearchOptions] = useDebounce<SearchOptions | null>(
    searchOptions,
    DEBOUNCE_TIME
  );

  const skip = !debouncedSearchOptions || !(sectionId && isSearchOptionsReady);

  const contentResults = useQuery(searchSectionContentQuery, {
    skip,
    fetchPolicy: 'cache-and-network',
    pollInterval: skip ? 0 : pollInterval,
    variables: {
      id: sectionId,
      searchOptions: searchOptionsOverride || {
        ...debouncedSearchOptions,
        _updated: undefined,
      },
    },
  });

  const sectionContent = useMemo(() => {
    const sectionContent = contentResults.data?.section?.sectionContent;
    return cloneDeep(sectionContent) || emptyResults;
  }, [contentResults.data?.section?.sectionContent]);

  const sectionContentGroups = useMemo(() => {
    if (section?.groupBy === ContentGroupByEnum.Category) {
      return groupResults<{ content: { category: ContentCategoryEnum } }>(
        contentResults.data?.section?.sectionContent,
        sectionContent => sectionContent.content.category
      );
    }

    if (section?.groupBy === ContentGroupByEnum.Channel) {
      return groupResults<{ content: { channel: { _id: LaneType.UUID } } }>(
        contentResults.data?.section?.sectionContent,
        sectionContent => sectionContent.content.channel._id
      );
    }

    return null;
  }, [section?.groupBy, contentResults.data?.section?.sectionContent]);

  useEffect(() => {
    if (sectionResults.data?.section) {
      setSection(cloneDeep(sectionResults.data.section));
    } else {
      setSection(null);
    }

    if (sectionResults.data?.section?.searchInfo?.channels) {
      setChannels(cloneDeep(sectionResults.data.section.searchInfo.channels));
    }
  }, [sectionResults.data?.section]);

  // hide the results if there is no content to display AND
  //   - there are no filters applied
  //   - there is no search term provided
  //   - the query has actually been called
  //   - the query isn't currently loading
  const fastIsHidden =
    autoHide &&
    (!contentResults.called ||
      (!searchOptions?.areFiltersApplied &&
        !searchOptions?.search &&
        contentResults.called &&
        !contentResults.loading &&
        sectionContent?.length === 0));

  // prevent some jank
  const [isHidden] = useDebounce(
    Boolean(autoHide ? fastIsHidden : false),
    DEBOUNCE_TIME / 5
  );

  return {
    section: translate({ model: section, columns: ['name'] }),
    sectionContent: translate({
      model: sectionContent.map((sc: any) => ({
        ...sc,
        channel: sc.content.channel,
      })),
      columns: ['content.name', 'content.description'],
    }),
    sectionContentGroups,
    channels,
    searchOptions,
    initialSearchOptions,
    editingSearchOptions,
    hasChannelSelector,
    hasChannelLocationsSelector,
    hasEventDateSelector,
    selectedChannel,
    updateSelectedChannel,
    hasSearch,
    hasFilters,
    isHidden,
    fetchSectionContent: contentResults.refetch,
    updateSearchOptions,
    resetSearchOptions,
    applySearchOptions,
    called: sectionResults?.called && contentResults.called,
    error: sectionResults?.error || contentResults?.error,
    loading: sectionResults?.loading || contentResults?.loading,
    sectionLoading: sectionResults?.loading,
    contentLoading: contentResults?.loading,
  };
}
