type Prop = {
  issue_type: {
    issue_category: string;
    description?: string;
    name?: string;
  };
};

export const getIssueText = (interaction: Prop) => {
  const issueType = interaction?.issue_type;
  let issueText = issueType?.issue_category;

  if (issueType?.description) {
    issueText += `: ${issueType?.description}`;
  } else if (issueType?.name) {
    issueText += `: ${issueType?.name}`;
  }

  return issueText;
};
