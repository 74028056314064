import React from 'react';

import type { i18n } from 'i18next';
import { z } from 'zod';

import {
  MjmlSection,
  MjmlColumn,
  MjmlImage,
  MjmlGroup,
  MjmlText,
} from 'lane-shared/emails/MjmlComponentsV2';

import RichTextHtmlRenderer from '../../../../components/RichTextRenderer';
import { isEmptyRichTextField, parseRichTextField } from '../../helpers';
import { buildingSchema } from '../schema';

export function EmailVisitorBuildingDetails({
  i18n,
  building,
}: {
  i18n: i18n;
  building: z.infer<typeof buildingSchema>;
}) {
  const visitorInstructionsJSON = parseRichTextField(
    building.visitorInstructions
  );

  const displayInstructions =
    building.visitorInstructions &&
    !isEmptyRichTextField(visitorInstructionsJSON);

  return (
    <>
      <MjmlSection background-color="#FFFFFF" padding="0">
        <MjmlGroup>
          <MjmlColumn width="20%">
            <MjmlImage
              width="25px"
              padding-right="0"
              src="https://lane-media-prd-us-east-2.s3.amazonaws.com/map-pin.png"
            />
          </MjmlColumn>
          <MjmlColumn width="70%">
            <MjmlText>
              {building.addressLineOne} <br />
              {building.addressLineTwo && (
                <>
                  {building.addressLineTwo} <br />
                </>
              )}
              {building.mapUri && (
                <a href={building.mapUri}>
                  {i18n.t(
                    'shared.email.visitorManagement.buildingDetails.mapLink'
                  )}
                </a>
              )}
            </MjmlText>
          </MjmlColumn>
        </MjmlGroup>
      </MjmlSection>

      {displayInstructions && (
        <MjmlSection background-color="#FFFFFF" padding="0">
          <MjmlGroup>
            <MjmlColumn width="20%">
              <MjmlImage
                width="25px"
                padding-right="0"
                padding-top="12px"
                src="https://lane-media-prd-us-east-2.s3.amazonaws.com/badge-verify.png"
              />
            </MjmlColumn>
            <MjmlColumn width="70%">
              <MjmlText>
                <RichTextHtmlRenderer value={visitorInstructionsJSON} />
              </MjmlText>
            </MjmlColumn>
          </MjmlGroup>
        </MjmlSection>
      )}
    </>
  );
}
