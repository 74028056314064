import { createContext } from 'react';

import { ApolloError } from '@apollo/client';

import { LaneIntegrationType } from 'common-types';
import { ChannelType } from '../../../types/ChannelType';
import { ContentType } from '../../../types/content/Content';
import {
  ShopifyRsvpWizardState,
  ShopifyRsvpContentStateType,
  ShopifyBurstRsvpEntryTimeValidEnum,
} from '../../../types/integrations/ShopifyRsvp';
import { ContentForCardType } from '../../useContentForCardQuery';

type ShopifyRsvpContextType = {
  contentId: string | null | undefined;
  initialWizardState: ShopifyRsvpWizardState;
  // todo: why do we have two wizard states exported here?
  wizardState: ShopifyRsvpWizardState;
  burstState: ShopifyRsvpContentStateType;
  updateWizardState: (newState: Partial<ShopifyRsvpWizardState>) => void;
  refetchRsvpInteraction: () => void;
  rsvpContent: ContentType | null;
  rsvpContentLoading: boolean;
  rsvpContentError: Error | null;
  // todo: we don't have a UCIType yet
  rsvpUserContentInteraction: any;
  rsvpIntegrationSettings: LaneIntegrationType.ShopifyRsvp | null;
  rsvpSubmitOnBehalfOfUser: { _id: string };
  loading: boolean;
  error: Error | null;
  port: ChannelType | null;
  portLoading: boolean;
  portError: ApolloError | null | undefined;
  primaryRoomBookingContent: ContentForCardType | null;
  primaryRoomBookingLoading: boolean;
  primaryRoomBookingError: ApolloError | null | undefined;
  dailyScheduleContent: ContentForCardType | null;
  dailyScheduleLoading: boolean;
  dailyScheduleError: ApolloError | null | undefined;
  activitiesBookingsContents: ContentForCardType[];
  activitiesBookingsLoading: boolean;
  activitiesBookingsError: ApolloError | null;
  burstStateLoading: boolean;
  entryTimeValid: ShopifyBurstRsvpEntryTimeValidEnum;
  specialRequestContent: ContentType | undefined;
  specialRequestContentLoading: boolean;
  specialRequestContentError: Error | null | undefined;
};

const ShopifyBurstContext = createContext({} as ShopifyRsvpContextType);

export default ShopifyBurstContext;
