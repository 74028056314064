import * as yup from 'yup';

export const emailError = 'Must be a valid email';

export default yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(emailError)
    .required('Email is a required field'),
});
