import React from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import styles from './Option.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  selected: boolean;
  hasIcon: boolean;
  disabled?: boolean;
  onClick: (event: MouseEvent) => void;
  children: React.ReactNode;
};

export default function Option({
  className,
  style,
  selected,
  disabled,
  onClick,
  hasIcon,
  children,
}: Props) {
  return (
    <div
      className={cx(styles.Option, className)}
      style={style}
      data-selected={selected}
      data-disabled={disabled}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: MouseEvent) => void' is not assignab... Remove this comment to see the full error message
      onClick={onClick}
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      onKeyPress={e => e.key === Key.Enter && onClick()}
      role="button"
      tabIndex={0}
    >
      {hasIcon && <div className={styles.icon} />}
      <div className={styles.description}>{children}</div>
    </div>
  );
}
