import { v4 as uuid } from 'uuid';

import getLink from '../getLink';

export default function ImageBlockMigrator(oldBlock: any) {
  return {
    _id: uuid(),
    blockId: '4VdpZFxzz9Xdj8XOEn5TUR',
    blockVersion: 0,
    name: oldBlock.name || oldBlock.type,
    properties: {
      image: oldBlock.image?._id || null,
      height: Math.round((oldBlock.height || 0) / 10),
      fullWidth: 'Full Width',
      resizeMode: 'Contain',
    },
    link: getLink(oldBlock.link),
  };
}
