import * as yup from 'yup';

import { TypeBase } from '../../types/baseTypes/TypeBase';
import { AllTypeContexts } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

// the Any type should only be used internally where a type may have several
// possible types as it's value.  This is not functionality that we expose
// to the client side builder tool.  However, some things like validators
// can have mixed types as their inputs.
export class AnyType extends TypeBase<yup.MixedSchema<any>> {
  contexts = AllTypeContexts;

  group = TYPE_GROUP_LANE;

  name = 'Any';

  friendlyName = 'Any';

  get example() {
    return null;
  }

  get default(): any {
    return null;
  }

  buildSchema() {
    return yup.mixed();
  }
}

const definition = new AnyType();

Types.addBaseType(definition);
export default definition;
