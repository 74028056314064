import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import {
  MAP_STATUS,
  ORDER_STATUS_LIST,
} from 'lane-shared/helpers/constants/integrations/BuildingEnginesClassic';
import * as BuildingEnginesClassic from 'lane-shared/helpers/integrations/BuildingEnginesClassic';
import {
  getToken,
  removeToken,
} from 'lane-shared/helpers/integrations/BuildingEnginesClassic/handleToken';

import { history } from '../../../../helpers';
import { Button, Loading } from '../../../general';
import UserIntegration from './UserIntegration';
import WorkOrderList from './WorkOrderList';

import styles from './OpenRequests.scss';

function onLogout() {
  removeToken();
  history.goBack();
}

export default function OpenRequests({ content }: any) {
  const { settings } = content.integration;
  const [storedToken, setStoredToken] = useState<any | undefined>(undefined);

  const { t } = useTranslation();
  const [loadingWorkorders, setLoadingWorkorders] = useState(true);
  const [workorders, setWorkorders] = useState([] as any);

  useEffect(() => {
    async function tokenHandler() {
      const token = await getToken();
      setStoredToken(token);
    }

    tokenHandler();
  }, [storedToken?.user]);

  useEffect(() => {
    if (!storedToken) {
      return;
    }

    async function getWorkOrders() {
      const response = await BuildingEnginesClassic.requestHelpers.fetchWorkorders(
        content.integration.settings.baseUrl,
        storedToken?.tenx,
        storedToken?.user,
        content.integration.settings.buildingId
      );

      setLoadingWorkorders(false);
      setWorkorders(response?.data?.requests);
    }

    getWorkOrders();
  }, [content, storedToken?.user]);

  if (loadingWorkorders) {
    return (
      <div className={styles.container}>
        <Loading />
      </div>
    );
  }

  if (workorders?.length === 0 && !loadingWorkorders) {
    return (
      <div className={styles.container}>
        <h1>{settings.name}</h1>
        <div className={styles.line} />
        <h2>{settings.description}</h2>
        <Link
          to={{
            pathname: routes.contentInteractionNew.replace(':id', content._id),
          }}
        >
          <Button
            className={styles.button}
            loading={loadingWorkorders}
            variant="contained"
            dataCy="BECreateNewOrder"
          >
            {t('Create a new order')}
          </Button>
        </Link>
        <UserIntegration content={content} onLogout={onLogout} />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.buttonContainer}>
        <Link
          to={{
            pathname: routes.contentInteractionNew.replace(':id', content._id),
          }}
        >
          <Button
            loading={loadingWorkorders}
            variant="contained"
            dataCy="BECreateNewOrder"
          >
            {t('Create a new order')}
          </Button>
        </Link>
      </div>

      {ORDER_STATUS_LIST.map(status => (
        <WorkOrderList
          key={status}
          sectionTitle={status}
          interactions={workorders.filter(
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            (item: any) => MAP_STATUS[item.status].toLowerCase() === status
          )}
        />
      ))}
      <UserIntegration content={content} onLogout={onLogout} />
    </div>
  );
}
