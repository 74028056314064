export type OpenIDConfiguration = {
  authorization_endpoint: string;
  token_endpoint: string;
};

export async function getOpenIdConfiguration(
  issuer: string
): Promise<OpenIDConfiguration> {
  const openIdUrl = `${issuer}/.well-known/openid-configuration`;

  const response = await fetch(openIdUrl, {
    method: 'GET',
  });

  const data = await response.json();

  return data;
}
