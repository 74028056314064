import * as yup from 'yup';

import { CONTENT_TYPES } from '../../helpers/constants/content';
import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  type: {
    type: 'String',
    validators: [
      {
        name: 'In',
        value: [
          CONTENT_TYPES.CONTENT,
          CONTENT_TYPES.NOTICE,
          CONTENT_TYPES.SCHEDULED_CONTENT,
          CONTENT_TYPES.SCHEDULED_NOTICE,
          CONTENT_TYPES.PROMOTION,
          CONTENT_TYPES.STATIC,
          CONTENT_TYPES.PERK,
        ],
      },
    ],
  },
};

export class ContentType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Content';

  friendlyName = 'Content';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  superUserOnly = false;

  get example() {
    return {
      _id: '000000Be3RPQB9nF9WZlwQ',
      type: CONTENT_TYPES.CONTENT,
    };
  }

  get default(): any {
    return {
      _id: null,
    };
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new ContentType();

Types.addBaseType(definition);
export default definition;
