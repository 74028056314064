import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  customGeneratedId: {
    type: 'UUID',
    friendlyName: 'UniqueAttachmentSectionId',
    validators: [],
  },
  attachments: {
    type: 'JSON',
    friendlyName: 'Attachments',
    isArray: true,
    validators: [
      {
        name: 'ArrayMin',
        value: 1,
      },
      {
        name: 'ArrayMax',
        value: 25,
      },
    ],
  },
};
export class AttachmentType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Attachment';

  friendlyName = 'Attachment';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  superUserOnly = false;

  properties = properties;

  get example() {
    return {
      customGeneratedId: '123',
      attachments: [],
    };
  }

  get default(): any {
    return {
      customGeneratedId: '',
      attachments: [],
    };
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }
}
export const definition = new AttachmentType();

Types.addBaseType(definition);
