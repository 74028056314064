import { toSchema } from 'lane-shared/helpers';
import { friendlyMinutes } from 'lane-shared/helpers/formatters';
import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';

// arbitrary but we need some sort of logical limit
export const MAX_QUANTITY = 1000;

export const formatters = {
  [ReservableUnitTypesEnum.Minutes]: friendlyMinutes,
  [ReservableUnitTypesEnum.Days]: (value: any) => `${value} days`,
  [ReservableUnitTypesEnum.Weeks]: (value: any) => `${value} weeks`,
  [ReservableUnitTypesEnum.Months]: (value: any) => `${value} months`,
};

export const minUnits = {
  [ReservableUnitTypesEnum.Minutes]: 15,
  [ReservableUnitTypesEnum.Days]: 1,
  [ReservableUnitTypesEnum.Weeks]: 1,
  [ReservableUnitTypesEnum.Months]: 1,
};

export const maxSlotUnits = {
  [ReservableUnitTypesEnum.Minutes]: 60 * 4,
  [ReservableUnitTypesEnum.Days]: 28,
  [ReservableUnitTypesEnum.Weeks]: 50,
  [ReservableUnitTypesEnum.Months]: 12,
};

export const maxUnits = {
  [ReservableUnitTypesEnum.Minutes]: 60 * 24,
  [ReservableUnitTypesEnum.Days]: 28,
  [ReservableUnitTypesEnum.Weeks]: 50,
  [ReservableUnitTypesEnum.Months]: 12,
};

export const enabledTypeOptions = [
  {
    label: 'Minutes',
    value: ReservableUnitTypesEnum.Minutes,
  },
  {
    label: 'Days',
    value: ReservableUnitTypesEnum.Days,
  },
];

export const unitTypeOptions = Object.values(ReservableUnitTypesEnum).map(
  toSchema
);
