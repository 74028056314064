import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesRequired } from './ITypeValidatesRequired';

export class RequiredValidator
  implements ValidatorInterface<ITypeValidatesRequired> {
  name = 'Required';

  friendlyName = 'Required Field';

  arrayOnly = false;

  array = true;

  description = 'shared.properties.validators.required.description';

  types = null;

  properties = {
    value: {
      type: 'Boolean',
      default: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesRequired) {
    type.addRequiredValidator(this);
  }

  constructor(public config?: Validator) {}
}

const Required: ValidatorInterface<ITypeValidatesRequired> = new RequiredValidator(
  undefined
);

ValidatorRegistry.register(RequiredValidator, Required.name);

export default Required;
