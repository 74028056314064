import React, { useEffect, useLayoutEffect } from 'react';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';

type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
  // eslint-disable-next-line no-undef
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];
type Handler = (event: PossibleEvent) => void;

const events: HandledEvents = [MOUSEDOWN, TOUCHSTART];

const currentDocument = typeof document !== 'undefined' ? document : undefined;

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: Handler | null,
  { document = currentDocument } = {}
) => {
  const handlerRef = React.useRef(handler);
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (!handler) {
      return;
    }

    const listener = (event: PossibleEvent) => {
      const path =
        (event as any)?.path || (event?.composedPath && event?.composedPath());
      if (
        !ref.current ||
        !handlerRef.current ||
        path.includes(ref?.current as Node)
      ) {
        return;
      }

      handlerRef.current(event);
    };

    if (document) {
      events.forEach(event => {
        document.addEventListener(event, listener);
      });
    }

    return () => {
      if (document) {
        events.forEach(event => {
          document.removeEventListener(event, listener);
        });
      }
    };
  }, [handler]);
};
