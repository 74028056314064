import urlJoin from 'url-join';

import { getEssensysBaseUrl } from './getEssensysBaseUrl';

/**
 * Simple helper function to generate a deep link into Essensys for a product url
 * @param productId
 */
export default function formatEssensysProductUrl(productId: string): string {
  return urlJoin(
    getEssensysBaseUrl(),
    `/spacecontrol/mod/265/view/${productId}`
  );
}
