import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import {
  ContentTypeEnum,
  ContentModalTypeEnum,
} from 'lane-shared/types/content/ContentTypeEnum';

import Input from 'components/form/Input';
import Button from 'components/general/Button';
import DatasetSchemaTable from 'components/lane/DatasetSchema/DatasetSchemaTable';
import Box from 'components/layout/Box';
import Flex from 'components/layout/Flex';
import { H5, H4 } from 'components/typography';

import { CreateContentOptions } from './DraftContentCreationModal';
import ModalTopRow from './components/ContentModalTopRow';
import DatabaseSelectEmptyState from './components/DatabaseSelectEmptyState';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './DatabaseSelectModal.scss';

const getDatasetSchemas = gql`
  query getDatasetSchemasOnChannelModal(
    $channelId: UUID!
    $search: DatasetSchemaSearch
  ) {
    unlinkedDatasetSchemas(channelId: $channelId, search: $search) {
      datasetSchema {
        _id
        name
        datasetRecords {
          items {
            value
          }
        }
        properties
      }
    }
  }
`;

type Props = {
  onClose: () => void;
  goToModal: (modalType: ContentModalTypeEnum) => void;
  onCreateContent: (
    contentType: ContentTypeEnum,
    { isContentGenerator, skipTemplate, generator }: CreateContentOptions
  ) => void;
};

export default function DatabaseSelectModal({
  onClose,
  goToModal,
  onCreateContent,
}: Props) {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');
  const [selectedDatasetSchema, setSelectedDatasetSchema] = useState<
    any | null
  >(null);
  const { channel } = useChannelAdminContext();

  const { data } = useQuery(getDatasetSchemas, {
    skip: !channel?._id,
    variables: {
      channelId: channel?._id,
      search: {
        name: { type: 'like', value: searchInput },
        sortBy: { key: '_created', dir: 'desc' },
      },
    },
  });

  function goToPreviousStep() {
    goToModal(ContentModalTypeEnum.Template);
  }

  function handleSelectDatasetSchema(datasetSchema: any) {
    return () => {
      if (datasetSchema._id === selectedDatasetSchema?._id) {
        setSelectedDatasetSchema(null);
        return;
      }
      setSelectedDatasetSchema(datasetSchema);
    };
  }

  return (
    <Box className={styles.container}>
      <ModalTopRow onClose={onClose} onBack={goToPreviousStep} />
      <Box p={[3, 5]} className={styles.content}>
        <div className={styles.columnLeft}>
          <H5 mb={3} bold>
            {t('Create batch content')}
          </H5>
          <H4 mb={1}>Select a database</H4>
          <Input
            value={searchInput}
            onChange={setSearchInput}
            icon="search"
            placeholder="Search data library"
            className={styles.searchInput}
          />
          <Flex direction="column">
            {data?.unlinkedDatasetSchemas
              ?.slice(0, 9)
              .map(({ datasetSchema }: any) => {
                const isSelected =
                  datasetSchema._id === selectedDatasetSchema?._id;
                return (
                  <button
                    key={datasetSchema._id}
                    className={cx(styles.datasetSchema, {
                      [styles.selected]: isSelected,
                    })}
                    onClick={handleSelectDatasetSchema(datasetSchema)}
                  >
                    <Icon name="database" set="FontAwesome" type="far" />
                    {datasetSchema.name}
                    {isSelected && (
                      <Icon
                        name="check"
                        set="FontAwesome"
                        type="far"
                        className={styles.checkmark}
                      />
                    )}
                  </button>
                );
              })}
          </Flex>
        </div>
        <Flex className={styles.columnRight} m={[0, 6]}>
          {!selectedDatasetSchema && (
            <Flex justify="center" p={[8, 0]} width="full">
              <DatabaseSelectEmptyState
                message={
                  data?.unlinkedDatasetSchema?.length > 0
                    ? 'Select a database to link to your batch content.'
                    : 'There are currently no databases in this channel.'
                }
              />
            </Flex>
          )}
          {selectedDatasetSchema && (
            <Box p={1}>
              <H4 bold mb={1}>
                Preview
              </H4>
              <H5 mb={4}>{selectedDatasetSchema.name}</H5>
              <DatasetSchemaTable datasetSchema={selectedDatasetSchema} />
            </Box>
          )}
        </Flex>
      </Box>
      <Box p={4} className={styles.bottomRow}>
        <span className={styles.spacer} />
        <Button
          variant="contained"
          className={styles.useTemplateButton}
          onClick={() =>
            onCreateContent(ContentTypeEnum.Static, {
              isContentGenerator: true,
              ...(selectedDatasetSchema
                ? { generator: { datasetSchema: selectedDatasetSchema } }
                : {}),
            })
          }
          disabled={!selectedDatasetSchema}
          dataCy="modalButtonConfirm"
        >
          {t('Get started')}
        </Button>
        <button
          className={styles.skipButton}
          onClick={() =>
            onCreateContent(ContentTypeEnum.Static, {
              isContentGenerator: true,
            })
          }
          data-cy="modalButtonSkip"
        >
          {t('Skip')}
        </button>
      </Box>
    </Box>
  );
}
