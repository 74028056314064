import React, { useState } from 'react';

import { Icon, Button } from 'design-system-web';
import cx from 'classnames';
import { Key } from 'ts-key-enum';

import {
  ICON_SET_FEATHER,
  ICON_SET_ION,
  ICON_SET_MATERIAL,
  ICON_SET_FONTAWESOME,
  ICON_SETS,
  IconSet,
  FontAwesomeType,
} from 'lane-shared/helpers/constants/icons';
import IconBaseType from 'lane-shared/properties/baseTypes/Icon';
import { ThemeColorPaletteType } from 'lane-shared/types/Theme';
import { useTranslation } from 'react-i18next';

import ControlMenu from '../../general/ControlMenu';
import ModalBackground from '../../general/ModalBackground';
import ResizableWindow from '../../general/ResizableWindow';
import Dropdown from '../Dropdown';
import Input from '../Input';
import FeatherIconPanel from './FeatherIconPanel';
import FontAwesomePanel from './FontAwesomePanel';
import IonIconPanel from './IonIconPanel';
import MaterialIconPanel from './MaterialIconPanel';

import styles from './styles.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  icon?: string;
  iconSet: IconSet;
  size?: number;
  color?: string;
  iconWeight?: string | null;
  palette: ThemeColorPaletteType;
  onChange: ({
    icon,
    iconSet,
    iconWeight,
  }: {
    icon?: string;
    iconSet?: string;
    iconWeight?: string;
  }) => void;
};

export default function IconPicker({
  size = IconBaseType.default.size,
  color,
  icon,
  iconSet = ICON_SET_FONTAWESOME,
  onChange,
  className,
  iconWeight,
  style,
  palette,
}: Props) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [iconSearch, setIconSearch] = useState('');

  const secondary = (palette! as any).secondary!;

  let Panel;

  switch (iconSet) {
    case ICON_SET_MATERIAL:
      Panel = MaterialIconPanel;
      break;
    case ICON_SET_ION:
      Panel = IonIconPanel;
      break;
    case ICON_SET_FONTAWESOME:
      Panel = FontAwesomePanel;
      break;
    case ICON_SET_FEATHER:
    default:
      Panel = FeatherIconPanel;
      break;
  }

  function changeSet(iconSet: IconSet) {
    setIconSearch('');

    onChange({
      icon,
      iconSet,
    });
  }

  function changeIcon(icon: any, iconWeight: any) {
    onChange({
      iconSet,
      icon,
      iconWeight,
    });
  }

  function changeIconSearch(searchValue: any) {
    setIconSearch(searchValue);
  }

  /**
   * onClick is captured as a bubbled up event from the icon panels for
   * performance reasons, there are hundreds of icons being rendered,
   * and passing in a click handler causes them render more than is
   * desired.
   */

  function removeIcon(e: any) {
    changeIcon(null, '');
    e.stopPropagation();
  }

  function onIconClicked(e: any) {
    function findButton(el: any, depth = 0) {
      if (!el || depth > 5) {
        return null;
      }

      if (el.tagName === 'BUTTON') {
        return el;
      }

      return findButton(el.parentElement, depth + 1);
    }

    // find the button control.
    const target = findButton(e.target);

    if (target?.parentElement?.children) {
      for (const child of target.parentElement.children) {
        child.dataset.selected = 'false';
      }
      target.dataset.selected = 'true';
      changeIcon(target.dataset.iconName, target.dataset.iconWeight);
    }
  }

  return (
    <>
      <div
        className={cx(styles.IconPicker, className)}
        style={style}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => setIsOpen(true)}
        data-cy="icon-picker"
      >
        <div className={styles.preview}>
          {icon ? (
            <Icon
              className={styles.icon}
              style={{
                fontSize: `${(size || IconBaseType.default.size) / 10}em`,
                color: color || secondary,
              }}
              name={icon}
              set={iconSet}
              type={iconWeight as FontAwesomeType}
            />
          ) : (
            <label>None selected.</label>
          )}
        </div>
        {icon && (
          <Icon
            name="times"
            className={styles.deleteButton}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: any) => void' is not assignable to type ... Remove this comment to see the full error message
            onClick={removeIcon}
          />
        )}
      </div>
      <ModalBackground
        className={styles.background}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <ResizableWindow
          className={styles.window}
          name="iconPicker"
          onClose={() => setIsOpen(false)}
          defaultPosition={ResizableWindow.centerPosition()}
          showHeader
          TopMenuComponent={
            <ControlMenu>
              <Dropdown
                className={styles.dropDown}
                onValueChange={changeSet}
                value={iconSet}
                items={ICON_SETS.map(name => ({ label: name, value: name }))}
                doTranslation={false}
              />

              <Input
                className={styles.input}
                placeholder="Search…"
                icon="search"
                value={iconSearch}
                onChange={changeIconSearch}
                showClear
              />
            </ControlMenu>
          }
          BottomMenuComponent={
            <ControlMenu className={cx(styles.menu, 'mt-2')}>
              <hr />
              <Button
                size="large"
                variant="primary"
                onClick={(e: { stopPropagation: () => void }) => {
                  setIsOpen(false);
                  e.stopPropagation();
                }}
              >
                {t('Done')}
              </Button>
            </ControlMenu>
          }
        >
          <div
            className={styles.iconWrapper}
            role="presentation"
            onClick={onIconClicked}
          >
            <Panel iconSearch={iconSearch} />
          </div>
        </ResizableWindow>
      </ModalBackground>
    </>
  );
}
