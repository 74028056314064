import * as yup from 'yup';

import { ChannelTypeEnum } from '../../types/ChannelType';

export default yup.object().shape({
  __typename: yup.string().strip(true),
  type: yup
    .string()
    .label('Type')
    .trim()
    .oneOf(Object.values(ChannelTypeEnum))
    .required(),
  parent: yup
    .object()
    .shape({
      _id: yup.string().trim().required(),
    })
    .nullable(),
});
