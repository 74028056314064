import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { IconSet } from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';

import styles from './PinListView.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  backgroundImage: string | undefined;
  backgroundColor: string;
  color: string;
  name: string;
  logo: string | undefined;
  icon: string;
  iconSet: IconSet;
  iconWeight: string | undefined;
};

export default function PinListView({
  className,
  style = {},
  backgroundImage,
  backgroundColor,
  color,
  name,
  logo,
  icon,
  iconSet,
  iconWeight,
}: Props) {
  const backgroundImages = [
    'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%)',
  ];

  if (backgroundImage) {
    backgroundImages.push(`url(${imageUrl(backgroundImage)})`);
  }

  let renderLogo;

  if (logo) {
    renderLogo = (
      <div
        className={styles.logo}
        style={{ backgroundImage: `url(${imageUrl(logo)})` }}
      />
    );
  } else if (icon) {
    renderLogo = (
      <div className={styles.logo}>
        <Icon
          className={styles.icon}
          name={icon}
          set={iconSet}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          type={iconWeight}
        />
      </div>
    );
  } else {
    renderLogo = <div className={styles.logo} />;
  }

  return (
    <div
      data-cy="pinListView"
      className={cx(styles.PinListView, className)}
      style={{
        ...style,
        backgroundImage: backgroundImages.join(', '),
        backgroundColor,
        color,
      }}
    >
      {renderLogo}
      <h1>{name}</h1>
    </div>
  );
}
