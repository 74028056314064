import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { parseDateTime } from 'lane-shared/helpers/dates';
import { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

import {
  SectionContent,
  SectionFeatureReservableAvailability,
} from '../types/unavailableDates';
import getBurstContentTimeZone from './getBurstContentTimeZone';
import updateDayMap from './updateDayMap';

export default function handleUnavailableSectionFeatureDates({
  sectionFeatureReservableAvailability = [],
  sectionContent,
  minDate,
  maxDate,
  dayMap,
  ports,
  timeZone,
}: {
  sectionFeatureReservableAvailability: SectionFeatureReservableAvailability[];
  sectionContent: SectionContent[];
  minDate: DateTime;
  maxDate: DateTime;
  dayMap: Map<string, LaneType.UUID[]>;
  timeZone: string;
  ports?: ShopifyChannelType[];
}) {
  sectionFeatureReservableAvailability.forEach(contentDateRange => {
    const channelId = sectionContent.find(
      ({ content }) => content._id === contentDateRange.contentId
    )?.content?.channel?._id;
    const contentTimeZone = getBurstContentTimeZone({
      channelId,
      ports,
      timeZone,
    });

    contentDateRange.dateRanges
      .filter(dateRange => dateRange?.startDate && dateRange?.endDate)
      .map(dateRange => ({
        startDate: parseDateTime(dateRange.startDate, contentTimeZone)!,
        endDate: parseDateTime(dateRange.endDate, contentTimeZone)!,
      }))
      .forEach(dateRange => {
        const newBookends = updateDayMap({
          contentId: contentDateRange.contentId,
          dateRange,
          dayMap,
          maxDate,
          minDate,
        });

        minDate = newBookends.minDate;
        maxDate = newBookends.maxDate;
      });
  });

  return {
    minDate,
    maxDate,
    dayMap,
  };
}
