import {
  MenuFeatureOrderType,
  MenuFeatureProperties,
} from '../../types/features/MenuFeatureTypes';
import { PaymentFeatureProperties } from '../../types/features/PaymentFeatureProperties';
import {
  FeatureQuoteLineItemType,
  FeatureQuoteType,
} from '../../types/payment/FeatureQuoteType';
import { PaymentProviderEnum } from '../../types/payment/PaymentProviderEnum';
import { PaymentTypeEnum } from '../../types/payment/PaymentTypeEnum';
import { CURRENCY_CONVERSION } from '../constants';
import getMenuFeatureItemConfiguredPrice from './getMenuFeatureItemConfiguredPrice';
import getPaymentFeatureCurrency from './getPaymentFeatureCurrency';

export default function generateMenuFeatureQuote({
  order,
  menuFeature,
  paymentFeature,
}: {
  order: MenuFeatureOrderType;
  menuFeature: MenuFeatureProperties;
  paymentFeature?: PaymentFeatureProperties;
}): FeatureQuoteType {
  const currency = getPaymentFeatureCurrency(paymentFeature);

  const items = order?.items || [];
  const total =
    items.reduce((subTotal, orderItem) => {
      const item = menuFeature.items.find(
        item => item._id === orderItem.itemId
      )!;
      const price = getMenuFeatureItemConfiguredPrice(item, orderItem);

      return subTotal + price * orderItem.quantity;
    }, 0) || 0;

  const taxes: FeatureQuoteLineItemType[] = [];
  let tax = 0;

  // apply all relevant taxes
  if (paymentFeature?.taxes) {
    paymentFeature.taxes.forEach(taxItem => {
      let taxPercent = parseFloat(String(taxItem.value).valueOf());

      // normalize any tax amounts to a %
      if (taxPercent > 1) {
        taxPercent /= CURRENCY_CONVERSION;
      }

      const taxAmount = Math.ceil(taxPercent * total);

      taxes.push({
        name: taxItem.name,
        rate: taxPercent * CURRENCY_CONVERSION,
        amount: taxAmount,
      });

      tax += taxAmount;
    });
  }

  return {
    _id: order._id,
    type: PaymentTypeEnum.Cash,
    provider: PaymentProviderEnum.Cash,
    currency,
    items: [],
    total,
    taxes,
    tax,
  };
}
