import jwtDecode from 'jwt-decode';

import { getClient } from '../apollo';
import { signUp } from '../graphql/user';
import { ChannelType } from '../types/ChannelType';
import { UserLoginProviderEnum, UserLoginTypeEnum } from '../types/UserLogin';
import { SupportedLocaleEnum } from 'localization';
import emitter, { EVENT_UPLOAD_PROFILE_PHOTO } from './emitter';
import getOAuthProfilePhoto from './oAuth/getOAuthProfilePhoto';

export type SignUpMutationDataType = {
  building?: ChannelType | null;
  company?: ChannelType | null;
  locations?: ChannelType[];
  inviteId?: string | null;
  name: string;
  isPublic?: boolean;
  password?: string;
  email?: string;
  emailOptIn: boolean;
  locale?: SupportedLocaleEnum;
  oAuth?: {
    _id?: string;
    code: string;
    loginProvider: UserLoginProviderEnum;
  };
  loginProvider: UserLoginProviderEnum | null;
  metadata?: SignUpMetadataType;
};

type SignUpVariablesType = {
  name: string;
  loginKey: string;
  loginType: UserLoginTypeEnum;
  loginProvider?: UserLoginProviderEnum;
  password?: string;
  channelIds: string[];
  inviteId?: string;
  isPublic?: boolean;
  emailOptIn: boolean;
  locale?: SupportedLocaleEnum;
  metadata?: any;
};

type SignUpMetadataType = {
  companyName: string;
  email: string;
  contentId: string;
};

// todo: this really doesn't belong here, should be put into a on signup
//  hook or event instead.
async function setupOAuthProfilePicture(oAuth: any, token: any) {
  try {
    const profilePhoto = await getOAuthProfilePhoto(oAuth);
    if (profilePhoto) {
      const { _id } = jwtDecode<{ _id: string }>(token.data.signUp.token);
      setTimeout(() => {
        emitter.emit(
          EVENT_UPLOAD_PROFILE_PHOTO,
          {
            userId: _id,
            profilePicture: profilePhoto,
          },
          2000
        );
      });
    }
  } catch {
    // Swallow, this step is permitted to fail.
  }
}

export default async function signUpMutation(data: SignUpMutationDataType) {
  const variables: Partial<SignUpVariablesType> = { channelIds: [] };

  if (data.inviteId) {
    variables.inviteId = data.inviteId;
  }

  if (!data.inviteId && data.building) {
    variables.channelIds!.push(data.building._id);
  }

  if (data.company) {
    variables.channelIds!.push(data.company._id);
  }

  if (data.locations) {
    variables.channelIds!.push(...data.locations.map(location => location._id));
  }

  if ((data as any).metadata) {
    variables.metadata = JSON.stringify((data as any).metadata);
  }

  variables.name = data.name;
  variables.isPublic = data.isPublic;
  variables.emailOptIn = data.emailOptIn;
  variables.locale = data.locale;

  if (data.oAuth) {
    variables.loginType = UserLoginTypeEnum.OAuth;
    variables.loginKey = data.oAuth.code;
    variables.loginProvider = data.oAuth.loginProvider || data.loginProvider;
    variables.password = data.password;
  } else {
    variables.loginType = UserLoginTypeEnum.Email;
    variables.password = data.password;
    variables.loginKey = data.email!;
  }

  if (data.loginProvider === UserLoginProviderEnum.Auth0) {
    variables.loginType = UserLoginTypeEnum.Auth0;
  }

  const token = await getClient().mutate({
    variables,
    mutation: signUp,
  });

  if (data.oAuth) {
    setupOAuthProfilePicture(data.oAuth, token);
  }

  return token;
}
