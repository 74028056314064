import * as yup from 'yup';

import Types from '../properties/Types';
import validatePropertySecurity from './validatePropertySecurity';
import validateValidator from './validateValidator';

const typeNames = Object.keys(Types.getTypes());

export default yup.object().shape({
  _order: yup.number().default(0).min(0).max(Number.MAX_SAFE_INTEGER),
  width: yup.number(),
  name: yup.string(),
  showControl: yup.boolean().default(true),
  friendlyName: yup.string().nullable(),
  secure: yup
    .object()
    .shape({
      create: validatePropertySecurity,
      read: validatePropertySecurity,
      update: validatePropertySecurity,
      delete: validatePropertySecurity,
    })
    .nullable(),
  type: yup.string().required().oneOf(typeNames),
  isArray: yup.bool().default(false).nullable(),
  default: yup
    .mixed()
    .nullable()
    .test('is-type', '${path} is not valid for its type.', function (value) {
      if (value) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        Types[this.parent.type].schema.validateSync(value);
      }

      return true;
    }),
  validators: yup.array().of(validateValidator).nullable(),
});
