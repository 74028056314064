import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';

import Typography from 'components/lds/Typography';

import ManagePaymentAccounts from './paymentAccountManagement/ManagePaymentAccounts';

import styles from './PaymentMethodSelectV2.scss';

type Props<TPaymentMethod> = {
  paymentAccounts: PaymentAccountType[];
  selectedPaymentAccount?: PaymentAccountType;
  onSelectPaymentAccount: (paymentAccount: PaymentAccountType) => void;
  paymentMethods?: TPaymentMethod[];
  selectedPaymentMethod: TPaymentMethod | null | undefined;
  onSelectPaymentMethod: (paymentMethod: TPaymentMethod) => void;
  onConfigure?: (
    paymentAccountType: PaymentProviderEnum,
    options: Record<any, any>
  ) => void;
};

export default function PaymentMethodSelectV2<TPaymentMethod>(
  props: Props<TPaymentMethod>
) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.paymentMethodSelect)}>
      <Typography type="h6" mode="primary">
        {t('Payment Options')}
      </Typography>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ paymentAccounts: PaymentAccountType[]; sel... Remove this comment to see the full error message */}
      <ManagePaymentAccounts {...props} />
    </div>
  );
}
