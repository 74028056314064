import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    friendlyName: 'ID',
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  coordinates: {
    friendlyName: 'Coordinates',
    type: 'GeoCoordinate',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  type: {
    friendlyName: 'GeoJSON Type',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: ['Polygon'],
      },
    ],
  },
};

export class GeoPolygon extends ObjectTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'GeoPolygon';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
    TypeContextEnum.Metatag,
  ];

  superUserOnly = false;

  friendlyName = 'Geo-location Polygon';

  get example() {
    return {
      _id: uuid(),
      type: 'Polygon',
      coordinates: [
        [-90 + Math.random() * 180, -180 + Math.random() * 360],
        [-90 + Math.random() * 180, -180 + Math.random() * 360],
      ],
    };
  }

  get default(): any {
    return null;
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new GeoPolygon();

Types.addBaseType(definition);
export default definition;
