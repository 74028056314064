import { mapValues } from 'lodash';

import { request, Res } from './common';

export interface ListParams {
  building_scope?: string;
  organization_type__in?: string;
  ordering?: string;
}

export interface ListResData {
  count: number;
  results: Organization[];
}

export interface Organization {
  id: string;
  name: string;
  is_active: boolean;
  address: {
    verified_formatted_address: string | null;
  };
}

export async function list(
  token: string,
  params: ListParams
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    path: '/organizations',
    params: mapValues(params, x => String(x)),
  });
}
