import { Interval } from 'luxon';

import parseDateTime from '../helpers/dates/parseDateTime';

export default function useDateCell({
  startDate,
  endDate,
  day,
  timeZone,
  monthStart,
}: {
  startDate: Date;
  endDate?: Date;
  day: Date;
  timeZone?: string;
  monthStart: Date;
}): {
  // is this date between two selected dates
  isBetween: boolean;
  // is this date the first selected date in a range
  firstSelected: boolean;
  // is this date the last selected date in a range
  lastSelected: boolean;
  // does the selected range start or end on the focus day
  daySelected: boolean;
  // is there a range of dates selected
  rangeSelected: boolean;
  // is this day on another month than the focus day
  otherMonth: boolean;
} {
  const _startDate = parseDateTime(startDate, timeZone);
  const _endDate = parseDateTime(endDate, timeZone);
  const _day = parseDateTime(day, timeZone);
  const _monthStart = parseDateTime(monthStart, timeZone);

  const isBetween = !!(
    _startDate &&
    _endDate &&
    _startDate < _endDate &&
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
    Interval.fromDateTimes(_startDate, _endDate).contains(_day)
  );

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const firstSelected = !!(_startDate && _day.hasSame(_startDate, 'day'));

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const lastSelected = !!(_endDate && _day.hasSame(_endDate, 'day'));

  const daySelected = !!(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
    (_startDate?.hasSame(_day, 'day') || _endDate?.hasSame(_day, 'day'))
  );

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
  const otherMonth = !_day?.hasSame(_monthStart, 'month');

  const rangeSelected = !!(
    _startDate &&
    _endDate &&
    !_startDate?.hasSame(_endDate, 'day')
  );

  return {
    isBetween,
    firstSelected,
    lastSelected,
    daySelected,
    rangeSelected,
    otherMonth,
  };
}
