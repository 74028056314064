import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { useTranslation } from 'react-i18next';

import { Button, Flex, Icon } from 'design-system-web';
import { CircleListView } from 'components';
import { M } from 'components/typography';
import { Stepper } from 'components/general';

import { mediaUrl } from 'lane-shared/helpers/formatters';

import MixedStateList from 'lane-web/src/domains/accessControl/components/user-access/MixedStateList';
import { Data } from 'lane-web/src/domains/accessControl/components/user-access/MixedStateList/types';

import { SelectedAccessGroupsToChannel, AccessGroupsListData } from './types';
import { validateAccessGroupSelection } from './utils/validators';

import styles from './styles.scss';

type props = {
  accessGroupData: AccessGroupsListData[];
  loading: boolean;
  locations: Data[];
  onEdit: () => void;
  onSubmit: (accessGroupsByChannel: SelectedAccessGroupsToChannel[]) => void;
  userCount?: number;
};

export function AccessGroupsList({
  accessGroupData,
  loading,
  locations,
  onEdit,
  onSubmit,
  userCount = 1,
}: props) {
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [accessGroupsByChannel, setAccessGroupsByChannel] = useState<
    SelectedAccessGroupsToChannel[]
  >([]);

  useEffect(() => {
    if (!loading && accessGroupData.length) {
      const accessData = accessGroupData.map(
        (channel: AccessGroupsListData) => {
          const { channelId, channelName, image, accessGroups } = channel;
          return {
            channelId,
            channelName: channelName ?? '',
            image: image ?? '',
            initialValues: accessGroups,
            selectedValues: accessGroups,
          };
        }
      );

      setAccessGroupsByChannel(accessData);
    }
  }, [accessGroupData, loading]);

  const handleAccessGroupSelect = (selectedValues: Data[]) => {
    const newAccessGroupsByChannel = [...accessGroupsByChannel];
    newAccessGroupsByChannel[currentIndex].selectedValues = selectedValues;
    setAccessGroupsByChannel(newAccessGroupsByChannel);
  };

  const handleStep = (channelName: string) => {
    const index = accessGroupsByChannel.findIndex(
      channel => channel.channelName === channelName
    );

    setCurrentIndex(index);
  };

  const handleContinue = () => {
    if (currentIndex + 1 < accessGroupsByChannel.length) {
      setCurrentIndex(currentIndex + 1);
    }

    if (currentIndex + 1 === accessGroupsByChannel.length) {
      onSubmit(accessGroupsByChannel);
    }
  };

  return (
    <div>
      <Flex m={[4, 4, 4, 0]}>
        <MixedStateList
          loading={loading}
          className={cx(styles.mobileAccessMixedStateList, styles.accessList)}
          loaderClassName={styles.mobileAccessMixedStateListLoader}
          mixedStateData={
            accessGroupsByChannel[currentIndex]?.selectedValues || []
          }
          onChange={handleAccessGroupSelect}
          mixedStateLabel={t(
            'web.admin.accessControl.userAccess.modal.manageBulkACGs.mixedLabel'
          )}
          titleComponent={
            <div>
              <CircleListView
                image={mediaUrl(accessGroupsByChannel[currentIndex]?.image)}
                logo={mediaUrl(accessGroupsByChannel[currentIndex]?.image)}
                name={accessGroupsByChannel[currentIndex]?.channelName}
                className={styles.mobileAccessCircleListView}
              >
                <M>{accessGroupsByChannel[currentIndex]?.channelName}</M>
              </CircleListView>
              <M className={styles.description}>
                {userCount > 1
                  ? t(
                      'web.admin.accessControl.userAccess.modal.enableMobileAccess.description_plural'
                    )
                  : t(
                      'web.admin.accessControl.userAccess.modal.enableMobileAccess.description'
                    )}
              </M>
            </div>
          }
          title={t(
            'web.admin.accessControl.userAccess.modal.accessControlGroup'
          )}
        />
        {!loading && accessGroupData.length > 0 && locations?.length > 1 && (
          <div className={styles.accessListStepperContainer}>
            <div className={styles.accessListEditContainer}>
              <M>{t('Locations')}</M>
              <Button
                variant="text"
                startIcon={<Icon name="edit" />}
                onClick={onEdit}
                style={{ marginLeft: 'auto' }}
              >
                {t('Edit')}
              </Button>
            </div>
            <Stepper
              steps={accessGroupsByChannel.map(channel => channel.channelName)}
              active={accessGroupsByChannel.findIndex(
                (_, idx) => idx === currentIndex
              )}
              onClick={handleStep}
              alignment="vertical"
              errorSteps={[]}
              iconName="circle"
              iconType=""
            />
          </div>
        )}
      </Flex>
      {!loading && accessGroupData.length > 0 && (
        <Button
          variant="primary"
          dataCy="mobileAccessModalEnableButton"
          onClick={handleContinue}
          disabled={
            currentIndex + 1 < accessGroupsByChannel.length
              ? !validateAccessGroupSelection([
                  accessGroupsByChannel[currentIndex],
                ])
              : !validateAccessGroupSelection(accessGroupsByChannel)
          }
          size="large"
        >
          {currentIndex + 1 < accessGroupsByChannel.length &&
          locations?.length > 1
            ? t('web.admin.accessControl.userAccess.modal.enable.nextLocation')
            : t('web.admin.accessControl.userAccess.modal.enable')}
        </Button>
      )}
    </div>
  );
}
