import { DateTime, Interval } from 'luxon';

import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

import { applyTimeStringToDateTime } from './applyTimeStringToDateTime';
import { getDayNameFromDateTime } from './helpers/getDayNameFromDate';

export function mapWeeklyAvailabilitiesToOperatingHours(
  availabilities: TimeAvailabilityFeatureProperties['availabilities'],
  referenceDateTime: DateTime
) {
  const weekDay = getDayNameFromDateTime(referenceDateTime);

  const intervalsForWeeklyAvailabilities = availabilities.reduce(
    (acc, weeklyAvailability) => {
      const weekTimeRule = weeklyAvailability.weekTimeRules[weekDay];

      if (
        weeklyAvailability.allGroupRoles &&
        weeklyAvailability.isAvailableAnytime
      ) {
        const interval = Interval.fromDateTimes(
          referenceDateTime,
          referenceDateTime.plus({ day: 1 })
        );
        acc.push(interval);
      } else if (weekTimeRule.isAvailableAllDay && weekTimeRule.isOpen) {
        const interval = Interval.fromDateTimes(
          referenceDateTime,
          referenceDateTime.plus({ day: 1 })
        );
        acc.push(interval);
      } else {
        if (!weekTimeRule.isOpen) {
          return acc;
        }

        const weekDayIntervals = weekTimeRule.timeRanges.map(tr => {
          const startDate = applyTimeStringToDateTime(
            tr.startTime,
            referenceDateTime
          );
          const endDate = applyTimeStringToDateTime(
            tr.endTime,
            referenceDateTime
          );
          return Interval.fromDateTimes(startDate, endDate);
        });
        acc.push(...weekDayIntervals);
      }

      return acc;
    },
    [] as Interval[]
  );

  const availableIntervals = Interval.merge(
    intervalsForWeeklyAvailabilities
  ).filter(i => i.isValid);

  if (availableIntervals.length === 0) {
    return null;
  }

  const startDate = DateTime.min(
    ...availableIntervals.map(interval => interval.start)
  ).toJSDate();
  const endDate = DateTime.max(
    ...availableIntervals.map(interval => interval.end)
  ).toJSDate();

  if (Interval.fromDateTimes(startDate, endDate).isEmpty()) {
    return null;
  }

  return {
    startDate,
    endDate,
    availableIntervals,
  };
}
