import parseValue from './parseValue';

type Args = {
  obj?: any;
  state?: any; // TODO: fix type
  props?: any; // TODO: fix type
  propDefs?: any; // TODO: fix type
  dataDefs?: any; // TODO: fix type
  features?: any; // TODO: fix type
  data?: any; // TODO: fix type
  $?: number;
  editMode: boolean;
  showExamples?: boolean;
};

export default function parseObject({
  obj = {},
  state = {},
  props = {},
  propDefs = {},
  dataDefs = {},
  features = {},
  data = {},
  $ = 0,
  editMode,
  showExamples = false,
}: Args) {
  const ret: Record<string, any> = {};

  Object.keys(obj).forEach(
    prop =>
      (ret[prop] = parseValue({
        value: obj[prop],
        state,
        props,
        data,
        propDefs,
        dataDefs,
        features,
        $,
        editMode,
        showExamples,
      }))
  );

  return ret;
}
