import React, { useState, useContext, useRef } from 'react';

import cx from 'classnames';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { useMutation } from '@apollo/client';

import { AppContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import signInMutation from 'lane-shared/graphql/user/signIn';
import { emitter, getValidationMessages, pause } from 'lane-shared/helpers';
import { useMagicLinksFeatureEnabled } from 'lane-shared/hooks';
import { UserLoginTypeEnum } from 'lane-shared/types/UserLogin';
import {
  validateLoginLegacy,
  validateMagicLinkLogin,
} from 'lane-shared/validation';

import ForgotPasswordComponent from 'components/authV2/ForgotPasswordComponent';
import SignupComponent from 'components/authV2/SignupComponent';
import Input from 'components/form/Input';
import Button from 'components/general/Button';
import ErrorMessage from 'components/general/ErrorMessage';
import { S, H4 } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import LoginSSOButtons from './LoginSSOButtons';
import setSharedCookiesForWebView from 'helpers/setSharedCookiesForWebView';

import styles from './Login.scss';

type Props = {
  header?: string;
  subHeader?: string;
  className?: string;
  isManualLogin?: boolean;
  onLogin?: (signInData: any) => void; // onLogin must usually handle: login by form & SSO + signup by SSO & SSO invite.
  onLoginFailed?: (err: Error) => void;
  isAuthPurchaseWrapper?: boolean;
  displayForgetPasswordForAuthPurchaseWrapper?: () => void;
  navigateToSignUp?: () => void;
  navigateToMagicLink: () => void;
  navigateToForgotPassword?: () => void;
};

/*
 * There are currently 3 places using this Login component
 * 1. The main homepage e.g https://app.joinlane.com/
 * 2. At the top navigation bar to the right, if user is not logged in "Login or Signup" is shown
 * 3. If a user is not logged in and attempt to click the purchase button
 * */
export default function Login({
  header = 'onboarding.recognized-email.welcome-header',
  subHeader = 'Log in and start improving your workplace experience.',
  isManualLogin = true,
  className,
  onLogin,
  onLoginFailed,
  isAuthPurchaseWrapper,
  displayForgetPasswordForAuthPurchaseWrapper,
  navigateToSignUp,
  navigateToMagicLink,
  navigateToForgotPassword,
}: Props) {
  const { inviteId, email: prefilledEmail, updateSignUp } = useSignUpContext();

  const [email, setEmail] = useState(
    inviteId || isManualLogin ? prefilledEmail : ''
  );
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState<ValidationError | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const { whitelabel } = useContext(AppContext);
  const [signIn] = useMutation(signInMutation);
  const inputPasswordRef = useRef<HTMLInputElement>(null);
  const [, setCookie] = useCookies(['jti', 'token']);

  const isModal = Boolean(navigateToForgotPassword);

  const magicLinksFeatureFlag = useMagicLinksFeatureEnabled();
  // manual login currently uses SSO but will not after once magicLinksFeatureFlag is disabled
  const showSSO =
    (magicLinksFeatureFlag && !isManualLogin) ||
    (!magicLinksFeatureFlag && isManualLogin);
  const validator = isManualLogin
    ? validateLoginLegacy
    : validateMagicLinkLogin;

  const emailError =
    Boolean(email) && getValidationMessages(validation, 'email');

  const passwordError =
    Boolean(password) && getValidationMessages(validation, 'password');

  const isButtonDisabled =
    (isManualLogin ? !email || !password : !email) || Boolean(validation);

  function onEnterKeyPress() {
    if (isManualLogin && password.length === 0 && inputPasswordRef.current) {
      inputPasswordRef.current.focus();
      return;
    }

    if (validation) {
      return;
    }

    onSubmit();
  }

  function handleError(err: Error | null) {
    setError(err);
  }

  async function validate(login = { email, password }): Promise<boolean> {
    try {
      // After updating the state, see if its valid.
      await validator.validate(login, { abortEarly: false });
      setValidation(null);
      return true;
    } catch (error) {
      // error object is the validation object from yup
      setValidation(error);
      return false;
    }
  }

  async function doLogin() {
    if (!(await validate())) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await pause();

      const { data } = await signIn({
        variables: {
          loginKey: email,
          password,
          loginType: UserLoginTypeEnum.Email,
        },
      });

      emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
        authToken: data.signIn,
      });

      await setSharedCookiesForWebView(
        data?.signIn?.token,
        data?.signIn?.jti,
        setCookie
      );

      if (onLogin) {
        onLogin(data?.signIn);
      }
    } catch (err) {
      setError(err);

      if (onLoginFailed) {
        onLoginFailed(err);
      }
    } finally {
      setLoading(false);
    }
  }

  function onSubmit() {
    if (isManualLogin) {
      doLogin();
      return;
    }

    updateSignUp({ email });
    navigateToMagicLink();
  }

  return (
    <div
      className={cx(
        welcomeFormStyles.formContents,
        isModal && welcomeFormStyles.isModal
      )}
    >
      {!isModal ? (
        <SignupComponent
          whitelabel={whitelabel}
          isModal={isModal}
          isAuthPurchaseWrapper={isAuthPurchaseWrapper}
          navigateToSignUp={navigateToSignUp}
        />
      ) : null}

      <div className={cx(styles.LoginForm, className)}>
        <div className={welcomeFormStyles.infoContainer}>
          <H4 mb={4} className={welcomeFormStyles.formTitle} v2>
            {t(header)}
          </H4>
          <S>{t(subHeader)}</S>
        </div>
        {showSSO ? (
          <LoginSSOButtons
            mode="LogIn"
            // @ts-expect-error ts-migrate(2322) FIXME: Type '((signInData: any) => void) | undefined' is ... Remove this comment to see the full error message
            onSuccess={onLogin}
            onError={handleError}
            oAuthConfig={whitelabel.oAuthConfig}
          />
        ) : null}
        <div
          className={cx(
            welcomeFormStyles.inputContainer,
            welcomeFormStyles.noTop
          )}
        >
          <Input
            className={welcomeFormStyles.inputMargin}
            onChange={email => {
              setEmail(email);
            }}
            value={email}
            type="email"
            disabled={Boolean(inviteId)}
            onBlur={() => validate({ email, password })}
            onFocus={() => setValidation(null)}
            onSubmit={onEnterKeyPress}
            label={t('Email')}
            error={emailError || null}
            fieldName="email"
          />

          {isManualLogin ? (
            <>
              <Input
                className={welcomeFormStyles.inputMargin}
                onChange={password => {
                  setPassword(password);
                }}
                type="password"
                value={password}
                onFocus={() => setValidation(null)}
                onBlur={() => validate({ email, password })}
                onSubmit={onEnterKeyPress}
                label={t('Password')}
                error={passwordError || null}
                ref={inputPasswordRef}
                fieldName="password"
              />
              <div className={welcomeFormStyles.forgotPassword}>
                <ForgotPasswordComponent
                  navigateToForgotPassword={navigateToForgotPassword}
                  isAuthPurchaseWrapper={isAuthPurchaseWrapper}
                  displayForgetPasswordForAuthPurchaseWrapper={
                    displayForgetPasswordForAuthPurchaseWrapper
                  }
                />
              </div>
            </>
          ) : null}
          <ErrorMessage
            className={welcomeFormStyles.errorMessage}
            error={error}
          />
          <div className={welcomeFormStyles.menu}>
            <Button
              className={welcomeFormStyles.actionButton}
              disabled={isButtonDisabled}
              dataCy="signinButton"
              variant="contained"
              type="button"
              loading={loading}
              color="inherit"
              onClick={onSubmit}
            >
              {t(isManualLogin ? 'Log in' : 'Continue')}
            </Button>
          </div>
          <div className={welcomeFormStyles.footerInfo}>
            {isModal && (
              <SignupComponent
                whitelabel={whitelabel}
                isModal={isModal}
                isAuthPurchaseWrapper={isAuthPurchaseWrapper}
                navigateToSignUp={navigateToSignUp}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
