import { WhiteLabelType } from '../types/WhiteLabelType';

type BaseURLMode =
  | 'development'
  | 'staging'
  | 'uat'
  | 'production'
  | string
  | undefined;

export default function getBaseUrl({
  mode,
  whiteLabel,
}: {
  mode: BaseURLMode;
  whiteLabel: WhiteLabelType;
}): string {
  switch (mode) {
    case 'development':
      return whiteLabel.urlDevelopment;
    case 'staging':
      return whiteLabel.urlStaging;
    case 'production':
    default:
      return whiteLabel.url;
  }
}
