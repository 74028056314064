import { useState, useEffect } from 'react';

import OnlinePlanDatasetRecordValue from 'lane-shared/hooks/integrations/essensys/types/OnlinePlanDatasetRecordValue';
import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';

import {
  AccountContactPair,
  EssensysOrganizationType,
  ORG_PAYEE_ID_NOT_FOUND,
} from '../../../types/Essensys';
import doConsolidatePaymentAccount from './effects/doConsolidatePaymentAccount';

type UseOnlinePlanProps = {
  datasetRecordValue?: OnlinePlanDatasetRecordValue;
  Sentry?: any;
  accountContactPair?: AccountContactPair;
};

export type OperateOnlinePlanPaymentSettings = {
  locationId: string | undefined;
  error: Error | undefined;
  loading: boolean;
  purchaseDisabled: boolean;
  paymentAccount: PaymentAccountType | undefined;
  organizationPayeeId: string | undefined;
};

export default function useOperateOnlinePlanPaymentSettings({
  datasetRecordValue,
  Sentry,
}: UseOnlinePlanProps): OperateOnlinePlanPaymentSettings {
  const [organization, setOrganization] = useState<
    Partial<EssensysOrganizationType> | undefined
  >(undefined);
  const [paymentAccount, setPaymentAccount] = useState<
    PaymentAccountType | undefined
  >(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  /*
   * These values should only come from CSV (datasetRecord)
   * Because these are the values correspond the correct
   * Online Plan product in Operate for purchasing
   * */

  const {
    location_id: locationId,
    client_type_id: clientTypeId,
    friendly_client_type: friendlyClientType,
  } = datasetRecordValue || {};

  useEffect(() => {
    if (locationId) {
      doConsolidatePaymentAccount({
        setLoading,
        locationId,
        clientTypeId,
        friendlyClientType,
        Sentry,
        setOrganization,
        setPaymentAccount,
        setError,
      });
    }
  }, [locationId]);

  useEffect(() => {
    if (organization?.errors?.length) {
      setError(new Error(organization.errors[0]));
    }
  }, [organization?.errors]);

  const purchaseDisabled =
    !paymentAccount?.paymentProcessor?.type || loading || Boolean(error);

  const organizationPayeeId =
    organization?.errors &&
    organization?.errors.includes(ORG_PAYEE_ID_NOT_FOUND)
      ? undefined
      : organization?.orgpayeeid;

  return {
    locationId,
    error,
    loading,
    purchaseDisabled,
    paymentAccount,
    organizationPayeeId,
  };
}
