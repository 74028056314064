import React from 'react';

import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import { CardElement, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

import styles from './CardForm.scss';

interface Props {
  cardName: {
    firstName: string;
    lastName: string;
  };
  onCardNameChange: (field: string, value: string) => void;
  onCardDetailsChange: (stripeCardField: StripeCardElement) => void;
}

export function CardFormInputFields({
  cardName,
  onCardNameChange,
  onCardDetailsChange,
}: Props) {
  const { t } = useTranslation();
  const elements = useElements();

  const handleCardDetailsChange = () => {
    const cardElement = elements?.getElement(CardElement);
    if (cardElement) {
      onCardDetailsChange(cardElement);
    }
  };
  return (
    <>
      <Input
        type="text"
        label={t('abp.payment.firstName')}
        onChange={val => onCardNameChange('firstName', val)}
        value={cardName.firstName}
        placeholder={t('abp.payment.firstName')}
        dataCy="FirstNameInput"
      />
      <Input
        type="text"
        label={t('abp.payment.lastName')}
        onChange={val => onCardNameChange('lastName', val)}
        value={cardName.lastName}
        placeholder={t('abp.payment.lastName')}
        dataCy="LastNameInput"
      />
      <div className={styles.stripeContainer} data-cy="CardDetailsInput">
        <CardElement onChange={() => handleCardDetailsChange()} />
      </div>
    </>
  );
}
