import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import { SecurityRuleTypeEnum } from '../../../../types/properties/PropertySecurity';

const properties: PropertiesInterface = {
  _id: {
    friendlyName: 'ID',
    type: 'String',
    forDisplay: false,
    secure: {
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [],
    },
  },
  cardId: {
    forDisplay: false,
    friendlyName: 'Payment Card Id',
    description: 'The Stripe card id that was used to purchase this content.',
    type: 'String',
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.Source,
        },
      ],
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
  cardBrand: {
    friendlyName: 'Card Brand',
    description: 'The brand of the card that was used.',
    type: 'String',
    secure: {
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
  cardLast4: {
    friendlyName: 'Card Last 4',
    description: 'The last 4 digits of that card that was used.',
    type: 'String',
    secure: {
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
  chargeId: {
    friendlyName: 'Payment Charge Id',
    description:
      'The charge id from the processor from the successful transaction.',
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.Source,
        },
      ],
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
    type: 'String',
  },
  charge: {
    friendlyName: 'Change Object',
    description: 'The charge object from the processor',
    forDisplay: false,
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.Source,
        },
      ],
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
    type: 'JSON',
  },
};

export class PaymentFeatureStripeDataType extends ObjectTypeBase {
  name = 'PaymentFeatureStripeData';

  friendlyName = 'Stripe Payment Data';

  contexts = [];

  get example() {
    return {
      _id: uuid(),
      chargeId: 'xxxxx',
      cardLast4: '1234',
      cardBrand: 'Visa',
      charge: {},
    };
  }

  get default(): any {
    return {
      _id: uuid(),
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new PaymentFeatureStripeDataType();

Types.addType(definition);
export default definition;
