import { Primitive } from '../../../../types/blocks/Primitive';

/**
 * @deprecated use PropertyInputBlock instead
 */
const SliderInputBlock: Primitive = {
  name: 'SliderInputBlock',
  isInput: true,
  isPrimitive: true,
  properties: {
    value: {
      type: 'Number',
      default: 0,
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
    min: {
      type: 'Number',
      validators: [
        {
          name: 'Min',
          value: -(2 ** 10),
        },
        {
          name: 'Max',
          value: 2 ** 10,
        },
      ],
    },
    max: {
      type: 'Number',
      validators: [
        {
          name: 'Min',
          value: -(2 ** 10),
        },
        {
          name: 'Max',
          value: 2 ** 10,
        },
      ],
    },
    step: {
      type: 'Number',
      default: 1,
      validators: [
        {
          name: 'Min',
          value: -(2 ** 10),
        },
        {
          name: 'Max',
          value: 2 ** 10,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    input: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default SliderInputBlock;
