import * as yup from 'yup';

import validateAddress from '../validateCreateAddress';
import validateProfile from '../validateCreateProfile';
import validateOAuthConfig from '../validateOAuthConfig';

export default yup.object().shape({
  name: yup.string().trim().min(2).max(255).required(),
  instance: yup
    .string()
    .trim()
    .min(4)
    .max(32)
    .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')
    .required(),
  description: yup
    .string()
    .trim()
    .max(2 ** 16)
    .nullable(),
  restrict: yup.boolean().default(false),
  commonName: yup.string().trim().min(6).max(1024).required(),
  sha256Fingerprint: yup.string().trim().min(95).max(95).required(),
  mobileIos: yup.string().trim().min(6).max(2048).required(),
  mobileAndroid: yup.string().trim().min(6).max(2048).required(),
  oAuthConfig: yup.array().of(validateOAuthConfig).nullable().notRequired(),
  hosts: yup.array().of(yup.string()),
  url: yup.string().trim().min(6).max(2048).required(),
  urlStaging: yup.string().trim().min(6).max(2048).required(),
  urlDevelopment: yup.string().trim().min(6).max(2048).required(),
  typography: yup.object().nullable(),
  settings: yup.object().nullable(),
  profile: validateProfile,
  address: validateAddress,
  operatingName: yup.string().trim().min(1).max(50).required(),
  shortOperatingName: yup.string().trim().min(1).max(25).required(),
});
