import { request, Res } from './common';

export interface ListParams {
  building: string;
  issueTypeId: string;
}

export interface ListResData {
  on_complete_form: unknown | null;
  on_new_form: Form | null;
}

export interface Form {
  id: string;
  type: 'form';
  children: Section[];
}

export interface Section {
  id: string;
  label: string;
  type: 'section';
  children: Field[];
}

export enum FieldType {
  Currency = 'currency',
  Date = 'date',
  Dropdown = 'dropdown',
  Number = 'number',
  TextArea = 'string', // Phone in UI is also string
  TextField = 'textfield',
}

interface IGenericField {
  fieldType: FieldType;
  id: string;
  label: string;
  meta: unknown;
  type: 'field';
  value: unknown;
}
interface GenericField extends IGenericField {
  fieldType: Exclude<FieldType, FieldType.Dropdown>;
}

export interface DropdownField extends IGenericField {
  fieldType: FieldType.Dropdown;
  customControls: string[];
}

export type Field = GenericField | DropdownField;

export async function list(
  token: string,
  params: ListParams
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    path: `/issue_types/${params.issueTypeId}/json_forms`,
    params: { building: params.building },
  });
}
