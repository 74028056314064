import React from 'react';

import RichTextHtmlRenderer from '../components/RichTextRenderer';
import i18nStub from '../helpers/i18nStub';
import { AddressType } from '../types/AddressType';
import { WhiteLabelType } from '../types/WhiteLabelType';
import { ChannelProfileType } from '../types/server/EmailTypes';
import {
  ChannelDataType,
  ContentDataType,
  WorkflowDataType,
} from '../types/server/WorkflowTypes';
import { MjmlSection, MjmlColumn } from './MjmlComponentsV2';
import EmailBody from './components/EmailBody';
import EmailFooter from './components/EmailFooter';
import EmailHeader from './components/EmailHeader';
import EmailText from './components/EmailText';
import { EmailContentNotificationBody } from './EmailContentNotificationBody';

export type EmailContentNotificationProps = {
  whiteLabel: WhiteLabelType;
  content: ContentDataType;
  channel: ChannelDataType;
  address: AddressType;
  profile: ChannelProfileType;
  timeZone: string;
  title: string;
  headerMessage: string;
  unsubscribe?: string;
  // because this component runs server side, we need the i18n passed in
  // from the current running context.
  i18n: any;
  EditableAreaTopComponent?: React.ReactNode;
  EditableAreaBottomComponent?: React.ReactNode;
  EditableAreaBodyComponent?: React.ReactNode;
  workflow?: WorkflowDataType;
};

export default function EmailContentNotification({
  whiteLabel,
  content,
  channel,
  address,
  profile,
  timeZone,
  title,
  headerMessage,
  unsubscribe,
  i18n = i18nStub,
  EditableAreaTopComponent,
  EditableAreaBottomComponent,
  EditableAreaBodyComponent,
  workflow,
}: EmailContentNotificationProps) {
  const subject = workflow?.payload?.text;
  const intro = workflow?.payload?.intro;
  const outtro = workflow?.payload?.outtro;

  return (
    <EmailBody title={subject || title}>
      <EmailHeader whiteLabel={whiteLabel} i18n={i18n} />
      {EditableAreaTopComponent && <>{EditableAreaTopComponent}</>}
      {!EditableAreaTopComponent && intro && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>
              <RichTextHtmlRenderer value={intro} />
            </EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
      <MjmlSection paddingTop={16} paddingBottom={24}>
        <MjmlColumn>
          <EmailText fontSize={20} align="center" lineHeight={28}>
            {headerMessage}
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      <EmailContentNotificationBody
        whiteLabel={whiteLabel}
        content={content}
        timeZone={timeZone}
        i18n={i18n}
        payload={workflow?.payload}
        EditableAreaBodyComponent={EditableAreaBodyComponent}
      />
      {EditableAreaBottomComponent && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>{EditableAreaBottomComponent}</EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
      {!EditableAreaBottomComponent && outtro && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>
              <RichTextHtmlRenderer value={outtro} />
            </EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
      <EmailFooter
        contactUsEmail={profile?.email || whiteLabel.profile.email}
        channel={channel}
        address={address}
        whiteLabel={whiteLabel}
        unsubscribe={unsubscribe}
        i18n={i18n}
        profile={profile}
      />
    </EmailBody>
  );
}
