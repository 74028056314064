import React from 'react';

import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import defaultColors from '../colors';

type OwnProps = {
  height?: string | number;
  width?: string | number;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  dataKey: string;
  nameKey: string;
  data: any[];
  xAxis?: any;
  yAxis?: any;
  legend?: 'top' | 'middle' | 'bottom';
  tooltipProps?: any;
  legendProps?: any;
  colors?: any[];
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PieGraph.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'PieGraph' implicitly has type 'any' because it do... Remove this comment to see the full error message
const PieGraph = ({
  data,
  margin,
  height,
  width,
  tooltipProps,
  legend,
  legendProps,
  dataKey,
  nameKey,
  colors,
}: Props) => {
  const radiusBase = typeof width === 'number' ? width : height;
  return (
    <ResponsiveContainer height={height} width={width}>
      <PieChart>
        <Tooltip {...tooltipProps} />
        {legend && (
          <Legend verticalAlign={legend} height={36} {...legendProps} />
        )}
        <Pie
          data={data}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; outerRadius: number; innerR... Remove this comment to see the full error message
          margin={margin}
          {...{ dataKey, nameKey }}
          outerRadius={radiusBase * 0.5 - 10}
          innerRadius={radiusBase * 0.5 * 0.4}
          fill="#8884d8"
          paddingAngle={0}
        >
          {data.map((entry: any, index: any) => (
            <Cell
              key={`cell_for_pie_for_${entry}_${index + 1}`}
              fill={colors[index % colors.length].base}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
PieGraph.defaultProps = {
  height: 400,
  width: '100%',
  margin: {
    top: 10,
    right: 30,
    left: 0,
    bottom: 0,
  },
  xAxis: {},
  yAxis: {},
  legend: undefined,
  tooltipProps: {},
  legendProps: {},
  colors: defaultColors,
};

export default React.memo(PieGraph);
