import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesIn } from './ITypeValidatesIn';

export class InValidator implements ValidatorInterface<ITypeValidatesIn> {
  name = 'In';

  friendlyName = 'One Of';

  arrayOnly = false;

  array = false;

  description = 'shared.properties.validators.in.description';

  types = ['String', 'Number', 'Option'];

  properties = {
    value: {
      friendlyName: 'shared.properties.validators.in.properties.friendlyName',
      default: [],
      type: 'Any',
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayUnique',
          value: true,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
        {
          name: 'ArrayMax',
          value: 2 ** 6,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesIn) {
    type.addInValidator(this);
  }

  constructor(public config?: Validator) {}
}

const In = new InValidator(undefined);

ValidatorRegistry.register(InValidator, In.name);

export default In;
