/* eslint-disable */
import React from 'react';

import cx from 'classnames';

import getBlockStyle from '../helpers/getBlockStyle';
import getMediaUrl from '../helpers/getMediaUrl';
import hasLink from '../helpers/hasLink';
import onBlockClick from '../helpers/onBlockClick';

import styles from './styles.scss';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function ImageBlock({ block, parent }: any) {
  const style = getBlockStyle(block, parent);
  const src = getMediaUrl(block.image);
  const doesHaveLink = hasLink(block);

  return (
    <div
      className={cx(styles.buildBlock, styles.imageBlock, {
        [styles.hasLink]: doesHaveLink,
      })}
      style={style}
      onClick={e => onBlockClick(e, block)}
    >
      <img alt={block.name} src={src} style={{ width: '100%' }} />
    </div>
  );
}
