import React from 'react';

import classNames from 'classnames';

import { Button } from 'design-system-web';
import Label from 'components/general/Label';

import styles from './index.scss';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

type TimeSlotOptionProps = {
  timeSlotLabel: string;
  actionLabel: string;
  timeSlot: DateRangeType;
  onClick: (arg: DateRangeType) => void;
  isSelected?: boolean;
};

export function TimeSlotOption({
  timeSlotLabel,
  timeSlot,
  actionLabel,
  onClick,
  isSelected = false,
}: TimeSlotOptionProps) {
  return (
    <div className={classNames(styles.fullSizeGrid)}>
      <div className={classNames(styles.left)}>
        <Label>{timeSlotLabel}</Label>
      </div>
      <div className={classNames(styles.right)}>
        <Button
          onClick={() => onClick(timeSlot)}
          variant="secondary"
          size="large"
          className={classNames({
            [styles.selected]: isSelected,
          })}
        >
          {actionLabel}
        </Button>
      </div>
    </div>
  );
}
