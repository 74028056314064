import { useState, useCallback } from 'react';

import { ContentRendererBlockContextType } from '../../contexts/ContentRendererContext';

export default function useContentRendererBlockContext() {
  const [
    blockContext,
    setBlockContext,
  ] = useState<ContentRendererBlockContextType>({});

  const updateBlockContext = useCallback(
    (update: Partial<ContentRendererBlockContextType>) => {
      setBlockContext(prevState => ({
        ...prevState,
        ...update,
      }));
    },
    []
  );

  return {
    updateBlockContext,
    blockContext,
  };
}
