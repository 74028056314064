// @ts-nocheck FIXME: Ignored due failing CI after React update
import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import styles from './LinkButton.scss';

type OwnLinkButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  iconName: string;
  iconSet?: string;
  doTranslate?: boolean;
  iconType?: string;
  children: React.ReactChildren | string;
  className?: string;
  style?: React.CSSProperties;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type LinkButtonProps = OwnLinkButtonProps & typeof LinkButton.defaultProps;

export default function LinkButton({
  className,
  style,
  disabled,
  loading,
  onClick,
  iconName,
  iconSet,
  iconType,
  doTranslate = false,
  children,
}: LinkButtonProps) {
  const { t } = useTranslation();

  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={cx(styles.LinkButton, className)}
      style={style}
    >
      <Icon
        className={styles.icon}
        name={iconName}
        set={iconSet}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        type={iconType}
      />
      <span>{doTranslate ? t(children) : children}</span>
    </button>
  );
}

LinkButton.defaultProps = {
  disabled: false,
  loading: false,
  onClick: () => {},
  iconSet: ICON_SET_FONTAWESOME,
  doTranslate: false,
};
