import { GroupRole } from '../../../types/GroupRole';

export const groupRoleCollectables = (
  groupRole?: Pick<GroupRole, '_id' | 'name' | 'description'>
) => {
  if (!groupRole) return {};
  return {
    name: groupRole.name,
    description: groupRole.description,
    id: groupRole._id,
  };
};
