import React from 'react';

import { imageUrl } from 'lane-shared/helpers/formatters';
import { ContentType } from 'lane-shared/types/content/Content';

import { M, S } from 'components/typography';

import styles from './PaymentPreview.scss';

export type PaymentPreviewProps = {
  content?: Pick<
    ContentType,
    'backgroundImage' | 'backgroundImageId' | 'name' | 'description'
  >;
};

export const dataTestIds = {
  root: 'PaymentPreview',
  imageSection: 'PaymentPreview-image',
  textSection: 'PaymentPreview-text',
};

export default function PaymentPreview({ content }: PaymentPreviewProps) {
  const backgroundImage: string =
    content?.backgroundImage ?? content?.backgroundImageId ?? '';
  const name: string = content?.name ?? '';
  const description: string = content?.description ?? '';

  if (!backgroundImage) {
    return null;
  }

  return (
    <div data-testid={dataTestIds.root} className={styles.previewContainer}>
      <div data-testid={dataTestIds.imageSection}>
        <div
          className={styles.previewSectionImage}
          style={{
            backgroundImage: `url(${imageUrl(backgroundImage)})`,
          }}
        />
      </div>
      <div
        data-testid={dataTestIds.textSection}
        className={styles.previewTextSection}
      >
        <div className={styles.previewTextSectionInner}>
          <div>
            <M bold>{name}</M>
          </div>
          <div>
            <S>{description}</S>
          </div>
        </div>
      </div>
    </div>
  );
}
