import { useLayoutEffect } from 'react';

import GeoJSON from 'geojson';

import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import useMapboxPoint from 'hooks/useMapboxPoint';

type OwnProps = {
  id: string;
  name?: string;
  iconImage?: string;
  size: number;
  draggable?: boolean;
  selectedColor?: string;
  onFocus: (rectangleId: string) => void;
  source?: GeoJSON.FeatureCollection<GeoJSON.Point>;
  coordinates?: GeoCoordinateType;
};

MapboxIconPoint.defaultProps = {
  onCoordinatesUpdated: () => null,
  onFocus: () => null,
};

type Props = OwnProps & typeof MapboxIconPoint.defaultProps;

export default function MapboxIconPoint({
  id,
  size,
  iconImage,
  draggable = false,
  onFocus,
  source,
  coordinates,
}: Props) {
  const layerId = `${id}-layer`;
  const sourceId = `${id}-source`;

  const { map } = useMapboxPoint({
    layerId,
    sourceId,
    onFocus,
    draggable,
    source,
    coordinates,
    onLayerLoad: map => {
      map.addLayer({
        id: layerId,
        type: 'symbol',
        source: sourceId,
        layout: {
          'icon-image': iconImage,
          'icon-size': size,
        },
      });
    },
  });

  function updateLayoutProperty(property: any, value: any) {
    if (!map) {
      return;
    }

    const layer = map.getLayer(layerId);

    if (!layer) {
      return;
    }

    try {
      map.setLayoutProperty(layerId, property, value);
    } catch (err) {
      // ok to ignore
    }
  }

  useLayoutEffect(() => {
    updateLayoutProperty('icon-image', iconImage);
  }, [iconImage]);

  useLayoutEffect(() => {
    updateLayoutProperty('icon-size', size);
  }, [size]);

  return null;
}
