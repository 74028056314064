import _ from 'lodash';

import { JsonObject } from '../../types/JSONType';
import { PropertiesInterfaceDependencies } from '../../types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';

export function clearUnsatisfiedDependencyInputValues(
  content: any,
  interaction: Object
): Object {
  const propertiesInterfaceDependencies = content?.propertiesOptions
    ? PropertiesInterfaceDependencies.fromJsonData(
        content.data,
        content.propertiesOptions?.dependencies || []
      )
    : undefined;

  const copyInteractionData: Record<string, any> = _.cloneDeep(interaction);

  if (!propertiesInterfaceDependencies) {
    return copyInteractionData;
  }

  for (const dependency of propertiesInterfaceDependencies?.dependencies?.values()) {
    if (
      !dependency.isSatisfied(copyInteractionData as JsonObject) &&
      dependency.propertyRef in copyInteractionData &&
      !Array.isArray(copyInteractionData[dependency.propertyRef])
    ) {
      copyInteractionData[dependency.propertyRef] = null;
    }
  }

  return copyInteractionData;
}
