import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';

import cx from 'classnames';
import { ErrorMessage } from 'components';
import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import { pause } from 'lane-shared/helpers';
import setLibraryOnLibraryItem from 'lane-shared/helpers/setLibraryOnLibraryItem';
import { validatePathType } from 'lane-shared/properties/baseTypes/Path';
import { LibraryType } from 'lane-shared/types/libraries';

import { Input } from 'design-system-web';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import { H2 } from 'components/typography';

import styles from './LibraryPathCreateModal.scss';

const createLibraryPathMutation = gql`
  mutation createLibraryPath($libraryItem: LibraryItemInput!) {
    createLibraryPath(libraryItem: $libraryItem) {
      _id
      path
    }
  }
`;

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  isOpen: boolean;
  atPath: string | null;
  selectedLibrary: LibraryType;
  onClose: () => void;
  onPathCreated: (newPath: string) => void;
  onCreateFailed: (error: Error) => void;
};

LibraryPathCreateModal.defaultProps = {
  onClose: () => null,
  onPathCreated: () => null,
  onCreateFailed: () => null,
};

type Props = OwnProps & typeof LibraryPathCreateModal.defaultProps;

export default function LibraryPathCreateModal({
  className,
  style,
  isOpen,
  selectedLibrary,
  atPath,
  onClose,
  onPathCreated,
  onCreateFailed,
}: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [validation, setValidation] = useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [newPath, setNewPath] = useState<string>('');
  const inputRef = useRef(null);

  function reset() {
    setError(null);
    setValidation(null);
    setSubmitting(false);
    setNewPath('');
  }

  function assemblePath() {
    // a root path does not get appended with a dot.
    // a new path should be appended to the existing path.
    // replace any spaces with an underscore
    return `${atPath ? `${atPath}.` : ''}${newPath.replace(/[\s.]/g, '_')}`;
  }

  function validate() {
    try {
      setValidation(null);
      validatePathType(assemblePath());
    } catch (err) {
      setValidation(err);
    }
  }

  async function createPath() {
    if (validation !== null) {
      return;
    }

    const libraryItem = setLibraryOnLibraryItem(
      { path: assemblePath() },
      selectedLibrary
    );

    try {
      setSubmitting(true);
      await pause();
      const { data } = await getClient().mutate({
        mutation: createLibraryPathMutation,
        variables: { libraryItem },
      });

      onPathCreated(data.createLibraryPath);
    } catch (err) {
      setError(err);
      onCreateFailed(err);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (newPath) {
      // validate when the new path changes
      validate();
    }
  }, [newPath]);

  useLayoutEffect(() => {
    if (isOpen && inputRef.current) {
      // auto-focus the input
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.focus();
    }

    // reset values on open
    reset();
  }, [isOpen]);

  return (
    <ModalBackground
      className={styles.background}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div
        className={cx(styles.LibraryPathCreateModal, className)}
        style={style}
      >
        <H2 mb={6} mt={2}>
          New Folder
        </H2>
        <Input
          ref={inputRef}
          className={styles.input}
          onChange={newPath => setNewPath(newPath)}
          value={newPath}
          maxLength={256}
          label="Folder name"
          placeholder="e.x. My Hero Images"
        />
        <ErrorMessage className={styles.errors} error={validation} />
        <ErrorMessage className={styles.errors} error={error} />
        <ControlMenu className={styles.menu}>
          <hr />
          <Button loading={submitting} onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={submitting}
            disabled={validation !== null}
            variant="contained"
            onClick={createPath}
          >
            Add
          </Button>
        </ControlMenu>
      </div>
    </ModalBackground>
  );
}
