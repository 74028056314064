// todo refactor code base to use this instead of CONTENT_TYPES
// see packages/lane-shared/helpers/constants/content.js

export enum ContentTypeEnum {
  Static = 'Static',
  Content = 'Content',
  Notice = 'Notice',
  ScheduledNotice = 'ScheduledNotice',
  ScheduledContent = 'ScheduledContent',
  Promotion = 'Promotion',
  Perk = 'Perk',
  WorkOrder = 'WorkOrder',
  VisitorManagement = 'VisitorManagement',
}

export enum ContentModalTypeEnum {
  Content = 'contentSelect',
  Template = 'templateSelect',
  Database = 'databaseSelect',
}

export const FRIENDLY_CONTENT_TYPES = {
  [ContentTypeEnum.Static]: 'Page',
  [ContentTypeEnum.Content]: 'Post',
  [ContentTypeEnum.Notice]: 'Notice',
  [ContentTypeEnum.ScheduledNotice]: 'Schedule Notice',
  [ContentTypeEnum.ScheduledContent]: 'Scheduled Content',
  [ContentTypeEnum.Promotion]: 'Promotion',
  [ContentTypeEnum.Perk]: 'Perk',
  [ContentTypeEnum.WorkOrder]: 'Work Order',
  [ContentTypeEnum.VisitorManagement]: 'Visitor Management',
};

export const FRIENDLY_CONTENT_TYPE_OPTIONS = Object.values(ContentTypeEnum)
  .filter(value => value !== ContentTypeEnum.WorkOrder)
  .map(value => ({
    label: FRIENDLY_CONTENT_TYPES[value],
    value,
  }));

export const CONTENT_TYPES_WITH_DATE = [
  ContentTypeEnum.Content,
  ContentTypeEnum.Notice,
  ContentTypeEnum.Perk,
  ContentTypeEnum.Promotion,
];

export const SCHEDULED_CONTENT_TYPES = [
  ContentTypeEnum.ScheduledContent,
  ContentTypeEnum.ScheduledNotice,
];

export const CONTENT_TYPES_WITH_ICON = [
  ContentTypeEnum.Static,
  ContentTypeEnum.VisitorManagement,
  ContentTypeEnum.WorkOrder,
];
