import React, { useState } from 'react';

import { DateTime } from 'luxon';

import { useReservableDateRange } from 'lane-shared/hooks/features/reservable/useReservableDateRange';

import DateRangeInput from 'components/form/DateRangeInput';

type Props = {
  intervalMinutes: number;
  maxDuration: number;
  timeZone: string;
  disabledWeekDays?: number[];
};

function startOfCurrentDate(timeZone: string) {
  return DateTime.fromObject({ zone: timeZone }).startOf('day').toJSDate();
}

function startOfDate(date: Date, timeZone: string) {
  return DateTime.fromJSDate(date).setZone(timeZone).startOf('day').toJSDate();
}

export function TimePickerAppearancePreview({
  intervalMinutes,
  maxDuration,
  timeZone,
  disabledWeekDays,
}: Props) {
  const [referenceDate, setReferenceDate] = useState(
    startOfCurrentDate(timeZone)
  );

  const handleDateChange = (date: Date) => {
    setReferenceDate(startOfDate(date, timeZone));
  };

  const { onTimeChange, displayableTimes, dateRange } = useReservableDateRange(
    'content-id',
    referenceDate,
    intervalMinutes,
    [],
    undefined,
    undefined,
    timeZone,
    () => undefined,
    maxDuration
  );

  return (
    <DateRangeInput
      onDateChange={handleDateChange}
      onTimeChange={onTimeChange}
      startTimes={displayableTimes.startTimes}
      endTimes={displayableTimes.endTimes}
      referenceDate={referenceDate}
      dateRange={dateRange}
      timeZone={timeZone}
      disabledWeekDays={disabledWeekDays}
      unavailableDateRanges={[]}
    />
  );
}
