import { useContext, useEffect } from 'react';

import { AnalyticsContext } from '../../contexts';
import {
  ANALYTIC_SECTION_VIEW,
  ANALYTIC_SECTION_DETAILS,
} from '../../helpers/constants/analytics';

type SectionType = {
  _id: string;
  channel?: {
    _id: string;
  };
};

type EventType = 'view' | 'details';

const eventMap = {
  view: ANALYTIC_SECTION_VIEW,
  details: ANALYTIC_SECTION_DETAILS,
};

/**
 * Used for analytics tracking in mobile or web.
 */
export default function useSectionAnalytics(
  event: EventType,
  section: SectionType | undefined | null
) {
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    if (section?._id) {
      analytics.track(eventMap[event], {
        sectionId: section?._id,
        channelId: section?.channel?._id,
      });
    }
  }, [section?._id]);
}
