/* eslint-disable */
/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function getMediaUrl(media: any) {
  if (!media) {
    // TODO: default to a place holder image?
    return 'none';
  }

  const id = typeof media === 'string' ? media : media._id;

  return `https://mona-production.s3.amazonaws.com/${id}.image`;
}
