import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import Address from '../../../../properties/baseTypes/Address';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import ThreeTenServFloor from './ThreeTenServFloor';

const properties: PropertiesInterface = {
  name: {
    friendlyName: 'Building Name',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 64,
      },
    ],
  },
  address: {
    friendlyName: 'Building Address',
    type: 'Address',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  floors: {
    friendlyName: 'Building Floors',
    type: 'ThreeTenServFloor',
    isArray: true,
    validators: [],
  },
};

export class ThreeTenServBuildingType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ThreeTenServBuilding';

  friendlyName = '310-SERV Building';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      name: 'Tower A',
      address: Address.example,
      floors: [ThreeTenServFloor.example],
    };
  }

  get default(): any {
    return {
      name: '',
      address: Address.default,
      floors: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new ThreeTenServBuildingType();

Types.addType(definition);
export default definition;
