import urlJoin from 'url-join';

import { getEssensysBaseUrl } from './getEssensysBaseUrl';

/**
 * Simple helper function to generate a deep link into Essensys for a booking url
 * @param bookingId
 */
export default function formatEssensysBookingUrl(bookingId: string): string {
  return urlJoin(
    getEssensysBaseUrl(),
    `/spacecontrol/mod/265/view/${bookingId}`
  );
}
