import { LaneType } from 'common-types';
import { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';

import { ReservableBookingItem } from '../hooks/useGetReservableBookings';
import {
  formatBookingDate,
  formatBookingTime,
  formatStatus,
} from './formatters';
import { getEarliestJoinedCompanyOrEmptyString } from './getEarliestJoinedCompanyOrEmptyString';

export type RowData = {
  id: string;
  content: { id: string | undefined; name: string | undefined };
  status: {
    initialStatus: string;
    interactionId: LaneType.UUID;
    statusFeature?: StatusesFeatureProperties;
    cancelableFeature?: CancelableFeatureProperties;
    isPastInteraction: boolean;
  };
  bookingDate: string;
  bookingTime: string;
  company: string;
  reservedBy: string;
  primaryEmail: string;
  adminNote: string;
  userNote: string;
};

function getUserNotes(booking: ReservableBookingItem) {
  const userNotes = booking.features?.Reservable?.userNotes;

  return userNotes || '';
}

export function bookingItemToRowDataMapper(
  translator: (key: string, params?: any) => string,
  channelTimeZone: string,
  bookings: ReservableBookingItem[]
): RowData[] {
  const mappedRows = bookings.map(booking => ({
    id: booking.id,
    content: {
      id: booking?.contentData?._id,
      name: booking?.contentData?.name,
    },
    status: formatStatus({
      interactionId: booking.id,
      status: booking.status,
      features: booking.contentData?.features,
      endDate: booking.endDate,
      channelTimeZone,
    }),
    bookingDate: formatBookingDate(
      booking.startDate,
      booking.endDate,
      channelTimeZone
    ),
    bookingTime: formatBookingTime(translator, {
      startDate: booking.startDate,
      endDate: booking.endDate,
      channelTimeZone,
      features: booking.contentData?.features,
    }),
    company: getEarliestJoinedCompanyOrEmptyString(booking.companies),
    reservedBy: booking.user.name,
    primaryEmail: booking.user.profile.email,
    adminNote: booking.adminNote || '',
    userNote: getUserNotes(booking),
  }));

  return mappedRows;
}
