import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  INTERACTION_CREATED,
  INTERACTION_OPEN_STATES,
} from 'lane-shared/helpers/constants/interactions';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  QRCodeCheckinFeatureCheckInOutRuleType,
  QRCodeCheckinFeatureProperties,
  QRCodeCheckinFeatureRuleType,
} from 'lane-shared/types/features/QRCodeCheckinFeatureProperties';
import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';

import Toggle from 'components/form/Toggle';
import Alert from 'components/general/Alert';
import Label from 'components/general/Label';
import { H4, H5, P } from 'components/typography';

import { FeatureRendererPropsType } from '../FeatureRendererPropsType';
import QRCodeCheckinCheckInOutRuleEdit from './QRCodeCheckinCheckInOutRuleEdit';
import QRCodeCheckinRuleEdit from './QRCodeCheckinRuleEdit';

import styles from './QRCodeCheckin.scss';

const TRANSLATION_KEYS = {
  statusesFeatureCheck:
    'web.content.features.qrCodeScanning.statusesFeatureCheck',
  checkInOut: 'web.content.features.qrCodeScanning.checkInOut',
  checkInOutEnabled: 'web.content.features.qrCodeScanning.checkInOutEnabled',
  checkIn: 'web.content.features.qrCodeScanning.checkIn',
  checkOut: 'web.content.features.qrCodeScanning.checkOut',
  qrCodeScanningRules:
    'web.content.features.qrCodeScanning.qrCodeScanningRules',
};

export default function QRCodeCheckin({
  className,
  style,
  feature,
  content,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<QRCodeCheckinFeatureProperties>) {
  const { t } = useTranslation();

  const settings: QRCodeCheckinFeatureProperties | null =
    contentFeature?.feature;

  const { statusesFeature } = explodeFeatures(content?.features);

  function constructCheckInOutRule(): QRCodeCheckinFeatureCheckInOutRuleType {
    return {
      ...constructRule(),
      content: undefined,
      autoStatus: null,
      autoStatusUnits: 1,
      autoStatusUnitType: ReservableUnitTypesEnum.Days,
    };
  }

  function constructRule(): QRCodeCheckinFeatureRuleType {
    return {
      _id: uuid(),
      fromStatus: findOpenState(),
      toStatus: null,
    };
  }

  function checkOutRuleUpdateHandler(
    update: Partial<QRCodeCheckinFeatureCheckInOutRuleType>
  ) {
    onFeatureUpdated({
      checkOut: {
        ...settings!.checkOut!,
        ...update,
      },
    });
  }

  function checkInRuleUpdateHandler(
    update: Partial<QRCodeCheckinFeatureCheckInOutRuleType>
  ) {
    onFeatureUpdated({
      checkIn: {
        ...settings!.checkIn!,
        ...update,
      },
    });
  }

  function toggleCheckinRuleHandler() {
    onFeatureUpdated({
      checkIn: settings!.checkIn ? null : constructCheckInOutRule(),
      checkOut: settings!.checkIn ? null : constructCheckInOutRule(),
    });
  }

  function findOpenState(): string {
    const rule = statusesFeature?.rules.find(rule =>
      INTERACTION_OPEN_STATES.includes(rule.status)
    );

    return rule?.status || INTERACTION_CREATED;
  }

  function removeRule(rule: Partial<QRCodeCheckinFeatureRuleType>) {
    onFeatureUpdated({
      rules: settings!.rules.filter(({ _id }) => _id !== rule._id),
    });
  }

  function ruleUpdateHandler(update: Partial<QRCodeCheckinFeatureRuleType>) {
    const ix = settings!.rules.findIndex(rule => rule._id === update._id);

    settings!.rules[ix] = {
      ...settings!.rules[ix],
      ...update,
    };

    onFeatureUpdated({
      rules: [...settings!.rules],
    });
  }

  function addRule() {
    onFeatureUpdated({
      rules: [...settings!.rules, constructRule()],
    });
  }

  return (
    <div className={cx(styles.QRCodeCheckin, className)} style={style}>
      <H4 mt={2} mb={4}>
        {t(feature.friendlyName)}
      </H4>

      <P>{t(feature.description)}</P>

      {!statusesFeature && (
        <Alert color="secondary">
          {t(TRANSLATION_KEYS.statusesFeatureCheck)}
        </Alert>
      )}

      {statusesFeature && settings && (
        <>
          <H5 mt={4}>{t(TRANSLATION_KEYS.checkInOut)}</H5>

          <Toggle
            className={styles.toggle}
            value={Boolean(settings.checkIn)}
            onChange={toggleCheckinRuleHandler}
            text={t(TRANSLATION_KEYS.checkInOutEnabled)}
          />

          {settings.checkIn && (
            <>
              <H5 mt={4}>{t(TRANSLATION_KEYS.checkIn)}</H5>
              <QRCodeCheckinCheckInOutRuleEdit
                className={styles.checkInOutRule}
                rule={settings.checkIn}
                statusesFeature={statusesFeature}
                onRuleUpdated={checkInRuleUpdateHandler}
              />
            </>
          )}
          {settings.checkOut && (
            <>
              <H5 mt={4} mb={2}>
                {t(TRANSLATION_KEYS.checkOut)}
              </H5>
              <QRCodeCheckinCheckInOutRuleEdit
                className={styles.checkInOutRule}
                rule={settings.checkOut}
                isAutoCancel
                statusesFeature={statusesFeature}
                onRuleUpdated={checkOutRuleUpdateHandler}
              />
            </>
          )}
          <Label
            TooltipComponent={t(feature!.properties!.rules.description)}
            className={styles.label}
          >
            <H5 mb={2}>{t(TRANSLATION_KEYS.qrCodeScanningRules)}</H5>
          </Label>
          <Icon
            name="plus-circle"
            set={ICON_SET_FONTAWESOME}
            className={styles.addIcon}
            onClick={addRule}
          />

          <ul className={styles.rules}>
            {settings.rules?.map(rule => (
              <QRCodeCheckinRuleEdit
                key={rule._id}
                className={styles.ruleEdit}
                rule={rule}
                statusesFeature={statusesFeature}
                onRuleDeleted={removeRule}
                onRuleUpdated={ruleUpdateHandler}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
