import gql from 'graphql-tag';

export default gql`
  query getSectionUnavailableDates(
    $id: UUID!
    $searchOptions: SearchOptionsInput
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    section(_id: $id, searchOptions: $searchOptions) {
      _id
      sectionContent {
        _id
        content {
          _id
          channel {
            _id
            settings {
              multiLanguageEnabled
              enabledLanguagesForMembers
              channelLanguages
            }
          }
          features {
            _id
            type
            feature
          }
        }
      }
    }

    sectionFeatureReservableAvailability(
      sectionId: $id
      start: $startDate
      end: $endDate
      searchOptions: $searchOptions
    ) {
      _id
      contentId
      dateRanges {
        startDate
        endDate
      }
    }
  }
`;
