import React from 'react';

import cx from 'classnames';

import { useChannelSectionsQuery } from 'lane-shared/hooks';

import SectionListView from './SectionListView';

import styles from './SectionSearchOnChannel.scss';

function SectionSearchOnChannel({
  className,
  style,
  channelId,
  slug,
  onSectionSelected,
}: any) {
  const { sections } = useChannelSectionsQuery({ channelId, slug });

  return (
    <div className={cx(styles.SectionSearchOnChannel, className)} style={style}>
      <ul data-testid="SectionSearchOnChannel">
        {sections?.map((section: any) => (
          <li key={section._id} onClick={() => onSectionSelected(section)}>
            <SectionListView
              className={styles.section}
              key={section._id}
              section={section}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SectionSearchOnChannel;
