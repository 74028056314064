import React from 'react';

import { useTranslation } from 'react-i18next';

import { PropertyType } from 'lane-shared/types/properties/Property';
import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';

import Toggle from '../../../../form/Toggle';
import Label from '../../../../general/Label';
import { FRIENDLY_PROPERTY_TEXTS } from './constants';

import styles from './FriendlyPropertyCreate.scss';

type FriendlyPropertyCreateProps = {
  property: PropertyType;
  onPropertyUpdated: (property: Partial<PropertyType>) => void;
};

export default function FriendlyPropertyCreate({
  property,
  onPropertyUpdated,
}: FriendlyPropertyCreateProps) {
  const { t } = useTranslation();
  // toggles
  function toggleUserCanInput() {
    const secure = {
      ...property.secure,
    };

    if (secure?.create) {
      // security rules have been set, remove them
      delete secure.create;
    } else {
      // else restrict, no create rules means nobody can
      // put in values when this is created.
      secure.create = [];
    }

    onPropertyUpdated({
      secure: {
        ...secure,
      },
    });
  }

  function toggleTeamCanEdit() {
    const secure = {
      ...property.secure,
    };

    if (secure?.update) {
      // security rules have been set, remove them which means anyone can
      // update
      delete secure.update;
    } else {
      // else restrict to just this channels teams.
      secure.update = [
        {
          type: SecurityRuleTypeEnum.Source,
        },
      ];
    }

    onPropertyUpdated({
      secure: {
        ...secure,
      },
    });
  }

  const canUserInput = !property?.secure?.create;
  const canTeamEdit = !property?.secure?.update;

  return (
    <>
      <Label h1 TooltipComponent={t(FRIENDLY_PROPERTY_TEXTS.tooltip.security)}>
        {t(FRIENDLY_PROPERTY_TEXTS.label.security)}
      </Label>
      <fieldset className={styles.field}>
        <Toggle
          value={canUserInput}
          onChange={toggleUserCanInput}
          text={t(FRIENDLY_PROPERTY_TEXTS.label.canUserInput)}
        />
      </fieldset>
      <fieldset className={styles.field}>
        <Toggle
          value={canTeamEdit}
          onChange={toggleTeamCanEdit}
          text={t(FRIENDLY_PROPERTY_TEXTS.label.canTeamEdit)}
        />
      </fieldset>
    </>
  );
}
