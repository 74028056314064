import { DateTime } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import parseDateTime from './parseDateTime';

const DAY_IN_MINS = 24 * 60;

/**
 * Given an array of date ranges, and a reference date, give me the earliest
 * available time and latest available time (in minutes).  This is useful
 * for creating UIs that only allow the end user to select things within
 * a time window within a day based on a bunch of availabilities.
 */
export default function getDateRangeDailyBookEnds(
  // an array of date ranges to collapse into min and max times of the day
  ranges: DateRangeType[],
  // the day we are looking at
  referenceDate: Date,
  // time zone if available.
  timeZone?: string
): {
  minTime: number;
  maxTime: number;
} {
  const startOfDay = parseDateTime(referenceDate, timeZone)!.startOf('day');

  // convert these guys to DateTime.
  const dateRanges: { startDate: DateTime; endDate: DateTime }[] = ranges.map(
    ({ startDate, endDate }) => ({
      startDate: parseDateTime(startDate, timeZone)!,
      endDate: parseDateTime(endDate, timeZone)!,
    })
  );

  // If no date ranges, you can book at any time of the day (i.e. no minTime, maxTime is the # of minutes in a day)
  if (dateRanges.length === 0) {
    return {
      minTime: 0,
      maxTime: DAY_IN_MINS,
    };
  }

  // find the latest start time.
  const minTime = dateRanges.reduce(
    (minStart, { endDate }) =>
      Math.min(
        endDate.hasSame(startOfDay, 'day')
          ? endDate.hour * 60 + endDate.minute
          : DAY_IN_MINS,
        minStart
      ),
    DAY_IN_MINS
  );

  // find the earliest end time.
  const maxTime = dateRanges.reduce(
    (maxEnd, { startDate }) =>
      Math.max(
        startDate.hasSame(startOfDay, 'day')
          ? startDate.hour * 60 + startDate.minute
          : 0,
        maxEnd
      ),
    0
  );

  return {
    minTime,
    maxTime,
  };
}
