import gql from 'graphql-tag';

export default gql`
  query getServiceRequestByIdQuery($serviceRequestId: String!) {
    serviceRequest(serviceRequestId: $serviceRequestId) {
      channelId {
        name
        id
      }
      serviceRequestId
      createdBy {
        name
        id
      }
      createdAt
      updatedAt
      updatedBy {
        name
        id
      }
      description
      category
      issue
      location
      floor
      company {
        name
        id
      }
      contentId
      extRefId
      suite
      assignee {
        name
        id
      }
      status
      customQuestions {
        Question
        Answer
      }
      assigneeGroups
      attachmentRefId
      isBillable
      equipment
      friendlyID
      notes
      hours
      userFriendlyID
      displayMetadata {
        provider
        friendlyId
      }
      nextStatuses
    }
  }
`;
