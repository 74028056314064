const securedKeys = [
  '_created',
  '_updated',
  '_updatedBy',
  '_createdBy',
  'expires',
  'settings',
  'channel',
  'account',
  'groupRole',
];

/**
 * takes in an update from the client side for a user trying to update a
 * user group role, and removes anything they should not be allowed to
 * update themselves.
 */
export default function secureUserGroupRoleUpdate(update: any): any {
  // clone the object to not modify the original.

  const ret = {
    ...update,
  };

  if (update.user) {
    update.user = update.user?._id;
  }

  securedKeys.forEach(key => delete ret[key]);

  return ret;
}
