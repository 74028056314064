import { useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';

import { getInteractiveContentOnChannel } from '../graphql/channel';
import { useMultiLanguage } from './useMultiLanguage';

export default function useInteractiveContentsQuery(
  channelId: string | null | undefined
) {
  const nowDate = useRef(new Date()).current;

  const { translate } = useMultiLanguage();

  const liveResults = useQuery(getInteractiveContentOnChannel, {
    skip: !channelId,
    variables: {
      channelId,
      search: {
        isInteractive: true,
        liveDate: {
          type: 'lt',
          value: nowDate,
        },
        unpublishDate: {
          type: 'gt',
          value: nowDate,
        },
      },
      pagination: {
        start: 0,
        perPage: 200,
      },
    },
  });

  const staticResults = useQuery(getInteractiveContentOnChannel, {
    skip: !channelId,
    variables: {
      channelId,
      search: {
        isInteractive: true,
        type: {
          type: 'eq',
          value: 'Static',
        },
      },
      pagination: {
        start: 0,
        perPage: 200,
      },
    },
  });

  const contents = useMemo(
    () => translate({ model: liveResults.data?.contentOnChannel?.items || [] }),
    [liveResults.data?.contentOnChannel?.items]
  );

  const statics = useMemo(
    () =>
      translate({ model: staticResults.data?.contentOnChannel?.items || [] }),
    [staticResults.data?.contentOnChannel?.items]
  );

  function refetchBoth() {
    liveResults.refetch();
    staticResults.refetch();
  }

  return {
    contents,
    statics,
    refetch: refetchBoth,
    loading: liveResults.loading || staticResults.loading,
  };
}
