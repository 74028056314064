import { parseDate } from '../dates';

export function isOverdue(dueDate: Date | string | undefined) {
  const parsedDueDate = parseDate(dueDate);

  if (!parsedDueDate || parsedDueDate.getTime() <= 0) {
    return false;
  }

  const now = new Date();

  const twentyFourHours = 86400000;
  const overdueDate = new Date(parsedDueDate.getTime() + twentyFourHours);
  const midnight = 0;
  overdueDate.setHours(midnight, 0, 0, 0);

  return now > overdueDate;
}
