import React from 'react';

import cx from 'classnames';
import { IconButton } from 'components';
import { CaptionProps, useNavigation } from 'react-day-picker';

import { formatMonthCaption } from '../helpers/helpers';

import styles from './CalendarHeadAdvanced.scss';

export default function CalendarHeadAdvanced({ displayMonth }: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  const nextYear = new Date(displayMonth);
  nextYear.setFullYear(displayMonth.getFullYear() + 1);
  const previousYear = new Date(displayMonth);
  previousYear.setFullYear(displayMonth.getFullYear() - 1);

  return (
    <div className={styles.calendarHeadAdvanced}>
      <IconButton
        className={cx(styles.iconButton, styles.doubleLeft)}
        icon="chevron-double-left"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousYear)}
        iconSet="FontAwesome"
        type="far"
        inverted
        dataCy="advancedPrevYear"
      />
      <IconButton
        className={cx(styles.iconButton, styles.left)}
        icon="chevron-left"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        iconSet="FontAwesome"
        type="far"
        inverted
        dataCy="advancedPrevMonth"
      />
      {formatMonthCaption(displayMonth)}
      <IconButton
        className={cx(styles.iconButton, styles.right)}
        icon="chevron-right"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
        iconSet="FontAwesome"
        type="far"
        inverted
        dataCy="advancedNextMonth"
      />
      <IconButton
        className={cx(styles.iconButton, styles.doubleRight)}
        icon="chevron-double-right"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextYear)}
        iconSet="FontAwesome"
        type="far"
        inverted
        dataCy="advancedNextYear"
      />
    </div>
  );
}
