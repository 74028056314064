import React, { memo, useState, useEffect, useRef } from 'react';

import { fontAwesomeIcons } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import {
  ICON_SET_FONTAWESOME,
  ICON_VALID_NAMES,
} from 'lane-shared/helpers/constants/icons';

import IconButton from './IconButton';
import getFilteredIcons from './getFilteredIcons';

import styles from './styles.scss';

// because there are hundreds of icons, this is generated on startup
// rather than on render.
const filteredIcons = fontAwesomeIcons.filter(icon =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  Boolean(ICON_VALID_NAMES.FontAwesome[icon.iconName])
);

const NUMBER_OF_ICONS_TO_SHOW = 300;

const iconButtons = filteredIcons.map(el => {
  return (
    <IconButton
      type={el.prefix}
      set={ICON_SET_FONTAWESOME}
      name={el.iconName}
      key={`${el.prefix}-${el.iconName}`}
      data-search-terms={el.searchTerms}
    />
  );
});

function FontAwesomeIconPanel({ iconSearch }: any) {
  const { t } = useTranslation();
  const numToShow = useRef(NUMBER_OF_ICONS_TO_SHOW);
  const [displayedIconsSubset, setDisplayedIconsSubset] = useState(
    NUMBER_OF_ICONS_TO_SHOW
  );
  const observerTarget = useRef<Element>(null);
  const interval = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          interval.current = setInterval(() => {
            numToShow.current += NUMBER_OF_ICONS_TO_SHOW;
            setDisplayedIconsSubset(numToShow.current);
          }, 50);
        } else {
          clearInterval(interval.current);
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  const iconButtonsFiltered = getFilteredIcons({
    iconSet: iconButtons,
    iconSearch,
  });

  const contents = iconButtonsFiltered.length
    ? iconButtonsFiltered.slice(0, displayedIconsSubset)
    : t('No results found.');

  return (
    <div className="pb-px">
      <menu className={styles.icons}>{contents}</menu>
      <div
        ref={observerTarget as React.RefObject<HTMLDivElement>}
        className="h-px w-full"
      />
    </div>
  );
}

export default memo(FontAwesomeIconPanel);
