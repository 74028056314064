import {
  Client,
  AuthProvider,
  Options,
} from '@microsoft/microsoft-graph-client';

export default function getMicrosoftGraphClient(
  authProvider: AuthProvider
): Client {
  const options: Options = {
    authProvider,
  };
  return Client.init(options);
}
