import React from 'react';

import cx from 'classnames';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import {
  getMenuFeatureItemConfiguredPrice,
  getMenuFeatureSelectedOptions,
} from 'lane-shared/helpers/features';
import {
  MenuFeatureItemType,
  MenuFeatureOrderItemType,
} from 'lane-shared/types/features/MenuFeatureTypes';

import QuantityInput from 'lane-web/src/components/form/QuantityInput';

import Typography from '../../lds/Typography';

import styles from './MenuOrderCartItem.scss';

type Props = {
  orderItem: MenuFeatureOrderItemType;
  item: MenuFeatureItemType;
  currencyFormat: (number: number) => string;
  hasRemoveItem: boolean;
  forReceipt?: boolean;
  onUpdateOrderItem: (props: any) => void;
};

export default function MenuOrderCartItem({
  orderItem,
  item,
  currencyFormat,
  forReceipt = false,
  onUpdateOrderItem,
}: Props) {
  const { quantity, notes } = orderItem;
  const { name } = item;

  const optionLists = getMenuFeatureSelectedOptions({ item, orderItem });
  const formattedPrice = currencyFormat(
    (getMenuFeatureItemConfiguredPrice(item, orderItem) * quantity) / 100
  );

  if (!item) {
    return null;
  }

  return (
    <div className={cx(styles.menuOrderCartItem)}>
      <div className={cx(styles.itemDetails)} data-cy="cartItem">
        <Typography type="m" mode="primary">
          {name}
          <div className={cx(styles.price)} data-cy="itemPrice">
            {' '}
            {formattedPrice}{' '}
          </div>
        </Typography>
        {forReceipt ? (
          <Typography className={cx(styles.options)} mode="secondary" type="xs">
            Qty: {quantity}
          </Typography>
        ) : null}

        <Typography className={cx(styles.notes)} type="xs" mode="secondary">
          {notes}
        </Typography>
        <div className={styles.options} data-cy="addOns">
          {optionLists?.map(optionList =>
            optionList.options.map(option => (
              <Typography
                className={cx(styles.option)}
                mode="secondary"
                type="xs"
                key={option._id}
              >
                {`${option.name} ${
                  option.price
                    ? currencyFormat(option.price / CURRENCY_CONVERSION)
                    : ''
                }`}
              </Typography>
            ))
          )}
        </div>
        {!forReceipt ? (
          <div className={cx(styles.quantityInput)} data-cy="cartQuantity">
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ classNam... Remove this comment to see the full error message */}
            <QuantityInput
              className={styles.quantityInput}
              quantity={orderItem.quantity}
              min={0}
              max={orderItem.maxQuantity}
              onChange={quantity => onUpdateOrderItem({ quantity, orderItem })}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
