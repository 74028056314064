import React from 'react';

import cx from 'classnames';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import {
  ScheduleAction,
  ScheduleActionType,
  InternalScheduleType,
} from 'lane-shared/domains/visitorManagement/reducers';

import { DaySelector } from '../../../DaySelector/DaySelector';

import styles from './WeeklyInterval.scss';

type Props = {
  state: InternalScheduleType;
  dispatch: (value: ScheduleAction) => void;
  defaultIntervalCount: number;
  className?: string;
};

export function WeeklyInterval({
  state,
  dispatch,
  defaultIntervalCount,
  className,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.weeklyInterval, className)}>
      <Input
        className={styles.input}
        label={t('web.content.feature.visitorManagement.form.label.every')}
        fixedLabel
        type="number"
        onChange={value => {
          dispatch({
            type: ScheduleActionType.SetIntervalCount,
            value: { number: Number(value) },
          });
        }}
        value={String(state.intervalCount)}
        onBlur={() => {
          if (!state.intervalCount) {
            dispatch({
              type: ScheduleActionType.SetIntervalCount,
              value: { number: defaultIntervalCount },
            });
          }
        }}
        rightText={t('web.content.feature.visitorManagement.form.label.week')}
        dataCy="weeklyIntervalCount"
      />
      <DaySelector
        selected={state.weekdayRepeats}
        onChange={days => {
          dispatch({
            type: ScheduleActionType.SetWeekdayRepeats,
            value: { numbers: days },
          });
        }}
        dataCy="weeklyDaySelector"
      />
    </div>
  );
}
