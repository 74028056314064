import gql from 'graphql-tag';

export const relayListQuery = gql`
  query GetRelayOutputList(
    $buildingId: UUID!
    $pagination: PaginationInput
    $search: RelaySearch
  ) {
    getRelayOutputList(
      buildingId: $buildingId
      pagination: $pagination
      search: $search
    ) {
      relayOutputs {
        accessPoint {
          name
          id
        }
        id
        name
        description
        relayModule {
          name
          status
        }
      }
      pageInfo {
        total
        start
        perPage
      }
    }
  }
`;
