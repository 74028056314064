import {
  validateContentInfo,
  validateContentTargeting,
  validateContentPublishing,
} from 'lane-shared/validation/content';

export const steps = ['Draft', 'Targeting', 'Publish'] as const;
export const [STEP_DRAFT, STEP_TARGETING, STEP_PUBLISH] = steps;

export const stepValidation = {
  [STEP_DRAFT]: validateContentInfo,
  [STEP_TARGETING]: validateContentTargeting,
  [STEP_PUBLISH]: validateContentPublishing,
};
