import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ScheduleType } from 'lane-shared/domains/visitorManagement/types/ScheduleType';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { ContentType } from 'lane-shared/types/content/Content';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { H5 } from 'components/typography';

import { VisitorScheduleForm } from '../components';

import styles from './AddScheduleBlock.scss';

export default function AddScheduleBlock({
  className,
  value,
  onChange,
  properties,
  content,
  submissionCompletedAt,
  show,
  isPreview = false,
}: {
  className?: string;
  value: ScheduleType | null;
  onChange: (value: ScheduleType) => void;
  properties: VisitorManagementFeatureProperties;
  content: ContentType;
  submissionCompletedAt?: Date;
  show: boolean;
  isPreview?: boolean;
}) {
  const { t } = useTranslation();

  const [longitude, latitude] = content.geo || [null, null];
  const timeZone = getTimeZoneByGeoLocation({
    latitude,
    longitude,
  });

  if (!show) return null;

  return (
    <div className={cx(styles.container, className)}>
      <H5>{t('web.content.feature.visitorManagement.scheduler.form.title')}</H5>
      <VisitorScheduleForm
        className={styles.form}
        properties={properties}
        onChange={onChange}
        timeZone={timeZone}
        value={value}
        submissionCompletedAt={submissionCompletedAt}
        isPreview={isPreview}
      />
    </div>
  );
}
