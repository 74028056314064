/**
 * Different types of payment.  This is used to indicate what are valid
 * types of payment to accept or to request.  I.e. I have created content
 * and I will accept Cash for this amount, EssensysCredits for this amount,
 * FunBucks for this amount.
 */
export enum PaymentTypeEnum {
  Cash = 'Cash',
  EssensysCredits = 'EssensysCredits',
  MoneyMovement = 'MoneyMovement', // @deprecated Left for backwards compatibility, use Cash
}
