import { DateTime } from 'luxon';

import { IntervalRange, TimeOption } from './types';

type OperatingHours = {
  startDate: Date;
  endDate: Date;
} | null;

type Props = {
  intervals: IntervalRange[];
  maxDurationMinutes: number;
  intervalMinutes: number;
  operatingHours: OperatingHours;
};

function filterEndTimes(
  endTimes: TimeOption[],
  maxEndTime: DateTime,
  endDateTime: DateTime
) {
  const updatedEndTimes = endTimes
    .filter(endTime => {
      const dateTime = DateTime.fromJSDate(endTime.date);

      return dateTime <= endDateTime;
    })
    .map(endTime => {
      const dateTime = DateTime.fromJSDate(endTime.date);

      if (dateTime <= maxEndTime) {
        return endTime;
      }

      return {
        date: endTime.date,
        disabled: true,
      };
    });

  return updatedEndTimes;
}

function isWithinCurrentInterval(
  currentTime: DateTime,
  rangeStartTime: DateTime,
  intervalMinutes: number
) {
  const rangeEndTime = rangeStartTime.plus({ minutes: intervalMinutes });

  return currentTime >= rangeStartTime && currentTime < rangeEndTime;
}

function filterPastIntervals(
  intervals: IntervalRange[],
  maxDurationMinutes: number,
  intervalMinutes: number,
  operatingHours: NonNullable<OperatingHours>
) {
  const currentDateTime = DateTime.now();
  const timeRanges: IntervalRange[] = [];
  const startDateTime = DateTime.fromJSDate(operatingHours.startDate);
  const endDateTime = DateTime.fromJSDate(operatingHours.endDate);

  for (const interval of intervals) {
    const intervalStartDateTime = DateTime.fromJSDate(interval.startTime.date);

    if (intervalStartDateTime < currentDateTime) {
      if (
        !isWithinCurrentInterval(
          currentDateTime,
          intervalStartDateTime,
          intervalMinutes
        )
      ) {
        continue;
      }
    }

    if (
      intervalStartDateTime < startDateTime ||
      intervalStartDateTime >= endDateTime
    ) {
      continue;
    }

    const maxEndTime = intervalStartDateTime.plus({
      minutes: maxDurationMinutes,
    });

    timeRanges.push({
      startTime: interval.startTime,
      endTimes: filterEndTimes(interval.endTimes, maxEndTime, endDateTime),
    });
  }

  return timeRanges;
}

export function useTimePickerIntervalRanges({
  intervals,
  maxDurationMinutes,
  intervalMinutes,
  operatingHours,
}: Props) {
  if (!operatingHours) {
    return { intervalRanges: [] as IntervalRange[] };
  }

  return {
    intervalRanges: filterPastIntervals(
      intervals,
      maxDurationMinutes,
      intervalMinutes,
      operatingHours
    ),
  };
}
