import React from 'react';

import { ANGUS_ORDER_STATUS_COLOR } from 'lane-shared/helpers/constants/integrations/AngusServiceRequests';

import styles from './StatusIndicator.scss';

export default function StatusIndicator({ status }: any) {
  return (
    <div
      className={styles.statusIndicator}
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      style={{ backgroundColor: ANGUS_ORDER_STATUS_COLOR[status] }}
    />
  );
}
