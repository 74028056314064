import { UserLoginType, UserLoginTypeEnum } from '../../types/UserLogin';
import getPrimaryLogin, { GetPrimaryLoginType } from './getPrimaryLogin';

/**
 * Tries to get the primary email first, if it can't it will find any email.
 *
 * @param logins
 */
export default function getAnyEmail<T = UserLoginType>(
  logins: GetPrimaryLoginType[] | null | undefined
): string | null {
  const primaryLogin = getPrimaryLogin<T>(logins, UserLoginTypeEnum.Email);

  if (primaryLogin) {
    return (primaryLogin as any).key;
  }

  const anyLogin = logins?.find(
    login => login.type === UserLoginTypeEnum.Email
  );

  return anyLogin?.key || null;
}
