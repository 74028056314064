/* eslint-disable */
import React from 'react';

import cx from 'classnames';

import getBlockStyle from '../helpers/getBlockStyle';
import youtubeEmbed from '../helpers/youtubeEmbed';

import styles from './styles.scss';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function YoutubeBlock({ block, parent }: any) {
  const style = getBlockStyle(block, parent);
  const src = youtubeEmbed(block);

  return (
    <div className={cx(styles.buildBlock, styles.youtubeBlock)} style={style}>
      <iframe src={src} />
    </div>
  );
}
