import { v4 as uuid } from 'uuid';

import Types from '../../properties/Types';

/**
 * Creates a new instance of a block based on it's definition.
 *
 * @param block
 * @param forExample flag to use example data
 * @returns {{_id: *}}
 */
export default function createBlockInstance(block: any, forExample = false) {
  // check to see if this is a primitive

  if (!block) {
    return null;
  }

  const instance = {
    _id: uuid(),
  };

  let properties;

  if (block.isPrimitive) {
    (instance as any).primitive = block.name;
    properties = block.properties;
  } else {
    (instance as any).blockId = block.def._id;
    (instance as any).blockVersion = block.def.version;
    properties = block.def.properties;
  }

  if (properties) {
    (instance as any).properties = {};

    Object.entries(properties).forEach(([key, property]) => {
      (instance as any).properties[key] = null;

      if ((property as any).default !== undefined) {
        (instance as any).properties[key] = JSON.parse(
          JSON.stringify((property as any).default)
        );
      } else if (forExample) {
        const type = Types.getType((property as any).type);
        const example = type.example;

        if (![undefined, null].includes(example)) {
          (instance as any).properties[key] = (property as any).isArray
            ? [example]
            : example;
        }
      }
    });
  }

  return instance;
}
