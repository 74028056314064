import React from 'react';

import { Button, Flex } from 'design-system-web';

import styles from './StickyFooter.scss';

interface StickyFooterProps {
  submitText: string;
  cancelText: string;
  detailText?: string;
  className?: string;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  enableSubmit?: boolean;
}

export const StickyFooter = ({
  submitText,
  cancelText,
  detailText = '',
  handleSubmit = () => {},
  handleCancel = () => {},
  enableSubmit = true,
}: StickyFooterProps) => {
  return (
    <Flex direction="row" className={styles.footer}>
      <Flex className={styles.submitButton}>
        <Button
          disabled={!enableSubmit}
          variant="primary"
          size="large"
          onClick={handleSubmit}
        >
          {submitText}
        </Button>
      </Flex>
      <Flex className={styles.cancelButton}>
        <Button variant="text" size="large" onClick={handleCancel}>
          {cancelText}
        </Button>
      </Flex>
      <Flex direction="row" className={styles.detailText}>
        {detailText}
      </Flex>
    </Flex>
  );
};
