import { gql } from '@apollo/client';

export default gql`
  query getDatasetSchema($id: UUID!, $pagination: PaginationInput) {
    datasetSchema(id: $id) {
      name
      description
      properties
      datasetRecords(pagination: $pagination) {
        pageInfo {
          start
          total
        }
        items {
          _id
          value
        }
      }
      generator {
        content {
          _id
          backgroundImage
          subtitle
          startDate
          endDate
          interactionEndDate
          backgroundColor
          name
          description
          tags
        }
      }
    }
  }
`;
