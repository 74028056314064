import React, { useRef, useState, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import {
  EssensysIntegrationSetupError,
  EssensysSubmitOnBehalfOfError,
} from 'activate-errors';
import { isThisError } from 'lane-shared/helpers';
import { getPaymentFeatureCurrency } from 'lane-shared/helpers/features';
import { usePaymentAndMenuFeatureConfirmation } from 'lane-shared/hooks';
import useOperateAccountContactPairs from 'lane-shared/hooks/integrations/essensys/useOperateAccountContactPairs';
import { AccountContactPair } from 'lane-shared/types/Essensys';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

import PaymentMethodContent from 'components/features/PaymentFeatureConfirmationModal/PaymentMethodContent';
import AccountForm from 'components/features/PaymentFeatureConfirmationModal/screens/AccountForm';
import LinkedAccounts from 'components/features/PaymentFeatureConfirmationModal/screens/LinkedAccounts';
import { CurrencyInput, Toggle } from 'components/form';
import { Button, ErrorMessage, Loading } from 'components/general';
import { Modal } from 'components/lds';
import PlacePayPaymentModal from 'components/payments/PlacePayPaymentModal';

import PaymentFeatureQuote from '../PaymentFeatureQuote';
import PaymentFeatureConfirmationModalProps from './PaymentFeatureConfirmationModalProps';
import Home from './screens/Home';
import useUserLocale from 'hooks/useUserLocale';

import styles from './PaymentFeatureConfirmationModal.scss';

const ALL_LOCATIONS = undefined;

export default function LegacyPaymentFeatureConfirmationModal({
  isOpen,
  onClose,
  onPaymentSuccess,
  onPaymentFailed,
  onPaymentFeatureUpdated,
  onBeforeSubmit,
  content,
  interaction,
}: PaymentFeatureConfirmationModalProps) {
  const locale = useUserLocale();
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const [modalHistory, setModalHistory] = useState<string[]>([]);
  const placePayRef = useRef({
    reject: () => null,
    resolve: () => null,
  });
  const [isPlacePayOpen, setIsPlacePayOpen] = useState(false);

  const userId = interaction.user?._id || user?._id;

  const {
    accountContactPairs,
    accountContactPair,
    createAccountContactPair,
    selectAccountContactPair,
    loading: accountContactPairsLoading,
  } = useOperateAccountContactPairs(ALL_LOCATIONS, { skip: false });

  const {
    error,
    loading: paymentMenuFeatureLoading,
    essensysError,
    essensysPaymentSettings,
    submitting,
    selectedCard,
    setSelectedCard,
    paymentAccounts,
    selectedPaymentAccount,
    isStripePaymentAccount,
    setSelectedPaymentAccount,
    isEssensys,
    loadingQuote,
    paymentFeature,
    paymentDetails,
    disabled,
    quote,
    timeZone,
    onSubmitInteraction,
    paymentType,
    essensysLoading,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ contactId: string | undefined;... Remove this comment to see the full error message
  } = usePaymentAndMenuFeatureConfirmation({
    contactId: accountContactPair?.contact.contactid,
    locale,
    content,
    interaction,
    userId,
    onBeforeSubmit,
    onPaymentFailed,
    onPaymentSuccess,
    onBeforePlacePayPayment: async () =>
      new Promise((resolve, reject) => {
        setIsPlacePayOpen(true);
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: unknown) => void' is not assignable ... Remove this comment to see the full error message
        placePayRef.current.resolve = resolve;
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(reason?: any) => void' is not assignable to... Remove this comment to see the full error message
        placePayRef.current.reject = reject;
      }),
  });

  if (!content || !interaction || !paymentFeature) {
    return null;
  }

  const handleBack = () => {
    setModalHistory(prevModalHistory => {
      if (modalHistory.length > 0) {
        return prevModalHistory.slice(0, -1);
      }
      return [];
    });
  };

  // get any applicable rules for this team role
  const applicableRules =
    paymentFeature?.rules?.filter(rule =>
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      user.roles.some(role => role.groupRole?._id === rule.groupRole?._id)
    ) || [];

  const canOverridePrice = applicableRules.some(rule => rule.canOverridePrice);

  // Submit on behalf of logic
  const userName =
    interaction.features[FeatureNameEnum.SubmitOnBehalfOf]?.user?.name || '';
  const invalidSubmitOnBehalfOf =
    interaction.features[FeatureNameEnum.SubmitOnBehalfOf]?.user?._id &&
    essensysPaymentSettings &&
    !essensysPaymentSettings.isOnAccount &&
    !isStripePaymentAccount;

  const hideError =
    isThisError(error, EssensysIntegrationSetupError) && !accountContactPair;

  const loading =
    paymentMenuFeatureLoading ||
    accountContactPairsLoading ||
    loadingQuote ||
    !quote ||
    essensysLoading;

  // TODO Ensure this is working for the Flex Member case (Credits) w/ missing payeeid
  let confirmDisabled =
    disabled ||
    (isEssensys && !(quote as any)?.data) ||
    !!invalidSubmitOnBehalfOf ||
    (essensysPaymentSettings &&
      !essensysPaymentSettings?.orgpayeeid &&
      !isStripePaymentAccount) ||
    loading;

  if (isEssensys) {
    confirmDisabled = confirmDisabled || !selectedPaymentAccount?._id;
  }

  // Don't render modal content if submitting is invalid
  const modalContent = invalidSubmitOnBehalfOf ? (
    <ErrorMessage error={new EssensysSubmitOnBehalfOfError()} />
  ) : (
    <>
      {error && !hideError && <ErrorMessage error={error} />}

      {loadingQuote ? (
        <Loading />
      ) : (
        <PaymentFeatureQuote quote={quote} content={content} />
      )}

      {canOverridePrice && (
        <div className={styles.override}>
          <Toggle
            value={interaction.features?.Payment?.overridePrice}
            onChange={() =>
              onPaymentFeatureUpdated({
                overridePrice: !interaction.features?.Payment?.overridePrice,
                overriddenAmount: quote?.total,
                amount: quote?.total,
              })
            }
            text={t('Override price')}
            doTranslate
          />
          <div className={styles.spacer} />
          {interaction.features?.Payment?.overridePrice && (
            <CurrencyInput
              value={interaction.features?.Payment?.amount || 0}
              currency={getPaymentFeatureCurrency(paymentFeature)}
              onValueChange={amount =>
                onPaymentFeatureUpdated({
                  amount,
                })
              }
            />
          )}
        </div>
      )}

      <PaymentMethodContent
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'EssensysPaymentSettingsType | undefined' is ... Remove this comment to see the full error message
        essensysPaymentSettings={essensysPaymentSettings}
        isEssensys={isEssensys}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Error | undefined' is not assignable to type... Remove this comment to see the full error message
        essensysError={essensysError}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Error | null' is not assignable to type 'Err... Remove this comment to see the full error message
        error={error}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentTypeEnum | null' is not assignable to... Remove this comment to see the full error message
        paymentType={paymentType}
        quote={quote}
        selectedCard={selectedCard}
        paymentDetails={paymentDetails}
        paymentAccounts={paymentAccounts}
        selectedPaymentAccount={selectedPaymentAccount}
        setSelectedPaymentAccount={setSelectedPaymentAccount}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Dispatch<any>' is not assignable to type '()... Remove this comment to see the full error message
        setSelectedCard={setSelectedCard}
        onClose={onClose}
        timeZone={timeZone}
        submitting={submitting}
      />
    </>
  );

  const hasAccountContactPairs = Boolean(accountContactPairs?.length);

  const suggestedCompanyName = accountContactPairs?.[0]?.account.clientname;

  const modalHistoryMap: {
    [key: string]: {
      title: string;
      component: React.ComponentType<any>;
      componentProps?: Record<any, any>;
      actions?: React.ComponentType<any>;
      actionsProps?: Record<any, any>;
    };
  } = {
    default: {
      title: t('Confirm your purchase'),
      component: Home,
      componentProps: {
        className: cx(styles.modalContainer),
        children: modalContent,
        userName,
        loading,
        essensysPaymentSettings,
        setModalHistory,
        accountContactPair,
        accountContactPairs,
        isStripePaymentAccount,
      },
      actions: () => (
        <Button
          fullWidth
          variant="contained"
          onClick={onSubmitInteraction}
          disabled={confirmDisabled}
        >
          {t(!submitting ? 'Purchase' : 'In progress...')}
        </Button>
      ),
    },
    linkedAccounts: {
      title: t('Linked accounts'),
      component: LinkedAccounts,
      componentProps: {
        className: cx(styles.modalContainer),
        contentId: content._id,
        accountContactPair,
        accountContactPairs,
        onAccountContactPairSelect: (
          accountContactPair: AccountContactPair
        ) => {
          selectAccountContactPair(accountContactPair);
          setModalHistory([]);
        },
        onAddNewAccountClick: () =>
          setModalHistory(prev => [...prev, 'confirmYourAccountInfo']),
      },
    },
    confirmYourAccountInfo: {
      title: hasAccountContactPairs
        ? t('Confirm your account info')
        : t('Add a new account'),
      component: AccountForm,
      componentProps: {
        className: cx(styles.modalContainer),
        buttonLabel: hasAccountContactPairs ? t('Confirm') : t('Add'),
        createAccountContactPair: async (companyName: any, email: any) => {
          await createAccountContactPair(companyName, email, content._id);
          setModalHistory([]);
        },
        defaultCompanyName: suggestedCompanyName,
      },
    },
  };

  const currentHistory = modalHistory[modalHistory.length - 1] ?? 'default';
  const {
    component: CurrentComponent,
    title,
    actions: CurrentActions,
    componentProps,
  } = modalHistoryMap[currentHistory];

  return (
    <>
      <Modal
        isOpen={isOpen}
        onBack={handleBack}
        modalHistory={modalHistory}
        onClose={onClose}
        title={title}
        actions={CurrentActions ? <CurrentActions /> : null}
      >
        <CurrentComponent {...componentProps} />
      </Modal>

      <PlacePayPaymentModal
        isOpen={isPlacePayOpen}
        paymentAccountId={selectedPaymentAccount?._id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        payeeId={essensysPaymentSettings?.orgpayeeid}
        amount={
          (quote as any)?.data?.overage
            ? (quote as any)?.data?.overage?.finalPriceInCents
            : // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              quote?.total + quote?.tax
        }
        description={content.name}
        onClose={() => {
          setIsPlacePayOpen(false);
          placePayRef.current.reject();
        }}
        onPaymentSuccess={data => {
          setIsPlacePayOpen(false);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.resolve(data);
        }}
        onPaymentFailed={err => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.reject(err);
          setIsPlacePayOpen(false);
        }}
      />
    </>
  );
}

LegacyPaymentFeatureConfirmationModal.defaultProps = {
  isOpen: false,
  onClose: () => undefined,
  onPaymentSuccess: () => undefined,
  onPaymentFailed: () => undefined,
  onPaymentFeatureUpdated: () => undefined,
  onBeforeSubmit: () => undefined,
};
