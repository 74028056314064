/* eslint-disable */
import hasLink from './hasLink';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function onBlockClick(e: any, block: any) {
  if (!hasLink(block)) {
    return;
  }

  e.stopPropagation();

  const { type } = block.link;
  let { url } = block.link;

  if (url.en) {
    url = url.en;
  }

  // These are all mobile only link types.
  if (['InApp', 'Channel', 'Content', 'Service', 'Media'].includes(type)) {
    alert(`// TODO make these links work. ${type} ${url}`);
    return;
  }

  // make sure the link has the required prefix.
  switch (type) {
    case 'Phone':
      window.open(url.startsWith('tel:') ? url : `tel:${url}`);
      return;
    case 'Email':
      window.open(url.startsWith('mailto:') ? url : `mailto:${url}`);
      return;
    case 'Web':
      window.open(url.startsWith('http') ? url : `http://${url}`, '_blank');
  }
}
