import React, { useContext, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { UserContentInteractionContext } from 'lane-shared/contexts';
import {
  useEarliestAvailableTimeOption,
  useFlag,
  useTimeSlotsPicker,
} from 'lane-shared/hooks';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';
import validateUserContentInteraction from 'lane-shared/validation/content/validateUserContentInteraction';

import { CustomTimeChips } from '../CustomTimeChips';
import DatePickerButtonV3 from '../DatePickersV3/DatePickerButtonV3';
import TimeChips from '../TimeChips';
import { Durations } from './Durations';

import styles from './TimeSlotsPicker.scss';
import { TimeSlotInput } from './TimeSlotInput';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

type TimeSlotsPickerProps = {
  className?: string;
  style?: React.CSSProperties;
  value: DateRangeType;
  existingValue?: DateRangeType;
  // a date that the user maybe currently looking at, this may or may not
  // be similar to value.  They maybe browsing other days.
  referenceDate?: Date;
  onDayChange?: (date: Date) => void;
  onChange: (range: DateRangeType) => void;
  // the unit size in minutes that can be selected
  slotSize: number;
  // the maximum number of slots that can be shown
  maxSlots: number;
  // the minimum date that is available
  minDate?: Date;
  // the maximum date that is available
  maxDate?: Date;
  // the earliest time that can be selected in minutes
  minTime?: number;
  // the latest time that can be selected in minutes
  maxTime?: number;
  showDatePicker?: boolean;
  disabled?: boolean;
  // should the control show a loading state
  loading?: boolean;
  // an array of date ranges that are unavailable */
  unavailableDateRanges?: DateRangeType[];
  // an array of date ranges that are unavailable */
  monthlyTimeAvailabilityUnavailableDateRanges?: DateRangeType[];
  // a time availability feature if applicable.
  timeAvailabilityFeature?: TimeAvailabilityFeatureProperties;
  // the time zone, if available
  timeZone?: string;
  hasCustomTimeSlots: Boolean;
  customTimeSlots?: Array<LaneType.TimeRange>;
  testId?: string;
  disabledWeekDays?: number[];
  hasWaitlistEnabled?: Boolean;
  contentId?: string;
  maxQuantityPerSlotPerUser: number;
};

type DurationsWithTimeChipsProps = {
  hasCustomTimeSlots: Boolean;
  options: DateRangeType[];
  onChangeWrapper: (range: DateRangeType) => void;
  unavailableTimeRanges: DateRangeType[];
  value: DateRangeType;
  timeZone?: string;
  maxSlots: number;
  slotSize: number;
};

function DurationsWithTimeChips({
  hasCustomTimeSlots,
  options,
  onChangeWrapper,
  unavailableTimeRanges,
  value,
  timeZone,
  maxSlots,
  slotSize,
}: DurationsWithTimeChipsProps) {
  const { t } = useTranslation();

  if (hasCustomTimeSlots) {
    return (
      <CustomTimeChips
        options={options}
        onClick={onChangeWrapper}
        unavailableDateRanges={unavailableTimeRanges}
        value={value}
        timeZone={timeZone}
      />
    );
  }

  return (
    <>
      <h5>{t('web.content.feature.reservable.timeSlots.duration')}</h5>
      <div className={styles.Duration}>
        <Durations maxSlots={maxSlots} slotSize={slotSize} />
      </div>
      <TimeChips
        options={options}
        onClick={onChangeWrapper}
        value={value}
        unavailableDateRanges={unavailableTimeRanges}
        timeZone={timeZone}
      />
    </>
  );
}

export default function TimeSlotsPicker({
  value,
  referenceDate,
  timeZone,
  disabled = false,
  loading = false,
  onChange,
  onDayChange = () => {},
  slotSize = 30,
  maxSlots = 1,
  showDatePicker = true,
  minDate,
  maxDate,
  minTime = 0,
  maxTime = 24 * 60,
  unavailableDateRanges = [],
  monthlyTimeAvailabilityUnavailableDateRanges = [],
  timeAvailabilityFeature,
  className,
  style,
  hasCustomTimeSlots = false,
  customTimeSlots,
  testId,
  disabledWeekDays,
  hasWaitlistEnabled = false,
  contentId,
  maxQuantityPerSlotPerUser,
}: TimeSlotsPickerProps) {
  const { options, updateUnavailableRanges } = useTimeSlotsPicker({
    timeAvailabilityFeature,
    referenceDate,
    value,
    timeZone,
    minTime,
    maxTime,
    slotSize: slotSize * maxSlots,
    useCustomTimeSlots: hasCustomTimeSlots,
    customTimeSlots,
  });
  const { validateAdditional } = useContext(UserContentInteractionContext);

  useEffect(() => {
    validateAdditional(validateUserContentInteraction);
  }, [hasCustomTimeSlots]);

  const onChangeWrapper = (range: DateRangeType) => {
    onChange(range);
    validateAdditional(validateUserContentInteraction);
  };

  const onDayChangeWrapper = (date: Date) => {
    onDayChange(date);
    onChange({ startDate: null, endDate: null });
    validateAdditional(validateUserContentInteraction);
  };

  const isWaitlistEnabled = hasWaitlistEnabled;

  const nowWithTimezone = timeZone
    ? DateTime.now().setZone(timeZone)
    : DateTime.now();

  const unavailableTimeRanges: DateRangeType[] = useMemo(() => {
    const pastTimeRange: DateRangeType[] = [];

    if (!hasCustomTimeSlots) {
      pastTimeRange.push({
        startDate: nowWithTimezone?.startOf('day').toJSDate(),
        endDate: nowWithTimezone?.toJSDate(),
      });
    }

    return [...unavailableDateRanges, ...pastTimeRange];
  }, [unavailableDateRanges, hasCustomTimeSlots, nowWithTimezone]);

  useEarliestAvailableTimeOption(options, unavailableTimeRanges, onChange);

  const isRecurringEventsFlagEnabled = useFlag<boolean>(
    FeatureFlag.RecurringEvents,
    false
  );

  const getDatePickerButton = () => {
    return (
      <DatePickerButtonV3
        value={referenceDate || value?.startDate}
        maxDate={maxDate}
        minDate={minDate}
        timezone={timeZone}
        onChange={isRecurringEventsFlagEnabled ? onDayChangeWrapper : undefined}
        onSubmit={isRecurringEventsFlagEnabled ? undefined : onDayChangeWrapper}
        isLoading={loading}
        onFocusChange={updateUnavailableRanges}
        unavailableDateRanges={unavailableDateRanges}
        disabledWeekDays={disabledWeekDays}
      />
    );
  };

  return (
    <div
      className={cx(styles.TimeSlotsPicker, className)}
      style={style}
      data-testid={testId}
      data-is-disabled={disabled}
      data-is-loading={loading}
    >
      {showDatePicker && getDatePickerButton()}

      {!isWaitlistEnabled && (
        <DurationsWithTimeChips
          hasCustomTimeSlots={hasCustomTimeSlots}
          options={options}
          onChangeWrapper={onChangeWrapper}
          unavailableTimeRanges={unavailableTimeRanges}
          value={value}
          timeZone={timeZone}
          maxSlots={maxSlots}
          slotSize={slotSize}
        />
      )}

      {isWaitlistEnabled && (
        <TimeSlotInput
          isFetchingReservable={loading}
          timeSlots={options}
          onClick={onChangeWrapper}
          unavailableByRules={monthlyTimeAvailabilityUnavailableDateRanges}
          unavailableByOccupied={unavailableDateRanges}
          selectedTimeSlot={value}
          timeZone={timeZone}
          selectedDay={referenceDate}
          contentId={contentId}
          maxQuantityPerSlotPerUser={maxQuantityPerSlotPerUser}
        />
      )}
    </div>
  );
}
