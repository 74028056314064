/**
 * Different types of payment providers.  These represent the possible sources
 * or destinations of where a payment will be made.
 */
export enum PaymentProviderEnum {
  Cash = 'Cash',
  Stripe = 'Stripe',
  PlacePay = 'PlacePay',
  Essensys = 'Essensys',
}
