import React from 'react';

import Calendar from './components/Calendar';

import styles from './DatePickers.scss';

type OwnProps = {
  onChange: (...args: any[]) => any;
  value?: any;
  includeTime?: boolean;
  minDate?: any;
  maxDate?: any;
  timeZone?: string;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof DatePicker.defaultProps;

/*
  @deprecated use DatePickerButton in design-system-web instead.  
*/
function DatePicker({
  value,
  includeTime,
  onChange,
  minDate,
  maxDate,
  timeZone,
}: Props) {
  return (
    <div className={styles.DatePicker}>
      <Calendar
        onChange={onChange}
        startDate={value}
        includeTime={includeTime}
        minDate={minDate}
        maxDate={maxDate}
        timeZone={timeZone}
      />
    </div>
  );
}

DatePicker.defaultProps = {
  value: null,
  includeTime: false,
  minDate: null,
  maxDate: null,
  timeZone: null,
};

export default DatePicker;
