import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

const serialize = (value: any) => {
  if (!value) {
    return value;
  }

  if (typeof value === 'string') {
    return value;
  }

  return value.rgb().string();
};

export class ColorType extends StringTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'Color';

  friendlyName = 'Color';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Properties,
  ];

  get example() {
    function randomColor() {
      return Math.floor(Math.random() * 255);
    }

    return `rgba(${randomColor()},${randomColor()},${randomColor()},1)`;
  }

  get default(): any {
    return 'rgba(255,255,255,1)';
  }

  superUserOnly = true;

  buildSchema() {
    return yup.string().nullable();
  }

  serialize = serialize;

  parseValue = serialize;
}

const definition = new ColorType();

Types.addBaseType(definition);

export default definition;
