import { EssensysInvoiceClientType } from '../../../types/Essensys';

export const STUDIO_GLOBAL_ACCESS_LOCATION_ID = '17';

export const ESSENSYS_DEFAULT_ACCOUNT = {
  location: '',
  credit: 0,
  printingBlackAndWhiteCredit: 0,
  printingColorCredit: 0,
};

export const ESSENSYS_DEFAULT_INVOICES = {
  invoices: [],
};

export const ESSENSYS_DEFAULT_INVOICE: EssensysInvoiceClientType = {
  description: '',
  paid: false,
  endDate: new Date(),
  startDate: new Date(),
  gross: 0,
  outstanding: 0,
  bfwd: 0,
  _id: '',
  number: '',
  accountId: '',
  accountName: '',
  approved: false,
  clientTypeId: 1,
  clientTypeName: '',
  currencyCode: '',
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'string[]'... Remove this comment to see the full error message
  emailAddress: '',
  endingBalance: 0,
  net: 0,
  location: '',
  locationName: '',
  organizationId: '',
  organizationName: '',
  tax: '',
};

export const ESSENSYS_DEFAULT_CONTACT = {
  printingPin: 0,
};
