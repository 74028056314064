import { AddressType } from '../../types/AddressType';

const defaultProps: (keyof AddressType)[] = [
  'name',
  'street1',
  'street2',
  'city',
  'state',
  'country',
  'code',
];

export default function addressFormatter(
  address: Partial<AddressType> | null | undefined,
  props: (keyof AddressType)[] = defaultProps
): string {
  if (!address) {
    return '';
  }

  return props
    .map(prop => address[prop])
    .filter(part => !!part)
    .join(', ');
}
