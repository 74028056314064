import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  Invoice,
  Product,
  convertCurrencyEnum,
} from 'lane-shared/domains/billingPayments/types';
import {
  CreateTransactionRequest,
  GroupType,
  PaymentMethod,
  ExternalEntityType,
  Currency,
} from 'graphql-query-contracts';
import { convertBPProductToGqlProductInput } from './convertBPProductToGqlProductInput';

const createTransactionRequestMutation = gql`
  mutation createTransaction(
    $createTransactionRequest: CreateTransactionRequest!
    $contentChannelId: String!
  ) {
    accounts {
      createTransaction(
        createTransactionRequest: $createTransactionRequest
        contentChannelId: $contentChannelId
      ) {
        transactionId
      }
    }
  }
`;

export interface CreateTransactionRequestMutationResult {
  accounts: {
    createTransaction: {
      transactionId: string;
    };
  };
}

export async function createTransactionRequest(
  paymentMethod: string | undefined,
  paymentMethodType: PaymentMethod,
  currentChannelId: string,
  contentChannelId: string, // Channel Id for Merchant
  accountsUserId: string | null,
  productName: string,
  currency: string,
  amount: number,
  invoice?: Invoice,
  products?: Product[],
  quantity: number = 1
): Promise<CreateTransactionRequestMutationResult> {
  if (!accountsUserId) {
    throw new Error('User Id cannot be empty');
  }
  if (!paymentMethod) {
    throw new Error('Payment method cannot be empty');
  }
  if (!currency) {
    throw new Error('Payment currency cannot be empty');
  }

  const createTransactionRequest: CreateTransactionRequest = {
    paidBy: accountsUserId,
    groupId: currentChannelId, // Channel Id
    groupType: GroupType.GroupTypeActivateChannel,
    productName,
    paymentMethod: paymentMethodType,
    createdAt: new Date(),
    amount,
    currency: convertCurrencyEnum(currency, Currency.CurrencyUnknown),
    metadata: JSON.stringify({
      PaymentMethodId: paymentMethod,
    }),
    externalEntityType: invoice
      ? ExternalEntityType.ExternalEntityTypeActivateInvoice
      : ExternalEntityType.ExternalEntityTypeActivateUci,
    externalEntityId: invoice?.id,
    productItems: products?.map(product => ({
      chargeCodeId: product.chargeCode?.id,
      productCategoryId: product.productCategory.productCategoryId,
      product: convertBPProductToGqlProductInput(product),
      quantity,
    })),
  };

  const { data } = await getClient().mutate({
    mutation: createTransactionRequestMutation,
    variables: { createTransactionRequest, contentChannelId },
  });

  return data;
}
