const fieldsToClear = ['_created', '_updated', '_updatedBy', '_createdBy'];

/**
 * Clears out any metadata on data files for import / export.
 *
 * i.e. createdBy, updatedBy etc.
 *
 * @param obj
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'updateMetaData' implicitly has return type 'any' ... Remove this comment to see the full error message
export default function updateMetaData(obj: any) {
  if (Array.isArray(obj)) {
    return obj.map(value => updateMetaData(value));
  }

  if (
    !obj ||
    ['boolean', 'string', 'number', 'function'].includes(typeof obj)
  ) {
    return obj;
  }

  const ret = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (fieldsToClear.includes(key)) {
      return;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ret[key] = updateMetaData(value);
  });

  return ret;
}
