import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import { SecurityRuleTypeEnum } from '../../../../types/properties/PropertySecurity';

const properties: PropertiesInterface = {
  _id: {
    friendlyName: 'ID',
    type: 'String',
    forDisplay: false,
    secure: {
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [],
    },
  },
  payments_attempted: {
    friendlyName: 'Payments Attempted',
    type: 'Number',
    secure: {
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
  plugin_id: {
    friendlyName: 'Plugin Id',
    type: 'String',
    secure: {
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
  transaction_id: {
    friendlyName: 'Transaction Id',
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.Source,
        },
      ],
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
    type: 'String',
  },
  type: {
    friendlyName: 'Type',
    type: 'String',
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.Source,
        },
      ],
      create: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
};

export class PaymentFeaturePlacePayDataType extends ObjectTypeBase {
  name = 'PaymentFeaturePlacePayData';

  friendlyName = 'PlacePay Payment Data';

  contexts = [];

  get example() {
    return {
      _id: uuid(),
      payments_attempted: 1,
      plugin_id: 'place_pay_3430484',
      transaction_id: 19631,
      type: 'PAID',
    };
  }

  get default(): any {
    return {
      _id: uuid(),
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new PaymentFeaturePlacePayDataType();

Types.addType(definition);
export default definition;
