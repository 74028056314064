import { useEffect, useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import ObjectTouchHelper from '../../helpers/ObjectTouchHelper';
import {
  ANALYTIC_USER_DETAILS,
  ANALYTIC_USER_VIEW,
} from '../../helpers/constants/analytics';
import { DocumentType } from '../../types/DocumentType';

const eventMap = {
  view: ANALYTIC_USER_VIEW,
  details: ANALYTIC_USER_DETAILS,
};

export default function useUserAnalytics(
  event: 'view' | 'details',
  user: DocumentType
) {
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    if (user?._id) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"User"' is not assignable to par... Remove this comment to see the full error message
      ObjectTouchHelper.touch(user, ObjectTouchHelper.mapAction(event), 'User');

      analytics.track(eventMap[event], {
        userId: user._id,
      });
    }
  }, [user?._id]);
}
