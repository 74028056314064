import gql from 'graphql-tag';

import { AnonymousChannelProfileFragment } from 'lane-shared/graphql/fragments';

export default gql`
  ${AnonymousChannelProfileFragment}

  query getChannelForAnonymous($id: UUID, $slug: String) {
    channel(_id: $id, slug: $slug) {
      ...AnonymousChannelProfileFragment
    }
  }
`;
