import { LaneType } from 'common-types';
import {
  ChannelLanguagesType,
  EnabledLanguagesForMembersType,
} from '../ChannelType';

export type RequirementFeatureContentInteractionProperties = {
  _id: LaneType.UUID;
  content: {
    _id: string;
  };
  header: string;
  description: string;
};

export type RequirementsSource = {
  slug: string;
  profileName: string;
  _id: LaneType.UUID;
};

export type RequirementsFeatureProperties = {
  source?: RequirementsSource;
  profilePictureRequired: boolean;
  multiLanguageEnabled?: boolean;
  channelLanguages?: ChannelLanguagesType;
  enabledLanguagesForMembers?: EnabledLanguagesForMembersType;
  fullNameRequired: boolean;
  phoneNumberRequired: boolean;
  companyNameRequired: boolean;
  verifiedEmailRequired?: boolean;
  contentInteractionRequired: RequirementFeatureContentInteractionProperties[];
};

export enum RequirementsEnum {
  ProfilePicture = 'Profile Picture',
  VerifiedEmail = 'Verified Email',
  FullName = 'Full Name',
  PhoneNumber = 'Phone Number',
  CompanyName = 'Company Name',
  ContentInteraction = 'Content Interaction',
}
