import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import SimpleInput from 'components/form/SimpleInput';
import Panel from 'components/layout/Panel';
import { H5 } from 'components/typography';

import styles from './BlockBuilder.scss';

export default function DeepLink({
  urlTypeSchema,
  content,
  onDataValidation,
  onContentUpdated,
}: any) {
  const [
    deepLinkUrlValidationError,
    setDeepLinkUrlValidationError,
  ] = useState<ValidationError | null>(null);

  const [
    appStoreUrlValidationError,
    setAppStoreUrlValidationError,
  ] = useState<ValidationError | null>(null);

  const [
    googlePlayStoreUrlValidationError,
    setGooglePlayStoreUrlValidationError,
  ] = useState<ValidationError | null>(null);

  const { t } = useTranslation();

  return (
    <div className={styles.deepLinkUrl}>
      <Panel className={styles.panel}>
        <H5 mb={2}>
          {t('web.admin.channel.content.layout.editor.deepLinkModal.deepLink')}
        </H5>
        <SimpleInput
          dataCy="deepLink"
          value={content.deepLink?.deepLinkUrl}
          onChange={url => {
            try {
              urlTypeSchema.validateSync(url);
              setDeepLinkUrlValidationError(null);
              onDataValidation();
            } catch (e) {
              if (e instanceof ValidationError) {
                setDeepLinkUrlValidationError(e);
                onDataValidation(e);
              }
            }
            onContentUpdated({
              deepLink: {
                ...content.deepLink,
                deepLinkUrl: url,
              },
            });
          }}
          errors={deepLinkUrlValidationError?.errors}
        />
        <br />

        <H5 mb={2}>
          {t(
            'web.admin.channel.content.layout.editor.deepLinkModal.appleAppStoreLink'
          )}
        </H5>
        <SimpleInput
          dataCy="appStoreUrl"
          value={content.deepLink?.appStoreUrl}
          onChange={url => {
            try {
              urlTypeSchema.validateSync(url);
              setAppStoreUrlValidationError(null);
              onDataValidation();
            } catch (e) {
              if (e instanceof ValidationError) {
                setAppStoreUrlValidationError(e);
                onDataValidation(e);
              }
            }
            onContentUpdated({
              deepLink: {
                ...content.deepLink,
                appStoreUrl: url,
              },
            });
          }}
          errors={appStoreUrlValidationError?.errors}
        />
        <br />

        <H5 mb={2}>
          {t(
            'web.admin.channel.content.layout.editor.deepLinkModal.googlePlayStoreLink'
          )}
        </H5>
        <SimpleInput
          dataCy="googlePlayStoreUrl"
          value={content.deepLink?.googlePlayStoreUrl}
          onChange={url => {
            try {
              urlTypeSchema.validateSync(url);
              setGooglePlayStoreUrlValidationError(null);
              onDataValidation();
            } catch (e) {
              if (e instanceof ValidationError) {
                setGooglePlayStoreUrlValidationError(e);
                onDataValidation(e);
              }
            }
            onContentUpdated({
              deepLink: {
                ...content.deepLink,
                googlePlayStoreUrl: url,
              },
            });
          }}
          errors={googlePlayStoreUrlValidationError?.errors}
        />
      </Panel>
    </div>
  );
}
