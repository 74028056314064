import React from 'react';

import { useTranslation } from 'react-i18next';

import { parseDate } from 'lane-shared/helpers/dates';

import DateRangePicker from 'components/form/DatePickers/DateRangePicker';
import TimePicker from 'components/form/DatePickers/TimePicker';
import Label from 'components/general/Label';

import FeatureOptionToggle from '../components/FeatureOptionToggle';

import styles from './styles.scss';

type Props = {
  description?: string;
  onChange: () => any;
  settings: any;
  timeZone: any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onFeatureUpdated: (any) => any;
  TooltipComponent: React.ReactNode;
};

const TRANSLATION_KEYS = {
  availableDateRange:
    'web.content.features.reservable.ReservableDateRange.availableDateRange',
  startTime: 'web.content.features.reservable.ReservableDateRange.startTime',
  endTime: 'web.content.features.reservable.ReservableDateRange.endTime',
};

export default function ReservableDateRange({
  description,
  onChange,
  settings,
  timeZone,
  onFeatureUpdated,
  TooltipComponent,
}: Props) {
  const { t } = useTranslation();

  return (
    <FeatureOptionToggle
      title={t(TRANSLATION_KEYS.availableDateRange)}
      description={description}
      isExpanded={Boolean(settings.dateRange)}
      onChange={onChange}
      TooltipComponent={TooltipComponent}
    >
      {settings.dateRange ? (
        <>
          <DateRangePicker
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
            timeZone={timeZone}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'neve... Remove this comment to see the full error message
            startDate={parseDate(settings.dateRange.startDate)}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'neve... Remove this comment to see the full error message
            endDate={parseDate(settings.dateRange.endDate)}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
            minRangeSize={1}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(range: any) => any' is not assignable to ty... Remove this comment to see the full error message
            onChange={(range: any) =>
              onFeatureUpdated({
                dateRange: range,
              })
            }
          />
          <div className={styles.times}>
            <div className={styles.group}>
              <Label>{t(TRANSLATION_KEYS.startTime)}</Label>
              <TimePicker
                className={styles.timePicker}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type '((st... Remove this comment to see the full error message
                value={parseDate(settings.dateRange.startDate)}
                hideLabel
                timeZone={timeZone}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(startDate: any) => any' is not assignable t... Remove this comment to see the full error message
                onChange={(startDate: any) =>
                  onFeatureUpdated({
                    dateRange: {
                      ...settings.dateRange,
                      startDate,
                    },
                  })
                }
              />
            </div>

            <div className={styles.group}>
              <Label>{t(TRANSLATION_KEYS.endTime)}</Label>
              <TimePicker
                className={styles.timePicker}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type '((st... Remove this comment to see the full error message
                value={parseDate(settings.dateRange.endDate)}
                hideLabel
                timeZone={timeZone}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(endDate: any) => any' is not assignable to ... Remove this comment to see the full error message
                onChange={(endDate: any) =>
                  onFeatureUpdated({
                    dateRange: {
                      ...settings.dateRange,
                      endDate,
                    },
                  })
                }
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </FeatureOptionToggle>
  );
}
