import createShapeFromProperties from '../../../properties/createShapeFromProperties';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import definition from './definition';
import filterPastMeetings from './filterPastMeetings';
import filterUpcomingMeetings from './filterUpcomingMeetings';
import getFriendlyTime from './getFriendlyTime';
import getName from './getName';
import { isTokenExpired } from './validateToken';

export {
  definition,
  filterPastMeetings,
  filterUpcomingMeetings,
  getFriendlyTime,
  getName,
  isTokenExpired,
};

export const validationShape = createShapeFromProperties(
  definition.interactionData
);

export const validationShapeBNY = createShapeFromProperties({
  parkingPass: {
    friendlyName: 'Parking Pass',
    description: 'Would you like to grant this visitor a parking pass?',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
  meetingLocation: {
    friendlyName: 'Meeting Location',
    description: 'Meeting location custom field.',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
  entranceGate: {
    friendlyName: 'Preferred Entrance Gate',
    description: 'Preferred entrance for the visitor.',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      update: [
        {
          type: SecurityRuleTypeEnum.System,
        },
      ],
    },
  },
});

export const proxyclickDataBNY = [
  {
    proxyclickId: 32271,
    shapeRef: 'parkingPass',
    label: 'Would you like to grant this visitor a parking pass?',
    options: [
      { value: 'NO. Pedestrian/Shuttle/Bike access ONLY' },
      { value: 'YES. Grant visitor parking access' },
    ],
    attachToVisitor: true,
    value: '',
  },
  {
    proxyclickId: 10676,
    shapeRef: 'meetingLocation',
    label: 'Meeting Location',
    options: [
      { value: 'Building 1' },
      { value: 'Building 2' },
      { value: 'Building 3' },
      { value: 'Building 4' },
      { value: 'Building 5' },
      { value: 'Building 6' },
      { value: 'Building 7' },
      { value: 'Building 8' },
      { value: 'Building 10' },
      { value: 'Building 11' },
      { value: 'Building 11A' },
      { value: 'Building 12' },
      { value: 'Building 12B' },
      { value: 'Building 20' },
      { value: 'Building 22' },
      { value: 'Building 25' },
      { value: 'Building 27' },
      { value: 'Building 28' },
      { value: 'Building 30' },
      { value: 'Building 41' },
      { value: 'Building 42' },
      { value: 'Building 46' },
      { value: 'Building 50' },
      { value: 'Building 50A' },
      { value: 'Building 51' },
      { value: 'Building 52' },
      { value: 'Building 58' },
      { value: 'Building 62' },
      { value: 'Dock 72' },
      { value: 'Building 74' },
      { value: 'Building 77' },
      { value: 'Building 92' },
      { value: 'Building 120' },
      { value: 'Building 121' },
      { value: 'Building 123' },
      { value: 'Building 127' },
      { value: 'Building 128' },
      { value: 'Building 128b' },
      { value: 'Building 131' },
      { value: 'Building 132' },
      { value: 'Building 152' },
      { value: 'Building 212' },
      { value: 'Building 249' },
      { value: 'Building 268' },
      { value: 'Building 269' },
      { value: 'Building 270' },
      { value: 'Building 275' },
      { value: 'Building 280' },
      { value: 'Building 292' },
      { value: 'Building 293' },
      { value: 'Building 300' },
      { value: 'Building 303' },
      { value: 'Building 313' },
      { value: 'Building 314' },
      { value: 'Building 500' },
      { value: 'Building 594' },
      { value: 'Building 595' },
      { value: 'Building 664' },
      { value: 'Building 700' },
      { value: 'Pier K' },
      { value: 'Pier C' },
      { value: 'Clinton Gate' },
      { value: 'Clymer Gate' },
      { value: 'Sands Gate' },
      { value: 'Cumberland Gate' },
    ],
    attachToVisitor: false,
    value: '',
  },
  {
    proxyclickId: 11508,
    shapeRef: 'entranceGate',
    label: 'Preferred Entrance Gate',
    options: [
      { value: 'any gate' },
      { value: 'Sands St. Gate (299 Sands St.)' },
      { value: 'Cumberland St. Gate' },
      { value: 'Clinton Ave. Gate' },
      { value: 'Clymer St. Gate' },
      { value: 'Building 77 (141 Flushing Ave.)' },
      { value: 'Building 92' },
    ],
    attachToVisitor: false,
    value: '',
  },
];
