import React from 'react';

import styles from '../../styles.scss';

export function VisitorManagementGeneralTab(): JSX.Element {
  return (
    <div className={styles.tab}>
      <h2>Visitor information</h2>
    </div>
  );
}
