/**
 * When should this workflow be triggered.
 */
export enum WorkflowWhenEnum {
  Before = 'Before',
  After = 'After',
  Immediate = 'Immediate',
}

/**
 * What time context are we considering to trigger the workflow.
 */
export enum WorkflowWhenContextEnum {
  Content = 'Content',
  UserContentInteraction = 'UserContentInteraction',
  Escalation = 'Escalation',
  ServiceRequest = 'ServiceRequest',
  Task = 'Task',
}

export enum WorkflowActionEnum {
  Notification = 'Notification',
  Email = 'Email',
  SMS = 'SMS',
  Webhook = 'Webhook',
  GroupRole = 'GroupRole',
  Content = 'Content',
  NotificationAndEmail = 'NotificationAndEmail',
}

export const WorkFlowActionLabels = {
  [WorkflowActionEnum.Notification]:
    'shared.admin.channel.content.workflow.action.notifcation',
  [WorkflowActionEnum.Email]:
    'shared.admin.channel.content.workflow.action.email',
  [WorkflowActionEnum.SMS]: 'shared.admin.channel.content.workflow.action.sms',
  [WorkflowActionEnum.Webhook]:
    'shared.admin.channel.content.workflow.action.webhook',
  [WorkflowActionEnum.GroupRole]:
    'shared.admin.channel.content.workflow.action.groupRole',
  [WorkflowActionEnum.Content]:
    'shared.admin.channel.content.workflow.action.content',
  [WorkflowActionEnum.NotificationAndEmail]:
    'shared.admin.channel.content.workflow.action.notificationAndEmail',
};

export enum WorkflowTargetEnum {
  GroupRole = 'GroupRole',
  Creator = 'Creator',
  User = 'User',
  SelectUser = 'SelectUser',
  InteractionCreator = 'Owner',
  GuestInviteFeature = 'GuestInviteFeature',
  Webhook = 'Webhook',
  All = 'All',
  WorkOrderTeam = 'WorkOrderTeam',
  WorkOrderAdmin = 'WorkOrderAdmin',
  WorkOrderCreator = 'WorkOrderCreator',
}

export const WORKFLOW_ANY_STATUS = 'Any';

export enum ContentWorkflowQueueStatusEnum {
  Created = 'Created',
  Processing = 'Processing',
  Failed = 'Failed',
}

export enum WebhookQueueStatusEnum {
  Created = 'Created',
  Processing = 'Processing',
  Failed = 'Failed',
}

export enum WorkflowStatusEnum {
  Created = 'Created',
  Accepted = 'Accepted',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Closed = 'Closed',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
}

export const WorkflowStatusLabels = {
  [WorkflowStatusEnum.Created]: 'shared.workflow.statusLabel.created',
  [WorkflowStatusEnum.Accepted]: 'shared.workflow.statusLabel.accepted',
  [WorkflowStatusEnum.InProgress]: 'shared.workflow.statusLabel.inprogress',
  [WorkflowStatusEnum.OnHold]: 'shared.workflow.statusLabel.onhold',
  [WorkflowStatusEnum.Closed]: 'shared.workflow.statusLabel.closed',
  [WorkflowStatusEnum.Declined]: 'shared.workflow.statusLabel.declined',
  [WorkflowStatusEnum.Cancelled]: 'shared.workflow.statusLabel.cancelled',
  [WorkflowStatusEnum.Complete]: 'shared.workflow.statusLabel.complete',
};
