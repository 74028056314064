import { InvalidIdError } from 'activate-errors';
import { base62to16 } from './baseConvert';
import { UUID, UUID_BASE62, MONGO_ID, MONGO_ID_BASE62 } from './constants';
import unPadLeft from './unPadLeft';

export default function convertTo16(id: string | undefined | null): string {
  if (!id) {
    throw new InvalidIdError('Invalid ID: no id provided.');
  }

  const _id = id.toString();

  switch (_id.length) {
    case UUID.length:
      return unPadLeft(_id.replace(/-/gi, ''));
    case MONGO_ID.length:
      return _id;
    case MONGO_ID_BASE62.length:
      return unPadLeft(base62to16(_id));
    case UUID_BASE62.length:
      return base62to16(_id);
    default:
      throw new InvalidIdError(`Invalid ID: ${_id} length ${_id.length}`);
  }
}
