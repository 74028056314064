import { useTranslation } from 'react-i18next';

import {
  useMutation,
  isApolloError,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';

import { unitErrorCodeTypes } from 'activate-errors';
import {
  removeUserFromChannel,
  RemoveUserFromChannelMutationResponse,
} from 'lane-shared/graphql/channel';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';

type handleRemoveUserArgs = {
  userId: string;
  userName: string;
  channelId: string;
  isMFExperience: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};
export function useRemoveUserFromChannel() {
  const { t } = useTranslation();

  const [
    removeUserFromChannelMutation,
  ] = useMutation<RemoveUserFromChannelMutationResponse>(removeUserFromChannel);

  const handleRemoveUser = async ({
    userId,
    userName,
    channelId,
    isMFExperience,
    refetch,
  }: handleRemoveUserArgs) => {
    try {
      await window.Alert.confirm({
        title: t(
          `web.admin.channel.users.all.table.removeUser.modal.title.${
            isMFExperience ? 'mf' : 'cre'
          }`,
          { name: userName }
        ),
        message: t(
          `web.admin.channel.users.all.table.removeUser.modal.message.${
            isMFExperience ? 'mf' : 'cre'
          }`,
          { name: userName }
        ),
        confirmText: t(
          'web.admin.channel.users.all.table.removeUser.modal.cta'
        ),
        cancelText: t(
          'web.admin.channel.users.all.table.removeUser.modal.return'
        ),
      });

      await removeUserFromChannelMutation({ variables: { userId, channelId } });
      await refetch();

      window.Toast.show(
        t('web.admin.channel.users.all.table.removeUser.modal.successToast')
      );
    } catch (err) {
      if (isApolloError(err)) {
        const codes = getErrorCodes(err);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          unitErrorCodeTypes,
          t('web.admin.channel.users.all.table.removeUser.modal.errorToast')
        );
        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }
    }
  };

  return {
    handleRemoveUser,
  } as const;
}
