import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';
import { RequiredValidator } from '../validators/Required';

const properties: PropertiesInterface = {
  users: {
    forDisplay: false,
    friendlyName: 'Users',
    description: 'User that are invited to the content.',
    type: 'User',
    isArray: true,
  },
};

export class SelectUserType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'SelectUser';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
  ];

  friendlyName = 'Select User';

  get example() {
    return {
      users: [],
    };
  }

  get default(): any {
    return {
      users: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  addRequiredValidator(validator: RequiredValidator): any {
    if (validator.config?.value) {
      this._schema = this.schema.test(
        'Required',
        `${this.getDisplayName()} is a required field.`,
        value => value.users.length
      );
    }

    this._schema = this.schema.notRequired();
  }

  properties = properties;
}

const definition = new SelectUserType();

Types.addBaseType(definition);
export default definition;
