import React from 'react';
import classNames from 'classnames';

import styles from './index.scss';

export function TimeSlotLoader() {
  const NUMBER_OF_SKELETONS = 5;

  return (
    <div data-testid="Waitlist Loader">
      {Array.from({ length: NUMBER_OF_SKELETONS }).map(
        (_: any, index: number) => (
          <div key={index} className={classNames(styles.fullSizeGrid)}>
            <div className={classNames(styles.left)}>
              <div className={classNames(styles.leftLoader)}>&nbsp;</div>
            </div>
            <div className={classNames(styles.right)}>
              <div className={classNames(styles.rightLoader)}>&nbsp;</div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
