import {
  EVENT_EMAIL_CREATED,
  EVENT_EMAIL_UPDATED,
  EVENT_LOGIN_EMAIL_DELETED,
  EVENT_LOGIN_EMAIL_VERIFIED,
  EVENT_LOGIN_EMAIL_CREATED,
} from 'lane-shared/helpers/constants/events';
import { convertTo62 } from 'lane-shared/helpers/convertId';

import { AnalyticsInterface } from '../../types/Analytics';

type EmitEmailEventType = {
  isPreviousEmail?: boolean;
  isLoginEmailCreated?: boolean;
  isEmailVerified?: boolean;

  userId: string;
  analytics: AnalyticsInterface;
  selectedLoginId?: string;
  isServerEndpoint?: boolean;
};

export function emitEmailAddress(data: EmitEmailEventType) {
  let eventName;

  eventName = data.isPreviousEmail
    ? (eventName = EVENT_EMAIL_UPDATED)
    : EVENT_EMAIL_CREATED;

  if (data?.selectedLoginId) {
    if (data.isLoginEmailCreated) {
      eventName = data.isEmailVerified
        ? EVENT_LOGIN_EMAIL_VERIFIED
        : EVENT_LOGIN_EMAIL_CREATED;
    } else {
      eventName = EVENT_LOGIN_EMAIL_DELETED;
    }
  }

  if (data.isServerEndpoint && data.analytics.trackMixpanel) {
    data.analytics.trackMixpanel(eventName, {
      distinct_id: convertTo62(data.userId),
    });
  } else {
    data.analytics.track(eventName, {
      userId: data.userId,
    });
  }
}
