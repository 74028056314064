import { DateTime } from 'luxon';

import { parseDateTime } from 'lane-shared/helpers/dates';

import { simpleDate, shortTime } from '../../formatters';

/**
 * Gets the friendly display time for ProxyClick meetings
 */
export default function getFriendlyTime(meeting: any) {
  if (!meeting || !meeting.startAt) {
    return '';
  }

  const now = DateTime.local();
  const startAt = parseDateTime(meeting.startAt);
  const endAt = parseDateTime(meeting.endAt);

  if (!startAt || !endAt) {
    return '';
  }

  let startComponent = '';
  const yearComponent = '';
  let divider = ' ';

  if (startAt < now) {
    if (endAt < now) {
      startComponent = `${simpleDate(startAt)}  ${shortTime(startAt)}`;
      divider = ' - ';
    } else {
      startComponent = 'Until';
    }
  } else if (startAt.hasSame(now, 'day')) {
    startComponent = `Today ${shortTime(startAt)}`;
    divider = ' - ';
  } else if (startAt.hasSame(now.plus({ days: 1 }), 'day')) {
    startComponent = `Tomorrow  ${shortTime(startAt)}`;
  } else {
    startComponent = `${simpleDate(startAt)}  ${shortTime(startAt)}`;
    divider = ' - ';
  }

  const endComponent = `${shortTime(endAt)}`;

  return startComponent + divider + endComponent + yearComponent;
}
