import { objectToArray } from 'lane-shared/helpers';
import getFeatureEditableProperties from 'lane-shared/renderers/v5/getFeatureEditableProperties';
import { isReservableFeatureInstance } from 'lane-shared/type-guards/reservable';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';

import findBlock from './findBlock';

function isReservableFeatureAndUserNotesDisabled(
  feature: any,
  property: PropertyType
) {
  if (isReservableFeatureInstance(feature) && !feature.feature?.userNotes) {
    return property.name !== 'userNotes';
  }

  return true;
}

export default function getMissingInputs(
  content: any,
  blocks: any,
  channel?: any
) {
  const missingInputs: any = [];
  const dataDefinitions = objectToArray(content.data);

  dataDefinitions.forEach(field => {
    const existingBlock = findBlock({
      content,
      blocks,
      key: 'for',
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      value: field.name,
    });

    if (!existingBlock) {
      missingInputs.push(field);
    }
  });

  if (content.features) {
    if (!channel?.settings.hasVisitorManagementEnabled) {
      content.features = content.features.filter(
        (item: { type: FeatureNameEnum }) =>
          item.type !== FeatureNameEnum.VisitorManagement
      );
    } // to hide the warning messages of visitor management if toggle is off

    content.features.map((feature: any) => {
      const properties = getFeatureEditableProperties(feature, true)?.filter(
        property =>
          // filters out user notes
          // this is to hide the warning message of user notes if toggle is off
          isReservableFeatureAndUserNotesDisabled(feature, property)
      );

      if (properties) {
        properties.forEach(field => {
          const existingBlock = findBlock({
            content,
            blocks,
            key: 'for',
            value: `${feature.type}.${field.name}`,
          });

          if (!existingBlock) {
            missingInputs.push(field);
          }
        });
      }
    });
  }

  return missingInputs;
}
