import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';

const RemoteFetch: FeatureDefinition = {
  name: FeatureNameEnum.RemoteFetch,
  superUserOnly: true,
  description: 'Connects this content to a remote data source',
  requiresInteraction: true,
  friendlyName: 'RemoteFetch',
  properties: {
    headers: {
      type: 'Option',
      editable: true,
      isArray: true,
    },
    postData: {
      type: 'JSON',
      editable: true,
    },
    method: {
      type: 'String',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: ['POST', 'GET'],
        },
      ],
    },
    interval: {
      type: 'Number',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Max',
          value: 7 * 24 * 60 * 60, // 1 week
        },
        {
          name: 'Min',
          value: 60 * 5, // 5 minutes
        },
      ],
    },
    url: {
      type: 'Url',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Max',
          value: 2 ** 64,
        },
        {
          name: 'Min',
          value: 1,
        },
      ],
    },
  },
};

export default RemoteFetch;
