import { AppState, Auth0Provider } from '@auth0/auth0-react';
import qs from 'qs';
import { history } from 'helpers';
import { config as sharedConfig } from 'lane-shared/config';
import { useInviteContext } from 'lane-shared/contexts/InviteContext';
import React, { ReactNode } from 'react';
import { Auth0ContextProvider } from 'lane-shared/contexts/Auth0Context';

export const Auth0Wrapper = ({ children }: { children: ReactNode }) => {
  const { domain, clientId, redirectionServiceURL } = sharedConfig.auth0;
  const { setInviteId } = useInviteContext();

  if (!redirectionServiceURL) {
    throw new Error('config.auth0.redirectServiceURL is required');
  }

  const handleRedirectCallback = (appState: AppState | undefined) => {
    setInviteId(appState?.inviteId);
    const contentPath = appState?.contentPath;
    if (contentPath) {
      history.push({
        pathname: history.location.pathname,
        search: qs.stringify({ contentPath }),
      });
    }
  };

  const domainURLSearchParams = new URLSearchParams({
    domain,
  });

  const redirectURLSearchParams = new URLSearchParams({
    destination_url: window.location.origin,
  });

  return (
    <Auth0ContextProvider>
      <Auth0Provider
        domain={`${redirectionServiceURL}/?${domainURLSearchParams}`}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: `https://${redirectionServiceURL}?${redirectURLSearchParams}`,
        }}
        issuer={`https://${domain}/`}
        onRedirectCallback={handleRedirectCallback}
        cacheLocation="localstorage"
      >
        {children}
      </Auth0Provider>
    </Auth0ContextProvider>
  );
};
