import React, { useContext } from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { H4 } from 'components/typography';

import ChannelCircleListView from '../lane/ChannelCircleListView';

import styles from './ChannelMenuItem.scss';

type ChannelMenuItemProps = {
  className?: string;
  style?: React.CSSProperties;
  channel: ChannelType;
  onChannelChange: (channel: ChannelType) => void;
  forAdmin: boolean;
  isTopLevel: boolean;
};

export default function ChannelMenuItem({
  channel,
  className,
  style,
  onChannelChange,
  forAdmin,
  isTopLevel,
}: ChannelMenuItemProps) {
  const { user } = useContext(UserDataContext);
  const { primaryChannel, secondaryChannel } = useContext(ChannelsContext);
  const currentChannels: any[] = [];

  if (primaryChannel) {
    currentChannels.push(primaryChannel._id);
  }

  if (secondaryChannel) {
    currentChannels.push(secondaryChannel._id);
  }

  function renderChannel(channel: any) {
    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'isTopLevel' is missing in type '{ onChan... Remove this comment to see the full error message
      <ChannelMenuItem
        onChannelChange={onChannelChange}
        key={channel._id}
        channel={channel}
        forAdmin={forAdmin}
      />
    );
  }

  // use has admin rights on this channel if they have some non-Workplace Member
  // role or they are a super user.
  const hasAdmin = user?.isSuperUser || channel._hasAdmin;

  const buttonOpts = {
    role: 'button',
    tabIndex: 0,
    onKeyPress: (e: any) => e.key === Key.Enter && onChannelChange(channel),
    onClick: () => onChannelChange(channel),
  };

  if (isTopLevel && !channel.parent) {
    return (
      <div className={cx(styles.TopLevelMenuItem, className)} style={style}>
        <div
          className={cx(styles.channelWrapper, {
            [styles.currentChannel]: currentChannels.includes(channel._id),
          })}
          data-admin={hasAdmin}
          {...(forAdmin ? buttonOpts : null)}
        >
          <H4 mb={1} className={cx({ [styles.topLevelChannelName]: forAdmin })}>
            {getDisplayName(channel)}
          </H4>
        </div>
        {channel.subs?.map(renderChannel)}
        {channel.channelLocations?.map(renderChannel)}
      </div>
    );
  }

  return (
    <div
      className={cx(
        styles.ChannelMenuItem,
        isTopLevel && styles.topLevel,
        className
      )}
      style={style}
    >
      {(!forAdmin || (forAdmin && hasAdmin)) && (
        <div
          className={cx(styles.channelWrapper)}
          data-admin={hasAdmin}
          data-parent={!!channel.parent}
          {...(forAdmin || channel.parent ? buttonOpts : null)}
        >
          <ChannelCircleListView
            className={cx(styles.channel, {
              [styles.currentChannel]: currentChannels.includes(channel._id),
            })}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
            channel={channel}
            showIfAdmin
          />
        </div>
      )}
      {channel.subs.map(renderChannel)}
      {channel.channelLocations?.map(renderChannel)}
    </div>
  );
}
