import React, { useState, useMemo, useEffect } from 'react';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import styles from './InviteNewUser.scss';
import { routes } from 'lane-shared/config';
import {
  Input,
  MultiselectField,
  PageNavigationAlert,
  Dropdown,
  RadioGroup,
  Toggle,
} from 'components';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { PhoneNumberInput } from '../component/PhoneNumberInput';
import { usePermissionGroupOptions, useCompanySearch } from '../hooks';
import { Button, DatePickerButton } from 'design-system-web';
import { useHistory } from 'react-router-dom';
import {
  CHANNEL_EXPERIENCE_TYPE_PERMISSION_GROUP_MAP,
  MULTIFAMILY_GROUP_ROLES_NAMES,
} from 'lane-shared/helpers/constants/channel';
import { useGetUnitsFieldOptions } from 'lane-web/src/pages/portal/admin/channel/floors/hooks';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { sendInvite } from 'lane-shared/graphql/mutation';
import {
  object,
  string,
  ValidationError,
  array,
  date,
  boolean,
  ref,
} from 'yup';
import { isValidPhoneNumber } from 'lane-shared/helpers/phoneNumbers';
import { isChannelForCRE } from 'lane-shared/helpers/channel';
import { getAggregatedValidationMessage } from 'lane-shared/helpers';
import { Item } from 'components/form/Dropdown/Dropdown';
import { DateTime } from 'luxon';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  MutationSendInviteArgs,
  ResidentType,
} from 'graphql-resolver-contracts';
import { P } from 'components/typography';
import { UsersPageTabEnum } from '..';

type Props = {
  channel: Pick<
    ChannelType,
    '_id' | 'name' | 'slug' | 'type' | 'experienceType'
  >;
};

type NewUserFields = {
  name: string;
  email: string;
  phoneNumber: string;
  company: string;
  permissionGroups: Item<string>[];
  isResident: boolean;
  units: Item<string>[];
  moveInDate?: Date;
  moveOutDate?: Date;
  residentType: ResidentType;
};

type NewUserFieldsKey = keyof NewUserFields;

const residentTypeItems = [
  {
    id: ResidentType.PrimaryResident,
    text:
      'web.admin.channel.users.invite.permissionsGroup.primaryResident.text',
    description:
      'web.admin.channel.users.invite.permissionsGroup.primaryResident.subtext',
  },
  {
    id: ResidentType.SecondaryResident,
    text:
      'web.admin.channel.users.invite.permissionsGroup.secondaryResident.text',
    description:
      'web.admin.channel.users.invite.permissionsGroup.secondaryResident.subtext',
  },
  {
    id: ResidentType.Guarantor,
    text: 'web.admin.channel.users.invite.permissionsGroup.guarantor.text',
    description:
      'web.admin.channel.users.invite.permissionsGroup.guarantor.subtext',
  },
];

const residentTypeRadioSchema = {
  id: 'id',
  text: 'text',
  subtext: 'description',
};

export const InviteNewUser = ({ channel }: Props) => {
  const [newUserFields, setNewUserFields] = useState<NewUserFields>({
    name: '',
    email: '',
    phoneNumber: '',
    company: '',
    permissionGroups: [],
    isResident: false,
    units: [],
    moveInDate: undefined,
    moveOutDate: undefined,
    residentType: ResidentType.PrimaryResident,
  });

  const [isResidentButtonPristine, setIsResidentButtonPristine] = useState(
    true
  );

  const [isUserInvited, setIsUserInvited] = useState(false);
  const [validationError, setValidationError] = useState<
    ValidationError[] | null
  >(null);

  const { t } = useTranslation();
  const history = useHistory();

  const [inviteUserMutation, { loading: sendInviteLoading }] = useMutation(
    sendInvite
  );

  const { data, loading: loadingCompanies } = useCompanySearch(channel?._id);
  const {
    options,
    loading: isFetchingPermissionGroups,
  } = usePermissionGroupOptions(channel._id);

  const {
    options: unitOptions,
    loading: isFetchingUnits,
  } = useGetUnitsFieldOptions(channel._id);

  const filterMFPermissionGroups = (permissionGroups: Item<string>[]) => {
    return permissionGroups.filter(
      p =>
        p.label === MULTIFAMILY_GROUP_ROLES_NAMES.ADMIN ||
        p.label === MULTIFAMILY_GROUP_ROLES_NAMES.PROPERTY_STAFF ||
        (channel.experienceType &&
          !(Object.keys(
            CHANNEL_EXPERIENCE_TYPE_PERMISSION_GROUP_MAP.get(
              channel.experienceType
            ) ?? {}
          ) as string[]).includes(p.label))
    );
  };

  useEffect(() => {
    if (isUserInvited) {
      history.push(
        `${routes.channelAdminUsersList.replace(':id', channel?.slug)}?tab=${
          UsersPageTabEnum.TAB_Pending_Invites
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserInvited]);

  const isCREChannel = isChannelForCRE(channel?.experienceType);

  const isCREProperty =
    isCREChannel && channel?.type === ChannelTypeEnum.Property;

  const isMFProperty =
    !isCREChannel && channel?.type === ChannelTypeEnum.Property;

  const isPristine = useMemo(() => {
    return (
      newUserFields.name === '' &&
      newUserFields.email === '' &&
      newUserFields.phoneNumber === '' &&
      newUserFields.permissionGroups.length === 0 &&
      (isMFProperty ? isResidentButtonPristine : true)
    );
  }, [newUserFields]);

  const inputLabels = {
    fullName: t('web.admin.channel.users.invite.inputLabel.fullName'),
    email: t('web.admin.channel.users.invite.inputLabel.email'),
    phoneNumber: t('web.admin.channel.users.invite.inputLabel.phoneNumber'),
    company: t('web.admin.channel.users.invite.inputLabel.company'),
    permissionGroups: t(
      'web.admin.channel.users.invite.inputLabel.permissionGroups'
    ),
    units: t('web.admin.channel.users.invite.inputLabel.units'),
    moveInDate: t('web.admin.channel.users.invite.inputLabel.moveInDate'),
  };

  const validationErrors = {
    nameMinimumLength: t(
      'web.admin.channel.users.invite.permissionGroups.validationError.nameMinLength'
    ),
    emailRequired: t(
      'web.admin.channel.users.invite.permissionGroups.validationError.emailRequired'
    ),
    invalidEmail: t(
      'web.admin.channel.users.invite.permissionGroups.validationError.invalidEmail'
    ),
    phoneNumberRequired: t(
      'web.admin.channel.users.invite.permissionGroups.validationError.phoneNumberRequired'
    ),
    invalidPhoneNumber: t(
      'web.admin.channel.users.invite.permissionGroups.validationError.invalidPhoneNumber'
    ),
    permissionGroupRequired: t(
      'web.admin.channel.users.invite.permissionGroups.validationError.permissionGroupMin'
    ),
    unitsRequired: t('web.admin.channel.users.invite.units.validationError'),
    moveInDateRequired: t(
      'web.admin.channel.users.invite.moveInDate.validationError'
    ),
    moveInDateMustBeGreaterThanMoveInDate: t(
      'web.admin.channel.users.invite.moveOutDate.validationError'
    ),
  };

  const creInviteValidator = object().shape({
    name: string()
      .label(inputLabels.fullName)
      .trim()
      .required()
      .min(1, validationErrors.nameMinimumLength),
    email: string()
      .label(inputLabels.email)
      .email(validationErrors.invalidEmail)
      .required(validationErrors.emailRequired),
    phoneNumber: string()
      .label(inputLabels.phoneNumber)
      .test(
        'phone-required-valid',
        validationErrors.invalidPhoneNumber,
        value => {
          if (!value) {
            return true;
          }
          return value != null && isValidPhoneNumber(value, 'US');
        }
      ),
    permissionGroups: array()
      .label(inputLabels.permissionGroups)
      .required()
      .min(1, validationErrors.permissionGroupRequired),
  });

  const multifamilyInviteValidator = object().shape({
    name: string()
      .label(inputLabels.fullName)
      .trim()
      .required()
      .min(1, validationErrors.nameMinimumLength),
    email: string()
      .label(inputLabels.email)
      .email(validationErrors.invalidEmail)
      .required(validationErrors.emailRequired),
    phoneNumber: string()
      .label(inputLabels.phoneNumber)
      .required(validationErrors.phoneNumberRequired)
      .test(
        'phone-required-valid',
        validationErrors.invalidPhoneNumber,
        value => {
          if (!value) {
            return true;
          }
          return value != null && isValidPhoneNumber(value, 'US');
        }
      ),
    units: array()
      .label(inputLabels.units)
      .required()
      .min(1, validationErrors.unitsRequired),
    isRequired: boolean(),
    moveInDate: date().when('isRequired', {
      is: true,
      then: date()
        .required(validationErrors.moveInDateRequired)
        .label(inputLabels.moveInDate),
    }),
    moveOutDate: date().min(
      ref('moveInDate'),
      validationErrors.moveInDateMustBeGreaterThanMoveInDate
    ),
  });

  const handleOnChange = (
    key: NewUserFieldsKey,
    value: NewUserFields[NewUserFieldsKey]
  ) => {
    if (key === 'isResident') {
      setIsResidentButtonPristine(false);
    }
    setNewUserFields({ ...newUserFields, [key]: value });
  };

  const handleOnSubmit = async () => {
    try {
      if (isCREProperty || (isMFProperty && !newUserFields.isResident)) {
        creInviteValidator.validateSync(
          {
            name: newUserFields.name,
            email: newUserFields.email,
            phoneNumber: newUserFields.phoneNumber,
            permissionGroups: newUserFields.permissionGroups,
          },
          { abortEarly: false }
        );
      } else {
        multifamilyInviteValidator.validateSync(
          {
            name: newUserFields.name,
            email: newUserFields.email,
            phoneNumber: newUserFields.phoneNumber,
            units: newUserFields.units,
            isRequired: newUserFields.isResident,
            moveInDate: newUserFields.moveInDate,
            moveOutDate: newUserFields.moveOutDate,
          },
          { abortEarly: false }
        );
      }

      setValidationError(null);

      const request: MutationSendInviteArgs =
        isCREProperty || (isMFProperty && !newUserFields.isResident)
          ? {
              name: newUserFields.name,
              email: newUserFields.email,
              groupRoleIds: newUserFields.permissionGroups.map(pg => pg.value),
              ...(newUserFields.company && {
                companyId: newUserFields.company,
              }),
              phone: newUserFields.phoneNumber,
            }
          : {
              name: newUserFields.name,
              email: newUserFields.email,
              phone: newUserFields.phoneNumber,
              groupRoleIds: [
                ...newUserFields.permissionGroups.map(pg => pg.value),
                ...options
                  .filter(
                    o => o.label === MULTIFAMILY_GROUP_ROLES_NAMES.RESIDENT
                  )
                  .map(r => r.value),
              ],
              unitsForResident: {
                unitIds: newUserFields.units.map(u => u.value),
                moveInDate: newUserFields.moveInDate,
                moveOutDate: newUserFields.moveOutDate,
              },
              residentType: newUserFields.residentType,
            };

      await inviteUserMutation({ variables: request });

      window.Toast.show(
        t('web.admin.channel.users.invite.successToast', {
          name: newUserFields.name,
        }),
        7500
      );

      setIsUserInvited(true);
    } catch (err) {
      if (err instanceof ValidationError) {
        setValidationError(err.inner);
      } else {
        window.Toast.show(t('web.admin.channel.users.invite.errorToast'));
      }
    }
  };

  const handleOnCancel = async () => {
    const url = routes.channelAdminUsersList.replace(':id', channel?.slug);
    history.push(url);
  };

  const getErrorMessage = (field: string) => {
    const validationMessage = getAggregatedValidationMessage(
      validationError?.filter(err => err.path === field),
      field
    );

    if (validationMessage) {
      return [
        validationMessage.charAt(0) +
          validationMessage.substring(1).toLowerCase(),
      ];
    }

    return [];
  };

  const pageHeaderProps = {
    header: t('web.admin.channel.users.invite.header'),
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t('web.admin.channel.users.invite.breadcrumb.back'),
        url: routes.channelAdminUsersList.replace(':id', channel?.slug),
      },
      {
        label: t('web.admin.channel.users.invite.breadcrumb.current'),
      },
    ],
    description: t('web.admin.channel.users.invite.body'),
  };

  return (
    <AdminPage className={styles.adminPage}>
      <PageNavigationAlert
        when={!isPristine && !isUserInvited}
        dataCy="pageNavigationAlert"
      />
      <PageHeader {...pageHeaderProps} />
      <div className={styles.inviteNewUserContainer}>
        <div className={styles.formContainer}>
          <Input
            fieldName="name"
            label={t('web.admin.channel.users.invite.inputLabel.fullName')}
            type="text"
            fixedLabel
            isRequired
            dataCy="nameInput"
            onChange={value => handleOnChange('name', value)}
            value={newUserFields.name}
            error={getErrorMessage('name')}
          />

          <Input
            fieldName="email"
            label={t('web.admin.channel.users.invite.inputLabel.email')}
            type="text"
            fixedLabel
            isRequired
            dataCy="emailInput"
            onChange={value => handleOnChange('email', value)}
            value={newUserFields.email}
            error={getErrorMessage('email')}
          />

          <PhoneNumberInput
            displayName={t(
              'web.admin.channel.users.invite.inputLabel.phoneNumber'
            )}
            value={newUserFields.phoneNumber}
            onChange={value => handleOnChange('phoneNumber', value)}
            error={getErrorMessage('phoneNumber')}
            dataCy="phoneNumberInput"
            isRequired={isMFProperty && newUserFields.isResident}
          />

          {isCREProperty && (
            <Dropdown
              label={t('web.admin.channel.users.invite.inputLabel.company')}
              fixedLabel
              isFullWidth
              placeholder={t(
                'web.admin.channel.users.invite.company.placeholder'
              )}
              items={data?.channelsByRelationship?.items?.map(
                (item: { channel: { name: string; _id: string } }) => {
                  return {
                    label: item.channel.name,
                    value: item.channel._id,
                  };
                }
              )}
              doTranslation={false}
              isLoading={loadingCompanies}
              noOptionsMessage={t(
                'web.admin.channel.users.invite.company.noOptions'
              )}
              onChange={value =>
                handleOnChange('company', value.value as string)
              }
              value={newUserFields.company}
              dataCy="companyDropdown"
            />
          )}

          {isMFProperty && (
            <Toggle
              dataCy="isResidentToggle"
              value={newUserFields.isResident}
              onChange={() =>
                handleOnChange('isResident', !newUserFields.isResident)
              }
              text={t('web.admin.channel.users.invite.linkUnit.text')}
              description={t(
                'web.admin.channel.users.invite.linkUnit.description'
              )}
            />
          )}

          {newUserFields.isResident && (
            <>
              <Dropdown
                label={t('web.admin.channel.users.invite.units.label')}
                fixedLabel
                isFullWidth
                items={unitOptions}
                onChange={value => handleOnChange('units', [value])}
                value={newUserFields.units?.[0]?.value}
                placeholder={t(
                  'web.admin.channel.users.invite.units.placeholder'
                )}
                noOptionsMessage={t(
                  'web.admin.channel.users.invite.units.noOptions'
                )}
                doTranslation={false}
                isLoading={isFetchingUnits}
                isRequired
                errors={getErrorMessage('units')}
                invalid={getErrorMessage('units').length > 0}
                dataCy="unitsDropdown"
              />
              <DatePickerButton
                dateInputLabel={t(
                  'web.admin.channel.users.invite.moveInDate.label'
                )}
                value={newUserFields.moveInDate}
                onChange={value => handleOnChange('moveInDate', value)}
                wrapperClassName={styles.datePickerWrapperWidth}
                buttonClassName={styles.datePickerButtonWidth}
                helperText={t(
                  'web.admin.channel.users.invite.moveInDate.subtext'
                )}
                dateError={getErrorMessage('moveInDate')}
                isRequired
                fixedLabel
                dataCy="moveInDateDatePicker"
              />
              <DatePickerButton
                dateInputLabel={t(
                  'web.admin.channel.users.invite.moveOutDate.label'
                )}
                minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
                value={newUserFields.moveOutDate}
                onChange={value => handleOnChange('moveOutDate', value)}
                wrapperClassName={styles.datePickerWrapperWidth}
                buttonClassName={styles.datePickerButtonWidth}
                helperText={t(
                  'web.admin.channel.users.invite.moveOutDate.subtext'
                )}
                fixedLabel
                dataCy="moveOutDateDatePicker"
                dateError={getErrorMessage('moveOutDate')}
              />
              <div>
                <div className={styles.permissionsGroupTitle}>
                  <P>
                    {t('web.admin.channel.users.invite.permissionsGroup.title')}
                  </P>
                </div>
                <RadioGroup
                  schema={residentTypeRadioSchema}
                  items={residentTypeItems}
                  name="residentType"
                  selected={newUserFields.residentType}
                  onChange={value => handleOnChange('residentType', value)}
                />
              </div>
            </>
          )}

          <MultiselectField
            label={t(
              !newUserFields.isResident
                ? 'web.admin.channel.users.invite.inputLabel.permissionGroups'
                : 'web.admin.channel.users.invite.inputLabel.permissionGroups.optional'
            )}
            fixedLabel
            isFullWidth
            items={filterMFPermissionGroups(options)}
            onChange={value => handleOnChange('permissionGroups', value)}
            value={newUserFields.permissionGroups}
            placeholder={t(
              'web.admin.channel.users.invite.permissionGroups.placeholder'
            )}
            noOptionsMessage={t(
              'web.admin.channel.users.invite.permissionGroups.noOptions'
            )}
            doTranslation={false}
            isLoading={isFetchingPermissionGroups}
            isRequired={
              isCREProperty || (isMFProperty && !newUserFields.isResident)
            }
            errors={getErrorMessage('permissionGroups')}
            dataCy="permissionGroupsDropdown"
          />

          <div className={styles.buttonContainer}>
            <Button
              disabled={isPristine}
              loading={sendInviteLoading}
              onClick={handleOnSubmit}
              dataCy="sendInviteButton"
              size="large"
            >
              {t('web.admin.channel.users.invite.cta.sendInvite')}
            </Button>
            <Button
              variant="secondary"
              onClick={handleOnCancel}
              dataCy="cancelButton"
              size="large"
            >
              {t('web.admin.channel.users.invite.cta.cancel')}
            </Button>
          </div>
        </div>
      </div>
    </AdminPage>
  );
};
