import { PaymentFeatureProperties } from '../../types/features/PaymentFeatureProperties';
import { PaymentTypeEnum } from '../../types/payment/PaymentTypeEnum';
import { CURRENCY_USD } from '../constants/currencyCodes';

/**
 * Sometimes you want to find the default cash currency set on a payment
 * feature.  I.e. in Menus that don't support non-cash values yet.
 * @param paymentFeature
 */
export default function getPaymentFeatureCurrency(
  paymentFeature?: PaymentFeatureProperties
): string {
  // either use a currency from the payment feature, or set to a default.
  let currency: string = CURRENCY_USD;

  if (paymentFeature) {
    const paymentAmount = paymentFeature.amounts?.find(
      paymentAmount => paymentAmount.type === PaymentTypeEnum.Cash
    );

    if (paymentAmount?.currency) {
      currency = paymentAmount.currency;
    }
  }

  return currency;
}
