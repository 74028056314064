import parsePhoneNumber from 'libphonenumber-js';

/**
 * Takes an input string or number that is assumed to be a phone number and formats it nicely.

 * @param num {string|number}  the phone number to prettify.
 * @returns {string}
 */
export default function prettyPhoneNumber(num: string | number): string {
  if (!num) return '';

  const phoneNumber = parsePhoneNumber(String(num));

  if (!phoneNumber) {
    return String(num);
  }

  return phoneNumber.formatNational();
}
