// todo: refactor duplicated enums from
//  here packages/lane-shared/helpers/constants/user.js
import { DocumentType, DocumentQueryFieldsType } from './DocumentType';
import { DateTimeSearchType, SearchModeEnum } from './graphql/search';

export enum UserLoginStatusEnum {
  System = 'System',
  // Active means it is a user login that is in use, but not verified
  Active = 'Active',
  // Pending means it is active and awaiting verification
  Pending = 'Pending',
  // Verified means it is active and verified
  Verified = 'Verified',
  // Disabled means this is not active.
  Disabled = 'Disabled',
  // Bounced delivery failed to this endpoint
  Bounced = 'Bounced',
  // Complaint the user actively complained about delivery to this endpoint
  Complaint = 'Complaint',
}

export enum UserLoginTypeEnum {
  OAuth = 'OAuth',
  Email = 'Email',
  Phone = 'Phone',
  MagicLink = 'MagicLink',
  ShopifyMultipass = 'ShopifyMultipass',
  Auth0 = 'Auth0',
}

// this needs to match IntegrationProviderEnum
export enum UserLoginProviderEnum {
  Essensys = 'Essensys',
  LinkedIn = 'LinkedIn',
  ParkWhiz = 'ParkWhiz',
  Microsoft = 'Microsoft',
  ProxyClick = 'ProxyClick',
  Safetrust = 'Safetrust',
  AzureAD = 'MicrosoftAzure',
  Google = 'GoogleWorkplace',
  Okta = 'Okta',
  Apple = 'Apple',
  Auth0 = 'Auth0',
}

export enum UserHeaderAuthType {
  // jwt uses jwt with token and jti in Authorization heaader
  JWT = 'JWT',
  // bearer uses apiKey in Authorization header
  BEARER = 'Bearer',
}

export type UserLoginType = DocumentType & {
  status: UserLoginStatusEnum;
  isPrimary: boolean;
  type: UserLoginTypeEnum;
  provider?: UserLoginProviderEnum;
  key: string;
  refreshToken?: string;
  accessToken?: string;
  tokenExpiresAt?: Date;
  autoRefreshToken?: boolean;
  verify?: string;
  data?: any;
};

export type UserLoginSearchType = {
  searchMode?: SearchModeEnum;
  _id?: string;
  _created?: DateTimeSearchType;
  _updated?: DateTimeSearchType;
  type?: UserLoginTypeEnum;
  provider?: UserLoginProviderEnum;
  isPrimary?: boolean;
  key?: string;
  status?: UserLoginStatusEnum;
};

export type UserLoginQueryFieldsType = DocumentQueryFieldsType & {
  status?: true;
  isPrimary?: true;
  type?: true;
  provider?: true;
  key?: true;
  refreshToken?: true;
  accessToken?: true;
  tokenExpiresAt?: true;
  autoRefreshToken?: true;
  data?: true;
};
