import React, {
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
  useRef,
} from 'react';

import cx from 'classnames';
import { Input, S } from '../../index';
import { useScrollToSelectedChild } from '../../hooks/useScrollToSelectedChild';
import { DateTime } from 'luxon';
import { Key } from 'ts-key-enum';

import { arrayClamp } from 'lane-shared/helpers';

import {
  formatTime,
  handleOpenDropdown,
  toDateTime,
  isOptionSelectedTime,
  mapTimesToTimeOptions,
  getTimesFromUnit,
} from './helpers';
import type { Option, TimeOption } from './helpers';
import { useInputCloseOutside } from '../../hooks/useInputCloseOutside';
import { useWindowDimensions } from './hooks/useWindowDimensions';
import buttonStyle from './DatePickers.scss';
import styles from './TimePicker.scss';

export enum TimeIntervals {
  QUARTER_HOUR = 15,
  HALF_HOUR = 30,
  ONE_HOUR = 60,
}

const ONE_MINUTE_UNIT = 60;
const OPTION_LIST_HEIGHT = 208;

type Props = {
  /** the current time selected today, in the number of minutes since the start of the day. */
  value?: string | Date | null;
  onChange?: (value: string) => any;
  timeZone?: string | null;
  className?: string;
  buttonClassName?: string;
  style?: React.CSSProperties;
  hideLabel?: boolean;
  label?: string;
  showClear?: boolean;
  showIcon?: boolean;
  dataCy?: string;
  error?: string[];
  disabled?: boolean;
  fixedLabel?: boolean;
  showTimezone?: boolean;
  unit?: TimeIntervals;
  times?: TimeOption[];
};

export const TimePicker = ({
  value,
  onChange = () => {},
  timeZone,
  unit = ONE_MINUTE_UNIT,
  times,
  className,
  buttonClassName,
  hideLabel,
  showClear = true,
  showIcon = true,
  label = 'Start time',
  style,
  dataCy,
  error,
  disabled,
  fixedLabel,
  showTimezone = true,
}: Props) => {
  const [openState, setOpen] = useState({ opened: false, openedAbove: false });
  const [referenceValue, setReferenceValue] = useState(
    value ? toDateTime(value, timeZone) : undefined
  );
  const pickerRef = useRef(null);

  useEffect(() => {
    setReferenceValue(value ? toDateTime(value, timeZone) : undefined);
  }, [value]);

  const date = referenceValue
    ? referenceValue.startOf('day')
    : DateTime.fromJSDate(new Date(), {
        zone: timeZone ?? undefined,
      }).startOf('day');

  const options: Option[] = useMemo(() => {
    if (times) {
      return mapTimesToTimeOptions(times, showTimezone, timeZone);
    }
    if (unit) {
      // create all the available units when the unit changes.
      // the times will just be strings instead of date times,
      // this saves a bit of effort dealing with time zones.
      return getTimesFromUnit(unit, date, showTimezone);
    }
    return [];
  }, [times, unit, timeZone, referenceValue, date]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { height: heightOfPage } = useWindowDimensions();

  function fireOnChange(isoDateTime: string) {
    const dateTime = timeZone
      ? DateTime.fromISO(isoDateTime).setZone(timeZone)
      : DateTime.fromISO(isoDateTime);

    setReferenceValue(dateTime);
    onChange(dateTime.toISO());
  }

  useLayoutEffect(() => {
    // find the selected time.
    setSelectedIndex(
      options.findIndex(option => {
        if (!referenceValue) {
          return false;
        }

        return isOptionSelectedTime(option, referenceValue, timeZone);
      })
    );
  }, [referenceValue, timeZone, options]);

  const selectedTime = referenceValue
    ? formatTime(referenceValue, showTimezone, timeZone)
    : '';

  const handleSelectOption = (option: Option) => {
    if (option.disabled) {
      return;
    }

    fireOnChange(option.value);
    setOpen({ opened: false, openedAbove: false });
  };

  const [optionsRef] = useScrollToSelectedChild(
    selectedIndex,
    openState.opened
  );

  const renderedOptions = useMemo(
    () =>
      options.map(option => {
        return (
          <div
            key={option.label}
            className={cx(styles.option, {
              [styles.disabled]: option.disabled,
            })}
            role="button"
            tabIndex={0}
            onKeyPress={onKeyPress}
            onClick={() => handleSelectOption(option)}
            data-is-selected={selectedTime === option.label}
          >
            <S>{option.label}</S>
          </div>
        );
      }),
    [options, onChange, referenceValue]
  );

  function onKeyUp(e: any) {
    switch (e.key) {
      case Key.ArrowUp:
        fireOnChange(options[arrayClamp(selectedIndex - 1, options)].value);
        break;
      case Key.ArrowDown:
        fireOnChange(options[arrayClamp(selectedIndex + 1, options)].value);
        break;
      default:
        break;
    }
  }

  function onKeyPress(e: any) {
    if (e.key === Key.Enter) {
      fireOnChange(options[selectedIndex].value);
    }
  }

  useInputCloseOutside(pickerRef, setOpen);
  return (
    <div
      data-cy="timePicker"
      ref={pickerRef}
      tabIndex={0}
      role="menu"
      onKeyUp={onKeyUp}
      className={cx(styles.TimePicker, className)}
      style={style}
    >
      <button
        className={cx(buttonStyle.button, buttonClassName, {
          [buttonStyle.disabled]: disabled,
        })}
        onClick={e =>
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 5-6 arguments, but got 4.
          handleOpenDropdown(e, heightOfPage, OPTION_LIST_HEIGHT, setOpen)
        }
        disabled={disabled}
      >
        <Input
          className={styles.input}
          icon={showIcon ? 'clock' : undefined}
          iconRight
          value={selectedTime}
          placeholder="Select Time"
          label={!hideLabel ? label : undefined}
          ariaLabel={label}
          fixedLabel={fixedLabel}
          onChange={() => null}
          boldBorder
          showClear={showClear}
          dataCy={dataCy}
          error={error}
          disabled={disabled}
        />
      </button>
      <div
        ref={optionsRef}
        className={styles.optionsContainer}
        data-is-open={openState.opened}
        style={openState.openedAbove ? { bottom: '3.5rem' } : { top: '3.5rem' }}
      >
        {renderedOptions}
      </div>
    </div>
  );
};
