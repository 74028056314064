import * as yup from 'yup';

export default yup.object().shape({
  _id: yup.string().trim().required(),
  name: yup.string().trim(),
  requirements: yup.object().shape({
    set: yup.array().of(yup.string()),
    add: yup.array().of(yup.string()),
    remove: yup.array().of(yup.string()),
  }),
  permissions: yup.object().shape({
    set: yup.array().of(yup.string()),
    add: yup.array().of(yup.string()),
    remove: yup.array().of(yup.string()),
  }),
  maxUsers: yup
    .number()
    .min(0)
    .max(2 ** 32),
});
