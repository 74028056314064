import {
  BORDER_INTERACTION_COLORS,
  INTERACTION_COLORS,
} from 'lane-shared/helpers/constants/interactions';
import React from 'react';
import { MjmlButton, MjmlColumn, MjmlSection } from 'components/mjml';
import {
  EVENT_CONTENT_INTERACTION_STARTED,
  EVENT_CONTENT_INTERACTION_ENDED,
  EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_CONTENT_INTERACTION_UPDATED,
  EVENT_CONTENT_INTERACTION_STATUSCHANGE,
} from 'lane-shared/helpers/constants/events';
import { colors } from 'lane-shared/config';
import { getEmptyBlockPlaceholder } from 'components/workflows/helpers';
import { EmailEditableEnum } from 'components/workflows/helpers/constants';
import { EmptyBlockPlaceholder } from '../EmailBuilderV2Preview';
import EmailText from 'lane-shared/emails/components/EmailText';
import EmailLabelWithValue from 'lane-shared/emails/components/EmailLabelWithValue';
import styles from '../EmailBuilderV2Preview.scss';
import { UserType } from 'lane-shared/types/User';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { i18n } from 'i18next';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';
import { ContentType } from 'lane-shared/types/content/Content';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';
import { Workflow } from 'lane-shared/types/workflow';

type DefaultIntroProps = {
  user: UserType | null;
  workflow: Workflow;
  interaction?: Partial<{
    features: UserContentInteractionFeaturesType;
    status: string;
  }>;
  i18n: i18n;
  forAdmin: boolean;
  channel: ChannelType;
  timeZone: string;
  subject?: string;
  statusesFeature?: StatusesFeatureProperties;
  whiteLabel: WhiteLabelType;
  content: DraftContentType | ContentType;
};

type DefaultIntroForInteractionProps = {
  interaction: Partial<{
    status: string;
  }>;
  user: UserType;
  content: DraftContentType | ContentType;
  forAdmin: boolean;
  i18n: i18n;
  subject?: string;
  statusesFeature?: StatusesFeatureProperties;
};

type DefaultIntroForInteractionStatusChangeProps = {
  interaction: Partial<{
    status: string;
  }>;
  user: UserType;
  forAdmin: boolean;
  i18n: i18n;
  oldStatus: string;
  newStatus: string;
  whiteLabel: WhiteLabelType;
  channel: ChannelType;
  timeZone: string;
};

const {
  firstLine: introFirstLine,
  secondLine: introSecondLine,
  thirdLine: introThirdLine,
} = getEmptyBlockPlaceholder(EmailEditableEnum.Intro);

function DefaultIntroForInteraction({
  interaction,
  user,
  content,
  forAdmin,
  subject,
  i18n,
  statusesFeature,
}: DefaultIntroForInteractionProps) {
  return (
    <MjmlColumn className={styles.defaultIntroText}>
      <EmailText>
        <span>
          {forAdmin
            ? subject ?? content.name
            : i18n.t(
                'web.admin.channel.content.workflow.editor.emailBuilder.intro.defaultText',
                {
                  userName: user?.profile.name,
                }
              )}
        </span>
      </EmailText>
      {statusesFeature && (
        <MjmlButton
          color={colors.gray1}
          borderRadius={25}
          border={`2px solid ${
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            BORDER_INTERACTION_COLORS[interaction!.status]
          }`}
          backgroundColor={
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            INTERACTION_COLORS[interaction.status]
          }
        >
          {i18n.t(
            'web.admin.channel.content.workflow.editor.emailBuilder.intro.statusPill.text'
          )}
        </MjmlButton>
      )}
    </MjmlColumn>
  );
}

function DefaultIntroForInteractionStatusChange({
  interaction,
  user,
  forAdmin,
  i18n,
  oldStatus,
  newStatus,
}: DefaultIntroForInteractionStatusChangeProps) {
  return (
    <>
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <h2
              style={{
                textAlign: 'center',
                fontWeight: 'normal',
              }}
            >
              {i18n.t('Hi')} {user.profile.name}
            </h2>
          </EmailText>
          <EmailText>
            <h3 style={{ textAlign: 'center' }}>{i18n.t('Changed from')}</h3>
          </EmailText>
          <MjmlButton
            color={colors.gray1}
            borderRadius={25}
            border={`2px solid ${
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              BORDER_INTERACTION_COLORS[oldStatus || interaction.status]
            }`}
            backgroundColor={
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              INTERACTION_COLORS[oldStatus || interaction.status]
            }
          >
            {oldStatus || interaction.status}
          </MjmlButton>
          <EmailText>
            <h3 style={{ textAlign: 'center' }}>{i18n.t('to')}</h3>
          </EmailText>
          <MjmlButton
            color={colors.gray1}
            borderRadius={25}
            border={`2px solid ${
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              BORDER_INTERACTION_COLORS[newStatus || interaction.status]
            }`}
            backgroundColor={
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              INTERACTION_COLORS[newStatus || interaction.status]
            }
          >
            {newStatus || interaction.status}
          </MjmlButton>
        </MjmlColumn>
      </MjmlSection>
      {forAdmin && (
        <EmailLabelWithValue label={i18n.t('Name')}>
          {user.profile.name}
        </EmailLabelWithValue>
      )}
    </>
  );
}

export function DefaultIntro({
  user,
  workflow,
  interaction,
  i18n,
  forAdmin,
  channel,
  timeZone,
  subject,
  statusesFeature,
  whiteLabel,
  content,
}: DefaultIntroProps) {
  if (!user || !workflow?.event || !interaction) {
    return null;
  }
  switch (workflow.event) {
    case EVENT_CONTENT_INTERACTION_STARTED:
    case EVENT_CONTENT_INTERACTION_ENDED:
    case EVENT_CONTENT_INTERACTION_CREATED:
    case EVENT_CONTENT_INTERACTION_UPDATED:
      return (
        <DefaultIntroForInteraction
          user={user}
          interaction={interaction}
          content={content}
          forAdmin={forAdmin}
          i18n={i18n}
          statusesFeature={statusesFeature}
          subject={subject}
        />
      );
    case EVENT_CONTENT_INTERACTION_STATUSCHANGE:
      return (
        <DefaultIntroForInteractionStatusChange
          user={user}
          interaction={interaction}
          i18n={i18n}
          whiteLabel={whiteLabel}
          forAdmin={forAdmin}
          channel={channel}
          oldStatus={workflow.workflow?.statusFrom || ''}
          newStatus={workflow.workflow?.statusTo || ''}
          timeZone={timeZone}
        />
      );
    default:
      return (
        <EmptyBlockPlaceholder
          firstLine={introFirstLine}
          secondLine={introSecondLine}
          thirdLine={introThirdLine}
          t={i18n.t}
        />
      );
  }
}
