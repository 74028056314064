import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { listUnits, ListUnitsQueryResponse } from 'lane-shared/graphql/units';

import { Item } from 'components/form/Dropdown/Dropdown';

export const useGetUnitsFieldOptions = (propertyId: string) => {
  const [options, setOptions] = useState<Item<string>[]>([]);
  const { loading, error, data } = useQuery<ListUnitsQueryResponse>(listUnits, {
    variables: {
      propertyId,
    },
  });

  useEffect(() => {
    if (data?.property?.units?.length) {
      const formattedUnits = data?.property.units.map((unit: any) => ({
        label: unit.name,
        value: unit.id,
      }));

      setOptions(formattedUnits);
    }
  }, [data]);

  return {
    loading,
    error,
    options,
  };
};
