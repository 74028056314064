import { LaneType } from 'common-types';
import { PublicUserType } from '../../../hooks/useMyPublicUsersQuery';
import { ContentType } from '../../../types/content/Content';
import { MEDIUM_DAY_DATE } from '../../constants/dates';
import { dateFormatter } from '../../formatters';

export function formatBurstDateRange(
  dateRange: LaneType.DateRange,
  timeZone?: string
) {
  const dateFormat = MEDIUM_DAY_DATE;

  return `${dateFormatter(
    dateRange.startDate,
    dateFormat,
    timeZone
  )} - ${dateFormatter(dateRange.endDate, dateFormat, timeZone)}`;
}

export function formatBurstRoomBooking(
  roomBooking: LaneType.UserContentInteraction | null | undefined
): string {
  if (!roomBooking) {
    return '';
  }

  return (roomBooking.contentData as ContentType)?.name;
}

export function formatBurstActivitiesBookings(
  activitiesBookings: Array<LaneType.UserContentInteraction> | null | undefined
): string {
  if (!activitiesBookings || activitiesBookings.length === 0) {
    return 'No bookings';
  }

  return activitiesBookings
    .map(booking => (booking.contentData as ContentType)?.name)
    .join('\n');
}

export function formatBurstShopifolk(user: PublicUserType): string {
  return `${user.profile.name} - ${user.profile.email || '[Email Not Set]'}`;
}
