import { useContext, useLayoutEffect, useRef } from 'react';

import GeoJSON from 'geojson';
import { MapContext } from 'react-mapbox-gl';
import { useDebouncedCallback } from 'use-debounce';

import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

interface Props {
  draggable?: boolean;
  onCoordinatesUpdated?: (
    pointId: string,
    coordinates: GeoCoordinateType
  ) => void;
  sourceId: string;
  layerId: string;
  onFocus: (rectangleId: string) => void;
  onLayerLoad: (map: any) => void;
  source?:
    | GeoJSON.FeatureCollection<GeoJSON.Point>
    | GeoJSON.Feature<GeoJSON.Point>;
  coordinates?: GeoCoordinateType;
}

interface MapboxPointHook {
  map: any;
}

export default function useMapboxPoint(props: Props): MapboxPointHook {
  const map = useContext(MapContext);

  const internalRef = useRef<{
    coordinates?: GeoCoordinateType;
    pointId?: string;
    props: Props; // props are put to refs because Mapbox handlers fix props at the time of the handlers initialization which will force to reinitialize handlers on each props change, fortunately refs are always up to date inside of those handlers (link referencing use of refs: https://github.com/alex3165/react-mapbox-gl/issues/963)
  }>({ props });

  internalRef.current.props = props;

  const debounceHandleMove = useDebouncedCallback(handleMove, 10);

  function handleMove(e: any) {
    const { sourceId, source } = internalRef.current.props;
    const existingSource = map.getSource(sourceId);

    if (!internalRef.current.pointId || !existingSource || !source) {
      return;
    }

    e.preventDefault();

    const canvas = map.getCanvasContainer();
    const coords = e.lngLat;
    if (source.type === 'FeatureCollection') {
      const featureIndex = source.features.findIndex(
        feature => feature.properties?.id === internalRef.current.pointId
      );

      if (featureIndex !== -1) {
        canvas.style.cursor = 'grabbing';

        internalRef.current.coordinates = [coords.lng, coords.lat];
        source.features[featureIndex].geometry.coordinates = [
          coords.lng,
          coords.lat,
        ];

        existingSource.setData(source);
      }
    } else {
      canvas.style.cursor = 'grabbing';

      internalRef.current.coordinates = [coords.lng, coords.lat];
      source.geometry.coordinates = [coords.lng, coords.lat];

      existingSource.setData(source);
    }
  }

  function handleUp() {
    const { onCoordinatesUpdated } = internalRef.current.props;
    const canvas = map.getCanvasContainer();
    canvas.style.cursor = '';

    map.off('mousemove', debounceHandleMove.callback);
    map.off('touchmove', debounceHandleMove.callback);

    if (
      onCoordinatesUpdated &&
      internalRef.current.pointId &&
      internalRef.current.coordinates
    ) {
      onCoordinatesUpdated(
        internalRef.current.pointId,
        internalRef.current.coordinates
      );

      internalRef.current.pointId = undefined;
      internalRef.current.coordinates = undefined;
    }
  }

  function handleDown(e: any) {
    e.preventDefault();
    const { draggable, sourceId, onFocus } = internalRef.current.props;
    const source = map.getSource(sourceId);

    if (draggable && source) {
      internalRef.current.pointId = e.features[0].properties.id;

      map.on('mousemove', debounceHandleMove.callback);
      map.on('touchmove', debounceHandleMove.callback);
      map.once('mouseup', handleUp);
    }

    if (onFocus) {
      onFocus(e);
    }
  }

  function handleLoad() {
    const {
      layerId,
      sourceId,
      onLayerLoad,
      source,
    } = internalRef.current.props;

    const existingSource = map.getSource(sourceId);

    map.on('mousedown', layerId, handleDown);
    map.on('touchdown', layerId, handleDown);

    if (existingSource) {
      return;
    }

    map.addSource(sourceId, {
      type: 'geojson',
      data: source,
    });

    onLayerLoad(map);
  }

  useLayoutEffect(() => {
    const { layerId, sourceId } = internalRef.current.props;
    if (map._loaded) {
      handleLoad();
    } else {
      map.on('load', handleLoad);
    }

    return () => {
      if (!map) {
        return;
      }

      map.off('load', handleLoad);
      map.off('mousedown', layerId, handleDown);
      map.off('touchdown', layerId, handleDown);

      try {
        if (map && map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }

        // remove the source last since the layers depend on it
        if (map && map.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      } catch (err) {
        // map may have unloaded.
      }
    };
  }, [props.sourceId]);

  useLayoutEffect(() => {
    const existingSource = map.getSource(props.sourceId);

    if (!existingSource || !props.source) {
      return;
    }

    existingSource.setData(props.source);
  }, [props.source]);

  useLayoutEffect(() => {
    if (props.coordinates) {
      const existingSource = map.getSource(props.sourceId);

      if (!existingSource) {
        return;
      }

      existingSource.setData({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: props.coordinates,
        },
      });
    }
  }, [props.coordinates]);

  return { map };
}
