import {
  explodeFeatures,
  convertToHourly,
  getUnitTime,
} from '../helpers/features';
import { ContentType } from '../types/content/Content';
import { PaymentTypeEnum } from '../types/payment/PaymentTypeEnum';

type Args = {
  content: Pick<ContentType, 'geo' | 'features' | 'category'> | undefined;
  showDistance: boolean;
  locationEnabled: boolean;
};

type DistanceAndPricePreview = {
  amount: { currency?: string } | undefined;
  shouldShowDistanceAway: boolean;
  isPriceShown: boolean;
  isFree: boolean;
  totalAmount: number;
  unitTime: string;
};

export default function useDistanceAndPricePreview({
  content,
  showDistance,
  locationEnabled,
}: Args): DistanceAndPricePreview | null {
  if (!content) {
    return null;
  }

  const shouldShowDistanceAway =
    showDistance && locationEnabled && Boolean(content.geo);
  const { paymentFeature, reservableFeature, menuFeature } = explodeFeatures(
    content.features
  );
  const amount = paymentFeature?.amounts.find(
    amount => amount.type === PaymentTypeEnum.Cash
  );
  const totalAmount = amount
    ? convertToHourly(reservableFeature, amount.amount)
    : 0;
  const isFree = Boolean(!totalAmount);
  const isMenuDisabled = Boolean(!menuFeature);
  const isPriceShown = Boolean(isMenuDisabled && amount);

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ReservableFeatureProperties | un... Remove this comment to see the full error message
  const unitTime = getUnitTime(reservableFeature, content.category);

  return {
    amount,
    shouldShowDistanceAway,
    isPriceShown,
    isFree,
    totalAmount,
    unitTime,
  };
}
