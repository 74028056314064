import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'lane-web/src/components/general';

// @ts-ignore - issue with typescript resolving scss paths
import styles from './AddPaymentOptionButton.scss';

type Props = {
  onClick: () => void;
};

export function AddPaymentOptionButton({ onClick }: Props) {
  const { t } = useTranslation();

  return (
    <Button
      className={styles.addPaymentOptionButton}
      variant="activate-contained"
      onClick={onClick}
      dataCy="addPaymentOptionButton"
    >
      {t('abp.payment.orderSummary.addPaymentMethod')}
    </Button>
  );
}
