import React, { PropsWithChildren } from 'react';

import cx from 'classnames';
import { Loading } from '../Loading/Loading';

import { ModalBackground } from './ModalBackground';
import { ModalHeader } from './ModalHeader';

import styles from './Modal.scss';

type Props<T> = PropsWithChildren<{
  isOpen: boolean;
  children: React.ReactNode;
  onClose: (...args: any) => void;
  className?: string;
  title?: string;
  modalHistory?: T[];
  isLoading?: boolean;
  isCloseButtonHidden?: boolean;
  actions?: React.ReactNode;
  size?: 'large' | 'small';
  options?: {
    hasNavigation: boolean;
  };
  style?: React.CSSProperties;
  dataCy?: string;
  disableContentPadding?: boolean;
  onBack?: (...args: any) => void;
  closeButtonSize?: 'small' | 'medium' | 'large';
  disableCloseOnBackgroundClick?: boolean;
  includeHeader?: boolean;
}>;

export const Modal = <T,>({
  isOpen,
  onClose,
  onBack,
  children,
  isLoading,
  size = 'small',
  actions = null,
  title,
  className,
  style,
  dataCy,
  modalHistory = [],
  disableContentPadding = false,
  isCloseButtonHidden = false,
  closeButtonSize = 'medium',
  disableCloseOnBackgroundClick = false,
  includeHeader = true,
}: Props<T>) => {
  return (
    <ModalBackground
      isOpen={isOpen}
      onClose={disableCloseOnBackgroundClick ? () => {} : onClose}
      className={styles.background}
      hasOpaqueBackground
    >
      <div
        className={cx(styles.modal, styles[size], className)}
        style={style}
        data-cy={dataCy}
        role="dialog"
      >
        <div
          data-cy="modalContent"
          className={cx([
            styles.container,
            Boolean(actions),
            Boolean(onBack || onClose || title) && styles.hasTitle,
          ])}
        >
          {includeHeader && (
            <ModalHeader
              modalHistory={modalHistory}
              onBack={onBack}
              onClose={onClose}
              size={size}
              title={title}
              closeButtonSize={closeButtonSize}
              isCloseButtonHidden={isCloseButtonHidden}
            />
          )}
          <div
            className={cx(
              styles.content,
              disableContentPadding ? styles.noPadding : null
            )}
          >
            {isLoading ? <Loading /> : children}
          </div>
          {actions && (
            <>
              <hr />
              <div className={styles.actions}>{actions}</div>
            </>
          )}
        </div>
      </div>
    </ModalBackground>
  );
};
