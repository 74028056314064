import { MutableRefObject, useEffect } from 'react';

import { createIconGradient } from 'helpers';

export default function useIconGradient(
  containerRef: MutableRefObject<HTMLElement | null>
) {
  useEffect(() => {
    const gradientElement = createIconGradient();
    if (containerRef.current && containerRef.current.children) {
      for (let i = 0; i < containerRef.current.children.length; i++) {
        const tag = containerRef.current.children[i];
        if (tag.tagName === 'svg') {
          tag.appendChild(gradientElement);
        }
      }
    }
  }, []);
}
