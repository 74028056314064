import React from 'react';

import { useTranslation } from 'react-i18next';

import FeatureOptionToggle from '../components/FeatureOptionToggle';
import ReservableFeatureTimeUnitSlider from './ReservableFeatureTimeUnitSlider';
import { minUnits } from './reservableFeatureValues';

type Props = {
  onChange: () => any;
  settings: any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onFeatureUpdated: (any) => any;
};

const TRANSLATION_KEYS = {
  minBookingWindow:
    'web.content.features.reservable.ReservableReservationStart.minBookingWindow',
  minBookingWindowDescription:
    'web.content.features.reservable.ReservableReservationStart.minBookingWindow.description',
  minBookingWindowTooltip:
    'web.content.features.reservable.ReservableReservationStart.minBookingWindow.tooltip',
};

export default function ReservableReservationStart({
  onChange,
  settings,
  onFeatureUpdated,
}: Props) {
  const { t } = useTranslation();

  if (!settings.mainRule) {
    return null;
  }

  return (
    <FeatureOptionToggle
      title={t(TRANSLATION_KEYS.minBookingWindow)}
      description={t(TRANSLATION_KEYS.minBookingWindowDescription)}
      onChange={onChange}
      isExpanded={settings.mainRule.unitsAfterNow !== 0}
      TooltipComponent={t(TRANSLATION_KEYS.minBookingWindowTooltip)}
    >
      <ReservableFeatureTimeUnitSlider
        onUnitTypeUpdated={unitsAfterNowType =>
          onFeatureUpdated({
            mainRule: {
              ...settings.mainRule,
              unitsAfterNowType,
              unitsAfterNow: minUnits[unitsAfterNowType],
            },
          })
        }
        onUnitsUpdated={unitsAfterNow =>
          onFeatureUpdated({
            mainRule: {
              ...settings.mainRule,
              unitsAfterNow,
            },
          })
        }
        units={settings.mainRule.unitsAfterNow}
        unitType={settings.mainRule.unitsAfterNowType}
      />
    </FeatureOptionToggle>
  );
}
