import React, { useEffect } from 'react';

import cx from 'classnames';
import { SearchBar } from 'components';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { queryChannelUsersByGroupRole } from 'lane-shared/graphql/query';
import { useChannelGroupRolesQuery, useStoredState } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';

import Loading from '../general/Loading';
import Pagination from '../general/Pagination';
import UserCircleListView from './UserCircleListView';

import styles from './UserSearchOnChannel.scss';

const PER_PAGE = 25;

type SearchType = {
  search: string;
  page: number;
  perPage: number;
  selectedGroupRoleId: string | null;
};

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  channelId: string;
  selectedUserId?: string;
  includeWorkplaceMember?: boolean;
  onUserSelected: (
    user: UserType | null,
    selectedChannel: string | null
  ) => void;
  storageKey?: string;
  disableStorage?: boolean;
};

UserSearchOnChannel.defaultProps = {
  onUserSelected: () => null,
  disableStorage: false,
};

type Props = OwnProps & typeof UserSearchOnChannel.defaultProps;

export default function UserSearchOnChannel({
  className,
  style,
  channelId,
  selectedUserId,
  onUserSelected,
  includeWorkplaceMember,
  storageKey,
  disableStorage,
}: Props) {
  const { t } = useTranslation();
  const [search, setSearch] = useStoredState<SearchType>(
    `UserSearchOnChannel${storageKey}`,
    {
      search: '',
      page: 0,
      perPage: PER_PAGE,
      selectedGroupRoleId: null,
    },
    { disableStorage }
  );

  const { groupRoles } = useChannelGroupRolesQuery({
    channelId,
    includeWorkplaceMember,
  });

  const { data, loading: usersLoading } = useQuery(
    queryChannelUsersByGroupRole,
    {
      skip: !search?.selectedGroupRoleId,
      variables: {
        groupRoleId: search.selectedGroupRoleId,
        search: search.search
          ? {
              user: {
                search: {
                  type: 'like',
                  value: search.search,
                },
              },
            }
          : undefined,
        pagination: {
          start: search.page * search.perPage,
          perPage: search.perPage,
        },
      },
    }
  );

  const { items: userGroupRoles, pageInfo } =
    data?.channelUsersByGroupRole || {};

  function updateSearch(props: Partial<SearchType>) {
    setSearch(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  useEffect(() => {
    updateSearch({ selectedGroupRoleId: null });
  }, [channelId]);

  useEffect(() => {
    updateSearch({ page: 0 });
  }, [search.search]);

  return (
    <div className={cx(styles.UserSearchOnChannel, className)} style={style}>
      <menu>
        {groupRoles.map(groupRole => (
          <button
            key={groupRole._id}
            data-is-selected={search.selectedGroupRoleId === groupRole._id}
            onClick={() =>
              updateSearch({
                selectedGroupRoleId: groupRole?._id,
                search: '',
              })
            }
          >
            {groupRole.name}
          </button>
        ))}
      </menu>
      <section>
        {search.selectedGroupRoleId && (
          <>
            <SearchBar
              className={styles.input}
              searchOptions={search}
              onSearchChange={search => updateSearch({ search })}
              searchInputPlaceholder={t('Search users…')}
            />
            {usersLoading && <Loading />}
            <Pagination
              page={search.page}
              perPage={PER_PAGE}
              loading={usersLoading}
              total={pageInfo?.total || 0}
              pagesToShow={3}
              onPage={page => updateSearch({ page })}
            />
            <ul>
              {(userGroupRoles || []).map((userGroupRole: any) => (
                <button
                  key={userGroupRole._id}
                  data-is-selected={
                    selectedUserId && selectedUserId === userGroupRole.user._id
                  }
                  onClick={() => onUserSelected(userGroupRole.user, channelId)}
                >
                  <UserCircleListView
                    className={styles.user}
                    user={userGroupRole.user}
                  />
                </button>
              ))}
            </ul>
          </>
        )}
      </section>
    </div>
  );
}
