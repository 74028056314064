import React from 'react';
import { SubscriptionReceiptHeader } from './SubscriptionReceiptHeader';
import { SubscriptionReceiptContentInfo } from './SubscriptionReceiptContentInfo';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { SubscriptionReceiptDisclaimer } from './SubscriptionReceiptDisclaimer';

interface Props {
  channelName: string;
  confirmationNumber: string;
  date: string;
  contentName: string;
  quote: PaymentFeatureQuoteType;
}

export function PaymentSubscriptionReceiptDetails({
  channelName,
  confirmationNumber,
  date,
  contentName,
  quote,
}: Props) {
  return (
    <div className="flex flex-col items-center gap-y-3">
      <SubscriptionReceiptHeader
        channelName={channelName}
        confirmationNumber={confirmationNumber}
        date={date}
      />
      <div className="flex flex-col w-70 max-w-70 justify-center gap-y-3">
        <SubscriptionReceiptContentInfo
          contentName={contentName}
          quote={quote}
        />
        <SubscriptionReceiptDisclaimer
          recurrenceInterval={quote.subscriptionDetails!.recurrenceInterval}
        />
      </div>
    </div>
  );
}
