import i18n from 'localization';

import { SupportedSchema, TypeBase } from '../../types/baseTypes/TypeBase';
import { RequiredIfValidator } from './RequiredIf';

export function addRequiredIfValidatorImpl<T extends SupportedSchema>(
  type: TypeBase<T>,
  validator: RequiredIfValidator
) {
  type._schema = type.schema.when(validator.config?.value as string, {
    is: val => !!val,
    then: type.schema.required(
      i18n.t('{{- field}}  is required.', {
        field: type.getDisplayName(),
      })
    ),
    otherwise: type.schema.notRequired(),
  }) as T;
}
