import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import calculateBufferTimeRangesForReservations from './calculateBufferTimeRangesForReservations';

export default function getBufferTimeDateRangesForReservations({
  units,
  minTime,
  maxTime,
  bufferTime,
  reservations,
  referenceDate,
  timeZone,
}: {
  units: number;
  minTime: number;
  maxTime: number;
  bufferTime: LaneType.BufferTime;
  reservations: DateRangeType[];
  referenceDate: Date;
  timeZone: string;
}): DateRangeType[] {
  const currentDate = DateTime.fromJSDate(referenceDate, { zone: timeZone });
  const bufferTimeRangesForReservations = calculateBufferTimeRangesForReservations(
    {
      reservations,
      units,
      bufferTime,
    }
  );
  const getReservationRangeFromTime = (minute: number) => [
    {
      startDate: currentDate.startOf('day').plus({ minute }).toJSDate(),
      endDate: currentDate.startOf('day').plus({ minute }).toJSDate(),
    },
  ];
  const bufferTimeRangesForMinTime = calculateBufferTimeRangesForReservations({
    reservations: getReservationRangeFromTime(minTime),
    units,
    bufferTime: {
      isEnabled: true,
      beforeDuration: bufferTime.beforeDuration,
    },
  });
  const bufferTimeRangesForMaxTime = calculateBufferTimeRangesForReservations({
    reservations: getReservationRangeFromTime(maxTime),
    units,
    bufferTime: {
      isEnabled: true,
      afterDuration: bufferTime.afterDuration,
    },
  });
  return bufferTimeRangesForReservations.concat(
    ...bufferTimeRangesForMinTime,
    ...bufferTimeRangesForMaxTime
  );
}
