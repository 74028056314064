import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { DAYS_OF_THE_WEEK } from '../../../../helpers/constants/timeUnits';
import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import { TypeContextEnum } from '../../../../types/properties/TypeContextEnum';
import TimeAvailabilityDayRule from './TimeAvailabilityDayRule';

const properties: PropertiesInterface = Object.keys(DAYS_OF_THE_WEEK).reduce(
  (obj, dayKey) => ({
    [dayKey]: {
      type: 'TimeAvailabilityDayRule',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  }),
  {
    _id: {
      type: 'UUID',
      get default(): any {
        return uuid();
      },
    },
  } as PropertiesInterface
);

export class TimeAvailabilityWeekRuleType extends ObjectTypeBase {
  name = 'TimeAvailabilityWeekRule';

  contexts = [TypeContextEnum.Properties];

  friendlyName = 'Availability for days of the week';

  get example(): any {
    return this.default;
  }

  get default(): any {
    return Object.keys(DAYS_OF_THE_WEEK).reduce(
      (obj, dayKey) => ({
        ...obj,
        [dayKey]: TimeAvailabilityDayRule.default,
      }),
      {
        _id: uuid(),
      }
    );
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new TimeAvailabilityWeekRuleType();

Types.addType(definition);
export default definition;
