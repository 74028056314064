import { Primitive } from '../../../types/blocks/Primitive';

const ImageBlock: Primitive = {
  name: 'ImageBlock',
  friendlyName: 'Image',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  tags: ['Media'],
  properties: {
    image: {
      type: 'Media',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    sizing: {
      type: 'String',
      default: false,
      validators: [
        {
          name: 'In',
          value: ['contain', 'cover'],
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ImageBlock;
