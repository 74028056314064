import {
  AssigneeChangedWebhookResponse,
  CommentAddedWebhookResponse,
  PrismEvent,
  StatusChangedWebhookResponse,
  WebhookResponse,
  WorkOrder,
} from '../types';
import { WoAssigneeChangedTemplate } from './woAssigneeChanged';
import { WoCommentAddedTemplate } from './woCommentAdded';
import { WoStatusChangedTemplate } from './woStatusChanged';

export const getWebhookEventEmailTemplate = (
  event: PrismEvent,
  workOrderDetail: WorkOrder,
  webhookResData: WebhookResponse
): JSX.Element => {
  switch (event) {
    case 'work_orders.assignee_change':
      return WoAssigneeChangedTemplate({
        workOrderDetail,
        webhookResData: webhookResData as AssigneeChangedWebhookResponse,
      });
    case 'work_orders.comment_added':
      return WoCommentAddedTemplate({
        workOrderDetail,
        webhookResData: webhookResData as CommentAddedWebhookResponse,
      });
    case 'work_orders.status_change':
      return WoStatusChangedTemplate({
        workOrderDetail,
        webhookResData: webhookResData as StatusChangedWebhookResponse,
      });
  }
};
