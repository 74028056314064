import { useMemo } from 'react';

import { getVisibleContentData } from 'lane-shared/helpers/content';
import definitionToProperties from 'lane-shared/helpers/properties/definitionToProperties';
import { ContentType } from 'lane-shared/types/content/Content';

import useUnsatisfiedDependenciesToFilter from './useUnsatisfiedDependenciesToFilter';

export default function useVisibleClearedFromDependenciesProperties({
  data,
  content,
}: {
  data: Record<string, any>;
  content: ContentType;
}) {
  const keysToFilter = useUnsatisfiedDependenciesToFilter(
    data,
    content?.data,
    content?.propertiesOptions?.dependencies
  );

  return useMemo(() => {
    const visibleContentData = getVisibleContentData(content);

    return definitionToProperties(content?.data || {}).filter(
      ([key]) =>
        !keysToFilter.includes(key) && visibleContentData?.data.includes(key)
    );
  }, [content, keysToFilter]);
}
