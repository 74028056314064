import { Primitive } from '../../../../types/blocks/Primitive';

export enum ColorSchemeEnum {
  Light = 'Light',
  Dark = 'Dark',
}
/**
 * @deprecated
 */
const AccessControlBlock: Primitive = {
  name: 'AccessControlBlock',
  friendlyName: 'Access Control',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  tags: ['Other'],
  properties: {
    colorScheme: {
      type: 'String',
      validators: [
        {
          name: 'In',
          value: [ColorSchemeEnum.Light, ColorSchemeEnum.Dark],
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default AccessControlBlock;
