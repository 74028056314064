import React from 'react';

import { MjmlSection, MjmlColumn, MjmlDivider } from '../MjmlComponentsV2';

export function EmailHorizontalRule() {
  return (
    <MjmlSection background-color="#FFFFFF" padding="0">
      <MjmlColumn background-color="#FFFFFF">
        <MjmlDivider border-color="#EAECEF" border-width="1px" />
      </MjmlColumn>
    </MjmlSection>
  );
}
