import React from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import Checkbox from '../form/Checkbox';
import Radio from '../form/Radio';
import IconButton from '../general/IconButton';
import Thumbnail from '../general/Thumbnail';

import styles from './CircleListView.scss';

type CircleListViewProps = {
  image: any;
  logo: any;
  highlight?: any;
  children: any;
  name?: any;
  className?: any;
  style?: any;
  onClick?: () => void;
  selected?: boolean | string;
  isSelectable?: boolean;
  isCancellable?: boolean;
  selectType?: 'checkbox' | 'radio';
  value?: any;
};

export default function CircleListView({
  image,
  logo,
  highlight,
  children,
  name,
  value,
  className,
  style,
  onClick = () => {},
  selected,
  isSelectable,
  isCancellable,
  selectType = 'checkbox',
}: CircleListViewProps) {
  const selectComponents = {
    checkbox: (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message
      <Checkbox selected={!!selected} onChange={onClick} selectedType="color" />
    ),
    radio: (
      <Radio
        name=""
        value={value}
        selected={selected as string}
        onChange={onClick}
      />
    ),
  };

  return (
    <div
      className={cx(styles.CircleListView, className)}
      style={style}
      role="button"
      tabIndex={0}
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      onKeyPress={e => e.key === Key.Enter && onClick && onClick(e)}
      onClick={isSelectable || isCancellable ? () => null : onClick}
      data-cy="circleListView"
    >
      <Thumbnail
        className={styles.logoWrapper}
        src={logo || image}
        name={name}
        highlight={highlight}
        outline={false}
      />
      <div className={styles.textWrapper}>{children}</div>
      {isSelectable && selectComponents[selectType]}
      {isCancellable && (
        <IconButton
          icon="times"
          onClick={onClick}
          testId="circle-list-view-cancel-button"
        />
      )}
    </div>
  );
}
