import { Primitive } from '../../../../types/blocks/Primitive';

/**
 * @deprecated use PropertyInputBlock instead
 */
const DropDownInputBlock: Primitive = {
  name: 'DropDownInputBlock',
  isInput: true,
  isPrimitive: true,
  properties: {
    options: {
      type: 'Option',
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
        {
          name: 'ArrayMax',
          value: 2 ** 8,
        },
      ],
    },
    selected: {
      type: 'Option',
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
    placeholder: {
      type: 'String',
      validators: [
        {
          name: 'Max',
          value: 2048,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    input: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default DropDownInputBlock;
