function clamp(value: number, min: number, max: number): number {
  return Math.min(max, Math.max(value, min));
}

export default function validatePaymentAmount(
  paymentAmount: any,
  outstandingAmount: any
): boolean {
  return clamp(paymentAmount, 0, outstandingAmount) === paymentAmount;
}
