export enum ExternalUrlOpeningModeEnum {
  InApp = 'InApp',
  InAppWithSso = 'InAppWithSso',
  External = 'External',
  Ritual = 'Ritual',
}

export type ExternalUrlType = {
  _id: string;
  url: string;
  openingMode: ExternalUrlOpeningModeEnum;
  mediaId?: string;
};
