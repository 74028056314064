import * as yup from 'yup';

import { NumberTypeBase } from '../../types/baseTypes/NumberTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

export class GeoCoordinate extends NumberTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'GeoCoordinate';

  isArray = true;

  type = 'Number';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  superUserOnly = true;

  friendlyName = 'GeoJSON Coordinate';

  get example(): any {
    return [-90 + Math.random() * 180, -180 + Math.random() * 360];
  }

  get default(): any {
    return [0, 0];
  }

  buildSchema() {
    return yup.number();
  }
}

const definition = new GeoCoordinate();

Types.addBaseType(definition);
export default definition;
