import { Location } from '../components/user-access/UserAccessLocationPicker';
import { AccessControlChannel } from '../types/AccessControl';

export const buildLocationData = (
  channels: AccessControlChannel[],
  currentChannelId: string
): Location[] => {
  channels.sort(
    (a, b) =>
      compareByPropertyChannelId(a, b) || compareAlphabetically(a.name, b.name)
  );
  const locationMap: { [key: string]: Location } = {};

  channels.forEach(channel => {
    const {
      channelId,
      name,
      profile,
      userRoles,
      type,
      isConnectedByTemplate,
    } = channel;
    const isCurrentChannel = channel.channelId === currentChannelId;
    const location: Location = {
      id: channelId,
      name,
      isCurrentChannel,
      logo: profile.logo,
      image: profile.image,
      userRoles: userRoles ?? [],
      type,
      isConnectedByTemplate,
    };

    if (channel.propertyChannelId && locationMap[channel.propertyChannelId]) {
      const parentLocation = locationMap[channel.propertyChannelId];
      parentLocation.childLocations = (
        parentLocation.childLocations || []
      ).concat(location);
    } else {
      locationMap[channel.channelId] = location;
    }
  });

  return Object.values(locationMap).sort((a, b) =>
    compareAlphabetically(a.name, b.name)
  );
};

const compareByPropertyChannelId = (
  a: AccessControlChannel,
  b: AccessControlChannel
) => {
  if (!a.propertyChannelId && b.propertyChannelId) return -1;
  if (a.propertyChannelId && !b.propertyChannelId) return 1;
  return 0;
};

const compareAlphabetically = (a: string, b: string) => {
  return a.localeCompare(b);
};
