import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import definition from '../definition';
import constructAddress from './constructAddress';
import constructNewRequest from './constructNewRequest';

export { constructAddress, constructNewRequest };

export const PRIORITIES = [
  { label: 'Urgent', value: 1 },
  { label: 'High', value: 2 },
  { label: 'Medium', value: 3 },
  { label: 'Normal', value: 4 },
  { label: 'Low', value: 5 },
];

export const STORAGE_KEY = 'ServiceRequest';
export const ERROR_MESSAGE =
  'Please check the form to make sure you have entered all information correctly.';
export const validationShape = createShapeFromProperties(
  definition.interactionData
);
export const NOT_SELECTED = ' - Select One - ';
export const RETURN_CHARS = ['\n', '\r'];
