/* eslint-disable */
import constants from './constants';
import getMediaUrl from './getMediaUrl';
import hasLink from './hasLink';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function getBlockStyle(
  _block: any,
  parent: any,
  multiplier = 10,
  units = 'em'
) {
  const style = {};

  // Early exit.
  if (!_block) {
    return style;
  }

  const block = {
    ...(_block.toJSON ? _block.toJSON() : _block),
  };

  // TODO: refactor out to use common isAbsoluteLayout function
  const isAbsoluteLayout = block && block.position === 'relative';
  const hasFlowHeight =
    !isAbsoluteLayout &&
    (block._type === 'LayoutBlock' || block._type === 'TextBlock');

  // Cast all props as integers.
  for (const prop of constants.INTEGER_PROPS) {
    block[prop] = +block[prop] || 0;
  }

  for (const prop of constants.NAKED_PROPS) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    style[prop] = block[prop];
  }

  for (const prop of constants.UNIT_PROPS) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    style[prop] = block[prop] / multiplier + units;
  }

  if (parent) {
    // Root block can't have margin set.
    for (const prop of constants.PARENT_PROPS) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      style[prop] = block[prop] / multiplier + units;
    }

    // Set this blocks isAbsolutePositioned flag in-case it got lost.
    if (parent && parent.position === 'relative') {
      block.isAbsolutePositioned = true;
    }
  }

  (style as any).transform =
    block.scale !== 1 || block.rotate !== 0
      ? `scale3d(${block.scale},${block.scale}, 1) rotate(${block.rotate}deg)`
      : '';

  let backgroundImage = '';

  if (block.gradient) {
    backgroundImage += block.gradient;
  }

  if (block.gradient && block.backgroundImage) {
    backgroundImage += ',';
  }

  if (block.backgroundImage) {
    backgroundImage += `url(${getMediaUrl(block.backgroundImage)})`;
  }

  (style as any).backgroundImage = backgroundImage;
  (style as any).backgroundPosition = `${block.backgroundPositionX || 0}% ${
    block.backgroundPositionY || 0
  }%`;
  (style as any).backgroundSize = block.backgroundSize;

  // TODO: is this a valid hack for backgroundSize?
  if ((style as any).backgroundSize === 'auto') {
    (style as any).backgroundSize = 'cover';
  }

  if (block.isAbsolutePositioned) {
    (style as any).position = 'absolute';

    for (const prop of ['top', 'left', 'width', 'height']) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      style[prop] = (+block[prop] || 0) / multiplier + units;
    }
  } else if (isAbsoluteLayout) {
    (style as any).position = 'relative';

    for (const prop of ['top', 'left']) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      style[prop] = 'auto';
    }

    for (const prop of ['width', 'height']) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      style[prop] = (+block[prop] || 0) / multiplier + units;
    }
  } else {
    (style as any).top = 'auto';
    (style as any).left = 'auto';
    (style as any).position = parent ? 'initial' : 'relative';
    (style as any).height =
      hasFlowHeight || !block.height
        ? 'auto'
        : (+block.height || 0) / multiplier + units;
    (style as any).width = block.width
      ? (+block.width || 0) / multiplier + block.widthMode
      : '100%';
  }

  // Column mode
  if (block.columnMode) {
    (style as any).display = 'table';
  } else if (block.isColumn) {
    (style as any).display = 'table-cell';
  } else {
    (style as any).display = parent ? 'inline-block' : 'block';
  }

  if (hasLink(block)) {
    (style as any).cursor = 'pointer';
  }

  return style;
}
