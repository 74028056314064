import { generatePath } from 'react-router-dom';

import routes from 'lane-shared/config/routes';

export function redirectToTeamsPage(history: any, channelSlug: string) {
  const route = generatePath(routes.channelAdminTeams, {
    id: channelSlug,
  });

  history.replace(route);
}
