import { v4 as uuid } from 'uuid';

import { PackagedTypeEnum } from '../../types/properties/PackagedTypeEnum';
import { PropertyType } from '../../types/properties/Property';

const defaultWidth = 150;

export default function constructPackagedType(
  packagedType: PackagedTypeEnum,
  name: string
): PropertyType {
  switch (packagedType) {
    case PackagedTypeEnum.Slider:
      return {
        name,
        packagedType,
        _id: uuid(),
        placeholder: '',
        type: 'Number',
        validators: [
          {
            name: 'Min',
            value: 0,
          },
          {
            name: 'Max',
            value: 100,
          },
        ],
        width: defaultWidth,
      };
    case PackagedTypeEnum.Quantity:
      return {
        name,
        packagedType,
        _id: uuid(),
        placeholder: '',
        type: 'Number',
        validators: [
          {
            name: 'Min',
            value: 0,
          },
          {
            name: 'Max',
            value: 10,
          },
        ],
        width: defaultWidth,
      };
    case PackagedTypeEnum.Checkboxes:
      return {
        name,
        packagedType,
        _id: uuid(),
        placeholder: '',
        type: 'Option',
        isArray: true,
        validators: [
          {
            name: 'ArrayMin',
            value: 0,
          },
          {
            name: 'ArrayMax',
            value: 3,
          },
          {
            name: 'In',
            value: [
              {
                _id: uuid(),
                _order: 0,
                value: 'Checkbox 1',
                name: 'Checkbox 1',
              },
              {
                _id: uuid(),
                _order: 1,
                value: 'Checkbox 2',
                name: 'Checkbox 2',
              },
              {
                _id: uuid(),
                _order: 2,
                value: 'Checkbox 3',
                name: 'Checkbox 3',
              },
            ],
          },
        ],
      };
    case PackagedTypeEnum.Radios:
      return {
        name,
        packagedType,
        _id: uuid(),
        placeholder: '',
        type: 'Option',
        isArray: false,
        validators: [
          {
            name: 'In',
            value: [
              { _id: uuid(), _order: 0, value: 'Option 1', name: 'Option 1' },
              { _id: uuid(), _order: 1, value: 'Option 2', name: 'Option 2' },
              { _id: uuid(), _order: 2, value: 'Option 3', name: 'Option 3' },
            ],
          },
        ],
      };
    case PackagedTypeEnum.Dropdown:
      return {
        name,
        packagedType,
        _id: uuid(),
        placeholder: '',
        type: 'Option',
        isArray: false,
        validators: [
          {
            name: 'In',
            value: [
              {
                _id: uuid(),
                _order: 0,
                value: 'Dropdown 1',
                name: 'Dropdown 1',
              },
              {
                _id: uuid(),
                _order: 1,
                value: 'Dropdown 2',
                name: 'Dropdown 2',
              },
              {
                _id: uuid(),
                _order: 2,
                value: 'Dropdown 3',
                name: 'Dropdown 3',
              },
            ],
          },
        ],
      };
    case PackagedTypeEnum.None:
    default:
      return {
        name,
        _id: uuid(),
        placeholder: '',
        type: 'String',
        validators: [],
      };
  }
}
