import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ShopifyBurstReviewSectionTitle } from 'lane-shared/types/integrations/ShopifyBurst';

import { Flex } from 'components/layout';
import { Text } from 'components/typography';

import ReviewData from './ReviewData';

import styles from './ReviewRequest.scss';

type Props = {
  onClick?: () => void;
  specialRequest?: string;
};

export default function ReviewRequest({ specialRequest, onClick }: Props) {
  const { t } = useTranslation();

  return (
    <ReviewData
      title={t(ShopifyBurstReviewSectionTitle.BurstSpecialRequest)}
      titleIcon={<Icon set="FontAwesome" name="file-plus" type="far" />}
      onClick={onClick}
      summary={
        <Flex direction="column">
          <Text size="large" className={styles.text}>
            {specialRequest}
          </Text>
        </Flex>
      }
    />
  );
}
