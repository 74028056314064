import { DateTime, Interval } from 'luxon';
import { getUnavailableDatesAndWeekDays } from 'lane-shared/hooks/features/reservable/helpers/getUnavailableDatesAndWeekDays';
import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

export function getReferenceDateAvailability({
  referenceDate,
  timeAvailabilityFeature,
  timeZone,
  userRoleBase62Ids,
}: {
  referenceDate: Date;
  timeZone: string;
  timeAvailabilityFeature: TimeAvailabilityFeatureProperties | undefined;
  userRoleBase62Ids: string[];
}) {
  const {
    unavailableDates,
    unavailableWeekdays,
  } = getUnavailableDatesAndWeekDays({
    timeAvailabilityFeature,
    userGroupRoleIds: userRoleBase62Ids,
  });

  const isReferenceDateInThePast =
    DateTime.fromJSDate(referenceDate).setZone(timeZone) <
    DateTime.now().setZone(timeZone).startOf('day');

  const isReferenceWeekdayUnavailable = unavailableWeekdays.some(
    d => d === referenceDate.getDay()
  );
  const isReferenceDateUnavailable = unavailableDates.some(range => {
    const beginningOfAllTime = DateTime.fromISO('0001-01-01T00:00:00.000Z');
    const endOfAllTime = DateTime.fromISO('9999-12-31T23:59:59.999Z');
    const startDate = range.startDate
      ? DateTime.fromJSDate(new Date(range.startDate))
      : beginningOfAllTime;
    const endDate = range.endDate
      ? DateTime.fromJSDate(new Date(range.endDate))
      : endOfAllTime;

    return Interval.fromDateTimes(startDate, endDate).contains(
      DateTime.fromJSDate(referenceDate)
    );
  });

  return {
    isReferenceDateInThePast,
    isReferenceWeekdayUnavailable,
    isReferenceDateUnavailable,
  };
}
