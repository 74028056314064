import React, { useContext } from 'react';

import { Button } from 'components';
import { H3 } from 'design-system-web';
import { useIsAdminView, useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { RendererContext } from 'lane-shared/contexts';
import constructContent from 'lane-shared/helpers/content/constructContent';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import { contentTypeRouteMapping } from 'lane-web/src/components/lane/DraftContent/DraftContentCreationModal';
import VisitorFormsContent from 'lane-web/src/domains/visitorManagement/pages/visitor-forms/VisitorFormsContent/VisitorFormsContent';

import { AdminPage } from 'components/layout';

import styles from './styles.scss';

export function VisitorFormsPage() {
  const { t } = useTranslation();
  const { constructBaseBlock } = useContext(RendererContext);
  const { channel } = useChannelAdminContext();
  const [, channelSlug] = useIsAdminView();
  const history = useHistory();
  const location = useLocation();

  function onNewRegistrationForm() {
    const contentType: ContentTypeEnum = ContentTypeEnum.VisitorManagement;

    const content = constructContent({
      contentType,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
      channel,
      baseBlock: constructBaseBlock(),
    });

    const redirectRoute = contentTypeRouteMapping[contentType];
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    history.push(generatePath(redirectRoute, { id: channelSlug }), {
      content,
      from: location.pathname,
    });
  }

  return (
    <AdminPage>
      <div className={styles.visitorTitleContainer}>
        <H3 as="h1">{t('web.admin.channel.visitor.forms.title')}</H3>
        <Button
          color="inherit"
          variant="contained"
          onClick={onNewRegistrationForm}
          dataCy="newRegistrationForm"
        >
          {t('web.admin.channel.visitor.forms.new')}
        </Button>
      </div>
      <div className={styles.visitorFormsContent}>
        <VisitorFormsContent onNewRegistrationForm={onNewRegistrationForm} />
      </div>
    </AdminPage>
  );
}
