/*
 * Given an minute interval, round the time in minutes to the nearest interval
 *
 * ie 80 mins, rounded to nearest 30 minute interval would be 90 minutes (or 1.5 hours)
 *
 * @param time {number} - the time in minutes
 * @param interval {number} - the time interval in minutes
 * @param defaultTime {number} - Specify a time to return a minimum or baseline (ie when rounding result in 0)
 *
 * * */
export default function roundToNearestTimeInterval(
  time: number,
  timeInterval: number,
  // Sometimes having this return 0 does not Make sense
  defaultTime?: number
): number {
  const result = timeInterval * Math.round(time / timeInterval);
  if (result === 0 && defaultTime) return defaultTime;
  return result;
}
