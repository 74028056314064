import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

type Props = {
  isAuthPurchaseWrapper?: boolean;
  displayForgetPasswordForAuthPurchaseWrapper?: () => void;
  navigateToForgotPassword?: () => void;
};

export default function ForgotPasswordComponent({
  navigateToForgotPassword,
  isAuthPurchaseWrapper,
  displayForgetPasswordForAuthPurchaseWrapper,
}: Props) {
  const { t } = useTranslation();

  if (navigateToForgotPassword || isAuthPurchaseWrapper)
    return (
      <a
        className={welcomeFormStyles.link}
        role="link"
        tabIndex={0}
        onClick={
          isAuthPurchaseWrapper
            ? displayForgetPasswordForAuthPurchaseWrapper
            : navigateToForgotPassword
        }
      >
        {t('Forgot password?')}
      </a>
    );

  return (
    <Link className={welcomeFormStyles.link} to={routes.forgotPassword}>
      {t('Forgot password?')}
    </Link>
  );
}
