// For non-content workflows i.e. not `ContentWorkflow`
import { LaneType } from 'common-types';
import { ContentWorkflowType } from '../ContentWorkflowType';

export type Workflow =
  | ContentWorkflowType
  | (Omit<ContentWorkflowType, 'content'> & {
      targetType?: string;
      type?: string;
      channelId: string;
      deletedAt?: Date;
    });

export type WorkflowDTO = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  event: string;
  action: string;
  target: string;
  targetType: string;
  channelId: string;
  workflow?: LaneType.JSON;
  payload?: LaneType.JSON;
  order: number;
  name?: string;
  when?: string;
  whenContext?: string;
  time?: number;
  inStatus?: string[];
  dataValidationSchema?: LaneType.JSON;
  type?: WorkflowTypeEnum;
  deletedAt?: Date;
};

export type WorkflowInput = {
  createdBy: string;
  updatedBy: string;
  event: string;
  action: string;
  target: string;
  targetType: string;
  channelId: string;
  workflow?: LaneType.JSON;
  payload?: LaneType.JSON;
  order: number;
  name?: string;
  when?: string;
  whenContext?: string;
  time?: number;
  inStatus?: string[];
  dataValidationSchema?: LaneType.JSON;
  type?: string;
};

export enum WorkflowTypeEnum {
  ServiceRequest = 'ServiceRequest',
  ServiceRequestEscalation = 'ServiceRequestEscalation',
  Task = 'Task',
}
