import { useEffect, useMemo, useRef, useState } from 'react';

import { CURRENCY_CONVERSION } from '../helpers/constants';
import { CURRENCY_USD } from '../helpers/constants/currencyCodes';
import currencyFormatter from '../helpers/formatters/currencyFormatter';
import { DefaultLocale } from 'localization';

const testNumber = 123456.789;
const PERIOD = '.';
const COMMA = ',';

type Props = {
  min?: number;
  max?: number;
  value: number;
  locale: string;
  currency?: string;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
  onChange?: (value: string) => void;
  onValueChange?: (value: number) => void;
};

export default function useCurrencyInput({
  currency = CURRENCY_USD,
  locale = DefaultLocale,
  min,
  max,
  value,
  onChange,
  onValueChange,
  minimumIntegerDigits,
  minimumFractionDigits,
  maximumFractionDigits,
  minimumSignificantDigits,
  maximumSignificantDigits,
}: Props) {
  const decimalRef = useRef(PERIOD);
  const [maskedValue, setMaskedValue] = useState('0');
  const numberFormat = useMemo(
    () =>
      currencyFormatter({
        currency,
        locale,
        minimumIntegerDigits,
        minimumFractionDigits,
        maximumFractionDigits,
        minimumSignificantDigits,
        maximumSignificantDigits,
      }),
    [
      currency,
      locale,
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
    ]
  );

  function inputOnChange(value: any) {
    setMaskedValue(value);
  }

  function inputOnBlur() {
    const regex = new RegExp(`[^0-9\\-${decimalRef.current}]`, 'gi');

    let value = parseFloat(maskedValue.replace(regex, '')) || 0;

    if (min != null) {
      value = Math.max(min / CURRENCY_CONVERSION, value);
    }

    if (max != null) {
      value = Math.min(max / CURRENCY_CONVERSION, value);
    }

    value *= CURRENCY_CONVERSION;

    if (onValueChange) {
      onValueChange(value);
    }

    const newMaskedValue = numberFormat(value / CURRENCY_CONVERSION);

    if (onChange) {
      onChange(newMaskedValue);
    }

    setMaskedValue(newMaskedValue);
  }

  useEffect(() => {
    setMaskedValue(numberFormat((value || 0) / CURRENCY_CONVERSION));
  }, [value]);

  useEffect(() => {
    // no easy way to know if we use decimals or commas for numbers other
    // than this test.
    const testString = currencyFormatter({ currency, locale })(testNumber);

    // if a period appears before a comma, then commas are used as decimal
    // places and we want to stripe out periods.  or vice versa.
    // i.e. $123.456,79 strip out periods.  $123,456.79 strip out commas
    decimalRef.current =
      testString.indexOf(PERIOD) > testString.indexOf(COMMA) ? PERIOD : COMMA;

    setMaskedValue(numberFormat((value || 0) / CURRENCY_CONVERSION));
  }, [locale, currency]);

  return {
    value,
    maskedValue,
    inputOnChange,
    inputOnBlur,
  };
}
