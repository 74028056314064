import * as yup from 'yup';

export default yup.object().shape({
  name: yup
    .string()
    .label('Display name')
    .trim()
    .required()
    .min(1, 'Display name must be at least 1 character')
    .max(1024),
  description: yup.string().trim().max(4096).nullable(),
  email: yup.string().label('Support email').email().trim().nullable(),
  phone: yup.string().trim().nullable(),
  color: yup.string().trim().nullable(),
  backgroundColor: yup.string().trim().nullable(),
  backgroundImage: yup.string().trim().nullable(),
  image: yup.string().trim().nullable(),
  wordmark: yup.string().trim().nullable(),
  logo: yup.string().trim().nullable(),
  block: yup.object().nullable(),
  version: yup.number().min(0).max(1024).nullable(),
  renderer: yup.number().min(0).max(1024).nullable(),
  properties: yup.object().nullable(),
});
