export const enum HardwarePassError {
  Expired = 'HARDWARE_PASS_ERROR_EXPIRED',
  NotYetValid = 'HARDWARE_PASS_ERROR_NOT_YET_VALID',
  PassInvalid = 'HARDWARE_PASS_ERROR_PASS_INVALID',
  WrongLocation = 'HARDWARE_PASS_ERROR_WRONG_LOCATION',
  Invalid = 'HARDWARE_PASS_ERROR_INVALID',
}

export function getHardwarePassErrorLabel(
  passError: HardwarePassError
): string {
  switch (passError) {
    case HardwarePassError.Expired: {
      return 'shared.hardware.hardwarePassError.expired';
    }
    case HardwarePassError.NotYetValid: {
      return 'shared.hardware.hardwarePassError.notYetValid';
    }
    case HardwarePassError.PassInvalid: {
      return 'shared.hardware.hardwarePassError.passInvalid';
    }
    case HardwarePassError.WrongLocation: {
      return 'shared.hardware.hardwarePassError.wrongLocation';
    }
    default: {
      return 'shared.hardware.hardwarePassError.invalid';
    }
  }
}
