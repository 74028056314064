import React from 'react';

import cx from 'classnames';

import styles from './Panel.scss';

type MarginType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;

type Props = {
  children: React.ReactNode;
  className?: string;
  mt?: MarginType;
  mb?: MarginType;
  style?: React.CSSProperties;
};

export default function Panel({ style, className, mb, mt, children }: Props) {
  return (
    <div
      className={cx(styles.Panel, className)}
      style={style}
      data-margin-bottom={mb}
      data-margin-top={mt}
    >
      {children}
    </div>
  );
}
