import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import IconButton from 'components/general/IconButton';
import { ChipSelect, ChipStyle } from 'components/ads';
import { H5, H4, XS } from 'components/typography';
import Thumbnail from 'components/general/Thumbnail';

import { INVOICE_DATE } from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import getMediaUrl from 'lane-shared/helpers/formatters/mediaUrl';
import KeyCardActions from '../keyCardActions';
import { Table } from 'design-system-web';

import {
  CredentialGroup,
  ChannelKeycardData,
  CredentialGroupStatus,
  Credential,
  KeyCardStatus,
} from '../../../types/AccessControl';

import styles from './styles.scss';

type props = {
  keyCard: Partial<CredentialGroup>;
  keyCardChannelData: ChannelKeycardData;
};

export default function KeyCardDetails({ keyCard, keyCardChannelData }: props) {
  const { t } = useTranslation();
  const [keyCards, setKeyCards] = useState<Record<string, boolean>>({});
  const [keyCardStatus, setKeyCardStatus] = useState<Partial<KeyCardStatus>>(
    {}
  );
  const [keyCardData, setKeyCardData] = useState<Record<string, Credential[]>>(
    {}
  );

  useEffect(() => {
    if (keyCard?.credentialMappings) {
      handleKeyCardTable();
    }
  }, [keyCard]);

  const evaluateCredentialStatus = (status: string | undefined) => {
    const statusData = {
      color: ChipStyle.Grey,
      value:
        'web.admin.accessControl.manageUserAccess.credential.status.unknown',
    };
    switch (status) {
      case CredentialGroupStatus.ACTIVE:
        statusData.color = ChipStyle.Green;
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.active';
        break;
      case CredentialGroupStatus.ISSUING:
        statusData.color = ChipStyle.Yellow;
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.issuing';
        break;
      case CredentialGroupStatus.SUSPENDING:
        statusData.color = ChipStyle.Yellow;
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.suspending';
        break;
      case CredentialGroupStatus.SUSPENDED:
        statusData.color = ChipStyle.Yellow;
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.suspended';
        break;
      case CredentialGroupStatus.FAILED:
        statusData.color = ChipStyle.Red;
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.failed';
        break;
      case CredentialGroupStatus.RESUMING:
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.resuming';
        break;
      case CredentialGroupStatus.REVOKING:
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.revoking';
        break;
      case CredentialGroupStatus.REVOKED:
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.revoked';
        break;
      case CredentialGroupStatus.EXPIRED:
        statusData.value =
          'web.admin.accessControl.manageUserAccess.credential.status.expired';
        break;
    }
    return statusData;
  };

  const handleKeyCardTable = () => {
    const tableSwitch: any = {};
    const groupedCredentials = keyCard?.credentialMappings?.reduce(
      (accumulator: any, credential: Credential) => {
        const { vtsOrganizationId } = credential;
        if (!accumulator[vtsOrganizationId]) {
          accumulator[vtsOrganizationId] = [];
        }

        tableSwitch[vtsOrganizationId] = true;

        accumulator[vtsOrganizationId].push({
          cardNumber: credential.cardNumber,
          system: credential.provider,
          status: credential.status,
          activatedAt: credential.activatedAt,
          revokedAt: credential.revokedAt,
          activityLog: '',
        });
        return accumulator;
      },
      {}
    );

    setKeyCardData(groupedCredentials);

    if (!keyCards || !Object.keys(keyCards).length) {
      setKeyCards(tableSwitch);
    }

    const keyCardStatusData = evaluateCredentialStatus(keyCard?.status);
    setKeyCardStatus(keyCardStatusData);
  };

  const handleCredentialTable = (keycardId: string, isOpenAction: boolean) => {
    setKeyCards(prevKeyCards => ({
      ...prevKeyCards,
      [keycardId]: isOpenAction,
    }));
  };

  const getColumns = () => {
    return [
      {
        header: t(
          'web.admin.accessControl.manageUserAccess.table.credentialMapping.system'
        ),
        key: 'system',
      },
      {
        header: t(
          'web.admin.accessControl.manageUserAccess.table.credentialMapping.status'
        ),
        key: 'status',
        renderCell: (_: any, row: any) => {
          const { value, color } = evaluateCredentialStatus(row.status);
          return (
            <ChipSelect.NonInteractive size="sm" value={value} type={color} />
          );
        },
      },
      {
        header: t(
          'web.admin.accessControl.manageUserAccess.table.credentialMapping.activityLog'
        ),
        key: 'activityLog',
        renderCell: (_: any, row: any) => {
          const defaultActivity = '-';
          const activatedActivity = `${t(
            'web.admin.accessControl.manageUserAccess.table.credential.activated'
          )} ${dateFormatter(row?.activatedAt, INVOICE_DATE)}`;
          const revokedActivity = `${t(
            'web.admin.accessControl.manageUserAccess.table.credential.revoked'
          )}  ${dateFormatter(row?.revokedAt, INVOICE_DATE)}`;
          return (
            <div>
              {row?.activatedAt && <p>{activatedActivity}</p>}
              {row?.revokedAt && <p>{revokedActivity}</p>}
              {!row?.revokedAt && !row?.activatedAt && <p>{defaultActivity}</p>}
            </div>
          );
        },
      },
    ];
  };

  const tableTemplate = () => {
    return Object.keys(keyCardData).map((orgId: string, index) => (
      <div key={orgId}>
        <div
          tabIndex={index}
          role="button"
          onClick={() => handleCredentialTable(orgId, !keyCards?.[orgId])}
          className={styles.RightContainerCredentialMappingHeaderBox}
        >
          <div className={styles.RightContainerBoxContent}>
            <div className={styles.RightContainerCredentialMappingData}>
              {!keyCards?.[orgId] && (
                <IconButton
                  icon="chevron-right"
                  iconSet="FontAwesome"
                  type="far"
                  inverted
                />
              )}
              {keyCards?.[orgId] && (
                <IconButton
                  icon="chevron-down"
                  onClick={() => handleCredentialTable(orgId, false)}
                  iconSet="FontAwesome"
                  type="far"
                  inverted
                />
              )}
              <div className={styles.RightContainerChannelAvatar}>
                <Thumbnail
                  src={getMediaUrl(keyCardChannelData[orgId]?.logo)}
                  name={keyCard.name || ''}
                  highlight={false}
                  outline={false}
                />
              </div>
              <H5 className={styles.RightContainerCredentialMappingTitle}>
                {keyCardChannelData[orgId]?.name}
              </H5>
            </div>
            <div className={styles.RightContainerMappingRightShiftData}>
              <div className={styles.RightContainerCardNumber}>
                {t(
                  'web.admin.accessControl.manageUserAccess.credential.cardNumberText'
                )}
                {keyCardData?.[orgId]?.[0]?.cardNumber}
              </div>
            </div>
          </div>
        </div>
        {keyCards?.[orgId] && (
          <div
            className={`${styles.CredentialTable} ${styles.FontSizeManagedTable}`}
          >
            <Table columns={getColumns()} data={keyCardData?.[orgId]} />
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className={styles.UserAccessContentContainerRight}>
      <div className={cx(styles.UserAccessContentContainerColumn)}>
        <div className={styles.RightContainerHeader}>
          <H4 style={{ paddingBottom: '25px' }}>{keyCard.name}</H4>
          <div className={styles.RightContainerMappingRightShiftData}>
            <ChipSelect.NonInteractive
              size="sm"
              dataTestId={keyCard.id}
              value={keyCardStatus.value || ''}
              type={keyCardStatus.color || ChipStyle.Grey}
            />

            {(keyCard.status === CredentialGroupStatus.ACTIVE ||
              keyCard.status === CredentialGroupStatus.SUSPENDED) && (
              <KeyCardActions keyCard={keyCard as CredentialGroup} />
            )}
          </div>
        </div>
        <div className={styles.RightContainerCredentialGroupBox}>
          <XS className={styles.RightContainerCredentialMappingsCount}>
            {keyCard?.credentialMappings?.length}{' '}
            {keyCard?.credentialMappings?.length === 1
              ? t('web.admin.accessControl.manageUserAccess.credentialText')
              : t('web.admin.accessControl.manageUserAccess.credentialsText')}
          </XS>
        </div>
        {tableTemplate()}
      </div>
    </div>
  );
}
