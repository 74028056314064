import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { getPublicUser } from '../graphql/query';

export type PublicUserType = {
  _id: LaneType.UUID;
  _created: string;
  name: string;
  online: boolean;
  lastSeen: string;
  profile: {
    _id: LaneType.UUID;
    email: LaneType.Email | null;
    phone: LaneType.PhoneNumber | null;
    name: string;
    image: LaneType.UUID | null;
    logo: LaneType.UUID | null;
  };
  role: {
    _id: LaneType.UUID;
    name: string;
    description: string | null;
    groupRoleName: string;
  }[];
};

export default function usePublicUserQuery({
  userId,
}: {
  userId: LaneType.UUID | undefined | null;
}) {
  const { data, loading, error } = useQuery<{ user: PublicUserType }>(
    getPublicUser,
    {
      client: getClient(),
      skip: !userId,
      variables: {
        id: userId,
      },
    }
  );

  const user = data?.user;

  return {
    user,
    loading,
    error,
  };
}
