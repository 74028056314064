import React from 'react';

import { Route, RouteProps, Redirect } from 'react-router-dom';

import useRedirect from 'hooks/useRedirect';

type RedirectRouteProps = RouteProps & {
  component?: React.Component | React.FunctionComponent;
  redirectWhen: boolean;
  redirectTo: string;
};

const RedirectRoute: React.FC<RedirectRouteProps> = function ({
  component: Component,
  render,
  redirectWhen,
  redirectTo,
  ...otherProps
}: RedirectRouteProps) {
  const { redirectString, isUrl, isSafeRedirect } = useRedirect();

  return (
    <Route
      {...otherProps}
      render={props => {
        if (redirectWhen) {
          if (isSafeRedirect) {
            if (isUrl) {
              window.location.replace(redirectString!);
            } else if (redirectString) {
              return (
                <Redirect
                  to={{
                    pathname: redirectString,
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
          return (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: props.location },
              }}
            />
          );
        }

        if (render) {
          return render(props);
        }

        if (Component) {
          return <Component {...props} />;
        }

        return null;
      }}
    />
  );
};

export default RedirectRoute;
