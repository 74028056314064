import { useQuery } from '@apollo/client';
import { GroupRole } from 'graphql-query-contracts';

import {
  getChannelGroupRoles,
  queryChannelUsersByGroupRoles,
  queryChannelUsersByGroupRolesIncludeResidentInfo,
} from 'lane-shared/graphql/query';

import { DateTime } from 'luxon';

import { UserLoginType } from 'lane-shared/types/UserLogin';

export type AllUsersRes = {
  _id: string;
  _created: string | DateTime | Date | null | undefined;
  permissionGroups: string[];
  status: string;
  lastSeen: string;
  logins: UserLoginType[];
  name: string;
  person?: {
    units: {
      name: string;
      floors?: { name: string }[];
    }[];
    resident: { status: string; type: string };
  };
  company: {
    _id: string;
    name: string;
  }[];
};

type UserItem = {
  user: {
    _id: string;
    _created: string | DateTime | Date | null | undefined;
    lastSeen: string;
    logins: UserLoginType[];
    status: string;
    profile: {
      name: string;
    };
    person?: {
      units: {
        name: string;
        floors?: { name: string }[];
      }[];
      resident: { status: string; type: string };
    };
  };
  groupRole: {
    name: string;
  };
  relatedChannels: {
    _id: string;
    name: string;
  }[];
};

export const useAllUsers = (
  channelId: string,
  includeResidentInfo: boolean = false
) => {
  const {
    data: groupRoles,
    loading: groupRolesLoading,
  }: {
    data?: {
      channel: {
        _id: string;
        _created: any;
        groupRoles: GroupRole[];
      };
    };
    loading: boolean;
  } = useQuery(getChannelGroupRoles, {
    variables: {
      id: channelId,
    },
    fetchPolicy: 'network-only',
  });

  const groupRoleIds =
    groupRoles?.channel?.groupRoles.map((g: { _id: string }) => g._id) || [];

  const { data: users, loading: userLoading, refetch } = useQuery(
    includeResidentInfo
      ? queryChannelUsersByGroupRolesIncludeResidentInfo
      : queryChannelUsersByGroupRoles,
    {
      skip: !groupRoleIds || groupRoleIds.length === 0,
      variables: {
        groupRoleIds,
        search: {},
        channelId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const userIdPermissionGroupMap: { [key: string]: AllUsersRes } = {};

  users?.channelUsersByGroupRoles?.items?.map((item: UserItem) => {
    // If the userId isn't in the map, create a new object and add it
    if (!userIdPermissionGroupMap[item.user._id]) {
      userIdPermissionGroupMap[item.user._id] = {
        _id: item.user._id,
        person: item.user.person,
        permissionGroups: [item.groupRole.name] ?? [],
        status: item.user.status,
        lastSeen: item.user.lastSeen,
        _created: item.user._created,
        logins: item.user.logins,
        name: item.user.profile.name,
        company: item.relatedChannels.filter(c => c), // When a user doesn't have a related channel, the response is [null]
      };
      // Otherwise, push the permission group to the existing object
    } else {
      userIdPermissionGroupMap[item.user._id].permissionGroups.push(
        item.groupRole.name
      );
    }
  });

  const deduplicatedUsers = Object.values(userIdPermissionGroupMap);
  const loading = groupRolesLoading || userLoading;

  return {
    users: deduplicatedUsers,
    groupRoles,
    loading,
    refetch,
  };
};
