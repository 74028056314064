import React from 'react';
import { Button, M, Icon } from 'design-system-web';
import { useTranslation, Trans } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import styles from './NoAutopaymentOptionStyles.scss';

interface Props {
  openAutopayModal: () => void;
}

export function NoAutopaymentOption({ openAutopayModal }: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-row rounded-lg p-3 border bg-background-disabled border-background-disabled">
        <Icon
          className={styles.infoIcon}
          size="medium"
          set={ICON_SET_FONTAWESOME}
          name="infoCircle"
        />
        <Trans
          parent={M}
          i18nKey="abp.payment.subscription.paymentMethod.noAutoPay.autopayRequired"
        >
          <span className="font-semibold">Autopay required</span> - Add a credit
          card to automatically pay all invoices on your account, even those
          unrelated to this subscription.
        </Trans>
      </div>
      <Button size="large" onClick={openAutopayModal}>
        {t('abp.payment.subscription.paymentMethod.noAutoPay.setUpAutopay')}
      </Button>
    </div>
  );
}
