import React, { useCallback } from 'react';

import { Trans } from 'react-i18next';

import { LaneType } from 'common-types';
import { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';

import { ChipSelect } from 'components/ads';
import { Text } from 'components/typography';

import { useReservableStatus } from '../../hooks/useReservableStatus';
import { reservableStatusColorMapper } from '../../utilities/reservableStatusColorMapper';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  errorMessage:
    'web.admin.channel.reservableManagement.table.statusUpdateError',
  successMessage:
    'web.admin.channel.reservableManagement.table.statusUpdateSuccess',
};

export type ReservableStatusDropdownProps = {
  initialStatus: string;
  interactionId: LaneType.UUID;
  statusFeature?: StatusesFeatureProperties;
  isPastInteraction?: boolean;
  cancelableFeature?: CancelableFeatureProperties;
  onSuccess?: () => void;
};

export function ReservableStatusDropdown({
  initialStatus,
  statusFeature,
  interactionId,
  cancelableFeature,
  onSuccess,
  isPastInteraction = false,
}: ReservableStatusDropdownProps) {
  const onStatusUpdateSuccess = useCallback(() => {
    showSuccessToastMessage();

    if (onSuccess) {
      onSuccess();
    }
  }, [onSuccess]);
  const {
    currentStatus,
    allowedStatuses,
    updateInteractionStatus,
  } = useReservableStatus({
    initialStatus,
    statusFeature,
    onError,
    onSuccess: onStatusUpdateSuccess,
    isPastInteraction,
    cancelableFeature,
  });

  const handleStatusChange = (nextStatus: string) => {
    updateInteractionStatus(interactionId, nextStatus);
  };

  return (
    <div className={styles.StatusChipWrapper}>
      {allowedStatuses.length > 0 ? (
        <ChipSelect
          value={currentStatus}
          list={dropdownItemsMapper(allowedStatuses, currentStatus)}
          onChange={handleStatusChange}
        />
      ) : (
        <ChipSelect.NonInteractive
          value={currentStatus}
          type={reservableStatusColorMapper(currentStatus)}
        />
      )}
    </div>
  );
}

export const dropdownItemsMapper = (
  statuses: string[],
  currentStatus: string
) => {
  if (!statuses.includes(currentStatus)) {
    statuses.push(currentStatus);
  }

  return statuses.map((status: string) => ({
    value: status,
    label: status,
    type: reservableStatusColorMapper(status),
  }));
};

export const onError = () => {
  window.Toast.show(
    <Text>
      <Trans i18nKey={TRANSLATION_KEYS.errorMessage} />
    </Text>
  );
};

const showSuccessToastMessage = () => {
  window.Toast.show(
    <Text>
      <Trans i18nKey={TRANSLATION_KEYS.successMessage} />
    </Text>
  );
};
