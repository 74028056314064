import { DateTime } from 'luxon';

import { LONG_DATE_TIME, LONG_DATE_TIME_WITH_TZ } from '../constants/dates';
import parseDateTime from '../dates/parseDateTime';
import dateFormatter from './dateFormatter';

export default function longDateTimeZone(
  date: Date | string,
  timeZone?: string
): string {
  const dateTime = parseDateTime(date, timeZone);

  if (!dateTime) {
    return '';
  }

  if (dateTime.offset !== DateTime.local().offset) {
    return dateFormatter(date, LONG_DATE_TIME_WITH_TZ, timeZone);
  }

  return dateFormatter(date, LONG_DATE_TIME, timeZone);
}
