import React from 'react';

import cx from 'classnames';

import Toggle from 'components/form/Toggle';

import styles from './FeatureOptionToggle.scss';

type Props = {
  title: string;
  description?: string;
  onChange: (value: boolean) => void;
  isExpanded?: boolean;
  children?: React.ReactNode;
  className?: string;
  toggleClassName?: string;
  TooltipComponent?: React.ReactNode;
  disabled?: boolean;
  dataCy?: string;
  isAligned?: boolean;
};

export default function FeatureOptionToggle({
  title,
  description,
  onChange,
  isExpanded,
  children,
  className,
  toggleClassName,
  TooltipComponent,
  disabled,
  dataCy,
  isAligned,
  ...props
}: Props) {
  const getChildrenWrapper = () => {
    if (isAligned) {
      return (
        <div className={cx(styles.childrenContainer)}>
          <div className={cx(styles.whitespace)} />
          <fieldset>{children}</fieldset>
        </div>
      );
    }

    return <fieldset>{children}</fieldset>;
  };

  return (
    <div className={cx(styles.option, className)} {...props}>
      <Toggle
        className={cx(styles.toggle, toggleClassName)}
        text={title}
        description={description}
        value={Boolean(isExpanded)}
        onChange={onChange}
        TooltipComponent={TooltipComponent}
        disabled={disabled}
        dataCy={dataCy}
      />
      {isExpanded && getChildrenWrapper()}
    </div>
  );
}
