import React from 'react';

import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { RowData } from '../../utilities/bookingItemToRowDataMapper';

export type Props = {
  channelSlug?: string;
  content: RowData['content'];
};

function formatContentUrl(channelSlug: string, contentId: string) {
  const urlWithInfo = routes.channelAdminContent
    .replace(':id', channelSlug)
    .replace(':contentId', contentId);

  return urlWithInfo;
}

export function ContentNameCell({ channelSlug, content }: Props) {
  return channelSlug && content.id ? (
    <Link to={formatContentUrl(channelSlug, content.id)}>{content.name}</Link>
  ) : (
    <div />
  );
}
