import { createShapeFromProperties } from 'lane-shared/properties';

import definition from './definition';
import { fetchUserImageUrl } from './fetchUserImageUrl';
import * as requestHelpers from './requestHelpers';

export { definition, fetchUserImageUrl, requestHelpers };

export const validationShape = createShapeFromProperties(
  definition.interactionData
);
