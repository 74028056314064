import { ApolloError } from '@apollo/client';

import { NotLoggedInError } from 'activate-errors';
import isThisError from './isThisError';

export default function isNotLoggedInError(
  err: Error | ApolloError | undefined | null
): boolean {
  if (isThisError(err, NotLoggedInError)) {
    return true;
  }

  if ((err as any)?.graphQLErrors) {
    return (err as any).graphQLErrors.some(
      (inner: any) => inner.extensions?.code === 'UNAUTHENTICATED'
    );
  }

  return false;
}
