import gql from 'graphql-tag';

export const shopifyMultipassLogin = gql`
  query MultipassLoginUrl(
    $channelIntegrationId: UUID!
    $createLoginRecord: Boolean!
    $externalUrl: URL
  ) {
    me {
      shopifyMultipassLogin(
        channelIntegrationId: $channelIntegrationId
        createLoginRecord: $createLoginRecord
        externalUrl: $externalUrl
      ) {
        shopifyCustomerId
        shopifyStoreUrl
        loginUrl
        multipassId
      }
    }
  }
`;
