export function getAreWhitelabelUrlsVisible(whitelabel: any) {
  return (
    whitelabel?.privacyPolicyUrls?.length > 1 ||
    whitelabel?.termsAndConditionsUrls?.length > 1
  );
}

export function getIsAndBetweenWhitelabelUrls(whitelabel: any) {
  return (
    whitelabel?.privacyPolicyUrls?.length > 1 &&
    whitelabel?.termsAndConditionsUrls?.length > 1
  );
}

export function getSAfterApostrophe(whitelabel: any) {
  return whitelabel.name.slice(-1) === 's' ? '' : 's';
}
