import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/form';
import { QuantityFeatureProperties } from 'lane-shared/types/features/QuantityFeatureProperties';
import { FeatureRendererPropsType } from './FeatureRendererPropsType';
import { Checkbox } from 'design-system-web';
import { LaneType } from 'common-types/src/lib/__generated__/staticTypes';
import { useChannelAdminContext } from 'hooks';
import ChannelSelectorButton from 'components/lane/ChannelSelectorButton';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { TranslationKeys } from 'localization';

type QuantityAdvancedRuleProps = {
  quantityAllocation: LaneType.QuantityAllocations;
} & Pick<
  FeatureRendererPropsType<QuantityFeatureProperties>,
  'onFeatureUpdated' | 'contentFeature'
>;

const TRANSLATION_KEYS: { [key: string]: TranslationKeys } = Object.freeze({
  selectChannel:
    'web.admin.content.features.quantity.advancedRules.selectChannel',
  allotedUnits:
    'web.admin.content.features.quantity.advancedRules.allotedUnits',
  primaryLocationCheck:
    'web.admin.content.features.quantity.advancedRules.primaryLocationCheck',
  claimAllAvailableQuantityCheck:
    'web.admin.content.features.quantity.advancedRules.claimAllQuantityCheck',
  claimAllAvailableQuantityCheckDescription:
    'web.admin.content.features.quantity.advancedRules.claimAllQuantityCheck.description',
  claimAllAvailableQuantityCheckTooltip:
    'web.admin.content.features.quantity.advancedRules.claimAllQuantityCheck.tooltip',
});

export function QuantityAdvancedRule({
  contentFeature,
  onFeatureUpdated,
  quantityAllocation,
}: QuantityAdvancedRuleProps) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();

  const [
    allocationDetails,
    setAllocationDetails,
  ] = useState<LaneType.QuantityAllocations>(quantityAllocation);
  const [selectedChannel, setSelectedChannel] = useState(channel);

  useEffect(() => {
    onFeatureUpdated({ quantityAllocations: [allocationDetails] });
  }, [allocationDetails]);

  useEffect(() => {
    if (!isSelectedChannelTypeProperty) {
      setAllocationDetails({
        ...allocationDetails,
        forPrimaryChannelUsersOnly: false,
      });
    }
  }, [selectedChannel]);

  const handleAllocationUnitsChange = (inputUnits: number | string) => {
    let allocationUnits = Number(inputUnits);

    if (allocationUnits < 0) {
      allocationUnits = 0;
    } else if (allocationUnits > contentFeature.feature.total) {
      allocationUnits = contentFeature.feature.total;
    }

    setAllocationDetails({ ...allocationDetails, units: allocationUnits });
  };

  const handleChannelSelected = (channel: ChannelType) => {
    setSelectedChannel(channel);
    if (channel) {
      setAllocationDetails({
        ...allocationDetails,
        channelIds: [channel._id],
      });
    }
  };

  const isSelectedChannelTypeProperty = selectedChannel?.type === 'Property';

  return (
    <>
      <div className="flex flex-row w-full gap-3">
        <ChannelSelectorButton
          className="w-3/4"
          placeholder={TRANSLATION_KEYS.selectChannel}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          channelId={selectedChannel?._id}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(channel: any) => void' is not assignable to... Remove this comment to see the full error message
          onChannelSelected={handleChannelSelected}
        />
        <Input
          className="w-1/4"
          value={quantityAllocation.units}
          onChange={handleAllocationUnitsChange}
          type="number"
          label={TRANSLATION_KEYS.allotedUnits}
        />
      </div>
      <div className="flex flex-col mt-2">
        {isSelectedChannelTypeProperty && (
          <Checkbox
            text={t(TRANSLATION_KEYS.primaryLocationCheck)}
            selected={!!allocationDetails.forPrimaryChannelUsersOnly}
            value={allocationDetails.forPrimaryChannelUsersOnly}
            onChange={forPrimaryChannelUsersOnly => {
              setAllocationDetails({
                ...allocationDetails,
                forPrimaryChannelUsersOnly: !forPrimaryChannelUsersOnly,
              });
            }}
          />
        )}
        <Checkbox
          text={t(TRANSLATION_KEYS.claimAllAvailableQuantityCheck)}
          selected={!!allocationDetails.canClaimBeyondAllocatedUnits}
          description={t(
            TRANSLATION_KEYS.claimAllAvailableQuantityCheckDescription
          )}
          value={allocationDetails.canClaimBeyondAllocatedUnits}
          onChange={canClaimBeyondAllocatedUnits =>
            setAllocationDetails({
              ...allocationDetails,
              canClaimBeyondAllocatedUnits: !canClaimBeyondAllocatedUnits,
            })
          }
          tooltipNextToText
          TooltipComponent={t(
            TRANSLATION_KEYS.claimAllAvailableQuantityCheckTooltip
          )}
        />
      </div>
    </>
  );
}
