import * as yup from 'yup';

import { templateBaseLength } from './constants';

export default yup.object().shape({
  templateName: yup
    .string()
    .trim()
    .min(templateBaseLength.templateName.min)
    .max(templateBaseLength.templateName.max)
    .required(),
  notes: yup.string().trim().max(templateBaseLength.notes.max),
  type: yup.string().trim().required(),
});
