import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { parseDateTime } from 'lane-shared/helpers/dates';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  SHOPIFY_BURST_MAX_RANGE_SIZE,
  ShopifyChannelType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { MAX_DATE, MIN_DATE } from '../constants/unavailableDates';
import { SectionContent } from '../types/unavailableDates';
import getBurstContentTimeZone from './getBurstContentTimeZone';
import updateDayMap from './updateDayMap';

export default function handleUnavailableContentFeatureDates({
  sectionContent = [],
  minDate,
  maxDate,
  dayMap,
  ports,
  timeZone,
}: {
  sectionContent: SectionContent[];
  minDate: DateTime;
  maxDate: DateTime;
  timeZone: string;
  ports?: ShopifyChannelType[];
  dayMap: Map<string, LaneType.UUID[]>;
}) {
  sectionContent.forEach(sectionContent => {
    const contentTimeZone = getBurstContentTimeZone({
      channelId: sectionContent.content.channel._id,
      ports,
      timeZone,
    });

    const { timeAvailabilityFeature } = explodeFeatures(
      sectionContent.content.features
    );
    const dates = timeAvailabilityFeature?.unavailableDates || [];

    dates
      .filter(ud => ud?.dateRange?.startDate && ud?.dateRange?.endDate)
      .map(ud => ({
        startDate: parseDateTime(ud.dateRange.startDate, contentTimeZone)!,
        endDate: parseDateTime(ud.dateRange.endDate, contentTimeZone)!,
      }))
      .filter(
        dateRange =>
          dateRange.endDate > MIN_DATE &&
          dateRange.endDate <
            MAX_DATE.plus({ days: SHOPIFY_BURST_MAX_RANGE_SIZE })
      )
      .forEach(dateRange => {
        const newBookends = updateDayMap({
          contentId: sectionContent.content._id,
          dateRange,
          dayMap,
          maxDate,
          minDate,
        });
        minDate = newBookends.minDate;
        maxDate = newBookends.maxDate;
      });
  });
  return { minDate, maxDate, dayMap };
}
