import React, { ReactChild } from 'react';

import classNames from 'classnames';

import styles from './index.scss';

type ChipButtonProps<ValueType> = {
  value: ValueType;
  children: ReactChild;
  isChecked: boolean;
  onClick: (value: ValueType) => void;
};

export default function ChipButton<ValueType>({
  value,
  children,
  isChecked,
  onClick,
}: ChipButtonProps<ValueType>) {
  return (
    <button
      className={classNames({
        [styles.chipButton]: true,
        [styles.checked]: isChecked,
      })}
      onClick={() => onClick(value)}
      aria-pressed={isChecked}
    >
      {children}
    </button>
  );
}
