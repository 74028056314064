import { useRef, useState, useEffect } from 'react';

import { ApolloQueryResult } from '@apollo/client';

import { getClient } from '../apollo';
import { POLL_INTERVAL } from '../helpers/constants';

/**
 * apollo-client useLazyQuery with a poll does often not suit our use case.
 *
 * It can be hard to manage data and an error state with the mechanism of
 * watching for changes to happen on variables using use effect.
 *
 * We often want a poll with an explicit error handler and result handler.
 *
 * i.e. when we are doing a sequence of events that depend on each other
 * like useUserChannelsData.
 */
export default function usePollingQuery<T = any>({
  query,
  pollInterval = POLL_INTERVAL,
  fetchPolicy = 'network-only',
  onBeforeFetch,
  onAfterFetch,
  onError,
  shouldThrow = false,
}: {
  name?: string;
  query: any;
  pollInterval?: number;
  onError?: (error: Error) => void;
  shouldThrow?: boolean;
  onBeforeFetch?: () => Promise<any>;
  onAfterFetch?: (data: T) => void;
  fetchPolicy?:
    | 'network-only'
    | 'cache-first'
    | 'cache-only'
    | 'no-cache'
    | undefined;
}) {
  const statusRef = useRef<{
    loading: boolean;
    lastQuery: number;
    timeout: number | null;
    isActive: boolean;
    query: any;
    variables: any;
  }>({
    loading: false,
    isActive: false,
    lastQuery: Date.now(),
    timeout: null,
    variables: null,
    query,
  });

  statusRef.current.query = query;

  const [loading, _setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<any | null>(null);

  function clearPollingTimeout() {
    if (statusRef.current.timeout) {
      clearTimeout(statusRef.current.timeout);
      statusRef.current.timeout = null;
    }
  }

  async function fetch(
    _variables?: any,
    force: boolean = false
  ): Promise<ApolloQueryResult<unknown> | undefined> {
    let result;

    if (!force && statusRef.current.loading) {
      return result;
    }

    // stop any previous fetch that maybe setup.
    clearPollingTimeout();

    statusRef.current.isActive = true;

    // track when this query was made, its possible that queries
    // may return after the next one is called.  if so we will throw
    // away those results
    const thisQuery = Date.now();
    statusRef.current.lastQuery = thisQuery;
    setLoading(true);

    try {
      // use the variables passed in, or the ones in the ref.
      let variables = _variables || statusRef.current.variables;

      // caller may also pass in a promise to return new variables.
      if (onBeforeFetch) {
        const newVariables = await onBeforeFetch();

        if (newVariables) {
          variables = newVariables;
        }
      }

      statusRef.current.variables = variables;

      result = await getClient().query<T>({
        query: statusRef.current.query,
        variables,
        fetchPolicy,
      });

      if (thisQuery !== statusRef.current.lastQuery) {
        // these query results are too old, throw them away.
        return result;
      }

      // clear out any errors
      setError(null);

      if (!statusRef.current.isActive) {
        return result;
      }

      if (result.data) {
        setData(result.data);

        if (onAfterFetch) {
          onAfterFetch(result.data);
        }
      }

      // set timeout to fetch again.
      clearPollingTimeout();
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'number'.
      statusRef.current.timeout = setTimeout(() => fetch(), pollInterval);
      return result;
    } catch (err) {
      // try again sooner on error

      if (thisQuery !== statusRef.current.lastQuery) {
        // these query results are too old, throw them away.
        return result;
      }

      if (statusRef.current.isActive) {
        clearPollingTimeout();

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'number'.
        statusRef.current.timeout = setTimeout(
          () => fetch(_variables),
          pollInterval / 2
        );
      }

      if (onError) {
        onError(err);
      }

      setError(err);

      if (shouldThrow) {
        throw err;
      }

      return result;
    } finally {
      setLoading(false);
    }
  }

  function setLoading(value: boolean) {
    _setLoading(value);
    statusRef.current.loading = value;
  }

  function reset() {
    clearPollingTimeout();
    clearError();
    clearData();
    setLoading(false);

    statusRef.current.isActive = false;
  }

  function clearError() {
    setError(null);
  }

  function clearData() {
    setData(null);
  }

  function startPolling(variables?: any) {
    if (!statusRef.current.isActive) {
      statusRef.current.isActive = true;
      fetch(variables);
    }
  }

  function stopPolling() {
    clearPollingTimeout();
    statusRef.current.isActive = false;
  }

  useEffect(() => {
    return clearPollingTimeout;
  }, []);

  return {
    loading,
    error,
    data,
    reset,
    clearError,
    clearData,
    startPolling,
    stopPolling,
    fetch,
  };
}
