import gql from 'graphql-tag';

import { ContentTemplateFragment } from 'lane-shared/graphql/fragments';

export default gql`
  ${ContentTemplateFragment}

  query contentTemplatesOnLibraryQuery(
    $libraryId: UUID!
    $search: ContentTemplateSearch
    $pagination: PaginationInput
  ) {
    contentTemplatesOnLibrary(
      libraryId: $libraryId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        template {
          ...ContentTemplateFragment
        }
      }
    }
  }
`;
