import React from 'react';

import { useTranslation } from 'react-i18next';

import Typography from 'components/lds/Typography';

import styles from './ReceiptFooter.scss';

type Props = {
  channelEmail?: string;
  channelNumber?: string;
  address?: string;
  className?: string;
};

export default function ReceiptFooter({
  channelEmail,
  channelNumber,
  address,
  className,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className={styles.receiptFooter}>
        <div style={{ marginBottom: '1em' }}>
          {channelEmail && channelNumber ? (
            <Typography type="s" mode="primary">
              {t(
                'Issues with your order? Contact us at {{- channelEmail}} or {{- channelNumber}}.',
                {
                  channelEmail,
                  channelNumber,
                }
              )}
            </Typography>
          ) : null}

          {channelEmail && !channelNumber ? (
            <Typography type="s" mode="primary">
              {t('Issues with your order? Contact us at {{- channelEmail}}.', {
                channelEmail,
              })}
            </Typography>
          ) : null}

          {!channelEmail && channelNumber ? (
            <Typography type="s" mode="primary">
              {t('Issues with your order? Contact us at {{- channelNumber}}.', {
                channelNumber,
              })}
            </Typography>
          ) : null}
        </div>

        <Typography type="s" mode="primary">
          {address}
        </Typography>
      </div>
    </div>
  );
}
