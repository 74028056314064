import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { faDotCircle, faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccountContactPair } from 'lane-shared/types/Essensys';

import { XS } from 'components/typography';

import styles from './styles/LinkedAccounts.scss';

type Props = {
  onAddNewAccountClick: () => void;
  accountContactPair?: AccountContactPair;
  accountContactPairs?: AccountContactPair[];
  onAccountContactPairSelect: (accountContactPair: AccountContactPair) => void;
  className?: string;
};
export default function LinkedAccounts({
  accountContactPair,
  accountContactPairs,
  onAccountContactPairSelect,
  className,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div>
        <div className={cx(styles.list)}>
          {accountContactPairs?.map((contactPair, index) => {
            const selected =
              accountContactPair?.account.accountid ===
              contactPair.account.accountid;

            return (
              <div
                key={index}
                role="button"
                tabIndex={0}
                className={cx(styles.row)}
                onClick={() => onAccountContactPairSelect(contactPair)}
              >
                {selected ? (
                  <FontAwesomeIcon icon={faDotCircle} />
                ) : (
                  <FontAwesomeIcon icon={faCircle} />
                )}
                <div>
                  <span className={styles.clientName}>
                    {contactPair?.account.clientname}
                  </span>{' '}
                  <span>{contactPair?.account.outputvalue_clienttype}</span>
                  <div>{contactPair?.contact.emailaddress}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <XS variant="secondary">{t('Powered by Operate')}</XS>
    </div>
  );
}
