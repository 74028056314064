import { appUrl } from '../../../config';
import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';
import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'lane-shared/types/integrations/IntegrationEnums';

enum SAMLServiceProviderEntity {
  AngusAnywhere = 'AngusAnywhere',
  Datawatch = 'Datawatch',
  Splan = 'splan_vms',
  JPass = 'saml-brookfield',
}

enum SAMLServiceIDP {
  Lane = 'lane',
  Auth0 = 'auth0',
}

function constructFullSAMLUrl(integrationId: string) {
  return `${appUrl}/sso/saml2?integrationId=${integrationId}&ts=${Date.now()}`;
}

const SAMLIntegrationDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.SAML,
  allowsMultipleInstances: true,
  hasCustomUI: true,
  friendlyName: 'SAML: Lane As Identity Provider',
  getChannelIntegrationName(integrationData): string {
    return `SAML - ${integrationData.settings.pageTitle}`;
  },
  description:
    'Defines properties for a SAML integration using Lane as the identity provider.',
  category: IntegrationCategoryEnum.Service,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.WebView,
  settings: {},
  properties: {
    pageTitle: {
      friendlyName: 'Page Title',
      type: 'String',
      description: 'The title of the page',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    description: {
      friendlyName: 'Page Description',
      type: 'LongText',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    serviceProvider: {
      friendlyName: 'Service Provider',
      packagedType: PackagedTypeEnum.Dropdown,
      type: 'Option',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    serviceURL: {
      friendlyName: 'Service URL',
      type: 'Url',
      description: 'The SAML service provider service endpoint URL.',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    metadata: {
      friendlyName: 'Service Metadata',
      type: 'String',
      description: 'Custom metadata fields required by current provider.',
      validators: [],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    contentPage: {
      friendlyName: 'Content Page',
      type: 'Content',
      editable: false,
    },
  },
  mobilePermissions: [],
};

export {
  constructFullSAMLUrl,
  SAMLServiceProviderEntity,
  SAMLIntegrationDefinition,
  SAMLServiceIDP,
};
