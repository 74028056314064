import React from 'react';

import { ContentRenderer } from 'components';

import useContentQuery from 'lane-shared/hooks/useContentQuery';

import { Loading } from 'components/general';
import Button from 'components/general/Button';
import { Modal } from 'components/lds';

import styles from './RoomBookingModal.scss';

type Props = {
  contentId: string;
  onClose: () => void;
  selectedRoom?: any;
  handleRoomSelect?: (content: any | null) => void;
  readOnly?: boolean;
};

/**
 * Modal to preview a room before booking it for a Burst.
 */
export default function RoomBookingModal({
  contentId,
  onClose,
  selectedRoom,
  handleRoomSelect,
  readOnly = false,
}: Props) {
  const { content, loading } = useContentQuery({
    contentId,
    forPublic: true,
  });

  async function selectRoomHandler() {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleRoomSelect(content);
    onClose();
  }

  const renderText = (readOnly: any, isSelectedAlready: any) => {
    if (readOnly) {
      return 'Close';
    }
    if (isSelectedAlready) {
      return 'Unselect';
    }
    return 'Select';
  };

  const isSelectedAlready = selectedRoom?._id === content?._id;

  return (
    <Modal
      className={styles.modal}
      isOpen
      onClose={onClose}
      isCloseButtonHidden
      actions={
        <Button
            onClick={readOnly ? onClose : selectRoomHandler}
            variant={readOnly ? 'outlined' : 'contained'}
        >
            {renderText(readOnly, isSelectedAlready)}
          </Button>
      }
    >
      {loading && <Loading />}
      {!loading && content && (
        <ContentRenderer
          className={styles.content}
          content={content}
          forAdmin={false}
        />
      )}
    </Modal>
  );
}
