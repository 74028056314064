import React from 'react';

import cx from 'classnames';

import styles from './Badge.scss';

type OwnProps = {
  value: number | string | React.ReactNode;
  title?: string;
  color?: string;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof Badge.defaultProps;

function Badge({ className, style, value, title, color }: Props) {
  return (
    <div
      className={cx(styles.Badge, className)}
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      style={{ ...style, backgroundColor: color }}
    >
      <h1>{value}</h1>
      {title && <h2>{title}</h2>}
    </div>
  );
}

Badge.defaultProps = {
  color: null,
  title: '',
};

export default Badge;
