import gql from 'graphql-tag';

export default gql`
  mutation createOperateAccountAndContact(
    $email: String
    $companyName: String!
    $contentId: UUID!
  ) {
    essensys {
      createOperateAccountAndContact(
        email: $email
        companyName: $companyName
        contentId: $contentId
      ) {
        account
        contact
        clientType
      }
    }
  }
`;
