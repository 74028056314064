import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useChannelTheme } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { validateProperty } from 'lane-shared/validation';

import TabStrip, { TabItem } from 'components/general/TabStrip';
import ValidationMessage from 'components/general/ValidationMessage';

import FieldSecurity from './FieldSecurity';
import FieldType from './FieldType';
import FieldValidators from './FieldValidators';

import styles from './FieldEdit.scss';

interface TabTypes {
  field: TabItem;
  validation: TabItem;
  security: TabItem;
}

const tabs: TabTypes = {
  field: {
    label: 'web.admin.content.metatag.edit.fieldType.tabItem.field',
    value: 'Field',
  },
  validation: {
    label: 'web.admin.content.metatag.edit.fieldType.tabItem.validation',
    value: 'Validation',
  },
  security: {
    label: 'web.admin.content.metatag.edit.fieldType.tabItem.security',
    value: 'Security',
  },
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channel: ChannelType;
  user: UserType;
  contexts: any;
  field?: PropertyType;
  onFieldUpdated?: (field: any) => void;
  onValidation?: (errors: string[]) => void;
  definition?: Array<any>;
  forCreate?: boolean;
  showSecurity?: boolean;
  showKey?: boolean;
  showName?: boolean;
  showPlaceholder?: boolean;
  isArrayAvailable?: boolean;
};

export default function FieldEdit({
  channel,
  user,
  contexts,
  definition = [],
  forCreate = false,
  showSecurity = true,
  showKey = true,
  showName = true,
  showPlaceholder = true,
  isArrayAvailable = true,
  field,
  className,
  style,
  onFieldUpdated = () => {},
  onValidation = () => {},
}: Props) {
  // store a local state to not overwrite the object that was passed in
  const [_field, setField] = useState(field);
  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs.field);
  const [errors, setErrors] = useState<string[]>([]);
  const theme = useChannelTheme(channel);
  const { t } = useTranslation();

  useEffect(() => {
    // only update if this value is not the same as in current state.
    if (field !== _field) {
      setField(field);
    }
  }, [field]);

  function updateField(props: Partial<PropertyType>) {
    const update = {
      ..._field,
      ...props,
    };

    // if an _id is missing, set one now.
    if (forCreate && !update._id) {
      update._id = uuid();
    }

    setField(update as PropertyType);
    onFieldUpdated(update);
  }

  async function validate(update = _field) {
    const errors = [];

    // validate the field
    try {
      await validateProperty.validate(update, {
        abortEarly: false,
      });
    } catch (err) {
      err.inner.forEach((e: { message: string }) => errors.push(e.message));
    }

    if (update?.validators) {
      const duplicate = update.validators.find(v =>
        update.validators!.filter(v2 => v2 !== v).find(v2 => v2.name === v.name)
      );

      if (duplicate) {
        errors.push(
          t(
            'web.admin.content.metatag.tabItem.edit.fieldEditWindow.errors.duplicateValidator',
            {
              duplicateName: duplicate.name,
            }
          )
        );
      }
    }

    if (!update?.name) {
      errors.push(
        t(
          'web.admin.content.metatag.tabItem.edit.fieldEditWindow.errors.keyRequired'
        )
      );
    }

    if (forCreate && definition.some(f => f.name === update?.name)) {
      errors.push(
        t(
          'web.admin.content.metatag.tabItem.edit.fieldEditWindow.errors.keyExists',
          {
            keyName: update?.name,
          }
        )
      );
    }

    setErrors(errors);
    onValidation(errors);
  }
  useEffect(() => {
    validate();
  }, [_field]);

  if (!_field) {
    return null;
  }

  const omitSecurityTab = () => {
    const { security: _security, ...restOfTabs } = tabs;
    return restOfTabs;
  };

  return (
    <div className={cx(styles.FieldEdit, className)} style={style}>
      <TabStrip
        tabs={
          showSecurity ? Object.values(tabs) : Object.values(omitSecurityTab())
        }
        selected={selectedTab}
        onSelectTab={tab => setSelectedTab(tab)}
      />

      {selectedTab.value === tabs.field.value && (
        <FieldType
          theme={theme}
          contexts={contexts}
          field={_field}
          user={user}
          onFieldUpdated={updateField}
          showKey={showKey}
          showName={showName}
          showPlaceholder={showPlaceholder}
          isArrayAvailable={isArrayAvailable}
        />
      )}

      {selectedTab.value === tabs.validation.value && (
        <FieldValidators
          field={_field}
          onFieldUpdated={updateField}
          definition={definition}
        />
      )}

      {selectedTab.value === tabs.security.value && (
        <FieldSecurity
          field={_field}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: Partial<PropertyType<PropertyOptionT... Remove this comment to see the full error message
          onFieldUpdated={updateField}
          channel={channel}
        />
      )}
      <ValidationMessage errors={errors} />
    </div>
  );
}
