import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import {
  getChannelContent,
  searchContentInteractions,
} from 'lane-shared/graphql/query';

import { getClient } from '../../../apollo';

export default function useShopifyRsvpContentsQuery(channelId: string) {
  const {
    data: channelContentData,
    loading: loadingChannelContentData,
    refetch: refetchChannelContentData,
  } = useQuery(getChannelContent, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables: {
      id: channelId,
      search: {
        tags: { all: ['Burst'] },
      },
    },
  });
  const rsvpContents = channelContentData?.channel?.content || [];

  const {
    data: rsvpInteractionsData,
    loading: loadingRsvpInteractionsData,
    refetch: refetchRsvpInteractionsData,
  } = useQuery(searchContentInteractions, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables: {
      search: {
        content: {
          tags: { all: ['Burst'] },
        },
      },
      pagination: {
        start: 0,
        perPage: 1000,
      },
    },
  });
  const rsvpInteractions =
    rsvpInteractionsData?.me?.contentInteractions?.items || [];

  const rsvpContentsWithInteractions = useMemo(
    () =>
      rsvpContents
        .map((rsvpContent: any) => ({
          content: rsvpContent,

          interaction: rsvpInteractions.find(
            (rsvpInteraction: any) =>
              rsvpInteraction.contentData._id === rsvpContent._id
          ),
        }))
        .sort((a: any, _b: any) => (a.interaction ? -1 : 1)),
    [rsvpContents, rsvpInteractions]
  );

  function refetch() {
    refetchChannelContentData();
    refetchRsvpInteractionsData();
  }

  return {
    refetch,
    loading: loadingChannelContentData || loadingRsvpInteractionsData,
    rsvpContentsWithInteractions,
  };
}
