import { useQuery } from '@apollo/client';

import { OrganizationSettings } from 'lane-shared/domains/visitorManagement/types/OrganizationSettingsType';

import { getVisitorManagementOrganizationSettings } from '../../../graphql/visitorManagement';

export function useOrganizationSettingsData(channelId: string) {
  const { data, refetch, loading } = useQuery(
    getVisitorManagementOrganizationSettings,
    {
      variables: {
        channelId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const organizationSettings: OrganizationSettings =
    data?.visitorOrganizationSettings;

  return {
    organizationSettings,
    refetch,
    loading,
  };
}

export const DefaultOrganizationSettingsData = {
  visitorEmailOnCreate: true,
  visitorEmailOnUpdate: true,
  visitorEmailOnCancel: true,
  hostEmailOnConfirm: true,
  hostEmailOnUpdate: true,
  hostEmailOnCancel: true,
  hostEmailOnCheckIn: true,
  hostNotificationOnCheckIn: true,
  visitorSmsNotificationOnCreate: true,
  bufferTime: 900,
};
