import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';

const ControlByWebDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.ControlByWeb,
  hasCustomUI: true,
  friendlyName: 'ControlByWeb',
  description: `Defines the properties required for an ControlByWeb integration.`,
  category: IntegrationCategoryEnum.IOT,
  platforms: [PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    username: {
      friendlyName: 'Username',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    password: {
      friendlyName: 'Password',
      type: 'Password',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    account_id: {
      friendlyName: 'Account ID',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    enablePrefix: {
      friendlyName: 'Enable Prefix',
      type: 'Boolean',
      default: false,
    },
    prefix_filter: {
      friendlyName: 'Prefix Filter',
      type: 'String',
      validators: [],
    },
  },
};

export default ControlByWebDefinition;
