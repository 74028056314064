import React, { useEffect, useState } from 'react';

import {
  BurstDraftInteractionData,
  ShopifyBurstInteractionType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { Modal } from 'components/lds';

import BurstEditDate from './BurstEditDate';
import BurstEditName from './BurstEditName';
import BurstEditPort from './BurstEditPort';
import BurstEditRoom from './BurstEditRoom';

type Props = {
  burstData: ShopifyBurstInteractionType;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  onClose: () => void;
  editTarget: Partial<keyof ShopifyBurstInteractionType> | null;
};

const TARGETS_WITH_CLOSE_ICON = ['name', 'roomId', 'port'];

export default function BurstEditAdminModal({
  onDataUpdated,
  burstData,
  onClose,
  editTarget,
}: Props) {
  const [showCloseIcon, setCloseIconVisibility] = useState(false);

  useEffect(() => {
    setCloseIconVisibility(
      !!editTarget && TARGETS_WITH_CLOSE_ICON.includes(editTarget)
    );
  }, [editTarget]);

  return (
    <Modal
      isOpen={!!editTarget}
      onClose={onClose}
      disableContentPadding
      isCloseButtonHidden={!showCloseIcon}
      size="large"
    >
      {editTarget === 'dateRange' ? (
        <BurstEditDate
          onDataUpdated={onDataUpdated}
          burstData={burstData}
          onClose={onClose}
        />
      ) : null}
      {editTarget === 'name' ? (
        <BurstEditName
          onDataUpdated={onDataUpdated}
          burstData={burstData}
          onClose={onClose}
        />
      ) : null}
      {editTarget === 'roomId' ? (
        <BurstEditRoom
          onDataUpdated={onDataUpdated}
          burstData={burstData}
          onClose={onClose}
        />
      ) : null}
      {editTarget === 'port' ? (
        <BurstEditPort
          onDataUpdated={onDataUpdated}
          burstData={burstData}
          onClose={onClose}
        />
      ) : null}
    </Modal>
  );
}
