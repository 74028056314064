import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import useShopifyRsvpContext from 'lane-shared/hooks/integrations/shopify/useShopifyRsvpContext';
import useShopifyRsvpInteraction from 'lane-shared/hooks/integrations/shopify/useShopifyRsvpInteraction';
import useFlag from 'lane-shared/hooks/useFlag';
import {
  rsvpAttendNote,
  rsvpAttendTitle,
  ShopifyBurstRsvpEntryTimeValidEnum,
  ShopifyRsvpContentStateType,
  ShopifyRsvpStatusEnum,
  specialRequestPlaceholder,
  specialRequestTitle,
} from 'lane-shared/types/integrations/ShopifyRsvp';

import { TextArea, Checkbox } from 'components/form';
import { Button, ButtonStrip } from 'components/general';
import RsvpReadOnlyView from 'components/integrations/ShopifyRsvp/components/RsvpReadOnlyView';
import { Flex } from 'components/layout';
import { Modal } from 'components/lds';
import { H5, M } from 'components/typography';

import BurstInteractionHeader from '../../ShopifyBurst/components/BurstInteractionHeader';
import useIsAdminView from 'hooks/useIsAdminView';

import styles from './BurstRsvp.scss';

type Answer = 'yes' | 'no';

type Props = {
  content: {
    state: {
      _ShopifyBurst: ShopifyRsvpContentStateType;
    };
  };
};

export default function BurstRsvp({ content }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isAdminView] = useIsAdminView();
  const [attendanceAnswer, setAttendanceAnswer] = useState<Answer>('yes');
  const [hasSpeicialRequest, setHasSpeicialRequest] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [specialRequest, setSpecialRequest] = useState<string>('');
  const { primaryChannel } = useContext(ChannelsContext);
  const { submitRsvpInteraction } = useShopifyRsvpInteraction();
  const {
    rsvpContent,
    rsvpUserContentInteraction,
    refetchRsvpInteraction,
    rsvpSubmitOnBehalfOfUser,
    rsvpContentLoading,
    entryTimeValid,
  } = useShopifyRsvpContext();

  const allowRsvpUpdateFeatureFlag = useFlag('allow-rsvp-update', true);

  const burstData = content.state._ShopifyBurst;

  const awaitingConfirmation = !rsvpUserContentInteraction;

  const isInputDisabled = !awaitingConfirmation && !allowRsvpUpdateFeatureFlag;

  const rsvpClosed =
    entryTimeValid === ShopifyBurstRsvpEntryTimeValidEnum.Invalid;

  async function handleSubmitAnswer() {
    try {
      await submitRsvpInteraction({
        isAttending: Boolean(attendanceAnswer === 'yes'),
        specialRequests: specialRequest,
        isComplyRegulations: true,
        rsvpUserContentInteraction,
        rsvpContent: rsvpContent!,
      });

      if (rsvpSubmitOnBehalfOfUser) {
        history.push(
          routes.channelAdminContentInteractions
            .replace(':id', primaryChannel!._id)
            .replace(':contentId', rsvpContent!._id)
        );

        window.Toast.show('Successfully submitted.');
        return;
      }

      await refetchRsvpInteraction();
      window.scrollTo(0, 0);
      window.Toast.show('Your response has been submitted.');
    } catch (err) {
      await window.Alert.alert({
        title: 'Error submitting rsvp status',
        error: err.message.includes('UCI01')
          ? t('The invitation has expired, please contact the Burst Lead.')
          : err.message,
      });
    } finally {
      setIsOpen(false);
    }
  }

  function getHeaderCopy(): {
    title: string;
    header: string;
    helperText: string;
  } {
    if (rsvpUserContentInteraction && !isAdminView) {
      if (
        rsvpUserContentInteraction.status === ShopifyRsvpStatusEnum.Accepted
      ) {
        return {
          title: '',
          header: 'See you soon!',
          helperText: 'To contact support, see the FAQ.',
        };
      }

      if (
        rsvpUserContentInteraction.status === ShopifyRsvpStatusEnum.Declined
      ) {
        return {
          title: '',
          header: "We'll miss you",
          helperText: 'To contact support, see the FAQ.',
        };
      }
    }

    return {
      title: 'RSVP',
      header: burstData.name,
      helperText: '',
    };
  }

  return (
    <Flex direction="column">
      <BurstInteractionHeader
        title={getHeaderCopy().title}
        header={getHeaderCopy().header}
        helperText={getHeaderCopy().helperText}
        status={!isAdminView && rsvpUserContentInteraction?.status}
        onClose={() => history.push(routes.home)}
      />
      <Flex
        p={[0, 10, 9, 10]}
        direction="column"
        gap={6}
        className={styles.rsvpContent}
      >
        <RsvpReadOnlyView
          mb={0}
          burstData={burstData}
          rsvpData={rsvpUserContentInteraction?.data?._ShopifyRsvp}
        />
        {rsvpClosed && !isAdminView ? (
          <Flex direction="column" align="center" gap={4}>
            <H5 bold>RSVP is closed for this Burst.</H5>
          </Flex>
        ) : (
          <Flex direction="column">
            <H5 mb={4}>{rsvpAttendTitle}</H5>
            <M mb={4} className={styles.helperText}>
              {rsvpAttendNote}
            </M>
            <ButtonStrip
              className={styles.buttonStrip}
              selected={attendanceAnswer}
              onClick={(answer: string) =>
                setAttendanceAnswer(answer as Answer)
              }
              buttons={[
                {
                  value: 'yes',
                  name: 'Yes',
                },
                {
                  value: 'no',
                  name: 'No',
                },
              ]}
            />
            {attendanceAnswer === 'yes' && (
              <Flex direction="column">
                <Checkbox
                  text={specialRequestTitle}
                  value={hasSpeicialRequest}
                  selected={hasSpeicialRequest}
                  onChange={() => {
                    setHasSpeicialRequest(!hasSpeicialRequest);
                  }}
                  className={styles.checkBox}
                />
                {hasSpeicialRequest && (
                  <TextArea
                    containerClassName={styles.textArea}
                    placeholder={specialRequestPlaceholder}
                    value={specialRequest}
                    onChange={setSpecialRequest}
                    maxLength={400}
                    showLengthIndicator
                    minRows={2}
                  />
                )}
              </Flex>
            )}
            <Flex justify="center">
              <Button
                variant="contained"
                onClick={() => setIsOpen(true)}
                className={styles.submitButton}
                disabled={isInputDisabled}
              >
                {awaitingConfirmation ||
                allowRsvpUpdateFeatureFlag ||
                !isAdminView
                  ? 'Submit'
                  : 'Save changes'}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
      {isOpen && (
        <Modal
          title="Submit Your Response?"
          isOpen
          onClose={() => setIsOpen(false)}
          actions={
            <>
              <Button
                onClick={() => setIsOpen(false)}
                variant="outlined"
                className={styles.cancelModal}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmitAnswer}
                variant="contained"
                className={styles.submitModal}
                disabled={rsvpContentLoading}
              >
                Submit
              </Button>
            </>
          }
        >
          <Flex direction="column">
            <M mb={6}>
              If you need to change your RSVP, you can always come back and
              update your submission.
            </M>
          </Flex>
        </Modal>
      )}
    </Flex>
  );
}
