export enum HardwareThermalState {
  INVALID = 'HARDWARE_THERMAL_STATE_INVALID',
  NOMINAL = 'HARDWARE_THERMAL_STATE_NOMINAL',
  FAIR = 'HARDWARE_THERMAL_STATE_FAIR',
  SERIOUS = 'HARDWARE_THERMAL_STATE_SERIOUS',
  CRITICAL = 'HARDWARE_THERMAL_STATE_CRITICAL',
}

export const translateHardwareThermalState = {
  [HardwareThermalState.INVALID]: 'shared.hardware.device.thermalState.invalid',
  [HardwareThermalState.NOMINAL]: 'shared.hardware.device.thermalState.nominal',
  [HardwareThermalState.FAIR]: 'shared.hardware.device.thermalState.fair',
  [HardwareThermalState.SERIOUS]: 'shared.hardware.device.thermalState.serious',
  [HardwareThermalState.CRITICAL]:
    'shared.hardware.device.thermalState.critical',
};
