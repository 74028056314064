import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import './types/CancelableFeatureRule';

const Cancelable: FeatureDefinition = {
  name: FeatureNameEnum.Cancelable,
  friendlyName: 'web.content.feature.cancelable.friendlyName',
  requiresInteraction: true,
  description: `web.content.feature.cancelable.description`,
  properties: {
    rules: {
      friendlyName: 'web.content.feature.cancelable.rules.friendlyName',
      description: 'web.content.feature.cancelable.rules.description',
      type: 'CancelableFeatureRule',
      isArray: true,
      default: [
        {
          allGroupRoles: true,
          anyTime: true,
          groupRoles: null,
          requiresReason: false,
          units: 'hour',
          unitsBefore: 0,
        },
      ],
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default Cancelable;
