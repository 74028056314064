import { TFunction } from 'i18next';
import { ContentType } from 'lane-shared/types/content/Content';
import { InteractionDataType } from 'lane-shared/types/server/WorkflowTypes';
import { SupportedLocaleEnum } from 'localization';

export function getContentDescription(
  locale: SupportedLocaleEnum,
  t: TFunction<'translation', undefined>,
  content?: ContentType,
  interaction?: InteractionDataType
) {
  const reservation = interaction?.features?.Reservable?.reservation;

  const startDate = reservation?.startDate;
  const endDate = reservation?.endDate;

  const sameDate =
    startDate?.getDate() === endDate?.getDate() &&
    startDate?.getMonth() === endDate?.getMonth() &&
    startDate?.getFullYear() === endDate?.getFullYear();

  const startDateString = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(reservation?.startDate);

  let startTimeString = new Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(reservation?.startDate);

  if (startTimeString.startsWith('0')) {
    startTimeString = startTimeString.slice(1);
  }

  let endDateString = '';
  let endTimeString = '';
  if (reservation?.endDate) {
    endDateString = new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(reservation?.endDate);

    endTimeString = new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(reservation?.endDate);

    if (endTimeString.startsWith('0')) {
      endTimeString = endTimeString.slice(1);
    }
  }

  const dateString = sameDate
    ? `${startDateString} ${startTimeString} - ${endTimeString}`
    : `${startDateString} ${startTimeString} - ${endDateString} ${endTimeString}`;

  const quantity = interaction?.features?.Quantity?.quantity;
  const quantityString = t('abp.payment.orderSummary.quantity', { quantity });

  let contentDescription = '';
  if (reservation) {
    contentDescription = dateString;
  } else if (quantity) {
    contentDescription = quantityString;
  } else {
    contentDescription = content?.description || '';
  }
  return contentDescription;
}
