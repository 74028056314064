import React, { CSSProperties, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ColumnFiltersState } from '@tanstack/react-table';

import {
  Table,
  PageSizeType,
  Sort,
  NativeFilterTypes,
} from 'design-system-web';
import { Text } from 'components/typography';

import { useDownloadCSVExport } from './hooks/useDownloadCSVExport';
import { useGetReservableBookings } from '../../hooks/useGetReservableBookings';
import type { DateRange } from '../../hooks/useGetReservableBookings';
import { useRowActions } from '../../hooks/useRowActions';
import { useColumnsHeaders } from './hooks/useColumnsHeaders';
import { useReservableTableViewQueryParams } from './hooks/useReservableTableViewQueryParams';
import {
  RowData,
  bookingItemToRowDataMapper,
} from '../../utilities/bookingItemToRowDataMapper';
import { ContentNameCell } from '../ContentNameCell';
import { ReservableStatusDropdown } from '../ReservableStatusDropdown/ReservableStatusDropdown';
import { useExportOptions } from './useExportOptions';

const TRANSLATION_KEYS = {
  serverError: 'web.admin.channel.reservableManagement.table.serverError',
  channelError: 'web.admin.channel.reservableManagement.table.channelError',
  emptyMessage: 'web.admin.channel.reservableManagement.table.emptyMessage',
  dateRangeFilterLabel:
    'web.admin.channel.reservableManagement.table.dateRangeFilterLabel',
};

const DEFAULT_PAGE_SIZE: PageSizeType = 25;
const DEFAULT_PAGE_START = 0;
const DEFAULT_SORT: Sort = {
  id: 'startDate',
  direction: 'asc',
};

type Props = {
  channelId: string;
  channelTimeZone: string;
};
const DATE_RANGE_FILTER_ID = 'dateFilter';

function isDateRange(filterValue: any): filterValue is DateRange {
  return (
    filterValue &&
    typeof filterValue === 'object' &&
    'startDate' in filterValue &&
    'endDate' in filterValue
  );
}

function getDateRange(filters: ColumnFiltersState) {
  const defaultDateRange = {
    startDate: DateTime.now().startOf('day').toJSDate(),
    endDate: DateTime.now().endOf('day').plus({ day: 30 }).toJSDate(),
  };
  const dateRangeFilter = filters.find(
    filter => filter.id === DATE_RANGE_FILTER_ID
  );

  if (dateRangeFilter && isDateRange(dateRangeFilter.value)) {
    return {
      startDate: dateRangeFilter.value.startDate,
      endDate: dateRangeFilter.value.endDate,
    };
  }

  return defaultDateRange;
}

const noteStyles = {
  width: '220px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as CSSProperties;

export function ReservableTableView({ channelId, channelTimeZone }: Props) {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const { t } = useTranslation();
  const {
    bookings,
    totalBookings,
    isLoading,
    error: getBookingsError,
    fetchBookings,
  } = useGetReservableBookings({
    channelId,
  });
  const { downloadCSVExport, error: downloadCSVError } = useDownloadCSVExport(
    channelId
  );
  const {
    initialQueryParams,
    updateQueryParams,
  } = useReservableTableViewQueryParams();
  const hasInitialQueryParamsLoaded = initialQueryParams !== null;
  const { columns } = useColumnsHeaders({
    channelTimeZone,
    noteStyles,
    statusRenderer: ReservableStatusDropdown,
    contentNameCellRenderer: ContentNameCell,
  });
  const { rowActions, error: rowActionError } = useRowActions();
  const exportOptions = useExportOptions(() => {
    downloadCSVExport({ keyword, dateRange: getDateRange(filters) });
  });

  useEffect(() => {
    if (!hasInitialQueryParamsLoaded) {
      return;
    }

    const { startDate, endDate } = getDateRange(filters);
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();

    updateQueryParams({
      search: keyword,
      startDate: startDateISO,
      endDate: endDateISO,
    });
  }, [hasInitialQueryParamsLoaded, updateQueryParams, filters, keyword]);

  useEffect(() => {
    if (!hasInitialQueryParamsLoaded) {
      return;
    }

    const { search, startDate, endDate } = initialQueryParams;

    if (search) {
      setKeyword(search);
    }

    if (startDate && endDate) {
      setFilters([
        {
          id: DATE_RANGE_FILTER_ID,
          value: {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          },
        },
      ]);
    }
  }, [hasInitialQueryParamsLoaded, initialQueryParams]);

  useEffect(() => {
    fetchBookings({
      keyword,
      dateRange: getDateRange(filters),
      pagination: { start: page * perPage, perPage },
      sort,
    });
  }, [page, perPage, keyword, filters, sort]);

  if (getBookingsError || downloadCSVError) {
    window.Toast.show(<Text>{t(TRANSLATION_KEYS.serverError)}</Text>);
    return null;
  }

  if (rowActionError) {
    window.Toast.show(<Text>{t(TRANSLATION_KEYS.channelError)}</Text>);
    return null;
  }

  const handleKeywordFilterChange = (keyword: string) => {
    setKeyword(keyword);
    setPage(DEFAULT_PAGE_START);
  };

  const handleFilterChange = (filters: ColumnFiltersState) => {
    setFilters(filters);
    setPage(DEFAULT_PAGE_START);
  };

  const getEmptyMessage = () => {
    const { startDate, endDate } = getDateRange(filters);
    const format = (date: Date) =>
      DateTime.fromJSDate(date).toFormat('MMMM d, yyyy');

    return t(TRANSLATION_KEYS.emptyMessage, {
      startDate: format(startDate),
      endDate: format(endDate),
    });
  };

  if (!hasInitialQueryParamsLoaded) {
    return null;
  }

  return (
    <Table<RowData>
      isLoading={isLoading}
      columns={columns}
      data={bookingItemToRowDataMapper(t, channelTimeZone, bookings)}
      pagination="server"
      onPageChange={setPage}
      onPageSizeChange={setPerPage}
      page={page}
      pageSize={perPage}
      totalRows={totalBookings}
      rowActions={rowActions}
      hasKeywordFilter
      keywordFilter={initialQueryParams.search || ''}
      activeFilters={
        initialQueryParams.startDate && initialQueryParams.endDate
          ? [
              {
                id: DATE_RANGE_FILTER_ID,
                value: {
                  startDate: new Date(initialQueryParams.startDate),
                  endDate: new Date(initialQueryParams.endDate),
                },
              },
            ]
          : []
      }
      onKeywordFilterChange={handleKeywordFilterChange}
      onFilterChange={handleFilterChange}
      emptyMessage={getEmptyMessage()}
      exportOptions={exportOptions}
      tableKey="reservableManagement.reservableTable"
      filters={[
        {
          key: DATE_RANGE_FILTER_ID,
          type: NativeFilterTypes.DateRange,
          label: t(TRANSLATION_KEYS.dateRangeFilterLabel),
          isPromoted: true,
        },
      ]}
      onSortChange={setSort}
      sorting={sort}
    />
  );
}
