import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';

import { S } from 'components/typography';

import styles from './Alert.scss';

export enum AlertType {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

type Props = {
  type: AlertType | `${AlertType}`;
  children: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const iconName: { [key in AlertType]: string } = {
  [AlertType.error]: 'times-circle',
  [AlertType.warning]: 'exclamation-triangle',
  [AlertType.success]: 'check-circle',
  [AlertType.info]: 'info-circle',
};

export default function Alert({
  type,
  fullWidth = false,
  children,
  className,
  style,
}: Props) {
  return (
    <div
      data-cy="alert"
      className={cx(
        styles.alert,
        fullWidth && styles.fullWidth,
        styles[type],
        className
      )}
      style={style}
    >
      <div className={cx(styles.iconWrapper, styles[type])}>
        <Icon
          name={iconName[type]}
          className={cx(styles.icon)}
          set={ICON_SET_FONTAWESOME}
          type={FONT_AWESOME_REGULAR}
        />
      </div>
      <div className={cx(styles.content)}>
        {typeof children === 'string' ? <S>{children}</S> : children}
      </div>
    </div>
  );
}
