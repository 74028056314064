function numberToPx(value: any) {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
}

const handlers = {
  width: numberToPx,
  height: numberToPx,
  borderRadius: numberToPx,
  borderWidth: numberToPx,
  backgroundSize: numberToPx,
  padding: numberToPx,
  paddingTop: numberToPx,
  paddingRight: numberToPx,
  paddingBottom: numberToPx,
  paddingLeft: numberToPx,
  fontSize: numberToPx,
  letterSpacing: numberToPx,
  lineHeight: numberToPx,
  iconPadding: numberToPx,
  textPadding: numberToPx,
};

export default function convertMjmlStyleProps(props: any) {
  const style = {};

  Object.keys(props).forEach(key => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (handlers[key]) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      style[key] = handlers[key](props[key]);
      return;
    }

    if (key === 'backgroundUrl') {
      (style as any).backgroundImage = `url(${props[key]})`;
      return;
    }

    if (key === 'innerPadding') {
      (style as any).padding = handlers.padding(props[key]);
      return;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    style[key] = props[key];
  });

  return style;
}
