import React from 'react';

import { useTranslation } from 'react-i18next';

import ReservableFeatureSlider from './ReservableFeatureSlider';
import { MAX_QUANTITY } from './reservableFeatureValues';

const TRANSLATION_KEYS = {
  capacity: 'web.content.features.reservable.ReservableTabQuantities.capacity',
  capacityDescription:
    'web.content.features.reservable.ReservableTabQuantities.capacity.description',
  capacityPerUser:
    'web.content.features.reservable.ReservableTabQuantities.capacityPerUser',
  capacityPerUserDescription:
    'web.content.features.reservable.ReservableTabQuantities.capacityPerUser.description',
};

export default function ReservableTabQuantities({
  feature,
  settings,
  onFeatureUpdated,
}: any) {
  const { t } = useTranslation();

  function enforceQuantity(maxQuantityPerSlot: any) {
    const obj = {
      maxQuantityPerSlot,
      maxQuantityPerSlotPerUser: Math.min(
        Number(maxQuantityPerSlot),
        settings.maxQuantityPerSlotPerUser
      ),
      bufferTime: {
        ...(settings?.bufferTime || {}),
        isEnabled:
          maxQuantityPerSlot > 1 ? false : settings?.bufferTime?.isEnabled,
      },
    };
    onFeatureUpdated({ ...obj });
  }

  return (
    <>
      <ReservableFeatureSlider
        TooltipComponent={t(feature.properties.maxQuantityPerSlot.description)}
        label={t(TRANSLATION_KEYS.capacity)}
        description={t(TRANSLATION_KEYS.capacityDescription)}
        onChange={enforceQuantity}
        min={1}
        max={MAX_QUANTITY}
        value={Number(settings.maxQuantityPerSlot)}
      />

      <ReservableFeatureSlider
        TooltipComponent={t(
          feature.properties.maxQuantityPerSlotPerUser.description
        )}
        disabled={Number(settings.maxQuantityPerSlot) === 1}
        label={t(TRANSLATION_KEYS.capacityPerUser)}
        description={t(TRANSLATION_KEYS.capacityPerUserDescription)}
        onChange={maxQuantityPerSlotPerUser =>
          onFeatureUpdated({ maxQuantityPerSlotPerUser })
        }
        min={1}
        max={settings.maxQuantityPerSlot}
        value={Number(settings.maxQuantityPerSlotPerUser)}
      />
    </>
  );
}
