import React from 'react';

import { OrderSummaryWithProducts } from 'lane-shared/domains/billingPayments/types';

import { OrderWithProductsPreview } from './OrderWithProductsPreview';
import { OrderSummaryWithProductsDetails } from './OrderSummaryWithProductsDetails';
import { OrderSummaryTitle } from '../OrderSummaryDetails/OrderSummaryTitle';

import styles from '../OrderSummaryDetails/OrderSummaryContainer.scss';
import { ContentType } from 'lane-shared/types/content/Content';
import { InteractionDataType } from 'lane-shared/types/server/WorkflowTypes';
import useUserLocale from 'hooks/useUserLocale';
import { useTranslation } from 'react-i18next';
import { getContentDescription } from 'lane-shared/domains/billingPayments/helpers/getContentDescription';

interface OrderSummaryWithProductsContainerProps {
  orderSummaryWithProducts: OrderSummaryWithProducts;
  content?: ContentType;
  interaction?: InteractionDataType;
}

export function OrderSummaryWithProductsContainer({
  content,
  interaction,
  orderSummaryWithProducts,
}: OrderSummaryWithProductsContainerProps) {
  const locale = useUserLocale();
  const { t } = useTranslation();

  const contentDescription = getContentDescription(
    locale,
    t,
    content,
    interaction
  );

  return (
    <div className={styles.orderSummaryContainer}>
      <OrderSummaryTitle />
      <OrderWithProductsPreview
        orderSummary={orderSummaryWithProducts}
        backgroundImage={
          content?.backgroundImage || content?.backgroundImageId || ''
        }
        contentName={content?.name || ''}
        contentDescription={contentDescription}
      />
      <div className={styles.divider} />
      <OrderSummaryWithProductsDetails
        orderSummary={orderSummaryWithProducts}
      />
    </div>
  );
}
