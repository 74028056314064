import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './index.scss';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { WaitListType } from 'lane-shared/hooks/useTimeSlotInput';
import { doDateRangesOverlap } from 'lane-shared/helpers/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { SHORT_TIME } from 'lane-shared/helpers/constants/dates';

import { TimeSlotOption } from './TimeSlotOption';
import { Icon } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

type TimeSlotSelectProps = {
  timeSlots: WaitListType[];
  timeZone?: string;
  selectedTimeSlot: DateRangeType;
};

type TimeSlotOptionType = {
  label: string;
  actionLabel: string;
  value: DateRangeType;
  onClick: (timeslot: DateRangeType) => void;
};

const TRANSLATION_KEYS = {
  noAvailability: 'shared.content.feature.reservable.timeSlots.noAvailability',
};

export const getFormattedTime = (
  value: DateRangeType,
  timeZone: string | undefined
) => {
  return `${dateFormatter(
    value.startDate,
    SHORT_TIME,
    timeZone
  )} - ${dateFormatter(value.endDate, SHORT_TIME, timeZone)}`;
};

export function TimeSlotSelect({
  timeSlots,
  timeZone,
  selectedTimeSlot,
}: TimeSlotSelectProps) {
  const { t } = useTranslation();

  const availableTimes: TimeSlotOptionType[] = useMemo(() => {
    return timeSlots.map(timeSlot => ({
      label: getFormattedTime(timeSlot.value, timeZone),
      actionLabel: timeSlot.label,
      onClick: timeSlot.onClick,
      value: timeSlot.value,
    }));
  }, [timeSlots, timeZone]);

  if (availableTimes.length === 0) {
    return (
      <div className={classNames(styles.noAvailabilityWrapper)}>
        <Icon
          name="info-circle"
          className={styles.infoIcon}
          set={ICON_SET_FONTAWESOME}
          type="far"
        />
        <p className={styles.noAvailable}>
          {t(TRANSLATION_KEYS.noAvailability)}
        </p>
      </div>
    );
  }

  const isSelected = (timeSlot: DateRangeType) =>
    doDateRangesOverlap(timeSlot, selectedTimeSlot);

  return (
    <>
      {availableTimes.map(timeSlot => (
        <TimeSlotOption
          key={timeSlot.label}
          timeSlot={timeSlot.value}
          actionLabel={timeSlot.actionLabel}
          timeSlotLabel={timeSlot.label}
          onClick={timeSlot.onClick}
          isSelected={isSelected(timeSlot.value)}
        />
      ))}
    </>
  );
}
