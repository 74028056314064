import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';

const properties: PropertiesInterface = {
  _order: {
    friendlyName: 'Order',
    description: 'The Order this should appear',
    type: 'Number',
    default: 0,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  name: {
    friendlyName: 'Option List Name',
    description: 'The name for this list of options',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 80,
      },
      {
        name: 'Min',
        value: 2,
      },
    ],
  },
  price: {
    friendlyName: 'Price',
    description: 'The price of this item',
    type: 'Currency',
    default: 0,
    validators: [
      {
        name: 'Max',
        value: 99900,
      },
      {
        name: 'Min',
        value: -99900,
      },
    ],
  },
  isDefault: {
    friendlyName: 'Is selected by default?',
    description: 'Is this option selected by default?',
    type: 'Boolean',
    default: false,
    validators: [],
  },
};

export class MenuFeatureItemOptionType extends ObjectTypeBase {
  name = 'MenuFeatureItemOption';

  friendlyName = 'Menu Item Option';

  contexts = AllTypeContexts;

  get example() {
    return {
      _id: uuid(),
      name: 'Ketchup',
      price: 0,
      isDefault: true,
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      name: '',
      price: 0,
      isDefault: false,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new MenuFeatureItemOptionType();

Types.addType(definition);
export default definition;
