import { useState } from 'react';

import {
  ScheduleTypeEnum,
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
} from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';

import { VisitorManagementFeatureProperties } from '../../../types/features/VisitorManagementFeatureProperties';
import {
  END_HOUR,
  START_HOUR,
} from '../features/VisitorManagementFeatureValues';
import {
  combineDateAndTime,
  createDefaultDateTime,
  getMatchingMonthIntervalTypes,
} from '../helpers';
import { ScheduleReducerType } from '../reducers';
import { ScheduleType } from '../types/ScheduleType';

type ScheduleOption = {
  _id: string;
  text: string;
};

type Props = {
  properties: VisitorManagementFeatureProperties;
  value: ScheduleType | null;
  timeZone?: string;
  onChange: (value: ScheduleType) => void;
};

export default function useVisitorScheduleForm({
  properties,
  value,
  timeZone,
  onChange,
}: Props) {
  let defaultIsAllDay = properties.allDayPassEnabled;
  const defaultStartDate = createDefaultDateTime(START_HOUR, 0, timeZone);
  const defaultEndDate = createDefaultDateTime(END_HOUR, 0, timeZone);
  const defaultType = !properties.customDatesEnabled
    ? ScheduleTypeEnum.SpecificDate
    : ScheduleTypeEnum.CustomDates;
  const defaultIntervalCount = 1;

  const defaultMonthIntervalOption =
    getMatchingMonthIntervalTypes(defaultStartDate, timeZone)[0] ??
    MonthIntervalTypeEnum.OnDate;

  if (value) {
    defaultIsAllDay = properties.allDayPassEnabled ? value.isAllDay : false;
  }

  const [isAllDay, setIsAllDay] = useState<boolean>(defaultIsAllDay);
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [intervalType, setIntervalType] = useState<IntervalTypeEnum>(
    IntervalTypeEnum.EveryDay
  );
  const [dates, setDates] = useState<Date[]>([defaultStartDate]);
  const [endRepeatDate, setEndRepeatDate] = useState<Date>(defaultEndDate);
  const [intervalCount, setIntervalCount] = useState<number>(
    defaultIntervalCount
  );
  const [weekdayRepeats, setWeekdayRepeats] = useState<number[]>([]);
  const [
    monthIntervalType,
    setMonthIntervalType,
  ] = useState<MonthIntervalTypeEnum>(defaultMonthIntervalOption);
  const [type, setType] = useState<ScheduleTypeEnum>(defaultType);

  const options: ScheduleOption[] = [];

  if (properties.customDatesEnabled) {
    options.push({
      _id: ScheduleTypeEnum.CustomDates,
      text: 'web.content.feature.visitorManagement.scheduler.radio.customDates',
    });
  } else {
    options.push({
      _id: ScheduleTypeEnum.SpecificDate,
      text:
        'web.content.feature.visitorManagement.scheduler.radio.specificDate',
    });
  }

  if (properties.dateRangeEnabled) {
    options.push({
      _id: ScheduleTypeEnum.DateRange,
      text: 'web.content.feature.visitorManagement.scheduler.radio.dateRange',
    });
  }
  if (properties.recurringPassesEnabled) {
    options.push({
      _id: ScheduleTypeEnum.Repeat,
      text: 'web.content.feature.visitorManagement.scheduler.radio.repeat',
    });
  }

  function handleDateTime({
    isAllDay,
    startDate,
    endDate,
    intervalType,
    endRepeatDate,
  }: ScheduleReducerType) {
    setIsAllDay(isAllDay);
    const updatedStartDate = isAllDay
      ? combineDateAndTime(startDate, defaultStartDate, timeZone)
      : startDate;
    const updatedEndDate = isAllDay
      ? combineDateAndTime(endDate, defaultEndDate, timeZone)
      : endDate;
    setStartDate(updatedStartDate);
    setEndDate(updatedEndDate);

    onChange({
      isAllDay,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      type,
      intervalType,
      endRepeatDate,
    });
  }

  function handleCustomDateTime({
    isAllDay,
    startDate,
    endDate,
    intervalType,
    endRepeatDate,
    dates,
  }: ScheduleReducerType) {
    setIsAllDay(isAllDay);
    const updatedStartDate = isAllDay
      ? combineDateAndTime(startDate, defaultStartDate, timeZone)
      : startDate;
    const updatedEndDate = isAllDay
      ? combineDateAndTime(endDate, defaultEndDate, timeZone)
      : endDate;
    setStartDate(updatedStartDate);
    setEndDate(updatedEndDate);
    setDates(dates ?? [defaultStartDate]);
    onChange({
      isAllDay,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      dates,
      type,
      intervalType,
      endRepeatDate,
    });
  }

  function handleDateTimeRange({
    isAllDay,
    startDate,
    endDate,
    startTime,
    endTime,
  }: ScheduleReducerType) {
    setIsAllDay(isAllDay);
    const updatedStartDate = isAllDay
      ? combineDateAndTime(startDate, defaultStartDate, timeZone)
      : startDate;
    const updatedEndDate = isAllDay
      ? combineDateAndTime(endDate, defaultEndDate, timeZone)
      : endDate;

    setStartDate(updatedStartDate);
    setEndDate(updatedEndDate);

    onChange({
      isAllDay,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      endRepeatDate: updatedEndDate,
      type,
      startTime,
      endTime,
    });
  }

  function handleRepeat({
    isAllDay,
    startDate,
    endDate,
    startTime,
    endTime,
    intervalType = IntervalTypeEnum.EveryDay,
    endRepeatDate,
    intervalCount = defaultIntervalCount,
    weekdayRepeats = [],
    monthIntervalType = MonthIntervalTypeEnum.OnDate,
  }: ScheduleReducerType) {
    setIsAllDay(isAllDay);
    setIntervalType(intervalType);
    const updatedStartDate = isAllDay
      ? combineDateAndTime(startDate, defaultStartDate, timeZone)
      : startDate;
    const updatedEndDate = isAllDay
      ? combineDateAndTime(endDate, defaultEndDate, timeZone)
      : endDate;

    const updatedEndRepeatDate = endRepeatDate || updatedEndDate;
    setStartDate(updatedStartDate);
    setEndDate(updatedEndDate);
    setEndRepeatDate(updatedEndRepeatDate);
    setWeekdayRepeats(weekdayRepeats);

    if (intervalType === IntervalTypeEnum.Monthly) {
      setMonthIntervalType(monthIntervalType);
    }

    const updatedIntervalCount =
      intervalCount < defaultIntervalCount
        ? defaultIntervalCount
        : intervalCount;
    setIntervalCount(updatedIntervalCount);

    onChange({
      isAllDay,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      type,
      intervalType,
      endRepeatDate: updatedEndRepeatDate,
      intervalCount: updatedIntervalCount,
      weekdayRepeats,
      monthIntervalType,
      startTime,
      endTime,
    });
  }

  function handleType(input: ScheduleTypeEnum) {
    setType(input);
    let updatedEndDate = combineDateAndTime(startDate, endDate, timeZone);
    updatedEndDate = updatedEndDate < startDate ? startDate : updatedEndDate;

    if (input === ScheduleTypeEnum.DateRange) {
      const newEndDate = new Date(updatedEndDate);
      newEndDate.setDate(newEndDate.getDate() + 1);
      updatedEndDate = newEndDate;
      setEndDate(updatedEndDate);
    } else {
      setEndDate(updatedEndDate);
    }

    setDates([startDate]);
    setIntervalCount(defaultIntervalCount);
    setIntervalType(IntervalTypeEnum.EveryDay);
    setWeekdayRepeats([]);
    setMonthIntervalType(defaultMonthIntervalOption);
    setEndRepeatDate(updatedEndDate);

    const repeatProperties =
      input === ScheduleTypeEnum.Repeat
        ? {
            intervalType: IntervalTypeEnum.EveryDay,
            endRepeatDate: updatedEndDate,
            intervalCount: defaultIntervalCount,
            weekdayRepeats: [],
            monthIntervalType: defaultMonthIntervalOption,
          }
        : {};

    onChange({
      isAllDay,
      startDate,
      endDate: updatedEndDate,
      dates: input === ScheduleTypeEnum.CustomDates ? [startDate] : undefined,
      type: input,
      ...repeatProperties,
    });
  }

  function resetForm() {
    // reset the form upon submission complete
    setIsAllDay(properties.allDayPassEnabled);
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setDates([defaultStartDate]);
    setType(defaultType);
    onChange({
      isAllDay: properties.allDayPassEnabled,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      dates: [defaultStartDate],
      type: defaultType,
    });
  }

  // WARN: This was causing issues with the state of the
  // schedules - setting attributes and resetting some state
  // IF this needs to be re-added - please be careful about what is
  // set by the different schedule types
  // useEffect(() => {
  //   onChange({
  //     isAllDay,
  //     startDate,
  //     endDate,
  //     dates,
  //     type,
  //     intervalType,
  //     endRepeatDate,
  //     intervalCount,
  //     weekdayRepeats,
  //     monthIntervalType,
  //   });
  // }, [
  //   value?.isAllDay,
  //   value?.startDate,
  //   value?.endDate,
  //   value?.dates,
  //   value?.type,
  //   value?.intervalType,
  //   value?.endRepeatDate,
  //   value?.intervalCount,
  //   value?.weekdayRepeats,
  //   value?.monthIntervalType,
  // ]);

  return {
    options,
    isAllDay,
    startDate,
    endDate,
    dates,
    intervalType,
    endRepeatDate,
    intervalCount,
    weekdayRepeats,
    monthIntervalType,
    type,
    resetForm,
    handleDateTime,
    handleCustomDateTime,
    handleDateTimeRange,
    handleRepeat,
    handleType,
  };
}
