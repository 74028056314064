import React, { useEffect } from 'react';

import { Loading } from 'components';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { PageSizeType, H3 } from 'design-system-web';
import AdminPage from 'components/layout/AdminPage';
import { Text } from 'components/typography';

import { useReservableManagementCalendarEnabled } from 'lane-shared/hooks/useReservableManagementCalendarEnabled';
import { useGetReservableBookings } from '../../hooks/useGetReservableBookings';
import EmptyPage from '../EmptyPage/EmptyPage';
import { ReservableTableView } from '../ReservableTableView/ReservableTableView';
import { ReservableCalendarView } from '../ReservableCalendarView';

import styles from './styles.scss';

const DEFAULT_PAGE_SIZE: PageSizeType = 25;
const DEFAULT_PAGE_START = 0;
const TRANSLATION_KEYS = {
  bookingsError: 'web.admin.channel.reservableManagement.fetchBookings.error',
  pageHeading: 'web.admin.channel.reservableManagement.pageHeading',
  list: 'web.admin.channel.reservableManagement.tabs.list',
  calendar: 'web.admin.channel.reservableManagement.tabs.calendar',
};
export const DEFAULT_TZ = 'America/New_York';

type Props = {
  channel?: Pick<ChannelType, '_id' | 'address'>;
};

function setChannelTimeZone(channel: Pick<ChannelType, '_id' | 'address'>) {
  const { address } = channel;
  let channelTimeZone = DEFAULT_TZ;

  if (address) {
    channelTimeZone = getTimeZoneByGeoLocation({
      longitude: address.geo[0],
      latitude: address.geo[1],
    });
  }

  return channelTimeZone;
}

function ReservableView({
  channel,
}: {
  channel: Pick<ChannelType, '_id' | 'address'>;
}) {
  const { t } = useTranslation();
  const {
    fetchBookings,
    bookings,
    isLoading,
    error,
  } = useGetReservableBookings({
    channelId: channel._id,
  });

  useEffect(() => {
    fetchBookings({
      pagination: { start: DEFAULT_PAGE_START, perPage: DEFAULT_PAGE_SIZE },
    });
  }, []);

  if (error) {
    window.Toast.show(<Text>{t(TRANSLATION_KEYS.bookingsError)}</Text>);
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (bookings?.length === 0) {
    return <EmptyPage />;
  }

  const channelTimeZone = setChannelTimeZone(channel);

  return (
    <ReservableTableView
      channelId={channel._id}
      channelTimeZone={channelTimeZone}
    />
  );
}

const getTabs = (includeCalendar: boolean) => {
  const tabs = [
    {
      value: 'list',
      label: TRANSLATION_KEYS.list,
    },
  ];

  if (includeCalendar) {
    tabs.unshift({
      value: 'calendar',
      label: TRANSLATION_KEYS.calendar,
    });
  }

  return tabs;
};

export function ReservableManagement({ channel }: Props) {
  const match = useRouteMatch();
  const { view } = useParams<{ view: string }>();
  const history = useHistory();
  const isCalendarViewEnabled = useReservableManagementCalendarEnabled();
  const tabs = getTabs(isCalendarViewEnabled);
  const { t } = useTranslation();

  if (!channel) {
    return null;
  }

  const channelTimeZone = setChannelTimeZone(channel);

  const handleTabSelect = (tab: TabItem) => {
    history.replace(`${tab.value}`);
  };

  return (
    <AdminPage>
      <div className={styles.reservableTitleContainer}>
        <H3 as="h1">{t(TRANSLATION_KEYS.pageHeading)}</H3>
        <TabStrip
          tabs={tabs}
          selected={tabs.find(t => t.value === view)}
          onSelectTab={handleTabSelect}
        />
      </div>
      <Switch>
        <Route path={match.path.replace(':view', 'calendar')}>
          <ReservableCalendarView
            channelId={convertToUUID(channel._id)}
            channelTimeZone={channelTimeZone}
          />
        </Route>
        <Route path={match.path.replace(':view', 'list')}>
          <ReservableView channel={channel} />
        </Route>
      </Switch>
    </AdminPage>
  );
}
