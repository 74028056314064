import React, { useRef, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import {
  useUpdateAutoPay,
  useEssensysPaymentSettings,
} from 'lane-shared/hooks';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"lane-shared/hooks/useMyPaymentAccountsQue... Remove this comment to see the full error message
import { PaymentAccountTypeSummary } from 'lane-shared/hooks/useMyPaymentAccountsQuery';

import PlacePayPaymentModal from 'components/payments/PlacePayPaymentModal';
import { H5 } from 'components/typography';

import PlacePayLogo from 'static/img/placepay-logo.png';

import styles from './UserPlacePayPaymentAccount.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  paymentAccount: PaymentAccountTypeSummary;
  accountId?: string;
};

export default function UserPlacePayPaymentAccount({
  className,
  style,
  paymentAccount,
  accountId,
}: Props) {
  const { t } = useTranslation();
  const placePayRef = useRef({
    reject: () => null,
    resolve: () => null,
  });

  const NO_USER_ID = undefined;

  const { essensysPaymentSettings } = useEssensysPaymentSettings(NO_USER_ID, {
    accountId,
  });

  const [isPlacePayOpen, setIsPlacePayOpen] = useState(false);
  const autoPay = useUpdateAutoPay({
    paymentAccountId: paymentAccount?._id,
    accountId,
  });
  // if there is a payment processor associated with this account, find
  // the channel so we can display info about it.
  const locationName = essensysPaymentSettings?.locationName;

  return (
    <div
      className={cx(styles.UserPlacePayPaymentAccount, className)}
      style={style}
    >
      {locationName && (
        <H5 mb={2}>
          {t('Payment at {{- name}}', {
            name: locationName,
          })}
        </H5>
      )}

      <ErrorMessage error={autoPay.error} />

      <button
        className={styles.placePayContainer}
        onClick={() => setIsPlacePayOpen(true)}
      >
        <img
          src={PlacePayLogo}
          alt="Placepay Logo"
          className={styles.placePayLogo}
        />
        <Icon className={styles.icon} name="chevron-right" />
      </button>

      <PlacePayPaymentModal
        isOpen={isPlacePayOpen}
        paymentAccountId={paymentAccount?._id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        payeeId={essensysPaymentSettings?.orgpayeeid}
        mode="autopay"
        onClose={() => {
          setIsPlacePayOpen(false);
          placePayRef.current.reject();
        }}
        onPaymentSuccess={data => {
          setIsPlacePayOpen(false);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.resolve(data);
        }}
        onPaymentFailed={err => {
          setIsPlacePayOpen(false);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.reject(err);
        }}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(data: { autopay: boolean; }) => void' is no... Remove this comment to see the full error message
        onAutoPayChanged={autoPay.update}
      />
    </div>
  );
}
