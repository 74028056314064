import { useEffect, useState } from 'react';

import gql from 'graphql-tag';

import { getClient } from '../apollo';
import { explodeFeatures } from '../helpers/features';

const quantityQuery = gql`
  query quantityClaimed($id: UUID!) {
    contentFeatureQuantityClaimed(_id: $id)
  }
`;

export default function useRemainingQuantity(content: any) {
  const [quantityClaimed, setQuantityClaimed] = useState(0);
  const [quantityRemaining, setQuantityRemaining] = useState(0);

  const { quantityFeature } = explodeFeatures(content?.features);

  async function getQuantityClaimed() {
    try {
      const { data } = await getClient().query({
        query: quantityQuery,
        fetchPolicy: 'no-cache',
        variables: {
          id: content._id,
        },
      });

      setQuantityClaimed(data.contentFeatureQuantityClaimed);
    } catch (err) {
      // ignore error
      // content might not be created yet - it might still be a draft
    }
  }

  useEffect(() => {
    if (content?._id && quantityFeature) {
      getQuantityClaimed();
    }
  }, [content?._id, !!quantityFeature]);

  useEffect(() => {
    const total = quantityFeature?.total ?? 0;
    const quantity = Math.max(0, total - quantityClaimed);
    setQuantityRemaining(quantity);
  }, [!!quantityFeature, quantityClaimed]);

  return { quantity: quantityRemaining };
}
