import React from 'react';

import { useTranslation } from 'react-i18next';

import { useDistanceAndPricePreview } from 'lane-shared/hooks';
import useFlag from 'lane-shared/hooks/useFlag';
import { LocationType } from 'lane-shared/types/LocationType';
import { ContentType } from 'lane-shared/types/content/Content';

import { M } from 'components/typography';

import PriceLabel from './PriceLabel';
import getDistanceFromLocation from 'helpers/getDistanceFromLocation';

import styles from './StandardContentCard.scss';

type Props = {
  content: Pick<ContentType, 'geo' | 'features' | 'category'>;
  showDistance?: boolean;
  location?: LocationType;
};

export default function StandardContentCardFooter({
  content,
  showDistance = false,
  location,
}: Props) {
  const { t } = useTranslation();
  const locationEnabled = !location?.noLocation;

  const enablePriceLabel = useFlag('enable-price-label', false);

  const preview = useDistanceAndPricePreview({
    content,
    showDistance,
    locationEnabled,
  });
  if (!preview) {
    return null;
  }
  const {
    amount,
    shouldShowDistanceAway,
    isPriceShown,
    isFree,
    totalAmount,
    unitTime,
  } = preview;

  return (
    <div className={styles.footer}>
      {shouldShowDistanceAway ? (
        <M className={styles.geoDistance}>
          {t('{{distance}} away', {
            distance: getDistanceFromLocation({
              latitude: content.geo?.[1],
              longitude: content.geo?.[0],
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'LocationType | undefined' is not assignable ... Remove this comment to see the full error message
              location,
            }),
          })}
        </M>
      ) : null}
      {isPriceShown && enablePriceLabel ? (
        <PriceLabel
          isFree={isFree}
          totalAmount={totalAmount}
          unitTime={unitTime}
          amount={amount}
        />
      ) : null}
    </div>
  );
}
