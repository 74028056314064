import React, { useEffect, useState, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { LONG_DATE } from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import * as requestClient from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client';
import { getToken } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/token';
import { STATUS_MAP } from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors/types/VisitTypes';
import { ContentType } from 'lane-shared/types/content/Content';
import type { PillColor } from 'lane-shared/types/general/pill';

import Pill from 'components/general/Pill';
import { PaginatedTable, Row } from 'components/lds/table';

import { VisitorQueryParams } from './VisitorsContainer';

export const COLUMNS = [
  { header: 'Status', key: 'status', disableSort: true },
  { header: 'Expected Arrival', key: 'expectedArrival', disableSort: false },
  { header: 'Group Id', key: 'groupId', disableSort: true },
  { header: 'Visitor', key: 'visitor', disableSort: false },
  { header: 'Host', key: 'host', disableSort: true },
  { header: 'Location', key: 'location', disableSort: true },
  //    {header: "Actions", key: "actions", disableSort: true},
];
export const SORT_KEYS: {
  [key: string]: requestClient.visitors.FetchVisitsOrderingEnum;
} = {
  status: requestClient.visitors.FetchVisitsOrderingEnum.StatusAsc,
  expectedArrival: requestClient.visitors.FetchVisitsOrderingEnum.DateAsc,
  visitor: requestClient.visitors.FetchVisitsOrderingEnum.NameAsc,
};

type Props = {
  paginationParams: VisitorQueryParams;
  setUrlSearchParams: Function;
  content: ContentType;
};

export const selectedTabFilter = {
  upcoming: requestClient.visitors.FetchVisitorsFilterValues.DateGte,
  past: requestClient.visitors.FetchVisitorsFilterValues.DateLt,
};

export default function VisitorsTable({
  paginationParams,
  setUrlSearchParams,
  content,
}: Props) {
  const [rows, setRows] = useState<requestClient.visitors.VisitorResData[]>([]);
  const [storedToken, setStoredToken] = useState<string | undefined>(undefined);

  const { t } = useTranslation();

  const statusText = (status: string): string => t(STATUS_MAP[status]!.label);
  const statusColor = (status: string): string => STATUS_MAP[status]!.color;

  const expectedArrival = (date: string): string => {
    return dateFormatter(date, LONG_DATE);
  };

  /**
   * Set the status pill on the first column
   * along with the tiny circle
   *
   * @param status
   * @returns ReactNode
   */
  const statusPill = (status: string): ReactNode => {
    const color = statusColor(status);
    const text = statusText(status);

    return (
      <Pill
        text={text}
        size="small"
        color={color as PillColor}
        iconName="circle"
        iconColor={color}
      />
    );
  };

  /**
   *
   * @param visitor Visitor to create the edit link for
   * @returns Link Link is scoped by content id to fetch integration information
   * on the subrouter level
   */
  const createVisitorEditLink = (
    visitor: requestClient.visitors.VisitorResData
  ) => {
    const visitorName = `${visitor.first_name} ${visitor.last_name}`;

    return (
      <Link
        to={{
          pathname: routes.visitEdit
            .replace(':id', content._id)
            .replace(':visitId', visitor.visit_id)
            .replace(':visitorId', visitor.id),
        }}
      >
        {visitorName}
      </Link>
    );
  };

  const mapVisitors = (visitors: requestClient.visitors.VisitorResData[]) => {
    return visitors.map(
      (v: requestClient.visitors.VisitorResData): Row => {
        const groupId = v.visit_id.split('-')[0] as string;
        const location = `${v.space.name} - ${v.building.name}`;

        return {
          id: v.id,
          items: [
            {
              key: 'status',
              type: 'custom',
              renderer: () => statusPill(v.status),
            },
            {
              key: 'expectedArrival',
              type: 'string',
              value: expectedArrival(v.date),
            },
            { key: 'groupId', type: 'string', value: groupId },
            {
              key: 'visitor',
              type: 'custom',
              renderer: () => createVisitorEditLink(v),
            },
            { key: 'host', type: 'string', value: v.host },
            { key: 'location', type: 'string', value: location },
            { key: 'actions', type: 'string', value: '' },
          ],
        };
      }
    );
  };

  useEffect(() => {
    async function getTokenOnLoad() {
      setStoredToken(await getToken());
    }

    getTokenOnLoad();
  }, []);

  const constructParams = () => {
    const orderSign = paginationParams.dir === 'asc' ? '' : '-';

    return {
      ordering: (paginationParams.sortKey !== ''
        ? (`${orderSign}${
            SORT_KEYS[paginationParams.sortKey]
          }` as requestClient.visitors.FetchVisitsOrderingEnum)
        : '') as requestClient.visitors.FetchVisitsOrderingEnum | '',
      offset: Number(paginationParams.page) * Number(paginationParams.perPage),
      limit: paginationParams.perPage,
      filters: {
        [selectedTabFilter[
          paginationParams.selectedTab
        ]]: new Date().toISOString().split('T')[0],
      },
      pagelimit: 100,
      visit__building__in: content.integration.settings.buildingId,
    };
  };

  useEffect(() => {
    async function loadData() {
      try {
        const { filters, ...params } = constructParams();

        const response = await requestClient.visitors.list(
          storedToken!,
          params,
          filters
        );
        const visitors = await response.data;

        setRows(visitors.results);
        setUrlSearchParams({ total: visitors.count });
      } catch (err) {
        console.error(err);
        // window.alert(t('Could not fetch Visitors. Please try again later.'));
      }
    }

    if (storedToken) loadData();
  }, [
    storedToken,
    paginationParams.sortKey,
    paginationParams.dir,
    paginationParams.page,
    paginationParams.perPage,
    paginationParams.selectedTab,
  ]);

  return <PaginatedTable columns={COLUMNS} rows={mapVisitors(rows)} />;
}
