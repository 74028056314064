import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const NewStandDefinition: IntegrationDefinition = {
  hasCustomUI: true,
  name: IntegrationProviderEnum.Newstand,
  friendlyName: 'Newstand',
  description: `Content Generator`,
  category: IntegrationCategoryEnum.Content,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api, // API or Webview? Both?
  settings: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'The base URL of the Newstand API endpoint',
      type: 'Url',
      default: 'https://newstand.com/data/api/', // they version. auth call includes /v1/token-auth/
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    version: {
      friendlyName: 'Version',
      description:
        'The current version of the API. Used for Auth endpoints only',
      type: 'String',
      default: 'v1',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    username: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    // can we mask this? We'll probably have to, it's not a UserLogin it's like a company login? Maybe ask clint
    password: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    zone_id: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    membership_id: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  interactionData: {},
  mobilePermissions: [],
};

export default NewStandDefinition;
