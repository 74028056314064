import useFlag from './useFlag';
import { FeatureFlag } from '../types/FeatureFlag';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-08-02T12:16:20-0400.
 */
export function useQuantityAdvancedRulesEnabled() {
  const isEnabled = useFlag<boolean>(FeatureFlag.QuantityAdvancedRule, false);

  return Boolean(isEnabled);
}
