export enum FlexDirectionEnum {
  Vertical = 'column',
  Horizontal = 'row',
}

export enum FlexJustifyContentEnum {
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center',
  SpaceBetween = 'space-between',
  SpaceEvenly = 'space-evenly',
}

export enum FlexAlignItemsEnum {
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center',
  Baseline = 'baseline',
  Stretch = 'stretch',
}

export enum FlexWrapEnum {
  Wrap = 'wrap',
  WrapReverse = 'wrap-reverse',
  NoWrap = 'nowrap',
}
