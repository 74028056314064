import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';

const Deliverable: FeatureDefinition = {
  description:
    'Indicates that this can be delivered to the end user at their location.',
  friendlyName: 'Delivery',
  requiresInteraction: true,
  name: FeatureNameEnum.Deliverable,
  superUserOnly: true,
  properties: {
    cost: {
      friendlyName: 'Delivery Fee',
      type: 'Currency',
      editable: true,
      validators: [
        {
          name: 'Max',
          value: 2 ** 16,
        },
        {
          name: 'Min',
          value: 0,
        },
      ],
    },
  },
  interactionData: {
    location: {
      friendlyName: 'Delivery Location',
      type: 'Address',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    notes: {
      friendlyName: 'Delivery Notes',
      type: 'LongText',
    },
  },
};

export default Deliverable;
