import React from 'react';

import {
  MjmlSection,
  MjmlColumn,
  MjmlImage,
} from 'lane-shared/emails/MjmlComponentsV2';

export function EmailVisitorHero({
  logoUri,
  backgroundUrl,
}: {
  logoUri?: string;
  backgroundUrl?: string;
}) {
  const fallbackBackground =
    'https://lane-media-prd-us-east-2.s3.amazonaws.com/visitor-email-hero-fallback.png';

  return (
    <MjmlSection
      padding="14%"
      background-color="#F5F6F7"
      background-url={backgroundUrl || fallbackBackground}
      background-size="cover"
    >
      {logoUri && (
        <MjmlColumn>
          <MjmlImage width="100px" src={logoUri} />
        </MjmlColumn>
      )}
    </MjmlSection>
  );
}
