import { DateTime, Interval } from 'luxon';

import { ReservableFeatureProperties } from '../../types/features/ReservableFeatureProperties';
import parseDateTime from '../dates/parseDateTime';

export default function getReservableFeatureNumberOfSlots({
  startDate,
  endDate,
  timeZone,
  reservableFeature,
}: {
  startDate?: Date;
  endDate?: Date;
  timeZone: string;
  reservableFeature?: ReservableFeatureProperties;
}): number {
  if (!startDate || !endDate || !reservableFeature) {
    return 1;
  }

  const _startDate = parseDateTime(startDate, timeZone) as DateTime;
  const _endDate = parseDateTime(endDate, timeZone) as DateTime;

  const interval = Interval.fromDateTimes(_startDate, _endDate);

  // stop divide by zero with || 1
  return (
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ReservableUnitTypesEnum' is not ... Remove this comment to see the full error message
    interval.length(reservableFeature.unitType) / (reservableFeature.units || 1)
  );
}
