import { useCallback, useEffect, useRef } from 'react';

export default function useIsComponentMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  function setMountedRef() {
    isMountedRef.current = false;
  }
  useEffect(() => setMountedRef, []);

  return isMounted;
}
