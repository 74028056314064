import React from 'react';

import { useTranslation } from 'react-i18next';

import { explodeFeatures } from 'lane-shared/helpers/features';

import { RelatedSpace } from 'components/cards/RelatedSpacesCard';
import MessageBox from 'components/lds/MessageBox';

import ChildRelatedSpaceDifferencesWithParent from '../../helpers/ChildRelatedSpaceDifferencesWithParent';

import styles from './RelatedSpaceChildDiscrepancyError.scss';

type Props = {
  parentContent: RelatedSpace;
  childContent: RelatedSpace;
};

function getValidationErrors(
  parentContent: RelatedSpace,
  childContent: RelatedSpace,
  t: (key: string, params?: any) => string
): { key: string; text: string }[] {
  const diff = new ChildRelatedSpaceDifferencesWithParent(
    parentContent,
    childContent
  );
  const { reservableFeature: parentFeature } = explodeFeatures(
    parentContent.features
  );

  if (!diff.isReservableEnabled) {
    return [
      {
        key: 'reservableEnabled',
        text: t('Reservable not enabled'),
      },
    ];
  }

  if (diff.isLinkedToDifferentParent) {
    return [
      {
        key: 'differentParent',
        text: t('Unable to link, already linked to another space:'),
      },
    ];
  }

  const errors: {
    key: string;
    text: string;
  }[] = [];

  if (diff.isUnitTypeDifferent) {
    errors.push({
      key: 'unitType',
      text: t('"Unit of Time" does not match "{{parentUnitType}}"', {
        parentUnitType: parentFeature?.unitType?.toLowerCase(),
      }),
    });
  }

  if (diff.isMinSlotsDifferent) {
    errors.push({
      key: 'minSlot',
      text: t(
        '"Min bookable time slot" does not match "{{parentUnits}} {{parentUnitType}}"',
        {
          parentUnits: parentFeature?.units,
          parentUnitType: parentFeature?.unitType?.toLowerCase(),
        }
      ),
    });
  }

  if (diff.isMaxSlotsDifferent) {
    errors.push({
      key: 'maxSlot',
      text: t(
        '"Max bookable consecutive slots" does not match "{{maxSlots}}"',
        {
          maxSlots: parentFeature?.mainRule.maxSlots,
        }
      ),
    });
  }

  if (diff.isSplitReservationSlotsDifferent) {
    errors.push({
      key: 'splitReservation',
      text: t('"Split by slots" does not match "{{splitReservationSlots}}"', {
        splitReservationSlots: parentFeature?.splitReservationSlots,
      }),
    });
  }

  if (diff.isMaxQuantityPerSlotMoreThanOne) {
    errors.push({
      key: 'maxQuantityPerSlot',
      text: t('Capacity is > 1'),
    });
  }

  if (diff.isBufferTimeDifferent) {
    errors.push({
      key: 'isBufferTimeDifferent',
      text: t('Buffer Time does not match'),
    });
  }

  return errors;
}

export default function RelatedSpaceChildDiscrepancyError({
  parentContent,
  childContent,
}: Props) {
  const { t } = useTranslation();
  const errors = getValidationErrors(parentContent, childContent, t);

  if (!errors.length) {
    return null;
  }

  return (
    <MessageBox
      className={styles.MessageBox}
      type="error"
      title={t('Unable to link, please update {{contentName}} settings.', {
        contentName: childContent.name,
      })}
    >
      <ul className={styles.errors}>
        {errors.map(err => (
          <li key={err.key}>{err.text}</li>
        ))}
      </ul>
    </MessageBox>
  );
}
