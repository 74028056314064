import React, { useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { AppContext, ChannelsContext } from 'lane-shared/contexts';

import { WhitelabeledLogo, Loading, ModalBackground } from 'components/general';
import PoweredBy from 'components/lane/PoweredBy';
import { S, XS } from 'components/typography';

import styles from './TransitionPage.scss';

type TransitionPageProps = {
  message?: {
    title?: string;
    subTitle?: string;
  };
  isOpen?: boolean;
};

export default function TransitionPage({
  message = {},
  isOpen,
}: TransitionPageProps) {
  const { isTransitioning } = useContext(AppContext);
  const { t } = useTranslation();
  const { primaryChannel, primaryChannelInitialized } = useContext(
    ChannelsContext
  );

  if (primaryChannel && primaryChannelInitialized) {
    message.title = message.title || primaryChannel.name;
    message.subTitle = message.subTitle || primaryChannel.address?.street1;
  }

  const { title, subTitle } = message;

  return (
    <ModalBackground
      isOpen={isOpen || isTransitioning}
      onClose={() => undefined}
    >
      <div className={cx(styles.content)}>
        <div className={styles.channelInfo}>
          <XS variant="secondary" mb={4}>
            {t('Entering...')}
          </XS>
          <WhitelabeledLogo size={112} />
          <S variant="secondary" mt={4}>
            {title}
          </S>
          {title !== subTitle && (
            <XS variant="secondary" className={styles.addressInfo} mt={1}>
              {subTitle}
            </XS>
          )}
        </div>
        <Loading className={styles.loadingIndicator} />
        <PoweredBy />
      </div>
    </ModalBackground>
  );
}
