import React, { useContext } from 'react';

import urlJoin from 'url-join';

import { LaneType } from 'common-types';
import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { ICON_SET_ION } from 'lane-shared/helpers/constants/icons';
import { IcsPersonType } from 'lane-shared/helpers/generateIcsData';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import LinkButton from '../general/LinkButton';
import downloadICS from 'helpers/downloadICS';

type ContentFeatureCalendarButtonProps = {
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  name: string;
  description: string;
  timeZone: string | undefined;
  interactionId: LaneType.UUID;
  startDate: Date | string | undefined;
  endDate: Date | string | undefined;
  attendees?: IcsPersonType[];
  organizer?: IcsPersonType;
  geo?: GeoCoordinateType;
  location?: string;
  categories?: string[];
  status?: string;
};

export default function ContentFeatureCalendarButton({
  className,
  style,
  loading = false,
  disabled = false,
  name,
  description,
  timeZone,
  startDate,
  endDate,
  attendees,
  organizer,
  geo,
  location,
  interactionId,
  categories,
  status,
}: ContentFeatureCalendarButtonProps) {
  const { whitelabel } = useContext(AppContext);

  function downloadHandler() {
    if (!(startDate && endDate)) {
      return;
    }

    downloadICS({
      id: interactionId,
      url: urlJoin(
        whitelabel.url,
        routes.interaction.replace(':id', interactionId)
      ),
      startDate,
      endDate,
      timeZone,
      name,
      description,
      organizer,
      attendees,
      geo,
      location,
      categories,
      status,
    });
  }

  if (!(startDate && endDate)) {
    return null;
  }

  return (
    <LinkButton
      className={className}
      style={style}
      loading={loading}
      disabled={disabled}
      iconName="MdCalendar"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"Ion"' is not assignable to type '"FontAweso... Remove this comment to see the full error message
      iconSet={ICON_SET_ION}
      onClick={downloadHandler}
      doTranslate
    >
      Add to Calendar
    </LinkButton>
  );
}
