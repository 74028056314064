import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesIncludes } from './ITypeValidatesIncludes';

export class IncludesValidator
  implements ValidatorInterface<ITypeValidatesIncludes> {
  name = 'Includes';

  friendlyName = 'Includes';

  arrayOnly = false;

  array = false;

  description = 'shared.properties.validators.includes.description';

  types = ['String', 'Email', 'LongText', 'Url', 'PhoneNumber'];

  properties = {
    value: {
      type: 'String',
      default: '',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesIncludes) {
    type.addIncludesValidator(this);
  }

  constructor(public config?: Validator) {}
}

const Includes = new IncludesValidator(undefined);

ValidatorRegistry.register(IncludesValidator, Includes.name);

export default Includes;
