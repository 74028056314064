import { HIDCodeType } from './HIDCodeType';

export const HID_NUM_DIGITS = 16;

export default function sanitizeAndFormatHIDCode(input: string): HIDCodeType {
  const value = input.toUpperCase().replace(/[^0-9A-Z]/gi, '');

  if (value.length !== HID_NUM_DIGITS) {
    throw new Error('Invalid HID code');
  }

  const parts = value.substr(0, HID_NUM_DIGITS).match(/.{1,4}/g);

  return {
    code1: parts?.[0] || '',
    code2: parts?.[1] || '',
    code3: parts?.[2] || '',
    code4: parts?.[3] || '',
  };
}
