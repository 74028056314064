import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { VisitorFieldEnum } from '../types/VisitorManagementFeatureProperties';

export const settings: VisitorManagementFeatureProperties = {
  _version: 1,
  nameSetting: VisitorFieldEnum.Required,
  emailSetting: VisitorFieldEnum.Required,
  phoneSetting: VisitorFieldEnum.Required,
  companySetting: VisitorFieldEnum.Required,
  allDayPassEnabled: true,
  dateRangeEnabled: true,
  customDatesEnabled: false,
  recurringPassesEnabled: true,
  multiSchedulesEnabled: false,
  scheduleInAdvanceAmount: 12,
  hostSelectionEnabled: true,
  notesToStaffEnabled: true,
  notesToVisitorEnabled: true,
  bulkImportSetting: true,
  additionalRecipientsEnabled: true,
};
