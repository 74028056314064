// Takes a temperature Number and a units String from dark-sky
// Returns a formatted String with the degree symbol

// See https://darksky.net/dev/docs (search for units) to see the
// unit types returned

const FAHRENHEIT_UNITS = ['us'];

export default function friendlyTemperature(temperature: any, units: any) {
  if (Number.isNaN(parseFloat(temperature))) {
    return '';
  }

  // only the US and Bahrain use F
  if (FAHRENHEIT_UNITS.includes(units)) {
    return `${Math.round(temperature)} °F`;
  }

  return `${Math.round(temperature)} °C`;
}
