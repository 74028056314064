export default function uploadToS3({ signed, file, contentType }: any) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signed);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.setRequestHeader('Content-Type', contentType);

    xhr.onload = function onLoad() {
      if (xhr.status === 200) {
        resolve(xhr);
        return;
      }

      reject(xhr);
    };

    xhr.onerror = function onError(e) {
      (e as any).xhr = xhr;
      reject(e);
    };

    xhr.send(file);
  });
}
