import { IntegrationProviderEnum } from '../../../../types/integrations/IntegrationEnums';
import { constructInteraction } from '../../../content';
import getDisplayName from '../../../getDisplayName';
import constructAddress from './constructAddress';

export default function constructNewRequest({
  content,
  user,
  oldRequest,
}: any) {
  const interaction = constructInteraction(content);
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const request = interaction.data[`_${IntegrationProviderEnum.ThreeTenServ}`];

  request.name = getDisplayName(user);
  request.email = user.profile.email || '';

  if (oldRequest) {
    request.email = oldRequest.email;
    request.name = oldRequest.name;
    request.company = oldRequest.company;
    request.phone = oldRequest.phone;
  }

  if (content.integration.settings?.buildings?.length === 1) {
    request.address = constructAddress({
      building: content.integration.settings.buildings[0],
      content,
    });
  }

  return request;
}
