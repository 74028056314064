import React, { memo, useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getFriendlyDate } from 'lane-shared/helpers/content';
import {
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content/subtitleHelpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ContentForCards } from 'lane-shared/types/content/ContentForCards';

import { useDescriptionText, useTitleText } from 'components/cards/helper';
import { H4, S, XS } from 'components/typography';

import GeneratorContentCount from '../../pages/portal/admin/channel/batch-content/components/GeneratorContentCount';
import ContentStatusPill from './ContentStatusPill';

import styles from './HorizontalContentCard.scss';
import useUserLocale from 'hooks/useUserLocale';

import { ContentPriceTag } from './ContentPriceTag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { useFlag } from 'lane-shared/hooks';

type Props = {
  content: ContentForCards;
  mode?: 'default' | 'preview';
  className?: string;
  style?: React.CSSProperties;
};

function HorizontalContentCard({ content, className, style, mode }: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const date = getFriendlyDate({ content, translator: t, locale });

  const [elements, setElements] = useState({ title: [], desc: [] }) as any;
  const [lines, setLines] = useState({ title: 0, desc: 0 });

  const priceOnCardFlag = useFlag(FeatureFlag.PriceOnCard, false);
  const { paymentFeature } = explodeFeatures(content?.features);

  function getLineClamp() {
    if (
      (isSubtitleCustom(content.subtitle) ||
        (date && isSubtitleDate(content.subtitle))) &&
      content?.description
    ) {
      if (lines?.title >= 2) {
        return 'medium-title';
      }
      return 'short-title';
    }
    if (!isSubtitleCustom(content.subtitle) || !date) {
      if (lines?.title >= 3) {
        return 'long-title';
      }
    }
    if (lines?.desc >= 2 && lines?.title === 1) {
      return 'long-description';
    }
    return 'rest';
  }

  function getNumberOfLines() {
    return elements?.title?.map((element: any, index: any) => {
      const TITLE_HEIGHT = 27;
      const DESC_HEIGHT = 22;
      const fullTitleHeight = element?.getClientRects()[0]?.height;
      const fullDescHeight = elements?.desc[index]?.getClientRects()[0]?.height;
      const linesOfTitle = Math.round(fullTitleHeight / TITLE_HEIGHT);
      const linesOfDesc = Math.round(fullDescHeight / DESC_HEIGHT);
      if (content?.name === element?.textContent) {
        setLines({ title: linesOfTitle, desc: linesOfDesc });
      }
    });
  }

  useEffect(() => {
    if (elements.title?.length) {
      getNumberOfLines();
    }
  }, [content, elements]);

  useEffect(() => {
    const title = document.querySelectorAll('#titleCard');
    const desc = document.querySelectorAll('#descriptionCard');
    setElements({ title: Array.from(title), desc: Array.from(desc) });
  }, []);

  return (
    <div
      data-cy="horizontalContentCard"
      className={cx(styles.HorizontalContentCard, className)}
      style={style}
      data-line-clamp={getLineClamp()}
    >
      {mode !== 'preview' ? (
        content?.backgroundImage && (
          <div
            className={styles.imageWrapper}
            style={{
              backgroundImage: `url(${imageUrl(content?.backgroundImage)})`,
            }}
          >
            {priceOnCardFlag && paymentFeature && (
              <div className={styles.priceTag}>
                <ContentPriceTag content={content} shortenInterval />
              </div>
            )}
          </div>
        )
      ) : (
        <div
          className={styles.imageWrapper}
          style={{
            backgroundImage: `url(${imageUrl(content?.backgroundImage)})`,
          }}
        >
          {priceOnCardFlag && paymentFeature && (
            <div className={styles.priceTag}>
              <ContentPriceTag content={content} shortenInterval />
            </div>
          )}
          {!content?.backgroundImage && (
            <Icon name="image" style={{ fontSize: '3em', color: '#e0e0e0' }} />
          )}
        </div>
      )}
      <div className={styles.textWrapper}>
        {content?.startDate && content?.endDate && (
          <ContentStatusPill
            startDate={content.startDate}
            endDate={content.endDate}
            isPreview={mode === 'preview'}
          />
        )}

        {isSubtitleDate(content.subtitle) && content?.startDate && (
          <XS className={styles.date}>{date}</XS>
        )}

        {isSubtitleCustom(content.subtitle) && (
          <XS className={styles.subtitle}>{content?.subtitle}</XS>
        )}
        <H4 className={cx(styles.hidden, styles.hiddenTitle)} id="titleCard">
          {useTitleText({ mode, content })}
        </H4>

        <S
          className={cx(styles.hidden, styles.hiddenDesc)}
          id="descriptionCard"
        >
          {useDescriptionText({ mode, content })}
        </S>

        <H4 className={styles.text}> {useTitleText({ mode, content })}</H4>

        <S className={styles.description}>
          {useDescriptionText({ mode, content })}
        </S>

        {content?.generatedChildren?.pageInfo?.total && (
          <GeneratorContentCount
            count={content?.generatedChildren?.pageInfo?.total}
          />
        )}
      </div>
      {mode !== 'preview' &&
        !content?.backgroundImage &&
        priceOnCardFlag &&
        paymentFeature && (
          <div className={styles.priceTagWithoutBg}>
            <ContentPriceTag content={content} shortenInterval />
          </div>
        )}
    </div>
  );
}

export default memo(HorizontalContentCard);
