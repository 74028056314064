import { setMjmlComponent } from 'lane-shared/emails/MjmlComponents';

import Mjml from './Mjml';
import MjmlBody from './MjmlBody';
import MjmlButton from './MjmlButton';
import MjmlColumn from './MjmlColumn';
import MjmlDivider from './MjmlDivider';
import MjmlHead from './MjmlHead';
import MjmlImage from './MjmlImage';
import MjmlPreview from './MjmlPreview';
import MjmlRaw from './MjmlRaw';
import MjmlSection from './MjmlSection';
import MjmlSocial from './MjmlSocial';
import MjmlSocialElement from './MjmlSocialElement';
import MjmlSpacer from './MjmlSpacer';
import MjmlStyle from './MjmlStyle';
import MjmlText from './MjmlText';
import MjmlTitle from './MjmlTitle';
import MjmlWrapper from './MjmlWrapper';

// import all the Mjml web components and setup our Mjml engine with
// the correct components.

setMjmlComponent('Mjml', Mjml);
setMjmlComponent('MjmlSection', MjmlSection);
setMjmlComponent('MjmlColumn', MjmlColumn);
setMjmlComponent('MjmlButton', MjmlButton);
setMjmlComponent('MjmlImage', MjmlImage);
setMjmlComponent('MjmlHead', MjmlHead);
setMjmlComponent('MjmlTitle', MjmlTitle);
setMjmlComponent('MjmlPreview', MjmlPreview);
setMjmlComponent('MjmlBody', MjmlBody);
setMjmlComponent('MjmlStyle', MjmlStyle);
setMjmlComponent('MjmlRaw', MjmlRaw);
setMjmlComponent('MjmlSocial', MjmlSocial);
setMjmlComponent('MjmlDivider', MjmlDivider);
setMjmlComponent('MjmlSocialElement', MjmlSocialElement);
setMjmlComponent('MjmlWrapper', MjmlWrapper);
setMjmlComponent('MjmlText', MjmlText);
setMjmlComponent('MjmlSpacer', MjmlSpacer);
