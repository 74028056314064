export default function getDisplayName(
  obj:
    | {
        name?: string;
        profile?: {
          name?: string | null | undefined;
        };
      }
    | null
    | undefined
): string {
  if (obj?.profile?.name) {
    return obj.profile.name.trim();
  }

  if (obj?.name) {
    return obj.name.trim();
  }

  return '';
}

export function getDisplayProfileName(
  obj:
    | {
        profile?: {
          name?: string | null | undefined;
        };
      }
    | null
    | undefined
) {
  return obj?.profile?.name?.trim() || '';
}
