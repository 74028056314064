/**
 * clamps a number between an array size, and cycles the number based on its
 * value vs. the array size.
 *
 * @param value
 * @param arr
 * @returns {*}
 */
export default function arrayClamp(value: number, arr: any[]): number {
  if (!arr?.length) {
    return 0;
  }

  if (value < 0) {
    return arr.length - 1;
  }

  if (value > arr.length - 1) {
    return 0;
  }

  return value;
}
