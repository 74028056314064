import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'lane-shared/types/integrations/IntegrationEnums';

import './types';

const BuildingEnginesPrismVisitorsDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.BuildingEnginesPrismVisitors,
  hasCustomUI: true,
  friendlyName: 'BuildingEngines: Prism Visitors API',
  description:
    'Defines the properties required for a BuildingEngines Prism integration',
  category: IntegrationCategoryEnum.VisitorRegistration,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Api,
  settings: {
    baseUrl: {
      friendlyName: 'Base URL',
      description:
        'The base URL of the BuildingEngines Prism Visitors API endpoint',
      type: 'Url',
      default: 'https://api.connect.buildingengines.com',
      editable: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    name: {
      friendlyName: 'Display Name',
      type: 'String',
      description:
        'Name that to be displayed on BuildingEngines Prism Visitors form',
      default: 'BuildingEngines Prism Visitors',
    },
    subtitle: {
      friendlyName: 'Subtitle',
      type: 'String',
      description: 'Subtitle for BuildingEngines Prism Visitors form',
      default: 'Tenant Service Request for Vistiors',
    },
    description: {
      friendlyName: 'Service Description',
      type: 'LongText',
      description:
        'Details for end user for BuildingEngines Prism Visitors form',
      default: 'Submit your BuildingEngines Prism Visitors request here.',
    },
    buildingId: {
      friendlyName: 'Building ID',
      type: 'String',
      description: 'Building ID that is stored in BuildingEngines system',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    supportEmail: {
      friendlyName: 'Support contact Email',
      type: 'Email',
    },
  },
  interactionData: {
    organizationId: {
      friendlyName: 'Organization',
      description: 'The unique id for the organization',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    spaceId: {
      friendlyName: 'Floor/Space',
      description: 'The unique id for the location of this request.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    instructions: {
      friendlyName: 'Instructions for staff',
      description: 'Leave your instructions for staff',
      type: 'String',
    },
    startDate: {
      friendlyName: 'Start Date',
      description: 'Start date of the visit',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    visitors: {
      friendlyName: 'Visitors',
      type: 'BEVisitor',
      isArray: true,
      default: [],
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
      ],
    },
  },
};

export default BuildingEnginesPrismVisitorsDefinition;
