export async function login(url: string, email: string, signal?: AbortSignal) {
  if (url.endsWith('/')) {
    // cut off "/" from the end of passed URL
    url = url.slice(0, -1);
  }

  return fetch(`${url}/auth`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
    signal,
  });
}

export async function fetchWorkorders(
  endpoint: string,
  tenx_token: string, // from auth response stored in the cookie
  user_token: string, // from auth response stored in the cookie
  building_id: string, // should be taken from the integration configuration
  signal?: AbortSignal
) {
  if (endpoint.endsWith('/')) {
    // cut off "/" from the end of passed URL
    endpoint = endpoint.slice(0, -1);
  }

  const response = await fetch(`${endpoint}/work_orders`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tenx_token,
      user_token,
      building_id,
    }),
    signal,
  });

  return response.json();
}

export async function fetchWorkorderFormData(
  endpoint: string,
  tenx_token: string, // from auth response stored in the cookie
  user_token: string, // from auth response stored in the cookie
  building_id: string, // should be taken from the integration configuration
  signal?: AbortSignal
): Promise<any> {
  if (endpoint.endsWith('/')) {
    // cut off "/" from the end of passed URL
    endpoint = endpoint.slice(0, -1);
  }

  const rs = await fetch(`${endpoint}/work_orders/form`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tenx_token,
      user_token,
      building_id,
    }),
    signal,
  });

  return rs.json();
}

export async function submitWorkorder(
  endpoint: string,
  tenx_token: string, // from auth response stored in the cookie
  user_token: string, // from auth response stored in the cookie
  building_id: string, // should be taken from the integration configuration
  space_id: string, // space ID retuned from /work_orders/form
  floor: {
    id: string; // floor ID retuned from /work_orders/form
    name: string; // floor name retuned from /work_orders/form
  },
  issue_type: {
    id: string; // issue ID retuned from /work_orders/form
    primary_type: string; // primary type retuned from /work_orders/form
    name: string; // issue name retuned from /work_orders/form
  },
  description: string, // issue description types by a user
  signal?: AbortSignal
): Promise<any> {
  if (endpoint.endsWith('/')) {
    // cut off "/" from the end of passed URL
    endpoint = endpoint.slice(0, -1);
  }

  const rs = await fetch(`${endpoint}/work_orders/new`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tenx_token,
      user_token,
      building_id,
      space_id,
      floor,
      issue_type,
      description,
    }),
    signal,
  });

  return rs.json();
}

export async function addCommentToWorkOrder(
  endpoint: string,
  tenx_token: string, // from auth response stored in the cookie
  user_token: string, // from auth response stored in the cookie
  building_id: string, // should be taken from the integration configuration
  comment_content: string,
  work_order_id: string,
  description?: string // issue description types by a user
): Promise<any> {
  if (endpoint.endsWith('/')) {
    // cut off "/" from the end of passed URL
    endpoint = endpoint.slice(0, -1);
  }

  const rs = await fetch(`${endpoint}/work_orders/add_comment`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tenx_token,
      user_token,
      building_id,
      work_order_id,
      comment_content,
      description,
    }),
  });

  return rs.json();
}
