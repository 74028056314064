import React from 'react';

import cx from 'classnames';

import { getMessagesFromError } from 'lane-shared/helpers';

import Alert, { AlertType } from 'components/lds/Alert';

import styles from './ErrorMessage.scss';

type OwnProps = {
  error: any;
  className?: string;
  style?: React.CSSProperties;
  color?: AlertType;
  fullWidth?: boolean;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof ErrorMessage.defaultProps;

export default function ErrorMessage({
  error,
  className,
  style,
  color = AlertType.error,
  fullWidth = false,
}: Props) {
  const messages = getMessagesFromError(error);

  if (!error) {
    return null;
  }

  return (
    <ul
      className={cx(styles.ErrorMessage, className)}
      data-cy="error"
      style={style}
    >
      {messages.map(message => (
        <Alert
          type={color}
          className={styles.alert}
          key={message}
          fullWidth={fullWidth}
        >
          {message}
        </Alert>
      ))}
    </ul>
  );
}

ErrorMessage.defaultProps = {
  error: null,
  color: AlertType.error,
};
