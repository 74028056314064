import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import './types/MenuFeatureCategory';
import './types/MenuFeatureItem';

export const MENU_MAX_CATEGORIES = 10;
export const MENU_MAX_ITEMS = 64;

const Menu: FeatureDefinition = {
  name: FeatureNameEnum.Menu,
  friendlyName: 'web.content.features.menu.friendlyName',
  description: 'web.content.features.menu.description',
  requiresInteraction: true,
  superUserOnly: false,
  properties: {
    supportContent: {
      friendlyName: 'Support Page',
      description:
        'web.content.features.menu.properties.supportContent.description',
      type: 'Content',
      default: null,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    categories: {
      friendlyName: 'Categories',
      description: 'Define your menu categories here.',
      isArray: true,
      default: [],
      type: 'MenuFeatureCategory',
      validators: [
        {
          name: 'ArrayMax',
          value: MENU_MAX_CATEGORIES,
        },
      ],
    },
    items: {
      friendlyName: 'Menu Items',
      description: 'The Items in this menu',
      type: 'MenuFeatureItem',
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMax',
          value: MENU_MAX_ITEMS,
        },
      ],
    },
  },
  // the Menu hook has its own validation, so we don't need to set this here
  interactionData: {
    order: {
      forDisplay: false,
      friendlyName: 'Menu Order',
      type: 'JSON',
    },
  },
};

export default Menu;
