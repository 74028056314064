import {
  Invoice,
  INVOICE_STATUS,
} from 'lane-shared/domains/billingPayments/types';

import { getInvoiceStatus } from '.';

export function isInvoicePayable(invoice: Invoice): boolean {
  const status = getInvoiceStatus(invoice.status, invoice)[0];
  if (
    status === INVOICE_STATUS.INVOICE_PAID ||
    status === INVOICE_STATUS.INVOICE_VOID ||
    status === INVOICE_STATUS.INVOICE_PENDING
  )
    return false;
  return true;
}
