import * as yup from 'yup';

export default yup.object().shape({
  provider: yup.string().trim().required(),
  iosClientId: yup.string().trim().required(),
  androidClientId: yup.string().trim().required(),
  iosRedirectUrl: yup.string().trim().required(),
  webClientId: yup.string().trim().required(),
  webClientSecret: yup.string().trim(),
  webRedirectUrl: yup.string().required(),
  androidRedirectUrl: yup.string().trim().required(),
  tenantId: yup.string().trim().notRequired(),
  enabled: yup.boolean().required(),
});
