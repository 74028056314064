import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as yup from 'yup';

// https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export default yup
  .string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .test('test-phone', 'Phone number is not valid', phone => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phone || '');

    return Boolean(parsedPhoneNumber?.isValid());
  });
