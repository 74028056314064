import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { FlexDirectionEnum } from '../../types/blocks/Flex';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

export class FlexDirectionType extends StringTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'FlexDirection';

  friendlyName = 'Scroll Orientation';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  superUserOnly = false;

  get example() {
    return 'row';
  }

  get default(): any {
    return 'row';
  }

  buildSchema() {
    return yup
      .string()
      .nullable()
      .oneOf(
        Object.keys(FlexDirectionEnum).map(
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          enumKey => FlexDirectionEnum[enumKey]
        )
      );
  }
}

const definition = new FlexDirectionType();

Types.addBaseType(definition);

export default definition;
