import { ClassNames } from 'react-day-picker';

import rdpStyles from 'react-day-picker/dist/style.module.css';

import styles from './Calendar.scss';

// We need to provide our own class names to react-day-picker to modify styles
// https://react-day-picker.js.org/api/types/StyledElement#type-declaration
export const classNames: ClassNames = {
  ...rdpStyles,
  nav: styles.nav,
  caption_label: styles.captionLabel,
  caption: styles.caption,
  caption_start: styles.captionStart,
  caption_end: styles.captionEnd,
  day: styles.day,
  day_today: styles.dayToday,
  day_range_start: styles.dayRangeStart,
  day_range_middle: styles.dayRangeMiddle,
  day_range_end: styles.dayRangeEnd,
  nav_button: styles.navBbutton,
  month: styles.month,
  multiple_months: styles.multipleMonths,
  table: styles.table,
  cell: styles.cell,
  head_cell: styles.headCell,
};
