import { useContext } from 'react';

import { PERMISSION_SETTINGS_DEBUGGER } from 'lane-shared/helpers/constants/permissions';
import useFlag from 'lane-shared/hooks/useFlag';

import { UserDataContext, ChannelsContext } from '../contexts';
import { hasPermission } from '../helpers';

type FeatureFlagsType = {
  enableAccessControlDebugging: boolean;
  enableWebviews: boolean;
};

/**
 * A useful hook for storing feature flag logic
 *
 * When adding a feature flag, please add helpful comments wherever the flag
 * is used to help when removing it.
 *
 * When removing a feature flag, make sure to remove it here too
 */
export default function useFeatureFlags(): FeatureFlagsType {
  const { user } = useContext(UserDataContext);
  const { focusOnChannels } = useContext(ChannelsContext);

  const enableAccessControlDebugging =
    user?.isSuperUser ||
    hasPermission(
      user?.roles?.filter(role =>
        focusOnChannels.some(
          channel => channel._id === role.groupRole?.channel?._id
        )
      ),
      [PERMISSION_SETTINGS_DEBUGGER],
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      null,
      false
    );
  const useFlagResponse = useFlag('enable-web-views', true);
  const enableWebviews = user?.isSuperUser || Boolean(useFlagResponse);

  return {
    enableAccessControlDebugging,
    enableWebviews,
  };
}
