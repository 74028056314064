import { AddressType } from '../../types/AddressType';
import addressFormatter from './addressFormatter';

export default function longAddress(
  address: Partial<AddressType> | null | undefined
) {
  return addressFormatter(address, [
    'name',
    'street1',
    'street2',
    'city',
    'state',
    'country',
    'code',
  ]);
}
