import { ProfileType } from '../../types/ProfileType';

export type MemberRoleType = {
  _id: string;
  _created: Date;
  name: string;
  description: string;
  isPublic: boolean;
  expires: Date;
  groupRole: {
    _id: string;
    name: string;
    isPublic: boolean;
    channel: {
      _id: string;
      name: string;
      slug: string;
      type: string;
      parent?: {
        _id: string;
      };
      isPublic: boolean;
      isSub: boolean;
      profile: ProfileType;
    };
  };
};

export type ChannelRolesType = {
  _id: string;
  name: string;
  slug: string;
  type: string;
  isPublic: boolean;
  isSub: boolean;
  parent?: {
    _id: string;
  };
  profile: ProfileType;
  roles: MemberRoleType[];
};

export default function collapseMemberChannels(
  roles: MemberRoleType[]
): ChannelRolesType[] {
  const channels = {};

  roles.forEach(role => {
    const { channel } = role.groupRole;

    if (!channel) {
      return;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!channels[channel._id]) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      channels[channel._id] = { ...channel, roles: [] };
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    channels[channel._id].roles.push(role);
  });

  return Object.values(channels);
}
