import React from 'react';

import { useTranslation } from 'react-i18next';

import { CHANNEL_DIRECTORY_TYPES } from 'lane-shared/helpers/constants/channel';

import ButtonStrip from '../general/ButtonStrip';

export default function DirectoryInput({ onChange, value }: any) {
  const { t } = useTranslation();
  const buttonLabels = CHANNEL_DIRECTORY_TYPES.map(type =>
    t(`web.admin.channel.content.directoryInput.${type.toLowerCase()}`)
  );

  return (
    <ButtonStrip buttons={buttonLabels} onClick={onChange} selected={value} />
  );
}
