import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

import { Modal } from 'components/lds';
import { M } from 'components/typography';

import { Button } from 'design-system-web';

import styles from './styles.scss';

type props = {
  when: boolean;
  path: string;
  onButtonPress: (type: 'save' | 'discard') => void;
};

export function UnsavedChangesPrompt({ onButtonPress, when, path }: props) {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [backButtonPressed, setBackButtonPressed] = useState(false);

  const history = useHistory();

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (
      !confirmedNavigation &&
      nextLocation.pathname !== window.location.pathname
    ) {
      setIsModalOpen(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const onClose = () => {
    setIsModalOpen(false);
    setConfirmedNavigation(true);
    onButtonPress('discard');
  };

  const onSave = () => {
    setIsModalOpen(false);
    onButtonPress('save');
  };

  const backNavigationBlock = () => {
    setIsModalOpen(true);
    setBackButtonPressed(true);
  };

  useEffect(() => {
    if (backButtonPressed && confirmedNavigation) {
      history.push(path);
    } else if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, backButtonPressed]);

  useEffect(() => {
    if (when) {
      window.history.pushState(null, '', window.location.pathname);
      window.addEventListener('popstate', backNavigationBlock);
      return () => {
        window.removeEventListener('popstate', backNavigationBlock);
      };
    }
    return () => {};
  });

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={t('web.admin.accessControl.unsavedChanges')}
        className={styles.modal}
      >
        <M>{t('web.admin.accessControl.discardUnsavedChanges')}</M>
        <div className={cx(styles.unsavedChangesButtons)}>
          <Button
            variant="primary"
            style={{ marginRight: '24px' }}
            onClick={onClose}
            size="large"
          >
            {t('web.admin.accessControl.unsavedChanges.discard')}
          </Button>
          <Button
            variant="text"
            className={styles.cancelButton}
            onClick={onSave}
            size="large"
          >
            {t('Save changes')}
          </Button>
        </div>
      </Modal>
    </>
  );
}
