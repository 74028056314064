import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getLibraryOptions,
  getValidationMessages,
  getTextColor,
} from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { EditorModeEnum } from 'lane-shared/types/EditorModeEnum';
import { ProfileType } from 'lane-shared/types/ProfileType';
import { ThemeType } from 'lane-shared/types/Theme';
import { LibraryType } from 'lane-shared/types/libraries';

import ColorPickerButton from 'components/form/ColorPickerButton';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import SimpleInput from 'components/form/SimpleInput';
import TextArea from 'components/form/TextArea';
import Label from 'components/general/Label';
import MediaPickerButton from 'components/lane/MediaPickerButton';
import ThemePaletteColorSelectorButton from 'components/lane/ThemePaletteColorSelectorButton';

import styles from './ProfileEdit.scss';

type Props = {
  theme?: ThemeType;
  channel?: ChannelType;
  library?: LibraryType;
  profile: ProfileType;
  onProfileUpdated: (profile: ProfileType) => void;
  validation: any | any[];
  validationPath?: string;
  className?: string;
  style?: React.CSSProperties;
  mode: EditorModeEnum.Channel | EditorModeEnum.Whitelabel | null;
};

const TRANSLATION_KEYS = {
  description:
    'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.description',
  displayName: 'web.components.lane.ProfileEdit.displayName',
  supportEmail: 'web.components.lane.ProfileEdit.supportEmail',
  noReplyEmail: 'web.components.lane.ProfileEdit.noReplyEmail',
  phoneNumber: 'web.components.lane.ProfileEdit.phoneNumber',
  accentColor: 'web.components.lane.ProfileEdit.accentColor',
  logo: 'web.components.lane.ProfileEdit.logo',
  wordmark: 'web.components.lane.ProfileEdit.wordmark',
  heroImage: 'web.components.lane.ProfileEdit.heroImage',
  backgroundImage: 'web.components.lane.ProfileEdit.backgroundImage',
};

export default function ProfileEdit({
  theme,
  channel,
  library,
  profile,
  onProfileUpdated,
  validation,
  validationPath = 'profile',
  className,
  style,
  mode,
}: Props) {
  const { t } = useTranslation();

  function backgroundColorChanged(backgroundColor: any) {
    onProfileUpdated({
      ...profile,
      color: getTextColor(backgroundColor),
      backgroundColor,
    });
  }

  const isWhitelabelProfile = !mode || mode === EditorModeEnum.Whitelabel;

  return (
    <div className={cx(styles.ProfileEdit, className)} style={style}>
      <Label className={styles.label}>{t(TRANSLATION_KEYS.displayName)}</Label>
      <SimpleInput
        dataCy="profileDisplayName"
        className={styles.input}
        errors={getValidationMessages(validation, `${validationPath}.name`)}
        placeholder="Name…"
        value={profile.name}
        onChange={name => onProfileUpdated({ ...profile, name })}
      />
      {isWhitelabelProfile && (
        <>
          <Label className={styles.label}>
            {t(TRANSLATION_KEYS.description)}
          </Label>
          <TextArea
            placeholder="Description…"
            dataCy="profileDisplayDescription"
            errors={getValidationMessages(
              validation,
              `${validationPath}.description`
            )}
            className={cx(styles.textArea, styles.input)}
            minRows={3}
            value={profile.description}
            onChange={description =>
              onProfileUpdated({ ...profile, description })
            }
          />
        </>
      )}

      <Label className={styles.label}>{t(TRANSLATION_KEYS.supportEmail)}</Label>
      <SimpleInput
        className={styles.input}
        dataCy="profileDisplayEmail"
        errors={getValidationMessages(validation, `${validationPath}.email`)}
        placeholder="example@email.com"
        value={profile.email}
        onChange={email => onProfileUpdated({ ...profile, email })}
      />

      {isWhitelabelProfile && (
        <>
          <Label className={styles.label}>
            {t(TRANSLATION_KEYS.noReplyEmail)}
          </Label>
          <SimpleInput
            className={styles.input}
            dataCy="profileDisplayNoReplyEmail"
            errors={getValidationMessages(
              validation,
              `${validationPath}.noReplyEmail`
            )}
            placeholder="no-reply@email.com"
            value={profile.noReplyEmail}
            // TODO: fix
             
            onChange={noReplyEmail =>
              onProfileUpdated({ ...profile, noReplyEmail })
            }
          />
        </>
      )}

      {isWhitelabelProfile && (
        <>
          <Label className={styles.label}>
            {t(TRANSLATION_KEYS.phoneNumber)}
          </Label>
          <PhoneNumberInput
            dataCy="profileDisplayNumber"
            onChange={phone => onProfileUpdated({ ...profile, phone })}
            value={profile.phone}
          />

          <Label className={styles.label}>
            {t(TRANSLATION_KEYS.accentColor)}
          </Label>
          {theme ? (
            <ThemePaletteColorSelectorButton
              value={profile.backgroundColor}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'ThemeColorPaletteType | undefined' is not as... Remove this comment to see the full error message
              palette={theme?.palette}
              onColorSelected={backgroundColorChanged}
            />
          ) : (
            <ColorPickerButton
              dataCy="profileColorPicker"
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
              value={profile.backgroundColor}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(backgroundColor: any) => void' is not assig... Remove this comment to see the full error message
              onChange={backgroundColorChanged}
            />
          )}
        </>
      )}

      <Label className={styles.label}>{t(TRANSLATION_KEYS.logo)}</Label>
      <div className={styles.imagePicker}>
        <MediaPickerButton
          libraries={getLibraryOptions({ channel, library })}
          errors={getValidationMessages(validation, `${validationPath}.logo`)}
          media={{ _id: profile.logo }}
          storageKey={channel?._id}
          onMediaSelected={media =>
            onProfileUpdated({
              ...profile,
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              logo: (media && media._id) || null,
            })
          }
          className={styles.imageButton}
          dataCy="profileLogoMediaPicker"
        />

        <div className={styles.imageWrapper}>
          <div
            className={cx(styles.image, styles.logo)}
            style={{ backgroundImage: `url(${imageUrl(profile.logo)})` }}
          />
        </div>
      </div>

      {isWhitelabelProfile && (
        <>
          <Label className={styles.label}>{t(TRANSLATION_KEYS.wordmark)}</Label>
          <div className={styles.imagePicker}>
            <MediaPickerButton
              libraries={getLibraryOptions({ channel, library })}
              errors={getValidationMessages(
                validation,
                `${validationPath}.wordmark`
              )}
              media={{ _id: profile.wordmark }}
              storageKey={channel?._id}
              onMediaSelected={media =>
                onProfileUpdated({
                  ...profile,
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                  wordmark: (media && media._id) || null,
                })
              }
              className={styles.imageButton}
              dataCy="profileWordmarkMediaPicker"
            />

            <div className={styles.imageWrapper}>
              <div
                className={cx(styles.image, styles.logo)}
                style={{
                  backgroundImage: `url(${imageUrl(profile.wordmark)})`,
                }}
              />
            </div>
          </div>

          <Label className={styles.label}>
            {t(TRANSLATION_KEYS.heroImage)}
          </Label>
          <div className={styles.imagePicker}>
            <MediaPickerButton
              libraries={getLibraryOptions({ channel, library })}
              errors={getValidationMessages(
                validation,
                `${validationPath}.image`
              )}
              media={{ _id: profile.image }}
              storageKey={channel?._id}
              onMediaSelected={media =>
                onProfileUpdated({
                  ...profile,
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                  image: (media && media._id) || null,
                })
              }
              className={styles.imageButton}
              dataCy="profileHeroMediaPicker"
            />

            <div className={styles.imageWrapper}>
              <div
                className={cx(styles.image, styles.hero)}
                style={{ backgroundImage: `url(${imageUrl(profile.image)})` }}
              />
            </div>
          </div>
          <Label className={styles.label}>
            {t(TRANSLATION_KEYS.backgroundImage)}
          </Label>
          <div className={styles.imagePicker}>
            <MediaPickerButton
              libraries={getLibraryOptions({ channel, library })}
              errors={getValidationMessages(
                validation,
                `${validationPath}.backgroundImage`
              )}
              media={{ _id: profile.backgroundImage }}
              storageKey={channel?._id}
              onMediaSelected={media =>
                onProfileUpdated({
                  ...profile,
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                  backgroundImage: (media && media._id) || null,
                })
              }
              className={styles.imageButton}
              dataCy="profileBackgroundMediaPicker"
            />

            <div className={styles.imageWrapper}>
              <div
                className={cx(styles.image, styles.logo)}
                style={{
                  backgroundImage: `url(${imageUrl(profile.backgroundImage)})`,
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
