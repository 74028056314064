import { LaneIntegrationType } from 'common-types';
import { getClient } from 'lane-shared/apollo';

import mutation from '../graphql/updateRsvp';

type Props = {
  interaction: any;
  rsvpInteractionData: any;
  status: string;
};

export default async function updateRSVPdata({
  interaction,
  rsvpInteractionData,
  status,
}: Props): Promise<LaneIntegrationType.ShopifyRsvpInteraction> {
  const { data } = await getClient().mutate({
    mutation,
    refetchQueries: ['searchContentInteractions'],
    variables: {
      interaction: {
        _id: interaction._id,
        data: rsvpInteractionData,
        status,
      },
    },
  });

  return data.createContentInteraction;
}
