import { ChannelType } from '../../types/ChannelType';
import { UserGroupRoleType } from '../../types/UserGroupRole';
import {
  PERMISSION_PORTAL_ACCESS,
  PERMISSION_PORTAL_ACCESS_MOBILE,
} from '../constants/permissions';

export default function hasAdminOnChannel(
  roles: UserGroupRoleType[] = [],
  channel: ChannelType,
  forMobile: boolean = false
): boolean {
  return roles?.some(
    ({ groupRole }) =>
      groupRole.channel?.hierarchy?.hierarchyDown?.includes(channel?._id) &&
      groupRole.permissions.includes(
        forMobile ? PERMISSION_PORTAL_ACCESS_MOBILE : PERMISSION_PORTAL_ACCESS
      )
  );
}
