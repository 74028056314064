import {
  PropertiesInterface,
  PropertyType,
} from '../../types/properties/Property';
import byOrder from '../sort/byOrder';

export default function definitionToProperties(
  definition: PropertiesInterface
): [string, PropertyType][] {
  if (!definition) {
    return [];
  }

  return Object.entries(definition)
    .sort((a, b) => byOrder(a[1], b[1]))
    .filter(([, { forDisplay }]) => forDisplay !== false);
}
