import { useQuery } from '@apollo/client';

import { adminInteraction } from '../graphql/query';
import { useMultiLanguage } from './useMultiLanguage';

export default function useInteractionQuery({
  interactionId,
}: {
  interactionId: string;
}) {
  const { translate } = useMultiLanguage();
  const { data, loading, error } = useQuery(adminInteraction, {
    skip: !interactionId,
    variables: {
      id: interactionId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const interaction = data?.interaction
    ? {
        ...data?.interaction,
        contentData: translate({
          model: data?.interaction?.contentData,
          columns: ['name', 'description', 'subtitle'],
        }),
      }
    : undefined;

  return {
    loading,
    error,
    interaction,
  };
}
