import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getTeam } from '../graphql/query';

export default function useTeamQuery({ groupRoleId }: any) {
  const [fetchTeam, { data, loading, error }] = useLazyQuery(getTeam, {
    client: getClient(),
  });

  useEffect(() => {
    if (groupRoleId) {
      fetchTeam({
        variables: {
          id: groupRoleId,
        },
      });
    }
  }, [groupRoleId]);
  const team = data?.team;

  return {
    team,
    loading,
    error,
  };
}
