import getTimeZoneFromPort from 'lane-shared/helpers/integrations/ShopifyBurst/getTimeZoneFromPort';
import { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

export default function getBurstContentTimeZone({
  ports,
  channelId,
  timeZone,
}: {
  ports?: ShopifyChannelType[];
  channelId?: string;
  timeZone?: string;
}) {
  if (!ports || !ports.length) {
    return timeZone;
  }
  const port = ports?.find(({ _id }) => _id === channelId);
  return getTimeZoneFromPort(port);
}
