import React from 'react';

import cx from 'classnames';
import { Flex } from 'components';
import { useTranslation } from 'react-i18next';

import { TimeRangeType } from 'lane-shared/types/baseTypes/TimeRangeType';

import TimePicker from '../form/DatePickers/TimePicker';

import styles from './TimeRangeInput.scss';

export type TimeRangeInputProps = {
  className?: string;
  style?: React.CSSProperties;
  value?: TimeRangeType;
  timeZone?: string;
  useDates?: boolean;
  unit?: number;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onChange: (TimeRangeType) => void;
};

export default function TimeRangeInput({
  useDates = false,
  value,
  onChange,
  timeZone,
  unit,
  className,
  style,
}: TimeRangeInputProps) {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.container, className)} style={style}>
      <Flex gap={2}>
        <TimePicker
          className={styles.timePicker}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          timeZone={timeZone}
          value={value?.startTime}
          useDates={useDates}
          label={t('Start time')}
          unit={unit}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(startTime: any) => void' is not assignable ... Remove this comment to see the full error message
          onChange={(startTime: any) =>
            onChange({
              ...value,
              startTime,
            })
          }
        />
        <TimePicker
          className={styles.timePicker}
          value={value?.endTime}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          timeZone={timeZone}
          useDates={useDates}
          label={t('End Time')}
          unit={unit}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(endTime: any) => void' is not assignable to... Remove this comment to see the full error message
          onChange={(endTime: any) =>
            onChange({
              ...value,
              endTime,
            })
          }
        />
      </Flex>
    </div>
  );
}
