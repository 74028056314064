import React from 'react';

import cx from 'classnames';

import { useDateCell } from 'lane-shared/hooks';

import styles from './DateCell.scss';

type OwnDateCellProps = {
  // function to be called when element is clicked, returns selected day
  onClick: (date: Date) => void;
  //  JS date object for start date
  startDate: Date;
  //  JS date object for end date
  endDate: Date;
  //  JS date object for currently rendered day
  day: Date;
  //  JS date object for currently selected month
  monthStart: Date;
  // formatted day presentation
  text: string;
  // is this button enabled or not
  disabled?: boolean;
  // the timezone to display dates in
  timeZone: string;
  // is this date unavailable, similar to disabled but different visual display
  unavailable?: boolean;
  // is this an already selected date
  existing?: boolean;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type DateCellProps = OwnDateCellProps & typeof DateCell.defaultProps;

export default function DateCell({
  startDate,
  endDate,
  day,
  timeZone,
  monthStart,
  onClick,
  text,
  disabled,
  unavailable,
  existing,
}: DateCellProps) {
  const {
    isBetween,
    firstSelected,
    lastSelected,
    daySelected,
    otherMonth,
    rangeSelected,
  } = useDateCell({
    startDate,
    endDate,
    day,
    timeZone,
    monthStart,
  });
  return (
    <span
      className={cx(styles.DateCell, {
        [styles.between]: !firstSelected && !lastSelected && isBetween,
        [styles.firstSelected]: rangeSelected && firstSelected,
        [styles.lastSelected]: rangeSelected && lastSelected,
        [styles.daySelected]: daySelected,
        [styles.otherMonth]: otherMonth,
        [styles.unavailable]: unavailable,
        [styles.disabled]: disabled,
        [styles.existing]: existing,
      })}
    >
      <button
        className={cx(styles.button, {
          [styles.selected]: daySelected,
        })}
        // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
        onClick={() => onClick(day)}
      >
        {text}
      </button>
    </span>
  );
}

DateCell.defaultProps = {
  endDate: null,
  disabled: false,
  timeZone: null,
  unavailable: false,
};
