import { LaneType } from 'common-types';
import { updateMedia } from 'lane-shared/graphql/mutation';

import { getClient } from '../apollo';
import { createMedia } from '../graphql/media';
import { ImageType } from '../types/ImageType';
import { LibraryType } from '../types/libraries';
import { HTMLImage, MediaTypeEnum, ThumbnailType } from '../types/media';
import setLibraryOnLibraryItem from './setLibraryOnLibraryItem';
import uploadToS3 from './uploadToS3';

type Props = {
  selectedLibrary: LibraryType;
  _id?: LaneType.UUID;
  name: string;
  media: HTMLImage | ImageType | File;
  mediaBlob: Blob;
  type: MediaTypeEnum;
  thumbnail?: ThumbnailType | ImageType;
  thumbnailBlob?: Blob;
  path?: string | null;
  tags?: string[] | null;
  s3Bucket?: string;
};

type LibraryItemForUpload = {
  path?: string | null;
  tags?: {
    set: string[];
  };
  media: {
    _id: LaneType.UUID;
    name?: string;
    description?: string;
    s3Bucket?: string;
    type: MediaTypeEnum;
    file: {
      size: number;
      type: string;
      name: string;
      width?: number;
      height?: number;
    };
  };
};

export const createAndUploadMedia = async ({
  selectedLibrary,
  name,
  media,
  mediaBlob,
  thumbnail,
  thumbnailBlob,
  type,
  path,
  tags,
  s3Bucket,
  _id,
}: Props) => {
  const asset = {
    _id,
    name,
    type,
    description: '',
    file: {
      ...media,
      size: media.size,
      type: media.type,
      name: media.name,
      // remove the data-uri
      uri: undefined,
    },
    thumbnail: {},
    contentType: media.type,
  };

  if (type === MediaTypeEnum.Image) {
    asset.file = {
      ...asset.file,
      width: (media as HTMLImage).width,
      height: (media as HTMLImage).height,
    };
  }

  if (thumbnail && thumbnailBlob) {
    asset.thumbnail = {
      ...thumbnail,
      // remove the data-uri
      uri: undefined,
    };
  }

  const libraryItem = setLibraryOnLibraryItem(
    { media: asset },
    selectedLibrary
  ) as LibraryItemForUpload;

  if (path) {
    libraryItem.path = path;
  }

  if (tags) {
    // this is a StringListInput
    libraryItem.tags = {
      set: tags,
    };
  }

  if (s3Bucket) {
    libraryItem.media.s3Bucket = s3Bucket;
  }

  const shouldUpdate = !!_id;

  const newMedia = await updateOrCreateAndGetSignedUrl(
    shouldUpdate,
    libraryItem
  );

  await uploadToS3({
    signed: newMedia.fileSignedUrl,
    file: mediaBlob,
    contentType: media.type,
  });

  if (newMedia.originalExtension) {
    await uploadToS3({
      signed: newMedia.originalExtension,
      file: mediaBlob,
      contentType: media.type,
    });
  }

  if (thumbnail && thumbnailBlob) {
    await uploadToS3({
      signed: newMedia.thumbnailSignedUrl,
      file: thumbnailBlob,
      contentType: thumbnail.type,
    });
  }

  return newMedia;
};

async function updateOrCreateAndGetSignedUrl(
  shouldUpdate: boolean,
  libraryItem: LibraryItemForUpload
) {
  return shouldUpdate
    ? await updateMediaAndGetSignedUrl(libraryItem)
    : await createMediaAndGetSignedUrl(libraryItem);
}

async function createMediaAndGetSignedUrl(libraryItem: LibraryItemForUpload) {
  const { data } = await getClient().mutate({
    mutation: createMedia,
    variables: {
      libraryItem,
    },
  });

  return {
    ...data.createMedia.libraryItem.media,
    originalExtension: data.createMedia.originalExtension,
  };
}

async function updateMediaAndGetSignedUrl(libraryItem: LibraryItemForUpload) {
  const media = libraryItem.media;
  delete media.name;
  delete media.description;

  const { data } = await getClient().mutate({
    mutation: updateMedia,
    variables: {
      media,
    },
  });

  return {
    ...data.updateMedia.media,
    originalExtension: data.updateMedia.originalExtension,
  };
}
