import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { H5, S } from 'components/typography';

import variables from '../../static/style/_variables.scss';
import styles from './Stepper.scss';

const TRANSLATION_KEYS = {
  info: 'web.admin.content.draftContent.header.stepper.label.info',
  editor: 'web.admin.content.draftContent.header.stepper.label.editor',
  targeting: 'web.admin.content.draftContent.header.stepper.label.targeting',
  publish: 'web.admin.content.draftContent.header.stepper.label.publish',
  database: 'web.admin.content.draftContent.header.stepper.label.database',
  template: 'web.admin.content.draftContent.header.stepper.label.template',
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  // list of step titles ['Step1', 'Step2', ... , 'StepN']
  steps: string[];
  // index of active step
  active: number;
  // is the control disabled or not
  disabled?: boolean;
  // maxStep is the highest step that is allowed right now,
  // set to null if there is none
  maxStep?: number;
  // callback when a step is clicked
  onClick: (step: string) => void;
  // an array of steps that currently have errors
  errorSteps?: number[];
  // icon component <Icon name='star' />.
  iconName?: string;
  iconType?: string;
  alignment?: 'horizontal' | 'vertical';
  // add color gradient to the Stepper
  gradient?: boolean;
};

export default function Stepper({
  steps,
  active,
  disabled,
  style,
  maxStep,
  className,
  onClick,
  errorSteps = [],
  iconName,
  iconType,
  alignment = 'horizontal',
  gradient,
}: Props) {
  const { t } = useTranslation();
  function clicked(item: any, i: any) {
    if (disabled) {
      return;
    }

    if (maxStep == null || i <= maxStep) {
      onClick(item);
    }
  }

  const widthOfElement =
    alignment === 'vertical' ? '100%' : `${100 / steps.length}%`;
  const heightOfElement =
    alignment === 'horizontal' ? '100%' : `${100 / steps.length}%`;

  const getTranslatedStep = (step: string) => {
    switch (step) {
      case 'Info':
        return t(TRANSLATION_KEYS.info);
      case 'Editor':
        return t(TRANSLATION_KEYS.editor);
      case 'Targeting':
        return t(TRANSLATION_KEYS.targeting);
      case 'Publish':
        return t(TRANSLATION_KEYS.publish);
      case 'Database':
        return t(TRANSLATION_KEYS.database);
      case 'Template':
        return t(TRANSLATION_KEYS.template);
      default:
        return step;
    }
  };

  return (
    <div
      data-cy="stepper"
      className={cx(styles.stepper, disabled && styles.disabled, className)}
      style={style}
      data-orientation={alignment}
      data-lines={steps.length}
    >
      <div className={styles.steps}>
        {steps.map((item, i) => {
          const isActive = i <= active;
          const isActiveNext = i + 1 <= active;
          const isDisabled = maxStep == null ? false : i >= maxStep;
          const isError = errorSteps?.includes?.(i);

          return (
            <div
              className={styles.step}
              key={item}
              data-is-error={isError}
              data-is-active={isActiveNext}
              style={{ width: widthOfElement }}
            >
              <button
                className={styles.button}
                onClick={() => clicked(item, i)}
              >
                <div
                  className={styles.icon}
                  data-is-active={isActive}
                  data-is-gradient={isActive && gradient}
                  data-is-disabled={isDisabled}
                >
                  {iconName ? (
                    <Icon
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      type={iconType}
                      name={iconName}
                    />
                  ) : (
                    <H5>{i + 1}</H5>
                  )}
                </div>
                <div
                  className={styles.after}
                  data-is-gradient={isActive && gradient}
                />
              </button>
            </div>
          );
        })}
      </div>
      <div className={styles.labels}>
        {steps.map((item, i) => {
          const isDisabled = maxStep == null ? false : i >= maxStep;
          const isError = errorSteps?.includes?.(i);
          return (
            <div
              key={item}
              style={{
                width: widthOfElement,
                height: heightOfElement,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                key={item}
                className={styles.label}
                onClick={() => clicked(item, i)}
                data-is-active={i <= active}
                data-is-disabled={isDisabled}
              >
                <S
                  style={isError ? { color: variables.colorError } : {}}
                  className={styles.textLabel}
                >
                  {getTranslatedStep(item)}
                </S>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
