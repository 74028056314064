import React from 'react';

import useStatusColor, { Status } from 'lane-shared/hooks/useStatusColor';

import Pill from './Pill';

type OwnStatusPillProps = {
  text: string;
  size?: 'small' | 'medium';
  status: Status;
  className?: string;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type StatusPillProps = OwnStatusPillProps & typeof StatusPill.defaultProps;

export default function StatusPill({
  size,
  status,
  text,
  className,
}: StatusPillProps) {
  const color = useStatusColor({ status });
  return <Pill text={text} color={color} size={size} className={className} />;
}

StatusPill.defaultProps = {
  size: 'small',
};
