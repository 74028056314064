import { errorCodes } from 'activate-errors';
import getErrorCodes from '../getErrorCodes';

export default function getSubmitInteractionErrorMessage(err: any) {
  const errCodes = getErrorCodes(err);

  let title = "We weren't able to submit your entry!";
  let message = `Try to resolve the errors below and please try again.\n\n${err?.message}`;

  if (err?.message && err.message.includes('entries per Reset period')) {
    title = 'Already entered.';
    message = "You've already entered the maximum number of times.";
  } else if (err?.message && err.message.includes('JSON')) {
    title = 'Something went wrong';
    message = 'Please try again in a few minutes.';
  } else if (errCodes.includes(errorCodes.contentNotEnoughQuantity.code)) {
    message = 'Unfortunately, these have all been claimed.';
  } else if (errCodes.includes(errorCodes.contentRequestedMoreThanMax.code)) {
    message = 'You requested more than the maximum allowed amount per person.';
  }

  return {
    title,
    message,
  };
}
