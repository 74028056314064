import getValidationMessages from '../helpers/getValidationMessages';
import useValidation from './useValidation';

export default function useValidatedControl(
  value: any,
  isPristine: any,
  validationSchema: any
) {
  const [validationResults, validate] = useValidation(value, validationSchema);

  const validationMessages = validationResults
    ? // @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 1.
      getValidationMessages(validationResults)
    : [];

  const success = Boolean(
    !isPristine && validationMessages && validationMessages.length === 0
  );
  const error = Boolean(
    !isPristine && validationMessages && validationMessages.length > 0
  );

  return [validate, validationMessages, success, error] as const;
}
