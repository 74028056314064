import { useEffect } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import castForUpdate from '../helpers/castForUpdate';

const audienceQuery = gql`
  query estimateContentAudienceSize(
    $placements: [ContentPlacementInput!]
    $audiences: [AudienceIdInput!]
  ) {
    estimateContentAudienceSize(placements: $placements, audiences: $audiences)
  }
`;

export function useContentTargetingAudienceSize({
  placements = [],
  audiences = [],
}: {
  placements?: (
    | { channel?: { _id: string } }
    | { groupRole?: { _id: string } }
  )[];
  audiences?: { id?: string }[];
}) {
  const [fetchAudienceSize, { data, loading }] = useLazyQuery(audienceQuery, {
    client: getClient(),
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (placements?.length > 0 || audiences?.length > 0) {
      fetchAudienceSize({
        variables: {
          placements: castForUpdate(placements),
          audiences: castForUpdate(audiences),
        },
      });
    }
  }, [JSON.stringify(placements), JSON.stringify(audiences)]); // eslint-disable-line react-hooks/exhaustive-deps

  const audienceSize =
    placements?.length === 0 && audiences?.length === 0
      ? 0
      : data?.estimateContentAudienceSize || 0;

  return [audienceSize, loading];
}
