import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().trim().max(75).notRequired(),
  description: yup.string().trim().max(255),
  subtitle: yup.string().max(75).notRequired().nullable(),
  tags: yup.array().of(yup.string()).max(6),
  color: yup.string().required(),
  backgroundColor: yup.string().required(),
});
