import React from 'react';

import { v4 as uuid } from 'uuid';

import { getChannelIntegrations } from 'lane-shared/graphql/query';
import { RitualFilter } from 'lane-shared/helpers/constants/integrations/ritual';

import Dropdown from '../form/Dropdown';
import Loading from '../general/Loading';
import { useQuery } from '@apollo/client';
import { getIntegrationDisplayName } from 'lane-shared/helpers/integrations/getIntegrationDefinition';

type Props = {
  onChange?: (...args: any[]) => any;
  onValueChange?: (...args: any[]) => any;
  name?: string;
  schema?: {
    label?: string;
    value?: string;
  };
  initialValue?: string;
  placeholder?: string;
  value?: string | number;
  channelId?: string;
  className?: string;
};

function ChannelIntegrationDropdown({ channelId, ...otherProps }: Props) {
  const { data } = useQuery(getChannelIntegrations, {
    variables: { id: channelId },
    fetchPolicy: 'network-only',
  });
  if (!data || !data.channel) {
    return <Loading />;
  }

  const integrations = data?.channel.integrations ?? [];
  const filteredIntegrationsList = RitualFilter(integrations);
  const filteredDeletedAccessIntegrations = filteredIntegrationsList.filter(
    (integration: any) => {
      // if integration is active deletedAt is null
      // if integration is deleted it has a timestamp
      return !integration?.deletedAt;
    }
  );

  return (
    <Dropdown
      {...otherProps}
      items={filteredDeletedAccessIntegrations.map((integration: any) => ({
        label: getIntegrationDisplayName(integration),
        value: integration._id,
      }))}
    />
  );
}

ChannelIntegrationDropdown.defaultProps = {
  name: `Dropdown-${uuid()}`,
  color: 'primary',
  schema: {
    label: 'label',
    value: 'value',
  },
  initialValue: null,
  placeholder: 'Select Integration',
  value: null,
  onChange: () => {},
  onValueChange: () => {},
};

export default ChannelIntegrationDropdown;
