import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import { GuestInviteFeatureProperties } from '../../../types/features/GuestInviteFeatureProperties';
import './types/GuestInviteGuestType';

export const MAX_GUESTS = 200;

const GuestInvite: FeatureDefinition = {
  superUserOnly: false,
  name: FeatureNameEnum.GuestInvite,
  requiresInteraction: true,
  friendlyName: 'web.content.feature.guestInvite.friendlyName',
  description: 'web.content.feature.guestInvite.description',
  properties: {
    showLaneUsers: {
      type: 'Boolean',
      friendlyName:
        'web.content.feature.guestInvite.properties.showLaneUsers.friendlyName',
      description:
        'web.content.feature.guestInvite.properties.showLaneUsers.description',
      default: true,
      validators: [],
    },
    minGuests: {
      type: 'Number',
      friendlyName:
        'web.content.feature.guestInvite.properties.minGuests.friendlyName',
      description:
        'web.content.feature.guestInvite.properties.minGuests.description',
      default: 0,
      validators: [
        {
          name: 'Min',
          value: 0,
        },
        {
          name: 'Max',
          value: MAX_GUESTS,
        },
      ],
    },
    maxGuests: {
      type: 'Number',
      friendlyName:
        'web.content.feature.guestInvite.properties.maxGuests.friendlyName',
      description:
        'web.content.feature.guestInvite.properties.maxGuests.description',
      default: MAX_GUESTS,
      validators: [
        {
          name: 'Min',
          value: 0,
        },
        {
          name: 'Max',
          value: MAX_GUESTS,
        },
      ],
    },
  },
  interactionData: {
    guests: {
      friendlyName:
        'web.content.feature.guestInvite.interactionData.guests.friendlyName',
      description:
        'web.content.feature.guestInvite.interactionData.guests.description',
      type: 'GuestInviteGuestType',
      editable: true,
      isArray: true,
      default: [],
      validators: [
        {
          name: 'ArrayMax',
          value: 32,
        },
        {
          name: 'ArrayMin',
          value: 0,
        },
      ],
    },
  },
  getInteractionData(featureInstance) {
    const featureProperties = featureInstance.feature as GuestInviteFeatureProperties;
    return {
      guests: {
        friendlyName:
          'web.content.feature.guestInvite.interactionData.guests.friendlyName',
        description:
          'web.content.feature.guestInvite.interactionData.guests.description',
        type: 'GuestInviteGuestType',
        editable: true,
        isArray: true,
        default: [],
        validators: [
          {
            name: 'ArrayMax',
            value: featureProperties.maxGuests,
          },
          {
            name: 'ArrayMin',
            value: featureProperties.minGuests,
          },
        ],
      },
    };
  },
};

export default GuestInvite;
