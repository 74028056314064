import { getGoogleCalendarLink } from 'lane-shared/helpers';
import { parseDateTime } from 'lane-shared/helpers/dates';

export default function openGoogleCalendarLink(
  startDate: any,
  endDate: any,
  name: any,
  description: any
) {
  const startDateTime = parseDateTime(startDate);
  const endDateTime = parseDateTime(endDate);

  const start = startDateTime?.toJSDate() || new Date();
  const end = endDateTime?.toJSDate() || new Date();

  window.open(
    getGoogleCalendarLink({ startDate: start, endDate: end, name, description })
  );
}
