import i18next from 'i18next';
import { v4 as uuid } from 'uuid';

import { colors } from 'lane-shared/config';
import { BLOCK_LOCK_MODES } from 'lane-shared/helpers/constants/blocks';
import { BlockInstanceType } from 'lane-shared/types/blocks/BlockInterface';
import { PropertyBindingType } from 'lane-shared/types/properties/PropertyBindingType';
import { PropertiesInterfaceDependencies } from 'lane-shared/types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';

type Props = {
  field: any;
  contentFeature: any;
  blockFor: string;
  propertyDependencies: PropertiesInterfaceDependencies;
  isDataDependencyBlock?: true | undefined;
};

/**
 * Creates a data block for visual representation based on the kind of input
 * field passed in.
 *
 * @param field
 * @param feature
 */
export default function generateDataBlock({
  field,
  contentFeature,
  blockFor,
  propertyDependencies,
  isDataDependencyBlock,
}: Props): BlockInstanceType[] {
  const id = uuid();

  let binding: PropertyBindingType = {
    _bind: true,
    source: 'data',
    name: field.name,
  };

  if (contentFeature) {
    binding = {
      _bind: true,
      source: 'feature',
      featureName: contentFeature.type,
      name: field.name,
    };
  }

  const children: any[] = [];

  if (!contentFeature && !field.hidePropertyLabel) {
    children.push({
      _id: uuid(),
      primitive: 'TextBlock',
      typography: 'p',
      name: `${field.friendlyName || field.name} Label`,
      properties: {
        text: field.friendlyName || field.name,
        text_l10n: field.friendlyName_l10n || field.name_l10n,
      },
      style: {
        paddingBottom: '0.5em',
        fontSize: '1.1em',
        color: colors.gray1,
      },
      blockVersion: 0,
    });
  }

  children.push({
    _id: uuid(),
    primitive: 'PropertyInputBlock',
    properties: {
      propertyKey: field.name,
      featureKey: contentFeature?.type,
      value: binding,
    },
    subscribe: {
      primitive: binding,
    },
  });

  for (const propertyDependency of propertyDependencies.dependencies.values()) {
    const rule = propertyDependency.rules.get(field.name);
    if (rule) {
      children.push(
        ...generateDataBlock({
          field:
            propertyDependencies.propertiesInterface[
              propertyDependency.propertyRef
            ],
          contentFeature,
          blockFor: propertyDependency.propertyRef,
          propertyDependencies,
          isDataDependencyBlock: true,
        })
      );
    }
  }

  return [
    {
      _id: id,
      for: blockFor,
      editable: true,
      name: `${i18next.t(field.friendlyName || field.name)} Input`,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; for: string; editable: true; ... Remove this comment to see the full error message
      lock: [BLOCK_LOCK_MODES.CLONE],
      primitive: 'ViewBlock',
      isDataDependencyBlock,
      children,
      style: {
        paddingTop: '1em',
        paddingBottom: '1em',
        paddingLeft: '2em',
        paddingRight: '2em',
      },
    },
  ];
}
