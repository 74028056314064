import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { faChevronRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BRAND_LOGO } from 'lane-shared/helpers/constants/cards';
import { CreditCardType } from 'lane-shared/types/Stripe';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';

import { Button } from 'components/general';
import Typography from 'components/lds/Typography';

import styles from './StripeConfigureButton.scss';

export enum StripeFlows {
  AddCard,
  ManageCards,
}

export default function StripeConfigureButton({
  selectedPaymentMethod,
  onConfigure,
}: {
  selectedPaymentMethod?: CreditCardType;
  onConfigure: (
    paymentAccountType: PaymentProviderEnum,
    options: { [key: string]: any }
  ) => void;
}) {
  const { t } = useTranslation();

  return (
    <Button
      className={cx(
        styles.stripeConfigureButton,
        selectedPaymentMethod && styles.withSelected
      )}
      variant="outlined"
      onClick={() => {
        onConfigure(PaymentProviderEnum.Stripe, {
          flow: !selectedPaymentMethod
            ? StripeFlows.AddCard
            : StripeFlows.ManageCards,
        });
      }}
      dataCy="addMethodOfPayment"
    >
      {!selectedPaymentMethod ? (
        <div className={cx(styles.addCardLabel)}>
          <FontAwesomeIcon icon={faPlus} />
          <Typography type="m" mode="primary" className={cx(styles.label)}>
            {t('Add a method of payment')}
          </Typography>
        </div>
      ) : (
        <div className={cx(styles.paymentMethod)}>
          <img
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            src={BRAND_LOGO[selectedPaymentMethod.brand]}
            alt={selectedPaymentMethod.brand}
            className={cx(styles.brand)}
          />
          <div className={cx(styles.cardInfo)}>
            <Typography type="s" mode="secondary">
              {t('Ending: {{last4}}', { last4: selectedPaymentMethod.last4 })}
            </Typography>
            <Typography type="s" mode="secondary">
              {t('Exp: {{expMonth}}/{{expYear}}', {
                expMonth: selectedPaymentMethod.exp_month,
                expYear: selectedPaymentMethod.exp_year,
              })}
            </Typography>
          </div>
          <FontAwesomeIcon
            icon={faChevronRight}
            size="2x"
            className={cx(styles.configureButton)}
          />
        </div>
      )}
    </Button>
  );
}
