import React from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { parseDate } from 'lane-shared/helpers/dates';

import { ONLINE_THRESHOLD_IN_MINUTES } from '../../pages/devices/tabs/hardware/hardware';

import styles from './HardwareActivityStatusSpan.scss';

type HardwareActivityStatusSpanProps = {
  lastActivityAt?: string | number | Date | DateTime | undefined | null;
};

const isOnline = (
  lastActivityAt?: string | number | Date | DateTime | undefined | null
): boolean => {
  const date = parseDate(lastActivityAt);
  return date
    ? DateTime.fromJSDate(date).diffNow('minutes').minutes >=
        -ONLINE_THRESHOLD_IN_MINUTES
    : false;
};

const HardwareActivityStatusSpan = ({
  lastActivityAt,
}: HardwareActivityStatusSpanProps) => {
  const { t } = useTranslation();

  if (isOnline(lastActivityAt)) {
    return (
      <span className={styles.hardwareActivityStatusSpan__online}>
        {t('web.admin.hardware.management.activity.status.online')}
      </span>
    );
  }

  return (
    <span className={styles.hardwareActivityStatusSpan__offline}>
      {t('web.admin.hardware.management.activity.status.offline')}
    </span>
  );
};

export default HardwareActivityStatusSpan;
