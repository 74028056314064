import React, { useState, FormEvent, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

import { validateStripeCardForm } from 'lane-shared/validation/paymentV2';

import Checkbox from 'lane-web/src/components/form/Checkbox';
import { ErrorMessage, Button } from 'lane-web/src/components/general';

import { CardFormInputFields } from './CardFormInputFields';

import styles from './CardForm.scss';
import { PaymentMethod, PaymentSource } from 'graphql-query-contracts';

type CardFormProps = {
  handleParentSubmit: () => void;
  cards: PaymentSource[];
  updateCards: (cards: PaymentSource[]) => void;
  savePaymentMethod: (paymentMethodId: string) => Promise<void>;
  updatePaymentMethod: (id: string) => void;
  goBack: () => void;
  isWallet?: boolean;
};

export function CardForm({
  handleParentSubmit,
  cards,
  updateCards,
  savePaymentMethod,
  updatePaymentMethod,
  goBack,
  isWallet,
}: CardFormProps) {
  const { t } = useTranslation();

  const stripe = useStripe();

  // Stripe.js has not loaded yet. Make sure to disable
  // form submission until Stripe.js has loaded.
  const isDisabled = !stripe;

  const [validation, setValidation] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null
  );
  const [cardName, setCardName] = useState({
    firstName: '',
    lastName: '',
  });
  const [stripeCardElement, setStripeCardElement] = useState<
    StripeCardElement | undefined
  >(undefined);

  const handleUpdateCardName = (field: string, value: string) => {
    setCardName(prev => ({ ...prev, [field]: value }));
  };

  const [saveCardCheckbox, setSaveCardCheckbox] = useState<boolean>(
    () => isWallet ?? false
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validation) {
      setErrorMessage(t('abp.payment.allFieldsRequired'));
      return;
    }
    if (isDisabled) {
      return;
    }
    if (stripeCardElement) {
      setLoading(true);
      try {
        const payload = await stripe?.createPaymentMethod({
          type: 'card',
          card: stripeCardElement,
          billing_details: {
            name: `${cardName.firstName} ${cardName.lastName}`,
          },
        });

        if (payload) {
          if (payload.error) {
            setErrorMessage(payload.error.message);
          } else {
            const newCard: PaymentSource = {
              paymentMethodId: payload.paymentMethod.id,
              brand: payload.paymentMethod.card!.brand,
              expiryMonth: payload.paymentMethod.card!.exp_month,
              expiryYear: payload.paymentMethod.card!.exp_year,
              last4: payload.paymentMethod.card!.last4,
              method: PaymentMethod.PaymentMethodCreditCard,
            };

            if (saveCardCheckbox) {
              await savePaymentMethod(payload.paymentMethod.id);
            }
            handleParentSubmit();
            updateCards([newCard, ...cards]);
            updatePaymentMethod(payload.paymentMethod.id);
          }
        }
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  async function validate() {
    try {
      await validateStripeCardForm.validate({
        firstName: cardName.firstName,
        lastName: cardName.lastName,
      });
      setValidation(null);
    } catch (validation) {
      setValidation(validation);
    }
  }

  useEffect(() => {
    validate();
  }, [cardName]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={cx(styles.stripeAddCard)}>
        <p>{t('abp.payment.paymentInformation')}</p>
        <div className={styles.inputFieldsWrapper}>
          <CardFormInputFields
            cardName={cardName}
            onCardNameChange={handleUpdateCardName}
            onCardDetailsChange={setStripeCardElement}
          />
          {!isWallet && (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                className={cx(styles.checkbox)}
                text={t('abp.payment.saveCardForFutureUse')}
                selected={saveCardCheckbox}
                onChange={() => {
                  setSaveCardCheckbox(!saveCardCheckbox);
                }}
                value={savePaymentMethod}
              />
            </div>
          )}
        </div>

        {errorMessage != null && <ErrorMessage error={errorMessage} />}

        <div className={cx(styles.stripeAddCardActions)}>
          <Button
            fullWidth
            type="submit"
            variant="activate-contained"
            disabled={!stripe || loading}
            dataCy="addCardButton"
          >
            {t('abp.payment.addCard')}
          </Button>
          <Button fullWidth variant="outlined" onClick={goBack}>
            {t('abp.payment.goBack')}
          </Button>
        </div>
      </div>
    </form>
  );
}
