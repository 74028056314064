import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().trim(),
  description: yup.string().trim(),
  user: yup
    .object()
    .shape({
      _id: yup.string().trim().required(),
    })
    .required(),
  groupRole: yup
    .object()
    .shape({
      _id: yup.string().trim().required(),
    })
    .required(),
});
