import React, { useState, useContext } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { updateUserContentInteraction } from 'lane-shared/graphql/mutation';
import { pause } from 'lane-shared/helpers';
import { SIMPLE_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import {
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED_STATES,
} from 'lane-shared/helpers/constants/interactions';
import { isCancellableForUser } from 'lane-shared/helpers/content';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import StatusIndicator from './StatusIndicator';

import styles from './Request.scss';

function Request({ className, style, interaction }: any) {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserDataContext);
  const { t } = useTranslation();
  const content = interaction.contentData;
  const request =
    interaction.data[`_${IntegrationProviderEnum.AngusServiceRequests}`];
  const { primaryId } = useContext(ChannelsContext);

  async function onCancelPressed() {
    try {
      await window.Alert.confirm({
        title: t('Cancel your request?'),
        message: t(
          `Are you sure you want to cancel your request? \n\nYou won't be able to undo this once it is done.`
        ),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: updateUserContentInteraction,
        variables: {
          interaction: {
            _id: interaction._id,
            status: INTERACTION_CANCELLED,
          },
          meChannelId: primaryId,
        },
      });
    } catch (err) {
      await window.Alert.alert({
        title: t('An error occurred while cancelling this.'),
        error: err,
      });
    }

    setLoading(false);
  }

  const roles = user?.roles.map(role => convertTo62(role?.groupRole?._id));

  const { cancelableFeature } = explodeFeatures(content?.features);
  const hasCancellable =
    cancelableFeature &&
    isCancellableForUser({ roles, feature: cancelableFeature });

  const inClosedState = INTERACTION_CLOSED_STATES.includes(interaction.status);

  return (
    <div className={cx(styles.Request, className)} style={style}>
      <div className={styles.row}>
        <h2>{request.requestTypeDescription}</h2>
        <StatusIndicator status={request.status} />
      </div>
      <div className={styles.detailsContainer}>
        <div>
          <h3>{t('Description')}</h3>
          <p>{request.description}</p>
        </div>
        <div>
          <h3>{t('Building Name')}</h3>
          <p>{request.buildingName}</p>
        </div>
        <div>
          <h3>{t('Tenant Name')}</h3>
          <p>{request.tenantName}</p>
        </div>
        <div>
          <h3>{t('Floor')}</h3>
          <p>{request.floor}</p>
        </div>
        {request.suite && (
          <div>
            <h3>{t('Suite')}</h3>
            <p>{request.suite}</p>
          </div>
        )}
        <div>
          <h3>{t('Property Name')}</h3>
          <p>{request.propertyName}</p>
        </div>
        <div>
          <h3>{t('Request Date')}</h3>
          <p> {dateFormatter(request.requestDate, SIMPLE_DATE_TIME)}</p>
        </div>
        <div>
          <h3>{t('Status')}</h3>
          <p>{request.status}</p>
        </div>
      </div>

      {hasCancellable && !inClosedState && (
        <button
          disabled={loading}
          onClick={onCancelPressed}
          className={styles.cancelButton}
        >
          <Icon className={styles.cancelIcon} name="times" />
          <span>{t('Cancel')}</span>
        </button>
      )}
    </div>
  );
}

export default Request;
