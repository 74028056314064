import React from 'react';

import { ContentCard, ContentLink, ErrorPage, Loading } from 'components';

import {
  useChannelProfileQuery,
  useChannelsContext,
  useContentOnChannelQuery,
  useMultiChannelContent,
} from 'lane-shared/hooks';
import useSortFn from 'lane-shared/hooks/useSortFn';
import { SortByEnum, SortDirectionEnum } from 'lane-shared/types/blocks/Sort';

import styles from './ChannelContentList.scss';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { useTranslation } from 'react-i18next';

export default function ChannelContentList({
  channelId,
  contentType,
  ...otherProps
}: any) {
  const resultContentType =
    otherProps?.history?.location?.state?.contentType || contentType;
  const { channel } = useChannelProfileQuery({ channelId });
  const { sortFn } = useSortFn({
    sortBy: SortByEnum.EndDate,
    sortDirection: SortDirectionEnum.Asc,
  });

  const canUseContextData = [
    ContentTypeEnum.Content,
    ContentTypeEnum.Notice,
  ].includes(resultContentType);

  const {
    contents: contentsFromDatabase,
    loading,
    error,
  } = useContentOnChannelQuery({
    channelId,
    contentType: resultContentType,
    skip: canUseContextData,
  });
  const { t } = useTranslation();
  const { notices, focusOnChannels } = useChannelsContext();
  const contents = useMultiChannelContent({
    channelIds: (focusOnChannels || []).map(channel => channel._id),
  });

  let contentToDisplay: never[] = [];
  if (canUseContextData) {
    contentToDisplay =
      contentType === ContentTypeEnum.Content ? contents : notices;
  } else {
    contentToDisplay = contentsFromDatabase;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const sortedContents = contentToDisplay.sort(sortFn);

  return (
    <div className={styles.ChannelContentList}>
      <h1>
        {channel?.name}{' '}
        {sortedContents.length === 1
          ? resultContentType
          : `${resultContentType}s`}
      </h1>
      {sortedContents?.map((content: any) => (
        <ContentLink
          className={styles.wrapper}
          key={content._id}
          content={content}
        >
          <ContentCard className={styles.content} content={content} />
        </ContentLink>
      ))}

      {!sortedContents?.length && !loading && (
        <div className={styles.noResults}>
          <h1>{t('No results')}</h1>
        </div>
      )}

      {loading && <Loading fullscreen />}
    </div>
  );
}
