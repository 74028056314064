import React from 'react';

import { Tooltip } from 'lane-web/src/components';

import styles from './styles.scss';

type Props = {
  text: string;
  textWrap?: boolean;
  maxLength?: number;
  className?: string;
  style?: React.CSSProperties;
  withTooltip?: boolean;
  tooltipPlacement?: 'left' | 'top' | 'right' | 'bottom';
};

export const TruncateTextComponent: React.FC<Props> = ({
  text,
  textWrap = false,
  maxLength = 27,
  className,
  style,
  withTooltip = true,
  tooltipPlacement = 'top',
}) => {
  const isTruncated = text.length > maxLength && maxLength > 0;
  const truncatedText = isTruncated
    ? `${text.substring(0, maxLength)}...`
    : text;

  return (
    <span
      style={style}
      className={`${styles.customTooltip} ${
        tooltipPlacement === 'top' ? styles.customTopPlacement : ''
      } ${textWrap ? styles.textWrap : ''} ${className || ''}`}
    >
      {withTooltip && isTruncated ? (
        <Tooltip
          TooltipComponent={<span>{text}</span>}
          placement={tooltipPlacement}
        >
          {truncatedText}
        </Tooltip>
      ) : (
        truncatedText
      )}
    </span>
  );
};
