import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';

export const formatAccessManagerRoles = (roles: string[]): string => {
  if (roles.includes(SPECIAL_GROUP_ROLES.ADMIN)) {
    return 'web.admin.accessControl.userAccess.table.userType.admin';
  }
  if (roles.includes(SPECIAL_GROUP_ROLES.ACCESS_MANAGER)) {
    return 'web.admin.accessControl.userAccess.table.userType.accessAdmin';
  }
  if (roles.includes(SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN)) {
    return 'web.admin.accessControl.userAccess.table.userType.companyAccessAdmin';
  }

  return '';
};
