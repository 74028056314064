import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  WorkflowActionEnum,
  WorkFlowActionLabels,
} from 'lane-shared/types/Workflows';

import Toggle from 'lane-web/src/components/form/Toggle/index';

import TextArea from 'components/form/TextArea';
import Label from 'components/general/Label';

import styles from './ContentWorkflowEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  workflowAction: WorkflowActionEnum;
  hasCustomText: boolean;
  value: string | null | undefined;
  onTextUpdated: (text: string | null) => void;
};

export default function ContentWorkflowTextArea({
  className,
  style,
  workflowAction,
  hasCustomText,
  value,
  onTextUpdated,
}: Props) {
  const { t } = useTranslation();
  const title = t(
    'web.admin.channel.content.workflow.editor.textArea.title.email'
  );
  return (
    <div
      className={cx(styles.textAreaWrapper, className)}
      style={style}
      data-testid="test-custom-subject-toggle"
    >
      <Label>
        <Toggle
          dataCy="email subject Toggle"
          value={hasCustomText}
          style={style}
          text={t(
            'web.admin.channel.content.workflow.editor.textArea.toggle.text',
            {
              action:
                workflowAction === WorkflowActionEnum.Email
                  ? title
                  : t(WorkFlowActionLabels[workflowAction]),
            }
          )}
          doTranslate
          onChange={() => onTextUpdated(hasCustomText ? null : '')}
        />
      </Label>
      {hasCustomText && (
        <TextArea
          className={styles.textArea}
          maxLength={128}
          showLengthIndicator
          onChange={onTextUpdated}
          placeholder={t(
            'web.admin.channel.content.workflow.editor.textArea.placeholder',
            {
              action:
                workflowAction === WorkflowActionEnum.Email
                  ? title
                  : t(WorkFlowActionLabels[workflowAction]),
            }
          )}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
          value={value}
          dataCy="email subject Text area"
        />
      )}
    </div>
  );
}
