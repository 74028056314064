import useFlag from './useFlag';

const FLAG = 'flex.quantity-waitlist';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-07-24T19:39:39+0530.
 */
export function useQuantityWaitlistEnabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
