import React, { useRef, useState, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { StripeProvider, Elements } from 'react-stripe-elements';

import { ORDER_RECEIPT_FLOW } from 'lane-shared/helpers/constants/features/payment';
import { usePaymentAndMenuFeatureConfirmation } from 'lane-shared/hooks';
import { CreditCardType } from 'lane-shared/types/Stripe';
import { PaymentMethod } from 'lane-shared/types/payment/PaymentAccount';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';

import { Loading } from 'components/general';
import UserContentInteractionReceipt from 'components/lane/UserContentInteractionReceipt';

import useUserLocale from '../../hooks/useUserLocale';
import Modal from '../lds/Modal';
import { StripeFlows } from '../payments/paymentAccountManagement/StripeConfigureButton';
import {
  MenuFeatureModalConfirmationMain,
  MenuFeatureModalConfirmationMainActions,
} from './Payment/MenuFeatureModalConfirmationMain';
import ModalCloseButton from './Payment/ModalCloseButton';
import PaymentFeatureStripeAddCard, {
  PaymentFeatureStripeAddCardActions,
} from './Payment/PaymentFeatureStripeAddCard';
import PaymentFeatureStripePaymentInformation from './Payment/PaymentFeatureStripePaymentInformation';

import styles from './ConfirmationModal.scss';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  content: any;
  interaction: any;
  updateInteraction: (props: any) => any;
  onPaymentSuccess?: (...args: any) => void;
  onPaymentFailed?: (...args: any) => void;
  onRemoveOrderItem?: (...args: any) => void;
  onBeforeSubmit: any;
};

function MenuFeatureConfirmationModal({
  isOpen = false,
  onClose = () => null,
  onPaymentSuccess = () => null,
  onPaymentFailed = () => null,
  onRemoveOrderItem = () => null,
  onBeforeSubmit,
  content,
  interaction,
  updateInteraction,
}: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  // NOTE: Web specific behaviour

  const placePayRef = useRef({
    reject: () => null,
    resolve: () => null,
  });
  const [isPlacePayOpen, setIsPlacePayOpen] = useState(false);
  const [modalHistory, setModalHistory] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);

  const handleBack = () => {
    setWorking(false);
    setModalHistory((prevModalHistory: any) => {
      if (modalHistory.length > 0) {
        return prevModalHistory.slice(0, -1);
      }
      return [];
    });
  };

  // NOTE: Shared web/mobile behaviour

  const {
    loading,
    submitting,
    paymentAccounts,
    selectedPaymentAccount,
    setSelectedPaymentAccount,
    paymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    addPaymentMethod,
    removePaymentMethod,
    paymentFeature,
    menuFeature,
    paymentDetails,
    order,
    currency,
    quote,
    timeZone,
    onSubmitInteraction,
    stripeApiKey,
    newCreatedInteraction,
    showReceipt,
    onUpdateOrder,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ locale: SupportedLocaleEnum; c... Remove this comment to see the full error message
  } = usePaymentAndMenuFeatureConfirmation({
    locale,
    content,
    interaction,
    updateInteraction,
    onPaymentFailed,
    onBeforeSubmit,
    onPaymentSuccess,
    onBeforePlacePayPayment: async () =>
      new Promise((resolve, reject) => {
        setIsPlacePayOpen(true);
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: unknown) => void' is not assignable ... Remove this comment to see the full error message
        placePayRef.current.resolve = resolve;
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(reason?: any) => void' is not assignable to... Remove this comment to see the full error message
        placePayRef.current.reject = reject;
      }),
    userId: undefined,
  });

  const [initialOrder, setInitialOrder] = useState(order);

  useEffect(() => {
    setInitialOrder(order);
  }, [order]);

  useEffect(() => {
    if (showReceipt) {
      setModalHistory((prevModalHistory: any) => [
        ...prevModalHistory,
        ORDER_RECEIPT_FLOW,
      ]);
    }
  }, [showReceipt]);

  if (!content || !interaction || !menuFeature || !order) {
    return null;
  }

  const baseProps = {
    interaction,
    menuFeature,
    currency,
    paymentFeature,
    onRemoveOrderItem,
    paymentAccounts,
    selectedPaymentAccount,
    setSelectedPaymentAccount,
    paymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod: async (paymentMethod: PaymentMethod) => {
      setSelectedPaymentMethod(paymentMethod);
    },
    setModalHistory,
    isPlacePayOpen,
    setIsPlacePayOpen,
    placePayRef,
    quote,
    content,
    paymentDetails,
    timeZone,
    removePaymentMethod,
    setInitialOrder,
    onUpdateOrder,
  };

  const baseActionsProps = {
    onDone: handleBack,
    working,
  };

  const modalHistoryMap: {
    [key: string]: {
      title: string;
      component: React.ComponentType<any>;
      componentProps?: Record<any, any>;
      actions?: React.ComponentType<any>;
      actionsProps?: Record<any, any>;
    };
  } = {
    [StripeFlows.AddCard]: {
      title: t('Add a card'),
      component: PaymentFeatureStripeAddCard,
      actions: PaymentFeatureStripeAddCardActions,
      actionsProps: {
        onAddCard: async (token: any) => {
          setWorking(true);
          if (!selectedPaymentAccount) {
            throw Error(
              t('A payment account must be selected in order to add a card.')
            );
          }

          if (selectedPaymentAccount?.type === PaymentProviderEnum.Stripe) {
            const paymentMethod = await addPaymentMethod(
              selectedPaymentAccount._id,
              token.id
            );
            setSelectedPaymentMethod(paymentMethod);
          }
          setWorking(false);
        },
      },
    },
    [StripeFlows.ManageCards]: {
      title: t('Payment Options'),
      component: PaymentFeatureStripePaymentInformation,
      componentProps: {
        onAddCard: () => {
          setModalHistory((prevModalHistory: any) => [
            ...prevModalHistory,
            StripeFlows.AddCard,
          ]);
        },
        onRemoveCard: async (
          paymentAccountId: string | undefined,
          paymentMethod: CreditCardType
        ) => {
          if (paymentAccountId) {
            await removePaymentMethod(paymentAccountId, paymentMethod.id);
          } else {
            await window.Alert.alert({
              title: t('Could not remove card'),
              message: t(
                'The card could not be removed, please try again later.'
              ),
            });
          }
        },
        onNoPaymentMethods: async () => {
          setModalHistory((prevModalHistory: any) => {
            const newHistory = [...prevModalHistory];
            newHistory.pop();
            newHistory.push(StripeFlows.AddCard);
            return newHistory;
          });
        },
      },
    },
    [ORDER_RECEIPT_FLOW]: {
      title: t('Order Receipt'),
      actions: ModalCloseButton,
      actionsProps: { onClick: onClose },
      component: UserContentInteractionReceipt,
      componentProps: {
        interaction: newCreatedInteraction,
      },
    },
    default: {
      title: t('Complete your Purchase'),
      component: MenuFeatureModalConfirmationMain,
      actions: MenuFeatureModalConfirmationMainActions,
      actionsProps: {
        purchaseDisabled:
          !selectedPaymentAccount ||
          submitting ||
          initialOrder?.items?.length === 0 ||
          !initialOrder?.items ||
          !selectedPaymentMethod,
        onSubmit: () => {
          setWorking(true);
          try {
            onSubmitInteraction();
          } catch (err) {
            setWorking(false);
            throw err;
          }
        },
      },
    },
  };

  const currentHistory = modalHistory[modalHistory.length - 1] ?? 'default';

  const {
    component: CurrentComponent,
    title,
    actions: CurrentActions,
    actionsProps,
    componentProps,
  } = modalHistoryMap[currentHistory];

  const stripeProviderProps = stripeApiKey
    ? {
        apiKey: stripeApiKey,
      }
    : { stripe: null };

  return (
    <StripeProvider key={stripeApiKey} {...stripeProviderProps}>
      <Elements>
        <Modal
          className={cx(styles.confirmationModal)}
          isOpen={isOpen}
          onBack={!showReceipt ? handleBack : undefined}
          onClose={!submitting ? onClose : () => null}
          modalHistory={modalHistory}
          title={title}
          actions={
            CurrentActions ? (
              <>
                {loading ? (
                  <Loading />
                ) : (
                  <CurrentActions
                    {...baseActionsProps}
                    {...(actionsProps || {})}
                  />
                )}
              </>
            ) : undefined
          }
        >
          {loading ? (
            <Loading />
          ) : (
            <CurrentComponent {...baseProps} {...(componentProps || {})} />
          )}
        </Modal>
      </Elements>
    </StripeProvider>
  );
}

export default MenuFeatureConfirmationModal;
