const formatter = new Intl.NumberFormat().format;

export const KM_TO_MILES_CONVERSATION_RATIO = 0.6213712;
export const DISTANCE_UNITS = ['km', 'mi'] as const;
export const [DISTANCE_UNIT_KM, DISTANCE_UNIT_MILE] = DISTANCE_UNITS;

const smallUnits = {
  [DISTANCE_UNIT_KM]: 'm',
  [DISTANCE_UNIT_MILE]: 'f',
};

export default function friendlyDistance(
  dist: number,
  units: string = DISTANCE_UNIT_KM
): string {
  if (Number.isNaN(parseFloat(String(dist)))) {
    return '';
  }

  if (dist > 10) {
    return `${formatter(Math.round(dist))} ${units}`;
  }

  if (dist > 0.5 || units !== DISTANCE_UNIT_KM) {
    return `${formatter(parseFloat(dist.toFixed(1)))} ${units}`;
  }

  return `${formatter(Math.round(dist * 1000))} ${smallUnits[units]}`;
}
