import { DateTime } from 'luxon';

import { TimeRangeType } from '../../types/baseTypes/TimeRangeType';
import { LONG_TIME } from '../constants/dates';

/**
 * Takes in a TimeRangeType that represents time as a string (i.e. 11:00AM) and
 * returns an object of DateTimes with those times turned into actual dates
 * using the reference date as the basis.  For example if the reference date
 * is Jan 1 2021 and the timeRange is 5:00am - 10:00am the returned object will
 * be Jan 1 2021 5:00am - Jan 1 2021 10:00am
 *
 * @param referenceDate date the date to base this from
 * @param timeZone string the timezone to base the return times in
 * @param timeRange TimeRangeType the time range
 * @param format string the format the time range is in
 * @param includeEndingMillis boolean when true, will set end time mins
 *        to be end of day, i.e. 11:59PM -> 11:59:59:9999
 * @returns {{start: DateTime, end: DateTime}}
 */
export default function getDateTimesFromTimeRange({
  referenceDate,
  timeRange,
  timeZone,
  format = LONG_TIME,
  includeEndingMillis = false,
}: {
  referenceDate: DateTime;
  timeRange: TimeRangeType;
  timeZone: string;
  format?: string;
  includeEndingMillis?: boolean;
}): {
  start: DateTime;
  end: DateTime;
} {
  const startTime = DateTime.fromFormat(timeRange.startTime, format, {
    zone: timeZone,
  });

  let endTime = DateTime.fromFormat(timeRange.endTime, format, {
    zone: timeZone,
  });

  if (includeEndingMillis) {
    endTime = endTime.endOf('minute');
  }

  const difference = endTime.diff(startTime);

  const start = referenceDate.set({
    hour: startTime.hour,
    minute: startTime.minute,
  });

  const end = start.plus(difference);

  return {
    start,
    end,
  };
}
