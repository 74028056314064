import { useTranslation } from 'react-i18next';
import {
  useMutation,
  isApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import {
  resendInvite,
  ResendInviteResponse,
} from 'lane-shared/graphql/mutation';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';

type handleResendInviteArgs = {
  inviteId: string;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

export const useResendInvite = () => {
  const { t } = useTranslation();

  const [resendInviteMutation] = useMutation<ResendInviteResponse>(
    resendInvite
  );

  const handleResendInvite = async ({
    inviteId,
    refetch,
  }: handleResendInviteArgs) => {
    try {
      await resendInviteMutation({
        variables: {
          id: inviteId,
        },
      });
      await refetch();

      window.Toast.show(
        t(
          'web.admin.channel.users.pendingInvites.table.resendInvite.successToast'
        )
      );
    } catch (err) {
      if (isApolloError(err)) {
        const codes = getErrorCodes(err);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          {},
          t('shared.invites.errors.resendError.generic')
        );
        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }
    }
  };

  return {
    handleResendInvite,
  } as const;
};
