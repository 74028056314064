import React from 'react';

import { useTranslation } from 'react-i18next';

import DateRangePickerButton from '../../../form/DatePickers/DateRangePickerButton';

type ShopifyDateRangePickerButtonProps = {
  className?: string;
  startDate: Date | null;
  endDate: Date | null;
  startCreationDate: Date | null;
  endCreationDate: Date | null;
  defaultStartDate: Date;
  defaultEndDate: Date;
  handleChange: (props: any) => void;
};

export default function ShopifyDateRangePickerButton({
  className,
  startDate,
  endDate,
  startCreationDate,
  endCreationDate,
  defaultStartDate,
  defaultEndDate,
  handleChange,
}: ShopifyDateRangePickerButtonProps) {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <span>{t('Creation date')}</span>
        <DateRangePickerButton
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          className={className}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'never'.
          startDate={startCreationDate || new Date()}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'neve... Remove this comment to see the full error message
          endDate={endCreationDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
          minRangeSize={1}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
          maxRangeSize={365}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
          shouldShowDefaultButtonText={!startCreationDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'never'.
          maxDate={new Date()}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '({ startDate, endDate }: any) => void' is no... Remove this comment to see the full error message
          onChange={({ startDate, endDate }: any) => {
            handleChange({
              startCreationDate: startDate || startCreationDate || new Date(),
              endCreationDate: endDate || endCreationDate || new Date(),
              page: 0,
            });
          }}
        />
      </div>
      <div>
        <span>{t('Burst date')}</span>
        <DateRangePickerButton
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          className={className}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'never'.
          startDate={startDate || defaultStartDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'never'.
          endDate={endDate || defaultEndDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
          shouldShowDefaultButtonText={!startDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
          minRangeSize={1}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
          maxRangeSize={365}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '({ startDate: startDateTime, endDate: endDat... Remove this comment to see the full error message
          onChange={({ startDate: startDateTime, endDate: endDateTime }) =>
            handleChange({
              startDate: startDateTime || startDate || defaultStartDate,
              endDate: endDateTime || endDate || startDate || defaultEndDate,
              page: 0,
            })
          }
        />
      </div>
    </>
  );
}
