import { Primitive } from '../../../types/blocks/Primitive';

const YoutubeBlock: Primitive = {
  name: 'YoutubeBlock',
  friendlyName: 'YouTube',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  tags: ['Media'],
  properties: {
    videoid: {
      type: 'String',
      default: 'dQw4w9WgXcQ',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    play: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    pause: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    seek: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    end: {
      type: 'Event',
    },
  },
};

export default YoutubeBlock;
