import React from 'react';

import { useUpdateInteraction } from 'lane-shared/hooks';
import { InteractionDataType } from 'lane-shared/types/server/WorkflowTypes';

import Dropdown from 'components/form/Dropdown';
import { Loading } from 'components/general';
import Button from 'components/general/Button';
import Modal from 'components/lds/Modal';
import { M } from 'components/typography';
import { useTranslation } from 'react-i18next';

type Props = {
  interaction: InteractionDataType;
  disabled?: boolean;
  className?: string;
};

/**
 * Dropdown that allows users to update the status of an interaction.
 */
export default function StatusDropdown({
  interaction,
  disabled,
  className,
}: Props) {
  const {
    interactionUpdate,
    setInteractionUpdate,
    hasChanged,
    updateInteraction,
    statusOptions,
    isActionable,
    isCancelled,
    resetChangesToInteraction,
    loading,
  } = useUpdateInteraction({ interaction });
  const { t } = useTranslation();

  async function onUpdateInteraction() {
    try {
      await updateInteraction();
      window.Toast.show(
        t('web.admin.content.interaction.status.update.success.message')
      );
    } catch (err) {
      await window.Alert.alert({
        title: t('web.admin.content.interaction.status.update.failed.message'),
        error: err,
      });
    }
  }

  if (!interaction) {
    return <Loading />;
  }

  const _status = interaction.status;
  const readOnly =
    disabled || statusOptions.length <= 1 || !isActionable || isCancelled;

  return (
    <>
      <Dropdown
        className={className}
        disabled={readOnly}
        value={_status}
        items={
          statusOptions.length > 0
            ? statusOptions
            : [{ label: _status, value: _status }]
        }
        onValueChange={status => {
          if (status !== _status) {
            setInteractionUpdate({ status });
          }
        }}
        dataCy="StatusDropdown"
      />

      <Modal
        isOpen={hasChanged}
        title={t(
          'web.admin.content.interaction.status.update.confirmation.title'
        )}
        onClose={resetChangesToInteraction}
        actions={[
          <Button
            key="1"
            onClick={resetChangesToInteraction}
            fullWidth
            loading={loading}
          >
            {t('Cancel')}
          </Button>,
          <Button
            key="2"
            variant="contained"
            onClick={onUpdateInteraction}
            loading={loading}
            fullWidth
            dataCy="Save"
          >
            {t('Save')}
          </Button>,
        ]}
      >
        <M mb={6} variant="secondary">
          {t(
            'web.admin.content.interaction.status.update.confirmation.message',
            {
              status: t(interactionUpdate.status),
            }
          )}
        </M>
      </Modal>
    </>
  );
}
