import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SurveysFeatureProperties } from 'lane-shared/types/features/SurveysFeatureProperties';

import RadioGroup from 'components/form/RadioGroup';
import { H5, S } from 'components/typography';

import { FeatureRendererPropsType } from '../../../components/renderers/features/FeatureRendererPropsType';

export const SurveysFeatureRenderer = ({
  className,
  style,
  feature,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<SurveysFeatureProperties>) => {
  const { t } = useTranslation();

  const settings = contentFeature?.feature as SurveysFeatureProperties;

  return (
    <div className={cx(className)} style={style}>
      <H5>{t(feature.friendlyName)}</H5>
      <S>{t(feature.description)}</S>
      <RadioGroup
        schema={{ id: '_id', text: 'name' }}
        name="name"
        items={feature.properties.type.options}
        onChange={type =>
          onFeatureUpdated({
            ...settings,
            type,
          })
        }
        selected={settings.type ?? feature.properties.type.default}
      />
    </div>
  );
};

export default SurveysFeatureRenderer;
