import { useState } from 'react';

import { generatePath } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { LaneType } from 'common-types';
import { appUrl, routes } from 'lane-shared/config';

import downloadFile from 'helpers/downloadFile';

type DateRange = {
  startDate: Date;
  endDate: Date;
};

const DEBOUNCE_TIME_MS = 500;

export function useDownloadCSVExport(channelId: LaneType.UUID) {
  const [error, setError] = useState<Error | null>(null);
  const debounced = useDebouncedCallback(() => {}, DEBOUNCE_TIME_MS);

  function _generateUrl(keyword?: string, dateRange?: DateRange) {
    const queryParams = new URLSearchParams();

    if (keyword) {
      queryParams.append('keyword', keyword);
    }

    if (dateRange) {
      queryParams.append('startDate', dateRange.startDate.toISOString());
      queryParams.append('endDate', dateRange.endDate.toISOString());
    }

    const url = `${appUrl}${generatePath(routes.reservableBookingsCSVExport, {
      channelId,
    })}`;
    const urlWithQueryParamsIfAnyDefined =
      keyword || dateRange ? `${url}?${queryParams}` : url;

    return urlWithQueryParamsIfAnyDefined;
  }

  async function download({
    keyword,
    dateRange,
  }: {
    keyword?: string;
    dateRange?: DateRange;
  }) {
    try {
      const url = _generateUrl(keyword, dateRange);

      await downloadFile(url);

      debounced.callback();
    } catch (err) {
      setError(err);
    }
  }

  return {
    downloadCSVExport: download,
    error,
  };
}
