import { Primitive } from '../../../types/blocks/Primitive';

const RichTextBlock: Primitive = {
  name: 'RichTextBlock',
  friendlyName: 'Rich Text',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  tags: ['Text'],
  properties: {
    text: {
      type: 'RichText',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default RichTextBlock;
