import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import Types from 'lane-shared/properties/Types';
import parseValue from 'lane-shared/renderers/v5/parseValue';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import { SECURITY_TYPE_DESCRIPTIONS } from 'lane-shared/types/properties/PropertySecurity';

import Label from '../../../general/Label';
 
import Property from './Property';
import PropertyInput from './PropertyInput';

import styles from './PropertyArrayInput.scss';

export default function PropertyArrayInput({
  disabled,
  object,
  property,
  value,
  onChange,
  onPropertyChange,
  onDelete,
  keys,
  channel,
  library,
  theme,
  timeZone,
  i,
  arr,
  propertyKey,
  workOrderVariant = false,
}: any) {
  const [isExpanded, setIsExpanded] = useState(true);
  // useStates for status section
  const [transitionKeys, setTransitionKeys] = useState([
    {
      id: '',
      currentStatus: 'Started',
      nextStatuses: ['Approved', 'Cancelled', 'Declined'],
      securityType: ['All'],
    },
  ]);
  const [isStatusPage, setIsStatusPage] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<string>('');
  const { t } = useTranslation();

  interface SecurityType {
    _id?: string;
    value?: string;
    type: string;
  }

  const type = Types.getType(property.type);
  useEffect(() => {
    if (type.name === 'StatusFeatureRule') {
      const securityKeys = value.security.map(
        (securityValue: SecurityType) => securityValue.type
      );
      setTransitionKeys([
        {
          id: value._id,
          currentStatus: value.status,
          nextStatuses: value.nextStatuses,
          securityType: securityKeys,
        },
      ]);
      setIsStatusPage(true);
    } else {
      setIsStatusPage(false);
    }
  }, []);

  const StatusClosedView = () => {
    if (transitionKeys.length > 1) {
      // if transitionKeys is greater than one then we need to specifically filter for the target id
      const filtered = transitionKeys.filter(value => value.id === targetId);
      return (
        <>
          {filtered.map(value => (
            <div data-cy="statusClosedView">
              <h1>
                Status can change from "{value.currentStatus}" to "
                {value.nextStatuses.join('" or "')}"
              </h1>
              <div className={styles.input}>
                {value.securityType.map((security, securityIndex) => (
                  <>
                    {securityIndex === 0
                      ? 'Security permissions are set to'
                      : ''}{' '}
                    "{security} - {SECURITY_TYPE_DESCRIPTIONS[security]}"{' '}
                    {securityIndex === value.securityType.length - 1
                      ? ''
                      : ' and '}
                  </>
                ))}
              </div>
            </div>
          ))}
        </>
      );
    }
    return (
      <>
        {transitionKeys.map(value => (
          <div data-cy="statusClosedView">
            <h1>
              Status can change from "{value.currentStatus}" to "
              {value.nextStatuses.join('" or "')}"
            </h1>
            <div className={styles.input}>
              {value.securityType.map((security, securityIndex) => (
                <>
                  {securityIndex === 0 ? 'Security permissions are set to' : ''}{' '}
                  "{security} - {SECURITY_TYPE_DESCRIPTIONS[security]}"{' '}
                  {securityIndex === value.securityType.length - 1
                    ? ''
                    : ' and '}
                </>
              ))}
            </div>
          </div>
        ))}
      </>
    );
  };

  const PropertyIcons = () => (
    <div className={styles.iconWrapper} data-testid="iconButtons">
      <Icon
        className={cx(styles.floatingButton, styles.expandButton)}
        name={isExpanded ? 'chevron-up' : 'chevron-down'}
        onClick={() => setIsExpanded(!isExpanded)}
        dataCy="expandButton"
      />
      {!workOrderVariant && (
        <Icon
          className={cx(styles.floatingButton, styles.deleteButton)}
          name="trash"
          set={ICON_SET_FONTAWESOME}
          onClick={() => onDelete(i)}
        />
      )}
    </div>
  );

  if (!Types.isBaseType(type.name)) {
    return isExpanded ? (
      <div className={styles.PropertyArrayInput}>
        <div className={styles.arrayWrapper}>
          <PropertyIcons />
          <fieldset>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            {Object.entries(type.properties)
              .filter(
                ([_, subProperty]) =>
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean | PropertyBindingType | ... Remove this comment to see the full error message
                  [undefined, null, true].includes(subProperty.showControl) ||
                  parseValue({
                    value: subProperty.showControl,
                    props: value,
                    propDefs: property,
                  })
              )
              .map(([key, subProperty]) => {
                return (
                  <div className={styles.arrayOuter} data-expanded={isExpanded}>
                    <div key={key} className={styles.arrayInner}>
                      {(!subProperty.isArray ||
                        subProperty.packagedType ===
                          PackagedTypeEnum.Checkboxes) && (
                        <Label
                          TooltipComponent={t(subProperty.description || '')}
                        >
                          {t(
                            subProperty.friendlyName || subProperty.name || key
                          )}
                        </Label>
                      )}
                      <Property
                        disabled={disabled}
                        timeZone={timeZone}
                        object={object}
                        channel={channel}
                        library={library}
                        theme={theme}
                        property={subProperty}
                        value={value?.[key]}
                        keys={keys}
                        propertyKey={`${propertyKey}.${key}`}
                        onChange={subValue => {
                          value[key] = subValue;
                          const newArr = [...arr];
                          newArr[i] = value;
                          onChange(newArr);
                          if (isStatusPage) {
                            newArr.forEach(value => {
                              const securityKeys = value.security.map(
                                (securityValue: SecurityType) =>
                                  securityValue.type
                              );
                              setTransitionKeys(prev => {
                                const prevFiltered = prev.filter(
                                  prevValue => prevValue.id !== value._id
                                );
                                return [
                                  ...prevFiltered,
                                  {
                                    id: value._id,
                                    currentStatus: value.status,
                                    nextStatuses: value.nextStatuses,
                                    securityType: securityKeys,
                                  },
                                ];
                              });
                            });
                            setTargetId(value._id);
                          }
                        }}
                        onPropertyChange={e => {
                          value[e.key] = e.value;
                          const newArr = [...arr];
                          newArr[i] = value;
                          onPropertyChange({ key: e.key, value: newArr });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </fieldset>
        </div>
      </div>
    ) : (
      <div className={styles.PropertyArrayInput}>
        <div className={styles.arrayWrapper}>
          <PropertyIcons />
          {isStatusPage ? <StatusClosedView /> : null}
        </div>
      </div>
    );
  }

  return (
    <fieldset className={styles.PropertyArrayInput}>
      <div className={styles.inputRow}>
        <PropertyInput
          disabled={disabled}
          timeZone={timeZone}
          object={object}
          channel={channel}
          library={library}
          theme={theme}
          property={property}
          propertyKey={propertyKey}
          value={value}
          keys={keys}
          onChange={(value: any) => {
            const newArr = [...arr];
            newArr[i] = value;
            onChange(newArr);
          }}
          onPropertyChange={({ key, value }) => {
            const newArr = [...arr];
            newArr[i] = value;
            onPropertyChange({ key, value: newArr });
          }}
        />
        <Icon
          className={styles.deleteButton}
          name="trash"
          set={ICON_SET_FONTAWESOME}
          onClick={() => onDelete(i)}
          dataCy="deleteSecurityButton"
        />
      </div>
    </fieldset>
  );
}
