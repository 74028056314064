/** Apply a general group for this property type, used for UI presentation */
export const TYPE_GROUPS = [
  'Text',
  'Number',
  'Media',
  'Lane',
  'Other',
  'Time',
  'Integration',
] as const;

export const [
  TYPE_GROUP_TEXT,
  TYPE_GROUP_NUMBER,
  TYPE_GROUP_MEDIA,
  TYPE_GROUP_LANE,
  TYPE_GROUP_OTHER,
  TYPE_GROUP_TIME,
  TYPE_GROUP_INTEGRATION,
] = TYPE_GROUPS;
