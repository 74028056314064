import React from 'react';

import { Icon } from 'design-system-web';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import Flex from 'components/layout/Flex';
import { Text } from 'components/typography';

type Props = {
  count: number;
};

/**
 *  Displays the number of generated children of a generator content template
 */
export default function GeneratorContentCount({ count }: Props) {
  return (
    <Flex align="center" gap={1}>
      <Icon type="far" name="brackets-curly" set={ICON_SET_FONTAWESOME} />
      <Text>{count}</Text>
    </Flex>
  );
}
