import React from 'react';

import cx from 'classnames';

import styles from './Box.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

/**
 * Group components into a box with a box-shadow and padding
 */
export default function Box({ children, className, ...otherProps }: Props) {
  return (
    <div className={cx(styles.container, className)} {...otherProps}>
      {children}
    </div>
  );
}
