import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { PublicUserFragment, FullWebhookFragment } from '../graphql/fragments';

const query = gql`
  ${PublicUserFragment}
  ${FullWebhookFragment}

  query getWebhook($webhookId: UUID!) {
    webhook(_id: $webhookId) {
      ...FullWebhookFragment
    }
  }
`;

export default function useWebhookQuery({ webhookId }: any) {
  const { data, loading, error } = useQuery(query, {
    client: getClient(),
    skip: !webhookId,
    variables: {
      webhookId,
    },
  });

  const webhook = data?.webhook;

  return {
    webhook,
    loading,
    error,
  };
}
