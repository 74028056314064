import React, { useState, useMemo } from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import { Key } from 'ts-key-enum';

import ModalBackground from './ModalBackground';
import useModalPosition, { ModalPositionEnum } from 'hooks/useModalPosition';

import styles from './ContextMenu.scss';

export { ModalPositionEnum };

type OwnProps = {
  disabled?: boolean;
  /** The items in the menu as react components */
  items: React.ReactNode;
  /**  Style to apply to the menu container */
  menuStyle?: React.CSSProperties;
  /**  A class for the menu container */
  menuClassName?: string;
  autoClose?: boolean;
  /** context menu position */
  align?: ModalPositionEnum;
  /** Should the menu be autofocused when it opens */
  autoFocus: boolean;
  /** Item that will be fixed to the bottom */
  fixedItem?: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  onMenuOpen?: (e: MouseEvent) => void;
  isRounded?: boolean;
  hasOpaqueBackground?: boolean;
  clickAction: 'left' | 'right';
};

ContextMenu.defaultProps = {
  /** Should the menu be autofocused when it opens */
  autoFocus: true,
  /** Should this context menu close on click? */
  autoClose: false,
  align: ModalPositionEnum.Right,
  hasOpaqueBackground: true,
  clickAction: 'left',
};

type Props = OwnProps & typeof ContextMenu.defaultProps;

export default function ContextMenu({
  children,
  items,
  disabled,
  className,
  style,
  fixedItem,
  menuStyle,
  menuClassName,
  autoFocus,
  autoClose,
  align,
  id,
  onMenuOpen,
  isRounded,
  hasOpaqueBackground,
  clickAction,
}: Props) {
  const [hoveredItem, setHoveredItem] = useState<React.ReactNode | null>(null);
  const {
    buttonRef,
    modalRef,
    childrenRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } = useModalPosition({
    autoFocus,
    align,
  });

  function openClick(e: any) {
    if (disabled) {
      return;
    }

    e.preventDefault();

    onOpen(e);

    if (onMenuOpen) {
      onMenuOpen(e);
    }
  }

  function onKeyUp(e: any) {
    if (!Array.isArray(items)) {
      return;
    }

    let ix = items.findIndex(item => item === hoveredItem);

    switch (e.key) {
      case Key.ArrowUp:
        ix--;
        break;
      case Key.ArrowDown:
        ix++;
        break;
      case Key.Enter:
        {
          const el = modalRef?.current?.querySelector(
            '*[data-is-selected=true]'
          ) as HTMLElement;
          if (el) {
            el.click();
          }
        }
        break;
      default:
    }

    if (ix < 0) {
      ix = items.length - 1;
    } else if (ix > items.length - 1) {
      ix = 0;
    }

    setHoveredItem(items[ix]);
  }

  function onMouseEnter(item: any) {
    setHoveredItem(item);
  }

  function onMouseLeave() {
    setHoveredItem(null);
  }

  const menuItems = useMemo(() => {
    if (typeof items === 'function') {
      // @ts-expect-error
      return items({
        isOpen,
        onOpen,
        onClose,
      });
    }

    return React.Children.map(items, item =>
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      React.cloneElement(item, {
        onMouseEnter: () => onMouseEnter(item),
        onMouseLeave: () => onMouseLeave(),
        'data-is-selected': item === hoveredItem,
      })
    );
  }, [items]);

  return (
    <span
      ref={buttonRef}
      className={cx(styles.ContextMenu, className)}
      style={style}
      role="button"
      tabIndex={0}
      data-cy="context-menu"
      onKeyPress={e => e.key === Key.Enter && openClick(e)}
      onClick={clickAction === 'left' ? openClick : undefined}
      onContextMenu={clickAction === 'right' ? openClick : undefined}
      id={id}
    >
      <span ref={childrenRef}>{children}</span>

      <ModalBackground
        className={styles.background}
        onClose={onClose}
        isOpen={isOpen}
        hasOpaqueBackground={hasOpaqueBackground}
      />

      {isOpen &&
        ReactDOM.createPortal(
          <menu
            data-cy="channelMenu"
            role="menu"
            tabIndex={0}
            ref={modalRef}
            className={cx(styles.menu, menuClassName)}
            style={{ ...(position as any), ...style, ...menuStyle }}
            onKeyUp={onKeyUp}
            onClick={() => autoClose && onClose()}
          >
            <div
              className={cx(
                styles.scrollContainer,
                isRounded && styles.isRounded
              )}
            >
              {menuItems}
            </div>
            {fixedItem}
          </menu>,
          document.body
        )}
    </span>
  );
}
