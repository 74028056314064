import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

export class UrlType extends StringTypeBase {
  group = TYPE_GROUP_TEXT;

  name = 'Url';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  friendlyName = 'Website URL';

  get default(): any {
    return '';
  }

  buildSchema() {
    return yup
      .string()
      .test(
        'is-url',
        'The value must be a valid URL e.g. http://www.joinlane.com',
        value => {
          if (value === undefined || value === null) {
            return true;
          }

          try {
            const url = new URL(String(value).valueOf());
            return url && !url.protocol.includes('javascript');
          } catch (err) {
            return false;
          }
        }
      )
      .nullable();
  }
}

const definition = new UrlType();

Types.addBaseType(definition);
export default definition;
