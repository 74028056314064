import React from 'react';

import IconButton from 'components/general/IconButton';
import { Flex, Box } from 'components/layout';
import { H5 } from 'components/typography';

import styles from './ReviewData.scss';

type Props = {
  title: string;
  titleIcon: React.ReactNode;
  summary: React.ReactNode;
  onClick?: () => void;
  isEditable?: boolean;
};

export default function ReviewData({
  title,
  titleIcon,
  summary,
  onClick,
  isEditable,
}: Props) {
  return (
    <Flex direction="column" className={styles.ReviewData}>
      <Flex justify="space-between" align="flex-start" mb={1}>
        <Flex align="center">
          <Box className={styles.titleIcon}>{titleIcon}</Box>
          <H5>{title}</H5>
        </Flex>
        {isEditable && onClick !== undefined && (
          <IconButton
            iconSet="FontAwesome"
            icon="pencil"
            type="fal"
            onClick={onClick}
            className={styles.editButton}
          />
        )}
      </Flex>
      <Box className={styles.summary}>{summary}</Box>
    </Flex>
  );
}
