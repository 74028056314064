import React, { useState, useEffect } from 'react';

import { Icon, Button } from 'design-system-web';
import { Input, Flex } from 'components';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { ValidationError } from 'yup';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { getMedia } from 'lane-shared/graphql/query';
import { isRitualEnabled } from 'lane-shared/helpers/constants/integrations/ritual';
import { getFriendlyBytes } from 'lane-shared/helpers/files';
import getMediaUrl from 'lane-shared/helpers/formatters/mediaUrl';
import getDisplayName from 'lane-shared/helpers/getDisplayName';
import type { MediaWithCreatedByInfo } from 'lane-shared/hooks/useMediaLibrary';
import { ExternalUrlOpeningModeEnum } from 'lane-shared/types/ExternalUrl';
import { LibraryTypeEnum } from 'lane-shared/types/libraries';

import { AttachmentThumbnail } from 'lane-web/src/components/cards/AttachmentThumbnail';
import { PDFPreview } from 'lane-web/src/components/lane/PDFPreview';

import RadioGroup from 'components/form/RadioGroup';
import CreatedBy from 'components/lane/CreatedBy';
import DocumentsLibrary from 'components/lane/DocumentsLibrary';
import { Modal } from 'components/lds';
import { H5, Text, M } from 'components/typography';

import styles from './ExternalLink.scss';
import type { ContentType } from 'lane-shared/types/content/Content';
import type { ChannelType } from 'lane-shared/types/ChannelType';

type ExternalLinkProps = {
  urlTypeSchema: {
    validateSync: (url: string) => void;
  };
  integrationsList: Array<{
    integration: {
      name: string;
    };
  }>;
  content: ContentType;
  channel: ChannelType;
  onDataValidation: (error?: ValidationError | undefined) => void;
  onContentUpdated: (value: Partial<ContentType>) => void;
  setExternalUrlValidationError: (error: ValidationError | null) => void;
  externalUrlValidationError: ValidationError | null;
};

export function ExternalLink({
  urlTypeSchema,
  integrationsList,
  content,
  channel,
  onDataValidation,
  onContentUpdated,
  setExternalUrlValidationError,
  externalUrlValidationError,
}: ExternalLinkProps) {
  const linkedTypeOptions = [
    {
      text:
        'web.admin.channel.content.layout.editor.linkModal.externalLinkText',
      id: 'externalLink',
    },
    {
      text:
        'web.admin.channel.content.layout.editor.linkModal.documentLinkText',
      id: 'document',
    },
  ];

  const externalUrlOpeningOptions = [
    {
      text:
        'web.admin.channel.content.layout.editor.linkModal.external.standard.text',
      subtext:
        'web.admin.channel.content.layout.editor.linkModal.external.standard.subtext',
      value: ExternalUrlOpeningModeEnum.InApp,
    },
    {
      text:
        'web.admin.channel.content.layout.editor.linkModal.external.standardSSO.text',
      subtext:
        'web.admin.channel.content.layout.editor.linkModal.external.standardSSO.subtext',
      value: ExternalUrlOpeningModeEnum.InAppWithSso,
    },
    {
      text:
        'web.admin.channel.content.layout.editor.linkModal.external.external.text',
      subtext:
        'web.admin.channel.content.layout.editor.linkModal.external.external.subtext',
      value: ExternalUrlOpeningModeEnum.External,
    },
  ];

  const documentOptionsList = [
    {
      text:
        'web.admin.channel.content.layout.editor.linkModal.document.external.standard.text',
      subtext:
        'web.admin.channel.content.layout.editor.linkModal.document.external.standard.subtext',
      value: ExternalUrlOpeningModeEnum.InApp,
    },
    {
      text:
        'web.admin.channel.content.layout.editor.linkModal.document.external.external.text',
      subtext:
        'web.admin.channel.content.layout.editor.linkModal.document.external.external.subtext',
      value: ExternalUrlOpeningModeEnum.External,
    },
  ];

  const { t } = useTranslation();

  const connectedDocumentId = content?.externalUrl?.mediaId;

  useEffect(() => {
    const fetchMediaWithUpdated = async () => {
      const response = await getClient().query({
        query: getMedia,
        variables: {
          id: connectedDocumentId,
        },
        fetchPolicy: 'network-only',
      });

      setMedia(response?.data?.media);
      setShowExternalLink(!response?.data?.media?._id);
    };

    if (connectedDocumentId) {
      fetchMediaWithUpdated();
    }
  }, [connectedDocumentId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [media, setMedia] = useState<MediaWithCreatedByInfo | undefined>();
  const [showExternalLink, setShowExternalLink] = useState<boolean>(true);

  const selectedExternalURLOption = content?.externalUrl?.openingMode;

  const optionsList = [
    {
      value: ExternalUrlOpeningModeEnum.InApp,
      name: t(
        'web.admin.channel.content.layout.editor.linkModal.options.inApp'
      ),
      icon: 'smartphone',
    },
    {
      value: ExternalUrlOpeningModeEnum.InAppWithSso,
      name: t(
        'web.admin.channel.content.layout.editor.linkModal.options.inAppWithSSO'
      ),
      icon: 'log-in',
    },
    {
      value: ExternalUrlOpeningModeEnum.External,
      name: t(
        'web.admin.channel.content.layout.editor.linkModal.options.external'
      ),
      icon: 'external-link',
    },
  ];

  if (isRitualEnabled(integrationsList)) {
    optionsList.push({
      value: ExternalUrlOpeningModeEnum.Ritual,
      name: t(
        'web.admin.channel.content.layout.editor.linkModal.options.ritual'
      ),
      icon: 'external-link',
    });

    externalUrlOpeningOptions.push({
      text:
        'web.admin.channel.content.layout.editor.linkModal.external.ritualSSO.text',
      subtext:
        'web.admin.channel.content.layout.editor.linkModal.external.ritualSSO.subtext',
      value: ExternalUrlOpeningModeEnum.Ritual,
    });
  }

  return (
    <>
      <div className={styles.ExternalLink}>
        <div className={styles.cardPanel}>
          <H5>
            {t('web.admin.channel.content.layout.editor.linkModal.header')}
          </H5>
          <RadioGroup
            name="linkType"
            selected={
              showExternalLink
                ? linkedTypeOptions[0].id
                : linkedTypeOptions[1].id
            }
            items={linkedTypeOptions}
            showBorder={false}
            onChange={selected => {
              setShowExternalLink(selected === linkedTypeOptions[0].id);
            }}
          />
          <div className={styles.divider} />
          {showExternalLink ? (
            <div>
              <H5>
                {t(
                  'web.admin.channel.content.layout.editor.linkModal.inputLabel'
                )}{' '}
                <span className={styles.required}>*</span>
              </H5>
              <Input
                dataCy="externalURL"
                value={content?.externalUrl?.url}
                onChange={url => {
                  try {
                    urlTypeSchema.validateSync(url);
                    setExternalUrlValidationError(null);
                    onDataValidation();
                  } catch (e) {
                    if (e instanceof ValidationError) {
                      setExternalUrlValidationError(e);
                      onDataValidation(e);
                    }
                  }
                  onContentUpdated({
                    externalUrl: { ...content?.externalUrl!, url },
                  });
                }}
                error={externalUrlValidationError?.errors}
              />
              <div className={styles.label} />
              <Text>
                {t(
                  'web.admin.channel.content.layout.editor.linkModal.urlOptions'
                )}
              </Text>
              <RadioGroup
                name="externalLinkType"
                selected={selectedExternalURLOption}
                schema={{
                  id: 'value',
                  text: 'text',
                  subtext: 'subtext',
                }}
                items={externalUrlOpeningOptions}
                showBorder={false}
                onChange={openingMode => {
                  onContentUpdated({
                    externalUrl: {
                      ...content.externalUrl!,
                      openingMode: openingMode as ExternalUrlOpeningModeEnum,
                    },
                  });
                }}
              />
            </div>
          ) : (
            <div>
              {media?._id ? (
                <div>
                  <H5>
                    {t(
                      'web.admin.channel.content.layout.editor.linkModal.documentLabel'
                    )}{' '}
                    <span className={styles.required}>*</span>
                  </H5>
                  <AttachmentThumbnail
                    attachment={{
                      id: media._id,
                      fileUrl: getMediaUrl(media),
                      file: media.file,
                    }}
                    disableDeleteWarning
                    attachmentThumbnailStyle={styles.attachmentPreview}
                    deleteAttachmentHandler={() => {
                      onContentUpdated({
                        externalUrl: {
                          _id: uuid(),
                          url: 'https://example.com',
                          openingMode: ExternalUrlOpeningModeEnum.InApp,
                        },
                      });
                      setMedia(undefined);
                    }}
                  />
                  <Flex m={[5, 0, 0, 0]} gap={5} direction="column">
                    <Button
                      size="large"
                      variant="secondary"
                      fullWidth
                      onClick={() => window.open(media?.previewUrl, '_blank')}
                    >
                      {t(
                        'web.admin.channel.content.layout.editor.linkModal.document.preview'
                      )}
                    </Button>
                    <Button
                      size="large"
                      variant="secondary"
                      fullWidth
                      onClick={() => {
                        const mediaPath = routes.channelAdminLibraryDocuments.replace(
                          ':id',
                          channel.slug
                        );

                        window.open(`${mediaPath}/${media._id}/edit`, '_blank');
                      }}
                    >
                      {t(
                        'web.admin.channel.content.layout.editor.linkModal.document.show'
                      )}
                    </Button>
                  </Flex>
                  <div className={styles.divider} />
                  <Text>
                    {t(
                      'web.admin.channel.content.layout.editor.linkModal.documentOpeningTitle'
                    )}
                  </Text>
                  <RadioGroup
                    name="externalLinkType"
                    selected={selectedExternalURLOption}
                    schema={{
                      id: 'value',
                      text: 'text',
                      subtext: 'subtext',
                    }}
                    items={documentOptionsList}
                    showBorder={false}
                    onChange={openingMode => {
                      onContentUpdated({
                        externalUrl: {
                          ...content.externalUrl!,
                          openingMode: openingMode as ExternalUrlOpeningModeEnum,
                        },
                      });
                    }}
                  />
                  <div className={styles.documentDetails}>
                    <M>
                      {t(
                        'web.admin.channel.content.layout.editor.linkModal.document.mediaType'
                      )}
                    </M>
                    <H5 bold>{media.type}</H5>
                  </div>
                  <div className={styles.documentDetails}>
                    <M>
                      {t(
                        'web.admin.channel.content.layout.editor.linkModal.document.fileSize'
                      )}
                    </M>
                    <H5 bold>{getFriendlyBytes(media.file.size)}</H5>
                  </div>
                  <CreatedBy
                    className={styles.documentDetails}
                    object={media}
                    forAdmin
                  />
                </div>
              ) : (
                <Button
                  variant="secondary"
                  size="large"
                  endIcon={<Icon name="upload" set="Feather" />}
                  className={styles.selectDocumentButton}
                  onClick={() => setIsModalOpen(true)}
                >
                  {t(
                    'web.admin.channel.content.layout.editor.linkModal.documentButtonText'
                  )}
                </Button>
              )}
            </div>
          )}
        </div>
        {!!media && !showExternalLink && (
          <PDFPreview media={{ url: media?.previewUrl! }} />
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className={styles.modalContainer}
        disableContentPadding
      >
        <DocumentsLibrary
          className={styles.documentLibrary}
          storageKey={channel?._id}
          library={{
            type: LibraryTypeEnum.Channel,
            _id: channel?._id,
            name: getDisplayName(channel),
          }}
          onMediaCreatedCallback={createdMedia => {
            onContentUpdated({
              externalUrl: {
                ...content.externalUrl!,
                url: getMediaUrl(createdMedia),
                openingMode: ExternalUrlOpeningModeEnum.InApp,
                mediaId: createdMedia?._id,
              },
            });
            setIsModalOpen(false);
          }}
          onMediaSelected={selectedMedia => {
            onContentUpdated({
              externalUrl: {
                ...content.externalUrl!,
                url: getMediaUrl(selectedMedia),
                openingMode: ExternalUrlOpeningModeEnum.InApp,
                mediaId: selectedMedia?._id,
              },
            });
            setIsModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}
