import React, { useMemo } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

import { FeatureRendererPropsType } from 'components/renderers/features/FeatureRendererPropsType';
import { H4, P } from 'components/typography';

import TimeAvailabilityDaily from './TimeAvailabilityDaily';
import TimeAvailabilityExcluded from './TimeAvailabilityExcluded';
import TimeAvailabilityTargeted from './TimeAvailabilityTargeted';
import useTimeAvailabilityInput from './useTimeAvailabilityInput';

import styles from './styles.scss';

export default function TimeAvailability({
  className,
  style,
  feature,
  content,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<TimeAvailabilityFeatureProperties>) {
  const { t } = useTranslation();

  const timeZone = useMemo(
    () =>
      content?.geo
        ? getTimeZoneByGeoLocation({
            latitude: content?.geo[1],
            longitude: content?.geo[0],
          })
        : undefined,
    [content?._id]
  );

  const {
    hasTargetedAvailability,
    hasExcludedAvailability,
    defaultAvailability,
    targetedAvailabilities,
    unavailableDates,
    onToggleOpen,
    onTimeRangeSelect,
    onAddTimeRange,
    onAllDayToggle,
    onRemoveTimeRange,
    addNewAvailability,
    addNewUnavailableDate,
    toggleExcludedDates,
    toggleTargetedDates,
    removeAvailability,
    removeUnavailableDate,
    onGroupRoleSelected,
    onAvailabilityAnytimeChange,
    updateDateRange,
  } = useTimeAvailabilityInput({ contentFeature, onFeatureUpdated, timeZone });

  return (
    <div className={cx(styles.TimeAvailability, className)} style={style}>
      <H4 mb={4}>{t(feature.friendlyName)}</H4>
      <P mb={2}>{t(feature.description)}</P>
      <P mb={4}>{t('web.content.features.TimeAvailability.index.note')}</P>
      {contentFeature && (
        <>
          <TimeAvailabilityDaily
            defaultAvailability={defaultAvailability}
            onRemoveAvailability={() =>
              removeAvailability(defaultAvailability!._id)
            }
            onAddAvailability={() =>
              addNewAvailability({ isAvailableAnytime: false })
            }
            onAvailabilityAnytimeChange={onAvailabilityAnytimeChange}
            onToggleOpen={onToggleOpen}
            onTimeRangeSelect={onTimeRangeSelect}
            onAddTimeRange={onAddTimeRange}
            onRemoveTimeRange={onRemoveTimeRange}
            onAllDayToggle={onAllDayToggle}
            timeZone={timeZone}
          />

          <hr />

          <TimeAvailabilityTargeted
            onToggleFeature={toggleTargetedDates}
            hasFeatureToggled={hasTargetedAvailability}
            availabilities={targetedAvailabilities}
            content={content}
            onGroupRoleSelected={onGroupRoleSelected}
            onRemoveAvailability={removeAvailability}
            onAddAvailability={() =>
              addNewAvailability({ allGroupRoles: false })
            }
            onToggleOpen={onToggleOpen}
            onTimeRangeSelect={onTimeRangeSelect}
            onAddTimeRange={onAddTimeRange}
            onRemoveTimeRange={onRemoveTimeRange}
            onAllDayToggle={onAllDayToggle}
            timeZone={timeZone}
          />

          <hr />

          <TimeAvailabilityExcluded
            unavailableDates={unavailableDates}
            onToggleFeature={toggleExcludedDates}
            hasFeatureToggled={hasExcludedAvailability}
            onAddUnavailableDate={addNewUnavailableDate}
            onRemoveUnavailableDate={removeUnavailableDate}
            updateDateRange={updateDateRange}
            timeZone={timeZone}
          />
        </>
      )}
    </div>
  );
}
