import i18next from 'i18next';
import { DateTime } from 'luxon';

import { SHORT_TIME, SHORT_DATE } from '../../constants/dates';
import parseDateTime from '../../dates/parseDateTime';
import dateFormatter from '../dateFormatter';

export default function formatDateRangeHour({
  _start,
  _end,
  i18n = i18next,
  timeZone,
  locale,
}: {
  _start: DateTime | Date | string;
  _end: DateTime | Date | string;
  i18n?: any;
  timeZone?: string;
  locale?: string;
}): string {
  const start = parseDateTime(_start, timeZone)!;
  const end = parseDateTime(_end, timeZone)!;
  const today = DateTime.local();
  if (timeZone) {
    today.setZone(timeZone);
  }
  const tomorrow = today.plus({ day: 1 });
  const yesterday = today.minus({ day: 1 });

  const hoursString = i18n.t('shared.helpers.formatters.dateRange.range', {
    startTime: dateFormatter(start, SHORT_TIME, timeZone, locale),
    endTime: dateFormatter(end, SHORT_TIME, timeZone, locale),
  });

  if (start.hasSame(today, 'day') && end?.hasSame(today, 'day')) {
    return `${i18n.t(
      'shared.helpers.formatters.dateRange.today'
    )} ${hoursString}`;
  }

  if (start.hasSame(tomorrow, 'day') && end.hasSame(tomorrow, 'day')) {
    return `${i18n.t(
      'shared.helpers.formatters.dateRange.tomorrow'
    )} ${hoursString}`;
  }

  if (start.hasSame(yesterday, 'day') && end.hasSame(yesterday, 'day')) {
    return `${i18n.t(
      'shared.helpers.formatters.dateRange.yesterday'
    )} ${hoursString}`;
  }

  if (start.hasSame(end, 'day')) {
    return `${dateFormatter(
      start,
      SHORT_DATE,
      timeZone,
      locale
    )} ${hoursString}`;
  }

  const startFormatted = dateFormatter(start, 'MM dd h a', timeZone, locale);
  const endFormatted = end
    ? dateFormatter(end, 'MM dd h a', timeZone, locale)
    : '…';

  return i18n.t('shared.helpers.formatters.dateRange.range', {
    startTime: startFormatted,
    endTime: endFormatted,
  });
}
