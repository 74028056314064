import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import { PublicUserFragment } from 'lane-shared/graphql/fragments';
import { simpleDateTime } from 'lane-shared/helpers/formatters';

import makeFileDownload from './makeFileDownload';

const perPage = 250;

const query = gql`
  ${PublicUserFragment}

  query interactionsOnChannelQuery(
    $channelId: UUID!
    $sectionId: UUID
    $paymentAccountId: UUID
    $merchantAccountId: UUID
    $search: UserContentInteractionSearch
    $pagination: PaginationInput
  ) {
    interactionsOnChannel(
      channelId: $channelId
      sectionId: $sectionId
      paymentAccountId: $paymentAccountId
      merchantAccountId: $merchantAccountId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        user {
          _id
          name
          profile {
            _id
            name
            image
            logo
          }
        }
        startDate
        endDate
        geo
        data
        state
        features
        contentData
        version
        status
      }
    }
  }
`;

export type ColumnParser = {
  parser: (value: any) => string;
  key: string;
  name: string;
};

export function dateColumnParser(value: any): string {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  return `"${value ? simpleDateTime(value) : value}"`;
}

export function simpleColumnParser(value: any): string {
  return `"${value}"`;
}

const columnParsers: ColumnParser[] = [
  {
    name: 'Created',
    key: '_created',
    parser: dateColumnParser,
  },
  {
    name: 'Updated',
    key: '_updated',
    parser: dateColumnParser,
  },
  {
    name: 'ID',
    key: '_id',
    parser: simpleColumnParser,
  },
  {
    name: 'Content',
    key: 'contentData',
    parser: contentData => `"${contentData.name}"`,
  },
  {
    name: 'Start Date',
    key: 'startDate',
    parser: dateColumnParser,
  },
  {
    name: 'End Date',
    key: 'endDate',
    parser: dateColumnParser,
  },
  {
    name: 'Status',
    key: 'status',
    parser: simpleColumnParser,
  },
  {
    name: 'User',
    key: 'user',
    parser: user => `"${user.profile.name}"`,
  },
];

function columnToHeader(columnParser: ColumnParser) {
  return `"${columnParser.name}"`;
}

export default async function downloadChannelInteractions({
  filename = 'Interactions',
  variables = {},
  additionalColumnParsers = [],
}: {
  filename?: string;
  variables: any;
  additionalColumnParsers: ColumnParser[];
}) {
  let interactions: any = [];
  let str = '';
  let start = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const newVariables = {
      ...variables,
      pagination: {
        start,
        perPage,
      },
    };

    const { data } = await getClient().query({
      query,
      fetchPolicy: 'network-only',
      variables: newVariables,
    });

    if (!data?.interactionsOnChannel) {
      break;
    }

    const { pageInfo, items } = data.interactionsOnChannel;

    interactions = [...interactions, ...(items || [])];

    if (start > pageInfo.total) {
      break;
    }

    window.Alert.loading({
      title: 'Preparing…',
      message: `${Math.round((start / pageInfo.total) * 100)}%`,
    });

    start += perPage;
  }

  // create a header from all the column names
  str += [
    ...columnParsers.map(columnToHeader),
    ...additionalColumnParsers.map(columnToHeader),
  ].join(',');

  str += '\n';

  // using the column parser parse each interaction as a row.
  str += interactions
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'interaction' implicitly has an 'any' ty... Remove this comment to see the full error message
    .map(interaction =>
      columnParsers
        .map(columnParser => columnParser.parser(interaction[columnParser.key]))
        .join(',')
    )
    // and join then all together with new lines.
    .join('\n');

  // then make a file download out of the string.
  makeFileDownload({
    name: `${filename}.csv`,
    contents: str,
    type: 'text/csv',
  });
}
