import React from 'react';

import SectionType from 'lane-shared/types/sections/SectionType';

import PinListView from './PinListView';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  section: Pick<
    SectionType,
    | 'name'
    | 'backgroundColor'
    | 'color'
    | 'logo'
    | 'backgroundImage'
    | 'icon'
    | 'iconSet'
    | 'iconWeight'
  >;
};

export default function SectionListView({ className, style, section }: Props) {
  return (
    <PinListView
      name={section.name}
      backgroundColor={section.backgroundColor}
      color={section.color}
      logo={section.logo}
      backgroundImage={section.backgroundImage}
      icon={section.icon}
      iconSet={section.iconSet}
      iconWeight={section.iconWeight}
      className={className}
      style={style}
    />
  );
}
