import React from 'react';

import { Icon } from 'design-system-web';
import { Marker } from 'react-google-maps';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import useShopifyBurstLocation from 'lane-shared/hooks/integrations/shopify/useShopifyBurstLocation';
import {
  ShopifyBurstReviewSectionTitle,
  ShopifyChannelType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { Map } from 'components/general';
import { M } from 'components/typography';

import ReviewData from './ReviewData';

import styles from './ReviewLocation.scss';

type Props = {
  port: ShopifyChannelType | Partial<ShopifyChannelType>;
  onClick?: () => void;
  primaryRoomBooking: LaneType.UserContentInteraction;
};

export default function ReviewLocation({
  port,
  onClick,
  primaryRoomBooking,
}: Props) {
  const { t } = useTranslation();

  const { address, longitude, latitude } = useShopifyBurstLocation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ShopifyChannelType | Partial<Sho... Remove this comment to see the full error message
    port,
    primaryRoomBooking
  );

  return (
    <ReviewData
      title={t(ShopifyBurstReviewSectionTitle.Location)}
      titleIcon={<Icon set="FontAwesome" name="map-marker-alt" type="far" />}
      onClick={onClick}
      summary={
        <>
          {port?.address && <M variant="secondary">{address}</M>}
          {longitude && latitude ? (
            <div className={styles.map}>
              <Map
                center={{
                  lng: longitude,
                  lat: latitude,
                }}
                zoom={15}
                options={{
                  streetViewControl: false,
                  scaleControl: false,
                  zoomControl: false,
                  panControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                <Marker
                  position={{
                    lng: longitude,
                    lat: latitude,
                  }}
                />
                <div className={styles.mapContent}>X</div>
              </Map>
            </div>
          ) : null}
        </>
      }
      isEditable
    />
  );
}
