import parsePhoneNumber, {
  AsYouType,
  getCountries,
  CountryCode,
  parseDigits,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  isValidPhoneNumber,
  isPossibleNumber,
  isSupportedCountry,
} from 'libphonenumber-js';

import { dropdownItems } from './dropdownItems';
import { mapCountryToCountryCode } from './mapCountryCodes';

/* Matches (123 */
const ENCLOSED_PARENTHESIS_REGEX = /\(\d{1,3}(?!.*\))/;
/**
 * E. 164 numbers are formatted [+] [country code] [subscriber number including area code]
 * and can have a maximum of fifteen digits.
 */
const INTL_FORMAT = 'E.164';

export {
  AsYouType,
  getCountries,
  parsePhoneNumber,
  parseDigits,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  isValidPhoneNumber,
  isPossibleNumber,
  isSupportedCountry,
  mapCountryToCountryCode,
  dropdownItems,
  ENCLOSED_PARENTHESIS_REGEX,
  INTL_FORMAT,
};

export type { CountryCode };
