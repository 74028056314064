import { getClient } from 'lane-shared/apollo';
import { resendVerificationEmail } from 'lane-shared/graphql/user';

export default async function resendVerificationEmailHelper(
  email: string
): Promise<boolean> {
  try {
    await getClient().mutate({
      mutation: resendVerificationEmail,
      variables: {
        email,
      },
    });
    return true
  } catch (err) {
    return false
  }
}
