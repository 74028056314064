import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './styles.scss';

export default function LiveSafe() {
  const { t } = useTranslation();
  return (
    <div className={styles.LiveSafe}>
      <h1>{t('LiveSafe is only available on Mobile.')}</h1>
      <p>{t('Use your mobile device to access this feature.')}</p>
    </div>
  );
}
