import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

export default function mapToGeoJSONPolygon(
  coordinates: [
    GeoCoordinateType,
    GeoCoordinateType,
    GeoCoordinateType,
    GeoCoordinateType
  ]
) {
  return [...coordinates, coordinates[0]];
}
