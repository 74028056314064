import React, { useState } from 'react';

import cx from 'classnames';
import { TimeZoneDropdown } from 'components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import { DatePickerButton, TimeIntervals } from 'design-system-web';

import {
  getTimeZoneByGeoLocation,
  getValidationMessages,
} from 'lane-shared/helpers';
import { AddNotificationType } from 'lane-shared/hooks/useCreateDraft';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';

import { Toggle } from 'components/form';
import { H2, M } from 'components/typography';

import { DraftContentNotifications } from './DraftContentNotifications';

import styles from './DraftContentNotificationsTab.scss';
import type { StepperTrackerType } from 'lane-shared/hooks/analytics/useDraftContentAnalytics';

type Props = {
  content: DraftContentType;
  onContentUpdated: (
    content: Partial<DraftContentType>
  ) => Partial<DraftContentType>;
  onAddNotification: AddNotificationType;
  updateTimezone: (timezone: string) => void;
  timeZone?: string;
  channel?: ChannelType | null;
  liveContent?: DraftContentType | null;
  validation?: ValidationError[] | null;
  stepperTracker?: StepperTrackerType;
};

const TRANSLATION_KEYS = {
  publishContentTitle:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.publishContentTitle',
  publishContentDescription:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.publishContentDescription',
  timezone:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.timezone',
  publishTitle:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.publishTitle',
  unpublishTitle:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.unpublishTitle',
  publishDate:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.publishDate',
  publishTime:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.publishTime',
  unpublishDate:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.unpublishDate',
  unpublishTime:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.unpublishTime',
  setInterativeDate:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.setInterativeDate',
  setInterativeDateTitle:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.setInterativeDateTitle',
  lastInteractiveDateTitle:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.lastInteractiveDateTitle',
  selectDate:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.selectDate',
  selectTime:
    'web.components.lane.DraftContent.DraftContentNotificationsTab.selectTime',
};

export const DraftContentNotificationsTab = ({
  timeZone,
  content,
  channel,
  liveContent,
  validation,
  onContentUpdated,
  onAddNotification,
  updateTimezone,
  stepperTracker,
}: Props) => {
  const { t } = useTranslation();

  const {
    interactionEndDate,
    isEvent,
    liveDate,
    endDate,
    unpublishDate,
  } = content;

  const [
    lastInteractiveDateRequired,
    updateLastInteractiveDateRequired,
  ] = useState<boolean>(!!interactionEndDate);

  if (isEvent && endDate && !unpublishDate) {
    onContentUpdated({ unpublishDate: endDate });
  }

  const channelTimeZone = getTimeZoneByGeoLocation({
    longitude: channel?.address?.geo?.[0]!,
    latitude: channel?.address?.geo?.[1]!,
  });

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const availableTimeZones = [channelTimeZone];

  if (channelTimeZone !== userTimeZone) {
    availableTimeZones.push(userTimeZone);
  }

  return (
    <div className={styles.DraftContentNotificationsTab}>
      {/* ===== PUBLISH DATES ===== */}
      <div className={styles.unpublishHeaderSection}>
        <H2 className={styles.unpublishHeaderText}>
          {t(TRANSLATION_KEYS.publishContentTitle)}
        </H2>
        <label className={styles.unpublishSubheaderText}>
          {t(TRANSLATION_KEYS.publishContentDescription)}
        </label>
      </div>
      <div>
        {!isEvent && (
          <div className={styles.timezoneSection}>
            <M className={styles.timezoneText}>
              {t(TRANSLATION_KEYS.timezone)}
            </M>
            <TimeZoneDropdown
              timeZones={availableTimeZones}
              className={styles.timezoneDropdown}
              onValueChange={updateTimezone}
              value={timeZone || null}
            />
          </div>
        )}
        <div className={styles.unpublishContainer}>
          <div className={styles.eachSection} id="publish-date">
            <label className={styles.publishText}>
              {t(TRANSLATION_KEYS.publishTitle)} <span>*</span>
            </label>
            <DatePickerButton
              includeTime
              timeZone={timeZone}
              onChange={date => {
                const newDraft = onContentUpdated({ liveDate: date });
                stepperTracker?.Publish.PublishDate(newDraft);
              }}
              value={liveDate}
              buttonClassName={cx(
                styles.datePickerButton,
                styles.datePickerButtonTextAlignLeft
              )}
              timeInterval={TimeIntervals.QUARTER_HOUR}
              className={styles.datePicker}
              wrapperClassName={styles.datePickerWrapper}
              dateInputLabel={TRANSLATION_KEYS.publishDate}
              timeInputLabel={TRANSLATION_KEYS.publishTime}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.eachSection} id="unpublish-date">
            <label className={styles.publishText}>
              {t(TRANSLATION_KEYS.unpublishTitle)} <span>*</span>
            </label>
            <DatePickerButton
              buttonClassName={cx(
                styles.datePickerButton,
                styles.datePickerButtonTextAlignLeft
              )}
              includeTime
              timeInterval={TimeIntervals.QUARTER_HOUR}
              timeZone={timeZone}
              className={styles.datePicker}
              wrapperClassName={styles.datePickerWrapper}
              value={unpublishDate}
              onChange={date => {
                const newDraft = onContentUpdated({ unpublishDate: date });
                stepperTracker?.Publish.PublishDate(newDraft);
              }}
              dateInputLabel={TRANSLATION_KEYS.unpublishDate}
              timeInputLabel={TRANSLATION_KEYS.unpublishTime}
              minDate={DateTime.max(
                DateTime.fromJSDate(liveDate!),
                DateTime.fromJSDate(new Date())
              ).toJSDate()}
              dateError={
                getValidationMessages(validation, 'unpublishDate') || []
              }
              timeError={
                getValidationMessages(validation, 'unpublishDate') || []
              }
            />
          </div>
        </div>
      </div>

      {/* ===== INTERACTION END DATE ===== */}
      {content?.isInteractive && (
        <div
          className={styles.lastInteractiveSection}
          id="last-interaction-section"
        >
          <div className={styles.lastInteractiveToggle}>
            <Toggle
              value={lastInteractiveDateRequired}
              onChange={isLastInteractiveRequiredToggleOn => {
                updateLastInteractiveDateRequired(
                  isLastInteractiveRequiredToggleOn
                );
                if (isLastInteractiveRequiredToggleOn) {
                  onContentUpdated({
                    requiredLastInteractiveDate: true,
                  });
                } else {
                  onContentUpdated({
                    requiredLastInteractiveDate: false,
                    interactionEndDate: null,
                  });
                }
              }}
              text={t(TRANSLATION_KEYS.setInterativeDate)}
              description={t(TRANSLATION_KEYS.setInterativeDateTitle)}
            />
          </div>
          {lastInteractiveDateRequired && (
            <div className={styles.unpublishContainer}>
              <div
                className={cx(styles.eachSection, styles.lastInteractiveDates)}
              >
                <M className={styles.lastInteractiveTimeText}>
                  {t(TRANSLATION_KEYS.lastInteractiveDateTitle)} <span>*</span>
                </M>
                <DatePickerButton
                  value={interactionEndDate}
                  onChange={date => {
                    const newDraft = onContentUpdated({
                      interactionEndDate: date,
                    });
                    stepperTracker?.Publish.InteractionEndDate(newDraft);
                  }}
                  includeTime
                  timeInterval={TimeIntervals.QUARTER_HOUR}
                  dateInputLabel={TRANSLATION_KEYS.selectDate}
                  timeInputLabel={TRANSLATION_KEYS.selectTime}
                  timeZone={timeZone}
                  className={styles.datePicker}
                  wrapperClassName={styles.datePickerWrapper}
                  buttonClassName={cx(
                    styles.datePickerButton,
                    styles.datePickerButtonTextAlignLeft
                  )}
                  dateError={
                    getValidationMessages(validation, 'interactionEndDate') ||
                    []
                  }
                  timeError={
                    getValidationMessages(validation, 'interactionEndDate') ||
                    []
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* ===== NOTIFICATIONS ===== */}
      <DraftContentNotifications
        content={content}
        onContentUpdated={onContentUpdated}
        onAddNotification={onAddNotification}
        timeZone={timeZone}
        liveContent={liveContent}
        validation={validation}
        stepperTracker={stepperTracker}
      />
    </div>
  );
};
