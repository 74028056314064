import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

export class LongTextType extends StringTypeBase {
  group = TYPE_GROUP_TEXT;

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  name = 'LongText';

  friendlyName = 'Long Text';

  get example() {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus vitae aliquet nec ullamcorper sit amet risus. Vitae suscipit tellus mauris a diam maecenas. 
    
    Magnis dis parturient montes nascetur ridiculus. Eu lobortis elementum nibh tellus molestie nunc non. Ut sem viverra aliquet eget sit amet tellus cras. Diam donec adipiscing tristique risus nec feugiat in fermentum posuere. Enim ut tellus elementum sagittis vitae et leo duis ut. `;
  }

  get default(): any {
    return '';
  }

  buildSchema() {
    return yup.string().nullable();
  }
}

const definition = new LongTextType();

Types.addBaseType(definition);
export default definition;
