import * as yup from 'yup';

import i18n from 'localization';
import { CONTENT_TYPES_WITH_DATE } from '../../types/content/ContentTypeEnum';
import validateNotification from '../validateCreateContentNotification';

type Notification = {
  __typename?: string;
  title?: string;
  content?: {
    __typename?: string;
    _id?: string;
  };
  sendAt?: Date;
  _new?: boolean;
};

export default yup.object().shape({
  isSyndicateable: yup.boolean().required(),
  notifications: yup
    .array()
    .of<Notification | undefined>(validateNotification)
    .nullable()
    .test(
      'bound_notification_sendAt',
      i18n.t('shared.validation.content.publishing.notification.message'),
      function (notifications) {
        const errors = notifications
          ?.map((notification, index) => {
            if (!notification?.sendAt || !notification?._new) {
              return;
            }
            if (this.parent.liveDate > notification.sendAt) {
              return new yup.ValidationError(
                i18n.t(
                  'shared.validation.content.publishing.notification.beforePublish'
                ),
                '',
                `notifications[${index}].sendAt`
              );
            }
            if (this.parent.unpublishDate < notification.sendAt) {
              return new yup.ValidationError(
                i18n.t(
                  'shared.validation.content.publishing.notification.afterUnpublish'
                ),
                '',
                `notifications[${index}].sendAt`
              );
            }
            return false;
          })
          .filter(Boolean);

        if (errors?.length) {
          // TypeScript expects `errors` to be string | string[]
          // however passing ValidationError[] as `errors` works becuase
          // in the constructor of ValidationError, they basically flatten all the nested validation
          // errors (see https://github.com/jquense/yup/blob/v0.28.5/src/ValidationError.js#L17-L22).
          // We use Yup v0.28.5. In the current version of Yup (v1.2.0), the type of `errors`
          // is actually string | ValidationError | ValidationError[]
          // @ts-expect-error
          return new yup.ValidationError(errors, '', 'notifications');
        }
        return true;
      }
    ),
  unpublishDate: yup.date().when(['type'], {
    is: type => CONTENT_TYPES_WITH_DATE.includes(type),
    then: yup.date().required(i18n.t('This field is required.')),
    otherwise: yup.date(),
  }),
  interactionEndDate: yup
    .date()
    .nullable()
    .when(['requiredLastInteractiveDate'], {
      is: Boolean,
      then: yup
        .date()
        .required(
          i18n.t(
            'shared.validation.content.publishing.lastInteractionEndDate.required'
          )
        ),
      otherwise: yup.date(),
    }),
});
