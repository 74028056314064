import React from 'react';

import { longAddress } from 'lane-shared/helpers/formatters';
import Types from 'lane-shared/properties/Types';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';

import Label from 'components/general/Label';
import { AttachmentContainer } from 'components/lane/AttachmentContainer';
import { SelectUserTypeValue } from 'components/types/SelectUserTypeValue';

import GuestInviteGuestListItem from '../../../features/GuestInvite/GuestInviteGuestListItem';
import ContentSelectorButton from '../../../lane/ContentSelectorButton';
import ChannelTypeValue from '../../../types/ChannelTypeValue';
import ColorTypeValue from '../../../types/ColorTypeValue';
import IconTypeValue from '../../../types/IconTypeValue';
import ImageTypeValue from '../../../types/ImageTypeValue';
import { NavigateToPage } from '../input/NavigateToPage';

import styles from './PropertyValue.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channel?: any;
  field: PropertyType;
  value: any;
  variant?: string;
};
/**
 * Displays the value of a property/type.  Ie. if there is an Icon field,
 * this will display the Icon that someone has selected.  This component is
 * useful in autogenerated UIs.
 */
// eslint-disable-next-line import/no-default-export
export default function PropertyValue({
  className,
  style,
  channel,
  field,
  value,
  variant,
}: Props) {
  if ([null, undefined].includes(value)) {
    return null;
  }

  if (field.forDisplay === false) {
    return null;
  }

  if (field.isArray && Array.isArray(value)) {
    return value.map((value, ix) => (
      <PropertyValue
        key={value?._id || value || ix}
        style={style}
        field={field}
        channel={channel}
        value={value}
      />
    ));
  }

  const Type = Types.getType(field.type);
  const types = Types.getTypes();

  // Types can define their own value renderer.
  switch (field.type) {
    case types.GuestInviteGuestType.name:
      return (
        <GuestInviteGuestListItem
          className={className}
          style={style}
          guest={value}
        />
      );
    case types.Address.name:
      return longAddress(value);
    case types.Icon.name:
      return (
        <IconTypeValue className={className} style={style} value={value} />
      );
    case types.Color.name:
      return (
        <ColorTypeValue className={className} style={style} value={value} />
      );
    case types.Image.name:
      return (
        <ImageTypeValue className={className} style={style} value={value} />
      );
    case types.Channel.name:
      return (
        <ChannelTypeValue className={className} style={style} value={value} />
      );
    case types.Content.name:
      return (
        <>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'options' is missing in type '{ disabled:... Remove this comment to see the full error message */}
          <ContentSelectorButton
            disabled
            channelId={channel?._id}
            contentId={value?._id}
          />
          {value?._id && (
            <NavigateToPage contentId={value?._id} channel={channel} />
          )}
        </>
      );
    case types.Currency.name:
      return Type.formatter!(value);
    case types.SelectUser.name:
      return <SelectUserTypeValue users={value?.users} variant={variant} />;
    case types.Attachment.name:
      return (
        <AttachmentContainer
          entityId={value?.customGeneratedId}
          editMode={false}
        />
      );
    default:
  }

  if (!Types.isBaseType(Type.name)) {
    return (
      <fieldset className={styles.wrapper}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        {Object.entries(Type.properties).map(([key, subProperty], ix) => (
          <div key={key}>
            {(!subProperty.isArray ||
              subProperty.packagedType === PackagedTypeEnum.Checkboxes) && (
              <Label TooltipComponent={subProperty.description}>
                {subProperty.friendlyName || subProperty.name || key}
              </Label>
            )}
            <PropertyValue
              key={value?._id || value || ix}
              style={style}
              field={subProperty}
              channel={channel}
              value={value?.[key]}
            />
          </div>
        ))}
      </fieldset>
    );
  }

  // Or types may have a formatter defined.
  if (Type.formatter) {
    return Type.formatter(value) || null;
  }

  if (typeof value === 'object') {
    // this should never happen, but there is a weird bug that causes this
    // type to be mis-matched with the actual data.
    // todo: we should fix the source problem rather than patch this.
    return JSON.stringify(value) || null;
  }

  return value || null;
}
