import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { getChannelAccessControlGroups } from 'lane-shared/graphql/accessControl';

import { Table } from 'design-system-web';
import { AdminPage, PageHeader } from 'components/layout';

import { AccessControlGroup } from 'graphql-query-contracts';

import styles from './styles.scss';

export function AccessControlGroupsPage({
  channel,
}: {
  channel: LaneType.ChannelIntegration;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [accessControlGroups, setAccessControlGroups] = useState<
    AccessControlGroup[]
  >([]);

  const fetchAccessControlGroups = useCallback(async (channelId: string) => {
    try {
      setLoading(true);
      const { data } = await getClient().query({
        query: getChannelAccessControlGroups,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });

      setAccessControlGroups(data.getChannelAccessControlGroups);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (channel?._id) {
      fetchAccessControlGroups(channel._id);
    }
  }, [channel?._id]);

  const columns = [
    {
      key: 'name',
      header: t('web.admin.accessControl.accessControlGroups.table.groupName'),
      renderCell: (_: any, row: AccessControlGroup) => {
        return (
          <Link
            to={routes.channelAdminAccessControlGroupDetails
              .replace(':id', channel._id)
              .replace(':acgId', row.id)}
          >
            {row.name}
          </Link>
        );
      },
    },
    {
      key: 'description',
      header: t(
        'web.admin.accessControl.accessControlGroups.table.description'
      ),
    },
    {
      key: 'userCount',
      header: t('web.admin.accessControl.accessControlGroups.table.userCount'),
    },
  ];

  const pageHeaderProps = {
    header: t('web.admin.accessControl.accessControlGroups.title'),
    breadcrumbs: [
      {
        label: t('web.admin.accessControl.header'),
        url: routes.channelAdminUserAccess.replace(':id', channel?._id),
      },
      {
        label: t('web.admin.accessControl.accessControlGroups.title'),
      },
    ],
  };

  return (
    <AdminPage>
      <div className={styles.accessControlGroupsHeader}>
        <PageHeader {...pageHeaderProps} headerLevel="h3" />
      </div>
      <Table
        isLoading={loading}
        columns={columns}
        data={accessControlGroups}
        filters={[]}
        pagination="client"
        isSelectable
        hasKeywordFilter
        rowActions={[]}
        emptyMessage={t(
          'web.admin.accessControl.accessControlGroups.table.acgs.emptyMessage'
        )}
      />
    </AdminPage>
  );
}
