import { request, Res } from './common';
import { Organization } from './organizations';

export enum UsersRightsEnum {
  CanCancelVisitor = 'can_cancel_visitor',
  CanManageVisits = 'can_manage_visits',
}
export interface User {
  id: string;
  name: string;
  organization: Organization;
  image_url: string | null;
  rights: Array<UsersRightsEnum>;
  building_ids: Array<string>;
}

export async function currentUser(token: string): Promise<Res<User, {}>> {
  return request({ token, method: 'get', path: '/users/current_user' });
}
