import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { H5, M } from 'components/typography';

import styles from './EmptyPage.scss';

const TRANSLATIONS_KEYS = {
  title: 'web.admin.channel.reservableManagement.emptyPage.title',
  description: 'web.admin.channel.reservableManagement.emptyPage.description',
};

const EmptyPage = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <div className={styles.icon}>
        <Icon type="fal" set="FontAwesome" name="calendar-week" />
      </div>
      <div className={styles.title}>
        <H5>{t(TRANSLATIONS_KEYS.title)}</H5>
      </div>
      <div className={styles.description}>
        <M>{t(TRANSLATIONS_KEYS.description)}</M>
      </div>
    </section>
  );
};

export default EmptyPage;
