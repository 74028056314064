import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

const MAX_LABELS = 65535;
const LABEL_MAX_LENGTH = 256;
const LABEL_MIN_LENGTH = 1;

export function validatePathType(value: string | undefined | null): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  const labels = value.split('.');

  if (labels.length > MAX_LABELS) {
    throw new Error(`Path can have max ${MAX_LABELS} labels`);
  }

  for (let i = 0; i < labels.length; i++) {
    if (labels[i].length > LABEL_MAX_LENGTH) {
      throw new Error(
        `Invalid value "${labels[i]}" Each label can have max length ${LABEL_MAX_LENGTH}`
      );
    }

    // only the root path may be empty string ''
    if (i !== 0 && labels[i].length < LABEL_MIN_LENGTH) {
      throw new Error(
        `Invalid value "${labels[i]}" Each label must be at least length ${LABEL_MIN_LENGTH}`
      );
    }

    if (!/^[A-Za-z0-9_]*$/.test(labels[i])) {
      throw new Error(
        `Invalid value "${labels[i]}" Path labels must only be A-Za-z0-9_`
      );
    }
  }

  return true;
}

/**
 * Path is a string that matches the implementation of ltree,
 * alphanumeric labels (A-Za-z0-9_) separated by a dot . that represent
 * a hierarchical path structure. This is implemented in postgres as a
 * gist ltree index and in several other databases using a similar format.
 *
 * See https://www.postgresql.org/docs/13/ltree.html for the postgres
 * implementation of ltree
 */
export class PathType extends StringTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Path';

  friendlyName = 'Path';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
    TypeContextEnum.Metatag,
  ];

  superUserOnly = false;

  get example() {
    return 'Top.Countries.North_America.Canada';
  }

  get default(): any {
    return null;
  }

  buildSchema() {
    return yup
      .string()
      .nullable()
      .test('is-valid-path', '${path} is not a valid path', validatePathType);
  }
}

const definition = new PathType();

Types.addBaseType(definition);

export default definition;
