import { useContext } from 'react';

import { AppContext } from 'lane-shared/contexts';
import { NotImplementedError } from 'activate-errors';
import {
  OAuthProvidersEnum,
  OAUTH_PROVIDERS,
} from 'lane-shared/helpers/constants/user';
import { canHandleError } from 'lane-shared/helpers/oAuth';
import useOAuthSignUp, {
  OAuthGetIdentityResponse,
} from 'lane-shared/hooks/useOAuthSignUp';
import LaneOAuthHook from 'lane-shared/types/LaneOAuthHook';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { UserLoginProviderEnum } from 'lane-shared/types/UserLogin';
import { OAuthUser } from 'lane-shared/types/oauth';

import { AuthToken } from '../typings';
import { getOAuthIdentity } from '../utils/oauth';
import { getAppleAccessToken } from '../utils/oauth/apple/getAppleAccessToken';

type UseAppleOAuthProps = {
  oAuthConfig: OAuthConfigType;
  onError?: (err: Error | null) => void;
  onSuccess: (signInData?: AuthToken) => void;
};

export async function getIdentity({
  oAuthConfig,
}: {
  oAuthConfig: OAuthConfigType;
}): Promise<OAuthGetIdentityResponse> {
  const oAuthIdentity = await getOAuthIdentity({
    oAuthConfig,
    provider: OAuthProvidersEnum.APPLE,
  });

  const { extra } = oAuthIdentity;

  if (extra) {
    const { user }: { user?: OAuthUser } = extra;

    return {
      preferredName: user ? `${user.firstName} ${user.lastName}` : '',
      userPrincipalName: user ? user.email : '',
    };
  }

  // NOTE/TODO: Best guess of name support for Okta's use of OAuth2 Spec,
  // This is unlikely to be seen, and can be addressed when/as encountered.
  // NI68 was chosen to represent line number at time of writing.
  throw new NotImplementedError(
    'Identity support for Apple ID is not well defined. NI72'
  );
}

export default function useAppleOAuth({
  oAuthConfig,
  onError,
  onSuccess,
}: UseAppleOAuthProps): LaneOAuthHook {
  const { setIsBlockingLoad } = useContext(AppContext);

  const isEnterprise = Boolean(oAuthConfig.tenantId);

  function handleError(err: Error | null) {
    if (onError && canHandleError(err)) {
      onError(err);
      setIsBlockingLoad(false);
    }
  }

  function handleResume() {
    setIsBlockingLoad(true);
  }

  function handleSuccess(signInData?: AuthToken) {
    setIsBlockingLoad(false);
    onSuccess(signInData);
  }

  const { doSignUp } = useOAuthSignUp(
    getOAuthIdentity,
    getAppleAccessToken,
    getIdentity,
    {
      onSuccess: handleSuccess,
      onError: handleError,
      onResume: handleResume,
      oAuthConfig,
    }
  );

  function onAppleIDSignUp() {
    handleError(null);

    doSignUp({
      oAuthIdentityProps: {
        oAuthConfig,
        provider: OAuthProvidersEnum.APPLE,
      },
      oAuthGetIdentityProps: {
        oAuthConfig,
      },
      oAuthAccessTokenProps: {
        oAuthConfig,
      },
      legacyOAuthProvider: OAUTH_PROVIDERS.APPLE,
      loginProvider: UserLoginProviderEnum.Apple,
    });
  }

  return {
    authorize: onAppleIDSignUp,
    isEnabled: Boolean(oAuthConfig?.enabled),
    enterprise: isEnterprise,
  };
}
