import { cloneDeep, isEqual } from 'lodash';

import {
  SURVEY_TYPE_TO_CONTENT_MAPPING,
  QuestionsType,
} from 'lane-shared/domains/surveys/constants/questions';
import { BlockInstanceType } from 'lane-shared/types/blocks/BlockInterface';
import { ContentType } from 'lane-shared/types/content/Content';

export function getSurveyContentToRemove({
  content,
}: {
  content: ContentType;
}): ContentType {
  let contentCopy = cloneDeep(content);

  // Get all survey related question object arrays
  const contentArrs = Object.values(SURVEY_TYPE_TO_CONTENT_MAPPING);

  const questionsArrs: QuestionsType[][] = contentArrs.map(
    ({ questions }: { questions: QuestionsType[] }) => questions
  );
  const flattendQuestionsArr: QuestionsType[] = questionsArrs.flat(1);
  const questions = flattendQuestionsArr.map(({ key }) => key);
  const fieldsToRemove = [] as string[];

  // Remove survey related data
  const dataWithoutSurveyInputs = cloneDeep(content.data);
  if (dataWithoutSurveyInputs) {
    Object.entries(dataWithoutSurveyInputs).forEach(([key, value]) => {
      if (questions.includes((value as any).friendlyName)) {
        fieldsToRemove.push(key);
        delete dataWithoutSurveyInputs[key];
      }
    });
  }

  // Remove survey related blocks
  const blockArrs: any[][] = contentArrs.map(
    ({ blocks }: { blocks: any[] }) => blocks
  );
  const flattendBlocksArr: any[] = blockArrs.flat(1);
  const blockWithoutSurveyInputs = cloneDeep(content.block);
  const blockChildren = blockWithoutSurveyInputs.properties.children;

  const blockChildrenWithoutSurvey = blockChildren.filter(
    (child: BlockInstanceType) => {
      const isBlockToKeep = !flattendBlocksArr.some(block =>
        isEqual(child.properties, block.properties)
      );
      const isQuestionToKeep = !fieldsToRemove.includes(child.for ?? '');
      return isQuestionToKeep && isBlockToKeep;
    }
  );
  blockWithoutSurveyInputs.properties.children = blockChildrenWithoutSurvey;

  // Revert changes to content
  contentCopy = {
    ...contentCopy,
    data: dataWithoutSurveyInputs,
    block: blockWithoutSurveyInputs,
  };

  return contentCopy;
}
