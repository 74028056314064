import React from 'react';

import cx from 'classnames';

import { ContentWorkflowWorkflowDataType } from 'lane-shared/types/ContentWorkflowType';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';

import Dropdown from 'components/form/Dropdown';
import useContentStatusTransitionOptions from 'components/workflows/useContentStatusTransitionOptions';

import styles from './ContentWorkflowEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  statusesFeature: StatusesFeatureProperties | null | undefined;
  workflowData: ContentWorkflowWorkflowDataType | null | undefined;
  onWorkflowDataUpdated: (
    workflowData: Partial<ContentWorkflowWorkflowDataType>
  ) => void;
};

export default function ContentWorkflowStatusesEdit({
  className,
  style,
  statusesFeature,
  workflowData,
  onWorkflowDataUpdated,
}: Props) {
  const {
    statusFromOptions,
    statusToOptions,
  } = useContentStatusTransitionOptions({
    statusesFeature,
    statusFrom: workflowData?.statusFrom,
  });

  return (
    <div className={cx(styles.statusChange, className)} style={style}>
      <Dropdown
        placeholder="from status"
        className={styles.mediumDropdown}
        items={statusFromOptions}
        value={workflowData?.statusFrom}
        onValueChange={statusFrom =>
          onWorkflowDataUpdated({
            statusFrom,
          })
        }
      />
      <span>to</span>
      <Dropdown
        placeholder="to status"
        className={styles.mediumDropdown}
        items={statusToOptions}
        value={workflowData?.statusTo}
        onValueChange={statusTo =>
          onWorkflowDataUpdated({
            statusTo,
          })
        }
      />
    </div>
  );
}
