// store the font size here.
let fontSize = 14;

// compute the font size using the body element
function _getBaseFontSize() {
  fontSize = parseFloat(getComputedStyle(document.body).fontSize);
}

// return the last computed value.
function getBaseFontSize() {
  return fontSize;
}

// every time the window resizes, recompute this.
window.addEventListener('onresize', _getBaseFontSize);

// compute this on load.
_getBaseFontSize();

export default getBaseFontSize;
