/**
 * Removes duplicates from an array based on the provided. Note this doesn't
 * work on objects, just values.
 *
 * @param arr
 * @param key
 * @returns {*}
 */
export default function deDupe(arr: any, key: any) {
  // use a simple hash table for quick lookups.
  const toFilter = {};

  return arr.filter((obj: any) => {
    if (!obj[key]) {
      return false;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (toFilter[obj[key]]) {
      return false;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    toFilter[obj[key]] = true;

    return true;
  });
}
