import { getClient } from 'lane-shared/apollo';
import { createAttachment as createAttachmentMutation } from 'lane-shared/graphql/attachment';
import type {
  AttachmentFilePayload,
  AttachmentThumbnailPayload,
  AttachmentResponse,
  AttachmentEntityInput,
} from 'lane-shared/types/attachment';

import { AmazonS3Buckets } from '../../types/media';

type GQLCreateAttachmentResponse = {
  data?: {
    createAttachment: AttachmentResponse;
  } | null;
};

type Props = {
  entity: AttachmentEntityInput;
  attachment: AttachmentFilePayload;
  thumbnail?: AttachmentThumbnailPayload;
  s3Bucket: string;
};

export const createAttachment = async ({
  entity,
  attachment,
  thumbnail,
  s3Bucket = AmazonS3Buckets.Lane,
}: Props): Promise<AttachmentResponse> => {
  const variables = {
    attachment: {
      entityType: entity.type,
      entityId: entity.id,
      file: attachment,
      thumbnail: {},
      s3Bucket,
    },
  };

  if (thumbnail) {
    variables.attachment.thumbnail = {
      ...thumbnail,
    };
  }

  const { data }: GQLCreateAttachmentResponse = await getClient().mutate({
    mutation: createAttachmentMutation,
    variables,
  });

  return data!.createAttachment;
};
