import { explodeFeatures } from '../../../helpers/features';
import { ContentType } from '../../../types/content/Content';
import useContentQuery from '../../useContentQuery';
import useQRCodeCheckIn, { QRCodeCheckInMode } from './useQRCodeCheckIn';

type Props = {
  interaction:
    | {
        contentData: ContentType;
      }
    | null
    | undefined;
  mode: QRCodeCheckInMode;
};

/**
 * Use this hook when coming in from the context of an Interaction, i.e. you
 * have a Interaction but you don't have the associated content yet.
 *
 * This would happen when the user clicks/scans/navigates to an interaction
 * route.
 */
export default function useInteractionQRCodeCheckIn({
  interaction,
  mode,
}: Props) {
  const { loading, error, content } = useContentQuery({
    contentId: interaction?.contentData?._id,
    forPublic: false,
    includeInteractions: true,
  });

  const { qrCodeCheckinFeature } = explodeFeatures(
    interaction?.contentData?.features
  );

  const {
    error: interactionError,
    loading: interactionLoading,
    checkInRule,
    checkOutRule,
    fromStatus,
    toStatus,
    canCheckInOrOut,
    isCancelled,
    submitCheckIn,
    submitCheckOut,
  } = useQRCodeCheckIn({ qrCodeCheckinFeature, interaction, mode });

  return {
    error: interactionError || error,
    loading: interactionLoading || loading,
    qrCodeCheckinFeature,
    content,
    checkInRule,
    checkOutRule,
    fromStatus,
    toStatus,
    canCheckInOrOut,
    isCancelled,
    submitCheckIn,
    submitCheckOut,
  };
}
