import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Features from 'lane-shared/renderers/v5/features';
import parseValue from 'lane-shared/renderers/v5/parseValue';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import {
  FeatureProperties,
  TAnyFeatureType,
} from 'lane-shared/types/FeatureInstance';

import Property from 'components/builder/properties/input/Property';
import Label from 'components/general/Label';

import { FeatureRendererPropsType } from '../FeatureRendererPropsType';
import AlertMessagesRenderer from './AlertMessagesRenderer';

import styles from './FeatureAutoRenderer.scss';

/**
 * Auto renders a feature.  This will not be the most ideal UI/UX for
 * interacting however it is a quick and easy solution to get a UI/UX up
 * and running.
 *
 * Implement a full feature by creating a full component, i.e. Payment
 */
export default function FeatureAutoRenderer<
  T extends FeatureProperties = TAnyFeatureType
>({
  disabled,
  className,
  style,
  timeZone,
  content,
  featureName,
  feature,
  contentFeature,
  onFeatureUpdated,
  workOrderVariant,
}: FeatureRendererPropsType<T, 'auto'>) {
  // we're doing some manipulation of the feature on FeatureBuilder, let's make sure we
  // use that version if we have it
  const featureDefinition: FeatureDefinition = feature || Features[featureName];
  const { t } = useTranslation();

  return (
    <section
      className={cx(styles.FeatureAutoRenderer, className)}
      style={style}
    >
      <Label h1>{t(featureDefinition.friendlyName || '')}</Label>
      <p>{t(featureDefinition.description)}</p>
      {AlertMessagesRenderer(featureName, content.features, t)}
      {contentFeature && (
        <section className={styles.values}>
          {Object.entries(featureDefinition.properties || {})
            .filter(([, property]) =>
              parseValue({
                value:
                  property.showControl === undefined
                    ? true
                    : property.showControl,
                props: contentFeature.feature,
                propDefs: featureDefinition.properties,
              })
            )
            .map(([key, property]) => (
              <div key={key} className={styles.property}>
                {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ disabled: boolean | undefined; propertyKey... Remove this comment to see the full error message */}
                <Property
                  disabled={workOrderVariant ? true : disabled}
                  propertyKey={key}
                  label={property.friendlyName || key}
                  description={property.description}
                  timeZone={timeZone}
                  channel={content.channel}
                  property={property}
                  value={contentFeature.feature[key]}
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ [x: string]: any; }' is not as... Remove this comment to see the full error message
                  onChange={value => onFeatureUpdated({ [key]: value })}
                  workOrderVariant={workOrderVariant}
                />
              </div>
            ))}
        </section>
      )}
    </section>
  );
}
