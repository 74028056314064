// @ts-nocheck FIXME: Ignored due failing CI after React update
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  ReactNode,
} from 'react';

import { Icon } from 'design-system-web';
import { ValidatedTextArea, Loading } from 'components';
import { useTranslation } from 'react-i18next';

import AnalyticsContext from 'lane-shared/contexts/AnalyticsContext';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import UserDataContext from 'lane-shared/contexts/UserDataContext';
import { emitFileUploaded } from 'lane-shared/helpers/analytics/emitFileUpload';
import { emitWorkOrderCommentCreated } from 'lane-shared/helpers/analytics/emitWorkOrder';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  mapStatusToLaneStatus,
  PRIORITY_MAP,
  PRIORITY_COLOR_MAP,
  isWorkOrderOpen,
} from 'lane-shared/helpers/constants/integrations/BuildingEnginesPrismWorkOrders';
import { simpleDate } from 'lane-shared/helpers/formatters';
import * as BuildingEnginesPrism from 'lane-shared/helpers/integrations/BuildingEnginesPrism';
import {
  workOrders as workOrdersClient,
  customFieldForms as customFieldFormsClient,
  issueTypesJsonForms as issueTypesJsonFormsClient,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client';
import { IssueType } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/issueTypes';
import { fetchUserImageUrl } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/fetchUserImageUrl';
import { getIssueText } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/getIssueText';
import {
  getToken,
  removeToken,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/token';
import { useFlag } from 'lane-shared/hooks';
import useStatusColor from 'lane-shared/hooks/useStatusColor';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { ContentType } from 'lane-shared/types/content/Content';

import FileInput from 'components/form/FileInput';
import Attachment, {
  attachmentProps,
} from 'components/general/AttachmentModal';
import Pill from 'components/general/Pill';
import Thumbnail from 'components/general/Thumbnail';
import { Alert, AlertType } from 'components/lds';
import { H4, M } from 'components/typography';

import { history } from '../../../../helpers';
import { Button } from '../../../general';
import Header from './Header';
import NewOrderModal from './NewOrderModal';
import RequestCard from './RequestCard';
import { MailIcon } from './mailIcon';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import styles from './OpenRequests.scss';

const timeToCloseAlert = 5000;

/**
 * Since the Pill Component icon uses colors that
 * CSS styles allow, meadow and honey need to be
 * converted to green and orange respectively
 * @see packages/lane-shared/hooks/useStatusColor.ts
 * @returns string
 */
export const getPillCircleColor = (statusColor: string): string => {
  if (statusColor === 'honey') {
    return 'orange';
  }
  if (statusColor === 'meadow') {
    return 'green';
  }
  return statusColor;
};

type Props = {
  content: ContentType;
};

export default function OpenRequests({ content }: Props) {
  const [storedToken, setStoredToken] = useState<string | undefined>(undefined);
  const [workorderToShow, setWorkorderToShow] = useState<number>(0);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const { t } = useTranslation();
  const [loadingWorkorders, setLoadingWorkorders] = useState(true);
  const [hasAttachmentError, setHasAttachmentError] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [isLoadingAttachment, setIsLoadingAttachment] = useState<boolean>(
    false
  );
  const [workorders, setWorkorders] = useState<workOrdersClient.WorkOrder[]>(
    []
  );
  const [attachments, setAttachments] = useState<attachmentProps[]>([]);
  const [workorderComments, setWorkorderComments] = useState<any[]>([]);
  const [allWorkorderComments, setAllWorkorderComments] = useState<any[]>([]);
  const [showLessComments, setShowLessComments] = useState(false);
  const [spaces, setSpaces] = useState<any[]>([]);
  const [issueTypes, setIssueTypes] = useState<IssueType[]>([]);
  const [nextPageLink, setNextPageLink] = useState<URLSearchParams | undefined>(
    undefined
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [error, setError] = useState<React.ReactNode>(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState<
    issueTypesJsonFormsClient.Field[]
  >([]);
  const [isCancellingWorkOrder, setIsCancellingWorkOrder] = useState<boolean>(
    false
  );
  const newFunctionalityEnabled = useFlag(FeatureFlag.PrismWorkOrder, true);

  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);
  const { primaryChannel } = useContext(ChannelsContext);

  function _activeWorkorder(opts: {
    fail: false;
  }): workOrdersClient.WorkOrder | undefined;
  function _activeWorkorder(opts?: { fail: true }): workOrdersClient.WorkOrder;
  function _activeWorkorder(
    opts: { fail: boolean } = { fail: true }
  ): workOrdersClient.WorkOrder | undefined {
    const workorder = workorders[workorderToShow];
    if (opts.fail && !workorder)
      throw new Error('failed to get active workorder');
    return workorder;
  }

  const activeWorkorder = useCallback(_activeWorkorder, [
    workorderToShow,
    workorders,
    _activeWorkorder,
  ]);

  function onLogout() {
    removeToken();
    history.goBack();
  }

  function handleNewOrderSuccess() {
    setSuccess(t('Your work order was successfully submitted'));
    setTimeout(() => {
      setSuccess(null);
    }, timeToCloseAlert);
  }

  async function ShowWorkOrderWithComments(i: number) {
    setWorkorderComments([]);
    setAllWorkorderComments([]);
    setCustomFields([]);
    setLoading(true);
    setShowLessComments(false);
    setWorkorderToShow(i);
    const workOrder = workorders[i];
    if (!workOrder) throw new Error('no workorder');
    await getWorkOrderComments(workOrder.id);
    await getAttachments(workOrder.id);
    if (workOrder.on_new_serdy_form) {
      await getCustomFieldForm(workOrder.on_new_serdy_form);
    }
  }

  async function getWorkOrderComments(workOrderId: string) {
    try {
      const response = await BuildingEnginesPrism.requestHelpers.getWorkorderComments(
        storedToken!,
        workOrderId
      );
      setWorkorderComments(response?.slice(0, 2));
      setAllWorkorderComments(response);
      setLoading(false);
    } catch (err) {
      window.Alert.alert({
        title: t('Unable to get comments!'),
        message: t('Please try again later.'),
      });
      setLoading(false);
    }
  }

  /**
   *
   * Here file id and file association id are optional
   * When user uploads a file, if it fails then we dont have these two fields.
   * We show an error template in that scenario.
   * If user tries to delete that error template there should not be any failure.
   */
  async function deleteFile(
    fileId?: string | number,
    fileAssociationId?: string
  ) {
    try {
      if (fileId && fileAssociationId) {
        const response = await BuildingEnginesPrism.requestHelpers.deleteFile(
          storedToken!,
          fileId as string,
          fileAssociationId
        );
        if (response?.status === 204) {
          const updatedAttachments = attachments.filter(
            attachment => attachment.id !== fileId
          );
          setAttachments(updatedAttachments);
          await getWorkOrderComments(activeWorkorder().id);
        }
      } else {
        setHasAttachmentError(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function updateComment() {
    try {
      setLoading(true);
      await BuildingEnginesPrism.requestHelpers.postWorkorderComments(
        storedToken!,
        comment,
        false,
        activeWorkorder().id
      );

      if (user && primaryChannel) {
        emitWorkOrderCommentCreated({
          userId: user._id,
          channelId: primaryChannel._id,
          integrationId: content.integration._id,
          analytics,
        });
      }

      setComment('');
      await getWorkOrderComments(activeWorkorder().id);
    } catch (err) {
      window.Alert.alert({
        title: t('Unable to add comment!'),
        message: t('Please try again later.'),
      });
      setLoading(false);
    }
  }
  const cancelWorkOrder = async () => {
    try {
      setIsCancellingWorkOrder(true);
      const cancelledWorkOrderResponse = await workOrdersClient.updateStatus(
        storedToken!,
        activeWorkorder().id,
        workOrdersClient.WorkOrderStatusTypes.Cancelled
      );
      const cancelledWorkOrder = await cancelledWorkOrderResponse.data;
      if (
        cancelledWorkOrder.status ===
        workOrdersClient.WorkOrderStatusTypes.Cancelled
      ) {
        window.Alert.alert({
          title: t(
            'shared.integrations.bePrismWorkOrders.cancelWorkOrderSuccessful'
          ),
        });
      }
    } catch (e) {
      window.Alert.alert({
        title: t(
          'shared.integrations.bePrismWorkOrders.cancelWorkOrderFailure'
        ),
      });
    }
    setIsCancellingWorkOrder(false);
  };

  const renderCancelButton = (): React.ReactNode => {
    if (isWorkOrderOpen(activeWorkorder())) {
      return (
        <Button
          className={styles.cancelWorkOrderButton}
          labelClassName={styles.cancelWorkOrderLabel}
          onClick={cancelWorkOrder}
          loading={isCancellingWorkOrder}
          variant="text"
          size="small"
        >
          {t('shared.integrations.bePrismWorkOrders.cancelWorkOrder')}
        </Button>
      );
    }
    return <></>;
  };

  async function updateAttachments(file: File) {
    setIsLoadingAttachment(true);
    setHasAttachmentError(false);
    setFileName(file.name);
    if (file.size > 30000000) {
      setIsLoadingAttachment(false);
      setHasAttachmentError(true);
    } else {
      try {
        const response = await BuildingEnginesPrism.requestHelpers.uploadFile(
          storedToken!,
          activeWorkorder().id,
          file,
          file.name
        );
        if (response.status !== 201) {
          setHasAttachmentError(true);
        } else {
          await getAttachments(activeWorkorder().id);
          await getWorkOrderComments(activeWorkorder().id);

          if (user && primaryChannel) {
            emitFileUploaded({
              userId: user._id,
              channelId: primaryChannel._id,
              integrationId: content.integration._id,
              analytics,
            });
          }
        }
        setIsLoadingAttachment(false);
      } catch (err) {
        console.error(err);
        setIsLoadingAttachment(false);
        setHasAttachmentError(true);
      }
    }
  }

  async function getAttachments(workOrderId: string) {
    try {
      const response = await BuildingEnginesPrism.requestHelpers.getWorkOrderFiles(
        storedToken!,
        workOrderId
      );
      setAttachments(response);
    } catch (err) {
      console.error(err);
    }
  }
  async function getCustomFieldForm(customFieldFormId: string) {
    try {
      const response = await customFieldFormsClient.get(
        storedToken!,
        customFieldFormId
      );
      const fields = (await response.data).form.children[0]?.children ?? [];
      setCustomFields(fields);
    } catch (err) {
      window.Alert.alert({
        title: t(
          'laneMobile.integrations.bePrismWorkOrders.fetchCustomFieldFormError'
        ),
      });
    }
  }

  const [listUpdated, setListUpdated] = useState(false);
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchImageUrl() {
      const assigneeId = activeWorkorder({ fail: false })?.assignee?.id;
      if (!assigneeId) return;
      const url = await fetchUserImageUrl(assigneeId, abortController);
      setImageUrl(url!);
    }

    fetchImageUrl();

    return () => {
      abortController.abort();
    };
  }, [activeWorkorder]);

  async function loadMore() {
    setLoadingMore(true);

    try {
      const response = await BuildingEnginesPrism.requestHelpers.fetchMoreWorkorders(
        storedToken!,
        nextPageLink!
      );

      setWorkorders(workorders.concat(response.results));
      setNextPageLink(response.next);
    } catch (err) {
      // do nothing
    }

    setLoadingMore(false);
  }

  function getMoreComments() {
    if (showLessComments) {
      setWorkorderComments(allWorkorderComments.slice(0, 2));
    } else {
      setWorkorderComments(allWorkorderComments);
    }
    setShowLessComments(!showLessComments);
  }

  useEffect(() => {
    async function getSpacesData() {
      const responseFormData = await BuildingEnginesPrism.requestHelpers.fetchWorkorderFormData(
        storedToken!,
        content.integration.settings.buildingId
      );

      const spaceIDs: any[] = [];

      if (responseFormData?.floors) {
        responseFormData.floors.forEach(({ id, name, spaces }: any) => {
          spaceIDs.push({
            value: id,
            label: name,
          });

          if (spaces) {
            // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'spaceId' implicitly has an 'any' ... Remove this comment to see the full error message
            spaces.forEach(({ id: spaceId, name: spaceName }) => {
              spaceIDs.push({
                value: spaceId,
                label: `Space: ${spaceName}`,
              });
            });
          }
        });
      }

      setSpaces(spaceIDs);
      setIssueTypes(responseFormData.issue_types);
    }

    if (!storedToken) {
      return;
    }

    getSpacesData();
  }, [storedToken]);

  useEffect(() => {
    async function getTokenOnLoad() {
      setStoredToken(await getToken());
    }

    getTokenOnLoad();
  }, []);

  useEffect(() => {
    async function doFetchWorkOrders() {
      setListUpdated(true);

      const response = await BuildingEnginesPrism.requestHelpers.fetchWorkorders(
        storedToken!,
        content.integration.settings.buildingId
      );
      setShowLessComments(false);
      setLoadingWorkorders(false);
      setListUpdated(false);
      setWorkorders(response?.results || []);
      setNextPageLink(response?.next);

      if (response?.results?.[0]) {
        await getWorkOrderComments(response.results[0].id);
        await getAttachments(response.results[0].id);
      }
    }

    if (!storedToken) {
      return;
    }

    doFetchWorkOrders();
  }, [storedToken, success, isCancellingWorkOrder]);

  const statusColor = useStatusColor({
    status: mapStatusToLaneStatus(
      activeWorkorder({ fail: false })?.status ?? ''
    ),
  });

  if (loadingWorkorders) {
    return (
      <div className={styles.container}>
        <Loading fullscreen />
      </div>
    );
  }

  if (workorders?.length === 0 && !loadingWorkorders) {
    return (
      <div className={styles.container}>
        <Header content={content} onLogout={onLogout} setError={setError} />
        <div className={styles.emptyBlock}>
          <MailIcon />
          <p className={styles.title}>{t('No requests available')}</p>
          <p className={styles.subTitle}>
            {t(
              'You currently don’t have any maintenance requests. To get started, click the button below to create new order.'
            )}
          </p>
          <Button
            className={styles.button}
            onClick={() => setIsAddOpen(true)}
            loading={loadingWorkorders}
            variant="contained"
          >
            {t('Create new order')}
          </Button>
        </div>
        <NewOrderModal
          isOpen={isAddOpen}
          onClose={() => setIsAddOpen(false)}
          onError={setError}
          onSuccess={handleNewOrderSuccess}
          spaces={spaces}
          issueTypes={issueTypes}
          content={content}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Header
        content={content}
        onLogout={onLogout}
        showCreateButton
        isAddOpen={isAddOpen}
        setIsAddOpen={setIsAddOpen}
        setError={setError}
        handleNewOrderSuccess={handleNewOrderSuccess}
        spaces={spaces}
        issueTypes={issueTypes}
      />
      {success && (
        <Alert type={AlertType.success} fullWidth>
          {success}
        </Alert>
      )}
      {error && (
        <Alert type={AlertType.error} fullWidth>
          {error}
        </Alert>
      )}
      <div className={styles.contentBlock}>
        <div className={styles.column1}>
          {listUpdated ? (
            <div style={{ margin: '0 auto' }}>
              <Loading />
            </div>
          ) : null}

          {workorders.map((workorder, i) => (
            <span
              style={{ width: '100%' }}
              key={workorder.id}
              onClick={() => ShowWorkOrderWithComments(i)}
              role="button"
              tabIndex={0}
              aria-label="Request"
            >
              <RequestCard
                interaction={workorder}
                isActive={workorder.id === activeWorkorder({ fail: false })?.id}
              />
            </span>
          ))}
          {nextPageLink ? (
            <Button
              loading={loadingMore}
              disabled={loadingMore}
              className={styles.loadMoreButton}
              onClick={loadMore}
            >
              {t('Load more')}
            </Button>
          ) : null}
        </div>
        <div className={styles.column2}>
          <div className={styles.detailedViewWrapper}>
            <div className={styles.row} style={{ marginBottom: '1em' }}>
              <div className={styles.dataPoint}>
                <M className={styles.label}> {t('Issue Type')}</M>
                <H4>{getIssueText(activeWorkorder())}</H4>
              </div>
              <div className={styles.row}>
                <Pill
                  text={t(mapStatusToLaneStatus(activeWorkorder().status)!)}
                  size="medium"
                  className={styles.pill}
                  color={statusColor}
                  iconName="circle"
                  iconColor={getPillCircleColor(statusColor)}
                />
                {renderCancelButton()}
              </div>
            </div>
            <div className={styles.dataPoint}>
              <M className={styles.label}>{t('Priority')}</M>
              <M
                style={{
                  color: PRIORITY_COLOR_MAP[activeWorkorder().priority],
                }}
              >
                {PRIORITY_MAP[activeWorkorder().priority]}
              </M>
            </div>
            <div className={styles.dataPoint}>
              <M style={{ color: 'grey' }}>{t('Details')}</M>
              <M>{activeWorkorder().description}</M>
            </div>
            <div className={styles.dataPoint}>
              <M className={styles.label}>{t('Floor/Space')}</M>
              <M>
                {`${activeWorkorder()?.space?.name} - ${
                  activeWorkorder()?.space?.floor?.name
                }`}
              </M>
            </div>
            <div className={styles.dataPoint}>
              <M className={styles.label}>{t('Requested')}</M>
              <div className={styles.row}>
                <div style={{ display: 'flex' }}>
                  <Thumbnail
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | { uri: string; } | null' is not assigna... Remove this comment to see the full error message
                    src={imageUrl && { uri: imageUrl }}
                    size={48}
                    name={`${
                      activeWorkorder().requester?.name || t('Unassigned')
                    }`}
                    style={{
                      marginBottom: 5,
                      marginRight: 10,
                      alignSelf: 'center',
                      backgroundColor: '#cdcded',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <M>
                      {activeWorkorder().requester?.name || t('Unassigned')}
                    </M>
                    <M style={{ color: 'gray' }}>
                      {simpleDate(activeWorkorder().created_at)}
                    </M>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.dataPoint}>
              <M className={styles.label}>{t('Assigned')}</M>
              <div className={styles.row}>
                <div style={{ display: 'flex' }}>
                  <Thumbnail
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | { uri: string; } | null' is not assigna... Remove this comment to see the full error message
                    src={imageUrl && { uri: imageUrl }}
                    size={48}
                    name={`${
                      activeWorkorder().assignee?.name || t('Unassigned')
                    }`}
                    style={{
                      marginBottom: 5,
                      marginRight: 10,
                      alignSelf: 'center',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <M>
                      {`${activeWorkorder().assignee?.name || t('Unassigned')}`}
                    </M>
                    <M style={{ color: 'gray' }}>
                      {simpleDate(activeWorkorder().assigned_at)}
                    </M>
                  </div>
                </div>
              </div>
            </div>

            {newFunctionalityEnabled && customFields.length > 0 && (
              <div className={styles.dataPoint}>
                <M className={styles.label} bold>
                  {t('Custom fields')}
                </M>
                <div>
                  {customFields.map(
                    (customField: issueTypesJsonFormsClient.Field) => (
                      <div
                        key={customField.id}
                        className={styles.customFieldDataPoint}
                      >
                        <M className={styles.label}>{customField.label}</M>
                        <M>
                          {((): ReactNode => {
                            switch (customField.fieldType) {
                              case issueTypesJsonFormsClient.FieldType.Currency:
                                return `${customField.value}`;
                              case issueTypesJsonFormsClient.FieldType.Date:
                                return customField.value
                                  ? simpleDate(String(customField.value))
                                  : 'N/A';
                              case issueTypesJsonFormsClient.FieldType.Dropdown:
                                return customField.customControls[
                                  Number(customField.value)
                                ];
                              default:
                                return customField.value
                                  ? String(customField.value)
                                  : 'N/A';
                            }
                          })()}
                        </M>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}

            {newFunctionalityEnabled && (
              <>
                <div className={styles.dataPoint}>
                  <M className={styles.label} bold>
                    {t('Attachments')}
                  </M>
                  <M className={styles.label}>
                    {t('Attach images and documents under 25MB')}
                  </M>
                </div>
                <FileInput
                  accept="*/*"
                  type={FileReturnTypeEnum.File}
                  disabled={isLoadingAttachment}
                  // @ts-ignore
                  onFileSelected={(file: File) => updateAttachments(file)}
                >
                  <Button
                    className={styles.buttonAttachments}
                    disabled={isLoadingAttachment}
                  >
                    <div className={styles.row}>
                      <Icon
                        className={styles.emptyImageIcon}
                        set={ICON_SET_FONTAWESOME}
                        name="paperclip"
                        type="fal"
                      />
                      <M style={{ color: 'rgba(38, 27, 189, 1)' }}>
                        {t('Attach File or Photo')}
                      </M>
                    </div>
                  </Button>
                </FileInput>
                {isLoadingAttachment && (
                  <Attachment
                    display_name={t('Uploading…')}
                    onDelete={(fileId, fileAssociationId) =>
                      deleteFile(fileId, fileAssociationId)
                    }
                  />
                )}
                {hasAttachmentError && (
                  <Attachment
                    display_name={decodeURIComponent(fileName)}
                    onDelete={(fileId, fileAssociationId) =>
                      deleteFile(fileId, fileAssociationId)
                    }
                    error
                  />
                )}
                {attachments?.map(attachment => (
                  <Attachment
                    id={attachment?.id}
                    file_association_id={attachment?.file_association_id}
                    display_name={
                      attachment
                        ? decodeURIComponent(attachment.display_name)
                        : ''
                    }
                    thumbnail={attachment?.thumbnail}
                    created_at={attachment?.created_at}
                    category={attachment?.category}
                    onDelete={(fileId, fileAssociationId) =>
                      deleteFile(fileId, fileAssociationId)
                    }
                    testID="close-icon"
                  />
                ))}
              </>
            )}

            {loading && <Loading className={styles.loader} />}

            {newFunctionalityEnabled && (
              <div className={styles.dataPoint}>
                <M className={styles.label} bold>
                  {t('Comments')}
                </M>
                <ValidatedTextArea
                  placeholder="Add new comment"
                  onChange={value => setComment(value)}
                  value={comment}
                  validationClassName={styles.textareaValidation}
                  minRows={5}
                  validation={BuildingEnginesPrism.validationShape.description}
                  className={styles.textArea}
                  showLengthIndicator
                />
                <Button
                  className={styles.commentButton}
                  onClick={() => updateComment()}
                >
                  {t('Add Comment')}
                </Button>
                {loading && <Loading className={styles.loader} />}
                {workorderComments?.map(workorderComment => (
                  <>
                    <div className={styles.row}>
                      <div style={{ display: 'flex' }}>
                        <Thumbnail
                          // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | { uri: string; } | null' is not assigna... Remove this comment to see the full error message
                          src={imageUrl && { uri: imageUrl }}
                          size={48}
                          name={workorderComment?.created_by?.name || 'User'}
                          style={{
                            marginBottom: 5,
                            marginRight: 10,
                            marginTop: 8,
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <M>{workorderComment?.created_by?.name}</M>
                          <M style={{ color: 'gray' }}>
                            {simpleDate(workorderComment?.created_at)}
                          </M>
                          <M className={styles.commentsAdded}>
                            {workorderComment?.text}
                          </M>
                        </div>
                      </div>
                    </div>

                    <div className={styles.horizontalSeperator} />
                  </>
                ))}
                {allWorkorderComments?.length > 2 && (
                  <Button
                    className={styles.commentsButton}
                    onClick={() => getMoreComments()}
                  >
                    <>
                      <M className={styles.commentsLink}>
                        {showLessComments
                          ? `${t('See less')}`
                          : `${t('See all comments')}`}
                      </M>
                    </>
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
