import { useContext } from 'react';

import ChannelsContext from '../../contexts/ChannelsContext';
import {
  LocationHelperInterface,
  LocationType,
} from '../../types/LocationType';
import useLocation from './useLocation';

export default function useLocationOrPrimaryLocation(): {
  location: LocationType;
  locationHelper: LocationHelperInterface;
} {
  const { location: userLocation, locationHelper } = useLocation();
  const { primaryChannel } = useContext(ChannelsContext);

  if (userLocation.noLocation && primaryChannel?.address?.geo) {
    return {
      location: {
        longitude: primaryChannel!.address.geo[0],
        latitude: primaryChannel!.address.geo[1],
        noLocation: false,
        accuracy: null,
        error: null,
        availability: null,
        distance: 0,
      },
      locationHelper,
    };
  }

  return { location: userLocation, locationHelper };
}
