import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import getMedia from '../graphql/query/getMedia';

type Params = {
  mediaId?: string;
};

export default function useMediaQuery({ mediaId }: Params) {
  const [fetchMedia, { data, loading, error }] = useLazyQuery(getMedia, {
    client: getClient(),
  });

  useEffect(() => {
    if (mediaId) {
      fetchMedia({
        variables: {
          id: mediaId,
        },
      });
    }
  }, [mediaId]);

  const media = data?.media;

  return {
    media,
    loading,
    error,
  } as const;
}
