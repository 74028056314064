import { ValidationError } from 'yup';

import getValidationForPath from './getValidationForPath';

export default function getValidationMessages(
  // validation error from yup
  validation: ValidationError | ValidationError[] | null | undefined,
  // path of the field that was validated. ie. content.liveDate
  path: string,
  // sub paths for deeply nested controls. ie. path = 'notifications[1]', subPath = 'title
  subPath: string = ''
): string[] | null {
  const errs = getValidationForPath(validation, path, subPath);

  if (errs) {
    return errs.map(err => err.message);
  }

  return null;
}
