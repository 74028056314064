import compareUUIDs from '../../helpers/compareUUIDs';
import { SPECIAL_GROUP_ROLES } from '../../helpers/constants/channel';
import { UserType } from '../../types/User';
import { ContentType } from '../../types/content/Content';
import { PropertyType } from '../../types/properties/Property';
import {
  PropertySecurityRule,
  SecurityModesEnum,
  SecurityRuleTypeEnum,
} from '../../types/properties/PropertySecurity';

export type SecurePropertyFieldProps = {
  // the user making this secure request
  readonly user: UserType;
  // the object that contains the data that we are securing
  readonly content: ContentType & { _createdBy?: UserType };
  // the property definition
  readonly property: PropertyType;
};

export type PropertySecurityType = {
  read?: boolean;
  create?: boolean;
  update?: boolean;
  delete?: boolean;
};

const SecurityModes = Object.values(SecurityModesEnum);

export default function securePropertyField({
  user,
  property,
  content,
}: SecurePropertyFieldProps): PropertySecurityType {
  const blockCRUD: { [index: string]: boolean } = {};
  SecurityModes.forEach(action => {
    blockCRUD[action] = true;
    if (property?.secure && property?.secure[action]) {
      // Is there security clear array?
      //  so no one can do this.
      if (!property?.secure[action]?.length) {
        return;
      }
      // Is there security named on this content?
      property.secure[action]!.forEach((secureAction: PropertySecurityRule) => {
        const secureActionValue =
          typeof secureAction.value === 'object'
            ? secureAction?.value?._id
            : secureAction?.value;

        switch (secureAction.type) {
          case SecurityRuleTypeEnum.All:
            // anyone can do this.
            blockCRUD[action] = false;
            break;

          case SecurityRuleTypeEnum.Owner:
            // the interaction owner (i.e. the user who created
            // the interaction) can do this action
            if (compareUUIDs(content.publishedBy._id, user._id)) {
              blockCRUD[action] = false;
            }
            break;

          case SecurityRuleTypeEnum.Creator:
            // the content creator can do this action
            if (compareUUIDs(content._createdBy?._id, user._id)) {
              blockCRUD[action] = false;
            }
            break;
          case SecurityRuleTypeEnum.Source: {
            // anyone with permissions on the source channel that created
            // the content can do this action
            if (
              content?.channel?._id &&
              user?.roles?.some(
                userGroupRole =>
                  compareUUIDs(
                    userGroupRole.groupRole.channel?._id,
                    content?.channel?._id
                  ) &&
                  userGroupRole.groupRole.name !==
                    SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              )
            ) {
              blockCRUD[action] = false;
            }
            break;
          }
          case SecurityRuleTypeEnum.Channel:
            // anyone on this specific named channel can do this action
            if (
              user?.roles?.some(
                userGroupRole =>
                  compareUUIDs(
                    userGroupRole.groupRole.channel?._id,
                    secureActionValue
                  ) &&
                  userGroupRole.groupRole.name !==
                    SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              )
            ) {
              blockCRUD[action] = false;
            }
            break;
          case SecurityRuleTypeEnum.ChannelPermission:
            // anyone with this specific permission can do this action
            if (
              user?.roles?.some(
                userGroupRole =>
                  compareUUIDs(
                    userGroupRole.groupRole.channel?._id,
                    content?.channel?._id
                  ) &&
                  (userGroupRole.name === SPECIAL_GROUP_ROLES.ADMIN ||
                    userGroupRole.groupRole.permissions.some(
                      permission => permission === secureAction.value
                    ))
              )
            ) {
              blockCRUD[action] = false;
            }
            break;
          case SecurityRuleTypeEnum.GroupRole:
            // anyone with this specific group role can do this action
            if (
              user?.roles?.some(userGroupRole =>
                compareUUIDs(userGroupRole.groupRole._id, secureActionValue)
              )
            ) {
              blockCRUD[action] = false;
            }
            break;
          case SecurityRuleTypeEnum.System:
            // only the system can do this, if you are the system don't
            // use this method.

            break;
          default:
            // no access is defined for this user.

            break;
        }
      });
    } else {
      // no security defined, so anyone can do this.
      blockCRUD[action] = false;
    }
  });
  return blockCRUD;
}
