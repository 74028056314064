import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import {
  MIN_DATE,
  MAX_DATE,
  DAY_PARSE_FORMAT,
} from './constants/unavailableDates';
import getSectionUnavailableDates from './graphql/getSectionUnavailableDates';
import handleUnavailableContentFeatureDates from './helpers/handleUnavailableContentFeatureDates';
import handleUnavailableSectionFeatureDates from './helpers/handleUnavailableSectionFeatureDates';
import { SectionQueryResultType } from './types/unavailableDates';

type Props = {
  sectionId: LaneType.UUID | undefined;
  channelId?: LaneType.UUID;
  timeZone: string;
};

export default function useAggregatedUnavailableDatesForSection({
  sectionId,
  channelId,
  timeZone,
}: Props) {
  const { data } = useQuery<SectionQueryResultType>(
    getSectionUnavailableDates,
    {
      skip: !sectionId || !channelId,
      fetchPolicy: 'no-cache',
      variables: {
        id: sectionId,
        searchOptions: {
          areFiltersApplied: true,
          channelId,
        },
        startDate: MIN_DATE.toJSDate(),
        endDate: MAX_DATE.toJSDate(),
      },
    }
  );

  return useMemo(() => {
    let maxDate = MAX_DATE;
    let minDate = MIN_DATE;
    const dayMap = new Map<string, LaneType.UUID[]>();

    const contentCount = (data?.section?.sectionContent || []).length;

    const sectionContent = data?.section?.sectionContent || [];

    const contentFeatureResult = handleUnavailableContentFeatureDates({
      sectionContent,
      minDate,
      maxDate,
      dayMap,

      timeZone,
    });
    const sectionFeatureReservableAvailability =
      data?.sectionFeatureReservableAvailability || [];

    const sectionFeatureResult = handleUnavailableSectionFeatureDates({
      sectionFeatureReservableAvailability,
      sectionContent,
      minDate: contentFeatureResult?.minDate,
      maxDate: contentFeatureResult?.maxDate,
      dayMap,
      timeZone,
    });

    minDate = sectionFeatureResult?.minDate;
    maxDate = sectionFeatureResult?.maxDate;

    return {
      unavailableTimeRanges: Array.from(dayMap.entries())
        .sort(([dayKeyA], [dayKeyB]) => dayKeyA.localeCompare(dayKeyB))
        .filter(([, contentIds]) => {
          // for the calendar to be marked unavailable, all rooms must be booked on that day.
          return contentIds.length >= contentCount;
        })
        .map(([dayKey]) => {
          const startDate = DateTime.fromFormat(dayKey, DAY_PARSE_FORMAT, {
            zone: timeZone,
          });

          return {
            startDate: startDate.toJSDate(),
            endDate: startDate.endOf('day').toJSDate(),
          };
        }),
      minDate,
      maxDate,
    };
  }, [data, timeZone]);
}
