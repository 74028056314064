import React from 'react';

import { useTranslation } from 'react-i18next';

import { Alert, AlertType } from 'components/lds';
import { M } from 'components/typography';

export default function EssensysOnlinePlanProduct() {
  const { t } = useTranslation();
  return (
    <Alert type={AlertType.info}>
        <M>{t('This feature relies on metadata embedded in your content.')}</M>
      </Alert>
  );
}
