import * as yup from 'yup';

import { MediaTypeEnum } from '../types/media';

export default yup.object().shape({
  name: yup.string().trim().max(256).required(),
  description: yup.string().trim().max(4096),
  tags: yup.array().of(yup.string().required()).max(6).nullable(),
  file: yup.object().required(),
  thumbnail: yup.object().required(),
  type: yup.string().required().oneOf(Object.values(MediaTypeEnum)),
  contentType: yup.string().required(),
});
