import { useEffect, useRef } from 'react';

import { BeforeUnloadHandler } from '../types/BeforeUnloadHandlerType';

/**
 * Listens for the 'beforeunload' event can then be used upon by a specified handler
 * @param handler @type BeforeUnloadHandler - a function used to handle the incoming beforeunload event
 */
export default function useBeforeUnload(handler: BeforeUnloadHandler) {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleBeforeUnload: BeforeUnloadHandler = e => {
      let returnValue;

      if (typeof handlerRef.current === 'function') {
        returnValue = handlerRef.current(e);
      }

      if (e.defaultPrevented) {
        e.returnValue = '';
      }

      return returnValue;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
}
