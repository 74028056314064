import * as yup from 'yup';

import { LaneType } from 'common-types';
import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';

import Types from '../../../../properties/Types';
import UUID from '../../../../properties/baseTypes/UUID';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { PropertiesInterface } from '../../../../types/properties/Property';

export enum ShopifyBurstTeamRegionEnum {
  NAEAST = 'AMER East',
  NAWEST = 'AMER West',
  EMEA = 'EMEA',
  APAC = 'APAC',
}

export enum ShopifyBurstGroupNameEnum {
  BrandAndAudienceExpansion = 'Brand & Audience Expansion',
  CommercialIntelligence = 'Commercial Intelligence',
  Communications = 'Communications',
  Core = 'Core',
  Corporate = 'Corporate',
  Ecosystem = 'Ecosystem',
  EmployeeExperience = 'Employee Experience',
  ExpansionIntelligence = 'Expansion Intelligence',
  Finance = 'Finance',
  Growth = 'Growth',
  Infrastructure = 'Infrastructure',
  Legal = 'Legal',
  MerchantAcceleration = 'Merchant Acceleration',
  MerchantServices = 'Merchant Services',
  OfficeoftheCTO = 'Office of the CTO',
  OneShopifyPartnerships = 'One Shopify Partnerships',
  ProductAcceleration = 'Product Acceleration',
  Revenue = 'Revenue',
  RnDPrograms = 'RnD Programs',
  SFN = 'SFN',
  Shop = 'Shop',
  Support = 'Support',
  Talent = 'Talent',
  Trust = 'Trust',
}

export const burstTeamRegionOptions = Object.entries(
  ShopifyBurstTeamRegionEnum
).map(([id, text]) => ({ id, text }));

export const burstGroupNameOptions = Object.entries(
  ShopifyBurstGroupNameEnum
).map(([id, text]) => ({ id, text }));

export const burstTextPrompts = {
  // Purpose
  purpose: 'What is the purpose of this burst?',
  purposeHelp: 'Include relevant GSD tag, if applicable.',
  justification: 'How will meeting in real life (IRL) help you with this work?',
  agenda: 'High-level Agenda.',
  agendaHelp: `Share a brief description of each day's activities.`,
  // Details
  groupName: 'Group name',
  groupNameHelp: `If you're not sure, visit your profile in Workday`,
  teamName: 'Team attending burst.',
  teamNameHelp:
    'This is the name of the reporting team or project team that is participating in this burst.',
  teamRegion: 'Team region.',
  teamRegionHelp: 'Where the majority of burst participants are located.',
  portOutsideReason: `If your planned Port is outside of the team's region, explain why this is required. Location will be reviewed by your L9+ approver.`,
};

const properties: PropertiesInterface = {
  _id: {
    type: 'String',
  },
  burstTeamType: {
    type: 'String',
  },
  purpose: {
    type: 'LongText',
    friendlyName: 'Purpose',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 2048,
      },
      {
        name: 'Min',
        value: 3,
      },
    ],
  },
  justification: {
    type: 'LongText',
    friendlyName: 'Justification',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 2048,
      },
      {
        name: 'Min',
        value: 3,
      },
    ],
  },
  agenda: {
    type: 'LongText',
    friendlyName: 'Agenda',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 2048,
      },
      {
        name: 'Min',
        value: 3,
      },
    ],
  },

  groupName: {
    type: 'String',
    friendlyName: 'Group Name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: Object.keys(ShopifyBurstGroupNameEnum),
      },
    ],
  },
  teamName: {
    type: 'String',
    friendlyName: 'Team Name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 255,
      },
      {
        name: 'Min',
        value: 3,
      },
    ],
  },
  teamRegion: {
    type: 'String',
    friendlyName: 'Team Region',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: Object.keys(ShopifyBurstTeamRegionEnum),
      },
    ],
  },
  portOutsideReason: {
    type: 'LongText',
    friendlyName: 'Port outside region',
    validators: [
      {
        name: 'Max',
        value: 2048,
      },
    ],
  },
};

export const REQUIRED_FIELDS: Partial<
  keyof LaneType.ShopifyBurstQuestionnaireTypeV2
>[] = [
  'purpose',
  'justification',
  'agenda',
  'groupName',
  'teamName',
  'teamRegion',
];

export class ShopifyBurstQuestionnaireTypeV2 extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ShopifyBurstQuestionnaireTypeV2';

  friendlyName = 'Shopify Burst Questionnaire';

  superUserOnly = false;

  contexts = [];

  get example(): any {
    return {
      _id: UUID.example,
      purpose: 'Purpose',
      justification: 'Justification',
      agenda: 'Agenda',
      groupName: ShopifyBurstGroupNameEnum.Core,
      teamName: 'Team name',
      teamRegion: ShopifyBurstTeamRegionEnum.EMEA,
      portOutsideReason: 'Port outside reason',
    };
  }

  get default(): any {
    return {
      _id: UUID.example,
      burstTypes: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new ShopifyBurstQuestionnaireTypeV2();

Types.addType(definition);
export default definition;
