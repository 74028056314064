import useFlag from './useFlag';

const FLAG = 'consumer-experience.onboarding-personalised-login';

function usePersonalizedSSOLoginEnabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}

export default usePersonalizedSSOLoginEnabled;
