import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesMin } from './ITypeValidatesMin';

// todo: figure out how to also deal with strings (or things with length).

export class MinValidator implements ValidatorInterface<ITypeValidatesMin> {
  name = 'Min';

  friendlyName = 'Minimum Value';

  arrayOnly = false;

  array = false;

  description = 'shared.properties.validators.min.description';

  types = ['String', 'LongText', 'Percentage', 'Currency', 'Number'];

  properties = {
    value: {
      type: 'Number',
      default: 1,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesMin) {
    type.addMinValidator(this);
  }

  constructor(public config?: Validator) {}
}

const Min = new MinValidator(undefined);

ValidatorRegistry.register(MinValidator, Min.name);

export default Min;
