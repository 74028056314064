import React from 'react';

import { Icon } from 'design-system-web';

import Tooltip from 'components/general/Tooltip';

import styles from './IconBatchVariableInfo.scss';

const batchVariableInfoText =
  'Use dynamic references to link content to data library e.g. {{Page Title}}';

type OwnProps = {
  placement: 'left' | 'top' | 'right' | 'bottom';
};

IconBatchVariableInfo.defaultProps = {
  placement: 'bottom',
};

type Props = OwnProps & typeof IconBatchVariableInfo.defaultProps;

/** Icon and tooltip shows extra information about Batch/Generator variables syntax. */
export default function IconBatchVariableInfo({ placement }: Props) {
  return (
    <div className={styles.tooltipContainer}>
        <Tooltip
          className={styles.tooltip}
          wrapperClassName={styles.icon}
          placement={placement}
          TooltipComponent={batchVariableInfoText}
        >
          <Icon name="brackets-curly" set="FontAwesome" type="far" />
        </Tooltip>
      </div>
  );
}
