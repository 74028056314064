import { gql } from '@apollo/client';

export const categoryListQuery = gql`
  query listProductCategories(
    $listProductCategoriesRequest: ListProductCategoriesRequest!
  ) {
    listProductCategories(
      listProductCategoriesRequest: $listProductCategoriesRequest
    ) {
      productCategory {
        productCategoryId
        name
        description
        productType
        groupId
        groupType
      }
      pageInfo {
        perPage
        start
        total
      }
    }
  }
`;

export const productExceptionsListQuery = gql`
  query listProductExceptions(
    $listProductExceptionsRequest: ListProductExceptionsRequest
  ) {
    listProductExceptions(
      listProductExceptionsRequest: $listProductExceptionsRequest
    ) {
      exceptions {
        id
        group {
          id
          type
        }
        payer {
          id
          type
        }
        productCategory {
          productCategoryId
          name
          description
          productType
          groupId
          groupType
        }
        productCount
        amount
        markup {
          value
          markupAmountType
        }
        tax {
          value
          taxAmountType
        }
      }
      pageInfo {
        perPage
        start
        total
      }
    }
  }
`;
