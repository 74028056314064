/**
 * Sorts an array by the name field.
 * @param a
 * @param b
 * @returns {number}
 */
export default function byName(a: { name: string }, b: { name: string }) {
  if (!a.name) {
    if (!b.name) {
      return 0;
    }

    return 1;
  }

  if (!b.name) {
    return -1;
  }

  return a.name.localeCompare(b.name);
}
