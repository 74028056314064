import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import {
  PropertySecurityRule,
  SecurityRuleTypeEnum,
} from '../../types/properties/PropertySecurity';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  type: {
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: Object.values(SecurityRuleTypeEnum),
      },
    ],
  },
  value: {
    type: 'String',
  },
};

export class SecurityType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Security';

  friendlyName = 'Security Permission';

  contexts = [];

  superUserOnly = true;

  get example(): any | PropertySecurityRule {
    return {
      _id: uuid(),
      type: SecurityRuleTypeEnum.Creator,
    };
  }

  get default(): any | PropertySecurityRule {
    return {
      _id: uuid(),
      type: SecurityRuleTypeEnum.All,
      value: null,
    };
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new SecurityType();

Types.addBaseType(definition);
export default definition;
