import React, { Dispatch, SetStateAction } from 'react';

import { DocumentType } from 'lane-shared/types/DocumentType';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';

import Button from '../general/Button';
import ControlMenu from '../general/ControlMenu';
import ModalBackground from '../general/ModalBackground';
import ResizableWindow from '../general/ResizableWindow';
import MediaLibrary from './MediaLibrary';

import styles from './MediaPickerButton.scss';

type Props = {
  library?: LibraryType;
  libraries: LibraryType[];
  storageKey?: string;
  errors?: string[] | null;
  newImageMaxWidth?: number;
  newImageMaxHeight?: number;
  media?: DocumentType;
  onMediaSelected: (media: DocumentType | null) => any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setMedia: Dispatch<SetStateAction<null | DocumentType>>;
  internalMedia: null | DocumentType;
  isOpen: boolean;
};

export default function MediaPickerModal({
  library,
  libraries,
  storageKey,
  newImageMaxWidth,
  newImageMaxHeight,
  media,
  onMediaSelected,
  setIsOpen,
  setMedia,
  internalMedia,
  isOpen,
}: Props) {
  function onCancel() {
    if (media) {
      onMediaSelected(media);
    }

    setIsOpen(false);
  }

  function mediaClicked(media: any) {
    setMedia(media);
  }

  function chooseMedia() {
    onMediaSelected(internalMedia);
    setIsOpen(false);
  }

  return (
    <ModalBackground
      onClose={onCancel}
      isOpen={isOpen}
      className={styles.background}
    >
      <ResizableWindow
        showHeader
        name="mediaPickerButton"
        className={styles.wrapper}
        contentContainerClassName={styles.wrapperContainer}
        autoHeight
        onClose={onCancel}
        defaultPosition={ResizableWindow.fullScreen()}
      >
        <MediaLibrary
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'LibraryType | undefined' is not assignable t... Remove this comment to see the full error message
          library={library}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'LibraryType[]' is not assignable to type 'nu... Remove this comment to see the full error message
          libraries={libraries}
          className={styles.mediaLibrary}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(media: any) => void' is not assignable to t... Remove this comment to see the full error message
          onMediaSelected={mediaClicked}
          storageKey={`MediaPickerButton${storageKey}`}
          newImageMaxWidth={newImageMaxWidth}
          newImageMaxHeight={newImageMaxHeight}
        />
        <ControlMenu className={styles.menu}>
          <hr />
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={!internalMedia}
            onClick={chooseMedia}
            variant="contained"
          >
            Select
          </Button>
        </ControlMenu>
      </ResizableWindow>
    </ModalBackground>
  );
}
