import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { ContentType } from 'lane-shared/types/content/Content';
import { ShopifyBurstReviewSectionTitle } from 'lane-shared/types/integrations/ShopifyBurst';

import ContentCard from 'components/cards/ContentCard';

import RoomBookingModal from '../RoomBookingModal';
import ReviewData from './ReviewData';
import { useModalContext } from 'contexts/ModalContext';

import styles from './ReviewRoom.scss';

type Props = {
  primaryRoomBooking: LaneType.UserContentInteraction;
  onClick?: () => void;
};

export default function ReviewRoom({ onClick, primaryRoomBooking }: Props) {
  const { t } = useTranslation();
  const { showModal, closeModal } = useModalContext();

  function handleRoomOpenPreview(contentId: string) {
    showModal(
      <RoomBookingModal onClose={closeModal} contentId={contentId} readOnly />
    );
  }

  return (
    <ReviewData
      title={t(ShopifyBurstReviewSectionTitle.Room)}
      titleIcon={<Icon set="FontAwesome" name="door-open" type="far" />}
      onClick={onClick}
      summary={
        <div
          aria-hidden="true"
          className={styles.container}
          onClick={() =>
            handleRoomOpenPreview((primaryRoomBooking?.contentData as any)?._id)
          }
        >
          <ContentCard
            content={primaryRoomBooking?.contentData as ContentType}
          />
        </div>
      }
    />
  );
}
