import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';

const properties: PropertiesInterface = {
  _order: {
    friendlyName: 'Order',
    type: 'Number',
    default: 0,
  },
  name: {
    friendlyName: 'Menu Category',
    description: 'The name of the category that items can be placed into',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 24,
      },
      {
        name: 'Min',
        value: 2,
      },
    ],
  },
};

export class MenuFeatureCategoryType extends ObjectTypeBase {
  name = 'MenuFeatureCategory';

  friendlyName = 'Menu Category';

  contexts = AllTypeContexts;

  get example() {
    return {
      _id: uuid(),
      _order: 0,
      name: 'Main Items',
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      _order: 0,
      name: '',
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new MenuFeatureCategoryType();

Types.addType(definition);
export default definition;
