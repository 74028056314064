import { DateTime } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import {
  ReservableFeatureProperties,
  ReservableUnitTypesEnum,
} from '../../types/features/ReservableFeatureProperties';

type Props = {
  reservableFeature: ReservableFeatureProperties | undefined;
  referenceDate: DateTime;
  timeZone?: string;
};

/**
 * Gets the default window to search for for a Reservable feature based on the
 * units of the Reservable feature and a reference date.
 *
 * i.e. If this reservable feature is in Days and the user is looking at a
 * calendar that is showing the current month, we should query the
 * server for a time range of this whole month to populate the calendar
 * with unavailable dates.
 */
export default function getDefaultSearchWindowForReservable({
  reservableFeature,
  referenceDate,
}: Props): DateRangeType {
  const range: DateRangeType = {
    startDate: null,
    endDate: null,
  };

  // default to some helpful start and end dates
  switch (reservableFeature?.unitType) {
    case ReservableUnitTypesEnum.Days:
    case ReservableUnitTypesEnum.Weeks:
      range.startDate = referenceDate.startOf('month').toJSDate();
      range.endDate = referenceDate.endOf('month').toJSDate();
      break;
    case ReservableUnitTypesEnum.Months:
      range.startDate = referenceDate.startOf('month').toJSDate();
      range.endDate = referenceDate
        .startOf('month')
        .plus({ months: 3 })
        .toJSDate();
      break;
    case ReservableUnitTypesEnum.Minutes:
    default:
      range.startDate = referenceDate.startOf('day').toJSDate();
      range.endDate = referenceDate.endOf('day').toJSDate();
      break;
  }

  return range;
}
