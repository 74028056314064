import gql from 'graphql-tag';

export default gql`
  mutation enableMobileAccess(
    $channelId: UUID!
    $userIds: [UUID!]!
    $accessControlGroups: [AccessControlGroupInput!]!
  ) {
    enableMobileAccess(
      channelId: $channelId
      userIds: $userIds
      accessControlGroups: $accessControlGroups
    ) {
      status
      userId
    }
  }
`;
