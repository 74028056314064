import React from 'react';

import { useTranslation } from 'react-i18next';

import { explodeValidators } from 'lane-shared/helpers/properties';
import { byOrder } from 'lane-shared/helpers/sort';
import useFormattedNumberInput from 'lane-shared/hooks/useFormattedNumberInput';
import { PropertyType } from 'lane-shared/types/properties/Property';

import Slider from '../../../../form/Slider';
import Toggle from '../../../../form/Toggle';
import ValidatedInput from '../../../../form/ValidatedInput';
import Label from '../../../../general/Label';
import { FRIENDLY_PROPERTY_TEXTS } from './constants';

import styles from './FriendlyPropertyCreate.scss';

type FriendlyPropertyValidationCreateProps = {
  hasMinMaxValidators: boolean;
  hasArrayMinMaxValidators: boolean;
  property: PropertyType;
  onPropertyUpdated: (property: Partial<PropertyType>) => void;
};

export default function FriendlyPropertyValidationCreate({
  hasMinMaxValidators,
  hasArrayMinMaxValidators,
  property,
  onPropertyUpdated,
}: FriendlyPropertyValidationCreateProps) {
  const { t } = useTranslation();

  // explode additional validations
  const {
    requiredValidator,
    inValidator,
    minValidator,
    maxValidator,
    arrayMaxValidator,
    arrayMinValidator,
  } = explodeValidators(property?.validators);

  const isNullable = property?.type === 'Boolean';

  function updateProperty(props: any) {
    onPropertyUpdated({
      ...property,
      ...props,
    });
  }

  function toggleRequiredValidator() {
    if (!requiredValidator) {
      updateProperty({
        validators: [
          ...(property?.validators || []),
          {
            name: 'Required',
            value: true,
          },
        ],
      });
    } else {
      updateProperty({
        validators: [
          ...(property?.validators?.filter(v => v.name !== 'Required') || []),
          {
            name: 'Required',
            value: !(requiredValidator?.value as boolean),
          },
        ],
      });
    }
  }

  if (inValidator?.value) {
    inValidator.value.sort(byOrder);
  }

  const {
    maskedValue: minValue,
    inputOnChange: minValueOnChange,
    inputOnBlur: minValueOnBlur,
  } = useFormattedNumberInput({
    value: minValidator?.value || 0,
    onValueChange: (value: string | number) => {
      if (minValidator) {
        minValidator.value = parseInt(value as string, 10) || 0;
        onPropertyUpdated({
          validators: [...(property.validators ? property.validators : [])],
        });
      }
    },
  });

  return (
    <>
      <hr />
      <Label
        h1
        TooltipComponent={t(FRIENDLY_PROPERTY_TEXTS.tooltip.validation)}
      >
        {t(FRIENDLY_PROPERTY_TEXTS.label.validation)}
      </Label>

      {!isNullable && (
        <fieldset className={styles.field}>
          <Label
            h2
            TooltipComponent={t(
              FRIENDLY_PROPERTY_TEXTS.tooltip.requiredValidator
            )}
          >
            {t(FRIENDLY_PROPERTY_TEXTS.label.requiredValidator)}
          </Label>
          <Toggle
            value={requiredValidator?.value === true}
            onChange={toggleRequiredValidator}
            dataCy="validator-required-toggle"
          />
        </fieldset>
      )}

      {hasMinMaxValidators && (
        <div className={styles.group}>
          {minValidator && (
            <fieldset className={styles.field}>
              <Label
                h2
                TooltipComponent={t(
                  FRIENDLY_PROPERTY_TEXTS.tooltip.minValidator
                )}
              >
                {t(FRIENDLY_PROPERTY_TEXTS.label.minValidator)}
              </Label>
              <ValidatedInput
                type="number"
                onChange={minValueOnChange}
                onBlur={minValueOnBlur}
                value={minValue}
                dataCy="validator-min"
                placeholder={t(
                  FRIENDLY_PROPERTY_TEXTS.placeholder.minValidator
                )}
              />
            </fieldset>
          )}
          {maxValidator && (
            <fieldset className={styles.field}>
              <Label
                h2
                TooltipComponent={t(
                  FRIENDLY_PROPERTY_TEXTS.tooltip.maxValidator
                )}
              >
                {t(FRIENDLY_PROPERTY_TEXTS.label.maxValidator)}
              </Label>
              <ValidatedInput
                type="number"
                onChange={value => {
                  maxValidator.value = parseInt(value, 10) || 0;
                  onPropertyUpdated({
                    validators: [
                      ...(property.validators ? property.validators : []),
                    ],
                  });
                }}
                dataCy="validator-max"
                value={maxValidator?.value}
                placeholder={t(
                  FRIENDLY_PROPERTY_TEXTS.placeholder.maxValidator
                )}
              />
            </fieldset>
          )}
        </div>
      )}
      {hasArrayMinMaxValidators && (
        <>
          {arrayMinValidator && (
            <fieldset className={styles.field}>
              <Label
                h2
                TooltipComponent={t(
                  FRIENDLY_PROPERTY_TEXTS.tooltip.arrayMinValidator
                )}
              >
                {t(FRIENDLY_PROPERTY_TEXTS.label.arrayMinValidator)}
              </Label>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                className={styles.slider}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={0}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                max={inValidator?.value.length}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: any) => void' is not assignable to t... Remove this comment to see the full error message
                onChange={(value: any) => {
                  arrayMinValidator.value = value as number;
                  onPropertyUpdated({
                    validators: [
                      ...(property.validators ? property.validators : []),
                    ],
                  });
                }}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={arrayMinValidator?.value}
              />
            </fieldset>
          )}
          {arrayMaxValidator && (
            <fieldset className={styles.field}>
              <Label
                h2
                TooltipComponent={t(
                  FRIENDLY_PROPERTY_TEXTS.tooltip.arrayMaxValidator
                )}
              >
                {t(FRIENDLY_PROPERTY_TEXTS.label.arrayMaxValidator)}
              </Label>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                className={styles.slider}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={0}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                max={inValidator?.value.length}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: any) => void' is not assignable to t... Remove this comment to see the full error message
                onChange={(value: any) => {
                  arrayMaxValidator.value = value as number;
                  onPropertyUpdated({
                    validators: [
                      ...(property.validators ? property.validators : []),
                    ],
                  });
                }}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={arrayMaxValidator?.value}
              />
            </fieldset>
          )}
        </>
      )}
    </>
  );
}
