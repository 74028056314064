/* eslint-disable react/forbid-component-props */ // Allows passing component instances as props
/* eslint-disable @nx/enforce-module-boundaries */ // Permits importing from modules outside enforced boundaries

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Dropdown, Input } from 'components';
import { useTranslation } from 'react-i18next';
import { S } from 'design-system-web';

import styles from './MonthlyInterval.scss';

export interface InternalScheduleType {
  intervalCount: number;
  monthIntervalType: MonthIntervalTypeEnum;
}

type Props = {
  defaultIntervalCount: number;
  defaultMonthIntervalType: MonthIntervalTypeEnum;
  timeZone?: string;
  className?: string;
  onChange: (updatedState: Partial<InternalScheduleType>) => void; // Adding onChange prop
};

export enum MonthIntervalTypeEnum {
  DayOne = 'day_one',
  TheFirstMonday = 'the_first_monday',
}

export function MonthlyInterval({
  defaultIntervalCount,
  defaultMonthIntervalType,
  className,
  onChange,
}: Props) {
  const { t } = useTranslation();

  // Internal state management
  const [intervalCount, setIntervalCount] = useState(defaultIntervalCount);
  const [monthIntervalType, setMonthIntervalType] = useState(
    defaultMonthIntervalType
  );

  const monthlyIntervalOptions = [
    {
      label: t(
        'web.admin.hardware.management.hardware.pin.form.repeats.on.day_one'
      ),
      value: MonthIntervalTypeEnum.DayOne,
    },
    {
      label: t(
        'web.admin.hardware.management.hardware.pin.form.repeats.on.the_first_monday'
      ),
      value: MonthIntervalTypeEnum.TheFirstMonday,
    },
  ];

  useEffect(() => {
    onChange({
      intervalCount,
      monthIntervalType,
    });
  }, [intervalCount, monthIntervalType, onChange]);

  return (
    <div className={cx(styles.monthlyInterval, className)}>
      <div className={styles.inputContainer}>
        <S>{t('web.content.feature.visitorManagement.form.label.every')}</S>
        <Input
          className={styles.input}
          fixedLabel
          type="number"
          onChange={value => {
            setIntervalCount(Number(value));
          }}
          value={String(intervalCount)}
          onBlur={() => {
            if (!intervalCount) {
              setIntervalCount(defaultIntervalCount);
            }
          }}
          rightText={t(
            'web.content.feature.visitorManagement.form.label.month'
          )}
          dataCy="monthlyIntervalCount"
        />
      </div>
      <div className={cx(styles.dropdownContainer, styles.inputContainer)}>
        <S>{t('web.admin.hardware.management.hardware.pin.form.repeats.on')}</S>
        <Dropdown
          className={styles.dropdown}
          isSearchable={false}
          initialValue={defaultMonthIntervalType}
          value={monthIntervalType}
          onChange={({ value }) => {
            setMonthIntervalType(value);
          }}
          isClearable
          rightIconClassName={styles.dropdownIcon}
          items={monthlyIntervalOptions}
          dataCy="monthlyIntervalDropdown"
        />
      </div>
    </div>
  );
}
