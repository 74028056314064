import { useRef } from 'react';

import { PlacePayDataResponse } from 'lane-shared/types/PlacePay';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';

import IPaymentCollection from './IPaymentCollection';

const PROVIDER_TYPE = PaymentProviderEnum.PlacePay;

type CollectPlacePayPaymentProps = {
  onStart?: (type: PaymentProviderEnum) => void;
  onSuccess?: (
    type: PaymentProviderEnum,
    paymentData: PlacePayDataResponse
  ) => void;
  onFail?: (type: PaymentProviderEnum, reason?: any) => void;
};

export default function useCollectPlacePayPayment({
  onStart,
  onSuccess,
  onFail,
}: CollectPlacePayPaymentProps): IPaymentCollection {
  const paymentSuccessRef = useRef<(value: PlacePayDataResponse) => void>();
  const paymentFailureRef = useRef<(reason?: any) => void>();

  async function getPayment() {
    if (onStart) {
      onStart(PROVIDER_TYPE);
    }
    return new Promise((resolve, reject) => {
      // TODO: Massage resolved result into the form { placePayData: result }
      // https://github.com/joinlane/lane-next/pull/6046#discussion_r765100715
      paymentSuccessRef.current = resolve;
      paymentFailureRef.current = reject;
    });
  }

  function handlePaymentSuccess(paymentData: any) {
    if (onSuccess) {
      onSuccess(PROVIDER_TYPE, paymentData);
    }
    if (paymentSuccessRef.current) {
      paymentSuccessRef.current(paymentData);
    }
  }

  function handlePaymentFail(reason?: any) {
    if (onFail) {
      onFail(PROVIDER_TYPE, reason);
    }
    if (paymentFailureRef.current) {
      paymentFailureRef.current(reason);
    }
  }

  return {
    getPayment,
    handlePaymentSuccess,
    handlePaymentFail,
  };
}
