import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import ObjectTouchHelper from '../../helpers/ObjectTouchHelper';
import {
  ANALYTIC_CHANNEL_DETAILS,
  ANALYTIC_CHANNEL_VIEW,
} from '../../helpers/constants/analytics';
import { EVENT_CHANNEL_RESERVABLE_MANAGER_ENABLED } from '../../helpers/constants/events';
import { ChannelType } from '../../types/ChannelType';
import { DocumentType } from '../../types/DocumentType';

/**
 * Used for analytics tracking in lane-web and lane-mobile.
 */
export default function useChannelAnalytics() {
  const analytics = useContext(AnalyticsContext);

  function trackChannelDetails(channel: DocumentType) {
    ObjectTouchHelper.touch(
      channel,
      ObjectTouchHelper.mapAction('details'),
      'Channel'
    );

    analytics.track(ANALYTIC_CHANNEL_DETAILS, {
      channelId: channel?._id,
    });
  }

  function trackChannelView(channel: DocumentType) {
    ObjectTouchHelper.touch(
      channel,
      ObjectTouchHelper.mapAction('view'),
      'Channel'
    );

    analytics.track(ANALYTIC_CHANNEL_VIEW, {
      channelId: channel?._id,
    });
  }

  function trackReservableManagerEnabled(channel: ChannelType) {
    analytics.track(EVENT_CHANNEL_RESERVABLE_MANAGER_ENABLED, {
      isReservableManagerEnabledForChannel: true,
      channelId: channel._id,
      channelName: channel.name,
    });
  }

  return {
    trackChannelDetails,
    trackChannelView,
    trackReservableManagerEnabled,
  };
}
