import { Validator } from './Validator';
import { ValidatorInterface } from './ValidatorInterface';
import { BaseType } from './baseTypes/TypeBase';

interface Registry {
  [key: string]: { new (config: Validator): ValidatorInterface<BaseType> };
}

export abstract class ValidatorRegistry {
  static registry: Registry = {};

  static register(
    cls: {
      new (config: Validator): ValidatorInterface<BaseType>;
    },
    className: string
  ) {
    ValidatorRegistry.registry[className] = cls;
  }

  static create<T extends BaseType>(
    _type: T,
    config: Validator
  ): ValidatorInterface<T> | undefined {
    const Cls = ValidatorRegistry.registry[config.name];
    if (!Cls) {
      return;
    }

    return new Cls(config);
  }
}
