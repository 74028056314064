import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import cx from 'classnames';
import { Input } from 'components';
import { getUserLocale } from 'helpers';

import { useCurrencyInput } from 'lane-shared/hooks';
import { SupportedLocaleEnum } from 'localization';
import { PaymentCurrencyEnum } from 'lane-shared/types/payment/PaymentCurrencyEnum';

import styles from './CurrencyInput.scss';

export type CurrencyInputProps = {
  value: number;
  className?: string;
  style?: any;
  label?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  locale?: SupportedLocaleEnum;
  currency?: string;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
  onChange?: (value: string) => void;
  onValueChange?: (value: number) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  dataCy?: string;
};

function CurrencyInput(
  {
    className,
    style,
    value,
    label,
    placeholder,
    min = -Number.MAX_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    locale = getUserLocale(),
    currency = PaymentCurrencyEnum.USD,
    onChange,
    onValueChange,
    onFocus,
    onBlur,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
    dataCy,
  }: CurrencyInputProps,
  ref: any
) {
  const inputRef = useRef(null);
  const { maskedValue, inputOnChange, inputOnBlur } = useCurrencyInput({
    min,
    max,
    currency,
    locale,
    value,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
    onChange,
    onValueChange,
  });

  function handleOnBlur(e: any) {
    inputOnBlur();
    if (onBlur) {
      onBlur(e);
    }
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.focus();
    },
    blur: () => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.blur();
    },
  }));

  return (
    <Input
      label={label}
      className={cx(styles.CurrencyInput, className)}
      data-is-negative={value < 0}
      style={style}
      ref={inputRef}
      value={maskedValue}
      placeholder={placeholder}
      onChange={value => inputOnChange(value)}
      onBlur={handleOnBlur}
      onFocus={onFocus}
      showClear={false}
      type="text"
      dataCy={dataCy}
    />
  );
}

const CurrencyInputForwarded = forwardRef(CurrencyInput);

export default CurrencyInputForwarded;
