import gql from 'graphql-tag';

export const shopifyCustomer = gql`
  query shopifyCustomer($channelIntegrationId: UUID!, $email: EmailAddress!) {
    shopifyCustomer(
      channelIntegrationId: $channelIntegrationId
      email: $email
    ) {
      shopifyCustomerId
    }
  }
`;
