import { useContext, useEffect, useState } from 'react';

import { GraphQLError } from 'graphql';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { ChannelsContext } from 'lane-shared/contexts';
import { updateUserContentInteraction } from 'lane-shared/graphql/mutation';
import { INTERACTION_CANCELLED } from 'lane-shared/helpers/constants/interactions';

export type ConfirmProps = {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
};

export type ConfirmFunction = () => Promise<void>;

export default function useCancelUCI(
  interaction: any,
  confirmFunction: ConfirmFunction,
  Sentry: any
) {
  const { t } = useTranslation();
  const { primaryId } = useContext(ChannelsContext);

  const [toCancel, setToCancel] = useState(false);
  const [isCancelled, setIsCancelled] = useState(
    interaction?.status === INTERACTION_CANCELLED
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function cancel() {
    try {
      await confirmFunction();
    } catch (err) {
      // user canceled.
      setToCancel(false);
      return;
    }

    setLoading(true);

    try {
      await getClient().mutate({
        mutation: updateUserContentInteraction,
        variables: {
          interaction: {
            _id: interaction._id,
            status: INTERACTION_CANCELLED,
          },
          meChannelId: primaryId,
        },
      });

      setIsCancelled(true);
    } catch (err) {
      let error = err as Error;

      if (err instanceof GraphQLError && err.message.includes('JSON')) {
        // a JSON error indicates that the either our Lane server
        // is currently down. Or some 3rd party service we are contacting
        // is down. (i.e. this is a JSON parse error because we received
        // HTML back)
        Sentry.captureException(err);
        error = new Error(t('Please try again in a few minutes.'));
      }

      setError(error);
      setToCancel(false);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!toCancel || isCancelled) {
      return;
    }

    setError(null);
    cancel();
  }, [toCancel, isCancelled]);

  return {
    loading,
    error,
    isCancelled,
    cancel: () => setToCancel(true),
    reset: () => {
      setError(null);
      setToCancel(false);
    },
  };
}
