import React from 'react';

import { routes } from 'lane-shared/config';
import { PaymentDetailsType } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';
import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';
import {
  EssensysPaymentSettingsType,
  PaymentFeatureQuoteType,
} from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';

import EssensysPaymentMethodSelect from 'components/payments/EssensysPaymentMethodSelect';
import PaymentMethodSelect from 'components/payments/PaymentMethodSelect';

import history from '../../../helpers/history';

type PaymentMethodContentType = {
  essensysPaymentSettings: EssensysPaymentSettingsType;
  isEssensys: boolean;
  essensysError: Error;
  error: Error;
  paymentType: PaymentTypeEnum;
  quote: FeatureQuoteType | PaymentFeatureQuoteType | null;
  selectedCard: any;
  paymentDetails: PaymentDetailsType | null;
  paymentAccounts: any;
  selectedPaymentAccount: PaymentAccountType | null;
  setSelectedPaymentAccount: (p: any) => void;
  setSelectedCard: () => void;
  onClose: () => void;
  timeZone: string;
  submitting: boolean;
};

export default function PaymentMethodContent({
  essensysPaymentSettings,
  isEssensys,
  essensysError,
  error,
  paymentType,
  quote,
  selectedCard,
  paymentDetails,
  paymentAccounts,
  selectedPaymentAccount,
  setSelectedPaymentAccount,
  setSelectedCard,
  onClose,
  timeZone,
  submitting,
}: PaymentMethodContentType) {
  if (essensysPaymentSettings?.isCredits) return null;

  if (isEssensys)
    return (
      <EssensysPaymentMethodSelect
        paymentType={paymentType}
        essensysInfo={(quote as any)?.data}
        essensysError={essensysError}
        selectedCard={selectedCard}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentDetailsType | null' is not assignable... Remove this comment to see the full error message
        paymentDetails={paymentDetails}
        paymentAccounts={paymentAccounts}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentAccountType | null' is not assignable... Remove this comment to see the full error message
        selectedPaymentAccount={selectedPaymentAccount}
        onPaymentAccountSelected={paymentAccount =>
          setSelectedPaymentAccount(paymentAccount)
        }
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        onCardSelected={card => setSelectedCard(card)}
        onSetupPayment={() => {
          onClose();
          history.push(routes.meWallet);
        }}
      />
    );

  return (
    <PaymentMethodSelect
      quote={quote}
      error={error}
      timeZone={timeZone}
      disabled={submitting}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentDetailsType | null' is not assignable... Remove this comment to see the full error message
      paymentDetails={paymentDetails}
      selectedCard={selectedCard}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentAccountType | null' is not assignable... Remove this comment to see the full error message
      selectedPaymentAccount={selectedPaymentAccount}
      onPaymentAccountSelected={paymentAccount =>
        setSelectedPaymentAccount(paymentAccount)
      }
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      onCardSelected={card => setSelectedCard(card)}
      onSetupPayment={() => {
        onClose();
        history.push(routes.meWallet);
      }}
    />
  );
}
