import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { usePublicUserQuery } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';

import IconButton from '../general/IconButton';
import ModalBackground from '../general/ModalBackground';
import ResizableWindow from '../general/ResizableWindow';
import UserSelector from './UserSelector';

import styles from './UserSelectorButton.scss';

type UserSelectorButtonProps = {
  className?: string | string[];
  style?: React.CSSProperties;
  isInvalid?: boolean;
  includeWorkplaceMember?: boolean;
  disabled?: boolean;
  userId: string;
  channelId?: string;
  disableChannelSelect?: boolean;
  disableStorage?: boolean;
  onUserSelected: (
    user: UserType | null,
    selectedChannel: string | null
  ) => void;
  renderUser?: (user: UserType) => React.ReactNode;
  storageKey?: string;
  dataCy?: string;
  isNewWorkflowsUIEnabled?: boolean;
};

export default function UserSelectorButton({
  className,
  style,
  isInvalid,
  disabled,
  userId,
  channelId,
  onUserSelected,
  disableChannelSelect = false,
  disableStorage = false,
  includeWorkplaceMember = false,
  renderUser,
  storageKey = 'UserSelectorButton',
  dataCy,
  isNewWorkflowsUIEnabled,
}: UserSelectorButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { user } = usePublicUserQuery({
    userId,
  });

  function render() {
    if (userId) {
      return (
        <>
          {renderUser ? (
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PublicUserType | undefined' is n... Remove this comment to see the full error message
            renderUser(user)
          ) : (
            <span className={styles.selectedValue}>
              {user && user.profile.name}
            </span>
          )}
          <IconButton
            className={styles.remove}
            inverted
            icon="times"
            onClick={e => {
              onUserSelected(null, null);
              e.stopPropagation();
            }}
          />
        </>
      );
    }

    return (
      <>
        <Icon className={styles.search} name={t('search')} />
        <span>
          {t(
            isNewWorkflowsUIEnabled
              ? 'web.admin.channel.content.workflow.editor.v2.outcome.targetLabel.user.selectUser.placeholder'
              : 'Select a Person'
          )}
        </span>
      </>
    );
  }

  return (
    <>
      <div
        className={cx(styles.UserSelectorButton, className)}
        style={style}
        data-is-selected={!!userId}
        data-invalid={!!isInvalid}
        data-disabled={disabled}
        data-cy={dataCy}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => setIsOpen(true)}
      >
        {render()}
      </div>

      <ModalBackground
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        className={styles.background}
      >
        {isOpen && (
          <ResizableWindow
            name={storageKey}
            className={styles.window}
            defaultPosition={ResizableWindow.centerPosition()}
            onClose={() => setIsOpen(false)}
            showHeader
          >
            <UserSelector
              storageKey={storageKey}
              disableStorage={disableStorage}
              disableChannelSelect={disableChannelSelect}
              includeWorkplaceMember={includeWorkplaceMember}
              channelId={channelId}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(user: any) => void' is not assignable to ty... Remove this comment to see the full error message
              onUserSelected={(user: any, selectedChannel: string) => {
                onUserSelected(user, selectedChannel);
                setIsOpen(false);
              }}
            />
          </ResizableWindow>
        )}
      </ModalBackground>
    </>
  );
}
