export default function objectToArray<T extends { name?: string }>(
  obj: Record<string, T> | null,
  byRef: boolean = false
): T[] {
  if (!obj) {
    return [];
  }

  return Object.entries(obj).map<T>(([key, value]) => {
    const ret = byRef ? value : { ...value };

    if (!ret.name) {
      ret.name = key;
    }

    return ret;
  });
}
