import React from 'react';

import type { i18n } from 'i18next';
import { z } from 'zod';

import {
  MjmlSection,
  MjmlColumn,
  MjmlText,
} from 'lane-shared/emails/MjmlComponentsV2';

import RichTextHtmlRenderer from '../../../../components/RichTextRenderer';
import { RichTextAlignEnum } from '../../../../properties/baseTypes/RichText';
import { isEmptyRichTextField, parseRichTextField } from '../../helpers';
import { buildingSchema, whitelabelSchema } from '../schema';

export function EmailVisitorFooter({
  i18n,
  building,
  whitelabel,
  primaryColor,
}: {
  i18n: i18n;
  building: z.infer<typeof buildingSchema>;
  whitelabel?: z.infer<typeof whitelabelSchema>;
  primaryColor?: string;
}) {
  const linkColor = primaryColor ?? '#4837B9';

  const visitorPoliciesJSON = parseRichTextField(building.visitorPolicies);

  const displayPolicies =
    building.visitorPolicies && !isEmptyRichTextField(visitorPoliciesJSON);

  if (visitorPoliciesJSON) {
    // need to manually add this styling to the parent JSON or it wont center
    visitorPoliciesJSON.forEach((element: any) => {
      element.align = RichTextAlignEnum.center;
    });
  }

  const addressLineOne = whitelabel
    ? whitelabel.addressLineOne
    : building.addressLineOne;
  const addressLineTwo = whitelabel
    ? whitelabel.addressLineTwo
    : building.addressLineTwo;

  const contactEmail = whitelabel?.contactEmail || building.contactEmail;

  return (
    <MjmlSection border-radius="0 0 12px 12px" background-color="#EAECEF">
      <MjmlColumn>
        {displayPolicies && (
          <MjmlText font-size="14px" color="#999DA3" align="center">
            <RichTextHtmlRenderer value={visitorPoliciesJSON} />
          </MjmlText>
        )}
        <MjmlText font-size="14px" align="center" color="#999DA3">
          {i18n.t('shared.common.email.footer.sentBy', {
            name: whitelabel?.name || building.name,
          })}{' '}
          <br />
          {addressLineOne} {addressLineTwo}
          <br />
          {contactEmail !== '' && (
            <a
              href={`mailto:${
                whitelabel?.contactEmail || building.contactEmail
              }`}
            >
              {i18n.t('shared.common.email.footer.contactUs')}
            </a>
          )}
        </MjmlText>

        {building.legal && (
          <MjmlText font-size="14px" color="#999DA3" align="center">
            <a href={building.legal.terms}>
              {i18n.t('shared.common.email.footer.terms')}
            </a>{' '}
            {`| `}
            <a href={building.legal.privacy}>
              {i18n.t('shared.common.email.footer.privacy')}
            </a>
          </MjmlText>
        )}

        <MjmlText font-size="14px" align="center" color="#999DA3">
          {i18n.t('shared.common.email.footer.poweredBy')}{' '}
          <a href="https://www.vts.com/vts-activate" color={linkColor}>
            {i18n.t('application.name')}
          </a>{' '}
          {i18n.t('shared.common.email.footer.copyright')}
        </MjmlText>
      </MjmlColumn>
    </MjmlSection>
  );
}
