import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().trim().required(),
  isPublic: yup.bool().default(false),
  channel: yup.object().required().shape({
    _id: yup.string().trim().required(),
  }),
  requirements: yup
    .object()
    .required()
    .shape({
      set: yup.array().of(yup.string()),
    }),
  permissions: yup
    .object()
    .required()
    .shape({
      set: yup.array().of(yup.string()),
    }),
  maxUsers: yup
    .number()
    .required()
    .min(0)
    .max(2 ** 32),
});
