import {
  CHANNEL_DIRECTORY_LIST_VIEWS,
  CHANNEL_DIRECTORY_LIST_VIEW,
} from '../../../helpers/constants/channel';
import { FlexDirectionEnum } from '../../../types/blocks/Flex';
import { Primitive } from '../../../types/blocks/Primitive';

const UserDirectoryBlock: Primitive = {
  name: 'UserDirectoryBlock',
  friendlyName: 'User Directory List',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Content'],
  properties: {
    listView: {
      friendlyName: 'List View',
      type: 'String',
      default: CHANNEL_DIRECTORY_LIST_VIEW.Thumbnail,
      validators: [
        {
          name: 'In',

          value: CHANNEL_DIRECTORY_LIST_VIEWS,
        },
      ],
    },
    flexDirection: {
      friendlyName: 'Scroll Orientation',
      type: 'FlexDirection',
      default: FlexDirectionEnum.Horizontal,
      validators: [],
    },
    perPage: {
      type: 'Number',
      friendlyName: 'Items per page',
      default: 5,
      validators: [
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: 25,
        },
      ],
    },
    channel: {
      friendlyName: 'Channel',
      type: 'Channel',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    header: {
      friendlyName: 'Title',
      type: 'String',
      validators: [
        {
          name: 'Max',
          value: 80,
        },
      ],
    },
    showSearch: {
      type: 'Boolean',
      friendlyName: 'Show search box?',
      default: false,
    },
    filterByChannel: {
      type: 'Boolean',
      friendlyName: 'Show filter by Channel?',
      default: false,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default UserDirectoryBlock;
