import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import {
  EssensysOrganizationType,
  PaymentProcessorEnum,
} from 'lane-shared/types/Essensys';
import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';

import createOperatePaymentAccount from './createOperatePaymentAccount';

const USE_PAYMENT_ACCOUNTS = gql`
  query UsePaymentAccounts {
    me {
      user {
        _id
        name
        paymentAccounts {
          _id
          name
          paymentProcessor {
            _id
            publicKey
            type
          }
          type
        }
        profile {
          _id
          name
        }
      }
    }
  }
`;

type UsePaymentAccountResponse = {
  me: {
    user: {
      _id: string;
      name: string;
      paymentAccounts: Array<PaymentAccountType>;
      profile: {
        _id: string;
        name: string;
      };
    };
  };
};

export default async function getOnlinePlanPaymentAccount(
  paymentProcessor: PaymentProcessorEnum,
  organization: Partial<EssensysOrganizationType>
): Promise<PaymentAccountType> {
  if (!organization.processoruserid) {
    throw new Error('Could not create an Operate payment account.');
  }

  const apolloClient = getClient();

  const paymentAccountResponse = await apolloClient.query<UsePaymentAccountResponse>(
    {
      query: USE_PAYMENT_ACCOUNTS,
      fetchPolicy: 'no-cache',
    }
  );

  const user = paymentAccountResponse.data.me.user;

  const paymentAccount = user.paymentAccounts.find(
    paymentAccount =>
      paymentAccount.paymentProcessor?.publicKey ===
      organization.processoruserid
  );

  if (!paymentAccount) {
    return await createOperatePaymentAccount(
      user.profile.name,
      paymentProcessor,
      user._id,
      organization.processoruserid,
      { organizationId: organization.organizationid }
    );
  }

  return paymentAccount;
}
