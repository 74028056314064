import React from 'react';

export default function IconPhone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106.000000pt"
      height="106.000000pt"
      viewBox="0 0 106.000000 106.000000"
    >
      <g
        transform="translate(0.000000,106.000000) scale(0.100000,-0.100000)"
        fill="#ff4c55"
        stroke="none"
      >
        <path
          d="M397 1035 c-382 -105 -512 -591 -233 -869 301 -300 809 -141 882 277
56 322 -188 609 -515 606 -47 0 -108 -7 -134 -14z m256 -10 c183 -48 336 -200
376 -375 15 -63 13 -186 -3 -246 -32 -120 -128 -249 -229 -308 -237 -139 -535
-72 -686 155 -139 208 -100 496 88 661 126 110 300 153 454 113z"
        />
        <path
          d="M586 855 c75 -16 113 -35 164 -82 54 -48 77 -89 99 -174 11 -41 20
-62 20 -48 1 37 -27 126 -54 169 -52 82 -169 151 -253 149 -32 0 -29 -2 24
-14z"
        />
        <path
          d="M239 721 c-66 -66 -56 -103 77 -261 85 -102 246 -238 301 -254 40
-13 126 41 134 84 6 31 -12 69 -51 104 -44 40 -82 46 -112 19 -18 -16 -23 -13
-106 70 l-87 87 19 30 c24 42 15 69 -39 119 -58 53 -85 54 -136 2z m125 -11
c53 -49 60 -72 35 -104 -10 -13 -19 -28 -19 -33 0 -15 175 -183 191 -183 9 0
22 7 29 15 21 26 51 17 96 -29 55 -55 57 -82 9 -125 -53 -46 -85 -42 -170 22
-95 71 -220 199 -279 284 -41 60 -47 76 -41 102 7 32 65 91 91 91 8 0 34 -18
58 -40z"
        />
        <path
          d="M575 743 c89 -27 138 -76 161 -161 10 -36 13 -41 13 -19 1 35 -24 95
-52 126 -28 30 -114 71 -147 70 -17 -1 -9 -6 25 -16z"
        />
        <path
          d="M556 629 c36 -10 57 -32 72 -74 6 -18 11 -23 11 -12 1 26 -26 75 -48
87 -11 5 -30 10 -43 9 -19 -1 -18 -2 8 -10z"
        />
      </g>
    </svg>
  );
}
