import { LaneType } from 'common-types';
import { convertTo62 } from '../convertId';
import isProbablyMongoId from '../isProbablyMongoId';
import isProbablyUUID from '../isProbablyUUID';

import { config as sharedConfig } from '../../config';

/**
 * Gets the full URL of a Media.  It supports legacy media that were uploaded
 * in v4 and have an shorter MongoIDs
 *
 * Any new media with a longer ID will have a slightly different URL.
 */
const defaultOptions = {
  notFromCache: false,
  suffix: 'media',
};

export default function getMediaUrl(
  media:
    | LaneType.UUID
    | {
        _id: LaneType.UUID;
        previewUrl?: string | undefined | null;
      }
    | null
    | undefined,
  options?: {
    notFromCache?: boolean;
    suffix?: string; // suffix should be mandatory in v2
    privateS3MediaEnabled?: boolean;
    mediaUploadV2Enabled?: boolean;
  }
): string {
  const resultOptions = { ...defaultOptions, ...options };
  if (!media) {
    // TODO: default to a place holder image?
    return '';
  }

  // add notFromCache parametr
  let parameter = '';
  if (resultOptions.notFromCache) {
    parameter = '?not-from-cache-please';
  }

  // check to see if this is an object being passed in.
  const id = typeof media === 'string' ? media : media._id;

  if (options?.privateS3MediaEnabled) {
    const previewUrl =
      typeof media === 'object' && media?.previewUrl ? media.previewUrl : '';

    if (previewUrl) return previewUrl;
  }

  if (isProbablyMongoId(id)) {
    // Legacy v4 image format.
    return `https://mona-production.s3.amazonaws.com/${id}.image${parameter}`;
  }

  const { assetsURL, privateBucketSubpath } = sharedConfig.cdn;
  let prefix = `https://${assetsURL}`;
  if (isProbablyUUID(id)) {
    // this is probably an Media id.
    if (options?.privateS3MediaEnabled) {
      prefix = `https://${assetsURL}/${privateBucketSubpath}`;
    }

    if (resultOptions.mediaUploadV2Enabled) {
      if (resultOptions.suffix === 'thumbnail') {
        return `${prefix}/${convertTo62(id)}/${convertTo62(
          id
        )}_thumbnail.jpeg${parameter}`;
      }

      return `${prefix}/${convertTo62(id)}/${convertTo62(id)}.${
        resultOptions.suffix
      }${parameter}`;
    }

    return `${prefix}/${convertTo62(id)}.${resultOptions.suffix}${parameter}`;
  }

  // if it doesn't match any of the above format's this is probably an SVG
  // we do not allow sftp, ssh, ftp, http, https so we can defend against
  // SSRF attacks. See `lane-server/src/routes/api/v5/media.ts`.
  return id;
}
