/**
 * Small function to help with using dropdowns, selects, etc.
 *
 * converts a value into a standard schema format.
 */
export type SchemaType<T extends string | number = string | number> = {
  value: T;
  label: string;
};

const VALUE_TO_LABEL: Record<string | number, string> = {
  Static: 'Manual (Pages only)',
  Content: 'Post',
};

const TRANSLATION_KEYS_MAP: Record<string, string> = {
  Setup: 'web.admin.features.reservable.setup',
  Capacity: 'web.admin.features.reservable.capacity',
  Advanced: 'web.admin.features.reservable.advanced',
  'Team Rules': 'web.admin.features.reservable.teamRules',
  Appearance: 'web.admin.features.reservable.appearance',
  'Custom Time Slots': 'web.admin.features.reservable.customTimeSlots',
};

export default function toSchema<T extends string | number = string | number>(
  value: T
): SchemaType<T> {
  return {
    value,
    label: VALUE_TO_LABEL[value] ?? value.toString(),
  };
}

export function getToSchemaWithTranslate(t?: (key: string) => string) {
  return function <T extends string | number = string | number>(
    value: T,
    _index: number
  ): SchemaType<T> {
    if (t !== undefined && typeof t !== 'function') {
      throw new Error('Invalid translation function provided');
    }

    const labelIdentifier = VALUE_TO_LABEL[value] ?? value.toString();

    return {
      value,
      label: t
        ? t(TRANSLATION_KEYS_MAP[labelIdentifier] ?? labelIdentifier)
        : labelIdentifier,
    };
  };
}
