import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import DatePickerButton from './DatePickerButton';

import styles from './DateTimeRangePicker.scss';

type DateTimeRangePickerProps = {
  value: DateRangeType;
  timeZone?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: ((range: DateRangeType) => void) | undefined;
  unavailableDateRanges?: DateRangeType[];
  className?: string;
  style?: React.CSSProperties;
};

const DATE_FORMAT = 'EEE MMM d, yyyy';
const TIME_FORMAT = 'h:mm a';

/**
 * @deprecated use DateTimeRangePicker from design-system-web instead.
 */
export default function DateTimeRangePicker({
  value,
  timeZone,
  disabled = false,
  loading = false,
  onChange = () => {},
  unavailableDateRanges = [],
  className,
  style,
}: DateTimeRangePickerProps) {
  const { t } = useTranslation();

  function updateValue(props: any) {
    const newRange = {
      ...value,
      ...props,
    };

    onChange(newRange);
  }

  return (
    <div className={cx(styles.DateTimeRangePicker, className)} style={style}>
      <DatePickerButton
        disabled={disabled}
        loading={loading}
        onSubmit={(startDate: any) => updateValue({ startDate })}
        includeTime
        unavailableDateRanges={unavailableDateRanges}
        value={value?.startDate}
        timeZone={timeZone}
        ButtonComponent={({ onOpen }: any) => (
          <div
            className={styles.row}
            onClick={e => onOpen(e)}
            role="button"
            tabIndex={0}
            onKeyPress={e => e.key === Key.Enter && onOpen(e)}
          >
            <h1>
              {t('web.admin.content.metatag.tabItem.edit.dateTime.start')}
            </h1>
            {value?.startDate && (
              <>
                <h2>
                  {dateFormatter(value?.startDate, DATE_FORMAT, timeZone)}
                </h2>
                <p>{dateFormatter(value?.startDate, TIME_FORMAT, timeZone)}</p>
              </>
            )}
            {!value?.startDate && <h2>{t('Select a start date…')}</h2>}
          </div>
        )}
      />
      <DatePickerButton
        disabled={disabled}
        loading={loading}
        onSubmit={(endDate: any) => updateValue({ endDate })}
        includeTime
        timeZone={timeZone}
        value={value?.endDate}
        unavailableDateRanges={unavailableDateRanges}
        ButtonComponent={({ onOpen }: any) => (
          <div
            className={styles.row}
            onClick={e => onOpen(e)}
            role="button"
            tabIndex={0}
            onKeyPress={e => e.key === Key.Enter && onOpen(e)}
          >
            <h1>{t('web.admin.content.metatag.tabItem.edit.dateTime.end')}</h1>
            {value?.endDate && (
              <>
                <h2>{dateFormatter(value?.endDate, DATE_FORMAT, timeZone)}</h2>
                <p>{dateFormatter(value?.endDate, TIME_FORMAT, timeZone)}</p>
              </>
            )}
            {!value?.endDate && <h2>{t('Select an end date…')}</h2>}
          </div>
        )}
      />
    </div>
  );
}
