import * as yup from 'yup';

import { InvalidTypeValueError } from 'activate-errors';
import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

export class JSONType extends ObjectTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'JSON';

  get default(): any {
    return {};
  }

  friendlyName = 'JSON Data';

  contexts = [TypeContextEnum.Block];

  superUserOnly = true;

  buildSchema() {
    return yup.object().nullable();
  }

  deserialize = (value: any) => {
    if (typeof value === 'string') {
      return JSON.parse(value);
    }

    if (typeof value === 'object') {
      return value;
    }

    throw new InvalidTypeValueError(`${value} for JSON is not valid.`);
  };
}

const definition = new JSONType();

Types.addBaseType(definition);
export default definition;
