import gql from 'graphql-tag';

import { AnonymousChannelProfileFragment } from 'lane-shared/graphql/fragments';

export default gql`
  ${AnonymousChannelProfileFragment}

  query getChannelForAnonymousSection($id: UUID!) {
    section(_id: $id) {
      channel {
        ...AnonymousChannelProfileFragment
      }
    }
  }
`;
