import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { AnalyticsContext } from 'lane-shared/contexts';

import client from './apollo';
import { analyticsHelper } from './helpers/analytics';
import { createRoot } from 'react-dom/client';
import { Auth0Wrapper } from './Auth0Wrapper';
import { InviteContextProvider } from 'lane-shared/contexts/InviteContext';

export default function render(Component: any, containerId = 'root') {
  const analytics = analyticsHelper(client);

  const container = document.getElementById(containerId);
  const root = createRoot(container!);

  root.render(
    <ApolloProvider client={client}>
      <InviteContextProvider>
        <Auth0Wrapper>
          <AnalyticsContext.Provider value={analytics}>
            <Component />
          </AnalyticsContext.Provider>
        </Auth0Wrapper>
      </InviteContextProvider>
    </ApolloProvider>
  );
}
