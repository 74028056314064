import React from 'react';

import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import { DefaultOrganizationSettingsData } from 'lane-shared/domains/visitorManagement/hooks/useOrganizationSettingsData';
import {
  OrganizationSettings,
  VisitorConfiguration,
} from 'lane-shared/domains/visitorManagement/types';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { Loading } from 'lane-web/src/components/general';

import Checkbox from 'components/form/Checkbox';
import { H4, H5, S } from 'components/typography';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useFlag } from 'lane-shared/hooks';
import cssVariables from 'static/style/_variables.scss';

import styles from './styles.scss';

type Prop = {
  channel: any;
  onConfigUpdated: (update: VisitorConfiguration) => void;
  config: VisitorConfiguration;
  loading?: boolean;
};

export function VisitorManagementNotificationsTab({
  channel,
  onConfigUpdated,
  config,
  loading,
}: Prop): JSX.Element {
  const { t } = useTranslation();
  const checkboxStyle = {
    paddingLeft: 0,
    paddingTop: cssVariables.spacing2,
    paddingBotton: cssVariables.spacing2,
  };

  const HostNotificationEnabledFlag = useFlag(
    FeatureFlag.VisitorManagementHostNotification,
    false
  );

  const VisitorManagementSmsNotificationFlag = useFlag(
    FeatureFlag.VisitorManagementSmsNotification,
    false
  );

  const organizationSettings =
    Object.keys(config.organizationSettings!).length !== 0
      ? config.organizationSettings
      : DefaultOrganizationSettingsData;
  const visitorEmailEnabled =
    organizationSettings?.visitorEmailOnCancel ||
    organizationSettings?.visitorEmailOnCreate ||
    organizationSettings?.visitorEmailOnUpdate;

  function onFieldUpdate(update: OrganizationSettings) {
    onConfigUpdated({
      organizationSettings: {
        hostEmailOnUpdate: organizationSettings?.hostEmailOnUpdate,
        hostEmailOnCancel: organizationSettings?.hostEmailOnCancel,
        hostEmailOnCheckIn: organizationSettings?.hostEmailOnCheckIn,
        hostEmailOnConfirm: organizationSettings?.hostEmailOnConfirm,
        visitorEmailOnCreate: organizationSettings?.visitorEmailOnCreate,
        visitorEmailOnUpdate: organizationSettings?.visitorEmailOnUpdate,
        visitorEmailOnCancel: organizationSettings?.visitorEmailOnCancel,
        hostNotificationOnCheckIn:
          organizationSettings?.hostNotificationOnCheckIn,
        visitorSmsNotificationOnCreate:
          organizationSettings?.visitorSmsNotificationOnCreate,
        ...update,
        organizationId: convertToUUID(channel._id),
      },
    });
  }

  function hostEmailEnabled() {
    return (
      organizationSettings?.hostEmailOnConfirm ||
      organizationSettings?.hostEmailOnUpdate ||
      organizationSettings?.hostEmailOnCancel
    );
  }

  function hostNotificationEnabled() {
    return (
      organizationSettings?.hostEmailOnCheckIn ||
      organizationSettings?.hostNotificationOnCheckIn
    );
  }

  function visitorSmsNotificationOnCreate() {
    return organizationSettings?.visitorSmsNotificationOnCreate;
  }

  const handleSmsNotificationChange = VisitorManagementSmsNotificationFlag
    ? () => {
        onFieldUpdate({
          visitorSmsNotificationOnCreate: !visitorSmsNotificationOnCreate(),
        });
      }
    : () => {};

  return (
    <div className={styles.notifications} data-cy="notificationsTab">
      {loading && <Loading className={styles.loading} />}
      {!loading && (
        <>
          <div>
            <H4 className={styles.title} data-cy="introSectionTitle">
              {t(
                'web.admin.visitorManagement.configuration.tab.notifications.title'
              )}
            </H4>
            <div
              className={styles.description}
              data-cy="introSectionDescribtion"
            >
              <S>
                {t(
                  'web.admin.visitorManagement.configuration.tab.notifications.description'
                )}
              </S>
            </div>
          </div>
          <div className={styles.section} data-cy="visitorNotificationsSection">
            <H5 data-cy="visitorNotificationsSectionTitle">
              {t(
                'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.title'
              )}
            </H5>
            <div className={styles.toggle}>
              <Toggle
                value={visitorEmailEnabled}
                onChange={() => {
                  onFieldUpdate({
                    visitorEmailOnCancel: !visitorEmailEnabled,
                    visitorEmailOnUpdate: !visitorEmailEnabled,
                    visitorEmailOnCreate: !visitorEmailEnabled,
                  });
                }}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.email.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.email.toggle.description'
                )}
                dataCy="visitorEmailToggle"
                valueDataCy="visitorEmailToggleValue"
              />
            </div>
            <div className={styles.toggle}>
              <Toggle
                disabled={!VisitorManagementSmsNotificationFlag}
                value={
                  VisitorManagementSmsNotificationFlag
                    ? visitorSmsNotificationOnCreate()
                    : false
                }
                onChange={handleSmsNotificationChange}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.sms.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.sms.toggle.description'
                )}
                dataCy="visitorSmsToggle"
                valueDataCy="visitorSmsToggleValue"
              />
            </div>
          </div>

          <div className={styles.section} data-cy="hostNotificationsSection">
            <H5 data-cy="hostNotificationsSectionTitle">
              {t(
                'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.title'
              )}
            </H5>
            <div className={styles.toggle}>
              <Toggle
                value={hostEmailEnabled()}
                onChange={() => {
                  onFieldUpdate({
                    hostEmailOnCancel: !hostEmailEnabled(),
                    hostEmailOnConfirm: !hostEmailEnabled(),
                    hostEmailOnUpdate: !hostEmailEnabled(),
                  });
                }}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.toggle.description'
                )}
                valueDataCy="hostEmailToggleValue"
                dataCy="hostEmailToggle"
              />
              {hostEmailEnabled() && (
                <div className={styles.checkboxes}>
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.checkbox.confirm'
                    )}
                    selected={organizationSettings?.hostEmailOnConfirm!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnConfirm: !organizationSettings?.hostEmailOnConfirm,
                      });
                    }}
                    style={checkboxStyle}
                    dataCy="hostEmailConfirm"
                  />
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.checkbox.update'
                    )}
                    selected={organizationSettings?.hostEmailOnUpdate!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnUpdate: !organizationSettings?.hostEmailOnUpdate,
                      });
                    }}
                    style={checkboxStyle}
                    dataCy="hostEmailUpdate"
                  />
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.checkbox.cancel'
                    )}
                    selected={organizationSettings?.hostEmailOnCancel!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnCancel: !organizationSettings?.hostEmailOnCancel,
                      });
                    }}
                    style={checkboxStyle}
                    dataCy="hostEmailCancel"
                  />
                </div>
              )}
            </div>
            <div className={styles.toggle}>
              <Toggle
                value={hostNotificationEnabled()}
                onChange={() => {
                  onFieldUpdate({
                    hostEmailOnCheckIn: !hostNotificationEnabled(),
                    hostNotificationOnCheckIn: !hostNotificationEnabled(),
                  });
                }}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.toggle.description'
                )}
                valueDataCy="visitorCheckInToggleValue"
                dataCy="visitorCheckInToggle"
              />
              {hostNotificationEnabled() && (
                <div className={styles.checkboxes}>
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.checkbox.email'
                    )}
                    selected={organizationSettings?.hostEmailOnCheckIn!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnCheckIn: !organizationSettings?.hostEmailOnCheckIn,
                      });
                    }}
                    style={checkboxStyle}
                    dataCy="hostEmailCheckIn"
                  />
                  {HostNotificationEnabledFlag && (
                    <Checkbox
                      value
                      selectedType="simple"
                      text={t(
                        'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.checkbox.push'
                      )}
                      selected={
                        organizationSettings?.hostNotificationOnCheckIn!
                      }
                      onChange={() => {
                        onFieldUpdate({
                          hostNotificationOnCheckIn: !organizationSettings?.hostNotificationOnCheckIn,
                        });
                      }}
                      style={checkboxStyle}
                      dataCy="hostNotificationOnCheckIn"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
