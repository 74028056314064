import { matchPath, useLocation } from 'react-router-dom';

import { routes } from 'lane-shared/config';

export default function useIsAdminView(): [boolean, string?] {
  const location = useLocation();

  const channelMatch = matchPath(location.pathname, {
    path: routes.channelAdmin,
    exact: false,
    strict: false,
  });

  return [!!channelMatch, (channelMatch?.params as any)?.id];
}
