import { useEffect } from 'react';

import { QRCodeCheckinFeatureProperties } from '../../../types/features/QRCodeCheckinFeatureProperties';
import useUpdateInteraction from '../../useUpdateInteraction';

export enum QRCodeCheckInMode {
  CHECK_IN = 'check-in',
  CHECK_OUT = 'check-out',
}

type Props = {
  qrCodeCheckinFeature: QRCodeCheckinFeatureProperties | null | undefined;
  interaction: any;
  mode: QRCodeCheckInMode;
};

/**
 * Use this hook when coming in from the context of the Content, i.e. you
 * have a Content Id but you don't have an interactionId yet.
 *
 * This would happen if the user scans a QR code for the Content itself,
 * not for a specific interaction.
 */
export default function useQRCodeCheckIn({
  qrCodeCheckinFeature,
  interaction,
  mode,
}: Props) {
  const checkInRule = qrCodeCheckinFeature?.checkIn;
  const checkOutRule = qrCodeCheckinFeature?.checkOut;
  const rule = mode === QRCodeCheckInMode.CHECK_IN ? checkInRule : checkOutRule;
  const fromStatus = rule?.fromStatus;
  const toStatus = rule?.toStatus;

  const {
    setInteractionUpdate,
    updateInteraction,
    error,
    loading,
    statusOptions,
    isActionable,
    isCancelled,
  } = useUpdateInteraction({ interaction });

  const canCheckInOrOut =
    !loading &&
    isActionable &&
    !isCancelled &&
    fromStatus &&
    interaction?.status === fromStatus &&
    statusOptions.some(option => option.value === toStatus);

  useEffect(() => {
    if (canCheckInOrOut) {
      setInteractionUpdate({ status: toStatus });
    }
  }, [canCheckInOrOut, toStatus]);

  async function submitCheckIn() {
    if (!canCheckInOrOut) {
      return;
    }

    await updateInteraction();
  }

  return {
    error,
    loading,
    checkInRule,
    checkOutRule,
    fromStatus,
    toStatus,
    canCheckInOrOut,
    isCancelled,
    submitCheckIn,
    submitCheckOut: submitCheckIn,
  };
}
