import React, { useState } from 'react';

import { Icon, Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import getMediaUrl from 'lane-shared/helpers/formatters/mediaUrl';
import { LibraryTypeEnum } from 'lane-shared/types/libraries';
import DocumentsLibrary from 'components/lane/DocumentsLibrary';
import { Modal } from 'components/lds';
import { AttachmentThumbnail } from 'lane-web/src/components/cards/AttachmentThumbnail';

import { LaneType } from 'common-types';
import styles from './DocumentSelectorButton.scss';
import type { Media } from 'graphql-query-contracts';

type DocumentSelectorButtonProps = {
  media: Pick<Media, '_id' | 'name' | 'file'>;
  setMedia: (value: Pick<Media, '_id' | 'name' | 'file'> | undefined) => void;
  channelId: LaneType.UUID;
  onContentUpdated: (value: Pick<Media, '_id' | 'name' | 'file'>) => void;
};

export const DocumentSelectorButton = ({
  media,
  channelId,
  setMedia,
  onContentUpdated,
}: DocumentSelectorButtonProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.DocumentSelectorButton}>
      {media ? (
        <AttachmentThumbnail
          attachment={{
            id: media._id!,
            fileUrl: getMediaUrl(media),
            file: media.file,
          }}
          disableDeleteWarning
          attachmentThumbnailStyle={styles.attachmentPreview}
          deleteAttachmentHandler={() => {
            setMedia(undefined);
          }}
        />
      ) : (
        <Button
          variant="secondary"
          size="large"
          endIcon={<Icon name="upload" set="Feather" />}
          className={styles.selectDocumentButton}
          onClick={() => setIsModalOpen(true)}
        >
          {t(
            'web.admin.channel.content.layout.editor.linkModal.documentButtonText'
          )}
        </Button>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className={styles.modalContainer}
        disableContentPadding
      >
        <DocumentsLibrary
          className={styles.documentLibrary}
          storageKey={channelId}
          library={{
            type: LibraryTypeEnum.Channel,
            _id: channelId,
            name: channelId,
          }}
          onMediaCreatedCallback={createdMedia => {
            if (createdMedia) {
              onContentUpdated(createdMedia);
              setIsModalOpen(false);
            }
          }}
          onMediaSelected={selectedMedia => {
            if (selectedMedia) {
              onContentUpdated(selectedMedia);
              setMedia(selectedMedia);
              setIsModalOpen(false);
            }
          }}
        />
      </Modal>
    </div>
  );
};
