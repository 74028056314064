import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { CONTENT_TYPES } from '../helpers/constants/content';
import { parseDateTime } from '../helpers/dates';
import { explodeFeatures } from '../helpers/features';
import getTimeZoneByGeoLocation from '../helpers/getTimeZoneByGeoLocation';
import { IntegrationProviderEnum } from '../types/integrations/IntegrationEnums';

export default function useAdminContentInfo({ content }: any) {
  // some integrations has start and end dates
  const integrationName = content?.integration?.integration
    ?.name as IntegrationProviderEnum;

  const features = useMemo(() => explodeFeatures(content?.features), [
    content?._id,
  ]);

  const {
    cancelableFeature,
    statusesFeature,
    inventoryFeature,
    reservableFeature,
  } = features;

  const timeZone = useMemo(
    () =>
      getTimeZoneByGeoLocation({
        latitude: content?.geo[1],
        longitude: content?.geo[0],
      }),
    [content?._id]
  );

  const contentHasDates = [
    CONTENT_TYPES.CONTENT,
    CONTENT_TYPES.NOTICE,
    CONTENT_TYPES.PERK,
    CONTENT_TYPES.PROMOTION,
  ].includes(content?.type);

  // determine if we should display these interactions by their start / end
  // dates or if we should use the creation / updated dates of the interactions
  let interactionsHaveDates = false;

  // these features all force start and end dates.
  // BEGIN-NOSCAN
  if (inventoryFeature || reservableFeature) {
    // END-NOSCAN
    interactionsHaveDates = true;
  } else if (integrationName) {
    // these integrations all have start and end dates.
    switch (integrationName) {
      case IntegrationProviderEnum.AngusServiceRequests:
      case IntegrationProviderEnum.HOM:
      case IntegrationProviderEnum.ParkWhiz:
      case IntegrationProviderEnum.ProxyClick:
        interactionsHaveDates = true;
    }
  }

  // only some types of content have start and end dates.
  let defaultStartDate = DateTime.local()
    .setZone(timeZone)
    .minus({ months: 3 });
  let defaultEndDate = DateTime.local().setZone(timeZone).endOf('day');

  if (interactionsHaveDates) {
    defaultStartDate = DateTime.local().setZone(timeZone).startOf('day');
    defaultEndDate = DateTime.local().setZone(timeZone).plus({ months: 3 });
  } else if (contentHasDates) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateTime | null' is not assignable to type '... Remove this comment to see the full error message
    defaultStartDate = parseDateTime(content?.liveDate);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateTime | null' is not assignable to type '... Remove this comment to see the full error message
    defaultEndDate = parseDateTime(content?.endDate);
  }

  const isActionable = cancelableFeature || statusesFeature;

  return {
    features,
    isActionable,
    interactionsHaveDates,
    contentHasDates,
    timeZone,
    defaultStartDate,
    defaultEndDate,
    integrationName,
  };
}
