import React, { memo } from 'react';

import { Icon } from 'design-system-web';

import { IconSet } from 'lane-shared/helpers/constants/icons';

import getIconRealName from './getIconRealName';

type Props = {
  set?: IconSet;
  name: string;
  type?: string;
};

function IconButton({ set, name, type }: Props) {
  const realName = getIconRealName({ name, set });

  return (
    <button data-icon-name={realName} data-icon-weight={type}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
      <Icon set={set} name={realName} type={type} />
    </button>
  );
}

export default memo(IconButton);
