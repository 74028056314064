import { v4 as uuid } from 'uuid';

import getLink from '../getLink';

export default function TextBlockMigrator(oldBlock: any) {
  const temp = document.createElement('div');
  temp.innerHTML = oldBlock.text.en;
  const text = temp.textContent || temp.innerText || '';

  return {
    _id: uuid(),
    blockId: '3MySOQd6QCKAv9HiktQe1w',
    blockVersion: 0,
    properties: { text, header: null, textAlign: 'left' },
    link: getLink(oldBlock.link),
  };
}
