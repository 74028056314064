import React from 'react';

import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';
import { H4 } from 'components/typography';
import styles from 'lane-web/src/components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type props = {
  channel: {
    settings: {
      hasWorkOrderServiceRequestsEnabled: boolean;
      hasWorkOrderPreventiveMaintenanceEnabled: boolean;
      hasWorkOrderEquipmentEnabled: boolean;
      hasWorkOrderCrossPropertyEnabled: boolean;
    };
  };
  disabled: boolean;
  onChannelUpdated: (channel: any) => void;
};

export function WorkOrderToggleSet({
  channel,
  disabled,
  onChannelUpdated,
}: props) {
  const { t } = useTranslation();

  return (
    <div>
      <H4 mb={4}>
        {t('web.admin.channel.profile.settings.workOrders.header')}
      </H4>
      <div className={styles.settings}>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="togglServiceRquests"
            disabled={disabled}
            value={channel.settings.hasWorkOrderServiceRequestsEnabled || false}
            onChange={value =>
              onChannelUpdated({
                settings: {
                  ...channel.settings,
                  hasWorkOrderServiceRequestsEnabled: value,
                },
              })
            }
            text={t(
              'web.content.feature.workOrder.serviceRequests.toggleFeature.title'
            )}
            description={t(
              'web.content.feature.workOrder.serviceRequests.toggleFeature.description'
            )}
          />
        </div>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="toggleEquipment"
            disabled={disabled}
            value={channel.settings.hasWorkOrderEquipmentEnabled || false}
            onChange={value =>
              onChannelUpdated({
                settings: {
                  ...channel.settings,
                  hasWorkOrderEquipmentEnabled: value,
                },
              })
            }
            text={t(
              'web.content.feature.workOrder.equipment.toggleFeature.title'
            )}
            description={t(
              'web.content.feature.workOrder.equipment.toggleFeature.description'
            )}
          />
        </div>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="togglePreventiveMaintenance"
            disabled={disabled}
            value={
              channel.settings.hasWorkOrderPreventiveMaintenanceEnabled || false
            }
            onChange={value =>
              onChannelUpdated({
                settings: {
                  ...channel.settings,
                  hasWorkOrderPreventiveMaintenanceEnabled: value,
                },
              })
            }
            text={t(
              'web.content.feature.workOrder.preventiveMaintenance.toggleFeature.title'
            )}
            description={t(
              'web.content.feature.workOrder.preventiveMaintenance.toggleFeature.description'
            )}
          />
        </div>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="togglePortfolioView"
            disabled={disabled}
            value={channel.settings.hasWorkOrderCrossPropertyEnabled || false}
            onChange={value =>
              onChannelUpdated({
                settings: {
                  ...channel.settings,
                  hasWorkOrderCrossPropertyEnabled: value,
                },
              })
            }
            text={t(
              'web.content.feature.workOrder.portfolioView.toggleFeature.title'
            )}
            description={t(
              'web.content.feature.workOrder.portfolioView.toggleFeature.description'
            )}
          />
        </div>
      </div>
    </div>
  );
}
