import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TIME } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  startTime: {
    type: 'Time',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'LessThan',
        value: 'endTime',
      },
    ],
  },
  endTime: {
    type: 'Time',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'GreaterThan',
        value: 'startTime',
      },
    ],
  },
};

export class TimeRangeType extends ObjectTypeBase {
  group = TYPE_GROUP_TIME;

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
    TypeContextEnum.Metatag,
  ];

  name = 'TimeRange';

  get example() {
    return {
      _id: uuid(),
      startTime: '1:00 PM',
      endTime: '4:00 PM',
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      startTime: '9:00 AM',
      endTime: '5:00 PM',
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;

  formatter = (value: any) => `${value?.startTime} - ${value?.endTime}`;
}

const definition = new TimeRangeType();

Types.addBaseType(definition);
export default definition;
