import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import * as staleChannelNotificationManager from 'lane-shared/helpers/channel/staleChannelNotification';

import styles from './StaleChannelDataNotification.scss';

/*
 * Let the users know that they may need to refresh to get latest data
 *
 * use case: https://viewthespace.atlassian.net/browse/TM-3470
 *
 * * */
export default function StaleChannelDataNotification() {
  const { t } = useTranslation();

  if (staleChannelNotificationManager.shouldShow()) {
    return (
      <div
        data-cy="stale-notification-container"
        className={cx(styles.Container)}
      >
        <span className={cx(styles.Text)} data-cy="stale-notification-text">
          {t(
            'It may take some time for your account to be added to the company channels. Please refresh to get the latest channel.'
          )}
        </span>
      </div>
    );
  }

  return null;
}
