import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'lane-shared/types/integrations/IntegrationEnums';

const LifeStartDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.LifeStart,
  hasCustomUI: false,
  friendlyName: 'LifeStart',
  description: `Defines the properties required for a LifeStart integration.`,
  category: IntegrationCategoryEnum.Content,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  allowsMultipleInstances: true,
  settings: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'The base URL of the LifeStart API endpoint',
      type: 'Url',
      default:
        'https://c90f9153-2910-4cf5-b5b6-58e0f35a32a6.integration-hook.com',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'Override the standard base URL for LifeStart?',
      type: 'Url',
    },
    name: {
      friendlyName: 'Display Name',
      type: 'String',
      description: 'The name to display for this LifeStart form',
      default: 'LifeStart',
    },
    subtitle: {
      friendlyName: 'Subtitle',
      type: 'String',
      description: 'The subtitle for this LifeStart form',
      default: 'Video content',
    },
    clubCode: {
      friendlyName: 'Club Code',
      type: 'String',
    },
  },
};

export default LifeStartDefinition;
