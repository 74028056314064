import * as yup from 'yup';

import {
  validateContentBase,
  validateContentInfo,
  validateContentPublishing,
  validateContentTargeting,
  validateContentEditor,
} from './content';

export default yup
  .object()
  .concat(validateContentBase)
  .concat(validateContentInfo)
  .concat(validateContentEditor)
  .concat(validateContentTargeting)
  .concat(validateContentPublishing);
