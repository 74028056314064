import React, { useContext } from 'react';

import { LaneType } from 'common-types';
import { UserDataContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';

import { M } from '../typography/index';
import CircleListView from './CircleListView';

type ChannelCircleListViewProps = {
  channel:
    | {
        _hasAdmin?: boolean;
        name: string;
        profile?: {
          name: string | null | undefined;
          image: LaneType.UUID;
          logo: LaneType.UUID;
        };
      }
    | null
    | undefined;
  className?: string;
  style?: React.CSSProperties;
  description?: string;
  showIfAdmin?: boolean;
  showAddress?: string;
};

export default function ChannelCircleListView({
  channel,
  className,
  style,
  description,
  showIfAdmin,
}: ChannelCircleListViewProps) {
  const name = getDisplayName(channel);
  const { user } = useContext(UserDataContext);
  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  const displayAdmin = showIfAdmin && (user?.isSuperUser || channel._hasAdmin);

  return (
    <CircleListView
      image={imageUrl(channel?.profile?.image)}
      logo={imageUrl(channel?.profile?.logo)}
      name={name}
      className={className}
      style={style}
    >
      <M mb={displayAdmin ? 1 : 0}>{name}</M>
      {displayAdmin && <span>Admin</span>}
      {description && <M variant="secondary">{description}</M>}
    </CircleListView>
  );
}
