import { Primitive } from '../../../../types/blocks/Primitive';

/**
 * @deprecated use PropertyInputBlock instead
 */
const LongTextInputBlock: Primitive = {
  name: 'LongTextInputBlock',
  isInput: true,
  isPrimitive: true,
  properties: {
    text: {
      type: 'String',
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
    minLength: {
      type: 'Number',
      validators: [
        {
          name: 'Min',
          value: 0,
        },
        {
          name: 'Max',
          value: 2 ** 18,
        },
      ],
    },
    maxLength: {
      type: 'Number',
      validators: [
        {
          name: 'Min',
          value: 0,
        },
        {
          name: 'Max',
          value: 2 ** 18,
        },
      ],
    },
    placeholder: {
      type: 'String',
      validators: [
        {
          name: 'Max',
          value: 2048,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    input: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default LongTextInputBlock;
