import { useEffect, useState } from 'react';

import { QueryResult, useLazyQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { ShopifyMultipassNoVerifiedEmailError } from 'activate-errors';
import { ContentType } from 'lane-shared/types/content/Content';

import { getClient } from '../apollo';
import { syncShopifyMultipassMarketingPreference } from '../graphql/user';
import { shopifyMultipassLogin } from '../helpers/integrations/ShopifyMultipass';

type HookParameters = {
  isAdminView: boolean;
  openUrl: (loginUrl: LaneType.Url) => void;
};

type ShopifyMultipassLogin = {
  shopifyCustomerId: LaneType.UUID;
  shopifyStoreUrl: LaneType.Url;
  loginUrl: LaneType.Url;
  multipassId: LaneType.UUID;
};

type HookReturnValue = {
  triggerShopifyMultipassFlow: (content: ContentType) => Promise<any>;
  createNewAccount: () => void;
  isEmailVerificationModalOpen: boolean;
  closeEmailVerificationModal: () => void;
  isErrorModalOpen: boolean;
  closeErrorModal: () => void;
  error: QueryResult['error'];
  loading: QueryResult['loading'];
};

export default function useShopifyMultipassIntegration({
  isAdminView,
  openUrl,
}: HookParameters): HookReturnValue {
  const [
    isEmailVerificationModalOpen,
    setIsEmailVerificationModalOpen,
  ] = useState<boolean>(false);

  const [
    channelIntegrationId,
    setChannelIntegrationId,
  ] = useState<LaneType.UUID | null>(null);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  async function updateMarketingPreferences() {
    const client = await getClient();
    client.mutate({
      mutation: syncShopifyMultipassMarketingPreference,
      variables: { channelIntegrationId },
    });
  }

  function onCompleted(data: {
    me: { shopifyMultipassLogin: ShopifyMultipassLogin };
  }) {
    if (isAdminView || !data?.me?.shopifyMultipassLogin) {
      return;
    }

    const shopifyMultipassLogin = data?.me?.shopifyMultipassLogin;

    const loginUrl = shopifyMultipassLogin?.loginUrl;
    const identifier = shopifyMultipassLogin?.multipassId;
    const shopifyCustomerId = shopifyMultipassLogin?.shopifyCustomerId;

    if (shopifyCustomerId && !identifier) {
      createNewAccount();
    } else if (loginUrl) {
      closeEmailVerificationModal();
      openUrl(loginUrl);
      updateMarketingPreferences();
    } else {
      setIsEmailVerificationModalOpen(true);
    }
  }

  const [
    fetchShopifyMultipassLogin,
    { loading, error, variables },
  ] = useLazyQuery(shopifyMultipassLogin, {
    client: getClient(),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    onCompleted,
  });

  const loginError = error?.message;

  const unverifiedEmailError = loginError?.includes(
    ShopifyMultipassNoVerifiedEmailError.errorName
  );

  useEffect(() => {
    if (unverifiedEmailError) {
      setIsEmailVerificationModalOpen(true);
    } else if (loginError) {
      setIsErrorModalOpen(true);
    }
  }, [loginError, unverifiedEmailError]);

  function closeEmailVerificationModal() {
    setIsEmailVerificationModalOpen(false);
  }

  function closeErrorModal() {
    setIsErrorModalOpen(false);
  }

  function triggerShopifyMultipassFlow(content: ContentType) {
    const variables = {
      channelIntegrationId: content?.integration?._id,
      createLoginRecord: false,
      externalUrl: content?.externalUrl?.url,
    };

    setChannelIntegrationId(content?.integration?._id);

    return fetchShopifyMultipassLogin({
      variables,
    });
  }

  async function createNewAccount() {
    await fetchShopifyMultipassLogin({
      variables: {
        ...variables,
        createLoginRecord: true,
      },
    });
  }

  return {
    triggerShopifyMultipassFlow,
    createNewAccount,
    isEmailVerificationModalOpen,
    closeEmailVerificationModal,
    isErrorModalOpen,
    closeErrorModal,
    loading,
    error,
  };
}
