import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import { TypeContextEnum } from '../../../../types/properties/TypeContextEnum';

export enum ContentCardEnum {
  Standard = 'Standard',
  Compact = 'compact',
}

export type ContentOptionsType = {
  isOnlyInteractive: boolean;
  icon?: string;
  label?: string;
  view?: ContentCardEnum;
};

const properties: PropertiesInterface<ContentOptionsType> = {
  content: {
    type: 'Content',
    friendlyName:
      'web.content.features.requirements.types.content.friendlyName',
    options: {
      isOnlyInteractive: true,
      icon: 'plus',
      label: 'web.content.features.requirements.types.content.options.label',
      view: ContentCardEnum.Compact,
    },
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  header: {
    type: 'String',
    friendlyName: 'web.content.features.requirements.types.header.friendlyName',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  description: {
    type: 'String',
    friendlyName:
      'web.content.features.requirements.types.description.friendlyName',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class ContentInteractionRequirementType extends ObjectTypeBase {
  name = 'ContentInteractionRequirementType';

  friendlyName = 'Description of required content';

  contexts = [TypeContextEnum.Properties];

  get default(): any {
    return {
      header: null,
      description: null,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new ContentInteractionRequirementType();

Types.addType(definition);
export default definition;
