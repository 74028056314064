import { Dispatch, SetStateAction } from 'react';

import { getMessagesFromError } from '../../../../helpers';
import {
  EssensysOrganizationType,
  PaymentProcessorEnum,
} from '../../../../types/Essensys';
import { PaymentAccountType } from '../../../../types/payment/PaymentAccount';
import getEssensysOrganization from '../helpers/getEssensysOrganization';
import getOnlinePlanPaymentAccount from '../helpers/getOnlinePlanPaymentAccount';

type DoConsolidatePaymentAccountOptions = {
  setLoading: Dispatch<SetStateAction<boolean>>;
  locationId: string;
  clientTypeId: string | undefined;
  friendlyClientType: string | undefined;
  Sentry: any;
  setOrganization: Dispatch<
    SetStateAction<Partial<EssensysOrganizationType> | undefined>
  >;
  setPaymentAccount: Dispatch<SetStateAction<PaymentAccountType | undefined>>;
  setError: Dispatch<SetStateAction<Error | undefined>>;
};

function getPaymentProcessor(
  paymentProcessor: string | undefined
): PaymentProcessorEnum | undefined {
  switch (paymentProcessor) {
    case PaymentProcessorEnum.PlacePay:
    case PaymentProcessorEnum.Stripe:
      return PaymentProcessorEnum[paymentProcessor];
    default:
      return undefined;
  }
}

export default async function doConsolidatePaymentAccount({
  setLoading,
  locationId,
  clientTypeId,
  friendlyClientType,
  Sentry,
  setOrganization,
  setPaymentAccount,
  setError,
}: DoConsolidatePaymentAccountOptions) {
  setLoading(true);
  try {
    const organization = await getEssensysOrganization(
      locationId,
      clientTypeId,
      friendlyClientType,
      Sentry
    );
    if (organization) {
      setOrganization(organization);
    }
    const paymentProcessor = getPaymentProcessor(
      organization?.paymentprocessor
    );
    if (paymentProcessor) {
      const onlinePlanPaymentAccount = await getOnlinePlanPaymentAccount(
        paymentProcessor,
        organization
      );
      setPaymentAccount(onlinePlanPaymentAccount);
    }
  } catch (err) {
    const messages = getMessagesFromError(err);
    if (messages.length) {
      setError(new Error(messages[0]));
    } else {
      setError(err);
    }
  } finally {
    setLoading(false);
  }
}
