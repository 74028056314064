import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesArrayMax } from './ITypeValidatesArrayMax';

export class ArrayMaxValidator
  implements ValidatorInterface<ITypeValidatesArrayMax> {
  name = 'ArrayMax';

  friendlyName = 'List Maximum Size';

  arrayOnly = true;

  array = true;

  description = 'Maximum number of entries for this field';

  types = null;

  properties = {
    value: {
      type: 'Number',
      default: 1,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: Number.MAX_SAFE_INTEGER,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesArrayMax) {
    type.addArrayMaxValidator(this);
  }

  constructor(public config?: Validator) {}
}

const ArrayMax = new ArrayMaxValidator(undefined);

ValidatorRegistry.register(ArrayMaxValidator, ArrayMax.name);

export default ArrayMax;
