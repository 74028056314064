import { Primitive } from '../../../types/blocks/Primitive';

const PaymentFeatureSummaryBlock: Primitive = {
  name: 'PaymentFeatureSummaryBlock',
  friendlyName: 'Payment Feature Summary',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Integration'],
  properties: {},
  publishes: {},
};

export default PaymentFeatureSummaryBlock;
