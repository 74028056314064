import { Storage } from 'lane-shared/helpers';

import { AuthorizeResult } from '../OAuth.service';

export async function getItemFromStorage(key: string) {
  const knownState = (await Storage.getItem(key)) as AuthorizeResult;

  return knownState;
}

export async function setItemInStorage(key: string, data: any) {
  await Storage.setItem(key, data);
}

export async function removeItemFromStorage(key: string) {
  await Storage.removeItem(key);
}
