import {
  EVENT_PHONE_NUMBER_UPDATED,
  EVENT_PHONE_NUMBER_CREATED,
  EVENT_LOGIN_PHONE_NUMBER_VERIFIED,
  EVENT_LOGIN_PHONE_NUMBER_DELETED,
  EVENT_LOGIN_PHONE_NUMBER_CREATED,
} from 'lane-shared/helpers/constants/events';

import { AnalyticsInterface } from '../../types/Analytics';

type EmitPhoneNumberType = {
  isPreviousPhoneNumber?: boolean;
  isLoginPhoneNumberCreated?: boolean;
  isPhoneNumberVerified?: boolean;
  userId: string;
  analytics: AnalyticsInterface;
  selectedLoginId?: string;
};

export function emitPhoneNumber(data: EmitPhoneNumberType) {
  let eventName;

  eventName = data.isPreviousPhoneNumber
    ? (eventName = EVENT_PHONE_NUMBER_UPDATED)
    : EVENT_PHONE_NUMBER_CREATED;

  if (data?.selectedLoginId) {
    if (data.isLoginPhoneNumberCreated) {
      eventName = data.isPhoneNumberVerified
        ? EVENT_LOGIN_PHONE_NUMBER_VERIFIED
        : EVENT_LOGIN_PHONE_NUMBER_CREATED;
    } else {
      eventName = EVENT_LOGIN_PHONE_NUMBER_DELETED;
    }
  }

  data.analytics.track(eventName, {
    userId: data.userId,
  });
}
