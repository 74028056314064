import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const PattisonDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Pattison,
  hasCustomUI: false,
  friendlyName: 'Pattison Displays',
  description: `Defines the properties required for a Pattison integration.`,
  category: IntegrationCategoryEnum.Displays,
  platforms: [],
  type: IntegrationTypeEnum.ContentTargeting,
  settings: {},
  properties: {
    tags: {
      friendlyName: 'Filters',
      type: 'String',
      isArray: true,
    },
    showDate: {
      friendlyName: 'Show dates on screens',
      type: 'Boolean',
      default: false,
    },
    showQRCode: {
      friendlyName: 'Show QR code on screens',
      type: 'Boolean',
      default: false,
    },
  },
  mobilePermissions: [],
};

export default PattisonDefinition;
