import { groupBy } from 'lodash';

import { DropdownValueProp } from '../BuildingEnginesPrismVisitors/types/VisitTypes';
import * as client from './client';

export async function login(
  email: string,
  password: string
): Promise<client.auth.LoginResDataOk | client.auth.LoginResDataError> {
  const res = await client.auth.login({ email, password });
  const data = await res.data;
  if (res.status !== 200) {
    return { ...data, isError: true } as client.auth.LoginResDataError;
  }
  return { ...data, isError: false } as client.auth.LoginResDataOk;
}

export async function fetchWorkorders(
  token: string,
  building_id: string
): Promise<client.workOrders.ListResData> {
  return (
    await client.workOrders.list(token, {
      building_scope: building_id,
      ordering: '-updated_at,-created_at',
      limit: 25,
      offset: 0,
    })
  ).data;
}

export async function postWorkorderComments(
  token: string,
  text: string,
  is_private: boolean = false,
  work_order_id: string
): Promise<null> {
  return (
    await client.workOrderComments.create(
      token,
      { work_order_id },
      { isPrivate: is_private, text }
    )
  ).data;
}

export async function getWorkorderComments(
  token: string,
  work_order_id: string
): Promise<client.workOrderComments.ListResData> {
  return (await client.workOrderComments.list(token, { work_order_id })).data;
}

export async function fetchUser(
  token: string
): Promise<client.users.User | {}> {
  return (await client.users.currentUser(token)).data;
}

export async function fetchMoreWorkorders(
  token: string,
  nextPage: URLSearchParams
): Promise<client.workOrders.ListResData> {
  return (await client.workOrders.list(token, nextPage)).data;
}

export async function fetchOrganizationsByBuildingId(
  token: string,
  building_id: string
): Promise<client.organizations.Organization[]> {
  return (
    await (
      await client.organizations.list(token, {
        building_scope: building_id,
        organization_type__in: 'tenant,pmo',
        ordering: 'name',
      })
    ).data
  ).results;
}

export async function fetchOwnSpaces(
  token: string,
  building_id: string
): Promise<client.spaces.Space[]> {
  const spaces = (await (await client.spaces.list(token, { building_id })).data)
    .results;
  const organizations = await fetchOrganizationsByBuildingId(
    token,
    building_id
  );

  const organizationIDs = new Set();
  organizations.forEach(item => organizationIDs.add(item.id));

  const result: Array<client.spaces.Space> = [];
  spaces.forEach(item => {
    if (organizationIDs.has(item.tenant_id)) {
      result.push(item);
    }
  });

  return result;
}

export interface FloorWithSpaces extends client.floors.Floor {
  spaces: client.spaces.Space[];
}

export async function fetchWorkorderFormData(
  token: string,
  building_id: string
): Promise<{
  issue_types: client.issueTypes.IssueType[];
  floors: FloorWithSpaces[];
}> {
  const spaces = await fetchOwnSpaces(token, building_id);
  const floors = await (await client.floors.list(token, { building_id })).data;
  const issueTypes = await (
    await client.issueTypes.list(token, { building: building_id })
  ).data;

  const spacesByFloor = groupBy(spaces, space => space.floor_id);
  const floorsWithSpaces = floors.map(floor => ({
    ...floor,
    spaces: spacesByFloor[floor.id] || [],
  }));

  return { issue_types: issueTypes, floors: floorsWithSpaces };
}

export async function submitWorkorder(
  token: string,
  building_id: string,
  space_id: string,
  issue_type_id: string,
  description: string,
  default_assignment?: boolean
): Promise<client.workOrders.WorkOrder> {
  const res = await client.workOrders.create(token, {
    building_id,
    description,
    issue_type_id,
    space_id,
    on_new_serdy_form: null,
    default_assignment,
  });
  if (!res.ok) throw new Error(res.status.toString());
  return res.data;
}

export async function uploadFile(
  token: string,
  work_order_id: string,
  file: Blob,
  fileName: string
): Promise<any> {
  return await client.fileOperations.post(token, work_order_id, file, fileName);
}

export async function getWorkOrderFiles(
  token: string,
  work_order_id: string
): Promise<any> {
  return (
    await client.fileOperations.get(token, {
      work_order_id,
    })
  ).data;
}

export async function deleteFile(
  token: string,
  file_id: string,
  file_association_id: string
): Promise<any> {
  return await client.fileOperations.deleteFile(token, {
    file_id,
    file_association_id,
  });
}

/**
 * @returns Promise with a Visit
 *
 * @see {@link https://api.connect.buildingengines.com/swagger/ | Building Engines Swagger Docs}
 * @see {@link https://connect.buildingengines.com/visits/create | BE Prism Create a Visitor Page}
 */
export async function createVisit(
  token: string,
  visit: client.visitors.VisitReqData
): Promise<client.visitors.VisitResData> {
  return (await client.visitors.create(token, visit)).data;
}

export async function fetchVisitors(
  token: string,
  ordering: string = client.visitors.FetchVisitsOrderingEnum.DateDesc,
  limit: number = 10,
  offset: number = 0,
  pagelimit: number = 100,
  filters?: object
): Promise<client.visitors.ListResData> {
  return (
    await client.visitors.list(
      token,
      {
        ordering,
        limit,
        offset,
        pagelimit,
      } as client.visitors.ListParams,
      filters as client.visitors.FetchVisitorsFilters
    )
  ).data;
}

/**
 * Fetch visit based on visit ID
 * @return Promise with visitor data
 */
export async function fetchVisit(
  token: string,
  visitId: string
): Promise<client.visitors.VisitResData> {
  return (await client.visitors.get(token, visitId)).data;
}

export async function cancelVisit(
  token: string,
  visitId: string
): Promise<any> {
  return await client.visitors.cancel(token, visitId);
}

export async function updateWorkOrderStatus(
  token: string,
  workOrderId: string,
  status: string,
  extraParams: object = {}
): Promise<client.workOrders.WorkOrder> {
  return (
    await client.workOrders.updateStatus(
      token,
      workOrderId,
      status as client.workOrders.WorkOrderStatusTypes,
      extraParams
    )
  ).data;
}

export async function getOrganizationsData(token: string, buildingId: string) {
  const responseOrganizationData = await fetchOrganizationsByBuildingId(
    token!,
    buildingId
  );
  return responseOrganizationData
    .filter(data => data.is_active)
    .map(data => ({
      value: data.id,
      label: data.name,
    }));
}

export const fetchRequiredSpaces = (
  tenantId: string,
  spacesData: any
): DropdownValueProp[] => {
  let spaceIDs: DropdownValueProp[] = [];
  if (spacesData) {
    if (tenantId) {
      spaceIDs = spacesData.flatMap(({ spaces }: FloorWithSpaces) =>
        spaces
          .filter(space => space.tenant_id === tenantId)
          .map(({ id: value, name: label }) => ({
            value,
            label: `Space: ${label}`,
          }))
      );
    }
    if (spaceIDs.length === 0) {
      spaceIDs = spacesData.reduce(
        (
          lSpaceIds: { value: string; label: string }[],
          { id, name, spaces }: FloorWithSpaces
        ) => {
          lSpaceIds.push({ value: id, label: name });

          if (spaces) {
            const nestedIDs = spaces.map(
              ({ id: spaceId, name: spaceName }) => ({
                value: spaceId,
                label: `Space: ${spaceName}`,
              })
            );

            return lSpaceIds.concat(nestedIDs);
          }

          return lSpaceIds;
        },
        []
      );
    }
  }
  return spaceIDs;
};

export async function getCustomFieldForm(
  token: string,
  id: string
): Promise<client.customFieldForms.CustomFieldForm> {
  return (await client.customFieldForms.get(token, id)).data;
}
