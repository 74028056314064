/**
 * Any event in constants/events also has an associated analytic.
 *
 * Analytic events includes more events that wouldn't trigger something in the
 * platform.  i.e. a content.view is important, but nothing happens in the
 * backend because of it.  where a content.created, some thing does happen.
 *
 * If you add a new event, also add it to the array below.
 *
 */
export const ANALYTIC_PATH = 'path';
export const ANALYTIC_PATH_EXTERNAL = 'path.external';
export const ANALYTIC_HARDWARE_VISIT = 'hardware.visit';
export const ANALYTIC_CLICK = 'click';
export const ANALYTIC_EMAIL = 'email';
export const ANALYTIC_SMS = 'sms';
export const ANALYTIC_PUSH = 'push';
export const ANALYTIC_ERROR = 'error';
export const ANALYTIC_ACCESS_CONTROL_ENABLED_BLUETOOTH =
  'access-control.enable-bluetooth';
export const ANALYTIC_ACCESS_CONTROL_SAFETRUST_ACCOUNT_ACTIVATED =
  'access-control.safetrust.account-activated';
export const ANALYTIC_ACCESS_CONTROL_HID_ACCOUNT_ACTIVATED =
  'access-control.hid.account-activated';
export const ANALYTIC_ACCESS_CONTROL_ACCOUNT_ACTIVATED =
  'access-control.account-activated';
export const ANALYTIC_ACCESS_CONTROL_INPUT_CODE = 'access-control.input-code';
export const ANALYTIC_ACCESS_CONTROL_UNLOCKING = 'access-control.unlocking';
export const ANALYTIC_ACCESS_CONTROL_UNLOCKED = 'access-control.unlocked';
export const ANALYTIC_ACCESS_CONTROL_OPEN_PANEL = 'access-control.open-panel';
export const ANALYTIC_ACCESS_CONTROL_SUPPORT_REQUEST =
  'access-control.support-requested';
export const ANALYTIC_CONTENT_READ = 'content.read';
export const ANALYTIC_CONTENT_VIEW = 'content.view';
export const ANALYTIC_CONTENT_TOUCH_PIN = 'content.touch.pin';
export const ANALYTIC_CONTENT_TOUCH_LIKE = 'content.touch.like';
export const ANALYTIC_CONTENT_TOUCH_SHARE = 'content.touch.share';
export const ANALYTIC_CONTENT_TOUCH_HIDE = 'content.touch.hide';
export const ANALYTIC_CONTENT_TOUCH_RATE = 'content.touch.rate';
export const ANALYTIC_CONTENT_TOUCH_STAR = 'content.touch.star';
export const ANALYTIC_CONTENT_TOUCH_DELETE = 'content.touch.delete';
export const ANALYTIC_CONTENT_DETAILS = 'content.details';
export const ANALYTIC_CONTENT_DETAILS_SHOPIFY_MULTIPASS_SECTION =
  'content.details.shopify-multipass.section-block';
export const ANALYTIC_CONTENT_NOTIFICATION_SENT = 'content.notification.sent';
export const ANALYTIC_CONTENT_INTERACTION_READ = 'content.interaction.read';
export const ANALYTIC_CONTENT_INTERACTION_VIEW = 'content.interaction.view';
export const ANALYTIC_CONTENT_INTERACTION_DETAIL = 'content.interaction.detail';
export const ANALYTIC_CHANNEL_READ = 'channel.read';
export const ANALYTIC_CHANNEL_VIEW = 'channel.view';
export const ANALYTIC_CHANNEL_DETAILS = 'channel.details';
export const ANALYTIC_CHANNEL_TOUCH_PIN = 'channel.touch.pin';
export const ANALYTIC_CHANNEL_TOUCH_LIKE = 'channel.touch.like';
export const ANALYTIC_CHANNEL_TOUCH_SHARE = 'channel.touch.share';
export const ANALYTIC_CHANNEL_TOUCH_HIDE = 'channel.touch.hide';
export const ANALYTIC_CHANNEL_TOUCH_RATE = 'channel.touch.rate';
export const ANALYTIC_CHANNEL_TOUCH_STAR = 'channel.touch.star';
export const ANALYTIC_CHANNEL_TOUCH_DELETE = 'channel.touch.delete';
export const ANALYTIC_USER_READ = 'user.read';
export const ANALYTIC_USER_VIEW = 'user.view';
export const ANALYTIC_USER_DETAILS = 'user.details';
export const ANALYTIC_USER_TOUCH_LIKE = 'user.touch.like';
export const ANALYTIC_USER_TOUCH_SHARE = 'user.touch.share';
export const ANALYTIC_USER_TOUCH_HIDE = 'user.touch.hide';
export const ANALYTIC_USER_TOUCH_RATE = 'user.touch.rate';
export const ANALYTIC_USER_TOUCH_STAR = 'user.touch.star';
export const ANALYTIC_USER_TOUCH_DELETE = 'user.touch.delete';
export const ANALYTIC_USER_SIGNIN_FAILED = 'user.signin.failed';
export const ANALYTIC_MEDIA_READ = 'media.read';
export const ANALYTIC_MEDIA_VIEW = 'media.view';
export const ANALYTIC_MEDIA_DETAILS = 'media.details';
export const ANALYTIC_CONTENTTEMPLATE_READ = 'contentTemplate.read';
export const ANALYTIC_CONTENTTEMPLATE_VIEW = 'contentTemplate.view';
export const ANALYTIC_CONTENTTEMPLATE_DETAILS = 'contentTemplate.details';
export const ANALYTIC_LIBRARY_READ = 'library.read';
export const ANALYTIC_LIBRARY_VIEW = 'library.view';
export const ANALYTIC_LIBRARY_DETAILS = 'library.details';
export const ANALYTIC_SECTION_READ = 'section.read';
export const ANALYTIC_SECTION_VIEW = 'section.view';
export const ANALYTIC_SECTION_DETAILS = 'section.details';
export const ANALYTIC_INTEGRATION_READ = 'integration.read';
export const ANALYTIC_INTEGRATION_VIEW = 'integration.view';
export const ANALYTIC_INTEGRATION_DETAILS = 'integration.details';
export const ANALYTIC_CHANNEL_INTEGRATION_READ = 'channel.integration.read';
export const ANALYTIC_CHANNEL_INTEGRATION_VIEW = 'channel.integration.view';
export const ANALYTIC_CHANNEL_INTEGRATION_DETAILS =
  'channel.integration.details';
export const ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETED =
  'channel-settings.channel.deleted';
export const ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETE_FAILED =
  'channel-settings.channel.delete.failed';
export const ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNCED =
  'channel-settings.channel.join-rule.synced';
export const ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNC_FAILED =
  'channel-settings.channel.join-rule.sync.failed';
export const ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETED =
  'channel-settings.child-channel.deleted';
export const ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETE_FAILED =
  'channel-settings.child-channel.delete.failed';
export const ANALYTIC_QRCODE_SCANNED = 'qrcode.scanned';
export const ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETED =
  'channel-management.channel.deleted';
export const ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETE_FAILED =
  'channel-management.channel.delete.failed';
export const ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGED =
  'channel-management.channel-logo.changed';
export const ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGE_FAILED =
  'channel-management.channel-logo.change.failed';
export const ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGED =
  'channel-management.channel-type.changed';
export const ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGE_FAILED =
  'channel-management.channel-type.change.failed';
export const ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGED =
  'channel-management.parent-channel.changed';
export const ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGE_FAILED =
  'channel-management.parent-channel.change.failed';
export const ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LINK_CLICK =
  'channel-management.channel-link.click';
export const ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_LINK_CLICK =
  'channel-management.parent-channel-link.click';
export const ANALYTIC_CHANNEL_MANAGEMENT_PAGE_VISITED =
  'channel-management.page.visited';
export const ANALYTIC_SEARCH_CHANNELS_PAGE_VISITED =
  'search-channels.page.visited';
export const ANALYTIC_CHANNEL_MANAGEMENT_TABLE_PAGE_NAVIGATED =
  'channel-management.table-page.navigated';
export const HOST_BUILDING_AUTO_SETUP_WIZARD_OPENED =
  'host-building.auto-setup-wizard.opened';
export const HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_SELECTED =
  'host-building.auto-setup-wizard.source-selected';
export const HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_LOCATION_SELECTED =
  'host-building.auto-setup-wizard.source-location-selected';
export const HOST_BUILDING_AUTO_SETUP_WIZARD_TEMPLATE_SELECTED =
  'host-building.auto-setup-wizard.template-selected';
export const HOST_BUILDING_AUTO_SETUP_WIZARD_CONFIRMED =
  'host-building.auto-setup-wizard.confirmed';
export const HOST_BUILDING_AUTO_SETUP_WIZARD_SAVED_SUCCESSFULLY =
  'host-building.auto-setup-wizard.saved-successfully';
export const HOST_BUILDING_AUTO_SETUP_WIZARD_SAVE_FAILED =
  'host-building.auto-setup-wizard.save-failed';
export const ANALYTIC_CHANNEL_PROFILE_LOGO_CHANGE_ATTEMPTED =
  'channel-profile.logo.change.attempted';
export const ANALYTIC_APPLE_WALLET_START_FLOW =
  'access.apple-wallet.start-flow';
export const ANALYTIC_APPLE_WALLET_START_FLOW_ERROR =
  'access.apple-wallet.start-flow.error';
export const ANALYTIC_APPLE_WALLET_ADD_PASS_SUCCESS =
  'access.apple-wallet.add-pass.success';
export const ANALYTIC_APPLE_WALLET_ADD_PASS_ERROR =
  'access.apple-wallet.add-pass.error';
export const ANALYTIC_APPLE_WALLET_MANAGE_PASS =
  'access.apple-wallet.manage-pass';
export const ANALYTIC_APPLE_WALLET_ADD_PASS_WATCH_SUCCESS =
  'access.apple-wallet.add-pass.watch.success';
export const ANALYTIC_SEAMLESS_BLE_START_FLOW =
  'access.seamless-ble.start-flow';
export const ANALYTIC_SEAMLESS_BLE_START_FLOW_ERROR =
  'access.seamless-ble.start-flow.error';
export const ANALYTIC_SEAMLESS_BLE_ADD_PASS_SUCCESS =
  'access.seamless-ble.add-pass.success';
export const ANALYTIC_SEAMLESS_BLE_ADD_PASS_ERROR =
  'access.seamless-ble.add-pass.error';
export const ANALYTIC_SEAMLESS_BLE_MANAGE_PASS =
  'access.seamless-ble.manage-pass';

export const ANALYTIC_FORCED_UPDATE_MODAL_VISIBLE =
  'mobile.forced.update.screen.visible';
export const ANLATYIC_FORCED_UPDATE_CTA_BUTTON_CLICK =
  'mobile.forced.update.button.clicked';
export const ANALYTIC_FORCED_UPDATE_ERROR_WHITELABEL =
  'mobile.forced.update.whitelabel.not.found';

export const MAX_ANALYTICS_PER_REQUEST = 40;

type AnalyticsType = {
  key: string;
  description: string;
  userPermitted?: boolean;
}[];

const analytics: AnalyticsType = [
  {
    key: ANALYTIC_PATH,
    description: 'A path was visited, or a screen was viewed',
    userPermitted: true,
  },
  {
    key: ANALYTIC_PATH_EXTERNAL,
    description: 'An external path was visited in the WebView browser',
    userPermitted: true,
  },
  {
    key: ANALYTIC_HARDWARE_VISIT,
    description: 'A hardware screen was visited in the device.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CLICK,
    description: 'A button was tapped or clicked',
    userPermitted: true,
  },
  {
    key: ANALYTIC_EMAIL,
    description: 'An email was sent',
  },
  {
    key: ANALYTIC_ERROR,
    description: 'An error happened',
    userPermitted: true,
  },
  {
    key: ANALYTIC_SMS,
    description: 'An sms was sent',
  },
  {
    key: ANALYTIC_PUSH,
    description: 'A push was sent',
  },
  {
    key: ANALYTIC_CONTENT_VIEW,
    description: 'Content impression.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_TOUCH_PIN,
    description: 'Content was pinned.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_TOUCH_LIKE,
    description: 'Content was liked.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_TOUCH_SHARE,
    description: 'Content was shared.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_TOUCH_HIDE,
    description: 'Content was hidden.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_TOUCH_RATE,
    description: 'Content was rated.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_TOUCH_STAR,
    description: 'Content was starred.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_TOUCH_DELETE,
    description: 'Content was shared.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_DETAILS,
    description: 'Content click through.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_NOTIFICATION_SENT,
    description:
      'A notification has been sent for this Content on this channel.',
  },
  {
    key: ANALYTIC_CONTENT_INTERACTION_VIEW,
    description: 'A Content Interaction impression.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_INTERACTION_DETAIL,
    description: 'A Content Interaction click through.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_VIEW,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_DETAILS,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'channel.touch.pin',
    description: 'Channel was pinned.',
    userPermitted: true,
  },
  {
    key: 'channel.touch.like',
    description: 'Channel was liked.',
    userPermitted: true,
  },
  {
    key: 'channel.touch.share',
    description: 'Channel was shared.',
    userPermitted: true,
  },
  {
    key: 'channel.touch.hide',
    description: 'Channel was hidden.',
    userPermitted: true,
  },
  {
    key: 'channel.touch.rate',
    description: 'Channel was rated.',
    userPermitted: true,
  },
  {
    key: 'channel.touch.star',
    description: 'Channel was starred.',
    userPermitted: true,
  },
  {
    key: 'channel.touch.delete',
    userPermitted: true,
    description: 'Channel was shared.',
  },
  {
    key: 'user.view',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'user.details',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'media.view',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'media.details',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'contentTemplate.view',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'contentTemplate.details',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'library.view',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'library.details',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'section.view',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'section.details',
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: 'user.touch.pin',
    description: 'User was pinned.',
  },
  {
    key: 'user.touch.like',
    description: 'User was liked.',
  },
  {
    key: 'user.touch.share',
    description: 'User was shared.',
  },
  {
    key: 'user.touch.hide',
    description: 'User was hidden.',
  },
  {
    key: 'user.touch.rate',
    description: 'User was rated.',
  },
  {
    key: 'user.touch.star',
    description: 'User was starred.',
  },
  {
    key: 'user.touch.delete',
    description: 'User was shared.',
  },
  {
    key: 'integration.view',
    description: 'Integration was viewed.',
    userPermitted: true,
  },
  {
    key: 'integration.details',
    description: 'Integration was detailed.',
    userPermitted: true,
  },
  {
    key: 'channel.integration.view',
    description: 'Channel Integration was viewed.',
    userPermitted: true,
  },
  {
    key: 'channel.integration.details',
    description: 'Channel Integration was detailed.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_SECTION_READ,
    description: 'Section was read.',
  },
  {
    key: ANALYTIC_INTEGRATION_READ,
    description: 'Integration was read.',
  },
  {
    key: ANALYTIC_CHANNEL_INTEGRATION_READ,
    description: 'Channel Integration was read.',
  },
  {
    key: ANALYTIC_LIBRARY_READ,
    description: 'Library was read.',
  },
  {
    key: ANALYTIC_CONTENTTEMPLATE_READ,
    description: 'Content Template was read.',
  },
  {
    key: ANALYTIC_MEDIA_READ,
    description: 'Media was read.',
  },
  {
    key: ANALYTIC_USER_READ,
    description: 'User was read.',
  },
  {
    key: ANALYTIC_CHANNEL_READ,
    description: 'Channel was read.',
  },
  {
    key: ANALYTIC_CONTENT_INTERACTION_READ,
    description: 'Content Interaction was read.',
  },
  {
    key: ANALYTIC_CONTENT_READ,
    description: 'Content was read.',
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_UNLOCKED,
    description: 'A user successfully unlocked a door using access control',
    userPermitted: true,
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_UNLOCKING,
    description: 'A user initiated unlocking using access control',
    userPermitted: true,
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_INPUT_CODE,
    description: 'A user input the activation code',
    userPermitted: true,
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_SAFETRUST_ACCOUNT_ACTIVATED,
    description: 'A user successfully activated a Safetrust account',
    userPermitted: true,
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_HID_ACCOUNT_ACTIVATED,
    description: 'A user successfully activated a HID account',
    userPermitted: true,
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_ACCOUNT_ACTIVATED,
    description: 'A user successfully activated an account',
    userPermitted: true,
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_SUPPORT_REQUEST,
    description: 'A user requested to contact support',
    userPermitted: true,
  },
  {
    key: ANALYTIC_ACCESS_CONTROL_OPEN_PANEL,
    description: 'A user opened the access control panel',
    userPermitted: true,
  },
  {
    key: ANALYTIC_QRCODE_SCANNED,
    description: 'A QRCode was scanned by the web or mobile app',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CONTENT_DETAILS_SHOPIFY_MULTIPASS_SECTION,
    description:
      'A user is viewing shopify multipass content from a section block',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETED,
    description: 'A user deleted a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETE_FAILED,
    description: 'Failure in deleting a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGED,
    description:
      'A user changed the logo of a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGE_FAILED,
    description:
      'Failure in changing the logo of a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGED,
    description:
      'A user changed the type of channels via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGE_FAILED,
    description:
      'Failure in changing the type of a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGED,
    description:
      'A user changed the parent channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGE_FAILED,
    description:
      'Failure in changing the parent channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LINK_CLICK,
    description: 'A user clicked on a channel link via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_LINK_CLICK,
    description:
      'A user clicked on a parent channel link via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_PAGE_VISITED,
    description: 'A user visited the channel management tool page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_SEARCH_CHANNELS_PAGE_VISITED,
    description: 'A user visited the search channels page in portal',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNCED,
    description: 'A user synced a join rule via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNC_FAILED,
    description: 'Failure in syncing a join rule via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETED,
    description: 'A user deleted a child channel via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETE_FAILED,
    description: 'Failure in deleting a channel via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETED,
    description: 'A user deleted a child channel via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETE_FAILED,
    description: 'Failure in deleting a child channel via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_MANAGEMENT_TABLE_PAGE_NAVIGATED,
    description: 'A user navigated to a new table page in channel management',
    userPermitted: true,
  },
  {
    key: HOST_BUILDING_AUTO_SETUP_WIZARD_OPENED,
    description: 'A user opened the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_SELECTED,
    description:
      'A user selected a source for the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_LOCATION_SELECTED,
    description:
      'A user selected the source locations for the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: HOST_BUILDING_AUTO_SETUP_WIZARD_TEMPLATE_SELECTED,
    description:
      'A user selected a template for the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: HOST_BUILDING_AUTO_SETUP_WIZARD_CONFIRMED,
    description:
      'A user clicked Confirm to save the Host Building auto setup settings',
    userPermitted: true,
  },
  {
    key: HOST_BUILDING_AUTO_SETUP_WIZARD_SAVED_SUCCESSFULLY,
    description:
      'The settings for Host Building auto setup were saved successfully',
    userPermitted: true,
  },
  {
    key: HOST_BUILDING_AUTO_SETUP_WIZARD_SAVE_FAILED,
    description:
      'The settings for Host Building auto setup were not saved successfully',
    userPermitted: true,
  },
  {
    key: ANALYTIC_CHANNEL_PROFILE_LOGO_CHANGE_ATTEMPTED,
    description:
      'A user attempted to change the channel logo via channel profile settings',
    userPermitted: true,
  },
];

export default analytics;
