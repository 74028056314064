import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';

const ShopifyRsvpIntegrationDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.ShopifyRsvp,
  hasCustomUI: true,
  friendlyName: 'Shopify Rsvp',
  description:
    'A Shopify Rsvp is an event for select Shopify teams and members.',
  category: IntegrationCategoryEnum.Custom,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Custom,
  allowsMultipleInstances: false,
  settings: {},
  properties: {},
  interactionData: {
    // track what shopify burst content this RSVP is related to
    shopifyBurstContentId: {
      name: 'shopifyBurstContentId',
      friendlyName: 'Shopify Burst Content Id',
      description: 'Which Shopify Burst Content is this Shopify RSVP for',
      hidden: true,
      type: 'Content',
      secure: {
        // this is only editable by the system. i.e. end user can't overwrite
        // this data.
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    // track what specific shopify burst this RSVP is related to, this would
    // be a UserContentInteraction on the ShopifyBurst related content above
    shopifyBurstInteractionId: {
      name: 'shopifyBurstInteractionId',
      friendlyName: 'Shopify Burst Interaction Id',
      description:
        'Which Shopify Burst Content Interaction is this Shopify RSVP for',
      hidden: true,
      type: 'UserContentInteraction',
      secure: {
        // this is only editable by the system. i.e. end user can't overwrite
        // this data.
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    isAttending: {
      name: 'isAttending',
      friendlyName: 'Is attending the burst.',
      description: 'Will you be attending this burst in person?',
      type: 'Boolean',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    specialRequests: {
      name: 'specialRequests',
      friendlyName: 'Special Requests',
      description: 'Special Requests',
      type: 'LongText',
      validators: [
        {
          name: 'Max',
          value: 400,
        },
      ],
    },
    isComplyRegulations: {
      name: 'isComplyRegulations',
      friendlyName: 'Is proof of vaccination uploaded?',
      description: 'Is proof of vaccination uploaded?',
      type: 'Boolean',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  mobilePermissions: [],
};

export default ShopifyRsvpIntegrationDefinition;
