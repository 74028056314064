import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

const secureIdQuery = gql`
  query UserSecureId {
    me {
      secureId
    }
  }
`;

export default function useMySecureId() {
  const { data, error, loading } = useQuery(secureIdQuery, {
    fetchPolicy: 'network-only',
    pollInterval: 90 * 1000, // 90 seconds
  });

  const secureId = data?.me?.secureId;

  return {
    secureId,
    error,
    loading,
  };
}
