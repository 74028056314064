// set a default message to prevent yups ugly nullable message
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    notType: '${path} is required.',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ notType: string; typeError: string; }' is ... Remove this comment to see the full error message
    typeError: '${path} is required.',
  },
});
