import { INTERACTION_STATES } from '../../../helpers/constants/interactions';
import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import StatusFeatureRule from './types/StatusFeatureRule';

const Statuses: FeatureDefinition = {
  name: FeatureNameEnum.Statuses,
  friendlyName: 'web.content.features.statuses.friendlyName',
  requiresInteraction: true,
  description: 'web.content.features.statuses.description',
  properties: {
    rules: {
      friendlyName:
        'web.content.features.statuses.properties.rules.friendlyName',
      type: 'StatusFeatureRule',
      isArray: true,
      description: 'web.content.features.statuses.properties.rules.description',
      default: [StatusFeatureRule.default],
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMax',
          value: INTERACTION_STATES.length,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
      ],
    },
  },
};

export default Statuses;
