import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { CreditCardType } from 'lane-shared/types/Stripe';

import { Loading } from 'components/general';
import Alert, { AlertType } from 'components/lds/Alert';

import { ManagePaymentAccountProps } from './ManagePaymentAccountProps';
import StripeConfigureButton from './StripeConfigureButton';

import styles from './ManageStripeAccounts.scss';

export default function ManageStripeAccounts({
  paymentMethods,
  selectedPaymentMethod,
  onConfigure,
}: ManagePaymentAccountProps<CreditCardType>) {
  const { t } = useTranslation();

  if (!paymentMethods) {
    return <Loading />;
  }

  if (paymentMethods.length === 0) {
    return (
      <div className={cx(styles.manageStripeAccounts)}>
        {onConfigure ? (
          <StripeConfigureButton onConfigure={onConfigure} />
        ) : (
          <Alert type={AlertType.warning} fullWidth>
            {t('This Stripe account is not configured.')}
          </Alert>
        )}
      </div>
    );
  }

  // onConfigure --> conditional renders Alert
  return selectedPaymentMethod && onConfigure ? (
    <StripeConfigureButton
      onConfigure={onConfigure}
      selectedPaymentMethod={selectedPaymentMethod}
    />
  ) : (
    <Alert type={AlertType.warning} fullWidth>
      {t('This Stripe account is not configured.')}
    </Alert>
  );
}
