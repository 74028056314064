import { distance } from 'lane-shared/helpers';
import friendlyDistance, {
  KM_TO_MILES_CONVERSATION_RATIO,
  DISTANCE_UNIT_KM,
  DISTANCE_UNIT_MILE,
} from 'lane-shared/helpers/formatters/friendlyDistance';

import getUserLocale from './getUserLocale';

export default function getDistanceFromLocation({
  latitude,
  longitude,
  location,
}: {
  latitude: number;
  longitude: number;
  location: {
    latitude: number;
    longitude: number;
  };
}) {
  const meters = distance(
    location.latitude,
    location.longitude,
    latitude,
    longitude
  );

  // If user locale is US, return miles, else return kms.
  if (getUserLocale().includes('-US')) {
    return friendlyDistance(
      meters * KM_TO_MILES_CONVERSATION_RATIO,
      DISTANCE_UNIT_MILE
    );
  }

  return friendlyDistance(meters, DISTANCE_UNIT_KM);
}
