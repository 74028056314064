import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';

export function ButtonUndoGroup({ isLoading, isDisabled, onUndo }: any) {
  const { t } = useTranslation();

  return (
    <Button loading={isLoading} disabled={isDisabled} onClick={onUndo}>
      {t('web.components.lane.Team.Header.ButtonUndoGroup')}
    </Button>
  );
}
