import React, {
  useEffect,
  useState,
  useRef,
  SetStateAction,
  Dispatch,
} from 'react';

import { useDebounce } from 'use-debounce';

import Storage from '../helpers/Storage';

// save to cache every 1/4 second.
const cacheTimeout = 250;

type StoredStateReturn<S> = Readonly<[S, Dispatch<SetStateAction<S>>, boolean]>;

/**
 * A helpful hook to store a components local state.  This is
 * useful to save a components state between reloads of the app (i.e.
 * window positions). Or even sort states (for tables, etc.)
 */
export default function useStoredState<S>(
  key: string,
  defaultState: S,
  {
    disableStorage,
    parser = value => value,
  }: {
    disableStorage?: boolean;
    // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
    parser?: (any) => S;
  } = {
    parser: value => value,
  }
): StoredStateReturn<S> {
  const [internalKey, setInternalKey] = useState(key);
  const [state, _setState] = useState<S>(defaultState);
  const [debouncedState] = useDebounce(state, cacheTimeout);
  const [isReady, setIsReady] = useState(Boolean(disableStorage));
  const statusRef = useRef<{
    hasBeenRestored: boolean;
    hasBeenPersisted: boolean;
    defaultState: S | null;
  }>({
    hasBeenRestored: false,
    hasBeenPersisted: false,
    defaultState: null,
  });

  useEffect(() => {
    // store the mounting default state, since default start the variable
    // will actually get passed in on each render.
    statusRef.current.defaultState = defaultState;
  }, []);

  const setState: React.Dispatch<React.SetStateAction<S>> = newState => {
    _setState(newState);
  };

  async function persistToStorage() {
    if (!internalKey) {
      return;
    }

    if (!isReady) {
      return;
    }

    // don't try to persist the default state, but only if it is an object.
    // this is because a stored state that is a string, number or boolean
    // a new state may equate to the original defaultState which is ok
    if (
      typeof statusRef.current.defaultState === 'object' &&
      statusRef.current.defaultState === state
    ) {
      return;
    }

    try {
      await Storage.setItem(`useStoredState${internalKey}`, state);
      statusRef.current.hasBeenPersisted = true;
    } catch (err) {
      // oh well we tried.
    }
  }

  async function hydrateFromStorage() {
    if (!internalKey) {
      return;
    }

    // this will only happen once in the lifecycle of the component.
    // use a ref so we don't have to worry about callbacks.
    statusRef.current.hasBeenRestored = false;
    setIsReady(false);

    try {
      const storedState = await Storage.getItem(`useStoredState${internalKey}`);

      _setState(parser(storedState));
    } catch (err) {
      // silent fail is okay here.
    }

    statusRef.current.hasBeenRestored = true;
    setTimeout(() => setIsReady(true), 1);
  }

  useEffect(() => {
    if (!disableStorage) {
      persistToStorage();
    }
  }, [key, debouncedState, isReady, disableStorage]);

  useEffect(() => {
    if (!disableStorage) {
      hydrateFromStorage();
    }
  }, [internalKey, disableStorage]);

  // updating storage key, so that all hooks/functions are safe to be switched to a new key
  useEffect(() => {
    // reseting state to the default value in order to prevent returning the existing state
    // for a new key, that is not yet stored
    _setState(defaultState);

    setInternalKey(key);
  }, [key]);

  return [state, setState, isReady] as const;
}
