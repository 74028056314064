import React from 'react';

import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';

export function TogglePublicTeam({ isPublic, updateGroupRole }: any) {
  const { t } = useTranslation();

  return (
    <fieldset>
      <Toggle
        text={t('web.components.lane.Team.TogglePublicTeam.toggleText')}
        value={isPublic}
        onChange={() => updateGroupRole({ isPublic: !isPublic })}
      />
    </fieldset>
  );
}
