import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getNextResetPeriod,
  getLastResetPeriod,
} from 'lane-shared/helpers/content';
import {
  explodeFeatures,
  getResetPeriodFormattedDate,
} from 'lane-shared/helpers/features';
import useAdminContentInfo from 'lane-shared/hooks/useAdminContentInfo';
import { TimeResetFrequencyEnum } from 'lane-shared/renderers/v5/features/Reset';

import IconButton from '../general/IconButton';

import styles from './ContentFeatureTimeResetPager.scss';

type Period = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
  startDate: Date | null;
  endDate: Date | null;
  loading?: boolean;
  disabled?: boolean;
  onPage: (period: Period) => void;
};

export default function ContentFeatureTimeResetPager({
  className,
  style,
  content,
  startDate,
  endDate,
  loading = false,
  disabled = false,
  onPage,
}: Props) {
  const { t } = useTranslation();
  const { resetFeature } = explodeFeatures(content?.features);
  const { defaultStartDate } = useAdminContentInfo({ content });

  // no pager needed for a never reset period.
  if (!content || resetFeature?.period === TimeResetFrequencyEnum.Never) {
    return null;
  }

  if (!startDate) {
    startDate = new Date(defaultStartDate.toString());

    const durationSymbol = resetFeature.period;
    const nextResetPeriod: Period = getNextResetPeriod({
      nextReset: startDate,
      period: durationSymbol,
    });

    endDate = nextResetPeriod.endDate ?? null;
  }

  function nextPage() {
    onPage(
      getNextResetPeriod({
        nextReset: endDate,
        period: resetFeature.period,
      })
    );
  }

  function previousPage() {
    onPage(
      getLastResetPeriod({
        lastReset: startDate,
        period: resetFeature.period,
      })
    );
  }

  return (
    <div
      className={cx(styles.ContentFeatureTimeResetPager, className)}
      style={style}
    >
      <IconButton
        loading={loading}
        disabled={disabled}
        className={styles.button}
        onClick={previousPage}
        icon="chevron-left"
        inverted
      />
      <h1>
        <span>
          {getResetPeriodFormattedDate({
            period: resetFeature.period,
            date: startDate,
          })}
        </span>
        {t('to')}
        <span>
          {getResetPeriodFormattedDate({
            period: resetFeature.period,
            date: endDate,
          })}
        </span>
      </h1>
      <IconButton
        loading={loading}
        disabled={disabled}
        className={styles.button}
        onClick={nextPage}
        icon="chevron-right"
        inverted
      />
    </div>
  );
}
