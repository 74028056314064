import { DateTime } from 'luxon';

type ValueType = string | number | Date | DateTime | undefined | null;

const TERMINABLE_VALUES: ValueType[] = [undefined, null];

/**
 * Parses a given value to a Date object.
 */
export default function parseDate(value: ValueType): Date | null {
  if (TERMINABLE_VALUES.includes(value)) {
    return null;
  }

  if (typeof value === 'string') {
    return new Date(value);
  }
  if (typeof value === 'number') {
    return new Date(value);
  }
  if (value?.constructor === Date) {
    return value;
  }
  if (DateTime.isDateTime(value)) {
    return value.toJSDate();
  }
  if (value instanceof Date) {
    return value;
  }

  return null;
}
