import { request, Res } from './common';

export interface LoginReqData {
  email: string;
  email_access_code?: string;
  password: string;
}

export interface LoginResData {
  email: string;
  id: string;
  password: string;
  token: string;
}

export interface LoginResDataOk {
  isError: false;
  token: string;
  email: string;
  id: string;
}

export interface LoginResDataError {
  isError: true;
  password: string;
}

export async function login(data: LoginReqData): Promise<Res<LoginResData>> {
  return request(
    { token: null, method: 'post', path: '/auth/login', data },
    { raiseOnError: false }
  );
}
