import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

export class EmailType extends StringTypeBase {
  group = TYPE_GROUP_TEXT;

  name = 'Email';

  friendlyName = 'Email';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  get example() {
    const examples = [
      'lane.doe@joinlane.com',
      'rmaccio@joinlane.com',
      'example@joinlane.com',
    ];

    return examples[Math.floor(Math.random() * examples.length)];
  }

  get default(): any {
    return '';
  }

  buildSchema() {
    return yup.string().email('Must be a valid email address.').nullable();
  }
}

const definition = new EmailType();

Types.addBaseType(definition);
export default definition;
