import useFlag from './useFlag';

const FLAG = 'flex.reservable-waitlist';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-05-03T15:46:07+0530.
 */
export function useReservableWaitlist() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
