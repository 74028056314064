export type TokenResponse = {
  access_token: string;
  expires_in: number;
  ext_expires_in: number;
  id_token: string;
  refresh_token: string;
  scope?: string;
  token_type: string;
};

export async function getAccessToken(
  tokenUrl: string,
  urlencoded: URLSearchParams
) {
  const response = await fetch(tokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    },
    body: urlencoded,
    redirect: 'follow',
  });

  const data: TokenResponse = await response.json();

  return data;
}
