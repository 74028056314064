import React from 'react';

import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

import colors from '../colors';

type OwnProps = {
  height?: string | number;
  width?: string | number;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  data: any[];
  lines: any[];
  xAxis?: any;
  yAxis?: any;
  dot?: boolean;
  lineChartProps?: any;
  lineType?:
    | 'basis'
    | 'basisClosed'
    | 'basisOpen'
    | 'linear'
    | 'linearClosed'
    | 'natural'
    | 'monotoneX'
    | 'monotoneY'
    | 'monotone'
    | 'step'
    | 'stepBefore'
    | 'stepAfter';
  legend?: 'top' | 'middle' | 'bottom';
  tooltipProps?: any;
  legendProps?: any;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LineGraph.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'LineGraph' implicitly has type 'any' because it d... Remove this comment to see the full error message
const LineGraph = ({
  height,
  width,
  margin,
  lines,
  data,
  xAxis,
  yAxis,
  legend,
  lineType,
  tooltipProps,
  legendProps,
  dot,
  lineChartProps,
}: Props) => {
  const Lines: any = [];
  lines.forEach((a: any, i: any) => {
    const color = colors[i];
    Lines.push(
      <Line
        key={`Line_components_for_${a.dataKey}`}
        type={lineType}
        name={a.label}
        dataKey={a.dataKey}
        stroke={color.base}
        dot={dot}
      />
    );
  });
  return (
    <ResponsiveContainer width={width} height={height}>
      <LineChart data={data} margin={margin} {...lineChartProps}>
        <XAxis {...xAxis} />
        <YAxis {...yAxis} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip {...tooltipProps} />
        {legend && (
          <Legend verticalAlign={legend} height={36} {...legendProps} />
        )}
        {Lines}
      </LineChart>
    </ResponsiveContainer>
  );
};

LineGraph.defaultProps = {
  height: 250,
  width: '100%',
  margin: {
    top: 10,
    right: 30,
    left: 0,
    bottom: 0,
  },
  xAxis: {},
  yAxis: {},
  legend: undefined,
  dot: false,
  lineType: 'natural',
  tooltipProps: {},
  legendProps: {},
  lineChartProps: {},
};

export default LineGraph;
