import gql from 'graphql-tag';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { getClient } from 'lane-shared/apollo';
import {
  EssensysOrganizationType,
  PaymentProcessorEnum,
} from 'lane-shared/types/Essensys';

const ESSENSYS_ORGANIZATION = gql`
  query essensysOrganization(
    $locationId: ID!
    $clientTypeId: ID
    $clientTypeName: String
  ) {
    essensys {
      organizations(
        locationId: $locationId
        clientTypeId: $clientTypeId
        clientTypeName: $clientTypeName
      )
    }
  }
`;

type EssensysLocationResult = {
  // NOTE: Partial as some returned fields are removed for security
  essensys: { organizations: Partial<EssensysOrganizationType>[] };
};

type EssensysLocationVariables = {
  locationId: string;
  clientTypeId?: string;
  clientTypeName?: string;
};

export default async function getEssensysOrganization(
  locationId: string,
  clientTypeId?: string,
  clientTypeName?: string,
  Sentry?: { captureException: (...args: any[]) => void }
): Promise<Partial<EssensysOrganizationType>> {
  const result = await getClient().query<
    EssensysLocationResult,
    EssensysLocationVariables
  >({
    query: ESSENSYS_ORGANIZATION,
    variables: {
      locationId,
      clientTypeId,
      clientTypeName,
    },
    fetchPolicy: 'network-only',
  });

  if (result.data.essensys.organizations.length !== 1) {
    const additionalDetail =
      result.data.essensys.organizations.length === 0
        ? 'No "organizations" found.'
        : 'Too many "organizations" found.';
    throw new Error(
      `We are unable to continue this transaction. ${additionalDetail}`
    );
  }

  const organization = result.data.essensys.organizations[0];

  const organizationShape = yup.object().shape({
    orgpayeeid: yup.string().when('paymentprocessor', {
      is: paymentprocessor =>
        paymentprocessor === PaymentProcessorEnum.PlacePay,
      then: yup.string().trim().required(),
    }),
    paymentprocessor: yup.string().trim().required().notOneOf(['null']),
  });

  try {
    await organizationShape.validate(organization, { abortEarly: false });
  } catch (err) {
    const errorId = uuid();
    if (Sentry?.captureException) {
      Sentry.captureException(err, {
        extra: {
          errorId,
          organization,
        },
      });
    }
    throw new Error(
      `The "organization" is not setup correctly in Operate, please contact an administrator. EID: ${errorId}`
    );
  }

  return organization;
}
