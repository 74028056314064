import React from 'react';

import { PLACEPAY_IMAGE_LINK } from 'lane-shared/helpers/constants/logos/placePay';

export default function PlacePayLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="87"
      height="39"
      fill="none"
      viewBox="0 0 87 39"
    >
      <path fill="url(#pattern0)" d="M0 0H87V39H0z" />
      <defs>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use
            transform="matrix(.00498 0 0 .01111 -.123 0)"
            xlinkHref="#image0_1895_14174"
          />
        </pattern>
        <image
          id="image0_1895_14174"
          width="250"
          height="90"
          xlinkHref={PLACEPAY_IMAGE_LINK}
        />
      </defs>
    </svg>
  );
}
