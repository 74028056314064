import React from 'react';

import { Icon } from 'design-system-web';

import styles from './InformationAlert.scss';

const InformationAlert = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.informationAlert}>
      <div>
        <Icon name="info-circle" className={styles.icon} size="medium" />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default InformationAlert;
