import React, { useMemo } from 'react';

import objectToArray from 'lane-shared/helpers/objectToArray';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import PropertiesDataTable from 'components/builder/properties/display/PropertiesDataTable';

type Props = {
  datasetSchema: {
    datasetRecords: {
      items: any[];
    };
    properties: PropertiesInterface;
  };
};

type DatasetRecordValueWithId = {
  _id: string;
  [key: string]: string;
};

/**
 * A table for displaying the DatasetSchema properties and it's related DatasetRecords
 */
export default function DatasetSchemaTable({ datasetSchema }: Props) {
  const datasetRecordValues = useMemo<DatasetRecordValueWithId[]>(() => {
    return datasetSchema
      ? datasetSchema.datasetRecords.items.map(record => ({
          ...record.value,
          _id: record._id,
        }))
      : [];
  }, [datasetSchema?.datasetRecords]);

  const datasetProperties = useMemo(() => {
    return objectToArray(datasetSchema?.properties).map(propName => ({
      ...propName,
      _id: propName.name,
      name: propName.name,
    }));
  }, [datasetSchema]);

  return (
    <PropertiesDataTable
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string | undefined; name: string | un... Remove this comment to see the full error message
      fields={datasetProperties}
      items={datasetRecordValues}
    />
  );
}
