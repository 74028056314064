import {
  MenuFeatureItemType,
  MenuFeatureOrderItemType,
} from '../../types/features/MenuFeatureTypes';

export default function getMenuFeatureItemConfiguredPrice(
  item: MenuFeatureItemType,
  orderItem: MenuFeatureOrderItemType
): number {
  if (!item) {
    return 0;
  }

  // this is the base price
  const price = parseInt(String(item.price).valueOf(), 10) || 0;

  // find all the selected options and sum their prices
  const optionPrice = orderItem.options.reduce(
    (price, orderOptionList) =>
      price +
      item.options
        .find(optionList => optionList._id === orderOptionList._id)!
        .options.filter(option => orderOptionList.options.includes(option._id))
        .reduce(
          (price, option) =>
            price + parseInt(String(option.price).valueOf(), 10),
          0
        ),
    0
  );

  return price + optionPrice;
}
