import React from 'react';
import { useQuery } from '@apollo/client';
import { GroupRole } from 'graphql-query-contracts';
import { MultiselectField } from 'components';
import { H5 } from 'components/typography';
import CardContainer from 'components/cards/CardContainer';
import styles from './styles.scss';
import { useTranslation } from 'react-i18next';

import { getChannelGroupRoles } from 'lane-shared/graphql/query';

type Props = {
  channelId: string;
  groupRoles: { label: string; value: string }[];
  onChange: (items: any[]) => void;
  defaultSelectionGroupRoleNames: string[];
  errorMessage: string | null;
};

export const TeamMultiSelect = ({
  channelId,
  groupRoles,
  onChange,
  errorMessage,
  defaultSelectionGroupRoleNames = [],
}: Props) => {
  const { t } = useTranslation();

  const {
    data,
    loading,
  }: {
    data?: {
      channel: {
        _id: string;
        _created: any;
        groupRoles: GroupRole[];
      };
    };
    loading: boolean;
  } = useQuery(getChannelGroupRoles, {
    variables: {
      id: channelId,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const defaultGroupRoles = data.channel.groupRoles.filter(x =>
        defaultSelectionGroupRoleNames.includes(x.name)
      );
      // Set initial values based on passed defaults
      onChange(defaultGroupRoles.map(mapGroupRoleToOption));
    },
  });

  const groupRoleOptions = data?.channel?.groupRoles.map(mapGroupRoleToOption);

  return (
    <CardContainer className={styles.bulkSettingsInnerCard}>
      <div className={styles.teamMultiSelect}>
        <H5>
          {t(
            'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.teamMultiSelect.header'
          )}
        </H5>
        <MultiselectField
          label={t(
            'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.teamMultiSelect.label'
          )}
          fixedLabel
          isRequired
          isFullWidth
          value={groupRoles}
          items={groupRoleOptions}
          onChange={value => onChange(value)}
          doTranslation={false}
          dataCy="bulkInviteTeamMultiSelect"
          errors={errorMessage ? [errorMessage] : null}
          isLoading={loading}
          truncateSelectedItems
        />
      </div>
    </CardContainer>
  );
};

const mapGroupRoleToOption = (
  groupRole: GroupRole
): { label: string; value: string } => {
  return {
    label: groupRole.name,
    value: groupRole._id,
  };
};
