import React, { useRef } from 'react';

import Skeleton from 'react-loading-skeleton';

import { spacing } from 'lane-shared/config';

import { Flex } from 'components/layout';

type Props = {
  count?: number;
};

/**
 * A skeleton loader component for the StandarcContentCard
 */
export default function StandardContentCardSkeleton({ count = 1 }: Props) {
  const numberOfCards = useRef(Array.from(Array(count).keys())).current;

  return (
    <>
      {numberOfCards.map(cardNumber => (
        <div
          key={cardNumber}
          style={{
            width: '19em',
            height: '21em',
            borderRadius: 16,
            boxShadow: '0 0 8px 0 rgba(15, 18, 31, 0.08)',
          }}
        >
          <Skeleton
            style={{
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              width: '19em',
              height: '10.69em',
              marginBottom: spacing[5],
            }}
          />
          <Flex direction="column">
            <Skeleton
              style={{
                width: 100,
                height: 20,
                marginLeft: spacing[3],
                marginBottom: spacing[3],
              }}
            />
            <Skeleton
              style={{
                width: 60,
                height: 10,
                marginLeft: spacing[3],
              }}
            />
          </Flex>
        </div>
      ))}
    </>
  );
}
