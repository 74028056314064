import { getClient } from 'lane-shared/apollo';
import {
  createChannelModule,
  deleteChannelModule,
} from 'lane-shared/graphql/mutation';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ModuleDefinition } from 'lane-shared/types/modules/moduleDefinition';
import { ModuleCategoryEnum } from 'lane-shared/types/modules/modulesEnums';

import {
  defaultCategories,
  defaultLocations,
} from './constants/defaultEquipmentSettings';
import { workOrder as defaultWorkOrderModule } from './constants/defaultWorkOrderModule';

import { constructDefaultWorkflowsForTaskNotifications } from 'lane-shared/domains/workOrder/emails/helpers/constructDefaultWorkflowNotifications';

import { updateEquipmentSettingsOnChannel } from 'graphql-queries';
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflowsByChannelAndType,
} from 'lane-shared/graphql/workflow';
import { UserType } from 'lane-shared/types/User';
import { Workflow, WorkflowTypeEnum } from 'lane-shared/types/workflow';

export default async function onWorkOrderModuleUpdated(
  hasWorkOrderEnabled: boolean,
  channelModules: ModuleDefinition[] | any,
  channel?: any,
  channelId?: string,
  hasWorkOrderServiceRequestsEnabled?: boolean,
  hasWorkOrderEquipmentEnabled?: boolean,
  hasWorkOrderPreventiveMaintenanceEnabled?: boolean,
  isWorkplaceEnablementEnabled?: boolean,
  user?: UserType | null
) {
  // TODO: Delete this hasWorkOrderEnabled logic TM-21661
  if (hasWorkOrderEnabled && !isWorkplaceEnablementEnabled) {
    await getClient().mutate({
      mutation: createChannelModule,
      variables: {
        channelModule: {
          ...defaultWorkOrderModule,
          channelId: convertToUUID(channelId),
        },
        channel,
      },
    });
    await getClient().mutate({
      mutation: updateEquipmentSettingsOnChannel,
      variables: {
        settings: {
          channelId: convertToUUID(channelId),
          categories: defaultCategories,
          locations: defaultLocations,
        },
      },
    });
  } else if (
    isWorkplaceEnablementEnabled &&
    (hasWorkOrderServiceRequestsEnabled || hasWorkOrderEquipmentEnabled)
  ) {
    if (
      hasWorkOrderServiceRequestsEnabled &&
      !channel.settings.hasWorkOrderServiceRequestsEnabled
      // only create the channel module if service requests has just been enabled
    ) {
      await getClient().mutate({
        mutation: createChannelModule,
        variables: {
          channelModule: {
            ...defaultWorkOrderModule,
            channelId: convertToUUID(channelId),
          },
          channel,
        },
      });
    }
    if (
      hasWorkOrderEquipmentEnabled &&
      !channel.settings.hasWorkOrderEquipmentEnabled
    ) {
      await getClient().mutate({
        mutation: updateEquipmentSettingsOnChannel,
        variables: {
          settings: {
            channelId: convertToUUID(channelId),
            categories: defaultCategories,
            locations: defaultLocations,
          },
        },
      });
    }
  } else {
    const workorder = channelModules?.find(
      (element: any) => element.name === ModuleCategoryEnum.WorkOrders
    );
    if (workorder) {
      await getClient().mutate({
        mutation: deleteChannelModule,
        variables: {
          _id: workorder._id,
        },
      });
    }
  }

  const workflows = await getClient().query({
    query: getWorkflowsByChannelAndType,
    variables: {
      channelId: convertToUUID(channelId),
      type: WorkflowTypeEnum.Task,
    },
  });
  const hasTaskWorkflows =
    workflows.data?.getWorkflowsByChannelAndType.length > 0;

  if (hasWorkOrderPreventiveMaintenanceEnabled && !hasTaskWorkflows) {
    const workflows = constructDefaultWorkflowsForTaskNotifications({
      channelId: convertToUUID(channelId),
      userId: convertToUUID(user?._id),
    }).map(workflow => ({
      createdBy: workflow._createdBy,
      updatedBy: workflow._updatedBy,
      event: workflow.event,
      name: workflow.name,
      when: workflow.when,
      whenContext: workflow.whenContext,
      inStatus: workflow.inStatus,
      time: workflow.time,
      order: workflow._order,
      action: workflow.action,
      type: workflow.type,
      target: workflow.target,
      targetType: workflow.targetType,
      channelId: convertToUUID(channelId),
      payload: workflow.payload,
      workflow: workflow.workflow,
      dataValidationSchema: workflow.dataValidationSchema,
    }));
    workflows.forEach(workflow => {
      getClient().mutate({
        mutation: createWorkflow,
        variables: {
          workflow: {
            ...workflow,
            channelId: convertToUUID(channelId),
          },
        },
      });
    });
  } else if (!hasWorkOrderPreventiveMaintenanceEnabled && hasTaskWorkflows) {
    workflows.data.getWorkflowsByChannelAndType.forEach(
      async (workflow: Workflow) => {
        await getClient().mutate({
          mutation: deleteWorkflow,
          variables: {
            workflowId: convertToUUID(workflow._id),
          },
        });
      }
    );
  }
}
