import gql from 'graphql-tag';

import { getClient } from '../apollo';
import pause from './pause';

export default async function doObjectTouchAction(obj: any, action: any) {
  if (!obj?._id || !action) {
    return;
  }

  await pause(250);
  await getClient().mutate({
    mutation: gql(`
        mutation ObjectTouch($contentId: UUID!, $${action}: Boolean) {
          touchContent(_id: $contentId, touch: { ${action}: $${action}}) {
            _id
            info {
              _id
              ${action}
            }
          }
        }
      `),
    variables: {
      contentId: obj._id,
      // if something is set already (i.e. a like) reverse it
      [action]: !obj.info?.[action],
    },
  });
}
