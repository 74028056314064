import React from 'react';

import cx from 'classnames';

import styles from './Line.scss';

type Props = {
  className?: string;
};

export default function Line({ className }: Props) {
  return <div className={cx(styles.line, className)} />;
}
