import { Primitive } from '../../../types/blocks/Primitive';

const MapBlock: Primitive = {
  name: 'MapBlock',
  friendlyName: 'Map',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '"Address"' is not assignable to type 'Tag'.
  tags: ['Address'],
  properties: {
    title: {
      type: 'String',
    },
    marker: {
      type: 'Location',
    },
    location: {
      type: 'Location',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    zoom: {
      type: 'Number',
      validators: [
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: 22,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default MapBlock;
