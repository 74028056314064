import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useContentTemplateQuery } from 'lane-shared/hooks';
import { ThemeType } from 'lane-shared/types/Theme';

import ContentRenderer from 'components/lane/ContentRenderer';
import Typography from 'components/lds/Typography';
import EmptyTemplatePreview from 'components/misc/EmptyTemplatePreview';

import styles from './TemplatePreview.scss';

type Props = {
  templateId?: string;
  isMobilePreview?: boolean;
  theme: ThemeType;
};

export default function TemplatePreview({
  templateId,
  isMobilePreview,
  theme,
}: Props) {
  const { t } = useTranslation();
  const { template, loading } = useContentTemplateQuery({ templateId });
  const [loadedTemplate, setLoadedTemplate] = useState(null);

  useEffect(() => {
    if (!loading) {
      // only change the template preview when the new one is done loading
      // this prevents an unpleasant flash in between template selections
      setLoadedTemplate(template);
    }
  }, [loading]);

  useEffect(() => {
    setLoadedTemplate(templateId ? template : null);
  }, [templateId, template?._id]);

  if (!loadedTemplate) {
    return (
      <div className={styles.emptyPreview}>
        <EmptyTemplatePreview />
        <Typography type="m" mode="secondary">
          {t('web.content.templatePickerModal.templatePreview.getStarted')}
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.preview} data-is-mobile-preview={isMobilePreview}>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'forAdmin' is missing in type '{ content:... Remove this comment to see the full error message */}
      <ContentRenderer content={{ ...loadedTemplate, theme }} />
    </div>
  );
}
