import gql from 'graphql-tag';

import {
  PublicUserFragment,
  ChannelSettingsRequirementsFragment,
  ContentFragment,
} from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ChannelSettingsRequirementsFragment}
  ${ContentFragment}
  query getWorkOrderContent($channelId: UUID!) {
    workOrderContent(channelId: $channelId) {
      items {
        ...ContentFragment
        _id
        _created
        _updated
        name
        description
        subtitle
        slug
        type
        tags
        category
        reportingGroup
        logo
        color
        geo
        isInteractive
        backgroundImage
        icon
        iconSet
        iconWeight
        backgroundColor
        liveDate
        startDate
        endDate
        unpublishDate
        data
        interactionEndDate
        state
        version
        renderer
        block
        features {
          _id
          type
          feature
        }
        isSyndicateable
        resetPeriod
        sectionContent {
          section {
            name
          }
        }
        card {
          _id
        }
        externalUrl {
          _id
          url
          openingMode
        }
        deepLink {
          _id
          deepLinkUrl
          appStoreUrl
          googlePlayStoreUrl
        }

        publishedBy {
          ...PublicUserFragment
        }
        channel {
          _id
          settings {
            requirements {
              ...ChannelSettingsRequirementsFragment
            }
            effectiveParentRequirements {
              ...ChannelSettingsRequirementsFragment
            }
            channelLanguages
            multiLanguageEnabled
            enabledLanguagesForMembers
          }
        }
        placements {
          _id
          _created
          _updated
          _createdBy {
            ...PublicUserFragment
          }
          _updatedBy {
            ...PublicUserFragment
          }
          type
          channel {
            _id
          }
          groupRole {
            _id
          }
          user {
            _id
          }
        }
      }
    }
  }
`;
