// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'get-... Remove this comment to see the full error message
import getUserLocale from 'get-user-locale';

/**
 * This method is a passthru just to keep helpers consistent between
 * web and mobile.
 */
export default function getUserLocalePassthru() {
  const locale = getUserLocale();

  // just incase it returns a en_CA instead of an en-CA
  return locale.replace('_', '-');
}
