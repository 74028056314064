import i18n from 'localization';

import { SupportedSchema, TypeBase } from '../../types/baseTypes/TypeBase';
import { RequiredValidator } from './Required';

export function addRequiredValidatorImpl<T extends SupportedSchema>(
  type: TypeBase<T>,
  validator: RequiredValidator
) {
  if (validator.config?.value) {
    type._schema = type.schema.required(
      i18n.t('{{- field}} is required.', {
        field: i18n.t(type.getDisplayName()),
      })
    ) as T;
  } else {
    type._schema = type.schema.notRequired() as T;
  }
}
