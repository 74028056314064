import { ChannelType } from 'lane-shared/types/ChannelType';

export function getFocusOnChannels(
  parentChannels: ChannelType[],
  result: ChannelType[]
) {
  parentChannels.forEach((channel: ChannelType) => {
    if (!channel) return;

    result.push(channel);
    if (channel.channelLocations && channel.channelLocations.length > 0) {
      getFocusOnChannels(channel.channelLocations, result);
    }
    if (channel.subs && channel.subs.length > 0) {
      getFocusOnChannels(channel.subs, result);
    }
  });
  return result;
}
