import React from 'react';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { UserType } from 'lane-shared/types/User';

import StackListView from './StackListView';

type Props = {
  user: UserType;
  className?: string;
  style?: React.CSSProperties;
};

export default function UserStackListView({ user, className, style }: Props) {
  const roleName = user?.role?.name || user?.role?.groupRoleName;

  return (
    <StackListView
      image={imageUrl(user?.profile?.image)}
      logo={imageUrl(user?.profile?.logo)}
      name={getDisplayName(user)}
      highlight={user?.online}
      className={className}
      style={style}
    >
      <h2>{getDisplayName(user)}</h2>
      {roleName && <p>{roleName}</p>}
    </StackListView>
  );
}
