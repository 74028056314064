export default function snapToGrid(gridSize: any, value: any) {
  const mod = value % gridSize;

  if (mod !== 0) {
    if (mod > gridSize / 2) {
      // snap to the right / bottom
      return value + gridSize - mod;
    }
    // snap to the left / top
    return value - mod;
  }

  return value;
}
