import React, { useState } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { useChannelSectionsQuery } from 'lane-shared/hooks';

import ContentPin from '../ContentPin';
import SectionListView from '../SectionListView';

import styles from './ContentSearchOnChannel.scss';

const sectionQuery = gql`
  query getSectionForSearch($id: UUID!, $searchOptions: SearchOptionsInput) {
    section(_id: $id, searchOptions: $searchOptions) {
      _id
      sectionContent {
        _id
        _created
        _updated
        _order
        content {
          _id
          _created
          _updated
          name
          description
          subtitle
          slug
          type
          tags
          category
          logo
          color
          geo
          isInteractive
          backgroundImage
          icon
          iconSet
          iconWeight
          backgroundColor
          liveDate
          startDate
          endDate
          interactionEndDate
          state
          externalUrl {
            _id
            url
            openingMode
          }
          version
          renderer
          channel {
            _id
            settings {
              multiLanguageEnabled
              enabledLanguagesForMembers
              channelLanguages
            }
          }
          card {
            _id
          }
        }
      }
    }
  }
`;

export default function SectionContentSearchOnChannel({
  channelId,
  onContentSelected,
}: any) {
  const [selectedSection, setSelectedSection] = useState(null);
  const { sections } = useChannelSectionsQuery({ channelId });
  const { data } = useQuery(sectionQuery, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables: {
      id: (selectedSection as any)?._id,
    },
  });

  const sectionContent = data?.section?.sectionContent || [];

  return (
    <>
      <ul>
        {sections?.map((section: any) => (
          <li
            key={section._id}
            onClick={() => setSelectedSection(section)}
            data-is-selected={
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              selectedSection && selectedSection._id === section._id
            }
          >
            <SectionListView
              className={styles.section}
              key={section._id}
              section={section}
            />
          </li>
        ))}
      </ul>

      <ul className={styles.sectionContent}>
        {sectionContent.map((sectionContent: any) => (
          <li
            key={sectionContent._id}
            onClick={() => onContentSelected(sectionContent.content)}
          >
            <ContentPin content={sectionContent.content} />
          </li>
        ))}
      </ul>
    </>
  );
}
