import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  ReservableFeatureRuleType,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import Checkbox from 'components/form/Checkbox';
import Label from 'components/general/Label';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';

import ReservableFeatureSlider from './ReservableFeatureSlider';
import ReservableFeatureTimeUnitSlider from './ReservableFeatureTimeUnitSlider';
import { minUnits } from './reservableFeatureValues';

import styles from './ReservableFeatureRuleEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  rule: ReservableFeatureRuleType;
  maxSlots: number;
  maxMaxSlots: number;
  channelId: string;
  onRuleUpdated: (update: Partial<ReservableFeatureRuleType>) => void;
  onRuleDeleted: (_id: string) => void;
};

const TRANSLATION_KEYS = {
  channelTeam:
    'web.content.features.reservable.ReservableFeatureRuleEdit.channelTeam',
  selectTeam:
    'web.content.features.reservable.ReservableFeatureRuleEdit.selectTeam',
  maxBookable: 'web.content.features.reservable.ReservableTabSetup.maxBookable',
  maxBookableDescription:
    'web.content.features.reservable.ReservableTabSetup.maxBookable.description',
  maxBookableTooltip:
    'web.content.features.reservable.ReservableTabSetup.maxBookable.tooltip',
  maxBookingWindow:
    'web.content.features.reservable.ReservableReservationEnd.maxBookingWindow',
  maxBookingWindowDescription:
    'web.content.features.reservable.ReservableReservationEnd.maxBookingWindow.description',
  maxBookingWindowTooltip:
    'web.content.features.reservable.ReservableReservationEnd.maxBookingWindow.tooltip',
  minBookingWindow:
    'web.content.features.reservable.ReservableReservationStart.minBookingWindow',
  minBookingWindowDescription:
    'web.content.features.reservable.ReservableReservationStart.minBookingWindow.description',
  minBookingWindowTooltip:
    'web.content.features.reservable.ReservableReservationEnd.minBookingWindow.tooltip',
};

export default function ReservableFeatureRuleEdit({
  className,
  style,
  rule,
  channelId,
  maxSlots,
  maxMaxSlots,
  onRuleUpdated,
  onRuleDeleted,
}: Props) {
  function updateRule(props: any) {
    onRuleUpdated({ _id: (rule as any)._id, ...props });
  }
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles.ReservableFeatureRuleEdit, className)}
      style={style}
    >
      <Label h3>{t(TRANSLATION_KEYS.channelTeam)}</Label>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; h5: true; }' is not assi... Remove this comment to see the full error message */}
      <Label h5>{t(TRANSLATION_KEYS.selectTeam)}</Label>
      <GroupRoleSelector
        className={styles.groupRoleSelector}
        groupRoleId={rule.groupRole?._id}
        channelId={channelId}
        onGroupRoleSelected={groupRole => updateRule({ groupRole })}
      />

      <ReservableFeatureSlider
        TooltipComponent={t(TRANSLATION_KEYS.maxBookableTooltip)}
        disabled={maxMaxSlots < 2}
        label={t(TRANSLATION_KEYS.maxBookable)}
        description={t(TRANSLATION_KEYS.maxBookableDescription)}
        onChange={maxSlots => updateRule({ maxSlots })}
        min={maxSlots}
        max={maxMaxSlots}
        value={Number(rule.maxSlots)}
      />

      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ className: ... Remove this comment to see the full error message */}
      <Checkbox
        className={styles.optionalCheckbox}
        onChange={() => {
          const update: Partial<ReservableFeatureRuleType> = {
            unitsAfterNow: 1,
            unitsAfterNowType: ReservableUnitTypesEnum.Days,
          };
          // set back to 0 to disable
          if (rule.unitsAfterNow !== 0) {
            update.unitsAfterNow = 0;
          }
          updateRule(update);
        }}
        selected={rule.unitsAfterNow !== 0}
        text={t(TRANSLATION_KEYS.minBookingWindow)}
        TooltipComponent={t(TRANSLATION_KEYS.minBookingWindowTooltip)}
        description={t(TRANSLATION_KEYS.minBookingWindowDescription)}
      />

      {rule.unitsAfterNow !== 0 && (
        <fieldset className={styles.optional}>
          <ReservableFeatureTimeUnitSlider
            onUnitTypeUpdated={unitsAfterNowType =>
              updateRule({
                unitsAfterNowType,
                unitsAfterNow: minUnits[unitsAfterNowType],
              })
            }
            onUnitsUpdated={unitsAfterNow =>
              updateRule({
                unitsAfterNow,
              })
            }
            units={rule.unitsAfterNow}
            unitType={rule.unitsAfterNowType}
          />
        </fieldset>
      )}

      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ className: ... Remove this comment to see the full error message */}
      <Checkbox
        className={styles.optionalCheckbox}
        onChange={() => {
          const update: Partial<ReservableFeatureRuleType> = {
            unitsBeforeStart: 1,
            unitsBeforeStartType: ReservableUnitTypesEnum.Days,
          };
          // set back to 0 to disable
          if (rule.unitsBeforeStart !== 0) {
            update.unitsBeforeStart = 0;
          }
          updateRule(update);
        }}
        selected={rule.unitsBeforeStart !== 0}
        text={t(TRANSLATION_KEYS.maxBookingWindow)}
        TooltipComponent={t(TRANSLATION_KEYS.maxBookingWindowTooltip)}
        description={t(TRANSLATION_KEYS.maxBookingWindowDescription)}
      />

      {rule.unitsBeforeStart !== 0 && (
        <fieldset className={styles.optional}>
          <ReservableFeatureTimeUnitSlider
            onUnitTypeUpdated={unitsBeforeStartType =>
              updateRule({
                unitsBeforeStartType,
                unitsBeforeNow: minUnits[unitsBeforeStartType],
              })
            }
            onUnitsUpdated={unitsBeforeStart =>
              updateRule({
                unitsBeforeStart,
              })
            }
            units={rule.unitsBeforeStart}
            unitType={rule.unitsBeforeStartType}
          />
        </fieldset>
      )}

      <Icon
        name="minus-circle"
        set={ICON_SET_FONTAWESOME}
        className={styles.removeIcon}
        onClick={() => onRuleDeleted((rule as any)._id)}
      />
    </div>
  );
}
