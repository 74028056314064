import { GroupDTO, Group } from '../models/Group';

export function createGroupModel(groupRole: Group): GroupDTO {
  const { name, isPublic, channel, permissions, maxUsers } = groupRole;
  return {
    name,
    isPublic,
    channel,
    permissions: {
      set: permissions,
    },
    maxUsers,
    isSystem: false,
  };
}
