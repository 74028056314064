import React, { useMemo, useState, useContext, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { LaneType, LaneIntegrationType } from 'common-types';
import { UserDataContext } from '../../../contexts';
import { cancelReservableInteraction } from '../../../graphql/integrations/ShopifyBurst/reservableInteractions';
import { getDraftInteraction } from '../../../graphql/query';
import {
  PERMISSION_ADMIN,
  PERMISSION_CONTENT_INTERACTIONS_DELETE,
  PERMISSION_CONTENT_INTERACTIONS_READ,
  PERMISSION_CONTENT_INTERACTIONS_UPDATE,
} from '../../../helpers/constants/permissions';
import explodeFeatures from '../../../helpers/features/explodeFeatures';
import upgradeOutdatedBurstInteractionData from '../../../helpers/integrations/ShopifyBurst/upgradeOutdatedBurstInteractionData';
import {
  BurstWizardState,
  CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION,
} from '../../../types/integrations/ShopifyBurst';
import useContentQuery from '../../useContentQuery';
import useCreateInteraction from '../../contentInteraction/useCreateInteraction';
import ShopifyBurstContext, {
  ShopifyBurstContextType,
} from './ShopifyBurstContext';
import useAggregatedUnavailableDatesForSection from './useAggregatedUnavailableDatesForSection';
import {
  DEFAULT_BURST_CAPACITY,
  DEFAULT_DIY_BURST_CAPACITY,
} from './useShopifyGuests';
import useShopifySaveDraftInteraction from './useShopifySaveDraftInteraction';

export const defaultWizardState = {
  currentStepIndex: 0,
  data: {
    schemaVersion: CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION,
  },
};

export enum BurstFlowType {
  UserEdit = 'UserEdit',
  AdminEdit = 'AdminEdit',
}

type Props = {
  contentId: string | undefined;
  burstFlowType?: BurstFlowType;
  children: React.ReactNode;
};

export default function ShopifyBurstContextProvider({
  contentId,
  burstFlowType = BurstFlowType.UserEdit,
  children,
}: Props) {
  const { hasAnyPermission } = useContext(UserDataContext);
  const {
    loading: burstContentLoading,
    error: burstContentError,
    content: burstContent,
    interactions: burstInteractions,
    getContentInteractions: refetchBurstInteractions,
  } = useContentQuery(
    {
      includeInteractions: true,
      forPublic: false,
      contentId,
      fetchPolicy: 'network-only',
      ignoreCache: true,
    },
    false
  );

  const channel = burstContent?.channel;

  const isTeamLead = hasAnyPermission(
    [PERMISSION_CONTENT_INTERACTIONS_READ],
    channel?._id
  );

  const { submitOnBehalfOfFeature } = explodeFeatures(burstContent?.features);

  const hasSubmitOnBehalfOfPermission = Boolean(
    submitOnBehalfOfFeature &&
      hasAnyPermission(
        [
          PERMISSION_ADMIN,
          PERMISSION_CONTENT_INTERACTIONS_UPDATE,
          PERMISSION_CONTENT_INTERACTIONS_DELETE,
        ],
        channel?._id
      )
  );

  const {
    data,
    loading: burstWizardStateLoading,
    error: burstWizardStateError,
    refetch: refetchDraftBurstInteractions,
  } = useQuery(getDraftInteraction, {
    skip: !burstContent?._id,
    fetchPolicy: 'network-only',
    variables: {
      contentId,
      includesOutdated: true,
    },
  });

  const burstIntegrationSettings: LaneIntegrationType.ShopifyBurst | undefined =
    burstContent?.integration?.settings;

  let burstWizardState: BurstWizardState | null =
    data?.me?.draftInteraction?.interaction;

  const {
    loading: diyQuestionsContentLoading,
    error: diyQuestionsContentError,
    content: diyQuestionsContent,
  } = useContentQuery(
    {
      includeInteractions: false,
      forPublic: false,
      contentId: burstIntegrationSettings?.diyQuestions?._id,
      fetchPolicy: 'network-only',
    },
    false
  );

  const {
    loading: acknowledgementContentLoading,
    error: acknowledgementContentError,
    content: acknowledgementContent,
  } = useContentQuery(
    {
      includeInteractions: false,
      forPublic: false,
      contentId: burstIntegrationSettings?.acknowledgement?._id,
      fetchPolicy: 'network-only',
    },
    false
  );

  const {
    loading: burstNameContentLoading,
    error: burstNameContentError,
    content: burstNameContent,
  } = useContentQuery(
    {
      includeInteractions: false,
      forPublic: false,
      contentId: burstIntegrationSettings?.burstName?._id,
      fetchPolicy: 'network-only',
    },
    false
  );

  const {
    loading: questionnaireContentLoading,
    error: questionnaireContentError,
    content: questionnaireContent,
  } = useContentQuery(
    {
      includeInteractions: false,
      forPublic: false,
      contentId: burstIntegrationSettings?.questionnaire?._id,
      fetchPolicy: 'network-only',
    },
    false
  );

  const {
    loading: specialRequestContentLoading,
    error: specialRequestContentError,
    content: specialRequestContent,
  } = useContentQuery(
    {
      includeInteractions: false,
      forPublic: false,
      contentId: burstIntegrationSettings?.specialRequest?._id,
      fetchPolicy: 'network-only',
    },
    false
  );

  if (burstWizardState?.data && questionnaireContent) {
    burstWizardState = {
      ...burstWizardState,
      data: upgradeOutdatedBurstInteractionData({
        interactionData: burstWizardState.data,
        questionnaireContent,
      }),
    };
  }

  if (burstWizardState?.data?.dateRange) {
    burstWizardState.data.dateRange = {
      startDate: burstWizardState.data.dateRange.startDate!,
      endDate: burstWizardState.data.dateRange.endDate!,
    };
  }

  if (burstWizardState) {
    burstWizardState.data.updatedAt = data?.me?.draftInteraction?._updated;
  }

  const initialWizardState = burstWizardState || defaultWizardState;

  const [wizardState, setWizardState] = useState<BurstWizardState>(
    initialWizardState
  );

  useEffect(() => {
    async function cancelDraftReservable(
      interaction: LaneType.UserContentInteraction
    ) {
      try {
        await cancelReservableInteraction(interaction);
        updateWizardState({
          data: {
            ...burstWizardState!.data,
            primaryRoomBooking: undefined,
          },
        });
      } catch (err) {
        // do nothing
      }
    }

    if (
      !burstWizardState ||
      !burstWizardState.data.primaryRoomBooking ||
      wizardState.currentStepIndex > 0
    ) {
      return;
    }

    cancelDraftReservable(burstWizardState.data.primaryRoomBooking);
  }, [
    wizardState.currentStepIndex,
    burstWizardState?.data.primaryRoomBooking?._id,
  ]);

  let maxCapacity: number = DEFAULT_BURST_CAPACITY;

  if (wizardState?.data?.isDiyBurst) {
    maxCapacity =
      burstIntegrationSettings?.diyMaxCapacity || DEFAULT_DIY_BURST_CAPACITY;
  } else if (
    wizardState?.data?.port?.stats?.subscribers &&
    wizardState.data.port.stats.subscribers > maxCapacity
  ) {
    maxCapacity = wizardState.data.port!.stats!.subscribers;
  } else if (
    burstIntegrationSettings?.rsvpMaxCapacity &&
    burstIntegrationSettings.rsvpMaxCapacity > maxCapacity
  ) {
    maxCapacity = burstIntegrationSettings.rsvpMaxCapacity;
  }

  useShopifySaveDraftInteraction({ contentId, wizardState });

  const {
    interaction: newBurstInteraction,
    setInteractionUser,
  } = useCreateInteraction({
    content: burstContent,
  });

  const {
    unavailableTimeRanges,
    minDate,
    maxDate,
  } = useAggregatedUnavailableDatesForSection({
    sectionId: burstIntegrationSettings?.roomBookingSection?._id,
    channelId: wizardState?.data?.port?._id,
    timeZone: wizardState?.data?.portTimeZone!,
  });

  function updateWizardState(newState: Partial<BurstWizardState>) {
    setWizardState(prevState => ({ ...prevState, ...newState }));
  }

  const contextValue = useMemo<ShopifyBurstContextType>(
    () => ({
      initialWizardState,
      wizardState,
      updateWizardState,
      contentId,
      burstFlowType,
      burstWizardState,
      burstWizardStateError,
      burstWizardStateLoading,
      burstContent,
      burstContentLoading,
      burstContentError,
      burstIntegrationSettings,
      burstInteractions,
      refetchBurstInteractions,
      refetchDraftBurstInteractions,
      newBurstInteraction,
      loading:
        burstContentLoading ||
        burstWizardStateLoading ||
        burstNameContentLoading ||
        diyQuestionsContentLoading ||
        specialRequestContentLoading ||
        questionnaireContentLoading,
      error: burstContentError || burstWizardStateError,
      isTeamLead,
      hasSubmitOnBehalfOfPermission,
      setInteractionUser: setInteractionUser as ShopifyBurstContextType['setInteractionUser'],
      unavailableDatesAsDateRanges: unavailableTimeRanges,
      minDate: minDate.toJSDate(),
      maxDate: maxDate.toJSDate(),
      maxCapacity,
      diyQuestionsContent: diyQuestionsContent || undefined,
      diyQuestionsContentLoading,
      diyQuestionsContentError,
      acknowledgementContentLoading,
      acknowledgementContentError,
      acknowledgementContent: acknowledgementContent || undefined,
      burstNameContent: burstNameContent || undefined,
      burstNameContentLoading,
      burstNameContentError,
      questionnaireContentLoading,
      questionnaireContentError,
      questionnaireContent: questionnaireContent || undefined,
      specialRequestContent: specialRequestContent || undefined,
      specialRequestContentLoading,
      specialRequestContentError,
    }),
    [
      contentId,
      burstFlowType,
      wizardState,
      updateWizardState,
      burstWizardState,
      burstWizardStateError,
      burstWizardStateLoading,
      burstContent,
      burstContentLoading,
      burstContentError,
      burstIntegrationSettings,
      burstInteractions,
      refetchBurstInteractions,
      refetchDraftBurstInteractions,
      isTeamLead,
      setInteractionUser,
      hasSubmitOnBehalfOfPermission,
      unavailableTimeRanges,
      minDate,
      maxDate,
      maxCapacity,
      diyQuestionsContent,
      diyQuestionsContentLoading,
      diyQuestionsContentError,
      acknowledgementContentLoading,
      acknowledgementContentError,
      acknowledgementContent,
      burstNameContent,
      burstNameContentLoading,
      burstNameContentError,
      questionnaireContentLoading,
      questionnaireContentError,
      questionnaireContent,
      specialRequestContent,
      specialRequestContentLoading,
      specialRequestContentError,
    ]
  );

  return (
    <ShopifyBurstContext.Provider value={contextValue}>
      {children}
    </ShopifyBurstContext.Provider>
  );
}
