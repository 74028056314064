import { parseDate } from '../../helpers/dates';
import { SearchOptions } from '../../types/filters/SearchOptions';

export default function storedSearchOptionsParser(
  storedSearchOptions: SearchOptions
): SearchOptions {
  storedSearchOptions._updated = parseDate(storedSearchOptions._updated)!;

  if (storedSearchOptions.filters) {
    storedSearchOptions.filters.forEach(filter => {
      if ((filter.filter as any)?.startDate) {
        (filter.filter as any).startDate = parseDate(
          (filter.filter as any)?.startDate
        );
      }

      if ((filter.filter as any)?.endDate) {
        (filter.filter as any).endDate = parseDate(
          (filter.filter as any)?.endDate
        );
      }
    });
  }

  return storedSearchOptions;
}
