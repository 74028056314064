/**
 * Gets the friendly display name for a ProxyClick person
 */
export default function getName(obj: any) {
  if (!obj) {
    return '';
  }

  return `${obj.firstName || obj.firstname} ${obj.lastName || obj.lastname}`;
}
