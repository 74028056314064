import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import styles from './NavLinks.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  routes: {
    route: string;
    name: string;
    icon?: string;
  }[];
};

export function NavLinks({ className, style, routes }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.NavLinks, className)} style={style}>
      {routes.map(route => (
        <NavLink
          key={route.route}
          to={route.route}
          activeClassName={styles.active}
          exact
        >
          {route.icon && <Icon name={route.icon} className={styles.icon} />}
          {t(route.name)}
        </NavLink>
      ))}
    </div>
  );
}
