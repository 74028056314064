import gql from 'graphql-tag';

import { AnonymousChannelProfileFragment } from 'lane-shared/graphql/fragments';

export default gql`
  ${AnonymousChannelProfileFragment}

  query getChannelForAnonymousContent($id: UUID!, $search: ContentSearch) {
    content(_id: $id, search: $search) {
      channel {
        ...AnonymousChannelProfileFragment
      }
    }
  }
`;
