import { DateTime, Interval } from 'luxon';
import { ContentFeature } from 'graphql-query-contracts';
import { ReservableUnavailabilityRange } from 'lane-shared/hooks/useReservableAvailabilityByRange';

export type FullCalendarBusinessHour = {
  daysOfWeek: [number];
  startTime: string;
  duration: string;
};

export function getBusinessHoursFromUnavailables({
  referenceDate,
  timeZone,
  unavailableRanges,
}: {
  referenceDate: Date;
  timeZone: string;
  features: ContentFeature[] | undefined;
  unavailableRanges: ReservableUnavailabilityRange[];
  userRoleBase62Ids: string[];
}): FullCalendarBusinessHour[] {
  const referenceDateTime = DateTime.fromJSDate(referenceDate).setZone(
    timeZone
  );
  const endDateTime = referenceDateTime.plus({ hour: 24 });
  const dayInterval = Interval.fromDateTimes(referenceDateTime, endDateTime);
  const fullDayavailableRange: FullCalendarBusinessHour[] = [];
  const fullDayUnavailableRange: FullCalendarBusinessHour[] = [
    {
      daysOfWeek: [
        DateTime.fromJSDate(referenceDate).setZone(timeZone).weekday % 7,
      ],
      startTime: '00:00',
      duration: '00:00',
    },
  ];

  if (!unavailableRanges.length) {
    // will make full day as business hours
    return fullDayavailableRange;
  }

  const unavailableRangeIntervals = unavailableRanges.map(({ interval }) => {
    const startDate = interval.startDate
      ? DateTime.fromJSDate(interval.startDate).setZone(timeZone)
      : referenceDateTime;
    const endDate = interval.endDate
      ? DateTime.fromJSDate(interval.endDate).setZone(timeZone)
      : endDateTime;
    const range = Interval.fromDateTimes(startDate, endDate);

    return range;
  });

  const availableRanges = Interval.xor([
    dayInterval,
    ...unavailableRangeIntervals,
  ]);

  if (availableRanges.length === 0) {
    return fullDayUnavailableRange;
  }

  const businessHours: FullCalendarBusinessHour[] = availableRanges.map(
    available => ({
      daysOfWeek: [
        DateTime.fromJSDate(referenceDate).setZone(timeZone).weekday % 7,
      ],
      startTime: available.start.toFormat('HH:mm'),
      duration: available.end.diff(available.start).toFormat('hh:mm'),
    })
  );

  return businessHours;
}
