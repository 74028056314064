import React from 'react';

import { InputWrapper } from 'design-system-web';
import cx from 'classnames';
import { Dropdown } from 'components';

import { ChipSelect, ChipStyle } from 'components/ads';
import { Item } from 'components/form/Dropdown/Dropdown';
import { M } from 'components/typography';

import styles from './PromotedAttributes.scss';

type BaseAttribute = {
  label: string;
  value: string;
};

type DropdownOption = {
  label: string;
  value: string;
  type?: never;
};

type ChipOption = {
  label: string;
  value: string;
  type: ChipStyle;
};

type ChipSelectAttribute = BaseAttribute & {
  options: ChipOption[];
  onChange: (value: string) => void;
};

type DropdownAttribute = BaseAttribute & {
  options: DropdownOption[];
  onChange: (value: Item<string>) => void;
};

type Attribute = BaseAttribute | ChipSelectAttribute | DropdownAttribute;

type Props = { attributes: Attribute[] };

const isChipSelect = (
  attribute: Attribute
): attribute is ChipSelectAttribute => {
  return (
    'options' in attribute &&
    attribute.options.length > 0 &&
    !!attribute?.options?.[0]?.type
  );
};

const isDropdown = (attribute: Attribute): attribute is DropdownAttribute => {
  return (
    'options' in attribute &&
    attribute.options.length > 0 &&
    !attribute?.options?.[0]?.type
  );
};

export function PromotedAttributes({ attributes }: Props) {
  return (
    <div className={styles.Wrapper}>
      {attributes.map((attribute, index) => {
        if (isChipSelect(attribute)) {
          return (
            <InputWrapper
              label={attribute.label}
              className={styles.InputWrapper}
            >
              <div className={styles.AttributeWrapper}>
                <ChipSelect
                  list={attribute.options}
                  onChange={attribute.onChange}
                  value={attribute.value}
                />
              </div>
            </InputWrapper>
          );
        }
        if (isDropdown(attribute)) {
          return (
            <Dropdown
              label={attribute.label}
              items={attribute.options}
              onChange={attribute.onChange}
              value={attribute.value}
              fixedLabel
              className={styles.Dropdown}
            />
          );
        }
        return (
          <InputWrapper
            label={attribute.label}
            className={cx(
              styles.InputWrapper,
              index > 0 && styles.AdditionalTextAttributeWrapper
            )}
          >
            <div
              className={cx(
                styles.AttributeWrapper,
                index > 0 && styles.AdditionalTextAttribute
              )}
            >
              <M>{attribute.value}</M>
            </div>
          </InputWrapper>
        );
      })}
    </div>
  );
}
