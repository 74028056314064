import React from 'react';

import {
  MjmlSection,
  MjmlColumn,
  MjmlText,
} from 'lane-shared/emails/MjmlComponentsV2';

export function EmailVisitorGreeting({
  salutation,
  details,
}: {
  salutation: string;
  details?: JSX.Element | string;
}) {
  return (
    <MjmlSection padding-bottom="0%" background-color="#FFFFFF">
      <MjmlColumn background-color="#FFFFFF">
        <MjmlText padding-bottom="0%">
          <h1>{salutation}</h1>
        </MjmlText>
        {details && <MjmlText>{details}</MjmlText>}
      </MjmlColumn>
    </MjmlSection>
  );
}
