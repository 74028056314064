import React from 'react';

import { useTranslation } from 'react-i18next';

export function UnderConstructionLegacy({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  const { t } = useTranslation();

  return (
    <div className={className} style={style}>
      <h1>{t('This is still under construction.')}</h1>
      <p>
        {t(
          'We are working hard to get this out for you. Feel free to check back later!'
        )}
      </p>
    </div>
  );
}
