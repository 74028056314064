import React, { useState } from 'react';

import { Icon } from 'design-system-web';

import * as Captivate from 'lane-shared/helpers/constants/integrations/Captivate';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import Checkbox from '../../../../form/Checkbox';

import styles from './styles.scss';

const dataKey = `_${IntegrationProviderEnum.Pattison}`;

export default function PattisonTargeting({
  hasChanged,
  content,
  onContentUpdated,
}: any) {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasIntegration = content.state && content.state[dataKey];

  return (
    <div className={styles.TargetingIntegration}>
      <h1>Pattison Displays</h1>
      <section className={styles.details}>
        {!hasIntegration && (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: f... Remove this comment to see the full error message
          <Checkbox
            selected={false}
            text="Display on Pattison screens."
            onChange={() => {
              onContentUpdated({
                state: {
                  ...(content.state || {}),
                  [dataKey]: true,
                },
              });
            }}
          />
        )}

        {hasIntegration && (
          <>
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: t... Remove this comment to see the full error message */}
            <Checkbox
              selected
              text="Display on Pattison screens."
              onChange={() => {
                onContentUpdated({
                  state: {
                    ...(content.state || {}),
                    [dataKey]: undefined,
                  },
                });
              }}
            />

            <h2 onClick={() => setIsExpanded(!isExpanded)}>
              <span>Preview</span>
              <Icon name="chevron-down" />
            </h2>

            {isExpanded && (
              <div className={styles.preview}>
                {hasChanged && (
                  <p>
                    You must save changes to your draft to see updated previews.
                  </p>
                )}

                {Captivate.CAPTIVATE_RESOLUTIONS.map(resolution => {
                  const src = `/api/v1/lane/clients/pattison/content/${content._id}?preview=true&resolution=${resolution}`;

                  const [width, height] = resolution
                    .split('x')
                    .map(parseFloat)
                    .map(value => value / 3);

                  return (
                    <div className={styles.previewResolution} key={resolution}>
                      <span>{resolution}</span>
                      <a href={src} target="_blank" rel="noopener noreferrer">
                        <img
                          alt={content.name}
                          style={{ width, height }}
                          src={src}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
}
