import { useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getInteractionsOnContent } from '../graphql/query';
import {
  ALL_INTERACTIONS,
  INTERACTION_OPEN,
} from '../helpers/constants/interactions';
import * as PAGINATION from '../helpers/constants/pagination';
import { SortByEnum, SortDirectionEnum } from '../types/blocks/Sort';
import { useMultiLanguage } from './useMultiLanguage';

export default function useInteractionsOnContentQuery({
  interactionState,
  contentId,
  perPage = PAGINATION.perPage,
  forUserId,
  usePagination,
  autoHide,
  pollInterval,
  sort,
}: any) {
  const { translate } = useMultiLanguage();
  const sortBy = sort?.sortBy || SortByEnum.EndDate;
  const sortDirection = sort?.sortDirection || SortDirectionEnum.Asc;

  const search: any = {
    sortBy: {
      key: sortBy,
      dir: sortDirection === SortDirectionEnum.Asc ? 'asc' : 'desc',
    },
  };

  if (forUserId) {
    search.user = {
      _id: forUserId,
    };
  }

  if (interactionState !== ALL_INTERACTIONS) {
    search.state = {
      isOpen: interactionState === INTERACTION_OPEN,
      isActive: interactionState === INTERACTION_OPEN,
    };
  }

  const [page, setPage] = useState(0);
  const [
    fetchInterations,
    { data, loading, error, fetchMore, called },
  ] = useLazyQuery(getInteractionsOnContent, {
    fetchPolicy: 'network-only',
    client: getClient(),
    pollInterval,
  });

  useEffect(() => {
    if (!usePagination && called) {
      // bug in apollo-client see https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
      try {
        fetchMore({
          variables: {
            contentId,
            pagination: {
              perPage,
              start: page * perPage,
            },
            search,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            // Don't do anything if there weren't any new items
            if (!fetchMoreResult) {
              return prev;
            }

            return {
              // Concatenate the new feed results after the old ones
              interactionsOnContent: {
                __typename: 'interactionsOnContent',
                pageInfo: {
                  ...fetchMoreResult.interactionsOnContent.pageInfo,
                  total: prev.interactionsOnContent.pageInfo.total,
                },
                items: [
                  ...prev.interactionsOnContent.items,
                  ...fetchMoreResult.interactionsOnContent.items,
                ],
              },
            };
          },
        });
      } catch (err) {
        // swallow error for now
      }
    } else if (usePagination) {
      fetchInterations({
        variables: {
          pagination: {
            perPage,
            start: page * perPage,
          },
          contentId,
          search,
        },
      });
    }
  }, [page]);

  useEffect(() => {
    if (contentId && !called) {
      fetchInterations({
        variables: {
          pagination: {
            perPage,
            start: 0,
          },
          contentId,
          search,
        },
      });
    }
  }, [contentId]);

  // fetch interactions if sort options changed
  // needed for the sort to be reflected in real time in the builder
  useEffect(() => {
    fetchInterations({
      variables: {
        pagination: {
          perPage,
          start: page * perPage,
        },
        contentId,
        search,
      },
    });
  }, [sort]);

  // manually refetch interactions with all pagination
  function refetch() {
    fetchInterations({
      variables: {
        pagination: {
          perPage,
          start: page * perPage,
        },
        contentId,
        search,
      },
    });
  }

  const items = (data?.interactionsOnContent?.items || []).map((item: any) => ({
    ...item,
    contentData: translate({
      model: item?.contentData,
      columns: ['name', 'description', 'subtitle'],
    }),
  }));

  const pageInfo = data?.interactionsOnContent?.pageInfo || {
    total: 0,
    start: 0,
    perPage: 0,
  };

  const fastIsHidden =
    (autoHide && !called) || (called && !loading && items?.length === 0);

  const [isHidden] = useDebounce(fastIsHidden, 100);

  return { items, pageInfo, loading, error, page, setPage, isHidden, refetch };
}
