import { isIE11 } from './isIE11';

export default function getBoundingClientRect(ref: any) {
  if (!isIE11) {
    return ref.getBoundingClientRect();
  }

  const { left, top, height, width, ...other } = ref.getBoundingClientRect();
  return { ...other, x: left, y: top, width, height };
}
