import { useMemo } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { defaultChannelPages } from '../helpers/constants';
import { byOrder } from '../helpers/sort';

export const query = gql`
  query queryPagesOnChannel($channelId: UUID!) {
    channel(_id: $channelId) {
      _id
      _updated
      name
      slug
      pages {
        _id
        _created
        _updated
        _order
        content {
          _id
          _created
          _updated
        }
        name
        slug
        icon
        iconSet
        iconWeight
        label
        label_l10n
      }
    }
  }
`;

export default function useChannelPagesForAdmin({ channelId }: any) {
  const { data, loading, error, refetch } = useQuery(query, {
    skip: !channelId,
    variables: {
      channelId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const channelPages = useMemo(
    () => [
      ...cloneDeep(data?.channel?.pages || []).sort(byOrder),
      defaultChannelPages.profileTab,
    ],
    [data?.channel?.pages]
  );

  return {
    channelPages,
    loading,
    error,
    refetch,
  };
}
