import { ContentType } from 'lane-shared/types/content/Content';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

export default function injectShopifyMultipassIntegration(
  channelIntegrationId: string,
  childContentList: { content: ContentType }[]
) {
  return childContentList.map(child => ({
    ...child,
    content: {
      ...child.content,
      integration: {
        _id: channelIntegrationId,
        name: IntegrationProviderEnum.ShopifyMultipass,
      },
    },
  }));
}
