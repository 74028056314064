import * as yup from 'yup';

import { InvalidTypeValueError } from 'activate-errors';
import { NumberTypeBase } from '../../types/baseTypes/NumberTypeBase';
import { AllTypeContexts } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_NUMBER } from '../constants';

export class NumberType extends NumberTypeBase {
  contexts = AllTypeContexts;

  group = TYPE_GROUP_NUMBER;

  name = 'Number';

  get example() {
    return Math.floor(Math.random() * 10000);
  }

  get default(): any {
    return 0;
  }

  buildSchema() {
    return yup.number().nullable();
  }

  parseValue = (value: any) => {
    if (value === undefined || value === null) {
      return value;
    }

    if (typeof value === 'number') {
      return value;
    }

    if (typeof value === 'string') {
      return Number.parseFloat(value);
    }

    throw new InvalidTypeValueError(`${value} for Number is not valid.`);
  };
}

const definition = new NumberType();

Types.addBaseType(definition);
export default definition;
