import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import Number from '../../../../properties/baseTypes/Number';
import String from '../../../../properties/baseTypes/String';
// need to make sure these guys are loaded.
import '../../../../properties/baseTypes/UUID';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';

const properties: PropertiesInterface = {
  isEnabled: {
    type: 'Boolean',
    validators: [
      {
        name: 'Required',
        value: 'true',
      },
    ],
  },
  beforeDurationCalendarLabel: {
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: 'false',
      },
    ],
  },
  beforeDuration: {
    type: 'Number',
    validators: [
      {
        name: 'Required',
        value: 'false',
      },
    ],
  },
  afterDurationCalendarLabel: {
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: 'false',
      },
    ],
  },
  afterDuration: {
    type: 'Number',
    validators: [
      {
        name: 'Required',
        value: 'false',
      },
    ],
  },
};

export class BufferTimeType extends ObjectTypeBase {
  name = 'BufferTime';

  get example() {
    return {
      _id: uuid(),
      isEnabled: false,
      beforeDuration: Number.example,
      beforeDurationCalendarLabel: String.example,
      afterDuration: Number.example,
      afterDurationCalendarLabel: String.example,
    };
  }

  get default(): any {
    return {
      isEnabled: false,
      _id: uuid(),
      beforeDuration: Number.default,
      beforeDurationCalendarLabel: String.default,
      afterDuration: Number.default,
      afterDurationCalendarLabel: String.default,
    };
  }

  contexts = [];

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new BufferTimeType();

Types.addType(definition);
export default definition;
