import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { DAYS_OF_THE_WEEK } from '../../../../helpers/constants/timeUnits';
import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import PaymentFeatureDayRule from './PaymentFeatureDayRule';

const properties: PropertiesInterface = Object.assign(
  {},
  ...Object.keys(DAYS_OF_THE_WEEK).map(dayKey => ({
    [dayKey]: {
      type: 'PaymentFeatureDayRule',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  }))
);

export class PaymentFeatureWeekRuleType extends ObjectTypeBase {
  name = 'PaymentFeatureWeekRule';

  friendlyName = 'Rule for the days of the week';

  contexts = [];

  get example() {
    return {};
  }

  get default(): any {
    return Object.assign(
      {},
      ...Object.keys(DAYS_OF_THE_WEEK).map(dayKey => ({
        [dayKey]: {
          ...PaymentFeatureDayRule.default,
          _id: uuid(),
        },
      }))
    );
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new PaymentFeatureWeekRuleType();

Types.addType(definition);
export default definition;
