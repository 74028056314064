import { ErrorCodeType } from 'activate-errors';

export const getErrorMessagesFromCodes = (
  codes: any[],
  errorCodeTypes: { [key: string]: ErrorCodeType },
  defaultMessage: string = ''
): string => {
  const messageList: string[] = [];

  const errorCodeTypesList = Object.values(errorCodeTypes);
  if (!errorCodeTypesList.length) return defaultMessage;

  codes.forEach(code => {
    const errorCodeType = errorCodeTypesList.find(type => type.code === code);
    if (errorCodeType) {
      messageList.push(errorCodeType.message);
    }
  });

  const messages = messageList.join(', ');

  return messages || defaultMessage;
};
