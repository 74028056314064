import React, { useState, useEffect, useContext } from 'react';

import {
  Button,
  TabStrip,
  IconButton,
  ControlMenu,
  DateRangePickerButton,
  Input,
} from 'components';
import { useChannelAdminContext, useQueryString } from 'hooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import {
  queryContentOnChannel,
  queryDraftContentOnChannel,
} from 'lane-shared/graphql/channel';
import { getChannel } from 'lane-shared/graphql/query';
import {
  PERMISSION_CONTENT_CREATE,
  PERMISSION_CONTENT_NOTICE_CREATE,
  PERMISSION_CONTENT_PAGE_CREATE,
  PERMISSION_CONTENT_PERK_CREATE,
  PERMISSION_CONTENT_POST_CREATE,
  PERMISSION_CONTENT_PROMOTION_CREATE,
} from 'lane-shared/helpers/constants/permissions';
import hasDates from 'lane-shared/helpers/content/hasDates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import {
  ContentTypeEnum,
  ContentModalTypeEnum,
} from 'lane-shared/types/content/ContentTypeEnum';

import { TemplatePickerModalContext } from '../../../../../contexts/TemplatePickerModalContext';
import {
  ChannelContentCenterTable,
  ChannelContentCenterGrid,
} from './components';
import {
  VIEW_GRID,
  VIEW_LIST,
  tabs,
  DATE_FORMAT,
  SORT_LIVE_DATE,
  SORT_UPDATED,
  SORT_ASC,
  SORT_DESC,
  views,
} from './helpers/constants';

import styles from './styles.scss';
import { useDraftContentAnalytics } from 'lane-shared/hooks/analytics';

const TRANSLATION_KEYS = {
  newPostButton: 'web.admin.channel.contentCenter.button.newPost',
  newPerkButton: 'web.admin.channel.contentCenter.button.newPerk',
  newNoticeButton: 'web.admin.channel.contentCenter.button.newNotice',
  newPromotionButton: 'web.admin.channel.contentCenter.button.newPromotion',
  newPageButton: 'web.admin.channel.contentCenter.button.newPage',
};

export default function ContentCentre({ availableTypes }: any) {
  const { channel } = useChannelAdminContext();
  const { hasAnyPermission } = useContext(UserDataContext);
  const [parent, setParent] = useState(null);
  const location = useLocation();
  const { setTemplateType, setModalType } = useContext(
    TemplatePickerModalContext
  );
  const { t } = useTranslation();

  const defaultSort = hasDates(availableTypes) ? SORT_LIVE_DATE : SORT_UPDATED;
  const defaultSortOrder =
    defaultSort === SORT_LIVE_DATE ? SORT_ASC : SORT_DESC;

  // todo: this pagination is tied to each instance. which is not ideal.
  // but it preserves our deep linking so navigation is better.
  const [query, goToUrl, makeUrl] = useQueryString({
    view: VIEW_GRID,
    tab: tabs[0].value,
    perPage: 25,
    page: 1,
    startDate: DateTime.local().startOf('day').toISO(),
    endDate: DateTime.local().plus({ weeks: 4 }).startOf('day').toISO(),
    sort: defaultSort,
    order: defaultSortOrder,
  });

  // todo: this will only go one level up on parent.
  async function getParent() {
    const { data } = await getClient().query({
      query: getChannel,
      variables: {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        id: channel.parent._id,
      },
    });

    if (data?.channel) {
      setParent(data.channel);
    }
  }

  useEffect(() => {
    if (channel && channel.parent) {
      // get the parent channel if there is one.
      getParent();
    }
  }, [channel?._id]);

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const perPage = parseInt(query.perPage, 10) || 25;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const page = (parseInt(query.page, 10) || 1) - 1;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const startDate = DateTime.fromISO(query.startDate) || new Date();
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const endDate = DateTime.fromISO(query.endDate) || new Date();
  const forDrafts = query.tab === tabs[1].value;
  const selectedSort = query.sort || defaultSort;
  const selectedOrder = query.order || defaultSortOrder;
  const reverseOrder = selectedOrder === SORT_ASC ? SORT_DESC : SORT_ASC;
  const { draftContentTracker } = useDraftContentAnalytics();
  if (
    Object.keys(query).length === 0 &&
    availableTypes.includes(ContentTypeEnum.VisitorManagement)
  ) {
    query.tab = tabs[0].value;
    query.view = VIEW_GRID;
    query.perPage = 25;
  }

  const publishedInfo = forDrafts
    ? {
        query: queryDraftContentOnChannel,
        name: 'draftContentOnChannel',
      }
    : {
        query: queryContentOnChannel,
        name: 'contentOnChannel',
      };

  function goQuery(props: any) {
    goToUrl(props);
  }

  function setDateRange(range: any) {
    goQuery({
      startDate: dateFormatter(range.startDate, DATE_FORMAT) || startDate,
      endDate: dateFormatter(range.endDate, DATE_FORMAT) || endDate,
    });
  }

  useEffect(() => {
    if (forDrafts) {
      draftContentTracker.View.Index();
    }
  }, [forDrafts]);

  const displayDateRangePicker = hasDates(availableTypes) && !forDrafts;

  return (
    <div className={styles.ContentCentre}>
      <ControlMenu>
        <TabStrip
          tabs={tabs}
          selected={tabs.find(tab => query.tab === tab.value) || null}
          onSelectTab={tab => goQuery({ tab: tab.value, page: 0 })}
        />

        <div className={styles.spacer} />

        <Input
          value={search}
          onChange={search => setSearch(search)}
          icon="search"
          showClear
        />

        {displayDateRangePicker && (
          <DateRangePickerButton
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'never'.
            minDate={new Date(2015, 0, 1)}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateTime' is not assignable to type 'never'.
            startDate={startDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateTime' is not assignable to type 'never'.
            endDate={endDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(range: any) => void' is not assignable to t... Remove this comment to see the full error message
            onChange={setDateRange}
          />
        )}

        {views.map(view => (
          <IconButton
            key={view}
            icon={view}
            className={styles.viewIcon}
            selected={query.view === view}
            onClick={() => goQuery({ view })}
            inverted
          />
        ))}

        {availableTypes.includes(ContentTypeEnum.Perk) &&
          hasAnyPermission(
            [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_PERK_CREATE],
            channel?._id
          ) && (
            <Button
              className="btnNewPerk"
              variant="outlined"
              size="small"
              dataCy="buttonNewPerk"
              onClick={() => {
                setTemplateType(ContentTypeEnum.Perk);
                setModalType(ContentModalTypeEnum.Template);
              }}
            >
              {t(TRANSLATION_KEYS.newPerkButton)}
            </Button>
          )}

        {availableTypes.includes(ContentTypeEnum.Content) &&
          hasAnyPermission(
            [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_POST_CREATE],
            channel?._id
          ) && (
            <Button
              className="btnNewPost"
              variant="outlined"
              size="small"
              dataCy="buttonNewPost"
              onClick={() => {
                setTemplateType(ContentTypeEnum.Content);
                setModalType(ContentModalTypeEnum.Template);
              }}
            >
              {t(TRANSLATION_KEYS.newPostButton)}
            </Button>
          )}

        {availableTypes.includes(ContentTypeEnum.Notice) &&
          hasAnyPermission(
            [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_NOTICE_CREATE],
            channel?._id
          ) && (
            <Link
              to={{
                pathname: `/l/channel/${channel?.slug}/admin/create-notice`,
                state: { from: location.pathname },
              }}
            >
              <Button
                className="btnNewNotice"
                variant="outlined"
                size="small"
                onClick={() => {
                  draftContentTracker.Create.Start.New({
                    type: ContentTypeEnum.Notice,
                  });
                }}
              >
                {t(TRANSLATION_KEYS.newNoticeButton)}
              </Button>
            </Link>
          )}

        {availableTypes.includes(ContentTypeEnum.Promotion) &&
          hasAnyPermission(
            [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_PROMOTION_CREATE],
            channel?._id
          ) && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setTemplateType(ContentTypeEnum.Promotion);
                setModalType(ContentModalTypeEnum.Template);
              }}
            >
              {t(TRANSLATION_KEYS.newPromotionButton)}
            </Button>
          )}

        {availableTypes.includes(ContentTypeEnum.Static) &&
          hasAnyPermission(
            [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_PAGE_CREATE],
            channel?._id
          ) && (
            <Button
              className="btnNewPage"
              variant="contained"
              size="small"
              dataCy="newPageButton"
              onClick={() => {
                setTemplateType(ContentTypeEnum.Static);
                setModalType(ContentModalTypeEnum.Template);
              }}
            >
              {t(TRANSLATION_KEYS.newPageButton)}
            </Button>
          )}
      </ControlMenu>

      <section data-view={query.view}>
        {query.view === VIEW_LIST && (
          <>
            <ChannelContentCenterTable
              query={publishedInfo.query}
              queryName={publishedInfo.name}
              channel={channel}
              page={page}
              perPage={perPage}
              selectedSort={selectedSort}
              selectedOrder={selectedOrder}
              search={debouncedSearch}
              availableTypes={availableTypes}
              endDate={endDate}
              startDate={startDate}
              forDrafts={forDrafts}
              reverseOrder={reverseOrder}
              goQuery={goQuery}
              makeUrl={makeUrl}
            />
            {!forDrafts && parent && (
              <ChannelContentCenterTable
                query={publishedInfo.query}
                queryName={publishedInfo.name}
                hideOnError
                channel={parent}
                page={page}
                perPage={perPage}
                selectedSort={selectedSort}
                selectedOrder={selectedOrder}
                search={debouncedSearch}
                availableTypes={availableTypes}
                endDate={endDate}
                startDate={startDate}
                forDrafts={forDrafts}
                reverseOrder={reverseOrder}
                goQuery={goQuery}
                makeUrl={makeUrl}
              />
            )}
          </>
        )}

        {query.view === VIEW_GRID && (
          <>
            <ChannelContentCenterGrid
              query={publishedInfo.query}
              queryName={publishedInfo.name}
              channel={channel}
              page={page}
              perPage={perPage}
              selectedSort={selectedSort}
              selectedOrder={selectedOrder}
              search={debouncedSearch}
              availableTypes={availableTypes}
              endDate={endDate}
              startDate={startDate}
              forDrafts={forDrafts}
              goQuery={goQuery}
              primaryId={channel?._id}
            />
            {!forDrafts && parent && (
              <ChannelContentCenterGrid
                query={publishedInfo.query}
                queryName={publishedInfo.name}
                hideOnError
                channel={parent}
                page={page}
                perPage={perPage}
                selectedSort={selectedSort}
                selectedOrder={selectedOrder}
                search={debouncedSearch}
                availableTypes={availableTypes}
                endDate={endDate}
                startDate={startDate}
                forDrafts={forDrafts}
                goQuery={goQuery}
                primaryId={channel?._id}
              />
            )}
          </>
        )}
      </section>
    </div>
  );
}
