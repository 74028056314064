import getBlockKey from 'lane-shared/renderers/v5/getBlockKey';
import {
  BlockInstanceType,
  BlockRenderInterface,
} from 'lane-shared/types/blocks/BlockInterface';

type _FindReturn = {
  found: any | null; // TODO: fix type
};

type _FindArgs = {
  block: BlockInstanceType;
  key: string;
  value: string;
  ret: _FindReturn;
  blocks: {
    [key: string]: BlockRenderInterface;
  };
};

function _find({ block, key, value, ret, blocks }: _FindArgs) {
  if (!block) {
    return;
  }

  // we need to find this block in this instance.
  // could be in children, or in the properties of any block.
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (block[key] === value) {
    ret.found = block;
    return;
  }

  if (!block.primitive && block.blockId) {
    const Block = blocks[getBlockKey(block)];

    if (Block) {
      Object.entries(Block.def.properties)
        .filter(([, property]) => property.type === 'Block')
        .forEach(([propertyKey, property]) => {
          // does the properties field exist
          const sub = block.properties?.[propertyKey];

          if (sub) {
            // is this an array of blocks in this property.
            if (property.isArray) {
              // if so, does the block we're looking for exist in that array.
              const ix = sub.findIndex((b: any) => b[key] === value);
              if (ix > -1) {
                ret.found = sub[ix];
              } else {
                // otherwise, we will have to check each block in this array recursively
                sub.forEach((b: any) =>
                  _find({ block: b, key, value, ret, blocks })
                );
              }
            } else if (sub[key] === value) {
              // if this is just a block property check to see if this the one we are looking for
              ret.found = sub;
            } else {
              // otherwise we will need to hunt for it recursively
              _find({ block: sub, key, value, ret, blocks });
            }
          }
        });
    }
  }
  // ok we checked the properties, not we also need to check the children.
  if (block.children) {
    const ix = (block.children as any).findIndex((b: any) => b[key] === value);
    if (ix > -1) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ret.found = block.children[ix];
    } else {
      (block.children as any).forEach((b: any) =>
        _find({ block: b, key, value, ret, blocks })
      );
    }
  }

  if (block.properties?.children) {
    const ix = block.properties.children.findIndex(
      (b: any) => b[key] === value
    );
    if (ix > -1) {
      ret.found = block.properties.children[ix];
    } else {
      block.properties.children.forEach((b: any) =>
        _find({ block: b, key, value, ret, blocks })
      );
    }
  }
}

type Args = {
  content: { block: BlockInstanceType };
  key?: string;
  value: string;
  blocks: {
    [key: string]: BlockRenderInterface;
  };
};

export default function findBlock({
  content,
  key = '_id',
  value,
  blocks,
}: Args) {
  const ret: _FindReturn = {
    found: null,
  };

  _find({ block: content.block, key, value, ret, blocks });

  return ret.found;
}
