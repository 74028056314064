import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

/**
const properties: PropertiesInterface = {
  value: {
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: ['center', 'left', 'right', 'justify'],
      },
    ],
  },
};
 */

export class TextAlignmentType extends StringTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'TextAlignment';

  friendlyName = 'Text Alignment';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  superUserOnly = false;

  get example() {
    return 'center';
  }

  get default(): any {
    return 'center';
  }

  buildSchema() {
    return yup.string().nullable();
  }
}

const definition = new TextAlignmentType();

Types.addBaseType(definition);

export default definition;
