import React from 'react';

import { ICON_SET_ION } from 'lane-shared/helpers/constants/icons';

import LinkButton from '../general/LinkButton';
import openGoogleCalendarLink from 'helpers/openGoogleCalendarLink';

type ContentFeatureGoogleCalendarButtonProps = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  name: string;
  description: string;
  startDate: Date | string | undefined;
  endDate: Date | string | undefined;
  timeZone: string | undefined;
};

export default function ContentFeatureGoogleCalendarButton({
  className,
  style,
  disabled = false,
  loading = false,
  name,
  description,
  startDate,
  endDate,
}: ContentFeatureGoogleCalendarButtonProps) {
  if (!(startDate && endDate)) {
    return null;
  }

  return (
    <LinkButton
      className={className}
      style={style}
      disabled={disabled}
      loading={loading}
      iconName="LogoGoogle"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"Ion"' is not assignable to type '"FontAweso... Remove this comment to see the full error message
      iconSet={ICON_SET_ION}
      onClick={() =>
        openGoogleCalendarLink(startDate, endDate, name, description)
      }
      doTranslate
    >
      Add to Google Calendar
    </LinkButton>
  );
}
