import gql from 'graphql-tag';

export const getAccessPointList = gql`
  query GetAccessPointList($buildingId: ID!) {
    getAccessPointList(buildingId: $buildingId) {
      id
      buildingId
      name
      description
    }
  }
`;

export const createAccessPointMutation = gql`
  mutation CreateAccessPoint($input: CreateAccessPointRequest!) {
    createAccessPoint(input: $input) {
      id
      buildingId
      name
      description
    }
  }
`;

export const triggerAccessPoint = gql`
  mutation TriggerAccessPoint($id: ID!) {
    triggerAccessPoint(id: $id) {
      message
    }
  }
`;
