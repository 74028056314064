import React from 'react';

import cx from 'classnames';

import convertMjmlStyleProps from './convertMjmlStyleProps';

import styles from './styles.scss';

export default function MjmlButton({
  className,
  style,
  children,
  ...otherProps
}: any) {
  switch (otherProps?.align) {
    case 'right':
      otherProps.alignSelf = 'flex-end';
      break;
    case 'left':
      otherProps.alignSelf = 'flex-start';
      break;
    case 'center':
      otherProps.alignSelf = 'center';
      break;
    default:
      break;
  }
  return (
    <div
      className={cx(styles.MjmlButton, className)}
      style={{
        ...style,
        ...convertMjmlStyleProps(otherProps),
      }}
    >
      {children}
    </div>
  );
}
