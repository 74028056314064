export enum ContentCategoryEnum {
  Access = 'Access',
  Advertising = 'Advertising',
  Amenity = 'Amenity',
  Announcement = 'Announcement',
  Audio = 'Audio',
  Award = 'Award',
  BasicPost = 'Basic Post',
  BikeLocker = 'Bike Locker',
  BuildingInformation = 'Building Information',
  BuildingProfile = 'Building Profile',
  Catering = 'Catering',
  Charity = 'Charity',
  Childcare = 'Childcare',
  Community = 'Community',
  Commuting = 'Commuting',
  Conference = 'Conference',
  Contest = 'Contest',
  COVIDScreener = 'COVID Screener',
  CoWorking = 'Coworking',
  CRM = 'CRM',
  Culture = 'Culture',
  DayPass = 'Day Pass',
  Education = 'Education',
  Entertainment = 'Entertainment',
  Event = 'Event',
  Facilities = 'Facilities',
  Fashion = 'Fashion',
  Feedback = 'Feedback',
  Fitness = 'Fitness',
  FlexDesk = 'Flex Desk',
  Food = 'Food',
  FoodOrdering = 'Food Ordering',
  Form = 'Form',
  FreightElevator = 'Freight Elevator',
  Fundraising = 'Fundraising',
  Games = 'Games',
  Health = 'Health',
  HelpDesk = 'Help Desk',
  HotDeskMembership = 'Hot Desk Membership',
  HourlyWalkthrough = 'Hourly Walkthrough',
  LocationProfile = 'Location Profile',
  Maintenance = 'Maintenance',
  Membership = 'Membership',
  Meetup = 'Meetup',
  Moving = 'Moving',
  Music = 'Music',
  News = 'News',
  Newsletter = 'Newsletter',
  None = 'None',
  Notice = 'Notice',
  Onboarding = 'Onboarding',
  OnlineLeasing = 'Online Leasing',
  OnlinePlan = 'Online Plan',
  Operations = 'Operations',
  Other = 'Other',
  Parking = 'Parking',
  Perks = 'Perks',
  Poll = 'Poll',
  Rentals = 'Rentals',
  ResourceBooking = 'Resource Booking',
  RetailFeature = 'Retail Feature',
  RetailPromotion = 'Retail Promotion',
  RoomBooking = 'Room Booking',
  RSVP = 'RSVP',
  Safety = 'Safety',
  Security = 'Security',
  Service = 'Service',
  ServiceRequest = 'Service Request',
  SharedSpace = 'Shared Space',
  SpaceBooking = 'Space Booking',
  Sports = 'Sports',
  Support = 'Support',
  Survey = 'Survey',
  Sustainability = 'Sustainability',
  TenantHandbook = 'Tenant Handbook',
  Training = 'Training',
  Transportation = 'Transportation',
  Travel = 'Travel',
  Tutorial = 'Tutorial',
  Vendor = 'Vendor',
  Video = 'Video',
  VisitorManagement = 'Visitor Management',
  Visitors = 'Visitors',
  VirtualOfficeMembership = 'Virtual Office Membership',
  Wellness = 'Wellness',
  WellnessProgramming = 'Wellness Programming',
  WiFi = 'WiFi',
  Workshop = 'Workshop',
  Messaging = 'Messaging',
}

export const CONTENT_CATEGORIES = [
  {
    label: ContentCategoryEnum.Other,
    value: ContentCategoryEnum.Other,
  },
  ...Object.entries(ContentCategoryEnum)
    .filter(([, category]) => category !== ContentCategoryEnum.Other)
    .map(([, value]) => ({
      label: value,
      value,
    })),
];
