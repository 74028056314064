// TODO: This should be removed once we allow for custom statuses
export enum ServiceRequestStatusEnum {
  Created = 'Created',
  Accepted = 'Accepted',
  Open = 'Open',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Closed = 'Closed',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
}
