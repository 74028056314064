import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

type Props = {
  isAuthPurchaseWrapper?: boolean;
  navigateToSignUp?: () => void;
};

export default function SignupPhrase({
  navigateToSignUp,
  isAuthPurchaseWrapper,
}: Props) {
  const { t } = useTranslation();

  if (navigateToSignUp || isAuthPurchaseWrapper)
    return (
      <a
        className={welcomeFormStyles.link}
        role="link"
        tabIndex={0}
        onClick={navigateToSignUp}
      >
        {t('Sign up')}
      </a>
    );

  return (
    <Link
      data-cy="signUp"
      className={welcomeFormStyles.link}
      to={routes.signUp}
    >
      {t('Sign up')}
    </Link>
  );
}
