import React, { memo } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { LaneType } from 'common-types';
import { colors } from 'lane-shared/config';
import { IconSet } from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';

import styles from './StandardPinView.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  backgroundImage: LaneType.UUID | { _id: LaneType.UUID } | undefined;
  backgroundColor: string | undefined;
  name: string | undefined;
  logo: LaneType.UUID | { _id: LaneType.UUID } | undefined;
  icon: string | undefined;
  iconSet: IconSet | undefined;
  iconWeight: 'far' | 'fal' | 'fab' | undefined;
};

function StandardPinView({
  className,
  style,
  backgroundImage,
  backgroundColor,
  name,
  logo,
  icon,
  iconSet,
  iconWeight,
}: Props) {
  const backgroundImages = [
    'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%)',
  ];

  if (backgroundImage) {
    backgroundImages.push(`url(${imageUrl(backgroundImage)})`);
  }

  let renderLogo;

  if (logo) {
    renderLogo = (
      <div
        className={styles.logo}
        style={{ backgroundImage: `url(${imageUrl(logo)})` }}
      />
    );
  } else if (icon) {
    renderLogo = (
      <div className={styles.logo}>
        <Icon
          className={styles.icon}
          name={icon}
          set={iconSet}
          type={iconWeight}
        />
      </div>
    );
  } else {
    renderLogo = <div className={styles.logo} />;
  }

  return (
    <div
      className={cx(styles.StandardPinView, className)}
      style={{
        ...style,
        backgroundImage: backgroundImages.join(', '),
        backgroundColor,
        color: colors.white,
      }}
    >
      {renderLogo}
      <div className={styles.text}>
        <h1>{name}</h1>
      </div>
    </div>
  );
}

export default memo(StandardPinView);
