import atob from 'atob';

export default function dataUriToBlob(dataURI: any) {
  const mimeType = dataURI
    .split(',')[0]
    .replace('data:', '')
    .replace(';base64', '');

  const binary = atob(dataURI.split(',')[1]);
  const array = [];

  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: mimeType });
}
