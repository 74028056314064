const HOM_IMAGES = [
  '2g3rnb3zmptEZOnZw3gJyP',
  '5XJUcXuTRMysJwgnG8lRKG',
  '6KPQ057qkBg1v7EtKfH9o5',
  '5HzTo9x0qgVVkldDQJNN8H',
  '7jOss7yrhU8NyOy2fXbHq9',
  '6FUjc5MqR5xyBEH9081GdI',
  '1BcHG9qwyf0waiXx9Yccsz',
  '2zWHc3Mx4zNQMKdssVwqP7',
];

export default HOM_IMAGES;
