import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesArrayMin } from './ITypeValidatesArrayMin';

export class ArrayMinValidator
  implements ValidatorInterface<ITypeValidatesArrayMin> {
  name = 'ArrayMin';

  friendlyName = 'List Minimum Size';

  arrayOnly = true;

  array = true;

  description = 'Minimum number of entries for this list.';

  types = null;

  properties = {
    value: {
      type: 'Number',
      default: 1,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 0,
        },
        {
          name: 'Max',
          value: Number.MAX_SAFE_INTEGER,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesArrayMin) {
    type.addArrayMinValidator(this);
  }

  constructor(public config?: Validator) {}
}

const ArrayMin = new ArrayMinValidator(undefined);

ValidatorRegistry.register(ArrayMinValidator, ArrayMin.name);

export default ArrayMin;
