export enum FeatureNameEnum {
  Cancelable = 'Cancelable',
  Deliverable = 'Deliverable',
  Entries = 'Entries',
  EssensysProduct = 'EssensysProduct',
  Inventory = 'Inventory',
  GuestInvite = 'GuestInvite',
  Menu = 'Menu',
  Payment = 'Payment',
  OutlookCalendar = 'OutlookCalendar',
  GoogleCalendar = 'GoogleCalendar',
  QRCodeCheckin = 'QRCodeCheckin',
  Quantity = 'Quantity',
  RemoteFetch = 'RemoteFetch',
  Requirements = 'Requirements',
  Reservable = 'Reservable',
  Reset = 'Reset',
  /**
   * @deprecated Scheduled is deprecated. Use Reservable instead.
   */
  Scheduled = 'Scheduled',
  Shipping = 'Shipping',
  SocialOptions = 'SocialOptions',
  Statuses = 'Statuses',
  SubmitOnBehalfOf = 'SubmitOnBehalfOf',
  TimeAvailability = 'TimeAvailability',
  UseCompanyPermissions = 'UseCompanyPermissions',
  VisitorManagement = 'VisitorManagement',
  WorkOrder = 'WorkOrder',
  Surveys = 'Surveys',
}
