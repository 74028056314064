import * as yup from 'yup';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';

const properties: PropertiesInterface = {
  channelIds: {
    type: 'String',
    isArray: true,
    default: [],
    editable: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  units: {
    type: 'Number',
    default: 0,
    editable: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  forPrimaryChannelUsersOnly: {
    type: 'Boolean',
    default: false,
    editable: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  canClaimBeyondAllocatedUnits: {
    type: 'Boolean',
    default: true,
    editable: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class QuantityAllocationsType extends ObjectTypeBase {
  name = 'QuantityAllocations';

  friendlyName = 'Quantity Allocations';

  contexts = AllTypeContexts;

  get example() {
    return {
      channelIds: [],
      units: 0,
      forPrimaryChannelUsersOnly: false,
      canClaimBeyondAllocatedUnits: true,
    };
  }

  get default(): any {
    return {
      channelIds: [],
      units: 0,
      forPrimaryChannelUsersOnly: false,
      canClaimBeyondAllocatedUnits: true,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new QuantityAllocationsType();

Types.addType(definition);
export default definition;
