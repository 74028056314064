import urlJoin from 'url-join';

import { routes } from '../../config';
import { WhiteLabelType } from '../../types/WhiteLabelType';
import { convertTo62 } from '../convertId';

export default function createMagicLinkUrl(
  verifyCode: string,
  userGuid: string,
  whiteLabel: WhiteLabelType
): string {
  return urlJoin(
    whiteLabel.baseUrl,
    routes.magicLink
      .replace(':verify', convertTo62(verifyCode))
      .replace(':guid', convertTo62(userGuid))
  );
}
