import React from 'react';

import { Icon } from 'design-system-web';

import { IconSet } from 'lane-shared/helpers/constants/icons';

type IconTypeValueProps = {
  className?: string;
  style?: React.CSSProperties;
  value: {
    name: string;
    set: IconSet;
    color: string;
  };
};

function IconTypeValue({ className, style, value }: IconTypeValueProps) {
  if (!value.name) {
    return null;
  }

  return (
    <Icon
      className={className}
      name={value.name}
      set={value.set}
      style={{
        ...style,
        color: value.color,
        width: '1.8em',
        height: '1.8em',
      }}
    />
  );
}

export default IconTypeValue;
