import { useState, useEffect, useMemo } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { ApolloError, NetworkStatus, useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { queryChannels } from '../graphql/channel';
import { ChannelType } from '../types/ChannelType';

const DEFAULT_PER_PAGE = 10;
const EMPTY_ARRAY: any = [];

type Props = {
  variables?: {
    pagination: {
      start: number;
      perPage: number;
    };
    search: any;
  };
  skip?: boolean;
};

type ChannelSearchResults = {
  called: boolean;
  loading: boolean;
  error: ApolloError | undefined;
  channels: ChannelType[];
  fetchMore: () => void;
  refetch: () => void;
  networkStatus: NetworkStatus;
};

export default function useChannelsSearchQuery({
  variables,
  skip,
}: Props): ChannelSearchResults {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [
    fetchChannels,
    {
      data,
      loading,
      error: queryError,
      fetchMore,
      called,
      refetch,
      networkStatus,
    },
  ] = useLazyQuery(queryChannels, {
    client: getClient(),
    fetchPolicy: 'network-only',
  });

  const channels = useMemo<ChannelType[]>(
    () => data?.channels?.items || EMPTY_ARRAY,
    [data?.channels?.items]
  );

  const pageInfo = useMemo(
    () =>
      data?.channels?.pageInfo || {
        total: 0,
        start: 0,
        perPage: variables?.pagination?.perPage || DEFAULT_PER_PAGE,
      },
    [data?.channels?.pageInfo]
  );

  const debouncedDoFetch = useDebouncedCallback(function doFetch() {
    fetchChannels({ variables });
  }, 250).callback;

  useEffect(() => {
    if (!skip && variables) {
      debouncedDoFetch();
    }
  }, [JSON.stringify(variables), skip]);

  async function doFetchMore() {
    if (loading) {
      return;
    }

    const perPage = pageInfo.perPage;

    if (pageInfo.start + perPage >= pageInfo.total) {
      // No more results.
      return;
    }

    try {
      await fetchMore({
        variables: {
          pagination: {
            start: pageInfo.start + perPage,
            perPage,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // Don't do anything if there weren't any new items
          if (
            fetchMoreResult?.channels?.items?.length !== undefined &&
            fetchMoreResult.channels.items.length === 0
          ) {
            return prev;
          }

          return {
            // Concatenate the new feed results after the old ones
            channels: {
              __typename: 'channels',
              pageInfo: fetchMoreResult.channels.pageInfo,
              items: [
                ...prev.channels.items,
                ...fetchMoreResult.channels.items,
              ],
            },
          };
        },
      });
    } catch (err) {
      setError(err);
    }
  }

  return {
    called,
    loading,
    error: error || queryError,
    channels,
    fetchMore: doFetchMore,
    refetch,
    networkStatus,
  };
}
