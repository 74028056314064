/**
 * Takes in an Address object, and returns a URL for opening that address
 * in either Apple Maps on iOS or Google Maps on Android.
 *
 * @param address
 * @returns {string}
 */
export default function longAddressFormatter(address: any) {
  if (!address) {
    return '';
  }

  const props = ['street1', 'street2', 'city', 'state', 'country'];

  let ret = 'http://maps.apple.com/?daddr=';

  for (const prop of props) {
    if (address[prop]) {
      if (ret) {
        ret += ',';
      }

      ret += address[prop].replace(/ /gi, '+');
    }
  }

  return ret;
}
