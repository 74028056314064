import { DateTime } from 'luxon';

import parseDateTime from '../dates/parseDateTime';

export default function fromNow(
  date: DateTime | Date | string | null | undefined,
  timeZone?: string,
  locale?: string
): string {
  if (!date) {
    return ' …';
  }

  if (!locale) {
    locale = 'en';
  }

  return parseDateTime(date, timeZone)!
    .setLocale(locale)
    .toRelative() as string;
}
