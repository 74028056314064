import React from 'react';

import { Pagination } from 'components/general';
import { Flex } from 'components/layout';

import Table, { Props as TableProps } from './Table';
import { UrlSearchParamsWithPages } from './types';
import useQueryString from 'hooks/useQueryString';

import styles from './styles.scss';

type Props = Omit<TableProps, 'sortingIsDoneOn'>;

/**
 * An extended version of the Table component.
 *
 * This Table will not handle sorting on the client side. To sort the
 * rows, use the correct sortKey in the colummns prop that is used as a search key on the server
 * and listen to changes in the url.
 */
/**
 * @deprecated This component is deprecated - use `Table` exported from `design-system-web` instead.
 */
export default function PaginatedTable(props: Props) {
  const [
    urlSearchParams,
    setUrlSearchParams,
  ] = useQueryString<UrlSearchParamsWithPages>();

  return (
    <Flex direction="column" gap={3} className={styles.paginatedTableContainer}>
      <Table {...props} sortingIsDoneOn="server" />
      {urlSearchParams?.total > 0 && (
        <Pagination
          perPage={Number(urlSearchParams.perPage)}
          page={Number(urlSearchParams.page)}
          total={Number(urlSearchParams.total)}
          pagesToShow={3}
          className={styles.pagination}
          onPage={page => {
            setUrlSearchParams({ page });
          }}
        />
      )}
    </Flex>
  );
}
