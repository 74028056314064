import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import Features from 'lane-shared/renderers/v5/features';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';

import { H1, M } from 'components/typography';

type FeatureRendererWrapperProps = {
  className?: string;
  style?: React.CSSProperties;
  feature: FeatureDefinition;
  children: ReactNode;
};

export default function FeatureRendererWrapper({
  feature,
  className,
  style,
  children,
}: FeatureRendererWrapperProps) {
  const featureDefinition: FeatureDefinition = Features[feature.name];
  const { t } = useTranslation();

  return (
    <section className={className} style={style}>
      <H1>{t(featureDefinition.friendlyName || '')}</H1>
      <M>{t(featureDefinition.description)}</M>
      {children}
    </section>
  );
}
