import { useState, useEffect, useMemo } from 'react';

import gql from 'graphql-tag';
import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { PublicUserFragment } from '../graphql/fragments';
import { SortDirection } from '../types/filters/SortDirection';

const query = gql`
  ${PublicUserFragment}

  query interactionsOnChannelQuery(
    $channelId: UUID!
    $sectionId: UUID
    $paymentAccountId: UUID
    $merchantAccountId: UUID
    $search: UserContentInteractionSearch
    $pagination: PaginationInput
  ) {
    interactionsOnChannel(
      channelId: $channelId
      sectionId: $sectionId
      paymentAccountId: $paymentAccountId
      merchantAccountId: $merchantAccountId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        user {
          _id
          name
          profile {
            _id
            name
            image
            logo
          }
        }
        startDate
        endDate
        geo
        data
        state
        features
        contentData
        version
        status
      }
    }
  }
`;

export interface variablesType {
  channelId: LaneType.UUID;
  sectionId: LaneType.UUID;
  merchantAccountId: LaneType.UUID;
  paymentAccountId: LaneType.UUID;
  pagination: {
    start: number;
    perPage: number;
  };
  search: {
    searchValue?: {};
    sortBy: {
      key: string;
      dir: SortDirection;
    };
    state?: {
      isOpen: boolean;
      isActive: boolean;
    };
  };
}

const defaultSearch = {};

export default function useInteractionsOnChannelQuery({
  channelId,
  paymentAccountId,
  sectionId,
  merchantAccountId,
  selectedSort = '_updated',
  selectedOrder = 'asc',
  selectedState = 'any',
  perPage = 100,
  search = defaultSearch,
}: any) {
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(search);

  const debouncedSearchValue = useDebouncedCallback(function () {
    setSearchValue(search);
  }, 500).callback;

  useEffect(() => {
    if (!loading) {
      debouncedSearchValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(search)]);

  const [fetchInteractions, { data, loading, error }] = useLazyQuery(query, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
  });

  const interactions = useMemo(() => {
    if (data?.interactionsOnChannel?.items) {
      return data.interactionsOnChannel.items;
    }

    return [];
  }, [data?.interactionsOnChannel?.items]);

  const pageInfo = useMemo(() => {
    if (data?.interactionsOnChannel?.pageInfo) {
      return data.interactionsOnChannel.pageInfo;
    }

    return {
      total: 0,
      start: 0,
      perPage,
    };
  }, [data?.interactionsOnChannel?.pageInfo]);

  const variables = useMemo(() => {
    const variables: variablesType = {
      channelId,
      sectionId,
      merchantAccountId,
      paymentAccountId,
      pagination: {
        start: page * perPage,
        perPage,
      },
      search: {
        ...searchValue,
        sortBy: {
          key: selectedSort,
          dir: selectedOrder,
        },
      },
    };

    if (selectedState === 'open') {
      variables.search.state = {
        isOpen: true,
        isActive: true,
      };
    } else if (selectedState === 'closed') {
      variables.search.state = {
        isOpen: false,
        isActive: false,
      };
    }

    return variables;
  }, [
    channelId,
    sectionId,
    paymentAccountId,
    merchantAccountId,
    page,
    perPage,
    selectedOrder,
    selectedSort,
    selectedState,
    searchValue,
  ]);

  function refresh() {
    fetchInteractions({
      variables,
    });
  }

  useEffect(() => {
    if (
      (channelId || sectionId || paymentAccountId || merchantAccountId) &&
      !loading
    ) {
      fetchInteractions({
        variables,
      });
    }
  }, [variables]);

  /*
   * This is a fix for TM-2468
   * due to the need to fetch interactions again when both channelId and sectionId is available.
   *
   * Not changing the logic of useEffect above because this hook is also used elsewhere.
   * */
  useEffect(() => {
    if (variables.channelId && variables.sectionId) {
      fetchInteractions({
        variables,
      });
    }
  }, [variables]);

  return {
    interactions,
    pageInfo,
    page,
    perPage,
    loading,
    error,
    setPage,
    refresh,
    variables,
  };
}
