import { useState, useEffect } from 'react';

import { LaneType } from 'common-types';
import { getAttachmentsByEntity } from 'lane-shared/helpers/attachment/getAttachmentsByEntity';
import { AttachmentResponse } from 'lane-shared/types/attachment';

type Props = {
  entityId: LaneType.UUID;
  editMode: boolean;
};

export function useAttachmentByEntityData({ entityId, editMode }: Props) {
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentResponse[]>([]);

  async function fetchAttachments() {
    if (!editMode && entityId) {
      setLoading(true);
      const attachments = await getAttachmentsByEntity({ entityId });
      setAttachments(attachments);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchAttachments();
  }, [entityId]);

  return { fetchAttachments, loading, attachments };
}
