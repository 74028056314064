import React, { ReactNode } from 'react';

import { Icon } from 'design-system-web';

import Checkbox from 'components/form/Checkbox';
import ContextMenu, { ModalPositionEnum } from 'components/general/ContextMenu';

import IconButton from '../../general/IconButton';
import { Row, Column, Cell } from './types';

import styles from './styles.scss';

const CHECKBOX_TD_WIDTH = 20;

type Props = {
  row: Row;
  columns: Column[];
  rowsAreSelectable: boolean;
  isSelected: boolean;
  onRowCheckBoxSelect: () => void;
};

/**
 * Render a row and display a checkbox if the rows are selectable.
 */
export default function TableRow({
  row,
  columns,
  isSelected,
  rowsAreSelectable,
  onRowCheckBoxSelect,
}: Props) {
  return (
    <tr key={row.id} data-selected={isSelected}>
      {rowsAreSelectable && (
        <td width={CHECKBOX_TD_WIDTH}>
          <Checkbox
            value={row.id}
            selected={isSelected}
            onClick={onRowCheckBoxSelect}
            className={styles.checkBox}
          />
        </td>
      )}
      {columns.map(column => {
        const cell: Cell | undefined = row.items.find(
          item => item.key === column.key
        );
        if (cell === undefined) {
          return (
            <td>
              <i style={{ color: 'lightcoral' }}>
                No column key matches this cell
              </i>
            </td>
          );
        }

        let columnItem: ReactNode;

        function assertNever(_cell: never): never {
          throw new Error(
            `Unexpected cell type. Cell type should be one of ["string", "number", "date", "custom"].`
          );
        }

        switch (cell.type) {
          case 'string':
            columnItem = cell.renderer ? cell.renderer(cell.value) : cell.value;
            break;
          case 'number':
            columnItem = cell.renderer ? cell.renderer(cell.value) : cell.value;
            break;
          case 'date':
            columnItem = cell.renderer ? cell.renderer(cell.value) : cell.value;
            break;
          case 'custom':
            columnItem = cell.renderer();
            break;
          default:
            return assertNever(cell);
        }
        return (
          <td
            className={
              column.key === 'status' ? styles.tdStatus : styles.tdNormal
            }
            key={column.key}
          >
            {columnItem}
          </td>
        );
      })}
      {row?.actions && (
        <td>
          <ContextMenu
            align={ModalPositionEnum.Left}
            items={
              <div className={styles.actions}>
                {row.actions.map((action, i) => (
                  <button
                    key={i}
                    className={styles.actionButton}
                    onClick={action.onClick}
                  >
                    {action.iconName && (
                      <Icon name={action.iconName} set="FontAwesome" />
                    )}
                    {action.label}
                  </button>
                ))}
              </div>
            }
            hasOpaqueBackground={false}
          >
            <IconButton icon="ellipsis-v" iconSet="FontAwesome" />
          </ContextMenu>
        </td>
      )}
    </tr>
  );
}
