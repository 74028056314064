import React from 'react';

import { Key } from 'ts-key-enum';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';

import styles from './MenuItemOption.scss';

export default function MenuItemOption({
  disabled,
  loading,
  selected,
  option,
  onOrderOptionSelected,
  isRadio,
  isCheckbox,
  currencyFormat,
}: any) {
  const price = parseInt(option.price, 10);
  const priceText = price ? currencyFormat(price / CURRENCY_CONVERSION) : '';

  return (
    <div
      className={styles.MenuItemOption}
      onClick={() => onOrderOptionSelected(option)}
      role="button"
      tabIndex={0}
      onKeyPress={e => e.key === Key.Enter && onOrderOptionSelected(option)}
      data-selected={selected}
      data-loading={loading}
      data-disabled={disabled}
      data-is-radio={isRadio}
      data-is-checkbox={isCheckbox}
    >
      <div className={styles.control}>
        <div className={styles.inner}>
          <div className={styles.dot} />
        </div>
      </div>
      <div className={styles.text}>
        <h1>{option.name}</h1>
        <p>{priceText}</p>
      </div>
    </div>
  );
}
