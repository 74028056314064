import * as yup from 'yup';

import {
  validateChannelBase,
  validateChannelSettings,
  validateChannelInfo,
} from './channel';
import './fixMessages';

export default yup
  .object()
  .concat(validateChannelBase)
  .concat(validateChannelInfo)
  .concat(validateChannelSettings);
