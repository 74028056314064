import React, { useState, createContext, useContext } from 'react';

type ModalContextType = {
  showModal: (node: React.ReactNode) => void;
  closeModal: () => void;
};

export const ModalContext = createContext({} as ModalContextType);

type Props = {
  children: React.ReactNode;
};

/**
 * @deprecated POD-594 remove this context provider
 */
export function ModalContextProvider({ children }: Props) {
  const [ReactNode, setReactNode] = useState<React.ReactNode | null>(null);

  function showModal(node: React.ReactNode) {
    setReactNode(node);
  }

  function closeModal() {
    setReactNode(null);
  }

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      {ReactNode}
    </ModalContext.Provider>
  );
}

export const useModalContext = () => useContext(ModalContext);
