import * as yup from 'yup';

import i18n from 'localization';

export default yup.object().shape({
  settings: yup.object().shape({
    __typename: yup.string().strip(true),
    subscribersJoinParent: yup.boolean().default(false),
    subscribersAreDecoyed: yup.boolean().default(false).nullable(),
    groupRoleJoinRules: yup
      .array()
      .of(
        yup.object().shape({
          groupRoleId: yup.string(),
          joinGroupRoleId: yup.string(),
          channelId: yup.string(),
        })
      )
      .nullable(),
    hasPerksEnabled: yup.boolean().default(false),
    areUserTemplatesAllowed: yup.boolean().default(false),
    multiLanguageEnabled: yup.boolean().nullable().default(null),
    enabledLanguagesForMembers: yup.object().nullable().default(null),
    channelLanguages: yup
      .object()
      .nullable()
      .shape({
        primary: yup.string().nullable(),
        secondary: yup.string().nullable(),
      })
      .test(
        'no_empty_channel_languages',
        i18n.t(
          'shared.validation.channel.settings.language.multiLanguage.languages.required'
        ),
        function (value) {
          const errors = [];
          if (this.parent.multiLanguageEnabled && !value?.primary) {
            errors.push(
              new yup.ValidationError(
                i18n.t(
                  'shared.validation.channel.settings.language.multiLanguage.primaryLanguage.required'
                ),
                '',
                `${this.path}.primary`
              )
            );
          }
          if (this.parent.multiLanguageEnabled && !value?.secondary) {
            errors.push(
              new yup.ValidationError(
                i18n.t(
                  'shared.validation.channel.settings.language.multiLanguage.secondaryLanguage.required'
                ),
                '',
                `${this.path}.secondary`
              )
            );
          }
          // TypeScript expects `errors` to be string | string[]
          // however passing ValidationError[] as `errors` works becuase
          // in the constructor of ValidationError, they basically flatten all nested validation
          // errors (see https://github.com/jquense/yup/blob/v0.28.5/src/ValidationError.js#L17-L22).
          // We use Yup v0.28.5. In the latest version of Yup (v1.2.0), the type of `errors`
          // is actually string | ValidationError | ValidationError[]
          return errors.length
            ? // @ts-expect-error
              new yup.ValidationError(errors, '', this.path)
            : true;
        }
      ),
    offboardingDelayDays: yup
      .number()
      .transform(
        v => (Number.isNaN(v) ? undefined : v) // Empty values should not run any validation
      )
      .when(
        '$hasCustomOffboarding',
        (value, schema) =>
          value
            ? schema.required().label('Days post move-out date').min(1).max(365) // arbitrary 1 year max to prevent entering past integer limit
            : schema // Field is validated only when custom offboarding enabled
      ),
  }),
  isPublic: yup.boolean().required(),
  isDiscoverable: yup.boolean().required(),
  isSub: yup.boolean().required(),
  inviteOnly: yup.boolean().required(),
  filters: yup.array().of(yup.string()).nullable(),
  hasCustomOffboarding: yup.boolean().nullable(),
  hasMessagingAndMarketplaceEnabled: yup.boolean().nullable(),
});
