import React, { memo } from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useTrackChannelView } from 'lane-shared/hooks/analytics';
import { DocumentType } from 'lane-shared/types/DocumentType';

import Thumbnail from '../general/Thumbnail';

import styles from './ChannelDirectoryListView.scss';

type OwnProps = {
  channel?: DocumentType;
  className?: string;
  onClick?: (...args: any[]) => any;
  style?: {};
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof ChannelDirectoryListView.defaultProps;

function ChannelDirectoryListView({
  className,
  channel,
  onClick,
  style,
}: Props) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  useTrackChannelView(channel);

  return (
    <div
      className={cx(styles.ChannelDirectoryListView, className)}
      style={style}
      role="button"
      tabIndex={0}
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      <Thumbnail
        className={styles.imageWrapper}
        src={
          imageUrl((channel as any).profile.logo) ||
          imageUrl((channel as any).profile.image)
        }
        name={(channel as any).name}
      />
      <div className={styles.textWrapper}>
        <h1>{getDisplayName(channel)}</h1>
        <p>{(channel as any).address.street1}</p>
        <p>{(channel as any).address.city}</p>
      </div>
    </div>
  );
}

ChannelDirectoryListView.defaultProps = {
  channel: {},
  className: '',
  onClick: () => {},
  style: {},
};

export default memo(ChannelDirectoryListView);
