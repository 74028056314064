import { DateTime } from 'luxon';

import parseDateTime from '../dates/parseDateTime';

type DateSortableType = {
  startDate: string | Date | DateTime | null;
};

export default function sortByStartAndEndDate(
  a: DateSortableType,
  b: DateSortableType
): number {
  const aStart = parseDateTime(a.startDate)?.toMillis() || 0;
  const bStart = parseDateTime(b.startDate)?.toMillis() || 0;

  return bStart - aStart;
}
