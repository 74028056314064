import React from 'react';

import { useTranslation } from 'react-i18next';

import routes from 'lane-shared/config/routes';

import ErrorMessage from './ErrorMessage';

import styles from './ErrorPage.scss';

/**
 * Used for when an error occurs, 404s, or a resource was not found.
 */
export default function ErrorPage({ error }: { error: Error }) {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <ErrorMessage error={error} />
      <a href={routes.home} className={styles.homeLink}>
        {t('Return to Home')}
      </a>
    </div>
  );
}
