import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { DAYS_OF_THE_WEEK } from 'lane-shared/helpers/constants/timeUnits';
import {
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  TimeRangeType,
} from 'lane-shared/types/baseTypes/TimeRangeType';
import {
  TimeAvailabilityDayRule,
  WeeklyAvailability as WeeklyAvailabilityType,
} from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

import Checkbox from 'components/form/Checkbox';
import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';
import Label from 'components/general/Label';
import TimeRangeInput from 'components/lane/TimeRangeInput';
import { Flex } from 'components/layout';
import { H5 } from 'components/typography';

import styles from './WeeklyTimeRangePicker.scss';

const TRANSLATION_KEYS: { [key: string]: string } = {
  Sunday: 'web.content.features.components.DayPriceTimeRange.sunday',
  Monday: 'web.content.features.components.DayPriceTimeRange.monday',
  Tuesday: 'web.content.features.components.DayPriceTimeRange.tuesday',
  Wednesday: 'web.content.features.components.DayPriceTimeRange.wednesday',
  Thursday: 'web.content.features.components.DayPriceTimeRange.thursday',
  Friday: 'web.content.features.components.DayPriceTimeRange.friday',
  Saturday: 'web.content.features.components.DayPriceTimeRange.saturday',
  addHours: 'web.content.features.components.DayPriceTimeRange.addHours',
  allDay: 'web.content.features.TimeAvailability.WeeklyTimeRangePicker.allDay',
  to: 'web.content.features.TimeAvailability.WeeklyTimeRangePicker.to',
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  weeklyAvailability: WeeklyAvailabilityType;
  onTimeRangeSelect: (
    availabilityId: LaneType.UUID,
    dayKey: string,
    timeRangeId: LaneType.UUID,
    timeRange: TimeRangeType
  ) => void;
  onAddTimeRange: (availabilityId: LaneType.UUID, dayKey: string) => void;
  onRemoveTimeRange: (
    availabilityId: LaneType.UUID,
    dayKey: string,
    timeRangeId: LaneType.UUID
  ) => void;
  onAllDayToggle: (availabilityId: LaneType.UUID, dayKey: string) => void;
  onToggleOpen: (
    availabilityId: LaneType.UUID,
    dayKey: string,
    isOpen: boolean
  ) => void;
  isEditing: boolean;
  timeZone?: string;
};

/**
 * For some reason iterating through WeeklyAvailability doesn't preserve the
 * day order, so we iterate through DAYS_OF_THE_WEEK constant and index into
 * WeeklyAvailability instead
 */
const daysOfTheWeek = Object.entries(DAYS_OF_THE_WEEK);

export default function WeeklyTimeRangePicker({
  className,
  style,
  weeklyAvailability,
  onTimeRangeSelect,
  onAddTimeRange,
  onRemoveTimeRange,
  onToggleOpen,
  onAllDayToggle,
  isEditing,
  timeZone,
}: Props) {
  const { t } = useTranslation();

  function renderTimeRanges({
    _id,
    startTime,
    endTime,
    index,
    dayKey,
    dayRule,
  }: any) {
    return (
      <div key={_id} className={styles.timeRangeInputContainer}>
        <Flex direction="column" align="self-start" justify="center">
          <Flex justify="flex-start" align="center">
            <TimeRangeInput
              className={styles.timeRangeInput}
              onChange={value =>
                onTimeRangeSelect(weeklyAvailability._id, dayKey, _id, value)
              }
              value={{
                startTime: startTime || DEFAULT_START_TIME,
                endTime: endTime || DEFAULT_END_TIME,
              }}
              timeZone={timeZone}
            />

            {(index !== 0 || dayRule.timeRanges.length > 1) && (
              <Icon
                onClick={() =>
                  onRemoveTimeRange(weeklyAvailability._id, dayKey, _id!)
                }
                name="times"
                className={styles.removeIcon}
                set={ICON_SET_FONTAWESOME}
              />
            )}
          </Flex>
        </Flex>

        {renderAddHoursButton({ dayRule, dayKey, index })}
      </div>
    );
  }

  function renderAddHoursButton({ index, dayRule, dayKey }: any) {
    return (
      index === dayRule.timeRanges.length - 1 && (
        <Button
          size="small"
          variant="outlined"
          className={styles.addButton}
          onClick={() => onAddTimeRange(weeklyAvailability._id, dayKey)}
        >
          {t(TRANSLATION_KEYS.addHours || '')}
        </Button>
      )
    );
  }
  return (
    <div className={cx(styles.WeeklyTimeRangePicker, className)} style={style}>
      {daysOfTheWeek.map(([dayKey, dayObject]) => {
        // Every dayKey should exist in the weekTimeRules, but sanity check just in case
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (!weeklyAvailability?.weekTimeRules?.[dayKey]) {
          return null;
        }

        const dayRule: TimeAvailabilityDayRule =
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          weeklyAvailability.weekTimeRules[dayKey];

        return (
          <div key={dayKey} className={styles.dayHourRanges}>
            <H5 className={styles.dayLabel}>
              {t(TRANSLATION_KEYS[dayObject.name] || '')}
            </H5>
            {!dayRule.isOpen && <div className={styles.spacer} />}

            {dayRule.isOpen && (
              <div className={styles.dayTimeRange}>
                {isEditing ? (
                  <>
                    <Checkbox
                      className={styles.allDayCheckbox}
                      onChange={() =>
                        onAllDayToggle(weeklyAvailability._id, dayKey)
                      }
                      text={t(TRANSLATION_KEYS.allDay || '')}
                      selected={dayRule.isAvailableAllDay}
                      value
                      dataCy="allDayCheckbox"
                    />

                    {!dayRule.isAvailableAllDay &&
                      dayRule.timeRanges?.map(
                        ({ _id, startTime, endTime }, index) =>
                          renderTimeRanges({
                            _id,
                            startTime,
                            endTime,
                            index,
                            dayKey,
                            dayRule,
                          })
                      )}
                  </>
                ) : (
                  <>
                    {dayRule.isAvailableAllDay ? (
                      <Flex
                        justify="space-evenly"
                        className={styles.displayTimeRange}
                      >
                        <Flex
                          justify="center"
                          className={styles.timeSlotAllDay}
                        >
                          {t(TRANSLATION_KEYS.allDay || '')}
                        </Flex>
                      </Flex>
                    ) : (
                      dayRule.timeRanges?.map(({ _id, startTime, endTime }) => (
                        <Flex
                          justify="space-evenly"
                          key={_id}
                          className={styles.displayTimeRange}
                        >
                          <Flex justify="flex-end" className={styles.timeSlot}>
                            <Label>{startTime}</Label>
                          </Flex>
                          <Label>{t(TRANSLATION_KEYS.to || '')}</Label>
                          <Flex
                            justify="flex-start"
                            className={styles.timeSlot}
                          >
                            <Label>{endTime}</Label>
                          </Flex>
                        </Flex>
                      ))
                    )}
                  </>
                )}
              </div>
            )}
            {onToggleOpen && (
              <Toggle
                onChange={value =>
                  onToggleOpen(weeklyAvailability._id, dayKey, value)
                }
                value={dayRule.isOpen}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
