import React, { useContext } from 'react';
import { PopupMenu, Button, Icon } from 'design-system-web';
import { extractChannelLanguagesArray } from 'lane-shared/helpers/dynamicLanguages';
import { ChannelsContext } from 'lane-shared/contexts';
import { useTranslation } from 'react-i18next';
import styles from './LanguagePreviewSelector.scss';

const TRANSLATION_KEYS = {
  defaultButtonLabel:
    'web.components.general.languagePreviewSelector.button.label',
};

export function LanguagePreviewSelector({
  previewLanguage,
  setPreviewLanguage,
}: any) {
  const { primaryChannel: channel } = useContext(ChannelsContext);
  const channelLanguages = extractChannelLanguagesArray(channel);
  const { t } = useTranslation();

  const languageItems = channelLanguages.map(language => ({
    label: language.friendlyName,
    value: language.languageCode,
    onSelect: () => setPreviewLanguage(language.languageCode),
  }));

  if (channelLanguages.length < 2) {
    return null;
  }

  const selectedLanguage = languageItems.find(
    language => language.value === previewLanguage
  ) || { label: '', value: '', onSelect: () => {} };

  return (
    <div
      data-cy="languagePreviewSelector"
      className={styles.LanguagePreviewSelector}
    >
      <PopupMenu
        trigger={({ isOpen, ...referenceProps }) => (
          <Button
            variant="text"
            size="large"
            startIcon={<Icon name="language" />}
            endIcon={
              isOpen ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />
            }
            dataCy="languagePreviewSelectorButton"
            {...referenceProps}
          >
            <span className={styles.label}>
              {selectedLanguage.label || t(TRANSLATION_KEYS.defaultButtonLabel)}
            </span>
          </Button>
        )}
        items={languageItems}
        zIndex={5}
      />
    </div>
  );
}
