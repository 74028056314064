import React from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';

import { PropertyType } from 'lane-shared/types/properties/Property';

import Label from 'components/general/Label';
import SecurityInput from 'components/lane/SecurityInput';

import { FRIENDLY_PROPERTY_TEXTS } from './constants';
import useFriendlyPropertyFilters from './hooks/useFriendlyPropertyFilters';

import styles from './FriendlyPropertyCreate.scss';

type FriendlyPropertyCreateProps = {
  property: PropertyType;
  onPropertyUpdated: (property: Partial<PropertyType>) => void;
};
export default function FriendlyPropertyFiltersCreate({
  property,
  onPropertyUpdated,
}: FriendlyPropertyCreateProps) {
  const { t } = useTranslation();

  function updateFilters(filter: any) {
    onPropertyUpdated({
      filters: {
        ...property.filters,
        ...filter,
      },
    });
  }

  const { channel } = useChannelAdminContext();

  const { groupRoles } = useFriendlyPropertyFilters(property.filters);

  return (
    <>
      {groupRoles && (
        <fieldset className={styles.field}>
          <Label
            h2
            TooltipComponent={t(FRIENDLY_PROPERTY_TEXTS.tooltip.groupRoles)}
          >
            {t(FRIENDLY_PROPERTY_TEXTS.label.groupRoles)}
          </Label>

          {channel && (
            <SecurityInput
              channel={channel}
              security={groupRoles.value}
              onUpdated={filter => updateFilters({ groupRoles: filter })}
              allowedOptions={groupRoles.settings.allowedOptions}
              disableChannelSelection={
                groupRoles.settings.disableChannelSelection
              }
            />
          )}
        </fieldset>
      )}
    </>
  );
}
