import React, { useState, useContext } from 'react';

import { Icon } from 'design-system-web';
import { Button, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { updateUser } from 'lane-shared/graphql/user';
import { pause } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useValidation } from 'lane-shared/hooks';
import { validatePhoneNumber } from 'lane-shared/validation';

import PhoneNumberInput from 'components/form/PhoneNumberInput';
import { H3, M } from 'components/typography';

import styles from './RequirementsBlock.scss';

export default function PhoneForm({ onSave }: any) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const { user, refetch: refetchUser } = useContext(UserDataContext);

  const [validation] = useValidation(phoneNumber, validatePhoneNumber);

  async function updateUserInfo() {
    setLoading(true);
    try {
      await pause();
      await getClient().mutate({
        mutation: updateUser,
        variables: {
          user: {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            _id: user._id,
            profile: { phone: phoneNumber },
          },
        },
      });

      await refetchUser();
      setLoading(false);
      onSave();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }

  return (
    <div className={styles.requirementForm}>
      <div className={styles.requirementFormBody}>
        <div className={styles.ellipse} />
        <Icon
          className={styles.formIcon}
          name="phone"
          set={ICON_SET_FONTAWESOME}
          type="fal"
          size="large"
        />
        <div className={styles.formTitleContainer}>
          <H3 className={styles.formTitle}>
            {t(
              'web.admin.channel.content.layout.editor.components.phoneForm.title'
            )}
          </H3>
        </div>

        <M className={styles.formDescription}>
          {t(
            'web.admin.channel.content.layout.editor.components.phoneForm.description'
          )}
        </M>

        <PhoneNumberInput
          onChange={number => setPhoneNumber(number)}
          value={phoneNumber}
          className={styles.formInput}
          isVerticallyStacked
          label={t(
            'web.admin.channel.content.layout.editor.components.phoneForm.title'
          )}
          placeholder="(555) 555-5555"
          dataCy="NumberInput"
        />

        {error ? <ErrorMessage error={error} /> : null}
      </div>
      <div className={styles.requirementFormFooter}>
        <Button
          className={styles.saveButton}
          variant="contained"
          loading={loading}
          disabled={!!validation}
          onClick={() => updateUserInfo()}
          fullWidth
          dataCy="Save"
        >
          {t(
            'web.admin.channel.content.layout.editor.components.phoneForm.save'
          )}
        </Button>
      </div>
    </div>
  );
}
