import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { getChannelAccessControlGroups } from 'lane-shared/graphql/accessControl';
import { getConsolidatedChannelUsers } from 'lane-shared/graphql/user';

import { Table } from 'design-system-web';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import { AdminPage, PageHeader } from 'components/layout';
import { H4, M } from 'components/typography';

import { UserInfo } from '../../types/AccessControl';
import { AccessControlGroup } from 'graphql-query-contracts';

import styles from './styles.scss';

enum AccessControlGroupDetailsTabs {
  details = 'Details',
  users = 'Users',
}

interface RouteParams {
  acgId: string;
}

export function AccessControlGroupDetailsPage({
  channel,
}: {
  channel: LaneType.ChannelIntegration;
}) {
  const [selected, setSelected] = useState<AccessControlGroupDetailsTabs>(
    AccessControlGroupDetailsTabs.users
  );
  const [data, setData] = useState<UserInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { acgId } = useParams<RouteParams>();
  const [
    accessControlGroup,
    setAccessControlGroup,
  ] = useState<AccessControlGroup>();

  const fetchAccessControlGroups = async (channelId: string) => {
    try {
      const { data } = await getClient().query({
        query: getChannelAccessControlGroups,
        variables: {
          channelId,
          acgIds: [acgId],
        },
      });

      setAccessControlGroup(
        data.getChannelAccessControlGroups.find(
          (acg: AccessControlGroup) => acg.id === acgId
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUsers = async (channelId: string) => {
    try {
      const { data } = await getClient().query({
        query: getConsolidatedChannelUsers,
        variables: {
          channelId,
          accessControlGroupId: acgId,
        },
        fetchPolicy: 'no-cache',
      });

      const userData = data.getChannelUsers;

      setData(userData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (channel?._id) {
      setLoading(true);
      Promise.all([
        fetchUsers(channel._id),
        fetchAccessControlGroups(channel._id),
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [channel?._id]);

  const tabs: TabItem[] = [
    {
      value: 'Users',
      label: 'web.admin.accessControl.accessControlGroupDetails.tab.users',
    },
    {
      value: 'Details',
      label: 'web.admin.accessControl.accessControlGroupDetails.tab.details',
    },
  ];

  const pageHeaderProps = {
    header: accessControlGroup?.name || '',
    breadcrumbs: [
      {
        label: t('web.admin.accessControl.header'),
        url: routes.channelAdminUserAccess.replace(':id', channel?._id),
      },
      {
        label: t('web.admin.accessControl.accessControlGroups.title'),
        url: routes.channelAdminAccessControlGroups.replace(
          ':id',
          channel?._id
        ),
      },
      {
        label: t('web.admin.accessControl.accessControlGroupDetails.title'),
      },
    ],
  };

  const userTableColumns = [
    {
      key: 'name',
      header: t('web.admin.accessControl.userAccess.table.name'),
    },
    {
      key: 'email',
      header: t('web.admin.accessControl.userAccess.table.email'),
    },
    {
      key: 'company',
      header: t('web.admin.accessControl.userAccess.table.company'),
    },
  ];

  return (
    <AdminPage>
      <div className={styles.accessControlGroupsDetailsHeader}>
        <PageHeader {...pageHeaderProps} headerLevel="h3" />
      </div>
      <TabStrip
        tabs={tabs}
        selected={{ value: selected }}
        onSelectTab={tab =>
          setSelected(tab.value as AccessControlGroupDetailsTabs)
        }
        className={styles.accessControlGroupDetailsTabStrip}
      />
      {selected === AccessControlGroupDetailsTabs.details && (
        <div className={styles.accessControlGroupDetailsContent}>
          <div>
            <H4>
              {t('web.admin.accessControl.accessControlGroupDetails.details')}
            </H4>
          </div>
          <div>
            <M
              variant="primary"
              className={styles.accessControlGroupDetailsGroupNameTitle}
            >
              {t('web.admin.accessControl.accessControlGroupDetails.groupName')}
            </M>
            <M
              className={styles.accessControlGroupDetailsGroupName}
              variant="primary"
            >
              {accessControlGroup?.name}
            </M>
          </div>
          <div>
            <M
              variant="primary"
              className={styles.accessControlGroupDetailsDescriptionTitle}
            >
              {t(
                'web.admin.accessControl.accessControlGroupDetails.description'
              )}
            </M>
            <M
              className={styles.accessControlGroupDetailsDescription}
              variant="primary"
            >
              {accessControlGroup?.description}
            </M>
          </div>
        </div>
      )}
      {selected === AccessControlGroupDetailsTabs.users && (
        <div>
          <Table
            isLoading={loading}
            columns={userTableColumns}
            data={data}
            filters={[]}
            pagination="client"
            isSelectable
            hasKeywordFilter
            emptyMessage={t(
              'web.admin.accessControl.accessControlGroupDetails.users.table.emptyMessage'
            )}
          />
        </div>
      )}
    </AdminPage>
  );
}
