import { uploadToS3 } from 'lane-shared/helpers';
import {
  AttachmentResponse,
  AttachmentEntityInput,
  AttachmentDataForUpload,
} from 'lane-shared/types/attachment';

import { createAttachment } from './createAttachment';

export const uploadAttachment = async (
  entity: AttachmentEntityInput,
  attachmentAndThumbnail: AttachmentDataForUpload
): Promise<AttachmentResponse> => {
  const createdAttachment = await createAttachment({
    entity,
    ...attachmentAndThumbnail,
  });

  await uploadToS3({
    signed: createdAttachment.fileSignedUrl,
    file: attachmentAndThumbnail.attachmentBlob,
    contentType: attachmentAndThumbnail.attachment.type,
  });

  if (
    attachmentAndThumbnail.thumbnail &&
    attachmentAndThumbnail.thumbnailBlob
  ) {
    await uploadToS3({
      signed: createdAttachment.thumbnailSignedUrl,
      file: attachmentAndThumbnail.thumbnailBlob,
      contentType: attachmentAndThumbnail.thumbnailBlob!.type,
    });
  }

  return createdAttachment;
};
