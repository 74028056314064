import Color from 'color';

function parseColor(color: any) {
  const { r, g, b } = color.rgb().object();
  const a = color.alpha();

  return `rgba(${r},${g},${b},${a})`;
}
export default function colorToRGBA(color: any) {
  if (!color) {
    return '';
  }

  // test for if this is a Color object.
  if (color.unitObject) {
    return parseColor(color);
  }

  if (typeof color === 'string') {
    return parseColor(new Color(color));
  }

  if (color.rgb) {
    const { r, g, b, a } = color.rgb;
    return `rgba(${r},${g},${b},${a})`;
  }

  return '';
}
