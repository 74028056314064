import { Primitive } from '../../../types/blocks/Primitive';

const ViewBlock: Primitive = {
  name: 'ViewBlock',
  friendlyName: 'View',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  properties: {
    scroll: {
      _order: 1,
      type: 'Boolean',
      default: false,
      friendlyName: 'Scroll',
    },
  },
  tags: ['Layout'],
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ViewBlock;
