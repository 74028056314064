import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { arrayReorder } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import MenuFeatureItemOption from 'lane-shared/renderers/v5/features/types/MenuFeatureItemOption';
import MenuFeatureItemOptionList, {
  MENU_FEATURE_MAX_OPTIONS,
} from 'lane-shared/renderers/v5/features/types/MenuFeatureItemOptionList';
import { MenuFeatureItemOptionListType } from 'lane-shared/types/features/MenuFeatureTypes';

import Checkbox from 'components/form/Checkbox';
import CurrencyInput from 'components/form/CurrencyInput';
import Input from 'components/form/Input';
import Option from 'components/form/Option';
import QuantityInput from 'components/form/QuantityInput';
import Label from 'components/general/Label';

import styles from './MenuFeatureItemOptionListEdit.scss';

type Props = {
  className?: string;
  style?: any;
  currency?: string;
  optionList: MenuFeatureItemOptionListType;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onOptionListUpdated: (object) => any;
};

const TRANSLATION_KEYS = {
  optionListName:
    'web.content.features.components.MenuFeatureItemOptionListEdit.optionListName',
  selectSize:
    'web.content.features.components.MenuFeatureItemOptionListEdit.selectSize',
  requiredChoice:
    'web.content.features.components.MenuFeatureItemOptionListEdit.requiredChoice',
  selectOneOption:
    'web.content.features.components.MenuFeatureItemOptionListEdit.selectOneOption',
  pickManyOptions:
    'web.content.features.components.MenuFeatureItemOptionListEdit.pickManyOptions',
  minRequiredOptions:
    'web.content.features.components.MenuFeatureItemOptionListEdit.minRequiredOptions',
  maxRequiredOptions:
    'web.content.features.components.MenuFeatureItemOptionListEdit.maxRequiredOptions',
  options:
    'web.content.features.components.MenuFeatureItemOptionListEdit.options',
  preselected:
    'web.content.features.components.MenuFeatureItemOptionListEdit.preselected',
};

export default function MenuFeatureItemOptionListEdit({
  className,
  style,
  currency,
  optionList,
  onOptionListUpdated,
}: Props) {
  const isRequiredOne =
    optionList.minChoices === 1 && optionList.maxChoices === 1;

  const isSelectOne =
    optionList.minChoices === 0 && optionList.maxChoices === 1;

  const isPickMany = !isRequiredOne && optionList.maxChoices > 1;

  const { t } = useTranslation();

  function addOption() {
    onOptionListUpdated({
      options: [
        ...optionList.options,
        {
          ...MenuFeatureItemOption.default,
          _order: optionList.options.length,
        },
      ],
    });
  }

  function removeOption(option: any) {
    onOptionListUpdated({
      options: optionList.options.filter(({ _id }) => _id !== option._id),
    });
  }

  function onOptionDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    onOptionListUpdated({
      options: arrayReorder(
        optionList.options,
        result.source.index,
        result.destination.index
      ),
    });
  }

  function updateOption(index: any, props: any) {
    optionList.options[index] = {
      ...optionList.options[index],
      ...props,
    };

    onOptionListUpdated({
      options: [...optionList.options],
    });
  }

  return (
    <div
      className={cx(styles.MenuFeatureItemOptionListEdit, className)}
      style={style}
    >
      <fieldset>
        <Label
          TooltipComponent={t(
            MenuFeatureItemOptionList.properties?.name?.description || ''
          )}
        >
          {t(TRANSLATION_KEYS.optionListName)}
        </Label>
        <Input
          className={styles.input}
          value={optionList.name}
          onChange={name => onOptionListUpdated({ name })}
          placeholder={t(TRANSLATION_KEYS.selectSize)}
        />
      </fieldset>

      <menu className={styles.options}>
        <Option
          className={styles.option}
          selected={isRequiredOne}
          hasIcon
          disabled={false}
          onClick={() =>
            onOptionListUpdated({
              maxChoices: 1,
              minChoices: 1,
            })
          }
        >
          <h2>{t(TRANSLATION_KEYS.requiredChoice)}</h2>
        </Option>

        <Option
          className={styles.option}
          selected={isSelectOne}
          hasIcon
          disabled={false}
          onClick={() =>
            onOptionListUpdated({
              maxChoices: 1,
              minChoices: 0,
            })
          }
        >
          <h2>{t(TRANSLATION_KEYS.selectOneOption)}</h2>
        </Option>

        <Option
          className={styles.option}
          selected={isPickMany}
          hasIcon
          disabled={false}
          onClick={() =>
            onOptionListUpdated({
              maxChoices: 10,
              minChoices: 0,
            })
          }
        >
          <h2>{t(TRANSLATION_KEYS.pickManyOptions)}</h2>
        </Option>
      </menu>
      {isPickMany && (
        <div className={styles.group}>
          <fieldset>
            <label>{t(TRANSLATION_KEYS.minRequiredOptions)}</label>
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ classNam... Remove this comment to see the full error message */}
            <QuantityInput
              className={styles.quantityInput}
              quantity={optionList.minChoices}
              min={0}
              max={optionList.maxChoices}
              onChange={minChoices => onOptionListUpdated({ minChoices })}
            />
          </fieldset>
          <fieldset>
            <label>{t(TRANSLATION_KEYS.maxRequiredOptions)}</label>
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ classNam... Remove this comment to see the full error message */}
            <QuantityInput
              className={styles.quantityInput}
              quantity={optionList.maxChoices}
              min={2}
              max={MENU_FEATURE_MAX_OPTIONS}
              onChange={maxChoices => onOptionListUpdated({ maxChoices })}
            />
          </fieldset>
        </div>
      )}
      <Label>
        {t(TRANSLATION_KEYS.options)}
        <Icon
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={addOption}
        />
      </Label>
      <DragDropContext onDragEnd={onOptionDragEnd}>
        <Droppable droppableId="options">
          {provided => (
            <ul className={styles.options} ref={provided.innerRef}>
              {optionList.options?.map((option, index) => (
                <Draggable
                  key={option._id}
                  draggableId={option._id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      key={option._id}
                      ref={provided.innerRef}
                      data-is-dragging={snapshot.isDragging}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Icon
                        name="bars"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.iconReorder}
                      />
                      <Input
                        className={styles.input}
                        value={option.name}
                        onChange={name => updateOption(index, { name })}
                      />
                      <CurrencyInput
                        value={option.price}
                        currency={currency}
                        onValueChange={price => updateOption(index, { price })}
                      />
                      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message */}
                      <Checkbox
                        selected={option.isDefault}
                        onChange={() =>
                          updateOption(index, { isDefault: !option.isDefault })
                        }
                        text={t(TRANSLATION_KEYS.preselected)}
                      />
                      <Icon
                        name="times-circle"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.removeIcon}
                        style={{ marginLeft: '1em' }}
                        onClick={() => removeOption(option)}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
