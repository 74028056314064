import { v4 as uuid } from 'uuid';

import { colors } from '../config';
import { ThemeType } from '../types/Theme';

export default function constructTheme(userId: string): Partial<ThemeType> {
  return {
    _id: uuid(),
    _created: new Date(),
    _createdBy: userId,
    _updated: new Date(),
    _updatedBy: userId,
    name: 'New Theme',
    palette: {
      _id: uuid(),
      primary: colors.primary,
      secondary: colors.secondary,
      tertiary: colors.tertiary,
      text: colors.text,
      background: colors.background,
      custom: [],
    },
    layout: undefined,
    typography: undefined,
  };
}
