import { getClient } from 'lane-shared/apollo';
import { getInteractionsOnContent } from 'lane-shared/graphql/query';
import exportInteractionsToCSV from 'lane-shared/helpers/interactions/exportInteractionsToCSV';

import makeFileDownload from './makeFileDownload';

const perPage = 250;

export default async function downloadContentInteractions({
  content,
  variables = {},
  opts,
  whitelabelUrl,
  isCurrentPage,
}: {
  content: any;
  variables: any;
  opts?: {
    locale?: string;
    timeZone?: string;
  };
  whitelabelUrl: string;
  isCurrentPage: boolean;
}) {
  let interactions: any[] = [];
  let start = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const newVariables = {
      ...variables,
      contentId: content._id,
      ...(!isCurrentPage && {
        pagination: {
          start,
          perPage,
        },
      }),
    };

    const { data } = await getClient().query({
      query: getInteractionsOnContent,
      fetchPolicy: 'network-only',
      variables: newVariables,
    });

    if (!data || !data.interactionsOnContent) {
      break;
    }

    const { pageInfo, items } = data.interactionsOnContent;

    interactions = [...interactions, ...(items || [])];

    if (start > pageInfo.total) {
      break;
    }

    window.Alert.loading({
      title: 'Preparing…',
      message: `${Math.round((start / pageInfo.total) * 100)}%`,
    });

    start += perPage;

    if (isCurrentPage) {
      break;
    }
  }

  const csv = exportInteractionsToCSV(
    content,
    interactions,
    whitelabelUrl,
    opts
  );

  makeFileDownload({
    name: `${content.name}.csv`,
    contents: csv,
    type: 'text/csv',
  });
}
