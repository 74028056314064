import { EventStatus } from 'lane-shared/domains/visitorManagement/types';

import { ChipStyle } from 'lane-web/src/components/ads/chipSelect/types';
import type { ChipListItem } from 'lane-web/src/components/ads/chipSelect/types';

export type StatusListItem = ChipListItem & { key: string };

export const StatusListItems: Readonly<StatusListItem[]> = Object.freeze([
  {
    key: 'pendingStatus',
    label: 'web.admin.channel.visitor.log.status.pending',
    value: EventStatus.EVENT_STATUS_PENDING,
    type: ChipStyle.Red,
  },
  {
    key: 'upcomingStatus',
    label: 'web.admin.channel.visitor.log.status.upcoming',
    value: EventStatus.EVENT_STATUS_UPCOMING,
    type: ChipStyle.Yellow,
  },
  {
    key: 'checkedInStatus',
    label: 'web.admin.channel.visitor.log.status.checkedIn',
    value: EventStatus.EVENT_STATUS_CHECKED_IN,
    type: ChipStyle.Green,
  },
  {
    key: 'checkedOutStatus',
    label: 'web.admin.channel.visitor.log.status.checkedOut',
    value: EventStatus.EVENT_STATUS_CHECKED_OUT,
    type: ChipStyle.Blue,
  },
  {
    key: 'noShowStatus',
    label: 'web.admin.channel.visitor.log.status.noShow',
    value: EventStatus.EVENT_STATUS_NO_SHOW,
    type: ChipStyle.Grey,
  },
  {
    key: 'cancelledStatus',
    label: 'web.admin.channel.visitor.log.status.cancelled',
    value: EventStatus.EVENT_STATUS_CANCELLED,
    type: ChipStyle.Red,
  },
  {
    key: 'recurrenceCancelledStatus',
    label: 'web.admin.channel.visitor.log.status.cancelled',
    value: EventStatus.RECURRENCE_EVENT_STATUS_CANCELLED,
    type: ChipStyle.Red,
  },
  {
    key: 'withdrawnStatus',
    label: 'web.admin.channel.visitor.log.status.withdrawn',
    value: EventStatus.EVENT_STATUS_WITHDRAWN,
    type: ChipStyle.Red,
  },
  {
    key: 'expiredStatus',
    label: 'web.admin.channel.visitor.log.status.expired',
    value: EventStatus.EVENT_STATUS_EXPIRED,
    type: ChipStyle.Blue,
  },
]);

const statusListMap: { [key: string]: string } = {};
for (const statusList of StatusListItems) {
  statusListMap[statusList.value] = statusList.label;
}
export const StatusKeyToLabelForExport = statusListMap;
