import * as yup from 'yup';

import { frequencies } from '../renderers/v5/features/Reset';
import validateFeature from './validateFeature';
import validateProperty from './validateProperty';

export const validateUpdateContentTemplate = yup.object().shape({
  __typename: yup.string().strip(true),
  _id: yup.string().trim().required(),
  _order: yup.number().min(0),
  templateName: yup.string().trim().min(3).max(75),
  notes: yup.string().trim().max(4096),
  name: yup.string().trim().max(75),
  description: yup.string().trim().max(255),
  subtitle: yup.string().nullable().max(75).notRequired(),
  type: yup.string().trim(),
  channel: yup
    .object()
    .nullable()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup.string(),
    }),
  card: yup.object().shape({
    __typename: yup.string().strip(true),
    _id: yup
      .string()
      .typeError('a card type is required.')
      .required('a card type is required.'),
  }),
  tags: yup.array().of(yup.string()).max(6),
  color: yup.string(),
  backgroundColor: yup.string(),
  liveDate: yup.date().nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  unpublishDate: yup.date().nullable(),
  isEvent: yup.boolean().nullable(),
  resetPeriod: yup.string().oneOf(frequencies),
  lastReset: yup.date().nullable(),
  nextReset: yup.date().nullable(),
  version: yup.number().min(0).max(1024),
  renderer: yup.number().min(0).max(256),
  block: yup.object().nullable(),
  properties: yup
    .object()
    .nullable()
    .test(
      'valid-properties',
      '${path} has invalid property defined.',
      value => {
        if (value) {
          Object.values(value).forEach(property =>
            validateProperty.validateSync(property)
          );
        }

        return true;
      }
    ),
  features: yup
    .array()
    .nullable()
    .when('version', {
      is: 5,
      then: yup.array().of(validateFeature),
      otherwise: yup.array(),
    }),
  // todo: data validation.
  data: yup.object().nullable(),
  // todo: state validation
  state: yup.object().nullable(),
  // todo: settings validation
  settings: yup.object().nullable(),
});
