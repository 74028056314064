import React from 'react';

import cx from 'classnames';

import convertMjmlStyleProps from './convertMjmlStyleProps';

import styles from './styles.scss';

export default function MjmlSpacer({ className, style, ...otherProps }: any) {
  return (
    <div
      className={cx(styles.MjmlSpacer, className)}
      style={{ ...style, ...convertMjmlStyleProps(otherProps) }}
    />
  );
}
