import { useEffect, useContext, useState } from 'react';

import { getClient } from '../apollo';
import { UserDataContext, ChannelsContext } from '../contexts';
import { queryPaymentAccounts } from '../graphql/me';
import { isPaymentAccountValidClientSide } from '../helpers/features';
import filterPaymentAccount from '../helpers/filters/paymentAccount';
import { ContentCategoryEnum } from '../types/content/ContentCategoryEnum';
import { PaymentAccountType } from '../types/payment/PaymentAccount';
import { PaymentProviderEnum } from '../types/payment/PaymentProviderEnum';

type UseMyChannelsPaymentAccountsQueryProps = {
  paymentProvider: PaymentProviderEnum;
  purchasePrice?: number;
  timeZone?: string;
  contentCategory?: ContentCategoryEnum;
};

export default function useMyChannelsPaymentAccountsQuery({
  paymentProvider = PaymentProviderEnum.Cash,
  purchasePrice,
  timeZone,
  contentCategory,
}: UseMyChannelsPaymentAccountsQueryProps): {
  myChannelsPaymentAccounts: PaymentAccountType[];
  loading: boolean;
  error: Error | null;
  refetchMyChannelsPaymentAccounts: () => Promise<void>;
} {
  const { user } = useContext(UserDataContext);
  const { channels } = useContext(ChannelsContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [myChannelsPaymentAccounts, setMyChannelsPaymentAccounts] = useState<
    PaymentAccountType[]
  >([]);

  async function refetchMyChannelsPaymentAccounts() {
    setLoading(true);
    setError(null);

    try {
      const { data } = await getClient().query({
        query: queryPaymentAccounts,
        fetchPolicy: 'network-only',
      });

      // reduce down to just payment accounts.
      const channelPaymentAccounts = (data?.me?.channels || [])
        .reduce(
          (arr: any, channel: any) => [
            ...arr,
            ...(channel.paymentAccounts || []),
          ],
          []
        )
        .map((paymentAccount: any) => {
          // get the channel profile data from the users channel context.
          const channel = channels.find(
            channel => channel._id === paymentAccount.channel?._id
          );

          const __isValid =
            timeZone && purchasePrice && contentCategory
              ? isPaymentAccountValidClientSide({
                  paymentAccount,
                  purchasePrice,
                  timeZone,
                  contentCategory,
                  userRoles: user?.roles || [],
                })
              : true;

          return {
            ...paymentAccount,
            channel,
            __isValid,
          };
        })
        .filter((paymentAccount: any) =>
          filterPaymentAccount(paymentAccount, paymentProvider)
        );

      setMyChannelsPaymentAccounts(channelPaymentAccounts);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }

  useEffect(() => {
    refetchMyChannelsPaymentAccounts();
  }, []);

  return {
    myChannelsPaymentAccounts,
    loading,
    error,
    refetchMyChannelsPaymentAccounts,
  };
}
