import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import friendlyTemperature from '../helpers/formatters/friendlyTemperature';
import getIconFromWeather from '../helpers/getIconFromWeather';

const channelWeatherQuery = gql`
  query getChannelWeather($id: UUID, $slug: String) {
    channel(_id: $id, slug: $slug) {
      _id
      address {
        _id
        geo
        weather {
          currently {
            temperature
            icon
          }
          flags {
            units
          }
        }
      }
    }
  }
`;

export default function useChannelWeatherQuery({ channelId }: any) {
  const { data, loading, error } = useQuery(channelWeatherQuery, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    skip: !channelId,
    variables: {
      id: channelId,
    },
  });

  const weather = data?.channel?.address?.weather;
  const temperatureNumber = weather?.currently?.temperature;
  const temperature = friendlyTemperature(
    temperatureNumber,
    weather?.flags?.units
  );

  const icon = getIconFromWeather(weather);

  return { temperature, icon, loading, error };
}
