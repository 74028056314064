import React from 'react';
import ContextMenu, { ModalPositionEnum } from 'components/general/ContextMenu';
import { useTranslation } from 'react-i18next';

import IconButton from 'components/general/IconButton';

import { getClient } from 'lane-shared/apollo';

import {
  CredentialGroup,
  CredentialGroupStatus,
} from '../../../types/AccessControl';

import {
  resumeCredentialGroup,
  revokeCredentialGroup,
  suspendCredentialGroup,
} from 'lane-shared/graphql/accessControl';

import styles from './styles.scss';

type props = {
  keyCard: CredentialGroup;
};

export default function KeyCardActions({ keyCard }: props) {
  const { t } = useTranslation();

  const resumeKeyCard = async (
    channelId: string,
    credentialGroupId: string,
    provider: string
  ) => {
    try {
      await getClient().mutate({
        mutation: resumeCredentialGroup,
        variables: {
          channelId,
          credentialGroupId,
          provider,
        },
      });
      window.Toast.show(
        t(
          'web.admin.accessControl.manageUserAccess.resumeCredentialGroup.successMessage'
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const revokeKeyCard = async (
    channelId: string,
    credentialGroupId: string,
    provider: string
  ) => {
    try {
      await getClient().mutate({
        mutation: revokeCredentialGroup,
        variables: {
          channelId,
          credentialGroupId,
          provider,
        },
      });
      window.Toast.show(
        t(
          'web.admin.accessControl.manageUserAccess.revokeCredentialGroup.successMessage'
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const suspendKeyCard = async (
    channelId: string,
    credentialGroupId: string,
    provider: string
  ) => {
    try {
      await getClient().mutate({
        mutation: suspendCredentialGroup,
        variables: {
          channelId,
          credentialGroupId,
          provider,
        },
      });
      window.Toast.show(
        t(
          'web.admin.accessControl.manageUserAccess.suspendCredentialGroup.successMessage'
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.IconContainer}>
      <ContextMenu
        autoClose
        items={
          <div className={styles.Actions}>
            {keyCard.status === CredentialGroupStatus.SUSPENDED && (
              <button
                className={styles.ActionButton}
                data-testid={`resume-button-${keyCard.id}`}
                onClick={() =>
                  resumeKeyCard(
                    keyCard.vtsOrganizationId,
                    keyCard.id,
                    keyCard.provider
                  )
                }
              >
                {t(
                  'web.admin.accessControl.manageUserAccess.credentialMapping.resumeCredentials'
                )}
              </button>
            )}
            {keyCard.status === CredentialGroupStatus.ACTIVE && (
              <button
                className={styles.ActionButton}
                data-testid={`revoke-button-${keyCard.id}`}
                onClick={() =>
                  revokeKeyCard(
                    keyCard.vtsOrganizationId,
                    keyCard.id,
                    keyCard.provider
                  )
                }
              >
                {t(
                  'web.admin.accessControl.manageUserAccess.credentialMapping.revokeCredentials'
                )}
              </button>
            )}
            {keyCard.status === CredentialGroupStatus.ACTIVE && (
              <button
                className={styles.ActionButton}
                data-testid={`suspend-button-${keyCard.id}`}
                onClick={() =>
                  suspendKeyCard(
                    keyCard.vtsOrganizationId,
                    keyCard.id,
                    keyCard.provider
                  )
                }
              >
                {t(
                  'web.admin.accessControl.manageUserAccess.credentialMapping.suspendCredentials'
                )}
              </button>
            )}
          </div>
        }
        hasOpaqueBackground={false}
        align={ModalPositionEnum.Left}
      >
        <IconButton
          icon="ellipsis-v"
          iconSet="FontAwesome"
          testId={`row-actions-${keyCard.id}`}
        />
      </ContextMenu>
    </div>
  );
}
