/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useCallback, useMemo } from 'react';
import { Icon } from '../Icon';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import styles from './TagInput.scss';

interface TagInputProps {
  tags: string[];
  setTags: (tags: string[]) => void;
  validateTag?: (tag: string) => boolean;
  placeholder?: string;
  maxTags?: number;
  maxTagInfo?: string;
  errorText?: string;
  id?: string;
}

export function TagInput({
  id = 'tag-input',
  tags,
  setTags,
  validateTag = () => true,
  placeholder = 'Enter tags',
  maxTags = 20,
  maxTagInfo,
  errorText,
}: TagInputProps) {
  const [input, setInput] = useState('');

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (['Enter', 'Tab', ' ', ','].includes(e.key) && input.trim()) {
        e.preventDefault();
        const newTags = input.split(/[ ,]+/).filter(Boolean);
        const validatedTags = newTags.map(tag => ({
          text: tag,
          error: !validateTag(tag),
        }));

        if (tags.length + validatedTags.length <= maxTags) {
          setTags([...tags, ...validatedTags.map(tag => tag.text)]);
        }
        setInput('');
      } else if (e.key === 'Backspace' && !input) {
        setTags(tags.slice(0, -1));
      }
    },
    [input, tags, setTags, validateTag, maxTags]
  );

  const handleDelete = useCallback(
    (index: number) => {
      setTags(tags.filter((_, i) => i !== index));
    },
    [tags, setTags]
  );

  const handleClearAll = useCallback(() => {
    setTags([]);
  }, [setTags]);

  const hasError = useMemo(
    () => tags.some(tag => !validateTag(tag)),
    [tags, validateTag]
  );

  return (
    <InputWrapper id={id}>
      <div
        className={`${styles.tagInputContainer} ${
          hasError ? styles.error : ''
        }`}
      >
        {tags.map((tag, index) => (
          <div
            key={index}
            className={`${styles.tag} ${
              !validateTag(tag) ? styles.tagError : ''
            }`}
          >
            <div className={styles.tagtext}>{tag}</div>
            <div className={styles.iconContainer}>
              {!validateTag(tag) && (
                <Icon name="exclamation-circle" style={{ color: '#C7200A' }} />
              )}
              <Icon name="times" onClick={() => handleDelete(index)} />
            </div>
          </div>
        ))}
        <input
          type="text"
          value={input}
          onChange={e => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={tags.length ? '' : placeholder}
        />
        {tags.length > 0 && <Icon name="times" onClick={handleClearAll} />}
      </div>
      {maxTagInfo && !hasError && (
        <div className={styles.maxTagsText}>
          <p>{maxTagInfo}</p>
        </div>
      )}
      {hasError && (
        <div className={styles.errorText}>
          <p>{errorText}</p>
        </div>
      )}
    </InputWrapper>
  );
}
