import { useHandleSaveAutopayment } from './helpers/useHandleSaveAutopayment';
import styles from './invoiceAutopayment.scss';
import { StripeCardElement } from '@stripe/stripe-js';
import { Button } from 'design-system-web';
import { CardFormInputFields } from 'components/features/Payment/PaymentSummaryV2/PaymentOptions/CardFormInputFields';
import { M } from 'components/typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AddNewCardAutopaymentModal({
  isAddNewCardClicked,
  setIsAddNewCardClicked,
  setSaveNewPaymentMethod,
}: {
  isAddNewCardClicked: boolean;
  setIsAddNewCardClicked: (isClicked: boolean) => void;
  setSaveNewPaymentMethod: React.Dispatch<
    React.SetStateAction<(() => Promise<string>) | undefined>
  >;
}) {
  const { t } = useTranslation();

  const [cardName, setCardName] = useState({
    firstName: '',
    lastName: '',
  });
  const handleCardNameChange = (key: string, value: string) => {
    setCardName(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const [cardDetails, setCardDetails] = useState<StripeCardElement | undefined>(
    undefined
  );
  const handleCardDetailsChange = (cardDetails: StripeCardElement) => {
    setCardDetails(cardDetails);
  };

  const addAutopaymentMethod = useHandleSaveAutopayment({
    cardName,
    cardDetails,
  });

  useEffect(() => {
    if (cardDetails && cardName.firstName && cardName.lastName) {
      setSaveNewPaymentMethod(() => addAutopaymentMethod);
    } else {
      setSaveNewPaymentMethod(undefined);
    }
  }, [cardDetails, cardName]);

  return (
    <div className={styles.addNewCard}>
      {isAddNewCardClicked ? (
        <Button
          className={styles.backToPaymentSourcesButton}
          dataCy="BackToPaymentSourcesButton"
          onClick={() => setIsAddNewCardClicked(false)}
          variant="text"
          size="large"
        >
          {t('abp.routes.accountInvoices.autopayment.modal.backToSavedCards')}
        </Button>
      ) : (
        <M mb={5} mt={5}>
          {t(
            'abp.routes.accountInvoices.autopayment.modal.addCreditCardSubtitle'
          )}
        </M>
      )}

      <div className={styles.inputFieldsWrapper}>
        <M bold>
          {t(
            'abp.routes.accountInvoices.autopayment.modal.creditCardInformation'
          )}
        </M>
        <CardFormInputFields
          cardName={cardName}
          onCardNameChange={handleCardNameChange}
          onCardDetailsChange={handleCardDetailsChange}
        />
      </div>
    </div>
  );
}
