import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import PaymentFeatureWeekRule from './PaymentFeatureWeekRule';

const properties: PropertiesInterface = {
  groupRole: {
    type: 'GroupRole',
  },
  priceRatio: {
    type: 'Percentage',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  weekTimeRulesEnabled: {
    type: 'Boolean',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  weekTimeRules: {
    type: 'PaymentFeatureWeekRule',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  canOverridePrice: {
    friendlyName: 'Can Override Price',
    description: 'Members of this team can override the price for this item',
    type: 'Boolean',
  },
};

export class PaymentFeatureRuleType extends ObjectTypeBase {
  name = 'PaymentFeatureRule';

  friendlyName = 'Payment Rule';

  contexts = [];

  get example() {
    return {
      priceRatio: 100,
      groupRole: null,
      weeklyTimeRulesEnabled: false,
      weekTimeRules: PaymentFeatureWeekRule.default,
      canOverridePrice: false,
    };
  }

  get default(): any {
    return {
      priceRatio: 100,
      groupRole: null,
      weeklyTimeRulesEnabled: false,
      weekTimeRules: PaymentFeatureWeekRule.default,
      canOverridePrice: false,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new PaymentFeatureRuleType();

Types.addType(definition);
export default definition;
