import { Primitive } from '../../../types/blocks/Primitive';

const ChannelSwitcherBlock: Primitive = {
  name: 'ChannelSwitcherBlock',
  friendlyName: 'Channel Switcher',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  tags: ['Other'],
  properties: {
    color: {
      type: 'Color',
      validators: [],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ChannelSwitcherBlock;
