import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import {
  joinWaitlistMutation,
  leaveReservableWaitlistMutation as leaveReservableWaitlist,
} from 'graphql-queries';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import getMessagesFromError from '../../helpers/getMessagesFromError';
import useUserDataContext from '../useUserDataContext';

const TRANSLATION_KEYS = {
  joinwaitlistUnauthorise:
    'shared.content.feature.reservable.waitlist.join.unauthorise',
  leavewaitlistUnauthorise:
    'shared.content.feature.reservable.waitlist.leave.unauthorise',
};

export const useReservableWaitlistActions = () => {
  const { user } = useUserDataContext();
  const { t } = useTranslation();
  const [joinReservableWaitlistMutation] = useMutation(joinWaitlistMutation);
  const [leaveReservableWaitlistMutation] = useMutation(
    leaveReservableWaitlist
  );

  const joinWaitlist = async (contentId: string, timeSlot: DateRangeType) => {
    try {
      if (!user?._id) {
        throw new Error(t(TRANSLATION_KEYS.joinwaitlistUnauthorise));
      }

      const { data } = await joinReservableWaitlistMutation({
        variables: {
          userId: user?._id,
          contentId,
          startDate: timeSlot.startDate,
          endDate: timeSlot.endDate,
        },
      });

      return data?.joinWaitlist;
    } catch (error) {
      const messages = getMessagesFromError(error);
      throw new Error(messages.join('\n'));
    }
  };

  const leaveWaitlist = async (enrollmentId: string) => {
    try {
      if (!user?._id) {
        throw new Error(t(TRANSLATION_KEYS.leavewaitlistUnauthorise));
      }

      const { data } = await leaveReservableWaitlistMutation({
        variables: {
          enrollmentId,
        },
      });
      return data?.leaveReservableWaitlist;
    } catch (error) {
      const messages = getMessagesFromError(error);
      throw new Error(messages.join('\n'));
    }
  };

  return { joinWaitlist, leaveWaitlist };
};
