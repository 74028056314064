import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import PriceTimeRange from './PriceTimeRange';

const properties: PropertiesInterface = {
  isOpen: {
    type: 'Boolean',
    default: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  priceTimeRanges: {
    type: 'PriceTimeRange',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class PaymentFeatureDayRuleType extends ObjectTypeBase {
  name = 'PaymentFeatureDayRule';

  friendlyName = 'Payment Day Rule';

  contexts = [];

  get example() {
    return {
      _id: uuid(),
      isOpen: true,
      priceTimeRanges: [PriceTimeRange.default],
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      isOpen: true,
      priceTimeRanges: [PriceTimeRange.default],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new PaymentFeatureDayRuleType();

Types.addType(definition);
export default definition;
