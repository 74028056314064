import { AnalyticsInterface } from '../../types/Analytics';
import { EVENT_FILE_UPLOADED } from '../constants/events';

type FileUploadedEvent = {
  userId: string;
  channelId: string;
  integrationId?: string;
  analytics: AnalyticsInterface;
};

export function emitFileUploaded({
  userId,
  channelId,
  integrationId,
  analytics,
}: FileUploadedEvent) {
  analytics.track(EVENT_FILE_UPLOADED, { userId, channelId, integrationId });
}
