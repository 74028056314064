import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';
import { AccessControlServiceDropdownOptions } from './accessControl';
import { IdentityProviderDropdownOptions } from './identityProvider';

const AccessManagementDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.AccessManagement,
  hasCustomUI: true,
  allowsMultipleInstances: false,
  friendlyName: 'Access Management',
  description:
    'Defines the properties required for an access control integration.',
  category: IntegrationCategoryEnum.AccessControl,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    identityProviderService: {
      friendlyName: 'Identity Provider Service',
      packagedType: PackagedTypeEnum.Dropdown,
      type: 'Option',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: IdentityProviderDropdownOptions,
        },
      ],
    },
    identityProviderSettings: {
      forDisplay: false,
      friendlyName: 'Identity Provider Service Settings',
      description: 'Storage for provider-specific property values.',
      type: String.name,
      validators: [],
      nestedPropertyRules: {
        checkSecurity: true,
        dynamicProviderKey: 'identityProviderService.value',
      },
    },
    accessControlService: {
      friendlyName: 'Access Control Service',
      packagedType: PackagedTypeEnum.Dropdown,
      type: 'Option',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: AccessControlServiceDropdownOptions,
        },
      ],
    },
    accessControlSettings: {
      forDisplay: false,
      friendlyName: 'Access Control Service Settings',
      description: 'Storage for provider-specific property values.',
      type: String.name,
      validators: [],
      nestedPropertyRules: {
        checkSecurity: true,
        dynamicProviderKey: 'accessControlService.value',
      },
    },
  },
};

export default AccessManagementDefinition;
