import { DefaultLocale, SupportedLocaleEnum } from 'localization';

export default function findClosestLocale(toFind: any): SupportedLocaleEnum {
  if (!toFind) {
    return DefaultLocale;
  }

  // find an exact match first.
  if (Object.values(SupportedLocaleEnum).includes(toFind)) {
    return toFind;
  }

  // try at least the same language category second
  const topLevel = toFind.split('-')[0];
  const locale = Object.values(SupportedLocaleEnum).find(value =>
    value.includes(topLevel)
  );

  if (locale) {
    return locale;
  }

  // have to default to something.
  return DefaultLocale;
}
