import { IntegrationProviderEnum } from '../../types/integrations/IntegrationEnums';
import { ShopifyBurstStatusEnum } from '../../types/integrations/ShopifyBurst';
import toSchema, { SchemaType } from '../toSchema';

// todo: no custom logic for customers, this should be integrated into the platform instead.
const customIntegrationStatuses: Record<string, string[]> = {
  [IntegrationProviderEnum.ShopifyBurst]: Object.values(ShopifyBurstStatusEnum),
};

export default function getContentCustomStatuses(
  integrationProvider: IntegrationProviderEnum | null | undefined
): {
  hasCustomStatuses: boolean;
  interactionStatuses: SchemaType[];
} {
  const customStatusesNotEnabled = {
    hasCustomStatuses: false,
    interactionStatuses: [],
  };

  if (!integrationProvider) {
    return customStatusesNotEnabled;
  }

  const interactionStatuses: SchemaType[] = (
    customIntegrationStatuses[integrationProvider] || []
  ).map(toSchema);

  if (interactionStatuses.length === 0) {
    return customStatusesNotEnabled;
  }

  return {
    hasCustomStatuses: true,
    interactionStatuses: [
      ...interactionStatuses,
      { label: 'All Statuses', value: 'any' },
    ],
  };
}
