import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Icon, TextLink, H4, M, XS, IconButton } from 'design-system-web';
import explodeFeatures from 'lane-shared/helpers/features/explodeFeatures';
import { useTranslation } from 'react-i18next';
import useIsAdminView from 'hooks/useIsAdminView';
import { FONT_AWESOME_LIGHT } from 'lane-shared/helpers/constants/icons';
import { FullCalendarEvent } from '../hooks/useReservableCalendarView';
import { generateInteractionUrl } from '../../../hooks/useRowActions';
import styles from './EventDetails.scss';
import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';
import { ReservableStatusDropdown } from '../../ReservableStatusDropdown/ReservableStatusDropdown';

type Props = {
  event: FullCalendarEvent;
  userLocale: string;
  onClose?: () => void;
  onStatusUpdate?: () => void;
};

const TRANSLATION_KEYS = {
  adminNotes: 'shared.common.adminNotes.title',
  editReservation:
    'web.admin.channel.reservableManagement.calendar.event.editReservation',
  dateRange:
    'web.admin.channel.reservableManagement.table.detailsPopup.bookingRange',
};

export function EventDetails({
  event,
  userLocale,
  onStatusUpdate,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const start = DateTime.fromJSDate(event.start)
    .setZone(event.extendedProps.timeZone)
    .setLocale(userLocale);
  const end = DateTime.fromJSDate(event.end)
    .setZone(event.extendedProps.timeZone)
    .setLocale(userLocale);

  const now = DateTime.local().setZone(event.extendedProps.timeZone);
  const [, channelSlug] = useIsAdminView();
  const reservationUrl =
    channelSlug && event.extendedProps.interactionId
      ? generateInteractionUrl(event.extendedProps.interactionId, channelSlug)
      : null;
  const {
    reservableFeature,
    statusesFeature,
    cancelableFeature,
  } = explodeFeatures(
    // @ts-expect-error Argument of type 'ContentFeature[]' is not assignable to parameter of type 'Pick<FeatureInstance, "type" | "feature">[]'
    event.extendedProps.featureDefinition
  );
  const usernameAndCompany = [
    event.extendedProps.username,
    event.extendedProps.companyName,
  ]
    .filter(s => Boolean(s.trim().length))
    .join(', ');
  const isPastInteraction = end < now;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <H4>{event.extendedProps.contentName}</H4>
        <IconButton
          className={styles.closeButton}
          type={FONT_AWESOME_LIGHT}
          onClick={onClose}
          icon="times"
          data-testid="closeButton"
          data-cy="closeButton"
          size="medium"
        />
      </div>

      <div className={styles.content}>
        <div className={styles.row}>
          <Icon name="clock" />
          <div>
            {reservableFeature?.unitType ===
              ReservableUnitTypesEnum.Minutes && (
              <M>
                {t(TRANSLATION_KEYS.dateRange, {
                  startDate: start.toFormat('LLLL d, yyyy h:mm a'),
                  endDate: end.toFormat('h:mm a'),
                })}
              </M>
            )}
            {reservableFeature?.unitType === ReservableUnitTypesEnum.Days && (
              <M>
                {t(TRANSLATION_KEYS.dateRange, {
                  startDate: start.toFormat('LLLL d, yyyy h:mm a'),
                  endDate: end.toFormat('LLLL d, yyyy h:mm a'),
                })}
              </M>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <Icon name="user" />
          <div>
            <M>
              <p className={styles.truncatedText} title={usernameAndCompany}>
                {usernameAndCompany}
              </p>
            </M>
          </div>
        </div>

        {event.extendedProps.adminNote && (
          <div className={styles.row}>
            <Icon name="file-alt" />
            <div>
              <XS>{t(TRANSLATION_KEYS.adminNotes)}</XS>
              <div>
                <M>{event.extendedProps.adminNote}</M>
              </div>
            </div>
          </div>
        )}

        {reservableFeature?.userNotes && (
          <div className={styles.row}>
            <Icon name="file-alt" />
            <div>
              <XS>{reservableFeature?.userNotes?.labelText}</XS>
              <div>
                <M>
                  {event.extendedProps.features.Reservable?.userNotes ?? ''}
                </M>
              </div>
            </div>
          </div>
        )}

        {reservationUrl && (
          <div className={styles.row}>
            <Icon name="pencil" />
            <div>
              <Link component={TextLink} target="_blank" to={reservationUrl}>
                {t(TRANSLATION_KEYS.editReservation)}
              </Link>
            </div>
          </div>
        )}
      </div>

      <hr />
      <div className={styles.footer}>
        <ReservableStatusDropdown
          interactionId={event.extendedProps.interactionId!}
          initialStatus={event.extendedProps.status}
          statusFeature={statusesFeature}
          cancelableFeature={cancelableFeature}
          isPastInteraction={isPastInteraction}
          onSuccess={onStatusUpdate}
        />
      </div>
    </div>
  );
}
