import React from 'react';

import cx from 'classnames';
import { ValidationError } from 'yup';

import { getValidationMessages } from 'lane-shared/helpers';
import { ContentType } from 'lane-shared/types/content/Content';

import DatePickerButton from '../form/DatePickers/DatePickerButton';
import TextArea from '../form/TextArea';

import styles from './ContentNotificationEdit.scss';

type Props = {
  content: Pick<ContentType, 'liveDate' | 'endDate'>;
  timeZone?: string;
  notification: any;
  className?: string;
  style?: React.CSSProperties;
  validation: ValidationError | ValidationError[] | null | undefined;
  validationPath: string;
  onNotificationUpdated: (notification: any) => void;
};

export function ContentNotificationEdit({
  content,
  timeZone,
  notification,
  className,
  style,
  validation,
  validationPath,
  onNotificationUpdated,
}: Props) {
  return (
    <div
      className={cx(styles.ContentNotificationEdit, className)}
      style={style}
    >
      <DatePickerButton
        timeZone={timeZone}
        value={notification.sendAt}
        includeTime
        minDate={content.liveDate ?? undefined}
        maxDate={content.endDate ?? undefined}
        onSubmit={(sendAt: any) =>
          onNotificationUpdated({
            ...notification,
            sendAt,
          })
        }
      />

      <TextArea
        dataCy="contentNotificationText"
        className={styles.input}
        minRows={2}
        placeholder="Enter the notification text here…"
        value={notification.title}
        errors={getValidationMessages(validation, validationPath, 'title')}
        maxLength={255}
        onChange={title =>
          onNotificationUpdated({
            ...notification,
            title,
          })
        }
      />
    </div>
  );
}
