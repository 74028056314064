import * as yup from 'yup';

import { ICON_SETS } from '../../helpers/constants/icons';
import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

export class IconType extends ObjectTypeBase {
  group = TYPE_GROUP_OTHER;

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Properties,
  ];

  name = 'Icon';

  friendlyName = 'Icon';

  get example() {
    return {
      name: 'exclamation-circle',
      set: 'Feather',
      color: null,
      size: 24,
    };
  }

  get default(): any {
    return {
      name: 'exclamation-circle',
      set: 'Feather',
      color: null,
      size: 24,
    };
  }

  buildSchema() {
    return yup
      .object()
      .shape({
        set: yup.string().required().oneOf(ICON_SETS),
        name: yup.string().required(),
      })
      .nullable();
  }

  properties = {
    color: {
      type: 'Color',
      friendlyName: 'Icon Color',
      default: null,
    },
    name: {
      type: 'String',
      friendlyName: 'Icon',
      validators: [
        {
          name: 'Max',
          value: 255,
        },
      ],
    },
    size: {
      type: 'Em',
      friendlyName: 'Icon Size',
      validators: [
        {
          name: 'Max',
          value: 100,
        },
        {
          name: 'Min',
          value: 5,
        },
      ],
    },
    set: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: ICON_SETS,
        },
      ],
    },
  } as PropertiesInterface;
}

const definition = new IconType();

Types.addBaseType(definition);
export default definition;
