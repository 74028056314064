import { visitorManagementFeatureDisplayStatus } from 'lane-shared/domains/visitorManagement/helpers/visitorManagementContentBuilder';
import {
  ContentTypeRequirementEnum,
  FeatureDefinition,
} from 'lane-shared/types/FeatureDefinition';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

type FeatureDisplayForContentType<
  Key extends keyof typeof ContentTypeEnum,
  Value extends (
    currentDisplayStatus: boolean,
    feature: FeatureDefinition,
    contentType: ContentTypeEnum
  ) => boolean
> = {
  [K in Key]: Value;
};

const featureDisplayForContentTypeMap: Readonly<
  FeatureDisplayForContentType<
    ContentTypeEnum,
    (
      currentDisplayStatus: boolean,
      feature: FeatureDefinition,
      contentType: ContentTypeEnum
    ) => boolean
  >
> = Object.freeze({
  [ContentTypeEnum.Static]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.Content]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.Notice]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.ScheduledContent]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.ScheduledNotice]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.Promotion]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.Perk]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.WorkOrder]: defaultFeatureDisplayForContentType,
  [ContentTypeEnum.VisitorManagement]: visitorManagementFeatureDisplayStatus,
});

function defaultFeatureDisplayForContentType(
  currentDisplayStatus: boolean,
  feature: FeatureDefinition,
  contentType: ContentTypeEnum
) {
  return (
    !featureForbiddenForContentType(feature, contentType) &&
    currentDisplayStatus
  );
}

function featureForbiddenForContentType(
  feature: FeatureDefinition,
  contentType: ContentTypeEnum
) {
  return (
    feature.contentTypeRequirements?.find(
      featureRequirement => featureRequirement.type === contentType
    )?.requirement === ContentTypeRequirementEnum.Forbidden
  );
}

export function featureDisplayForContentType(
  feature: FeatureDefinition,
  currentDisplayStatus: boolean,
  contentType: ContentTypeEnum
) {
  return featureDisplayForContentTypeMap[contentType](
    currentDisplayStatus,
    feature,
    contentType
  );
}
