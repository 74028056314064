import { LaneType } from 'common-types';

export enum SecurityRuleTypeEnum {
  All = 'All',
  GroupRole = 'GroupRole',
  Owner = 'Owner',
  Creator = 'Creator',
  SpecificUser = 'SpecificUser',
  Source = 'Source',
  Channel = 'Channel',
  ChannelPermission = 'ChannelPermission',
  System = 'System',
}

export enum SecurityModesEnum {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export const SECURITY_TYPE_DESCRIPTIONS: { [key: string]: string } = {
  All: 'Anyone',
  GroupRole: 'A specific group role (team)',
  Owner: 'The user who created this',
  Creator: 'The user who created this, or the user who last updated this',
  Source:
    'Anyone on the Channel where this originated, with more than a Workplace Member role',
  Channel:
    'Anyone on this specific Channel, with more than a Workplace Member role',
  ChannelPermission:
    'Anyone on this specific Channel with specified permission',
  System: 'Only the system (ie. no users)',
};

export type PropertySecurityRule = {
  _id?: string;
  type: SecurityRuleTypeEnum;
  value?: null | { _id: string } | string | LaneType.UUID;
};

export type PropertySecurity = {
  read?: PropertySecurityRule[];
  create?: PropertySecurityRule[];
  update?: PropertySecurityRule[];
  delete?: PropertySecurityRule[];
};
