import { useContext } from 'react';

import UserDataContext from 'lane-shared/contexts/UserDataContext';
import { SupportedLocaleEnum } from 'localization';

import getUserLocale from '../helpers/getUserLocale';

/**
 * Gets the users locale, or if no user is set, gets the default locale
 */
export default function useUserLocale(): SupportedLocaleEnum {
  const { user } = useContext(UserDataContext);

  return user?.locale || getUserLocale();
}
