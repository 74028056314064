import { PublicUserType } from '../../hooks/useMyPublicUsersQuery';
import Types from '../../properties/Types';
import { PropertyType } from '../../types/properties/Property';

const CSV_ESCAPE_CHARACTER = '"';
const CSV_NO_ESCAPE_CHARACTER = '';

export default function propertyValueToCSVField({
  property,
  value,
  shouldEscape = true,
  locale,
  timeZone,
}: {
  property: PropertyType;
  value: any;
  // should the csv values be escaped
  shouldEscape?: boolean;
  locale?: string;
  timeZone?: string;
}): string {
  // sometimes we don't want to escape these values, for example when we
  // are iterating through an array of values, the top level value will
  // be escaped, so we don't need to escape each individual value
  const escapeChar = shouldEscape
    ? CSV_ESCAPE_CHARACTER
    : CSV_NO_ESCAPE_CHARACTER;

  if ([null, undefined].includes(value)) {
    return `${escapeChar}${escapeChar}`;
  }

  const Type = Types.getType(property.type);

  // if this is an array value, map the array.
  if (Array.isArray(value)) {
    return `${escapeChar}${value
      .map(value =>
        propertyValueToCSVField({
          property,
          value,
          shouldEscape: false,
          locale,
          timeZone,
        })
      )
      .join(', ')}${escapeChar}`;
  }

  // Is there a formatter for this type? if so use it.
  if (Type.formatter) {
    return `${escapeChar}${Type.formatter(value, {
      locale,
      timeZone,
    })}${escapeChar}`;
  }

  const baseTypes = Types.getBaseTypes();

  function selectUserToCSV(users: PublicUserType[]) {
    if (!users || !users.length) return '';
    return users.map(
      user =>
        ` ${escapeChar}${user.profile.name} - ${user.profile.email}${escapeChar}`
    );
  }

  switch (property.type) {
    case baseTypes.Number!.name:
    case baseTypes.Currency!.name:
      return `${value}`;
    case baseTypes.Url!.name:
    case baseTypes.String!.name:
    case baseTypes.Email!.name:
    case baseTypes.LongText!.name:
    case baseTypes.Time!.name:
      return `${escapeChar}${value}${escapeChar}`;
    case baseTypes.SelectUser!.name:
      return `${selectUserToCSV(value?.users)}`;
    case baseTypes.Channel!.name:
      return `${value.name}`;
    default:
      return `${escapeChar}${JSON.stringify(value)}${escapeChar}`;
  }
}
