import { useEffect } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { PaymentAccountFragment } from '../graphql/fragments';

type Params = {
  paymentAccountId?: string;
};

const query = gql`
  ${PaymentAccountFragment}

  query getPaymentAccount($paymentAccountId: UUID!) {
    payments {
      paymentAccount(paymentAccountId: $paymentAccountId) {
        _id
        ...PaymentAccountFragment
        channel {
          _id
        }
        user {
          _id
        }
      }
    }
  }
`;

export default function usePaymentAccountQuery({ paymentAccountId }: Params) {
  const [fetchMedia, { data, loading, error }] = useLazyQuery(query, {
    client: getClient(),
  });

  useEffect(() => {
    if (paymentAccountId) {
      fetchMedia({
        variables: {
          paymentAccountId,
        },
      });
    }
  }, [paymentAccountId]);

  const paymentAccount = data?.payments?.paymentAccount;

  return {
    paymentAccount,
    loading,
    error,
  } as const;
}
