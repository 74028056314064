import * as yup from 'yup';
import i18next from 'i18next';
import { isValidPhoneNumber } from '../helpers/phoneNumbers';

export default yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(i18next.t('web.admin.users.invitation.validationError.email'))
    .required(),
  name: yup
    .string()
    .trim()
    .min(
      2,
      i18next.t('web.admin.users.invitation.validationError.nameMinLength')
    )
    .required(),
  groupRoleId: yup.string(),
  companyId: yup.string().notRequired(),
  expires: yup.date().min(new Date()),
  mobileAccess: yup.boolean().notRequired(),
  accessControlGroups: yup.array().of(
    yup.object({
      channelId: yup.string(),
      acgIds: yup.string(),
    })
  ),
  groupRoleIds: yup.array().of(yup.string()),
  phone: yup
    .string()
    .trim()
    .test(
      'phone-validation',
      i18next.t('web.admin.users.invitation.validationError.invalidPhone'),
      value => {
        if (!value) {
          return true;
        }
        return value != null && isValidPhoneNumber(value, 'US');
      }
    ),
});
