import React, { useState, useEffect, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Modal } from 'design-system-web';
import { CircleListView } from 'components';
import { S } from 'components/typography';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getClient } from 'lane-shared/apollo';
import { enableMobileAccess } from 'lane-shared/graphql/accessControl';
import { mediaUrl } from 'lane-shared/helpers/formatters';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';

import {
  Data,
  DataStatus,
  UserAccessGroupIds,
} from 'lane-web/src/domains/accessControl/components/user-access/MixedStateList/types';
import {
  UserInfo,
  AccessControlGroup,
} from 'lane-web/src/domains/accessControl/types/AccessControl';
import { buildMixedStateData } from 'lane-web/src/domains/accessControl/helpers/buildMixedStateData';
import { useMobileAccessData } from 'lane-web/src/domains/accessControl/hooks/useMobileAccessData';

import { Location } from '../UserAccessLocationPicker';
import { LocationsList } from './LocationsList';
import { AccessGroupsList } from './AccessGroupsList';
import { SelectedAccessGroupsToChannel } from './types';
import * as converter from './utils/converters';
import * as operator from './utils/operators';
import * as validator from './utils/validators';

import styles from './styles.scss';

enum EnableMobileAccessModes {
  enable = 'enableMobileAccess',
  location = 'selectLocations',
}

enum ErrorReasons {
  fetchAccessGroups = 'fetchAccessGroups',
  onMobileAccessSubmit = 'onMobileAccessSubmit',
  validation = 'validation',
}

type MobileAccessChannelProfile = {
  channelId: string;
  channelName: string | undefined;
  image: string | undefined;
  accessGroups: AccessControlGroup[];
  selected: boolean;
};

type MobileAccessUserProfile = {
  userId: string;
  userName: string;
  image: string;
  accessGroups: { [key: string]: string[] };
};

type props = {
  currentUser: UserInfo;
  channelId: string;
  users: UserInfo[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (usersUpdated: UserInfo[]) => void;
  availableLocations?: Location[];
};

export function MobileAccessModal({
  currentUser,
  channelId,
  users,
  isOpen,
  onClose,
  onSubmit,
  availableLocations = [],
}: props) {
  const { t } = useTranslation();
  const v5Enabled = useFlag(FeatureFlag.AccessControlCredentialGroups, false);
  const currentUserContext = useContext(UserDataContext);
  const { channels } = useContext(ChannelsContext);
  const {
    accessGroups,
    loading,
    connectedChannel,
    fetchAccessGroups,
  } = useMobileAccessData(channelId, currentUser.integrationChannelId);

  const [loggedUser] = useState<MobileAccessUserProfile>(
    converter.convertUsertoUserProfile(currentUser, channels, channelId)
  );
  const [selectedUsers] = useState<MobileAccessUserProfile[]>(
    users.map(user =>
      converter.convertUsertoUserProfile(user, channels, channelId)
    )
  );
  const [mode, setMode] = useState<EnableMobileAccessModes>(
    EnableMobileAccessModes.enable
  );

  const [locations, setLocations] = useState<Data[]>([]);
  const [channelsToProcess, setChannelsToProcess] = useState<
    MobileAccessChannelProfile[]
  >([]);
  const [hasError, setHasError] = useState<{
    hasError: boolean;
    channelId: string;
    reason: ErrorReasons | null;
  }>({ hasError: false, channelId: '', reason: null });

  const initChannelsToProcess = () => {
    let channelsToProcess: MobileAccessChannelProfile[] = [
      converter.convertChannelToChannelProfile(
        channels,
        accessGroups,
        channelId,
        true
      ),
    ];

    if (connectedChannel) {
      const connectedChannelData = converter.convertChannelToChannelProfile(
        channels,
        connectedChannel.accessGroups,
        connectedChannel.channelId,
        true
      );
      channelsToProcess = [...channelsToProcess, connectedChannelData];
    }

    if (availableLocations.length && v5Enabled) {
      const connectedLocations = availableLocations.filter(
        location => location.isConnectedByTemplate
      );
      connectedLocations.forEach((location: Location) => {
        if (
          location.id !== channelId &&
          location.id !== connectedChannel?.channelId
        ) {
          const locationData = converter.convertChannelToChannelProfile(
            channels,
            [],
            location.id,
            false
          );
          channelsToProcess = [...channelsToProcess, locationData];
        }
      });
    }

    return channelsToProcess;
  };

  const buildAccessGroupsData = (
    accessGroups: AccessControlGroup[],
    channelId: string
  ) => {
    const filteredAcgList = operator.filterAccessGroupsByPermissions(
      accessGroups || [],
      channelId,
      currentUserContext.user?.roles || [],
      loggedUser.accessGroups[channelId] || []
    );

    const userAccessGroups: UserAccessGroupIds[] = selectedUsers.map(user => ({
      userId: user.userId,
      accessGroupIds: user.accessGroups[channelId] || [],
    }));

    return buildMixedStateData(filteredAcgList, [], { userAccessGroups });
  };

  const handleLocationSubmit = async (locations: Data[]) => {
    setLocations(locations);
    setMode(EnableMobileAccessModes.enable);

    const selectedLocations = locations.filter(
      location => location.status === DataStatus.selected
    );

    const updated = await Promise.all(
      channelsToProcess.map(async channel => {
        const location = selectedLocations.find(
          location => location.channelId === channel.channelId
        );
        if (!channel.selected && location) {
          if (!channel.accessGroups.length) {
            try {
              const accessGroups = await fetchAccessGroups(channel.channelId);
              return { ...channel, accessGroups, selected: true };
            } catch (error) {
              console.error('Error fetching access groups:', error);
              setHasError({
                hasError: true,
                channelId: channel.channelId,
                reason: ErrorReasons.fetchAccessGroups,
              });
            }
          }
          return { ...channel, selected: true };
        }

        if (channel.selected && !location) {
          return { ...channel, selected: false };
        }
        return channel;
      })
    );

    setChannelsToProcess(updated);
  };

  const handleMobileAccessSubmit = async (
    accessGroupsByChannel: SelectedAccessGroupsToChannel[]
  ) => {
    const isValid = validator.validateAccessGroupSelection(
      accessGroupsByChannel
    );
    if (!isValid) {
      setHasError({
        hasError: true,
        channelId,
        reason: ErrorReasons.validation,
      });
      return;
    }

    const accessGroupsCombined = converter.buildPayload(accessGroupsByChannel);

    try {
      await getClient().mutate({
        mutation: enableMobileAccess,
        variables: {
          channelId,
          userIds: selectedUsers.map(user => user.userId),
          accessControlGroups: accessGroupsCombined,
        },
      });

      onSubmit(users);
      onClose();
      const channelNames = channelsToProcess
        .filter(channel => channel.selected)
        .map(channel => channel.channelName)
        .join(' & ');
      window.Toast.show(
        users.length === 1
          ? t(
              'web.admin.accessControl.userAccess.modal.enableMobileAccess.toast.success.singleUser',
              {
                channelName: channelNames,
                userName: selectedUsers[0].userName,
              }
            )
          : t(
              'web.admin.accessControl.userAccess.modal.enableMobileAccess.toast.success.multipleUsers',
              { channelName: channelNames, userCount: selectedUsers.length }
            )
      );
    } catch (err) {
      console.error(err);
      setHasError({
        hasError: true,
        channelId: '',
        reason: ErrorReasons.onMobileAccessSubmit,
      });
    }
  };

  useEffect(() => {
    if (!loading && accessGroups.length) {
      const channelsToProccessUpdated = initChannelsToProcess();

      const locations = channelsToProccessUpdated.map(
        (channel: MobileAccessChannelProfile) => ({
          value: channel.channelId,
          label: channel.channelName || '',
          provider: '',
          channelId: channel.channelId,
          status: channel.selected
            ? DataStatus.selected
            : DataStatus.unselected,
          isDisabled: false,
          image: channel.image,
        })
      );

      setLocations(locations);
      setChannelsToProcess(channelsToProccessUpdated);
    }
  }, [accessGroups, loading]);

  const userCount = selectedUsers.length;
  return channelsToProcess.length > 0 && userCount > 0 ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(
        'web.admin.accessControl.userAccess.table.bulkActions.enableMobileAccess'
      )}
      className={cx(
        styles.modal,
        mode === EnableMobileAccessModes.enable && styles.accessList
      )}
      size="large"
      dataCy="mobileAccessModal"
    >
      <div data-testid="access-control-modal">
        <CircleListView
          image={mediaUrl(selectedUsers[0].image)}
          logo={mediaUrl(selectedUsers[0].image)}
          name={selectedUsers[0].userName}
          className={styles.mobileAccessCircleListView}
        >
          {userCount === 1 && <S>{selectedUsers[0].userName}</S>}
          {userCount === 2 && (
            <S>
              {t(
                'web.admin.accessControl.userAccess.modal.enableMobileAccess.userDetaiils.multipleUsers',
                {
                  fullname: selectedUsers[0].userName,
                  userCount: userCount - 1,
                }
              )}
            </S>
          )}
          {userCount > 2 && (
            <S>
              {t(
                'web.admin.accessControl.userAccess.modal.enableMobileAccess.userDetaiils.multipleUsers_plural',
                {
                  fullname: selectedUsers[0].userName,
                  userCount: userCount - 1,
                }
              )}
            </S>
          )}
        </CircleListView>
        {!loading &&
          hasError.hasError &&
          (hasError.reason === ErrorReasons.fetchAccessGroups ? (
            <S className={cx(styles.errorText)}>
              <span>
                {t('web.admin.accessControl.userAccess.modal.acgList.error')}
              </span>
              <label
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {}}
              >
                {t('web.admin.accessControl.userAccess.modal.acgList.reload')}
              </label>
              <span>
                {t('web.admin.accessControl.userAccess.modal.acgList.tryAgain')}
              </span>
            </S>
          ) : (
            <S className={cx(styles.errorText)}>
              <span>{t('Something went wrong')}</span>
            </S>
          ))}
        {mode === EnableMobileAccessModes.location && (
          <LocationsList
            availableLocations={locations}
            onClose={onClose}
            onSubmit={handleLocationSubmit}
          />
        )}
        {mode === EnableMobileAccessModes.enable &&
          channelsToProcess.length > 0 && (
            <AccessGroupsList
              accessGroupData={channelsToProcess
                ?.filter(channel => channel.selected)
                ?.map(channel => ({
                  channelId: channel.channelId,
                  channelName: channel.channelName || '',
                  image: channel.image,
                  accessGroups: buildAccessGroupsData(
                    channel.accessGroups,
                    channel.channelId
                  ),
                }))}
              loading={loading}
              locations={locations}
              onEdit={() => setMode(EnableMobileAccessModes.location)}
              onSubmit={handleMobileAccessSubmit}
              userCount={userCount}
            />
          )}
      </div>
    </Modal>
  ) : null;
}

export default MobileAccessModal;
