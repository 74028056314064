import { DateTime } from 'luxon';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { getContentOperatingHours } from 'lane-shared/hooks/features/reservable/helpers/getContentOperatingHours';
import { getReservableUnavailableRanges } from 'lane-shared/hooks/features/reservable/helpers/getReservableUnavailableRanges';
import {
  ReservableUnavailabilityRange,
  ReservableEventTypeEnum,
} from 'lane-shared/hooks/useReservableAvailabilityByRange';
import { ReservableWithReservationRanges } from './fetchUnavailabilityRangesForReservables';
import { getReferenceDateAvailability } from './getReferenceDateAvailability';

export function getUnavailabilityRangesForReservableContent({
  content,
  referenceDate,
  userRoleBase62Ids,
}: {
  content: ReservableWithReservationRanges[0];
  referenceDate: Date;
  userRoleBase62Ids: string[];
}) {
  const referenceDateTime = DateTime.fromJSDate(referenceDate).setZone(
    content.timeZone ?? 'UTC'
  );
  const { reservableFeature, timeAvailabilityFeature } = explodeFeatures(
    // @ts-expect-error TODO: fix this FeatureName != FeatureNameEnum
    content?.features
  );
  let unavailableRanges: ReservableUnavailabilityRange[] = [];

  const {
    isReferenceDateInThePast,
    isReferenceWeekdayUnavailable,
    isReferenceDateUnavailable,
  } = getReferenceDateAvailability({
    referenceDate,
    timeZone: content.timeZone ?? 'UTC',
    timeAvailabilityFeature,
    userRoleBase62Ids,
  });

  if (
    isReferenceDateInThePast ||
    isReferenceWeekdayUnavailable ||
    isReferenceDateUnavailable
  ) {
    const fullDayUnavailability = {
      eventType: ReservableEventTypeEnum.TimeUnavailable,
      interval: {
        startDate: referenceDate,
        endDate: referenceDateTime.plus({ hour: 24 }).toJSDate(),
      },
    };

    unavailableRanges = [fullDayUnavailability];

    return unavailableRanges;
  }

  const operatingHours = getContentOperatingHours({
    referenceDate,
    timeAvailability: timeAvailabilityFeature,
    userRoleBase62Ids,
    timeZone: content.timeZone ?? undefined,
  });

  unavailableRanges = getReservableUnavailableRanges({
    reservationDateRanges: content.unavailableRanges,
    referenceDate,
    operatingHours,
    bufferTimeConfig: reservableFeature!.bufferTime,
  });

  return unavailableRanges;
}
