export enum VisitorManagementFields {
  ALL_FIELDS_REQUIRED = 'ALL_FIELDS_REQUIRED',
  NAME_REQUIRED = 'NAME_REQUIRED',
  NAME_EMAIL_CELL_REQUIRED = 'NAME_EMAIL_CELL_REQUIRED',
  NAME_EMAIL_COMPANY_REQUIRED = 'NAME_EMAIL_COMPANY_REQUIRED',
  NAME_EMAIL_REQUIRED = 'NAME_EMAIL_REQUIRED',
  NAME_CELL_REQUIRED = 'NAME_CELL_REQUIRED',
  NAME_CELL_COMPANY_REQUIRED = 'NAME_CELL_COMPANY_REQUIRED',
  NAME_COMPANY_REQUIRED = 'NAME_COMPANY_REQUIRED',
}
