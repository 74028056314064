// https://openid.net/specs/openid-connect-core-1_0.html

export const OAUTH_IDENTITY_REQUIRED_FIELDS = [
  'iss',
  'sub',
  'aud',
  'exp',
  'iat',
];
export const OAUTH_IDENTITY_OPTIONAL_FIELDS = ['azp'];

export type OAuthIdentity = {
  // REQUIRED. Issuer Identifier for the Issuer of the response.
  // The iss value is a case sensitive URL using the https scheme
  // that contains scheme, host, and optionally, port number and
  // path components and no query or fragment components.
  iss: string;
  // REQUIRED. Subject Identifier. A locally unique and never
  // reassigned identifier within the Issuer for the End-User,
  // which is intended to be consumed by the Client, e.g.,
  // 24400320 or AItOawmwtWwcT0k51BayewNvutrJUqsvl6qs7A4.
  // It MUST NOT exceed 255 ASCII characters in length.
  // The sub value is a case sensitive string.
  sub: string;
  // REQUIRED. Audience(s) that this ID Token is intended for.
  // It MUST contain the OAuth 2.0 client_id of the Relying
  // Party as an audience value. It MAY also contain identifiers
  // for other audiences. In the general case, the aud value is
  // an array of case sensitive strings. In the common special
  // case when there is one audience, the aud value MAY be a
  // single case sensitive string.
  aud: string | string[];
  // REQUIRED. Expiration time on or after which the ID Token
  // MUST NOT be accepted for processing. The processing of this
  // parameter requires that the current date/time MUST be before
  // the expiration date/time listed in the value. Implementers
  // MAY provide for some small leeway, usually no more than a
  // few minutes, to account for clock skew. Its value is a JSON
  // number representing the number of seconds from
  // 1970-01-01T0:0:0Z as measured in UTC until the date/time.
  // See RFC 3339 [RFC3339] for details regarding date/times in
  // general and UTC in particular.
  exp: number;
  // REQUIRED. Time at which the JWT was issued. Its value is a
  // JSON number representing the number of seconds from
  // 1970-01-01T0:0:0Z as measured in UTC until the date/time.
  iat: number;
  // OPTIONAL. Authorized party - the party to which the ID
  // Token was issued. If present, it MUST contain the OAuth 2.0
  // Client ID of this party. This Claim is only needed when the
  // ID Token has a single audience value and that audience is
  // different than the authorized party. It MAY be included even
  // when the authorized party is the same as the sole audience.
  // The azp value is a case sensitive string containing a
  // StringOrURI value.
  azp: string;
} & { extra?: { [key: string]: any } };

export type OAuthUser = { firstName: string; lastName: string; email: string };
export type AzureADMe = {
  '@odata.context': string;
  businessPhones: string[];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: null | string;
  mail: null | string;
  mobilePhone: null | string;
  officeLocation: null | string;
  preferredLanguage: null | string;
  surname: string;
  userPrincipalName: string;
};
