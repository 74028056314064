import roundToNearestTimeInterval from './roundToNearestTimeInterval';

export default function getDisplayTime({ startMinutes, endMinutes }: any) {
  const mins = endMinutes - startMinutes;
  const nearestHalfHour = roundToNearestTimeInterval(mins, 15, 15);

  if (mins < 60) {
    return `${Math.round(nearestHalfHour)} mins`;
  }

  return `${Math.round((nearestHalfHour / 60) * 10) / 10} hrs`;
}
