import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';

import makeFileDownload from 'helpers/makeFileDownload';

export function ButtonExportGroup({ groupRole, loading }: any) {
  const { t } = useTranslation();

  function exportGroupToJSON() {
    makeFileDownload({
      name: `${groupRole.name}.json`,
      contents: JSON.stringify({
        permissions: groupRole.permissions,
      }),
      type: 'application/json',
    });
  }

  return (
    <Button
      loading={loading}
      onClick={exportGroupToJSON}
      startIcon={<Icon name="download" />}
    >
      {t('web.components.lane.Team.Header.ButtonExportGroup')}
    </Button>
  );
}
