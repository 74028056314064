import { LaneType } from 'common-types';
import { useDateRangeInput } from 'lane-shared/hooks/features/reservable/useDateRangeInput';
import { useReservableIntervalRanges } from 'lane-shared/hooks/features/reservable/useReservableIntervalRanges';
import { useTimePickerIntervalRanges } from 'lane-shared/hooks/features/reservable/useTimePickerIntervalRanges';
import { useWeekDayUnavailable } from 'lane-shared/hooks/features/reservable/useWeekDayUnavailable';
import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

export type DateRange = {
  startDate: Date;
  endDate: Date;
} | null;

export function useReservableDateRange(
  contentId: LaneType.UUID,
  referenceDate: Date,
  intervalMinutes: number,
  userGroupRoleIds: LaneType.UUID[],
  timeAvailabilityFeature: TimeAvailabilityFeatureProperties | undefined,
  bufferTimeConfig: LaneType.BufferTime | undefined,
  timeZone: string,
  onInteractionChange: (range: DateRange) => void,
  maxDuration: number,
  reservableDateRange?: {
    minDate: Date | undefined;
    maxDate: Date | undefined;
  }
) {
  const { intervals, isLoading, operatingHours } = useReservableIntervalRanges({
    contentId,
    referenceDate,
    intervalMinutes,
    userRoleBase62Ids: userGroupRoleIds,
    timeAvailabilityFeature,
    bufferTimeConfig,
    timeZone,
    reservableDateRange,
  });

  const { intervalRanges } = useTimePickerIntervalRanges({
    intervals,
    intervalMinutes,
    maxDurationMinutes: maxDuration,
    operatingHours,
  });

  const { dateRange, displayableTimes, onTimeChange } = useDateRangeInput({
    intervals: intervalRanges,
    onDateRangeChange: onInteractionChange,
    timeZone,
  });

  const { unavailableDates, unavailableWeekdays } = useWeekDayUnavailable({
    timeAvailabilityFeature,
    userGroupRoleIds,
  });

  return {
    isLoading,
    dateRange,
    displayableTimes,
    onTimeChange,
    unavailableDates,
    unavailableWeekdays,
  };
}
