import React from 'react';

import { useTranslation } from 'react-i18next';

import { useValidatedControl } from 'lane-shared/hooks';

import ValidationMessage from '../general/ValidationMessage';
import Dropdown from './Dropdown';
import { Item } from './Dropdown/Dropdown';

type Props<T> = {
  containerClassName?: any;
  className?: string | string[];
  items: Item<T>[];
  name?: string;
  value?: T;
  validation: any;
  initialValue?: T;
  onChange?: (item: Item<T>) => void;
  onValueChange: (value: T) => void;
  onBlur?: (value: T) => void;
  placeholder?: string;
  isPristine?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  label?: string;
  doTranslation?: boolean;
  fixedLabel?: boolean;
};

export default function ValidatedDropdown<T>({
  containerClassName,
  className = [],
  items,
  name,
  value,
  validation,
  initialValue,
  onChange,
  onValueChange,
  onBlur,
  placeholder,
  isPristine,
  isFullWidth,
  disabled,
  ...props
}: Props<T>) {
  const { t } = useTranslation();
  const [validate, validationMessages, success, error] = useValidatedControl(
    value,
    isPristine,
    validation
  );

  function handleValueChange(value: any) {
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    validate(value);
    onValueChange(value);
  }

  return (
    <div className={containerClassName}>
      <Dropdown
        className={className}
        placeholder={placeholder ?? t('Select Option')}
        initialValue={initialValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onValueChange={handleValueChange}
        name={name}
        success={success}
        error={error}
        items={items}
        isFullWidth={isFullWidth}
        disabled={disabled}
        {...props}
      />
      {!isPristine && <ValidationMessage errors={validationMessages} />}
    </div>
  );
}
