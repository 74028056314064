import React from 'react';

import cx from 'classnames';

import { MarginType } from '../typography/index';

import styles from './Grid.scss';

type GridProps = {
  children: React.ReactNode;
  columns?: number;
  rows?: number | 'auto';
  className?: string;
};

/** @deprecated, use this other better grid component... lane-web/src/components/general/Grid  */

export default function Grid({
  children,
  columns = 12,
  rows = 'auto',
  className,
}: GridProps) {
  const gridTemplateColumns = `repeat(${columns}, 1fr)`;

  return (
    <div
      className={cx(styles.grid, className)}
      style={{
        gridTemplateColumns,
        gridTemplateRows: rows,
      }}
    >
      {children}
    </div>
  );
}

type ColProps = {
  children?: React.ReactNode;
  className?: string;
  columns: [startLine: number, endLine: number];
  mb?: MarginType;
};

export function Col({ children, className, columns, mb }: ColProps) {
  const gridColumn = columns.join(' / ');

  return (
    <div className={className} style={{ gridColumn }} data-margin-bottom={mb}>
      {children}
    </div>
  );
}
