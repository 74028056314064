import { LaneType, LaneIntegrationType } from 'common-types';
import {
  INTERACTION_ACCEPTED,
  INTERACTION_AWAITING_RESPONSE,
  INTERACTION_CANCELLED,
  INTERACTION_CONFIRMED,
  INTERACTION_DECLINED,
} from '../../helpers/constants/interactions';
import { PublicUserType } from '../../hooks/useMyPublicUsersQuery';
import { IWizardState } from '../../hooks/useWizard';
import { ContentType } from '../content/Content';
import { ShopifyChannelType } from './ShopifyBurst';

export type ShopifyRsvpDraftInteractionData = Partial<LaneIntegrationType.ShopifyRsvpInteraction>;

export type ShopifyRsvpWizardState = Pick<
  IWizardState<ShopifyRsvpDraftInteractionData>,
  'data' | 'currentStepIndex'
>;

export const SHOPIFY_BURST_RSVP_VALID_DAYS = 14;

export enum ShopifyBurstRsvpEntryTimeValidEnum {
  /** not sure yet if this is valid, we maybe loading the info */
  Unknown = 'Unknown',
  /** we have passed the entry time on this burst */
  Invalid = 'Invalid',
  /** the user is still allowed to submit */
  Valid = 'Valid',
}

export type ShopifyBurstInteractionCacheType = {
  contentId: string;
  contentName: string;
  contentDescription: string;
  contentData: object;
  features: object;
  interactionId: string;
  interactionStartDate: Date;
  interactionEndDate: Date;
};

export type ShopifyRsvpContentStateType = {
  shopifyBurstContentId: LaneType.UUID;
  shopifyBurstInteractionId: LaneType.UUID;
  attendees: PublicUserType[];
  l9?: PublicUserType;
  attendeesCount: number;
  name: LaneType.String;
  port?: ShopifyChannelType;
  portTimeZone?: string;
  dateRange: LaneType.DateTimeRange;
  dailySchedule?: LaneType.UserContentInteraction;
  primaryRoomBooking?: LaneType.UserContentInteraction;
  activitiesBookings?: Array<LaneType.UserContentInteraction>;
  dailyScheduleData?: ShopifyBurstInteractionCacheType;
  primaryRoomBookingData?: ShopifyBurstInteractionCacheType;
  activitiesBookingsData?: ShopifyBurstInteractionCacheType[];
  acknowledgementContent?: ContentType;
  burstNameContent?: ContentType;
  /** @deprecated this field was preserved for backward compatability with old mobile versions that work with only v1 schema */
  questionnaire: LaneType.ShopifyBurstQuestionnaireTypeV2;
  questionnaireInteraction?: any;
  questionnaireContent?: ContentType;
  /** @deprecated this field was preserved for backward compatability with old mobile versions that work with only v1 schema */
  specialRequest: string;
  specialRequestContent?: ContentType;
  specialRequestInteraction?: any;
  isDiyBurst?: boolean;
  diyQuestions?: any;
  diyQuestionsContent?: ContentType;
  schemaVersion?: number;
};

export type RSVPContent = ContentType & {
  state: {
    _ShopifyBurst: ShopifyRsvpContentStateType;
  };
  channelId: LaneType.UUID;
  placements?: { user?: { _id: LaneType.UUID } }[];
};

export enum ShopifyRsvpTabsEnum {
  Invited = 'invited',
  Declined = 'declined',
  Accepted = 'accepted',
}

export enum ShopifyRsvpStatusEnum {
  /** -> Shopifolk responded Yes to RSVP */
  Accepted = INTERACTION_ACCEPTED,
  /** -> Shopifolk responded No to RSVP */
  Declined = INTERACTION_DECLINED,
  /** -> The burst was cancelled */
  Cancelled = INTERACTION_CANCELLED,
  /** -> The burst was confirmed */
  Confirmed = INTERACTION_CONFIRMED,
  /** -> Awaiting response from Shopifolk member */
  Awaiting = INTERACTION_AWAITING_RESPONSE,
}

export const rsvpAttendTitle = `Will you be attending this burst in person?`;
export const rsvpAttendNote = `Note: if you cannot attend in person please click ‘No’, however remote attendance is still required for bursts. You will be included in all calendar invites as well.`;
export const rsvpChangeNote =
  'If you need to change your RSVP, you can always come back and update your submission.';
export const specialRequestTitle = `Add special requests`;
export const specialRequestNote = `Note: Travel will be booked separately once your burst has been confirmed by email.`;
export const specialRequestPlaceholder = `Dietary restrictions? Accessibility requests?`;
export const covidRegulationsTitle = `I understand Shopify's COVID-19 regulations and have uploaded my proof of vaccination to Workday.`;
export const shopifyVaultRsvpLink =
  'https://vault.shopify.io/pages/12466-Uploading-Your-Vaccination-Records';
export const covidRegulationsErrorMessage = `Due to Shopify’s Covid-19 vaccination policies, you are not able to attend this burst in person. Please change your in-person attendance to ‘No’ to complete your RSVP.`;
