import * as yup from 'yup';

import {
  CHANNEL_DIRECTORY_TYPES,
  CHANNEL_DIRECTORY_TYPE,
} from '../../helpers/constants/channel';
import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

export class DirectoryType extends StringTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Directory';

  friendlyName = 'Directory';

  contexts = [TypeContextEnum.Block];

  get example() {
    return CHANNEL_DIRECTORY_TYPE.Retail;
  }

  get default(): any {
    return CHANNEL_DIRECTORY_TYPE.Retail;
  }

  buildSchema() {
    return yup.string().nullable().oneOf(CHANNEL_DIRECTORY_TYPES);
  }
}

const definition = new DirectoryType();

Types.addBaseType(definition);
export default definition;
