import React, { useState, useRef, useEffect } from 'react';

import LocationType from 'lane-shared/properties/baseTypes/Location';

import Map from '../general/Map';
import PlacesAutocomplete from '../general/PlacesAutocomplete';

import styles from './LocationInput.scss';

function LocationInput({ value, onChange, zoom, onZoomChanged }: any) {
  const [localZoom, setLocalZoom] = useState(zoom || 3);
  const [center, setCenter] = useState({
    lat: value ? value.latitude : LocationType.default.latitude,
    lng: value ? value.longitude : LocationType.default.longitude,
  });

  const mapRef = useRef(null);

  useEffect(() => {
    if (zoom) {
      setLocalZoom(zoom);
    }
  }, [zoom]);

  function handleChange(address: any) {
    onChange(address.geo);
    onZoomChanged(13);
    setLocalZoom(13);
    setCenter({
      lat: address.geo.latitude,
      lng: address.geo.longitude,
    });
  }

  function handleZoomChanged() {
    if (mapRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      onZoomChanged(mapRef.current.getZoom());
    }
  }

  function handleCenterChanged() {
    if (mapRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const latLng = mapRef.current.getCenter();

      const newLocation = {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      };

      onChange(newLocation);
    }
  }

  return (
    <div className={styles.Location}>
      <PlacesAutocomplete
        className={styles.search}
        value=""
        onChange={handleChange}
      />
      <Map
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ byRef: MutableRefObject<null>; zoom: any; ... Remove this comment to see the full error message
        byRef={mapRef}
        zoom={localZoom}
        defaultCenter={center}
        center={center}
        onCenterChanged={handleCenterChanged}
        onZoomChanged={handleZoomChanged}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      />
    </div>
  );
}

LocationInput.defaultProps = {
  onZoomChanged: () => {},
};

export default LocationInput;
