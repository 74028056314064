import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  ICON_SET_ION,
  ICON_VALID_NAMES,
} from 'lane-shared/helpers/constants/icons';

import IconButton from './IconButton';
import getFilteredIcons from './getFilteredIcons';
import getIconRealName from './getIconRealName';
import * as IonSet from 'react-icons/io';

import styles from './styles.scss';

// because there are hundreds of icons, this is generated on startup
// rather than on render.
const iconButtons = Object.keys(IonSet)
  .filter(name => {
    const realName = getIconRealName({ name, set: ICON_SET_ION });
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!ICON_VALID_NAMES.Ion[realName]) {
      return false;
    }

    return true;
  })
  .map(key => <IconButton set={ICON_SET_ION} name={key} key={key} />);

function IonIconPanel({ iconSearch }: any) {
  const { t } = useTranslation();
  const iconButtonsFiltered = getFilteredIcons({
    iconSet: iconButtons,
    iconSearch,
  });
  const contents = iconButtonsFiltered.length
    ? iconButtonsFiltered
    : t('No results found.');
  return <menu className={styles.icons}>{contents}</menu>;
}

export default memo(IonIconPanel);
