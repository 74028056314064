import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { FlexAlignItemsEnum } from '../../types/blocks/Flex';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

export class FlexAlignItemsType extends StringTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'FlexAlignItems';

  friendlyName = 'Cross-axis alignment.';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  superUserOnly = false;

  get example() {
    return FlexAlignItemsEnum.Center;
  }

  get default(): any {
    return FlexAlignItemsEnum.Center;
  }

  buildSchema() {
    return yup.string().nullable().oneOf(Object.values(FlexAlignItemsEnum));
  }
}

const definition = new FlexAlignItemsType();

Types.addBaseType(definition);

export default definition;
