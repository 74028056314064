import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesLessThan } from './ITypeValidatesLessThan';

export class LessThanValidator
  implements ValidatorInterface<ITypeValidatesLessThan> {
  name = 'LessThan';

  friendlyName = 'Less Than';

  arrayOnly = false;

  array = false;

  description = 'Field must be less than this other field.';

  types = ['Percentage', 'Currency', 'Number', 'DateTime', 'Time', 'Date'];

  properties = {
    value: {
      type: 'Key',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesLessThan) {
    type.addLessThanValidator(this);
  }

  constructor(public config?: Validator) {}
}

const LessThan = new LessThanValidator(undefined);

ValidatorRegistry.register(LessThanValidator, LessThan.name);

export default LessThan;
