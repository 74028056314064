import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  ReservableFeatureProperties,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import { getIsEnabled } from 'components/renderers/helpers/bufferTime';

import FeatureOptionToggle from '../components/FeatureOptionToggle';
import ReservableFeatureBufferTimeInput from './ReservableFeatureBufferTimeInput';

type props = {
  onChange: (isEnabled: boolean) => void;
  settings: ReservableFeatureProperties;
  onFeatureUpdated: (values: Partial<ReservableFeatureProperties>) => void;
  showCalendarInput: boolean;
  description: string;
  title: string;
};

const TRANSLATION_KEYS = {
  bufferTimeDescription:
    'web.content.features.reservable.ReservableBufferTime.bufferTime.description',
  beforeReservation:
    'web.content.features.reservable.ReservableBufferTime.beforeReservation',
  afterReservation:
    'web.content.features.reservable.ReservableBufferTime.afterReservation',
};

export default function ReservableBufferTime({
  onChange,
  settings,
  onFeatureUpdated,
  showCalendarInput,
  title,
  description,
}: props) {
  const { t } = useTranslation();
  return (
    <FeatureOptionToggle
      title={title}
      description={t(TRANSLATION_KEYS.bufferTimeDescription)}
      onChange={onChange}
      isExpanded={!!settings?.bufferTime?.isEnabled}
      TooltipComponent={description}
      disabled={
        settings?.maxQuantityPerSlot > 1 ||
        settings?.unitType !== ReservableUnitTypesEnum.Minutes
      }
      isAligned
    >
      {/* Before Reservation */}
      <ReservableFeatureBufferTimeInput
        title={t(TRANSLATION_KEYS.beforeReservation)}
        style={{ marginBottom: '34px' }}
        settings={settings}
        onDurationChange={value =>
          onFeatureUpdated({
            bufferTime: {
              ...settings.bufferTime,
              beforeDuration: value,
            },
          })
        }
        onCalenderLabelChange={text =>
          onFeatureUpdated({
            bufferTime: {
              ...settings.bufferTime,
              beforeDurationCalendarLabel: text,
            },
          })
        }
        showCalendarInput={showCalendarInput}
        selectedTime={settings?.bufferTime?.beforeDuration}
        calendarLabel={settings?.bufferTime?.beforeDurationCalendarLabel}
        isEnabled={getIsEnabled(settings?.bufferTime?.beforeDuration)}
      />

      {/* After Reservation */}
      <ReservableFeatureBufferTimeInput
        title={t(TRANSLATION_KEYS.afterReservation)}
        settings={settings}
        onDurationChange={value =>
          onFeatureUpdated({
            bufferTime: {
              ...settings.bufferTime,
              afterDuration: value,
            },
          })
        }
        onCalenderLabelChange={text =>
          onFeatureUpdated({
            bufferTime: {
              ...settings.bufferTime,
              afterDurationCalendarLabel: text,
            },
          })
        }
        showCalendarInput={showCalendarInput}
        selectedTime={settings?.bufferTime?.afterDuration}
        calendarLabel={settings?.bufferTime?.afterDurationCalendarLabel}
        isEnabled={getIsEnabled(settings?.bufferTime?.afterDuration)}
      />
    </FeatureOptionToggle>
  );
}
