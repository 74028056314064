import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { explodeValidators } from 'lane-shared/helpers/properties';
import { QuantityFeatureProperties } from 'lane-shared/types/features/QuantityFeatureProperties';

import Input from 'components/form/Input';
import Slider from 'components/form/Slider';
import Label from 'components/general/Label';
import { FeatureRendererPropsType } from 'components/renderers/features/FeatureRendererPropsType';

import styles from './Quantity.scss';
import { QuantityWaitlist } from './QuantityWaitlist';
import { QuantityAdvancedRules } from './QuantityAdvancedRules';

const quantityQuery = gql`
  query quantityClaimed($id: UUID!) {
    contentFeatureQuantityClaimed(_id: $id)
  }
`;

const TRANSLATION_KEYS = {
  amountRemaining: 'web.content.features.quantity.amountRemaining',
  maxPerUser: 'web.content.features.quantity.maxPerUser',
  totalQuantity: 'web.content.features.quantity.totalQuantity',
};

export default function QuantityFeatureRenderer({
  className,
  style,
  feature,
  content,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<QuantityFeatureProperties>) {
  const { t } = useTranslation();

  const [quantityClaimed, setQuantityClaimed] = useState(0);

  async function getQuantityClaimed() {
    try {
      const result = await getClient().query({
        query: quantityQuery,
        fetchPolicy: 'network-only',
        variables: {
          id: content._id,
        },
      });

      setQuantityClaimed(result.data.contentFeatureQuantityClaimed);
    } catch (err) {
      // ignore error
      // content might not be created yet - it might still be a draft
    }
  }

  useEffect(() => {
    if (content?._id && contentFeature) {
      getQuantityClaimed();
    }
  }, [content?._id, !!contentFeature]);

  const { maxValidator } = explodeValidators(
    feature.properties.total.validators
  );

  return (
    <div className={cx(styles.Quantity, className)} style={style}>
      <Label h1>{t(feature.friendlyName)}</Label>

      <p>{t(feature.description)}</p>

      {contentFeature && (
        <section>
          <fieldset>
            <Label TooltipComponent={t(feature.properties.total.description)}>
              {t(TRANSLATION_KEYS.totalQuantity)}
            </Label>
            <div className={styles.row}>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                className={styles.slider}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(total: any) => void' is not assignable to t... Remove this comment to see the full error message
                onChange={(total: any) => onFeatureUpdated({ total })}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={2}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                max={maxValidator.value}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={Number(contentFeature.feature.total)}
              />
              <Input
                className={styles.input}
                value={contentFeature.feature.total}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
                onChange={total => onFeatureUpdated({ total })}
                input={{
                  min: 2,
                  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  max: maxValidator.value,
                }}
                type="number"
              />
            </div>
          </fieldset>

          {quantityClaimed > 0 && (
            <fieldset>
              <Label
                TooltipComponent={t(feature.properties.quantity.description)}
              >
                {t(TRANSLATION_KEYS.amountRemaining)}
              </Label>
              <div className={styles.row}>
                <p className={styles.number}>
                  <span className={styles.bold}>
                    {Math.max(
                      0,
                      contentFeature.feature.total - quantityClaimed
                    )}
                  </span>{' '}
                  ({quantityClaimed} claimed)
                </p>
              </div>
            </fieldset>
          )}

          <fieldset>
            <Label TooltipComponent={t(feature.properties.max.description)}>
              {t(TRANSLATION_KEYS.maxPerUser)}
            </Label>
            <div className={styles.row}>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                className={styles.slider}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(max: any) => void' is not assignable to typ... Remove this comment to see the full error message
                onChange={(max: any) => onFeatureUpdated({ max })}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={1}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                max={Number(contentFeature.feature.quantity)}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={Number(contentFeature.feature.max)}
              />
              <Input
                className={styles.input}
                value={contentFeature.feature.max}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
                onChange={max => onFeatureUpdated({ max })}
                input={{
                  min: 1,
                  max: contentFeature.feature.quantity,
                }}
                type="number"
              />
            </div>
          </fieldset>
        </section>
      )}

      <QuantityWaitlist
        feature={feature}
        contentFeature={contentFeature}
        content={content}
        onFeatureUpdated={onFeatureUpdated}
      />
      <QuantityAdvancedRules
        feature={feature}
        contentFeature={contentFeature}
        onFeatureUpdated={onFeatureUpdated}
      />
    </div>
  );
}
