import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { getDisplayName } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { usePublicUserQuery } from 'lane-shared/hooks';
import { GuestInviteGuestType } from 'lane-shared/renderers/v5/features/types/GuestInviteGuestType';

import { Thumbnail } from 'components/general';
import { XS, H5 } from 'components/typography';

import styles from './GuestInviteGuestListItem.scss';

type GuestInviteGuestListItemProps = {
  guest: GuestInviteGuestType;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onRemoveGuest?: (GuestInviteGuestType) => void;
  className?: string;
  style?: React.CSSProperties;
};

export default function GuestInviteGuestListItem({
  guest,
  onRemoveGuest,
  className,
  style,
}: GuestInviteGuestListItemProps) {
  const { user } = usePublicUserQuery({
    userId: guest?.user?._id,
  });

  return (
    <div className={cx(styles.GuestListGuestListItem, className)} style={style}>
      {user && (
        <div className={styles.user}>
          <Thumbnail
            className={styles.thumbnail}
            src={imageUrl(user.profile.image || user.profile.logo)}
            name={getDisplayName(user)}
          />
          <div className={styles.textWrapper}>
            <H5 bold>{getDisplayName(user)}</H5>
            <XS>{user.profile.email}</XS>
          </div>
        </div>
      )}
      {!user && (
        <div className={styles.user}>
          <Thumbnail
            className={styles.thumbnail}
            name={getDisplayName(guest)}
          />
          <div className={styles.textWrapper}>
            <H5>{guest?.name}</H5>
            <XS>{guest?.email}</XS>
            <div>
              <XS>{guest?.company}</XS>
            </div>
          </div>
        </div>
      )}
      {onRemoveGuest && (
        <Icon
          onClick={() => onRemoveGuest(guest)}
          name="times"
          type="far"
          className={styles.removeIcon}
          set={ICON_SET_FONTAWESOME}
        />
      )}
    </div>
  );
}
