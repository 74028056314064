import React, { useState, useEffect, useMemo } from 'react';
import { Icon } from 'design-system-web';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { mediaUrl } from 'lane-shared/helpers/formatters';

import CircleListView from 'components/lane/CircleListView';
import { M, S } from 'components/typography';
import { Tooltip } from 'lane-web/src/components';
import { formatAccessManagerRoles } from 'lane-web/src/domains/accessControl/helpers/formatAccessManagerRoles';

import styles from './styles.scss';

export type Location = {
  id: string;
  name: string;
  isCurrentChannel: boolean;
  logo?: string;
  image?: string;
  childLocations?: Location[];
  userRoles?: string[];
  type?: string | null;
  isConnectedByTemplate?: boolean;
};

type props = {
  locations: Location[];
  onChange: (channelId: string) => void;
  value?: string;
  className?: string;
};

const sortLocations = (locations: Location[]): Location[] => {
  return locations.sort((a, b) => {
    const aIsSelected =
      a.isCurrentChannel ||
      a.childLocations?.some(child => child.isCurrentChannel);
    const bIsSelected =
      b.isCurrentChannel ||
      b.childLocations?.some(child => child.isCurrentChannel);

    if (aIsSelected && !bIsSelected) {
      return -1;
    }
    if (!aIsSelected && bIsSelected) {
      return 1;
    }
    return 0;
  });
};

const UserAccessLocationButton = (
  location: Location,
  onChange: any,
  isSelected: boolean,
  isExpanded: boolean,
  isChildLocation?: boolean
) => {
  const { t } = useTranslation();
  const { id, name, image, logo, userRoles } = location;
  const accessManagerRole = formatAccessManagerRoles(userRoles ?? []);
  return (
    <button
      className={cx(
        styles.UserAccessLocationPickerButton,
        isSelected ? styles.selected : '',
        isChildLocation ? styles.ChildLocation : ''
      )}
      disabled={isSelected}
      onClick={() => onChange(location)}
      data-testid={`user-access-location-picker-${id}`}
    >
      <Tooltip
        wrapperClassName={styles.Tooltip}
        TooltipComponent={<span>{name}</span>}
        placement="right"
      >
        <span />
      </Tooltip>

      <CircleListView image={mediaUrl(image)} logo={mediaUrl(logo)}>
        <div
          className={cx(
            styles.UserAccessLocationPickerContent,
            !isExpanded ? styles.Collapsed : ''
          )}
        >
          <M style={{ fontWeight: '500' }}>{name}</M>
          {accessManagerRole && (
            <S variant="secondary">{t(accessManagerRole)}</S>
          )}
        </div>
      </CircleListView>
    </button>
  );
};

export function UserAccessLocationPicker({
  locations,
  value,
  onChange,
  className,
}: props) {
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(
    value || null
  );
  const [channels, setChannels] = useState<Location[]>(locations);
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setChannels(locations);
  }, [locations]);

  const sortedLocations = useMemo(() => sortLocations(channels), [channels]);
  const handleOnChange = (location: Location) => {
    setSelectedChannelId(location.id);
    onChange(location.id);
  };

  const handleLocationPickerToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={cx(
        styles.UserAccessLocationPickerContainer,
        className,
        !isExpanded ? styles.Collapsed : ''
      )}
    >
      <div className={cx(styles.UserAccessLocationPickerControlsContainer)}>
        {isExpanded ? (
          <button
            className={cx(styles.UserAccessLocationPickerControls)}
            onClick={handleLocationPickerToggle}
          >
            <Icon name="chevron-left" />
            <S>
              {t(
                'web.admin.accessControl.userAccess.locationPicker.controls.minimizeMenu'
              )}
            </S>
          </button>
        ) : (
          <Tooltip
            TooltipComponent={
              <span>
                {t(
                  'web.admin.accessControl.userAccess.locationPicker.controls.expandMenu'
                )}
              </span>
            }
            placement="right"
          >
            <button
              className={cx(styles.UserAccessLocationPickerControls)}
              onClick={handleLocationPickerToggle}
            >
              <Icon name="chevron-right" />
            </button>
          </Tooltip>
        )}
      </div>
      <div className={cx(styles.UserAccessLocationsContainer)}>
        {sortedLocations.map((location: Location) => {
          const { id } = location;
          const isSelected = selectedChannelId === id;
          return (
            <div key={id} className={cx(styles.UserAccessLocationPickerGroup)}>
              {UserAccessLocationButton(
                location,
                handleOnChange,
                isSelected,
                isExpanded
              )}
              {location?.childLocations?.map((childLocation: Location) => {
                const { id } = childLocation;
                const isSelected = selectedChannelId === id;
                return (
                  <div
                    key={id}
                    className={cx(styles.UserAccessLocationPickerGroup)}
                  >
                    {UserAccessLocationButton(
                      childLocation,
                      handleOnChange,
                      isSelected,
                      isExpanded,
                      true
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
