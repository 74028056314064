import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { updateMetatagValues } from 'lane-shared/graphql/metatag';
import { castForUpdate, objectToArray, pause } from 'lane-shared/helpers';
import { constructProperty } from 'lane-shared/helpers/content';
import { createShapeFromProperties } from 'lane-shared/properties';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { MetatagValue } from 'lane-shared/types/Metatag';
import {
  PropertiesInterface,
  PropertyType,
} from 'lane-shared/types/properties/Property';

import { H4 } from 'components/typography';

import Property from '../builder/properties/input/Property';
import Button from '../general/Button';
import ControlMenu from '../general/ControlMenu';
import ErrorMessage from '../general/ErrorMessage';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  metatag: {
    _id: LaneType.UUID;
    name: string;
    type: 'Simple' | 'Complex';
    properties: PropertiesInterface;
  };
  metatagValue: MetatagValue;
  channel: ChannelType;
  onValueUpdate?: (update: Partial<MetatagValue>) => void;
  onValueCreated?: (metatagValue: MetatagValue) => void;
  onValueSaved?: (metatagValue: MetatagValue) => void;
  onCancel: () => void;
};

export default function MetatagValueEdit({
  className,
  style,
  metatag,
  metatagValue,
  channel,
  onValueUpdate = () => null,
  onValueCreated = () => null,
  onValueSaved = () => null,
  onCancel,
}: Props) {
  const { user } = useContext(UserDataContext);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [fields, setFields] = useState<PropertyType[]>([]);
  const [newValue, setNewValue] = useState<any>(metatagValue?.value || null);
  const [validator, setValidator] = useState<yup.Schema<any> | null>(null);
  const { t } = useTranslation();

  function constructNewValue() {
    const obj = {
      _id: uuid(),
    };

    Object.entries(metatag.properties).forEach(
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ([key, property]) => (obj[key] = constructProperty(property))
    );

    setNewValue(obj);
  }

  function enforceValueProperties() {
    Object.entries(metatag.properties).forEach(([key, property]) => {
      if (!newValue) {
        newValue[key] = constructProperty(property);
      }
    });

    setNewValue({ ...newValue });
  }

  useEffect(() => {
    if (metatag?.properties) {
      // when properties updates, recreate the field array for easy access
      setFields(objectToArray(metatag.properties));
      setValidator(
        yup.object().shape(createShapeFromProperties(metatag.properties))
      );

      if (!metatagValue) {
        constructNewValue();
      } else {
        enforceValueProperties();
      }
    }
  }, [metatag?.properties]);

  async function validateValue(value = newValue) {
    setError(null);

    if (!validator) {
      return false;
    }

    try {
      await validator.validate(value, { abortEarly: false });
      return true;
    } catch (err) {
      setError(err);
      return false;
    }
  }

  async function updateNewValue(props: any) {
    const updated = {
      ...newValue,
      ...props,
    };

    setNewValue(updated);

    await validateValue(updated);

    onValueUpdate(updated);
  }

  async function createValue() {
    if (!(await validateValue())) {
      return;
    }

    setUpdating(true);

    try {
      await pause();

      const forCreate = !metatagValue;

      // this is either a new value, or we are updating an existing value
      const variables = forCreate
        ? {
            metatag: {
              _id: metatag._id,
              values: [
                {
                  value: newValue,
                },
              ],
            },
          }
        : {
            metatag: {
              _id: metatag._id,
              values: [
                {
                  ...castForUpdate(metatagValue),
                  value: newValue,
                },
              ],
            },
          };

      const { data } = await getClient().mutate({
        mutation: updateMetatagValues,
        variables,
      });

      if (forCreate) {
        onValueCreated(data.updateMetatag);
        window.Toast.show(
          t(`web.admin.content.metatag.tabItem.edit.valueAdded`)
        );
      } else {
        onValueSaved(data.updateMetatag);
        window.Toast.show(
          t('web.admin.content.metatag.tabItem.edit.valueUpdated')
        );
      }
    } catch (err) {
      setError(err);
    }

    setUpdating(false);
  }

  if (!newValue) {
    return null;
  }

  return (
    <div className={cx(className)} style={style}>
      <ErrorMessage error={error} />

      {fields.map(field => (
        <fieldset key={field._id}>
          <H4 mt={6} mb={2}>
            {field.friendlyName || field.name}
          </H4>
          <Property
            object={newValue}
            channel={channel}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ object: any; channel: ChannelType; user: U... Remove this comment to see the full error message
            user={user}
            property={field}
            value={newValue[field.name!]}
            onChange={value => updateNewValue({ [field.name!]: value })}
          />
        </fieldset>
      ))}
      <ControlMenu mt={4}>
        <hr />
        <Button loading={updating} onClick={onCancel}>
          <label>
            {t('web.admin.content.metatag.tabItem.edit.fieldEditWindow.cancel')}
          </label>
        </Button>
        <Button loading={updating} onClick={createValue} variant="contained">
          <label>
            {t('web.admin.content.metatag.tabItem.edit.fieldEditWindow.save')}
          </label>
        </Button>
      </ControlMenu>
    </div>
  );
}
