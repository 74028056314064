import { useContext, useEffect, useMemo, useState } from 'react';

import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { UserDataContext } from '../contexts';
import { parseDateTime } from '../helpers/dates';
import {
  getDateTimesFromTimeRange,
  getMonthUnavailableDateRangesFromTimeAvailability,
} from '../helpers/features';
import { DateRangeType } from '../types/baseTypes/DateRangeType';

type Props = {
  referenceDate?: Date;
  value: DateRangeType | null;
  timeZone?: string;
  minTime: number;
  maxTime: number;
  slotSize: number;
  timeAvailabilityFeature: any;
  useCustomTimeSlots: Boolean;
  customTimeSlots?: LaneType.TimeRange[];
};

export default function useTimeSlotsPicker({
  referenceDate,
  value,
  timeZone,
  minTime,
  maxTime,
  slotSize,
  timeAvailabilityFeature,
  useCustomTimeSlots,
  customTimeSlots,
}: Props) {
  const { user } = useContext(UserDataContext);

  const [unavailableMonthlyRanges, setUnavailableMonthlyRanges] = useState<
    DateRangeType[]
  >([]);

  function updateUnavailableRanges(day: any) {
    const ranges = getMonthUnavailableDateRangesFromTimeAvailability(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
      parseDateTime(day),
      timeAvailabilityFeature,
      timeZone,
      user?.roles?.map(({ groupRole }) => groupRole._id) || []
    );

    setUnavailableMonthlyRanges(ranges);
  }

  useEffect(() => {
    if (referenceDate) {
      updateUnavailableRanges(referenceDate);
    }
  }, [referenceDate?.getTime?.()]);

  const options = useMemo(() => {
    const refDate = parseDateTime(referenceDate, timeZone);
    const startDate = parseDateTime(value?.startDate, timeZone);
    let today = (refDate || startDate || DateTime.local()).startOf('day');

    if (timeZone) {
      today = today.setZone(timeZone);
    }

    const options: DateRangeType[] = [];

    if (useCustomTimeSlots && customTimeSlots && timeZone) {
      for (const slot of customTimeSlots) {
        const dateTimeRangeForSlot = getDateTimesFromTimeRange({
          referenceDate: today,
          timeRange: slot,
          timeZone,
        });
        options.push({
          startDate: dateTimeRangeForSlot.start.startOf('minute').toJSDate(),
          endDate: dateTimeRangeForSlot.end.startOf('minute').toJSDate(),
        });
      }
    } else {
      // starting at the minTime.
      // break the options into time slots.
      let start = minTime;

      while (start < maxTime) {
        const hour = Math.floor(start / 60);
        const minute = start - hour * 60;

        options.push({
          startDate: today.set({ hour, minute }).toJSDate(),
          endDate: today.set({ hour, minute: minute + slotSize }).toJSDate(),
        });

        start += slotSize;
      }
    }

    return options;
  }, [
    minTime,
    maxTime,
    slotSize,
    referenceDate?.getTime?.(),
    value?.startDate?.getTime?.(),
    useCustomTimeSlots,
    customTimeSlots,
  ]);

  return {
    options,
    unavailableMonthlyRanges,
    updateUnavailableRanges,
  };
}
