import { v4 as uuid } from 'uuid';

import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';

import { PackagedTypeEnum } from '../../types/properties/PackagedTypeEnum';
import { PropertyType } from '../../types/properties/Property';
import constructPackagedType from './constructPackagedType';
import { FriendlyType } from './friendlyTypeOptions';

const defaultWidth = 150;

const MAX_USERS_PER_SELECT = 200;

export default function constructFriendlyType(
  friendlyType: FriendlyType,
  name: string
): PropertyType {
  switch (friendlyType) {
    case PackagedTypeEnum.Checkboxes:
    case PackagedTypeEnum.Dropdown:
    case PackagedTypeEnum.Quantity:
    case PackagedTypeEnum.Radios:
    case PackagedTypeEnum.Slider:
      return constructPackagedType(friendlyType, name);
    case 'Currency':
      return {
        name,
        _id: uuid(),
        isArray: false,
        packagedType: PackagedTypeEnum.None,
        placeholder: '',
        type: friendlyType,
        validators: [
          {
            name: 'Min',
            value: 0,
          },
        ],
        width: defaultWidth,
      };
    case 'Number':
    case 'Percentage':
      return {
        name,
        _id: uuid(),
        isArray: false,
        packagedType: PackagedTypeEnum.None,
        placeholder: '',
        type: friendlyType,
        validators: [
          {
            name: 'Min',
            value: 0,
          },
          {
            name: 'Max',
            value: 100,
          },
        ],
        width: defaultWidth,
      };
    case 'LongText':
      return {
        name,
        _id: uuid(),
        isArray: false,
        packagedType: PackagedTypeEnum.None,
        placeholder: '',
        type: friendlyType,
        validators: [
          {
            name: 'Max',
            value: 2 ** 14, // 16kb of text
          },
        ],
        width: defaultWidth,
      };
    case 'Url':
      return {
        name,
        _id: uuid(),
        isArray: false,
        packagedType: PackagedTypeEnum.None,
        placeholder: '',
        type: friendlyType,
        validators: [
          {
            name: 'Max',
            value: 2048, // RFC limit
          },
        ],
        width: defaultWidth,
      };
    case 'Email':
    case 'PhoneNumber':
    case 'String':
      return {
        name,
        _id: uuid(),
        isArray: false,
        packagedType: PackagedTypeEnum.None,
        placeholder: '',
        type: friendlyType,
        validators: [
          {
            name: 'Max',
            value: 256,
          },
        ],
        width: defaultWidth,
      };
    case 'SelectUser':
      return {
        name,
        _id: uuid(),
        isArray: false,
        isMultiSelect: true,
        hidePropertyLabel: true,
        packagedType: PackagedTypeEnum.None,
        placeholder: 'Select User(s)',
        type: friendlyType,
        filters: {
          groupRoles: {
            type: SecurityRuleTypeEnum.All,
            value: null,
          },
        },
        validators: [{ name: 'ArrayMax', value: MAX_USERS_PER_SELECT }],
        width: defaultWidth,
        alertText:
          'All Users of the channel will be included in the Data Field input, if the ‘Included Users’ is not filtered by a specific team.',
      };
    case 'Address':
    case 'Audio':
    case 'Boolean':
    case 'Color':
    case 'Date':
    case 'DateRange':
    case 'DateTimeRange':
    case 'DateTime':
    case 'Document':
    case 'Image':
    case 'Location':
    case 'Attachment':
    case 'Time':
    case 'TimeRange':
    case 'Video':
    case PackagedTypeEnum.None:
    default:
      return {
        name,
        _id: uuid(),
        isArray: false,
        packagedType: PackagedTypeEnum.None,
        placeholder: '',
        type: friendlyType,
        validators: [],
        width: defaultWidth,
      };
  }
}
