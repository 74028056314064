import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { explodeFeatures } from 'lane-shared/helpers/features';

import { RelatedSpace } from 'components/cards/RelatedSpacesCard';
import ChildRelatedSpaceDifferencesWithParent from 'components/renderers/helpers/ChildRelatedSpaceDifferencesWithParent';

const relatedSpaceContentQuery = (_: string, index: number) => `
    content${index}: content(_id: $contentId_${index}) {
      _id
      name
      description
      backgroundImage
      channel {
        _id
        slug
      }
      features {
        _id
        type
        feature
      }
    }
  `;

export function formatQueryVariableNames(contentIds: Array<string>) {
  return contentIds.map((_, idx) => `$contentId_${idx}: UUID!`).join(', ');
}

export function composeQuery(contentIds?: Array<string>) {
  if (!contentIds || contentIds === null || contentIds.length === 0) {
    // noop query
    return gql`
      query content {
        _
      }
    `;
  }

  const queriesList = contentIds.map(relatedSpaceContentQuery);

  const query = gql`
    query RelatedSpace(${formatQueryVariableNames(contentIds)}) {
      ${queriesList}
    }
  `;

  return query;
}

export const formatChildrenRelatedSpaces = (
  data: Record<string, RelatedSpace>,
  parentsChildrenIds: Array<string>
) => {
  const childrenRelatedSpaces: Array<RelatedSpace> = [];

  if (data) {
    parentsChildrenIds.forEach((_childId, index) => {
      childrenRelatedSpaces.push(data[`content${index}`]!);
    });
  }

  return childrenRelatedSpaces;
};

export default function useCheckRelatedSpacesDiscrepancies(
  content: RelatedSpace
) {
  const { reservableFeature: parentFeature } = explodeFeatures(
    content?.features
  );

  const childrenIds = parentFeature?.relatedSpaces?.childrenIds ?? [];
  const query = composeQuery(childrenIds);

  const variables = childrenIds.reduce((acc, childrenId, idx) => {
    acc[`contentId_${idx}`] = childrenId;
    return acc;
  }, {} as Record<string, LaneType.UUID>);

  const { data } = useQuery(query, {
    variables,
    skip: !parentFeature?.relatedSpaces?.childrenIds?.length,
  });

  const hasRelatedSpacesDiscrepancies = formatChildrenRelatedSpaces(
    data,
    childrenIds
  ).some(child => {
    const diff = new ChildRelatedSpaceDifferencesWithParent(content, child);
    return diff.hasDifferences;
  });

  return {
    hasRelatedSpacesDiscrepancies,
  };
}
