import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import './MenuFeatureItemOption';
import './MenuFeatureItemOptionList';

// $999 dollars per menu item seems a reasonable limit?
export const MENU_FEATURE_HIGHEST_PRICE = 99900;
export const MENU_FEATURE_MAX_QUANTITY = 99;
export const MENU_FEATURE_MAX_OPTION_LISTS = 10;
export const MENU_FEATURE_DEFAULT_PREPARATION_TIME = 10;
export const MENU_FEATURE_MAX_PREPARATION_TIME = 120;

export const MENU_ITEM_TAGS = [
  'Wellness',
  'Vegan',
  'Vegetarian',
  'Halal',
  'Gluten Free',
  'Peanut Free',
  'Non Dairy',
];

const properties: PropertiesInterface = {
  name: {
    friendlyName: 'web.content.features.menuFeatureItem.name.friendlyName',
    description: 'web.content.features.menuFeatureItem.name.description',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 80,
      },
      {
        name: 'Min',
        value: 2,
      },
    ],
  },
  description: {
    friendlyName:
      'web.content.features.menuFeatureItem.description.friendlyName',
    description: 'web.content.features.menuFeatureItem.description.description',
    type: 'LongText',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 256,
      },
      {
        name: 'Min',
        value: 2,
      },
    ],
  },
  category: {
    friendlyName: 'Menu Item Category',
    description: 'What category should this item appear in.',
    type: 'String',
    validators: [],
  },
  tags: {
    friendlyName: 'Menu Item Tags',
    description: 'What tags should this item be associated with.',
    type: 'String',
    isArray: true,
    default: [],
    validators: [
      {
        name: 'In',
        value: MENU_ITEM_TAGS,
      },
    ],
  },
  isAvailable: {
    friendlyName:
      'web.content.features.menuFeatureItem.isAvailable.friendlyName',
    description: 'web.content.features.menuFeatureItem.isAvailable.description',
    type: 'Boolean',
    default: true,
  },
  price: {
    friendlyName: 'Price',
    description: 'web.content.features.menuFeatureItem.price.description',
    type: 'Currency',
    default: 0,
    validators: [
      {
        name: 'Max',
        value: MENU_FEATURE_HIGHEST_PRICE,
      },
      {
        name: 'Min',
        value: -MENU_FEATURE_HIGHEST_PRICE,
      },
    ],
  },
  maxQuantity: {
    friendlyName: 'Maximum Quantity',
    description: 'web.content.features.menuFeatureItem.maxQuantity.description',
    type: 'Number',
    default: 10,
    validators: [
      {
        name: 'Max',
        value: MENU_FEATURE_MAX_QUANTITY,
      },
      {
        name: 'Min',
        value: 1,
      },
    ],
  },
  minQuantity: {
    friendlyName: 'Minimum Quantity',
    description: 'web.content.features.menuFeatureItem.minQuantity.description',
    type: 'Number',
    default: 1,
    validators: [
      {
        name: 'Max',
        value: MENU_FEATURE_MAX_QUANTITY,
      },
      {
        name: 'Min',
        value: 1,
      },
    ],
  },
  preparationMinutes: {
    friendlyName: 'Preparation time (minutes)',
    description:
      'web.content.features.menuFeatureItem.preparationMinutes.description',
    type: 'Number',
    default: MENU_FEATURE_DEFAULT_PREPARATION_TIME,
    validators: [
      {
        name: 'Max',
        value: MENU_FEATURE_MAX_PREPARATION_TIME,
      },
      {
        name: 'Min',
        value: 1,
      },
    ],
  },
  image: {
    friendlyName: 'Hero Image',
    description: 'web.content.features.menuFeatureItem.image.description',
    type: 'Image',
    validators: [],
  },
  options: {
    friendlyName: 'Menu Item Options',
    description: 'web.content.features.menuFeatureItem.options.description',
    type: 'MenuFeatureItemOptionList',
    isArray: true,
    validators: [
      {
        name: 'ArrayMax',
        value: MENU_FEATURE_MAX_OPTION_LISTS,
      },
    ],
  },
};

export class MenuFeatureItemType extends ObjectTypeBase {
  name = 'MenuFeatureItem';

  friendlyName = 'Menu Item';

  contexts = AllTypeContexts;

  get example() {
    return {
      _id: uuid(),
      name: 'Lane Latte',
      description: 'A 12oz freshly brewed latte.',
      tags: [],
      price: 650,
      maxQuantity: 10,
      minQuantity: 1,
      preparationMinutes: 10,
      options: [],
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      name: '',
      description: '',
      tags: [],
      price: 0,
      maxQuantity: 10,
      minQuantity: 1,
      preparationMinutes: 10,
      options: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new MenuFeatureItemType();

Types.addType(definition);
export default definition;
