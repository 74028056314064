import { useMemo } from 'react';

import isShopifyBurstInteraction from 'lane-shared/helpers/integrations/ShopifyBurst/isShopifyBurstInteraction';

import { LaneType } from 'common-types';
import getTimeZoneByGeoLocation from '../helpers/getTimeZoneByGeoLocation';
import { GeoCoordinateType } from '../types/baseTypes/GeoTypes';

type InteractionType = {
  _id: LaneType.UUID;
  contentData: {
    geo: GeoCoordinateType;
  };
  data?: {
    _ShopifyBurst: any;
  };
};

export function getTimeZoneFromInteraction(
  interaction: InteractionType | undefined | null
) {
  if (isShopifyBurstInteraction(interaction)) {
    return interaction?.data?._ShopifyBurst?.portTimeZone;
  }

  if (interaction?.contentData?.geo) {
    return getTimeZoneByGeoLocation({
      latitude: interaction.contentData.geo[1],
      longitude: interaction.contentData.geo[0],
    });
  }
  return null;
}

export default function useInteractionTimeZone(
  interaction: InteractionType | undefined | null
): string | undefined {
  return useMemo(() => getTimeZoneFromInteraction(interaction), [
    interaction?._id,
  ]);
}
