import gql from 'graphql-tag';

const getSsoLink = gql`
  query channel($channelId: UUID!) {
    biltSsoLink(channelId: $channelId) {
      ssoLink
    }
  }
`;

export default getSsoLink;
