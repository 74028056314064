import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { config } from 'lane-shared/config';

import { CardForm } from './CardForm';
import { PaymentSource } from 'graphql-query-contracts';

const stripePromise = loadStripe(config.stripe.publishableKey ?? '');

type AddStripeCardProps = {
  handleSubmit: () => void;
  cards: PaymentSource[];
  updateCards: (cards: PaymentSource[]) => void;
  savePaymentMethod: (paymentMethodId: string) => Promise<void>;
  updatePaymentMethod: (id: string) => void;
  goBack: () => void;
  isWallet?: boolean;
};

export function AddStripeCard({
  handleSubmit,
  cards,
  updateCards,
  savePaymentMethod,
  updatePaymentMethod,
  goBack,
  isWallet,
}: AddStripeCardProps) {
  return (
    <Elements stripe={stripePromise}>
      <CardForm
        handleParentSubmit={handleSubmit}
        goBack={goBack}
        cards={cards}
        updateCards={updateCards}
        updatePaymentMethod={updatePaymentMethod}
        savePaymentMethod={savePaymentMethod}
        isWallet={isWallet}
      />
    </Elements>
  );
}
