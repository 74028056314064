import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';

const properties: PropertiesInterface = {
  category: {
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 2,
      },
      {
        name: 'Max',
        value: 64,
      },
    ],
  },
  types: {
    type: 'String',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class ServiceRequestCategoryType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ServiceRequestCategory';

  friendlyName = 'Service Request Category';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      category: 'Building/Furnishings',
      types: ['Furniture Relocation'],
    };
  }

  get default(): any {
    return {
      category: '',
      types: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new ServiceRequestCategoryType();

Types.addType(definition);
export default definition;
