import React from 'react';

import { ORDER_STATUS_COLOR } from 'lane-shared/helpers/constants/integrations/BuildingEnginesClassic';

import styles from './StatusIndicator.scss';

export default function StatusIndicator({ status }: any) {
  return (
    <div
      className={styles.statusIndicator}
      style={{ backgroundColor: ORDER_STATUS_COLOR[status] }}
    />
  );
}
