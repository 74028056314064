import urlJoin from 'url-join';

import { LaneFeatureType } from 'common-types';
import routes from '../../config/routes';
import { WhiteLabelType } from '../../types/WhiteLabelType';
import { IcsPersonType } from '../generateIcsData';
import getPublicUsers from '../user/getPublicUsers';

export default async function mapGuestInvitesToIcsAttendees(
  guestInviteData: LaneFeatureType.GuestInviteInteraction,
  whitelabel: WhiteLabelType
): Promise<IcsPersonType[]> {
  if (!guestInviteData?.guests?.length) {
    return [];
  }

  let userAttendees: IcsPersonType[] = [];

  const userIds = guestInviteData.guests
    .filter(guest => Boolean(guest.user))
    .map(guest => guest.user!._id);

  if (userIds.length > 0) {
    const userProfiles = await getPublicUsers(userIds);
    userAttendees = userProfiles.map<IcsPersonType>(user => ({
      name: user.profile.name,
      email: user.profile.email,
      dir: urlJoin(whitelabel.url, routes.user.replace(':id', user._id)),
    }));
  }

  const otherAttendees = guestInviteData.guests
    .filter(guest => Boolean(guest.email))
    .map<IcsPersonType>(guest => ({ name: guest.name, email: guest.email }));

  return [...userAttendees, ...otherAttendees];
}
