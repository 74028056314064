import { Group } from '../models/Group';
import { createGroupModel } from './createGroupModel';
import { redirectToEditTeamPage } from './redirectToEditTeamPage';
import {
  CreateGroupFailed,
  sendCreateGroupRoleMutation,
} from './sendCreateGroupRoleMutation';
import { validateGroupModel, ValidationFailed } from './validateGroupModel';

type CreateTeamFailed = ValidationFailed | CreateGroupFailed;
type CreateTeamResult = { key: 'success' } | CreateTeamFailed;

export default async function createGroupFlow(
  groupRole: Group,
  channelSlug: string,
  history: object,
  redirectTo?: string
): Promise<CreateTeamResult> {
  const groupDTO = createGroupModel(groupRole);
  const validationResult = await validateGroupModel(groupDTO);
  if (validationResult.key === 'validationFailed') {
    return validationResult;
  }
  const createdGroupIdResult = await sendCreateGroupRoleMutation(groupDTO);
  if (createdGroupIdResult.key === 'createGroupFailed') {
    return createdGroupIdResult;
  }

  redirectToEditTeamPage(
    history,
    channelSlug,
    createdGroupIdResult.id,
    redirectTo
  );
  return { key: 'success' };
}
