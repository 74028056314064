import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getPaymentDetailsWithQuote } from 'lane-shared/helpers';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { PaymentDetailsItemType } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import { ContentType } from 'lane-shared/types/content/Content';
import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';

import QuantityInput from 'lane-web/src/components/form/QuantityInput';

import StripeChargeDetails from 'components/payments/StripeChargeDetails';
import { H4, H5, M, S } from 'design-system-web';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import PaymentPreview from './Payment/PaymentPreview';
import useUserLocale from 'hooks/useUserLocale';
import { useFlag } from 'lane-shared/hooks';
import styles from './PaymentFeatureQuote.scss';

type PaymentFeatureQuoteProps = {
  className?: string;
  style?: React.CSSProperties;
  quote: FeatureQuoteType | PaymentFeatureQuoteType | null;
  stripeData?: PaymentFeatureStripeDataType;
  content?: ContentType;
  onUpdateItem?: (props: any) => void;
  setInitialQuote?: (props: any) => void;
  forReceipt?: boolean;
};

export default function PaymentFeatureQuote({
  className,
  style,
  quote,
  stripeData,
  content,
  onUpdateItem = () => {},
  setInitialQuote = () => {},
  forReceipt = false,
}: PaymentFeatureQuoteProps) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const ProductsInContent = useFlag(FeatureFlag.ProductsInContent, false);

  if (!quote) {
    return null;
  }

  const withProducts =
    ProductsInContent && quote.products && quote.products.length > 0;

  const paymentDetails = getPaymentDetailsWithQuote(quote, locale);

  const percentageFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  const { quantityFeature } = explodeFeatures(content?.features);
  const shouldRenderQuantityAsReadOnly =
    forReceipt || !quantityFeature || quote.maxQuantity === 1;

  // overridden amount could be 0, so check for null or undefined.
  const isOverridden = quote.overriddenAmount != null;

  const filteredPaymentDetails = Object.values(
    paymentDetails.items.reduce<Record<string, PaymentDetailsItemType>>(
      (filteredData, currLineItem) => {
        if (!filteredData[currLineItem.name]) {
          filteredData[currLineItem.name] = currLineItem;
        } else if (currLineItem.quantity) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          filteredData[currLineItem.name].quantity += currLineItem.quantity;
        } else {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ++filteredData[currLineItem.name].quantity;
        }
        return filteredData;
      },
      {}
    )
  );

  function updateItem({ quantity }: any) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (quantity > quote.items.length) {
      // If we add to the quantity
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const items = quote.items;
      const item = items[0];
      while (quantity > items.length) {
        items.push(item);
        quantity -= 1;
      }
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.items = items;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.total = quote?.items[0]?.amount * items?.length || 0;
      setInitialQuote({
        ...quote,
        items,
      });
      // Updates the item quantities
      onUpdateItem(items.length);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    } else if (quantity < quote.items.length) {
      // If we remove from the quantity
      const items = quote?.items?.slice(0, quantity) || [];
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.items = items;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.total = quote?.items[0]?.amount * items?.length || 0;
      setInitialQuote({
        ...quote,
        items,
      });
      // Updates the item quantities
      onUpdateItem(items.length);
    }
  }

  return (
    <div className={cx(styles.PaymentFeatureQuote, className)} style={style}>
      <H4 mb={2}>{t('abp.payment.orderSummary')}</H4>

      <PaymentPreview content={content} />
      {withProducts ? (
        <div className={styles.lineItem}>
          <M>{filteredPaymentDetails[0].name}</M>
          <M>{paymentDetails.cashTotal}</M>
        </div>
      ) : (
        <>
          {filteredPaymentDetails.map(lineItem => (
            <div key={lineItem.key}>
              <div className={styles.lineItem}>
                <M>{lineItem.name}</M>
                <M>{lineItem.amount}</M>
              </div>
              {shouldRenderQuantityAsReadOnly ? (
                <S data-cy="itemQuantity" className={styles.quantity}>
                  Qty: {lineItem.quantity}
                </S>
              ) : (
                <QuantityInput
                  className={styles.quantityInput}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                  quantity={lineItem.quantity}
                  min={quote.minQuantity || 0}
                  max={quote.maxQuantity ?? undefined}
                  onChange={quantity => updateItem({ quantity })}
                />
              )}
            </div>
          ))}
        </>
      )}

      <div className={styles.divider} />

      {!withProducts && paymentDetails.taxes.length > 0 && (
        <div className={styles.taxesContainer}>
          {paymentDetails.subTotal && (
            <div className={styles.taxItem}>
              <M>{t('Sub total')}</M>
              <M>{paymentDetails.subTotal}</M>
            </div>
          )}
          {paymentDetails.taxes.map(taxItem => (
            <div className={styles.taxItem} key={taxItem.key}>
              <M>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                {taxItem.name} {percentageFormat(taxItem.rate)}%
              </M>
              <M>{taxItem.amount}</M>
            </div>
          ))}
        </div>
      )}

      {isOverridden ? (
        <>
          <div className={styles.total}>
            <M>{t('Original Total')}</M>
            <M>{paymentDetails.overriddenTotal}</M>
          </div>
          <div className={styles.total}>
            <M>{t('Total')}</M>
            <M>{paymentDetails.total}</M>
          </div>
        </>
      ) : (
        <div className={styles.total}>
          <H5>{t('Total')}</H5>
          <H5>
            {withProducts ? paymentDetails.cashTotal : paymentDetails.total}
          </H5>
        </div>
      )}

      {stripeData && <StripeChargeDetails stripeData={stripeData} />}
    </div>
  );
}
