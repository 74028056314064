import * as yup from 'yup';

import slugify from '../../helpers/formatters/slugify';
import validateAddress from '../validateCreateAddress';
import validateProfile from '../validateCreateProfile';

export default yup.object().shape({
  name: yup.string().label('Name').trim().required().min(2).max(255),
  slug: yup
    .string()
    .trim()
    .label('Unique link')
    .required()
    .min(6)
    .max(1024)
    .test(
      'is-slug',
      '${label} cannot contain spaces or special characters',
      value => value === slugify(value)
    ),
  description: yup
    .string()
    .trim()
    .max(2 ** 16)
    .nullable(),
  stats: yup
    .object()
    .shape({
      __typename: yup.string().strip(true),
      sf: yup.number().min(0).max(250000000),
      subscribers: yup.number().min(0).max(250000000),
    })
    .nullable(),
  tags: yup.array().of(yup.string()).max(12).nullable(),
  profile: validateProfile,
  address: validateAddress,
});
