import i18next from 'i18next';

import { SurveyTypeEnum } from 'lane-shared/types/features/SurveysFeatureProperties';

import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';

const satisfiedTranslationPrefix =
  'web.admin.content.features.surveys.satisfiedDropdown.';
const SATISFIED_DROPDOWNS = [
  i18next.t(`${satisfiedTranslationPrefix}delighted`),
  i18next.t(`${satisfiedTranslationPrefix}pleased`),
  i18next.t(`${satisfiedTranslationPrefix}mostlySatisfied`),
  i18next.t(`${satisfiedTranslationPrefix}mixedFeelings`),
  i18next.t(`${satisfiedTranslationPrefix}mostDissatisfied`),
  i18next.t(`${satisfiedTranslationPrefix}unhappy`),
  i18next.t(`${satisfiedTranslationPrefix}terrible`),
];

const wellTranslationPrefix =
  'web.admin.content.features.surveys.wellDropdown.';
const WELL_DROPDOWNS = [
  i18next.t(`${wellTranslationPrefix}extremelyWell`),
  i18next.t(`${wellTranslationPrefix}veryWell`),
  i18next.t(`${wellTranslationPrefix}well`),
  i18next.t(`${wellTranslationPrefix}ok`),
  i18next.t(`${wellTranslationPrefix}poorly`),
  i18next.t(`${wellTranslationPrefix}veryPoorly`),
  i18next.t(`${wellTranslationPrefix}extremelyPoorly`),
];

const optimisticTranslationPrefix =
  'web.admin.content.features.surveys.optimisticDropdown.';
const OPTIMISTIC_DROPDOWNS = [
  i18next.t(`${optimisticTranslationPrefix}optimistic`),
  i18next.t(`${optimisticTranslationPrefix}positive`),
  i18next.t(`${optimisticTranslationPrefix}steady`),
  i18next.t(`${optimisticTranslationPrefix}soSo`),
  i18next.t(`${optimisticTranslationPrefix}pessimistic`),
];

export type QuestionsType = {
  key: string;
  dropdowns?: string[];
  type: PackagedTypeEnum | string;
};

export const TENANT_ADMIN_SATISFACTION_QUESTIONS: QuestionsType[] = [
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q2'
    ),
    dropdowns: [
      ...SATISFIED_DROPDOWNS,
      i18next.t('web.admin.content.features.surveys.dropdown.notApplicable'),
    ],
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q3'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q4'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q5'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q6'
    ),
    dropdowns: WELL_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q7'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q8'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q9'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q1'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q10'
    ),
    dropdowns: OPTIMISTIC_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q11'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: 'LongText',
  },
];

const emptyParagraph = {
  type: 'paragraph',
  children: [
    {
      text: '',
    },
  ],
};

const getRichTextObject = (text: string, type = 'paragraph') => {
  return {
    type,
    children: [
      {
        text,
      },
    ],
  };
};

const getHeaderObject = (order: number, header: string) => {
  return {
    type: 'Header',
    order,
    properties: {
      size: 'Medium',
      header,
      textAlign: 'left',
    },
  };
};

export const TENANT_ADMIN_SATISFACTION_BLOCKS = [
  {
    type: 'Hero',
    order: 0,
    properties: {
      size: 'Medium',
      // Office Building.jpg
      images: ['4q2fxqxzGUWfrbqOgSSjOt'],
      textColor: '#FFFFFF',
      useGradient: true,
      backgroundColor: '#000',
    },
  },
  {
    type: 'RichText',
    order: 1,
    properties: {
      text: [
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.title'
          ),
          'heading-two'
        ),
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.p1'
          )
        ),
        emptyParagraph,
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.p2'
          )
        ),
        emptyParagraph,
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.p3'
          )
        ),
      ],
    },
  },
  getHeaderObject(
    2,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h1'
    )
  ),
  getHeaderObject(
    6,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h2'
    )
  ),
  getHeaderObject(
    9,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h3'
    )
  ),
  getHeaderObject(
    13,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h4'
    )
  ),
];

export const SURVEY_TYPE_TO_CONTENT_MAPPING: {
  [key in SurveyTypeEnum]: {
    questions: QuestionsType[];
    blocks: any[];
  };
} = {
  [SurveyTypeEnum.TenantAdminSatisfaction]: {
    questions: TENANT_ADMIN_SATISFACTION_QUESTIONS,
    blocks: TENANT_ADMIN_SATISFACTION_BLOCKS,
  },
};
