export enum routes {
  UsersList = 'user-management/users',
  UsersInvite = 'user-management/users/invite',
  UsersInviteWithGroupRoleId = 'user-management/users/invite/:groupRoleId',
  PermissionGroupsList = 'user-management/permission-groups',
  PermissionGroupView = 'user-management/permission-groups/:groupRoleId/view',
  PermissionGroupEdit = 'user-management/permission-groups/:groupRoleId/edit',
  PermissionGroupCreate = 'user-management/permission-groups/new',
  TeamsList = 'user-management/teams',
  TeamView = 'user-management/teams/:groupRoleId/view',
  TeamCreate = 'user-management/teams/new',
  TeamEdit = 'user-management/teams/:groupRoleId/edit',
}
