import React, { useContext, useMemo, useEffect, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { AppContext, UserDataContext } from 'lane-shared/contexts';
import EmailContentNotification from 'lane-shared/emails/EmailContentNotification';
import EmailUserContentInteractionReceipt from 'lane-shared/emails/EmailUserContentInteractionReceipt';
import EmailUserContentInteractionStatusChange from 'lane-shared/emails/EmailUserContentInteractionStatusChange';
import {
  EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  EVENT_CONTENT_INTERACTION_UPDATED,
  EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  EVENT_CONTENT_INTERACTION_STARTED,
  EVENT_CONTENT_INTERACTION_ENDED,
  EVENT_CONTENT_STARTED,
  EVENT_CONTENT_ENDED,
  EVENT_CONTENT_ENDED_INTERACTION_DATE,
  EVENT_CONTENT_LIVE,
} from 'lane-shared/helpers/constants/events';
import constructInteraction from 'lane-shared/helpers/content/constructInteraction';
import {
  ContentWorkflowPayloadType,
  ContentWorkflowType,
} from 'lane-shared/types/ContentWorkflowType';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { WorkflowTargetEnum } from 'lane-shared/types/Workflows';
import { ChannelDataType } from 'lane-shared/types/server/WorkflowTypes';

import Toggle from 'lane-web/src/components/form/Toggle/index';

import RichText from '../form/RichText';

import styles from './EmailBuilder.scss';

import DOMPurify from 'dompurify';
import mjml2html from 'mjml-browser';
import { renderToMjml } from '@faire/mjml-react/utils/renderToMjml';

type Props = {
  className: string;
  style: React.CSSProperties;
  workflow: ContentWorkflowType;
  channel: ChannelDataType;
  content: DocumentType;
  onEmailUpdated: (email: ContentWorkflowPayloadType) => void;
  forAdmin: boolean;
  timeZone: string;
};

export default function EmailBuilder({
  className,
  style,
  workflow,
  channel,
  content,
  onEmailUpdated,
  timeZone,
}: Props) {
  const { t, i18n } = useTranslation();

  // emails require a whitelabel to render
  const appContext = useContext(AppContext);
  // use the currently logged in user as the example user
  const { user } = useContext(UserDataContext);
  const [subject, setSubject] = useState<string | undefined>(undefined);
  const intro = workflow.payload?.intro;
  const outtro = workflow.payload?.outtro;
  const body = workflow.payload?.body;
  const qrcode = workflow.payload?.qrCodeCheckIn;
  const showContentName = workflow.payload?.showContentName;
  const showDateTime = workflow.payload?.showDateTime;

  useEffect(() => {
    setSubject(workflow.payload?.text);
  }, [workflow.payload?.text]);

  const whitelabel = useMemo(
    () => ({ ...appContext.whitelabel, baseUrl: appContext.whitelabel.url }),
    []
  );

  const forAdmin = ![
    WorkflowTargetEnum.InteractionCreator,
    WorkflowTargetEnum.GuestInviteFeature,
  ].includes(workflow.target);

  // create a fake interaction to show an example email
  const interaction = useMemo(() => {
    if (content?._id) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DocumentType' is not assignable ... Remove this comment to see the full error message
      const interaction = constructInteraction(content);

      return {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _id: user._id,
        _created: new Date(),
        _updated: new Date(),
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _createdBy: user._id,
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _updatedBy: user._id,
        status: 'Created',
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        userId: user._id,
        authorizedUserIds: [],
        channelId: channel._id,
        contentData: content,
        ...interaction,
      };
    }

    return undefined;
  }, [content?._id]);

  if (interaction) {
    interaction.contentData = content;
  }

  function updateEmail(key: any, value: any) {
    onEmailUpdated({
      ...workflow.payload,
      [key]: value,
    });
  }

  function renderEmail() {
    switch (workflow.event) {
      case EVENT_CONTENT_LIVE:
      case EVENT_CONTENT_STARTED:
      case EVENT_CONTENT_ENDED:
      case EVENT_CONTENT_ENDED_INTERACTION_DATE:
        return (
          <EmailContentNotification
            whiteLabel={whitelabel}
            i18n={i18n}
            // @ts-expect-error ts-migrate(2740) FIXME: Type 'DocumentType' is missing the following prope... Remove this comment to see the full error message
            content={content}
            channel={channel}
            timeZone={timeZone}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            title={subject}
            // @ts-expect-error ts-migrate(2739) FIXME: Type 'ContentWorkflowType' is missing the followin... Remove this comment to see the full error message
            workflow={workflow}
            EditableAreaBodyComponent={
              body && (
                <RichText
                  className={styles.richText}
                  value={body}
                  onChange={(body: any) => updateEmail('body', body)}
                />
              )
            }
            EditableAreaTopComponent={
              intro && (
                <RichText
                  className={styles.richText}
                  value={intro}
                  onChange={(intro: any) => updateEmail('intro', intro)}
                />
              )
            }
            EditableAreaBottomComponent={
              outtro && (
                <RichText
                  className={styles.richText}
                  value={outtro}
                  onChange={(outtro: any) => updateEmail('outtro', outtro)}
                />
              )
            }
          />
        );
      case EVENT_CONTENT_INTERACTION_STARTED:
      case EVENT_CONTENT_INTERACTION_ENDED:
      case EVENT_CONTENT_INTERACTION_CREATED:
      case EVENT_CONTENT_INTERACTION_UPDATED:
        return (
          <EmailUserContentInteractionReceipt
            whiteLabel={whitelabel}
            i18n={i18n}
            interaction={interaction as any}
            workflow={workflow as any}
            channel={channel}
            profile={(channel as any).profile}
            user={user as any}
            interactionUser={user as any}
            timeZone={timeZone}
            title={(subject || content.name) ?? ''}
            forAdmin={forAdmin}
            unsubscribe={user?._id}
            EditableAreaBodyComponent={
              body && (
                <RichText
                  className={styles.richText}
                  value={body}
                  onChange={(body: any) => updateEmail('body', body)}
                />
              )
            }
            EditableAreaTopComponent={
              intro && (
                <RichText
                  className={styles.richText}
                  value={intro}
                  onChange={(intro: any) => updateEmail('intro', intro)}
                />
              )
            }
            EditableAreaBottomComponent={
              outtro && (
                <RichText
                  className={styles.richText}
                  value={outtro}
                  onChange={(outtro: any) => updateEmail('outtro', outtro)}
                />
              )
            }
          />
        );
      case EVENT_CONTENT_INTERACTION_STATUSCHANGE:
      case EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED:
        return (
          <EmailUserContentInteractionStatusChange
            whiteLabel={whitelabel}
            i18n={i18n}
            workflow={workflow as any}
            interaction={interaction as any}
            channel={channel}
            profile={(channel as any).profile}
            user={user as any}
            interactionUser={user as any}
            oldStatus={workflow.workflow?.statusFrom ?? ''}
            timeZone={timeZone}
            title={content.name ?? ''}
            forAdmin={forAdmin}
            unsubscribe={user?._id}
            EditableAreaBodyComponent={
              body && (
                <RichText
                  className={styles.richText}
                  value={body}
                  onChange={(body: any) => updateEmail('body', body)}
                />
              )
            }
            EditableAreaTopComponent={
              intro && (
                <RichText
                  className={styles.richText}
                  value={intro}
                  onChange={(intro: any) => updateEmail('intro', intro)}
                />
              )
            }
            EditableAreaBottomComponent={
              outtro && (
                <RichText
                  className={styles.richText}
                  value={outtro}
                  onChange={(outtro: any) => updateEmail('outtro', outtro)}
                />
              )
            }
          />
        );

      default:
        return null;
    }
  }

  const contentEvent =
    workflow.event !== EVENT_CONTENT_STARTED &&
    workflow.event !== EVENT_CONTENT_ENDED &&
    workflow.event !== EVENT_CONTENT_ENDED_INTERACTION_DATE &&
    workflow.event !== EVENT_CONTENT_LIVE;

  const previewMjml = renderToMjml(<>{renderEmail()}</>);

  const previewHtml = mjml2html(previewMjml, { validationLevel: 'soft' });

  const sanitizedHtml = DOMPurify.sanitize(previewHtml.html);

  return (
    <>
      <div className={cx(styles.EmailBuilder, className)} style={style}>
        <div className={cx(styles.toggles, className)} style={style}>
          <div className={cx(styles.toggleColumn, className)} style={style}>
            <Toggle
              dataCy="customIntroTextToggle"
              value={Boolean(intro)}
              style={style}
              text={t(
                'web.admin.channel.content.emailBuilder.toggle.customIntroText'
              )}
              doTranslate
              onChange={() =>
                updateEmail('intro', !intro ? RichText.defaultValue : null)
              }
            />
            {contentEvent && (
              <Toggle
                dataCy="showQrToggle"
                disabled={!contentEvent}
                value={Boolean(qrcode)}
                text={t('web.admin.channel.content.emailBuilder.toggle.showQr')}
                doTranslate
                onChange={() => updateEmail('qrCodeCheckIn', !qrcode)}
              />
            )}
          </div>
          <div className={cx(styles.toggleColumn, className)} style={style}>
            <Toggle
              dataCy="customBodyTextToggle"
              value={Boolean(body)}
              text={t(
                'web.admin.channel.content.emailBuilder.toggle.customBodyText'
              )}
              doTranslate
              onChange={() =>
                updateEmail('body', !body ? RichText.defaultValue : null)
              }
            />
            {contentEvent && (
              <Toggle
                dataCy="showContentNameToggle"
                value={Boolean(showContentName)}
                text={t(
                  'web.admin.channel.content.emailBuilder.toggle.showContentName'
                )}
                doTranslate
                onChange={() =>
                  updateEmail('showContentName', !showContentName)
                }
              />
            )}
          </div>
          <div className={cx(styles.toggleColumn, className)} style={style}>
            <Toggle
              dataCy="customOutroTextToggle"
              value={Boolean(outtro)}
              text={t(
                'web.admin.channel.content.emailBuilder.toggle.customOutroText'
              )}
              doTranslate
              onChange={() =>
                updateEmail('outtro', !outtro ? RichText.defaultValue : null)
              }
            />
            {contentEvent && (
              <Toggle
                dataCy="showDateTimeToggle"
                value={Boolean(showDateTime)}
                text={t(
                  'web.admin.channel.content.emailBuilder.toggle.showDateTime'
                )}
                doTranslate
                onChange={() => updateEmail('showDateTime', !showDateTime)}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={cx(styles.wrapper, className)}
        style={style}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    </>
  );
}
