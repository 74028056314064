import { v4 as uuid } from 'uuid';

import { InvalidFeatureStateError } from 'activate-errors';
import {
  WeeklyAvailability,
  TimeAvailabilityDayRule,
} from '../../types/features/TimeAvailabilityFeatureProperties';
import { SHORT_TIME } from '../constants/dates';
import { DAY_KEYS_BY_NUMBER, DAYS_OF_THE_WEEK } from '../constants/timeUnits';
import parseDateTime from '../dates/parseDateTime';
import getDateTimesFromTimeRange from './getDateTimesFromTimeRange';

const defaultDayRule: TimeAvailabilityDayRule = {
  _id: uuid(),
  isOpen: false,
  timeRanges: [],
  isAvailableAllDay: false,
};

export default function isAvailabilityValid(
  availability: WeeklyAvailability,
  timeZone: string,
  contentName: string,
  startDate: Date = new Date(),
  endDate: Date = new Date()
): boolean {
  const { weekTimeRules } = availability;
  const interactionStartDate = parseDateTime(startDate, timeZone)!;
  const interactionEndDate = parseDateTime(endDate, timeZone);
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const dayKey = DAY_KEYS_BY_NUMBER[interactionStartDate.weekday];

  if (availability.isAvailableAnytime) {
    return true;
  }

  const dayAvailability: TimeAvailabilityDayRule =
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    weekTimeRules[dayKey] || defaultDayRule;

  // throw error if not open on this day
  if (!dayAvailability.isOpen) {
    throw new InvalidFeatureStateError(
      `“${contentName}” is not available on ${DAYS_OF_THE_WEEK[dayKey].name}.`
    );
  }

  if (dayAvailability?.isAvailableAllDay) {
    return true;
  }

  // Looping thru time ranges
  let isValid = false;

  dayAvailability.timeRanges.forEach(timeRange => {
    const { start, end } = getDateTimesFromTimeRange({
      format: SHORT_TIME,
      referenceDate: interactionStartDate,
      includeEndingMillis: true,
      timeZone,
      timeRange,
    });

    // Check if time is within range
    if (
      start <= interactionStartDate &&
      interactionEndDate &&
      end >= interactionEndDate
    ) {
      isValid = true;
    }
  });

  if (!isValid) {
    throw new InvalidFeatureStateError(
      `“${contentName}” is not available at this time.`
    );
  }

  // We found a valid time range
  return true;
}
