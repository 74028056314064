import { mapKeys } from 'lodash';

export function getErrorMessage(listData: any, msg: string) {
  if (Array.isArray(listData)) {
    for (const item of listData) {
      msg = getErrorMessage(item, msg);
    }
  } else if (typeof listData === 'object' && listData !== null) {
    for (const key in listData) {
      if ('on_new_serdy_form' in listData) {
        mapKeys(listData.on_new_serdy_form, (k, val) => {
          msg = `${msg + k}: ${val}\n`;
        });
        delete listData.on_new_serdy_form;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      msg = getErrorMessage(listData[key], msg);
    }
  } else if (typeof listData === 'string') {
    msg = `${msg + listData}\n`;
  }
  return msg;
}
