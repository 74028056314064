import React from 'react';

import cx from 'classnames';

import { useMenuFeatureForOrder } from 'lane-shared/hooks';

import useUserLocale from '../../../hooks/useUserLocale';
import TabStrip from '../../general/TabStrip';
import MenuOrderItem from './MenuOrderItem';

import styles from './MenuOrder.scss';

function MenuOrder({
  disabled,
  loading,
  className,
  style,
  content,
  existingOrder,
  onOrderUpdated,
  theme,
}: any) {
  const locale = useUserLocale();

  const {
    menuFeature,
    items,
    currencyFormat,
    onItemAdded,
    selectedCategory,
    setSelectedCategory,
  } = useMenuFeatureForOrder({
    content,
    existingOrder,
    onOrderUpdated,
    locale,
  });

  return (
    <div className={cx(styles.MenuOrder, className)} style={style}>
      {menuFeature?.categories && (
        <TabStrip
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ theme: any; tabs: { value: string; label: ... Remove this comment to see the full error message
          theme={theme}
          tabs={menuFeature.categories.map(({ _id, name }) => ({
            value: _id,
            label: name,
          }))}
          selected={selectedCategory}
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TabItem' is not assignable to pa... Remove this comment to see the full error message
          onSelectTab={tab => setSelectedCategory(tab)}
        />
      )}
      {items.map((item: any) => (
        <MenuOrderItem
          key={item._id}
          disabled={disabled}
          loading={loading}
          item={item}
          currencyFormat={currencyFormat}
          onItemAdded={onItemAdded}
        />
      ))}
    </div>
  );
}

export default MenuOrder;
