import {
  SafetrustCredentialRangingEventType,
  SafetrustCredentialType,
  SafetrustOrganizationType,
} from 'lane-shared/helpers/integrations/Safetrust/SafetrustEnums';
import { SafetrustFeedbackEnum } from 'lane-shared/types/integrations/Safetrust';

import { AccessControlPermissionsHealthEnum } from './MobilePermissionEnum';
import {
  HIDCredentialType,
  HIDFeedbackEnum,
  HIDReaderType,
} from './integrations/HID';
import { IntegrationProviderEnum } from './integrations/IntegrationEnums';
import { KastleReaderType } from './integrations/Kastle';

/**
 * @deprecated Use AccessIntegrationEventsEnum instead, AccessControlEventsEnum is
 * used for legacy integrations under the legacy AccessControlProvider.
 * Events that an Access Control integration can fire through out it's
 * life cycle.  These are standardized across all providers of
 * access control.
 *
 * Specific access control implementations may still fire their own
 * events.
 */
export enum AccessControlEventsEnum {
  SDKInitialized = 'SDKInitialized',
  SDKError = 'SDKError',
  SDKTurnedOff = 'SDKTurnedOff',
  ScanningStarted = 'ScanningStarted',
  ScanningStopped = 'ScanningStopped',
  AccessModeSet = 'AccessModeSet',
  FeedbackModeSet = 'FeedbackModeSet',
  LeashingStarted = 'LeashingStarted',
  LeashingStopped = 'LeashingStopped',
  CredentialsLoaded = 'CredentialsLoaded',
  OrganizationsLoaded = 'OrganizationsLoaded',
  UserAuthenticated = 'UserAuthenticated',
  UserLoggedOut = 'UserLoggedOut',
  SessionStarted = 'SessionStarted',
  SessionEnded = 'SessionEnded',
  ReadersInRange = 'ReadersInRange',
  ReaderUnlocking = 'ReaderUnlocking',
  ReaderUnlocked = 'ReaderUnlocked',
  ReaderFailedToUnlock = 'ReaderFailedToUnlock',
}

export enum AccessIntegrationEventsEnum {
  AccessModeSet = 'AccessModeSet',
  CredentialsLoaded = 'CredentialsLoaded',
  FeedbackModeSet = 'FeedbackModeSet',
  OrganizationsLoaded = 'OrganizationsLoaded',
  ReaderAccessDenied = 'ReaderAccessDenied',
  ReaderAccessGranted = 'ReaderAccessGranted',
  ReaderBeginScanning = 'ReaderBeginScanning',
  ReaderConnecting = 'ReaderConnecting',
  ReaderEndScanning = 'ReaderEndScanning',
  ReaderReadingComplete = 'ReaderReadingComplete',
  ReaderUnknownReadingState = 'ReaderUnknownReadingState',
  ReadersInRange = 'ReadersInRange',
  SDKError = 'SDKError',
  SDKInitialized = 'SDKInitialized',
  SDKTurnedOff = 'SDKTurnedOff',
  SessionEnded = 'SessionEnded',
  SessionStarted = 'SessionStarted',
  SessionRegenerated = 'SessionRegenerated',
  UserAuthenticated = 'UserAuthenticated',
  UserAuthenticationFailed = 'UserAuthenticationFailed',
  UserLoggedOut = 'UserLoggedOut',
}

export type AccessIntegrationEventLogType = {
  ts: number;
  event: AccessIntegrationEventsEnum;
};

export type AccessControlEventLogType = {
  ts: number;
  event: AccessControlEventsEnum;
};

export type AccessControlProvidersEventLogsType = {
  [IntegrationProviderEnum.HID]: AccessControlEventLogType[];
  [IntegrationProviderEnum.Safetrust]: AccessControlEventLogType[];
  [IntegrationProviderEnum.Openpath]: AccessControlEventLogType[];
  [IntegrationProviderEnum.Kastle]: AccessControlEventLogType[];
  [IntegrationProviderEnum.Gallagher]: AccessControlEventLogType[];
};

export const AccessControlFeedbackModeNotAvailable = 'N/A';

export type SafetrustFeedbackIndex = 0 | 1 | 2;

export type AccessControlFeedbackModeInputType =
  | SafetrustFeedbackIndex
  | HIDFeedbackEnum;

export type AccessControlFeedbackModeType =
  | HIDFeedbackEnum
  | SafetrustFeedbackEnum
  | typeof AccessControlFeedbackModeNotAvailable;

// Legacy code for Openpath and Kastle doesn't react to the credentials state
export type AccessControlCredentialType =
  | HIDCredentialType[]
  | SafetrustCredentialType[]
  | null;

export function isHIDCredentialTypeArray(
  credential: AccessControlCredentialType
): credential is HIDCredentialType[] {
  return (
    Array.isArray(credential) &&
    (credential.length === 0 ||
      (credential[0] as HIDCredentialType).cardNumber !== undefined)
  );
}

export function isSafetrustCredentialTypeArray(
  credential: AccessControlCredentialType
): credential is SafetrustCredentialType[] {
  return (
    Array.isArray(credential) &&
    (credential.length === 0 ||
      (credential[0] as SafetrustCredentialType).userIdentifier !== undefined)
  );
}

export type AccessControlOrganizationsType =
  | any[]
  | SafetrustOrganizationType[];

export function isSafetrustOrganizationTypeArray(
  organizations: AccessControlOrganizationsType
): organizations is SafetrustOrganizationType[] {
  return (
    Array.isArray(organizations) &&
    (organizations.length === 0 ||
      (organizations[0] as SafetrustOrganizationType).id !== undefined)
  );
}

/**
 * Turning on an Access Control integration on a mobile phone requires a
 * "pre-flight" checklist.  Many things that need to be done before we
 * can even try to scan for.
 *
 * These are used as trinary values.  null means no action has been taken yet.
 * false means action, and it failed.  true means action it it was successful.
 */
export type AccessControlPreflightType = {
  // has the SDK been initialized with the required keys.
  // this is the absolute first step that must happen.
  isSDKInitialized: boolean | null;
  // has the SDK failed to initialize.
  hasSDKfailedToInitialize: boolean | null;
  // has the user asked for this access control type yet? if not we should
  // not be throwing up permission screens nagging the user if they don't
  // even want to use this access control yet.
  hasUserInitiated: boolean | null;
  // has the user enabled all the required permissions on their mobile for
  // this access control properly to function?
  hasAllRequiredMobilePermissions: boolean | null;
  // has the user successfully authenticated themselves? this does not mean
  // their session is ready yet.
  isUserAuthenticated: boolean | null;
  // does the user have a valid session, it may not be ready yet.
  hasActiveSession: boolean | null;
  // is the session loaded and ready to use.
  isSessionReady: boolean | null;
  // are Credentials revieved from provider
  hasReceivedCredentials: boolean | null;
};

export enum AccessControlAccessModeEnum {
  Foreground = 'Foreground',
  Background = 'Background',
}

/**
 * Used to indicate the current lock/reader status of an access control SDK.
 * This is only about the state of locking, unlocking, or a recent error doing
 * such.  For other status of the state of the SDK, check preflight, isScanning
 * isLeashing, etc.
 */
export enum AccessControlStatusEnum {
  // waiting for a lock/reader
  Waiting = 'Waiting',
  // currently opening a lock/reader
  Opening = 'Opening',
  // last lock/reader was successfully opened
  Opened = 'Opened',
  // last lock/reader failed to open
  Failed = 'Failed to open',
}
/**
 * Access Control SDKs require permissions enabled on the mobile device
 * to function. An SDK may have the status of 'Scanning' as it is attempting
 * to scan, but if all of these permissions are not satisfied, it will not
 * be able to do so.
 */
export type AccessControlPermissionsStatusType = {
  [AccessControlPermissionsHealthEnum.BluetoothGranted]: boolean | null;
  [AccessControlPermissionsHealthEnum.BluetoothOn]: boolean | null;
  [AccessControlPermissionsHealthEnum.DeviceSecured]: boolean | null;
  [AccessControlPermissionsHealthEnum.LocationAlwaysGranted]: boolean | null;
  [AccessControlPermissionsHealthEnum.LocationOn]: boolean | null;
  [AccessControlPermissionsHealthEnum.BluetoothGrantedAndroid12Plus]:
    | boolean
    | null;
};

/**
 * Legacy Access Control Provider to handle integrations
 * before new ACIM Initiative (TM-14692)
 */
export type ImplementedAccessControlProviderLegacy =
  | IntegrationProviderEnum.HID
  | IntegrationProviderEnum.Safetrust
  | IntegrationProviderEnum.Openpath
  | IntegrationProviderEnum.Kastle;

/**
 * An array of all Access Control Providers supported by the mobile app
 * before new ACIM Initiative (TM-14692)
 */
export const ImplementedAccessControlProviderLegacyValues: ImplementedAccessControlProviderLegacy[] = [
  IntegrationProviderEnum.HID,
  IntegrationProviderEnum.Safetrust,
  IntegrationProviderEnum.Openpath,
  IntegrationProviderEnum.Kastle,
];

/**
 * Access Control Providers supported by the mobile app
 */
export type ImplementedAccessControlProvider =
  | IntegrationProviderEnum.HID
  | IntegrationProviderEnum.Safetrust
  | IntegrationProviderEnum.Openpath
  | IntegrationProviderEnum.Kastle
  | IntegrationProviderEnum.Gallagher
  | IntegrationProviderEnum.SaltoSVN;

/**
 * An array of all Access Control Providers supported by the mobile app
 */
export const ImplementedAccessControlProviderValues: ImplementedAccessControlProvider[] = [
  IntegrationProviderEnum.HID,
  IntegrationProviderEnum.Safetrust,
  IntegrationProviderEnum.Openpath,
  IntegrationProviderEnum.Kastle,
  IntegrationProviderEnum.Gallagher,
  IntegrationProviderEnum.SaltoSVN,
];

/**
 * Represent a mapping of Access Control Integration to a specific type
 * @template T The type of the values in the mapping
 * @example AccessControlProviderLegacyMapping<string[]> creates a mapping of an empty string array for each legacy provider
 */
export type AccessControlProviderLegacyMapping<T> = {
  [k in ImplementedAccessControlProviderLegacy]: T;
};

/**
 * Represent a mapping of Access Control Integration to a specific type
 * @template T The type of the values in the mapping
 * @example AccessControlProviderMapping<string[]> creates a mapping of an empty string array for each provider
 */
export type AccessControlProviderMapping<T> = {
  [k in ImplementedAccessControlProvider]: T;
};

/**
 * Represent a mapping to a specific type without being Access Control Integration specific.
 * This is needed because of immutable states.
 * @template T The type of the values in the mapping
 * @example AccessControlMapping<string[]> creates a mapping of an empty string array
 */
export type AccessControlMapping<T> = {
  [k in 0]: T;
};

export enum AccessControlUserSyncStatusEnum {
  UNMAPPED = '',
  SYNCED = 'Synced',
  IN_PROGRESS = 'InProgress',
  FAILED = 'Failed',
}

export enum AccessControlChipSelectEnum {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  ENABLING = 'Enabling',
  PROCESSING = 'Processing',
  ERROR = 'Error',
}

export enum AccessControlUserSourceEnum {
  ACTIVATE = 'Activate',
  EXTERNAL = 'External',
  UNRECOGNIZED = 'Unrecognized',
}

export type AccessControlReadersType =
  | HIDReaderType[]
  | SafetrustCredentialRangingEventType[]
  | []
  | Map<string, KastleReaderType>;

export function isHIDReaderTypeArray(
  readers: AccessControlReadersType
): readers is HIDReaderType[] {
  return (
    Array.isArray(readers) &&
    (readers.length === 0 ||
      (readers[0] as HIDReaderType).supportedOpeningTypes !== undefined)
  );
}

export type AccessManagersMappingInfo = {
  userGroupRoleId: string;
  externalId: string;
  userId: string;
  groupRoleName: string;
};

export type FiltersListType = {
  label: string;
  value: string;
};

export type ChannelListType = {
  id: string;
  name: string;
};

export type AccessControlGroupsView = {
  id: string;
  name: string;
};

export type MultiIntegrationAcgs = {
  channelName: string;
  accessControlGroups: AccessControlGroupsView[];
};
