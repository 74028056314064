import React, { useRef } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useRippleEffect } from 'hooks';

import styles from './SelectableCardWrapper.scss';

export type Props = {
  children: React.ReactNode;
  isSelected?: boolean;
  onClick?: (value: boolean) => void;
  style?: React.CSSProperties;
  className?: string;
};

export default function SelectableCardWrapper({
  children,
  onClick,
  isSelected,
  style,
  className,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const rippleContainerRef = useRef<HTMLSpanElement | null>(null);

  useRippleEffect(containerRef, rippleContainerRef);

  return (
    <div
      aria-hidden="true"
      className={cx(styles.container, className)}
      data-is-clicked={isSelected}
      ref={containerRef}
      onClick={() => onClick && onClick(!isSelected)}
      style={style}
    >
      {children}
      <div className={styles.overlay}>
        {!isSelected ? (
          <div className={styles.check} />
        ) : (
          <Icon name="check-circle" set="FontAwesome" className={styles.icon} />
        )}
      </div>
      <span ref={rippleContainerRef} className={styles.RippleContainer} />
    </div>
  );
}
