import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';

export const buildCSVRows = (rows: any[], columns: any[]) => {
  const csvRows = [];

  const headers = columns?.map(({ header }: { header: string }) => header);
  csvRows.push(headers);

  rows.forEach(row => {
    const values = columns?.map(({ key, renderForCSV }) => {
      if (renderForCSV) {
        return renderForCSV(row[key], row);
      }
      return row[key];
    });
    csvRows.push(values);
  });
  return csvRows;
};

export const exportCSV = (rows: any[], columns: any[], filename: string) => {
  const csvRows = buildCSVRows(rows, columns);
  const csv = Papa.unparse(csvRows);

  makeFileDownload({
    name: `${filename}`,
    contents: csv,
    type: 'application/csv',
  });

  return csv;
};
