import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import ReservableFeatureRule from 'lane-shared/renderers/v5/features/types/ReservableFeatureRule';
import { ReservableFeatureRuleType } from 'lane-shared/types/features/ReservableFeatureProperties';

import Label from 'components/general/Label';

import ReservableFeatureRuleEdit from './ReservableFeatureRuleEdit';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  teamRules: 'web.content.features.reservable.ReservableTabTeamRules.teamRules',
  teamRulesDescription:
    'web.content.features.reservable.ReservableTabTeamRules.teamRules.description',
};

export default function ReservableTabTeamRules({
  settings,
  channelId,
  maxMaxSlots,
  onFeatureUpdated,
}: any) {
  const { t } = useTranslation();
  function addRule() {
    onFeatureUpdated({
      rules: [
        ...settings.rules,
        {
          ...ReservableFeatureRule.default,
          maxSlots: settings.mainRule.maxSlots,
        },
      ],
    });
  }

  function removeRule(id: any) {
    onFeatureUpdated({
      rules: settings.rules.filter(({ _id }: any) => id !== _id),
    });
  }

  function updateRule(update: Partial<ReservableFeatureRuleType>) {
    const ix = settings.rules.findIndex(
      ({ _id }: any) => _id === (update as any)._id
    );

    settings.rules[ix] = {
      ...settings.rules[ix],
      ...update,
    };

    onFeatureUpdated({
      rules: [...settings.rules],
    });
  }

  return (
    <>
      <Label h2>
        <span style={{ lineHeight: '1em' }}>
          {t(TRANSLATION_KEYS.teamRules)}
        </span>
        <Icon
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={addRule}
        />
      </Label>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; h5: true; }' is not ass... Remove this comment to see the full error message */}
      <Label h5>
        <span style={{ lineHeight: '1em' }}>
          {t(TRANSLATION_KEYS.teamRulesDescription)}
        </span>
      </Label>
      {settings.rules.map((rule: any) => (
        <ReservableFeatureRuleEdit
          key={rule._id}
          rule={rule}
          channelId={channelId}
          maxSlots={settings.mainRule.maxSlots}
          maxMaxSlots={maxMaxSlots}
          onRuleUpdated={updateRule}
          onRuleDeleted={removeRule}
        />
      ))}
    </>
  );
}
