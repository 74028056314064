import { Transforms } from 'slate';

export function countChars(editor: any) {
  const children = editor.children;
  // characters + linebreaks
  return (
    countChildChars(children) + (children.length > 0 ? children.length - 1 : 0)
  );
}

function countChildChars(children: any) {
  let count = 0;
  children.forEach((child: any) => {
    if (child.children) {
      count += countChildChars(child.children);
    } else {
      count += child.text.length;
    }
  });
  return count;
}

export function withCharLimit(editor: any, charLimit?: number) {
  const { insertData, insertText, insertBreak } = editor;

  editor.insertText = (text: any) => {
    if (!charLimit || (charLimit && countChars(editor) < charLimit)) {
      insertText(text);
    }
  };

  editor.insertBreak = () => {
    if (!charLimit || (charLimit && countChars(editor) < charLimit)) {
      insertBreak();
    }
  };

  editor.insertData = (data: any) => {
    if (charLimit) {
      const pasteData = data.getData('text');
      const totalChars = countChars(editor) + pasteData.length;
      if (totalChars < charLimit) {
        insertData(data);
      } else if (countChars(editor) < charLimit) {
        // since we consider linebreaks as characters
        const hasBreak = /\r|\n/.exec(pasteData);
        const over = totalChars - charLimit + (hasBreak ? -1 : 0);
        insertData(data);
        if (over > 0) {
          Transforms.delete(editor, {
            reverse: true,
            distance: over,
            unit: 'character',
          });
        }
      }
    } else {
      insertData(data);
    }
  };

  return editor;
}
