import { unset } from 'lodash';

import { getColumnNamesHavingL10nSuffix } from 'lane-shared/helpers/dynamicLanguages/getColumnNamesHavingL10nSuffix';

import { LocalizationColumnType } from '../../types/LocalizationColumnType';
import { BlockInstanceType } from '../../types/blocks/BlockInterface';
import { PropertiesInterface } from '../../types/properties/Property';

type ContentWithTranslationsType = {
  name_l10n: LocalizationColumnType;
  description_l10n: LocalizationColumnType;
  subtitle_l10n: LocalizationColumnType;
  block?: BlockInstanceType;
  data?: PropertiesInterface | null;
};

/**
 * This function removes translation fields from the (draft) content object.
 * It sets the `name_l10n`, `description_l10n`, and `subtitle_l10n` fields to empty objects.
 * If the content object has a `block` or `data` property, it also removes translation fields from those objects.
 *
 * @param {ContentWithTranslationsType} content - The (draft) content object from which to remove translation fields.
 */
export function removeTranslationsFromContent(
  content: ContentWithTranslationsType
) {
  if (!content) return;
  content.name_l10n = {};
  content.description_l10n = {};
  content.subtitle_l10n = {};
  if (content.block) {
    content.block = removeTranslationsFromObject(
      content.block
    ) as BlockInstanceType;
  }
  if (content.data) {
    content.data = removeTranslationsFromObject(
      content.data
    ) as PropertiesInterface;
  }
}

function removeTranslationsFromObject(
  object: BlockInstanceType | PropertiesInterface
) {
  const fieldsWithL10n = getColumnNamesHavingL10nSuffix(object);
  fieldsWithL10n?.forEach((fieldName: string) => {
    unset(object, `${fieldName}_l10n`);
  });
  return object;
}
