import React, { useState } from 'react';

import cx from 'classnames';

import { convertToUUID, convertTo62 } from 'lane-shared/helpers/convertId';

import styles from './ShowId.scss';

type Props = {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  dataCy?: string;
};

export default function ShowId({ id, className, style, dataCy }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={cx(styles.ShowId, className)}
      style={style}
      onDoubleClick={() => setIsExpanded(!isExpanded)}
      data-cy={dataCy}
    >
      {!isExpanded && (
        <p>
          <span>Id</span>
          <span>{id}</span>
        </p>
      )}

      {isExpanded && (
        <>
          <p>
            <span>Long Id: </span>
            <span>{convertToUUID(id)}</span>
          </p>
          <p>
            <span>Short Id: </span>
            <span>{convertTo62(id)}</span>
          </p>
        </>
      )}
    </div>
  );
}
