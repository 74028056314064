// Web Exclusive Behaviour
export function useWhitelabelByIndexHTML() {
  // eslint-disable-next-line no-undef
  if (typeof globalThis?.document === 'undefined') {
    return undefined;
  }

  const whitelabel = document
    .querySelector('meta[property="x:whitelabel"]')
    ?.getAttribute('content');

  if (whitelabel) {
    return JSON.parse(whitelabel);
  }

  return undefined;
}
