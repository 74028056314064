import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';

import { getClient } from '../apollo';
import { searchContentInteractions } from '../graphql/query';

export type ContentInteractionsQueryInteraction = Pick<
  UserContentInteractionType,
  | '_id'
  | '_created'
  | '_updated'
  | 'startDate'
  | 'endDate'
  | 'geo'
  | 'data'
  | 'contentData'
  | 'version'
  | 'status'
>;

/**
 * A useful hook for getting content interactions.
 *
 * We use content interactions in many places, ie. for lots of integration pages
 * @param search
 * @param pagination
 * @returns {[Array, {total: number, perPage: number, start: number}, boolean, unknown]}
 */
export default function useContentInteractionsQuery({
  search,
  pagination,
}: any) {
  const [pageInfo, setPageInfo] = useState({
    start: 0,
    perPage: 0,
    total: 0,
  });

  const [fetchContentInteractions, { data, loading, error }] = useLazyQuery(
    searchContentInteractions,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
    }
  );

  const [interactions, setInteractions] = useState<
    ContentInteractionsQueryInteraction[]
  >([]);

  useEffect(() => {
    if (!data?.me?.contentInteractions) {
      setInteractions([]);
      setPageInfo({
        start: 0,
        perPage: 0,
        total: 0,
      });
    } else {
      setInteractions(data.me.contentInteractions.items);
      setPageInfo(data.me.contentInteractions.pageInfo);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [search, pagination?.perPage, pagination?.page]);

  async function refetch() {
    fetchContentInteractions({
      variables: { search, pagination },
    });
  }

  return [interactions, pageInfo, loading, error, refetch];
}
