import { createContext } from 'react';

import { ChannelType } from 'lane-shared/types/ChannelType';

type ChannelAdminContextType = {
  loading: boolean;
  timeZone: string;
  specialPermissions: string[];
  channel: ChannelType | null;
  error: Error | null;
  called: boolean;
  refetchChannel: () => void;
};

const ChannelAdminContext = createContext({
  loading: false,
  timeZone: '',
  specialPermissions: [],
  channel: null,
  error: null,
  called: false,
  refetchChannel: () => null,
} as ChannelAdminContextType);

export default ChannelAdminContext;
