import {
  permissions,
  UNSORTED_PERMISSION_CATEGORIES,
  INTEGRATION_DISABLED_PERMISSIONS,
} from 'lane-shared/helpers/constants/permissions';

/**
 * Splits permissions and requirements into more manageable lists ordered by their
 * categories.
 */

type Permission = {
  key: string;
  description: string;
  disabled?: boolean;
};
type OrganizedPermission = {
  key: string;
  value: Permission[];
  disabledText?: string;
};
export function getOrganizedPermissions() {
  const organizedPermissions: {
    key: string;
    value: Permission[];
  }[] = [];
  const unsortedPermissionsByCategories: {
    [key: string]: Permission[];
  } = {};
  let re = new RegExp(`^(${UNSORTED_PERMISSION_CATEGORIES.join('|')})`);
  if (UNSORTED_PERMISSION_CATEGORIES.length === 0) {
    // If there are no unsorted categories, then we just sort by the first letter
    re = new RegExp(`.^`);
  }

  // Sorting list of permissions, leaving out the keys that are part of the UNSORTED_PERMISSION_CATEGORIES
  const sortedPermissions = permissions
    .filter(a => !a.key.match(re))
    .sort((a, b) => {
      return a.key.localeCompare(b.key);
    });
  sortedPermissions.push(...permissions.filter(a => a.key.match(re)));
  // Spltting up permissions into their categories
  sortedPermissions.forEach(a => {
    const type = a.key.split('.')[0];
    const prettyType: string = type!.replace('-', ' ');

    unsortedPermissionsByCategories[prettyType] =
      unsortedPermissionsByCategories[prettyType] || [];

    unsortedPermissionsByCategories[prettyType]!.push(a);
  });

  // Sorting the list of permission categories alphabetically
  const sortedPermissionsByCategories = Object.keys(
    unsortedPermissionsByCategories
  )
    .sort()
    .reduce((obj: { [key: string]: Permission[] }, key) => {
      obj[key] = unsortedPermissionsByCategories[key]!;
      return obj;
    }, {});

  Object.entries(sortedPermissionsByCategories).forEach(([key, value]) =>
    organizedPermissions.push({
      key,
      value,
    })
  );
  return organizedPermissions;
}

export function mapDisabledPermissions(
  permissions: OrganizedPermission[],
  category: string
) {
  const modulePermissions = permissions.find(
    (p: { key: string }) => p.key === category
  );
  const moduleCat = INTEGRATION_DISABLED_PERMISSIONS[category];

  if (!modulePermissions || !moduleCat?.permissions.length) {
    return;
  }

  let hasDisabledPermissions = false;

  for (const permission of modulePermissions.value) {
    permission.disabled = moduleCat.permissions.includes(permission.key);
    if (!hasDisabledPermissions && permission.disabled) {
      hasDisabledPermissions = true;
    }
  }

  if (hasDisabledPermissions) {
    modulePermissions.disabledText = moduleCat.text;
  }
}
