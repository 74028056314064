import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';
import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'lane-shared/types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';

const AngusDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.AngusServiceRequests,
  hasCustomUI: true,
  friendlyName: 'Angus: Service Requests API',
  description: `Defines the properties required for an Angus integration.`,
  category: IntegrationCategoryEnum.Maintenance,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'The base URL of the Angus API endpoint',
      type: 'Url',
      default: 'https://ng1.angusanywhere.com/wointegrationapi/',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    authUrl: {
      friendlyName: 'Auth Url',
      description: 'The URL of the authentication endpoint for the Angus API',
      type: 'Url',
      default: 'https://ng1.angusanywhere.com/authenticationapi/connect/token',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'Override the standard base URL for Angus?',
      type: 'Url',
    },
    authUrl: {
      friendlyName: 'Auth Url',
      description: 'Override the standard auth URL for Angus?',
      type: 'Url',
    },
    name: {
      friendlyName: 'Display Name',
      type: 'String',
      description: 'The name to display for this Angus form',
      default: 'Angus',
    },
    subtitle: {
      friendlyName: 'Subtitle',
      type: 'String',
      description: 'The subtitle for this Angus form',
      default: 'Tenant Service Request',
    },
    description: {
      friendlyName: 'Service Description',
      type: 'LongText',
      description:
        'The description to display to the end user for this Angus form.',
      default: 'Submit your Angus request here.',
    },
    supportEmail: {
      friendlyName: 'Support Email',
      type: 'Email',
    },
    client_id: {
      friendlyName: 'API Client ID',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    client_secret: {
      friendlyName: 'API Client Secret',
      type: 'UUID',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    scope: {
      friendlyName: 'API Scope',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    grant_type: {
      friendlyName: 'API Grant Type',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    propertyId: {
      friendlyName: 'API Property ID',
      type: 'UUID',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    buildingId: {
      friendlyName: 'API Building ID',
      type: 'UUID',
    },
  },
  interactionData: {
    _id: {
      friendlyName: 'id',
      description: 'ID for request',
      type: 'String',
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    description: {
      friendlyName: 'Description',
      description: 'The description of this work order.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 5,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    requestTypeRefNum: {
      friendlyName: 'Request Type ID',
      description: 'The unique id in the Angus system for the request type.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    requesterRefNum: {
      friendlyName: 'Requester ID',
      description:
        'The unique id in the Angus system for this requester (i.e. user).',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    locationRefNum: {
      friendlyName: 'Location ID',
      description:
        'The unique id in the Angus system for the location of this request.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    requestTypeDescription: {
      friendlyName: 'Request Type',
      description: 'The request type of this work order.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    status: {
      friendlyName: 'Status',
      description: 'The status of this work order.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    buildingName: {
      friendlyName: 'Building Name',
      description: 'The name of the building this request was made for.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    tenantName: {
      friendlyName: 'Tenant Name',
      description: 'The name of the tenant this request was made for.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    floor: {
      friendlyName: 'Floor',
      description: 'The floor this request was made for.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    suite: {
      friendlyName: 'Suite',
      description: 'The suite this request was made for.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    propertyName: {
      friendlyName: 'Property Name',
      description: 'The property this request was made for.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    requestDate: {
      friendlyName: 'Request Date',
      description: 'The date & time this request was made on.',
      type: 'DateTime',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
  },
  mobilePermissions: [],
};

export default AngusDefinition;
