import React, { Dispatch, SetStateAction } from 'react';

import { Button, ContextMenu } from 'components';
import { useTranslation } from 'react-i18next';

import { IssueType } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/issueTypes';
import { ContentType } from 'lane-shared/types/content/Content';

import { H3 } from 'components/typography';

import NewOrderModal from './NewOrderModal';
import UserIntegration from './UserIntegration';

import styles from './Header.scss';

const Header = ({
  showCreateButton,
  isLoading = false,
  content,
  onLogout,
  isAddOpen = false,
  setIsAddOpen,
  setError,
  handleNewOrderSuccess = () => {},
  spaces = [],
  issueTypes = [],
}: {
  showCreateButton?: boolean;
  isLoading?: boolean;
  content: ContentType;
  onLogout: () => void;
  isAddOpen?: boolean;
  setIsAddOpen?: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<React.ReactNode>>;
  handleNewOrderSuccess?: () => void;
  spaces?: any[];
  issueTypes?: IssueType[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.headerBlock}>
        <div>
          <H3 className={styles.title}>{t('Maintenance Requests')}</H3>
        </div>

        <div className={styles.buttonBlock}>
          <ContextMenu
            id="log-out-menu"
            items={[
              <UserIntegration
                key="logOutButton"
                content={content}
                onLogout={onLogout}
              />,
            ]}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '"left"' is not assignable to type 'ModalPosi... Remove this comment to see the full error message
            align="left"
            autoFocus={false}
            autoClose
            hasOpaqueBackground={false}
          >
            <Button className={styles.actionsButton}>
              {t('More Actions')}
            </Button>
          </ContextMenu>

          {showCreateButton && (
            <Button
              className={styles.button}
              onClick={() => setIsAddOpen && setIsAddOpen(true)}
              loading={isLoading || spaces.length === 0}
              disabled={spaces.length === 0}
              variant="contained"
            >
              {t('Create new order')}
            </Button>
          )}
        </div>
        <NewOrderModal
          isOpen={isAddOpen}
          onClose={() => setIsAddOpen && setIsAddOpen(false)}
          onError={setError}
          onSuccess={handleNewOrderSuccess}
          spaces={spaces}
          issueTypes={issueTypes}
          content={content}
        />
      </div>
  );
};

export default Header;
