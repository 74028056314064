import React from 'react';

import Login from './Login';
import { OpenRequests } from './components';

export default function BuildingEnginesClassic({ content }: any) {
  return (
    <Login content={content}>
      <OpenRequests content={content} />
    </Login>
  );
}
