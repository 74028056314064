import React from 'react';

import Button from 'components/general/Button';

/**
 * Display the AlertModal with targeting warning to user
 */

export default function useContentTargetingMissingModal(styles: any) {
  async function showContentTargetingMissingModal() {
    await window.Alert.show({
      subTitle: "Something's missing...",
      children: (
        <>
          <p className={styles.modalAlertText}>
            This content is not targeted which means no one will be able to view
            it. Make sure to set up targeting and try again.
          </p>
          <hr className={styles.divider} />
          <Button
            interfaceStyle="dark"
            variant="contained-dark"
            onClick={() => window.Alert.hide()}
            size="large"
          >
            Got it
          </Button>
        </>
      ),
    });
  }
  return {
    showContentTargetingMissingModal,
  };
}
