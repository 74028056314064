import React, { useState, useMemo } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import Color from 'color';
import { useModalPosition } from 'hooks';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { SketchPicker } from 'react-color';
import ReactDOM from 'react-dom';
import { Key } from 'ts-key-enum';

import { DEFAULT_COLOR } from 'lane-shared/helpers/constants';
import {
  colorToRGBA,
  colorToHex,
  capitalize,
} from 'lane-shared/helpers/formatters';
import { ThemeColorPaletteType } from 'lane-shared/types/Theme';

import TabStrip from 'components/general/TabStrip';
import ThemePaletteColorDetails from 'components/lane/ThemePaletteColorDetails';
import { paletteColors } from 'components/lane/ThemePaletteEdit';

import ModalBackground from '../general/ModalBackground';

import styles from './ThemePaletteColorSelectorButton.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  palette: ThemeColorPaletteType;
  value: string | null;
  defaultValue?: string;
  onColorSelected: (color: string | null) => void;
};

type TabName = 'Palette' | 'Custom';
type Tab = { value: TabName; label: TabName };

const tabs: Tab[] = [
  {
    value: 'Palette',
    label: 'Palette',
  },
  {
    value: 'Custom',
    label: 'Custom',
  },
];

/**
 * Selects a color from a theme palette.
 */
export default function ThemePaletteColorSelectorButton({
  className,
  style,
  palette,
  value,
  onColorSelected,
  defaultValue,
}: Props) {
  const {
    buttonRef,
    modalRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } = useModalPosition();
  const [selectedTab, setSelectedTab] = useState<Tab>(tabs[0]);
  const color = new Color(value || DEFAULT_COLOR);
  const alpha = color.alpha();

  const uniqueColors = useMemo(() => {
    const mainColors = paletteColors.map(
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      paletteColor => palette[paletteColor.type]
    );

    const customColors = palette.custom.map(customColor => customColor.color);

    return [...new Set([...mainColors, ...customColors])];
  }, [palette, paletteColors]);

  function doColorsMatch(str1: string | null, str2: string | null): boolean {
    if (!str1 || !str2) {
      return false;
    }

    const color1 = new Color(str1);
    const color2 = new Color(str2);

    return color1.toString() === color2.toString();
  }

  function getPaletteName() {
    const name = Object.keys(palette).find(
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      key => palette[key] === defaultValue
    );

    return name ? capitalize(name) : colorToHex(defaultValue);
  }

  function getColorName(): string {
    if (value === defaultValue) {
      return getPaletteName();
    }

    return `${color.hex()} ${alpha !== 1 ? `${Math.round(alpha * 100)}%` : ''}`;
  }

  function removeColor() {
    if (onColorSelected) {
      onColorSelected(defaultValue || null);
    }
  }

  function renderColor() {
    return (
      <span className={value ? styles.hexValue : styles.noValue}>
        {getColorName()}
      </span>
    );
  }

  return (
    <>
      <div
        data-cy="colorSelector"
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
        ref={buttonRef}
        className={cx(styles.ThemePaletteColorSelectorButton, className)}
        style={style}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && onOpen(e)}
        onClick={onOpen}
      >
        <span
          className={styles.swatch}
          style={{ backgroundColor: value || defaultValue }}
        />
        {renderColor()}
        {defaultValue && value && value !== defaultValue && (
          <Icon
            name="times"
            className={styles.deleteButton}
            onClick={removeColor}
          />
        )}
      </div>
      <ModalBackground
        onClose={onClose}
        isOpen={isOpen}
        className={styles.background}
      />
      {isOpen &&
        ReactDOM.createPortal(
          <div
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
            ref={modalRef}
            className={styles.window}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ top: number; left: number; height?: number... Remove this comment to see the full error message
            style={position}
            role="presentation"
            onClick={e => e.stopPropagation()}
          >
            <TabStrip
              tabs={tabs}
              selected={selectedTab}
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TabItem' is not assignable to pa... Remove this comment to see the full error message
              onSelectTab={tab => setSelectedTab(tab)}
            />
            {selectedTab.value === 'Custom' ? (
              <SketchPicker
                color={value || ''}
                onChange={(newColor: any) =>
                  onColorSelected(colorToRGBA(newColor))
                }
              />
            ) : (
              <div className={styles.colorPalette}>
                {uniqueColors.map(color => {
                  const isSelected = doColorsMatch(color, value);

                  return (
                    <ThemePaletteColorDetails
                      className={styles.swatch}
                      key={color}
                      color={color}
                      size="small"
                      isSelected={isSelected}
                      onClick={() => {
                        if (isSelected) {
                          onClose();
                          return;
                        }

                        onColorSelected(color);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>,
          document.body
        )}
    </>
  );
}
