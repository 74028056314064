import React, { useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { shortDateTime } from 'lane-shared/helpers/formatters';

import Image from '../../general/Image';

import styles from './ParkWhizReceipt.scss';

function ParkWhizReceipt({ style, className, parking }: any) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const booking = parking.bookings[0];

  return (
    <div className={cx(className, styles.ParkWhizReceipt)} style={style}>
      <div className={styles.image}>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'alt' is missing in type '{ src: string; ... Remove this comment to see the full error message */}
        <Image src={`data:image/png;base64, ${booking.qrCode}`} />
      </div>
      <div className={styles.section}>
        <h2>{t('Name')}</h2>
        {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
        <p>{user.profile?.name}</p>
      </div>
      <div className={styles.section}>
        <h2>{t('Perk Purchased')}</h2>
        <a href="" target="_blank">
          {t('View Map')}
        </a>
      </div>
      <div className={styles.section}>
        <h2>{t('Date and Time')}</h2>
        {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1. */}
        <p>{shortDateTime(parking.startDate)}</p>
      </div>
      <div className={styles.section}>
        <h2>{t('Cost')}</h2>
        <p>${parking.final_price}</p>
      </div>
    </div>
  );
}

export default ParkWhizReceipt;
