import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  ReservableFeatureProperties,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import { Checkbox, Dropdown, Input } from 'components/form';
import { bufferTime } from 'components/renderers/helpers';

import styles from './ReservableFeatureBufferTimeInput.scss';

type ReservableFeatureBufferTimeInputPropsType = {
  title: string;
  showCalendarInput?: boolean;
  timeUnit?: string;
  style?: object;
  settings: ReservableFeatureProperties;
  selectedTime: number | undefined;
  calendarLabel: string | undefined;
  isEnabled: boolean;
  onDurationChange: (durationValue: number | undefined) => void;
  onCalenderLabelChange: (labelText: string) => void;
};

export default function ReservableFeatureBufferTimeInput({
  title,
  showCalendarInput = false,
  timeUnit = 'minutes',
  style = {},
  settings,
  selectedTime,
  calendarLabel,
  isEnabled,
  onDurationChange,
  onCalenderLabelChange,
}: ReservableFeatureBufferTimeInputPropsType) {
  const { t } = useTranslation();

  const allowedMaxBufferTime = 120;

  const modifiedDropdownOptions = bufferTime
    .getDropdownOptionsForUnitType(
      settings?.units,
      settings?.unitType || ReservableUnitTypesEnum.Minutes,
      allowedMaxBufferTime
    )
    .map(item => ({ label: `${item}`, value: item }));

  return (
    <div className={styles.ReservableFeatureBufferTimeInput} style={style}>
      <Checkbox
        selected={isEnabled}
        onChange={() => onDurationChange(isEnabled ? undefined : 0)}
        text={title}
        value={title}
        style={{
          padding: '0',
        }}
        labelStyles={{
          fontSize: '1em',
        }}
        data-testid="buffer-time-checkbox"
      />
      {isEnabled && (
        <>
          <div className={styles.timePickerContainer}>
            <Dropdown
              items={modifiedDropdownOptions}
              value={selectedTime}
              className={styles.timeDropdown}
              onChange={(item: { label: string; value: number }) =>
                onDurationChange(item.value)
              }
              name="buffer-time-dropdown"
              placeholder="Select duration"
            />
            <p>{timeUnit}</p>
          </div>
          {showCalendarInput && (
            <Input
              type="text"
              placeholder={t('Calendar label ex. “Cleaning time”')}
              onChange={(labelText: string) => onCalenderLabelChange(labelText)}
              value={calendarLabel}
            />
          )}
        </>
      )}
    </div>
  );
}
