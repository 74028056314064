import React from 'react';

import { LaneType } from 'common-types';
import type { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import type { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import type { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';
import type { InteractionCreatorType } from 'lane-shared/types/server/WorkflowTypes';

import {
  ScheduleSummary,
  UCIReceiptHostInformation,
  VisitorManagementVisitorListItems,
  UCIReceiptNotes,
} from '..';

import styles from './VisitorManagementReceipt.scss';

type Props = {
  interaction: {
    _id: LaneType.UUID;
    _created: string;
    _createdBy: InteractionCreatorType;
    startDate: string | Date | null;
    endDate: string | Date | null;
    status: string;
    geo: GeoCoordinateType;
    features: UserContentInteractionFeaturesType;
    contentData: {
      channelId?: string | null;
      geo?: GeoCoordinateType | null;
    };
  };

  properties: VisitorManagementFeatureProperties;
  displayScheduleAsRow?: boolean;
  adminUciEdit?: boolean;
};

export function VisitorManagementReceipt({
  interaction,
  properties,
  displayScheduleAsRow = false,
  adminUciEdit = false,
}: Props) {
  const [hostInfo, invitedVisitors, scheduleSummary, notes] = [
    <UCIReceiptHostInformation
      key="hostInformation"
      createdById={interaction._createdBy?._id}
      channelId={interaction.contentData?.channelId}
      hostInformation={interaction.features.VisitorManagement?.hostInformation}
    />,
    <VisitorManagementVisitorListItems
      key="visitorListItems"
      visitors={interaction.features.VisitorManagement?.visitors}
      properties={properties}
      receipt
    />,
    <ScheduleSummary
      key="scheduleSummary"
      schedule={interaction.features.VisitorManagement?.schedule}
      geo={interaction.contentData.geo as GeoCoordinateType}
      row={displayScheduleAsRow}
    />,
    <UCIReceiptNotes notes={interaction.features.VisitorManagement?.notes} />,
  ];
  const defaultOrder = [
    hostInfo,
    <HR key="hr1" />,
    invitedVisitors,
    <HR key="hr2" />,
    scheduleSummary,
    notes,
  ];
  const adminUciEditOrder = [
    hostInfo,
    <HR key="hr3" />,
    scheduleSummary,
    notes,
    <HR key="hr4" />,
    invitedVisitors,
  ];

  if (adminUciEdit) {
    return <>{adminUciEditOrder.slice()}</>;
  }

  return <>{defaultOrder.slice()}</>;
}

function HR() {
  return <div className={styles.hr} />;
}
