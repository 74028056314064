import React, {
  HTMLProps,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Icon } from '../Icon';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import styles from './IndeterminateCheckbox.scss';

interface IndeterminateCheckboxProps
  extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  indeterminate?: boolean;
  hideLabel?: boolean;
  label?: string | ReactNode;
}

export const IndeterminateCheckbox = forwardRef<
  HTMLInputElement | null,
  IndeterminateCheckboxProps
>(({ indeterminate, checked, label, hideLabel, ...rest }, ref) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null!);
  useImperativeHandle(ref, () => checkboxRef.current!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      checkboxRef.current.indeterminate = !checked && indeterminate;
    }
  }, [indeterminate]);

  return (
    <label className={styles.CheckboxWrapper}>
      <div className={styles.CheckboxContainer}>
        <input
          type="checkbox"
          ref={checkboxRef}
          className={styles.CheckboxInput}
          checked={checked}
          {...rest}
        />
        {checked && (
          <Icon
            name="check"
            className={styles.CheckboxIcon}
            set={ICON_SET_FONTAWESOME}
            type="far"
            testID="icon"
          />
        )}
        {indeterminate && !checked && (
          <Icon
            name="minus"
            className={styles.CheckboxIcon}
            set={ICON_SET_FONTAWESOME}
            type="far"
            testID="icon"
          />
        )}
      </div>
      <span data-hidden={hideLabel} className={styles.CheckboxLabel}>
        {label}
      </span>
    </label>
  );
});
