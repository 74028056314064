import React, { useCallback, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import type { VisitorPassQueryResponse } from 'lane-shared/domains/visitorManagement/types';
import { EventStatus } from 'lane-shared/domains/visitorManagement/types';

import { ChipListItem, ChipSelect } from 'components/ads';

import { StatusListItems } from '../../types/EventStatus';

import styles from './VisitorPassStatusSelect.scss';

const isWithinTime = (
  visitorPass: VisitorPassQueryResponse,
  bufferTime: number
): boolean => {
  const { startDatetime, endDatetime } = visitorPass;
  const currentTime = new Date().getTime();

  let start = DateTime.fromISO(
    startDatetime ?? new Date().toISOString()
  ).toMillis();
  const end = DateTime.fromISO(
    endDatetime ?? new Date().toISOString()
  ).toMillis();

  if (bufferTime > 0) {
    const bufferTimeInMilliseconds = bufferTime * 1000;
    start -= bufferTimeInMilliseconds;
  }
  return start <= currentTime && currentTime < end;
};

export function VisitorPassStatusSelect({
  visitorPass,
  loading,
  updateStatus,
  bufferTime,
}: {
  visitorPass: VisitorPassQueryResponse;
  loading: boolean | undefined;
  bufferTime: number;
  updateStatus: (
    status: string,
    pass: VisitorPassQueryResponse
  ) => Promise<void>;
}): React.ReactElement {
  const { t } = useTranslation();
  const validTransitionsString = JSON.stringify(
    visitorPass.validStateTransitions
  );

  const generateValidStatusList = useCallback(() => {
    // NOTE: We add Checked In here if the visitor pass is within the time window,
    // because the projection may be out of date
    const validStatusList = StatusListItems.filter(
      item =>
        visitorPass.status === item.value ||
        visitorPass.validStateTransitions.includes(item.value)
    ).map(item => ({ ...item, label: t(item.label) }));

    const checkedInIncluded: boolean = Boolean(
      validStatusList.find(
        item => item.value === EventStatus.EVENT_STATUS_CHECKED_IN
      )
    );

    const noShowIncluded: boolean = Boolean(
      validStatusList.find(
        item => item.value === EventStatus.EVENT_STATUS_NO_SHOW
      )
    );

    const validUpcomingPass: boolean =
      visitorPass.status === EventStatus.EVENT_STATUS_UPCOMING &&
      isWithinTime(visitorPass, bufferTime);

    const checkedInStatus = StatusListItems.find(
      item => item.value === EventStatus.EVENT_STATUS_CHECKED_IN
    );
    const noShowStatus = StatusListItems.find(
      item => item.value === EventStatus.EVENT_STATUS_NO_SHOW
    );

    if (validUpcomingPass) {
      if (!checkedInIncluded && checkedInStatus)
        validStatusList.push({
          ...checkedInStatus,
          label: t(checkedInStatus.label),
        });

      if (!noShowIncluded && noShowStatus)
        validStatusList.push({
          ...noShowStatus,
          label: t(noShowStatus.label),
        });
    }

    return validStatusList;
  }, [visitorPass, bufferTime, t]);

  const [statusList, setStatusList] = useState((): ChipListItem[] =>
    generateValidStatusList()
  );

  useEffect(() => {
    const validStatusList = generateValidStatusList();
    setStatusList(validStatusList);
  }, [validTransitionsString, generateValidStatusList]);

  const handleChange = (newStatus: string) => {
    return updateStatus(newStatus, visitorPass);
  };

  return (
    <div className={styles.chipSelectContainer}>
      <ChipSelect
        dataCy="statusSelect"
        dataTestId={`statusSelect-${visitorPass.id}`}
        anchorClassName={styles.anchor}
        value={visitorPass.status}
        list={statusList}
        onChange={handleChange}
        loading={loading}
        loadingText={t('web.admin.channel.visitor.log.status.updating')}
      />
    </div>
  );
}
