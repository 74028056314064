import { OAuthProvidersEnum } from 'lane-shared/helpers/constants/user';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { OAuthIdentity } from 'lane-shared/types/oauth';

import { getAppleOAuthIdentity } from './apple';
import { getAzureOAuthIdentity } from './azureAD';
import { getGoogleOAuthIdentity } from './google';
import { getOktaOAuthIdentity } from './okta';

type GetOAuthIdentityProps = {
  provider: OAuthProvidersEnum;
  oAuthConfig: OAuthConfigType | undefined;
  options?: OAuthIdentityOptions;
};

const IDENTITY_PROVIDER: {
  [key in OAuthProvidersEnum]?: (...args: any) => Promise<OAuthIdentity>;
} = {
  [OAuthProvidersEnum.AZURE_AD]: getAzureOAuthIdentity,
  [OAuthProvidersEnum.GOOGLE]: getGoogleOAuthIdentity,
  [OAuthProvidersEnum.OKTA]: getOktaOAuthIdentity,
  [OAuthProvidersEnum.APPLE]: getAppleOAuthIdentity,
};

type OAuthIdentityOptions = Partial<{
  scopes: string | string[];
}>;

export async function getOAuthIdentity({
  provider,
  oAuthConfig,
  options = {},
}: GetOAuthIdentityProps): Promise<OAuthIdentity> {
  const getProviderOAuthIdentity = IDENTITY_PROVIDER[provider];

  if (!getProviderOAuthIdentity) {
    throw Error(`${provider} has not been made an available OAuth provider`);
  }

  return getProviderOAuthIdentity(oAuthConfig, options);
}
