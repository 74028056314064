import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { ChannelIntegrationType } from 'lane-shared/types/ChannelIntegrationType';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

const queryPrimaryChannelIntegrations = gql`
  query getPrimaryChannelIntegrations($id: UUID) {
    channel(_id: $id) {
      _id
      integrations {
        _id
        integration {
          _id
          name
          type
          category
        }
      }
    }
  }
`;

export default function useCurrentChannelIntegrations(): ChannelIntegrationType[] {
  const { channel } = useChannelAdminContext();

  const { data } = useQuery<{
    channel: {
      integrations: ChannelIntegrationType[];
    };
  }>(queryPrimaryChannelIntegrations, {
    fetchPolicy: 'network-only',
    skip: !channel?._id,
    variables: {
      id: channel?._id,
    },
  });

  return data?.channel?.integrations || [];
}
