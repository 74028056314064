import React, { useEffect } from 'react';

import { Icon } from 'design-system-web';
import { v4 as uuid } from 'uuid';

import { getValidationMessages } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { SUPPORTED_SSO_PROVIDERS } from 'lane-shared/helpers/constants/user';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';

import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';
import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';

import styles from './WhitelabelInfoEdit.scss';

const FILTERED_SUPPORTED_SSO_PROVIDERS = Object.values(
  SUPPORTED_SSO_PROVIDERS
).filter(provider => provider !== SUPPORTED_SSO_PROVIDERS.APPLE);
const oAuthProvidersArray = FILTERED_SUPPORTED_SSO_PROVIDERS.map(provider => ({
  value: provider,
  label: provider,
}));

type WhitelabelInfoEditOAuthProps = {
  oAuthConfig: OAuthConfigType;
  onUpdateOAuthConfig: (
    update: Partial<OAuthConfigType>,
    index?: number
  ) => void;
  removeOAuthConfig: (oAuthConfig: OAuthConfigType) => void;
  validation: any;
  index: number;
};

export default function WhitelabelInfoEditOAuth({
  oAuthConfig,
  onUpdateOAuthConfig,
  removeOAuthConfig,
  validation,
  index,
}: WhitelabelInfoEditOAuthProps) {
  // NOTE: Migration did not add `_id` field as intended, we will gently
  // coerce an `_id` onto current and future `OAuthConfigType` instances
  useEffect(() => {
    if (!oAuthConfig._id) {
      handleChange({ _id: uuid() }, index);
    }
  }, []);

  function handleChange(update: Partial<OAuthConfigType>, index?: number) {
    onUpdateOAuthConfig(
      {
        ...oAuthConfig,
        ...update,
      },
      index
    );
  }

  return (
    <li>
      <Toggle
        className={styles.toggle}
        text="Enabled"
        value={oAuthConfig.enabled}
        onChange={enabled => handleChange({ enabled })}
        doTranslate
      />

      <Dropdown
        className={styles.input}
        items={oAuthProvidersArray}
        value={oAuthConfig.provider}
        onValueChange={provider => handleChange({ provider })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'iosClientId'
        )}
        className={styles.input}
        placeholder="7289362712937-4tol48ul7f5k5n5pr0ffknb1293jei53.apps.googleusercontent.com"
        label="iOS Client ID"
        value={oAuthConfig.iosClientId}
        onChange={iosClientId => handleChange({ iosClientId })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'iosRedirectUrl'
        )}
        className={styles.input}
        placeholder="com.googleusercontent.apps.7218365226388-4tol48ul7f5k5n5pr0ffknb4867jei53:/oauth2redirect/google"
        label="iOS Redirect URL"
        value={oAuthConfig.iosRedirectUrl}
        onChange={iosRedirectUrl => handleChange({ iosRedirectUrl })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'androidClientId'
        )}
        className={styles.input}
        placeholder="7289362712937-4tol48ul7f5k5n5pr0ffknb1293jei53.apps.googleusercontent.com"
        label="Android Client ID"
        value={oAuthConfig.androidClientId}
        onChange={androidClientId => handleChange({ androidClientId })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'androidRedirectUrl'
        )}
        className={styles.input}
        placeholder="com.lane:/oauth2callback"
        label="Android Redirect URL"
        value={oAuthConfig.androidRedirectUrl}
        onChange={androidRedirectUrl => handleChange({ androidRedirectUrl })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'webClientId'
        )}
        className={styles.input}
        placeholder="88394723993483-0kdrjnbrf42b7gbehrrn1qjslrhfi2jt.apps.googleusercontent.com"
        label="Web Client ID"
        value={oAuthConfig.webClientId}
        onChange={webClientId => handleChange({ webClientId })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'webClientSecret'
        )}
        className={styles.input}
        placeholder="IjsO0kj8SldJEN_eLpULmslKS"
        label="Web Client Secret"
        value={oAuthConfig.webClientSecret || ''}
        onChange={webClientSecret => handleChange({ webClientSecret })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'webRedirectUrl'
        )}
        className={styles.input}
        placeholder="https://app.joinlane.com/"
        label="Web Redirect URL"
        value={oAuthConfig.webRedirectUrl}
        onChange={webRedirectUrl => handleChange({ webRedirectUrl })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'tenantId'
        )}
        className={styles.input}
        placeholder="00000000-0000-0000-0000-000000000000"
        label="Tenant/Directory ID"
        value={oAuthConfig.tenantId || ''}
        onChange={tenantId => handleChange({ tenantId })}
      />

      <Input
        error={getValidationMessages(
          validation,
          `oAuthConfig[${index}]`,
          'buttonLabel'
        )}
        className={styles.input}
        placeholder="Connect with Work Account"
        label="Button Label"
        value={oAuthConfig.buttonLabel || ''}
        onChange={buttonLabel => handleChange({ buttonLabel })}
      />

      <Icon
        name="times-circle"
        set={ICON_SET_FONTAWESOME}
        className={styles.removeIcon}
        onClick={() => removeOAuthConfig(oAuthConfig)}
      />

      <Button
        style={styles.clipboardButton}
        onClick={() => {
          navigator.clipboard.writeText(JSON.stringify(oAuthConfig, null, 2));
        }}
      >
        <Icon name="clipboard" set={ICON_SET_FONTAWESOME} />
      </Button>
    </li>
  );
}
