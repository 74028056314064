import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesArrayUnique } from './ITypeValidatesArrayUnique';

export class ArrayUniqueValidator
  implements ValidatorInterface<ITypeValidatesArrayUnique> {
  name = 'ArrayUnique';

  friendlyName = 'Unique Values';

  arrayOnly = true;

  array = true;

  description = 'Each entry in this list must be unique';

  types = null;

  properties = {
    value: {
      type: 'Boolean',
      default: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesArrayUnique) {
    type.addArrayUniqueValidator(this);
  }

  constructor(public config?: Validator) {}
}

const ArrayUnique = new ArrayUniqueValidator(undefined);

ValidatorRegistry.register(ArrayUniqueValidator, ArrayUnique.name);

export default ArrayUnique;
