import React, { useEffect, useState } from 'react';

import { TimeIntervals } from 'design-system-web';
import { IconButton, Label } from 'components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { getValidationMessages } from 'lane-shared/helpers';
import { AddNotificationType } from 'lane-shared/hooks/useCreateDraft';
import {
  ContentNotificationType,
  DraftContentType,
} from 'lane-shared/types/content/DraftContent';

import { DatePickerCard } from 'components/cards/DatePickerCard';
import { Checkbox } from 'components/form';
import { H2 } from 'components/typography';

import {
  DraftContentExistingNotificationUI,
  NotificationStatus,
} from './DraftContentExistingNotificationUI';

import { extractChannelLanguagesArray } from 'lane-shared/helpers/dynamicLanguages';
import { LocalizationColumnType } from 'lane-shared/types/LocalizationColumnType';
import { useCurrentChannel } from 'lane-shared/hooks';

import i18next from 'i18next';

import styles from './DraftContentNotifications.scss';
import type { StepperTrackerType } from 'lane-shared/hooks/analytics/useDraftContentAnalytics';

type Props = {
  content: DraftContentType;
  onContentUpdated: (
    content: Partial<DraftContentType>
  ) => Partial<DraftContentType>;
  onAddNotification: AddNotificationType;
  timeZone?: string;
  liveContent?: DraftContentType | null;
  validation?: ValidationError[] | null;
  stepperTracker?: StepperTrackerType;
};

type RoundUpTimeInDate = {
  timeZone?: string | null;
  fromDate?: Date | null;
  timeInterval?: TimeIntervals;
};

const TRANSLATION_KEYS = {
  setUpNotifications:
    'web.components.lane.DraftContent.DraftContentNotifications.setUpNotifications',
  setUpNotificationsDescription:
    'web.components.lane.DraftContent.DraftContentNotifications.setUpNotificationsDescription',
  contentIsLive:
    'web.components.lane.DraftContent.DraftContentNotifications.contentIsLive',
  notificationTitle:
    'web.components.lane.DraftContent.DraftContentNotifications.notificationTitle',
  automaticallySetupNotification:
    'web.components.lane.DraftContent.DraftContentNotifications.automaticallySetupNotification',
  notificationMessage:
    'web.components.lane.DraftContent.DraftContentNotifications.notificationMessage',
  setUpAnotherNotification:
    'web.components.lane.DraftContent.DraftContentNotifications.setUpAnotherNotification',
  addNotificaion:
    'web.components.lane.DraftContent.DraftContentNotifications.addNotificaion',
};

export const roundUpTimeInDate = ({
  timeZone,
  fromDate,
  timeInterval = TimeIntervals.ONE_HOUR,
}: RoundUpTimeInDate = {}) => {
  let dateInLuxon = fromDate ? DateTime.fromJSDate(fromDate) : DateTime.local();

  if (timeZone) {
    dateInLuxon = dateInLuxon.setZone(timeZone);
  }
  const roundedMinutes =
    Math.ceil(dateInLuxon.minute / timeInterval) * timeInterval;
  dateInLuxon = dateInLuxon.set({ minute: roundedMinutes, second: 0 });

  return dateInLuxon.toJSDate();
};

export const DraftContentNotifications = ({
  timeZone,
  content,
  liveContent,
  validation,
  onContentUpdated,
  onAddNotification,
  stepperTracker,
}: Props) => {
  const { t } = useTranslation();
  const existingGoesLiveNotification = content?.notifications?.find(
    notification => notification.contentGoesLiveNotification
  );

  const [autoCreatedNotificationId, updateAutoCreatedNotificationId] = useState<
    string | null
  >(existingGoesLiveNotification?._id ?? null);

  function bySendAt(a: ContentNotificationType, b: ContentNotificationType) {
    return a.sendAt?.valueOf()! - b.sendAt?.valueOf()!;
  }

  const liveNotifications = (liveContent && liveContent.notifications) || [];
  const channel = useCurrentChannel();
  const channelLanguagesArr = extractChannelLanguagesArray(channel);

  // separate notifications that aren't live
  const newNotifications =
    content?.notifications?.filter(
      (notification: ContentNotificationType) =>
        !liveNotifications.some(
          (liveNotification: ContentNotificationType) =>
            liveNotification._id === notification._id
        )
    ) || [];

  // notifications that are live but not sent. user can cancel
  const scheduledNotifications =
    content?.notifications
      ?.filter((notification: ContentNotificationType) =>
        liveNotifications.some(
          (liveNotification: ContentNotificationType) =>
            liveNotification._id === notification._id && !liveNotification.sent
        )
      )
      .sort(bySendAt) || [];

  // notifications that are live and sent. too late!
  const sentNotifications = liveNotifications
    .filter(
      (liveNotification: ContentNotificationType) => liveNotification.sent
    )
    .sort(bySendAt);

  function notificationUpdated(notification: ContentNotificationType) {
    content.notifications[
      content.notifications.findIndex(
        (n: ContentNotificationType) => n._id === notification._id
      )
    ] = notification;

    onContentUpdated({
      notifications: [...content.notifications],
    });
  }

  useEffect(() => {
    if (liveContent === undefined) {
      // waiting for data
      return;
    }
    if (!liveContent) {
      return content.notifications?.forEach(notification => {
        notification._new = true;
      });
    }
    const notificationsWithoutNewFlag = newNotifications?.filter(
      notification => !notification._new
    );
    if (notificationsWithoutNewFlag?.length) {
      notificationsWithoutNewFlag.forEach(
        notification => (notification._new = true)
      );
    }
  }, [content.notifications, liveContent]);

  function removeNotification(notification: ContentNotificationType) {
    return onContentUpdated({
      notifications: content.notifications.filter(
        (eachNotification: ContentNotificationType) =>
          eachNotification._id !== notification._id
      ),
    });
  }

  function handleNotificationTitleTranslation() {
    let notificationTitle;
    let notificationTitleL10n;

    if (content.name) {
      notificationTitle = `${content.name} ${t(
        TRANSLATION_KEYS.contentIsLive
      )} ${t(TRANSLATION_KEYS.notificationTitle)}`;

      notificationTitleL10n =
        channelLanguagesArr?.length > 1
          ? channelLanguagesArr.reduce(
              (translations: LocalizationColumnType, { languageCode }) => {
                translations[languageCode] = `${
                  content.name_l10n[languageCode]
                } ${i18next.t(TRANSLATION_KEYS.contentIsLive, {
                  lng: languageCode,
                })} ${i18next.t(TRANSLATION_KEYS.notificationTitle, {
                  lng: languageCode,
                })}`;
                return translations;
              },
              {}
            )
          : {};
    } else {
      notificationTitle = t(TRANSLATION_KEYS.notificationTitle);

      notificationTitleL10n =
        channelLanguagesArr?.length > 1
          ? channelLanguagesArr.reduce(
              (translations: LocalizationColumnType, { languageCode }) => {
                translations[languageCode] = i18next.t(
                  TRANSLATION_KEYS.notificationTitle,
                  {
                    lng: languageCode,
                  }
                );
                return translations;
              },
              {}
            )
          : {};
    }
    return { notificationTitle, notificationTitleL10n };
  }

  return (
    <div className={styles.notificationsSection} id="notifications">
      <H2 className={styles.notificationHeader}>
        {t(TRANSLATION_KEYS.setUpNotifications)}
      </H2>
      <Label className={styles.notificationSubtext}>
        {t(TRANSLATION_KEYS.setUpNotificationsDescription)}
      </Label>
      {!liveContent && (
        <Checkbox
          value={!!autoCreatedNotificationId}
          selected={!!autoCreatedNotificationId}
          onChange={(isAutoCreateNotificationChecked: boolean) => {
            let newDraft;
            if (
              isAutoCreateNotificationChecked &&
              !!autoCreatedNotificationId
            ) {
              newDraft = removeNotification(
                content.notifications.find(
                  notification => notification._id === autoCreatedNotificationId
                )!
              );
              updateAutoCreatedNotificationId(null);
            }
            if (!isAutoCreateNotificationChecked) {
              const {
                notificationTitle,
                notificationTitleL10n,
              } = handleNotificationTitleTranslation();
              const notification = onAddNotification({
                title: notificationTitle,
                title_l10n: notificationTitleL10n,
                sendAt: roundUpTimeInDate({
                  timeZone,
                  fromDate: content.liveDate,
                  timeInterval: TimeIntervals.QUARTER_HOUR,
                }),
                contentGoesLiveNotification: true,
              });
              updateAutoCreatedNotificationId(notification._id);
              newDraft = {
                ...content,
                notifications: content.notifications.concat(notification),
              };
            }
            stepperTracker?.Publish.AutoNotificationIsSet(newDraft);
          }}
          className={styles.autoNotificationCheckbox}
          data-cy="autoNotificationCheckbox"
          text={t(TRANSLATION_KEYS.automaticallySetupNotification)}
        />
      )}
      <IconButton
        icon="PlusCircle"
        text={t(TRANSLATION_KEYS.addNotificaion)}
        onClick={() => {
          const newNotification = onAddNotification({
            sendAt: roundUpTimeInDate({
              timeZone,
              fromDate: content.liveDate,
              timeInterval: TimeIntervals.QUARTER_HOUR,
            }),
            title: '',
            contentGoesLiveNotification: false,
          });
          const newDraft = {
            ...content,
            notifications: content.notifications.concat(newNotification),
          };
          stepperTracker?.Publish.AddNotification(newDraft);
        }}
        size="small"
        dataCy="addNotification"
        className={styles.addNotificationButton}
      />
      <div className={styles.notificationCards}>
        <div className={styles.newNotifications} data-cy="notificationCards">
          {newNotifications
            // enforcing the auto created notification to be the first notification rendered
            .sort((notification, _) =>
              notification._id === autoCreatedNotificationId ? -1 : 0
            )
            .map((notification: ContentNotificationType, index: number) => {
              // We add the index offset because when we send the array of notifications
              // to our validator, that array contains sent and scheduled notifications as well.
              // So, when validator throws an error for a specific item in the array,
              // the index is not correctly mapped to what we render here (since here we only render the new notifications).
              // We need to offset the index with the length of the sent and scheduled notifications.
              // Another thing to keep in mind is that we are always guaranteed that sent and scheduled notifications
              // would come before all the new notifications (since we store these in a jsonb format in the db
              // and when retrieving them, their order is guaranteed to be the same). So, index offset should always work!
              const indexWithOffset =
                index +
                sentNotifications.length +
                scheduledNotifications.length;
              return (
                <DatePickerCard
                  key={notification._id}
                  id={notification._id}
                  inputMaxLength={255}
                  notification={notification}
                  datePickerButtonProps={{
                    minDate: new Date(),
                    timeZone,
                    timeInterval: TimeIntervals.QUARTER_HOUR,
                  }}
                  onChange={({ date, updates }) => {
                    notificationUpdated({
                      ...notification,
                      sendAt: date,
                      ...updates,
                    });
                  }}
                  deletable
                  onDelete={id => {
                    if (
                      autoCreatedNotificationId &&
                      id === autoCreatedNotificationId
                    ) {
                      updateAutoCreatedNotificationId(null);
                    }
                    const newDraft = removeNotification(notification);
                    stepperTracker?.Publish.DeleteNotification(newDraft);
                  }}
                  messageLabel={TRANSLATION_KEYS.notificationMessage}
                  dateValue={notification.sendAt}
                  messageValue={notification.title || ''}
                  dateError={
                    getValidationMessages(
                      validation,
                      `notifications[${indexWithOffset}]`,
                      'sendAt'
                    ) || []
                  }
                  timeError={
                    getValidationMessages(
                      validation,
                      `notifications[${indexWithOffset}]`,
                      'sendAt'
                    ) || []
                  }
                  messageError={
                    getValidationMessages(
                      validation,
                      `notifications[${indexWithOffset}]`,
                      'title'
                    ) || []
                  }
                />
              );
            })}
        </div>

        <div className={styles.scheduledNotifications}>
          {scheduledNotifications.map(
            (notification: ContentNotificationType) => (
              <DraftContentExistingNotificationUI
                channel={channel}
                key={notification._id}
                notification={notification}
                status={NotificationStatus.SCHEDULED}
                timezone={timeZone}
                removeNotification={removeNotification}
              />
            )
          )}
        </div>

        <div className={styles.sentNotifications}>
          {sentNotifications.map((notification: ContentNotificationType) => (
            <DraftContentExistingNotificationUI
              channel={channel}
              key={notification._id}
              notification={notification}
              status={NotificationStatus.SENT}
              timezone={timeZone}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
