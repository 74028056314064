import EventEmitter from 'eventemitter3';

export const EVENT_SUBSCRIPTIONS_CHANGED = 'subscriptionsChanged';
export const EVENT_SUBSCRIPTIONS_MODAL = 'subscriptionsShowModal';
// the user has specifically logged out.
export const EVENT_LOGOUT = 'logout';
// the user no longer has a valid auth token.
export const EVENT_AUTH_TOKEN_INVALID = 'authTokenInvalid';
export const EVENT_AUTH_TOKEN = 'authToken';
export const EVENT_LOCATION = 'location';
export const EVENT_LOCATION_ERROR = 'location-error';
export const EVENT_LOCATION_STOPPED = 'location-stopped';
export const EVENT_CUSTOM_LOCATION = 'custom-location';
export const EVENT_NAVIGATE = 'navigate-route';
export const EVENT_NAVIGATE_CONTENT = 'navigate-content';
export const EVENT_LEAVE_CONTENT = 'leave-content';
export const EVENT_UPLOAD_PROFILE_PHOTO = 'upload-profile-photo';
export const EVENT_NAG_LOCATION_REQUEST = 'nag-location-request';
export const EVENT_NAG_LOCATION_REQUEST_RESULT = 'nag-location-request-result';
export const EVENT_BLUETOOTH_MANAGER_INITIALIZED =
  'bluetooth-manager-initialized';

const emitter = new EventEmitter();

(emitter as any).EVENT_SUBSCRIPTIONS_CHANGED = EVENT_SUBSCRIPTIONS_CHANGED;
(emitter as any).EVENT_SUBSCRIPTIONS_MODAL = EVENT_SUBSCRIPTIONS_MODAL;
(emitter as any).EVENT_LOGOUT = EVENT_LOGOUT;
(emitter as any).EVENT_AUTH_TOKEN = EVENT_AUTH_TOKEN;
(emitter as any).EVENT_LOCATION = EVENT_LOCATION;
(emitter as any).EVENT_LOCATION_ERROR = EVENT_LOCATION_ERROR;
(emitter as any).EVENT_LOCATION_STOPPED = EVENT_LOCATION_STOPPED;
(emitter as any).EVENT_CUSTOM_LOCATION = EVENT_CUSTOM_LOCATION;
(emitter as any).EVENT_NAVIGATE = EVENT_NAVIGATE;
(emitter as any).EVENT_LEAVE_CONTENT = EVENT_LEAVE_CONTENT;
(emitter as any).EVENT_UPLOAD_PROFILE_PHOTO = EVENT_UPLOAD_PROFILE_PHOTO;

export default emitter;
