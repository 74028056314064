import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import Color from 'color';
import { useTranslation } from 'react-i18next';

import { DEFAULT_COLOR } from 'lane-shared/helpers/constants';
import { colorToRGBA } from 'lane-shared/helpers/formatters';

import ColorPicker from 'components/form/ColorPicker';

import styles from './ColorPickerButton.scss';

type OwnProps = {
  style?: React.CSSProperties;
  className?: string;
  value?: string | null;
  onChange?: (color: string | null) => any;
  disableAlpha: boolean;
  dataCy?: string;
};

ColorPickerButton.defaultProps = {
  value: null,
  disableAlpha: true,
  onChange: () => null,
};

type Props = OwnProps & typeof ColorPickerButton.defaultProps;

export default function ColorPickerButton({
  className,
  style,
  value,
  onChange,
  disableAlpha,
  dataCy,
}: Props) {
  const { t } = useTranslation();
  const color = new Color(value || DEFAULT_COLOR);
  const alpha = color.alpha();

  function removeColor(e: any) {
    e.stopPropagation();
    if (onChange) {
      onChange(null);
    }
  }

  function renderColor() {
    if (!value) {
      return <span className={styles.noValue}>{t('No color selected')}</span>;
    }

    return (
      <span className={styles.hexValue}>
        {color.hex()} {alpha !== 1 && `${Math.round(alpha * 100)}%`}
      </span>
    );
  }

  return (
    <ColorPicker
      value={value}
      onChange={newColor => onChange(colorToRGBA(newColor))}
      disableAlpha={disableAlpha}
      dataCy={dataCy}
    >
      <div className={cx(styles.ColorPicker, className)} style={style}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'BackgroundC... Remove this comment to see the full error message */}
        <span className={styles.swatch} style={{ backgroundColor: value }} />
        {renderColor()}
        {value && (
          <Icon
            name="times"
            className={styles.deleteButton}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: any) => void' is not assignable to type ... Remove this comment to see the full error message
            onClick={removeColor}
          />
        )}
      </div>
    </ColorPicker>
  );
}
