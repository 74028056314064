export default function isMenuFeatureOrderItemValid(item: any, orderItem: any) {
  // can't order nothing of a thing
  if (orderItem.quantity === 0) {
    return false;
  }

  // check quantity first.
  if (
    orderItem.quantity < item.minQuantity ||
    orderItem.quantity > item.maxQuantity
  ) {
    return false;
  }

  for (const optionList of item.options) {
    const orderOptionList = orderItem.options.find(
      (orderOptionList: any) => orderOptionList._id === optionList._id
    );

    // not enough options selected.
    if (orderOptionList.options.length < optionList.minChoices) {
      return false;
    }

    // too many options selected.
    if (orderOptionList.options.length > optionList.maxChoices) {
      return false;
    }
  }

  return true;
}
