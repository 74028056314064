import * as yup from 'yup';

import {
  STEP_EDITOR,
  STEP_INFO,
  STEP_PUBLISH,
  STEP_TARGETING,
  STEP_TEMPLATE,
  STEP_DATABASE,
} from '../helpers/constants/steps';
import {
  validateContentBase,
  validateContentEditor,
  validateContentInfo,
  validateContentPublishing,
  validateContentTargeting,
} from './content';

export default {
  [STEP_DATABASE]: null,
  [STEP_TEMPLATE]: null,
  [STEP_INFO]: yup
    .object()
    .concat(validateContentInfo)
    .concat(validateContentBase),
  [STEP_EDITOR]: validateContentEditor,
  [STEP_TARGETING]: validateContentTargeting,
  [STEP_PUBLISH]: validateContentPublishing,
};
