import React, { useContext } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import hasAdminOnChannel from 'lane-shared/helpers/user/hasAdminOnChannel';
import { ChannelType } from 'lane-shared/types/ChannelType';

import styles from './ViewSwitchButton.scss';

type Props = {
  className?: string;
  style: React.CSSProperties;
  channel?: ChannelType;
};

export default function ViewSwitchButton({ className, style, channel }: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const [isAdminView] = useIsAdminView();

  if (isAdminView) {
    if (!channel?.parent) {
      return null;
    }

    return (
      <Link to={routes.home} className={cx(styles.ViewSwitchButton, className)}>
        <Icon name="sync-alt" className={styles.icon} />
        <span data-cy="menu-option-switch-to-members">
          {t('Switch to Members View on')} {getDisplayName(channel)}
        </span>
      </Link>
    );
  }

  if (
    channel &&
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    (user?.isSuperUser || hasAdminOnChannel(user.roles, channel))
  ) {
    return (
      <Link
        to={routes.channelAdmin.replace(':id', channel?.slug)}
        className={cx(styles.ViewSwitchButton, className)}
        style={style}
      >
        <Icon name="sync-alt" className={styles.icon} />
        <span data-cy="menu-option-switch-to-admin">
          {t('Switch to Admin on')} {getDisplayName(channel)}
        </span>
      </Link>
    );
  }

  return null;
}
