import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { MediaTypeEnum } from '../../types/media';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  type: {
    type: 'String',
    validators: [
      {
        name: 'In',
        value: Object.values(MediaTypeEnum),
      },
    ],
  },
  contentType: {
    type: 'String',
  },
};

export class MediaType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Media';

  friendlyName = 'Media Item';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  get default(): any {
    return {
      _id: null,
    };
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new MediaType();

Types.addBaseType(definition);
export default definition;
