import i18next from 'i18next';
import * as yup from 'yup';

export function validateAudience(
  translator: (key: string, params?: any) => string = i18next.t
) {
  const AUDIENCE_NAME_REQUIRED = translator(
    'web.pages.portal.admin.audiences.create.nameError'
  );

  const AUDIENCE_NAME_MIN_CHARS = translator(
    'web.pages.portal.admin.audiences.create.nameLengthError'
  );

  const AUDIENCE_TARGET_RULES_MIN = translator(
    'web.pages.portal.admin.audiences.create.targetError'
  );

  const audienceNameValidator = yup
    .string()
    .required(AUDIENCE_NAME_REQUIRED)
    .min(3, AUDIENCE_NAME_MIN_CHARS)
    .trim();

  const audienceTargetingRulesValidator = yup
    .array()
    .min(1, AUDIENCE_TARGET_RULES_MIN);

  return { audienceNameValidator, audienceTargetingRulesValidator };
}
