import React from 'react';

import { useTranslation } from 'react-i18next';

import { config } from 'lane-shared/config';

type Props = {
  className?: string;
};

export default function StripeLegal({ className }: Props) {
  const { t } = useTranslation();
  return (
    <p className={className}>
      {t('Your payment info encrypted and protected by')}{' '}
      <a
        href={config.stripe.securityUrl}
        style={{
          textDecorationLine: 'underline',
          fontWeight: 'bold',
        }}
        rel="noopener noreferrer"
        target="_blank"
      >
        Stripe
      </a>
    </p>
  );
}
