import React, { CSSProperties, PropsWithChildren } from 'react';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mapb... Remove this comment to see the full error message
import * as MapboxGl from 'mapbox-gl';
import ReactMapboxGl from 'react-mapbox-gl';

import { config } from 'lane-shared/config';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import ComponentErrorBoundary from 'components/errors/ComponentErrorBoundary';

import RotateImage from 'static/img/map-box/rotate-01.png';
import ScaleImage from 'static/img/map-box/scale-01.png';
import UnavailableImage from 'static/img/map-box/unavailable-icon.png';

import './MapboxMap.scss';

const MAX_ZOOM = 23;
const DEFAULT_MIN_ZOOM = 18;

const Map = ReactMapboxGl({
  accessToken: config.mapbox.publicKey,
  maxZoom: MAX_ZOOM,
});

const rotateImg = new Image();
rotateImg.src = RotateImage;
rotateImg.crossOrigin = 'Anonymous';

const scaleImg = new Image();
scaleImg.src = ScaleImage;
scaleImg.crossOrigin = 'Anonymous';

const unavailableImg = new Image();
unavailableImg.src = UnavailableImage;
unavailableImg.crossOrigin = 'Anonymous';

type OwnProps = {
  children: React.ReactNode;
  onStyleLoad?: unknown;
  zoom?: [number];
  center?: [number, number];
  bearing?: [number];
  isPositionReady?: boolean;
  fitBounds?: unknown;
  maxBounds?: any;
  containerStyle?: CSSProperties;
  minZoom?: number;
  onZoom?: (zoom: number) => void;
  onCenter?: (center: GeoCoordinateType) => void;
  animationOptions?: Partial<any>;
  fullScreenControlContainer?: HTMLElement;
};

MapboxMap.defaultProps = {
  minZoom: DEFAULT_MIN_ZOOM,
  isPositionReady: true,
};

type Props = OwnProps & typeof MapboxMap.defaultProps;

type ErrorComponentProps = {
  error: any;
};

const ErrorComponent = ({ error }: PropsWithChildren<ErrorComponentProps>) => {
  return (
    <div>
      <h1>WebGL is not available on this browser</h1>
      <p>{error.message}</p>
    </div>
  );
};

export default function MapboxMap({
  children,
  onStyleLoad,
  minZoom,
  fitBounds,
  fullScreenControlContainer,
  bearing,
  center,
  zoom,
  isPositionReady = true,
  onCenter,
  onZoom,
  ...props
}: Props) {
  const [map, setMap] = React.useState<MapboxGl.Map>();
  const initialPositionRef = React.useRef<{
    zoom?: [number];
    center?: [number, number];
  }>();

  function mapReady(map: MapboxGl.Map, loadEvent: any) {
    map.setMinZoom(minZoom);

    // add on the required images for the controls
    map.addImage('rotate', rotateImg);
    map.addImage('scale', scaleImg);

    if (fullScreenControlContainer) {
      map.addControl(
        new MapboxGl.FullscreenControl({
          container: fullScreenControlContainer,
        })
      );
    } else {
      map.addControl(new MapboxGl.FullscreenControl());
    }

    map.addImage('unavailable', unavailableImg);
    map.resize();

    // we use initial values ref set at the time position becomes ready because otherwise user interactions while position is loading or not ready to be set for other factors will rewrite the position and result in a wrong position used
    if (
      initialPositionRef.current?.center ||
      initialPositionRef.current?.zoom
    ) {
      // otherwise if we put object with undefined it will produce Invalid LngLat object: (NaN, NaN) error
      const jumpPosition: any = {};
      if (initialPositionRef.current?.center) {
        jumpPosition.center = initialPositionRef.current?.center;
      }
      if (initialPositionRef.current?.zoom) {
        jumpPosition.zoom = initialPositionRef.current?.zoom;
      }
      map.jumpTo(jumpPosition);
    } else if (fitBounds) {
      map.fitBounds(fitBounds);
    }

    if (onStyleLoad) {
      (onStyleLoad as any)(map, loadEvent);
    }
    setMap(map);
  }

  function handleZoom(map: MapboxGl.Map) {
    if (onZoom) {
      // without setTimout it produces "this._onEaseFrame is not a function" error
      // here's the link for a similar issue with proposed solution: https://github.com/mapbox/mapbox-gl-js/issues/1744#issuecomment-555246044
      setTimeout(() => {
        onZoom(map.getZoom());
      }, 0);
    }
  }

  function handleCenter(map: MapboxGl.Map) {
    if (onCenter) {
      // without setTimout it produces "this._onEaseFrame is not a function" error
      // here's the link for a similar issue with proposed solution: https://github.com/mapbox/mapbox-gl-js/issues/1744#issuecomment-555246044
      setTimeout(() => {
        const center = map.getCenter();
        onCenter([center.lng, center.lat]);
      }, 0);
    }
  }

  React.useEffect(() => {
    if (!initialPositionRef.current && isPositionReady) {
      initialPositionRef.current = {
        zoom,
        center,
      };
    }
  }, [isPositionReady, center]);

  // for some reason pasting bearing directly into the map props together with zoom and center controlled by onMove, onZoom handlers will cause significant lags on movements
  React.useEffect(() => {
    if (map && bearing) {
      map.jumpTo({ bearing });
    }
  }, [map, bearing?.[0]]);

  return (
    <ComponentErrorBoundary ErrorComponent={ErrorComponent}>
      <Map
        /* eslint-disable react/style-prop-object */
        style="mapbox://styles/mapbox/light-v10"
        onStyleLoad={mapReady}
        onZoom={handleZoom}
        onMove={handleCenter}
        {...props}
      >
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        {children}
      </Map>
    </ComponentErrorBoundary>
  );
}
