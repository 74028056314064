import fuzzyMatch from '../helpers/fuzzyMatch';

interface AccessGroups {
  label: string;
}

export function useAccessGroupsSearch<T extends AccessGroups>(
  accessGroupList: T[],
  search: string
): T[] {
  return accessGroupList.filter(accessGroup =>
    fuzzyMatch(accessGroup.label, search)
  );
}
