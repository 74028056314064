import React from 'react';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { routes } from 'lane-shared/config';
import { getRelatedSpace } from 'lane-shared/graphql/query';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { FeatureInstance } from 'lane-shared/types/FeatureInstance';

import IconButton from 'components/general/IconButton';

import SkeletonRelatedSpacesCard from './SkeletonRelatedSpacesCard';

import styles from './RelatedSpacesCard.scss';

export type Props = {
  relatedSpaceId: LaneType.UUID;
  onDelete: (id: LaneType.UUID) => void;
  Footer?: null | (({ content }: { content: RelatedSpace }) => JSX.Element);
};

export type RelatedSpace = {
  _id: LaneType.UUID;
  name: string;
  description?: string;
  backgroundImage?: string;
  channel: {
    _id: LaneType.UUID;
    slug: string;
  };
  features: Pick<FeatureInstance, '_id' | 'type' | 'feature'>[];
};

export default function RelatedSpacesCard({
  relatedSpaceId,
  Footer = null,
  onDelete,
}: Props) {
  const { data, loading } = useQuery<{ content: RelatedSpace }>(
    getRelatedSpace,
    {
      variables: { contentId: relatedSpaceId },
    }
  );

  const handleOpenInNewTab = () => {
    const url = routes.channelAdminDraft
      .replace(':id', data?.content.channel.slug || '')
      .replace(
        ':draftId',
        `${relatedSpaceId}?reservableMenu=Setup&feature=Reservable&menu=Features&step=Editor`
      );
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (loading || !data) {
    return <SkeletonRelatedSpacesCard />;
  }

  return (
    <div className={styles.relatedSpacesCard}>
      <div className={styles.cardContainer}>
        <div className={styles.cardContentContainer}>
          {!!data.content.backgroundImage && (
            <img
              className={styles.cardThumbnail}
              src={imageUrl(data.content.backgroundImage)}
              alt="Related Space thumbnail"
            />
          )}
          <div>
            <p className={styles.title}>{data.content.name}</p>
            {!!data.content.description && (
              <p className={styles.description}>{data.content.description}</p>
            )}
          </div>
        </div>
        <div className={styles.cardActionsContainer}>
          <IconButton
            icon="external-link-alt"
            iconSet="FontAwesome"
            className={styles.cardActionIcon}
            onClick={handleOpenInNewTab}
          />
          <IconButton
            icon="trash-alt"
            iconSet="FontAwesome"
            className={styles.cardActionIconDelete}
            onClick={() => onDelete(relatedSpaceId)}
          />
        </div>
      </div>
      {Footer && <Footer content={data.content} />}
    </div>
  );
}
