import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { ErrorMessage } from 'components';
import { matchPath } from 'react-router';

import routes from 'lane-shared/config/routes';
import { ChannelsContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { errorCodes } from 'activate-errors';
import {
  signUpMutation,
  emitter,
  pause,
  getErrorCodes,
} from 'lane-shared/helpers';
import { useContentQuery } from 'lane-shared/hooks';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import i18n, { parseSupportedLocaleEnum } from 'localization';

import SignupValidateEmail from 'lane-web/src/pages/user/SignupV2/SignupValidateEmail';
import SignupDetailsLegacy from 'lane-web/src/pages/user/SignupV2/__legacy__/SignupDetailsLegacy';

import ForgotPassword from 'components/authV2/ForgotPassword';
import Login from 'components/authV2/Login';
import { Modal } from 'components/lds';

import styles from './AuthModal.scss';

function getContentId() {
  const pathname = window.location.pathname;
  const match = matchPath(pathname, { path: routes.content });

  return (match?.params as any)?.id;
}

type Props = {
  onLogin?: (signInData?: any) => void;
  onClose: () => void;
};

export default function AuthModal({ onLogin, onClose }: Props) {
  const [modalPage, setModalPage] = useState('login');
  const [signupError, setSignupError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    name,
    email,
    inviteId,
    password,
    emailOptIn,
    updateSignUp,
    companyName,
  } = useSignUpContext();
  const { primaryChannel } = useContext(ChannelsContext);

  const contentId = getContentId();
  const { content } = useContentQuery({
    forPublic: true,
    contentId,
  });

  const isEssensysProductEnabled = Boolean(
    content?.features?.filter(
      (f: any) => f.type === FeatureNameEnum.EssensysProduct
    )?.length
  );

  async function createAccount() {
    setLoading(true);
    setSignupError(null);

    try {
      const payload = {
        email,
        password,
        name,
        inviteId,
        building: primaryChannel,
        isPublic: true,
        emailOptIn,
        locale: parseSupportedLocaleEnum(i18n.language),
      };

      if (isEssensysProductEnabled) {
        (payload as any).metadata = {
          contentId,
          companyName,
        };
      }

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ email: string; password: strin... Remove this comment to see the full error message
      const { data } = await signUpMutation(payload);

      const { jti, token, warnings } = data.signUp;

      emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
        authToken: { jti, token },
        warnings,
      });

      updateSignUp({
        name: '',
        password: '',
        email: '',
      });

      onClose();
    } catch (err) {
      const errCodes = getErrorCodes(err);
      if (errCodes.includes(errorCodes.unverifiedEmailError.code)) {
        await pause();
        setModalPage('validate-email');
      }

      setSignupError(err);
    }

    setLoading(false);
  }

  async function loginSuccess(authToken: any) {
    onClose();

    if (onLogin) {
      onLogin(authToken);
    }
  }

  return (
    <Modal size="large" className={cx(styles.wrapper)} onClose={onClose} isOpen>
      <div className={styles.wrapper}>
        {modalPage === 'login' && (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'navigateToMagicLink' is missing in type ... Remove this comment to see the full error message
          <Login
            header="Log in"
            onLogin={loginSuccess}
            navigateToSignUp={() => setModalPage('signup')}
            navigateToForgotPassword={() => setModalPage('forgot-password')}
            className={styles.modalComponent}
          />
        )}
        {modalPage === 'signup' && (
          <>
            <ErrorMessage error={signupError} />
            <SignupDetailsLegacy
              onNext={createAccount}
              navigateToLogin={() => setModalPage('login')}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onNext: () => Promise<void>; navigateToLog... Remove this comment to see the full error message
              isModal
              loading={loading}
              isEssensysProductEnabled={isEssensysProductEnabled}
            />
          </>
        )}
        {modalPage === 'forgot-password' && (
          <ForgotPassword
            className={styles.modalComponent}
            onForgotPassword={() => setModalPage('login')}
            isModal
          />
        )}
        {modalPage === 'validate-email' && (
          <SignupValidateEmail onLogin={onClose} isModal />
        )}
      </div>
    </Modal>
  );
}
