import { useEffect } from 'react';

import { ChannelType } from '../../types/ChannelType';
import useChannelAnalytics from './useChannelAnalytics';

export function useTrackChannelDetails(channel: ChannelType) {
  const { trackChannelDetails } = useChannelAnalytics();

  useEffect(() => {
    if (channel?._id) {
      trackChannelDetails(channel);
    }
  }, [channel?._id]);
}
