import { useEffect } from 'react';

import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';

export default function useResetDateRangeForDayPass(
  unitType: any,
  value: any,
  onInput: any,
  defaultRange: any
) {
  // this behaviour is only for day passes
  const isUnitDays = unitType === ReservableUnitTypesEnum.Days;
  const isDateRangeSet = Boolean(value?.startDate && value?.endDate);

  useEffect(() => {
    if (!isUnitDays || isDateRangeSet) {
      return;
    }

    // set default value in the interaction state while using DateRangePicker
    onInput({
      startDate: defaultRange.startDate,
      endDate: defaultRange.endDate,
    });
  });
}
