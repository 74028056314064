import React from 'react';

import LayoutBlock from './blocks/LayoutBlock';

import styles from './blocks/styles.scss';

export default function ContentV1Renderer({ content, scale = 1 }: any) {
  return (
    <div className={styles.contentV1} style={{ fontSize: `${scale * 100}%` }}>
      <LayoutBlock parent={null} block={content.block} />
    </div>
  );
}
