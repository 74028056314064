import { useState } from 'react';

import { LaneType } from 'common-types';
import { deleteAttachment } from 'lane-shared/helpers/attachment/deleteAttachment';

type Props = {
  fetchAttachments: () => {};
};

export function useAttachmentDelete({ fetchAttachments }: Props) {
  const [deleting, setDeleting] = useState(false);
  async function deleteAttachmentHandler(attachmentId: LaneType.UUID) {
    setDeleting(true);
    const attachment = await deleteAttachment({
      attachmentId,
    });
    if (attachment.deletedAt) {
      fetchAttachments();
    }
    setDeleting(false);
  }

  return {
    deleting,
    deleteAttachmentHandler,
  };
}
