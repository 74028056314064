import * as yup from 'yup';

export default yup.object().shape({
  _id: yup.string().required(),
  _order: yup
    .number()
    .min(0)
    .max(2 ** 32)
    .required(),
});
