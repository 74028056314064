import baseX from 'base-x';

const base16Alphabet = '0123456789abcdef';
const base62Alphabet =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const decodeBase16 = baseX(base16Alphabet).decode;
const encodeBase16 = baseX(base16Alphabet).encode;
const decodeBase62 = baseX(base62Alphabet).decode;
const encodeBase62 = baseX(base62Alphabet).encode;

export function base62to16(str: any) {
  return encodeBase16(decodeBase62(str));
}

export function base16to62(str: any) {
  return encodeBase62(decodeBase16(str));
}
