import i18next from 'i18next';
import * as yup from 'yup';

import { isValidPhoneNumber } from 'lane-shared/helpers/phoneNumbers';

export default function validateVisitor(
  translator: (key: string, params?: any) => string = i18next.t
) {
  const REQUIRED_MESSAGE = translator(
    'web.content.feature.visitorManagement.visitorInvite.form.requiredField'
  );

  const INVALID_PHONE_MESSAGE = translator(
    'web.content.feature.visitorManagement.visitorInvite.form.validPhone'
  );
  const INVALID_EMAIL_MESSAGE = translator(
    'web.content.feature.visitorManagement.visitorInvite.form.validEmail'
  );

  const emailValidator = yup.string().nullable().email(INVALID_EMAIL_MESSAGE);

  const emailRequiredValidator = yup
    .string()
    .email(INVALID_EMAIL_MESSAGE)
    .required(REQUIRED_MESSAGE);

  const firstNameValidator = yup.string().required(REQUIRED_MESSAGE);
  const lastNameValidator = yup.string().required(REQUIRED_MESSAGE);
  const companyValidator = yup.string().required(REQUIRED_MESSAGE);

  // TODO: CANADA/US is set as default, we need it so this can be changed for other countries
  const phoneRequiredValidator = yup
  .string()
  .required(REQUIRED_MESSAGE)
  .test('phone-required-valid', INVALID_PHONE_MESSAGE, value => {
    return value === "" || isValidPhoneNumber(value!, 'US');
  });
  const phoneValidator = yup
    .string()
    .optional()
    .test('phone-valid', INVALID_PHONE_MESSAGE, value => {
      return !value || isValidPhoneNumber(value, 'US');
    });

  return {
    emailValidator,
    emailRequiredValidator,
    firstNameValidator,
    lastNameValidator,
    companyValidator,
    phoneRequiredValidator,
    phoneValidator,
  };
}
