import React from 'react';

import { Query } from '@apollo/client/react/components';

import {
  useCurrentChannel,
  useFlag,
  useMultiLanguage,
} from 'lane-shared/hooks';
import { CONTENT_TYPES_WITH_DATE } from 'lane-shared/types/content/ContentTypeEnum';

import ErrorMessage from '../../../../../../components/general/ErrorMessage';
import Loading from '../../../../../../components/general/Loading';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

export default function ContentQuery({
  query,
  queryName,
  hasPlacements,
  channelId,
  page,
  perPage,
  selectedSort,
  selectedOrder,
  search,
  availableTypes,
  endDate,
  startDate,
  children,
  hideOnError,
  primaryId,
}: any) {
  const { translate } = useMultiLanguage();
  const currentChannel = useCurrentChannel();
  const isMLSEnabledOnCurrentChannel =
    currentChannel?.settings?.multiLanguageEnabled;
  const isMLSSearchEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportSearch,
    false
  );
  const variables = {
    channelId,
    pagination: {
      start: page * perPage,
      perPage,
    },
    search: {
      sortBy: {
        key: selectedSort,
        dir: selectedOrder,
      },
      type: {
        any: [...availableTypes],
      },
    },
  };

  if (queryName === 'draftContentOnChannel') {
    (variables.search as any).sortBy = {
      key: '_updated',
      dir: 'desc',
    };
  } else if (
    CONTENT_TYPES_WITH_DATE.some(type => availableTypes.includes(type))
  ) {
    (variables.search as any).liveDate = {
      type: 'lte',
      value: endDate,
    };
    (variables.search as any).unpublishDate = {
      type: 'gte',
      value: startDate,
    };
  }

  if (search) {
    if (isMLSSearchEnabled && isMLSEnabledOnCurrentChannel) {
      (variables.search as any).name_l10n = {
        type: 'like',
        value: search,
      };
    } else {
      (variables.search as any).name = {
        type: 'like',
        value: search,
      };
    }
  }

  return (
    <Query fetchPolicy="network-only" query={query} variables={variables}>
      {({ data, error, loading }: any) => {
        if (error) {
          return hideOnError ? null : <ErrorMessage error={error} />;
        }

        if (loading && !data?.[queryName]) {
          return <Loading />;
        }

        const contents = translate({
          model: data[queryName].items || [],
          columns: ['name', 'description', 'subtitle'],
        });

        // simple hash table to remember if we've put this content
        // in a bucket
        const contentPlaced = {};
        const placements = {
          here: [],
          groupRole: {},
          channel: {},
        };

        if (hasPlacements) {
          contents.forEach((content: any) => {
            // Content placements may be of interest to this channel or not.
            // Check to see if a content placement matches this channel as
            // the origin.  if it does, just put it in the here category.
            // (i.e. this content was placed on this channel, but it was also
            // created from this channel).
            //
            // if a placement is for this channel AND the content comes from
            // another channel, that is of interest to show here.
            //
            // if a placement is not for this channel, we aren't interested in
            // displaying that here.

            if (content.placements) {
              content.placements.forEach((placement: any) => {
                if (placement.channel) {
                  // things that are placed on this channel, and are from
                  // this channel.
                  if (
                    placement.channel._id === channelId &&
                    content.channel._id === channelId &&
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    !contentPlaced[content._id]
                  ) {
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    contentPlaced[content._id] = true;
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                    placements.here.push(content);
                    return;
                  }

                  // things placed to this channel, from another channel.
                  if (
                    placement.channel._id === channelId &&
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    !contentPlaced[content._id]
                  ) {
                    // track where this other content came from.
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    if (!placements.channel[content.channel._id]) {
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      placements.channel[content.channel._id] = [];
                    }

                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    contentPlaced[content._id] = true;
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    placements.channel[content.channel._id].push(content);
                    return;
                  }

                  const pageVisibleOnlyOnTargetedChannels =
                    primaryId === placement.channel._id ||
                    primaryId === content.channel._id;
                  // things placed to other channels, from this channel
                  if (
                    placement.channel._id !== channelId &&
                    content.channel._id === channelId &&
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    !contentPlaced[content._id] &&
                    pageVisibleOnlyOnTargetedChannels
                  ) {
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    contentPlaced[content._id] = true;
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                    placements.here.push(content);
                    return;
                  }
                }

                // todo: there is a potential problem here where content was placed
                // on a groupRole from ANOTHER channel. Ths will still list it here
                // we should cross reference against the list of groupRoles this
                // channel actually has.
                if (placement.groupRole) {
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  if (!placements.groupRole[placement.groupRole._id]) {
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    placements.groupRole[placement.groupRole._id] = [];
                  }

                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  placements.groupRole[placement.groupRole._id].push(content);
                }

                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                if (placement.user?._id && !contentPlaced[content._id]) {
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  contentPlaced[content._id] = true;
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                  placements.here.push(content);
                }
              });
            } else {
              // no placements on this content? Then we should still show it.
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
              placements.here.push(content);
            }
          });
        } else {
          placements.here = contents;
        }

        // put into groups.
        return children({
          loading: data.loading,
          contents,
          placements,
          pageInfo: data[queryName].pageInfo,
        });
      }}
    </Query>
  );
}
