import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import {
  getMenuFeatureItemConfiguredPrice,
  isMenuFeatureOrderItemValid,
} from 'lane-shared/helpers/features';
import { imageUrl } from 'lane-shared/helpers/formatters';

import QuantityInput from '../../form/QuantityInput';
import TextArea from '../../form/TextArea';
import Button from '../../general/Button';
import ModalBackground from '../../general/ModalBackground';
import MenuItemOptionList from './MenuItemOptionList';

import styles from './MenuOrderEnterItemModal.scss';

export default function MenuOrderEnterItemModal({
  className,
  style,
  loading,
  disabled,
  isOpen,
  onClose,
  item,
  orderItem,
  currencyFormat,
  onUpdateOrderItem,
  onAddToCart,
}: any) {
  const { t } = useTranslation();
  const price = getMenuFeatureItemConfiguredPrice(item, orderItem);
  const quantity = parseInt(orderItem.quantity, 10) || 0;
  const priceText = currencyFormat((price * quantity) / CURRENCY_CONVERSION);

  return (
    <ModalBackground
      onClose={onClose}
      isOpen={isOpen}
      className={styles.background}
    >
      <div
        className={cx(styles.MenuOrderEnterItemModal, className)}
        style={style}
        data-cy="itemModal"
      >
        {item.image && (
          <div
            className={styles.hero}
            style={{ backgroundImage: `url(${imageUrl(item.image)})` }}
          />
        )}
        <div className={styles.inner}>
          <h1>{item.name}</h1>
          <p>{item.description}</p>
          <div className={styles.optionLists}>
            {item.options.map((optionList: any) => (
              <MenuItemOptionList
                disabled={disabled}
                loading={loading}
                key={optionList._id}
                optionList={optionList}
                currencyFormat={currencyFormat}
                orderOptionList={orderItem.options.find(
                  (orderOptionList: any) =>
                    orderOptionList._id === optionList._id
                )}
                onOrderOptionListUpdate={(newOrderOptionList: any) => {
                  const ix = orderItem.options.findIndex(
                    (orderOptionList: any) =>
                      orderOptionList._id === newOrderOptionList._id
                  );
                  orderItem.options[ix] = newOrderOptionList;

                  onUpdateOrderItem({ options: orderItem.options });
                }}
              />
            ))}
          </div>

          <h2>Notes</h2>
          <TextArea
            className={styles.textArea}
            maxLength={80}
            value={orderItem.notes}
            placeholder={t('e.g. special requests')}
            onChange={notes => onUpdateOrderItem({ notes })}
            showLengthIndicator
          />

          <div className={styles.quantityWrapper}>
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ classNam... Remove this comment to see the full error message */}
            <QuantityInput
              className={styles.quantity}
              min={item.minQuantity}
              max={item.maxQuantity}
              quantity={quantity}
              onChange={quantity => onUpdateOrderItem({ quantity })}
            />
          </div>
        </div>

        <Button
          className={styles.addToCart}
          loading={loading}
          variant="contained"
          disabled={disabled || !isMenuFeatureOrderItemValid(item, orderItem)}
          onClick={onAddToCart}
          dataCy="addToCart"
        >
          {t('Add to cart')} {priceText}
        </Button>
      </div>
    </ModalBackground>
  );
}
