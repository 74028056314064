import React from 'react';

import { useTranslation } from 'react-i18next';

import { M } from 'components/typography';

interface TableColumn {
  title: string;
  dataIndex: string;
  key: string;
  disableSorting: boolean;
}

export function useChargeCodesTableColumns(): TableColumn[] {
  const { t } = useTranslation();
  const chargeCodeTableColumns: any = [
    {
      key: 'chargeCode',
      header: t('abp.chargeCodes.chargeCode'),
      renderCell: renderStringCell,
      disableSorting: false,
    },
    {
      key: 'glCode',
      header: t('abp.chargeCodes.glCode'),
      renderCell: renderStringCell,
      disableSorting: false,
    },
    {
      key: 'description',
      header: t('abp.chargeCodes.description'),
      renderCell: renderStringCell,
      disableSorting: true,
    },
  ];

  return chargeCodeTableColumns;
}

function renderStringCell(cell: string) {
  return <M>{cell}</M>;
}
