import { parseDateTime } from '../../../helpers/dates';
import { explodeFeatures } from '../../../helpers/features';
import { ContentType } from '../../../types/content/Content';
import useReservableAvailabilityByRange from '../../useReservableAvailabilityByRange';

type Props = {
  content?: ContentType | null;
  timeZone?: string;
  startDate?: Date;
  endDate?: Date;
};

export default function useUnavailableDatesForContent({
  content,
  timeZone,
  startDate,
  endDate,
}: Props) {
  const {
    unavailableDateRanges: reservableUnavailableDateRanges,
  } = useReservableAvailabilityByRange({
    contentId: content?._id,
    startDate,
    endDate,
  });

  const unavailableDateRanges = reservableUnavailableDateRanges.map(
    ({ interval }) => interval
  );
  const { timeAvailabilityFeature } = explodeFeatures(content?.features);

  const startDateTime = parseDateTime(startDate)!;
  (timeAvailabilityFeature?.unavailableDates || []).forEach(({ dateRange }) => {
    if (!dateRange?.startDate || !dateRange?.endDate) {
      return;
    }

    const _endDate = parseDateTime(dateRange.endDate, timeZone)!;
    if (_endDate <= startDateTime) {
      return;
    }

    unavailableDateRanges.push({
      endDate: _endDate.toJSDate(),
      startDate: parseDateTime(dateRange.startDate, timeZone)!.toJSDate(),
    });
  });

  return {
    unavailableDateRanges,
  };
}
