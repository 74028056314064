import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

/**
 * A special type that refers just to other keys (i.e. field names, property names) in this object.
 */
export class KeyType extends StringTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'Key';

  get default(): any {
    return '';
  }

  friendlyName = 'Key';

  contexts = [TypeContextEnum.Block];

  superUserOnly = true;

  buildSchema() {
    return yup.string().nullable();
  }
}

const definition = new KeyType();

Types.addBaseType(definition);
export default definition;
