import { ContentType } from '../../../../types/content/Content';
import { ShopifyBurstInteractionType } from '../../../../types/integrations/ShopifyBurst';

export default function fromV1ToV2SchemaMapper({
  interactionData,
  questionnaireContent,
}: {
  interactionData: Partial<ShopifyBurstInteractionType>;
  questionnaireContent: ContentType;
}): Partial<ShopifyBurstInteractionType> {
  const { questionnaire, specialRequest } = interactionData;

  const teamRegionDataField = questionnaireContent.data?.teamRegion;
  const groupNameDataField = questionnaireContent.data?.groupName;
  const teamRegionOptions = teamRegionDataField?.validators?.find(
    v => v.name === 'In'
  )?.value;
  const groupNameOptions = groupNameDataField?.validators?.find(
    v => v.name === 'In'
  )?.value;
  const teamRegionSelectedOption = teamRegionOptions?.find(
    (option: any) => option.name === questionnaire?.teamRegion
  );
  const groupNameSelectedOption = groupNameOptions?.find(
    (option: any) => option.name === questionnaire?.groupName
  );

  return {
    ...interactionData,
    questionnaireInteraction: interactionData.questionnaire
      ? {
          data: {
            ...interactionData.questionnaire,
            groupName: groupNameSelectedOption || undefined,
            teamRegion: teamRegionSelectedOption || undefined,
          },
          state: {},
          features: {
            UseCompanyPermissions: {},
          },
        }
      : undefined,
    specialRequestInteraction: specialRequest
      ? {
          data: {
            specialRequest,
          },
          state: {},
          features: {
            UseCompanyPermissions: {},
          },
        }
      : undefined,
    // schemaVersion v2 ensures that we do not run this mapper second time on already mapped data
    schemaVersion: 2,
  };
}
