import React from 'react';

import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import {
  explodePresetContentFilters,
  getChangedFilters,
} from 'lane-shared/helpers/content';
import { FloorMapType } from 'lane-shared/helpers/integrations/FloorMaps/types/FloorMap';
import useSectionSearchOptionsMenu from 'lane-shared/hooks/useSectionSearchOptionsMenu';
import { PresetContentFilter } from 'lane-shared/types/filters/PresetContentFilter';
import { PresetContentFilterInterface } from 'lane-shared/types/filters/PresetContentFilterInterface';
import { PresetContentSort } from 'lane-shared/types/filters/PresetContentSort';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';
import SectionType from 'lane-shared/types/sections/SectionType';

import Chip from 'components/general/Chip';
import SortingSelector from 'components/integrations/FloorMaps/SortingSelector';
import { filtersOnPills } from 'components/integrations/FloorMaps/constants';
import DateFilter from 'components/integrations/FloorMaps/filters/DateFilter';
import FloorFilter from 'components/integrations/FloorMaps/filters/FloorFilter';
import TypeOfSpaceFilter from 'components/integrations/FloorMaps/filters/TypeOfSpaceFilter';
import getNumberOfAdditionalFiltersChanged from 'components/integrations/FloorMaps/helpers/getNumberOfAdditionalFiltersChanged';

import styles from './FloorMapFilterBar.scss';

interface Props {
  searchOptions?: SearchOptions;
  initialSearchOptions?: SearchOptions;
  floorMapItems: {
    label: string;
    value: string;
  }[];
  filters?: PresetContentFilterInterface[];
  sortings?: PresetContentSort[];
  selectFloor: (floorId: string) => void;
  selectedFloor?: FloorMapType;
  timeZone?: string;
  sections?: SectionType[];
  selectSections: (ids: LaneType.UUID[]) => void;
  onOpenMoreFilters: () => void;
  onSearchOptionsUpdated: (update: Partial<SearchOptions>) => void;
  onApplySearchOptions: () => void;
  loading?: boolean;
}

export default function FloorMapFilterBar({
  onOpenMoreFilters,
  onSearchOptionsUpdated,
  onApplySearchOptions,
  floorMapItems,
  searchOptions,
  initialSearchOptions,
  timeZone,
  filters,
  sortings,
  selectFloor,
  selectedFloor,
  sections,
  selectSections,
  loading,
}: Props) {
  const { t } = useTranslation();
  const { filterTimeRange } = explodePresetContentFilters(
    searchOptions?.filters
  );

  const { timeRangeChangeHandler } = useSectionSearchOptionsMenu({
    searchOptions,
    onSearchOptionsUpdated,
  });

  const numberOfChangedAdditionalFilters = getNumberOfAdditionalFiltersChanged(
    searchOptions,
    initialSearchOptions
  );

  const changedFilters = getChangedFilters(searchOptions, initialSearchOptions);
  const hasAdditionalFilters =
    !loading &&
    Boolean(
      filters?.filter(filter => !filtersOnPills.includes(filter.type)).length ||
        searchOptions?.metatagFilters?.length
    );

  function sortingChangeHandler(orderBy: PresetContentSort) {
    onSearchOptionsUpdated({
      sorts: [orderBy],
    });
    onApplySearchOptions();
  }

  return (
    <div className={styles.filterBar}>
      {sortings?.length ? (
        <SortingSelector
          selected={Boolean(searchOptions?.sorts?.length)}
          selectedSorting={searchOptions?.sorts?.[0]}
          onChange={sortingChangeHandler}
          sortings={sortings}
        />
      ) : null}
      <FloorFilter
        selected
        selectedIcon={null}
        selectedFloor={selectedFloor}
        onChange={selectFloor}
        items={floorMapItems}
      />
      {filters?.find(
        item => item.type === PresetContentFilter.FeatureReservableAvailableDays
      ) &&
        filterTimeRange?.startDate && (
          <DateFilter
            selected={changedFilters.includes(
              PresetContentFilter.FeatureReservableAvailableDays
            )}
            selectedIcon={null}
            timeZone={timeZone}
            startDate={filterTimeRange?.startDate}
            endDate={filterTimeRange?.endDate}
            onChange={timeRangeChangeHandler}
            onApplySearchOptions={onApplySearchOptions}
          />
        )}
      {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message */}
      {sections && !sections.includes(null) ? (
        <TypeOfSpaceFilter
          items={sections?.map(item => ({
            value: item._id,
            label: item.name,
          }))}
          selectedIcon={null}
          selectedFloor={selectedFloor}
          onChange={selectSections}
        />
      ) : null}
      {hasAdditionalFilters && (
        <Chip
          onClick={onOpenMoreFilters}
          selected={!!numberOfChangedAdditionalFilters}
          selectedIcon={null}
          label={
            numberOfChangedAdditionalFilters
              ? t('More filters ({{numberOfFilters}})', {
                  numberOfFilters: numberOfChangedAdditionalFilters,
                })
              : t('More filters')
          }
          deleteIcon={<div className={styles.pseudoIcon} />}
        />
      )}
    </div>
  );
}
