import React from 'react';

import cx from 'classnames';

import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import VisitorFieldRadioGroup from '../VisitorFieldRadioGroup/VisitorFieldRadioGroup';
import FeatureOptionToggle from 'components/renderers/features/components/FeatureOptionToggle';
import styles from './VisitorFieldSettings.scss';
import { useTranslation } from 'react-i18next';

export default function VisitorFieldSettings({
  feature,
  settings,
  className,
  style,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  settings: VisitorManagementFeatureProperties;
  onFeatureUpdated: (
    feature: Partial<VisitorManagementFeatureProperties>
  ) => void;
  className?: string;
  style?: React.CSSProperties;
}) {
  const { t } = useTranslation();
  const visitorInviteBulkUploadFlag = useFlag(
    FeatureFlag.VisitorInviteBulkUpload,
    false
  );

  if (!feature.properties) return null;

  return (
    <div className={cx(styles.visitorFieldSettings, className)} style={style}>
      <div className={styles.radioGroupContainer}>
        {visitorInviteBulkUploadFlag && (
          <FeatureOptionToggle
            title={t(
              'web.admin.content.features.visitor.management.general.visitor.information.bulk.import'
            )}
            description={t(
              'web.admin.content.features.visitor.management.general.visitor.information.bulk.import.description'
            )}
            onChange={value =>
              onFeatureUpdated({
                ...settings,
                bulkImportSetting: value,
              })
            }
            isExpanded={settings.bulkImportSetting}
            dataCy="bulkImportToggle"
          />
        )}
        <VisitorFieldRadioGroup
          fieldProperties={feature.properties.nameSetting!}
          onChange={name =>
            onFeatureUpdated({
              ...settings,
              nameSetting: name,
            })
          }
          value={settings.nameSetting}
        />
        <VisitorFieldRadioGroup
          fieldProperties={feature.properties.emailSetting!}
          onChange={email =>
            onFeatureUpdated({
              ...settings,
              emailSetting: email,
            })
          }
          value={settings.emailSetting}
        />
        <VisitorFieldRadioGroup
          fieldProperties={feature.properties.phoneSetting!}
          onChange={phone =>
            onFeatureUpdated({
              ...settings,
              phoneSetting: phone,
            })
          }
          value={settings.phoneSetting}
        />
        <VisitorFieldRadioGroup
          fieldProperties={feature.properties.companySetting!}
          onChange={company =>
            onFeatureUpdated({
              ...settings,
              companySetting: company,
            })
          }
          value={settings.companySetting}
        />
      </div>
    </div>
  );
}
