import { DateTime } from 'luxon';

import { parseDateTime } from 'lane-shared/helpers/dates';

import escapeRegExp from '../escapeRegExp';

/**
 * Filters content that is either starting or ending today.
 *
 * @param content
 * @param search
 * @returns {boolean}
 */
export default function filterToday(content: any, search = '') {
  const today = DateTime.local();
  const startDate = parseDateTime(content.startDate) || today;
  const endDate = parseDateTime(content.endDate) || today;

  if (!startDate.hasSame(today, 'day') || !endDate.hasSame(today, 'day')) {
    return false;
  }

  if (!search) {
    return true;
  }

  return (
    new RegExp(escapeRegExp(search), 'gi').test(content.name) ||
    new RegExp(escapeRegExp(search), 'gi').test(content.description)
  );
}
