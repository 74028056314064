/**
 * Because of a legacy import from MongoDB, and the need to keep any
 * existing URLs still valid that maybe in the wild, we need to support
 * two kinds of Base62 ids from the client side. But luckily, they will
 * be a specific length. So there will be no problems converting between
 * many formats.
 *
 */
const UUID = '00000000-0000-0000-0000-000000000000'; // 36
const UUID_REGEX =
  '^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$';
const UUID_NAKED = UUID.replace(/-/gi, ''); // 32
const UUID_BASE62 = '0000000000000000000000'; // 22
const UUID_PARTS = UUID.split('-');
const MONGO_ID = '000000000000000000000000'; // 24
const MONGO_ID_BASE62 = '0000000000000000'; // 16

export {
  UUID,
  UUID_REGEX,
  UUID_NAKED,
  UUID_BASE62,
  UUID_PARTS,
  MONGO_ID,
  MONGO_ID_BASE62,
};
