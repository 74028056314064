import React from 'react';

import parseDateTime from '../../helpers/dates/parseDateTime';
import { MjmlRaw } from '../MjmlComponentsV2';

/**
 * Constructs a markup type for gmail and other email products that
 * support this format.
 * see https://developers.google.com/gmail/markup/reference/event-reservation#json-ld
 */
export default function EmailReservationSchema({
  id,
  userName,
  eventName,
  startDate,
  endDate,
  timeZone,
  locationName,
  qrCodeLink,
  interactionLink,
}: {
  id: string;
  userName: string;
  eventName: string;
  startDate: Date;
  endDate: Date;
  timeZone: string;
  locationName: string;
  qrCodeLink: string;
  contentLink: string;
  interactionLink: string;
}) {
  if (!startDate) {
    return null;
  }

  const _startDate = parseDateTime(startDate, timeZone);
  const _endDate = parseDateTime(endDate, timeZone);

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'EventReservation',
    reservationId: id,
    reservationStatus: 'http://schema.org/Confirmed',
    underName: {
      '@type': 'Person',
      name: userName,
    },
    modifyReservationUrl: interactionLink,
    ticketPrintUrl: interactionLink,
    ticketToken: qrCodeLink,
    reservationFor: {
      '@type': 'Event',
      name: eventName,
      startDate: _startDate?.toISO({ suppressMilliseconds: true }),
      endDate: _endDate?.toISO({ suppressMilliseconds: true }),
      location: {
        '@type': 'Place',
        name: locationName,
      },
    },
  };

  // At the moment, we are passing safe stuff in schema. But be careful when changing it!
  /* eslint-disable react/no-danger */
  return (
    <MjmlRaw>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </MjmlRaw>
  );
}
