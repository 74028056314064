import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';

const query = gql`
  query StripeConfig($paymentAccountId: UUID) {
    payments {
      stripe(paymentAccountId: $paymentAccountId) {
        _id
        publicKey
        clientId
      }
    }
  }
`;

export default function useStripeConfig(paymentAccountId?: string) {
  const { data, loading, error } = useQuery(query, {
    client: getClient(),
    variables: { paymentAccountId },
    fetchPolicy: 'cache-and-network',
  });

  const publicKey = data?.payments?.stripe?.publicKey;
  const clientId = data?.payments?.stripe?.clientId;

  return {
    loading,
    error,
    publicKey,
    clientId,
  };
}
