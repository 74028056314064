import { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export function useReservableTableViewQueryParams() {
  const [initialQueryParams, setInitialQueryParams] = useState<{
    search: string | null;
    startDate: string | null;
    endDate: string | null;
  } | null>(null);
  const location = useLocation();
  const history = useHistory();
  const hasInitialQueryParamsLoaded = initialQueryParams !== null;

  useEffect(() => {
    if (hasInitialQueryParamsLoaded) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search');
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');

    setInitialQueryParams({
      search,
      startDate,
      endDate,
    });
  }, [location.search, hasInitialQueryParamsLoaded]);

  const updateQueryParams = useCallback(
    ({
      search,
      startDate,
      endDate,
    }: {
      search: string;
      startDate: string;
      endDate: string;
    }) => {
      if (!hasInitialQueryParamsLoaded) {
        return;
      }

      const queryParams = new URLSearchParams({
        search,
        startDate,
        endDate,
      }).toString();

      history.replace(`${location.pathname}?${queryParams.toString()}`);
    },
    [hasInitialQueryParamsLoaded, history, location.pathname]
  );

  return {
    initialQueryParams,
    updateQueryParams,
  };
}
