import { ShopifyBurstInteractionType } from 'lane-shared/types/integrations/ShopifyBurst';

import { ContentType } from '../../../types/content/Content';
import fromV1ToV2SchemaMapper from './schemaVersionMappers/fromV1ToV2SchemaMapper';

/**
 * Maps burst interaction data from the version it has to the current version our app works with
 * @note: the idea to later on make this function call mappers incrementally once for each version
 *        it goes through starting from the version interaction has right now and calling mappers
 *        until the current contemporary version is reached.
 *        Example: interaction has schema version 1 and current version is 3 therefore we should
 *        call fromV1ToV2SchemaMapper, then fromV2ToV3SchemaMapper calling them dynamically.
 */
export default function upgradeOutdatedBurstInteractionData({
  interactionData,
  questionnaireContent,
}: {
  interactionData: Partial<ShopifyBurstInteractionType>;
  questionnaireContent: ContentType;
}): Partial<ShopifyBurstInteractionType> {
  // absence of schema version is considered as version 1
  if (!interactionData.schemaVersion) {
    return fromV1ToV2SchemaMapper({
      interactionData,
      questionnaireContent,
    });
  }
  return interactionData;
}
