import { DateTime, Zone } from 'luxon';

import { RECENT_DATE } from '../constants/dates';
import dateFormatter from './dateFormatter';

export function recentDate(
  date: Date | DateTime | string | undefined | null,
  timeZone?: string | Zone
) {
  return dateFormatter(date, RECENT_DATE, timeZone);
}
