import React from 'react';

import cx from 'classnames';

import {
  EssensysProductFeatureProperties,
  EssensysProductInteractionType,
} from 'lane-shared/types/features/EssensysProductFeatureProperties';

import { Dropdown } from 'components/form';

import { FeatureRendererPropsType } from '../FeatureRendererPropsType';
import FeatureAutoRenderer from '../components/FeatureAutoRenderer';
import FeatureRendererWrapper from '../components/FeatureRendererWrapper';
import EssensysOnlinePlanProduct from './EssensysOnlinePlanProduct';
import EssensysPointOfSaleProduct from './EssensysPointOfSaleProduct';

const ESSENSYS_PRODUCT_COMPONENT: {
  [key in EssensysProductInteractionType]: (
    props: FeatureRendererPropsType<EssensysProductFeatureProperties>
  ) => React.ReactElement;
} = {
  [EssensysProductInteractionType.OnlinePlan]: EssensysOnlinePlanProduct,
  [EssensysProductInteractionType.PointOfSale]: EssensysPointOfSaleProduct,
};

export default function EssensysProduct(
  props: FeatureRendererPropsType<EssensysProductFeatureProperties>
) {
  const { onFeatureUpdated, contentFeature } = props;
  const feature = contentFeature?.feature;

  if (!feature?.productInteractionType) {
    return <FeatureAutoRenderer {...props} />;
  }

  const items = Object.values(EssensysProductInteractionType).map(value => ({
    label: value,
    value,
  }));

  const EssensysProductComponent =
    ESSENSYS_PRODUCT_COMPONENT[feature.productInteractionType];

  return (
    <FeatureRendererWrapper
      className={cx(props.className)}
      style={props.style}
      feature={props.feature}
    >
      <Dropdown
        value={feature.productInteractionType}
        items={items}
        onValueChange={(value: EssensysProductInteractionType) => {
          onFeatureUpdated({ productInteractionType: value });
        }}
      />
      <EssensysProductComponent {...props} />
    </FeatureRendererWrapper>
  );
}
