import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Tags.scss';

type OwnProps = {
  tags: string[];
  selected?: string[];
  onClick?: (...args: any[]) => any;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof Tags.defaultProps;

// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
function Tags({ tags, selected, onClick, className, style }: Props) {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.Tags, className)} style={style}>
      {tags.map(tag => (
        <button
          key={tag}
          className={styles.tag}
          data-is-selected={selected && selected.includes(tag)}
          onClick={() => onClick(tag)}
        >
          {t(tag)}
        </button>
      ))}
    </div>
  );
}

Tags.defaultProps = {
  selected: [],
  onClick: () => {},
};

export default Tags;
