import { CodedError } from 'activate-errors';

function isCodedError(t: any): t is CodedError {
  return !!(t as CodedError).code;
}

/**
 * Does a few different checks to figure out if its this CodedError. Because
 * errors can be serialized/deserialized over the wire, we may lose some
 * information (i.e. err.code) when this happens. So we need to check multiple
 * locations
 */
export default function isThisError<T extends Error | CodedError>(
  err: any,
  // there is no TypeScript type to pass in an Class, just an instance of
  // a class.  so the best is a constructor that returns a CodedError...
  ErrorType: new () => T
): err is T {
  if (!err) {
    return false;
  }

  const errorToTest = new ErrorType();

  if (err.constructor === ErrorType) {
    return true;
  }

  // check to see if the name matches exactly
  if (err.name === errorToTest.name) {
    return true;
  }

  // check to see if the messages match exactly.
  if (err.message && err.message === errorToTest.message) {
    return true;
  }

  if (isCodedError(errorToTest) && isCodedError(err)) {
    // check to see if this error has a code, and it matches the ErrorType code
    if (err.code === errorToTest.code) {
      return true;
    }

    // check to see if the error code has been injected in the message.
    if (err.message && err.message.includes(errorToTest.code)) {
      return true;
    }
  }

  // check to see if includes was too soft.
  if (err.message && err.message.indexOf(errorToTest.message) >= 0) {
    return true;
  }

  return false;
}
