import { useEffect } from 'react';

import { useDebounce } from 'use-debounce';

import { getClient } from '../../../apollo';
import { updateDraftInteraction } from '../../../graphql/mutation';
import { getMessagesFromError } from '../../../helpers';
import { BurstWizardState } from '../../../types/integrations/ShopifyBurst';
import { ShopifyRsvpWizardState } from '../../../types/integrations/ShopifyRsvp';
import useToast from '../../topLevelComponents/useToast';

type Props = {
  contentId: string | undefined;
  wizardState: ShopifyRsvpWizardState | BurstWizardState;
};

async function saveDraftInteractionData(
  contentId: string,
  wizardState: ShopifyRsvpWizardState | BurstWizardState
): Promise<boolean> {
  const response = await getClient().mutate({
    mutation: updateDraftInteraction,
    variables: {
      interaction: wizardState,
      contentId,
    },
  });

  return Boolean(response.data.me.updateDraftInteraction);
}

const DEBOUNCE_THROTTLE = 500; // 500ms

export default function useShopifySaveDraftInteraction({
  contentId,
  wizardState,
}: Props) {
  const { showToast } = useToast();

  const [debouncedWizardState] = useDebounce(wizardState, DEBOUNCE_THROTTLE, {
    trailing: true,
  });

  function handleError(e: any) {
    const messages = getMessagesFromError(e);

    const title = 'An error occurred!';

    showToast({ title, description: messages.join('\n') });
  }

  async function doSave() {
    try {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await saveDraftInteractionData(contentId, {
        ...wizardState,
        currentStepIndex: 0,
      });
    } catch (err) {
      handleError(err);
    }
  }

  useEffect(() => {
    // there is a default schemaVersion
    if (Object.keys(wizardState.data).length <= 1) {
      return;
    }

    doSave();
  }, [debouncedWizardState]);
}
