import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Checkbox, Button } from 'design-system-web';
import styles from './defaultMessageGroups.scss';

type GroupItem = {
  title: string;
  description: string;
};

const groupList: GroupItem[] = [
  {
    title: 'Announcements',
    description:
      'This is reserved for Property Admins to post instructional messages for residents. Residents will not be able to post in this group.',
  },
  {
    title: 'Housing',
    description: 'Post housing related messages.',
  },
  {
    title: 'Help needed/offered',
    description: 'Help needed/offered.',
  },
  {
    title: 'Recommendation',
    description: 'Recommendation.',
  },
];

export function DefaultMessageGroups() {
  const [selectedGroups, setSelectedGroups] = useState<boolean[]>(
    groupList.map(() => true)
  );
  const { t } = useTranslation();

  const handleCheckboxChange = (index: number) => {
    setSelectedGroups(prevSelectedGroups => {
      const newSelectedGroups = [...prevSelectedGroups];
      newSelectedGroups[index] = !newSelectedGroups[index];
      return newSelectedGroups;
    });
  };

  return (
    <section className={styles.defaultMessageGroups}>
      <div className={styles.checkboxGroup}>
        {groupList.map((group, index) => (
          <Checkbox
            key={index}
            value={group.title}
            text={group.title}
            description={group.description}
            selected={selectedGroups[index]}
            onChange={() => handleCheckboxChange(index)}
            className={styles.checkboxRow}
          />
        ))}
      </div>
      <div className={styles.controlMenuBottom}>
        <Button onClick={() => {}}>
          {t(
            'web.admin.channel.settings.messagingAndMarketplace.defaultGroup.confirm'
          )}
        </Button>
        {/* TODO TM-18019: Implement onclick */}
      </div>
    </section>
  );
}
