export default function getMinutesFromSlider({
  sliderPosition,
  optionWidth,
  slotSize,
  minSlot = 0,
}: {
  sliderPosition: {
    left: number;
    width: number;
  };
  optionWidth: number;
  slotSize: number;
  minSlot: number;
}): {
  startMinutes: number;
  endMinutes: number;
} {
  const startMinutes =
    (sliderPosition.left / optionWidth) * slotSize + minSlot * slotSize || 0;

  const endMinutes =
    ((sliderPosition.left + sliderPosition.width) / optionWidth) * slotSize +
      minSlot * slotSize || 0;

  return {
    startMinutes,
    endMinutes,
  };
}
