import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const MicrosoftAzureDefinition: IntegrationDefinition = {
  hasCustomUI: false,
  name: IntegrationProviderEnum.MicrosoftAzure,
  friendlyName: 'Microsoft Azure',
  description: `Links this Channel to a Microsoft Azure tenant id so that users can auto-join this channel when they sign up with Microsoft OAuth`,
  category: IntegrationCategoryEnum.Authentication,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    tenantId: {
      friendlyName: 'Microsoft Azure Id',
      type: 'String',
    },
  },
  mobilePermissions: [],
};

export default MicrosoftAzureDefinition;
