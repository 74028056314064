import i18n from 'localization';

import { BaseType } from '../../types/baseTypes/TypeBase';
import { ArrayMinValidator } from './ArrayMin';

export function addArrayMinValidatorImpl(
  type: BaseType,
  validator: ArrayMinValidator
) {
  const displayName = i18n.t(`${type.getDisplayName()}`);
  const translatedValidationMessage = i18n.t(
    `{{name}} must have min {{value}} value(s).`,
    {
      value: validator.config?.value,
      name: displayName,
    }
  );
  type._arraySchema = type.arraySchema.min(
    validator.config?.value as number,
    translatedValidationMessage
  );
}
