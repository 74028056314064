import React from 'react';

import { Toggle, Tooltip } from 'components';
import { useTranslation } from 'react-i18next';

import styles from 'lane-web/src/components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type props = {
  channel: {
    settings: {
      hasWorkOrderCrossPropertyEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  t: (key: string, params?: any) => string;
  disabled: boolean;
  hasWorkOrderEnabled: boolean;
  isPhysicalBuilding: boolean;
  onHasWorkOrderEnabledUpdated: any;
};

export function WorkOrderToggle({
  channel,
  onChannelUpdated,
  disabled = false,
  hasWorkOrderEnabled,
  onHasWorkOrderEnabledUpdated,
  isPhysicalBuilding,
}: props) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.setting}>
        <Tooltip
          TooltipComponent={
            <p>{t('web.admin.channel.profile.settings.workOrders.toolTip')}</p>
          }
          placement="top"
          className={styles.tooltip}
        >
          <Toggle
            className={styles.toggle}
            dataCy="toggleWorkOrders"
            value={hasWorkOrderEnabled || false}
            disabled={disabled || !isPhysicalBuilding}
            onChange={enabled => {
              onChannelUpdated({
                settings: {
                  ...channel.settings,
                  hasWorkOrderServiceRequestsEnabled: enabled,
                  hasWorkOrderPreventiveMaintenanceEnabled: enabled,
                  hasWorkOrderEquipmentEnabled: enabled,
                },
              });
              onHasWorkOrderEnabledUpdated(enabled);
            }}
            text={t('web.content.feature.workOrder.toggleFeature.text')}
            description={t(
              'web.content.feature.workOrder.toggleFeature.description'
            )}
          />
        </Tooltip>
      </div>

      <div className={styles.setting}>
        <Toggle
          disabled={isPhysicalBuilding}
          className={styles.toggle}
          dataCy="workOrderCrossPropertyToggle"
          value={channel.settings.hasWorkOrderCrossPropertyEnabled || false}
          onChange={enabled =>
            onChannelUpdated({
              settings: {
                ...channel.settings,
                hasWorkOrderCrossPropertyEnabled: enabled,
              },
            })
          }
          text={t(
            'web.content.feature.workOrder.crossProperty.toggleFeature.title'
          )}
          description={t(
            'web.content.feature.workOrder.crossProperty.toggleFeature.description'
          )}
        />
      </div>
    </>
  );
}
