import { ContentQueryInteraction } from 'lane-shared/hooks/useContentQuery';

import { INTERACTION_CLOSED_STATES } from '../../constants/interactions';
import { parseDate } from '../../dates';

export default function filterPastMeetings(
  interactions: ContentQueryInteraction[]
) {
  return interactions.filter(meeting => {
    const endDate = parseDate(meeting.endDate);

    return (
      (endDate && endDate.getTime() < Date.now()) ||
      INTERACTION_CLOSED_STATES.includes(meeting.status)
    );
  });
}
