import { Primitive } from '../../../types/blocks/Primitive';

// a Meta block to allow a Property Input, shouldn't be added by end users.
const PropertyInputBlock: Primitive = {
  name: 'PropertyInputBlock',
  friendlyName: 'Property Input',
  isInput: true,
  isPrimitive: true,
  properties: {
    featureKey: {
      type: 'Key',
      showControl: false,
    },
    fieldKey: {
      type: 'Key',
      showControl: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    value: {
      type: 'Any',
    },
    isValid: {
      type: 'Boolean',
      default: true,
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    input: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default PropertyInputBlock;
