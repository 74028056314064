import React from 'react';
import { H5, H4, Icon, S } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME, FONT_AWESOME_SOLID } from 'lane-shared/helpers/constants/icons';

interface Props {
  channelName: string;
  confirmationNumber: string;
  date: string;
}

export function SubscriptionReceiptHeader({
  channelName,
  confirmationNumber,
  date,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center flex-col gap-y-2">
      <Icon
        set={ICON_SET_FONTAWESOME}
        type={FONT_AWESOME_SOLID}
        name="checkCircle"
        className="text-text-success h-7"
        size='large'
      />
      <div className='flex flex-col items-center'>
        <H4>{t('abp.receipt.subscription.header.subscriptionConfirmed')}</H4>
        <H5 className="text-text-primary font-medium">{channelName}</H5>
      </div>
      <div className='flex flex-row gap-x-2'>
        <S className='text-text-subdued'>
          {t('abp.receipt.subscription.header.confirmationNumber', {
            confirmationNumber,
          })}
        </S>
        <S className='text-text-subdued'>
          {t('abp.receipt.subscription.header.purchased', {
            purchasedDate: date,
          })}
        </S>
      </div>
    </div>
  );
}
