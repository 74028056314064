import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';

export const MENU_FEATURE_MAX_OPTIONS = 25;

const properties: PropertiesInterface = {
  _order: {
    friendlyName: 'Order',
    description: 'The Order this should appear',
    type: 'Number',
    default: 0,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  name: {
    friendlyName: 'Option List Name',
    description:
      'web.content.features.menuFeatureItemOptionList.name.description',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 80,
      },
      {
        name: 'Min',
        value: 2,
      },
    ],
  },
  maxChoices: {
    friendlyName: 'Maximum Choices',
    description:
      'What is the maximum number of choices the user can select from this list?',
    type: 'Number',
    default: 1,
    validators: [
      {
        name: 'Max',
        value: MENU_FEATURE_MAX_OPTIONS,
      },
      {
        name: 'Min',
        value: 1,
      },
    ],
  },
  minChoices: {
    friendlyName: 'Minimum Choices',
    description:
      'What is the minimum number of choices the user must make from this list?',
    type: 'Number',
    default: 0,
    validators: [
      {
        name: 'Max',
        value: MENU_FEATURE_MAX_OPTIONS,
      },
      {
        name: 'Min',
        value: 0,
      },
    ],
  },
  options: {
    friendlyName: 'Menu Item Options',
    description: 'The options for this list.',
    type: 'MenuFeatureItemOption',
    isArray: true,
    validators: [
      {
        name: 'ArrayMax',
        value: MENU_FEATURE_MAX_OPTIONS,
      },
    ],
  },
};

export class MenuFeatureItemOptionListType extends ObjectTypeBase {
  name = 'MenuFeatureItemOptionList';

  friendlyName = 'Option List';

  contexts = AllTypeContexts;

  get example() {
    return {
      _id: uuid(),
      order: 0,
      name: 'Select a size',
      maxChoices: 1,
      minChoices: 1,
      options: [
        {
          name: 'Large',
          price: 150,
        },
        {
          name: 'Medium',
          price: 50,
        },
        {
          name: 'Small',
          price: 0,
        },
      ],
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      order: 0,
      name: '',
      maxChoices: 1,
      minChoices: 0,
      options: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new MenuFeatureItemOptionListType();

Types.addType(definition);
export default definition;
