import { DateTime } from 'luxon';

import parseDateTime from './parseDateTime';

type UnsafeDateRangeType =
  | {
      startDate?: Date | DateTime | string | null | undefined;
      endDate?: Date | DateTime | string | null | undefined;
    }
  | undefined
  | null;

export default function areDateRangesEqual(
  rangeA: UnsafeDateRangeType,
  rangeB: UnsafeDateRangeType
): boolean {
  if (!rangeA || !rangeB) {
    return false;
  }

  if (
    !rangeA.startDate ||
    !rangeA.endDate ||
    !rangeB.startDate ||
    !rangeB.endDate
  ) {
    return false;
  }

  const rangeAStart = parseDateTime(rangeA.startDate);
  const rangeBStart = parseDateTime(rangeB.startDate);
  const rangeAEnd = parseDateTime(rangeA.endDate);
  const rangeBEnd = parseDateTime(rangeB.endDate);

  if (!rangeAStart || !rangeBStart || !rangeAEnd || !rangeBEnd) {
    return false;
  }

  return rangeAStart.equals(rangeBStart) && rangeAEnd.equals(rangeBEnd);
}
