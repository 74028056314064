import { useEffect } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import {
  PublicUserFragment,
  ThemeFragment,
  FullContentTemplateFragment,
} from '../graphql/fragments';

export const templateQuery = gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullContentTemplateFragment}

  query useContentTemplateQuery($templateId: UUID!) {
    contentTemplate(_id: $templateId) {
      ...FullContentTemplateFragment
    }
  }
`;

export default function useContentTemplateQuery({ templateId }: any) {
  const [fetchTemplate, { data, error, loading, refetch }] = useLazyQuery(
    templateQuery,
    {
      client: getClient(),
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (templateId) {
      fetchTemplate({
        variables: {
          templateId,
        },
      });
    }
  }, [templateId]);

  const template = data?.contentTemplate || null;

  return {
    template,
    loading,
    error,
    refetch,
  };
}
