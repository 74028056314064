import React, { useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  constructMenuFeatureOrderItem,
  getMenuFeatureItemConfiguredPrice,
} from 'lane-shared/helpers/features';
import { imageUrl } from 'lane-shared/helpers/formatters';

import MenuOrderEnterItemModal from './MenuOrderEnterItemModal';

import styles from './MenuOrderItem.scss';

export default function MenuOrderItem({
  className,
  style,
  disabled,
  loading,
  item,
  currencyFormat,
  onItemAdded,
  editMode,
}: any) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [orderItem, setOrderItem] = useState(
    constructMenuFeatureOrderItem(item)
  );

  function onAddToCart() {
    onItemAdded(orderItem, item);
    setIsOpen(false);
    window.Toast.show(t('Added {{name}}', { name: item.name }));
    setOrderItem(constructMenuFeatureOrderItem(item));
  }

  function updateOrderItem(props: any) {
    setOrderItem({
      ...orderItem,
      ...props,
    });
  }

  // let's show the price as configured.
  const price = getMenuFeatureItemConfiguredPrice(item, orderItem);

  return (
    <>
      <div
        className={cx(styles.MenuOrderItem, className)}
        style={style}
        tabIndex={0}
        role="button"
        onClick={() => {
          if (editMode || item.isAvailable) {
            setIsOpen(true);
          }
        }}
      >
        <div className={styles.top}>
          <div className={styles.info}>
            <h1>{item.name}</h1>
            <p>{item.description}</p>
            <label>{currencyFormat(price / 100)}</label>
          </div>

          {!item.isAvailable && (
            <div className={styles.soldOut}>{t('Not Available')}</div>
          )}

          {item.image && (
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${imageUrl(item.image)})` }}
            />
          )}
        </div>
      </div>
      <MenuOrderEnterItemModal
        currencyFormat={currencyFormat}
        loading={loading}
        disabled={disabled}
        isOpen={isOpen}
        editMode={editMode}
        item={item}
        orderItem={orderItem}
        onClose={() => setIsOpen(false)}
        onAddToCart={onAddToCart}
        onUpdateOrderItem={updateOrderItem}
      />
    </>
  );
}
