import * as yup from 'yup';

import { validatePasswordV2 } from './validatePassword';

const emailValidation = yup.object().shape({
  name: yup.string().trim().required(),
  loginType: yup.string().required(),
  loginKey: yup.string().trim().email().required(),
  password: validatePasswordV2.notOneOf(
    [yup.ref('loginKey')],
    'Your password cannot be the same as your username or email address'
  ),
});

export default emailValidation;
