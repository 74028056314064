/**
 * Possible filters that sections or content blocks can turn on to allow
 * filtering of the results.  These filters are pre-built UI/UX that will
 * appear when these filters are added.
 *
 * I.e. allow a section to have a location filter.
 */
export enum PresetContentFilter {
  // allows the end user to filter a dynamic section by channel.
  ByChannel = 'ByChannel',
  // allows the end user to filter a dynamic section by locations of channel.
  ByChannelLocations = 'ByChannelLocations',
  // show a search box to the end user.
  SearchBox = 'SearchBox',
  Location = 'Location',
  // applies to Reservable and TimeAvailability
  AvailableNow = 'AvailableNow',
  FeaturePaymentPrice = 'FeaturePaymentPrice',
  FeatureQuantityRemaining = 'FeatureQuantityRemaining',
  // see packages/lane-shared/renderers/v5/features/Reservable.ts
  FeatureReservableAvailableMinutes = 'FeatureReservableAvailableMinutes',
  FeatureReservableAvailableDays = 'FeatureReservableAvailableDays',
  FeatureReservableAvailableWeeks = 'FeatureReservableAvailableWeeks',
  FeatureReservableAvailableMonths = 'FeatureReservableAvailableMonths',
  ByEventDate = 'ByEventDate',
}

export const PresetContentFilterFriendlyName = {
  [PresetContentFilter.SearchBox]: 'Filter by Name',
  [PresetContentFilter.ByChannel]: 'Filter by Channel',
  [PresetContentFilter.ByChannelLocations]: 'Filter by Channel Locations',
  [PresetContentFilter.Location]: 'Filter by location',
  [PresetContentFilter.AvailableNow]: 'Filter by available now',
  [PresetContentFilter.FeaturePaymentPrice]: 'Filter by price',
  [PresetContentFilter.FeatureQuantityRemaining]: 'Filter by quantity',
  [PresetContentFilter.FeatureReservableAvailableMinutes]:
    'Filter by hourly time window',
  [PresetContentFilter.FeatureReservableAvailableDays]:
    'Filter by daily time window',
  [PresetContentFilter.FeatureReservableAvailableWeeks]:
    'Filter by weekly time window',
  [PresetContentFilter.FeatureReservableAvailableMonths]:
    'Filter by monthly time window',
  [PresetContentFilter.ByEventDate]: 'Filter by event date',
};

export const PresetContentFilterDescription = {
  [PresetContentFilter.SearchBox]:
    'Shows a search box to the user to filter by searching for text.',
  [PresetContentFilter.ByChannel]:
    'Allows users to filter by the Channel content originated from.',
  [PresetContentFilter.ByChannelLocations]:
    'Allows users to filter by the locations of the Channels. (i.e. search by City)',
  [PresetContentFilter.Location]:
    'Allows users to filter by location, setting distance from a location and only showing results that are within that distance.',
  [PresetContentFilter.AvailableNow]:
    'Allows users to show results that are only available now at this time, this applies to things that have a limited Time Availability or things that have a Reservable feature enabled.',
  [PresetContentFilter.FeaturePaymentPrice]:
    'Allows users to show results that are within a certain price. This applies to things that have the Payment feature enabled. ',
  [PresetContentFilter.FeatureQuantityRemaining]:
    'Allows users to show results that have a quantity remaining. This applies to things with the Quantity feature enabled.',
  [PresetContentFilter.FeatureReservableAvailableMinutes]:
    'Allows users to select an hourly time window and only show results that are available during that time.  This applies to things with the Reservable feature enabled that have set their time unit to minutes or hourly.',
  [PresetContentFilter.FeatureReservableAvailableDays]:
    'Allows users to select a daily time window and only show results that are available during that time.  This applies to things with the Reservable feature enabled that have set their time unit to days.',
  [PresetContentFilter.FeatureReservableAvailableWeeks]:
    'Allows users to select a weekly time window and only show results that are available during that time.  This applies to things with the Reservable feature enabled that have set their time unit to weeks.',
  [PresetContentFilter.FeatureReservableAvailableMonths]:
    'Allows users to select a monthly time window and only show results that are available during that time.  This applies to things with the Reservable feature enabled that have set their time unit to months.',
  [PresetContentFilter.ByEventDate]:
    'Allows users to filter based on the Event Date set on the info step.',
};
