import pause from './pause';

/**
 * Waits for random ms before returning.  Most likely used in security
 * scenarios.  i.e. to throttle requests and add entropy into the call
 * so that people cannot do a timing attack.
 *
 * @param ceiling the ceiling amount of random time to add, default is 250
 * @param floor the min amount of time to add, default is 150
 */
export default async function pauseRandom(
  ceiling: number = 250,
  floor: number = 150
): Promise<void> {
  return pause(Math.floor(Math.random() * Math.floor(ceiling)) + floor);
}
