import * as yup from 'yup';

import { LaneType } from 'common-types';
import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

export class RichTextType extends StringTypeBase {
  group = TYPE_GROUP_TEXT;

  name = 'RichText';

  superUserOnly = true;

  friendlyName = 'Rich Text';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  get default(): any {
    return [];
  }

  buildSchema() {
    return yup.string().nullable();
  }
}

export type RichTextNodeType = {
  type?: RichTextBlockEnum;
  text?: string;
  children?: RichTextNodeType[];
  url?: LaneType.Url;
  bold?: boolean;
  underlined?: boolean;
  italic?: boolean;
  align?: RichTextAlignEnum;
};

export enum RichTextAlignEnum {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum RichTextBlockEnum {
  H1 = 'heading-one',
  H2 = 'heading-two',
  H3 = 'heading-three',
  H4 = 'heading-four',
  H5 = 'heading-five',
  H6 = 'heading-six',
  Paragraph = 'paragraph',
  Code = 'code',
  BlockQuote = 'block-quote',
  NumberedList = 'numbered-list',
  BulletedList = 'bulleted-list',
  ListItem = 'list-item',
  Link = 'link',
}

export enum RichTextMarksEnum {
  Bold = 'bold',
  Italic = 'italic',
  Underlined = 'underlined',
}

export enum RichTextToolsEnum {
  Mark = 'mark',
  Block = 'block',
  Clear = 'clear',
}

const definition = new RichTextType();

Types.addBaseType(definition);
export default definition;
