import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
  TimeAvailabilityFeatureProperties,
  WeekDay,
  WeeklyAvailability,
} from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

export const WEEK_DAYS = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
};

function getUnavailableWeekDays(
  weeklyAvailabilities: WeeklyAvailability[],
  userRolesBase62Ids: string[]
) {
  const isTargetedRole = ({ groupRole }: WeeklyAvailability) => {
    return (
      groupRole?._id &&
      userRolesBase62Ids.some(
        roleId => convertToUUID(roleId) === convertToUUID(groupRole._id)
      )
    );
  };

  const availabilities =
    weeklyAvailabilities.filter(
      availability => availability.allGroupRoles || isTargetedRole(availability)
    ) || [];

  const isTimeAvailableAnyTime = availabilities.some(
    ({ isAvailableAnytime }) => isAvailableAnytime
  );

  if (isTimeAvailableAnyTime) {
    return [];
  }

  const daysWithTimeAvailability: number[] = [];

  availabilities.forEach(({ weekTimeRules }) => {
    Object.entries(WEEK_DAYS).forEach(([id, day]) => {
      const weekTimeRule = weekTimeRules[day as WeekDay];

      if (weekTimeRule.isOpen) {
        daysWithTimeAvailability.push(Number(id));
      }
    });
  });

  const weekDaysWithoutTimeAvailability = Object.keys(WEEK_DAYS)
    .map(Number)
    .filter(day => !daysWithTimeAvailability.includes(day));

  return weekDaysWithoutTimeAvailability;
}

interface Props {
  timeAvailabilityFeature: TimeAvailabilityFeatureProperties | undefined;
  userGroupRoleIds: string[];
}

export function getUnavailableDatesAndWeekDays({
  timeAvailabilityFeature,
  userGroupRoleIds,
}: Props) {
  if (timeAvailabilityFeature === undefined) {
    return {
      unavailableWeekdays: [],
      unavailableDates: [],
    };
  }
  const { availabilities, unavailableDates: ud } = timeAvailabilityFeature;
  const unavailableDates = ud ?? [];

  return {
    unavailableWeekdays: getUnavailableWeekDays(
      availabilities,
      userGroupRoleIds
    ),
    unavailableDates: unavailableDates
      .filter(
        ({ allGroupRoles, groupRole }) =>
          allGroupRoles ||
          (groupRole?._id &&
            userGroupRoleIds.some(
              roleId => convertToUUID(roleId) === convertToUUID(groupRole._id)
            ))
      )
      .map(ud => ud.dateRange),
  };
}
