import { getClient } from 'lane-shared/apollo';
import { getAttachmentsByEntity as getAttachmentsByEntityQuery } from 'lane-shared/graphql/attachment';
import type { AttachmentResponse } from 'lane-shared/types/attachment';

import { LaneType } from 'common-types';

type GQLCreateAttachmentResponse = {
  data?: {
    getAttachmentsByEntity: AttachmentResponse[];
  } | null;
};

type Props = {
  entityId: LaneType.UUID;
};

export const getAttachmentsByEntity = async ({
  entityId,
}: Props): Promise<AttachmentResponse[]> => {
  const variables = {
    id: entityId,
  };

  const { data }: GQLCreateAttachmentResponse = await getClient().mutate({
    mutation: getAttachmentsByEntityQuery,
    variables,
  });

  return data!.getAttachmentsByEntity;
};
