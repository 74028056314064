/**
 * Checks a CancellableFeature to see if this current user can cancel based
 * on the rules.
 *
 * @param roles an array of base62 IDs that represents this users group roles
 * @param feature the cancellable feature
 * @returns {*}
 */
import { LaneType } from 'common-types';
import { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';

type Props = {
  feature: CancelableFeatureProperties;
  roles?: LaneType.UUID[];
};
export default function isCancellableForUser({ feature, roles = [] }: Props) {
  return feature.rules?.some(rule => {
    if (rule.allGroupRoles) {
      return true;
    }

    return rule.groupRoles?.some(role => roles.includes(role?._id));
  });
}
