import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ChipButton from '../ChipButton';

import styles from './index.scss';

type ChipProps<ValueType> = {
  options: { label: string; value: ValueType }[];
  compare: (value: ValueType) => boolean;
  onClick: (value: ValueType) => void;
  itemName?: string;
  hasSeeMore?: boolean;
  seeMoreThreshold?: number | null;
  seeMoreCount?: number | null;
  gridSize: 'small' | 'medium' | 'large';
};

export default function Chips<ValueType>({
  options,
  compare,
  onClick,
  itemName = 'item',
  hasSeeMore = false,
  seeMoreThreshold = null,
  seeMoreCount = null,
  gridSize,
}: ChipProps<ValueType>) {
  const showThreshold = useMemo(() => {
    return seeMoreThreshold || (seeMoreCount || 4) * 2;
  }, [seeMoreThreshold, seeMoreCount]);

  const showMoreCount = seeMoreCount || options.length - showThreshold;

  const [show, setShow] = useState(hasSeeMore ? showThreshold : options.length);

  const selectedOptions = useMemo(() => {
    if (!hasSeeMore) return [];

    return options
      .map((option, i) => ({ index: i, value: option.value }))
      .filter(option => compare(option.value) && option.index >= show)
      .map(option => option.index);
  }, [options, compare, show, hasSeeMore]);

  const { t } = useTranslation();

  useEffect(() => setShow(hasSeeMore ? showThreshold : options.length), [
    options.length,
  ]);

  const shownAvailableOptions = options.slice(0, show - selectedOptions.length);
  const shownSelectedOptions = selectedOptions.map(
    selectionIndex => options[selectionIndex]
  );

  return (
    <>
      <div
        className={classNames({
          [styles.largeSizeGrid]: gridSize === 'large',
          [styles.mediumSizeGrid]: gridSize === 'medium',
        })}
      >
        {[...shownAvailableOptions, ...shownSelectedOptions].map(
          (option, index) => {
            if (option) {
              return (
                <ChipButton<ValueType>
                  key={option.label ?? index}
                  value={option.value}
                  isChecked={compare(option.value)}
                  onClick={value => onClick(value)}
                >
                  {option.label}
                </ChipButton>
              );
            }

            return null;
          }
        )}
      </div>
      <div className={styles.expandRow}>
        {hasSeeMore && show < options.length && (
          <button
            className={styles.expandButton}
            onClick={() => setShow(show + showMoreCount)}
          >
            {t(
              `web.content.feature.reservable.timeSlots.seeMore.seeMoreLabel${
                (showMoreCount || 0) > 1 ? '_plural' : ''
              }`,
              { count: showMoreCount, itemName }
            )}
          </button>
        )}
        {hasSeeMore && show > showThreshold && (
          <button
            className={styles.expandButton}
            onClick={() => setShow(show - showMoreCount)}
          >
            {t('web.content.feature.reservable.timeSlots.seeMore.seeLessLabel')}
          </button>
        )}
      </div>
    </>
  );
}
