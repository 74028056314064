import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import SignupPhrase from 'components/authV2/SignupPhrase';
import { M } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

type Props = {
  whitelabel?: any;
  isModal?: boolean;
  isAuthPurchaseWrapper?: boolean;
  navigateToSignUp?: () => void;
};

export default function SignupComponent({
  whitelabel,
  isModal,
  navigateToSignUp,
  isAuthPurchaseWrapper,
}: Props) {
  const { t } = useTranslation();

  return (
    whitelabel.settings?.allowSignUps && (
      <M
        className={cx(
          welcomeFormStyles.needAnAccount,
          isAuthPurchaseWrapper && welcomeFormStyles.authPurchaseWrapper,
          isModal && welcomeFormStyles.isModal
        )}
      >
        {t("Don't have an account?")}&nbsp;
        <SignupPhrase
          navigateToSignUp={navigateToSignUp}
          isAuthPurchaseWrapper={isAuthPurchaseWrapper}
        />
      </M>
    )
  );
}
