import React, { memo } from 'react';

import getBlockKey from 'lane-shared/renderers/v5/getBlockKey';
import RequireOnlyOne from 'lane-shared/types/RequireOnlyOne';

type WithBlock = { block: any };

type BaseProps = {
  blocks: { [key: string]: any };
  top: any;
  isTop?: boolean;
  className?: string;
  content: WithBlock;
  example: WithBlock;
  isPreview?: boolean;
};

type Props = RequireOnlyOne<BaseProps, 'content' | 'example'>;

function BlockRenderer({ content, example, blocks, ...otherProps }: Props) {
  const blockProvider = content || example;

  const Component = blocks[getBlockKey(blockProvider.block)];

  if (!Component) {
    return null;
  }

  return <Component {...otherProps} />;
}

export default memo(BlockRenderer);
