function formatTime(date: any) {
  return date.toISOString().replace(/-|:|\.\d+/g, '');
}

export default function getGoogleCalendarLink({
  startDate,
  endDate,
  name,
  description,
}: any) {
  return encodeURI(
    [
      'https://www.google.com/calendar/render',
      '?action=TEMPLATE',
      `&text=${name}`,
      `&dates=${formatTime(startDate)}`,
      `/${formatTime(endDate)}`,
      `&details=${description}`,
      '&sprop=&sprop=name:',
    ].join('')
  );
}
