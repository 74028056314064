import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { Hardware } from 'lane-shared/domains/hardwareManagement/types/Hardware';
import { PERMISSION_ADMIN } from 'lane-shared/helpers/constants/permissions';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { ControlMenu, Button } from 'lane-web/src/components';

import { Table, PageSizeType, Sort } from 'design-system-web';

import HardwareActivityStatusSpan from '../../../../../components/HardwareActivityStatusSpan/HardwareActivityStatusSpan';
import { hardwareType, HardwareType, SORT_DESC } from '../hardware';

import styles from './styles.scss';

const hardwareListQuery = gql`
  query ChannelHardwares(
    $channelId: UUID!
    $search: HardwareSearch
    $pagination: PaginationInput!
  ) {
    channelHardwares(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      items {
        id
        name
        createdAt
        status
        location
        notes
        type
        deviceInfo {
          lastActivityAt
        }
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`;

export function HardwareListPage({
  channel,
  hasAnyPermission,
}: {
  channel: ChannelType;
  hasAnyPermission: (hasAnyPermission: string[]) => Boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [sort, setSort] = useState<Sort>({});
  const DEFAULT_SORT = 'created_at';
  const isAdminOrSuperUser = hasAnyPermission([PERMISSION_ADMIN]);

  function goToDetail(hardwareId: string) {
    history.push(
      routes.channelAdminHardwareDetails
        .replace(':id', channel?.slug)
        .replace(':hardwareId', hardwareId),
      {}
    );
  }

  function redirectToAddHardware() {
    history.push(routes.channelAdminHardwareNew.replace(':id', channel?.slug));
  }

  function goToEdit(hardwareId: string) {
    history.push(
      routes.channelAdminHardwareDetails
        .replace(':id', channel?.slug)
        .replace(':hardwareId', hardwareId),
      { goToEdit: true }
    );
  }

  const viewDetailsRowAction = {
    label: t(
      'web.admin.hardware.management.hardware.list.row.action.view.details'
    ),
    onClick: (row: Hardware) => goToDetail(row.id),
  };

  const editRowAction = {
    label: t('web.admin.hardware.management.hardware.profile.edit'),
    onClick: (row: Hardware) => goToEdit(row.id),
  };

  const rowActions = [viewDetailsRowAction, editRowAction];

  const columns = [
    {
      header: t('web.admin.hardware.management.hardware.list.column.name'),
      key: 'name',
      renderCell: (_: any, row: Hardware) => {
        return (
          <Link
            to={routes.channelAdminHardwareDetails
              .replace(':id', channel?.slug)
              .replace(':hardwareId', row.id)}
            className={cx(styles.linkText, styles.numberOfCharacters)}
          >
            {row.name}
          </Link>
        );
      },
    },
    {
      header: t('web.admin.hardware.management.hardware.list.column.status'),
      key: 'status',
      renderCell: (_: any, row: Hardware) => {
        return (
          <HardwareActivityStatusSpan
            lastActivityAt={row.deviceInfo?.lastActivityAt}
          />
        );
      },
    },
    {
      header: t('web.admin.hardware.management.hardware.list.column.type'),
      key: 'type',
      renderCell: (type: HardwareType) => t(hardwareType(type)),
    },
    {
      header: t('web.admin.hardware.management.hardware.list.column.location'),
      key: 'location',
      renderCell: (cell: string) => {
        return <span className={styles.numberOfCharacters}>{cell}</span>;
      },
    },
    {
      header: t('web.admin.hardware.management.hardware.list.column.notes'),
      key: 'notes',
      renderCell: (cell: string) => {
        return <span className={styles.numberOfCharacters}>{cell}</span>;
      },
    },
  ];

  const { data, loading, refetch: refetchHardwares } = useQuery(
    hardwareListQuery,
    {
      client: getClient(),
      variables: {
        channelId: channel?._id,
        search: {
          sortBy: { key: DEFAULT_SORT, dir: SORT_DESC },
        },
        pagination: {
          start: page * pageSize,
          perPage: pageSize,
        },
      },
    }
  );
  const hardwareData = data?.channelHardwares?.items || [];
  const totalRows = data?.channelHardwares?.pageInfo?.total || 0;

  const hardwareItems = hardwareData.map((hardware: any) => {
    return { ...hardware, details: '' };
  });

  useEffect(() => {
    refetchHardwares({
      channelId: channel?._id,
      pagination: {
        start: page * pageSize,
        perPage: pageSize,
      },
    });
  }, [page, pageSize]);

  useEffect(() => {
    if ('id' in sort) {
      if (sort.id === 'createdAt') {
        refetchHardwares({
          channelId: channel?._id,
          search: {
            sortBy: { key: DEFAULT_SORT, dir: sort.direction },
          },
          pagination: {
            start: page * pageSize,
            perPage: pageSize,
          },
        });
      } else {
        refetchHardwares({
          channelId: channel?._id,
          search: {
            sortBy: { key: sort.id, dir: sort.direction },
          },
          pagination: {
            start: page * pageSize,
            perPage: pageSize,
          },
        });
      }
    }
  }, [sort]);

  return (
    <div className={styles.Hardwares}>
      <ControlMenu className={styles.controlMenu}>
        {isAdminOrSuperUser && (
          <Button variant="contained" onClick={() => redirectToAddHardware()}>
            {t(
              'web.admin.hardware.management.hardware.list.button.add.a.device'
            )}
          </Button>
        )}
      </ControlMenu>
      <hr />
      <div>
        <Table
          columns={columns}
          pagination="server"
          totalRows={totalRows}
          pageSize={pageSize}
          page={page}
          onPageChange={setPage}
          onSortChange={setSort}
          onPageSizeChange={setPageSize}
          data={hardwareItems}
          isLoading={loading}
          rowActions={rowActions}
        />
      </div>
    </div>
  );
}
