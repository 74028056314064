import React from 'react';

import {
  INTERACTION_APPROVED,
  INTERACTION_REJECTED,
  INTERACTION_WAITING,
} from 'lane-shared/helpers/constants/interactions';
import { colors } from 'lane-shared/helpers/integrations/ShopifyBurst/colors';
import { ShopifyRsvpStatusEnum } from 'lane-shared/types/integrations/ShopifyRsvp';

import { IconButton } from 'components/general';
import StatusPill from 'components/general/StatusPill';
import { Flex, Box } from 'components/layout';
import { H4, S } from 'components/typography';

import useIsAdminView from 'hooks/useIsAdminView';

import styles from './BurstInteractionHeader.scss';

type Props = {
  title: string;
  header: string;
  status?: ShopifyRsvpStatusEnum;
  helperText?: string;
  onClose: () => void;
};

export default function BurstInteractionHeader({
  header,
  status,
  title,
  onClose,
  helperText,
}: Props) {
  const [isAdminView] = useIsAdminView();

  const backgroundColor = status
    ? {
        [INTERACTION_APPROVED]: colors.burstingGreenLight,
        [INTERACTION_WAITING]: colors.shopifySecondary,
        [INTERACTION_REJECTED]: colors.burstingPinkLight,
        [ShopifyRsvpStatusEnum.Accepted]: colors.burstingGreenLight,
        [ShopifyRsvpStatusEnum.Confirmed]: colors.burstingGreenLight,
        [ShopifyRsvpStatusEnum.Declined]: colors.burstingPinkLight,
        [ShopifyRsvpStatusEnum.Cancelled]: colors.burstingPinkLight,
        [ShopifyRsvpStatusEnum.Awaiting]: colors.shopifySecondary,
      }[status]
    : colors.shopifySecondary;

  return (
    <Box p={[5, 5, 6, 5]} className={styles.header} style={{ backgroundColor }}>
      {!isAdminView && (
        <IconButton
          icon="times"
          iconSet="FontAwesome"
          type="far"
          className={styles.button}
          onClick={onClose}
        />
      )}
      <Flex align="center" direction="column" className={styles.headerText}>
        <S className={styles.title}>{title}</S>
        <H4 bold>{header}</H4>
        {helperText && (
          <S variant="secondary" mt={2} className={styles.helperText}>
            {helperText}
          </S>
        )}
        {status && (
          <StatusPill
            size="medium"
            status={status}
            text={status}
            className={styles.status}
          />
        )}
      </Flex>
    </Box>
  );
}
