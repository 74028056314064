import { getClient } from 'lane-shared/apollo';
import { deleteAttachment as deleteAttachmentMutation } from 'lane-shared/graphql/attachment';
import type { AttachmentResponse } from 'lane-shared/types/attachment';

import { LaneType } from 'common-types';

type GQLDeleteAttachmentResponse = {
  data?: {
    deleteAttachment: AttachmentResponse;
  } | null;
};

type Props = {
  attachmentId: LaneType.UUID;
};

export const deleteAttachment = async ({
  attachmentId,
}: Props): Promise<AttachmentResponse> => {
  const variables = {
    id: attachmentId,
  };

  const { data }: GQLDeleteAttachmentResponse = await getClient().mutate({
    mutation: deleteAttachmentMutation,
    variables,
  });

  return data!.deleteAttachment;
};
