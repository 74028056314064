import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import {
  FONT_AWESOME_LIGHT,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';

import Typography from 'components/lds/Typography';

import ReviewData from './ReviewData';

type Props = {
  onClick?: () => void;
  city: string;
  country: string;
};

export default function ReviewDeparture({ onClick, city, country }: Props) {
  const { t } = useTranslation();

  return (
    <ReviewData
      title={t`Departing city`}
      titleIcon={
        <Icon
          set={ICON_SET_FONTAWESOME}
          name="globe"
          type={FONT_AWESOME_LIGHT}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"lg"' is not assignable to type '"small" | "... Remove this comment to see the full error message
          size="lg"
        />
      }
      onClick={onClick}
      summary={
        <>
          {city && country && (
            <Typography type="m" mode="primary">
              {city}
              {', '}
              {country}
            </Typography>
          )}
        </>
      }
    />
  );
}
