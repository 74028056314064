import { useMemo } from 'react';

import {
  friendlyTypeOptions,
  FriendlyTypeOption,
  FriendlyType,
} from 'lane-shared/helpers/properties/friendlyTypeOptions';
import Types, { TypesCollection } from 'lane-shared/properties/Types';
import { TypeInterface } from 'lane-shared/types/baseTypes/TypeInterface';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

// useful to use this array later.
export const allPackagedTypes: FriendlyType[] = [
  PackagedTypeEnum.Quantity,
  PackagedTypeEnum.Slider,
  PackagedTypeEnum.Radios,
  PackagedTypeEnum.Checkboxes,
  PackagedTypeEnum.Dropdown,
];

// which packaged types have several options?
export const optionTypes: FriendlyType[] = [
  PackagedTypeEnum.Radios,
  PackagedTypeEnum.Checkboxes,
  PackagedTypeEnum.Dropdown,
];

// what types can have placeholder text on them?
export const placeholderTypes: FriendlyType[] = [
  'String',
  'LongText',
  'Number',
  'Email',
  'PhoneNumber',
  'Url',
  'Currency',
  'Percentage',
  'SelectUser',
];

export const minMaxValidatorTypes: FriendlyType[] = [
  PackagedTypeEnum.Quantity,
  PackagedTypeEnum.Slider,
  'Number',
  'Currency',
  'Percentage',
];

export const filterTypes: FriendlyType[] = ['SelectUser'];

export const arrayMinMaxValidatorTypes: FriendlyType[] = [
  PackagedTypeEnum.Checkboxes,
];

export const multiSelectTypes: FriendlyType[] = ['SelectUser'];

// Temporarily changes to introduce Select User's feature only to the Bursting Admins
// TODO: remove after GA or when STAR user will have LD access and replace this part
const BURSTING_APP_ID_62 = '6b2QoHAtUjsEDjthrAjyUH';
const isBurstingApp = (channel: any) =>
  channel?.slug === 'bursting-app' && channel?._id === BURSTING_APP_ID_62;

export default function useFriendlyPropertyType({ user, contexts }: any) {
  const { channel } = useChannelAdminContext();

  const isBurst = isBurstingApp(channel);

  const types: TypesCollection = Types.getBaseTypes();
  const availableBaseTypes: TypeInterface[] = useMemo(
    () =>
      Object.values(types)
        .filter(
          type =>
            !type.contexts ||
            type.contexts.some(context => contexts.includes(context))
        )
        .filter(type => user?.isSuperUser || !type.superUserOnly),
    [user, contexts]
  );

  const friendlyTypes: FriendlyTypeOption[] = friendlyTypeOptions.filter(
    friendlyTypeOption =>
      allPackagedTypes.includes(friendlyTypeOption?.value) ||
      availableBaseTypes.some(type => type.name === friendlyTypeOption?.value)
  );

  const availableFriendlyTypes = !isBurst
    ? friendlyTypes.filter(type => type.value !== 'SelectUser')
    : friendlyTypes;

  return {
    availableFriendlyTypes,
    allPackagedTypes,
    optionTypes,
    placeholderTypes,
    minMaxValidatorTypes,
    arrayMinMaxValidatorTypes,
    filterTypes,
    multiSelectTypes,
  };
}
