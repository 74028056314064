import {
  resetDateFunctions,
  TimeResetFrequencyEnum,
} from '../../renderers/v5/features/Reset';
import parseDate from '../dates/parseDate';

type Args = {
  lastReset: any;
  period: TimeResetFrequencyEnum;
};

/**
 * Gets the reset date/time for a Reset Feature.
 *
 * @param lastReset the last known next reset time
 * @param period the reset feature to base this off of
 * @returns {{start: Date, end: Date}}
 */
export default function getLastResetPeriod({ lastReset, period }: Args) {
  const endDate = parseDate(lastReset) ?? undefined;
  const startDate = resetDateFunctions[period](endDate, -1);

  return {
    startDate,
    endDate,
  };
}
