import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesGreaterThan } from './ITypeValidatesGreaterThan';

export class GreaterThanValidator
  implements ValidatorInterface<ITypeValidatesGreaterThan> {
  name = 'GreaterThan';

  friendlyName = 'Greater Than';

  arrayOnly = false;

  array = false;

  description = 'Field must be greater than this other field.';

  types = ['Percentage', 'Currency', 'Number', 'DateTime'];

  properties = {
    value: {
      type: 'Key',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesGreaterThan) {
    type.addGreaterThanValidator(this);
  }

  constructor(public config?: Validator) {}
}

const GreaterThan = new GreaterThanValidator(undefined);

ValidatorRegistry.register(GreaterThanValidator, GreaterThan.name);

export default GreaterThan;
