import { mapValues } from 'lodash';

import { request, Res } from './common';

export interface ListParams {
  building: string;
}

export type ListResData = IssueType[];

export interface IssueType {
  id: string;
  name: string;
  children: IssueTypeChild[];
}

export interface IssueTypeChild {
  id: string;
  name: string;
  on_new_form: Form | null;
}

export interface Form {
  account_id: string;
  content_type: string;
  sub_content_type: string;
  id: string;
  name: string;
  fields: Field[];
}

export interface Field {
  id: string;
  field_id: string;
  field: FieldData;
}

export enum FieldType {
  Choice = 'choice',
  Currency = 'currency',
  Date = 'date',
  Number = 'float',
  Phone = 'phone',
  Float = 'float',
  TextArea = 'textarea',
  TextField = 'textfield',
}

export interface IFieldData {
  account_id: string;
  accounting_related: boolean;
  display_name: string;
  displayed_on_pages: string[];
  id: string;
  name: string;
  placeholder_text: string;
  private: boolean;
  required: boolean;
  required_on_close: boolean;
  required_on_completed: boolean;
  validation_rules: {};
}

export interface GenericFieldData extends IFieldData {
  data_type: Exclude<FieldType, FieldType.Choice>;
}

export interface ChoiceFieldData extends IFieldData {
  data_type: FieldType.Choice;
  validation_rules: {
    options: string[];
  };
}

type FieldData = GenericFieldData | ChoiceFieldData;

export async function list(
  token: string,
  params: ListParams
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    path: '/issue_types',
    params: mapValues(params, x => String(x)),
  });
}
