import {
  Password,
  String,
  Url,
  Address,
} from 'lane-shared/properties/baseTypes';

import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const ShopifyMultipassDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.ShopifyMultipass,
  // hasCustomUI allows it to be loaded on mobile
  hasCustomUI: true,
  friendlyName: 'Shopify Multipass',
  description: 'Shopify Multipass login for Lane Perks SSO',
  category: IntegrationCategoryEnum.Commerce,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Custom,
  settings: {
    name: {
      type: String.name,
      friendlyName: 'Name',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    storeUrl: {
      friendlyName: 'Store URL',
      description: 'The URL of the Shopify store',
      type: Url.name,
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    multipassSecret: {
      friendlyName: 'Multipass Secret',
      type: Password.name,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    adminApiPassword: {
      friendlyName: 'Admin API Password',
      type: Password.name,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    tags: {
      friendlyName: 'User Tags',
      type: String.name,
      validators: [
        {
          name: 'Required',
          value: false,
        },
      ],
    },
    address: {
      friendlyName: 'Address',
      type: Address.name,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default ShopifyMultipassDefinition;
