import React from 'react';

import { AdminPage, TabStrip, Loading } from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';

import { INVITE_STATUS } from 'lane-shared/helpers/constants/invites';
import {
  ChannelType,
  ChannelExperienceTypeEnum,
} from 'lane-shared/types/ChannelType';

import { H3 } from 'lane-web/src/components/typography';

import { useAllUsers, useNewPendingInvites } from './hooks';
import { AllUsersView, PendingInvitesView, EmptyStateView } from './view/index';

import styles from './styles.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

type Props = {
  channel: ChannelType;
};

export enum UsersPageTabEnum {
  TAB_ACTIVE = 'active',
  TAB_Pending_Invites = 'pending-invites',
}

export function UserManagementUsersPage({ channel }: Props) {
  const isAddManualResidentsEnabled = useFlag(
    FeatureFlag.AddManualResidents,
    false
  );

  const isMFExperience =
    channel.experienceType === ChannelExperienceTypeEnum.multifamily;

  const shouldIncludeResidentInfo =
    isAddManualResidentsEnabled && isMFExperience;

  const { t } = useTranslation();
  const { users, groupRoles, loading: usersLoading, refetch } = useAllUsers(
    channel._id,
    shouldIncludeResidentInfo
  );

  const {
    channelInvites,
    loading: channelInvitesLoading,
    refetch: channelPendingInvitesRefetch,
  } = useNewPendingInvites(channel._id);

  const [query, goToUrl] = useQueryString({
    tab: UsersPageTabEnum.TAB_ACTIVE,
  });

  const pendingInvitesCount =
    channelInvites &&
    channelInvites.filter(invite => {
      return [
        INVITE_STATUS.CREATED,
        INVITE_STATUS.SENT,
        INVITE_STATUS.RESENT,
      ].includes(invite.status);
    }).length;

  const tabs = [
    {
      label: t(
        'web.admin.channel.userManagement.users.view.tabActivity.active'
      ),
      value: UsersPageTabEnum.TAB_ACTIVE,
    },
    {
      label: t(
        'web.admin.channel.userManagement.users.view.tabActivity.pendingInvites',
        { pendingInvitesCount }
      ),
      value: UsersPageTabEnum.TAB_Pending_Invites,
    },
  ];

  const selectedTab = query.tab || UsersPageTabEnum.TAB_ACTIVE;

  const channelHasUsers = users?.length > 0;

  const channelHasInvites = channelInvites && channelInvites.length > 0;

  const hasDataAndNotLoading =
    !usersLoading &&
    !channelInvitesLoading &&
    (channelHasUsers || channelHasInvites);

  const emptyStateView = !channelHasUsers &&
    !usersLoading &&
    !channelHasInvites &&
    !channelInvitesLoading && (
      <EmptyStateView
        channel={channel}
        body="web.pages.portal.admin.users.emptyState.body"
      />
    );

  const tables =
    selectedTab === UsersPageTabEnum.TAB_ACTIVE
      ? !usersLoading && (
          <AllUsersView
            usersData={users}
            groupRoles={groupRoles?.channel?.groupRoles}
            channelId={channel._id}
            channelSlug={channel.slug}
            refetch={refetch}
            isMFExperience={isMFExperience}
          />
        )
      : !channelInvitesLoading && (
          <PendingInvitesView
            invites={channelInvites}
            refetch={channelPendingInvitesRefetch}
            groupRoles={groupRoles?.channel?.groupRoles.map(
              (g: { name: string }) => g.name
            )}
            channelSlug={channel?.slug || ''}
            isMFExperience={isMFExperience}
          />
        );

  const renderTables = () => {
    if (usersLoading || channelInvitesLoading) {
      return <Loading />;
    }

    if (hasDataAndNotLoading) {
      return tables;
    }

    return emptyStateView;
  };

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.channelUsers}>
        <div className={styles.header}>
          <div className={styles.subheader}>
            <H3>{t('web.pages.portal.admin.users.index.header')}</H3>
          </div>
          <TabStrip
            tabs={tabs}
            selected={tabs.find(tab => tab.value === selectedTab)}
            onSelectTab={tab => goToUrl({ tab: tab.value })}
            className={styles.tabStrip}
            skipLabelTranslation
            fullWidth
          />
          {renderTables()}
        </div>
      </div>
    </AdminPage>
  );
}
