import React from 'react';

import { FlexDirectionEnum } from 'lane-shared/types/blocks/Flex';

import ButtonStrip from '../general/ButtonStrip';

const iconSet = 'FontAwesome';

const flexDirectionValues = [
  {
    value: FlexDirectionEnum.Horizontal,
    icon: 'grip-lines',
    iconSet,
  },
  {
    value: FlexDirectionEnum.Vertical,
    icon: 'grip-lines-vertical',
    iconSet,
  },
] as const;

type Props = {
  onChange: (value: FlexDirectionEnum) => void;
  value: FlexDirectionEnum | null;
};

export default function FlexDirectionInput({ onChange, value }: Props) {
  return (
    <ButtonStrip
      buttons={[...flexDirectionValues]}
      onClick={onChange}
      selected={value}
    />
  );
}
