import React from 'react';

import cx from 'classnames';

import { longDate } from 'lane-shared/helpers/formatters';
import { DocumentType } from 'lane-shared/types/DocumentType';

import PublicUserLink from './PublicUserLink';

import styles from './PublishedBy.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  object: DocumentType;
  forAdmin: boolean;
};

export default function PublishedBy({
  className,
  style,
  object,
  forAdmin,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.PublishedBy, className)} style={style}>
      <p>
        <span>
          {t('web.admin.content.components.publishedBy.text')}{' '}
          {longDate((object as any)?.publishedAt)}
        </span>
        <PublicUserLink
          userId={(object as any)?.publishedBy?._id}
          forAdmin={forAdmin}
        />
      </p>
    </div>
  );
}
