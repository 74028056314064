import { MergeMethodEnum } from './MergeMethodEnum';

/**
 * Simple helper for template import/export.  Returns the value of a trinary
 * used in TemplateImportOptions or TemplateExportOptions
 *
 */
export default function getMergeKeyValue(
  obj: any,
  key: any
): MergeMethodEnum | undefined {
  if (Object.hasOwnProperty.call(obj, key)) {
    return MergeMethodEnum.Copy;
  }

  return undefined;
}
