import React from 'react';
import {
  AddProductToContentDetails,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import styles from './index.scss';
import { H4, Table } from 'design-system-web';
import { useTableColumnsAddedProducts } from '../hooks/useTableColumnsAddedProducts';
import { useTranslation } from 'react-i18next';

export function AddedProductsTable({
  handleProductRemoval,
  products,
  currency,
  inclusivePricing,
}: {
  handleProductRemoval: (product: AddProductToContentDetails) => void;
  products: AddProductToContentDetails[];
  currency: SupportedPaymentCurrency;
  inclusivePricing: boolean;
}) {
  const productColumns = useTableColumnsAddedProducts({
    currency,
    removeProduct: handleProductRemoval,
    inclusivePricing,
  });

  const { t } = useTranslation();

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.header}>
        <H4>
          {t(
            'web.content.features.paymentv2.addedProductsTable.productOrService'
          )}
        </H4>
      </div>
      <Table
        columns={productColumns}
        data={products}
        disableSorting
        isLoading={false}
      />
    </div>
  );
}
