import { CHARGE_STATUS } from '../../domains/billingPayments/types';
import { isOverdue } from './isOverdue';

export const CHARGE_STATUS_MAP: { [key: string]: [string, string] } = {
  PENDING: [CHARGE_STATUS.CHARGE_PENDING, 'blue'],
  CREATED: [CHARGE_STATUS.CHARGE_PENDING, 'blue'],
  RECEIVABLE_PROCESSED: [CHARGE_STATUS.CHARGE_PENDING, 'blue'],
  PENDING_VOIDED: [CHARGE_STATUS.CHARGE_VOIDED, 'grey'],
  VOIDED: [CHARGE_STATUS.CHARGE_VOIDED, 'grey'],
  INVOICED: [CHARGE_STATUS.CHARGE_UNPAID, 'yellow'],
  OVERDUE: [CHARGE_STATUS.CHARGE_OVERDUE, 'red'],
  PAID: [CHARGE_STATUS.CHARGE_PAID, 'green'],
};

export function getChargeStatus(
  chargeStatus: string,
  row: any
): [string, string] {
  const useInvoiceStatus = [
    'PENDING',
    'CREATED',
    'RECEIVABLE_PROCESSED',
  ].includes(chargeStatus);
  const validInvoiceStatuses = [
    'INVOICED',
    'PAID',
    'PAYMENT_FAILED',
    'OVERDUE',
  ];
  let statusMap;
  if (
    useInvoiceStatus &&
    row.invoice &&
    validInvoiceStatuses.includes(row.invoice.status)
  ) {
    switch (row.invoice.status) {
      case 'OVERDUE':
        statusMap = CHARGE_STATUS_MAP.OVERDUE;
        break;
      case 'INVOICED':
        if (isOverdue(row.invoice.dueDate)) {
          statusMap = CHARGE_STATUS_MAP.OVERDUE;
          break;
        } else {
          statusMap = CHARGE_STATUS_MAP.INVOICED;
          break;
        }
      case 'PAID':
        statusMap = CHARGE_STATUS_MAP.PAID;
        break;
      default:
        statusMap = CHARGE_STATUS_MAP.INVOICED;
        break;
    }
  }
  return (
    statusMap ||
    CHARGE_STATUS_MAP[chargeStatus] || [
      'abp.chargeList.status.pending',
      'honey',
    ]
  );
}
