import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import DateTime from '../../../../properties/baseTypes/DateTime';
import Image from '../../../../properties/baseTypes/Image';
import UUID from '../../../../properties/baseTypes/UUID';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'String',
  },
  name: {
    type: 'String',
  },
  timezone: {
    type: 'String',
  },
  locale: {
    type: 'String',
  },
  logoOriginal: {
    type: 'String',
  },
  createdAt: {
    type: 'DateTime',
  },
};

export class ProxyClickCompanyType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ProxyClickCompany';

  friendlyName = 'Proxyclick Company';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      _id: UUID.example,
      name: 'Lane Technologies Inc.',
      timezone: 'America/Toronto',
      locale: 'en-US',
      logoOriginal: Image.example,
      createdAt: DateTime.example,
    };
  }

  get default(): any {
    return {
      _id: null,
      name: '',
      timezone: '',
      locale: '',
      logoOriginal: null,
      createdAt: null,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties: any;
}

const definition = new ProxyClickCompanyType();

Types.addType(definition);
export default definition;
