import { LaneType } from 'common-types';
import { DateRangeType } from '../baseTypes/DateRangeType';

export enum ReservableUnitTypesEnum {
  Minutes = 'Minutes',
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
}

export enum ReservableUiTypesEnum {
  TimeSlider = 'Date Picker',
  TimeSlots = 'List of Time Slots',
  TimePicker = 'Time Picker',
}

/**
 * This is the settings on a Reservable feature
 */

export type ReservableFeatureRuleType = {
  // what group role does this rule apply to
  groupRole:
    | {
        _id: string;
      }
    | undefined
    | null;
  // the type of units that unitsAfterNow is in
  unitsAfterNowType: ReservableUnitTypesEnum;
  // the amount of time units after now that is the earliest a user may book
  unitsAfterNow: number;
  // the type of units that unitsBeforeStart is in
  unitsBeforeStartType: ReservableUnitTypesEnum;
  // the max amount of time units before a reservation starts a user may book
  unitsBeforeStart: number;
  // the max slots the user can book
  maxSlots: number;
};

export type ReservableFeatureProperties = {
  _version: undefined | 2;
  // the type of units that units is in
  unitType: ReservableUnitTypesEnum;
  units: number;
  uiType: ReservableUiTypesEnum;
  maxQuantityPerSlot: number;
  maxQuantityPerSlotPerUser: number;
  dateRange: DateRangeType | null;
  mainRule: ReservableFeatureRuleType;
  rules: ReservableFeatureRuleType[];
  useCustomTimeSlots?: boolean;
  useWaitlist?: LaneType.Boolean;
  customTimeSlots?: LaneType.TimeRange[];
  splitReservation?: boolean;
  splitReservationSlots?: number;
  bufferTime?: LaneType.BufferTime;
  relatedSpaces?: LaneType.RelatedSpaces | null;
  userNotes?: LaneType.UserNotes;
};
