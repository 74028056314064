import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';

const properties: PropertiesInterface = {
  name: {
    friendlyName: 'Floor Name',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 64,
      },
    ],
  },
  suites: {
    friendlyName: 'Floor Suites',
    type: 'String',
    isArray: true,
    validators: [],
  },
};

export class ThreeTenServFloorType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ThreeTenServFloor';

  friendlyName = '310-SERV Floor';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      name: '05',
      suites: ['Suite 500', 'Suite 501'],
    };
  }

  get default(): any {
    return {
      name: '',
      suites: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new ThreeTenServFloorType();

Types.addType(definition);
export default definition;
