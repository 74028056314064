import React from 'react';

import cx from 'classnames';
import { DateRange, DayPicker, Matcher } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import {
  getToday,
  fromLocalDate,
  toLocalDate,
} from 'lane-shared/domains/visitorManagement/helpers';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { dateRangesToMatcher, formatters, weekends } from '../helpers/helpers';
import { classNames } from './CalendarClassNames';
import CalendarDay from './CalendarDay';
import CalendarHeadAdvanced from './CalendarHeadAdvanced';
import 'react-day-picker/dist/style.css';

import calendarStyles from './Calendar.scss';
import { Button } from 'design-system-web/components/Button/Button';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  selected?: DateRange;
  onChange: (date: DateRange | undefined) => void;
  onSubmit?: (() => void) | null;
  onClear?: (() => void) | null;
  onFocusChange?: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
  rangeMin?: number;
  rangeMax?: number;
  unavailableDateRanges?: DateRangeType[];
  weekdayOnly?: boolean;
  placeholder?: string;
  submitLabel?: string;
  dataCy?: string;
  timeZone?: string;
};

export default function DateRangeCalendar({
  className,
  style,
  loading,
  disabled,
  selected,
  onChange,
  onSubmit,
  onClear,
  onFocusChange,
  maxDate,
  minDate,
  rangeMin,
  rangeMax,
  unavailableDateRanges,
  weekdayOnly,
  submitLabel,
  dataCy,
  timeZone,
}: Props) {
  const { t } = useTranslation();

  const handleClear = () => {
    handleSelect(undefined);

    if (onClear) onClear();
  };

  const disabledDates = unavailableDateRanges
    ? dateRangesToMatcher(unavailableDateRanges)
    : [];

  if (weekdayOnly) {
    disabledDates.push(weekends);
  }

  const today = getToday(timeZone);

  function handleSelect(dateRange?: DateRange) {
    const tzSelected = {
      from: fromLocalDate(dateRange?.from, timeZone),
      to: fromLocalDate(dateRange?.to, timeZone),
    } as DateRange;

    selected = tzSelected;
    onChange(tzSelected);
  }

  const localSelected = {
    from: toLocalDate(selected?.from, timeZone),
    to: toLocalDate(selected?.to, timeZone),
  } as DateRange;

  return (
    <div
      data-cy={dataCy}
      className={cx(
        calendarStyles.datePicker,
        calendarStyles.dateRangeCalendar,
        className
      )}
      style={style}
    >
      <DayPicker
        className={disabled ? calendarStyles.disabled : ''}
        classNames={classNames}
        mode="range"
        components={{
          Caption: CalendarHeadAdvanced,
          Day: CalendarDay,
        }}
        disabled={disabledDates}
        fromDate={toLocalDate(minDate, timeZone)}
        toDate={toLocalDate(maxDate, timeZone)}
        selected={localSelected}
        modifiers={{
          selected: localSelected as Matcher,
        }}
        modifiersClassNames={{ localSelected: calendarStyles.selected }}
        onSelect={handleSelect}
        onDayFocus={onFocusChange}
        min={rangeMin}
        max={rangeMax}
        numberOfMonths={2}
        formatters={formatters}
        today={today}
      />
      {onSubmit && (
        <>
          <div className={calendarStyles.calendarSubmit}>
            <Button
              className={calendarStyles.button}
              dataCy="submitRange"
              loading={loading}
              disabled={disabled || !selected}
              onClick={onSubmit}
              variant="primary"
            >
              {!submitLabel
                ? t('web.admin.channel.visitor.log.datePicker.button')
                : submitLabel}
            </Button>
            <Button
              className={calendarStyles.button}
              loading={loading}
              disabled={disabled || !selected || !selected.from || !selected.to}
              onClick={handleClear}
              variant="secondary"
            >
              {t('web.admin.channel.visitor.log.datePicker.clear')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
