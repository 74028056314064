import { useTranslation } from 'react-i18next';
import {
  useMutation,
  isApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import {
  deleteInvite,
  DeleteInviteResponse,
} from 'lane-shared/graphql/mutation';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';

type handleRevokeInviteArgs = {
  invite: {
    id: string;
    name: string;
    groupRole: string;
  };
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

export const useRevokeInvite = () => {
  const { t } = useTranslation();

  const [revokeInviteMutation] = useMutation<DeleteInviteResponse>(
    deleteInvite
  );

  const handleRevokeInvite = async ({
    invite,
    refetch,
  }: handleRevokeInviteArgs) => {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.users.pendingInvites.table.revokeInvite.prompt.title',
          {
            userName: invite.name,
            userGroup: invite.groupRole,
          }
        ),
        message: t(
          'web.admin.channel.users.pendingInvites.table.revokeInvite.prompt.message',
          {
            userName: invite.name,
            userGroup: invite.groupRole,
          }
        ),
      });

      await revokeInviteMutation({
        variables: {
          id: invite.id,
        },
      });
      await refetch();

      window.Toast.show(
        t(
          'web.admin.channel.users.pendingInvites.table.revokeInvite.successToast'
        )
      );
    } catch (err) {
      if (isApolloError(err)) {
        const codes = getErrorCodes(err);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          {},
          t('shared.invites.errors.revokeError.generic')
        );
        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }
    }
  };

  return {
    handleRevokeInvite,
  } as const;
};
