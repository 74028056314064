 
import React, { useState, useRef, useMemo, useEffect } from 'react';

import cx from 'classnames';
import 'react-mapbox-gl';
import { v4 as uuid } from 'uuid';

import {
  GeoCoordinateType,
  GeoFeatureType,
  GeoPolygonType,
} from 'lane-shared/types/baseTypes/GeoTypes';

import DrawControl from 'components/mapBox/DrawControl';
import MapboxMap from 'components/mapBox/MapboxMap';

import styles from './GeoPolygonInput.scss';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  value: GeoPolygonType | undefined | null;
  center?: GeoCoordinateType;
  disabled?: boolean;
  onChange: (value: GeoPolygonType | undefined | null) => void;
};

const controls = {
  point: false,
  line_string: false,
  polygon: true,
  combine_features: false,
  uncombine_features: false,
  trash: true,
};

const animationOptions = {
  animate: false,
};

export default function GeoPolygonInput({
  className,
  style,
  center,
  value,
  onChange,
}: Props) {
  // generate a unique id to track features
  const idRef = useRef(uuid());
  // hold a reference to the draw control tool
  const drawControlRef = useRef(null);
  // hold a none state ref to the
  const featureRef = useRef<GeoFeatureType | null>(null);
  // use a state handler to trigger a re-render
  const [, triggerRender] = useState(Date.now());

  useEffect(() => {
    if (value?._id === idRef.current) {
      // existing polygon is being set
      featureRef.current = {
        id: value!._id,
        type: 'Feature',
        geometry: value as GeoPolygonType,
        properties: {},
      };

      updateFeatureOnMap();
    } else if (value) {
      // a value that is not on the map is being set, clear the map
      (drawControlRef.current as any)?.draw?.deleteAll();

      // and set this new value
      idRef.current = value!._id;

      featureRef.current = {
        id: value!._id,
        type: 'Feature',
        geometry: value as GeoPolygonType,
        properties: {},
      };

      updateFeatureOnMap();
    } else {
      // a value is being cleared.
      (drawControlRef.current as any)?.draw?.deleteAll();
      // generate a new id since this one was cleared out.
      idRef.current = uuid();
      featureRef.current = null;
    }
  }, [value]);

  function updateFeatureOnMap() {
    // the map controls may not have loaded yet.
    if (!drawControlRef.current) {
      setTimeout(updateFeatureOnMap, 100);
      return;
    }

    const draw = (drawControlRef.current! as any).draw!;

    // feature exists on map
    if (draw.get(idRef.current)) {
      draw.setFeatureProperty(
        idRef.current,
        'geometry',
        featureRef.current?.geometry
      );
    } else {
      // clear the map and add a new feature
      draw.deleteAll();

      draw.add(featureRef.current);
    }
  }

  function featureUpdated() {
    if (featureRef.current) {
      onChange({
        ...featureRef.current?.geometry,
        _id: idRef.current,
      } as GeoPolygonType);
    } else {
      onChange(null);
    }
  }

  function drawCreate(event: any) {
    if (featureRef.current) {
      // if there are more features other than the id we are tracking, remove
      // them.  the draw tools support multiple polygons, but we only allow
      // one.
      (drawControlRef.current as any)?.draw?.delete(
        event.features
          .map((feature: any) => feature.id)
          .filter((id: any) => id !== idRef.current)
      );
    } else {
      // there is no feature set yet, map the id to the id of this component.
      // get the first feature
      featureRef.current = event.features[0];

      (drawControlRef.current as any)?.draw?.setFeatureProperty(
        featureRef.current?.id,
        'id',
        idRef.current
      );

      featureRef.current!.id = idRef.current;
      featureUpdated();
    }
  }

  function drawDelete() {
    onChange(null);
    featureRef.current = null;
  }

  function drawUpdate(event: any) {
    // make sure we are only finding the feature we are tracking
    const feature = featureRef.current
      ? event.features?.find((feature: any) => feature.id === idRef.current)
      : null;

    if (feature) {
      featureRef.current = feature;
      featureUpdated();
    }
  }

  function styleLoaded() {
    triggerRender(Date.now());
  }

  const mapBoxStyle = useMemo(
    () => ({ minWidth: 200, minHeight: 200, ...style }),
    [style]
  );

  return (
    <div className={cx(styles.GeoPolygonInput, className)}>
      <MapboxMap
        animationOptions={animationOptions}
        center={center}
        containerStyle={mapBoxStyle}
        onStyleLoad={styleLoaded}
      >
        <DrawControl
          ref={drawControlRef}
          controls={controls}
          onDrawCreate={drawCreate}
          onDrawDelete={drawDelete}
          onDrawUpdate={drawUpdate}
        />
      </MapboxMap>
    </div>
  );
}
