import React from 'react';

import RequestCard from './RequestCard';
import StatusIndicator from './StatusIndicator';

import styles from './WorkOrderList.scss';

function WorkOrderList({ interactions, sectionTitle }: any) {
  if (!interactions || interactions.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {!!sectionTitle && (
        <div className={styles.titleContainer}>
          <StatusIndicator status={sectionTitle} />
          <h2>{sectionTitle}</h2>
        </div>
      )}
      <div className={styles.requestContainer}>
        {interactions.map((interaction: any) => (
          <div className={styles.requestWrapper} key={interaction.id}>
            <RequestCard key={interaction.id} interaction={interaction} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorkOrderList;
