import { useQuery } from '@apollo/client';
import { getContentsOnChannelsQuery } from '../graphql/query';
import { useMultiLanguage } from './useMultiLanguage';

export const useMultiChannelContent = ({
  channelIds,
}: {
  channelIds: Array<string>;
}) => {
  const { data } = useQuery(getContentsOnChannelsQuery, {
    variables: { channelIds },
  });

  const { translate } = useMultiLanguage();

  if (data?.me?.contentsOnChannels) {
    return translate({ model: [...data.me.contentsOnChannels] });
  }

  return [];
};
