import { useMutation } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { deleteUserGroupRole } from '../graphql/mutation';

export default function useDeleteUserRole(
  toastFunction: (message: any) => void,
  alertFunction: (title: any, message: any, error: any) => void
) {
  const [mutationFunc, { loading, error, data }] = useMutation(
    deleteUserGroupRole,
    {
      client: getClient(),
    }
  );

  const deleteUserRole = async (
    userGroupRoleId: LaneType.UUID,
    successMessage?: string
  ) => {
    try {
      await mutationFunc({
        variables: {
          id: userGroupRoleId,
        },
      });

      if (successMessage) {
        toastFunction(successMessage);
      }
    } catch (err) {
      if (err !== undefined) {
        alertFunction(
          'An error occurred while removing the user.',
          'Please try again',
          err
        );
      }
    }
  };

  return {
    deleteUserRole,
    loading,
    error,
    data,
  };
}
