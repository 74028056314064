import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { DEFAULT_COLOR } from 'lane-shared/helpers/constants';
import { ThemeType, CustomColorPaletteType } from 'lane-shared/types/Theme';

import Button from 'components/general/Button';
import ThemePaletteColorEdit from 'components/lane/ThemePaletteColorEdit';
import Panel from 'components/layout/Panel';
import { H4, M } from 'components/typography';

import styles from './ThemePaletteEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  theme: ThemeType;
  onThemeUpdated: (update: Partial<ThemeType>) => void;
};

export const paletteColors = [
  {
    type: 'primary',
    description:
      'web.admin.channel.settings.profile.themesTab.theme.palette.color.primary',
  },
  {
    type: 'secondary',
    description:
      'web.admin.channel.settings.profile.themesTab.theme.palette.color.secondary',
  },
  {
    type: 'background',
    description:
      'web.admin.channel.settings.profile.themesTab.theme.palette.color.background',
  },
];

/**
 * Page for setting the theme at the channel level. The whitelabel theme
 * is used as a default.
 */
export default function ThemePaletteEdit({
  className,
  style,
  theme,
  onThemeUpdated,
}: Props) {
  const { t } = useTranslation();

  function updateColor(name: string, color: string) {
    onThemeUpdated({
      palette: {
        ...theme.palette!,
        [name]: color,
      },
    });
  }

  function updateCustomColor(customColor: CustomColorPaletteType) {
    const ix = theme.palette!.custom.findIndex(
      ({ _id }) => customColor._id === _id
    );

    theme.palette!.custom[ix] = customColor;

    onThemeUpdated({
      palette: {
        ...theme.palette!,
        custom: [...theme.palette!.custom],
      },
    });
  }

  function removeCustomColor(id: string) {
    onThemeUpdated({
      palette: {
        ...theme.palette!,
        custom: theme.palette!.custom.filter(({ _id }) => id !== _id),
      },
    });
  }

  function addCustomColor() {
    onThemeUpdated({
      palette: {
        ...theme.palette!,
        custom: [
          ...theme.palette!.custom,
          {
            _id: uuid(),
            name: 'New Color',
            color: DEFAULT_COLOR,
          },
        ],
      },
    });
  }

  if (!theme) {
    return null;
  }

  return (
    <Panel className={cx(styles.ThemePaletteEdit, className)} style={style}>
      <H4 mb={2}>
        {t(
          'web.admin.channel.settings.profile.themesTab.theme.palette.color.palette.selection.title'
        )}
      </H4>
      <M mb={4} variant="secondary">
        {t(
          'web.admin.channel.settings.profile.themesTab.theme.palette.color.palette.selection.subtitle'
        )}
      </M>
      <H4 mb={2}>
        {t(
          'web.admin.channel.settings.profile.themesTab.theme.palette.color.palette'
        )}
      </H4>
      <div className={styles.colorPalette}>
        {paletteColors.map(p => (
          <ThemePaletteColorEdit
            className={styles.swatch}
            key={p.type}
            colorName={p.type}
            colorDescription={t(p.description)}
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            color={theme.palette![p.type]}
            onColorUpdated={color => color && updateColor(p.type, color)}
          />
        ))}
      </div>

      <H4 mb={2}>
        {t(
          'web.admin.channel.settings.profile.themesTab.theme.palette.custom.palette'
        )}
      </H4>
      <div className={styles.customColorPalette}>
        {theme.palette!.custom?.map(customColor => (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'colorName' is missing in type '{ classNa... Remove this comment to see the full error message
          <ThemePaletteColorEdit
            className={styles.swatch}
            key={customColor._id}
            color={customColor.color}
            onColorUpdated={color =>
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              updateCustomColor({ ...customColor, color })
            }
            onColorDeleted={() => removeCustomColor(customColor._id)}
          />
        ))}
      </div>
      <Button
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"primary"' is not assignable to type '"defau... Remove this comment to see the full error message
        color="primary"
        variant="outlined"
        startIcon={<Icon name="plus" />}
        onClick={addCustomColor}
        dataCy="addCustomColor"
      >
        {t(
          'web.admin.channel.settings.profile.themesTab.theme.palette.custom.palette.add'
        )}
      </Button>
    </Panel>
  );
}
