import React from 'react';

import cx from 'classnames';

import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';

import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';
import Slider from 'components/form/Slider';

import {
  unitTypeOptions,
  formatters,
  maxUnits,
  minUnits,
} from './reservableFeatureValues';

import styles from './ReservableFeatureTimeUnitSlider.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  units: number;
  unitType: ReservableUnitTypesEnum;
  onUnitsUpdated: (units: number) => void;
  onUnitTypeUpdated: (unitType: ReservableUnitTypesEnum) => void;
};

export default function ReservableFeatureTimeUnitSlider({
  className,
  style,
  units,
  unitType,
  onUnitsUpdated,
  onUnitTypeUpdated,
}: Props) {
  return (
    <div
      className={cx(styles.ReservableFeatureTimeUnitSlider, className)}
      style={style}
    >
      <Dropdown
        className={styles.dropDown}
        onValueChange={onUnitTypeUpdated}
        items={unitTypeOptions}
        initialValue={ReservableUnitTypesEnum.Minutes}
        isSearchable={false}
        placeholder="Select a Time Unit"
        value={unitType}
      />

      <Slider
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        className={styles.slider}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(units: number) => void' is not assignable t... Remove this comment to see the full error message
        onChange={onUnitsUpdated}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
        min={minUnits[unitType]}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
        max={maxUnits[unitType]}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
        step={minUnits[unitType]}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
        value={Number(units)}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '((value: number) => string) | ((value: any) ... Remove this comment to see the full error message
        formatLabel={formatters[unitType]}
      />
      <Input
        className={styles.input}
        value={units}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(units: number) => void' is not assignable t... Remove this comment to see the full error message
        onChange={onUnitsUpdated}
        input={{
          min: 1,
          max: maxUnits[unitType],
          step: minUnits[unitType],
        }}
        type="number"
      />
    </div>
  );
}
