import React, { useEffect } from 'react';

import { Html5Qrcode } from 'html5-qrcode';

import Label from 'components/general/Label';

import styles from './QRScanner.scss';

export enum CameraFacingMode {
  Front = 'user',
  Back = 'environment',
}

const onScanSuccess = (decodedText: string) => {
  window.Toast.show(decodedText);
};

type QrDimensions = {
  width: number;
  height: number;
};

type QRScannerProps = {
  placeholderText?: string;
  facingMode?: CameraFacingMode;
  onScanSuccess?: () => {};
  onScanFailed?: () => {};
  fps: number | undefined;
  qrBoxDimensions?: number | QrDimensions;
  aspectRatio?: number;
  disableFlip?: boolean;
};

// With this we are setting the QR box dimensions to always be 70% of the smaller edge of the video stream so it works on both mobile and PC platforms.
const getQRBoxDimensions = (
  viewfinderWidth: number,
  viewfinderHeight: number
) => {
  const minEdgePercentage = 0.7;
  const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
  const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
  return {
    width: qrboxSize,
    height: qrboxSize,
  };
};

QRScanner.defaultProps = {
  facingMode: CameraFacingMode.Back,
  fps: 100,
  qrBoxDimensions: getQRBoxDimensions,
  onScanSuccess,
};

const configureQrShadedRegion = () => {
  const html5QrCode = new Html5Qrcode('qr-web-reader');
  const oldRegion = document.getElementById('qr-shaded-region');

  if (oldRegion) {
    oldRegion.remove();
  }
  return html5QrCode;
};

export default function QRScanner({
  placeholderText,
  facingMode,
  fps,
  qrBoxDimensions,
  aspectRatio,
  disableFlip,
  onScanSuccess,
  onScanFailed,
}: QRScannerProps) {
  useEffect(() => {
    const html5QrCode = configureQrShadedRegion();
    window.history.pushState(null, document.title, window.location.href);

    function disableMouseClick(event: MouseEvent) {
      if (!html5QrCode.isScanning) {
        event.stopPropagation();
        event.preventDefault();
      }
    }

    function disableBrowserBackButton() {
      if (!html5QrCode.isScanning) {
        window.history.pushState(null, document.title, window.location.href);
      }
    }

    const startQRScanner = async () => {
      await html5QrCode.start(
        { facingMode },
        {
          fps,
          qrbox: qrBoxDimensions,
          aspectRatio,
          disableFlip,
        },
        onScanSuccess,
        onScanFailed
      );
    };

    const stopQRScanner = async () => {
      if (html5QrCode.isScanning) {
        await html5QrCode.stop();
        html5QrCode.clear();
      }
    };

    /*
     Since camera hardware takes time to load, so we have added two event listeners which will disable
     browser back button until the camera is loaded so that we will not face any issues while stopping the 
     camera once component is unmounted.
    */
    document.addEventListener('click', disableMouseClick, true);
    window.addEventListener('popstate', disableBrowserBackButton);
    startQRScanner();

    return () => {
      stopQRScanner();
      document.removeEventListener('click', disableMouseClick, true);
      window.removeEventListener('popstate', disableBrowserBackButton);
    };
  }, [
    facingMode,
    fps,
    qrBoxDimensions,
    aspectRatio,
    disableFlip,
    onScanSuccess,
    onScanFailed,
  ]);

  return (
    <>
      {placeholderText && (
        <Label h1 className={styles.placeholder}>
          {placeholderText}
        </Label>
      )}
      <div id="qr-web-reader" className={styles.scanner} />
    </>
  );
}
