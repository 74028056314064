import React from 'react';

import { useTranslation } from 'react-i18next';

import { FlexWrapEnum } from 'lane-shared/types/blocks/Flex';

import ButtonStrip from '../general/ButtonStrip';

const iconSet = 'Material';

type Props = {
  onChange: (value: FlexWrapEnum) => void;
  value: FlexWrapEnum | null;
  disabled?: boolean;
};

export default function FlexWrapInput({ onChange, value, disabled }: Props) {
  const { t } = useTranslation();

  const flexWrapValues = [
    {
      value: FlexWrapEnum.Wrap,
      name: t('web.admin.channel.content.flexWrapInput.wrap'),
      iconSet,
    },
    {
      value: FlexWrapEnum.NoWrap,
      name: t('web.admin.channel.content.flexWrapInput.noWrap'),
      iconSet,
    },
    {
      value: FlexWrapEnum.WrapReverse,
      name: t('web.admin.channel.content.flexWrapInput.wrapReverse'),
      iconSet,
    },
  ] as const;

  return (
    <ButtonStrip
      buttons={[...flexWrapValues]}
      onClick={onChange}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ buttons: ({ readonly value: FlexWrapEnum.W... Remove this comment to see the full error message
      disabled={disabled}
      selected={value}
    />
  );
}
