import gql from 'graphql-tag';

export const updateRelayOutputMutation = gql`
  mutation UpdateRelayOutput(
    $updateRelayOutputId: ID!
    $input: UpdateRelayOutputInput!
  ) {
    updateRelayOutput(id: $updateRelayOutputId, input: $input) {
      id
      buildingId
      name
      description
      pulse
      delay
      accessPoint {
        id
        name
      }
    }
  }
`;
