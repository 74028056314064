import useFlag from './useFlag';
import { FeatureFlag } from '../types/FeatureFlag';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-06-10T14:12:26+0000.
 */
export function useReservableManagementCalendarV2Enabled() {
  const isEnabled = useFlag<boolean>(
    FeatureFlag.ReservableManagementCalendarV2,
    false
  );

  return Boolean(isEnabled);
}
