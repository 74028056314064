import * as yup from 'yup';

import { validatePasswordV2 } from './validatePassword';

export default yup.object().shape({
  password1: validatePasswordV2,
  password2: validatePasswordV2.oneOf(
    [yup.ref('password1')],
    'Passwords must match'
  ),
});
