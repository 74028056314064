import React, { useEffect } from 'react';

import { Redirect, useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import getContentChannelIntegration from 'lane-shared/helpers/integrations/getContentChannelIntegration';

import useIsAdminView from 'hooks/useIsAdminView';
import { constructOIDCRedirectUrl } from 'lane-shared/helpers/integrations/OIDC/definition';

export default function OidcWebviewPage({ content }: any) {
  const history = useHistory();
  const [isAdminView] = useIsAdminView();
  const { data, loading } = useQuery(getContentChannelIntegration, {
    variables: { id: content._id },
    fetchPolicy: 'network-only',
  });

  const oidcRedirectUrl = constructOIDCRedirectUrl({
    integrationId: data?.me.content.integration._id,
    thirdPartyRedirectUrl: data?.me.content.integration.settings?.redirectURL,
  });

  useEffect(() => {
    if (isAdminView || loading || !data) {
      return;
    }

    window.open(oidcRedirectUrl, '_blank');
    history.goBack();
  }, [loading]);

  if (!loading && !data) {
    return (
      <Redirect
        to={{
          pathname: `${routes.identifier}?
            contentPath=${routes.content.replace(':id', content._id)}`,
        }}
      />
    );
  }

  return null;
}
