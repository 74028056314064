import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const RitualDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Ritual,
  allowsMultipleInstances: false,
  hasCustomUI: true,
  friendlyName: 'Ritual Integration',
  description: `Defines the required properties for a Ritual integration.`,
  category: IntegrationCategoryEnum.FoodOrdering,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.WebView,
  settings: {},
  properties: {},
};

export { RitualDefinition };
