import humanizeDuration from 'humanize-duration';

const shortHumanizer = humanizeDuration.humanizer({
  units: ['h', 'm'],
  spacer: ' ',
  language: 'shortEn',
  delimiter: ' ',
  languages: {
    shortEn: {
      h: () => 'hr',
      m: () => 'min',
    },
  },
});

const pluralShortHumanizer = humanizeDuration.humanizer({
  units: ['h', 'm'],
  spacer: ' ',
  language: 'shortEn',
  delimiter: ' ',
  languages: {
    shortEn: {
      h: () => 'hrs',
      m: () => 'mins',
    },
  },
});

const longHumanizer = humanizeDuration.humanizer({
  units: ['h', 'm'],
  spacer: ' ',
  delimiter: ' ',
});

export function friendlyMinutes(
  value: number,
  format: 'short' | 'long' | 'plural short' = 'plural short'
) {
  const milliseconds = value * 60 * 1000;

  if (format === 'long') {
    return longHumanizer(milliseconds);
  }

  if (format === 'short') {
    return shortHumanizer(milliseconds);
  }

  return pluralShortHumanizer(milliseconds);
}
