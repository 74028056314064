import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';
import './types';

const ProxyClickDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.ProxyClick,
  hasCustomUI: true,
  friendlyName: 'Proxyclick',
  description: `Defines the properties required for a Proxyclick integration.`,
  category: IntegrationCategoryEnum.VisitorRegistration,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'The base URL of the Proxyclick API endpoint',
      type: 'Url',
      default: 'https://api.proxyclick.com/',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    supportEmail: {
      friendlyName: 'Support Email',
      type: 'Email',
    },
    client_id: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    client_secret: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    grant_type: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    hasCustomFields: {
      friendlyName: 'Require custom fields',
      type: 'Boolean',
    },
  },
  interactionData: {
    _id: {
      friendlyName: 'id',
      description: 'ID for request',
      type: 'String',
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    title: {
      friendlyName: 'Title',
      description: 'The title of this meeting.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    description: {
      friendlyName: 'Description',
      description: 'The description of this meeting.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    company: {
      friendlyName: 'Company',
      description: 'The object in the Proxyclick system for this company.',
      type: 'ProxyClickCompany',
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    companyId: {
      friendlyName: 'Company ID',
      description: 'The unique id in the Proxyclick system for this company.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    hostId: {
      friendlyName: 'Host ID',
      description: 'The unique id in the Proxyclick system for this host.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    visitors: {
      friendlyName: 'Visitors',
      description: 'The list of visitors in this meeting.',
      type: 'ProxyClickVisitor',
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    host: {
      friendlyName: 'Host',
      description: 'The Host of this meeting.',
      type: 'ProxyClickHost',
      validators: [
        {
          name: 'Required',
          value: false,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },

    startAt: {
      friendlyName: 'Starts At',
      description: 'The date & time this meeting starts.',
      type: 'DateTime',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    endAt: {
      friendlyName: 'Ends At',
      description: 'The date & time this meeting ends.',
      type: 'DateTime',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    customFields: {
      type: 'Any',
    },
  },
  mobilePermissions: [],
};

export default ProxyClickDefinition;
