import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'lane-shared/config';

export const useInviteUsersRedirect = ({
  channelSlug,
  isOfficeExperience,
}: {
  channelSlug: string;
  isOfficeExperience: boolean;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (!isOfficeExperience) {
    const mfInviteUserRedirect = () => {
      history.push(routes.channelAdminUsersInvite.replace(':id', channelSlug));
    };

    return {
      inviteUsersRedirect: mfInviteUserRedirect,
      inviteUsersLabel: t('web.admin.channel.users.bulkInvite.cta'),
    };
  }

  const inviteUsersRedirect = () => {
    history.push(routes.channelAdminUsersInvite.replace(':id', channelSlug));
  };

  return {
    inviteUsersRedirect,
    inviteUsersLabel: t('web.admin.channel.users.bulkInvite.cta'),
  };
};
