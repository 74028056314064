import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import convertMjmlStyleProps from './convertMjmlStyleProps';

import styles from './styles.scss';

export default function MjmlSocialElement({
  className,
  style,
  ...otherProps
}: any) {
  let icon = 'facebook';

  if (otherProps.name.includes('instagram')) {
    icon = 'instagram';
  } else if (otherProps.name.includes('facebook')) {
    icon = 'facebook';
  } else if (otherProps.name.includes('twitter')) {
    icon = 'twitter';
  } else if (otherProps.name.includes('linkedin')) {
    icon = 'linkedin';
  }

  return (
    <div
      className={cx(styles.MjmlSocialElement, className)}
      style={{ ...style, ...convertMjmlStyleProps(otherProps) }}
    >
      <Icon name={icon} />
    </div>
  );
}
