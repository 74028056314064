import { Validators } from 'lane-shared/properties/validators';

import { ValidatorRegistry } from '../types/ValidatorRegistry';
import { SupportedSchema } from '../types/baseTypes/TypeBase';
import { PropertiesInterface } from '../types/properties/Property';
import {
  SecurityModesEnum,
  SecurityRuleTypeEnum,
} from '../types/properties/PropertySecurity';
import Types from './Types';
import './baseTypes';

/**
 * Creates a validation object using yup from a property type definition.
 *
 * depending on the security object passed in, it will include or exclude
 * different validation based on the security settings of each property.
 */
export default function createShapeFromProperties(
  obj?: PropertiesInterface,
  security?: {
    mode: SecurityModesEnum;
    type: SecurityRuleTypeEnum;
  }
) {
  if (!obj) {
    return {};
  }

  const shape: Record<string, SupportedSchema> = {};

  Object.entries(obj).forEach(([key, def]) => {
    const type = Types.create(def);

    if (def.validators) {
      def.validators.forEach(validator => {
        const Validator = ValidatorRegistry.create(type, validator);

        if (!Validator) {
          throw new Error(
            `Invalid validator ${validator.name} attached to ${key}`
          );
        }

        if (Validator.arrayOnly) {
          return;
        }

        // if something is required, it should only be validated on create.
        // when updating an object this entry may not be being supplied.
        if (
          validator.name === Validators.Required.name &&
          security?.mode === SecurityModesEnum.Update
        ) {
          // todo: we actually do need to attach a validator that disallows
          // someone setting a required value to null (i.e. removing a value
          // that was already set.
          return;
        }

        // A security object exists on this definition, determine if we should
        // attach a Required validator.  There are instances where this is
        // not Required, because this user doesn't even have the ability
        // to set this data.
        if (
          validator.name === Validators.Required.name &&
          security &&
          def.secure
        ) {
          // this is to validate an end user creating something.
          // we can ignore some system only validation.
          if (
            security.mode === SecurityModesEnum.Create &&
            security.type === SecurityRuleTypeEnum.Creator &&
            def.secure.create &&
            !def.secure.create.some(
              secure => secure.type === SecurityRuleTypeEnum.Creator
            )
          ) {
            return;
          }
        }

        Validator.attach(type);
        shape[key] = type.schema;
      });
    }

    if (def.isArray) {
      if (def.validators) {
        def.validators.forEach(validator => {
          const Validator = ValidatorRegistry.create(type, validator);

          if (!Validator) {
            throw new Error(
              `Invalid validator ${validator.name} attached to ${key}`
            );
          }

          if (Validator.array) {
            Validator.attach(type);
            shape[key] = type.arraySchema;
          }
        });
      }
    }
  });

  return shape;
}
