/**
 * clamps a number between two values.
 *
 * @param min
 * @param value
 * @param max
 * @returns {*}
 */
export default function clamp(min: any, value: any, max: any) {
  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
}
