import { getClient } from 'lane-shared/apollo';
import updateGroupRoleMutation from 'lane-shared/graphql/mutation/updateGroupRole';

import { Group } from '../models/Group';

export type UpdateGroupRoleSuccess = {
  key: 'success';
};
export type UpdateGroupFailed = {
  key: 'updateGroupFailed';
  err: Error | string;
};

export async function sendUpdateGroupRoleMutation(
  groupRole: Group
): Promise<UpdateGroupRoleSuccess | UpdateGroupFailed> {
  const client = getClient();
  try {
    // @ts-expect-error ts-migrate(2339) FIXME: Property '_id' does not exist on type 'Group'.
    const { _id, isPublic, name, permissions, maxUsers } = groupRole;

    await client.mutate({
      mutation: updateGroupRoleMutation,
      variables: {
        groupRole: {
          _id,
          isPublic,
          maxUsers,
          name,
          permissions: {
            set: permissions,
          },
        },
      },
    });

    return { key: 'success' };
  } catch (err) {
    return { key: 'updateGroupFailed', err };
  }
}
