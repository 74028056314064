/* eslint-disable no-useless-escape */

/**
 * Fuzzy Match a search string against another string.
 *
 * from https://codereview.stackexchange.com/questions/23899/faster-javascript-fuzzy-string-matching-function/23905
 * @param str string the string to search against
 * @param pattern string the pattern to search for
 */
export default function fuzzyMatch(str: string = '', pattern: string): boolean {
  if (!str) {
    return false;
  }

  return new RegExp(
    `^${str.replace(/./g, x =>
      /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/.test(x) ? `\\${x}?` : `${x}?`
    )}$`,
    'gi'
  ).test(pattern);
}
