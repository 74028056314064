import React from 'react';

import cx from 'classnames';

import convertMjmlStyleProps from './convertMjmlStyleProps';

import styles from './styles.scss';

export default function MjmlColumn({
  className,
  style,
  children,
  ...otherProps
}: any) {
  return (
    <div
      className={cx(styles.MjmlColumn, className)}
      style={{ ...style, ...convertMjmlStyleProps(otherProps) }}
    >
      {children}
    </div>
  );
}
