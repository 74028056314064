import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { explodeValidators } from 'lane-shared/helpers/properties';
import { GuestInviteFeatureProperties } from 'lane-shared/types/features/GuestInviteFeatureProperties';

import Input from 'components/form/Input';
import Slider from 'components/form/Slider';
import Toggle from 'components/form/Toggle';
import Label from 'components/general/Label';
import { FeatureRendererPropsType } from 'components/renderers/features/FeatureRendererPropsType';

import styles from './GuestInvite.scss';

export default function GuestInviteFeatureRenderer({
  className,
  style,
  feature,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<GuestInviteFeatureProperties>) {
  const properties = contentFeature?.feature as GuestInviteFeatureProperties;
  const { maxValidator } = explodeValidators(
    feature.properties!.maxGuests!.validators
  );

  const minGuests = properties?.minGuests || 0;
  const maxGuests = properties?.maxGuests || (maxValidator!.value as number);

  const { t } = useTranslation();

  function updateMinGuests(newMinGuests: number | string) {
    let minGuests = Number(newMinGuests);

    if (minGuests < 0) {
      minGuests = 0;
    } else if (minGuests > maxGuests) {
      minGuests = maxGuests;
    }

    onFeatureUpdated({ minGuests });
  }

  function updateMaxGuests(newMaxGuests: number | string) {
    let maxGuests = Number(newMaxGuests);

    if (maxGuests < minGuests) {
      maxGuests = minGuests;
      // @ts-expect-error ts-migrate(2352) FIXME: Conversion of type 'boolean' to type 'number' may ... Remove this comment to see the full error message
    } else if ((maxGuests > maxValidator!.value) as number) {
      maxGuests = maxValidator!.value as number;
    }

    onFeatureUpdated({ maxGuests });
  }

  return (
    <div className={cx(styles.GuestInvite, className)} style={style}>
      <Label h1>{t(feature.friendlyName)}</Label>

      <p>{t(feature.description)}</p>

      {contentFeature && (
        <section>
          <fieldset>
            <Label
              TooltipComponent={t(
                feature.properties!.showLaneUsers!.description
              )}
            >
              {t(
                'web.content.feature.guestInvite.properties.showLaneUsers.label'
              )}
            </Label>
            <div className={styles.row}>
              <Toggle
                value={properties.showLaneUsers}
                onChange={() =>
                  onFeatureUpdated({ showLaneUsers: !properties.showLaneUsers })
                }
              />
            </div>
          </fieldset>

          <fieldset>
            <Label
              TooltipComponent={t(feature.properties!.minGuests!.description)}
            >
              {t('web.content.feature.guestInvite.properties.minGuests.label')}
            </Label>
            <div className={styles.row}>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                className={styles.slider}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(newMinGuests: string | number) => void' is ... Remove this comment to see the full error message
                onChange={updateMinGuests}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={0}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                max={maxGuests}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={minGuests}
              />
              <Input
                className={styles.input}
                value={minGuests}
                onChange={updateMinGuests}
                input={{
                  min: 0,
                  max: maxGuests,
                }}
                type="number"
              />
            </div>
          </fieldset>
          <fieldset>
            <Label
              TooltipComponent={t(feature.properties!.maxGuests!.description)}
            >
              {t('web.content.feature.guestInvite.properties.maxGuests.label')}
            </Label>
            <div className={styles.row}>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                className={styles.slider}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(newMaxGuests: string | number) => void' is ... Remove this comment to see the full error message
                onChange={updateMaxGuests}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={properties.minGuests}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                max={maxValidator!.value as number}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={maxGuests}
              />
              <Input
                className={styles.input}
                value={maxGuests}
                onChange={updateMaxGuests}
                input={{
                  min: minGuests,
                  max: maxValidator!.value as number,
                }}
                type="number"
              />
            </div>
          </fieldset>
        </section>
      )}
    </div>
  );
}
