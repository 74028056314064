import React from 'react';

import { v4 as uuid } from 'uuid';

import { BLOCK_FOR } from 'lane-shared/helpers/constants/blocks';

import Dropdown from '../form/Dropdown';

type OwnProps = {
  onChange?: (...args: any[]) => any;
  onValueChange?: (...args: any[]) => any;
  name?: string;
  color?: string;
  schema?: {
    label?: string;
    value?: string;
  };
  for?: any[]; // TODO: PropTypes.oneOf(Object.values(BLOCK_FOR))
  initialValue?: string;
  placeholder?: string;
  value?: string | number;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof ContentBlockDropdown.defaultProps;

function ContentBlockDropdown(props: Props) {
  const items = (props as any).blocks
    .filter((card: any) => (props as any).for.includes(card.for))
    .map((card: any) => ({
      label: card.name,
      value: card._id,
    }));

  // @ts-expect-error
  return <Dropdown {...props} items={items} />;
}

ContentBlockDropdown.defaultProps = {
  name: `Dropdown-${uuid()}`,
  color: 'primary',
  schema: {
    label: 'label',
    value: 'value',
  },
  for: [BLOCK_FOR.CONTENT],
  initialValue: null,
  placeholder: 'Select Card',
  value: null,
  onChange: () => {},
  onValueChange: () => {},
};

export default ContentBlockDropdown;
