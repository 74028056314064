import React from 'react';

import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { useContentQuery } from 'lane-shared/hooks';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import useUnavailableDatesForContent from 'lane-shared/hooks/integrations/shopify/useUnavailableDatesForContent';
import {
  BurstDraftInteractionData,
  ShopifyBurstInteractionType,
  SHOPIFY_BURST_MAX_RANGE_SIZE,
  SHOPIFY_BURST_MIN_RANGE_SIZE,
  SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE,
} from 'lane-shared/types/integrations/ShopifyBurst';

import BurstDateSelector from '../../BurstDateSelector';
import handleSave from './helpers/saveRoomHelper';

type Props = {
  burstData: ShopifyBurstInteractionType;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  onClose: () => void;
  modalTarget?: string | null;
};

export default function BurstEditDate({
  onDataUpdated,
  burstData,
  onClose,
}: Props) {
  const minDate = DateTime.local()
    .setZone(burstData.portTimeZone!)
    .startOf('day')
    .plus({
      weeks: SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE,
    })
    .toJSDate();

  const { maxDate } = useShopifyBurstContext();

  const { primaryRoomBooking, isDiyBurst } = burstData;
  const contentId = isDiyBurst
    ? undefined
    : (primaryRoomBooking?.contentData as { _id: string } | undefined)?._id;
  const { content } = useContentQuery({
    contentId,
    forPublic: true,
    includeInteractions: false,
  });
  const {
    unavailableDateRanges: unavailableDatesAsDateRanges,
  } = useUnavailableDatesForContent({
    content,
    timeZone: burstData.portTimeZone,
    startDate: minDate,
    endDate: maxDate,
  });
  // add the ability to new range select days from existing range
  const filteredUnavailableDatesAsDateRanges = unavailableDatesAsDateRanges.filter(
    range =>
      range.startDate?.toString() !== burstData.dateRange.startDate?.toString()
  );

  function updateDateRange(dateRange: LaneType.DateRange | undefined) {
    // during date change we need to take care about reservation feature too
    // so we are reusing the existing room save helper.
    if (dateRange?.startDate && dateRange?.endDate) {
      handleSave({
        data: {
          ...burstData,
          dateRange,
        },
        content,
        onDataUpdated,
        onClose,
      });
    }
  }
  return (
    <BurstDateSelector
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      timeZone={burstData.portTimeZone}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'string'.
      startDate={burstData.dateRange.startDate}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'string'.
      endDate={burstData.dateRange.endDate}
      minRangeSize={SHOPIFY_BURST_MIN_RANGE_SIZE}
      maxRangeSize={SHOPIFY_BURST_MAX_RANGE_SIZE}
      unavailableDateRanges={filteredUnavailableDatesAsDateRanges}
      maxDate={maxDate}
      minDate={minDate}
      onChange={interval => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateRange' is not assignable to ... Remove this comment to see the full error message
        updateDateRange(interval);
      }}
      onClear={onClose}
    />
  );
}
