import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '../../../general';

import styles from './UserIntegration.scss';

export default function UserIntegration({ content, onLogout }: any) {
  const { settings } = content.integration;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  async function logout() {
    try {
      await window.Alert.confirm({
        title: t('Logout of {{name}}?', { name: settings.name }),
        message: t(
          'You are about to logout of {{name}} and un-link your accounts. Are you sure you want to do this?',
          { name: settings.name }
        ),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);

    onLogout();

    setLoading(false);
  }

  return (
    <div className={styles.UserIntegration}>
      <p>
        <Button disabled={loading} onClick={logout}>
          {t('Logout')}
        </Button>
      </p>
    </div>
  );
}
