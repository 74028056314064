import React, { useState } from 'react';
import { Button, SidePanel } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import {
  AddProductToContentDetails,
  SupportedPaymentCurrency,
  Product,
  EMPTY_CATEGORY_DETAILS,
  EMPTY_PRODUCT_DETAILS,
  MarkupAmountType,
  TaxAmountType,
  ProductCategory,
} from 'lane-shared/domains/billingPayments/types';

import styles from './index.scss';

import { ProductType } from 'graphql-query-contracts';
import { AddProductRadioGroup } from 'pages/portal/admin/channel/charges-invoices/add-items-to-charge/AddProductForm/AddProductRadioGroup';
import { AddProductCategoryDropdown } from './AddProductCategoryDropdown';
import { AddProductDropdown } from './AddProductDropdown';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { AddProductSummary } from './AddProductSummary';
import useUserLocale from 'hooks/useUserLocale';

export function AddProductPanel({
  handleProductSelection,
  currency,
  isPanelOpen,
  onPanelClose,
  inclusivePricing,
}: {
  handleProductSelection: (product: AddProductToContentDetails) => void;
  currency: SupportedPaymentCurrency;
  isPanelOpen: boolean;
  onPanelClose: () => void;
  inclusivePricing: boolean;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const [selectedProductType, setSelectedProductType] = useState(
    ProductType.ProductTypeUnknown
  );

  const [selectedCategory, setSelectedCategory] = useState(
    EMPTY_CATEGORY_DETAILS
  );

  const [
    selectedProduct,
    setSelectedProduct,
  ] = useState<AddProductToContentDetails>(EMPTY_PRODUCT_DETAILS);

  const resetSelectedProduct = () => {
    setSelectedProduct(EMPTY_PRODUCT_DETAILS);
    setSelectedCategory(EMPTY_CATEGORY_DETAILS);
  };

  const updateProductType = (value: string) => {
    setSelectedProductType(prev => {
      if (value !== prev) {
        resetSelectedProduct();
      }
      return value as ProductType;
    });
  };

  const handleCategorySelection = (selectedCategory: ProductCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const onProductSelection = (selectedProductFromDropdown: Product) => {
    const priceComponents = productToPriceComponents(
      (selectedProductFromDropdown as unknown) as Product
    );

    // price here is already accounting for tax inclusion and exclusion.
    // always treat the values as tax-exclusive
    // eslint-disable-next-line prefer-const
    let { netPrice, rate, grossTax, listPrice } = getPricing({
      currency,
      locale,
      priceComponents,
    });

    if (inclusivePricing) {
      listPrice += grossTax;
    }

    setSelectedProduct({
      ...selectedProduct,
      productId: selectedProductFromDropdown.id,
      productGroupId: selectedProductFromDropdown.groupId,
      name: selectedProductFromDropdown.name,
      description: selectedProductFromDropdown.description,
      rate: rate.toString(),
      markup: {
        type:
          selectedProductFromDropdown?.markup?.markupAmountType ||
          MarkupAmountType.MarkupAmountTypePercentage,
        value: selectedProductFromDropdown?.markup?.value.toString() || '',
      },
      tax: {
        type:
          selectedProductFromDropdown?.tax?.taxAmountType ||
          TaxAmountType.TaxAmountTypePercentage,
        value: selectedProductFromDropdown?.tax?.value.toString() || '',
      },
      productCategoryId: selectedCategory.productCategoryId,
      productCategoryName: selectedCategory.name,
      productCategoryType: selectedCategory.productType,
      listPrice: listPrice.toString(),
      grossTax: grossTax.toString(),
      netPrice: netPrice.toString(),
      creditAmount: selectedProductFromDropdown?.creditAmount?.toString(),
    });
  };

  return (
    <SidePanel
      id="add-products-panel"
      isOpen={isPanelOpen}
      onClose={() => {
        onPanelClose();
      }}
      header={t('web.content.features.paymentv2.addProductsPanel.header')}
      footerActions={
        <>
          <Button
            dataCy="addProductButton"
            size="large"
            onClick={() => {
              handleProductSelection(selectedProduct);
              onPanelClose();
            }}
            disabled={!selectedProduct.productId}
          >
            {t(
              'web.content.features.paymentv2.addProductsPanel.addProductButton'
            )}
          </Button>
          <Button
            dataCy="cancelButton"
            size="large"
            onClick={() => {
              resetSelectedProduct();
              onPanelClose();
            }}
            variant="secondary"
          >
            {t('web.content.features.paymentv2.addProductsPanel.cancelButton')}
          </Button>
        </>
      }
    >
      <div className={styles.panelSection}>
        <AddProductRadioGroup
          productType={selectedProductType}
          updateProductType={updateProductType}
        />
      </div>
      <div className={styles.panelSection}>
        <AddProductCategoryDropdown
          selectedCategory={selectedCategory}
          selectedProductType={selectedProductType}
          handleCategorySelection={handleCategorySelection}
        />
      </div>
      <div className={styles.panelSection}>
        <AddProductDropdown
          selectedProduct={selectedProduct}
          selectedCategory={selectedCategory}
          handleProductSelection={onProductSelection}
        />
      </div>
      {selectedProduct.productId && (
        <div className={styles.panelSection}>
          <AddProductSummary
            currency={currency}
            locale={locale}
            product={selectedProduct}
            inclusivePricing={inclusivePricing}
          />
        </div>
      )}
    </SidePanel>
  );
}
