/**
 * Simple helper for import/export of templates. Gets a trinary value t
 * will be set on TemplateImportOptions or TemplateExportOptions
 */
export default function getKeyValue(obj: any, key: any): boolean | undefined {
  if (Object.hasOwnProperty.call(obj, key)) {
    return true;
  }

  return undefined;
}
