import { i18n } from 'lane-shared';

import { getUserLogins } from 'lane-shared/graphql/user';
import {
  UserLoginStatusEnum,
  UserLoginType,
  UserLoginTypeEnum,
} from 'lane-shared/types/UserLogin';

import { getClient } from '../apollo';

type GetUserLoginsQueryResponse = {
  me: {
    user: {
      logins: UserLoginType[];
    };
  };
};

type AlertCallbackType = (title: string, message: string) => void;

const useVerifiedEmailConfirmation = (
  alertCallback: AlertCallbackType
): {
  confirmEmailVerification: () => Promise<boolean>;
} => {
  const confirmEmailVerification = async (): Promise<boolean> => {
    const {
      data: {
        me: {
          user: { logins },
        },
      },
      error,
    } = await getClient().query<GetUserLoginsQueryResponse>({
      query: getUserLogins,
      fetchPolicy: 'network-only',
    });

    if (error) {
      await alertCallback(i18n.t('An Error Occurred'), i18n.t(error.message));

      return false;
    }

    // check if primary login is verified.
    const primaryLogin = logins.find(
      login => login.isPrimary && login.type === UserLoginTypeEnum.Email
    );
    if (!primaryLogin || primaryLogin.status !== UserLoginStatusEnum.Verified) {
      return false;
    }
    return true;
  };

  return {
    confirmEmailVerification,
  };
};

export default useVerifiedEmailConfirmation;
