import { doDateRangesOverlap } from 'lane-shared/helpers/dates';
import areDateRangesEqual from 'lane-shared/helpers/dates/areDateRangesEqual';
import getDaysInTimeRange from 'lane-shared/helpers/dates/getDaysInTimeRange';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

export default function validateSelectedDateRange({
  reservation,
  dateRange,
  unavailableDatesAsDateRanges,
  timeZone,
}: {
  reservation: DateRangeType;
  dateRange?: DateRangeType;
  unavailableDatesAsDateRanges: DateRangeType[];
  timeZone: string;
}) {
  if (areDateRangesEqual(reservation, dateRange)) {
    return true;
  }

  if (!dateRange) {
    return false;
  }

  const isUnavailable = unavailableDatesAsDateRanges?.some(
    unavailableDateRange => doDateRangesOverlap(unavailableDateRange, dateRange)
  );

  if (isUnavailable) {
    return false;
  }

  const selectedRange = getDaysInTimeRange(
    dateRange.startDate,
    dateRange.endDate,
    timeZone
  );

  if (!selectedRange.length) {
    return false;
  }

  return !selectedRange.some(day => day.weekday > 5);
}
