import { useState, useRef, useContext, useEffect } from 'react';

import { getClient } from '../apollo';
import { UserDataContext } from '../contexts';
import sendInvoicePayment from '../graphql/essensys/sendInvoicePayment';
import { currencyFormatter } from '../helpers/formatters';
import Validators from '../helpers/validators';
import { CreditCardType } from '../types/Stripe';
import { PaymentProviderEnum } from '../types/payment/PaymentProviderEnum';
import useEssensysInvoice from './useEssensysInvoice';
import { useEssensysPaymentSettingsForInvoice } from './useEssensysPaymentSettings';
import useOperatePaymentAccount from './useOperatePaymentAccount';

type Props = {
  invoiceId: string;
  onPaymentSuccess: () => void;
  onPaymentFailed: (err: any) => void;
};

export default function useInvoice({
  invoiceId,
  onPaymentSuccess,
  onPaymentFailed,
}: Props) {
  const { user } = useContext(UserDataContext);

  const {
    invoice,
    loading,
    error,
    pdfUrl,
    formattedStartDate,
    formattedEndDate,
  } = useEssensysInvoice({ invoiceId });

  const formatter = currencyFormatter({
    currency: invoice.currencyCode,
    locale: user?.locale,
  });

  const {
    essensysPaymentSettings,
    error: essensysPaymentSettingsError,
  } = useEssensysPaymentSettingsForInvoice(invoiceId);

  const {
    paymentAccount: selectedPaymentAccount,
    paymentAccounts,
    loading: operatePaymentAccountsLoading,
    called: operatePaymentAccountsCalled,
    error: operatePaymentAccountsError,
    onSelectPaymentAccount: setSelectedPaymentAccount,
    createPaymentAccount,
    operatePaymentAccountError,
  } = useOperatePaymentAccount({
    publicKey: essensysPaymentSettings?.paymentProcessor?.publicKey,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentProcessorEnum | undefined' is not ass... Remove this comment to see the full error message
    paymentProcessor: essensysPaymentSettings?.paymentProcessor?.type,
  });

  useEffect(() => {
    const paymentProcessorType =
      essensysPaymentSettings?.paymentProcessor?.type;
    const operatePaymentAccountsReady =
      !operatePaymentAccountsLoading &&
      operatePaymentAccountsCalled &&
      !operatePaymentAccountsError &&
      paymentAccounts &&
      paymentProcessorType;

    if (!operatePaymentAccountsReady) {
      return;
    }

    if (!user?.profile.name) {
      return;
    }

    if (!paymentAccounts?.length) {
      createPaymentAccount(
        user?.profile.name.trim(),
        'Payment account to make transactions against Operate'
      );
      return;
    }

    if (!selectedPaymentAccount) {
      setSelectedPaymentAccount(paymentAccounts[0]);
    }
  }, [operatePaymentAccountsLoading, paymentAccounts, essensysPaymentSettings]);

  const placePayRef = useRef({
    reject: () => {},
    resolve: () => {},
  });

  const [partialAmount, setPartialAmount] = useState('');
  const [isPartialAmount, setIsPartialAmount] = useState(false);
  const [partialPaymentError, setPartialPaymentError] = useState<any>(null);

  const [isPlacePayOpen, setIsPlacePayOpen] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [selectedCard, setSelectedCard] = useState<CreditCardType | null>(null);

  const [submitting, setSubmitting] = useState(false);

  async function submitPayment() {
    if (!selectedPaymentAccount) {
      return;
    }

    // Default to outstanding amount
    let paymentAmount = invoice.outstanding;

    // Validate partial payment amount if toggled
    if (isPartialAmount) {
      const parsedPartialAmount: number = parseFloat(partialAmount || '0');
      if (
        !Validators.essensys.validatePaymentAmount(
          parsedPartialAmount,
          invoice.outstanding
        )
      ) {
        setPartialPaymentError(new Error('Invalid payment amount'));
        return;
      }
      paymentAmount = parsedPartialAmount;
    }

    setPaymentError(null);

    let placePayData;
    let stripeData;

    if (selectedPaymentAccount.type === PaymentProviderEnum.PlacePay) {
      try {
        placePayData = await new Promise((resolve, reject) => {
          setIsPlacePayOpen(true);
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: unknown) => void' is not assignable ... Remove this comment to see the full error message
          placePayRef.current.resolve = resolve;
          placePayRef.current.reject = reject;
        });
      } catch (err) {
        setIsPlacePayOpen(false);
        setPaymentError(err);
        return;
      }
    } else if (selectedPaymentAccount.type === PaymentProviderEnum.Stripe) {
      stripeData = {
        cardId: selectedCard?.id,
      };
    }

    try {
      setSubmitting(true);

      // send into the api
      await getClient().mutate({
        refetchQueries: ['getEssensysInvoice'],
        mutation: sendInvoicePayment,
        variables: {
          invoiceId: invoice._id,
          paymentAccountId: selectedPaymentAccount._id,
          currency: invoice.currencyCode,
          paymentAmount,
          stripeData,
          placePayData,
        },
      });

      onPaymentSuccess();
      setIsPartialAmount(false);
      setPartialAmount('');
    } catch (err) {
      onPaymentFailed(err);
      setPaymentError(err);
    }

    setSubmitting(false);
  }

  const isPayDisabled =
    loading ||
    operatePaymentAccountsLoading ||
    isPlacePayOpen ||
    submitting ||
    invoice.paid ||
    !essensysPaymentSettings ||
    !selectedPaymentAccount ||
    (selectedPaymentAccount.type === PaymentProviderEnum.Stripe &&
      !selectedCard);

  return {
    invoice,
    loading: loading || operatePaymentAccountsLoading,
    error,
    submitting,
    pdfUrl,
    formattedStartDate,
    formattedEndDate,
    isPlacePayOpen,
    isPayDisabled,
    placePayRef,
    essensysPaymentSettings,
    selectedCard,
    paymentAccounts,
    selectedPaymentAccount,
    partialAmount,
    isPartialAmount,

    // Errors
    paymentError,
    partialPaymentError,
    essensysPaymentSettingsError,
    operatePaymentAccountError,

    // functions
    submitPayment,
    formatter,
    setPartialAmount,
    setIsPartialAmount,
    setIsPlacePayOpen,
    setSelectedPaymentAccount,
    setSelectedCard,
    setPaymentError,
    setPartialPaymentError,
  } as const;
}
