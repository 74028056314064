import React from 'react';

import cx from 'classnames';

import { useContentQuery } from 'lane-shared/hooks';
import { ContentType } from 'lane-shared/types/content/Content';
import { PropertyType } from 'lane-shared/types/properties/Property';

import styles from './JSONTypeValue.scss';

type DataFieldType = {
  name: string;
  value: string;
  _id: string;
  _order: number;
};

export type JSONValueDIYType = {
  data: { id: string; name: string | DataFieldType }[];
  feature: string;
  state: string;
};

type JSONTypeValueProps = {
  className?: string;
  style?: React.CSSProperties;
  contentData?: ContentType;
  value?: JSONValueDIYType;
};

export default function JSONTypeValue({
  className,
  style,
  value,
  contentData,
}: JSONTypeValueProps) {
  const { content } = useContentQuery({
    contentId: contentData?.integration?.settings?.diyQuestions._id,
  });
  if (!content?.data || !Object.values(content?.data).length || !value?.data)
    return null;

  const listOfValues = Object.values(content.data).map((item: PropertyType) => {
    const keyOfJSON = item?.friendlyName;
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const nameOfJSON = value?.data[item?.name];
    if (typeof nameOfJSON === 'object') {
      return { keyOfJSON, nameOfJSON: nameOfJSON?.name };
    }
    return { keyOfJSON, nameOfJSON };
  });

  return (
    <div className={cx(className, styles.JSONTypeStyle)} style={style}>
      <ul>
        {listOfValues.map((field, key) => (
          <li key={key}>
            <b>{field.keyOfJSON}</b> {`: ${field.nameOfJSON} `}
          </li>
        ))}
      </ul>
    </div>
  );
}
