import { DateTime, Interval } from 'luxon';
import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';
import { getHoursOfOperationByWeeklyAvailabilities } from '../getHoursOfOperationByWeeklyAvailabilities';

function getIsReferenceDateAnUnavailableDate(
  unavailableDates:
    | TimeAvailabilityFeatureProperties['unavailableDates']
    | undefined,
  referenceDateTime: DateTime
) {
  if (!unavailableDates) {
    return false;
  }

  const unavailableDateIntervals = unavailableDates
    .filter(ud => ud.dateRange.startDate)
    .map(ud => {
      if (typeof ud.dateRange.startDate === 'string') {
        return DateTime.fromISO(ud.dateRange.startDate, {
          zone: referenceDateTime.zone,
        });
      }

      if (ud.dateRange.startDate instanceof Date) {
        return DateTime.fromJSDate(ud.dateRange.startDate, {
          zone: referenceDateTime.zone,
        });
      }

      throw new Error('Unusable date format.');
    });

  return unavailableDateIntervals.some(d => d.equals(referenceDateTime));
}

type Props = {
  referenceDate: Date;
  timeAvailability: TimeAvailabilityFeatureProperties | undefined;
  userRoleBase62Ids: string[];
  timeZone?: string;
};

export function getContentOperatingHours({
  referenceDate,
  timeAvailability,
  userRoleBase62Ids,
  timeZone = 'UTC',
}: Props) {
  const referenceDateTime = DateTime.fromJSDate(referenceDate, {
    zone: timeZone,
  });

  if (!timeAvailability) {
    return {
      startDate: referenceDate,
      endDate: DateTime.fromJSDate(referenceDate)
        .plus({ hours: 24 })
        .toJSDate(),
      availableIntervals: [
        Interval.fromDateTimes(
          referenceDateTime,
          referenceDateTime.plus({ hours: 24 })
        ),
      ],
    };
  }

  const { availabilities, unavailableDates } = timeAvailability;

  if (
    getIsReferenceDateAnUnavailableDate(unavailableDates, referenceDateTime)
  ) {
    return null;
  }

  return getHoursOfOperationByWeeklyAvailabilities(
    availabilities,
    userRoleBase62Ids,
    referenceDateTime
  );
}
