import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { EssensysPaymentSettingsType } from '../types/payment/PaymentFeatureQuoteType';
import useCompanyId from './useCompanyId';

const ESSENSYS_PAYMENT_SETTINGS = gql`
  query EssensysPaymentSettings(
    $companyId: UUID
    $userId: UUID
    $accountId: ID
    $contactId: ID
    $invoiceId: ID
  ) {
    essensys {
      paymentSettings(
        companyId: $companyId
        userId: $userId
        accountId: $accountId
        contactId: $contactId
        invoiceId: $invoiceId
      ) {
        _id
        isEssensys
        isOnAccount
        isCash
        isCredits
        isCashNotOnAccount
        balance
        creditsUser
        creditsRemaining
        credits
        accountId
        contactId
        locationName
        overageproductid
        orgpayeeid
        shopperreference
        paymentProcessor {
          type
          publicKey
        }
      }
    }
  }
`;

export function useEssensysPaymentSettingsForInvoice(
  invoiceId: string | undefined
) {
  const essensysPaymentSettingsQuery = useQuery<
    { essensys: { paymentSettings: EssensysPaymentSettingsType } },
    { invoiceId: string | undefined }
  >(ESSENSYS_PAYMENT_SETTINGS, {
    skip: !invoiceId,
    variables: {
      invoiceId,
    },
  });

  return {
    ...essensysPaymentSettingsQuery,
    essensysPaymentSettings:
      essensysPaymentSettingsQuery.data?.essensys.paymentSettings,
  } as const;
}

export default function useEssensysPaymentSettings(
  userId: string | undefined = undefined,
  {
    contactId,
    accountId,
    skip = false,
  }: {
    contactId?: string;
    accountId?: string;
    skip?: boolean;
  } = {}
): {
  essensysPaymentSettings?: EssensysPaymentSettingsType;
  loading?: boolean;
  error?: Error;
} {
  /**
   * "userId" will be passed in when submitting on behalf of. In that case we don't want to use the
   * currently logged in user's companyId, instead let the paymentSettings resolver handle
   * trying to find the user's company Id
   * EDIT: This is now handled by the resolver
   */
  const companyId = useCompanyId();

  const shouldSkip = !companyId && !contactId && !accountId;

  const variables: {
    userId?: string;
    companyId?: string | null;
    contactId?: string;
    accountId?: string;
  } = {
    companyId,
    userId,
    contactId,
    accountId,
  };

  if (!companyId) {
    delete variables.companyId;
  }

  const { data, loading, error } = useQuery(ESSENSYS_PAYMENT_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    skip: shouldSkip,
    variables,
  });

  /*
    Not super clean solution, but underlying problem would require much large refactor and is outside the scope of current work.
    Logic currently exists to skip fetching essensysPaymentSettings if payment account is of type stripe

    This logic was added to prevent an error where essensysPaymentSettings would be fetched but it wouldn't exist/be configured properly and would throw an error - [link to PR which addressed this originally](https://github.com/viewthespace/lane-next/pull/9142)

    The issue that this logic is causing: In brazil studio channels selectedPaymentAccount will have type Stripe but essensysPaymentSettings still needs to be fetched so that a user can pay with credits.

    Solution added: If an error is thrown and the skip flag was true just "pretend" that the query was skipped and return undefined (what would have been returned if it had been skipped). Reasoning: skip argument would only be true if selectedPaymentAccount is of type Stripe, but if essensyPaymentSettings can still be fetched successfully then the skip flag was incorrect.
  */
  if (error && skip) {
    return {
      essensysPaymentSettings: undefined,
      loading,
      error: undefined,
    };
  }

  return {
    essensysPaymentSettings: data?.essensys?.paymentSettings,
    loading,
    error,
  };
}
