import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import OnlinePlanQuote from 'lane-shared/hooks/integrations/essensys/types/OnlinePlanQuote';

const ONLINE_PLAN_QUOTE = gql`
  query OnlinePlanQuote($objectDatasetRecordId: UUID!) {
    essensys {
      onlinePlanQuote(objectDatasetRecordId: $objectDatasetRecordId) {
        productLineItems {
          productId
          productName
          unitPrice
          quantity
        }
        currencyDetail {
          currencyCode
          currencySymbol
          operateCurrencyId
        }
        totals {
          subtotal
          totalTax
          total
        }
      }
    }
  }
`;

export default function useOperateOnlinePlanQuote(
  objectDatasetRecordId?: string
) {
  const { data, error, loading, called } = useQuery<
    { essensys: { onlinePlanQuote: OnlinePlanQuote } },
    { objectDatasetRecordId: string }
  >(ONLINE_PLAN_QUOTE, {
    variables: {
      objectDatasetRecordId: objectDatasetRecordId!,
    },
    skip: !objectDatasetRecordId,
  });

  return {
    onlinePlanQuote: data?.essensys.onlinePlanQuote,
    error,
    loading,
    called,
  };
}
