import { useEffect, useMemo } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { queryChannels } from 'lane-shared/graphql/channel';

import { ShopifyChannelType } from '../../../types/integrations/ShopifyBurst';
import useShopifyBurstContext from './useShopifyBurstContext';

function getSearch(
  searchQuery: string | undefined,
  shopifyParentChannelId: string | null | undefined
) {
  return {
    search: {
      type: 'like',
      value: searchQuery,
    },
    sortBy: {
      key: 'name',
      dir: 'asc',
    },
    isSub: false,
    parent: {
      _id: shopifyParentChannelId,
    },
    includeChannelsFromHierarchy: true,
  };
}

export function useShopifyPortsQuery(
  shopifyParentChannelId: string | undefined | null,
  searchQuery?: string
) {
  const [fetchChannels, { data, loading, error }] = useLazyQuery<{
    channels: {
      pageInfo: {
        start: number;
        total: number;
        perPage: number;
      };
      items: ShopifyChannelType[];
    };
  }>(queryChannels, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        start: 0,
        perPage: 50,
      },
      search: getSearch(searchQuery, shopifyParentChannelId),
    },
  });

  const debouncedDoFetch = useDebouncedCallback(function doFetch() {
    fetchChannels({
      variables: {
        pagination: {
          start: 0,
          perPage: 50,
        },
        search: getSearch(searchQuery, shopifyParentChannelId),
      },
    });
  }, 250).callback;

  useEffect(() => {
    if (shopifyParentChannelId) {
      debouncedDoFetch();
    }
  }, [shopifyParentChannelId, searchQuery]);

  const { burstContent } = useShopifyBurstContext();

  const ports = useMemo(
    () =>
      (data?.channels?.items || [])
        .filter(
          channel =>
            channel._id !== burstContent?.channel?._id &&
            channel.stats.subscribers > 0
        )
        .filter(channel => channel.isPublic),
    [data?.channels?.items, burstContent?.channel?._id]
  );

  return {
    ports,
    loading,
    error,
  };
}
