import React, { useState, useRef } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { validateEmail } from 'lane-shared/validation';

import DatePickerButton from '../form/DatePickers/DatePickerButton';
import DateRangePickerButton from '../form/DatePickers/DateRangePickerButton';
import TimePicker from '../form/DatePickers/TimePicker';
import Input from '../form/Input';
import Button from './Button';
import ToggleView from './ToggleView';

import styles from './ListExpandableForm.scss';

type OwnProps = {
  title: string;
  values: any[];
  description?: string;
  onClick?: (...args: any[]) => any;
  buttonText?: string;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof ListExpandableForm.defaultProps;

function ListExpandableForm({
  title,
  description,
  values,
  buttonText,
  onClick,
}: Props) {
  const { t } = useTranslation();
  const initialValues = {};
  let initialValidated = true;
  const startOfDay = useRef(DateTime.local().startOf('day')).current;

  values.map(value => {
    if (initialValidated && value.required) {
      initialValidated = false;
    }
    switch (value.type) {
      case 'Number':
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        initialValues[value.key] = 0;
        break;
      case 'Date':
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        initialValues[value.key] = startOfDay.toJSDate();
        break;
      case 'DateRange':
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        initialValues[value.key] = {
          startDate: startOfDay.toJSDate(),
          endDate: startOfDay.endOf('day').toJSDate(),
        };
        break;
      case 'Text':
      case 'Email':
      default:
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        initialValues[value.key] = null;
        break;
    }
  });

  const [formStates, setFormStates] = useState(initialValues);
  const [validated, setValidated] = useState(initialValidated);

  async function validate(value: any, change: any) {
    for (const checkVal of values) {
      if (
        (checkVal === value && value.required && !change) ||
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (checkVal.required && !formStates[checkVal.key])
      ) {
        return setValidated(false);
      }
    }

    try {
      switch (value.type) {
        case 'Number':
          if (!change.isInteger) {
            setValidated(false);
          }
          break;
        case 'Email':
          await validateEmail.validate({ email: change });
          break;
        case 'Date':
        case 'DateRange':
        case 'Text':
        default:
          break;
      }
      setValidated(true);
    } catch (validation) {
      setValidated(false);
    }
  }

  function handleFormStatesChange(value: any, change: any) {
    if (change.length <= 0) {
      change = null;
    }

    validate(value, change);

    if (typeof change === 'object') {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      change = { ...formStates[value.key], ...change };
    }

    setFormStates({
      ...formStates,
      [value.key]: change,
    });
  }

  return (
    <div className={styles.ListExpandableForm}>
      <ToggleView title={title}>
        <div className={styles.form}>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          {values.map(value => (
            <div key={value.key} className={styles.fields}>
              <div className={styles.textField}>
                {value.type === 'Text' && (
                  <Input
                    label={value.label}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={formStates[value.key]}
                    placeholder={value.placeholder}
                    onChange={change => handleFormStatesChange(value, change)}
                  />
                )}
                {value.type === 'Email' && (
                  <Input
                    label={value.label}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={formStates[value.key]}
                    placeholder={value.placeholder}
                    onChange={change => handleFormStatesChange(value, change)}
                  />
                )}
                {value.type === 'Number' && (
                  <Input
                    type="number"
                    label={value.label}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={formStates[value.key]}
                    placeholder={value.placeholder}
                    onChange={change => handleFormStatesChange(value, change)}
                  />
                )}
              </div>
              <div className={styles.dateField}>
                {value.type === 'Date' && (
                  <label>
                    {value.label}
                    <DatePickerButton
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                      value={formStates[value.key]}
                      onSubmit={(change: any) =>
                        handleFormStatesChange(value, change)
                      }
                    />
                  </label>
                )}
                {value.type === 'DateRange' && (
                  <>
                    <label>
                      {value.label}
                      <DateRangePickerButton
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                        startDate={formStates[value.key]?.startDate}
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                        endDate={formStates[value.key]?.endDate}
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '(change: any) => void' is not assignable to ... Remove this comment to see the full error message
                        onChange={(change: any) =>
                          handleFormStatesChange(value, change)
                        }
                      />
                    </label>
                    {value.misc?.includeTime && (
                      <>
                        <label>
                          {t('Start Time')}
                          <div className={styles.timeField}>
                            <TimePicker
                              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                              value={formStates[value.key]?.startDate}
                              // @ts-expect-error ts-migrate(2322) FIXME: Type '(startDate: any) => void' is not assignable ... Remove this comment to see the full error message
                              onChange={(startDate: any) =>
                                handleFormStatesChange(value, { startDate })
                              }
                            />
                          </div>
                        </label>
                        <label>
                          {t('End Time')}
                          <div className={styles.timeField}>
                            <TimePicker
                              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                              value={formStates[value.key]?.endDate}
                              // @ts-expect-error ts-migrate(2322) FIXME: Type '(endDate: any) => void' is not assignable to... Remove this comment to see the full error message
                              onChange={(endDate: any) =>
                                handleFormStatesChange(value, { endDate })
                              }
                            />
                          </div>
                        </label>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className={styles.required}>
                {value.required && <span>{t('Required')}</span>}
              </div>
            </div>
          ))}
          <div className={styles.footer}>
            <Button
              size="small"
              onClick={() => onClick(formStates)}
              disabled={!validated}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </ToggleView>
    </div>
  );
}

ListExpandableForm.defaultProps = {
  onClick: () => null,
  description: '',
  buttonText: 'Submit',
};

export default React.memo(ListExpandableForm);
