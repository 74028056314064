import React from 'react';

import InputRange, { Range } from 'react-input-range';

import './style.scss';

type OwnProps = {
  /** Function to be called when slider changes values, returns (value, [name]) */
  onChange: (value: number | Range, name: string) => void;
  /** Unique identifier as form input */
  name: string;
  /** value of our slider, can be, number, or object {min: 0, max: 10} */
  value: number | Range;
  /** minimum value of slider value that can be chosen, default: 0 */
  min: number;
  /** maximum value of slider value that can be chosen, default: 100 */
  max: number;
  /** value to be added or subtracted on each step the slider makes.  */
  step: number;
  /** Marks component as disabled and user is not able to interact with it */
  disabled: boolean;
  formatLabel: (value: number, type: string) => string;
  style?: React.CSSProperties;
  className?: string;
};

Slider.defaultProps = {
  name: '',
  value: 0,
  min: 0,
  max: 100,
  step: 1,
  disabled: false,
  formatLabel: null,
};

type Props = OwnProps & typeof Slider.defaultProps;

function Slider({
  onChange,
  name,
  value,
  min,
  max,
  step,
  disabled,
  className,
  style,
  formatLabel,
}: Props) {
  return (
    <div className={className} style={style}>
      <InputRange
        formatLabel={formatLabel}
        maxValue={max}
        minValue={min}
        value={value}
        // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
        onChange={val => onChange(val, name)}
        step={step}
        disabled={disabled}
      />
    </div>
  );
}

export default Slider;
