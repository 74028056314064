import * as yup from 'yup';

import localization from 'localization';

import commonPasswords from './commonPasswords';

const i18n = localization.cloneInstance();

const PASS_MIN_CHAR = 10;
const passwordError = i18n.t('Password must be at least 6 characters');
const passwordError2 = i18n.t(
  `Password must be at least ${PASS_MIN_CHAR} characters`
);

const validatePasswordV2 = yup
  .string()
  .trim()
  .min(PASS_MIN_CHAR, passwordError2)
  .required()
  .notOneOf(
    commonPasswords,
    i18n.t(
      'The password you created is unfortunately too common. For added security, try making it longer or adding special characters.'
    )
  );

/**
 * Use the new password validation schema.
 * @deprecated
 */
const validatePasswordV1 = yup.string().trim().min(6, passwordError).required();

export {
  validatePasswordV1,
  validatePasswordV2,
  passwordError,
  passwordError2,
};
