import gql from 'graphql-tag';

export const createUserAndAddToTeams = gql`
  mutation createUserAndAddToTeams(
    $email: EmailAddress!
    $phoneNumber: String
    $userStatus: UserStatus
    $emailOptIn: Boolean
    $password: String
    $name: String!
    $channelId: ID!
    $teamsToAdd: [String]
    $addUserToTenants: Boolean = false
  ) {
    createUserAndAddToTeams(
      email: $email
      phoneNumber: $phoneNumber
      userStatus: $userStatus
      emailOptIn: $emailOptIn
      password: $password
      name: $name
      channelId: $channelId
      teamsToAdd: $teamsToAdd
      addUserToTenants: $addUserToTenants
    )
  }
`;
