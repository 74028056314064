import { GeoCoordinateType } from '../baseTypes/GeoTypes';
import { MetatagFilter } from './MetatagFilter';
import { PresetContentFilterInterface } from './PresetContentFilterInterface';
import { PresetContentSort } from './PresetContentSort';
import { SortDirection } from './SortDirection';

export type SearchOptions = {
  _updated?: Date;
  search?: string;
  includedMLSColumns?: string[];
  areFiltersApplied: boolean;
  filters: PresetContentFilterInterface[];
  metatagFilters: MetatagFilter[];
  sorts?: PresetContentSort[];
  sortDirection?: SortDirection;
  channelId?: string | null;
  contentTags?: string[];
  geo?: GeoCoordinateType;
};

export enum ContentGroupByEnum {
  None = 'None',
  Channel = 'Channel',
  Category = 'Category',
}
