import { Res, request } from './common';
import { Form } from './issueTypesJsonForms';

/**
 * These are called SerdyForms in Building Engines.
 * Each workorder can have a new and complete serdyForm attached
 */
export interface CustomFieldForm {
  id: string;
  created_at: string;
  updated_at: string;
  form: Form;
  type: string;
  href: string;
  field_count: number;
  field_value_count: number;
  progress: number;
  attachments_href: string;
  work_orders_href: string;
  answers_href: string;
  summary_href: string;
}

export async function get(
  token: string,
  id: string
): Promise<Res<CustomFieldForm>> {
  return request({
    token,
    method: 'get',
    path: `/serdy_forms/${id}`,
  });
}
