import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

export const views = ['list', 'th-large'] as const;
export const [VIEW_LIST, VIEW_GRID] = views;

export const tabs = [
  {
    label: 'web.admin.channel.contentCenter.tab.published',
    value: 'Published',
    icon: 'award',
  },
  {
    label: 'web.admin.channel.contentCenter.tab.drafts',
    value: 'Drafts',
    icon: 'archive',
  },
];

export const DATE_FORMAT = 'yyyy-MM-dd';

export const sorts = [
  '_created',
  '_updated',
  'name',
  'liveDate',
  'type',
  'isInteractive',
] as const;

export const [
  SORT_CREATED,
  SORT_UPDATED,
  SORT_NAME,
  SORT_LIVE_DATE,
  SORT_TYPE,
  SORT_INTERACTIVE,
] = sorts;

export const sortOrders = ['asc', 'desc'] as const;
export const [SORT_ASC, SORT_DESC] = sortOrders;

export const headers = [
  {
    label: 'Name',
    sort: SORT_NAME,
    translation_key: 'web.admin.channel.contentCenter.table.header.name',
  },
  {
    label: 'Type',
    sort: SORT_TYPE,
    translation_key: 'web.admin.channel.contentCenter.table.header.type',
  },
  {
    label: 'Sections',
    translation_key: 'web.admin.channel.contentCenter.table.header.sections',
  },
  {
    label: 'Tags',
    translation_key: 'web.admin.channel.contentCenter.table.header.tags',
  },
  {
    label: 'Interactive',
    sort: SORT_INTERACTIVE,
    translation_key: 'web.admin.channel.contentCenter.table.header.interactive',
  },
  {
    label: 'Schedule',
    sort: SORT_LIVE_DATE,
    translation_key: 'web.admin.channel.contentCenter.table.header.schedule',
  },
  {
    label: 'Created',
    sort: SORT_CREATED,
    translation_key: 'web.admin.channel.contentCenter.table.header.created',
  },
  {
    label: 'Last Update',
    sort: SORT_UPDATED,
    translation_key: 'web.admin.channel.contentCenter.table.header.lastUpdate',
  },
];

export const CONTENT_WITH_SECTIONS = [
  ContentTypeEnum.Static,
  ContentTypeEnum.Content,
  ContentTypeEnum.Notice,
];
