import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { toSchema } from 'lane-shared/helpers';
import {
  explodeFeatures,
  getDefaultDatesForReservable,
} from 'lane-shared/helpers/features';
import { useWeekDayUnavailable } from 'lane-shared/hooks/features/reservable/useWeekDayUnavailable';
import { useReservableTimePickerEnabled } from 'lane-shared/hooks/useReservableTimePickerEnabled';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import {
  ReservableUiTypesEnum,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import { Input, Toggle } from 'components/form';
import TimeRangePicker from 'components/form/DatePickers/TimeRangePicker';
import TimeSlotsPicker from 'components/form/DatePickers/TimeSlotsPicker';
import Dropdown from 'components/form/Dropdown';
import Label from 'components/general/Label';
import { DatePickerButton, DateRangePickerButton, H5 } from 'design-system-web';

import { TimePickerAppearancePreview } from '../TimePickerAppearancePreview';
import { useReservableWaitlist } from 'lane-shared/hooks/useReservableWaitlist';

import { convertTo62 } from 'lane-shared/helpers/convertId';
import { isCancellableForUser } from 'lane-shared/helpers/content';
import { WarningMessage } from './WarningMessage';
import styles from '../styles.scss';

const TRANSLATION_KEYS = {
  customTimeSlotsDisplayType:
    'web.renderers.feature.reservable.displayType.customTimeSlots',
  defaultDisplayType: 'web.renderers.feature.reservable.displayType.default',
  previewTitle:
    'web.admin.content.features.reservable.appearance.preview.title',
  displayTypeTitle:
    'web.admin.content.features.reservable.appearance.displayType.title',
  cancelationFeatureWarning:
    'web.admin.content.features.waitlist.cancelationFeatureWarning',
  anonymousEntriesFeatureWarning:
    'web.admin.content.features.waitlist.anonymousEntriesFeatureWarning',
};

const bufferTimeSupportedUiTypes = [
  ReservableUiTypesEnum.TimeSlider,
  ReservableUiTypesEnum.TimePicker,
];

function getAvailableInputTypes(
  isBufferTimeEnabled: boolean,
  isReservableTimePickerEnabled: boolean
) {
  const reservableUiTypes = Object.values(ReservableUiTypesEnum).filter(
    uiType => {
      if (uiType === ReservableUiTypesEnum.TimePicker) {
        return isReservableTimePickerEnabled;
      }

      return true;
    }
  );

  if (isBufferTimeEnabled) {
    return reservableUiTypes.filter(uiType =>
      bufferTimeSupportedUiTypes.includes(uiType)
    );
  }

  return reservableUiTypes;
}

export function ReservableTabAppearance({
  feature,
  timeZone,
  settings,
  onFeatureUpdated,
  timeAvailabilityFeature,
  content,
}: any) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const userGroupRoleIds =
    user?.roles?.map(({ groupRole }) => groupRole._id) || [];
  const { unavailableWeekdays } = useWeekDayUnavailable({
    timeAvailabilityFeature,
    userGroupRoleIds,
  });

  const roles = user?.roles.map(role => convertTo62(role?.groupRole?._id));
  const { cancelableFeature, entriesFeature } = explodeFeatures(
    content?.features
  );
  const hasCancellable =
    cancelableFeature &&
    isCancellableForUser({ roles, feature: cancelableFeature });

  const [exampleRange, setExampleRange] = useState<DateRangeType>(
    getDefaultDatesForReservable({
      referenceDate: DateTime.local().setZone(timeZone),
    })
  );
  const isReservableWaitlistEnabled = useReservableWaitlist();
  const isTimePickerEnabled = useReservableTimePickerEnabled();
  const isReservableTimePickerEnabled = useReservableTimePickerEnabled();
  const availableReservableUiTypes = getAvailableInputTypes(
    settings?.bufferTime?.isEnabled,
    isReservableTimePickerEnabled
  );

  const viewingDate = DateTime.local();
  const referenceDate: Date = timeZone
    ? viewingDate.setZone(timeZone).toJSDate()
    : viewingDate.toJSDate();

  const isTimeSlot =
    settings.unitType === ReservableUnitTypesEnum.Minutes &&
    settings.uiType === ReservableUiTypesEnum.TimeSlots;

  const isWaitlistEnabled = isReservableWaitlistEnabled && isTimeSlot;

  const isTimePicker =
    isTimePickerEnabled &&
    settings.uiType === ReservableUiTypesEnum.TimePicker &&
    settings.unitType === ReservableUnitTypesEnum.Minutes;

  function renderDisplayType() {
    if (settings.useCustomTimeSlots) {
      return (
        <div style={{ maxWidth: '400px' }}>
          <Input
            value={t(TRANSLATION_KEYS.customTimeSlotsDisplayType) as string}
            onChange={() => {}}
            showClear={false}
            readOnly
          />
        </div>
      );
    }

    return (
      <Dropdown
        onValueChange={uiType => onFeatureUpdated({ uiType })}
        items={availableReservableUiTypes.map(toSchema)}
        initialValue={
          isReservableTimePickerEnabled
            ? ReservableUiTypesEnum.TimePicker
            : ReservableUiTypesEnum.TimeSlider
        }
        isSearchable={false}
        placeholder={t(TRANSLATION_KEYS.defaultDisplayType)}
        value={settings.uiType}
      />
    );
  }

  return (
    <fieldset>
      {settings.unitType === ReservableUnitTypesEnum.Minutes && (
        <>
          {!hasCancellable && settings.useWaitlist && (
            <WarningMessage
              message={t(TRANSLATION_KEYS.cancelationFeatureWarning)}
            />
          )}
          {entriesFeature?.allowAnonymous && settings.useWaitlist && (
            <WarningMessage
              message={t(TRANSLATION_KEYS.anonymousEntriesFeatureWarning)}
            />
          )}
          <Label>
            <H5>{t(TRANSLATION_KEYS.displayTypeTitle)}</H5>
          </Label>
          {renderDisplayType()}
        </>
      )}

      {isWaitlistEnabled && (
        <div className={styles.waitlistToggle || false}>
          <Toggle
            value={settings.useWaitlist}
            onChange={value => {
              onFeatureUpdated({
                useWaitlist: value,
              });
            }}
            text={t(feature.properties.useWaitlist.friendlyName)}
          />
        </div>
      )}
      <div className={styles.previewHeader}>
        <H5>{t(TRANSLATION_KEYS.previewTitle)}</H5>
      </div>

      <div className={classNames([styles.preview])}>
        {settings.unitType === ReservableUnitTypesEnum.Minutes &&
          settings.uiType === ReservableUiTypesEnum.TimeSlider && (
            <TimeRangePicker
              value={exampleRange}
              timeZone={timeZone}
              minRangeSize={settings.units}
              maxRangeSize={settings.units * settings.mainRule.maxSlots}
              slotSize={settings.units}
              className={styles.timeRange}
              onChange={range => setExampleRange(range)}
              showTimeSlider
              displayAllOptions
              disabledWeekDays={unavailableWeekdays}
            />
          )}
        {isTimePicker && (
          <TimePickerAppearancePreview
            intervalMinutes={settings.units}
            maxDuration={settings.mainRule.maxSlots * settings.units}
            timeZone={timeZone}
            disabledWeekDays={unavailableWeekdays}
          />
        )}
        {isTimeSlot && (
          <TimeSlotsPicker
            value={exampleRange}
            slotSize={settings.units}
            timeZone={timeZone}
            referenceDate={referenceDate}
            maxSlots={settings.mainRule.maxSlots}
            className={styles.timeSlots}
            onChange={range => setExampleRange(range)}
            hasCustomTimeSlots={settings.useCustomTimeSlots}
            hasWaitlistEnabled={isWaitlistEnabled && settings.useWaitlist}
            customTimeSlots={settings.customTimeSlots}
            disabledWeekDays={unavailableWeekdays}
            maxQuantityPerSlotPerUser={settings.maxQuantityPerSlotPerUser}
          />
        )}
        {settings.unitType === ReservableUnitTypesEnum.Days && (
          <div className={styles.DatePicker}>
            {settings.mainRule.maxSlots === 1 ? (
              <DatePickerButton
                timeZone={timeZone}
                onChange={(date: any) => setExampleRange(date)}
              />
            ) : (
              <DateRangePickerButton
                timeZone={timeZone}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
                startDate={exampleRange.startDate}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
                endDate={exampleRange.endDate}
                minRangeSize={settings.units}
                maxRangeSize={settings.units * settings.mainRule.maxSlots}
                onChange={(range: any) => setExampleRange(range)}
                longFormat
                disabledWeekDays={unavailableWeekdays}
              />
            )}
          </div>
        )}
      </div>
    </fieldset>
  );
}
