import { ContentInteractionsQueryInteraction } from 'lane-shared/hooks/useContentInteractionsQuery';

import { INTERACTION_CLOSED_STATES } from '../../constants/interactions';
import parseDate from '../../dates/parseDate';

export default function filterUpcomingMeetings(
  interactions: ContentInteractionsQueryInteraction[] = []
) {
  return interactions.filter(meeting => {
    const endDate = parseDate(meeting.endDate);
    return (
      endDate &&
      endDate.getTime() > Date.now() &&
      !INTERACTION_CLOSED_STATES.includes(meeting.status)
    );
  });
}
