import { DateTime } from 'luxon';

import parseDateTime from 'lane-shared/helpers/dates/parseDateTime';

import {
  CONTENT_LIVE,
  CONTENT_UPCOMING,
  CONTENT_COMPLETED,
} from '../constants/contentStates';

export function getContentStatus(startDate: Date | null, endDate: Date | null) {
  const startDateTime = parseDateTime(startDate);
  const endDateTime = parseDateTime(endDate);

  const now = DateTime.fromJSDate(new Date());

  if (!endDateTime || !startDateTime) {
    return [null, null];
  }

  if (now < endDateTime && now > startDateTime) {
    return [CONTENT_LIVE.text, CONTENT_LIVE.color];
  }

  if (now < startDateTime) {
    return [CONTENT_UPCOMING.text, CONTENT_UPCOMING.color];
  }

  return [CONTENT_COMPLETED.text, CONTENT_COMPLETED.color];
}
