import React from 'react';

import { useContentAnalytics } from 'lane-shared/hooks/analytics';

import ImagePinView from './ImagePinView';

type ContentImagePinProps = {
  className?: string;
  imageStyle?: React.CSSProperties;
  content: any;
};

export default function ContentImagePin({
  className,
  imageStyle,
  content,
}: ContentImagePinProps) {
  useContentAnalytics('view', content);

  return (
    <ImagePinView
      name={content?.name}
      backgroundColor={content?.backgroundColor}
      backgroundImage={content?.backgroundImage}
      className={className}
      imageStyle={imageStyle}
    />
  );
}
