import { useLazyQuery } from '@apollo/client';

import getReservableBookings from 'lane-shared/graphql/query/getReservableBookings';
import { ReservableBooking } from 'lane-shared/types/ReservableBooking';

import { PageSizeType, Sort } from 'design-system-web';

import { ColumnKey } from '../components/ReservableTableView/hooks/useColumnsHeaders';

interface Pagination {
  start: number;
  perPage: PageSizeType;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface FetchParams {
  keyword?: string;
  dateRange?: DateRange;
  pagination: Pagination;
  sort?: Sort;
}

interface Search {
  channelId: string;
  keyword?: string;
  dateRange?: DateRange;
  sort?: {
    sortBy: string;
    direction: 'asc' | 'desc';
  };
}

export type ReservableBookingItem = Pick<
  ReservableBooking,
  | 'id'
  | 'user'
  | 'features'
  | 'status'
  | 'contentData'
  | 'channel'
  | 'companies'
  | 'adminNote'
> & {
  startDate: string;
  endDate: string;
};

interface ReservableBookings {
  items: ReservableBookingItem[];
  pageInfo: {
    start: number;
    perPage: number;
    total: number;
  };
}

interface QueryResponse {
  reservableBookings: ReservableBookings;
}

function isSortObjectWithProperties(
  obj: Sort
): obj is { id: string; direction: 'asc' | 'desc' } {
  return (
    obj &&
    typeof obj === 'object' &&
    'id' in obj &&
    'direction' in obj &&
    ['asc', 'desc'].includes(obj.direction)
  );
}

function processSort(key: Sort) {
  if (!isSortObjectWithProperties(key)) {
    return;
  }

  const result = {
    sortBy: key.id,
    direction: key.direction,
  };

  switch (key?.id) {
    case ColumnKey.bookingDate:
      result.sortBy = 'startDate';
      break;
    case ColumnKey.status:
      result.sortBy = 'status';
  }

  return result;
}

export function useGetReservableBookings({ channelId }: { channelId: string }) {
  const [refetch, { data, loading, error }] = useLazyQuery<QueryResponse>(
    getReservableBookings,
    {
      fetchPolicy: 'network-only',
    }
  );

  function fetchBookings({
    keyword,
    dateRange,
    pagination,
    sort,
  }: FetchParams) {
    const search: Search = {
      channelId,
      dateRange,
      keyword,
      sort: sort && processSort(sort),
    };

    refetch({
      variables: {
        search,
        pagination: {
          start: pagination.start,
          perPage: pagination.perPage,
        },
      },
    });
  }

  return {
    fetchBookings,
    bookings: data?.reservableBookings.items || [],
    totalBookings: data?.reservableBookings?.pageInfo?.total || 0,
    isLoading: loading,
    error,
  };
}
