/* eslint-disable eqeqeq */
import ReservableFeatureRule from '../../renderers/v5/features/types/ReservableFeatureRule';
import {
  ReservableFeatureProperties,
  ReservableFeatureRuleType,
} from '../../types/features/ReservableFeatureProperties';
import getReservableMinutesFromUnits from './getReservableMinutesFromUnits';

/**
 * Gets the "best" reservable feature rule that applies to the current
 * user based on their user roles.
 */
export default function getReservableBestRule(
  // the reservable feature
  reservableFeature: ReservableFeatureProperties | null | undefined,
  // an array of UUID strings of user group role ids
  userGroupRoleIds: string[]
): ReservableFeatureRuleType {
  if (!reservableFeature) {
    return ReservableFeatureRule.default;
  }

  if (!reservableFeature.rules || reservableFeature.rules.length === 0) {
    return reservableFeature.mainRule;
  }

  const bestRule: ReservableFeatureRuleType = {
    ...reservableFeature.mainRule,
  };

  const rules = reservableFeature.rules.filter(rule =>
    userGroupRoleIds.includes(rule.groupRole?._id as string)
  );

  const bestAfterMinutes = getReservableMinutesFromUnits(
    bestRule.unitsAfterNow,
    bestRule.unitsAfterNowType
  );

  const bestBeforeMinutes = getReservableMinutesFromUnits(
    bestRule.unitsBeforeStart,
    bestRule.unitsBeforeStartType
  );

  // because rules are additive, we take the best parts of all the applicable
  // rules and generate one based on the users roles
  rules.forEach(rule => {
    // the one with the most slots available
    bestRule.maxSlots = Math.max(bestRule.maxSlots, rule.maxSlots);

    // the one with the earliest after now booking time, use loose equality
    // == to check for 0, "0", false, etc.
    if (
      rule.unitsAfterNow != 0 &&
      getReservableMinutesFromUnits(
        rule.unitsAfterNow,
        rule.unitsAfterNowType
      ) < bestAfterMinutes
    ) {
      bestRule.unitsAfterNowType = rule.unitsAfterNowType;
      bestRule.unitsAfterNow = rule.unitsAfterNow;
    }

    // the one with the earliest before start time, , use loose equality
    //  == to check for 0, "0", false, etc.
    if (
      rule.unitsBeforeStart != 0 &&
      getReservableMinutesFromUnits(
        rule.unitsBeforeStart,
        rule.unitsBeforeStartType
      ) > bestBeforeMinutes
    ) {
      bestRule.unitsBeforeStartType = rule.unitsBeforeStartType;
      bestRule.unitsBeforeStart = rule.unitsBeforeStart;
    }
  });

  return bestRule;
}
