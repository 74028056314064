import { useEffect, useRef } from 'react';

const MAX_DEPTH = 3;

/**
 * Hooks up a click listener that will fire when anywhere on screen but this
 * component is clicked.
 *
 * @param onClose the cb to execute when the ref is clicked away from
 * @param when optional only attach when this flag is true.
 * @returns {React.MutableRefObject<null>}
 */
export default function useModalClick(onClose: any, when: any) {
  const ref = useRef(null);

  function includesComponent(e: any) {
    function checkHierarchy(node: any, depth = 0) {
      if (depth > MAX_DEPTH) {
        return false;
      }

      if (!node) {
        // we don't know if this node was contained, maybe it was removed?
        // so err on the side on leaving it open.
        return true;
      }

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (ref.current.contains(node)) {
        return true;
      }

      return checkHierarchy(node.parentElement, depth + 1);
    }

    if (ref && ref.current) {
      // walk up the dom a bit, this helps when things inside have be removed
      // from the dom by the time this is fired.
      if (!checkHierarchy(e.target)) {
        onClose();
      }
    }
  }

  useEffect(
    () => {
      if (when) {
        window.addEventListener('click', includesComponent);
      }

      return () => {
        window.removeEventListener('click', includesComponent);
      };
    },
    when !== undefined ? [when] : []
  );

  return ref;
}
