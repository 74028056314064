import { useEffect, useMemo, useState, useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import {
  IntervalTypeEnum,
  RecurrenceData,
  ScheduleType,
  ScheduleTypeEnum,
} from 'lane-shared/domains/visitorManagement/types';
import { RequestSchedule } from 'lane-infrastructure/domains/visitorManagement/features/visitorManagement/types.local';
import {
  scheduleToDuration,
  scheduleToRRule,
} from 'lane-infrastructure/domains/visitorManagement/features/visitorManagement/utilities';
import { ScheduleReducerType } from 'lane-shared/domains/visitorManagement/reducers';
import { rrulestr } from 'rrule';
import {
  combineDateAndTime,
  parseRRuleSchedule,
} from 'lane-shared/domains/visitorManagement/helpers';
import { updateRecurrencePassesMutation } from 'graphql-queries';

type Props = {
  recurrenceDetail: RecurrenceData | undefined;
  isAllDayPassEnabled: boolean;
  timeZone: string;
};

export const useEditRecurrencePassesDetails = ({
  recurrenceDetail,
  isAllDayPassEnabled,
  timeZone,
}: Props) => {
  const apolloClient = useApolloClient();

  const [isLoading, setIsLoading] = useState(false);
  const [staffNote, setStaffNote] = useState(recurrenceDetail?.staffNote ?? '');
  const [visitorNote, setVisitorNote] = useState(
    recurrenceDetail?.visitorNote ?? ''
  );
  const [schedule, setSchedule] = useState<ScheduleType | null>(null);
  const [rRuleSchedule, setRRuleSchedule] = useState<string>(
    recurrenceDetail?.schedule ?? ''
  );
  const [scheduleDuration, setScheduleDuration] = useState<string>(
    recurrenceDetail?.scheduleDuration ?? ''
  );

  const recurrenceType = useMemo(
    () =>
      recurrenceDetail?.scheduleType === 'SCHEDULE_TYPE_REPEAT'
        ? ScheduleTypeEnum.Repeat
        : ScheduleTypeEnum.DateRange,
    [recurrenceDetail]
  );

  const getIntervalType = (schedule = '') => {
    const rrule = rrulestr(schedule);
    const scheduleText = rrule
      .toText()
      .split('until')[0]
      .split(' on')[0]
      .trim();
    const scheduleMap: { [key: string]: IntervalTypeEnum } = {
      'every day': IntervalTypeEnum.EveryDay,
      'every weekday': IntervalTypeEnum.EveryWeekday,
      'every week': IntervalTypeEnum.Weekly,
      'every month': IntervalTypeEnum.Monthly,
    };
    if (scheduleText.includes('weeks')) {
      return IntervalTypeEnum.Weekly;
    }
    if (scheduleText.includes('months')) {
      return IntervalTypeEnum.Monthly;
    }
    return scheduleMap[scheduleText];
  };

  const convertRRuleToSchedule = useCallback(
    (recurrenceDetail: RecurrenceData) => {
      const rruleOptions = rrulestr(recurrenceDetail.schedule).options;
      const parsedSchedule = parseRRuleSchedule(
        recurrenceDetail.schedule,
        recurrenceDetail.scheduleDuration
      );
      const recurrenceStartDate = new Date(
        parsedSchedule?.scheduleStartDatetime
      );
      const recurrenceEndDate = new Date(parsedSchedule.scheduleEndDate);
      const recurrenceEndTime = new Date(parsedSchedule.eventEndTime);

      setSchedule({
        startDate: recurrenceStartDate,
        endDate: recurrenceEndDate,
        endRepeatDate: recurrenceEndDate,
        startTime: recurrenceStartDate,
        endTime: recurrenceEndTime,
        intervalType: getIntervalType(recurrenceDetail.schedule),
        intervalCount: rruleOptions.interval || 1,
        weekdayRepeats: rruleOptions.byweekday || [],
        type: recurrenceType,
        isAllDay: isAllDayPassEnabled,
      });
    },
    [isAllDayPassEnabled, recurrenceType]
  );

  const onChange = (value: ScheduleReducerType) => {
    const updatedStartDate = combineDateAndTime(
      value?.startDate,
      value?.startTime || new Date(),
      timeZone
    ).toISOString();
    const updatedEndDate = combineDateAndTime(
      value?.endDate,
      value?.endTime || new Date(),
      timeZone
    ).toISOString();
    const modifiedSchedule = {
      ...value,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      endRepeatDate: new Date(
        value?.endRepeatDate || updatedEndDate
      ).toISOString(),
    };
    const rRuleSchedule = `${
      scheduleToRRule(modifiedSchedule as RequestSchedule).split(';COUNT')[0]
    }`;
    const scheduleDuration = scheduleToDuration(
      modifiedSchedule as RequestSchedule
    );
    setRRuleSchedule(rRuleSchedule);
    setScheduleDuration(scheduleDuration);
    setSchedule(value as ScheduleType);
  };

  useEffect(() => {
    if (recurrenceDetail?.staffNote) {
      setStaffNote(recurrenceDetail.staffNote);
    }
    if (recurrenceDetail?.visitorNote) {
      setVisitorNote(recurrenceDetail.visitorNote);
    }
    if (recurrenceDetail?.schedule) {
      setRRuleSchedule(recurrenceDetail?.schedule);
    }
    if (recurrenceDetail?.scheduleDuration) {
      setScheduleDuration(recurrenceDetail?.scheduleDuration);
    }
    if (recurrenceDetail?.schedule && recurrenceDetail?.scheduleDuration) {
      convertRRuleToSchedule(recurrenceDetail);
    }
  }, [recurrenceDetail, convertRRuleToSchedule]);

  const isPristine = useMemo(() => {
    return (
      staffNote === (recurrenceDetail?.staffNote ?? '') &&
      visitorNote === (recurrenceDetail?.visitorNote ?? '') &&
      rRuleSchedule === recurrenceDetail?.schedule &&
      scheduleDuration === recurrenceDetail?.scheduleDuration
    );
  }, [
    staffNote,
    visitorNote,
    rRuleSchedule,
    scheduleDuration,
    recurrenceDetail,
  ]);

  const updateStaffNote = (newNote: string): void => {
    setStaffNote(newNote);
  };

  const updateVisitorNote = (newNote: string): void => {
    setVisitorNote(newNote);
  };

  const reset = useCallback((): void => {
    if (recurrenceDetail?.staffNote) {
      setStaffNote(recurrenceDetail.staffNote);
    }
    if (recurrenceDetail?.visitorNote) {
      setVisitorNote(recurrenceDetail.visitorNote);
    }
    if (recurrenceDetail?.schedule && recurrenceDetail?.scheduleDuration) {
      convertRRuleToSchedule(recurrenceDetail);
    }
  }, [recurrenceDetail, convertRRuleToSchedule]);

  const handleOnEditSave = async () => {
    if (!recurrenceDetail) return;

    try {
      setIsLoading(true);
      const variables: {
        recurrenceId: string;
        newSchedule: string;
        newScheduleDuration: string;
        staffNote?: string;
        visitorNote?: string;
      } = {
        recurrenceId: recurrenceDetail?.recurrenceId,
        newSchedule: rRuleSchedule,
        newScheduleDuration: scheduleDuration,
      };
      if (staffNote !== recurrenceDetail?.staffNote) {
        variables.staffNote = staffNote;
      }

      if (visitorNote !== recurrenceDetail?.visitorNote) {
        variables.visitorNote = visitorNote;
      }

      await apolloClient.mutate({
        mutation: updateRecurrencePassesMutation,
        variables,
      });

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      throw error;
    }
  };

  return {
    isLoading,
    recurrenceType,
    schedule,
    isPristine,
    staffNote,
    visitorNote,
    updateVisitorNote,
    updateStaffNote,
    reset,
    onChange,
    onSave: handleOnEditSave,
  };
};
