import { DateTime } from 'luxon';
import { getBatchPublicClient } from 'lane-shared/apollo';
import { ReservableEdge } from 'graphql-query-contracts';
import { getContentFeatureReservableAvailability } from 'lane-shared/graphql/content';
import DateRange from 'lane-shared/properties/baseTypes/DateRange';
import { ReservableEventTypeEnum } from 'lane-shared/hooks/useReservableAvailabilityByRange';

export type ReservableWithReservationRanges = Awaited<
  Promise<
    PromiseLike<ReturnType<typeof fetchUnavailabilityRangesForReservables>>
  >
>;

export async function fetchUnavailabilityRangesForReservables(
  referenceDate: Date,
  reservablesOnChannel: ReservableEdge[]
) {
  const batchClient = getBatchPublicClient();
  const unavailables = await Promise.all(
    reservablesOnChannel.map(async ({ node: content }) => {
      const startDate = DateTime.fromJSDate(referenceDate);
      const endDate = startDate.plus({ hours: 24 });

      const unavailable = await batchClient.query<{
        contentFeatureReservableAvailability: {
          startDate: string;
          endDate: string;
          eventType: ReservableEventTypeEnum | null;
        }[];
      }>({
        query: getContentFeatureReservableAvailability,
        fetchPolicy: 'network-only',
        variables: {
          id: content.id,
          startDate: referenceDate,
          endDate: endDate.toJSDate(),
        },
      });

      if (unavailable.error) {
        throw unavailable.error;
      }

      const unavailableRanges = unavailable.data.contentFeatureReservableAvailability.map(
        ({ startDate, endDate, eventType }) => ({
          interval: DateRange.parseValue({ startDate, endDate }),
          eventType,
        })
      );

      return {
        id: content.id,
        name: content.name,
        reservableBookings: content.reservableBookings,
        type: content.type,
        timeZone: content.timeZone,
        features: content.features,
        unavailableRanges,
      };
    })
  );

  return unavailables;
}
