import { v4 as uuid } from 'uuid';

import migrateBlock from './migrateBlock';

export default function migrateV1ToV5(content: any) {
  return {
    _id: uuid(),
    blockId: '2wECcIaKSKjzQNKikhIeO7',
    blockVersion: 0,
    properties: {
      children: content.block.blocks?.map(migrateBlock),
    },
    name: content.name,
  };
}
