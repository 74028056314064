import { M, H4 } from 'design-system-web';
import { OrderSummaryWithProducts } from 'lane-shared/domains/billingPayments/types';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OrderSummaryWithProductsDetails.scss';

interface OrderSummaryWithProductsDetailsProps {
  orderSummary: OrderSummaryWithProducts;
}

export function OrderSummaryWithProductsDetails({
  orderSummary,
}: OrderSummaryWithProductsDetailsProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.summaryRow}>
        <M>{t('abp.payment.subtotal')}</M>
        <M>
          {currencyFormatter({
            currency: orderSummary.currency,
          })(Number(orderSummary.cashTotal - orderSummary.taxTotal))}
        </M>
      </div>
      <div className={styles.summaryRow}>
        <M>{t('abp.payment.tax')}</M>
        <M>
          {currencyFormatter({
            currency: orderSummary.currency,
          })(Number(orderSummary.taxTotal))}
        </M>
      </div>
      <div className={styles.summaryRow}>
        <H4>{t('abp.payment.orderSummary.total')}</H4>
        <div className={styles.total}>
          <div className={styles.currencyAndValue}>
            <H4 className={styles.currency}>{orderSummary.currency}</H4>
            <H4>
              {currencyFormatter({
                currency: orderSummary.currency,
              })(Number(orderSummary.cashTotal))}
            </H4>
          </div>
          {orderSummary?.subscriptionDetails && (
            <H4
              block
              className="self-end text-md font-normal text-text-subdued"
            >
              {t(
                `abp.payment.orderSummary.recurrenceInterval.${orderSummary?.subscriptionDetails?.recurrenceInterval}`
              )}
            </H4>
          )}
        </div>
      </div>
    </>
  );
}
