import React from 'react';

import { ServiceRequestType } from 'lane-shared/domains/workOrder';

import { FeatureRendererPropsType } from 'lane-web/src/components/renderers/features/FeatureRendererPropsType';

export interface ServiceRequestFeatureProperties extends ServiceRequestType {
  _version: undefined | 1;
}

export const ServiceRequestFeatureSettingsRenderer = ({
  className,
  style,
}: FeatureRendererPropsType<ServiceRequestFeatureProperties>) => {
  return <div className={className} style={style} />;
};
