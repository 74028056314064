import { AddNewCardAutopaymentModal } from './AddNewCardAutopaymentModal';
import { UseExistingCardForAutopayment } from './UseExistingCardForAutopayment';
import styles from './invoiceAutopayment.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Checkbox } from 'design-system-web';
import { ErrorMessage, Line } from 'components';
import { M } from 'components/typography';
import { config } from 'lane-shared/config';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentSource } from 'graphql-query-contracts';

const stripePromise = loadStripe(config.stripe.publishableKey ?? '');

interface Props {
  paymentSources: PaymentSource[];
  saveAutopaymentMethod: (paymentMethod: string) => Promise<void>;
  closeModal: () => void;
}

export function TurnOnAutopaymentModal({
  saveAutopaymentMethod,
  closeModal,
  paymentSources,
}: Props) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<Error | string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [saveNewPaymentMethod, setSaveNewPaymentMethod] = useState<
    (() => Promise<string>) | undefined
  >(undefined);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentSources[0]?.paymentMethodId || ''
  );
  const [isAddNewCardClicked, setIsAddNewCardClicked] = useState(false);
  const isExistingCardView =
    Boolean(paymentSources.length) && !isAddNewCardClicked;

  const handleOnSave = async () => {
    setIsLoading(true);
    try {
      let paymentMethodId = selectedPaymentMethod;
      if (!isExistingCardView && saveNewPaymentMethod) {
        paymentMethodId = await saveNewPaymentMethod();
      }
      await saveAutopaymentMethod(paymentMethodId);
      window.Toast.show(
        t('abp.routes.accountInvoices.autopayment.modal.autopaymentAdded')
      );
      closeModal();
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isSaveDisabled = () => {
    if (isExistingCardView) {
      return !isCheckboxChecked;
    }
    return !isCheckboxChecked || !saveNewPaymentMethod;
  };

  return (
    <div>
      <M level="h3">
        {t(
          'abp.routes.accountInvoices.autopayment.modal.turnOnAutomaticPayments'
        )}
      </M>
      {isExistingCardView ? (
        <UseExistingCardForAutopayment
          paymentSources={paymentSources}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setIsAddNewCardClicked={setIsAddNewCardClicked}
        />
      ) : (
        <Elements stripe={stripePromise}>
          <AddNewCardAutopaymentModal
            isAddNewCardClicked={isAddNewCardClicked}
            setIsAddNewCardClicked={setIsAddNewCardClicked}
            setSaveNewPaymentMethod={setSaveNewPaymentMethod}
          />
        </Elements>
      )}

      <Checkbox
        value="acceptAutopayment"
        selected={isCheckboxChecked}
        onChange={() => setIsCheckboxChecked(prev => !prev)}
        dataCy="TurnOnAutopaymentModalCheckbox"
        text={t('abp.routes.accountInvoices.autopayment.modal.authorization')}
      />
      <Line className={styles.buttonDivider} />
      <div className={styles.turnOnAutopaymentButtonGroup}>
        <Button
          dataCy="TurnOnAutopaymentModalCancelButton"
          onClick={() => closeModal()}
          variant="secondary"
          size="large"
        >
          {t('Cancel')}
        </Button>
        <Button
          dataCy="TurnOnAutopaymentModalSaveButton"
          onClick={() => handleOnSave()}
          disabled={isSaveDisabled()}
          size="large"
          loading={isLoading}
        >
          {t('Save')}
        </Button>
      </div>
      <div className={styles.errorMessageContainer}>
        <ErrorMessage error={errorMessage} fullWidth />
      </div>
    </div>
  );
}
