import React from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { parseDateTime } from 'lane-shared/helpers/dates';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';

import { Alert, AlertType } from 'components/lds';

import styles from './DraftContentWarnings.scss';

type Props = Partial<DraftContentType> & {
  hasChanged: boolean;
};
export const DraftContentWarnings = ({
  hasChanged,
  interactionEndDate,
  unpublishDate,
  liveDate,
}: Props) => {
  const { t } = useTranslation();

  if (!hasChanged) return null;
  let warningMessage;

  if (
    interactionEndDate &&
    parseDateTime(interactionEndDate)! < DateTime.local()
  ) {
    warningMessage = t(
      `The interaction end time has passed and it can no longer accept any new submissions. You can override this by editing the interaction end time.`
    );
  }
  if (unpublishDate) {
    if (parseDateTime(unpublishDate)! < DateTime.local()) {
      warningMessage = t(`The unpublish date is in the past.`);
    }
    if (liveDate && parseDateTime(unpublishDate)! < parseDateTime(liveDate)!) {
      warningMessage = t(`The unpublish date is before publish date.`);
    }
  }
  return warningMessage ? (
    <Alert type={AlertType.warning} className={styles.alertBox}>
      {warningMessage}
    </Alert>
  ) : null;
};
