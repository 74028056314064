import React, { useState, useEffect, useMemo } from 'react';

import cx from 'classnames';
import {
  Button,
  ControlMenu,
  ErrorMessage,
  WhitelabeledLogo,
} from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { errorCodes } from 'activate-errors';
import { signIn } from 'lane-shared/graphql/user';
import { getMessagesFromError } from 'lane-shared/helpers';
import emitter, { EVENT_AUTH_TOKEN } from 'lane-shared/helpers/emitter';
import { useUserLoginsQuery } from 'lane-shared/hooks';
import {
  UserLoginTypeEnum,
  UserLoginStatusEnum,
  UserLoginType,
} from 'lane-shared/types/UserLogin';

import AuthForm from 'components/auth/AuthForm';

import { history } from '../../../helpers';

import styles from './SignupValidateEmail.scss';

type Props = {
  onLogin: () => void;
  isModal?: boolean;
};

export default function SignupValidateEmail({
  onLogin = () => null,
  isModal,
}: Props) {
  const { email, password } = useSignUpContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string[]>();

  const { t } = useTranslation();

  const [userLogins, userLoginsLoading] = useUserLoginsQuery();

  const isEmailLoginValidated = useMemo(
    () =>
      userLogins.length > 0 &&
      userLogins.some(
        (login: UserLoginType) =>
          login?.key.toLowerCase() === email.toLowerCase() &&
          login.status === UserLoginStatusEnum.Verified
      ),
    [email, userLogins]
  );

  useEffect(() => {
    if (isEmailLoginValidated) {
      history.push(routes.home);
      onLogin();
    } else {
      setError([t(errorCodes.unverifiedEmailPersistsError.message)]);
    }
  }, [isEmailLoginValidated]);

  async function login() {
    setLoading(true);

    if (!email || !password) {
      // Maybe the user refreshed?
      // Either way, we don't have enough info to login
      history.push(routes.login);
    }

    try {
      const { data } = await getClient().mutate({
        mutation: signIn,
        variables: {
          loginKey: email,
          password,
          loginType: UserLoginTypeEnum.Email,
        },
      });
      emitter.emit(EVENT_AUTH_TOKEN, {
        authToken: data.signIn,
        route: routes.signUpValidateEmail,
      });
    } catch (err) {
      setError(getMessagesFromError(err));
    }
    setLoading(false);
  }

  return (
    <div className={cx(isModal && styles.isModal, styles.SignupValidateEmail)}>
      {!isModal && <WhitelabeledLogo />}
      <AuthForm className={styles.instructions}>
        <h1>{t('Almost there!')}</h1>
        <p>{t('This channel requires that you verify your email address.')}</p>
        {email ? (
          <p>
            {t("We've sent an email to ")}
            <span>{email}.</span>
            {t(' It contains instructions on getting started.')}
          </p>
        ) : (
          <p>
            {t(
              "We've sent you an email. It contains instructions on getting started."
            )}
          </p>
        )}

        <ErrorMessage error={error} />

        <ControlMenu className={styles.menu}>
          <Button
            size="large"
            loading={loading || userLoginsLoading}
            onClick={login}
          >
            {t('I validated my email')}
          </Button>
        </ControlMenu>
      </AuthForm>
    </div>
  );
}
