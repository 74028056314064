import { useState } from 'react';

import { useTranslation } from 'react-i18next';

export const useAfterRefresh = () => {
  const [visitorIds, setVisitorIds] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleRefresh = (currentVisitorIds: string[]): void => {
    const prevVisitorIds = visitorIds;
    let toastMessage;
    if (prevVisitorIds.length > 0) {
      if (currentVisitorIds === prevVisitorIds) {
        toastMessage = t('web.admin.channel.visitor.log.refresh.uptodate');
      } else {
        toastMessage = t('web.admin.channel.visitor.log.refresh.updated');
      }
      window.Toast.show(toastMessage, 5000);
    }
    setVisitorIds(currentVisitorIds);
  };

  return {
    handleRefresh,
    visitorIds,
    setVisitorIds,
  };
};
