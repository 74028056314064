import { errorCodeTypes as floorErrorCodeTypes } from '../floors';
import { errorCodeTypes as unitErrorCodeTypes } from '../units';

export type ErrorCodeType = {
  // a unique code used to determine what the error is, this will be included
  // in the error message so that errors can be determined after being
  // serialized, transformed, displayed, etc.
  code: string;
  // the name of the error object
  name: string;
  // the message for this error
  message: string;
  // HTTP status code if applicable
  status?: number;

  errorType?: string;

  safetrustCode?: string;
};

export const errorCodes = {
  alreadyExistsError: {
    code: 'AE01',
    name: 'AlreadyExistsError',
    message: 'This already exists.',
  } as ErrorCodeType,
  audienceNotFoundError: {
    code: 'AUD01',
    name: 'AudienceNotFoundError',
    message: 'This Audience could not be found.',
  } as ErrorCodeType,
  audienceMissingFieldError: {
    code: 'AUD02',
    name: 'AudienceMissingFieldError',
    message: 'This Audience is missing a required field.',
  } as ErrorCodeType,
  audienceAttachContentError: {
    code: 'AUD03',
    name: 'AudienceAttachContentError',
    message:
      'This piece of content cannot be associated to one or more of the audiences specified',
  } as ErrorCodeType,
  audienceNotEmptyError: {
    code: 'AUD04',
    name: 'AudienceNotEmptyError',
    message:
      'This audience could not be deleted because there is content attached to it',
  } as ErrorCodeType,
  badRequestError: {
    code: 'R01',
    name: 'BadRequestError',
    message: 'Bad Request.',
    status: 400,
  } as ErrorCodeType,
  channelCreationError: {
    code: 'CH01',
    name: 'ChannelCreationError',
    message: 'Cannot create this channel',
  } as ErrorCodeType,
  channelFiltersError: {
    code: 'CH02',
    name: 'ChannelFiltersError',
    message: 'You do not have the required email address to join this channel.',
  } as ErrorCodeType,
  channelIntegrationNotSetupError: {
    code: 'CI01',
    name: 'ChannelIntegrationNotSetupError',
    message: 'This channel integration is not properly setup.',
  } as ErrorCodeType,
  channelInviteOnlyError: {
    code: 'CH03',
    name: 'ChannelInviteOnlyError',
    message: 'This Channel is Invite Only.',
  } as ErrorCodeType,
  channelMaxUsersError: {
    code: 'CH04',
    name: 'ChannelMaxUsersError',
    message: 'This channel is at max users',
  } as ErrorCodeType,
  channelNotSetupError: {
    code: 'CH06',
    name: 'ChannelNotSetupError',
    message: 'A Channel must have an address set before it can create content.',
  } as ErrorCodeType,
  channelPrivateError: {
    code: 'CH07',
    name: 'ChannelPrivateError',
    message: 'This Channel is Private.',
  } as ErrorCodeType,
  removeUserFromChannelError: {
    code: 'CH08',
    name: 'removeUserFromChannelError',
    message: 'Unable to remove user from channel.',
  } as ErrorCodeType,
  channelRelationshipCreationError: {
    code: 'CR01',
    name: 'ChannelRelationshipCreationError',
    message: 'Creating this Channel Relationship failed.',
  } as ErrorCodeType,
  completeSignupExpiredError: {
    code: 'SU01',
    name: 'CompleteSignupExpiredError',
    message: 'Your signup link has expired.',
  } as ErrorCodeType,
  contentNotEnoughQuantity: {
    code: 'CF01',
    name: 'ContentNotEnoughQuantityError',
    message: 'Unfortunately, these have all been claimed',
  } as ErrorCodeType,
  contentPublishError: {
    code: 'CP01',
    name: 'ContentPublishError',
    message: 'A piece of content must be targeted before being published.',
  } as ErrorCodeType,
  eventContentMissingStartOrEndDateError: {
    code: 'CP02',
    name: 'EventContentMissingStartOrEndDateError',
    message: 'Content marked as an event must have event dates.',
  },
  contentMissingTitleError: {
    code: 'CT01',
    name: 'ContentMissingTitleError',
    message: 'Draft Content must have a title',
  } as ErrorCodeType,
  contentDoesNotExistError: {
    code: 'CT02',
    name: 'ContentDoesNotExistError',
    message: 'This piece of content does not exist',
  } as ErrorCodeType,
  contentMissingCompleteWorkflowError: {
    code: 'CT03',
    name: 'ContentMissingCompleteWorkflowError',
    message: 'Complete all workflows before publishing.',
  } as ErrorCodeType,
  contentRequestedMoreThanMax: {
    code: 'CF02',
    name: 'ContentRequestedMoreThanMaxError',
    message: 'You requested more than the maximum allowed amount per person',
  } as ErrorCodeType,
  notificationValidationError: {
    code: 'NT01',
    name: 'NotificationValidationError',
    message: 'Please ensure that the notification meets the required criteria.',
  } as ErrorCodeType,
  notLoggedIn: {
    code: 'L01',
    name: 'NotLoggedInError',
    message: 'You are not logged in.',
    status: 401,
  } as ErrorCodeType,
  notFoundError: {
    code: 'NF01',
    name: 'NotFoundError',
    message: 'This resource was not found.',
    status: 404,
  } as ErrorCodeType,
  notImplementedError: {
    code: 'NI01',
    name: 'NotImplementedError',
    message: 'This is not yet implemented.',
    status: 404,
  } as ErrorCodeType,
  networkRequestFailedError: {
    code: 'NW01',
    name: 'NetworkRequestFailedError',
    message: 'Network request failed.',
  } as ErrorCodeType,
  passwordResetExpiredError: {
    code: 'PW01',
    name: 'PasswordResetExpiredError',
    message: 'Your password reset link has expired, please try again.',
    status: 403,
  } as ErrorCodeType,
  oauthNotSetupError: {
    code: 'PC04',
    name: 'OAuthNotSetupError',
    message: 'You need to link this OAuth account',
  } as ErrorCodeType,
  loginInvalidError: {
    code: 'L02',
    name: 'LoginInvalidError',
    message: 'You’ve entered an invalid email or password.',
  } as ErrorCodeType,
  loginNotExistsError: {
    code: 'L03',
    name: 'LoginNotExistsError',
    message: 'This login does not yet exist.',
  } as ErrorCodeType,
  magicLinkExpiredError: {
    code: 'L04',
    name: 'MagicLinkExpiredError',
    message: 'This magic link has expired.',
  } as ErrorCodeType,
  emailAlreadyInUseError: {
    code: 'E02',
    name: 'EmailAlreadyInUseError',
    message: 'This email address is already in use.',
  } as ErrorCodeType,
  phoneNumberAlreadyInUseError: {
    code: 'E05',
    name: 'PhoneNumberAlreadyInUseError',
    message: 'This phone number is already in use.',
  } as ErrorCodeType,
  unverifiedEmailError: {
    code: 'E03',
    name: 'UnverifiedEmailError',
    message: 'You must verify your email to login.',
  } as ErrorCodeType,
  invalidEmailError: {
    code: 'E04',
    name: 'InvalidEmailError',
    message: 'This email has bounced or has registered a complaint.',
  } as ErrorCodeType,
  unverifiedEmailPersistsError: {
    code: 'E06',
    name: 'UnverifiedEmailErrorChannel',
    message: 'Email has not been verified yet.',
  } as ErrorCodeType,
  unverifiedEmailNotFound: {
    code: 'E07',
    name: 'unverifiedEmailNotFound',
    message: 'This email is not found or verified already.',
  } as ErrorCodeType,
  invalidMediaContentTypeError: {
    code: 'ME01',
    name: 'InvalidMediaContentTypeError',
    message: 'This media type isn’t supported.',
  } as ErrorCodeType,
  inviteClaimedError: {
    code: 'IV01',
    name: 'InviteClaimedError',
    message: 'This invite has already been claimed.',
  } as ErrorCodeType,
  inviteExpiredError: {
    code: 'IV02',
    name: 'InviteExpiredError',
    message: 'This invite has expired.',
  } as ErrorCodeType,
  inviteInvalidEmailError: {
    code: 'IV03',
    name: 'InviteInvalidEmailError',
    message: 'This invite email did not match the email you signed up with.',
  } as ErrorCodeType,
  missingParametersError: {
    code: 'MP01',
    name: 'MissingParametersError',
    message: 'You are missing parameters.',
  } as ErrorCodeType,
  paymentNotSetupError: {
    code: 'P01',
    name: 'PaymentNotSetupError',
    message: 'You have not setup a valid payment option for your account.',
  } as ErrorCodeType,
  paymentFailedError: {
    code: 'P02',
    name: 'PaymentFailedError',
    message: 'This payment failed, see details for more info.',
  } as ErrorCodeType,
  invalidCardError: {
    code: 'P03',
    name: 'InvalidCardError',
    message:
      'Your card could not be validated. Please check your information and try again.',
  } as ErrorCodeType,
  paymentSettingsAccessPermission: {
    code: 'P04',
    name: 'PaymentSettingsAccessPermission',
    message:
      'You do not currently have permission to access the payment settings for this user.',
  } as ErrorCodeType,
  inUseError: {
    code: 'I01',
    name: 'InUseError',
    message: "You can't remove this because it is in use.",
  } as ErrorCodeType,
  invalidStatusTransitionError: {
    code: 'FS01',
    name: 'InvalidStatusTransitionError',
    message:
      'Invalid status transition. Please contact the creator of this content for assistance',
  } as ErrorCodeType,
  invalidIdError: {
    code: 'ID01',
    name: 'InvalidIdError',
    message: 'This id is invalid.',
  } as ErrorCodeType,
  invalidFeatureStateError: {
    code: 'FS03',
    name: 'InvalidFeatureStateError',
    message: 'Invalid Feature State',
  } as ErrorCodeType,
  invalidTypeValueError: {
    code: 'TV03',
    name: 'InvalidTypeValueError',
    message: 'Invalid type value',
  } as ErrorCodeType,
  missingStatusTransitionRuleError: {
    code: 'FS02',
    name: 'MissingStatusTransitionRuleError',
    message: 'There is no rule from this status to the next.',
  } as ErrorCodeType,
  contentFeatureDoesntExistError: {
    code: 'CF01',
    name: 'ContentFeatureDoesntExistError',
    message: 'This feature does not exist on this Content.',
  } as ErrorCodeType,
  contentFeatureCancelableError: {
    code: 'CFC01',
    name: 'ContentFeatureCancelableError',
    message: 'This interaction is over and cannot be Cancelled',
  } as ErrorCodeType,
  dataSecurityError: {
    code: 'DS01',
    name: 'DataSecurityError',
    message: 'This data cannot be read.',
  } as ErrorCodeType,
  contentFeatureReservableNotAvailableError: {
    code: 'CFR01',
    name: 'ContentFeatureReservableNotAvailableError',
    message: 'This time is no longer available',
  } as ErrorCodeType,
  contentFeatureGuestInviteError: {
    code: 'CFG01',
    name: 'ContentFeatureGuestInviteError',
    message: 'Invalid number of guests invited.',
  } as ErrorCodeType,
  groupRoleExistsError: {
    code: 'GR01',
    name: 'GroupRoleExistsError',
    message: 'A GroupRole with this name already exists',
  } as ErrorCodeType,
  groupRoleInUseError: {
    code: 'GR02',
    name: 'GroupRoleInUseError',
    message: 'This GroupRole is still in use',
  } as ErrorCodeType,
  groupRoleRequirementsError: {
    code: 'GR03',
    name: 'GroupRoleRequirementsError',
    message: "Can't add GroupRole requirements while it has existing users",
  } as ErrorCodeType,
  userGroupRoleMaxUsersError: {
    code: 'UGM01',
    name: 'UserGroupRoleMaxUsersError',
    message: 'This group is at max users.',
  } as ErrorCodeType,
  notAuthorizedError: {
    code: 'AUTH01',
    name: 'NotAuthorizedError',
    message: 'You are not authorized to perform this action.',
    status: 401,
  } as ErrorCodeType,
  uciExpiredError: {
    code: 'UCI01',
    name: 'UCIExpiredError',
    message: 'Has ended.',
  } as ErrorCodeType,
  contentStartedError: {
    code: 'CS01',
    name: 'ContentStartedError',
    message: 'Has not started yet.',
  } as ErrorCodeType,
  phoneVerificationCodeIncorrectError: {
    code: 'PHVE01',
    name: 'PhoneVerificationCodeIncorrectError',
    message: 'The code you entered is incorrect. Please try again',
  } as ErrorCodeType,
  phoneVerificationError: {
    code: 'PHVE02',
    name: 'PhoneVerificationError',
    message: 'We are unable to send the SMS. Please try again.',
  } as ErrorCodeType,
  secureIdRequiredError: {
    code: 'SI01',
    name: 'SecureIdRequiredError',
    message: 'You need a Secure Id to complete this.',
  } as ErrorCodeType,
  secureIdExpiredError: {
    code: 'SI02',
    name: 'SecureIdExpiredError',
    message: 'This Secure ID has expired.',
  } as ErrorCodeType,
  secureIdInvalidError: {
    code: 'SI03',
    name: 'SecureIdInvalidError',
    message: 'This Secure ID is invalid.',
  } as ErrorCodeType,
  slugExistsError: {
    code: 'SL01',
    name: 'SlugExistsError',
    message: 'Slug already exists.',
  } as ErrorCodeType,
  tabIconExistsError: {
    code: 'TAB01',
    name: 'TabIconExistsError',
    message: 'A tab with this icon already exists.',
  } as ErrorCodeType,
  invalidQRCodeError: {
    code: 'QR01',
    name: 'InvalidQRCodeError',
    message: 'This QR Code is invalid.',
  } as ErrorCodeType,
  userGroupRoleExistsError: {
    code: 'GR01',
    name: 'UserGroupRoleExistsError',
    message: 'A UserGroupRole for this user already exists.',
  } as ErrorCodeType,
  angusIntegrationBadSetup: {
    code: 'IA02',
    name: 'AngusIntegrationBadSetupError',
    errorType: 'invalid_client',
    message: 'Please contact your property manager or try again later.',
  } as ErrorCodeType,
  homIntegrationSetupError: {
    code: 'H01',
    name: 'HomIntegrationSetupError',
    message: 'HOM integration is not setup properly.',
  } as ErrorCodeType,
  homRSVPError: {
    code: 'H02',
    name: 'HomRSVPError',
    message: 'Was not able to RSVP to HOM Event.',
  } as ErrorCodeType,
  homUNRSVPError: {
    code: 'H02',
    name: 'HomUNRSVPError',
    message: 'Was not able to UNRSVP to HOM Event.',
  } as ErrorCodeType,
  newstandIntegrationSetupError: {
    code: 'NS01',
    name: 'NewstandIntegrationSetupError',
    message: 'Newstand integration is not setup properly.',
  } as ErrorCodeType,
  newstandDigestError: {
    code: 'NS02',
    name: 'NewstandDigestError',
    message: 'Error processing Newstand Digest.',
  } as ErrorCodeType,
  parkWhizOAuthNotSetupError: {
    code: 'PW01',
    name: 'ParkWhizOAuthNotSetupError',
    message: 'ParkWhiz account is not setup.',
  } as ErrorCodeType,
  angusApiError: {
    code: 'IA03',
    name: 'AngusApiError',
    message: 'Please contact your property manager or try again later.',
  } as ErrorCodeType,
  placePayError: {
    code: 'PLP01',
    name: 'PlacePayError',
    message: 'Encountered an error with PlacePay, please contact support.',
  } as ErrorCodeType,
  proxyClickApiError: {
    code: 'PC01',
    name: 'ProxyClickApiError',
    message: 'Please contact your property manager or try again later.',
  } as ErrorCodeType,
  essensysIntegrationSetupError: {
    code: 'EI01',
    name: 'EssensysIntegrationSetupError',
    message:
      'Essensys integration is not setup properly! Please contact your account manager or try again later.',
  } as ErrorCodeType,
  essensysApiError: {
    code: 'EI02',
    name: 'EssensysApiError',
    message:
      'Essensys API error. Please contact your account manager or try again later.',
  } as ErrorCodeType,
  essensysRequestContainsValidationError: {
    code: 'EI03',
    name: 'EssensysRequestContainsValidationError',
    message:
      'Essensys Request error. Please contact your account manager or try again later.',
  } as ErrorCodeType,
  essensysSyncError: {
    code: 'ES01',
    name: 'EssensysSyncError',
    message: 'A failure has occurred during the sync process',
  } as ErrorCodeType,
  essensysBookingConflictError: {
    code: 'EI04',
    name: 'EssensysBookingConflictError',
    message:
      'This timeslot is already reserved, select another timeslot and try again.',
  } as ErrorCodeType,
  essensysSubmitOnBehalfOfError: {
    code: 'EI05',
    name: 'EssensysSubmitOnBehalfOfError',
    message:
      'Bookings can only be made on behalf of Essensys users that are on account.',
  } as ErrorCodeType,
  oAuthConfigError: {
    code: 'OA01',
    name: 'OAuthConfigError',
    message: 'Please contact your property manager or try again later',
  } as ErrorCodeType,
  oAuthIdentityExpiredError: {
    code: 'OA02',
    name: 'OAuthIdentityExpiredError',
    message: 'An expired id_token has been rejected.',
  } as ErrorCodeType,
  oAuthClientSecretError: {
    code: 'OA03',
    name: 'oAuthClientSecretError',
    message: 'A client_secret will be required for the given provider.',
  } as ErrorCodeType,
  singleSignOnProviderNotAvailable: {
    code: 'SSO01',
    name: 'SingleSignOnProviderNotAvailableError',
    message: 'The SSO provider you are attempting to use is not supported.',
  } as ErrorCodeType,
  singleSignOnOpenIdNotAvailable: {
    code: 'SSO02',
    name: 'SingleSignOnOpenIdNotAvailableError',
    message:
      'Auto-discovery failed for the SSO provider you are using, please try again later.',
  } as ErrorCodeType,
  singleSignOnIdTokenRejected: {
    code: 'SSO03',
    name: 'SingleSignOnIdTokenRejectedError',
    message: 'The provided `id_token` has been rejected',
  } as ErrorCodeType,
  signUpPrivateLocationError: {
    code: 'SU02',
    name: 'SignUpPrivateLocationError',
    message: 'Sorry, you must request access to join this location.',
  } as ErrorCodeType,
  signUpFullAccessError: {
    code: 'SU03',
    name: 'SignUpFullAccessError',
    message:
      'Some content may not be available, you can contact your property manager for full access.',
  } as ErrorCodeType,
  ApiKeyEnvironmentError: {
    code: 'AKE01',
    name: 'ApiKeyEnvironmentError',
    message:
      'key is not supported in this environment. Make sure you use the correct key.',
  } as ErrorCodeType,
  signUpUnsupportedLocationError: {
    code: 'SU04',
    name: 'SignUpUnsupportedLocationError',
    message: 'Sorry, this location is not supported yet.',
  } as ErrorCodeType,
  outlookCalendarChannelIntegrationSettingsNotFound: {
    code: 'OCI01',
    name: 'OutlookCalendarChannelIntegrationSettingsNotFound',
    message: 'Outlook Calendar channel integration is not setup!',
  } as ErrorCodeType,
  outlookCalendarInvalidFeatureSetup: {
    code: 'OCI02',
    name: 'OutlookCalendarInvalidFeatureSetup',
    message:
      'Outlook Calendar feature is not setup properly! Please contact your account manager or try again later.',
  } as ErrorCodeType,
  outlookCalendarReservableFeatureNotFound: {
    code: 'OCI03',
    name: 'OutlookCalendarReservableFeatureNotFound',
    message:
      'Outlook Calendar feature is not setup properly! Please contact your account manager or try again later.',
  } as ErrorCodeType,
  outlookCalendarTimeNotAvailableError: {
    code: 'OCI04',
    name: 'OutlookCalendarTimeNotAvailableError',
    message:
      'This time is no longer available. Please select another timeslot and try again.',
  } as ErrorCodeType,
  shopifyMultipassIntegrationSetupError: {
    code: 'SMI01',
    name: 'shopifyMultipassIntegrationSetupError',
    message: 'ShopifyMultipass integration is not setup properly.',
  } as ErrorCodeType,
  shopifyMultipassNoVerifiedEmailError: {
    code: 'SMI02',
    name: 'shopifyMultipassNoVerifiedEmailError',
    message:
      'Unable to find valid verified email, please verify the email you wish to use with this store.',
  } as ErrorCodeType,
  shopifyMultipassStoreRequestError: {
    code: 'SMI03',
    name: 'shopifyMultipassStoreRequestError',
    message: 'Failed to query Shopify store API.',
  } as ErrorCodeType,
  shopifyMultipassUserNotFound: {
    code: 'SMI04',
    name: 'shopifyMultipassUserNotFound',
    message:
      'We can’t seem to find that email. Please double check your email and try again.',
  } as ErrorCodeType,
  googleCalendarChannelIntegrationSettingsNotFound: {
    code: 'GCI01',
    name: 'GoogleCalendarChannelIntegrationSettingsNotFound',
    message: 'Google Calendar channel integration is not setup!',
  } as ErrorCodeType,
  googleCalendarInvalidFeatureSetup: {
    code: 'GCI02',
    name: 'GoogleCalendarInvalidFeatureSetup',
    message:
      'Google Calendar feature is not setup properly! Please contact your account manager or try again later.',
  } as ErrorCodeType,
  googleCalendarExistingEventNotFound: {
    code: 'GCI02',
    name: 'GoogleCalendarExistingEventNotFound',
    message: 'Google Calendar event or event ID not found.',
  } as ErrorCodeType,
  googleCalendarReservableFeatureNotFound: {
    code: 'GCI03',
    name: 'GoogleCalendarReservableFeatureNotFound',
    message:
      'Google Calendar feature is not setup properly! Please contact your account manager or try again later.',
  } as ErrorCodeType,
  googleCalendarTimeNotAvailableError: {
    code: 'GCI04',
    name: 'GoogleCalendarTimeNotAvailableError',
    message:
      'This time is no longer available. Please select another timeslot and try again.',
  } as ErrorCodeType,
  essensysOnlinePlanError: {
    code: 'EOP01',
    name: 'EssensysOnlinePlanError',
    message: 'Online Plan error.  Please contact admin.',
  } as ErrorCodeType,
  deleteChannelChildrenError: {
    code: 'DC01',
    name: 'deleteChannelChildrenError',
    message: 'Children channels must be removed in order to delete a channel',
  } as ErrorCodeType,
  deleteChannelSubChannelError: {
    code: 'DC02',
    name: 'deleteChannelSubChannelError',
    message: 'Sub channels must be removed in order to delete a channel',
  } as ErrorCodeType,
  deleteChannelUsersError: {
    code: 'DC02',
    name: 'deleteChannelUsersError',
    message: 'Users must be removed in order to delete a channel',
  } as ErrorCodeType,
  deleteUserMaskError: {
    code: 'DU01',
    name: 'deleteUserMaskError',
    message: 'Error masking user and fetching profileId',
  } as ErrorCodeType,
  deleteUserMaskProfileError: {
    code: 'DU02',
    name: 'deleteUserMaskProfileError',
    message: "Error masking user's profile",
  } as ErrorCodeType,
  deleteUserLoginsError: {
    code: 'DU03',
    name: 'deleteUserLoginsError',
    message: "Error deleting user's logins",
  } as ErrorCodeType,
  deleteUserSessionsError: {
    code: 'DU04',
    name: 'deleteUserSessionsError',
    message: "Error deleting user's sessions",
  } as ErrorCodeType,
  deleteUserEventSubscriptionsError: {
    code: 'DU05',
    name: 'deleteUserEventSubscriptionsError',
    message: "Error deleting user's event subscriptions",
  } as ErrorCodeType,
  deleteUserGroupRolesError: {
    code: 'DU06',
    name: 'deleteUserGroupRolesError',
    message: "Error deleting user's group roles",
  } as ErrorCodeType,
  deleteUserIntegrationsError: {
    code: 'DU07',
    name: 'deleteUserIntegrationsError',
    message: "Error deleting user's integrations",
  } as ErrorCodeType,
  deleteUserMaskPaymentAccountsError: {
    code: 'DU08',
    name: 'deleteUserMaskPaymentAccountsError',
    message: "Error masking user's payment accounts",
  } as ErrorCodeType,
  deleteUserDeviceTokenError: {
    code: 'DU09',
    name: 'deleteUserDeviceTokenError',
    message: "Error deleting user's device tokens",
  } as ErrorCodeType,
  disableUserError: {
    code: 'DIU01',
    name: 'disableUserError',
    message: 'Error disabling user',
  } as ErrorCodeType,
  enableUserError: {
    code: 'EUS01',
    name: 'enableUserError',
    message: 'Error enabling user',
  } as ErrorCodeType,
  enableDeletedUserError: {
    code: 'EUS02',
    name: 'enableDeletedUserError',
    message: 'Error deleted users cannot be re-enabled',
  } as ErrorCodeType,
  essensysBookingZeroAmountError: {
    code: 'EI06',
    name: 'essensysBookingZeroAmountError',
    message: 'The client type cannot book room at 0 amount.',
  } as ErrorCodeType,
  essensysBookingNegativeAmountError: {
    code: 'EI07',
    name: 'essensysBookingNegativeAmountError',
    message: 'Negative amount is not allowed.',
  } as ErrorCodeType,
  channelModuleNotFoundError: {
    code: 'CM01',
    name: 'ChannelModuleNotFoundError',
    message: 'Channel module name you requested for is not found.',
  } as ErrorCodeType,
  channelModuleAlreadyExistError: {
    code: 'CM02',
    name: 'channelModuleAlreadyExistError',
    message: 'Channel module already exists for this channel',
  } as ErrorCodeType,
  featureNameNotFoundError: {
    code: 'CM03',
    name: 'FeatureNameNotFoundError',
    message: 'Feature name not found',
  } as ErrorCodeType,
  channelModuleTypeError: {
    code: 'CM04',
    name: 'ChannelModuleTypeError',
    message: 'Only channel with type property allowed.',
  } as ErrorCodeType,
  channelModulePramsError: {
    code: 'CM05',
    name: 'ChannelModuleParamsError',
    message: 'Required parameters of Channel module to be are missing.',
  } as ErrorCodeType,
  channelNotFoundError: {
    code: 'CM06',
    name: 'ChannelNotFoundError',
    message: 'Channel you requested for is not found.',
  } as ErrorCodeType,
  hardwareManagementNotEnabledError: {
    code: 'HM01',
    name: 'HardwareManagementNotEnabledError',
    message: 'Hardware management not enabled for channel you requested.',
  } as ErrorCodeType,
  serviceUnavailableError: {
    code: 'SVC01',
    name: 'ServiceUnavailableError',
    message: 'Service is unavailable',
  } as ErrorCodeType,
  continuedAuthForbiddenError: {
    code: 'AUTH02',
    name: 'ContinuedAuthForbiddenError',
    message: 'Continued authorization is forbidden, preferring redirect.',
  } as ErrorCodeType,
  unsupportedMediaTypeError: {
    code: 'MED01',
    name: 'UnsupportedMediaTypeError',
    message: 'This media type is unsupported.',
  } as ErrorCodeType,
  contentOnChannelViaAudiencesSearchError: {
    code: 'AUD01',
    name: 'ContentOnChannelViaAudiencesSearchError',
    message: 'Search parameters are malformed.',
  },
  generateSignedUrlError: {
    code: 'GSU01',
    name: 'generateSignedUrlError',
    message: 'Failed to generate signed URL from S3',
  } as ErrorCodeType,
  ccureInvalidFieldError: {
    code: 'IG01',
    name: 'ccureInvalidFieldError',
    message: 'Missing required parameters for CCURE.',
  } as ErrorCodeType,
  geneaInvalidFieldError: {
    code: 'IG02',
    name: 'geneaInvalidFieldError',
    message: 'Missing required parameters for GENEA.',
  } as ErrorCodeType,
  unsupportedAttachmentTypeError: {
    code: 'UAT01',
    name: 'unsupportedAttachmentTypeError',
    message: 'This attachment type is not supported',
  } as ErrorCodeType,
  acsEmailInviteSendFailedError: {
    code: 'ACS01',
    name: 'AcsEmailInviteSendFailedError',
    message: 'Email invitation could not be sent by ACS worker',
  } as ErrorCodeType,
  reservablebookingsCSVExportRowLimitError: {
    code: 'RB01',
    name: 'ReservablebookingsCSVExportRowLimitError',
    message: 'The CSV file cannot exceed 10000 rows',
  } as ErrorCodeType,
  braxosInvalidFieldError: {
    code: 'IG03',
    name: 'braxosInvalidFieldError',
    message: 'Missing required parameters for BRAXOS.',
  } as ErrorCodeType,
  openpathInvalidFieldError: {
    code: 'IG04',
    name: 'openpathInvalidFieldError',
    message: 'Missing required parameters for OPENPATH.',
  } as ErrorCodeType,
  brivoInvalidFieldError: {
    code: 'IG05',
    name: 'brivoInvalidFieldError',
    message: 'Missing required parameters for BRIVO.',
  } as ErrorCodeType,
  yardiInvalidFieldError: {
    code: 'IG06',
    name: 'yardiInvalidFieldError',
    message: 'Missing required parameters for YARDI.',
  } as ErrorCodeType,
  maxxessInvalidFieldError: {
    code: 'IG06',
    name: 'maxxessInvalidFieldError',
    message: 'Missing required parameters for MAXXESS.',
  } as ErrorCodeType,
  gallagherInvalidFieldError: {
    code: 'IG06',
    name: 'gallagherInvalidFieldError',
    message: 'Missing required parameters for GALLAGHER.',
  } as ErrorCodeType,
  gallagherInvalidClientCertificateError: {
    code: 'IG07',
    name: 'gallagherInvalidClientCertificateError',
    message: 'Invalid Client Certificate for GALLAGHER.',
  } as ErrorCodeType,
  gallagherInvalidCertificatePassphraseError: {
    code: 'IG08',
    name: 'gallagherInvalidCertificatePassphraseError',
    message: 'Invalid Certificate Passphrase for GALLAGHER.',
  } as ErrorCodeType,
  gallagherClientCertificateValidationError: {
    code: 'IG09',
    name: 'gallagherClientCertificateValidationError',
    message:
      'Something went wrong while validating the client certificate for GALLAGHER.',
  } as ErrorCodeType,
  dsxInvalidFieldError: {
    code: 'IG10',
    name: 'dsxInvalidFieldError',
    message: 'Missing required parameters for DSX.',
  } as ErrorCodeType,
  genetecInvalidFieldError: {
    code: 'IG10',
    name: 'genetecInvalidFieldError',
    message: 'Missing required parameters for GENETEC.',
  } as ErrorCodeType,
  saltoSvnInvalidFieldError: {
    code: 'IG10',
    name: 'saltoSvnInvalidFieldError',
    message: 'Missing required parameters for SaltoSvn.',
  } as ErrorCodeType,
  kastleInvalidFieldError: {
    code: 'IG11',
    name: 'kastleInvalidFieldError',
    message: 'Missing required parameters for Kastle.',
  } as ErrorCodeType,
  HIDInvalidFieldError: {
    code: 'IG10',
    name: 'HIDInvalidFieldError',
    message: 'Missing required parameters for HID.',
  } as ErrorCodeType,
  mailchimpInvalidFieldError: {
    code: 'IG11',
    name: 'mailchimpInvalidFieldError',
    message: 'Missing required parameters for Mailchimp.',
  } as ErrorCodeType,
  userNotFoundError: {
    code: 'US01',
    name: 'userNotFoundError',
    message: 'User not found.',
  } as ErrorCodeType,
  userStatusInvalidError: {
    code: 'US02',
    name: 'userStatusInvalidError',
    message: 'Invalid user status',
  } as ErrorCodeType,
  internalError: {
    code: 'IE01',
    name: 'internalError',
    message: 'Something went wrong',
    status: 500,
  } as ErrorCodeType,
  invalidVisitorEndTimeError: {
    code: 'VET01',
    name: 'invalidEndTimeError',
    message: 'The time window must be set to a future date and time',
  } as ErrorCodeType,
  auth0OrganizationNotFoundError: {
    code: 'AU01',
    name: 'auth0OrganizationNotFoundError',
    message: 'Auth0 organization not found.',
  } as ErrorCodeType,
  auth0OrganizationInvitationError: {
    code: 'AU02',
    name: 'auth0OrganizationInvitationError',
    message: 'Unable to create Auth0 invitation.',
  } as ErrorCodeType,
  contentAlreadyPinnedToSectionError: {
    code: 'SEC01',
    name: 'ContentAlreadyPinnedToSectionError',
    message:
      'Unable to pin content to the section because it is already pinned',
  },
  contentNotPinnedToSectionError: {
    code: 'SEC02',
    name: 'ContentNotPinnedToSectionError',
    message: 'Unable to unpin content because it is not pinned to the section.',
  },
  ...unitErrorCodeTypes,
  ...floorErrorCodeTypes,
} as const;

export { unitErrorCodeTypes, floorErrorCodeTypes };

export default errorCodes;
