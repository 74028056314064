import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { generateIcsData } from 'lane-shared/helpers';
import { parseDateTime } from 'lane-shared/helpers/dates';
import { IcsPersonType } from 'lane-shared/helpers/generateIcsData';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import makeFileDownload from './makeFileDownload';

export default function downloadICS({
  id,
  startDate,
  endDate,
  creationDate,
  name,
  description,
  organizer,
  attendees,
  status,
  geo,
  url,
  categories,
  location,
  timeZone,
}: {
  id?: LaneType.UUID;
  startDate: string | Date;
  endDate: string | Date;
  creationDate?: DateTime;
  name: string;
  description: string;
  organizer?: IcsPersonType;
  attendees?: IcsPersonType[];
  status?: string;
  geo?: GeoCoordinateType;
  url?: LaneType.Url;
  categories?: string[];
  location?: string;
  timeZone?: string;
}) {
  const start = parseDateTime(startDate, timeZone)!;
  const end = parseDateTime(endDate, timeZone)!;

  const icsData = generateIcsData({
    startDate: start,
    endDate: end,
    id,
    creationDate,
    name,
    description,
    organizer,
    attendees,
    status,
    geo,
    url,
    categories,
    location,
  });

  makeFileDownload({
    contents: icsData,
    type: 'text/calendar;charset=utf-8',
    name: `${name}.ics`,
  });
}
