import { doDateRangesOverlap } from 'lane-shared/helpers/dates';

import { LaneType } from 'common-types';
import { InvalidFeatureStateError } from 'activate-errors';
import { TimeAvailabilityFeatureProperties } from '../../types/features/TimeAvailabilityFeatureProperties';
import { convertToUUID } from '../convertId';
import isAvailabilityValid from './isAvailabilityValid';

export default function canUserInteractWithTimeAvailability(
  feature: TimeAvailabilityFeatureProperties,
  groupRoleIds: LaneType.UUID[] | undefined | null,
  timeZone: string,
  name: string,
  startDate: Date = new Date(),
  endDate: Date = new Date()
): boolean {
  let error: Error | null = null;

  const mappedGroupRoleIds = (groupRoleIds || [])
    .filter(Boolean)
    .map(id => convertToUUID(id));

  for (const excludedDatesUnavailability of feature.unavailableDates || []) {
    if (
      excludedDatesUnavailability.allGroupRoles &&
      doDateRangesOverlap(
        { startDate, endDate },
        excludedDatesUnavailability.dateRange
      )
    ) {
      throw new InvalidFeatureStateError(
        `“${name}” is not available at this time FS04.`
      );
    }

    const doRangesOverlap = doDateRangesOverlap(
      { startDate, endDate },
      excludedDatesUnavailability.dateRange
    );

    const isAvailableForUser =
      !excludedDatesUnavailability.allGroupRoles &&
      excludedDatesUnavailability?.groupRole?._id &&
      mappedGroupRoleIds.includes(
        convertToUUID(excludedDatesUnavailability.groupRole._id)
      );

    if (isAvailableForUser && doRangesOverlap) {
      throw new InvalidFeatureStateError(
        `“${name}” is not available at this time FS04.`
      );
    }
  }

  // looping through each rule
  for (const availability of feature.availabilities) {
    try {
      // check if availability applies to all roles or if groupRole has permission
      if (availability.allGroupRoles) {
        if (availability.isAvailableAnytime) {
          return true;
        }

        const isValidForAll = isAvailabilityValid(
          availability,
          timeZone,
          name,
          startDate,
          endDate
        );

        // It's valid for everybody, so it's valid for this user
        if (isValidForAll) {
          return true;
        }
      } else if (
        availability?.groupRole?._id &&
        mappedGroupRoleIds.includes(convertToUUID(availability.groupRole._id))
      ) {
        if (availability.isAvailableAnytime) {
          return true;
        }

        const isValidForGroupRole = isAvailabilityValid(
          availability,
          timeZone,
          name,
          startDate,
          endDate
        );

        // We found a groupRole where availability is valid, break
        if (isValidForGroupRole) {
          return true;
        }
      }
    } catch (e) {
      error = e;
    }
  }

  // If we got an error during validation, throw here
  if (error) {
    throw error;
  }

  // If we got this far, user cannot interact with time availability
  throw new InvalidFeatureStateError(
    `“${name}” is not available at this time FS05.`
  );
}
