import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import useFlag from 'lane-shared/hooks/useFlag';

import styles from 'components/features/PaymentFeatureConfirmationModal/PaymentFeatureConfirmationModal.scss';
import { Loading } from 'components/general';
import { Alert, AlertType } from 'components/lds';
import { Text } from 'components/typography';

export default function Home({
  className,
  children,
  userName,
  loading,
  essensysPaymentSettings,
  accountContactPair,
  accountContactPairs,
  setModalHistory,
  isStripePaymentAccount,
}: any) {
  const { t } = useTranslation();

  const allowExternalAccountCreation = useFlag(
    'allow-external-account-creation',
    false
  );

  if (loading) {
    return <Loading />;
  }

  const canSwitchAccountContactPairs =
    accountContactPairs && accountContactPairs?.length > 1;

  const accountContactPairSelectorProps = canSwitchAccountContactPairs
    ? {
        role: 'button',
        tabIndex: 0,
        onClick: () =>
          setModalHistory((prev: any) => [...prev, 'linkedAccounts']),
        className: cx(styles.paymentDetailsButton),
      }
    : {
        className: cx(styles.paymentDetails),
      };

  const showOrgPayeeIdError =
    essensysPaymentSettings &&
    Boolean(!essensysPaymentSettings?.orgpayeeid) &&
    !isStripePaymentAccount;
  return (
    <div className={cx(className, styles.justifyHome, styles.dynamicHeight)}>
      {accountContactPair ? (
        <div {...accountContactPairSelectorProps}>
          <div>
            <div>
              <span className={styles.clientName}>
                {accountContactPair.account.clientname}
              </span>{' '}
              <span>({accountContactPair.account.outputvalue_clienttype})</span>
            </div>
            <div>{accountContactPair.contact.emailaddress}</div>
          </div>

          {canSwitchAccountContactPairs ? <Icon name="chevron-right" /> : null}
        </div>
      ) : (
        <>
          {!allowExternalAccountCreation ? (
            <Alert fullWidth type="info">
              {t('Account creation is not currently enabled.')}
            </Alert>
          ) : null}
          <div
            role="button"
            className={cx([
              styles.confirmAccountButton,
              !allowExternalAccountCreation &&
                styles.disabledConfirmAccountButton,
            ])}
            tabIndex={0}
            onClick={() => {
              if (allowExternalAccountCreation) {
                setModalHistory((prev: any) => [
                  ...prev,
                  'confirmYourAccountInfo',
                ]);
              }
            }}
          >
            <Icon name="info" /> <span>{t('Confirm your account info')}</span>
          </div>
        </>
      )}

      <div className={styles.purchaseDetails}>
        <div className={styles.modalHeader}>
          {userName && (
            <Text size="medium">
              {t('Submitting on behalf of {{- userName}}', { userName })}
            </Text>
          )}
        </div>
        {children}
        {showOrgPayeeIdError ? (
          <Alert type={AlertType.info} fullWidth>
            {t(
              "The 'orgpayeeid' for this location has not be set. Please contact support."
            )}
          </Alert>
        ) : null}
      </div>
    </div>
  );
}
