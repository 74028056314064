import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { EVENT_CONTENT_LIVE } from 'lane-shared/helpers/constants/events';
import { ContentWorkflowEventType } from 'lane-shared/types/ContentWorkflowType';
import {
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
} from 'lane-shared/types/Workflows';
import { GuestInviteFeatureProperties } from 'lane-shared/types/features/GuestInviteFeatureProperties';

import createWorkflowTargetOptionValue from 'components/workflows/helpers/createWorkflowTargetOptionValue';

import { targetLabels, targetLabelsV2 } from './workflowLabels';
import { WorkflowTypeEnum } from 'lane-shared/types/workflow';

type OptionType = {
  value: {
    target: WorkflowTargetEnum;
    [key: string]: any;
  };
  label: string;
};

type Props = {
  whenContext?: WorkflowWhenContextEnum;
  guestInviteFeature?: GuestInviteFeatureProperties;
  event?: ContentWorkflowEventType;
  workflowType?: WorkflowTypeEnum;
  selectUserTypeList?: { name: string; _id: string }[];
  isNewWorkflowsUIEnabled?: boolean;
};

export default function useWorkflowTargetOptions({
  whenContext,
  guestInviteFeature,
  event,
  workflowType,
  selectUserTypeList,
  isNewWorkflowsUIEnabled,
}: Props) {
  const { t } = useTranslation();
  // since we use both dataFieldId and target for selected option identification in SelectUser targets
  // and we had to store option value as an object there therefore it was decided to make all targets work
  // this way to ensure consistency and so we may support similar cases for other target in the future
  return useMemo(() => {
    let options: OptionType[] = [];
    if (event && event === EVENT_CONTENT_LIVE) {
      options.push(
        {
          value: createWorkflowTargetOptionValue(WorkflowTargetEnum.GroupRole),
          label: isNewWorkflowsUIEnabled
            ? t(targetLabelsV2[WorkflowTargetEnum.GroupRole])
            : t(targetLabels[WorkflowTargetEnum.GroupRole]),
        },
        {
          value: createWorkflowTargetOptionValue(WorkflowTargetEnum.User),
          label: t(targetLabels[WorkflowTargetEnum.User]),
        }
      );
    } else {
      options.push(
        {
          value: createWorkflowTargetOptionValue(WorkflowTargetEnum.GroupRole),
          label: isNewWorkflowsUIEnabled
            ? t(targetLabelsV2[WorkflowTargetEnum.GroupRole])
            : t(targetLabels[WorkflowTargetEnum.GroupRole]),
        },
        {
          value: createWorkflowTargetOptionValue(WorkflowTargetEnum.User),
          label: isNewWorkflowsUIEnabled
            ? t(targetLabelsV2[WorkflowTargetEnum.User])
            : t(targetLabels[WorkflowTargetEnum.User]),
        },
        {
          value: createWorkflowTargetOptionValue(
            WorkflowTargetEnum.InteractionCreator
          ),
          label: isNewWorkflowsUIEnabled
            ? t(targetLabelsV2[WorkflowTargetEnum.InteractionCreator])
            : t(targetLabels[WorkflowTargetEnum.InteractionCreator]),
        }
      );

      if (guestInviteFeature) {
        options.push({
          value: createWorkflowTargetOptionValue(
            WorkflowTargetEnum.GuestInviteFeature
          ),
          label: isNewWorkflowsUIEnabled
            ? t(targetLabelsV2[WorkflowTargetEnum.GuestInviteFeature])
            : t(targetLabels[WorkflowTargetEnum.GuestInviteFeature]),
        });
      }
      if (selectUserTypeList?.length) {
        selectUserTypeList.map(item => {
          options.push({
            value: createWorkflowTargetOptionValue(
              WorkflowTargetEnum.SelectUser,
              {
                dataField: {
                  _id: item._id,
                },
              }
            ),
            label: item.name,
          });
        });
      }
    }
    if (
      workflowType === WorkflowTypeEnum.ServiceRequest ||
      workflowType === WorkflowTypeEnum.Task ||
      workflowType === WorkflowTypeEnum.ServiceRequestEscalation
    ) {
      options = [
        {
          value: { target: WorkflowTargetEnum.WorkOrderAdmin },
          label: targetLabelsV2[WorkflowTargetEnum.WorkOrderAdmin],
        },
        {
          value: { target: WorkflowTargetEnum.WorkOrderTeam },
          label: targetLabelsV2[WorkflowTargetEnum.WorkOrderTeam],
        },
        {
          value: { target: WorkflowTargetEnum.WorkOrderCreator },
          label: targetLabelsV2[WorkflowTargetEnum.WorkOrderCreator],
        },
      ];
      if (
        workflowType === WorkflowTypeEnum.Task ||
        workflowType === WorkflowTypeEnum.ServiceRequestEscalation
      ) {
        options.pop();
      }
    }
    return options;
  }, [whenContext, event]);
}
