import React from 'react';
import { Button, M, H5, S } from 'design-system-web';
import {
  currencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from 'lane-shared/config/routes';

interface Props {
  contentName: string;
  quote: PaymentFeatureQuoteType;
}

const DAY_MONTH_YEAR_FORMAT = 'd LLLL, yyyy';

export function SubscriptionReceiptContentInfo({ contentName, quote }: Props) {
  const { t } = useTranslation();
  const { subscriptionDetails } = quote;
  const formattedTotal = currencyFormatter({
    currency: quote.currency,
  })(Number(quote.cashTotal));

  const formattedEndDate = dateFormatter(
    subscriptionDetails?.endDate || '',
    DAY_MONTH_YEAR_FORMAT
  );

  const history = useHistory();
  const navigateToUserSubscriptions = () => {
    history.push(routes.meRecurringCharges);
  };

  return (
    <div className="flex flex-row justify-between gap-x-3 border rounded-lg border-border-subdued py-2 px-3 w-full">
      <div className="flex flex-col">
        <H5>{contentName}</H5>
        <div>
          {/* Adding the space manually here because the total and interval have to be styled differently. 
          Possible to do with the translation logic but is signifficantly more complicated than just adding the space */}
          <M>{formattedTotal}</M>{' '}
          <M className="text-text-subdued">
            {t(
              `abp.payment.orderSummary.recurrenceInterval.${subscriptionDetails?.recurrenceInterval}`
            )}
          </M>
        </div>
        <S className="text-text-subdued">
          {t('abp.receipt.subscription.content.ends', {
            endDate: formattedEndDate,
          })}
        </S>
      </div>
      <Button
        className="h-min self-center"
        variant="text"
        onClick={() => navigateToUserSubscriptions()}
      >
        {t('abp.receipt.subscription.manage')}
      </Button>
    </div>
  );
}
