import { FeatureQuoteType } from '../types/payment/FeatureQuoteType';
import { PaymentTypeEnum } from '../types/payment/PaymentTypeEnum';
import { CURRENCY_CONVERSION } from './constants';
import { currencyFormatter } from './formatters';

function formatEssensysCredits(quote: FeatureQuoteType): string {
  return `${quote.total / CURRENCY_CONVERSION} credit(s)`;
}

function formatCurrency(quote: FeatureQuoteType, locale: string): string {
  const numberFormat = currencyFormatter({
    locale,
    currency: quote.currency,
  });

  if (quote?.products && quote?.products.length > 0) {
    return numberFormat(quote.cashTotal ?? 0);
  }

  return numberFormat((quote.total + quote.tax) / CURRENCY_CONVERSION);
}

export default function getPaymentTotalWithQuote(
  quote: FeatureQuoteType | undefined,
  locale: string = 'en-US'
): string {
  if (!quote) return '';
  if (quote.type === PaymentTypeEnum.EssensysCredits) {
    return formatEssensysCredits(quote);
  }

  return formatCurrency(quote, locale);
}
