import React, { useState, useMemo } from 'react';

import cx from 'classnames';
import { DateTime, Interval } from 'luxon';

import { VERY_SHORT_DAY } from 'lane-shared/helpers/constants/dates';
import { parseDateTime } from 'lane-shared/helpers/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { M } from '../../../index';

import { DatePickerRow } from '../DatePickerRow';
import { DateCell } from './DateCell';

import styles from './Calendar.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  // function to be called when element is clicked, returns selected day
  onChange: (date: Date) => void;
  // callback when user changes months
  onFocusChange?: (date: Date) => void;
  // JS date object for start date
  startDate?: string | Date | null;
  // JS date object for end date
  endDate?: string | Date | null;
  // JS date object for max date
  maxDate?: Date;
  // JS date object for min date
  minDate?: Date;
  // an existing value
  existingValue?: DateRangeType;
  // date format for the day
  dateFormat?: string;
  // the timezone to display dates in
  timeZone?: string;
  // limits the range available to be selected, in days
  rangeLimit?: number;
  singleDate?: boolean;
  // unavailable ranges
  unavailableDateRanges?: DateRangeType[];
  dateRangePicker?: boolean;
  weekdayOnly?: boolean;
  disabledWeekDays?: number[];
  disablePastDays?: boolean;
};

export const Calendar = ({
  className,
  style,
  timeZone,
  startDate,
  endDate,
  existingValue,
  rangeLimit,
  onChange,
  onFocusChange = () => {},
  maxDate = new Date(2069, 0, 1),
  minDate = new Date(2014, 0, 1),
  singleDate,
  dateFormat = 'd',
  unavailableDateRanges = [],
  dateRangePicker,
  weekdayOnly = false,
  disabledWeekDays = [],
  disablePastDays = false,
  disabled,
}: Props) => {
  const [currentMonth, setCurrentMonth] = useState(
    DateTime.fromJSDate(new Date(), { zone: timeZone }).toISO()
  );
  const [dateUpdated, setDateUpdated] = useState(false);

  const month = dateUpdated || !startDate ? currentMonth : startDate;
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const monthStart = parseDateTime(month, timeZone).startOf('month');
  const monthEnd = monthStart.endOf('month');
  const startWeek =
    monthStart.weekday === 7
      ? monthStart
      : monthStart.startOf('week').minus({ day: 1 });

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const nextMonth = parseDateTime(month, timeZone).plus({ month: 1 }).toISO();
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const nextMonthStart = parseDateTime(nextMonth, timeZone).startOf('month');
  const nextMonthEnd = nextMonthStart.endOf('month');
  const nextMonthStartWeek =
    nextMonthStart.weekday === 7
      ? nextMonthStart
      : nextMonthStart.startOf('week').minus({ day: 1 });

  const _unavailableDateRanges = useMemo(
    () =>
      (unavailableDateRanges || []).map(range =>
        Interval.fromDateTimes(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
          parseDateTime(range.startDate, timeZone),
          parseDateTime(range.endDate, timeZone)
        )
      ),
    [unavailableDateRanges, timeZone]
  );

  function enforceDate(newDate: any, changeMonth?: boolean) {
    const _newDate = parseDateTime(newDate, timeZone) as DateTime;

    if (
      // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
      (maxDate && _newDate.startOf('day') > maxDate) ||
      // @ts-expect-error ts-migrate(2365) FIXME: Operator '<' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
      (minDate && _newDate.endOf('day') < minDate)
    ) {
      return false;
    }

    setDateUpdated(true);
    if (nextMonthStart <= _newDate && !singleDate && !changeMonth) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | Date' is not assignable... Remove this comment to see the full error message
      setCurrentMonth(month);
    } else {
      setCurrentMonth(_newDate.toISO());
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    onFocusChange(_newDate.toISO());
    return true;
  }

  function enforceRangeLimit(newDate: any) {
    if (!rangeLimit) {
      return true;
    }

    const interval = Interval.fromDateTimes(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
      parseDateTime(newDate, timeZone),
      parseDateTime(startDate, timeZone)
    );

    return Math.abs(interval.length('days')) < rangeLimit;
  }

  const dayNames = useMemo(() => {
    const days: React.ReactNode[] = [];

    for (let i = 0; i < 7; i += 1) {
      days.push(
        <div key={i} className={styles.date}>
          <M>
            {dateFormatter(
              startWeek.plus({ day: i }),
              VERY_SHORT_DAY,
              timeZone
            )}
          </M>
        </div>
      );
    }

    return <div className={styles.dateNames}>{days}</div>;
  }, [startWeek?.toMillis(), timeZone]);

  function renderDayCells(monthStart: any, monthEnd: any, startWeek: any) {
    const rows: any = [];

    let days: React.ReactNode[] = [];
    let day: DateTime = startWeek;

    while (day <= monthEnd) {
      for (let i = 0; i < 7; i += 1) {
        const dayInThePast =
          day <
          DateTime.fromJSDate(new Date(), { zone: timeZone }).startOf('day');
        const formattedDate = dateFormatter(day, dateFormat, timeZone);
        // is this unavailable?
        const unavailable =
          _unavailableDateRanges.some(range => range.contains(day)) ||
          (weekdayOnly && [6, 7].includes(day.weekday));
        const disabled =
          (disablePastDays && dayInThePast) ||
          disabledWeekDays.includes(i) ||
          // @ts-expect-error ts-migrate(2365) FIXME: Operator '<' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
          (minDate && day.endOf('day') < minDate) ||
          // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
          (maxDate && day.startOf('day') > maxDate);

        const existing =
          existingValue &&
          Interval.fromDateTimes(
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
            parseDateTime(existingValue.startDate, timeZone),
            parseDateTime(existingValue.endDate, timeZone)
          ).contains(day);

        days.push(
          <DateCell
            key={day.toMillis()}
            timeZone={timeZone}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
            startDate={startDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
            endDate={endDate}
            day={day.toJSDate()}
            monthStart={monthStart}
            monthEnd={monthEnd}
            onClick={(date: any) => {
              if (enforceDate(date) && enforceRangeLimit(date)) {
                onChange(date);
              }
            }}
            existing={existing}
            unavailable={unavailable}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Interval[]' is not assignable to type 'never... Remove this comment to see the full error message
            unavailableDateRanges={_unavailableDateRanges}
            disabled={disabled}
            text={(monthStart <= day && day <= monthEnd && formattedDate) || ''}
          />
        );

        day = day.plus({ day: 1 });
      }

      rows.push(
        <div className={styles.cells} key={day.toMillis()}>
          {days}
        </div>
      );

      days = [];
    }

    return rows;
  }

  return (
    <div
      data-cy="datePicker"
      className={cx(styles.calendar, className, disabled && styles.disabled)}
      style={style}
      data-single-date={singleDate || !dateRangePicker}
    >
      <div className={styles.wrapper}>
        <div className={styles.calendarWrapper}>
          <DatePickerRow
            value={typeof month === 'string' ? new Date(month) : month}
            timeZone={timeZone}
            maxDate={maxDate}
            minDate={minDate}
            quickTimeUnit="month"
            showDays={false}
            onChange={(v: any) => enforceDate(v, true)}
          />
          {dayNames}
          {renderDayCells(monthStart, monthEnd, startWeek)}
        </div>
        {dateRangePicker && (
          <div className={styles.calendarWrapper}>
            <DatePickerRow
              value={new Date(nextMonth)}
              timeZone={timeZone}
              maxDate={maxDate}
              minDate={minDate}
              quickTimeUnit="month"
              showDays={false}
              onChange={(v: any) => enforceDate(v, true)}
              dateRangePicker={dateRangePicker}
            />
            {dayNames}
            {renderDayCells(nextMonthStart, nextMonthEnd, nextMonthStartWeek)}
          </div>
        )}
      </div>
    </div>
  );
};
