import React from 'react';

import IconButton from 'components/general/IconButton';

export type Props = {
  value: string;
};

export default function CopyButton({ value }: Props) {
  return (
    <IconButton
      icon="clipboard"
      iconSet="FontAwesome"
      onClick={e => {
        e.stopPropagation();
        navigator.clipboard.writeText(value);
      }}
    />
  );
}
