export const colors = {
  shopifyGreen: '#002A20',
  burstingGreenLight: 'rgba(144, 230, 181, 0.1)',
  shopifySecondary: '#D5D9001A',
  shopifySecondaryNonTransparent: '#FBFBE6',
  shopifyPink: 'rgba(255, 108, 92, 0.1)',
  shopifyPinkSolid: '#CB434329',
  shopifyPinkSolidNonTransparent: '#FAECEC',
  burstingPinkLight: 'rgba(255, 108, 92, 0.1)',
};
