import { PackagedTypeEnum } from '../../types/properties/PackagedTypeEnum';

export type FriendlyType =
  | PackagedTypeEnum
  | 'String'
  | 'LongText'
  | 'Number'
  | 'Email'
  | 'PhoneNumber'
  | 'Url'
  | 'Color'
  | 'Boolean'
  | 'Address'
  | 'Currency'
  | 'Date'
  | 'Time'
  | 'DateTime'
  | 'DateRange'
  | 'DateTimeRange'
  | 'DatesTimeRange'
  | 'TimeRange'
  | 'Image'
  | 'Audio'
  | 'Video'
  | 'Document'
  | 'Location'
  | 'Percentage'
  | 'SelectUser'
  | 'Attachment';

export type FriendlyTypeOption = {
  value: FriendlyType;
  label: string;
};

export const friendlyTypeOptions: FriendlyTypeOption[] = [
  {
    value: 'String',
    label: 'Text',
  },
  {
    value: 'LongText',
    label: 'Long text',
  },
  {
    value: 'Number',
    label: 'Number',
  },
  {
    value: PackagedTypeEnum.Radios,
    label: 'Choose one from a list (Radio buttons)',
  },
  {
    value: PackagedTypeEnum.Checkboxes,
    label: 'Choose many from a list (Checkboxes)',
  },
  {
    value: PackagedTypeEnum.Dropdown,
    label: 'Choose one from a dropdown',
  },
  {
    value: PackagedTypeEnum.Slider,
    label: 'Choose a number from a slider',
  },
  {
    value: PackagedTypeEnum.Quantity,
    label: 'Choose a number using buttons',
  },
  {
    value: 'Email',
    label: 'Email address',
  },
  {
    value: 'PhoneNumber',
    label: 'Phone number',
  },
  {
    value: 'Url',
    label: 'Website',
  },
  {
    value: 'Color',
    label: 'Select a color',
  },
  {
    value: 'Boolean',
    label: 'Yes / No',
  },
  {
    value: 'Address',
    label: 'Enter an address',
  },
  {
    value: 'Currency',
    label: 'Currency amount',
  },
  {
    value: 'Date',
    label: 'Select a date',
  },
  {
    value: 'Time',
    label: 'Select a time of day',
  },
  {
    value: 'DateTime',
    label: 'Select a date and time',
  },
  {
    value: 'DateRange',
    label: 'Select a date range',
  },
  {
    value: 'DateTimeRange',
    label: 'Select a date with time range',
  },
  {
    value: 'DatesTimeRange',
    label: 'Select a date range with times',
  },
  {
    value: 'TimeRange',
    label: 'Select a time range',
  },
  {
    value: 'Image',
    label: 'Upload an image',
  },
  {
    value: 'Audio',
    label: 'Upload some audio',
  },
  {
    value: 'Video',
    label: 'Upload a video',
  },
  {
    value: 'Document',
    label: 'Upload a document',
  },
  {
    value: 'Location',
    label: 'Share their location',
  },
  {
    value: 'Percentage',
    label: 'Percentage',
  },
  {
    value: 'SelectUser',
    label: 'Select User(s)',
  },
  {
    value: 'Attachment',
    label: 'web.admin.content.friendlyProperty.labels.attachments',
  },
];
