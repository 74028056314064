import { Primitive } from '../../../../types/blocks/Primitive';

const MAX_INPUT = 99;

/**
 * @deprecated use PropertyInputBlock instead
 */
const QuantityInputBlock: Primitive = {
  name: 'QuantityInputBlock',
  isInput: true,
  isPrimitive: true,
  properties: {
    value: {
      type: 'Number',
      default: 0,
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
    min: {
      type: 'Number',
      validators: [
        {
          name: 'Min',
          value: -MAX_INPUT,
        },
        {
          name: 'Max',
          value: MAX_INPUT,
        },
      ],
    },
    max: {
      type: 'Number',
      validators: [
        {
          name: 'Min',
          value: -MAX_INPUT,
        },
        {
          name: 'Max',
          value: MAX_INPUT,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    input: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default QuantityInputBlock;
