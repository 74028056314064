import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import TextAreaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { Key } from 'ts-key-enum';

import LengthIndicator from 'design-system-web/components/LengthIndicator/LengthIndicator';

import styles from './TextArea.scss';

type Props = {
  className?: string;
  containerClassName?: string;
  style?: TextareaAutosizeProps['style'];
  errors?: any;
  success?: any;
  showClear?: boolean;
  onChange: (text: string) => void;
  onBlur?: (value: any) => void;
  onClear?: () => void;
  value: string;
  maxLength?: number;
  showLengthIndicator?: boolean;
  label?: string;
  placeholder?: string;
  dataCy?: string;
  disabled?: boolean;
  minRows?: number;
  helperText?: string;
};

export const TextArea = ({
  className,
  containerClassName,
  style,
  errors,
  success,
  showClear = true,
  onChange,
  onClear,
  onBlur,
  value,
  maxLength,
  showLengthIndicator,
  label,
  placeholder,
  dataCy,
  disabled,
  helperText,
  ...otherProps
}: Props) => {
  function onChangeHandler(text: any) {
    if (
      typeof maxLength === 'number' &&
      maxLength > 0 &&
      text?.length > maxLength
    ) {
      text = text.substring(0, maxLength);
    }

    onChange(text);
  }

  function clearHandler() {
    onChange('');

    if (onClear) {
      onClear();
    }
  }

  const isTouched = String(value).length > 0;
  const error = Array.isArray(errors) ? errors[0] : errors;
  const displayClearButton = showClear && isTouched && !error;

  return (
    <div
      data-cy="textArea"
      className={cx(styles.container, containerClassName)}
    >
      <div className={styles.textAreaWrapper}>
        <TextAreaAutosize
          data-touched={value?.length > 0}
          data-has-placeholder={Boolean(placeholder)}
          value={value || ''}
          style={style}
          className={cx(
            styles.TextArea,
            {
              [styles.error]: errors,
              [styles.success]: success,
              [styles.disabled]: disabled,
            },
            className
          )}
          onChange={e => onChangeHandler(e.target.value)}
          onBlur={onBlur}
          placeholder={placeholder}
          maxLength={maxLength}
          data-cy={dataCy}
          {...otherProps}
        />
        {displayClearButton && (
          <div
            className={cx(
              styles.iconWrapper,
              styles.rightIconWrapper,
              styles.interactive
            )}
            role="button"
            tabIndex={0}
            onKeyDown={e => e.key === Key.Enter && clearHandler()}
            onClick={clearHandler}
          >
            <Icon className={styles.icon} name="times" />
          </div>
        )}
      </div>

      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.bottomBox}>
        {error ? (
          <p className={styles.errorMessage} data-cy="errorMessage">
            {error}
          </p>
        ) : (
          helperText && <span className={styles.helperText}>{helperText}</span>
        )}

        <LengthIndicator
          showLengthIndicator={showLengthIndicator}
          value={value}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};
