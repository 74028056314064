import {
  SafetrustErrorCodeEnum,
  SafetrustUserErrorMessagesEnum,
} from './SafetrustEnums';

/**
 * Get's a user friendly error message to display to the end user using
 * a SafetrustErrorCodeEnum
 */
export default function mapSafetrustErrorMessage(
  code: SafetrustErrorCodeEnum
): string {
  switch (code) {
    // general SDK errors, not much we can tell the user to do.
    case SafetrustErrorCodeEnum.CREDENTIAL_SYSTEM_INVALID:
    case SafetrustErrorCodeEnum.CONTENT_SIGNED_INVALID:
    case SafetrustErrorCodeEnum.CREDENTIAL_CERTIFICATES_NOT_EXIST:
    case SafetrustErrorCodeEnum.CREDENTIAL_CERTIFICATES_INVALID:
    case SafetrustErrorCodeEnum.CREDENTIAL_REQUEST_DATA_INVALID:
    case SafetrustErrorCodeEnum.SWDK_CERTIFICATE_INVALID:
    case SafetrustErrorCodeEnum.ROOT_DATA_INVALID:
    case SafetrustErrorCodeEnum.CREDENTIAL_NOT_EXISTED:
    case SafetrustErrorCodeEnum.CONFIG_SERVER_API_ERROR:
    case SafetrustErrorCodeEnum.API_VERSION_INVALID:
    case SafetrustErrorCodeEnum.CREDENTIAL_CERTIFICATE_NOT_EFFECTIVE:
    case SafetrustErrorCodeEnum.SDK_UUID_NOT_EXISTED:
    case SafetrustErrorCodeEnum.PARAMS_INVALID:
    case SafetrustErrorCodeEnum.CAN_NOT_GET_VERSION_INFO:
    case SafetrustErrorCodeEnum.CANNOT_RETRIEVE_API_VERSION:
      return SafetrustUserErrorMessagesEnum.SdkError;
    // these should be handled by our code.
    case SafetrustErrorCodeEnum.UNHANDLE:
    case SafetrustErrorCodeEnum.FAIL:
    case SafetrustErrorCodeEnum.CONNECT:
    case SafetrustErrorCodeEnum.DISCONNECT:
      return SafetrustUserErrorMessagesEnum.UnknownError;
    case SafetrustErrorCodeEnum.ENABLED_BUT_NEED_ALWAYS_LOCATION_PERMISSION:
      return SafetrustUserErrorMessagesEnum.LocationServicesRequired;
    case SafetrustErrorCodeEnum.ACCOUNT_BLOCKED:
      return SafetrustUserErrorMessagesEnum.AccountBlocked;
    case SafetrustErrorCodeEnum.TIMEOUT:
      return SafetrustUserErrorMessagesEnum.Timeout;
    case SafetrustErrorCodeEnum.ACTIVATE_CODE_INVALID:
    case SafetrustErrorCodeEnum.WRONG_OTP:
    case SafetrustErrorCodeEnum.ACTIVATION_LINK_INVALID:
      return SafetrustUserErrorMessagesEnum.PinInvalid;
    case SafetrustErrorCodeEnum.WRONG_PASSCODE_MANY_TIMES:
      return SafetrustUserErrorMessagesEnum.PasswordCoolDown;
    case SafetrustErrorCodeEnum.NEED_TO_ACCEPT_LICENSE_AGREEMENT:
      return SafetrustUserErrorMessagesEnum.LicenseAgreementRequired;
    case SafetrustErrorCodeEnum.PASSCODE_INVALID:
      return SafetrustUserErrorMessagesEnum.PasswordInvalid;
    case SafetrustErrorCodeEnum.SUCCESS:
      return SafetrustUserErrorMessagesEnum.Success;
    case SafetrustErrorCodeEnum.LOGIN_NOT_SETUP:
      return SafetrustUserErrorMessagesEnum.LoginNotSetup;
    case SafetrustErrorCodeEnum.INVITATION_NOT_ACCEPTED:
      return SafetrustUserErrorMessagesEnum.InvitationNotAccepted;
    case SafetrustErrorCodeEnum.REQUIRE_SET_PASSCODE:
      return SafetrustUserErrorMessagesEnum.PasscodeRequired;
    case SafetrustErrorCodeEnum.DEVICE_NOT_FOUND:
    case SafetrustErrorCodeEnum.DEVICE_INFO_NOT_FOUND:
      return SafetrustUserErrorMessagesEnum.DeviceNotFound;
    case SafetrustErrorCodeEnum.USERS_NOT_EXISTED:
    case SafetrustErrorCodeEnum.ACCOUNT_NOT_FOUND:
      return SafetrustUserErrorMessagesEnum.AccountNotFound;
    case SafetrustErrorCodeEnum.DEVICE_LIMIT_REACHED:
      return SafetrustUserErrorMessagesEnum.DeviceLimitReached;
    // the following are error states that should never be shown to an end
    // user.  so we probably don't need error messaging for them?
    case SafetrustErrorCodeEnum.ADDRESS_INVALID:
    case SafetrustErrorCodeEnum.ACCOUNT_EXISTED:
    case SafetrustErrorCodeEnum.REQUIRE_APP_START_FINISHED_BEFORE_DO_BUSINESS:
    case SafetrustErrorCodeEnum.ACCOUNT_SESSION_NOT_FOUND:
    case SafetrustErrorCodeEnum.COMPANY_LIST_SESSION_NOT_FOUND:
    case SafetrustErrorCodeEnum.CAN_NOT_LOGIN_AFTER_ACTIVE_DEVICE:
    case SafetrustErrorCodeEnum.OLD_SECRET_INVALID:
    case SafetrustErrorCodeEnum.NEW_SECRET_INVALID:
    case SafetrustErrorCodeEnum.SCANNING_NOT_START:
    case SafetrustErrorCodeEnum.AUTHENTICATE_MANUAL_PROCESSING:
    case SafetrustErrorCodeEnum.CREDENTIAL_EXISTED:
    case SafetrustErrorCodeEnum.CAN_NOT_REQUEST_CREDENTIAL_IN_ONLINE_MODE:
    case SafetrustErrorCodeEnum.LOGIN_NOT_FOUND:
    case SafetrustErrorCodeEnum.LOGIN_IS_NOT_PHONE_NUMBER:
    case SafetrustErrorCodeEnum.ACCOUNT_NOT_ACTIVE:
    default:
      return SafetrustUserErrorMessagesEnum.UnknownError;
  }
}
