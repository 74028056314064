import React, { useRef } from 'react';

import cx from 'classnames';
import { DayProps, useDayRender, Button } from 'react-day-picker';

import styles from '../DatePicker.scss';

export default function CalendarDay({ date, displayMonth }: DayProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);

  const isFirstDayOfMonth = date.getDate() === 1;
  const isLastDayOfMonth =
    new Date(date.getTime() + 24 * 60 * 60 * 1000).getDate() === 1;

  if (dayRender.isHidden) {
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    return <div role="gridcell" />;
  }

  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  return (
    <Button
      name="day"
      ref={buttonRef}
      {...dayRender.buttonProps}
      className={cx(
        isFirstDayOfMonth && styles.firstDay,
        isLastDayOfMonth && styles.lastDay,
        dayRender.buttonProps.className
      )}
    />
  );
}
