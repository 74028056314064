import createShapeFromProperties from '../../../properties/createShapeFromProperties';
import definition from './definition';
import * as handleToken from './handleToken';
import * as requestHelpers from './requestHelpers';
import useWorkOrder from './useWorkOrder';

export { definition, handleToken, useWorkOrder, requestHelpers };

export const validationShape = createShapeFromProperties(
  definition.interactionData
);
