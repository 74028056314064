import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';

import Types from '../../../../properties/Types';
import UUID from '../../../../properties/baseTypes/UUID';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { PropertiesInterface } from '../../../../types/properties/Property';

export enum ShopifyBurstTypeEnum {
  Align = 'Align',
  Build = 'Build',
  Evolve = 'Evolve',
  Other = 'Other',
}

export enum ShopifyBurstTeamTypeEnum {
  OrgStructure = 'My reporting structure team (we all report to the same lead)',
  ProjectTeam = 'Another team (a project team or a team I collaborate with)',
  MixTeam = 'A mix: my reporting structure team plus additional members from other teams',
}

export const burstTypeOptions = [
  {
    id: ShopifyBurstTypeEnum.Align,
    text: ShopifyBurstTypeEnum.Align,
    description:
      'Fiercely debate a gnarly problem, new builds, new work cycles, and/or make critical decisions.',
  },
  {
    id: ShopifyBurstTypeEnum.Build,
    text: ShopifyBurstTypeEnum.Build,
    description:
      'Rapidly move through the design cycle and have something to show by the end of your burst.',
  },
  {
    id: ShopifyBurstTypeEnum.Evolve,
    text: ShopifyBurstTypeEnum.Evolve,
    description:
      'Not for the faint of heart. Achieve a year’s worth of learning in a week together.',
  },
  {
    id: ShopifyBurstTypeEnum.Other,
    text: ShopifyBurstTypeEnum.Other,
    description:
      'What you’re planning doesn’t fit neatly into any of the previous three categories.',
  },
];

export const burstTeamTypeOptions = Object.entries(
  ShopifyBurstTeamTypeEnum
).map(([id, text]) => ({ id, text }));

export const burstTextPrompts = {
  justification:
    'What’s the purpose of your gathering? How will meeting IRL help you achieve it?',
  burstTypeOther: 'Briefly describe the structure of your burst',
  burstTeamType: 'The team I am bursting with is...',
};

export const properties: PropertiesInterface = {
  _id: {
    type: 'String',
  },
  justification: {
    type: 'LongText',
    friendlyName: 'Justification',
    validators: [
      {
        name: 'Max',
        value: 2048,
      },
      {
        name: 'Min',
        value: 3,
      },
    ],
  },
  burstTeamType: {
    type: 'String',
    friendlyName: 'Burst Team Type',
    validators: [
      {
        name: 'In',
        value: Object.values(ShopifyBurstTeamTypeEnum),
      },
    ],
  },
};

export class ShopifyBurstQuestionnaireType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ShopifyBurstQuestionnaireType';

  friendlyName = 'Shopify Burst Questionnaire';

  superUserOnly = false;

  contexts = [];

  get example(): any {
    return {
      _id: UUID.example,
      justification: 'Justification',
      burstTeamType: ShopifyBurstTeamTypeEnum.ProjectTeam,
    };
  }

  get contentExample(): any {
    return {
      _id: UUID.example,
      justification: undefined,
      burstTeamType: undefined,
    };
  }

  get default(): any {
    return {
      _id: UUID.example,
      burstTypes: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new ShopifyBurstQuestionnaireType();

Types.addType(definition);
export default definition;
