import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useM6Approvers } from 'lane-shared/hooks/integrations/shopify/useM6Approvers';
import useShopifyGuests from 'lane-shared/hooks/integrations/shopify/useShopifyGuests';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';
import { ShopifyBurstTabsEnum } from 'lane-shared/types/integrations/ShopifyBurst';

import Input from 'components/form/Input';
import Button from 'components/general/Button';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import CircleListView from 'components/lane/CircleListView';
import { Flex } from 'components/layout';
import { Modal } from 'components/lds';
import Alert, { AlertType } from 'components/lds/Alert';
import { H5, XS } from 'components/typography';

import styles from './AddShopifolkModal.scss';

function generateTabs(selectedAmount: number): TabItem[] {
  return [
    {
      value: ShopifyBurstTabsEnum.Shopify,
      label: 'Shopifolk',
    },

    {
      value: ShopifyBurstTabsEnum.Selected,
      label: `Selected (${selectedAmount})`,
    },
  ];
}

type Props = {
  modalTitle: string;
  actionButtonTitle: string;
  updateGuestList: (guests: PublicUserType[]) => void;
  attendees: PublicUserType[];
  maxCapacity: number;
  required?: boolean;
  onClose: () => void;
  maxCapacityAlertTitle?: string;
  maxCapacityToastTitle?: string;
  forM6?: boolean;
};

/**
 * Modal for adding guests to a Shopify Burst event
 */
export default function AddShopifolkModal({
  onClose,
  updateGuestList,
  attendees,
  maxCapacity,
  required,
  modalTitle,
  actionButtonTitle,
  maxCapacityAlertTitle,
  maxCapacityToastTitle,
  forM6 = false,
}: Props) {
  const isSingle = maxCapacity === 1;

  const { t } = useTranslation();
  const [guests, setGuests] = useState(attendees);
  const [selectedTab, setSelectedTab] = useState<ShopifyBurstTabsEnum>(
    ShopifyBurstTabsEnum.Shopify
  );

  const attendeesLimitReached = guests.length >= maxCapacity;

  const guestHash = guests.reduce<{ [id: string]: boolean }>((hash, guest) => {
    hash[guest._id] = true;
    return hash;
  }, {});

  const shopifyUsers = useShopifyGuests({ maxCapacity });

  const m6Users = useM6Approvers({
    maxCapacity,
  });

  const { users, search, updateSearch } = forM6 ? m6Users : shopifyUsers;

  useEffect(() => {
    if (guests.length === 0) {
      setSelectedTab(ShopifyBurstTabsEnum.Shopify);
    }
  }, [guests]);

  function toggleUser(user: PublicUserType) {
    if (isSingle) {
      setGuests([user]);
      return;
    }
    if (guests.some(g => g._id === user._id)) {
      removeGuest(user._id);
    } else {
      selectUser(user);
    }
  }

  function selectUser(user: PublicUserType) {
    if (maxCapacityToastTitle && attendeesLimitReached) {
      window.Toast.show(t(maxCapacityToastTitle, { maxCapacity }));
      return;
    }

    setGuests([...guests, user]);
  }

  function removeGuest(guestId: LaneType.UUID) {
    setGuests(guests.filter(g => g._id !== guestId));
  }

  return (
    <Modal
      title={modalTitle}
      isOpen
      onClose={onClose}
      className={styles.modal}
      actions={
        <Button
          onClick={() => updateGuestList(guests)}
          variant="contained"
          disabled={required && guests.length === 0}
        >
          {actionButtonTitle}
        </Button>
      }
    >
      {!isSingle && (
        <TabStrip
          tabs={generateTabs(guests.length)}
          selected={{ value: selectedTab }}
          onSelectTab={tab => setSelectedTab(tab.value as ShopifyBurstTabsEnum)}
          className={styles.tabStrip}
        />
      )}
      <Flex direction="column" className={styles.userList}>
        {selectedTab === ShopifyBurstTabsEnum.Shopify && (
          <>
            <Input
              value={search}
              onChange={updateSearch}
              placeholder={t('First name, Last name or email')}
              icon="search"
              className={styles.searchInput}
              showClear
            />
            <Flex
              p={1}
              direction="column"
              gap={2}
              className={styles.shopifolkList}
            >
              {maxCapacityAlertTitle && attendeesLimitReached && (
                <Alert type={AlertType.warning} fullWidth>
                  {t(maxCapacityAlertTitle)}
                </Alert>
              )}
              {users.map(user => (
                <CircleListView
                  key={user._id}
                  className={styles.circleListView}
                  image={imageUrl(user.profile.image)}
                  logo={imageUrl(user.profile.logo)}
                  name={getDisplayName(user)}
                  onClick={() => toggleUser(user)}
                  selected={isSingle ? guests[0]?._id : guestHash[user._id]}
                  value={user._id}
                  selectType={isSingle ? 'radio' : 'checkbox'}
                  isSelectable
                >
                  <H5 bold>{getDisplayName(user)}</H5>
                  <XS variant="secondary">{user.profile.email}</XS>
                </CircleListView>
              ))}
            </Flex>
          </>
        )}
        {selectedTab === ShopifyBurstTabsEnum.Selected && (
          <Flex
            direction="column"
            gap={2}
            p={1}
            className={styles.shopifolkList}
          >
            {guests.map(guest => (
              <CircleListView
                key={guest._id}
                className={styles.circleListView}
                image={imageUrl(guest.profile.image)}
                logo={imageUrl(guest.profile.logo)}
                name={getDisplayName(guest)}
                onClick={() => removeGuest(guest._id)}
                selected={Boolean(guest._id)}
                isSelectable
              >
                <H5 bold>{getDisplayName(guest)}</H5>
                <XS variant="secondary">{guest.profile.email}</XS>
              </CircleListView>
            ))}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
}
