import { v4 as uuid } from 'uuid';

import {
  MenuFeatureItemType,
  MenuFeatureOrderItemType,
} from '../../types/features/MenuFeatureTypes';

/**
 * Creates a orderItem based on an item and sets all defaults as configured.
 *
 * @param item MenuFeatureItemType
 * @returns {MenuFeatureOrderItemType}
 */
export default function constructMenuFeatureOrderItem(
  item: MenuFeatureItemType
): MenuFeatureOrderItemType {
  return {
    _id: uuid(),
    itemId: item._id,
    quantity: item.minQuantity || 1,
    maxQuantity: item.maxQuantity,
    minQuantity: item.minQuantity,
    options: item.options.map(optionList => ({
      _id: optionList._id,
      options: optionList.options
        .filter(option => option.isDefault)
        .map(option => option._id),
    })),
    notes: '',
  };
}
