import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const CineplexDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Cineplex,
  hasCustomUI: false,
  friendlyName: 'Cineplex Displays',
  description: `Defines the properties required for a Cineplex integration.`,
  category: IntegrationCategoryEnum.Displays,
  platforms: [],
  type: IntegrationTypeEnum.ContentTargeting,
  settings: {},
  properties: {},
  mobilePermissions: [],
};

export default CineplexDefinition;
