import React from 'react';

import ShopifyRsvpContextProvider from 'lane-shared/hooks/integrations/shopify/ShopifyRsvpContextProvider';

import BurstRsvp from './pages/BurstRsvp';

type Props = {
  content: any;
  interaction: any;
};

export default function ShopifyRsvpIndex({ content, interaction }: Props) {
  // the rsvp will be submitted on behalf of this user, otherwise the current user is used
  const rsvpSubmitOnBehalfOfUser = interaction.user ?? null;

  return (
    <ShopifyRsvpContextProvider
      contentId={content?._id}
      rsvpSubmitOnBehalfOfUser={rsvpSubmitOnBehalfOfUser}
    >
      <BurstRsvp content={content} />
    </ShopifyRsvpContextProvider>
  );
}
