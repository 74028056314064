import { DateTime, Zone } from 'luxon';

export default function parseDateTime(
  date: string | number | Date | DateTime | null | undefined,
  timeZone?: string | Zone
): DateTime | null {
  if (!date) {
    return null;
  }

  let ret;

  if (typeof date === 'string') {
    ret = DateTime.fromISO(date);
  } else if (typeof date === 'number') {
    ret = DateTime.fromMillis(date);
  } else if (DateTime.isDateTime(date)) {
    ret = date;
  } else if (date.constructor === Date) {
    ret = DateTime.fromJSDate(date);
  } else if (date instanceof Date) {
    ret = DateTime.fromJSDate(date);
  }

  if (!ret) {
    return null;
  }

  if (timeZone) {
    ret = ret.setZone(timeZone);
  }

  return ret;
}
