import React from 'react';

import { ContentType } from 'lane-shared/types/content/Content';
import { PropertyType } from 'lane-shared/types/properties/Property';

import JSONTypeValue, {
  JSONValueDIYType,
} from 'components/integrations/ShopifyBurst/components/JSONTypeValue';

type DIYPropertyRendererProps = {
  content: ContentType;
  field: PropertyType;
  UCIDataOfPropertyDefinition: JSONValueDIYType;
};

export default function ShopifyPropertyRenderer({
  content,
  field,
  UCIDataOfPropertyDefinition,
}: DIYPropertyRendererProps) {
  switch (field.type) {
    case 'JSON':
      return (
        <JSONTypeValue
          value={UCIDataOfPropertyDefinition}
          contentData={content}
        />
      );
  }

  return null;
}
