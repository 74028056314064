import { whenLabels, whenContextLabels, whenLabelsV2 } from './workflowLabels';
import {
  WorkflowWhenContextEnum,
  WorkflowWhenEnum,
} from 'lane-shared/types/Workflows';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';
import { WorkflowTypeEnum } from 'lane-shared/types/workflow';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type OptionType = {
  value: any;
  label: string;
};

type Props = {
  when?: WorkflowWhenEnum;
  contentType?: ContentTypeEnum;
  workflowType?: WorkflowTypeEnum;
  reservableFeature?: ReservableFeatureProperties;
  isNewWorkflowsUIEnabled?: boolean;
};

export function getDefaultWhenContext({
  when,
  contentType,
  workflowType,
  reservableFeature,
}: Props): WorkflowWhenContextEnum | undefined {
  if (contentType) {
    if (contentType === ContentTypeEnum.Content) {
      return WorkflowWhenContextEnum.Content;
    }

    if (when === WorkflowWhenEnum.Immediate || reservableFeature) {
      return WorkflowWhenContextEnum.UserContentInteraction;
    }

    if (contentType === ContentTypeEnum.WorkOrder) {
      return WorkflowWhenContextEnum.Escalation;
    }
  }

  if (workflowType) {
    if (workflowType === WorkflowTypeEnum.ServiceRequest) {
      return WorkflowWhenContextEnum.ServiceRequest;
    }
    if (workflowType === WorkflowTypeEnum.Task) {
      return WorkflowWhenContextEnum.Task;
    }

    if (workflowType === WorkflowTypeEnum.ServiceRequestEscalation) {
      return WorkflowWhenContextEnum.Escalation;
    }
  }

  return undefined;
}

export default function useWorkflowWhenOptions({
  when,
  contentType,
  workflowType,
  reservableFeature,
  isNewWorkflowsUIEnabled,
}: Props) {
  const { t } = useTranslation();
  const whenOptions = useMemo(
    () => {
      const options: OptionType[] = [];
      if (contentType) {
        options.push({
          value: WorkflowWhenEnum.Immediate,
          label: isNewWorkflowsUIEnabled
            ? t(whenLabelsV2[WorkflowWhenEnum.Immediate])
            : t(whenLabels[WorkflowWhenEnum.Immediate]),
        });
      }

      if (contentType === ContentTypeEnum.WorkOrder) {
        options.pop();
        options.push({
          value: WorkflowWhenEnum.After,
          label: isNewWorkflowsUIEnabled
            ? t(whenLabelsV2[WorkflowWhenEnum.After])
            : t(whenLabels[WorkflowWhenEnum.After]),
        });
      } else if (contentType === ContentTypeEnum.Content || reservableFeature) {
        options.push(
          {
            value: WorkflowWhenEnum.After,
            label: isNewWorkflowsUIEnabled
              ? t(whenLabelsV2[WorkflowWhenEnum.After])
              : t(whenLabels[WorkflowWhenEnum.After]),
          },
          {
            value: WorkflowWhenEnum.Before,
            label: isNewWorkflowsUIEnabled
              ? t(whenLabelsV2[WorkflowWhenEnum.Before])
              : t(whenLabels[WorkflowWhenEnum.Before]),
          }
        );
      }

      if (
        workflowType === WorkflowTypeEnum.ServiceRequest ||
        workflowType === WorkflowTypeEnum.Task
      ) {
        options.push({
          value: WorkflowWhenEnum.Immediate,
          label: isNewWorkflowsUIEnabled
            ? t(whenLabelsV2[WorkflowWhenEnum.Immediate])
            : t(whenLabels[WorkflowWhenEnum.Immediate]),
        });
      }

      if (workflowType === WorkflowTypeEnum.ServiceRequestEscalation) {
        options.push({
          value: WorkflowWhenEnum.After,
          label: isNewWorkflowsUIEnabled
            ? t(whenLabelsV2[WorkflowWhenEnum.After])
            : t(whenLabels[WorkflowWhenEnum.After]),
        });
      }

      return options;
    },
    // we don't need to listen for changes on the contentType or
    // reservableFeature because they cannot be modified from the
    // ContentWorkflowEdit component
    []
  );

  const whenContextOptions = useMemo(
    () => {
      const options: OptionType[] = [];

      if (contentType === ContentTypeEnum.Content) {
        options.push({
          value: WorkflowWhenContextEnum.Content,
          label: t(whenContextLabels[WorkflowWhenContextEnum.Content]),
        });
      }

      if (
        contentType &&
        (contentType === ContentTypeEnum.Content ||
          when === WorkflowWhenEnum.Immediate ||
          reservableFeature)
      ) {
        options.push({
          value: WorkflowWhenContextEnum.UserContentInteraction,
          label: t(
            whenContextLabels[WorkflowWhenContextEnum.UserContentInteraction]
          ),
        });
      }

      if (contentType === ContentTypeEnum.WorkOrder) {
        options.push({
          value: WorkflowWhenContextEnum.Escalation,
          label: t(whenContextLabels[WorkflowWhenContextEnum.Escalation]),
        });
      }

      if (workflowType === WorkflowTypeEnum.ServiceRequest) {
        options.push({
          value: WorkflowWhenContextEnum.ServiceRequest,
          label: t(whenContextLabels[WorkflowWhenContextEnum.ServiceRequest]),
        });
      }

      if (workflowType === WorkflowTypeEnum.Task) {
        options.push({
          value: WorkflowWhenContextEnum.Task,
          label: t(whenContextLabels[WorkflowWhenContextEnum.Task]),
        });
      }

      if (workflowType === WorkflowTypeEnum.ServiceRequestEscalation) {
        options.push({
          value: WorkflowWhenContextEnum.Escalation,
          label: t(whenContextLabels[WorkflowWhenContextEnum.Escalation]),
        });
      }

      return options;
    },
    // we don't need to listen for changes on the contentType or
    // reservableFeature because they cannot be modified from the
    // ContentWorkflowEdit component
    [when]
  );

  return { whenOptions, whenContextOptions };
}
