/* eslint-disable react/jsx-no-literals */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getAttachmentType } from 'lane-shared/helpers/attachment/getAttachmentType';
import { uploadAttachment } from 'lane-shared/helpers/attachment/uploadAttachment';
import { dataUriToBlob } from 'lane-shared/helpers/files';
import {
  AttachmentTypeEnum,
  AttachmentResponse,
  AttachmentEntityInput,
  AttachmentDataForUpload,
} from 'lane-shared/types/attachment';
import { AmazonS3Buckets, HTMLImage } from 'lane-shared/types/media';
import { validateUploadableAsset } from 'lane-shared/validation/validateUploadableAsset';

import { H3 } from 'components/typography';

import { createThumbnail, enforceSize } from 'helpers/images';

type Props = {
  onAttachmentCreated?: (attachment: AttachmentResponse) => void;
  entity?: AttachmentEntityInput;
  s3Bucket?: string;
};
/**
 * @description lane-web hook for processing attachments
 * @example
 * const entity = {id: <id>, type:  EntityTypeEnum.Content}
 * const { uploading, filesSelectedHandler } = useAttachmentUpload({onAttachmentCreated, entity})
 */
export function useAttachmentUpload({
  onAttachmentCreated,
  entity,
  s3Bucket = AmazonS3Buckets.Lane,
}: Props) {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);

  /**
   * @description A callback to whenever a file is selected a your file input
   * @example
   * <FileInput onFileSelected={filesSelectedHandler} enableMultiUpload>
   */
  async function filesSelectedHandler(
    files: File[],
    entityIdOverride?: string,
    entityObj?: AttachmentEntityInput
  ) {
    setUploading(true);

    const {
      attachmentMaxFilesValidator,
      uploadableAssetMaxFileSizeValidator,
    } = validateUploadableAsset(t);

    const validate = async () => {
      await attachmentMaxFilesValidator.validate(files);

      for (const file of files) {
        await uploadableAssetMaxFileSizeValidator.validate(file.size);
      }
    };

    await validate();

    if (entityIdOverride) {
      entity!.id = entityIdOverride;
    }

    const entityData = entity! || entityObj!;
    const createdAttachments: AttachmentResponse[] = [];
    for (let i = 0; i < files.length; i++) {
      await window.Alert.loading({
        title: t('Uploading…'),
        children: (
          <H3 style={{ textAlign: 'center' }}>
            {i + 1} / {files.length}
          </H3>
        ),
      });
      const file = files[i]!;
      try {
        const attachmentType = getAttachmentType(file.type);
        const attachmentAndThumbnail = await processAttachmentForUpload(
          file,
          attachmentType
        );
        const createdAttachment = await uploadAttachment(
          entityData,
          attachmentAndThumbnail
        );
        createdAttachments.push(createdAttachment);
        if (onAttachmentCreated) {
          onAttachmentCreated(createdAttachment);
        }
      } catch (error) {
        console.error(error);
        window.Toast.show(
          <H3 style={{ textAlign: 'center' }}>
            {t('Failed to upload')}{' '}
            <span>{typeof file !== 'string' && file.name}</span>
          </H3>
        );
      }
    }

    setUploading(false);
    window.Alert.hide();
    return createdAttachments;
  }

  const processAttachmentForUpload = async (
    attachment: File,
    attachmentType: AttachmentTypeEnum
  ): Promise<AttachmentDataForUpload> => {
    let attachmentDataForUpload: Partial<AttachmentDataForUpload> = {
      attachment: {
        name: attachment.name,
        size: attachment.size,
        type: attachment.type,
      },
      s3Bucket,
    };

    if (attachmentType === AttachmentTypeEnum.Image) {
      // Convert File to Image object for canvas resizing
      const image = new Image() as HTMLImage;
      image.src = URL.createObjectURL(attachment);
      await new Promise<Event>(res => (image.onload = res));

      const resizedBlob = dataUriToBlob(enforceSize({ image }).uri);
      const thumbnail = createThumbnail({ image });
      const thumbnailBlob = dataUriToBlob(thumbnail.uri);

      attachmentDataForUpload = {
        ...attachmentDataForUpload,
        attachmentBlob: resizedBlob,
        thumbnail: {
          name: attachment.name,
          size: thumbnailBlob.size,
          type: thumbnail.type,
          width: thumbnail.width,
          height: thumbnail.height,
        },
        thumbnailBlob,
      };
    } else {
      attachmentDataForUpload = {
        ...attachmentDataForUpload,
        attachmentBlob: attachment,
      };
    }

    return attachmentDataForUpload as AttachmentDataForUpload;
  };

  return {
    filesSelectedHandler,
    uploading,
  };
}
