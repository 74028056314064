import * as yup from 'yup';

import localization from 'localization';

const i18n = localization.cloneInstance();
const REQUIRED_MESSAGE = i18n.t('shared.validation.field.required');

const error = i18n.t('Full name must be at least 2 characters');

export default yup
  .string()
  .required(REQUIRED_MESSAGE)
  .min(2, error)
  .trim()
  .nullable();
