import React, { forwardRef } from 'react';

import cx from 'classnames';

import { Loading } from '../Loading/Loading';

import styles from './Button.scss';

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'text';

type BaseButtonProps = {
  className?: string;
  style?: React.CSSProperties;
  variant?: ButtonVariants;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  dataCy?: string;
  children?: React.ReactNode;
  size?: 'medium' | 'large';
  interfaceStyle?: 'light' | 'dark';
  isPreview?: boolean;
  ariaLabel?: string;
};

type AdditionalButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'button' | 'reset' | 'submit' | undefined;
  disabled?: boolean;
  loading?: boolean;
};

export type ButtonProps = BaseButtonProps & AdditionalButtonProps;

type AnchorProps = {
  to?: string;
  target?: string;
  as: 'a';
  onClick?: (e: any) => void;
};

type RouterProps = {
  to?: any;
  as: React.ComponentType<any>;
  target?: never;
  onClick?: (e: any) => void;
};

export type ButtonLinkProps = BaseButtonProps & (AnchorProps | RouterProps);

const getButtonProps = (
  props: AnchorProps | RouterProps | AdditionalButtonProps
) => {
  if ('as' in props) {
    if (props.as === 'a') {
      return {
        href: props.to,
        target: props.target,
        'data-link': true,
        onClick: props.onClick || (() => null),
      };
    }
    return {
      to: props.to,
      'data-link': true,
      onClick: props.onClick || (() => null),
    };
  }
  return {
    onClick: props.onClick || (() => null),
    type: props.type || 'button',
    disabled: props.disabled || props.loading || false,
    'data-loading': props.loading || false,
  };
};

export const Button = forwardRef<
  HTMLButtonElement | null,
  ButtonProps | ButtonLinkProps
>(
  (
    {
      children,
      startIcon,
      endIcon,
      fullWidth,
      className,
      style,
      variant = 'primary',
      dataCy,
      size = 'medium',
      interfaceStyle = 'light',
      ariaLabel,
      ...props
    },
    ref
  ) => {
    function renderStartIcon() {
      if ('loading' in props && props.loading)
        return <Loading className={styles.loader} />;
      if (!startIcon) return null;
      return <span className={styles.startIcon}>{startIcon}</span>;
    }

    const Element = 'as' in props ? props.as : 'button';

    return (
      <Element
        ref={ref}
        className={cx(styles.Button, className)}
        style={style}
        data-start-icon={Boolean(startIcon)}
        data-end-icon={Boolean(endIcon)}
        data-cy={dataCy}
        data-variant={variant}
        data-children={Boolean(children)}
        data-full-width={fullWidth}
        data-interface-style={interfaceStyle}
        data-size={size}
        data-icon-button={!children}
        aria-label={ariaLabel}
        {...getButtonProps(props)}
      >
        {renderStartIcon()}
        {children}
        {endIcon ? <span className={styles.endIcon}>{endIcon}</span> : null}
        <span className={styles.overlay} />
      </Element>
    );
  }
);
