import { DEFAULT_BACKGROUND } from 'lane-shared/helpers/constants';

import resizeImage from './resizeImage';

export default function createThumbnail({
  image,
  imageType = 'image/jpeg',
  quality = 0.5,
  maxWidth = 160,
  background = DEFAULT_BACKGROUND,
}: any) {
  return resizeImage({
    image,
    imageType,
    quality,
    maxWidth,
    background,
  });
}
