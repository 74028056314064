import { useQuery } from '@apollo/client';
import { ChannelRelationshipPagination } from 'graphql-query-contracts';

import queryByChannelRelationships from 'lane-shared/graphql/channel/queryChannelsByRelationship';

type ChannelRelationshipPaginationRes = {
  channelsByRelationship: ChannelRelationshipPagination;
};

type CompanySearch = {
  data?: ChannelRelationshipPaginationRes;
  loading: boolean;
};

export const useCompanySearch = (channelId: string): CompanySearch => {
  // Refactor this hook to use this query when it is written: https://viewthespace.atlassian.net/browse/TM-16750
  const { data, loading } = useQuery<ChannelRelationshipPaginationRes>(
    queryByChannelRelationships,
    {
      variables: {
        search: {
          channel: {
            sortBy: {
              key: 'name',
              dir: 'asc',
            },
            isSub: false,
          },
          relatedTo: {
            _id: channelId,
          },
        },
        skipPagination: true,
      },
    }
  );

  return {
    data,
    loading,
  };
};
