import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import Email from '../../../../properties/baseTypes/Email';
import PhoneNumber from '../../../../properties/baseTypes/PhoneNumber';
import UUID from '../../../../properties/baseTypes/UUID';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';

const properties: PropertiesInterface = {
  id: {
    friendlyName: 'ID',
    type: 'String',
  },
  first_name: {
    friendlyName: 'First Name',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  last_name: {
    friendlyName: 'Last Name',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  email: {
    friendlyName: 'Visitor Email',
    type: 'Email',
  },
  phone: {
    friendlyName: 'Visitor Phone',
    type: 'PhoneNumber',
  },
};

const shape = createShapeFromProperties(properties);
const schema = yup
  .object()
  .shape(createShapeFromProperties(properties))
  .nullable();

interface Visitor {
  id?: string;
  firstName: string;
  lastName: string;
  email?: string;
  number?: string;
}

export class BEVisitorType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'BEVisitor';

  friendlyName = 'Building Prism Visitor';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      id: UUID.example,
      firstName: 'Lane',
      lastName: 'Doe',
      email: Email.example,
      number: PhoneNumber.example,
    };
  }

  get default(): Visitor {
    return {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      number: '',
    };
  }

  shape = shape;

  buildSchema() {
    return schema;
  }

  properties = properties;
}

const definition = new BEVisitorType();

Types.addType(definition as any);

export default definition;
