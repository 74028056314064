import { ReactNode } from 'react';

export type Column = {
  header: string;
  key: string;
  /** Disable sorting for this column */
  disableSort?: boolean;
};

/**
 * key: must match one of the keys in the Columns array
 * type: type of the value you're using for this column (only used for sorting purposes)
 * value: the value to display in the table
 * renderer: a function that takes the value and returns a ReactNode (or a new string)
 */
export type Cell =
  | {
      key: string;
      type: 'string';
      value: string | string[];
      renderer?: (value: string | string[]) => ReactNode;
    }
  | {
      key: string;
      type: 'number';
      value: number;
      renderer?: (value: number) => ReactNode;
    }
  | {
      key: string;
      type: 'date';
      /** new Date(value) must be valid  */
      value: string;
      renderer?: (value: string) => ReactNode;
    }
  | {
      key: string;
      type: 'custom';
      renderer: () => ReactNode;
    };

export type Action = {
  iconName?: string;
  label: string;
  onClick: () => void;
  isDelete?: boolean;
};

export type Row = {
  id: string;
  /** Seperate data and any custom rendering so we can properly sort */
  items: Cell[];
  /** Display actions in a context menu in the last column. Add a 'Actions' header too.  */
  actions?: Action[];
};

export enum Dir {
  asc = 'asc',
  desc = 'desc',
}

export type UrlSearchParams = {
  sortKey: string;
  dir: Dir;
};

export type UrlSearchParamsWithPages = UrlSearchParams & {
  perPage: number;
  page: number;
  total: number;
};

export type VisitorManagementUrlSearchParams<
  TabType = string
> = UrlSearchParamsWithPages & {
  tab: TabType;
};

export type ServiceRequestsUrlSearchParams = UrlSearchParamsWithPages & {
  search: string;
  company: string;
  category: string;
  status: string;
  startDate: Date;
  endDate: Date;
};
