import * as yup from 'yup';

const codePartSchema = yup.string().required().min(4).max(4);

export default yup.object({
  code1: codePartSchema,
  code2: codePartSchema,
  code3: codePartSchema,
  code4: codePartSchema,
});
