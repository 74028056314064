import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { convertTo62, convertToUUID } from '../../helpers/convertId';
import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

export class UUIDType extends StringTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'UUID';

  friendlyName = 'Unique ID';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Properties,
  ];

  get example() {
    return convertTo62(uuid());
  }

  get default(): any {
    return convertTo62(uuid());
  }

  buildSchema() {
    return yup.string().nullable();
  }

  /**
   * todo: disable UUID validation for now

   .test(
   'is-uuid',
   '${path} is not a valid UUID',
   value =>
   value &&
   (value.length === UUID.length || value.length === UUID_BASE62.length)
   )
   */
  serialize = (value: any) => {
    if (!value) {
      return value;
    }

    return convertTo62(value);
  };

  parseValue = (value: any) => {
    if (!value) {
      return value;
    }

    return convertToUUID(value);
  };
}

const definition = new UUIDType();

Types.addBaseType(definition);
export default definition;
