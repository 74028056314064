import * as yup from 'yup';

export default yup.object().shape({
  __typename: yup.string().strip(true),
  type: yup.string().trim().oneOf(['Published', 'Syndicated']),
  channel: yup
    .object()
    .nullable()
    .notRequired()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup.string(),
    }),
  user: yup
    .object()
    .nullable()
    .notRequired()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup.string().trim(),
    }),
  groupRole: yup
    .object()
    .nullable()
    .notRequired()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup.string(),
    }),
});
