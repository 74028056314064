import React from 'react';

import cx from 'classnames';

import { DocumentType } from 'lane-shared/types/DocumentType';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { PropertySecurityRule } from 'lane-shared/types/properties/PropertySecurity';

import SecurityInput from 'components/lane/SecurityInput';

import styles from './FieldSecurityItem.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channel: DocumentType;
  field: PropertyType;
  operation: 'read' | 'create' | 'update' | 'delete';
  security: PropertySecurityRule;
  onFieldUpdated: (field: Partial<PropertyType>) => void;
};

export default function FieldSecurityItem({
  className,
  style,
  channel,
  field,
  operation,
  security,
  onFieldUpdated,
}: Props) {
  function onUpdated(updatedSecurity: PropertySecurityRule) {
    const rules = field.secure![operation]!;
    const ix = rules.findIndex(rule => rule._id === updatedSecurity._id);

    rules[ix] = {
      ...rules[ix],
      ...updatedSecurity,
    };

    onFieldUpdated({ secure: { ...field.secure, [operation]: [...rules] } });
  }

  return (
    <li className={cx(styles.FieldSecurityItem, className)} style={style}>
      <SecurityInput
        security={security}
        channel={channel}
        onUpdated={onUpdated}
        onRemoved={() => {
          field.secure![operation] = field.secure![operation]!.filter(
            s => s !== security
          );

          onFieldUpdated({ secure: { ...field.secure } });
        }}
      />
    </li>
  );
}
