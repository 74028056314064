import React from 'react';

import cx from 'classnames';

import QuantityInput from 'components/form/QuantityInput';
import Slider from 'components/form/Slider';
import Label from 'components/general/Label';
import { S, H5 } from 'components/typography';

import styles from './ReservableFeatureSlider.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  TooltipComponent: React.ReactNode;
  value: number;
  label: string;
  description?: string;
  min: number;
  max: number;
  step?: number;
  formatLabel?: (value: number) => string;
  onChange: (value: number) => void;
};

export default function ReservableFeatureSlider({
  className,
  style,
  disabled,
  TooltipComponent,
  value,
  label,
  description,
  min,
  max,
  step,
  formatLabel,
  onChange,
}: Props) {
  return (
    <fieldset
      className={cx(styles.ReservableFeatureSlider, className)}
      style={style}
      data-disabled={disabled}
    >
      <Label TooltipComponent={TooltipComponent} className={styles.label}>
        <H5 mb={2}>{label}</H5>
      </Label>
      {description && <S>{description}</S>}
      <div className={styles.row}>
        <Slider
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
          className={styles.slider}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: number) => void' is not assignable t... Remove this comment to see the full error message
          onChange={onChange}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
          min={min}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
          max={max}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '((value: number) => string) | undefined' is ... Remove this comment to see the full error message
          formatLabel={formatLabel}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          step={step}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
          value={Number(value)}
        />
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ style: {... Remove this comment to see the full error message */}
        <QuantityInput
          style={{ border: 'none' }}
          className={styles.input}
          disabled={disabled}
          onChange={onChange}
          quantity={value}
          min={min}
          max={max}
        />
      </div>
    </fieldset>
  );
}
