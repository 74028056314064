import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import {
  ReservableEventTypeEnum,
  ReservableUnavailabilityRange,
} from 'lane-shared/hooks/useReservableAvailabilityByRange';

function mapBufferTime(startDate: Date, endDate: Date) {
  return {
    interval: {
      startDate,
      endDate,
    },
    eventType: ReservableEventTypeEnum.Buffer,
  };
}

export function getUnavailableRangesFromBufferTime(
  startDate: Date,
  endDate: Date,
  reservations: ReservableUnavailabilityRange[],
  bufferTimeConfig: LaneType.BufferTime
) {
  const unavailableRanges: ReservableUnavailabilityRange[] = [];

  if (!bufferTimeConfig.isEnabled) {
    return unavailableRanges;
  }

  if (bufferTimeConfig.beforeDuration) {
    const startDateTime = DateTime.fromJSDate(startDate);
    const startingBufferTime = startDateTime.plus({
      minutes: bufferTimeConfig?.beforeDuration,
    });

    unavailableRanges.push(
      mapBufferTime(startDate, startingBufferTime.toJSDate())
    );
  }

  reservations.forEach(({ interval }) => {
    if (bufferTimeConfig.afterDuration) {
      const bufferTime = mapBufferTime(
        DateTime.fromJSDate(interval.startDate!)
          .minus({ minutes: bufferTimeConfig.afterDuration })
          .toJSDate(),
        interval.startDate!
      );

      unavailableRanges.push(bufferTime);
    }

    if (bufferTimeConfig.beforeDuration) {
      const bufferTime = mapBufferTime(
        interval.endDate!,
        DateTime.fromJSDate(interval.endDate!)
          .plus({ minutes: bufferTimeConfig.beforeDuration })
          .toJSDate()
      );

      unavailableRanges.push(bufferTime);
    }
  });

  if (bufferTimeConfig.afterDuration) {
    const endDateTime = DateTime.fromJSDate(endDate);
    const startingBufferTime = endDateTime.minus({
      minutes: bufferTimeConfig?.afterDuration,
    });

    unavailableRanges.push(
      mapBufferTime(startingBufferTime.toJSDate(), endDate)
    );
  }

  return unavailableRanges;
}
