import { constructUrl, request, Res, UPLOAD_ENDPOINT } from './common';

export interface WorkOrderAttachment {
  /** unique key that will be returned in onClose() */
  id?: string;
  file_association_id?: string;
  display_name: string;
  thumbnail?: string;
  created_at?: string;
  category?: {
    name: string;
  };
}

export type ListResData = WorkOrderAttachment[];

export interface ListParams {
  work_order_id: string;
}

export interface FileParams {
  file_id: string;
  file_association_id: string;
}

export async function get(
  token: string,
  params: ListParams
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    path: `/work_orders/${params.work_order_id}/files`,
  });
}

export async function deleteFile(
  token: string,
  fileParams: FileParams
): Promise<Res<void>> {
  return request({
    token,
    method: 'delete',
    path: `/files/${fileParams.file_id}/associations/${fileParams.file_association_id}`,
  });
}

export async function post(
  token: string,
  work_order_id: string,
  file: Blob,
  fileName: string
): Promise<any> {
  const url = constructUrl({
    endpoint: UPLOAD_ENDPOINT,
    reqPath: `/work_orders/${work_order_id}/files?${fileName}`,
  });

  return fetch(url, {
    method: 'post',
    headers: {
      'X-BE-Authorization': `Bearer ${token}`,
    },
    body: file,
  });
}
