import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SHORT_TIME } from 'lane-shared/helpers/constants/dates';
import { doDateRangesOverlap } from 'lane-shared/helpers/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import Chips from '../Chips';

import styles from './index.scss';
import { Icon } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import classNames from 'classnames';

const SEE_MORE_THRESHOLD = 12;

type TimeChipProps = {
  options: DateRangeType[];
  onClick: (range: DateRangeType) => void;
  value: DateRangeType;
  unavailableDateRanges?: DateRangeType[];
  timeZone?: string;
};

export default function TimeChips({
  options,
  onClick,
  value,
  unavailableDateRanges = [],
  timeZone,
}: TimeChipProps) {
  const { t } = useTranslation();

  const onClickHandler = (selectedValue: DateRangeType) => {
    if (doDateRangesOverlap(selectedValue, value)) {
      onClick({ startDate: null, endDate: null });
    } else {
      onClick(selectedValue);
    }
  };

  const availableTimes = useMemo(
    () =>
      options
        .filter(
          option =>
            !unavailableDateRanges.some(range =>
              doDateRangesOverlap(option, range)
            )
        )
        .map(option => ({
          label: dateFormatter(option.startDate, SHORT_TIME, timeZone),
          value: option,
        })),
    [options, unavailableDateRanges, timeZone]
  );

  if (availableTimes.length === 0) {
    return (
      <div className={classNames(styles.noAvailabilityWrapper)}>
        <Icon
          name="info-circle"
          className={styles.infoIcon}
          set={ICON_SET_FONTAWESOME}
          type="far"
        />
        <p className={styles.noAvailable}>
          {t('shared.content.feature.reservable.timeSlots.noAvailability')}
        </p>
      </div>
    );
  }

  return (
    <>
      <h5 className={styles.timeChipHeader}>
        {t('web.content.feature.reservable.timeSlots.availableTimes')}
      </h5>
      <Chips<DateRangeType>
        onClick={onClickHandler}
        compare={range => doDateRangesOverlap(range, value)}
        options={availableTimes}
        itemName="time"
        hasSeeMore
        seeMoreThreshold={SEE_MORE_THRESHOLD}
        gridSize="large"
      />
    </>
  );
}
