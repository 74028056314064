export const validateVisitorTenant = (interaction: {
  data?: {};
  state?: {};
  features: {
    VisitorManagement?: {
      hostInformation?: { hostTenantId?: string };
      visitors?: { tenantId?: string }[];
    };
  };
}) => {
  return (
    Boolean(
      interaction?.features?.VisitorManagement?.hostInformation
        ?.hostTenantId
    ) ||
    Boolean(
      interaction?.features?.VisitorManagement?.visitors?.[0]?.tenantId
    )
  );
};
