import { useEffect, useRef, useMemo, useContext } from 'react';

import { DateTime } from 'luxon';

import { ContentRendererContext } from '../contexts';
import { getDefaultSearchWindowForReservable } from '../helpers/features';
import { ReservableFeatureProperties } from '../types/features/ReservableFeatureProperties';
import useReservableAvailabilityByRange, {
  ReservableUnavailabilityRange,
} from './useReservableAvailabilityByRange';

type Props = {
  contentId: string;
  reservableFeature: ReservableFeatureProperties | undefined;
  timeZone: string;
  referenceDate: DateTime;
};

export default function useReservableAvailability({
  contentId,
  reservableFeature,
  timeZone,
  referenceDate,
}: Props): {
  unavailableDateRanges: ReservableUnavailabilityRange[];
  loading: boolean;
  error: Error | null;
  getReservableAvailability: () => Promise<void>;
} {
  const internalRef = useRef<{
    loading: boolean;
    referenceDate: DateTime;
  }>({
    loading: false,
    referenceDate: DateTime.local().setZone(timeZone),
  });

  const { submissionCompletedAt } = useContext(ContentRendererContext);
  const { startDate, endDate } = useMemo(
    () =>
      getDefaultSearchWindowForReservable({
        reservableFeature,
        referenceDate,
        timeZone,
      }),
    [reservableFeature, referenceDate, timeZone]
  );

  const {
    unavailableDateRanges,
    loading,
    error,
    getReservableAvailability: fetchReservableAvailability,
  } = useReservableAvailabilityByRange({
    contentId,
    startDate,
    endDate,
  });

  async function getReservableAvailability() {
    if (internalRef.current.loading) {
      return;
    }

    if (!startDate && !endDate) {
      return;
    }

    internalRef.current.referenceDate = referenceDate;
  }

  useEffect(() => {
    (async () => {
      await fetchReservableAvailability();
    })();

    if (reservableFeature && referenceDate) {
      getReservableAvailability();
    }
  }, [
    reservableFeature,
    referenceDate?.toMillis(),
    submissionCompletedAt,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    internalRef.current.loading = loading;
  }, [loading]);

  return { unavailableDateRanges, loading, error, getReservableAvailability };
}
