import React from 'react';
import { Dropdown } from 'design-system-web';
import {
  AddProductToChargeDetails,
  AddProductToContentDetails,
  ProductCategory,
} from 'lane-shared/domains/billingPayments/types';
import { useProductsListQuery } from 'pages/portal/admin/channel/products-services/helpers';
import { Item } from 'components/form/Dropdown/Dropdown';

export function ProductDropdown({
  selectedProduct,
  productCategory,
  handleProductSelection,
  disabled = false,
  label = '',
  placeholder = '',
  fixedLabel = false,
  className,
  isRequired = false,
}: {
  selectedProduct: AddProductToContentDetails | AddProductToChargeDetails;
  productCategory: ProductCategory;
  handleProductSelection: Function;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  fixedLabel?: boolean;
  className?: string | string[];
  isRequired?: boolean;
}) {
  const productsList = useProductsListQuery({
    page: 0,
    pageSize: 100,
    productCategoryId: productCategory.productCategoryId,
  });

  function getProductItems(): Item<string>[] | undefined {
    const listOfProducts = productsList.data?.listProducts.products;

    if (listOfProducts) {
      const dropdownProductItems = listOfProducts.map(product => {
        return {
          label: product.name,
          value: product.id,
        };
      });
      return dropdownProductItems;
    }
    return undefined;
  }

  const onProductSelectionFromDropdown = (item: Item<string>) => {
    const selectedProductFromList = productsList.data?.listProducts.products.find(
      product => product.id === item.value
    );

    if (selectedProductFromList) {
      handleProductSelection(selectedProductFromList);
    }
  };

  return (
    <Dropdown
      dataCy="productDropdown"
      doTranslation={false}
      value={selectedProduct.productId}
      items={getProductItems()}
      onChange={onProductSelectionFromDropdown}
      label={label}
      placeholder={placeholder}
      fixedLabel={fixedLabel}
      disabled={disabled}
      className={className}
      isRequired={isRequired}
    />
  );
}
