import { DateTime, Interval } from 'luxon';

import parseDateTime from './parseDateTime';

export default function getDaysInTimeRange(
  startDate: Date | undefined | null,
  endDate: Date | undefined | null,
  timeZone?: string
): DateTime[] {
  if (!startDate || !endDate) {
    return [];
  }

  return Interval.fromDateTimes(
    parseDateTime(startDate, timeZone)!.startOf('day'),
    parseDateTime(endDate, timeZone)!.endOf('day')
  )
    .splitBy({ days: 1 })
    .map(d => d.start);
}
