import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { useEffect } from 'react';
import { doDateRangesOverlap } from '../helpers/dates';
import useFlag from './useFlag';
import { FeatureFlag } from '../types/FeatureFlag';

export function useEarliestAvailableTimeOption(
  options: DateRangeType[],
  unavailableTimeRanges: DateRangeType[],
  onChange: (range: DateRangeType) => void
) {
  const availableTimes = options.filter(
    option =>
      !unavailableTimeRanges?.some(range => doDateRangesOverlap(option, range))
  );

  const isRecurringEventsFlagEnabled = useFlag<boolean>(
    FeatureFlag.RecurringEvents,
    false
  );

  useEffect(() => {
    if (isRecurringEventsFlagEnabled && availableTimes.length > 0) {
      onChange(availableTimes[0]);
    }
  }, [JSON.stringify(availableTimes)]);
}
