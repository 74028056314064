import * as yup from 'yup';

import { DAYS_OF_THE_WEEK } from '../../helpers/constants/timeUnits';
import { NumberTypeBase } from '../../types/baseTypes/NumberTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

export class DayType extends NumberTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Day';

  friendlyName = 'Day';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  get example() {
    return DAYS_OF_THE_WEEK.MONDAY.numericValue;
  }

  get default(): any {
    return DAYS_OF_THE_WEEK.MONDAY.numericValue;
  }

  buildSchema() {
    return yup.number().nullable().min(1).max(7);
  }
}

const definition = new DayType();

Types.addBaseType(definition);

export default definition;
