import { useState, useEffect, useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { ReservableEdge } from 'graphql-query-contracts';
import { UserDataContextInterface } from 'lane-shared/contexts/UserDataContext';
import {
  fetchUnavailabilityRangesForReservables,
  ReservableWithReservationRanges,
} from './helpers/fetchUnavailabilityRangesForReservables';
import { getUnavailabilityRangesForReservableContent } from './helpers/getUnavailabilityRangesForReservableContent';

export function useUnavailableRangesForContents({
  referenceDate,
  reservablesOnChannel,
  user,
}: {
  referenceDate: Date;
  reservablesOnChannel: ReservableEdge[];
  user: UserDataContextInterface['user'];
}) {
  const [error, setError] = useState<ApolloError | null>(null);
  const [contents, setContents] = useState<ReservableWithReservationRanges>([]);
  const userRoleBase62Ids = useMemo(
    () => user?.roles?.map(({ groupRole }) => groupRole._id) || [],
    [user?.roles]
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const contents = await fetchUnavailabilityRangesForReservables(
          referenceDate,
          reservablesOnChannel
        );

        setContents(contents);
      } catch (err) {
        setError(err);
      }
    }

    fetchData();
  }, [referenceDate, reservablesOnChannel]);

  const reservablesWithUnavailableRanges = useMemo(() => {
    return contents.map(content => {
      const unavailableRangesByRulesAndReservations = getUnavailabilityRangesForReservableContent(
        {
          content,
          referenceDate,
          userRoleBase62Ids,
        }
      );

      return {
        ...content,
        unavailableRanges: unavailableRangesByRulesAndReservations,
      };
    });
  }, [contents, referenceDate, userRoleBase62Ids]);

  return { reservablesWithUnavailableRanges, error };
}
