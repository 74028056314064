import { request, Res } from './common';

export interface ListParams {
  building_id: string;
}

export interface ListResData {
  count: number;
  results: Space[];
}

export interface Space {
  id: string;
  floor_id: string;
  name: string;
  tenant_id: string;
}

export async function list(
  token: string,
  params: ListParams
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    path: `/buildings/${params.building_id}/spaces`,
  });
}
