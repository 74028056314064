import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import FeatureOptionToggle from 'components/renderers/features/components/FeatureOptionToggle';
import styles from './VisitorAdditionalRecipients.scss';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

export function VisitorAdditionalRecipients({
  feature,
  settings,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  settings: VisitorManagementFeatureProperties;
  onFeatureUpdated: (
    feature: Partial<VisitorManagementFeatureProperties>
  ) => void;
}) {
  const { t } = useTranslation();

  if (!feature.properties) return null;

  return (
    <div className={styles.VisitorAdditionalRecipients}>
      <FeatureOptionToggle
        title={t(
          'web.admin.content.features.visitor.management.general.additional.recipients.notify'
        )}
        onChange={value =>
          onFeatureUpdated({
            ...settings,
            additionalRecipientsEnabled: value,
          })
        }
        isExpanded={settings.additionalRecipientsEnabled}
        dataCy="visitorAdditionalRecipientsToggle"
        className={styles.toggle}
      />
    </div>
  );
}
