import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  ORDER_STATUS_COLOR,
  MAP_STATUS,
} from 'lane-shared/helpers/constants/integrations/BuildingEnginesClassic';
import { simpleDateTime } from 'lane-shared/helpers/formatters';

import styles from './RequestCard.scss';

function RequestCard({ style = {}, className = '', interaction }: any) {
  const { t } = useTranslation();
  const issueType = interaction.secondary_type;

  return (
    <div className={cx(styles.RequestCard, className)} style={style}>
      <h1>{issueType?.name}</h1>
      <div
        className={styles.line}
        style={{
          borderColor:
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ORDER_STATUS_COLOR[MAP_STATUS[interaction.status]] || '#E5C34C',
        }}
      />
      <p>
        {t('Request Number')}: <span>{interaction.id}</span>
      </p>
      <p>
        {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {t('Status')}: <span>{MAP_STATUS[interaction.status]}</span>
      </p>
      <p>
        {t('Submitted')}:{' '}
        <span>{simpleDateTime(interaction.created, 'UTC')}</span>
      </p>
      <p>
        {t('Updated')}:{' '}
        <span>{simpleDateTime(interaction.last_modified, 'UTC')}</span>
      </p>
      <p>
        Description: <span>{interaction.description}</span>
      </p>
    </div>
  );
}

export default RequestCard;
