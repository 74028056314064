import React from 'react';

import { Icon } from '../Icon';

import {
  ICON_SET_FONTAWESOME,
  FONT_AWESOME_SOLID,
} from 'lane-shared/helpers/constants/icons';

import styles from './SortIcon.scss';

type SortIconProps = {
  direction?: 'asc' | 'desc';
};

export const SortIcon = ({ direction }: SortIconProps) => {
  if (direction === 'asc') {
    return (
      <Icon
        name="sort-down"
        set={ICON_SET_FONTAWESOME}
        className={styles.Icon}
        type={FONT_AWESOME_SOLID}
        testID="sort-icon"
      />
    );
  }

  if (direction === 'desc') {
    return (
      <Icon
        name="sort-up"
        className={styles.Icon}
        set={ICON_SET_FONTAWESOME}
        type={FONT_AWESOME_SOLID}
        testID="sort-icon"
      />
    );
  }

  return (
    <Icon
      name="sort"
      className={styles.Icon}
      set={ICON_SET_FONTAWESOME}
      type={FONT_AWESOME_SOLID}
      testID="sort-icon"
    />
  );
};
