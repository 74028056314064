export enum ResidentStatusEnum {
  RESIDENT_STATUS_INVALID = 'INVALID',
  RESIDENT_STATUS_PAST = 'PAST',
  RESIDENT_STATUS_FUTURE = 'FUTURE',
  RESIDENT_STATUS_EVICTION = 'EVICTION',
  RESIDENT_STATUS_NOTICE = 'NOTICE',
  RESIDENT_STATUS_CURRENT = 'CURRENT',
  NO_PRIOR_STATUS = 'NO_PRIOR_STATUS',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum ResidentTypeEnum {
  Invalid = 'INVALID',
  PrimaryResident = 'PRIMARY_RESIDENT',
  SecondaryResident = 'SECONDARY_RESIDENT',
  Guarantor = 'GUARANTOR',
  Unrecognized = 'UNRECOGNIZED',
}
