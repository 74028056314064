import React from 'react';

import { useTranslation } from 'react-i18next';

import { useFlag } from 'lane-shared/hooks';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { VisitorNotesSettings } from 'lane-web/src/domains/visitorManagement/feature/components/VisitorNotesSettings/VisitorNotesSettings';

import { H5, S } from 'components/typography';

import { VisitorAdditionalRecipients, VisitorFieldSettings } from '..';
import VisitorHostSelectionSettings from '../VisitorHostSelectionSettings/VisitorHostSelectionSettings';

export default function VisitorGeneralTab({
  feature,
  settings,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  settings: VisitorManagementFeatureProperties;
  onFeatureUpdated: (
    feature: Partial<VisitorManagementFeatureProperties>
  ) => void;
}) {
  const { t } = useTranslation();

  const hostSelectionEnabled = useFlag(
    FeatureFlag.VisitorManagementHostSelection,
    false
  );

  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );

  const multipleNotificationRecipientsEnabled = useFlag(
    FeatureFlag.VisitorManagementMultipleNotificationRecipients,
    false
  );

  return (
    <>
      <H5>
        {t(
          'web.admin.content.features.visitor.management.general.visitor.information.title'
        )}
      </H5>
      <S>
        {t(
          'web.admin.content.features.visitor.management.general.visitor.information.description'
        )}
      </S>
      <VisitorFieldSettings
        feature={feature}
        settings={settings}
        onFeatureUpdated={onFeatureUpdated}
      />
      {hostSelectionEnabled && (
        <>
          <H5>
            {t(
              'web.admin.content.features.visitor.management.general.host.information.title'
            )}
          </H5>
          <S>
            {t(
              'web.admin.content.features.visitor.management.general.host.information.description'
            )}
          </S>
          <VisitorHostSelectionSettings
            feature={feature}
            settings={settings}
            onFeatureUpdated={onFeatureUpdated}
          />
        </>
      )}
      {visitorNotesEnabled && (
        <>
          <H5>
            {t(
              'web.admin.content.features.visitor.management.general.notes.title'
            )}
          </H5>
          <S>
            {t(
              'web.admin.content.features.visitor.management.general.notes.description'
            )}
          </S>
          <VisitorNotesSettings
            feature={feature}
            settings={settings}
            onFeatureUpdated={onFeatureUpdated}
          />
        </>
      )}
      {multipleNotificationRecipientsEnabled && (
        <VisitorAdditionalRecipients
          feature={feature}
          settings={settings}
          onFeatureUpdated={onFeatureUpdated}
        />
      )}
    </>
  );
}
