import { LaneType } from 'common-types';
import { getBatchPublicClient } from '../../apollo';
import getPublicUserQuery from '../../graphql/query/getPublicUser';

type PublicUserType = {
  _id: LaneType.UUID;
  _created: string;
  name: string;
  online: boolean;
  lastSeen: string;

  role: {
    _id: LaneType.UUID;
    name: string;
    description: string;
    groupRoleName: string;
  };

  profile: {
    _id: LaneType.UUID;
    name: string;
    phone: LaneType.PhoneNumber;
    email: LaneType.Email;
    logo: LaneType.UUID;
    image: LaneType.UUID;
  };
};

export default async function getPublicUsers(
  userIds: LaneType.UUID[]
): Promise<PublicUserType[]> {
  const results = await Promise.all(
    userIds.map(id =>
      getBatchPublicClient().query<{ user: PublicUserType }>({
        query: getPublicUserQuery,
        variables: {
          id,
        },
      })
    )
  );

  return results
    .filter(({ data }) => Boolean(data?.user))
    .map(({ data }) => data.user);
}
