import * as yup from 'yup';
import i18next from 'i18next';

export const validateCreateOIDCServiceProviderDefinition = (
  existingProviders: string[]
) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        i18next.t('shared.integrations.ssoIntegrations.form.validations.name')
      )
      .notOneOf(
        existingProviders,
        i18next.t(
          'shared.integrations.ssoIntegrations.form.validations.existingProviders'
        )
      ),
    entityId: yup
      .string()
      .trim()
      .required(
        i18next.t(
          'shared.integrations.ssoIntegrations.form.validations.entityId'
        )
      ),
    customFields: yup.array(),
  });
