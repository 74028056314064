import * as yup from 'yup';

import slugify from '../../helpers/formatters/slugify';

export default yup.object().shape({
  _order: yup.number().max(256).min(0).default(0).required(),
  name: yup.string().min(3).trim().required(),
  slug: yup
    .string()
    .trim()
    .min(3)
    .max(55)
    .test(
      'is-slug',
      '${path} is not a valid slug',
      value => value === slugify(value)
    )
    .required(),
  icon: yup.string().max(55).trim().required(),
  iconSet: yup.string().max(55).trim().required(),
  label: yup.string().max(16).trim().required(),
  content: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required('Content is required'),
});
