import React from 'react';
import { CreditCardV2 } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/CreditCardV2';
import { PaymentSource } from 'graphql-query-contracts';
import { CreditCardBrands } from 'lane-shared/domains/billingPayments/types';
import { useTranslation } from 'react-i18next';
import { Button } from 'design-system-web';

import styles from '../../StripePaymentMethod.scss';

interface Props {
  paymentSource: PaymentSource;
  onChangeAutopayMethodClick: () => void;
}

export function SelectedSubscriptionPaymentMethod({
  paymentSource,
  onChangeAutopayMethodClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row justify-between rounded-lg border border-border-subdued p-3">
      <CreditCardV2
        expMonth={paymentSource.expiryMonth}
        expYear={paymentSource.expiryYear}
        last4={paymentSource.last4}
        brand={paymentSource.brand as CreditCardBrands}
        className={styles.card}
      />
      <Button
        variant="text"
        className="h-min self-center"
        onClick={onChangeAutopayMethodClick}
      >
        {t('abp.payment.subscription.paymentMethod.autopayEnabled.change')}
      </Button>
    </div>
  );
}
