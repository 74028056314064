import { StringSchema } from 'yup';

import { ITypeValidatesMax } from '../../properties/validators/ITypeValidatesMax';
import { ITypeValidatesMin } from '../../properties/validators/ITypeValidatesMin';
import { IncludesValidator } from '../../properties/validators/Includes';
import { MaxValidator } from '../../properties/validators/Max';
import { MinValidator } from '../../properties/validators/Min';
import { TypeBase } from './TypeBase';

export abstract class StringTypeBase
  extends TypeBase<StringSchema<any>>
  implements ITypeValidatesMin, ITypeValidatesMax {
  validatesMin = true;

  addMinValidator(validator: MinValidator) {
    this._schema = this.schema.min(
      validator.config?.value as number,
      `${this.getDisplayName()} can be min ${validator.config?.value}.`
    );
  }

  validatesMax = true;

  addMaxValidator(validator: MaxValidator) {
    this._schema = this.schema.max(
      validator.config?.value as number,
      `${this.getDisplayName()} can be max ${validator.config?.value}.`
    );
  }

  validatesIncludes = true;

  addIncludesValidator(validator: IncludesValidator) {
    this._schema = this.schema.matches(
      new RegExp(validator.config?.value as string, 'gi'),
      `${this.getDisplayName()} must include "${validator.config?.value}".`
    );
  }
}
