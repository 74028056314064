import urlJoin from 'url-join';

import { getEssensysBaseUrl } from './getEssensysBaseUrl';

/**
 * Simple helper function to generate a deep link into Essensys for a account url
 * @param accountId
 */
export default function formatEssensysBookingUrl(accountId: string): string {
  return urlJoin(getEssensysBaseUrl(), `/spacecontrol/mod/1/view/${accountId}`);
}
