import React from 'react';

import cx from 'classnames';
import { Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getValidationMessages } from 'lane-shared/helpers';

import Input from 'components/form/Input';
import ErrorMessage from 'components/general/ErrorMessage';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

export type EmailValidation = yup.ObjectSchema<
  yup.Shape<
    object | undefined,
    {
      email: string;
    }
  >,
  object
>;

type Props = {
  email: string;
  onSubmit: () => void;
  onChangeEmail: (email: string) => void;
  validation: EmailValidation | null;
  loading: boolean;
  error: Error | null;
};

export default function ForgotPasswordForm({
  email,
  onSubmit,
  onChangeEmail,
  validation,
  loading,
  error,
}: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        welcomeFormStyles.inputContainer,
        welcomeFormStyles.forgotPasswordInputContainer
      )}
    >
      <Input
        className={welcomeFormStyles.input}
        onChange={onChangeEmail}
        value={email}
        type="email"
        label={t('Enter your email')}
        onSubmit={onSubmit}
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'EmailValidation | null' is not a... Remove this comment to see the full error message
        error={email ? getValidationMessages(validation, 'email') : null}
      />
      <ErrorMessage className={welcomeFormStyles.errorMessage} error={error} />
      <div
        className={cx(
          welcomeFormStyles.menu,
          welcomeFormStyles.buttonContainer
        )}
      >
        <Button
          className={welcomeFormStyles.actionButton}
          disabled={Boolean(validation)}
          variant="primary"
          onClick={onSubmit}
          loading={loading}
          type="submit"
          size="large"
        >
          {t('Reset password')}
        </Button>
      </div>
    </div>
  );
}
