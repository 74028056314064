import { ContentType } from 'lane-shared/types/content/Content';
import { validateVisitorEndTime } from './validateVisitorEndTime';
import { validateVisitorTenant } from './validateVisitorTenant';
import { TFunction } from 'i18next';

export function validateVisitorInteraction(
  content: ContentType,
  interaction: {
    data: {};
    state: {};
    features: {};
  },
  t: TFunction,
  setSubmitAttempted: Function
): boolean {
  const endTimeValid = validateVisitorEndTime(content, interaction);
  const tenantValid = validateVisitorTenant(interaction);
  if (!endTimeValid) {
    window.Toast.show(
      t('web.admin.channel.visitor.passDetails.validationError.futureDateTime'),
      5000
    );
    setSubmitAttempted(false);
    return false;
  }
  if (!tenantValid) {
    window.Toast.show(
      t(
        'web.admin.channel.visitor.passDetails.validationError.missingTenantId'
      ),
      5000
    );
    setSubmitAttempted(false);
    return false;
  }
  return true;
}
