import React from 'react';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import isUserOnline from 'lane-shared/helpers/user/isUserOnline';
import { UserType } from 'lane-shared/types/User';

import CircleListView from './CircleListView';

export type UserCircleListViewProps = {
  user?: Pick<UserType, 'role' | 'profile' | 'name' | 'online' | 'lastSeen'>;
  className?: string;
  style?: React.CSSProperties;
};

export default function UserCircleListView({
  user,
  className,
  style,
}: UserCircleListViewProps) {
  const roleName = user?.role?.name || user?.role?.groupRoleName;
  const isOnline = isUserOnline(user);

  return (
    <CircleListView
      image={imageUrl(user?.profile?.image)}
      logo={imageUrl(user?.profile?.logo)}
      name={getDisplayName(user)}
      highlight={isOnline}
      className={className}
      style={style}
    >
      <div className="text-md font-bold">{getDisplayName(user)}</div>
      {roleName && <div className="text-md font-bold">{roleName}</div>}
      {user?.profile?.email && <div>{user.profile.email}</div>}
    </CircleListView>
  );
}
