import * as yup from 'yup';

import { createShapeFromProperties } from '../../../properties';
import { JsonObject, JsonSerializable } from '../../JSONType';
import { Validator } from '../../Validator';
import {
  IPropertyDependencyRule,
  PropertiesInterface,
  PropertyType,
} from '../Property';

export class PropertyDependencyRule implements JsonSerializable {
  constructor(
    public targetPropertyRef: string,
    public targetProperty: PropertyType | undefined,
    public targetPropertyCondition: Validator,
    public disabled: boolean = false
  ) {}

  static fromJsonData(
    properties: PropertiesInterface,
    ruleData: IPropertyDependencyRule
  ): PropertyDependencyRule {
    return new PropertyDependencyRule(
      ruleData.targetPropertyRef,
      properties[ruleData.targetPropertyRef],
      ruleData.targetPropertyCondition,
      ruleData.disabled
    );
  }

  isValid() {
    return this.targetProperty !== undefined;
  }

  isSatisfied(inputData: JsonObject): boolean {
    if (!this.isValid()) {
      return false;
    }

    if (this.disabled) {
      return false;
    }

    const interactionWithoutNulls = Object.keys(inputData).reduce<JsonObject>(
      (accum, currentKey: string) => {
        if (inputData[currentKey] !== null) {
          accum[currentKey] = inputData[currentKey];
        }
        return accum;
      },
      {} as JsonObject
    );

    const schema = yup.object().shape(
      createShapeFromProperties({
        [this.targetPropertyRef]: {
          ...this.targetProperty!,
          validators: [this.targetPropertyCondition],
        },
      })
    );
    try {
      schema.validateSync(interactionWithoutNulls, { abortEarly: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  serialize(): IPropertyDependencyRule {
    return {
      targetPropertyRef: this.targetPropertyRef,
      targetPropertyCondition: this.targetPropertyCondition,
      disabled: this.disabled,
    };
  }
}
