import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import {
  ICON_SET_FONTAWESOME,
  ICON_SET_FEATHER,
} from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useMediaQuery } from 'lane-shared/hooks';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';

import CardContainer from 'components/cards/CardContainer';
import { H5 } from 'components/typography';

import IconButton from '../general/IconButton';
import ValidationMessage from '../general/ValidationMessage';
import MediaPickerModal from './MediaPickerModal';

import styles from './MediaPickerButton.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  showRemove?: boolean;
  showDownload?: boolean;
  showMedia?: boolean;
  media: DocumentType;
  onMediaSelected: (media: DocumentType | null) => any;
  library?: LibraryType;
  libraries: LibraryType[];
  storageKey?: string;
  errors?: string[] | null;
  newImageMaxWidth?: number;
  newImageMaxHeight?: number;
  emptyText?: string;
  dataCy?: string;
};

export default function MediaPickerButton({
  showRemove = true,
  showDownload = true,
  showMedia = true,
  disabled,
  media,
  onMediaSelected,
  library,
  libraries,
  className,
  storageKey,
  errors,
  newImageMaxWidth,
  newImageMaxHeight,
  emptyText,
  dataCy,
}: Props) {
  const { t } = useTranslation();
  const mediaResult = useMediaQuery({ mediaId: media?._id });
  const [isOpen, setIsOpen] = useState(false);
  const [internalMedia, setMedia] = useState<null | DocumentType>(
    media || null
  );

  function removeMedia(e: any) {
    e.stopPropagation();
    onMediaSelected(null);
    setMedia(null);
  }

  const hasMedia = media?._id && mediaResult?.media;

  const labelText = !emptyText ? t('web.media.mediaPicker.select') : emptyText;

  return (
    <>
      <div
        className={cx(styles.MediaPickerButton, className)}
        data-disabled={disabled}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => setIsOpen(true)}
        data-cy={dataCy}
      >
        <CardContainer
          data-disabled={disabled}
          data-invalid={!!errors}
          size="small"
          className={styles.card}
        >
          {!hasMedia && (
            <>
              <H5 className={styles.noImageText}>{labelText}</H5>
              <Icon
                className={styles.uploadIcon}
                name="upload"
                set={ICON_SET_FEATHER}
              />
            </>
          )}
          {hasMedia && (
            <>
              {showMedia && (
                <div
                  data-cy="media-background-image"
                  className={styles.previewImage}
                  style={{ backgroundImage: `url(${imageUrl(media._id)})` }}
                />
              )}
              <H5 className={styles.fileName}>{mediaResult.media.name}</H5>
              <div className={styles.actionButtons}>
                {showDownload && (
                  <a
                    href={`/api/v5/media/${media._id}`}
                    rel="noreferrer noopener"
                    target="_blank"
                    download={mediaResult.media.name}
                    onClick={e => e.stopPropagation()}
                  >
                    <IconButton
                      dataCy="download-button"
                      className={styles.downloadIconButton}
                      iconSet={ICON_SET_FONTAWESOME}
                      type="far"
                      size="small"
                      icon="arrow-to-bottom"
                    />
                  </a>
                )}
                {showRemove && (
                  <IconButton
                    dataCy="remove-button"
                    className={styles.removeIconButton}
                    iconSet={ICON_SET_FONTAWESOME}
                    type="far"
                    size="small"
                    icon="times"
                    onClick={removeMedia}
                  />
                )}
              </div>
            </>
          )}
        </CardContainer>
      </div>

      <ValidationMessage errors={errors} />

      <MediaPickerModal
        library={library}
        libraries={libraries}
        storageKey={storageKey}
        newImageMaxWidth={newImageMaxWidth}
        newImageMaxHeight={newImageMaxHeight}
        media={media}
        onMediaSelected={onMediaSelected}
        setIsOpen={setIsOpen}
        setMedia={setMedia}
        internalMedia={internalMedia}
        isOpen={isOpen}
      />
    </>
  );
}
