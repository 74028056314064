import React from 'react';
import { useTranslation } from 'react-i18next';

import { Radio, RadioGroup } from 'components';
import { Box, Checkbox, Flex, Input, InputWrapper, M } from 'design-system-web';
import { RecurrenceInterval } from 'graphql-query-contracts';
import { PaymentFeatureProperties } from 'lane-shared/types/features/PaymentFeatureProperties';

import FeatureOptionToggle from '../components/FeatureOptionToggle';

const FREQUENCY_OPTIONS = [
  {
    label: 'abp.subscription.weekly',
    id: RecurrenceInterval.RecurrenceIntervalWeekly,
  },
  {
    label: 'abp.subscription.monthly',
    id: RecurrenceInterval.RecurrenceIntervalMonthly,
  },
  {
    label: 'abp.subscription.yearly',
    id: RecurrenceInterval.RecurrenceIntervalYearly,
  },
];

export function Subscription({
  paymentFeature,
  onFeatureUpdated,
}: {
  paymentFeature: PaymentFeatureProperties;
  onFeatureUpdated: (feature: Partial<PaymentFeatureProperties>) => void;
}) {
  const { t } = useTranslation();

  const subscriptionFields = paymentFeature.subscription ?? {
    frequency: RecurrenceInterval.RecurrenceIntervalMonthly,
    endDateOption: 'never',
    totalCounts: 1,
    anyoneCanCancel: false,
  };

  return (
    <FeatureOptionToggle
      title={t('web.content.features.paymentv2.subscription.title')}
      description={t('web.content.features.paymentv2.subscription.description')}
      isExpanded={Boolean(paymentFeature.subscriptionEnabled)}
      onChange={() => {
        onFeatureUpdated({
          subscriptionEnabled: !paymentFeature.subscriptionEnabled,
          subscription: { ...subscriptionFields },
        });
      }}
      className="border border-border-subdued rounded-lg mb-3 p-3"
      toggleClassName="m-0"
    >
      <InputWrapper label={t('abp.subscription.frequency')} className="pt-3">
        <div className="flex gap-x-2" role="radiogroup">
          <RadioGroup
            key="type"
            name="frequency"
            selected={subscriptionFields.frequency}
            schema={{
              id: 'id',
              text: 'label',
            }}
            items={FREQUENCY_OPTIONS}
            onChange={(value: string) => {
              onFeatureUpdated({
                subscription: {
                  ...subscriptionFields,
                  frequency: value as RecurrenceInterval,
                },
              });
            }}
            className="!min-w-0"
            showBorder
          />
        </div>
      </InputWrapper>
      <Checkbox
        value={subscriptionFields.anyoneCanCancel}
        selected={subscriptionFields.anyoneCanCancel}
        mt={4}
        mb={4}
        name="anyoneCanCancel"
        onChange={value => {
          onFeatureUpdated({
            subscription: {
              ...subscriptionFields,
              anyoneCanCancel: !value,
            },
          });
        }}
        text={t(
          'abp.subscriptions.form.subscriptionDetailsSection.selfCancelSubscription'
        )}
      />
      <InputWrapper label={t('abp.subscription.ends')}>
        <Radio
          dataCy="endDate-never"
          selected={subscriptionFields.endDateOption}
          onChange={value => {
            onFeatureUpdated({
              subscription: {
                ...subscriptionFields,
                endDateOption: value,
                totalCounts: 1,
              },
            });
          }}
          name="endDate"
          value="never"
          text={t('abp.subscription.endDate.never')}
          doTranslate={false}
        />
        <Flex align="center" gap={6}>
          <Radio
            dataCy="endDate-after"
            selected={subscriptionFields.endDateOption}
            onChange={value => {
              onFeatureUpdated({
                subscription: {
                  ...subscriptionFields,
                  endDateOption: value,
                },
              });
            }}
            name="endDate"
            value="after"
            text={t('abp.subscription.endDate.after')}
            doTranslate={false}
          />
          <Box>
            <Input
              type="number"
              dataCy="occurrences"
              onChange={(value: string) =>
                onFeatureUpdated({
                  subscription: {
                    ...subscriptionFields,
                    totalCounts: parseInt(value, 10),
                  },
                })
              }
              fieldName="totalCounts"
              disabled={subscriptionFields.endDateOption !== 'after'}
              value={subscriptionFields.totalCounts}
              min={1}
            />
          </Box>
          <M className="-ml-2">{t('abp.subscription.endDate.occurences')}</M>
        </Flex>
      </InputWrapper>
    </FeatureOptionToggle>
  );
}
