import { MobilePermissionEnum } from '../../../types/MobilePermissionEnum';
import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const SafetrustDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Safetrust,
  hasCustomUI: true,
  friendlyName: 'Safetrust Access Control',
  description: `Defines the properties required for an Safetrust integration.`,
  category: IntegrationCategoryEnum.AccessControl,
  platforms: [PlatformEnum.Mobile],
  type: IntegrationTypeEnum.SDK,
  settings: {},
  properties: {
    applicationUUID: {
      friendlyName: 'Safetrust Application ID',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    sdkUUID: {
      friendlyName: 'Safetrust SDK ID',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    credentialManagerServiceUrl: {
      friendlyName: 'Safetrust Credential Manager Service URL',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    supportEmail: {
      friendlyName: 'Support Email',
      type: 'Email',
    },
    helpPageURL: {
      friendlyName: 'Help page URL',
      type: 'String',
    },
    privacyConfidentialityStatements: {
      friendlyName: 'Privacy and Confidentiality Statements URL',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    dataControllerEmail: {
      friendlyName: 'Data Controller Email',
      type: 'Email',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  mobilePermissions: [MobilePermissionEnum.Bluetooth],
  mobilePermissionsRequest:
    'To unlock doors, Workplace Access requires these device settings to be enabled, including accessing your location when the application is not in use:',
};

export default SafetrustDefinition;
