import React from 'react';

import { OrderSummaryItemType } from 'lane-shared/domains/billingPayments/types';

import { OrderSummaryItem } from './OrderSummaryItem';

interface OrderSummaryItemListProps {
  orderSummaryItems?: OrderSummaryItemType[];
}

/**
 * @deprecated 5.190.0 - use OrderSummaryWithProducts.
 */
export function OrderSummaryItemList({
  orderSummaryItems,
}: OrderSummaryItemListProps) {
  return (
    <>
      {orderSummaryItems?.map(orderItem => (
        <OrderSummaryItem
          key={orderItem.name + Math.random()}
          name={orderItem.name}
          description={orderItem.description}
          quantity={orderItem.quantity}
          dollarAmount={orderItem.dollarAmount}
        />
      ))}
    </>
  );
}
