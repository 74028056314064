export const BurstSection = {
  name: 'Create a Burst',
  icon: 'plus',
  type: 'far',
  route: '/l/post/:contentId/new',
};

// By initial design, the FAQ page is a part of the integration settings, it used to be content.
// But now, it should just be a link to external resources.
// It is dangerous to edit the integration settings minutes before the release, so for now, it is decided just to hide the FAQ link.

// TODO: remove FAQ content ID from integration settings and make it a simple link in a new tab/ external browser.
// const FaqSection = {
//   name: 'FAQ',
//   icon: 'info-circle',
//   type: 'far',
//   route: '/l/post/:contentId',
// };

export const HomeSections = [BurstSection];
