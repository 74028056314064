import { SupportedSchema, TypeBase } from '../../types/baseTypes/TypeBase';
import { InValidator } from './In';

export function addInValidatorImpl<T extends SupportedSchema>(
  type: TypeBase<T>,
  validator: InValidator
) {
  type._schema = type.schema.test(
    'In',
    `${type.getDisplayName()} must be one of the required values.`,
    (value: any) => {
      if ([undefined, null].includes(value)) {
        return true;
      }

      // TODO: https://linear.app/joinlane/issue/LANE-1469/move-type-specific-logic-from-validator-classes-to-respective-base

      const options = validator.config?.value as any[];

      // will need to do some introspection on the value
      const primitiveType = typeof value;

      if (['number', 'string', 'boolean'].includes(primitiveType)) {
        return options.some(option => option === value);
      }

      if (value.value) {
        return options.some(option => option.value === value.value);
      }

      if (value?._id) {
        return options.some(option => option._id === value._id);
      }

      if (value?.constructor === Date) {
        return options.some(option => option.getTime() === value.getTime());
      }

      // todo: once 1469 is implemented there will be more types supported
      return false;
    }
  ) as T;
}
