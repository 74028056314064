import { DateTime } from 'luxon';

import parseDateTime from './parseDateTime';

/**
 * Copies the current time of day from one date to another.
 *
 * @param fromDate the date you are taking the time of day from
 * @param toDate the date you are setting the time of day to
 * @returns {Date} a new date object with the time of fromDate and date of toDate
 */
export default function copyTimeToDate(
  fromDate: Date | DateTime,
  toDate: Date | DateTime
): Date {
  const _fromDate = parseDateTime(fromDate);
  const _toDate = parseDateTime(toDate)!;

  if (_fromDate) {
    return _toDate
      .set({
        hour: _fromDate.hour,
        minute: _fromDate.minute,
        second: _fromDate.second,
      })
      .toJSDate();
  }

  return _toDate?.toJSDate() as Date;
}
