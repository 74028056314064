import * as yup from 'yup';

import './fixMessages';
import validateGeo from './validateGeo';

export default yup.object().shape({
  name: yup.string().trim().nullable(),
  street1: yup.string().trim().required(),
  street2: yup.string().trim().nullable(),
  street3: yup.string().trim().nullable(),
  city: yup.string().trim().required(),
  country: yup.string().trim().required(),
  state: yup.string().trim().nullable(),
  code: yup.string().trim().required(),
  geo: validateGeo.required('geo co-ordinates are required'),
  _order: yup.number().nullable(),
});
