import gql from 'graphql-tag';

export default gql`
  mutation sendInvoicePayment(
    $invoiceId: String!
    $paymentAccountId: UUID!
    $companyId: UUID
    $currency: String!
    $paymentAmount: Float
    $placePayData: JSON
    $stripeData: JSON
  ) {
    essensys {
      sendInvoicePayment(
        invoiceId: $invoiceId
        paymentAccountId: $paymentAccountId
        currency: $currency
        placePayData: $placePayData
        paymentAmount: $paymentAmount
        stripeData: $stripeData
        companyId: $companyId
      ) {
        accountname
        glcode
        paymentdate
        paymenttype
        reference
        allocateto
        amountpaid
        paymentcurrencycode
      }
    }
  }
`;
