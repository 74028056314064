import { H5, S } from 'design-system-web';
import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers';
import {
  AddProductToContentDetails,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { SupportedLocaleEnum } from 'localization';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.scss';

export function AddProductSummary({
  currency,
  locale,
  product,
  inclusivePricing,
}: {
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  product: AddProductToContentDetails;
  inclusivePricing: boolean;
}) {
  const { t } = useTranslation();
  const { subTotal, tax, total } = getAmounts(
    product,
    currency,
    locale,
    inclusivePricing
  );

  return (
    <div className={styles.summaryWrapper}>
      <div className={styles.innerWrapper}>
        <div>
          <S variant="secondary">
            {t(
              `abp.productsServices.productType.${product.productCategoryType}`
            )}
          </S>{' '}
          <S variant="secondary">{`>`}</S>{' '}
          <S variant="secondary">{product.productCategoryName}</S>
        </div>
        <div>
          <H5>{product.name}</H5>
        </div>
        <div className={styles.amounts}>
          <div className={styles.amountRow}>
            <S>
              {t(
                'web.content.features.paymentv2.addProductsPanel.summary.subtotal'
              )}
            </S>
            <S>{subTotal}</S>
          </div>
          <div className={styles.amountRow}>
            <S>
              {t('web.content.features.paymentv2.addProductsPanel.summary.tax')}
              {inclusivePricing &&
                ` ${t('web.content.features.paymentv2.tax.included')}`}
            </S>
            <S>{tax}</S>
          </div>
          <div className={styles.amountRow}>
            <H5>
              {t(
                'web.content.features.paymentv2.addProductsPanel.summary.total',
                {
                  currency,
                }
              )}
            </H5>
            <H5>{total}</H5>
          </div>
          {product.creditAmount && (
            <div className={styles.amountRow}>
              <H5>
                {t(
                  'web.content.features.paymentv2.addProductsPanel.summary.creditTotal'
                )}
              </H5>
              <H5>{product.creditAmount}</H5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function getAmounts(
  product: AddProductToContentDetails,
  currency: SupportedPaymentCurrency,
  locale: SupportedLocaleEnum,
  inclusivePricing: boolean
) {
  const priceComponents = priceInputToComponents(product);
  const { listPrice, grossTax, netPrice, formatPrice } = getPricing({
    currency,
    locale,
    priceComponents,
  });

  let subTotal;
  if (inclusivePricing) {
    subTotal = formatPrice(listPrice + grossTax, 'narrowSymbol');
  } else {
    subTotal = formatPrice(listPrice, 'narrowSymbol');
  }

  const tax = formatPrice(grossTax, 'narrowSymbol');
  const total = formatPrice(netPrice, 'narrowSymbol');

  return { subTotal, tax, total };
}
