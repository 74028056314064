import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import styles from './SkeletonRelatedSpacesCard.scss';

export default function SkeletonRelatedSpacesCard() {
  return (
    <div className={styles.skeletonCardContainer}>
      <Skeleton
        variant="rounded"
        className={styles.skeletonThumbnailContainer}
      />
      <div className={styles.skeletonCardDetailsContainer}>
        <Skeleton variant="rounded" className={styles.skeletonTitleContainer} />
        <Skeleton
          variant="rounded"
          className={styles.skeletonDescriptionContainer}
        />
      </div>
    </div>
  );
}
