import React from 'react';

import { Toggle } from 'components';

import { UserType } from 'lane-shared/types/User';

import styles from 'lane-web/src/components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type props = {
  channel: {
    settings: {
      hasAccessControlEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  t: (key: string, params?: any) => string;
  user: UserType | null;
};

export function AccessControlModuleToggle({
  channel,
  onChannelUpdated,
  t,
  user,
}: props) {
  return (
    <div className={styles.setting}>
      <Toggle
        className={styles.toggle}
        dataCy="toggleAccessControl"
        value={channel.settings.hasAccessControlEnabled || false}
        disabled={!user?.isSuperUser}
        onChange={enabled =>
          onChannelUpdated({
            settings: {
              ...channel.settings,
              hasAccessControlEnabled: enabled,
            },
          })
        }
        text={t('web.admin.channel.profile.settings.accessControl.toggle.text')}
        description={t(
          'web.admin.channel.profile.settings.accessControl.toggle.description'
        )}
      />
    </div>
  );
}
