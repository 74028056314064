import React, { useState, useEffect } from 'react';

import { getAdminClient } from 'lane-shared/apollo';
import {
  updateIntegration,
  createIntegration,
} from 'lane-shared/graphql/mutation';
import { toSchema, castForUpdate } from 'lane-shared/helpers';
import { getIntegrationDefinition } from 'lane-shared/helpers/integrations';
import { useValidation, useUpdatedData } from 'lane-shared/hooks';
import Types from 'lane-shared/properties/Types';
import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { validateCreateIntegration } from 'lane-shared/validation';

import Property from '../builder/properties/input/Property';
import Dropdown from '../form/Dropdown';
import MultiselectField from '../form/MultiselectField';
import Button from '../general/Button';
import ControlMenu from '../general/ControlMenu';
import ErrorMessage from '../general/ErrorMessage';
import Loading from '../general/Loading';
import Tooltip from '../general/Tooltip';
import history from 'helpers/history';

import styles from './IntegrationEdit.scss';

function IntegrationEdit({
  integration,
  dataLoading,
  dataError,
  forCreate,
}: any) {
  const [_integration, setIntegration] = useUpdatedData(null);
  const [loading, setLoading] = useState(false);
  const [validation, validate] = useValidation(
    _integration,
    validateCreateIntegration
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    if (integration) {
      setIntegration(castForUpdate(integration));
    }
  }, [integration]);

  if (dataError) {
    return <ErrorMessage error={dataError} />;
  }

  if (!_integration || dataLoading) {
    return <Loading />;
  }

  const definition = (_integration as any).name
    ? getIntegrationDefinition((_integration as any).name)
    : null;

  function changeIntegration(name: any) {
    const definition = getIntegrationDefinition(name);
    const settings = {};

    Object.entries(definition.settings).forEach(
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ([key, value]) => (settings[key] = value.default)
    );

    const integration = {
      category: definition.category,
      type: definition.type,
      platforms: [...definition.platforms],
      properties: { ...definition.properties },
      workflows: {},
      name,
      settings,
    };

    setIntegration(integration);
  }

  async function onSave() {
    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    if (!validate(_integration)) {
      return;
    }

    if (forCreate) {
      try {
        setLoading(true);
        setError(null);
        const { data } = await getAdminClient().mutate({
          mutation: createIntegration,
          variables: {
            integration: _integration,
          },
        });

        window.Toast.show(`${(_integration as any).name} created.`);
        history.push(`${data.createIntegration._id}/edit`);
      } catch (err) {
        setError(err);
      }
    } else {
      try {
        setLoading(true);
        setError(null);
        await getAdminClient().mutate({
          mutation: updateIntegration,
          variables: {
            integration: _integration,
          },
        });

        window.Toast.show(`${(_integration as any).name} updated.`);
      } catch (err) {
        setError(err);
      }
    }

    setLoading(false);
  }

  return (
    <div className={styles.IntegrationEdit}>
      <ControlMenu>
        <Button loading={loading} onClick={onSave} variant="contained">
          <Button
            loading={loading}
            onClick={onSave}
            variant="contained"
            dataCy="CreateNewIntegration"
          />
          {forCreate ? 'Create' : 'Save'}
        </Button>
      </ControlMenu>

      <ErrorMessage error={error} />

      <ErrorMessage error={validation} />

      <section className={styles.edit}>
        {forCreate ? (
          <Dropdown
            className={styles.dropDown}
            placeholder="Select Integration Provider"
            value={(_integration as any).name}
            items={Object.values(IntegrationProviderEnum).map(toSchema)}
            onValueChange={name => changeIntegration(name)}
          />
        ) : (
          <h1>{(_integration as any).name}</h1>
        )}

        {(_integration as any).name && (
          <>
            <h2>
              <span>Category</span>
              <strong>{(_integration as any).category}</strong>
            </h2>

            <h2>
              <span>Type</span>
              <strong>{(_integration as any).type}</strong>
            </h2>

            <h2>Platforms</h2>
            <MultiselectField
              placeholder="Platforms"
              value={(_integration as any).platforms.map(toSchema)}
              items={Object.values(PlatformEnum).map(toSchema)}
              onChange={values =>
                setIntegration({
                  platforms: values.map(value => value.value),
                })
              }
            />
            <h2>
              <span>Integration Settings</span>
            </h2>
            <ul>
              {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
              {Object.entries(definition.settings).map(([key, property]) => (
                <div key={key} className={styles.property}>
                  {property.description ? (
                    <Tooltip TooltipComponent={property.description}>
                      <label>{property.friendlyName || key}</label>
                    </Tooltip>
                  ) : (
                    <label>{property.friendlyName || key}</label>
                  )}
                  {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ property: PropertyType<PropertyOptionType>... Remove this comment to see the full error message */}
                  <Property
                    property={property}
                    value={(_integration as any).settings[key]}
                    onChange={value =>
                      setIntegration({
                        settings: {
                          ...(_integration as any).settings,
                          [key]: value,
                        },
                      })
                    }
                  />
                </div>
              ))}
            </ul>

            <h2>Channel Integration Properties</h2>
            <ul className={styles.properties}>
              {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
              {Object.entries(definition.properties).map(([key, value]) => (
                <li key={key}>
                  <strong>{Types.getType(value.type).friendlyName}</strong>
                  <span>{value.friendlyName || key}</span>
                </li>
              ))}
            </ul>
          </>
        )}
      </section>
    </div>
  );
}

export default IntegrationEdit;
