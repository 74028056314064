const FRIENDLY_PROPERTY_TOOLTIPS = {
  multiSelect:
    'Select Multiple Users determines if the end user can select more than one user or not.',
  dependency: 'This input is only visible if the following condition is met.',
  preview: 'This is an example of what your input field will look like.',

  // General
  name: 'The name of your input field.',
  placeholder:
    'Placeholder text for your end users to understand what to enter.',
  placeholderSelectUser:
    'Placeholder text for your end users to understand who to select.',

  // Validation
  validation:
    'Validation can be used to make sure your end users fill out the information properly.',
  requiredValidator: 'Requires the end user to enter a value into this field.',

  minValidator: 'What is the minimum value that the user is allowed to enter?',
  maxValidator: 'What is the maximum value that the user is allowed to enter?',
  arrayMinValidator:
    'What is the minimum number of options that the user is allowed to select?',
  arrayMaxValidator:
    'What is the maximum number of options the user is allowed to select?',

  // Filters
  groupRoles:
    'Included Users determines if all Users or a specific team will be included in the Data Field input.',

  // Options
  options:
    'Setup what options your input has here.  You can re-order by dragging and dropping.',
  optionPropertyDependency: 'No workflows attached',

  // Security
  security:
    'Security can be used to determine if end users can update data or not.',
};

const FRIENDLY_PROPERTY_LABELS = {
  multiSelect: 'web.admin.content.friendlyProperty.labels.multiselect',
  dependency: 'web.admin.content.friendlyProperty.labels.dependency',
  preview: 'web.admin.content.friendlyProperty.labels.preview',
  // General
  inputType: 'web.admin.content.friendlyProperty.labels.inputType',
  name: 'web.admin.content.friendlyProperty.labels.name',
  placeholder: 'web.admin.content.friendlyProperty.labels.placeholder',
  // Validation
  validation: 'web.admin.content.friendlyProperty.labels.validation',
  requiredValidator:
    'web.admin.content.friendlyProperty.labels.requiredValidator',
  minValidator: 'web.admin.content.friendlyProperty.labels.minValidator',
  maxValidator: 'web.admin.content.friendlyProperty.labels.maxValidator',
  arrayMinValidator:
    'web.admin.content.friendlyProperty.labels.arrayMinValidator',
  arrayMaxValidator:
    'web.admin.content.friendlyProperty.labels.arrayMaxValidator',
  // Filters
  groupRoles: 'web.admin.content.friendlyProperty.labels.groupRoles',

  // Options
  options: 'web.admin.content.friendlyProperty.labels.options',
  optionLabel: 'web.admin.content.friendlyProperty.labels.optionLabel',
  optionValue: 'web.admin.content.friendlyProperty.labels.optionValue',
  optionPropertyDependency:
    'web.admin.content.friendlyProperty.labels.optionPropertyDependency',

  // Security
  security: 'web.admin.content.friendlyProperty.labels.security',
  canUserInput: 'web.admin.content.friendlyProperty.labels.canUserInput',
  canTeamEdit: 'web.admin.content.friendlyProperty.labels.canTeamEdit',
};

const FRIENDLY_PROPERTY_PLACEHOLDERS = {
  // General
  name: 'web.admin.content.friendlyProperty.placeholder.name',
  placeholder: 'web.admin.content.friendlyProperty.placeholder.placeholder',

  // Validation
  minValidator: 'web.admin.content.friendlyProperty.placeholder.minValidator',
  maxValidator: 'web.admin.content.friendlyProperty.placeholder.maxValidator',

  // Options
  optionLabel: 'web.admin.content.friendlyProperty.placeholder.optionLabel',
  optionValue: 'web.admin.content.friendlyProperty.placeholder.optionValue',
};

export const FRIENDLY_PROPERTY_TEXTS = {
  label: FRIENDLY_PROPERTY_LABELS,
  tooltip: FRIENDLY_PROPERTY_TOOLTIPS,
  placeholder: FRIENDLY_PROPERTY_PLACEHOLDERS,
};
