import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import Typography from 'components/lds/Typography';

import styles from './ReceiptHeader.scss';

type Props = {
  channelName: string;
  confirmationNumber: string;
  email?: string;
  date?: string;
  className?: string;
};

export default function ReceiptHeader({
  channelName,
  confirmationNumber,
  email,
  className,
  date,
}: Props) {
  const { t } = useTranslation();
  const formattedDate = date?.replace('-', 'at');
  return (
    <div className={className}>
      <div className={styles.receiptHeader}>
        <Icon
          set={ICON_SET_FONTAWESOME}
          name="checkCircle"
          className={styles.receiptCheckmark}
        />
        <div style={{ marginBottom: '1em' }}>
          <Typography type="m" mode="primary">
            {t('Thank you for your purchase at {{- channelName}}', {
              channelName,
            })}
          </Typography>
          <Typography type="m" mode="primary">
            {t('Your confirmation number is #{{confirmationNumber}}', {
              confirmationNumber,
            })}
          </Typography>
        </div>
        {date && (
          <Typography type="s" mode="primary">
            {formattedDate}
          </Typography>
        )}
        {email && (
          <Typography type="s" mode="primary">
            {t('An email of this receipt has been sent to {{- email}}', {
              email,
            })}
          </Typography>
        )}
      </div>
    </div>
  );
}
