import React from 'react';

import { routes } from 'lane-shared/config';
import { PaymentDetailsType } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';
import {
  EssensysPaymentSettingsType,
  PaymentFeatureQuoteType,
} from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';

import EssensysPaymentMethodSelect from 'components/payments/EssensysPaymentMethodSelect';

import history from '../../../../helpers/history';

type PaymentMethodContentType = {
  essensysPaymentSettings: EssensysPaymentSettingsType | undefined;
  essensysError: Error | undefined;
  paymentType: PaymentTypeEnum;
  quote: PaymentFeatureQuoteType | undefined;
  selectedCard: any;
  paymentDetails: PaymentDetailsType | undefined;
  paymentAccounts: any;
  selectedPaymentAccount: PaymentAccountType | null;
  setSelectedPaymentAccount: (...args: any) => void;
  setSelectedCard: (...args: any) => void;
  onClose: () => void;
};

export default function PaymentMethodContent({
  essensysPaymentSettings,
  essensysError,
  paymentType,
  quote,
  selectedCard,
  paymentDetails,
  paymentAccounts,
  selectedPaymentAccount,
  setSelectedPaymentAccount,
  setSelectedCard,
  onClose,
}: PaymentMethodContentType) {
  if (essensysPaymentSettings?.isCredits) return null;

  return (
    <EssensysPaymentMethodSelect
      paymentType={paymentType}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'EssensysPaymentSettingsType | null | undefin... Remove this comment to see the full error message
      essensysInfo={quote?.data}
      essensysError={essensysError}
      selectedCard={selectedCard}
      paymentDetails={paymentDetails}
      paymentAccounts={paymentAccounts}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentAccountType | null' is not assignable... Remove this comment to see the full error message
      selectedPaymentAccount={selectedPaymentAccount}
      onPaymentAccountSelected={paymentAccount =>
        setSelectedPaymentAccount(paymentAccount)
      }
      onCardSelected={card => setSelectedCard(card)}
      onSetupPayment={() => {
        onClose();
        history.push(routes.meWallet);
      }}
    />
  );
}
