import { LONG_DATE_TIME } from '../constants/dates';
import dateFormatter from './dateFormatter';

export default function longDate(
  date: Date | string,
  timeZone?: string,
  locale?: string
): string {
  return dateFormatter(date, LONG_DATE_TIME, timeZone, locale);
}
