import { PaymentFeaturePaymentAmountType } from 'lane-shared/types/features/PaymentFeatureProperties';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';

/*
 * Given a paymentAmount, check if item is “free of charge”
 *
 * This only applies to:
 *
 *  - Items with amount type of “Cash”
 *  - Items have an amount of zero (0)
 *
 * * */
export default function isFreeOfCharge(
  paymentAmount: PaymentFeaturePaymentAmountType
): boolean {
  return (
    paymentAmount.type === PaymentTypeEnum.Cash && paymentAmount.amount === 0
  );
}
