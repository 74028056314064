import { DateTime, Interval } from 'luxon';
import { DateSpanApi } from '@fullcalendar/core';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

export function calendarSelectAllow(selectInfo: DateSpanApi) {
  const { start, end } = selectInfo;
  const selectedRange = Interval.fromDateTimes(
    DateTime.fromJSDate(start),
    DateTime.fromJSDate(end)
  );
  const extendedProps = selectInfo.resource?.extendedProps;

  if (
    !extendedProps?.unavailableRanges ||
    !extendedProps.unavailableRanges.length
  ) {
    return true;
  }

  const unavailableRanges = extendedProps.unavailableRanges;
  const isOverlapping = unavailableRanges.some(
    ({ interval }: { interval: DateRangeType }) => {
      if (!interval.startDate || !interval.endDate) {
        return false;
      }

      const startDate = DateTime.fromJSDate(interval.startDate);
      const endDate = DateTime.fromJSDate(interval.endDate);
      const range = Interval.fromDateTimes(startDate, endDate);

      return range.overlaps(selectedRange);
    }
  );

  return !isOverlapping;
}
