import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { getFriendlyDate } from 'lane-shared/helpers/content';
import {
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content/subtitleHelpers';

import TextCard from './TextCard';
import useUserLocale from 'hooks/useUserLocale';

function NoticeCard({ className, style, notice }: any) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  let subtitle;

  if (isSubtitleDate(notice?.subtitle) && notice?.startDate) {
    subtitle = getFriendlyDate({ content: notice, translator: t, locale });
  }

  if (isSubtitleCustom(notice?.subtitle)) {
    subtitle = notice?.subtitle;
  }
  return (
    <div>
      <TextCard
        className={className}
        style={style}
        backgroundColor={notice?.backgroundColor}
        color={notice?.color}
        header={notice?.name}
        subHeader={subtitle}
        text={notice?.description}
        startDate={notice?.startDate}
        endDate={notice?.endDate}
      />
    </div>
  );
}

export default memo(NoticeCard);
