import React from 'react';

import { FeatureInstance } from 'lane-shared/types/FeatureInstance';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

import { ErrorMessage } from 'components/general';
import { AlertType } from 'components/lds';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';
import { EntriesFeatureProperties } from 'lane-shared/types/features/EntriesFeatureProperties';

const AlertMessagesRenderer = (
  featureName: `${FeatureNameEnum}` | FeatureNameEnum,
  features: FeatureInstance[],
  t: (v: string, opts?: any) => string
) => {
  if (
    featureName === FeatureNameEnum.Cancelable &&
    features.find(f => f.type === FeatureNameEnum.Statuses)
  ) {
    return (
      <ErrorMessage
        error={t(
          'Statuses are also turned on, this will effect the Cancelling feature. To allow for cancelling, please ensure the ‘Cancelled’ status is selected for all required status flows.'
        )}
        color={AlertType.warning}
      />
    );
  }

  if (
    featureName === FeatureNameEnum.Statuses &&
    features.find(
      (f: { type: FeatureNameEnum }) => f.type === FeatureNameEnum.Cancelable
    )
  ) {
    return (
      <ErrorMessage
        error={t(
          'Cancelling is also turned on. To allow for cancelling to function, please ensure the ‘Cancelled’ status is selected for all required status flows.'
        )}
        color={AlertType.warning}
      />
    );
  }

  if (featureName === FeatureNameEnum.Entries) {
    const entries = features.find(
      f => f.type === FeatureNameEnum.Entries
    ) as FeatureInstance<EntriesFeatureProperties>;
    const hasAnonymousAllowed = entries?.feature?.allowAnonymous;
    const reservable = features.find(
      f => f.type === FeatureNameEnum.Reservable
    ) as FeatureInstance<ReservableFeatureProperties>;
    const quantity = features.find(
      f => f.type === FeatureNameEnum.Quantity
    ) as FeatureInstance<ReservableFeatureProperties>;
    const hasWaitlistEnabled = reservable?.feature?.useWaitlist;
    const hasQuantityWaitlistEnabled = quantity?.feature?.useWaitlist;

    if (
      hasAnonymousAllowed &&
      (hasWaitlistEnabled || hasQuantityWaitlistEnabled)
    ) {
      return (
        <ErrorMessage
          error={t('web.admin.content.feature.entries.warning.waitlist', {
            feature: hasQuantityWaitlistEnabled
              ? FeatureNameEnum.Quantity.toLowerCase()
              : FeatureNameEnum.Reservable.toLowerCase(),
          })}
          color={AlertType.warning}
        />
      );
    }
  }

  return <div />;
};

export default AlertMessagesRenderer;
