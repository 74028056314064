import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import '../../../../properties/baseTypes/Percentage';
import '../../../../properties/baseTypes/Time';
import TimeRange from '../../../../properties/baseTypes/TimeRange';
// need to make sure these guys are loaded.
import '../../../../properties/baseTypes/UUID';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';

const properties: PropertiesInterface = {
  startTime: {
    type: 'Time',
    validators: [
      {
        name: 'Required',
        value: 'true',
      },
      {
        name: 'LessThan',
        value: 'endTime',
      },
    ],
  },
  endTime: {
    type: 'Time',
    validators: [
      {
        name: 'Required',
        value: 'true',
      },
      {
        name: 'GreaterThan',
        value: 'startTime',
      },
    ],
  },
  priceRatio: {
    type: 'Percentage',
    validators: [
      {
        name: 'Required',
        value: 'true',
      },
    ],
  },
};

export class PriceTimeRangeType extends ObjectTypeBase {
  name = 'PriceTimeRange';

  get example() {
    return {
      ...TimeRange.default,
      _id: uuid(),
      priceRatio: 100,
    };
  }

  get default(): any {
    return {
      ...TimeRange.default,
      _id: uuid(),
      priceRatio: 100,
    };
  }

  contexts = [];

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new PriceTimeRangeType();

Types.addType(definition);
export default definition;
