import { useMemo } from 'react';

import { getVisibleContentData } from 'lane-shared/helpers/content';
import {
  explodeFeatures,
  isFeatureVisible,
} from 'lane-shared/helpers/features';
import { ContentType } from 'lane-shared/types/content/Content';

export default function useVisibleAndSanitizedFeatures(
  content?: ContentType | null
) {
  return useMemo(() => {
    const visibleContentData = getVisibleContentData(content);
    const filteredFeatures = visibleContentData
      ? content?.features?.filter(({ type }) =>
          isFeatureVisible(visibleContentData?.features || [], type)
        )
      : content?.features;
    return explodeFeatures(filteredFeatures);
  }, [content]);
}
