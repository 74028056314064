import i18next from 'i18next';
import * as yup from 'yup';

export function validateTag(
  translator: (key: string, params?: any) => string = i18next.t
) {
  const TAG_NAME_MIN_CHARS = translator(
    'web.pages.portal.admin.library.tags.create.nameLengthError'
  );

  const TAG_NAME_MAX_CHARS = translator(
    'web.pages.portal.admin.library.tags.create.nameMaxLengthError'
  );

  const tagNameValidator = yup
    .string()
    .min(1, TAG_NAME_MIN_CHARS)
    .max(60, TAG_NAME_MAX_CHARS)
    .trim();

  return { tagNameValidator };
}
