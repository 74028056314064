import { useEffect, useMemo } from 'react';

import { useLazyQuery, ApolloError } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { GroupRole } from 'lane-shared/types/GroupRole';

import {
  getChannelGroupRoles,
  getChannelGroupRolesWithCounts,
} from '../graphql/query';
import { SPECIAL_GROUP_ROLES } from '../helpers/constants/channel';
import { byName } from '../helpers/sort';

type Params = {
  channelId?: string | null;
  includeWorkplaceMember?: boolean;
  includeCounts?: boolean;
};

type HookReturn = {
  groupRoles: GroupRole[];
  loading: boolean;
  error: ApolloError | undefined;
};

export default function useChannelGroupRolesQuery({
  channelId,
  includeWorkplaceMember,
  includeCounts,
}: Params): HookReturn {
  const [fetchGroupRole, { data, loading, error }] = useLazyQuery(
    includeCounts ? getChannelGroupRolesWithCounts : getChannelGroupRoles,
    {
      variables: {
        id: channelId,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  let groupRoles = useMemo(() => cloneDeep(data?.channel?.groupRoles || []), [
    data?.channel?.groupRoles,
  ]);
  groupRoles.sort(byName);

  if (!includeWorkplaceMember) {
    groupRoles = groupRoles.filter(
      (role: any) => role.name !== SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
    );
  }

  if (includeCounts) {
    const counts = data?.channelGroupRolesUserCount || [];

    groupRoles.forEach((groupRole: any) => {
      groupRole.stats = counts.find(
        (count: any) => groupRole._id === count._id
      );
    });
  }

  useEffect(() => {
    if (channelId) {
      fetchGroupRole({
        variables: {
          id: channelId,
        },
      });
    }
  }, [channelId]);

  if (!channelId) {
    groupRoles = [];
  }

  return {
    groupRoles,
    loading,
    error,
  } as const;
}
