import gql from 'graphql-tag';

export const deactivateRelayModuleMutation = gql`
  mutation DeactivateRelayModule($input: RelayAction!) {
    deactivateRelayModule(input: $input) {
      id
      buildingId
      status
      name
      lastSeen
    }
  }
`;

export const activateRelayModuleMutation = gql`
  mutation ActivateRelayModule($input: RelayAction!) {
    activateRelayModule(input: $input) {
      id
      buildingId
      status
      name
      lastSeen
    }
  }
`;
