import { useMemo } from 'react';

import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';

import { NotImplementedError } from 'activate-errors';
import { PaymentProviderEnum } from '../../types/payment/PaymentProviderEnum';
import IPaymentCollection from './IPaymentCollection';
import useCollectPlacePayPayment from './useCollectPlacePayPayment';

type CollectPaymentProps = {
  paymentAccount?: PaymentAccountType;
  paymentAccounts?: PaymentAccountType[];
  collectPaymentOptions?: {
    onPaymentStart?: (type: PaymentProviderEnum) => void;
  };
};

const defaultPaymentCollection: IPaymentCollection = {
  async getPayment() {
    throw new NotImplementedError();
  },
  handlePaymentSuccess() {
    throw new NotImplementedError();
  },
  handlePaymentFail() {
    throw new NotImplementedError();
  },
};

export default function useCollectPayment({
  paymentAccount,
  paymentAccounts,
  collectPaymentOptions: { onPaymentStart } = {},
}: CollectPaymentProps): { [key in PaymentProviderEnum]?: IPaymentCollection } {
  if (paymentAccount && paymentAccounts) {
    throw new Error(
      'Only one of paymentAccount or PaymentAccounts can be used.'
    );
  }

  const collectPlacePayPayment = useCollectPlacePayPayment({
    onStart: onPaymentStart,
  });

  const paymentCollectionInterfaces: {
    [key in PaymentProviderEnum]?: IPaymentCollection;
  } = { [PaymentProviderEnum.PlacePay]: collectPlacePayPayment };

  return useMemo(() => {
    if (!paymentAccounts && !paymentAccount) {
      return {};
    }

    return (paymentAccounts ?? [paymentAccount!]).reduce((acc, pa) => {
      return {
        ...acc,
        [pa.type]:
          paymentCollectionInterfaces[pa.type] ?? defaultPaymentCollection,
      };
    }, {});
  }, [paymentAccounts, paymentAccount]);
}
