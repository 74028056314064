import { DateTime } from 'luxon';

import parseDateTime from '../dates/parseDateTime';

type DateSortableType = {
  startDate: string | Date | DateTime | null;
  endDate: string | Date | DateTime | null;
};

export default function sortByStartAndEndDate(
  a: DateSortableType,
  b: DateSortableType
): number {
  const aEnd = parseDateTime(a.endDate)?.toMillis() || 0;
  const bEnd = parseDateTime(b.endDate)?.toMillis() || 0;
  const aStart = parseDateTime(a.startDate)?.toMillis() || 0;
  const bStart = parseDateTime(b.startDate)?.toMillis() || 0;

  if (aEnd !== bEnd) {
    return bEnd - aEnd;
  }

  if (aStart !== bStart) {
    return aStart - bStart;
  }

  return 0;
}
