import React from 'react';

import { useTranslation } from 'react-i18next';

import { useEssensysUserMetadata } from 'lane-shared/hooks';

import { Loading } from 'components/general';
import { Alert, AlertType } from 'components/lds';
import { M } from 'components/typography';

import InvoicePayment from './InvoicePayment';

import styles from './Home.scss';

export default function Home() {
  const { t } = useTranslation();
  const { loading, accounts, userLoginsError } = useEssensysUserMetadata();

  if (loading) {
    return <Loading />;
  }

  if (userLoginsError) {
    return (
      <div className={styles.invoiceContainer}>
        <Alert type={AlertType.error} fullWidth>
          <M>
            {userLoginsError?.message ||
              new Error('Accounts failed to load.').message}
          </M>
        </Alert>
      </div>
    );
  }

  if (!accounts) {
    return (
      <div className={styles.invoiceContainer}>
        <Alert type={AlertType.warning} fullWidth>
          <M>{t("You don't have an Operate account")}</M>
        </Alert>
      </div>
    );
  }

  if (accounts.length === 0) {
    return (
      <div className={styles.invoiceContainer}>
        <div>{t('You do not administer any accounts.')}</div>
      </div>
    );
  }

  return (
    <div className={styles.invoiceContainer}>
      {accounts.map(account => (
        <InvoicePayment key={account._id} account={account} />
      ))}
    </div>
  );
}
