import React from 'react';

import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import colors from '../colors';

type OwnProps = {
  height?: string | number;
  width?: string | number;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  stacked?: boolean;
  data: any[];
  areas: any[];
  xAxis?: any;
  yAxis?: any;
  legend?: 'top' | 'middle' | 'bottom';
  tooltipProps?: any;
  legendProps?: any;
  areaProps?: any;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof LineGraphArea.defaultProps;

function LineGraphArea({
  width,
  height,
  margin,
  data,
  areas,
  xAxis,
  yAxis,
  legend,
  tooltipProps,
  legendProps,
  stacked,
  areaProps,
}: Props) {
  const isStack = stacked ? { stackId: '1' } : {};

  return (
    <ResponsiveContainer height={height} width={width}>
      <AreaChart data={data} margin={margin}>
        <defs>
          {areas.map((a, i) => (
            <linearGradient
              key={a.dataKey}
              id={`area_color_${a.dataKey}_${stacked}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="0%"
                stopColor={colors[i].start || colors[i].base}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={colors[i].stop || colors[i].base}
                stopOpacity={stacked ? 0.3 : 0.1}
              />
            </linearGradient>
          ))}
        </defs>
        <XAxis {...xAxis} />
        <YAxis {...yAxis} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip {...tooltipProps} />
        {legend && (
          <Legend verticalAlign={legend} height={36} {...legendProps} />
        )}
        {areas.map((a, i) => (
          <Area
            key={a.dataKey}
            type="monotone"
            dataKey={a.dataKey}
            name={a.label}
            stroke={colors[i].base}
            fillOpacity={1}
            fill={`url(#area_color_${a.dataKey}_${stacked})`}
            strokeWidth={2}
            {...{ ...isStack, ...areaProps }}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}

LineGraphArea.defaultProps = {
  height: 400,
  width: '100%',
  stacked: false,
  margin: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  xAxis: {},
  yAxis: {},
  tooltipProps: {},
  legendProps: {},
  legend: undefined,
  areaProps: {},
};

export default React.memo(LineGraphArea);
