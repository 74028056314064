import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './OrderSummaryTitle.scss';

export function OrderSummaryTitle() {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.orderSummaryTitleContainer)}>
      <h3 className={cx(styles.orderSummaryTitle)}>
        {t('abp.payment.orderSummary')}
      </h3>
    </div>
  );
}
