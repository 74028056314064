import { LaneType, LaneFeatureType } from 'common-types';
import { AddressType } from './AddressType';
import { ChannelSettingsType } from './ChannelType';
import { FeatureInstance } from './FeatureInstance';

// we need to put some meta-data on booking created through lane so
// we can ignore them during webhooks
export const ESSENSYS_BOOKING_TAG = 'Reservation from Lane';

type EssensysSettingsType = {
  locationId?: string;
  accountId?: string;
  accountLocationId?: string;
  _Essensys?: EssensysLocationType | EssensysAccountType;
};

export type EssensysChannelIntegrationType = {
  _id: LaneType.UUID;
  channelUpdated: Date;
  channelUpdatedById: LaneType.UUID;
  channelId: LaneType.UUID;
  channelSlug: string;
  address: AddressType;
  profile: {
    _id: LaneType.UUID;
    name: string;
    themeId: LaneType.UUID;
  };
  channelSettings: ChannelSettingsType;
  integrationId: LaneType.UUID;
  parentId: LaneType.UUID;
  whitelabels: {
    _id: LaneType.UUID;
    channelId: string;
    whitelabelId: string;
  }[];
  settings: EssensysSettingsType;
};

export type EssensysBookingInteractionType = {
  _id: string;
  userId: string;
  channelId: string;
  startDate: string;
  endDate: string;
  features: any | null;
  contentData: any | null;
};

export const ORG_PAYEE_ID_NOT_FOUND =
  "Organization's `orgpayeeid` cannot be found, please contact support.";

export type EssensysTimezoneType = {
  createdby: string; // "admin",
  creationdate: string; // "2016-03-03T11:42:50+00:00",
  timezoneid: string; //  "35",
  timezonename: string; // "(UTC) Dublin, Edinburgh, Lisbon, London",
  timezneoffset: string; // "0",
  updatedate: string; // "2016-03-03T11:42:50+00:00",
  updatedby: string; // "admin",
};

export type EssensysOrganizationType = {
  errors?: string[];
  aba: string | null; //  null;
  outputvalue_aba: string | null; //  null;
  accountsexportaccounts: string; //  'false';
  outputvalue_accountsexportaccounts: string; //  'false';
  accountsexportcashandjournals: string; //  'false';
  outputvalue_accountsexportcashandjournals: string; //  'false';
  accountsexportcostcentres: string; //  'false';
  outputvalue_accountsexportcostcentres: string; //  'false';
  accountsexportdepartments: string; //  'false';
  outputvalue_accountsexportdepartments: string; //  'false';
  accountsexportglcodes: string; //  'false';
  outputvalue_accountsexportglcodes: string; //  'false';
  accountsexportinvoicesandcreditnotes: string; //  'true';
  outputvalue_accountsexportinvoicesandcreditnotes: string; //  'true';
  achaccountname: string | null; //  null;
  outputvalue_achaccountname: string | null; //  null;
  achaccountno: string | null; //  null;
  outputvalue_achaccountno: string | null; //  null;
  achbankaddress: string | null; //  null;
  outputvalue_achbankaddress: string | null; //  null;
  achbankname: string | null; //  null;
  outputvalue_achbankname: string | null; //  null;
  addressline1: string; //  '600 Fifth Avenue';
  outputvalue_addressline1: string; //  '600 Fifth Avenue';
  addressline2: string; //  '2nd Floor';
  outputvalue_addressline2: string; //  '2nd Floor';
  addressline3: string | null; //  null;
  outputvalue_addressline3: string | null; //  null;
  avalaraclientprofileid: string; //  '1';
  outputvalue_avalaraclientprofileid: string; //  '1';
  avalaraenabled: string; //  'false';
  outputvalue_avalaraenabled: string; //  'false';
  accountname: string; //  'Rock CW, L.L.C. ';
  outputvalue_accountname: string; //  'Rock CW, L.L.C. ';
  accountno: string; //  '300998835';
  outputvalue_accountno: string; //  '300998835';
  bankaddress: string | null; //  null;
  outputvalue_bankaddress: string | null; //  null;
  bankname: string; //  'JP Morgan Chase';
  outputvalue_bankname: string; //  'JP Morgan Chase';
  sortcode: string | null; //  null;
  outputvalue_sortcode: string | null; //  null;
  companyno: string | null; //  null;
  outputvalue_companyno: string | null; //  null;
  lglcountry: string; //  'USA';
  outputvalue_lglcountry: string; //  'USA';
  lglcounty: string; //  'NY';
  outputvalue_lglcounty: string; //  'NY';
  createdby: string; //  'live';
  outputvalue_createdby: string; //  'live';
  creationdate: string; //  '2018-07-19T09:45:08-05:00';
  outputvalue_creationdate: string; //  '2018-07-19T09:45:08-05:00';
  ddfifthdigit: string | null; //  null;
  outputvalue_ddfifthdigit: string | null; //  null;
  ddfirstdigit: string | null; //  null;
  outputvalue_ddfirstdigit: string | null; //  null;
  ddforthdigit: string | null; //  null;
  outputvalue_ddforthdigit: string | null; //  null;
  ddoriginator1: string | null; //  null;
  outputvalue_ddoriginator1: string | null; //  null;
  ddoriginator2: string | null; //  null;
  outputvalue_ddoriginator2: string | null; //  null;
  ddoriginator3: string | null; //  null;
  outputvalue_ddoriginator3: string | null; //  null;
  ddseconddigit: string | null; //  null;
  outputvalue_ddseconddigit: string | null; //  null;
  ddsixthdigit: string | null; //  null;
  outputvalue_ddsixthdigit: string | null; //  null;
  ddthirddigit: string | null; //  null;
  outputvalue_ddthirddigit: string | null; //  null;
  disableamex: string; //  'false';
  outputvalue_disableamex: string; //  'false';
  earliestinvoicedate: string; //  '2018-10-31T00:00:00-05:00';
  outputvalue_earliestinvoicedate: string; //  '2018-10-31T00:00:00-05:00';
  fulladdress: string; //  '600 Fifth Avenue<br />2nd Floor<br />New York<br />NY<br />10111<br />USA';
  outputvalue_fulladdress: string; //  '600 Fifth Avenue<br />2nd Floor<br />New York<br />NY<br />10111<br />USA';
  iban: string | null; //  null;
  outputvalue_iban: string | null; //  null;
  logourl: string; //  '/spacecontrol/v2/controls/getobjectimage.aspx?operator=TishmanSpeyer_Test&moduleid=199&fieldid=25256&itemid=5';
  output_logourl: string; //  '/spacecontrol/v2/controls/getobjectimage.aspx?operator=TishmanSpeyer_Test&moduleid=199&fieldid=25256&itemid=5';
  legalentitycode: string | null; //  null;
  outputvalue_legalentitycode: string | null; //  null;
  organizationname: string; //  'Rock CW L.L.C.';
  outputvalue_organizationname: string; //  'Rock CW L.L.C.';
  organizationid: string; //  '5';
  outputvalue_organizationid: string; //  '5';
  paymentprocessor: PaymentProcessorEnum; //  'PlacePay';
  outputvalue_paymentprocessor: string; //  'PlacePay';
  lglpostcode: string; //  '10111';
  outputvalue_lglpostcode: string; //  '10111';
  processorcurrencycode: string; //  'USD';
  outputvalue_processorcurrencycode: string; //  'USD';
  enablerecurringpayments: string; //  'true';
  outputvalue_enablerecurringpayments: string; //  'true';
  processorsignature: string; //  'private_key_3FnqdeLUjeEkkpY9QUN4n0ZtGepG';
  outputvalue_processorsignature: string; //  'private_key_3FnqdeLUjeEkkpY9QUN4n0ZtGepG';
  processorusesandbox: string; //  'false';
  outputvalue_processorusesandbox: string; //  'false';
  processoruserid: string; //  'public_key_Zwgl0SBdaXpulZNu_ALiFFmhkZ6dr';
  outputvalue_processoruserid: string; //  'public_key_Zwgl0SBdaXpulZNu_ALiFFmhkZ6dr';
  recordcounter: string; //  '1';
  outputvalue_recordcounter: string; //  '1';
  recordtype: string; //  '0';
  singlelineaddress: string; //  '600 Fifth Avenue,2nd Floor New York NY 10111 USA';
  outputvalue_singlelineaddress: string; //  '600 Fifth Avenue,2nd Floor New York NY 10111 USA';
  swiftcode: string | null; //  null;
  outputvalue_swiftcode: string | null; //  null;
  vatnumber: string; //  '831039360';
  outputvalue_vatnumber: string; //  '831039360';
  lgltown: string; //  'New York';
  outputvalue_lgltown: string; //  'New York';
  updatedate: string; //  '2018-11-02T09:31:41-05:00';
  outputvalue_updatedate: string; //  '2018-11-02T09:31:41-05:00';
  updatedby: string | null; //  null;
  outputvalue_updatedby: string | null; //  null;
  useudcredits: string; //  'false';
  outputvalue_useudcredits: string; //  'false';
  orgpayeeid: string;
};

export type EssensysAccountType = {
  clientname: string; // "Tuesday's Children";
  outputvalue_clientname: string; // "Tuesday's Children";
  clientnamelocation: string; // "Tuesday's Children - Studio at Rock Center";
  outputvalue_clientnamelocation: string; // "Tuesday's Children - Studio at Rock Center";
  accounttype_id: string; // '-4';
  outputvalue_accounttype_id: string; // 'Prospect';
  accountsid: string; // 'TUESD000';
  outputvalue_accountsid: string; // 'TUESD000';
  accountid: string; // '133';
  organization: string; // '5';
  outputvalue_accountid: string; // '133';
  salestaxexempt: string; // 'false';
  outputvalue_salestaxexempt: string; // 'false';
  balance: string; // '0.0000';
  outputvalue_balance: string; // '0.0000';
  broker: string; // '0';
  agentcontact: string; // '0';
  agentemail: string | null; // null;
  outputvalue_agentemail: string | null; // null;
  brokerfirstname: string | null; // null;
  outputvalue_brokerfirstname: string | null; // null;
  brokerlastname: string | null; // null;
  outputvalue_brokerlastname: string | null; // null;
  campaign_id: string; // '0';
  chargevat: string; // 'true';
  outputvalue_chargevat: string; // 'true';
  clienttype: string; // '0';
  outputvalue_clienttype: string; // 'External';
  companylogourl: string; // '/SPACECONTROL/v2/Controls/img/no_image.jpg';
  output_companylogourl: string; // '/SPACECONTROL/v2/Controls/img/no_image.jpg';
  configuredworkstations: string; // '0';
  outputvalue_configuredworkstations: string; // '0';
  connectsiteid: string; // '404';
  outputvalue_connectsiteid: string; // '404';
  createdby: string; // 'Rachelle Borja';
  outputvalue_createdby: string; // 'Rachelle Borja';
  creationdate: string; // '2018-09-20T09:44:42-05:00';
  outputvalue_creationdate: string; // '2018-09-20T09:44:42-05:00';
  ud_creditsremaining: string; // '0.0';
  outputvalue_ud_creditsremaining: string; // '0.0';
  creditsremaining: string; // '0.0';
  outputvalue_creditsremaining: string; // '0.0';
  creditsused: string; // '0.0';
  outputvalue_creditsused: string; // '0.0';
  ud_creditsused: string; // '0.0';
  outputvalue_ud_creditsused: string; // '0.0';
  calendarmonthcreditspurchased?: string; // '0.0';
  calendarmonthcreditsspent?: string; // '0.0';
  calendarmonthcreditsremaining?: string; // '0.0';
  currency: string; // '2';
  outputvalue_currency: string; // 'US Dollar';
  defaulttocredits: string; // 'false';
  outputvalue_defaulttocredits: string; // 'false';
  earliesttransactiondateallowed: string; // '1970-01-01T00:00:00-05:00';
  outputvalue_earliesttransactiondateallowed: string; // '1970-01-01T00:00:00-05:00';
  emailaddress: string; // 'test@essensys.tech';
  outputvalue_emailaddress: string; // 'test@essensys.tech';
  emailinvoice: string; // 'true';
  outputvalue_emailinvoice: string; // 'true';
  enablepaymentreminder: string; // 'true';
  outputvalue_enablepaymentreminder: string; // 'true';
  firstname: string; // 'Terry';
  outputvalue_firstname: string; // 'Terry';
  fulladdress: string | null; // null;
  outputvalue_fulladdress: string | null; // null;
  hasactivelicence: string; // 'false';
  outputvalue_hasactivelicence: string; // 'false';
  includebfwd: string; // 'false';
  outputvalue_includebfwd: string; // 'false';
  showcalldetails: string; // 'false';
  outputvalue_showcalldetails: string; // 'false';
  includeitemisedbill: string; // 'true';
  outputvalue_includeitemisedbill: string; // 'true';
  includestatement: string; // 'false';
  outputvalue_includestatement: string; // 'false';
  industry: string; // '0';
  invoicebccaddress: string | null; // null;
  outputvalue_invoicebccaddress: string | null; // null;
  invoiceccaddress: string | null; // null;
  outputvalue_invoiceccaddress: string | null; // null;
  invoiceemailaddress: string; // 'test@essensys.tech';
  outputvalue_invoiceemailaddress: string; // 'test@essensys.tech';
  isexternal: string; // 'false';
  outputvalue_isexternal: string; // 'false';
  isresidential: string; // 'false';
  outputvalue_isresidential: string; // 'false';
  isvirtual: string; // 'false';
  outputvalue_isvirtual: string; // 'false';
  lastcontact: string; // '2018-09-20T09:44:07-05:00';
  outputvalue_lastcontact: string; // '2018-09-20T09:44:07-05:00';
  lastname: string; // 'Sears';
  outputvalue_lastname: string; // 'Sears';
  leadsource: string; // '0';
  leaddadiagent: string; // 'false';
  outputvalue_leaddadiagent: string; // 'false';
  leaddadiid: string; // '46e4a5c5-369e-4199-9b99-bf77af1bd7b8';
  outputvalue_leaddadiid: string; // '46e4a5c5-369e-4199-9b99-bf77af1bd7b8';
  tenancylength: string; // '21';
  outputvalue_tenancylength: string; // '21';
  location: string; // '1';
  outputvalue_location: string; // 'Studio at Rock Center';
  locationemail: string; // 'rockcenter@yourstudio.com';
  outputvalue_locationemail: string; // 'rockcenter@yourstudio.com';
  noofcredits: string; // '0.0';
  outputvalue_noofcredits: string; // '0.0';
  ezeepblackwhite: string; // '0';
  outputvalue_ezeepblackwhite: string; // '0';
  ezeepcolor: string; // '0';
  outputvalue_ezeepcolor: string; // '0';
  onaccount: string; // 'false';
  outputvalue_onaccount: string; // 'false';
  overridediscount: string; // '0';
  outputvalue_overridediscount: string; // '0%';
  user_id: string; // '14';
  outputvalue_user_id: string; // 'Rachelle Testing';
  printinvoice: string; // 'false';
  outputvalue_printinvoice: string; // 'false';
  recordcounter: string; // '1';
  outputvalue_recordcounter: string; // '1';
  recordtype: string; // '100';
  outputvalue_recordtype: string; // 'Prospect';
  registeredaddresssingleline: string | null; // null;
  outputvalue_registeredaddresssingleline: string | null; // null;
  registeredfulladdress: string | null; // null;
  outputvalue_registeredfulladdress: string | null; // null;
  ezeepblackwhiteremaining: string; // '0';
  outputvalue_ezeepblackwhiteremaining: string; // '0';
  ezeepcolorremaining: string; // '0';
  outputvalue_ezeepcolorremaining: string; // '0';
  sendtoconnect: string; // 'false';
  outputvalue_sendtoconnect: string; // 'false';
  singlelineaddress: string | null; // null;
  outputvalue_singlelineaddress: string | null; // null;
  startdate: string; // '2018-09-20T00:00:00-05:00';
  outputvalue_startdate: string; // '2018-09-20T00:00:00-05:00';
  enddate?: string; // "2020-08-27T00:00:00-04:00",
  outputvalue_enddate?: string; // "2020-08-27T00:00:00-04:00",
  updatedate: string; // '2018-09-20T09:44:42-05:00';
  outputvalue_updatedate: string; // '2018-09-20T09:44:42-05:00';
  updatedby: string; // 'Rachelle Borja';
  outputvalue_updatedby: string; // 'Rachelle Borja';
  placepayaccountid: number | string | null; // a place pay id?
  shopperreference: string | null; // either a stripe cus id or a place pay token
  paymenttoken: string | null;
};

export type EssensysInvoiceType = {
  accountname: string; // '94';
  outputvalue_accountname: string; // 'Youplus Inc.';
  approved: string; // 'true';
  outputvalue_approved: string; // 'true';
  bfwd: string; // '0.0000';
  outputvalue_bfwd: string; // '0.0000';
  clienttype: string; // '10';
  outputvalue_clienttype: string; // 'Squad Member';
  createdby: string; // 'Rachelle Borja';
  outputvalue_createdby: string; // 'Rachelle Borja';
  creationdate: string; // '2018-09-06T08:59:23-05:00';
  outputvalue_creationdate: string; // '2018-09-06T08:59:23-05:00';
  emaildate: string; // '2018-09-06T15:02:14-05:00';
  outputvalue_emaildate: string; // '2018-09-06T15:02:14-05:00';
  printdate: string; // '2018-10-19T18:38:50-05:00';
  outputvalue_printdate: string; // '2018-10-19T18:38:50-05:00';
  invoicedescription: string; // 'Standard Invoice';
  outputvalue_invoicedescription: string; // 'Standard Invoice';
  duedate: string; // '2018-10-01T00:00:00-05:00';
  outputvalue_duedate: string; // '2018-10-01T00:00:00-05:00';
  emailinvoices: string; // 'true';
  outputvalue_emailinvoices: string; // 'true';
  invoiceemailaddress: string; // 'test@essensys.tech';
  outputvalue_invoiceemailaddress: string; // 'test@essensys.tech';
  emailaddress: string; // 'test@essensys.tech';
  outputvalue_emailaddress: string; // 'test@essensys.tech';
  emailed: string; // 'true';
  outputvalue_emailed: string; // 'true';
  endingbalance: string; // '17800';
  outputvalue_endingbalance: string; // '17800';
  gross: string; // '17800';
  outputvalue_gross: string; // '17800';
  invoicedate: string; // '2018-09-06T00:00:00-05:00';
  outputvalue_invoicedate: string; // '2018-09-06T00:00:00-05:00';
  invoicenumber: string; // 'RC12-1003';
  outputvalue_invoicenumber: string; // 'RC12-1003';
  invoicetemplate: string; // '7';
  outputvalue_invoicetemplate: string; // 'Default US  Invoice Template';
  invoicetext1: string | null; // null;
  outputvalue_invoicetext1: string | null; // null;
  invoicetext2: string | null; // null;
  outputvalue_invoicetext2: string | null; // null;
  invoiceid: string; // '4916';
  outputvalue_invoiceid: string; // '4916';
  kid: string | null; // null;
  outputvalue_kid: string | null; // null;
  location: string; // '1';
  outputvalue_location: string; // 'Studio at Rock Center';
  net: string; // '17800';
  outputvalue_net: string; // '17800';
  organization: string; // '5';
  outputvalue_organization: string; // 'Rock CW L.L.C.';
  paid: string; // 'false';
  outputvalue_paid: string; // 'false';
  payinvoice: string | null; // null;
  outputvalue_payinvoice: string | null; // null;
  paymentreminder1: string; // 'false';
  outputvalue_paymentreminder1: string; // 'false';
  paymentreminder2: string; // 'false';
  outputvalue_paymentreminder2: string; // 'false';
  paymentreminder3: string; // 'false';
  outputvalue_paymentreminder3: string; // 'false';
  paymentscredits: string; // '0';
  outputvalue_paymentscredits: string; // '0';
  printed: string; // 'true';
  outputvalue_printed: string; // 'true';
  publishedtoconnect: string; // 'false';
  outputvalue_publishedtoconnect: string; // 'false';
  recordcounter: string; // '1';
  outputvalue_recordcounter: string; // '1';
  recordtype: string; // '0';
  sent: string; // '1';
  outputvalue_sent: string; // '1';
  status: string; // '5';
  outputvalue_status: string; // '5';
  tax: string; // '0';
  outputvalue_tax: string; // '0';
  typeofinvoice: string; // 'Deposit';
  outputvalue_typeofinvoice: string; // 'Deposit';
  updatedate: string; // '2018-11-02T09:36:33-05:00';
  outputvalue_updatedate: string; // '2018-11-02T09:36:33-05:00';
  updatedby: string | null; // null;
  outputvalue_updatedby: string | null; // null;
  outstanding: string | null; // supposedly added by Essensys
};
export type EssensysCurrencyType = {
  createdby: string; // 'LIVE';
  outputvalue_createdby: string; // 'LIVE';
  creationdate: string; // '2019-10-17T04:14:59-04:00';
  outputvalue_creationdate: string; // '2019-10-17T04:14:59-04:00';
  currencycode: string; //  'PLN';
  outputvalue_currencycode: string; // 'PLN';
  currencyformat: string; //  '###,###,##0.00zl';
  outputvalue_currencyformat: string; // '###,###,##0.00zl';
  currencyname: string; // 'Polish Zloty';
  outputvalue_currencyname: string; // 'Polish Zloty';
  currencysymbol: string; // 'zl';
  outputvalue_currencysymbol: string; // 'zl';
  currencyid: string; // '101';
  outputvalue_currencyid: string; // '101';
  noofdecdigits: string; //  '2';
  outputvalue_noofdecdigits: string; // '2';
  recordcounter: string; //  '1';
  outputvalue_recordcounter: string; // '1';
  recordtype: string; //  '25';
  outputvalue_recordtype: string; // 'Currency';
  updatedate: string; // '2019-10-17T04:14:59-04:00';
  outputvalue_updatedate: string; // '2019-10-17T04:14:59-04:00';
  updatedby: string; // 'LIVE';
  outputvalue_updatedby: string; // 'LIVE'
};

export type EssensysContactType = {
  organizationId?: string; //  '8' this is manually added for convenience to use in UserLogin table;
  acceptedprivacypolicy: string; //  'false';
  outputvalue_acceptedprivacypolicy: string; //  'false';
  address_id: string; //  '247';
  outputvalue_address_id: string; //  'ECS Global';
  accountrecordtype: string; //  'Account';
  outputvalue_accountrecordtype: string; //  'Account';
  ud_badgeissuedate: string; //  '2018-12-19T00:00:00-04:00';
  outputvalue_ud_badgeissuedate: string; //  '2018-12-19T00:00:00-04:00';
  ud_badgeprovisiondate: string; //  '2018-12-10T00:00:00-04:00';
  outputvalue_ud_badgeprovisiondate: string; //  '2018-12-10T00:00:00-04:00';
  ud_badgeid: string; //  '165204';
  outputvalue_ud_badgeid: string; //  '165204';
  ud_carregno: string | null; //  null;
  outputvalue_ud_carregno: string | null; //  null;
  checkedin: string; //  'false';
  outputvalue_checkedin: string; //  'false';
  clienttype: string; //  '10';
  outputvalue_clienttype: string; //  'Squad Member';
  communitylocation: string; //  '0';
  communityprofileactive: string; //  'true';
  outputvalue_communityprofileactive: string; //  'true';
  communitystatus: string | null; //  null;
  outputvalue_communitystatus: string | null; //  null;
  companylogourl: string; //  '/SPACECONTROL/v2/Themes/Hubcreate-Modern/img/Default/avatar.gif';
  output_companylogourl: string; //  '/SPACECONTROL/v2/Themes/Hubcreate-Modern/img/Default/avatar.gif';
  fullname: string; //  'Luis Miranda';
  outputvalue_fullname: string; //  'Luis Miranda';
  contacttypeid: string; //  '0';
  contactid: string; //  '853';
  outputvalue_contactid: string; //  '853';
  createdby: string; //  'Rachelle Borja';
  outputvalue_createdby: string; //  'Rachelle Borja';
  creationdate: string; //  '2018-12-06T16:02:41-04:00';
  outputvalue_creationdate: string; //  '2018-12-06T16:02:41-04:00';
  currency: string; //  '2';
  outputvalue_currency: string; //  'US Dollar';
  ud_deletedfromportal: string; //  'false';
  outputvalue_ud_deletedfromportal: string; //  'false';
  disableportalaccess: string; //  'false';
  outputvalue_disableportalaccess: string; //  'false';
  disableprinting: string; //  'false';
  outputvalue_disableprinting: string; //  'false';
  dislikestags: string | null; //  null;
  outputvalue_dislikestags: string | null; //  null;
  emailaddress: string; //  'test@essensys.tech';
  outputvalue_emailaddress: string; //  'test@essensys.tech';
  emailoptin: string; //  'false';
  outputvalue_emailoptin: string; //  'false';
  optout: string; //  'false';
  outputvalue_optout: string; //  'false';
  enabledooraccesscontrol: string; //  'false';
  outputvalue_enabledooraccesscontrol: string; //  'false';
  expertisetags: string | null; //  null;
  outputvalue_expertisetags: string | null; //  null;
  ezeepregistered: string; //  'true';
  outputvalue_ezeepregistered: string; //  'true';
  firstname: string; //  'Luis';
  outputvalue_firstname: string; //  'Luis';
  hasactivelicence: string; //  'true';
  outputvalue_hasactivelicence: string; //  'true';
  contactimageurl: string; //  '/spacecontrol/v2/controls/getobjectimage.aspx?operator=TishmanSpeyer_UAT&moduleid=4&fieldid=992&itemid=853';
  output_contactimageurl: string; //  '/spacecontrol/v2/controls/getobjectimage.aspx?operator=TishmanSpeyer_UAT&moduleid=4&fieldid=992&itemid=853';
  importantnote: string | null; //  null;
  outputvalue_importantnote: string | null; //  null;
  includeincommunity: string; //  '1';
  outputvalue_includeincommunity: string; //  '1';
  industry: string; //  'Other';
  outputvalue_industry: string; //  'Other';
  interestingfacts: string | null; //  null;
  outputvalue_interestingfacts: string | null; //  null;
  invitedfromportal: string; //  'false';
  outputvalue_invitedfromportal: string; //  'false';
  jobfunction: string; //  null;
  outputvalue_jobfunction: string | null; //  null;
  lastcontact: string; //  '2020-04-15T15:24:40-04:00';
  outputvalue_lastcontact: string; //  '2020-04-15T15:24:40-04:00';
  lastloggedin: string; //  '2019-07-11T17:38:41-04:00';
  outputvalue_lastloggedin: string; //  '2019-07-11T17:38:41-04:00';
  lastname: string; //  'Miranda';
  outputvalue_lastname: string; //  'Miranda';
  leadname: string; //  '247';
  outputvalue_leadname: string; //  'ECS Global';
  likestags: string | null; //  null;
  outputvalue_likestags: string | null; //  null;
  linkedinaccount: string | null; //  null;
  outputvalue_linkedinaccount: string | null; //  null;
  locationname: string; //  '1';
  outputvalue_locationname: string; //  'Studio at 600 5th';
  newsletter: string; //  'false';
  outputvalue_newsletter: string; //  'false';
  numberbeingfollowed: string; //  '0';
  outputvalue_numberbeingfollowed: string; //  '0';
  numberoffollowers: string; //  '0';
  outputvalue_numberoffollowers: string; //  '0';
  ud_onboardemailsent: string; //  'false';
  outputvalue_ud_onboardemailsent: string; //  'false';
  opportunityname: string; //  '78';
  outputvalue_opportunityname: string; //  'ECS Global';
  optedintocontact: string; //  'false';
  outputvalue_optedintocontact: string; //  'false';
  user_id: string; //  '0';
  pinno: string; //  '165204';
  outputvalue_pinno: string; //  '165204';
  portalaccesslevel: string; //  '0';
  outputvalue_portalaccesslevel: string; //  '0';
  profilepercentcomplete: string; //  '10';
  outputvalue_profilepercentcomplete: string; //  '10%';
  companyprofileactive: string; //  'false';
  outputvalue_companyprofileactive: string; //  'false';
  receiveshoutoutemails: string; //  'false';
  outputvalue_receiveshoutoutemails: string; //  'false';
  receivestatusupdateemails: string; //  'false';
  outputvalue_receivestatusupdateemails: string; //  'false';
  recordcounter: string; //  '1';
  outputvalue_recordcounter: string; //  '1';
  recordtype: string; //  '4';
  outputvalue_recordtype: string; //  'Contact';
  remaininghours: string; //  '0m';
  outputvalue_remaininghours: string; //  '0m';
  securitycode: string; //  '749258';
  outputvalue_securitycode: string; //  '749258';
  sendemailsecuritycode: string; //  'false';
  outputvalue_sendemailsecuritycode: string; //  'false';
  sendsmssecuritycode: string; //  'false';
  outputvalue_sendsmssecuritycode: string; //  'false';
  statusupdates: string; //  'true';
  outputvalue_statusupdates: string; //  'true';
  swipecard: string | null; //  null;
  outputvalue_swipecard: string | null; //  null;
  socialtags: string | null; //  null;
  outputvalue_socialtags: string | null; //  null;
  twitteraccount: string | null; //  null;
  outputvalue_twitteraccount: string | null; //  null;
  unitnumbers: string; //  'Squad 15, Squad 16';
  outputvalue_unitnumbers: string; //  'Squad 15, Squad 16';
  updatedate: string; //  '2019-05-09T16:40:51-04:00';
  outputvalue_updatedate: string; //  '2019-05-09T16:40:51-04:00';
  updatedby: string | null; //  null;
  outputvalue_updatedby: string | null; //  null;
  watercoolersubject: string | null; //  null;
  outputvalue_watercoolersubject: string | null; //  null;
  socialwebsites: string | null; //  null;
  outputvalue_socialwebsites: string | null; //  null;
  whatdoido: string | null; //  null;
  outputvalue_whatdoido: string | null; //  null;
  whaticareabout: string | null; //  null;
  outputvalue_whaticareabout: string | null; //  null;
  ud_enddate?: string; //  '2019-05-09T16:40:51-04:00'
  mobilecellno?: string;
};

export type EssensysLicenceType = {
  accountname?: string; // '29098'
  outputvalue_accountname?: string; // 'ET  via Lane'
  workstations?: string; // '0'
  outputvalue_workstations?: string; // '0'
  additionaldeposit?: string; // '0.0000'
  outputvalue_additionaldeposit?: string; // '0.0000'
  additionalproductnames?: string | null;
  outputvalue_additionalproductnames?: string | null;
  additionalproductprices?: string | null;
  outputvalue_additionalproductprices?: string | null;
  additionalproductquantities?: string | null;
  outputvalue_additionalproductquantities?: string | null;
  additionalproducttotalprices?: string | null;
  outputvalue_additionalproducttotalprices?: string | null;
  agreementno?: string; // '0000001834'
  outputvalue_agreementno?: string; // '0000001834'
  autorenew?: string; // 'true'
  outputvalue_autorenew?: string; // 'true'
  contactid?: string; // '34958'
  outputvalue_contactid?: string; // 'ET           34958'
  clienttype?: string; // '31'
  outputvalue_clienttype?: string; // 'External'
  contact?: string; // '34958'
  outputvalue_contact?: string; // 'ET           34958'
  contractdiscount?: string; // '0'
  outputvalue_contractdiscount?: string; // '0%'
  contractlistprice?: string; // '0.0000'
  outputvalue_contractlistprice?: string; // '0.0000'
  contractservicenames?: string; // 'Hot Desk Membership                                                         <br>'
  outputvalue_contractservicenames?: string; // 'Hot Desk Membership                                                         <br>'
  contractserviceprices?: string; // '$350.00<br>'
  outputvalue_contractserviceprices?: string; // '$350.00<br>'
  contractservicequantities?: string; // '1<br>'
  outputvalue_contractservicequantities?: string; // '1<br>'
  contractservicetotalprices?: string; // '$350.00<br>'
  outputvalue_contractservicetotalprices?: string; // '$350.00<br>'
  contractvalue?: string; // '350'
  outputvalue_contractvalue?: string; // '350'
  countersignuser?: string; // '0'
  createdby?: string; // 'operate-integration'
  outputvalue_createdby?: string; // 'operate-integration'
  creationdate?: string; // '2022-05-26T20:04:51+00:00'
  outputvalue_creationdate?: string; // '2022-05-26T20:04:51+00:00'
  datelicencesigned?: string; // '2022-05-26T00:00:00+00:00'
  outputvalue_datelicencesigned?: string; // '2022-05-26T00:00:00+00:00'
  depositheld?: string; // '0.0000'
  outputvalue_depositheld?: string; // '0.0000'
  directdebit?: string; // 'false'
  outputvalue_directdebit?: string; // 'false'
  effectivedate?: string; // '2022-05-26T00:00:00+00:00'
  outputvalue_effectivedate?: string; // '2022-05-26T00:00:00+00:00'
  escalateby?: string; // '1'
  outputvalue_escalateby?: string; // '1'
  escalationdate?: string; // '2022-06-26T00:00:00+00:00'
  outputvalue_escalationdate?: string; // '2022-06-26T00:00:00+00:00'
  escalationmonths?: string; // '12'
  outputvalue_escalationmonths?: string; // '12'
  esignedlicenceurl?: string | null;
  outputvalue_esignedlicenceurl?: string | null;
  initialinvoicegrandtotal?: string; // '0.0000'
  outputvalue_initialinvoicegrandtotal?: string; // '0.0000'
  initialinvoicetax?: string; // '0.0000'
  outputvalue_initialinvoicetax?: string; // '0.0000'
  initialinvoicetotal?: string; // '0.0000'
  outputvalue_initialinvoicetotal?: string; // '0.0000'
  licencename?: string; // 'ET  via Lane'
  outputvalue_licencename?: string; // 'ET  via Lane'
  licencestatus?: string; // 'Activated (Expiring)'
  outputvalue_licencestatus?: string; // 'Activated (Expiring)'
  licenceid?: string; // '1834'
  outputvalue_licenceid?: string; // '1834'
  locationname: string; // '6'
  outputvalue_locationname?: string; // 'Studio at 125 High'
  locationemail?: string; // 'donotreply@essensys.tech'
  outputvalue_locationemail?: string; // 'donotreply@essensys.tech'
  mandatorychargesloaded?: string; // 'false'
  outputvalue_mandatorychargesloaded?: string; // 'false'
  newmember?: string; // 'false'
  outputvalue_newmember?: string; // 'false'
  noofcredits?: string; // '0.0'
  outputvalue_noofcredits?: string; // '0.0'
  noticegiven?: string; // '0'
  outputvalue_noticegiven?: string; // '0'
  noticeperiod?: string; // '1'
  outputvalue_noticeperiod?: string; // '1'
  ezeepblackwhite?: string; // '0'
  outputvalue_ezeepblackwhite?: string; // '0'
  ezeepcolor?: string; // '0'
  outputvalue_ezeepcolor?: string; // '0'
  officefeeinitialinvoice?: string; // '0.0000'
  outputvalue_officefeeinitialinvoice?: string; // '0.0000'
  monthlyoffice?: string; // '0.0000'
  outputvalue_monthlyoffice?: string; // '0.0000'
  monthlyofficetax?: string; // '0.0000'
  outputvalue_monthlyofficetax?: string; // '0.0000'
  monthlyofficetotal?: string; // '0.0000'
  outputvalue_monthlyofficetotal?: string; // '0.0000'
  onboardingemailsent?: string; // 'false'
  outputvalue_onboardingemailsent?: string; // 'false'
  opportunity?: string; // '0'
  parkingspaces?: string; // '0'
  outputvalue_parkingspaces?: string; // '0'
  paymenttoken?: string; // '05b860e2-ead5-46ab-84fd-1ddaf9321643'
  outputvalue_paymenttoken?: string; // '05b860e2-ead5-46ab-84fd-1ddaf9321643'
  items?: string; // 'Studio Credits US,Hot Desk Membership'
  outputvalue_items?: string; // 'Studio Credits US,Hot Desk Membership'
  recordcounter?: string; // '1'
  outputvalue_recordcounter?: string; // '1'
  recurringfeeintialinvoice?: string; // '0.0000'
  outputvalue_recurringfeeintialinvoice?: string; // '0.0000'
  recurringinitialinvoicetax?: string; // '0.0000'
  outputvalue_recurringinitialinvoicetax?: string; // '0.0000'
  renewalstartdate: string; // '2022-07-01T00:00:00+00:00'
  outputvalue_renewalstartdate: string; // '2022-07-01T00:00:00+00:00'
  renewalterm?: string; // '1'
  outputvalue_renewalterm?: string; // '1'
  renewed?: string; // 'false'
  outputvalue_renewed?: string; // 'false'
  renewing?: string; // 'false'
  outputvalue_renewing?: string; // 'false'
  rentuplift?: string; // '3'
  outputvalue_rentuplift?: string; // '3%'
  rentschedulemonths?: string | null;
  outputvalue_rentschedulemonths?: string | null;
  rentscheduleprices?: string | null;
  outputvalue_rentscheduleprices?: string | null;
  rentvalue?: string; // '0.0000'
  outputvalue_rentvalue?: string; // '0.0000'
  rollinglicence?: string; // 'true'
  outputvalue_rollinglicence?: string; // 'true'
  rollingbreakdates?: string; // 'false'
  outputvalue_rollingbreakdates?: string; // 'false'
  sameasbillingaddress?: string; // 'false'
  outputvalue_sameasbillingaddress?: string; // 'false'
  servicefeeinitialinvoice?: string; // '0.0000'
  outputvalue_servicefeeinitialinvoice?: string; // '0.0000'
  serviceuplift?: string; // '3'
  outputvalue_serviceuplift?: string; // '3%'
  monthlyservice?: string; // '350'
  outputvalue_monthlyservice?: string; // '350'
  monthlyservicetax?: string; // '0.0000'
  outputvalue_monthlyservicetax?: string; // '0.0000'
  monthlyservicetotal?: string; // '350'
  outputvalue_monthlyservicetotal?: string; // '350'
  servicenames?: string; // ', Studio Credits US,Hot Desk Membership,, Studio Credits US,Hot Desk Membership,'
  outputvalue_servicenames?: string; // ', Studio Credits US,Hot Desk Membership,, Studio Credits US,Hot Desk Membership,'
  servicevalue?: string; // '350'
  outputvalue_servicevalue?: string; // '350'
  setupfeeinitialinvoice?: string; // '0.0000'
  outputvalue_setupfeeinitialinvoice?: string; // '0.0000'
  setupfeeinitialinvoicetax?: string; // '0.0000'
  outputvalue_setupfeeinitialinvoicetax?: string; // '0.0000'
  showbreakdates?: string; // 'false'
  outputvalue_showbreakdates?: string; // 'false'
  startdate: string; // '2022-05-26T00:00:00+00:00'
  outputvalue_startdate: string; // '2022-05-26T00:00:00+00:00'
  licencestatusid?: string; // '2'
  outputvalue_licencestatusid?: string; // 'Activated'
  suminsured?: string; // '0.0000'
  outputvalue_suminsured?: string; // '0.0000'
  suretyrequired?: string; // 'false'
  outputvalue_suretyrequired?: string; // 'false'
  termmonths?: string; // '1'
  outputvalue_termmonths?: string; // '1'
  termdays?: string; // '0'
  outputvalue_termdays?: string; // '0'
  enddate: string; // '2022-06-25T00:00:00+00:00'
  outputvalue_enddate?: string; // '2022-06-25T00:00:00+00:00'
  totaldeposit?: string; // '0.0000'
  outputvalue_totaldeposit?: string; // '0.0000'
  monthlytotal?: string; // '350'
  outputvalue_monthlytotal?: string; // '350'
  monthlygross?: string; // '350'
  outputvalue_monthlygross?: string; // '350'
  monthlygrossnooneoff?: string; // '350'
  outputvalue_monthlygrossnooneoff?: string; // '350'
  monthlytax?: string; // '0.0000'
  outputvalue_monthlytax?: string; // '0.0000'
  transferred?: string; // 'false'
  outputvalue_transferred?: string; // 'false'
  transferring?: string; // 'false'
  outputvalue_transferring?: string; // 'false'
  updatedate?: string; // '2022-05-26T20:04:51+00:00'
  outputvalue_updatedate?: string; // '2022-05-26T20:04:51+00:00'
  updatedby?: string; // 'operate-integration'
  outputvalue_updatedby?: string; // 'operate-integration'
  valueofgoods?: string; // '0.0000'
  outputvalue_valueofgoods?: string; // '0.0000'
  virtualfeeinitialinvoice?: string; // '0.0000'
  outputvalue_virtualfeeinitialinvoice?: string; // '0.0000'
  virtualuplift?: string; // '3'
  outputvalue_virtualuplift?: string; // '3%'
  monthlyvirtual?: string; // '0.0000'
  outputvalue_monthlyvirtual?: string; // '0.0000'
  monthlyvirtualtax?: string; // '0.0000'
  outputvalue_monthlyvirtualtax?: string; // '0.0000'
  monthlyvirtualtotal?: string; // '0.0000'
  outputvalue_monthlyvirtualtotal?: string; // '0.0000'
  virtualvalue?: string; // '0.0000'
  outputvalue_virtualvalue?: string; // '0.0000"'
};

export type EssensysLocationType = {
  activepromoters: string;
  outputvalue_activepromoters: string;
  activerooms: string;
  outputvalue_activerooms: string;
  address1: string;
  outputvalue_address1: string;
  addressline2: string;
  outputvalue_addressline2: string;
  ud_afterhoursemergencycontact: string;
  outputvalue_ud_afterhoursemergencycontact: string;
  approvalpercent: string;
  outputvalue_approvalpercent: string;
  autochargeoverage: string;
  outputvalue_autochargeoverage: string;
  brand: string;
  ud_buildingamenities: string;
  outputvalue_ud_buildingamenities: string;
  officehoursend: string;
  outputvalue_officehoursend: string;
  connectcalllogger: string;
  outputvalue_connectcalllogger: string;
  connectregion: string;
  outputvalue_connectregion: string;
  connectsiteid: string;
  outputvalue_connectsiteid: string;
  connectusesandbox: string;
  outputvalue_connectusesandbox: string;
  connectusername: string;
  outputvalue_connectusername: string;
  connectverified: string;
  outputvalue_connectverified: string;
  country: string;
  outputvalue_country: string;
  county: string;
  outputvalue_county: string;
  createdby: string;
  outputvalue_createdby: string;
  creationdate: string;
  outputvalue_creationdate: string;
  creditnoteprefix: string;
  outputvalue_creditnoteprefix: string;
  currency: string;
  outputvalue_currency: string;
  discontinued: string;
  outputvalue_discontinued: string;
  email: string;
  outputvalue_email: string;
  enableapprovals: string;
  outputvalue_enableapprovals: string;
  enableezeep: string;
  outputvalue_enableezeep: string;
  enableportal: string;
  outputvalue_enableportal: string;
  ud_eventsimages: string;
  outputvalue_ud_eventsimages: string;
  fulladdress: string;
  outputvalue_fulladdress: string;
  inactiverooms: string;
  outputvalue_inactiverooms: string;
  invoiceprefix: string;
  outputvalue_invoiceprefix: string;
  placepaypayeeid: string;
  outputvalue_placepaypayeeid: string;
  officeimageurl: string;
  output_officeimageurl: string;
  officeimage2url: string;
  output_officeimage2url: string;
  officeimage3url: string;
  output_officeimage3url: string;
  officeimage4url: string;
  output_officeimage4url: string;
  officeimage5url: string;
  output_officeimage5url: string;
  locationuuid: string;
  outputvalue_locationuuid: string;
  locationid: string;
  outputvalue_locationid: string;
  hostimageurl: string;
  output_hostimageurl: string;
  contactemail: string;
  outputvalue_contactemail: string;
  contactname: string;
  outputvalue_contactname: string;
  map: string;
  outputvalue_map: string;
  ud_nearestrailstation: string;
  outputvalue_ud_nearestrailstation: string;
  nextaccountid: string;
  outputvalue_nextaccountid: string;
  noofimages: string;
  outputvalue_noofimages: string;
  onlinealias: string;
  outputvalue_onlinealias: string;
  officehoursstart: string;
  outputvalue_officehoursstart: string;
  overageproduct: string;
  ud_laneoveragesproductid: string; // '275';
  outputvalue_ud_laneoveragesproductid: string; // '275';
  ud_laneoverage: string; // '275';
  outputvalue_ud_laneoverage: string; // '275';
  postcode: string;
  outputvalue_postcode: string;
  printingblackwhiteproduct: string;
  outputvalue_printingblackwhiteproduct: string;
  printingcolorproduct: string;
  outputvalue_printingcolorproduct: string;
  published: string;
  outputvalue_published: string;
  recordcounter: string;
  outputvalue_recordcounter: string;
  recordtype: string;
  outputvalue_recordtype: string;
  restrictbookingstohomecentre: string;
  outputvalue_restrictbookingstohomecentre: string;
  separateaccommadation: string;
  outputvalue_separateaccommadation: string;
  singlelineaddress: string;
  outputvalue_singlelineaddress: string;
  locationname: string;
  outputvalue_locationname: string;
  telephone: string;
  outputvalue_telephone: string;
  timezone: string;
  outputvalue_timezone: string;
  ud_totalbuildingsqft: string;
  outputvalue_ud_totalbuildingsqft: string;
  town: string;
  outputvalue_town: string;
  updatedate: string;
  outputvalue_updatedate: string;
  updatedby: string;
  outputvalue_updatedby: string;
  usesitebankdetails: string;
  outputvalue_usesitebankdetails: string;
  ud_walkingdirections: string;
  outputvalue_ud_walkingdirections: string;
};

export type EssensysProductType = {
  allowzeropriceoninvoices: string; // "true"
  applymeetingroomdisadvantage: string; // "false"
  applysuitedisadvantage: string; // "false"
  availableat: string; // "Studio at Rockefeller Center↵Studio at Rock Center"
  avalaralinesitem: string; // "false"
  avalarataxitem: string; // "false"
  canbebundled: string; // "false"
  canbookonwebsite: string; // "true"
  caneditmandatorycharge: string; // "false"
  catering: string; // 'false';
  costtype: string; // '2';
  createdby: string; // 'live';
  creationdate: string; // '2018-07-19T09:46:11-05:00';
  creditdayext: string; // '1';
  creditdayint: string; // '24';
  credithalfdayext: string; // '1';
  credithalfdayint: string; // '12';
  credithourlyext: string; // '1';
  credithourlyint: string; // '3';
  currency: string; // '2';
  outputvalue_currency: string; // 'USD'
  currencyoccupier: string; // 'Vacant';
  description: string; // 'Phone - TV Display';
  desk: string; // 'false';
  discontinued: string; // 'false';
  enablebookingforinternalonly: string; // 'false';
  enablecredits: string; // 'true';
  enableonline: string; // 'false';
  exemption: string; // 'false';
  extractcostfromcalllogger: string; // 'false';
  facility: string; // 'false';
  floor: string; // 'Not Set';
  frequency: string; // '0';
  hidefrominvoices: string; // '0';
  hidefrompos: string; // 'true';
  includedincommissions: string; // 'false';
  includereservationdiscounts: string; // 'false';
  lifetime: string; // '0 ';
  lifetimefrequency: string; // '0';
  location: string; // '1';
  locationname: string; // 'Studio at Rock Center';
  mandatorycharge: string; // 'false';
  mandatorychargepercentofall: string; // '0';
  mandatorychargetype: string; // '0';
  mandatorypercentofrent: string; // '0';
  mandatorysqftcalc: string; // '0.0000';
  mandatorytovirtualclients: string; // 'false';
  meetingroom: string; // 'true';
  meetingroomimageurl: string; // '/spacecontrol/v2/controls/getobjectimage.aspx?operator=TishmanSpeyer_Test&moduleid=265&fieldid=26125&itemid=263';
  meetingroomtype: string; // '0';
  meteredcharge: string; // 'false';
  minimumprice: string; // '0.0000';
  mobileimageurl: string; // '/SPACECONTROL/v2/Controls/img/no_image.jpg';
  noofcredits: string; // '1.0';
  noofpeople: string; // '6';
  office: string; // 'false';
  officeandoccupier: string; // 'Brooklyn Heights - Vacant';
  officeimageurl: string; // '/spacecontrol/v2/controls/getobjectimage.aspx?operator=TishmanSpeyer_Test&moduleid=265&fieldid=26155&itemid=263';
  officestatus: string; // '0';
  officetype: string; // '0';
  onlinealias: string; // null;
  pictureurl: string; // '/spacecontrol/v2/controls/getobjectimage.aspx?operator=TishmanSpeyer_Test&moduleid=265&fieldid=26159&itemid=263';
  pricingdayext: string; // '1';
  pricingdayhighestqualifier: string; // '480';
  pricingdayint: string; // '24';
  pricingdaylowestqualifier: string; // '480';
  pricingdaymins: string; // '480';
  pricingeveningoffext: string; // '0.0000';
  pricingeveningoffint: string; // '0.0000';
  pricinghalfdayext: string; // '1';
  pricinghalfdayhighestqualifier: string; // '240';
  pricinghalfdayint: string; // '12';
  pricinghalfdaylowestqualifier: string; // '240';
  pricinghalfdaymins: string; // '240';
  pricinghourlyext: string; // '1';
  pricinghourlyhighestqualifier: string; // '60';
  pricinghourlyint: string; // '3';
  pricinghourlylowestqualifier: string; // '60';
  pricinghourlymins: string; // '60';
  pricingmorningoffext: string; // '0.0000';
  pricingmorningoffint: string; // '0.0000';
  productId: string; // '263';
  productUUID: string; // 'f11f9561-c1d4-4e77-83d3-fc6bedf0d437';
  productcannotbediscounted: string; // 'false';
  productcost: string; // '0.0000';
  productgroup_id: string; // '26';
  productgroupname: string; // 'Meeting Rooms';
  productid: string; // '263';
  productname: string; // 'Brooklyn Heights';
  productuuid: string; // 'f11f9561-c1d4-4e77-83d3-fc6bedf0d437';
  published: string; // 'false';
  recordtype: string; // '10478';
  relatedspace: string; // null;
  retainer: string; // 'false';
  retainerproduct: string; // '0';
  revaccno: string; // '490002';
  service: string; // '0';
  servicechargeitem: string; // 'false';
  showreservations: string; // 'true';
  smsitem: string; // 'false';
  startdate: string; // '2000-01-01T00:00:00-05:00';
  styles: string; // 'Boardroom';
  tax: string; // '3';
  taxrate: string; // '0';
  timebasedplan: string; // 'false';
  timebasedplanhours: string; // '0';
  unitprice: string; // '0.0000';
  updatedate: string; // '2020-05-27T08:34:08-05:00';
  updatedby: string; // 'tishmantest1 Testing';
  virtualservice: string; // 'false';
  withholdingtax: string; // 'false';
  enddate?: string;
};

export type EssensysAssignedClientTypeType = {
  assignedclienttypeid: string; // '166';
  outputvalue_assignedclienttypeid: string; // '166';
  clienttype: string; // '95';
  outputvalue_clienttype: string; // '222 NLS Properties LLC - Tenant';
  createdby: string; // 'Tishman Admin';
  outputvalue_createdby: string; // 'Tishman Admin';
  creationdate: string; // '2020-04-07T09:37:53-04:00';
  outputvalue_creationdate: string; // '2020-04-07T09:37:53-04:00';
  location: string; // '19';
  outputvalue_location: string; // 'Studio at 222 NLS';
  organizationname: string; // '222 NLS Properties LLC';
  outputvalue_organizationname: string; // '222 NLS Properties LLC';
  recordcounter: string; // '1';
  outputvalue_recordcounter: string; // '1';
  recordtype: string; // '11426';
  outputvalue_recordtype: string; // 'AssignedClientType';
  updatedate: string; // '2020-04-07T09:37:53-04:00';
  outputvalue_updatedate: string; // '2020-04-07T09:37:53-04:00';
  updatedby: string; // 'Tishman Admin';
  outputvalue_updatedby: string; // 'Tishman Admin';
  clienttypename: string; // 'External';
};

export type EssensysClientTypeType = {
  chargeexternalrates: string; // 'false';
  outputvalue_chargeexternalrates: string; // 'false';
  clienttypeandorganization: string; // 'Rock CW L.L.C. - Squad Member';
  outputvalue_clienttypeandorganization: string; // 'Rock CW L.L.C. - Squad Member';
  clienttypename: string; // 'Squad Member';
  outputvalue_clienttypename: string; // 'Squad Member';
  clienttypeid: string; // '10';
  outputvalue_clienttypeid: string; // '10';
  connectclienttype: string; // '2';
  outputvalue_connectclienttype: string; // '2';
  createdby: string; // 'live';
  outputvalue_createdby: string; // 'live';
  creationdate: string; // '2018-07-19T09:45:08-05:00';
  outputvalue_creationdate: string; // '2018-07-19T09:45:08-05:00';
  creditnoteprefix: string; // '0';
  outputvalue_creditnoteprefix: string; // '0';
  defaulttocredits: string; // 'true';
  outputvalue_defaulttocredits: string; // 'true';
  disadvantageclassification: string; // 'false';
  outputvalue_disadvantageclassification: string; // 'false';
  nominalextension: string | null; // null;
  outputvalue_nominalextension: string | null; // null;
  invoicefreetexttitle: string | null; // null;
  outputvalue_invoicefreetexttitle: string | null; // null;
  invoiceprefix: string | null; // null;
  organization: string; // '5';
  outputvalue_organization: string; // 'Rock CW L.L.C.';
  recordcounter: string; // '1';
  outputvalue_recordcounter: string; // '1';
  recordtype: string; // '11254';
  outputvalue_recordtype: string; // 'ClientType';
  webrescreditcard: string; // 'false';
  outputvalue_webrescreditcard: string; // 'false';
  residentialclassification: string; // 'true';
  outputvalue_residentialclassification: string; // 'true';
  updatedate: string; // '2020-03-11T04:57:21-05:00';
  outputvalue_updatedate: string; // '2020-03-11T04:57:21-05:00';
  updatedby: string; // 'tishmantest1 Testing';
  outputvalue_updatedby: string; // 'tishmantest1 Testing';
  usecreditnoteprefix: string; // 'false';
  outputvalue_usecreditnoteprefix: string; // 'false';
  useinvoiceprefix: string; // 'false';
  outputvalue_useinvoiceprefix: string; // 'false';
  virtualclassification: string; // 'false';
  outputvalue_virtualclassification: string; // 'false';
};

export type EssensysCalendarBookingType = {
  accountemailaddress: string; // 'test+stud2io@essensys.tech';
  outputvalue_accountemailaddress: string; // 'test+stud2io@essensys.tech';
  account: string; // '47';
  outputvalue_account: string; // 'Studio at Rock Center';
  accountname: string; // 'Studio at Rock Center';
  outputvalue_accountname: string; // 'Studio at Rock Center';
  bookedby: string; // 'Johan Skattum';
  outputvalue_bookedby: string; // 'Johan Skattum';
  bookingcharge: string; // '0';
  outputvalue_bookingcharge: string; // '0';
  bookingdate: string; // '2019-11-13T11:30:00-04:00';
  outputvalue_bookingdate: string; // '2019-11-13T11:30:00-04:00';
  bookingendtime: string; // ' 5:30PM';
  outputvalue_bookingendtime: string; // ' 5:30PM';
  bookingstarttime: string; // '11:30AM';
  outputvalue_bookingstarttime: string; // '11:30AM';
  bookingtitle: string; // 'New Booking';
  outputvalue_bookingtitle: string; // 'New Booking';
  bookingid: string; // '10026';
  outputvalue_bookingid: string; // '10026';
  cateringfacilities: string; // null;
  outputvalue_cateringfacilities: string; // null;
  cateringresource: string; // '0';
  clearancetime: string; // '0';
  outputvalue_clearancetime: string; // '0';
  clienttype: string; // '85';
  outputvalue_clienttype: string; // 'Studio Team';
  contact: string; // '0';
  contactname: string | null; // null;
  outputvalue_contactname: string | null; // null;
  createdby: string; // 'Johan Skattum';
  outputvalue_createdby: string; // 'Johan Skattum';
  creationdate: string; // '2019-11-12T17:42:06-04:00';
  outputvalue_creationdate: string; // '2019-11-12T17:42:06-04:00';
  remainingcredits: string; // '0';
  outputvalue_remainingcredits: string; // '0';
  creditsused: string; // '6';
  outputvalue_creditsused: string; // '6';
  datebooked: string; // '2019-11-12T17:42:06-04:00';
  outputvalue_datebooked: string; // '2019-11-12T17:42:06-04:00';
  delegaterate: string; // '0';
  defaulttocredits: string; // 'true';
  outputvalue_defaulttocredits: string; // 'true';
  discountpercent: string; // '0';
  outputvalue_discountpercent: string; // '0';
  discounthours: string; // '0 Hrs';
  outputvalue_discounthours: string; // '0 Hrs';
  discountpercentapplied: string; // '0';
  outputvalue_discountpercentapplied: string; // '0';
  duration: string; // '6 Hrs';
  outputvalue_duration: string; // '6 Hrs';
  durationhours: string; // '6';
  outputvalue_durationhours: string; // '6';
  enddate: string; // '2019-11-13T17:30:00-04:00';
  outputvalue_enddate: string; // '2019-11-13T17:30:00-04:00';
  end_date: string; // '2019-11-13T17:30:00-04:00';
  outputvalue_end_date: string; // '2019-11-13T17:30:00-04:00';
  facilitescharge: string; // '0';
  outputvalue_facilitescharge: string; // '0';
  id: string; // '10026';
  outputvalue_id: string; // '10026';
  invoiced: string; // 'true';
  outputvalue_invoiced: string; // 'true';
  unit_id: string; // '267';
  outputvalue_unit_id: string; // '267';
  location: string; // '1';
  outputvalue_location: string; // 'Studio at 600 5th';
  locationemail: string; // 'rockcenter@yourstudio.com';
  outputvalue_locationemail: string; // 'rockcenter@yourstudio.com';
  locationname: string; // 'Studio at 600 5th';
  outputvalue_locationname: string; // 'Studio at 600 5th';
  mobilebooking: string; // 'false';
  outputvalue_mobilebooking: string; // 'false';
  multiplebooking: string; // 'false';
  outputvalue_multiplebooking: string; // 'false';
  newbooking: string; // 'false';
  outputvalue_newbooking: string; // 'false';
  discounthoursapplied: string; // '0';
  outputvalue_discounthoursapplied: string; // '0';
  organization: string; // '5';
  outputvalue_organization: string; // 'Rock CW L.L.C.';
  originalbookingid: string; // '0';
  outputvalue_originalbookingid: string; // '0';
  overagecredits: string; // '0';
  outputvalue_overagecredits: string; // '0';
  priceoverridden: string; // 'false';
  outputvalue_priceoverridden: string; // 'false';
  productgroup: string; // '26';
  outputvalue_productgroup: string; // 'Meeting Rooms';
  recordcounter: string; // '1';
  outputvalue_recordcounter: string; // '1';
  recurringbooking: string; // 'false';
  outputvalue_recurringbooking: string; // 'false';
  relatedspaceproducts: string; // null;
  outputvalue_relatedspaceproducts: string; // null;
  reservationdiscount: string; // '0';
  outputvalue_reservationdiscount: string; // '0';
  resource: string; // '267';
  outputvalue_resource: string; // 'Times Square';
  resourcename: string; // 'Times Square';
  outputvalue_resourcename: string; // 'Times Square';
  startdate: string; // '2019-11-13T11:30:00-04:00';
  outputvalue_startdate: string; // '2019-11-13T11:30:00-04:00';
  start_date: string; // '2019-11-13T11:30:00-04:00';
  outputvalue_start_date: string; // '2019-11-13T11:30:00-04:00';
  statusname: string; // '1';
  outputvalue_statusname: string; // '1';
  text: string; // 'New Booking';
  outputvalue_text: string; // 'New Booking';
  updatedate: string; // '2019-11-12T17:42:06-04:00';
  outputvalue_updatedate: string; // '2019-11-12T17:42:06-04:00';
  updatedby: string; // 'Johan Skattum';
  outputvalue_updatedby: string; // 'Johan Skattum';
  webbooking: string; // 'false';
  outputvalue_webbooking: string; // 'false';
};

export type EssensysBookingChargeType = {
  accountname: string; // "5529",
  outputvalue_accountname: string; // "Conway MacKenzie, Inc.",
  booking: string; // "19738",
  outputvalue_booking: string; // "New Booking",
  chargesheetid: string; // "55201",
  outputvalue_chargesheetid: string; // "55201",
  clienttype: string; // "12",
  outputvalue_clienttype: string; // "Tenant",
  createdby: string; // "Tishman Testing",
  outputvalue_createdby: string; // "Tishman Testing",
  creationdate: string; // "2020-08-31T12:44:40-04:00",
  outputvalue_creationdate: string; // "2020-08-31T12:44:40-04:00",
  delegatecharge: string; // "false",
  outputvalue_delegatecharge: string; // "false",
  datefrom: string; // "2020-08-31T16:30:00-04:00",
  outputvalue_datefrom: string; // "2020-08-31T16:30:00-04:00",
  locationname: string; // "1",
  outputvalue_locationname: string; // "Studio at 600 5th",
  location: string; // "1",
  outputvalue_location: string; // "Studio at 600 5th",
  markup: string; // "0",
  outputvalue_markup: string; // "0",
  markuptype: string; // "0",
  outputvalue_markuptype: string; // "0",
  meetingstatus: string; // "1",
  outputvalue_meetingstatus: string; // "1",
  noofcredits: string; // "0.00",
  outputvalue_noofcredits: string; // "0.00",
  product: string; // "259",
  outputvalue_product: string; // "Flatiron | 7 Seats",
  productgroup: string; // "26",
  outputvalue_productgroup: string; // "Meeting Rooms",
  quantity: string; // "1",
  outputvalue_quantity: string; // "1",
  recordcounter: string; // "1",
  outputvalue_recordcounter: string; // "1",
  referencetext: string; // "4:30 p.m. to 5:00 p.m.",
  outputvalue_referencetext: string; // "4:30 p.m. to 5:00 p.m.",
  tax: string; // "3",
  outputvalue_tax: string; // "0",
  taxrate: string; // "0",
  outputvalue_taxrate: string; // "0%",
  dateto: string; // "2020-08-31T17:00:00-04:00",
  outputvalue_dateto: string; // "2020-08-31T17:00:00-04:00",
  totalcredits: string; // "0",
  outputvalue_totalcredits: string; // "0",
  totalprice: string; // "100",
  outputvalue_totalprice: string; // "100",
  type: string; // "Reservations",
  outputvalue_type: string; // "Reservations",
  unitprice: string; // "100",
  outputvalue_unitprice: string; // "100",
  updatedate: string; // "2020-08-31T12:44:40-04:00",
  outputvalue_updatedate: string; // "2020-08-31T12:44:40-04:00",
  updatedby: string; // "Tishman Testing",
  outputvalue_updatedby: string; // "Tishman Testing"
};

export type EssensysRecordTypeType = {
  active: string; // 'true';
  outputvalue_active: string; // 'true';
  alias: string; // 'Account';
  outputvalue_alias: string; // 'Account';
  createdby: string; // 'sa';
  outputvalue_createdby: string; // 'sa';
  creationdate: string; // '2014-02-19T09:12:24+00:00';
  outputvalue_creationdate: string; // '2014-02-19T09:12:24+00:00';
  description: string; // 'Account';
  outputvalue_description: string; // 'Account';
  masterrecordtype: string; // 'true';
  outputvalue_masterrecordtype: string; // 'true';
  module: string; // '1';
  outputvalue_module: string; // 'Account';
  pagelayout: string; // '51';
  outputvalue_pagelayout: string; // 'Account';
  recordcounter: string; // '1';
  outputvalue_recordcounter: string; // '1';
  recordtypelabel: string; // 'Account';
  outputvalue_recordtypelabel: string; // 'Account';
  recordtypename: string; // 'Account';
  outputvalue_recordtypename: string; // 'Account';
  recordtypeid: string; // '1';
  outputvalue_recordtypeid: string; // '1';
  updatedate: string; // '2014-02-19T09:12:24+00:00';
  outputvalue_updatedate: string; // '2014-02-19T09:12:24+00:00';
  updatedby: string; // 'sa';
  outputvalue_updatedby: string; // 'sa';
};
export type EssensysResponseType = {
  id: string;
  status: string;
  providername: string;
  datetimeutc: string;
};

export enum EssensysAccountTypesEnum {
  Customer = -1,
}

export enum EssensysRecordTypeEnum {
  Account = '1',
  Prospect = '100',
}

export type EssensysProductResponseType = EssensysResponseType & {
  products: EssensysProductType[];
};

export type EssensysCalendarResponseType = EssensysResponseType & {
  calendars: EssensysCalendarBookingType[];
};

export type EssensysClientTypesResponseType = EssensysResponseType & {
  clienttypes: EssensysClientTypeType[];
};

export type EssensysRecordTypesResponseType = EssensysResponseType & {
  recordtypes: EssensysRecordTypeType[];
};

export type EssensysAssignedClientTypesResponseType = EssensysResponseType & {
  assignedclienttypes: EssensysAssignedClientTypeType[];
};

export type EssensysContactsResponseType = EssensysResponseType & {
  contacts: EssensysContactType[];
};

export type EssensysLicencesResponseType = EssensysResponseType & {
  licences: EssensysLicenceType[];
};

export type EssensysCurrencyResponseType = EssensysResponseType & {
  currencys: EssensysCurrencyType[];
};

export type EssensysLocationResponseType = EssensysResponseType & {
  locations?: EssensysLocationType[];
};

export type EssensysInvoicesResponseType = EssensysResponseType & {
  invoices: EssensysInvoiceType[];
};

export type EssensysAccountResponseType = EssensysResponseType & {
  accounts: EssensysAccountType[];
};

export type EssensysPaymentsResponseType = EssensysResponseType & {
  payments: EssensysPaymentType[];
};

export type EssensysOrganizationResponseType = EssensysResponseType & {
  organizations?: EssensysOrganizationType[];
};

export type EssensysTimezoneResponseType = EssensysResponseType & {
  timezones?: EssensysTimezoneType[];
};
export type EssensysPaymentTypesResponseType = EssensysResponseType & {
  paymenttypes?: EssensysPaymentTypeType[];
};

export enum EssensysBookingTypeEnum {
  Confirmed = 1,
  Provisional = 2,
  Unassigned = 3,
  Cancelled = 4,
}

export enum EssensysChannelTypeEnum {
  Parent = 'parent',
  Location = 'location',
  Company = 'company',
}

export type EssensysBookingRequestType = {
  contactid: number; // 1568,
  resourceid: number; // 263,
  bookingtitle: string; // "New Booking",
  notes?: string;
  type: EssensysBookingTypeEnum; // 1 = confirmed, 2 = provisional,
  startdatetime: string; // "2020/05/10 11:30:00",
  enddatetime: string; // "2020/05/10 12:30:00",
  usecredits: boolean; // true,
  noofcredits?: number; // 12,
  overagecredits?: number; // 2,
  price?: number; // 0.00 when usecredits = true or an amount when false ,
  overageproductid?: number; // 282
};

export type EssensysConfirmProvisionalBookingRequestType = {
  bookingid: number; // 642,
  overageproductid: number; // 282
};

// todo: determine what all the status codes are
export enum EssenysBookingResponseStatusEnum {
  Success = 1,
  Unknown2 = 2,
  ContactDoesNotExist = 3,
  Unknown4 = 4,
  Unknown5 = 5,
  ResourceDoesNotExist = 6,
  InvalidAccountType = 7,
}

export type EssensysBookingResponseType = {
  status: EssenysBookingResponseStatusEnum; // 1,
  statusmessage: string; // "Booking completed successfully",
  bookingid: number; // 680
  overageproductid: number;
};

export enum EssensysCancelBookingStatusEnum {
  Success = 1,
  AlreadyCancelled = 2,
  InvalidBookingId = 3,
}

export type EssensysCancelBookingResponseType = {
  status: EssensysCancelBookingStatusEnum;
  statusmessage: string;
  refundbooking: boolean;
};

export type EssensysGroupRoleType = {
  _id: LaneType.UUID;
  name: string;
  externalId: LaneType.UUID;
  externalType: 'Channel';
};

export type EssensysSyncUserGroupRoleType = {
  _id: LaneType.UUID;
  isPublic: boolean;
  groupRoleId: LaneType.UUID;
  externalId: string;
  externalType: 'Channel';
  name: string;
  settings: {
    _Essensys: EssensysGroupRoleSettingsType;
  };
};

export type EssensysPaymentTypeType = {
  createdby: string; // 'sa',
  outputvalue_createdby: string; // 'sa';
  currency: string; // '0';
  incbacstransfer: string; // 'false';
  outputvalue_incbacstransfer: string; // 'false';
  invoicetext: string; // 'This invoice is due for immediate payment';
  outputvalue_invoicetext: string; // 'This invoice is due for immediate payment';
  paymenttypename: string; // 'Cash';
  outputvalue_paymenttypename: string; // 'Cash';
  paymenttypeid: string; // '1';
  outputvalue_paymenttypeid: string; //  '1';
  recordcounter: string; // '1';
  outputvalue_recordcounter: string; //  '1';
  updatedate: string; // '2005-06-06T16:19:56+00:00';
  outputvalue_updatedate: string; // '2005-06-06T16:19:56+00:00';
  updatedby: string; // 'sa';
  outputvalue_updatedby: string; //  'sa';
};

export type EssensysPaymentType = {
  accountname: string;
  glcode: number;
  paymentdate: string;
  paymenttype: number;
  reference: string;
  allocateto: string;
  amountpaid: number;
  paymentcurrencycode: string;
};

export enum StudioGroupRoleNameEnum {
  StudioTeam = 'Studio Team',
  StudioPrivate = 'Studio Private',
  SquadMember = 'Squad Member',
  FlexMember = 'Flex Member',
  External = 'External',
  Tenant = 'Tenant',
  Invalid = 'Invalid',
}

export enum PortalAccessLevelEnum {
  Mid = 2,
  Admin = 1,
  Basic = 0,
}

export enum PaymentProcessorEnum {
  PlacePay = 'PlacePay',
  Stripe = 'Stripe',
}

export enum CompanyGroupRoleNameEnum {
  Mid = 'Studio Mid',
  Basic = 'Studio Basic',
  Admin = 'Studio Admin',
  Invalid = 'Invalid',
}

// note: that these are dynamic in the Essensys platform and will need to be
// mapped for new clients
export enum EssensysPaymentTypesEnum {
  Cash = 1,
  Cheque = 2,
  CreditCard = 3,
  DirectDebit = 4,
  StandingOrder = 6,
  BankTransferACH = 7,
}

export type EssensysInvoiceClientType = {
  _id: string;
  number: string;
  accountId: string;
  accountName: string;
  approved: boolean;
  clientTypeId: number;
  clientTypeName: string;
  currencyCode: string;
  startDate: Date;
  description: string;
  emailAddress: string[];
  endingBalance: number;
  outstanding: number;
  bfwd: number;
  gross: number;
  net: number;
  endDate: Date;
  location: string;
  locationName: string;
  paid: boolean;
  organizationId: string;
  organizationName: string;
  tax: string;
};

export type EssensysUserLoginType = {
  _id: string;
  userLoginId: string;
  data: EssensysContactType;
  foundBy: 'contact' | 'email';
};

export const EssensysDefaultImageUrls: string[] = [
  // 'Location', // Hash if needed
  '/SPACECONTROL/v2/Themes/Hubcreate-Modern/img/Default/avatar.gif', // ef7782b5e76e2ad400a124c839c77725
  '/SPACECONTROL/v2/Controls/img/no_image.jpg', // 7c929382d684a762bf57b518285c720e
];

export type EssensysAPIResponse<TKey extends string, T> = {
  response: {
    id: string;
    status: string;
    providername: string;
    datetimeutc: string;
  } & {
    [key in TKey]: T[];
  };
};

// NOTE: Not quite a type, but neccessary for
// timezone conversions
export const ESSENSYS_TIMEZONEID_TO_IANA = {
  '1': 'Etc/GMT+12',
  '2': 'Etc/GMT+11',
  '3': 'America/Adak',
  '4': 'Pacific/Honolulu',
  '5': 'Pacific/Marquesas',
  '6': 'America/Anchorage',
  '7': 'Etc/GMT+9',
  '8': 'America/Tijuana',
  '9': 'Etc/GMT+8',
  '10': 'America/Los_Angeles',
  '11': 'America/Phoenix',
  '12': 'America/Chihuahua',
  '13': 'America/Ojinaga',
  '14': 'America/Bahia_Banderas',
  '15': 'America/Chicago',
  '16': 'Pacific/Easter',
  '17': 'America/Mexico_City',
  '18': 'America/Regina',
  '19': 'America/Lima',
  '20': 'America/Merida',
  '21': 'America/New_York',
  '22': 'America/Port-au-Prince',
  '23': 'America/Havana',
  '24': 'America/Indiana/Indianapolis',
  '25': 'America/Cayman',
  '26': 'America/Asuncion',
  '27': 'America/Halifax',
  '28': 'America/Caracas',
  '29': 'America/Cuiaba',
  '30': 'America/Manaus',
  '31': 'America/Santiago',
  '32': 'America/St_Johns',
  '33': 'America/Araguaina',
  '34': 'America/Bahia',
  '35': 'America/Cayenne',
  '36': 'America/Argentina/Buenos_Aires',
  '37': 'America/Godthab',
  '38': 'America/Montevideo',
  '39': 'America/Punta_Arenas',
  '40': 'America/Miquelon',
  '41': 'America/Bahia',
  '42': 'Etc/GMT+2',
  '43': 'America/Noronha',
  '44': 'Atlantic/Azores',
  '45': 'Atlantic/Cape_Verde',
  '46': 'Etc/UTC',
  '47': 'Africa/Casablanca',
  '48': 'Europe/London',
  '49': 'Atlantic/Reykjavik',
  '50': 'Europe/Amsterdam',
  '51': 'Europe/Budapest',
  '52': 'Europe/Paris',
  '53': 'Europe/Warsaw',
  '54': 'Africa/Lagos',
  '55': 'Asia/Amman',
  '56': 'Europe/Athens',
  '57': 'Asia/Beirut',
  '58': 'Africa/Cairo',
  '59': 'Europe/Chisinau',
  '60': 'Asia/Damascus',
  '61': 'Asia/Gaza',
  '62': 'Africa/Maputo',
  '63': 'Europe/Helsinki',
  '64': 'Asia/Jerusalem',
  '65': 'Europe/Kaliningrad',
  '66': 'Africa/Khartoum',
  '67': 'Africa/Tripoli',
  '68': 'Africa/Windhoek',
  '69': 'Asia/Baghdad',
  '70': 'Europe/Istanbul',
  '71': 'Asia/Riyadh',
  '72': 'Europe/Minsk',
  '73': 'Europe/Moscow',
  '74': 'Africa/Nairobi',
  '75': 'Asia/Tehran',
  '76': 'Asia/Dubai',
  '77': 'Europe/Astrakhan',
  '78': 'Asia/Baku',
  '79': 'Europe/Samara',
  '80': 'Indian/Mauritius',
  '81': 'Europe/Saratov',
  '82': 'Asia/Tbilisi',
  '83': 'Asia/Yerevan',
  '84': 'Asia/Kabul',
  '85': 'Asia/Ashgabat',
  '86': 'Asia/Yekaterinburg',
  '87': 'Asia/Karachi',
  '88': 'Asia/Kolkata',
  '89': 'Asia/Colombo',
  '90': 'Asia/Kathmandu',
  '91': 'Asia/Almaty',
  '92': 'Asia/Dhaka',
  '93': 'Asia/Omsk',
  '94': 'Asia/Yangon',
  '95': 'Asia/Bangkok',
  '96': 'Asia/Barnaul',
  '97': 'Asia/Hovd',
  '98': 'Asia/Krasnoyarsk',
  '99': 'Asia/Novosibirsk',
  '100': 'Asia/Tomsk',
  '101': 'Asia/Shanghai',
  '102': 'Asia/Irkutsk',
  '103': 'Asia/Singapore',
  '104': 'Australia/Perth',
  '105': 'Asia/Taipei',
  '106': 'Asia/Ulaanbaatar',
  '107': 'Asia/Pyongyang',
  '108': 'Australia/Eucla',
  '109': 'Asia/Chita',
  '110': 'Asia/Tokyo',
  '111': 'Asia/Seoul',
  '112': 'Asia/Yakutsk',
  '113': 'Australia/Adelaide',
  '114': 'Australia/Darwin',
  '115': 'Australia/Brisbane',
  '116': 'Australia/Sydney',
  '117': 'Pacific/Guam',
  '118': 'Australia/Hobart',
  '119': 'Asia/Vladivostok',
  '120': 'Australia/Lord_Howe',
  '121': 'Pacific/Bougainville',
  '122': 'Asia/Sakhalin',
  '123': 'Asia/Magadan',
  '124': 'Pacific/Norfolk',
  '125': 'Asia/Sakhalin',
  '126': 'Pacific/Noumea',
  '127': 'Asia/Anadyr',
  '128': 'Pacific/Auckland',
  '129': 'Etc/GMT-12',
  '130': 'Pacific/Fiji',
  '131': 'Asia/Anadyr',
  '132': 'Pacific/Chatham',
  '133': 'Etc/GMT-13',
  '134': 'Pacific/Tongatapu',
  '135': 'Pacific/Pago_Pago',
  '136': 'Pacific/Kiritimati',
};

export type EssensysUser = {
  row_num: string;
  acceptedprivacypolicy: string;
  approvallevel: string;
  approvallocations: string | null;
  approvalpercent: string;
  approvalpricepercent: string;
  canapprovelicences: string;
  cancountersign: string;
  canseeprospects: string;
  checklistdate: string;
  checklisttasks: string;
  checklisttaskscomplete: string;
  companyname: string;
  country: string;
  creationdate: string;
  currencyid: string;
  currencycode: string;
  currentlocation: string;
  discontinued: string;
  emailaddress: string;
  emailsignature: string;
  firstname: string;
  fullname: string;
  userlastlogon: string;
  lastname: string;
  ud_listoflocations: string;
  locationids: string;
  locations: string;
  nickelleduserid: string;
  ntaccount: string;
  optedintocontact: string;
  organizationids: string;
  pictureurl: string;
  output_pictureurl: string;
  recordcounter: string;
  recordtype: string;
  useraccesslevel: string;
  showchecklist: string;
  phoneno: string;
  tier3: string;
  timezone: string;
  timezoneoffset: string;
  updatedate: string;
  updatedby: string;
  usemyemailsignature: string;
  username: string;
  userid: string;
  utctimezoneoffset: string;
};

export const ESSENSYS_DATE_TIME_FORMAT = "yyyy-LL-dd'T'HH:mm:ss'+00:00'";
export const ESSENSYS_DATE_TIME_INPUT_FORMAT = "yyyy-LL-dd'T'TT";
export const ESSENSYS_DATE_TIME_DAY_PASS_FORMAT = "yyyy-LL-dd'T'HH:mm:ss";

export type AccountContactPair = {
  account: EssensysAccountType;
  contact: EssensysContactType;
  clientType: EssensysClientTypeType;
};

export type LaneEssensysCalendarBookingType = {
  interactionId: LaneType.UUID;
  interactionFeatures: {
    EssensysProduct: LaneFeatureType.EssensysProductInteraction;
    Reservable: LaneFeatureType.ReservableInteraction;
  };
  contentId: LaneType.UUID;
  userId: LaneType.UUID;
  channelId: LaneType.UUID;
  startDate: Date;
  endDate: Date;
  status: string;
  reservationId?: LaneType.UUID;
  features: FeatureInstance<LaneFeatureType.EssensysProduct>[];
};

export type EssensysCalendarSyncMessageType = {
  essensysCalendar?: EssensysCalendarBookingType;
  laneCalendar?: LaneEssensysCalendarBookingType;
};

export type EssensysGroupRoleSettingsType = {
  locationId?: string;
  accountId?: string;
  clientTypeId?: string;
};
