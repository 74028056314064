import {
  INTERACTION_ACCEPTED,
  INTERACTION_APPROVED,
  INTERACTION_ARRIVED,
  INTERACTION_ASSIGNED,
  INTERACTION_AWAITING_RESPONSE,
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED,
  INTERACTION_COMPLETE,
  INTERACTION_CONFIRMED,
  INTERACTION_CREATED,
  INTERACTION_DECLINED,
  INTERACTION_DELETED,
  INTERACTION_FAILED,
  INTERACTION_IN_PROGRESS,
  INTERACTION_NEEDS_MORE_INFO,
  INTERACTION_ON_HOLD,
  INTERACTION_OPEN,
  INTERACTION_REJECTED,
  INTERACTION_REOPEN,
  INTERACTION_RESENT,
  INTERACTION_UNASSIGNED,
  INTERACTION_UPCOMING,
  INTERACTION_WAITING,
} from 'lane-shared/helpers/constants/interactions';

export function getInteractionColor(status: string) {
  switch (status) {
    case INTERACTION_CREATED:
    case INTERACTION_UPCOMING:
      return 'blue';
    case INTERACTION_OPEN:
    case INTERACTION_REOPEN:
    case INTERACTION_IN_PROGRESS:
    case INTERACTION_ARRIVED:
      return 'meadow';
    case INTERACTION_ON_HOLD:
    case INTERACTION_ASSIGNED:
    case INTERACTION_WAITING:
    case INTERACTION_AWAITING_RESPONSE:
    case INTERACTION_NEEDS_MORE_INFO:
    case INTERACTION_UNASSIGNED:
    case INTERACTION_RESENT:
      return 'honey';
    case INTERACTION_REJECTED:
    case INTERACTION_FAILED:
    case INTERACTION_DELETED:
    case INTERACTION_CANCELLED:
    case INTERACTION_DECLINED:
      return 'red';
    case INTERACTION_ACCEPTED:
    case INTERACTION_APPROVED:
    case INTERACTION_COMPLETE:
    case INTERACTION_CONFIRMED:
      return 'green';
    case INTERACTION_CLOSED:
      return 'gray';
    default:
      return 'gray';
  }
}
