import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import Address from '../../../../properties/baseTypes/Address';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import ServiceRequestFloor from './ServiceRequestFloor';

const properties: PropertiesInterface = {
  name: {
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 2,
      },
      {
        name: 'Max',
        value: 64,
      },
    ],
  },
  address: {
    type: 'Address',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  floors: {
    type: 'ServiceRequestFloor',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'ArrayMin',
        value: 1,
      },
    ],
  },
};

export class ServiceRequestBuildingType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ServiceRequestBuilding';

  friendlyName = 'Service Request Building';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      name: 'Tower A',
      address: Address.example,
      floors: [ServiceRequestFloor.example],
    };
  }

  get default(): any {
    return {
      name: '',
      address: Address.default,
      floors: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new ServiceRequestBuildingType();

Types.addType(definition);
export default definition;
