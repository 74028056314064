import { visitorManagementIsEditableProperty } from 'lane-shared/domains/visitorManagement/helpers/visitorManagementContentBuilder';
import { FeatureInstance } from 'lane-shared/types/FeatureInstance';

import objectToArray from '../../helpers/objectToArray';
import { QuantityFeatureProperties } from '../../types/features/QuantityFeatureProperties';
import { PropertyType } from '../../types/properties/Property';
import { SecurityRuleTypeEnum } from '../../types/properties/PropertySecurity';
import Features from './features';

export default function getFeatureEditableProperties(
  feature: FeatureInstance,
  forDisplay: boolean = false
): PropertyType[] | null {
  const Feature = Features[feature.type];

  if (!Feature?.interactionData) {
    return null;
  }

  return objectToArray<PropertyType>(Feature.interactionData).filter(
    property => {
      // filter out any properties the user can't update.
      // filter out any system read only properties.

      if (forDisplay && property.showControl === false) {
        return false;
      }

      if (
        property.secure?.update &&
        !property.secure.update.some(
          (secure: any) => secure.type === SecurityRuleTypeEnum.Owner
        )
      ) {
        return false;
      }

      if (
        property.secure?.read?.some(
          (secure: any) => secure.type === SecurityRuleTypeEnum.System
        )
      ) {
        return false;
      }

      // special case for Quantity feature where maxPerUser is set to 1
      if (
        feature.type === Features.Quantity.name &&
        property.name === 'quantity' &&
        (feature.feature as QuantityFeatureProperties).max === 1
      ) {
        return false;
      }

      if (feature.type === Features.VisitorManagement.name) {
        return visitorManagementIsEditableProperty(property, feature);
      }

      return true;
    }
  );
}
