import React, { useEffect } from 'react';

import { ErrorMessage, Loading } from 'components';

import { LaneType } from 'common-types';
import { ShopifyMultipassNoVerifiedEmailError } from 'activate-errors';
import useShopifyMultipassIntegration from 'lane-shared/hooks/useShopifyMultipassIntegration';
import { ContentType } from 'lane-shared/types/content/Content';

import EmailVerificationModal from './EmailVerificationModal';
import history from 'helpers/history';
import useIsAdminView from 'hooks/useIsAdminView';

type Props = {
  content: ContentType;
};

export default function ShopifyMultipassPage({ content }: Props) {
  const [isAdminView] = useIsAdminView();

  const channelIntegrationId: LaneType.UUID = content?.integration?._id;

  const {
    triggerShopifyMultipassFlow,
    createNewAccount,
    error,
    loading,
    isEmailVerificationModalOpen,
    closeEmailVerificationModal,
  } = useShopifyMultipassIntegration({
    isAdminView,
    openUrl,
  });

  const unverifiedEmailError = error?.message?.includes(
    ShopifyMultipassNoVerifiedEmailError.errorName
  );

  useEffect(() => {
    triggerShopifyMultipassFlow(content);
  }, []);

  function openUrl(loginUrl: LaneType.Url) {
    window.open(loginUrl);
    history.goBack();
  }

  if (loading) {
    return <Loading fullscreen />;
  }

  if (error && !unverifiedEmailError) {
    return <ErrorMessage error={error} />;
  }

  async function onCloseModal() {
    await triggerShopifyMultipassFlow(content);
  }

  function onExitModal() {
    closeEmailVerificationModal();
    history.goBack();
  }

  return (
    <EmailVerificationModal
      channelIntegrationId={channelIntegrationId}
      unverifiedEmailError={unverifiedEmailError}
      isOpen={isEmailVerificationModalOpen}
      onClose={onCloseModal}
      onExit={onExitModal}
      onCreateNewAccount={createNewAccount}
    />
  );
}
