import { UserType } from '../types/User';
import getPrimaryEmail from './user/getPrimaryEmail';

export function getUserForSentry(user: UserType) {
  if (!user) {
    return undefined;
  }

  const { _id, name, logins } = user;
  const primaryLoginKey = getPrimaryEmail(logins);

  return { id: _id, name, email: primaryLoginKey || undefined };
}
