import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getUserIntegration } from '../graphql/user';

/**
 * A useful hook for getting current users integrations.
 */
export default function useUserIntegrationQuery({
  channelIntegrationId,
  pollInterval,
  fetchPolicy = 'cache-and-network',
}: any) {
  const [fetchUserIntegration, { data, loading, error, called }] = useLazyQuery(
    getUserIntegration,
    {
      client: getClient(),
      fetchPolicy,
      pollInterval,
    }
  );

  useEffect(() => {
    if (channelIntegrationId) {
      fetchUserIntegration({ variables: { channelIntegrationId } });
    }
  }, [channelIntegrationId]);

  function userIntegrationRefetch() {
    fetchUserIntegration({ variables: { channelIntegrationId } });
  }

  return {
    userIntegration: data?.userIntegration,
    loading,
    error,
    called,
    userIntegrationRefetch,
  };
}
