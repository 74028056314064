import * as yup from 'yup';

import { BLOCK_LINK_TYPES } from 'lane-shared/helpers/constants/blocks';

import { frequencies } from '../../renderers/v5/features/Reset';
import validateBlockURI from '../validateBlockURI';
import validateFeature from '../validateFeature';
import validateProperty from '../validateProperty';

export default yup.object().shape({
  resetPeriod: yup.string().required().oneOf(frequencies),
  lastReset: yup.date().nullable(),
  nextReset: yup.date().nullable(),
  renderer: yup.number().min(0).max(256).required(),
  // todo: block validation.
  block: yup
    .object()
    .default(null)
    .nullable()
    .test(
      'valid-block',
      'The Block has some invalid properties',
      (value: any) => {
        if (!value) return true;
        const children = value?.properties?.children ?? [];
        for (const child of children) {
          if (child.link && child.link.type === BLOCK_LINK_TYPES.WEB_IN_APP)
            validateBlockURI.validateSync(child.link.uri);
        }
        return true;
      }
    ),
  properties: yup
    .object()
    .nullable()
    .test(
      'valid-properties',
      '${path} has invalid property defined.',
      value => {
        if (value) {
          Object.values(value).forEach(property =>
            validateProperty.validateSync(property)
          );
        }

        return true;
      }
    ),
  features: yup
    .array()
    .nullable()
    .when('version', {
      is: 5,
      then: yup.array().of(validateFeature),
      otherwise: yup.array(),
    }),
  // todo: data validation.
  data: yup.object().nullable(),
  // todo: state validation
  state: yup.object().nullable(),
  // todo: settings validation
  settings: yup.object().nullable(),
  integration: yup
    .object()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup.string(),
    })
    .nullable(),
});
