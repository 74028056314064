import parseDateTime from '../../../helpers/dates/parseDateTime';
import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';

export enum TimeResetFrequencyEnum {
  Never = 'Never',
  Hourly = 'Hourly',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export const frequencies = Object.values(TimeResetFrequencyEnum);

export const resetDateFunctions = {
  [TimeResetFrequencyEnum.Never]: () => undefined,
  [TimeResetFrequencyEnum.Hourly]: (date: any, hours: any) =>
    parseDateTime(date)!.plus({ hours }).toJSDate(),
  [TimeResetFrequencyEnum.Daily]: (date: any, days: any) =>
    parseDateTime(date)!.plus({ days }).toJSDate(),
  [TimeResetFrequencyEnum.Weekly]: (date: any, weeks: any) =>
    parseDateTime(date)!.plus({ weeks }).toJSDate(),
  [TimeResetFrequencyEnum.Monthly]: (date: any, months: any) =>
    parseDateTime(date)!.plus({ months }).toJSDate(),
  [TimeResetFrequencyEnum.Yearly]: (date: any, years: any) =>
    parseDateTime(date)!.plus({ years }).toJSDate(),
};

const Reset: FeatureDefinition = {
  name: FeatureNameEnum.Reset,
  friendlyName: 'web.content.features.reset.friendlyName',
  description: 'web.content.features.reset.description',
  requiresInteraction: true,
  properties: {
    date: {
      friendlyName: 'web.content.features.reset.properties.date.friendlyName',
      type: 'DateTime',
      editable: true,
      default: new Date(),
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    period: {
      friendlyName: 'Reset Period',
      type: 'String',
      editable: true,
      default: TimeResetFrequencyEnum.Never,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: frequencies,
        },
      ],
    },
  },
};

export default Reset;
