import { EssensysProductType } from '../Essensys';

export enum EssensysProductInteractionType {
  PointOfSale = 'Point of Sale',
  OnlinePlan = 'Online Plan',
}

export type EssensysProductFeatureProperties = {
  productInteractionType: EssensysProductInteractionType;

  // Point of Sale
  productId: string;
  syncPrice: boolean;
  syncName: boolean;
  ignorePublish: boolean;
  ignoreEndDate: boolean;
  productData: EssensysProductType;

  // Online Plan
};
