import React from 'react';

import { VisitorBlocks } from 'lane-shared/domains/visitorManagement/types/VisitorBlocks';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { ContentType } from 'lane-shared/types/content/Content';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import VisitorManagement from '.';

export default function VisitorBlockHandler({
  propertyKey,
  show,
  value,
  content,
  properties,
  submissionCompletedAt,
  submitAttempted,
  isPreview = false,
  onChange,
}: {
  propertyKey: string;
  show: boolean;
  value: any;
  content: ContentType;
  properties: VisitorManagementFeatureProperties;
  submissionCompletedAt?: Date;
  submitAttempted?: boolean;
  isPreview?: boolean;
  onChange: (() => null) & ((value: any) => void);
}): React.ReactElement | null {
  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );
  function renderBlock() {
    switch (propertyKey) {
      case VisitorBlocks.Visitors:
        return VisitorManagement.AddVisitorsBlock({
          onChange,
          properties,
          value,
          show,
          isPreview,
          content,
          submissionCompletedAt,
          submitAttempted,
        });
      case VisitorBlocks.Schedule:
        return VisitorManagement.AddScheduleBlock({
          properties,
          onChange,
          content,
          value,
          submissionCompletedAt,
          show,
          isPreview,
        });
      case VisitorBlocks.HostInformation:
        return VisitorManagement.AddHostInformationBlock({
          onChange,
          show,
          properties,
          value,
          content,
          isPreview,
        });
      case VisitorBlocks.Notes:
        return visitorNotesEnabled
          ? VisitorManagement.AddNotesBlock({
              onChange,
              show,
              properties,
              submissionCompletedAt,
              isPreview,
            })
          : null;
      default:
        return null;
    }
  }

  return renderBlock();
}
