import React from 'react';

export default function IconTool() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.32 198.32">
      <defs>
        <style>{'.cls-1{fill:#ff4c55;'}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Shape_133" data-name="Shape 133">
            <path
              className="cls-1"
              d="M52.68,170.61a26.11,26.11,0,0,1-7.4-1.06,3.62,3.62,0,0,1-2.5-2.5,3.54,3.54,0,0,1,.91-3.44l15.6-15.5-1.11-7.26a.32.32,0,0,0-.28-.22l-7.4-.88a.36.36,0,0,0-.32.1l-15.59,15.5a3.73,3.73,0,0,1-3.41,1A3.65,3.65,0,0,1,28.52,154a23.94,23.94,0,0,1,7.07-26.23,26.26,26.26,0,0,1,26.68-4.53c.54-.56,1.29-1.31,2.32-2.35a1.67,1.67,0,0,1,2.34,0,1.65,1.65,0,0,1,0,2.34l-3.05,3.07a1.9,1.9,0,0,1-.21.19,1.63,1.63,0,0,1-1.68.21,23,23,0,0,0-24.24,3.57,20.67,20.67,0,0,0-6.12,22.63c.39.23.55.19.64.1L47.85,137.5a3.79,3.79,0,0,1,3-1l7.4.89a3.62,3.62,0,0,1,3.15,3l1.18,7.07a3.52,3.52,0,0,1-1,3.07L46,166l.17.43a22.82,22.82,0,0,0,23.51-6.69,20.52,20.52,0,0,0,2.85-23.21,1.67,1.67,0,0,1,.3-1.94L113.6,93.8a1.65,1.65,0,0,1,2.34,2.34L76,136a23.83,23.83,0,0,1-3.83,25.83A26,26,0,0,1,52.68,170.61Z"
            />
          </g>
          <g id="Shape_133-2" data-name="Shape 133">
            <path
              className="cls-1"
              d="M125,86.42a1.65,1.65,0,0,1-1.17-2.82l11.49-11.49a1.65,1.65,0,0,1,1.94-.29A20.53,20.53,0,0,0,160.44,69a22.85,22.85,0,0,0,6.69-23.51l-1.61-1.34,1.18,1.16-15.5,15.6a3.54,3.54,0,0,1-3.08,1l-7.07-1.18a3.62,3.62,0,0,1-3-3.15l-.88-7.4a3.72,3.72,0,0,1,1-3l15.51-15.6a.42.42,0,0,0,.09-.37A20.38,20.38,0,0,0,131,37a23,23,0,0,0-3.56,24.23,1.67,1.67,0,0,1-.35,1.84l-12.4,12.38a1.67,1.67,0,0,1-2.34,0,1.65,1.65,0,0,1,0-2.34L124,61.52a26.29,26.29,0,0,1,4.54-26.68,24,24,0,0,1,26.23-7.07,3.65,3.65,0,0,1,2.33,2.66,3.69,3.69,0,0,1-1,3.4L140.59,49.44a.4.4,0,0,0-.1.31l.89,7.4a.29.29,0,0,0,.22.28l7.07,1.18L164.35,43a3.56,3.56,0,0,1,6,1.59,26.15,26.15,0,0,1-7.68,26.91,23.82,23.82,0,0,1-25.83,3.84L126.14,85.94A1.64,1.64,0,0,1,125,86.42Z"
            />
          </g>
          <path
            className="cls-1"
            d="M71,125.88H71a8.92,8.92,0,0,1-6.39-2.7L58.32,117a9.06,9.06,0,0,1-2.69-6.55,9,9,0,0,1,2.69-6.4,9.37,9.37,0,0,1,12.94,0l6.23,6.16a9.17,9.17,0,0,1,0,12.94h0A9.1,9.1,0,0,1,71,125.88Zm-6.2-21.18a5.84,5.84,0,0,0-5.88,5.78,5.83,5.83,0,0,0,1.72,4.19l6.25,6.18A5.66,5.66,0,0,0,71,122.57h0a5.84,5.84,0,0,0,4.15-10l-6.25-6.19A5.51,5.51,0,0,0,64.81,104.7ZM76.33,122h0Z"
          />
          <path
            className="cls-1"
            d="M81.78,115.3a9,9,0,0,1-6.44-2.59L64.43,101.8a9.06,9.06,0,0,1-2.7-6.51,8.83,8.83,0,0,1,2.72-6.46,9.16,9.16,0,0,1,12.87,0l11,11A9,9,0,0,1,91,106.21a9,9,0,0,1-9.17,9.09ZM70.84,89.47a5.75,5.75,0,0,0-4.08,1.72A5.57,5.57,0,0,0,65,95.29a5.67,5.67,0,0,0,1.69,4.13l0,0,10.92,10.92a6.06,6.06,0,0,0,8.27,0,5.92,5.92,0,0,0,1.7-4.14,5.74,5.74,0,0,0-1.72-4.09L75,91.19A6.06,6.06,0,0,0,70.84,89.47Z"
          />
          <path
            className="cls-1"
            d="M92.26,104.65A9.05,9.05,0,0,1,85.8,102L74.87,91.05a9.12,9.12,0,0,1-2.68-6.55,8.88,8.88,0,0,1,2.7-6.39,9.35,9.35,0,0,1,12.91,0L98.72,89a9.11,9.11,0,0,1,0,12.93h0A9.07,9.07,0,0,1,92.26,104.65Zm-11-25.91a5.87,5.87,0,0,0-5.81,5.78,5.8,5.8,0,0,0,1.72,4.19L88.13,99.63a6,6,0,0,0,8.26,0h0a5.83,5.83,0,0,0,0-8.25L85.46,80.46A5.81,5.81,0,0,0,81.3,78.74Z"
          />
          <path
            className="cls-1"
            d="M100.5,91.69A9.18,9.18,0,0,1,94,89.1l-6.19-6.28a8.77,8.77,0,0,1-2.66-6.43,8.91,8.91,0,0,1,2.72-6.52,9.33,9.33,0,0,1,12.89,0l5.94,6,.23.25a1.65,1.65,0,1,1-2.5,2.15l-6-6.09a6.05,6.05,0,0,0-8.25,0,5.68,5.68,0,0,0-1.72,4.16,5.5,5.5,0,0,0,1.68,4.07l6.21,6.31a6.12,6.12,0,0,0,6.57,1.13,1.68,1.68,0,0,0,1-1.62l0-.69a1.66,1.66,0,0,1,1.64-1.67h0a1.66,1.66,0,0,1,1.66,1.64v.57a5,5,0,0,1-3,4.81A9.43,9.43,0,0,1,100.5,91.69Z"
          />
          <path
            className="cls-1"
            d="M106.43,163.16a1.67,1.67,0,0,1-1.17-.49l-29.09-29.1a1.65,1.65,0,0,1,2.33-2.33l29.1,29.09a1.66,1.66,0,0,1-1.17,2.83Z"
          />
          <path
            className="cls-1"
            d="M131.35,144.52a1.61,1.61,0,0,1-1.23-.55L123.91,137a16.09,16.09,0,0,1,.06-22.67l1.68-1.68a17.61,17.61,0,0,0,0-24.9l-10.2-10.21a5.51,5.51,0,0,0-3.9-1.64,5.84,5.84,0,0,0-4,1.64,5.43,5.43,0,0,0-1.64,3.9,5.53,5.53,0,0,0,1.64,4l8.35,8.35a1.65,1.65,0,0,1,0,2.34,1.67,1.67,0,0,1-2.34,0l-8.35-8.35a8.86,8.86,0,0,1-2.61-6.33,8.71,8.71,0,0,1,2.62-6.22,8.33,8.33,0,0,1,6.32-2.61,8.72,8.72,0,0,1,6.22,2.63L128,85.45A20.91,20.91,0,0,1,128,115l-1.68,1.68a12.78,12.78,0,0,0,0,18.06l6.27,7a1.65,1.65,0,0,1-.13,2.33A1.6,1.6,0,0,1,131.35,144.52Z"
          />
          <path
            className="cls-1"
            d="M195.57,118.56l-.19,0a1,1,0,0,1-.82-1.21,98.45,98.45,0,0,0,1.7-18.17,97.1,97.1,0,0,0-194.19,0,98.44,98.44,0,0,0,1.69,18.17,1,1,0,0,1-2,.39A99.27,99.27,0,0,1,99.16,0a99.27,99.27,0,0,1,97.43,117.72A1,1,0,0,1,195.57,118.56Z"
          />
          <path
            className="cls-1"
            d="M192.66,130.08a1.19,1.19,0,0,1-.32-.05,1,1,0,0,1-.67-1.3,96.57,96.57,0,0,0,2.89-11.4,1,1,0,1,1,2,.39,98.68,98.68,0,0,1-3,11.64A1,1,0,0,1,192.66,130.08Z"
          />
          <path
            className="cls-1"
            d="M99.19,198.32h0a101,101,0,0,1-11.29-.63,1,1,0,1,1,.24-2.06,96.8,96.8,0,0,0,11,.63h0a98.34,98.34,0,0,0,11.13-.64,1,1,0,1,1,.24,2A100.25,100.25,0,0,1,99.19,198.32Zm-33.07-5.7a1.07,1.07,0,0,1-.35-.06,99.41,99.41,0,0,1-20.34-10,1,1,0,1,1,1.12-1.74,97.14,97.14,0,0,0,19.92,9.84,1,1,0,0,1,.62,1.32A1,1,0,0,1,66.12,192.62Zm66.18,0a1,1,0,0,1-.35-2,97.1,97.1,0,0,0,19.91-9.85,1,1,0,1,1,1.12,1.73,99.45,99.45,0,0,1-20.33,10.07A1,1,0,0,1,132.3,192.59ZM28.64,168.43a1,1,0,0,1-.74-.31,100,100,0,0,1-13.82-18,1,1,0,0,1,.36-1.41,1,1,0,0,1,1.41.35,98.36,98.36,0,0,0,13.53,17.62,1,1,0,0,1,0,1.46A1,1,0,0,1,28.64,168.43Zm141.11-.07a1,1,0,0,1-.72-.29,1,1,0,0,1,0-1.46A97.93,97.93,0,0,0,182.52,149a1,1,0,0,1,1.42-.35,1,1,0,0,1,.35,1.42,99.11,99.11,0,0,1-13.8,18A1,1,0,0,1,169.75,168.36Z"
          />
          <path
            className="cls-1"
            d="M5.67,130.08a1,1,0,0,1-1-.72,101.2,101.2,0,0,1-2.95-11.64,1,1,0,0,1,.83-1.21,1,1,0,0,1,1.2.82,97.07,97.07,0,0,0,2.89,11.4A1,1,0,0,1,6,130,1.12,1.12,0,0,1,5.67,130.08Z"
          />
        </g>
      </g>
    </svg>
  );
}
