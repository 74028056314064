import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';

export enum ScheduledUnitTypesEnum {
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
}

const Scheduled: FeatureDefinition = {
  superUserOnly: true,
  name: FeatureNameEnum.Scheduled,
  friendlyName: 'Scheduled Date - Deprecated',
  requiresInteraction: true,
  description:
    'DEPRECATED - Use Reservable instead. The end user must specify a date and time when interacting with this content',
  properties: {
    unitType: {
      friendlyName: 'Time Unit',
      description: `This is the unit type that the scheduled date will be created in. i.e. 30`,
      type: 'String',
      editable: true,
      default: 'Minutes',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: Object.values(ScheduledUnitTypesEnum),
        },
      ],
    },
    units: {
      friendlyName: 'Number of Time Units',
      description: `The number of Time Units that this will be scheduled for. i.e. 30 Minutes`,
      type: 'Number',
      editable: true,
      default: 30,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: 60,
        },
      ],
    },
  },
  interactionData: {
    scheduled: {
      friendlyName: 'Scheduled Date',
      type: 'DateTimeRange',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default Scheduled;
