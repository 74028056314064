import { createContext } from 'react';

import { ApolloError } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { LaneType, LaneIntegrationType } from 'common-types';
import constructInteraction from '../../../helpers/content/constructInteraction';
import { ContentType } from '../../../types/content/Content';
import { BurstWizardState } from '../../../types/integrations/ShopifyBurst';
import { BurstFlowType } from './ShopifyBurstContextProvider';

export type ShopifyBurstContextType = {
  contentId: string | null | undefined;
  burstFlowType: BurstFlowType;
  initialWizardState: BurstWizardState;
  wizardState: BurstWizardState;
  updateWizardState: (newState: Partial<BurstWizardState>) => void;
  burstContent: any | null;
  burstContentLoading: boolean;
  burstContentError: ApolloError | Error | null | undefined;
  burstInteractions: any[];
  burstIntegrationSettings: LaneIntegrationType.ShopifyBurst | null | undefined;
  burstWizardState: BurstWizardState | null;
  burstWizardStateLoading: boolean;
  burstWizardStateError: ApolloError | Error | null | undefined;
  loading: boolean;
  error: ApolloError | Error | null | undefined;
  // todo: this is the wrong type, this is an interaction being created /
  //       updated, not a reference to an UCI
  newBurstInteraction:
    | LaneType.UserContentInteraction
    | ReturnType<typeof constructInteraction>;
  isTeamLead: boolean;
  hasSubmitOnBehalfOfPermission: boolean;
  setInteractionUser: (
    user: { _id: LaneType.UUID; name: string } | null
  ) => void;
  refetchBurstInteractions: () => Promise<void>;
  refetchDraftBurstInteractions: <TVariables, TData>(
    variables?: Partial<TVariables>
  ) => Promise<ApolloQueryResult<TData>>;
  unavailableDatesAsDateRanges: DateRangeType[];
  minDate: Date;
  maxDate: Date;
  maxCapacity: number;
  diyQuestionsContent: ContentType | undefined;
  diyQuestionsContentLoading: boolean;
  diyQuestionsContentError: Error | null | undefined;
  acknowledgementContent: ContentType | undefined;
  acknowledgementContentLoading: boolean;
  acknowledgementContentError: Error | null | undefined;
  burstNameContent: ContentType | undefined;
  burstNameContentLoading: boolean;
  burstNameContentError: Error | null | undefined;
  questionnaireContent: ContentType | undefined;
  questionnaireContentLoading: boolean;
  questionnaireContentError: Error | null | undefined;
  specialRequestContent: ContentType | undefined;
  specialRequestContentLoading: boolean;
  specialRequestContentError: Error | null | undefined;
};

const ShopifyBurstContext = createContext({} as ShopifyBurstContextType);

export default ShopifyBurstContext;
