import React from 'react';

import { amex, discover, mastercard, visa } from '../helpers/acceptedCardIcons';

// @ts-ignore - issue with typescript resolving scss paths
import styles from './AcceptedCardsHeader.scss';

export function AcceptedCardsHeader() {
  return (
    <div className={styles.acceptedCardsHeader}>
      <img src={visa} alt="visa-icon" />
      <img src={mastercard} alt="mastercard-icon" />
      <img src={amex} alt="amex-icon" />
      <img src={discover} alt="discover-icon" />
    </div>
  );
}
