import React from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/typography';
import { Button, DatePickerButton, Icon } from 'design-system-web';
import { useReservableCalendarView } from './hooks/useReservableCalendarView';
import styles from './styles.scss';
import { CalendarView } from './Calendar';

const TRANSLATION_KEYS = {
  today: 'web.admin.channel.reservableManagement.calendar.button.today',
  unavailable:
    'web.admin.channel.reservableManagement.calendar.legend.unavailable',
  temporaryPost:
    'web.admin.channel.reservableManagement.calendar.legend.temporaryPost',
  bookingsError:
    'web.admin.channel.reservableManagement.calendar.fetchBookings.error',
};

function ReservableCalendarLegend() {
  const { t } = useTranslation();

  return (
    <div className={styles.legend}>
      <div className={styles.row}>
        <span className={styles.unavailable} />
        <span>{t(TRANSLATION_KEYS.unavailable)}</span>
      </div>
      <div className={styles.row}>
        <Icon name="alarm-clock" />
        <span>{t(TRANSLATION_KEYS.temporaryPost)}</span>
      </div>
    </div>
  );
}

export function ReservableCalendarView({
  channelId,
  channelTimeZone,
}: {
  channelId: string;
  channelTimeZone: string;
}) {
  const { t } = useTranslation();
  const {
    data: { calendarRef, referenceDate, events, resources, error },
    handlers: { setCalendarDate, refetchReservablesOnChannel },
  } = useReservableCalendarView({ channelId, channelTimeZone });

  if (error) {
    window.Toast.show(<Text>{t(TRANSLATION_KEYS.bookingsError)}</Text>);
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Button
            type="button"
            variant="tertiary"
            size="large"
            onClick={() => {
              const todayDate = DateTime.now()
                .setZone(channelTimeZone)
                .startOf('day')
                .toJSDate();

              setCalendarDate(todayDate);
            }}
          >
            {t(TRANSLATION_KEYS.today)}
          </Button>
          <DatePickerButton
            hideLabel
            value={referenceDate}
            timeZone={channelTimeZone}
            onChange={(date: Date) => {
              const dateWithTimeZone = DateTime.fromJSDate(date)
                .setZone(channelTimeZone)
                .startOf('day')
                .toJSDate();
              setCalendarDate(dateWithTimeZone);
            }}
          />
        </div>
        <ReservableCalendarLegend />
      </div>
      <CalendarView
        ref={calendarRef}
        timeZone={channelTimeZone}
        events={events}
        resources={resources}
        refetchReservablesOnChannel={refetchReservablesOnChannel}
      />
    </div>
  );
}
