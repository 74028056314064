import { useMemo } from 'react';

import { WORKFLOW_ANY_STATUS } from 'lane-shared/types/Workflows';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';

const anyOption = {
  value: WORKFLOW_ANY_STATUS,
  label: 'Any status',
};

const translationKeys: Record<string, string> = {
  'any status':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.any.label',
  created:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.created.label',
  accepted:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.accepted.label',
  approved:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.approved.label',
  open:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.open.label',
  're opened':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.reopened.label',
  upcoming:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.upcoming.label',
  'in progress':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.inProgress.label',
  assigned:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.assigned.label',
  confirmed:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.confirmed.label',
  'un assigned':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.unassigned.label',
  arrived:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.arrived.label',
  'on hold':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.onHold.label',
  waiting:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.waiting.label',
  'awaiting response':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.awaitingResponse.label',
  'needs more info':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.needsMoreInfo.label',
  rejected:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.rejected.label',
  declined:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.declined.label',
  failed:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.failed.label',
  resent:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.resent.label',
  complete:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.complete.label',
  closed:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.closed.label',
  deleted:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.deleted.label',
  cancelled:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.canceled.label',
  attending:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.attending.label',
  'not attending':
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.notAttending.label',
  all:
    'web.admin.channel.content.workflow.editor.v2.conditions.interaction.status.all.label',
};

function mapRuleStatusOption(rule: any) {
  return {
    value: rule.status,
    label: rule.status,
  };
}

function mapRuleStatusToOptions(rule: any) {
  if (!rule) {
    return [];
  }

  return rule.nextStatuses.map((status: any) => ({
    label: status,
    value: status,
  }));
}

type Props = {
  statusesFeature: StatusesFeatureProperties | null | undefined;
  statusFrom: string | null | undefined;
  isNewWorkflowsUIEnabled?: boolean;
};

export type SchemaType = {
  label: string;
  value: string;
};

export default function useContentStatusTransitionOptions({
  statusesFeature,
  statusFrom,
  isNewWorkflowsUIEnabled,
}: Props): { statusToOptions: SchemaType[]; statusFromOptions: SchemaType[] } {
  const { t } = useTranslation();

  const statusFromOptions = useMemo(() => {
    if (!statusesFeature) {
      return [];
    }

    return [anyOption, ...statusesFeature.rules.map(mapRuleStatusOption)];
  }, []);

  const statusToOptions = useMemo(() => {
    if (!statusesFeature) {
      return [];
    }

    // if any is chosen as the status from, display all possible status to values
    if (statusFrom === anyOption.value) {
      const nextPossibleStatuses = statusesFeature.rules.reduce(
        (arr, rule) => [...arr, ...rule.nextStatuses],
        [] as string[]
      );

      // only get the unique set of statuses
      const uniqueStatuses = [...new Set(nextPossibleStatuses)];

      return [
        anyOption,
        ...uniqueStatuses.map(status => ({ value: status, label: status })),
      ];
    }

    // otherwise only show valid transition statuses based on the from status
    return [
      anyOption,
      ...mapRuleStatusToOptions(
        statusesFeature.rules.find(rule => rule.status === statusFrom)
      ),
    ];
  }, [statusFrom]);

  const statusFromOptionsV2 = statusFromOptions.map(option => ({
    ...option,
    label: t(translationKeys[lowerCase(option.label)]),
  }));

  const statusToOptionsV2 = statusToOptions.map(option => ({
    ...option,
    label: t(
      'web.admin.channel.content.workflow.editor.v2.conditions.statusTo.label',
      { status: t(translationKeys[lowerCase(option.label)]) }
    ),
  }));

  return {
    statusFromOptions: isNewWorkflowsUIEnabled
      ? statusFromOptionsV2
      : statusFromOptions,
    statusToOptions: isNewWorkflowsUIEnabled
      ? statusToOptionsV2
      : statusToOptions,
  };
}
