import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { EditorModeEnum } from 'lane-shared/types/EditorModeEnum';
import { WhitelabelChannelType } from 'lane-shared/types/WhiteLabelType';

import Toggle from 'components/form/Toggle';
import Label from 'components/general/Label';
import ChannelSelectorButton from 'components/lane/ChannelSelectorButton';
import WhitelabelSelectorButton from 'components/lane/WhitelabelSelectorButton';

import styles from './WhitelabelChannelEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  onWhitelabelChannelRemoved: (
    whitelabelChannel: Partial<WhitelabelChannelType>
  ) => void;
  onWhitelabelChannelUpdated: (
    whitelabelChannel: Partial<WhitelabelChannelType>
  ) => void;
  whitelabelChannel: WhitelabelChannelType;
  mode: EditorModeEnum.Channel | EditorModeEnum.Whitelabel | null;
};

export default function WhitelabelChannelEdit({
  className,
  style,
  onWhitelabelChannelRemoved,
  onWhitelabelChannelUpdated,
  whitelabelChannel,
  mode,
}: Props) {
  return (
    <div className={cx(styles.WhitelabelChannelEdit, className)} style={style}>
      {(!mode || mode === EditorModeEnum.Channel) && (
        <>
          <Label>Channel</Label>
          <ChannelSelectorButton
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(channel: any) => void' is not assignable to... Remove this comment to see the full error message
            onChannelSelected={(channel: any) =>
              onWhitelabelChannelUpdated({
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: any; } | null' is not assignable to t... Remove this comment to see the full error message
                channel: channel ? { _id: channel._id } : null,
              })
            }
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
            channelId={whitelabelChannel?.channel?._id}
          />
        </>
      )}

      {(!mode || mode === EditorModeEnum.Whitelabel) && (
        <>
          <Label mt={4}>Whitelabel</Label>
          <WhitelabelSelectorButton
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(whitelabel: any) => void' is not assignable... Remove this comment to see the full error message
            onWhitelabelSelected={(whitelabel: any) =>
              onWhitelabelChannelUpdated({
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: any; } | null' is not assignable to t... Remove this comment to see the full error message
                whitelabel: whitelabel ? { _id: whitelabel._id } : null,
              })
            }
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
            whitelabelId={whitelabelChannel?.whitelabel?._id}
          />
        </>
      )}

      <Toggle
        className={styles.toggle}
        text="Primary Channel"
        value={Boolean(whitelabelChannel.isPrimary)}
        onChange={isPrimary => onWhitelabelChannelUpdated({ isPrimary })}
        mt={6}
        doTranslate
      />

      <Icon
        onClick={() => onWhitelabelChannelRemoved(whitelabelChannel)}
        className={styles.removeIcon}
        name="times-circle"
        set={ICON_SET_FONTAWESOME}
      />
    </div>
  );
}
