import gql from 'graphql-tag';

import { QueryResult, useQuery } from '@apollo/client';

import ObjectDatasetRecordResult from './types/ObjectDatasetRecordResult';

export const OBJECT_DATASET_RECORD_QUERY = gql`
  query ObjectDatasetRecord($externalId: UUID!) {
    objectDatasetRecord(externalId: $externalId) {
      _id
      datasetRecord {
        _id
        value
      }
    }
  }
`;

export default function useObjectDatasetRecordByExternalId<T = any>(
  externalId: string
): QueryResult<
  ObjectDatasetRecordResult<T>,
  {
    externalId: string;
  }
> {
  return useQuery<ObjectDatasetRecordResult<T>, { externalId: string }>(
    OBJECT_DATASET_RECORD_QUERY,
    {
      variables: { externalId },
    }
  );
}
