import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
// need to make sure these guys are loaded.
import '../../../../properties/baseTypes/UUID';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';

const properties: PropertiesInterface = {
  parentId: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: 'true',
      },
    ],
  },
  childrenIds: {
    type: 'UUID',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: 'true',
      },
    ],
  },
};

export class RelatedSpacesType extends ObjectTypeBase {
  name = 'RelatedSpaces';

  get example() {
    return {
      _id: uuid(),
    };
  }

  get default(): any {
    return null;
  }

  contexts = [];

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new RelatedSpacesType();

Types.addType(definition);
export default definition;
