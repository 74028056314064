import React, { useState, memo } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { DAYS_OF_THE_WEEK } from 'lane-shared/helpers/constants/timeUnits';

import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';
import Label from 'components/general/Label';

import DayPriceTimeRange from './DayPriceTimeRange';

import styles from './WeeklyPriceTimeRange.scss';

type WeeklyPriceTimeRangeParams = {
  baseAmount: number;
  weekTimeRules: any;
  weekTimeRulesEnabled: boolean;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onAddTimeRange: (any) => any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onRemoveTimeRange: (any) => any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onTimeRangeSelect: (any) => any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onChangePriceRatio: (any) => any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onToggleOpen: (any) => any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onToggleHours: (any) => any;
};

const TRANSLATION_KEYS = {
  configureHours: 'web.components.WeeklyPriceTimeRange.configureHours',
  doneButton: 'web.components.WeeklyPriceTimeRange.doneButton',
};

function WeeklyPriceTimeRange({
  baseAmount,
  weekTimeRules,
  weekTimeRulesEnabled,
  onAddTimeRange,
  onRemoveTimeRange,
  onTimeRangeSelect,
  onChangePriceRatio,
  onToggleOpen,
  onToggleHours,
}: WeeklyPriceTimeRangeParams) {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.weekTimeRulesContainer}>
      <Label className={styles.timeToggle}>
        <div>{t(TRANSLATION_KEYS.configureHours)}</div>
        <Toggle
          className={styles.toggleButton}
          onChange={onToggleHours}
          value={weekTimeRulesEnabled}
        />
      </Label>
      {weekTimeRulesEnabled && (
        <div className={styles.priceTimeRanges}>
          <div>
            {/*
              For some reason iterating through WeeklyAvailability doesn't preserve the day order,
              so we iterate through DAYS_OF_THE_WEEK constant and index into WeeklyAvailability instead
            */}
            {Object.entries(DAYS_OF_THE_WEEK).map(([dayKey, dayObject]) => {
              // Every dayKey should exist in the weekTimeRules, but sanity check just in case
              if (!weekTimeRules?.[dayKey]) {
                return null;
              }

              const { isOpen, priceTimeRanges } = weekTimeRules[dayKey];

              return (
                <DayPriceTimeRange
                  key={dayKey}
                  baseAmount={baseAmount}
                  dayKey={dayKey}
                  dayObject={dayObject}
                  isOpen={isOpen}
                  isEditing={isEditing}
                  priceTimeRanges={priceTimeRanges}
                  onAddTimeRange={onAddTimeRange}
                  onRemoveTimeRange={onRemoveTimeRange}
                  onTimeRangeSelect={onTimeRangeSelect}
                  onChangePriceRatio={onChangePriceRatio}
                  onToggleOpen={onToggleOpen}
                />
              );
            })}
          </div>
          <div className={styles.editContainer}>
            {!isEditing && (
              <Icon
                name="pencil"
                set={ICON_SET_FONTAWESOME}
                type={FONT_AWESOME_REGULAR}
                className={styles.icon}
                onClick={() => setIsEditing(!isEditing)}
              />
            )}
            {isEditing && (
              <Button
                onClick={() => setIsEditing(!isEditing)}
                variant="contained"
              >
                {t(TRANSLATION_KEYS.doneButton)}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(WeeklyPriceTimeRange);
