import { Primitive } from '../../../types/blocks/Primitive';

const PurchaseBlock: Primitive = {
  name: 'PurchaseBlock',
  friendlyName: 'Purchase Button',
  isInput: true,
  isPrimitive: true,
  properties: {
    isValid: {
      type: 'Boolean',
      default: true,
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
    isSubmit: {
      type: 'Boolean',
      default: false,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    submit: {
      type: 'Event',
    },
  },
};

export default PurchaseBlock;
