import gql from 'graphql-tag';

const getContentChannelIntegration = gql`
  query contentQueryForChannelIntegration($id: UUID!) {
    me {
      content(_id: $id) {
        _id
        integration {
          _id
          settings
        }
      }
    }
  }
`;

export default getContentChannelIntegration;
