export default class ProxyClickCustomField {
  proxyclickId: number = 0;

  shapeRef: string = '';

  label: string = '';

  options: { value: string }[] = [];

  attachToVisitor: boolean = false;

  value: string = '';
}
