import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { getFriendlyDate } from 'lane-shared/helpers/content';
import {
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content/subtitleHelpers';

import TextCard from './TextCard';

function MessageCard({ className, style, notice }: any) {
  const { t } = useTranslation();
  let subtitle;

  if (isSubtitleDate(notice?.subtitle) && notice?.startDate) {
    subtitle = getFriendlyDate({ content: notice, translator: t });
  }

  if (isSubtitleCustom(notice?.subtitle)) {
    subtitle = notice?.subtitle;
  }

  return (
    <TextCard
      className={className}
      style={style}
      backgroundColor={notice?.backgroundColor}
      color={notice?.color}
      header={notice?.name}
      subHeader={subtitle}
      text={notice?.description}
      user={notice?.publishedBy}
      startDate={notice?.startDate}
      endDate={notice?.endDate}
    />
  );
}

export default memo(MessageCard);
