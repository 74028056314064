import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Flex } from 'design-system-web';

import { H3, H4 } from 'lane-web/src/components/typography';
import { BreadCrumbs } from 'lane-web/src/components/lds';
import { StickyFooter } from 'lane-web/src/components/general';

import styles from './MultiSectionForm.scss';

type FormSection = {
  id: string;
  title: string;
  content: JSX.Element;
};

interface MultiSectionFormProps {
  title: string;
  submitText: string;
  cancelText: string;
  sections: FormSection[];
  handleSubmit: () => void;
  handleCancel: () => void;
  enableSubmit?: boolean;
  activeSection?: string;
  setActiveSection?: (id: string) => void;
  links?: { label: string; url?: string }[];
}

export const MultiSectionForm = ({
  title,
  submitText,
  cancelText,
  sections,
  handleSubmit,
  handleCancel,
  enableSubmit = true,
  activeSection = '',
  setActiveSection = () => {},
  links = [],
}: MultiSectionFormProps) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" width="full" className={styles.container}>
      {/* Breadcrumbs */}
      <BreadCrumbs
        links={links}
      />

      {/* Title */}
      <Flex direction="row" className={styles.title}>
        <H3>{title}</H3>
      </Flex>

      {/* Form */}
      <Flex direction="row" className={styles.form}>
        {/* Content Pane */}
        <Flex direction="column" className={styles.content}>
          {sections.map(section => (
            <div
              key={section.id}
              id={section.id}
              data-testid={section.id}
              className={styles.section}
            >
              <H4>{section.title}</H4>
              {section.content}
            </div>
          ))}
          <a id="link-bottom" href="#content-bottom" className={styles.backToTop}>
            <div id="content-bottom" />
          </a>
        </Flex>

        {/* Navigation Pane */}
        <Flex direction="column" className={styles.navigation}>
          <div className={styles.navHeader}>
            <H4>{t('web.admin.multiSectionForm.formSections')}</H4>
          </div>
          {sections.map(section => (
            <div key={section.id}>
              <a
                href={`#${section.id}`}
                className={styles.navLink}
                onClick={() => setActiveSection(section.id)}
              >
                <div
                  className={
                    activeSection === section.id
                      ? styles.activeItem
                      : styles.navItem
                  }
                >
                  {section.title}
                </div>
              </a>
            </div>
          ))}
        </Flex>
      </Flex>

      {/* Footer */}
      <StickyFooter
        submitText={submitText}
        cancelText={cancelText}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        enableSubmit={enableSubmit}
      />
    </Flex>
  );
};
