import React from 'react';

import { getUserLocale } from 'helpers';
import { useTranslation } from 'react-i18next';

import { currencyFormatter, imageUrl } from 'lane-shared/helpers/formatters';
import OnlinePlanQuote from 'lane-shared/hooks/integrations/essensys/types/OnlinePlanQuote';
import { ContentType } from 'lane-shared/types/content/Content';

import { H5, H6, M, XS } from 'components/typography';

import styles from './OnlinePlanQuoteSummary.scss';

type OnlinePlanQuoteSummaryProps = {
  content?: ContentType | null;
  onlinePlanQuote?: OnlinePlanQuote | null;
  onError?: (error: Error) => void;
};

export default function OnlinePlanQuoteSummary({
  onlinePlanQuote,
  content,
}: OnlinePlanQuoteSummaryProps) {
  const { t } = useTranslation();

  const currencySymbol = onlinePlanQuote?.currencyDetail.currencySymbol;

  if (!content) {
    return null;
  }

  if (!onlinePlanQuote?.currencyDetail.currencyCode) {
    return (
      <M>{t('No currency setup for this item, please contact support.')}</M>
    );
  }

  const userLocale = getUserLocale();
  const formatCurrency = currencyFormatter({
    locale: userLocale,
    currency: onlinePlanQuote.currencyDetail.currencyCode,
    currencyDisplay: 'none',
  });

  return (
    <>
      <H6>{t('Order Summary')}</H6>
      <div className={styles.contentSummaryContainer}>
        {content.backgroundImage ? (
          <img
            className={styles.thumbnail}
            src={imageUrl(content.backgroundImage)}
            alt={content.name}
          />
        ) : null}
        <div className={styles.header}>
          <M>{content.name}</M>
          {content.description ? (
            <XS className={styles.description}>{content.description}</XS>
          ) : null}
        </div>
      </div>
      <hr />
      <div className={styles.lineItems}>
        {onlinePlanQuote
          ? onlinePlanQuote.productLineItems.map((productLineItem, idx) => (
              <React.Fragment key={idx}>
                <M className={styles.productName}>
                  {productLineItem.productName}
                </M>
                {productLineItem.quantity > 1 ? (
                  <XS className={styles.quantity}>
                    {`${currencySymbol} ${productLineItem.unitPrice} × ${productLineItem.quantity}`}
                  </XS>
                ) : null}
                <M className={styles.currencySymbol}>{`${currencySymbol}`}</M>
                <M className={styles.totalPrice}>
                  {formatCurrency(
                    productLineItem.unitPrice * productLineItem.quantity
                  )}
                </M>
              </React.Fragment>
            ))
          : null}
        <hr className={styles.spanningLine} />
        {onlinePlanQuote.totals.totalTax > 0 ? (
          <>
            <>
              <M className={styles.productName}>{t('Subtotal')}</M>
              <M className={styles.currencySymbol}>{`${currencySymbol}`}</M>
              <M className={styles.totalPrice}>
                {formatCurrency(onlinePlanQuote?.totals.subtotal)}
              </M>
            </>
            <>
              <M className={styles.productName}>{t('Tax')}</M>
              <M className={styles.currencySymbol}>{`${currencySymbol}`}</M>
              <M className={styles.totalPrice}>
                {formatCurrency(onlinePlanQuote?.totals.totalTax)}
              </M>
            </>
          </>
        ) : null}
        <H5 className={styles.productName}>{t('Total')}</H5>
          <H5 className={styles.currencySymbol}>{`${currencySymbol}`}</H5>
          <H5 className={styles.totalPrice}>
            {formatCurrency(onlinePlanQuote?.totals.total)}
          </H5>
      </div>
    </>
  );
}
