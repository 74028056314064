import Types from 'lane-shared/properties/Types';
import getBlockKey from 'lane-shared/renderers/v5/getBlockKey';
import {
  BlockInstanceType,
  BlockRenderInterface,
} from 'lane-shared/types/blocks/BlockInterface';

const blockType = Types.getBaseTypes().Block;

function _remove(
  block: BlockInstanceType,
  toFind: any, // TODO: fix type,
  blocks: {
    [key: string]: BlockRenderInterface;
  }
) {
  // we need to find this block in this instance.
  // could be in children, or in the properties of any block.
  const Block = blocks[getBlockKey(block)];

  if (Block) {
    Object.entries(Block.def.properties)
      .filter(([, property]) => property.type === blockType.name)
      .forEach(([key, property]) => {
        // does the properties field exist
        if (block.properties[key]) {
          // is this an array of blocks in this property.
          if (property.isArray) {
            // if so, does the block we're looking for exist in that array.
            if (block.properties[key].some((b: any) => b._id === toFind._id)) {
              // remove it if so
              block.properties[key] = block.properties[key].filter(
                (b: any) => b._id !== toFind._id
              );
            } else {
              // otherwise, we will have to check each block in this array recursively
              block.properties[key].forEach((b: any) =>
                _remove(b, toFind, blocks)
              );
            }
          } else if (block.properties[key]._id === toFind._id) {
            // if this is just a block property check to see if this the one we are looking for
            block.properties[key] = null;
          } else {
            // otherwise we will need to hunt for it recursively
            _remove(block.properties[key], toFind, blocks);
          }
        }
      });
  }

  // ok we checked the properties, not we also need to check the children.
  if (block?.children) {
    if ((block.children as any).some((b: any) => b._id === toFind._id)) {
      block.children = (block.children as any).filter(
        (b: any) => b._id !== toFind._id
      );
    } else {
      (block.children as any).forEach((b: any) => _remove(b, toFind, blocks));
    }
  }

  if (block?.properties?.children) {
    if (block.properties.children.some((b: any) => b._id === toFind._id)) {
      block.properties.children = block.properties.children.filter(
        (b: any) => b._id !== toFind._id
      );
    } else {
      block.properties.children.forEach((b: any) => _remove(b, toFind, blocks));
    }
  }
}

export default function removeBlock(
  content: { block: BlockInstanceType },
  toFind: any, // TODO: fix type,
  blocks: {
    [key: string]: BlockRenderInterface;
  }
) {
  _remove(content.block, toFind, blocks);
}
