import type { PillColor } from '../../types/general/pill';

export const CONTENT_LIVE = {
  text: 'Live',
  color: 'green' as PillColor,
};

export const CONTENT_UPCOMING = {
  text: 'Upcoming',
  color: 'blue' as PillColor,
};

export const CONTENT_COMPLETED = {
  text: 'Completed',
  color: 'red' as PillColor,
};
