import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getMemberInfo } from '../graphql/channel';
import { byName } from '../helpers/sort';
import { collapseMemberChannels } from '../helpers/user';

export default function useMemberInfoQuery({ userId }: any) {
  const { data } = useQuery(getMemberInfo, {
    variables: {
      id: userId,
    },
    client: getClient(),
    fetchPolicy: 'cache-and-network',
  });

  const user = data?.user;
  const roles = data?.memberGroupRoles || [];

  // collapse the roles as they may have multiple roles at one place.
  const channels = collapseMemberChannels(roles)
    .sort(byName)
    .filter(channel => !!channel.parent);

  return { user, roles, channels };
}
