import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import addCreditCardQL from '../graphql/mutation/addCreditCard';
import removeCreditCardQL from '../graphql/mutation/removeCreditCard';
import getCreditCards from '../graphql/query/getCreditCards';
import pause from '../helpers/pause';

export default function useCreditCards(paymentAccountIds: string[]) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const [fetchCreditCards, { data, loading, error }] = useLazyQuery(
    getCreditCards,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  async function addCreditCard(paymentAccountId: any, source: any) {
    setIsUpdating(true);
    setUpdateError(null);

    try {
      await pause();
      await getClient().mutate({
        mutation: addCreditCardQL,
        variables: {
          paymentAccountId,
          source,
        },
      });

      fetchCreditCards({
        fetchPolicy: 'network-only',
        variables: {
          paymentAccountIds,
        },
      });
    } catch (err) {
      setUpdateError(err);
    }

    setIsUpdating(false);
  }

  async function removeCreditCard(paymentAccountId: any, id: any) {
    setIsUpdating(true);
    setUpdateError(null);

    try {
      await pause();
      await getClient().mutate({
        mutation: removeCreditCardQL,
        variables: {
          paymentAccountId,
          id,
        },
      });

      fetchCreditCards({
        fetchPolicy: 'network-only',
        variables: {
          paymentAccountIds,
        },
      });
    } catch (err) {
      setUpdateError(err);
    }

    setIsUpdating(false);
  }

  async function refetchCreditCards() {
    if (paymentAccountIds.length !== 0) {
      fetchCreditCards({
        variables: {
          paymentAccountIds,
        },
      });
    }
  }

  // NOTE: https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list
  useEffect(() => {
    refetchCreditCards();
  }, [JSON.stringify(paymentAccountIds)]);

  const cards = useMemo(() => data?.payments?.paymentMethods || [], [
    data?.payments?.paymentMethods,
  ]);

  return {
    cards,
    loading: loading || isUpdating,
    error: error || updateError,
    removeCreditCard,
    addCreditCard,
    refetchCreditCards,
  };
}
