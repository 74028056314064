import React, { useContext } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { UserDataContext } from 'lane-shared/contexts';
import { INVOICE_DATE } from 'lane-shared/helpers/constants/dates';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';
import { EssensysInvoiceClientType } from 'lane-shared/types/Essensys';

import styles from './InvoiceListView.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  invoice: EssensysInvoiceClientType;
};

export default function InvoiceListView({ invoice, className, style }: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const _id = invoice?._id;
  const startDate = invoice?.startDate;

  const formattedStartDate = dateFormatter(startDate, INVOICE_DATE);
  const formatter = currencyFormatter({
    currency: invoice.currencyCode,
    locale: user?.locale,
  });

  const link = routes.essensysInvoice.replace(':invoiceId', _id);

  return (
    <div
      className={cx(
        styles.container,
        invoice.paid && styles.paidContainer,
        className
      )}
      style={style}
    >
      <div className={styles.column}>
        <Link to={link}>
          <h1>
            {t('Invoice')} <strong>{invoice.number}</strong>
          </h1>
        </Link>
        <span>{formattedStartDate}</span>
      </div>
      <div className={styles.amount}>
        {invoice.paid && <h2>{t('Paid')}</h2>}
        <p>{formatter(invoice.outstanding)}</p>
      </div>
      <Link to={link}>
        <Icon name="chevron-right" />
      </Link>
    </div>
  );
}
