import React from 'react';

import { i18n } from 'i18next';
import { z } from 'zod';

import { Mjml, MjmlBody } from 'lane-shared/emails/MjmlComponentsV2';
import {
  EmailHorizontalRule,
  EmailSpacer,
} from 'lane-shared/emails/components';

import {
  EmailVisitorHeader,
  EmailVisitorFooter,
  EmailVisitorHero,
  EmailVisitorGreeting,
  EmailVisitorBuildingDetails,
  EmailRecurringVisitorPass,
  EmailVisitorAlert,
  AlertType,
} from './components';
import {
  buildingSchema,
  hostSchema,
  visitorSchema,
  credentialSchema,
  appleWalletSchema,
  calendarLinksSchema,
  scheduleChangeDetailsSchema,
  whitelabelSchema,
  recurringScheduleSchema,
} from './schema';
import { RecurrenceEventStatus } from '../types';
import { EmailVisitorRecurringScheduleUpdateSummary } from './components/EmailVisitorRecurringScheduleUpdateSummary';

const ActivateRecurrenceVisitorUpdateParams = z.object({
  whitelabel: whitelabelSchema.optional(),
  building: buildingSchema,
  host: hostSchema,
  visitor: visitorSchema,
  oldRecurrenceScheduleData: scheduleChangeDetailsSchema.optional(),
  credential: credentialSchema,
  appleWallet: appleWalletSchema.optional(),
  calendarLinks: calendarLinksSchema.optional(),
  recurringSchedule: recurringScheduleSchema,
});

export type EmailRecurrenceVisitorUpdateProps = {
  title: string;
  params: z.infer<typeof ActivateRecurrenceVisitorUpdateParams>;
  // because this component runs server side, we need the i18n passed in
  // from the current running context.
  i18n: i18n;
};

export function EmailRecurrenceVisitorUpdate({
  title,
  params,
  i18n,
}: EmailRecurrenceVisitorUpdateProps) {
  try {
    params = ActivateRecurrenceVisitorUpdateParams.parse(params);
  } catch (error) {
    console.error(error);
    throw error;
  }

  const recurrenceIsCancelled: Boolean = [
    RecurrenceEventStatus.RECURRENCE_EVENT_STATUS_CANCELLED,
  ].includes(params.recurringSchedule.status);

  const basicGreeting = (
    <>
      {i18n.t('shared.email.visitorManagement.visitorUpdate.greeting', {
        firstName: params.host.firstName,
      })}{' '}
      <b>{params.building.name}</b>
      {i18n.t('punctuation.period')}
    </>
  );

  const greetingWithCompany = (
    <>
      {i18n.t(
        'shared.email.visitorManagement.visitorUpdate.greeting.company.partOne',
        {
          firstName: params.host.firstName,
        }
      )}{' '}
      <b>{params.host.companyName}</b>{' '}
      {i18n.t(
        'shared.email.visitorManagement.visitorUpdate.greeting.company.partTwo'
      )}{' '}
      <b>{params.building.name}</b>
      {i18n.t('punctuation.period')}
    </>
  );

  const greeting =
    params.host.companyName?.length &&
    params.host.companyName !== params.building.name
      ? greetingWithCompany
      : basicGreeting;

  const scheduleIsUpdated = Boolean(
    params.oldRecurrenceScheduleData?.startDate ||
      params.oldRecurrenceScheduleData?.endDate ||
      params.oldRecurrenceScheduleData?.scheduleText
  );
  const timeIsUpdated = Boolean(
    params.oldRecurrenceScheduleData?.startTime ||
      params.oldRecurrenceScheduleData?.endTime
  );

  const isVisitorNoteUpdated = Boolean(
    params.oldRecurrenceScheduleData?.notesToVisitor !== undefined &&
      params.oldRecurrenceScheduleData?.notesToVisitor !==
        params.recurringSchedule.notesToVisitor
  );

  const recurrenceIsUpdated = Boolean(
    scheduleIsUpdated || timeIsUpdated || isVisitorNoteUpdated
  );

  const details =
    recurrenceIsCancelled || recurrenceIsUpdated ? undefined : greeting;

  return (
    <Mjml>
      <EmailVisitorHeader
        title={title}
        primaryColor={params.building.primaryColor}
      />
      <MjmlBody background-color="#F5F6F7">
        <EmailSpacer />

        <EmailVisitorHero backgroundUrl={params.building.visitorHeroUri} />

        <EmailVisitorGreeting
          salutation={i18n.t('shared.email.visitorManagement.greeting', {
            firstName: params.visitor.firstName,
          })}
          details={details}
        />

        {recurrenceIsCancelled && (
          <EmailVisitorAlert type={AlertType.error}>
            <b style={{ color: '#0F121F' }}>
              {i18n.t(
                'shared.email.visitorManagement.visitorUpdate.recurrence.cancelled'
              )}
            </b>
          </EmailVisitorAlert>
        )}

        {recurrenceIsUpdated && (
          <>
            <EmailVisitorAlert type={AlertType.success}>
              <b style={{ color: '#0F121F' }}>
                {i18n.t(
                  'shared.email.visitorManagement.visitorAlert.pass.updated.visitor.line.one'
                )}
              </b>
              <br />
              <b style={{ color: '#0F121F' }}>
                {i18n.t(
                  'shared.email.visitorManagement.visitorAlert.pass.updated.line.two'
                )}
              </b>
              <span>{params.oldRecurrenceScheduleData?.changeNote}</span>
            </EmailVisitorAlert>

            <EmailVisitorRecurringScheduleUpdateSummary
              i18n={i18n}
              building={params.building}
              recurringSchedule={params.recurringSchedule}
              oldRecurrenceScheduleData={params.oldRecurrenceScheduleData!}
            />
          </>
        )}

        <EmailHorizontalRule />

        <EmailVisitorBuildingDetails i18n={i18n} building={params.building} />

        <EmailHorizontalRule />

        <EmailRecurringVisitorPass
          i18n={i18n}
          building={params.building}
          host={params.host}
          visitor={params.visitor}
          credential={recurrenceIsCancelled ? undefined : params.credential}
          appleWallet={recurrenceIsCancelled ? undefined : params.appleWallet}
          calendarLinks={
            recurrenceIsCancelled ? undefined : params.calendarLinks
          }
          recurringSchedule={params.recurringSchedule}
          recurrenceIsUpdated={recurrenceIsUpdated}
        />

        <EmailVisitorFooter
          i18n={i18n}
          primaryColor={params.building.primaryColor}
          building={params.building}
          whitelabel={params.whitelabel}
        />

        <EmailSpacer />
      </MjmlBody>
    </Mjml>
  );
}
