import i18next from 'i18next';
import { DateTime, Duration } from 'luxon';

import { LONG_DATE } from '../constants/dates';
import friendlyDateDifference from '../dates/friendlyDateDifference';
import parseDateTime from '../dates/parseDateTime';
import dateFormatter from './dateFormatter';

export default function friendlyDate(
  instance: { startDate: Date | DateTime; endDate: Date | DateTime },
  timeZone: string,
  translator: (key: string, params?: any) => string = i18next.t
): string {
  if (!instance) {
    return '';
  }

  const start = parseDateTime(instance.startDate, timeZone)!;
  const end = parseDateTime(instance.endDate, timeZone)!;
  const today = DateTime.local().setZone(timeZone).startOf('day');

  const diff: Duration = today.diff(start, 'days');
  const endDiff: Duration = today.diff(end, 'days');

  if (start > new DateTime()) {
    if (diff.days < 1) {
      return translator(`starts {{distance}}`, {
        distance: friendlyDateDifference(
          start,
          new Date(),
          timeZone,
          translator
        ),
      });
    }

    if (diff.days < 2) {
      return translator('starts tomorrow');
    }

    if (diff.days < 6) {
      return translator(`starts {{date}}`, {
        date: dateFormatter(start, LONG_DATE, timeZone),
      });
    }

    return translator(`starts {{distance}}`, {
      distance: friendlyDateDifference(start, new Date(), timeZone, translator),
    });
  }

  if (endDiff.days > 0) {
    if (endDiff.days < 1) {
      return translator(`ends {{distance}}`, {
        distance: friendlyDateDifference(end, new Date(), timeZone, translator),
      });
    }

    if (endDiff.days < 2) {
      return translator('ends tomorrow');
    }

    if (endDiff.days < 6) {
      return translator(`ends {{date}}`, {
        date: dateFormatter(end, LONG_DATE, timeZone),
      });
    }

    return translator(`ends {{distance}}`, {
      distance: friendlyDateDifference(end, new Date(), timeZone, translator),
    });
  }

  return translator(`ended {{distance}}`, {
    distance: friendlyDateDifference(end, new Date(), timeZone, translator),
  });
}
