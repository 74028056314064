/* eslint-disable react/forbid-component-props */ // Allows passing component instances as props
/* eslint-disable @nx/enforce-module-boundaries */ // Permits importing from modules outside enforced boundaries
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  S,
  Input,
  TextArea,
  TimePicker,
  TimeIntervals,
  Dropdown,
} from 'design-system-web';
import MultiselectField from 'components/form/MultiselectField';
import { Button } from 'components';
import styles from './styles.scss';
import { PinKioskTypeEnum } from '../../devices/tabs/hardware/hardware';
import { DaySelector } from '../../../../visitorManagement/components/DaySelector/DaySelector';
import { START_HOUR } from 'lane-shared/domains/visitorManagement/features/VisitorManagementFeatureValues';
import {
  createDefaultDateTime,
  getMatchingMonthIntervalTypes,
} from 'lane-shared/domains/visitorManagement/helpers';
import {
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
  repeatDropdownItems,
} from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';

import { getMonthIntervalOptions } from '../../../../visitorManagement/components/ScheduleRepeat/helpers/getMonthIntervalOptions';

export function HardwareDetailEditPinCodes({
  setIsEditPinDetails,
  timezone,
}: {
  setIsEditPinDetails: (value: boolean) => void;
  timezone: string;
}) {
  const { t } = useTranslation();
  const defaultMonthIntervalOption =
    getMatchingMonthIntervalTypes(
      createDefaultDateTime(START_HOUR, 0, timezone),
      timezone
    )[0] ?? MonthIntervalTypeEnum.OnDate;

  interface PinDataType {
    assignTo: string;
    repeats: string;
    startTime: string | null;
    endTime: string | null;
    notes: string;
    kioskType: any[];
    intervalCount: number;
    weekdayRepeats: number[];
    monthIntervalType: MonthIntervalTypeEnum.OnDate;
    startDate: Date;
  }

  const [pinData, setPinData] = useState<PinDataType>({
    assignTo: 'USPS',
    repeats: IntervalTypeEnum.EveryWeekday,
    startTime: null,
    endTime: null,
    notes: '',
    kioskType: [],
    intervalCount: 1,
    weekdayRepeats: [],
    monthIntervalType: MonthIntervalTypeEnum.OnDate,
    startDate: new Date(),
  });

  const [intervalCount, setIntervalCount] = useState(pinData.intervalCount);
  const [weekdayRepeats, setWeekdayRepeats] = useState(pinData.weekdayRepeats);

  const [intervalCountMonthly, setIntervalCountMonthly] = useState(
    pinData.intervalCount
  );
  const [monthIntervalType, setMonthIntervalType] = useState(
    defaultMonthIntervalOption
  );

  const monthlyIntervalOptions = getMonthIntervalOptions(
    pinData.startDate,
    t,
    timezone
  );

  const handleOnChange = (
    value: string | undefined | number,
    fieldname: string
  ) => {
    setPinData(prevPinData => ({
      ...prevPinData,
      [fieldname]: value,
    }));
  };

  const handleRepeatsOnChange = (value: string) => {
    setPinData(prevPinData => ({
      ...prevPinData,
      repeats: value,
    }));
  };

  function onCancel() {
    setIsEditPinDetails(false);
  }

  const handleKioskOnChange = (items: any[]) => {
    setPinData(prevPinData => ({
      ...prevPinData,
      kioskType: items,
    }));
  };

  return (
    <div>
      <div className={styles.rowsContainer}>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <S mb={1}>
              {t('web.admin.hardware.management.hardware.pin.details.assignTo')}
            </S>
            <Input
              fieldName="assignTo"
              value={pinData.assignTo}
              fixedLabel
              dataCy="assignToInput"
              onChange={handleOnChange}
            />
          </div>
          <div className={styles.rowItem}>
            <S mb={1}>
              {t('web.admin.hardware.management.hardware.pin.details.repeats')}
            </S>
            <Dropdown
              name="repeats"
              onValueChange={handleRepeatsOnChange}
              items={repeatDropdownItems.map(item => ({
                label: item.label,
                value: item.value,
              }))}
              isSearchable={false}
              value={pinData.repeats}
              dataCy="RepeatInterval"
              isFullWidth
            />
          </div>
        </div>

        {pinData.repeats === IntervalTypeEnum.Weekly && (
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <Input
                label={t(
                  'web.content.feature.visitorManagement.form.label.every'
                )}
                fixedLabel
                type="number"
                onChange={value => {
                  setIntervalCount(Number(value));
                }}
                value={String(intervalCount)}
                onBlur={() => {
                  if (!intervalCount) {
                    setIntervalCount(pinData.intervalCount);
                  }
                }}
                rightText={t(
                  'web.content.feature.visitorManagement.form.label.week'
                )}
                dataCy="weeklyIntervalCount"
              />
            </div>
            <div className={styles.rowItem}>
              <DaySelector
                daySelectorClass={styles.daySelector}
                selected={weekdayRepeats}
                onChange={days => {
                  setWeekdayRepeats(days);
                }}
                dataCy="weeklyDaySelector"
                className={styles.day}
              />
            </div>
          </div>
        )}

        {pinData.repeats === IntervalTypeEnum.Monthly && (
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <Input
                label={t(
                  'web.content.feature.visitorManagement.form.label.every'
                )}
                fixedLabel
                type="number"
                onChange={value => {
                  setIntervalCountMonthly(Number(value));
                }}
                value={intervalCountMonthly}
                onBlur={() => {
                  if (!intervalCountMonthly) {
                    setIntervalCountMonthly(pinData.intervalCount);
                  }
                }}
                rightText={t(
                  'web.content.feature.visitorManagement.form.label.month'
                )}
                dataCy="monthlyIntervalCount"
              />
            </div>
            <div className={styles.rowItem}>
              <Dropdown
                className={styles.monthlyIntervalDropdown}
                isSearchable={false}
                initialValue={defaultMonthIntervalOption}
                value={monthIntervalType}
                onChange={value => {
                  setMonthIntervalType(value.value);
                }}
                items={monthlyIntervalOptions}
                dataCy="monthlyIntervalDropdown"
                isFullWidth
              />
            </div>
          </div>
        )}

        {(pinData.repeats === IntervalTypeEnum.EveryDay ||
          pinData.repeats === IntervalTypeEnum.EveryWeekday) && (
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <S>
                {t('web.admin.hardware.management.hardware.pin.form.startTime')}
              </S>
              <TimePicker
                hideLabel
                className={styles.timePicker}
                buttonClassName={styles.timePickerButton}
                value={pinData.startTime}
                onChange={value => handleOnChange(value, 'startTime')}
                showClear={false}
                showIcon
                timeZone={timezone}
                unit={TimeIntervals.QUARTER_HOUR}
                dataCy="startTime"
                disabled={false}
              />
            </div>

            <div className={styles.rowItem}>
              <S>
                {t('web.admin.hardware.management.hardware.pin.form.endTime')}
              </S>
              <TimePicker
                hideLabel
                className={styles.timePicker}
                buttonClassName={styles.timePickerButton}
                value={pinData.endTime}
                onChange={value => handleOnChange(value, 'endTime')}
                showClear={false}
                showIcon
                timeZone={timezone}
                unit={TimeIntervals.QUARTER_HOUR}
                dataCy="endTime"
                disabled={false}
                fixedLabel={false}
              />
            </div>
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.rowItem}>
            <S>
              {t('web.admin.hardware.management.hardware.pin.form.startTime')}
            </S>
            <TimePicker
              hideLabel
              className={styles.timePicker}
              buttonClassName={styles.timePickerButton}
              value={pinData.endTime}
              onChange={value => handleOnChange(value, 'startTime')}
              showClear={false}
              showIcon
              timeZone={timezone}
              unit={TimeIntervals.QUARTER_HOUR}
              dataCy="startTime"
              disabled={false}
              fixedLabel={false}
            />
          </div>
          <div className={styles.rowItem}>
            <S>
              {t('web.admin.hardware.management.hardware.pin.form.endTime')}
            </S>
            <TimePicker
              hideLabel
              className={styles.timePicker}
              buttonClassName={styles.timePickerButton}
              value={pinData.startTime}
              onChange={value => handleOnChange(value, 'endTime')}
              showClear={false}
              showIcon
              timeZone={timezone}
              unit={TimeIntervals.QUARTER_HOUR}
              dataCy="endTime"
              disabled={false}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <S>
              {t('web.admin.hardware.management.hardware.pin.form.notes.label')}
            </S>
            <TextArea
              className={styles.textArea}
              onChange={value => handleOnChange(value, 'notes')}
              value={pinData.notes}
              maxLength={200}
              dataCy="notesToPinInput"
              showLengthIndicator
              helperText={t(
                'web.admin.hardware.management.hardware.pin.form.notes.helper'
              )}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <S>
              {t('web.admin.hardware.management.hardware.pin.form.kioskType')}
            </S>
            <MultiselectField
              items={PinKioskTypeEnum}
              value={pinData.kioskType}
              onChange={items => handleKioskOnChange(items)}
              isFullWidth
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button dataCy="saveButton" variant="activate-contained">
            {t('Save')}
          </Button>
          <Button onClick={() => onCancel()} dataCy="cancelButton">
            {t('Cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
}
