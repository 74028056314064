import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { WebhookType } from 'lane-shared/helpers/webhooks/WebhookType';
import useWebhookQuery from 'lane-shared/hooks/useWebhookQuery';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';

import IconButton from 'components/general/IconButton';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';

import WebhookLibrary from './WebhookLibrary';

import styles from './WebhookSelectorButton.scss';

type WebhookSelectorButtonProps = {
  className?: string;
  style?: React.CSSProperties;
  isInvalid?: boolean;
  disabled?: boolean;
  webhookId: string | undefined;
  library?: LibraryType;
  libraries: LibraryType[];
  onWebhookSelected: (webhook: WebhookType | null) => void;
  renderWebhook?: (webhook: WebhookType) => React.ReactNode;
  storageKey?: string;
  isNewWorkflowsUIEnabled?: boolean;
};

export default function WebhookSelectorButton({
  className,
  style,
  isInvalid,
  disabled,
  webhookId,
  library,
  libraries,
  onWebhookSelected,
  renderWebhook,
  storageKey = 'WebhookSelectorButton',
  isNewWorkflowsUIEnabled,
}: WebhookSelectorButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { webhook } = useWebhookQuery({
    webhookId,
  });

  function render() {
    if (webhookId) {
      return (
        <>
          {renderWebhook ? (
            renderWebhook(webhook)
          ) : (
            <span className={styles.selectedValue}>{webhook?.name}</span>
          )}
          <IconButton
            className={styles.remove}
            inverted
            icon="times"
            onClick={e => {
              onWebhookSelected(null);
              e.stopPropagation();
            }}
          />
        </>
      );
    }

    return (
      <>
        <Icon className={styles.search} name={t('search')} />
        <span>
          {t(
            isNewWorkflowsUIEnabled
              ? 'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.webhook.selectWebhook.placeholder'
              : 'Select a Webhook'
          )}
        </span>
      </>
    );
  }

  return (
    <>
      <div
        className={cx(styles.WebhookSelectorButton, className)}
        style={style}
        data-is-selected={Boolean(webhookId)}
        data-invalid={Boolean(isInvalid)}
        data-disabled={disabled}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => setIsOpen(true)}
      >
        {render()}
      </div>

      <ModalBackground
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        className={styles.background}
      >
        {isOpen && (
          <ResizableWindow
            name={storageKey}
            className={styles.window}
            defaultPosition={ResizableWindow.centerPosition()}
            onClose={() => setIsOpen(false)}
            showHeader
          >
            <WebhookLibrary
              library={library!}
              libraries={libraries}
              storageKey={storageKey}
              onWebhookSelected={webhook => {
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DocumentType | null' is not assi... Remove this comment to see the full error message
                onWebhookSelected(webhook);
                setIsOpen(false);
              }}
            />
          </ResizableWindow>
        )}
      </ModalBackground>
    </>
  );
}
