import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { arrayReorder, getLibraryOptions } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import MenuFeatureItem, {
  MENU_FEATURE_HIGHEST_PRICE,
  MENU_FEATURE_MAX_PREPARATION_TIME,
  MENU_FEATURE_MAX_QUANTITY,
} from 'lane-shared/renderers/v5/features/types/MenuFeatureItem';
import MenuFeatureItemOptionList from 'lane-shared/renderers/v5/features/types/MenuFeatureItemOptionList';
import { MenuFeatureItemType } from 'lane-shared/types/features/MenuFeatureTypes';

import Toggle from 'components/form/Toggle';

import CurrencyInput from '../../../form/CurrencyInput';
import Input from '../../../form/Input';
import QuantityInput from '../../../form/QuantityInput';
import Slider from '../../../form/Slider';
import TextArea from '../../../form/TextArea';
import Button from '../../../general/Button';
import ControlMenu from '../../../general/ControlMenu';
import Label from '../../../general/Label';
import MediaPickerButton from '../../../lane/MediaPickerButton';
import MenuFeatureItemOptionListEdit from './MenuFeatureItemOptionListEdit';

import styles from './MenuFeatureItemEdit.scss';

type Props = {
  className?: string;
  style?: any;
  currency?: string;
  item: MenuFeatureItemType;
  channel: object;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onItemUpdated: (MenuFeatureItemType, object) => any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onItemDone: (MenuFeatureItemType) => any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onItemRemove: (MenuFeatureItemType) => any;
};

const TRANSLATION_KEYS = {
  price: 'web.content.features.components.MenuFeatureItemEdit.price',
  heroImage: 'web.content.features.components.MenuFeatureItemEdit.heroImage',
  isCurrentlyAvailable:
    'web.content.features.components.MenuFeatureItemEdit.isCurrentlyAvailable',
  itemName: 'web.content.features.components.MenuFeatureItemEdit.itemName',
  itemDescription:
    'web.content.features.components.MenuFeatureItemEdit.itemDescription',
  minQuantity:
    'web.content.features.components.MenuFeatureItemEdit.minQuantity',
  maxQuantity:
    'web.content.features.components.MenuFeatureItemEdit.maxQuantity',
  preparationTime:
    'web.content.features.components.MenuFeatureItemEdit.preparationTime',
  optionList: 'web.content.features.components.MenuFeatureItemEdit.optionList',
  removeButton:
    'web.content.features.components.MenuFeatureItemEdit.removeButton',
  doneButton: 'web.content.features.components.MenuFeatureItemEdit.doneButton',
};

export default function MenuFeatureItemEdit({
  className,
  style,
  currency,
  item,
  channel,
  onItemUpdated,
  onItemDone,
  onItemRemove,
}: Props) {
  const { t } = useTranslation();

  function onOptionsDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    onItemUpdated(item, {
      option: arrayReorder(
        item.options,
        result.source.index,
        result.destination.index
      ),
    });
  }

  return (
    <div className={cx(styles.MenuFeatureItemEdit, className)} style={style}>
      <div className={styles.group}>
        <fieldset>
          <Label
            TooltipComponent={t(
              MenuFeatureItem.properties.price?.description || ''
            )}
          >
            {t(TRANSLATION_KEYS.price)}
          </Label>
          <CurrencyInput
            currency={currency}
            value={item.price}
            max={MENU_FEATURE_HIGHEST_PRICE}
            onValueChange={price => onItemUpdated(item, { price })}
          />
        </fieldset>
        <fieldset>
          <Label
            TooltipComponent={t(
              MenuFeatureItem.properties.image?.description || ''
            )}
          >
            {t(TRANSLATION_KEYS.heroImage)}
          </Label>
          <MediaPickerButton
            showDownload={false}
            showRemove
            showMedia
            libraries={getLibraryOptions({
              channel,
            })}
            storageKey={(channel as any)?._id}
            onMediaSelected={media =>
              onItemUpdated(item, {
                image: media?._id,
              })
            }
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; } | null' is not assignable t... Remove this comment to see the full error message
            media={item.image ? { _id: item.image } : null}
          />
        </fieldset>
        <fieldset>
          <Toggle
            value={Boolean(item.isAvailable)}
            onChange={isAvailable => onItemUpdated(item, { isAvailable })}
            text={t(TRANSLATION_KEYS.isCurrentlyAvailable)}
          />
        </fieldset>
      </div>
      <div className={styles.group}>
        <fieldset>
          <Label
            TooltipComponent={t(
              MenuFeatureItem.properties.name?.description || ''
            )}
          >
            {t(TRANSLATION_KEYS.itemName)}
          </Label>
          <Input
            className={styles.input}
            value={item.name}
            onChange={name => onItemUpdated(item, { name })}
            placeholder={t(MenuFeatureItem.properties.name?.friendlyName || '')}
          />
        </fieldset>
        <fieldset>
          <Label
            TooltipComponent={t(
              MenuFeatureItem.properties.description?.description || ''
            )}
          >
            {t(TRANSLATION_KEYS.itemDescription)}
          </Label>
          <TextArea
            className={styles.textArea}
            value={item.description}
            onChange={description => onItemUpdated(item, { description })}
            placeholder={t(
              MenuFeatureItem.properties.description?.friendlyName || ''
            )}
          />
        </fieldset>
      </div>
      <div className={styles.group}>
        <fieldset>
          <Label
            TooltipComponent={t(
              MenuFeatureItem.properties.minQuantity?.description || ''
            )}
          >
            {t(TRANSLATION_KEYS.minQuantity)}
          </Label>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ classNam... Remove this comment to see the full error message */}
          <QuantityInput
            className={styles.quantityInput}
            onChange={minQuantity => onItemUpdated(item, { minQuantity })}
            quantity={item.minQuantity}
            min={1}
            max={item.maxQuantity}
          />
        </fieldset>
        <fieldset>
          <Label
            TooltipComponent={t(
              MenuFeatureItem.properties.maxQuantity?.description || ''
            )}
          >
            {t(TRANSLATION_KEYS.maxQuantity)}
          </Label>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ classNam... Remove this comment to see the full error message */}
          <QuantityInput
            className={styles.quantityInput}
            onChange={maxQuantity => onItemUpdated(item, { maxQuantity })}
            quantity={item.maxQuantity}
            min={1}
            max={MENU_FEATURE_MAX_QUANTITY}
          />
        </fieldset>
        <fieldset>
          <Label
            TooltipComponent={t(
              MenuFeatureItem.properties.preparationMinutes?.description || ''
            )}
          >
            {t(TRANSLATION_KEYS.preparationTime)}
          </Label>
          <Slider
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
            min={0}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
            max={MENU_FEATURE_MAX_PREPARATION_TIME}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
            className={styles.slider}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
            value={item.preparationMinutes}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(preparationMinutes: any) => any' is not ass... Remove this comment to see the full error message
            onChange={(preparationMinutes: any) =>
              onItemUpdated(item, {
                preparationMinutes,
              })
            }
          />
        </fieldset>
      </div>
      <Label
        TooltipComponent={t(
          MenuFeatureItem.properties.options?.description || ''
        )}
      >
        {t(TRANSLATION_KEYS.optionList)}
        <Icon
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={() => {
            const option = {
              ...MenuFeatureItemOptionList.default,
              _order: item.options.length,
            };
            onItemUpdated(item, {
              options: [...item.options, option],
            });
          }}
        />
      </Label>

      <DragDropContext onDragEnd={onOptionsDragEnd}>
        <Droppable droppableId="optionLists">
          {provided => (
            <ul className={styles.items} ref={provided.innerRef}>
              {item.options?.map((optionList, index) => (
                <Draggable
                  key={optionList._id}
                  draggableId={optionList._id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      key={optionList._id}
                      ref={provided.innerRef}
                      data-is-dragging={snapshot.isDragging}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Icon
                        name="bars"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.iconReorder}
                      />
                      <MenuFeatureItemOptionListEdit
                        className={styles.optionListEdit}
                        currency={currency}
                        optionList={optionList}
                        onOptionListUpdated={props => {
                          item.options[index] = {
                            ...optionList,
                            ...props,
                          };
                          onItemUpdated(item, {
                            options: [...item.options],
                          });
                        }}
                      />
                      <Icon
                        name="times-circle"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.removeIcon}
                        style={{ marginLeft: '1em' }}
                        onClick={() =>
                          onItemUpdated(item, {
                            options: item.options.filter(
                              ({ _id }) => _id !== optionList._id
                            ),
                          })
                        }
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <ControlMenu>
        <hr />
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"def... Remove this comment to see the full error message */}
        <Button onClick={() => onItemRemove(item)} color="secondary">
          {t(TRANSLATION_KEYS.removeButton)}
        </Button>
        <Button onClick={() => onItemDone(item)} variant="contained">
          {t(TRANSLATION_KEYS.doneButton)}
        </Button>
      </ControlMenu>
    </div>
  );
}
