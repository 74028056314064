import {
  ICON_SET_FEATHER,
  ICON_SET_ION,
  ICON_SET_MATERIAL,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { decamelize } from 'lane-shared/helpers/formatters';

const prefixes = {
  [ICON_SET_FEATHER]: 'Fi',
  [ICON_SET_ION]: 'Io',
  [ICON_SET_MATERIAL]: 'Md',
  [ICON_SET_FONTAWESOME]: 'Fa',
};
export default function getIconRealName({ name, set }: any) {
  if (set === ICON_SET_FONTAWESOME) {
    return name;
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return decamelize(name.replace(prefixes[set], ''));
}
