import React, { useContext, useState, useMemo } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import { deleteChannelMutation } from 'lane-shared/graphql/channel';
import { getDisplayName, getValidationMessages } from 'lane-shared/helpers';
import {
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETED,
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETE_FAILED,
} from 'lane-shared/helpers/constants/analytics';
import { CHANNEL_DATA_TAGS } from 'lane-shared/helpers/constants/channel';
import { PERMISSION_ADMIN } from 'lane-shared/helpers/constants/permissions';
import hasPermission from 'lane-shared/helpers/hasPermission';
import { useFlag } from 'lane-shared/hooks';
import {
  ActiveChannelTypeEnum,
  ChannelExperienceTypeEnum,
  ChannelType,
} from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { GroupRoleJoinRuleType } from 'lane-shared/types/GroupRoleJoinRuleType';
import { ProfileType } from 'lane-shared/types/ProfileType';
import { ContentType } from 'lane-shared/types/content/Content';
import { RequirementsFeatureProperties } from 'lane-shared/types/features/RequirementsFeatureProperties';

import { AccessControlModuleToggle } from 'lane-web/src/domains/accessControl/components/AccessControlModuleToggle';
import { HardwareManagementToggle } from 'lane-web/src/domains/hardwareManagement';
import { ReservableManagementToggle } from 'lane-web/src/domains/reservableManagement';
import { SurveysToggle } from 'lane-web/src/domains/surveys';
import { VisitorManagementToggle } from 'lane-web/src/domains/visitorManagement';
import { WorkOrderToggle } from 'lane-web/src/domains/workOrder/components/workOrderToggle';
import { BillingPaymentsToggle } from 'lane-web/src/domains/billingAndPayments/components/BillingPaymentsToggle';

import { Input, Toggle, Slider } from 'components/form';
import { IconButton } from 'components/general';
import { GroupRoleSelector, ChannelSelectorButton } from 'components/lane';
import ChannelWhitelabelSettings from 'components/lane/ChannelSettingsEdit/ChannelWhitelabelSettings';
import { H4, H5, M, Text } from 'components/typography';

import Alert, { AlertType } from '../../lds/Alert';
import { ChannelLanguageSettings } from './ChannelLanguageSettings';
import ChannelRequirementsSettings from './ChannelRequirementsSettings';
import { SyncJoinRulesButton } from './components/SyncJoinRulesButton';

import styles from './ChannelSettings.scss';
import { MessagingAndMarketplaceToggle } from 'lane-web/src/domains/messagingAndMarketplace';
import { WorkOrderToggleSet } from '../../../domains/workOrder/components/WorkOrderToggleSet';

export type Channel = Pick<
  ChannelType,
  | '_id'
  | 'name'
  | 'isDiscoverable'
  | 'isCustomer'
  | 'isPublic'
  | 'inviteOnly'
  | 'profile'
  | 'filters'
  | 'settings'
  | 'parent'
  | 'hasCustomOffboarding'
  | 'experienceType'
>;

type Props = {
  validation: any;
  channel: Channel;
  isSaveDisabled?: boolean;
  onChannelUpdated: any;
  channelForDataIdentifiers: ChannelType;
  dataCy?: string;
  hasWorkOrderEnabled: boolean;
  onHasWorkOrderEnabledUpdated: any;
};

export function ChannelSettings({
  validation,
  channel,
  isSaveDisabled = false,
  onChannelUpdated,
  channelForDataIdentifiers,
  hasWorkOrderEnabled,
  onHasWorkOrderEnabledUpdated,
}: Props) {
  const allowChannelSettingsRequirements = useFlag<boolean>(
    'allow-channel-settings-requirements',
    false
  );
  const allowChannelArchiving = useFlag<boolean>(
    'allow-channel-archiving',
    false
  );
  const isDynamicLanguagesEnabled = useFlag<boolean>(
    FeatureFlag.DynamicLanguagesMultiLanguageToggle,
    false
  );
  const isSmartUserManagementFeatureEnabled = useFlag<boolean>(
    FeatureFlag.RemoveBuildingUser,
    false
  );
  const isWorkOrdersEnabled = useFlag<boolean>(FeatureFlag.WorkOrders, false);
  const isWorkPlaceEnabmentEnabled = useFlag<boolean>(
    FeatureFlag.WorkOrdersWorkplaceEnablement,
    false
  );

  const reservableManagementFlag = useFlag(
    FeatureFlag.ReservableManagement,
    false
  );

  const surveysFlag = useFlag(FeatureFlag.Surveys, false);

  const syncJoinRulesFlag = useFlag(FeatureFlag.SyncJoinRules, false);

  const messagingAndMarketplaceFlag = useFlag(
    FeatureFlag.MessagingAndMarketplace,
    false
  );

  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const analytics = useContext(AnalyticsContext);
  const [requirementsEnabled, setRequirementsEnabled] = useState(
    Boolean(channel.settings.requirements)
  );

  const history = useHistory();

  const canViewChannelLanguageSettings =
    user?.isSuperUser && isDynamicLanguagesEnabled;

  function onAddFilter() {
    const filters = channel.filters || [];

    if (!filters.includes('')) {
      filters.push('');
    }

    onChannelUpdated({ filters });
  }

  function handleChangeRequirements(
    nextRequirements: Partial<RequirementsFeatureProperties>
  ) {
    onChannelUpdated({
      settings: {
        ...channel.settings,
        requirements: { ...channel.settings.requirements, ...nextRequirements },
      },
    });
  }

  function handleToggleRequirements(enabled: boolean) {
    setRequirementsEnabled(enabled);
    onChannelUpdated({
      settings: {
        ...channel.settings,
        requirements: enabled ? {} : null,
      },
    });
  }

  function handleTogglePhysicalBuilding(enabled: boolean) {
    const tags = new Set(channelForDataIdentifiers.tags);
    const fields: Partial<ChannelType> = {};

    if (enabled) {
      tags.add(CHANNEL_DATA_TAGS.physicalBuilding);
    } else {
      tags.delete(CHANNEL_DATA_TAGS.physicalBuilding);
      if (!isWorkPlaceEnabmentEnabled) {
        // if toggling off physical property must remove work orders as well.
        onHasWorkOrderEnabledUpdated(false);
        fields.settings = {
          ...channel.settings,
          hasWorkOrdersEnabled: false,
        };
      }
    }

    fields.tags = Array.from(tags);

    updateChannel({
      fields,
    });
  }

  function onAddJoinAlso() {
    const groupRoleJoinRules: Partial<GroupRoleJoinRuleType>[] =
      channel.settings.groupRoleJoinRules || [];

    groupRoleJoinRules.push({
      _id: uuid(),
    });

    onChannelUpdated({
      settings: {
        ...channel.settings,
        groupRoleJoinRules: [...groupRoleJoinRules],
      },
    });
  }

  async function deleteChannel() {
    if (!allowChannelArchiving) {
      return;
    }
    try {
      await window.Alert.confirm({
        title: t('web.admin.channel.profile.settings.delete.title'),
        message: t('web.admin.channel.profile.settings.delete.message'),
        confirmText: t('web.admin.channel.profile.settings.delete.confirmText'),
      });
    } catch (err) {
      return;
    }

    try {
      await getClient().mutate({
        mutation: deleteChannelMutation,
        variables: {
          channelId: channel?._id,
        },
      });

      analytics.track(ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETED, {
        deletedChannelId: channel?._id,
        deletedChannelName: channel?.name,
      });

      history.replace(routes.me);
      window.Toast.show(
        <p>
          {t('web.admin.channel.profile.settings.delete.toast.success', {
            channelName: getDisplayName(channel),
          })}
        </p>
      );
    } catch (ex) {
      analytics.track(ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETE_FAILED, {
        attemptedChannelId: channel?._id,
        attemptedChannelName: channel?.name,
        error: ex,
      });

      window.Toast.show(
        <p>
          {t('web.admin.channel.profile.settings.delete.toast.error', {
            errorMessage: (ex as any).message,
          })}
        </p>
      );
    }
  }

  const groupRoleJoinRules: Partial<GroupRoleJoinRuleType>[] =
    channel.settings.groupRoleJoinRules || [];

  // isCustomer is a new field with no default value, let's check if it has been set already
  const isChannelIsCustomerDefined = !(
    typeof channel.isCustomer === 'undefined' || channel.isCustomer === null
  );

  function updateChannel({ fields = {} }: { fields?: Partial<ChannelType> }) {
    const updatedProfile = {
      ...(channel?.profile || {}),
    } as ProfileType;

    if (updatedProfile.content?._id) {
      // There maybe full content here loaded from the server.
      // but we don't want to set full content info back.
      // Just the id.  ProfileInput expects a ContentIdInput.
      updatedProfile.content = ({
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        _id: channel.profile?.content._id,
      } as Partial<ContentType>) as ContentType;
    }

    onChannelUpdated({
      ...fields,
    });
  }

  function setTag(tag: string, value: boolean): void {
    const tags = new Set(channelForDataIdentifiers.tags);
    if (value) {
      tags.add(tag);
    } else {
      tags.delete(tag);
    }

    updateChannel({ fields: { tags: Array.from(tags) } });
  }

  function getTag(tagName: string): boolean {
    return channelForDataIdentifiers.tags.includes(tagName);
  }

  const shouldRenderPhysicalBuildingTag = useMemo(() => {
    // only render for child channels or sub channels
    return (
      channelForDataIdentifiers.type === ActiveChannelTypeEnum.Property &&
      channelForDataIdentifiers.parent
    );
  }, [channelForDataIdentifiers.type, channelForDataIdentifiers.parent]);

  const isSuperUserOrAdmin =
    user?.isSuperUser ||
    hasPermission(user?.roles, [PERMISSION_ADMIN], channel?._id);

  return (
    <div className={styles.channelSettings}>
      <H4 mb={2}>
        {t('web.admin.channel.profile.settings.channelConfiguration.header')}
      </H4>
      <div className={styles.settings}>
        <div className={styles.setting}>
          <Toggle
            dataCy="isPublicToggle"
            className={styles.toggle}
            value={channel.isPublic}
            onChange={isPublic => onChannelUpdated({ isPublic })}
            text={t('web.admin.channel.profile.settings.public.toggle.text')}
            description={t(
              'web.admin.channel.profile.settings.public.toggle.description'
            )}
          />
        </div>
        <div className={styles.setting}>
          <Toggle
            dataCy="isDiscoverableToggle"
            className={styles.toggle}
            value={channel.isDiscoverable}
            onChange={isDiscoverable => onChannelUpdated({ isDiscoverable })}
            text={t(
              'web.admin.channel.profile.settings.discoverable.toggle.text'
            )}
            description={t(
              'web.admin.channel.profile.settings.discoverable.toggle.description'
            )}
          />
        </div>
        {isSmartUserManagementFeatureEnabled && (
          <div className={styles.setting}>
            <Toggle
              dataCy="isSmartUserManagementToggle"
              className={styles.toggle}
              value={Boolean(channel.settings.hasSmartUserManagementEnabled)}
              onChange={enabled => {
                onChannelUpdated({
                  settings: {
                    ...channel.settings,
                    hasSmartUserManagementEnabled: enabled,
                  },
                });
              }}
              text={t(
                'web.admin.channel.profile.settings.smartRemoveManagement.toggle.text'
              )}
              description={t(
                'web.admin.channel.profile.settings.smartRemoveManagement.toggle.description'
              )}
            />
          </div>
        )}
        <div className={styles.setting}>
          <Toggle
            dataCy="inviteOnlyToggle"
            className={styles.toggle}
            value={channel.inviteOnly}
            onChange={inviteOnly => onChannelUpdated({ inviteOnly })}
            text={t(
              'web.admin.channel.profile.settings.inviteOnly.toggle.text'
            )}
            description={t(
              'web.admin.channel.profile.settings.inviteOnly.toggle.description'
            )}
          />
        </div>
        <div className={styles.setting}>
          <Toggle
            dataCy="maxUsersToggle"
            className={styles.toggle}
            value={Boolean(channel.settings.maxUsers)}
            onChange={() =>
              onChannelUpdated({
                settings: {
                  ...channel.settings,
                  maxUsers: channel.settings.maxUsers ? 0 : 1000,
                },
              })
            }
            text={t('web.admin.channel.profile.settings.maxUsers.toggle.text')}
            description={t(
              'web.admin.channel.profile.settings.maxUsers.toggle.description'
            )}
          />
          {Boolean(channel.settings.maxUsers) && (
            <div className={styles.settingInput}>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={1}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                max={20000}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                step={50}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={channel.settings.maxUsers}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(maxUsers: any) => any' is not assignable to... Remove this comment to see the full error message
                onChange={(maxUsers: any) =>
                  onChannelUpdated({
                    settings: {
                      ...channel.settings,
                      maxUsers,
                    },
                  })
                }
              />
            </div>
          )}
        </div>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="toggleSubscribersJoinParent"
            value={channel.settings.subscribersJoinParent}
            onChange={subscribersJoinParent => {
              onChannelUpdated({
                settings: { ...channel.settings, subscribersJoinParent },
              });
            }}
            text={t(
              'web.admin.channel.profile.settings.joinParent.toggle.text'
            )}
            description={t(
              'web.admin.channel.profile.settings.joinParent.toggle.description'
            )}
          />
        </div>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="toggleSubscribersAreDecoyed"
            value={channel.settings.subscribersAreDecoyed}
            onChange={subscribersAreDecoyed => {
              onChannelUpdated({
                settings: { ...channel.settings, subscribersAreDecoyed },
              });
            }}
            text={t(
              'web.admin.channel.profile.settings.subscribersAreDecoyed.toggle.text'
            )}
            description={t(
              'web.admin.channel.profile.settings.subscribersAreDecoyed.toggle.description'
            )}
          />
        </div>
        {channel.experienceType === ChannelExperienceTypeEnum.multifamily && (
          <>
            <div className={styles.setting}>
              <Toggle
                dataCy="customOffboardingToggle"
                className={styles.toggle}
                value={Boolean(channel.hasCustomOffboarding)}
                onChange={hasCustomOffboarding => {
                  onChannelUpdated({
                    hasCustomOffboarding,
                    settings: {
                      ...channel.settings,
                      offboardingDelayDays: hasCustomOffboarding
                        ? channel.settings.offboardingDelayDays
                        : undefined,
                    },
                  });
                }}
                text={t(
                  'web.admin.channel.profile.settings.customOffboarding.toggle.text'
                )}
                description={t(
                  'web.admin.channel.profile.settings.customOffboarding.toggle.description'
                )}
              />
              {Boolean(channel.hasCustomOffboarding) && (
                <div
                  className={cx(styles.settingInput, styles.settingInputNested)}
                >
                  <div className={styles.emptySpace} />
                  <Input
                    type="number"
                    dataCy="customOffboardingInput"
                    className={styles.input}
                    label={t(
                      'web.admin.channel.profile.settings.customOffboarding.input.description'
                    )}
                    fixedLabel
                    error={getValidationMessages(
                      validation,
                      `settings.offboardingDelayDays`
                    )}
                    value={channel.settings.offboardingDelayDays}
                    onChange={offboardingDelayDays =>
                      onChannelUpdated({
                        settings: {
                          ...channel.settings,
                          offboardingDelayDays: parseInt(
                            offboardingDelayDays,
                            10
                          ),
                        },
                      })
                    }
                  />
                </div>
              )}
            </div>
            <div className={styles.setting} />{' '}
            {/* Spacer to keep email filter and join rules aligned correctly */}
          </>
        )}

        <div className={styles.settingsHeader}>
          <H5>{t('web.admin.channel.profile.settings.emailFilters.header')}</H5>
          <Icon
            dataCy="newEmailFilter"
            name="plus-circle"
            onClick={onAddFilter}
          />
        </div>
        {(channel.filters || []).map((filter, i) => (
          <div key={i} className={styles.inputWrapper}>
            <Input
              dataCy="filterEmailInput"
              className={styles.input}
              error={getValidationMessages(validation, `filters[${i}]`)}
              placeholder="example.com"
              value={filter}
              onChange={newFilter => {
                channel.filters[i] = newFilter;
                onChannelUpdated({ filters: channel.filters });
              }}
            />
            <IconButton
              icon="times"
              dataCy="removeEmailFilter"
              className={styles.removeIcon}
              inverted
              onClick={() =>
                onChannelUpdated({
                  filters: channel.filters.filter((filter, j) => j !== i),
                })
              }
            />
          </div>
        ))}
        <div className={styles.settingsHeader}>
          <H5>{t('web.admin.channel.profile.settings.joinRules.header')}</H5>
          <Icon name="plus-circle" onClick={onAddJoinAlso} />
        </div>
        {groupRoleJoinRules.map(groupRoleJoinRule => (
          <div key={groupRoleJoinRule._id} className={styles.inputWrapper}>
            <span className={styles.inputBlock}>
              <GroupRoleSelector
                channelId={channel._id}
                className={styles.dropdown}
                groupRoleId={groupRoleJoinRule.groupRoleId || null}
                includeWorkplaceMember
                placeholder={t(
                  'web.admin.channel.profile.settings.joinRules.placeHolder'
                )}
                onGroupRoleSelected={groupRole => {
                  groupRoleJoinRule.groupRoleId = groupRole?._id;
                  onChannelUpdated({ settings: { ...channel.settings } });
                }}
              />
              <Text className={styles.lineText}>
                {t('web.admin.channel.profile.settings.joinRules.text')}
              </Text>
            </span>
            <ChannelSelectorButton
              className={styles.dropdown}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              channelId={groupRoleJoinRule?.channelId}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(selectedChannel: any) => void' is not assig... Remove this comment to see the full error message
              onChannelSelected={(selectedChannel: any) => {
                groupRoleJoinRule.channelId = selectedChannel?._id;
                onChannelUpdated({ settings: { ...channel.settings } });
              }}
            />
            <Text className={styles.lineText}>{t('as a')}</Text>
            <span className={styles.inputBlock}>
              {groupRoleJoinRule.channelId ? (
                <GroupRoleSelector
                  className={styles.dropdown}
                  channelId={groupRoleJoinRule.channelId}
                  groupRoleId={groupRoleJoinRule.joinGroupRoleId || null}
                  includeWorkplaceMember
                  placeholder={t(
                    'web.admin.channel.profile.settings.joinRules.placeHolder'
                  )}
                  onGroupRoleSelected={groupRole => {
                    groupRoleJoinRule.joinGroupRoleId = groupRole?._id;
                    onChannelUpdated({ settings: { ...channel.settings } });
                  }}
                />
              ) : (
                <div>...</div>
              )}
              <IconButton
                icon="times"
                className={styles.removeIcon}
                inverted
                onClick={() =>
                  onChannelUpdated({
                    settings: {
                      ...channel.settings,
                      groupRoleJoinRules: groupRoleJoinRules.filter(
                        jr => jr._id !== groupRoleJoinRule._id
                      ),
                    },
                  })
                }
              />
              {/* Hide Sync button until changes are saved */}
              {isSaveDisabled && syncJoinRulesFlag && (
                <SyncJoinRulesButton
                  channel={channel}
                  groupRoleJoinRule={groupRoleJoinRule}
                />
              )}
            </span>
          </div>
        ))}
      </div>
      <H4 mb={2}>
        {t('web.admin.channel.profile.settings.advanceFeatures.header')}
      </H4>
      <div className={styles.settings}>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="toggleHasPerksEnabled"
            value={channel.settings.hasPerksEnabled}
            onChange={enabled =>
              onChannelUpdated({
                settings: { ...channel.settings, hasPerksEnabled: enabled },
              })
            }
            text={t('web.admin.channel.profile.settings.perks.toggle.text')}
            description={t(
              'web.admin.channel.profile.settings.perks.toggle.description'
            )}
          />
        </div>
        <div className={styles.setting}>
          <Toggle
            className={styles.toggle}
            dataCy="toggleAreUserTemplatesAllowed"
            value={channel.settings.areUserTemplatesAllowed}
            onChange={enabled =>
              onChannelUpdated({
                settings: {
                  ...channel.settings,
                  areUserTemplatesAllowed: enabled,
                },
              })
            }
            text={t(
              'web.admin.channel.profile.settings.userTemplateLibrary.toggle.text'
            )}
            description={t(
              'web.admin.channel.profile.settings.userTemplateLibrary.toggle.description'
            )}
          />
        </div>
      </div>

      <H4 mb={4}>
        {t('web.admin.channel.profile.settings.propertyOperations.header')}
      </H4>
      <div className={styles.settings}>
        <VisitorManagementToggle
          channel={channel}
          onChannelUpdated={onChannelUpdated}
          t={t}
          disabled={!user?.isSuperUser}
        />
        {/* TODO: Delete this hasWorkOrderEnabled logic TM-21661 */}
        {!isWorkPlaceEnabmentEnabled && (
          <WorkOrderToggle
            channel={channel}
            onChannelUpdated={onChannelUpdated}
            t={t}
            disabled={!isSuperUserOrAdmin && !isWorkOrdersEnabled}
            hasWorkOrderEnabled={hasWorkOrderEnabled}
            isPhysicalBuilding={getTag(CHANNEL_DATA_TAGS.physicalBuilding)}
            onHasWorkOrderEnabledUpdated={onHasWorkOrderEnabledUpdated}
          />
        )}
        {isSuperUserOrAdmin && (
          <HardwareManagementToggle
            channel={channel}
            onChannelUpdated={onChannelUpdated}
            t={t}
            user={user}
          />
        )}
        {reservableManagementFlag && user?.isSuperUser && (
          <ReservableManagementToggle
            channel={channel}
            onChannelUpdated={onChannelUpdated}
            t={t}
          />
        )}
        {surveysFlag && user?.isSuperUser && (
          <SurveysToggle
            channel={channel}
            onChannelUpdated={onChannelUpdated}
            t={t}
          />
        )}
        {user?.isSuperUser && (
          <AccessControlModuleToggle
            channel={channel}
            onChannelUpdated={onChannelUpdated}
            t={t}
            user={user}
          />
        )}
        <BillingPaymentsToggle
          channel={channel}
          onChannelUpdated={onChannelUpdated}
          disabled={!user?.isSuperUser}
        />
        {messagingAndMarketplaceFlag && (
          <MessagingAndMarketplaceToggle
            channel={channel}
            onChannelUpdated={onChannelUpdated}
            disabled={false}
          />
        )}
      </div>
      {isWorkPlaceEnabmentEnabled && (
        <WorkOrderToggleSet
          channel={channel}
          disabled={!user?.isSuperUser}
          onChannelUpdated={onChannelUpdated}
        />
      )}
      <div>
        <H4 mb={4}>
          {t('web.admin.channel.profile.settings.dataIdentifiers.header')}
        </H4>
        <div className={styles.dataIdentifiersWrapper}>
          <div>
            <Toggle
              value={getTag(CHANNEL_DATA_TAGS.demo)}
              onChange={value => setTag(CHANNEL_DATA_TAGS.demo, value)}
              text={t(
                'web.admin.channel.profile.settings.demoChannel.toggle.text'
              )}
              description={t(
                'web.admin.channel.profile.settings.demoChannel.toggle.description'
              )}
              dataCy="demoChannelToggle"
            />
          </div>
          <div>
            <Toggle
              value={getTag(CHANNEL_DATA_TAGS.contentDistribution)}
              onChange={value =>
                setTag(CHANNEL_DATA_TAGS.contentDistribution, value)
              }
              text={t(
                'web.admin.channel.profile.settings.contentDistribution.toggle.text'
              )}
              description={t(
                'web.admin.channel.profile.settings.contentDistribution.toggle.description'
              )}
              dataCy="contentDistributionChannelToggle"
            />
          </div>
          <div>
            <Toggle
              value={getTag(CHANNEL_DATA_TAGS.flexCompany)}
              onChange={value => setTag(CHANNEL_DATA_TAGS.flexCompany, value)}
              text={t(
                'web.admin.channel.profile.settings.flexCompany.toggle.text'
              )}
              description={t(
                'web.admin.channel.profile.settings.flexCompany.toggle.description'
              )}
              dataCy="flexCompanyChannelToggle"
            />
          </div>
          {shouldRenderPhysicalBuildingTag && (
            <div>
              <Toggle
                value={getTag(CHANNEL_DATA_TAGS.physicalBuilding)}
                onChange={handleTogglePhysicalBuilding}
                text={t(
                  'web.admin.channel.profile.settings.physicalProperty.toggle.text'
                )}
                description={t(
                  'web.admin.channel.profile.settings.physicalProperty.toggle.description'
                )}
                dataCy="physicalPropertyChannelToggle"
              />
            </div>
          )}
          <div>
            <Toggle
              value={isChannelIsCustomerDefined ? channel.isCustomer : false}
              onChange={() => {
                updateChannel({
                  fields: {
                    isCustomer: !channel.isCustomer,
                  },
                });
              }}
              disabled={Boolean(channel.parent) || Boolean(!user?.isSuperUser)}
              text={t(
                'web.admin.channel.profile.settings.isCustomer.toggle.text'
              )}
              description={t(
                'web.admin.channel.profile.settings.isCustomer.toggle.description'
              )}
              dataCy="isCustomerChannelToggle"
            />
          </div>
        </div>
      </div>

      {canViewChannelLanguageSettings && (
        <ChannelLanguageSettings
          channel={channel}
          updateChannel={updateChannel}
          validation={validation}
        />
      )}

      {allowChannelSettingsRequirements && (
        <>
          <hr />
          <Toggle
            className={styles.toggle}
            disabled={Boolean(channel.settings.effectiveParentRequirements)}
            dataCy="enableRequirementsToggle"
            value={requirementsEnabled}
            onChange={enabled => {
              handleToggleRequirements(enabled);
            }}
            text={t(
              'web.admin.channel.profile.settings.requirements.toggle.text'
            )}
            description={t(
              'web.admin.channel.profile.settings.requirements.toggle.description'
            )}
          />
          {channel.settings.effectiveParentRequirements?.source && (
            <Alert type={AlertType.info}>
              <M variant="secondary">
                {t('web.admin.channel.profile.settings.requirements.setIn')}{' '}
                {
                  channel.settings.effectiveParentRequirements?.source
                    ?.profileName
                }
              </M>
            </Alert>
          )}
          {requirementsEnabled && (
            // @ts-ignore
            <ChannelRequirementsSettings
              onChangeRequirements={handleChangeRequirements}
              requirements={channel.settings.requirements}
            />
          )}
        </>
      )}

      {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
      {user.isSuperUser && !channel?.parent && (
        <>
          <hr />
          <div className={styles.settingsHeader}>
            <H5>
              {t('web.admin.channel.profile.settings.whiteLabels.header')}
            </H5>
          </div>
          <div className={styles.settings}>
            <ChannelWhitelabelSettings
              channel={channel}
              validation={validation}
            />
          </div>

          {allowChannelArchiving && (
            <div
              className={styles.deleteLink}
              onClick={deleteChannel}
              role="button"
              tabIndex={0}
              data-cy="deleteChannelButton"
            >
              <H5>
                {t('web.admin.channel.profile.settings.delete.channel.header')}
              </H5>
            </div>
          )}
        </>
      )}
    </div>
  );
}
