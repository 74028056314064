import { ApolloError } from '@apollo/client';

import { NotAuthorizedError } from 'activate-errors';
import type { ChannelErrorData } from 'activate-errors';
import isThisError from './isThisError';

// this function finds restricted access errors for section, content & channels
export default function findAccessRestrictedError(
  err: Error | ApolloError | undefined
): ChannelErrorData | null {
  if (!err) {
    return null;
  }

  if (!isThisError(err, NotAuthorizedError)) {
    return null;
  }

  if (!(err as any).graphQLErrors) {
    return null;
  }

  const graphQLError = (err as any).graphQLErrors!.find((graphQLError: any) =>
    Boolean(graphQLError.extensions?.exception?.channel)
  );

  if (!graphQLError) {
    return null;
  }

  return graphQLError.extensions.exception.channel;
}
