import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Input from 'components/form/Input';

import styles from './OptionsMenu.scss';

export default function OptionsMenu({
  value,
  items,
  onChange,
  hasSearch,
  searchPlaceholder,
}: {
  items: {
    label: string;
    value: string;
    icon?: string;
  }[];
  onChange: (v: string) => void;
  value?: string;
  hasSearch?: boolean;
  searchPlaceholder?: string;
}) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');
  const itemsMemo = React.useMemo(
    () =>
      items.filter(content =>
        content.label.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [items, searchValue]
  );

  return (
    <div className={styles.OptionsMenu}>
      {hasSearch && (
        <Input
          icon="search"
          value={searchValue}
          onChange={value => setSearchValue(value)}
          placeholder={searchPlaceholder || t('Search')}
          showClear
          className={styles.search}
        />
      )}
      <div className={styles.items}>
        {itemsMemo.map(data => (
          <div
            className={cx(styles.option)}
            key={data.value}
            data-active={data.value === value}
            onClick={() => onChange(data.value)}
            role="button"
            tabIndex={0}
          >
            {data.icon && (
              <Icon
                className={styles.optionIcon}
                set="FontAwesome"
                name={data.icon}
              />
            )}
            <span className={styles.optionText}>{data.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
