import React, { useState } from 'react';
import { useIsAdminView } from 'hooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import {
  ActivateVisitor,
  EventStatus,
  VisitorPassQueryResponse,
} from 'lane-shared/domains/visitorManagement/types';
import { getPastVisits } from 'lane-shared/graphql/visitorManagement';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import {
  SHORT_TIME_WITH_TZ,
  SIMPLE_DATE,
} from 'lane-shared/helpers/constants/dates';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { ChipSelect, ChipStyle } from 'components/ads';

import { useAfterRefresh } from '../../../../hooks/useAfterRefresh';
import {
  StatusKeyToLabelForExport,
  StatusListItems,
} from '../../../../types/EventStatus';
import type { StatusListItem } from '../../../../types/EventStatus';
import history from 'helpers/history';
import styles from './styles.scss';
import { NativeFilterTypes, Table } from 'design-system-web';
import { VisitorLogTableFilters } from '../../../../components';
import { ColumnFiltersState } from '@tanstack/react-table';
import { exportCSV } from '../../../../helpers/exportCSV';

type PastVisitsResponse = {
  pastVisits: {
    result: {
      visits: VisitorPassQueryResponse[];
      eventNames: string[];
      tenantNames: string[];
    };
    totalCount: number;
  };
};

export type VisitorRow = Pick<
  ActivateVisitor,
  'firstName' | 'lastName' | 'company' | 'email'
>;

type ColumnVisibilityState = Record<string, boolean>;

export type VisitorPassRow = Pick<
  VisitorPassQueryResponse,
  | 'companyName'
  | 'createdAtDatetime'
  | 'endDatetime'
  | 'eventName'
  | 'hostName'
  | 'tenantName'
  | 'floor'
  | 'id'
  | 'sourceId'
  | 'startDatetime'
  | 'status'
  | 'submissionId'
  | 'submittedBy'
> & { visitor: VisitorRow };

const unavailableFilters = [
  EventStatus.EVENT_STATUS_PENDING.toString(),
  EventStatus.EVENT_STATUS_UPCOMING.toString(),
  EventStatus.EVENT_STATUS_CHECKED_IN.toString(),
];

const statusListItems = StatusListItems.filter(
  (statusListItem: StatusListItem) =>
    !unavailableFilters.includes(statusListItem.value)
);
export function PastVisitsComponent({ channel }: { channel: any }) {
  const { t } = useTranslation();
  const [, channelSlug] = useIsAdminView();

  const timeZone = getTimeZoneByGeoLocation({
    latitude: channel.address.geo[1],
    longitude: channel.address.geo[0],
  });

  const currentTime = DateTime.fromObject({ zone: timeZone });
  const eodToday = currentTime.endOf('day').toJSDate();
  const sodOneMonthAgo = currentTime
    .minus({
      month: 1,
    })
    .startOf('day')
    .toJSDate();

  const [sodOneYearAgo] = useState(
    currentTime.minus({ year: 1 }).startOf('day').toJSDate()
  );
  const [startDate, setStartDate] = useState(sodOneMonthAgo);
  const [endDate, setEndDate] = useState(eodToday);
  const [visibleColumns, setVisibleColumns] = useState<ColumnVisibilityState>();
  const [searchKeyValue, setSearchKeyValue] = useState('');
  const mandatoryKeys = new Set([
    'startDate',
    'checkedInDatetime',
    'checkedOutDatetime',
  ]);

  const { handleRefresh } = useAfterRefresh();

  const { data, loading, refetch } = useQuery<PastVisitsResponse>(
    getPastVisits,
    {
      variables: {
        channelId: channel._id,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        handleRefresh(data.pastVisits.result.visits.map(p => p.id));
      },
    }
  );

  const refresh = useDebouncedCallback(() => refetch(), 500).callback;

  const [activeFilters, setActiveFilters] = useState<ColumnFiltersState>([
    {
      id: 'startDatetime',
      value: {
        startDate: sodOneMonthAgo,
        endDate: eodToday,
      },
    },
  ]);

  const handleDateFilterChange = (dateRange: DateRangeType) => {
    if (dateRange) {
      const newStartDate = DateTime.fromJSDate(
        dateRange.startDate ?? sodOneMonthAgo
      )
        .setZone(timeZone)
        .startOf('day')
        .toJSDate();

      const newEndDate = DateTime.fromJSDate(dateRange.endDate ?? eodToday)
        .setZone(timeZone)
        .endOf('day')
        .toJSDate();

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  const getFullName = (cell: ActivateVisitor) => {
    return `${cell.firstName} ${cell.lastName}`;
  };

  const getTenantFloor = (tenantName: string, floor?: string) => {
    return floor ? `${tenantName} - ${floor}` : tenantName;
  };

  const onFilterChange = (filters: ColumnFiltersState) => {
    setActiveFilters(filters);
    setSearchKeyValue('');
    for (const filter of filters) {
      if (filter.id === 'startDatetime') {
        handleDateFilterChange(filter.value as DateRangeType);
      }
    }
  };

  const getStatusData = (row: VisitorPassQueryResponse) =>
    StatusListItems.filter(item => row.status === item.value)[0];

  const columns = [
    {
      header: t('web.admin.channel.visitor.log.columns.visitor'),
      key: 'visitor',
      renderCell: (cell: ActivateVisitor) => (
        <span className={styles.visitorName}>{getFullName(cell)}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        `${visitorPass.visitor.firstName} ${visitorPass.visitor.lastName}`,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.date'),
      key: 'startDatetime',
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, SIMPLE_DATE, timeZone)}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.startDatetime,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.checkedIn'),
      key: 'checkedInDatetime',
      renderCell: (cell: string | undefined) => (
        <span>
          {cell ? dateFormatter(cell, SHORT_TIME_WITH_TZ, timeZone) : '--'}
        </span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.checkedInDatetime,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.checkedOut'),
      key: 'checkedOutDatetime',
      renderCell: (cell: string | undefined) => (
        <span>
          {cell ? dateFormatter(cell, SHORT_TIME_WITH_TZ, timeZone) : '--'}
        </span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.checkedOutDatetime,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.status'),
      key: 'status',
      renderCell: (_: any, row: VisitorPassQueryResponse) => (
        <ChipSelect.NonInteractive
          key={row.id}
          value={getStatusData(row)?.label || ''}
          type={getStatusData(row)?.type || ChipStyle.Yellow}
        />
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        t(StatusKeyToLabelForExport[visitorPass.status]),
    },
    {
      header: t('web.admin.channel.visitor.log.columns.host'),
      key: 'hostName',
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.hostName,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.hostCompany'),
      key: 'tenantName',
      renderCell: (cell: string, row: VisitorPassQueryResponse) => (
        <span>{getTenantFloor(cell, row?.floor)}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.tenantName,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.submittedBy'),
      key: 'submittedBy',
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.submittedBy,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.registeredFrom'),
      key: 'eventName',
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.eventName,
    },
  ];

  const rowActions = [
    {
      label: t(
        'web.admin.channel.visitor.log.rowActions.viewVisitorPassDetails'
      ),
      onClick: (row: VisitorPassQueryResponse) => {
        const base62VisitorPassId = convertTo62(row?.id);
        const url = routes.visitorManagementVisitorPassDetails
          .replace(':id', channelSlug || '')
          .replace(':visitorPassId', base62VisitorPassId);
        history.push(url);
      },
    },
    {
      label: t(
        'web.admin.channel.visitor.log.rowActions.viewSubmissionReceipt'
      ),
      onClick: (row: VisitorPassQueryResponse) => {
        const base62SubmissionId = convertTo62(row?.submissionId);
        const url = routes.channelAdminInteraction
          .replace(':id', channelSlug || '')
          .replace(':interactionId', base62SubmissionId);
        history.push(url);
      },
    },
  ];

  const onColumnVisibilityChange = (
    columnVisibility: ColumnVisibilityState
  ) => {
    setVisibleColumns(columnVisibility);
  };

  function keywordFilter(row: VisitorPassRow, keyword: string): boolean {
    const searchableString = [
      row.visitor.firstName,
      row.visitor.lastName,
      row.visitor.company,
      row.visitor.email,
      row.companyName,
      row.submittedBy,
      row.eventName,
      row.hostName,
      row.tenantName,
      row.floor,
    ]
      .join(' ')
      .toLowerCase();
    setSearchKeyValue(keyword);
    return searchableString.includes(keyword.toLowerCase());
  }

  const filterByDateRange = (
    data: VisitorPassQueryResponse[],
    activeFilters: ColumnFiltersState
  ) => {
    const dateRangeObject: { startDate: Date; endDate: Date } = activeFilters[0]
      .value as { startDate: Date; endDate: Date };
    const { startDate, endDate } = dateRangeObject;

    return data.filter(item => {
      const startDatetime = item.startDatetime
        ? new Date(item.startDatetime)
        : null;
      return (
        startDatetime && startDatetime >= startDate && startDatetime <= endDate
      );
    });
  };

  const filterVisitorPasses = (
    visitorPasses: VisitorPassQueryResponse[],
    filters: ColumnFiltersState
  ) => {
    return visitorPasses.filter((visitorPass: VisitorPassQueryResponse) => {
      return filters.every(filter => {
        const { id, value } = filter;
        const key = id as keyof VisitorPassQueryResponse;
        if (id === 'startDatetime') {
          return true;
        }
        if (id in visitorPass) {
          if (Array.isArray(value)) {
            return value.includes(visitorPass[key]);
          }
          return visitorPass[key] === value;
        }
        return false;
      });
    });
  };

  const exportCurrentPageAsCSV = () => {
    const dataWithRange = filterByDateRange(
      data?.pastVisits?.result?.visits || [],
      activeFilters
    );

    const visitorPasses = filterVisitorPasses(dataWithRange, activeFilters);
    const filteredData = visitorPasses.filter((row: VisitorPassRow) => {
      return keywordFilter(row, searchKeyValue);
    });

    const filteredColumn = visibleColumns
      ? columns.filter(
          item => visibleColumns[item.key] || mandatoryKeys.has(item.key)
        )
      : columns;

    exportCSV(
      filteredData,
      filteredColumn,
      `past-visitor-logs-${Date.now()}.csv`,
      channel
    );
  };

  const exportAllAsCSV = () => {
    const dataWithRange = filterByDateRange(
      data?.pastVisits?.result?.visits || [],
      activeFilters
    );

    exportCSV(
      dataWithRange,
      columns,
      `past-visitor-logs-${Date.now()}.csv`,
      channel
    );
  };

  const exportOptions = [
    {
      label: t('web.admin.visitorManagement.exportSelection'),
      onClick: exportCurrentPageAsCSV,
    },
    {
      label: t('web.admin.visitorManagement.exportAll'),
      onClick: exportAllAsCSV,
    },
  ];

  return (
    <Table
      isLoading={loading}
      columns={columns}
      data={data?.pastVisits?.result?.visits || []}
      pagination="client"
      hasKeywordFilter
      customKeywordFilterFn={keywordFilter}
      onColumnVisibilityChange={onColumnVisibilityChange}
      rowActions={rowActions}
      onFilterChange={onFilterChange}
      onRefreshClick={refresh}
      filters={VisitorLogTableFilters({
        dateFilter: NativeFilterTypes.DateRange,
        statusListItems,
        hostName: data?.pastVisits.result?.tenantNames || [],
        registeredFrom: data?.pastVisits.result.eventNames || [],
        minDate: sodOneYearAgo,
        maxDate: eodToday,
        t,
      })}
      activeFilters={activeFilters}
      exportOptions={exportOptions}
      showColumnVisibility
    />
  );
}
