/* eslint-disable react/jsx-no-literals */
import React from 'react';

import { colors } from '../../../config';
import appUrl from '../../../config/getAppUrl';
import { MjmlColumn, MjmlSection } from '../../../emails/MjmlComponentsV2';
import EmailBody from '../../../emails/components/EmailBody';
import EmailButton from '../../../emails/components/EmailButton';
import EmailFooter from '../../../emails/components/EmailFooter';
import EmailHeader from '../../../emails/components/EmailHeader';
import EmailText from '../../../emails/components/EmailText';
import { AddressType } from '../../../types/AddressType';
import { WhiteLabelType } from '../../../types/WhiteLabelType';
import i18nStub from '../../i18nStub';
import { getWebhookEventEmailTemplate } from './templates';
import { PrismEvent, WebhookResponse, WorkOrder } from './types';

export type EmailUserInteractionChangeProps = {
  whiteLabel: WhiteLabelType;
  address?: AddressType;
  webhookResData: WebhookResponse;
  event: PrismEvent;
  title: string;
  unsubscribe?: string;
  workOrderDetail: WorkOrder;
  i18n?: any;
};

export function EmailUserInteractionChange({
  whiteLabel,
  address,
  webhookResData,
  unsubscribe,
  event,
  title,
  workOrderDetail,
  i18n = i18nStub,
}: EmailUserInteractionChangeProps) {
  const username = workOrderDetail.created_by.name;

  return (
    <EmailBody title={title}>
      <EmailHeader whiteLabel={whiteLabel} i18n={i18n} />
      <MjmlSection borderTop={`1px solid ${colors.charcoal[9]}`}>
        <MjmlColumn>
          <EmailText>
            <h3
              style={{
                textAlign: 'center',
                fontWeight: 'normal',
              }}
            >
              Hi {username}
            </h3>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>

      {getWebhookEventEmailTemplate(event, workOrderDetail, webhookResData)}

      <MjmlSection padding-top="24px">
        <MjmlColumn>
          <EmailButton href={appUrl} color="#4837b9">
            View work order
          </EmailButton>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <h4>Ticket Details</h4>
          </EmailText>
          <EmailText>
            <p data-testid="wo-detail-id">
              <span style={{ fontWeight: 600 }}>Work Order ID:</span>{' '}
              {workOrderDetail.identifier}
            </p>
            <p data-testid="wo-detail-status">
              <span style={{ fontWeight: 600 }}>Status:</span>{' '}
              {workOrderDetail.status}
            </p>
            <p data-testid="wo-detail-issue">
              <span style={{ fontWeight: 600 }}>Category:</span>{' '}
              {workOrderDetail.issue_type.name} -{' '}
              {workOrderDetail.issue_type.issue_category}
            </p>
            <p data-testid="wo-detail-desc">
              <span style={{ fontWeight: 600 }}>Description:</span>{' '}
              {workOrderDetail.issue_type.description}
            </p>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      <EmailFooter
        contactUsEmail={workOrderDetail.email}
        address={address}
        whiteLabel={whiteLabel}
        unsubscribe={unsubscribe}
        i18n={i18n}
      />
    </EmailBody>
  );
}
