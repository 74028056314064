import React from 'react';

import cx from 'classnames';

import { useStoredState } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';

import ChannelSelectorButton from './ChannelSelectorButton';
import UserSearchOnChannel from './UserSearchOnChannel';

import styles from './UserSelector.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  channelId?: string;
  onUserSelected: (
    user: UserType | null,
    selectedChannel: string | null
  ) => void;
  disableChannelSelect: boolean;
  disableStorage?: boolean;
  includeWorkplaceMember: boolean;
  storageKey?: string;
};

UserSelector.defaultProps = {
  onUserSelected: () => null,
  disableChannelSelect: false,
  disableStorage: false,
  includeWorkplaceMember: false,
};

type Props = OwnProps & typeof UserSelector.defaultProps;

export default function UserSelector({
  className,
  style,
  channelId,
  onUserSelected,
  disableChannelSelect,
  disableStorage,
  includeWorkplaceMember,
  storageKey,
}: Props) {
  const [selectedChannel, setSelectedChannel] = useStoredState<
    string | undefined | null
  >(`UserSelector${storageKey}`, channelId, { disableStorage });

  return (
    <div className={cx(styles.UserSelector, className)} style={style}>
      <section>
        {!disableChannelSelect && (
          <ChannelSelectorButton
            className={styles.selectorButton}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
            channelId={selectedChannel}
            storageKey={`UserSelector${storageKey}ChannelSelectorButton`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(channel: any) => void' is not assignable to... Remove this comment to see the full error message
            onChannelSelected={(channel: any) =>
              setSelectedChannel((channel && channel._id) || null)
            }
            disableStorage={disableStorage}
          />
        )}

        {selectedChannel && (
          <UserSearchOnChannel
            className={styles.userSearch}
            storageKey={`UserSelector${storageKey}UserSearchOnChannel`}
            channelId={selectedChannel}
            onUserSelected={onUserSelected}
            disableStorage={disableStorage}
            includeWorkplaceMember={includeWorkplaceMember}
          />
        )}
      </section>
    </div>
  );
}
