import React from 'react';

import cx from 'classnames';

import styles from './Report.scss';

export default function Report({ className, style, children, loading }: any) {
  return (
    <div
      className={cx(styles.Report, className)}
      style={style}
      data-loading={loading}
    >
      {children}
    </div>
  );
}
