import { useMemo } from 'react';

import _ from 'lodash';

import { ContentType } from '../../types/content/Content';
import { UserContentInteractionType } from '../../types/UserContentInteraction';
import { EnabledFeaturesForAnalyticsType } from '../../types/Analytics';
import { ReservableFeatureProperties } from '../../types/features/ReservableFeatureProperties';
import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';

export const useFormatInteractionForAnalytics = ({
  content,
  interaction,
  primaryId,
  reservableFeature,
}: {
  content: ContentType;
  interaction: UserContentInteractionType | null;
  primaryId: string | null;
  reservableFeature: ReservableFeatureProperties | undefined;
}) => {
  const interactionForAnalytics = useMemo(() => {
    const features: EnabledFeaturesForAnalyticsType = {};

    if (reservableFeature) {
      const { bufferTime, relatedSpaces } = reservableFeature;

      features[FeatureNameEnum.Reservable] = {
        bufferTime: Boolean(bufferTime?.isEnabled),
        relatedSpaces: !_.isEmpty(relatedSpaces),
      };
    }

    return {
      _id: interaction?._id,
      channel: primaryId
        ? {
            _id: primaryId,
          }
        : undefined,
      content: content
        ? {
            _id: content._id,
          }
        : undefined,
      enabledFeatures: features,
    };
  }, [interaction?._id, primaryId, reservableFeature]);

  return {
    interactionForAnalytics,
  };
};
