import { UserType } from 'lane-shared/types/User';

import { IntegrationProviderEnum } from '../../../../types/integrations/IntegrationEnums';
import { constructInteraction } from '../../../content';
import getDisplayName from '../../../getDisplayName';
import constructAddress from './constructAddress';

type Args = {
  content: any;
  user: UserType | null;
  oldRequest: any;
};

export default function constructNewRequest({
  content,
  user,
  oldRequest,
}: Args) {
  const interaction = constructInteraction(content);
  const request =
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    interaction.data[`_${IntegrationProviderEnum.ServiceRequest}`];

  request.name = getDisplayName(user);
  request.email = user?.profile?.email || '';

  if (oldRequest) {
    request.email = oldRequest.email;
    request.name = oldRequest.name;
    request.company = oldRequest.company;
    request.phone = oldRequest.phone;
  }

  if (content.integration.settings.buildings.length === 1) {
    request.address = constructAddress({
      building: content.integration.settings.buildings[0],
      content,
    });
  }

  if (content.integration.settings.categories.length === 1) {
    request.category = content.integration.settings.categories[0].category;
  }

  return request;
}
