type YearItem = {
  label: string;
  value: number;
};

const yearItems: YearItem[] = new Array(20).fill(1).map((x, i) => ({
  label: `${2015 + i}`,
  value: 2015 + i,
}));

export default yearItems;
