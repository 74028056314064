import { useContext } from 'react';

import { ChannelsContext, ThemeContext } from 'lane-shared/contexts';
import { useChannelProfileQuery, useChannelTheme } from 'lane-shared/hooks';

/**
 * Fetch theme colors from channel context.
 * If those don't exist, default to theme from ThemeContext.
 */
export default function useThemeColors(channelId?: string) {
  const channelContext = useContext(ChannelsContext);
  const { channel } = useChannelProfileQuery({
    channelId: channelId ?? channelContext?.primaryChannel?._id,
  });
  const theme = useChannelTheme(channel ?? undefined);
  const themeFromContext = useContext(ThemeContext);

  return {
    primary: theme?.palette?.primary ?? themeFromContext.primary,
    secondary:
      channel && theme?.palette?.secondary
        ? theme.palette.secondary
        : themeFromContext.accentColor,
  };
}
