import { useContext } from 'react';

import ChannelAdminContext from '../pages/portal/admin/channel/ChannelAdminContext';

/**
 * Note that this hook must be used under a route from
 * pages/portal/admin/channel as that is where the provider is created
 */
export default function useChannelAdminContext() {
  return useContext(ChannelAdminContext);
}
