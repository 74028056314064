import React from 'react';

import cs from 'classnames';
import { Button } from 'components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import { LaneType } from 'common-types';
import { invalidStatuses } from 'lane-shared/domains/visitorManagement/constants';
import {
  EventStatus,
  VisitorPassQueryResponse,
} from 'lane-shared/domains/visitorManagement/types';
import { cancelSubmission } from 'lane-shared/graphql/visitorManagement';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { InteractionCreatorType } from 'lane-shared/types/server/WorkflowTypes';

import { Modal } from 'components/lds';
import { H4 } from 'components/typography';

import styles from './VisitorManagementCancelButton.scss';

type VisitorPassServiceData = {
  visitorPassesBySubmissionId: {
    items: VisitorPassQueryResponse[];
  };
};

type Interaction = {
  _id: LaneType.UUID;
  _created: string;
  _createdBy: InteractionCreatorType;
  startDate: string | Date | null;
  endDate: string | Date | null;
  status: string;
  geo: GeoCoordinateType;
  features: UserContentInteractionFeaturesType;
  contentData: {
    channelId?: string | null;
    geo?: GeoCoordinateType | null;
  };
};

export function VisitorManagementCancelButton({
  interaction,
  data,
  refetch,
  title,
}: {
  interaction: Interaction;
  data?: VisitorPassServiceData;
  refetch: () => void;
  title: string;
}) {
  const [isCanceling, setIsCanceling] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const { t } = useTranslation();

  const [cancelSubmissionMutation] = useMutation(cancelSubmission, {
    variables: {
      submissionId: convertToUUID(interaction._id),
    },
  });

  const visitorManagementFlag = useFlag(FeatureFlag.VisitorManagement, false);
  if (!visitorManagementFlag) return null;
  const isEmpty = !data?.visitorPassesBySubmissionId?.items?.length;

  if (isEmpty) return null;

  const allPassesCancelled =
    !isEmpty &&
    data?.visitorPassesBySubmissionId?.items?.every(pass =>
      invalidStatuses.includes(pass.status as EventStatus)
    );

  if (allPassesCancelled) return null;

  const handleCancel = async () => {
    setIsCanceling(true);
    setShowCancelModal(false);
    try {
      await cancelSubmissionMutation();
      setTimeout(() => {
        refetch();
      }, 3000);
    } catch {
      setIsCanceling(false);
    }
  };

  const uciExpired = hasVisitorPassExpired(
    interaction.features.VisitorManagement?.schedule?.endDate,
    interaction.contentData?.geo
  );
  if (uciExpired) return null;

  return (
    <>
      <Button
        variant="text"
        onClick={() => setShowCancelModal(true)}
        className={styles.cancelButton}
        style={{ width: '100%' }}
        loading={isCanceling}
      >
        {t('web.content.feature.visitorManagement.receipt.cancelSubmission')}
      </Button>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        className={styles.vmCancelModal}
        style={{ maxWidth: 320 }}
        dataCy="vmCancelModal"
      >
        <H4>
          {t(
            'web.content.feature.visitorManagement.receipt.cancelSubmission.areYouSure',
            { title }
          )}
        </H4>
        <p className={styles.content}>
          {t(
            'web.content.feature.visitorManagement.receipt.cancelSubmission.areYouSure.content'
          )}
        </p>
        <p className={cs(styles.content)}>
          {t(
            'web.content.feature.visitorManagement.receipt.cancelSubmission.areYouSure.thisAction'
          )}
        </p>
        <hr />
        <div className={styles.buttonContainer}>
          <Button onClick={() => setShowCancelModal(false)}>
            {t(
              'web.content.feature.visitorManagement.receipt.cancelSubmission.areYouSure.goBack'
            )}
          </Button>
          <Button
            onClick={async () => await handleCancel()}
            variant="contained"
          >
            {t(
              'web.content.feature.visitorManagement.receipt.cancelSubmission.areYouSure.iMSure'
            )}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export function hasVisitorPassExpired(
  endDate: string | Date | undefined,
  geo?: GeoCoordinateType | null
): boolean {
  let timeZone = 'America/New_York';

  if (Array.isArray(geo) && geo[0] && geo[1]) {
    timeZone = getTimeZoneByGeoLocation({
      latitude: geo[1],
      longitude: geo[0],
    });
  }

  const validatedDate =
    endDate instanceof Date ? endDate.toISOString() : endDate;

  const convertedEndDate = DateTime.fromISO(validatedDate as string, {
    zone: timeZone,
  }).valueOf();

  const convertedNow = DateTime.now().setZone(timeZone).valueOf();

  return convertedNow > convertedEndDate;
}
