import * as ics from 'ics';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { GeoCoordinateType } from '../types/baseTypes/GeoTypes';
import { INTERACTION_CLOSED_STATES } from './constants/interactions';

function dateTimeToDateArray(date: DateTime): ics.DateArray {
  return [date.year, date.month, date.day, date.hour, date.minute];
}

function dirToQuoted(event: string): string {
  // capture everything that goes after 'dir=' to the ';'.
  const match = new RegExp('(?<=DIR=)(.*\\s*.*)(?=;)', 'g');

  return event.replace(
    match,
    (match: string) => `"${match.replace(/\s+/, '')}"`
  );
}

export type IcsPersonType = {
  name?: string;
  email?: LaneType.Email;
  dir?: LaneType.Url;
};

export default function generateIcsData({
  id = uuid(),
  startDate,
  endDate,
  creationDate = DateTime.local(),
  name,
  description,
  organizer,
  attendees,
  status,
  geo,
  url,
  categories,
  location,
}: {
  id?: LaneType.UUID;
  startDate: DateTime;
  endDate: DateTime;
  creationDate?: DateTime;
  name: string;
  description: string;
  organizer?: IcsPersonType;
  attendees?: IcsPersonType[];
  status?: string;
  geo?: GeoCoordinateType;
  url?: LaneType.Url;
  categories?: string[];
  location?: string;
}): string {
  const event: ics.EventAttributes = {
    created: dateTimeToDateArray(creationDate),
    start: dateTimeToDateArray(startDate),
    end: dateTimeToDateArray(endDate),
    title: name,
    status:
      status && INTERACTION_CLOSED_STATES.includes(status)
        ? 'CANCELLED'
        : 'CONFIRMED',
    productId: 'lane',
    alarms: [
      {
        action: 'display',
        trigger: { minutes: 10, before: true },
        attachType: 'VALUE=URI',
      },
    ],
    uid: id,
    busyStatus: 'BUSY',
    geo: geo ? { lat: geo[1], lon: geo[0] } : undefined,
    description,
    location,
    categories,
    url,
    organizer,
    attendees,
  };

  const { error, value } = ics.createEvent(event);

  if (error) {
    throw error;
  }

  if (!value) {
    throw new Error('Invalid ICS data');
  }

  /* Note:
    ics npm lib is not working as expected. 
    If user have dir property then it won't be visible in the calendar as a invitee.
    It happened because of missing quotes around dir URI. Ref.: https://datatracker.ietf.org/doc/html/rfc5545
    Unfortunately we can't add quotes before event creation because in this case ics lib throws an error during url regex check.
    Also for some reason after passing url to the lib, it may be returned in with a \r\n\t in the middle of the url like:
    https://app.joinlane.com/l/user/0P3GlgNGq1Lbw33I90
        zjDT;CN...
    For this reason we need to wrap it with quotes and clean possible whitespaces in the url.

    TODO: Check if dir quotes were fixed here https://github.com/adamgibbons/ics/blob/master/src/utils/set-organizer.js#L3    
  */
  const formattedValue = dirToQuoted(value);

  return formattedValue;
}
