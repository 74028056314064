import Color from 'color';

import { DEFAULT_COLOR, DEFAULT_BACKGROUND } from './constants';

/**
 * Checks the colour used for a background and returns a suitable text colour that
 * would have enough contrast with it.
 */
export default function getTextColor(bgColor: string | Color) {
  const color = new Color(bgColor);

  return color.isDark() ? DEFAULT_BACKGROUND : DEFAULT_COLOR;
}
