import gql from 'graphql-tag';

const searchSectionContentQuery = gql`
  query searchSectionContent($id: UUID!, $searchOptions: SearchOptionsInput) {
    section(_id: $id, searchOptions: $searchOptions) {
      _id
      type
      sectionContent {
        _id
        _created
        _updated
        _order
        isPinned
        content {
          _id
          _created
          _updated
          name
          name_l10n
          data
          description
          description_l10n
          subtitle
          slug
          type
          tags
          category
          logo
          color
          geo
          isInteractive
          backgroundImage
          icon
          iconSet
          iconWeight
          backgroundColor
          liveDate
          startDate
          endDate
          unpublishDate
          version
          renderer
          externalUrl {
            _id
            url
            openingMode
          }
          signedExternalUrl
          deepLink {
            _id
            deepLinkUrl
            appStoreUrl
            googlePlayStoreUrl
          }
          channel {
            _id
            settings {
              multiLanguageEnabled
              channelLanguages
              enabledLanguagesForMembers
            }
          }
          card {
            _id
          }
          state
          integration {
            _id
            settings
            integration {
              _id
              name
              settings
              platforms
            }
          }
          features {
            _id
            type
            feature
          }
          contentMetatags {
            _id
            value
            metatag {
              _id
              name
              properties
            }
          }
          info {
            _id
            rating
            viewed
            details
            hidden
            deleted
            pinned
            starred
            liked
          }
        }
      }
    }
  }
`;

export default searchSectionContentQuery;
