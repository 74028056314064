/**
 * Gets HTML for a Place Pay modal on web or mobile.
 */
export default function getPlacePayHtml({
  publicKey,
  accessToken,
  amount,
  description,
  depositAccountId,
  autopay,
  mode,
  scriptSrc,
  platform,
}: {
  // the public key for this PlacePay modal
  publicKey: string;
  // the access token for the user opening this modal.
  accessToken: string;
  // the amount of the transaction, in dollars
  amount?: number;
  // the description for this transaction
  description?: string;
  // the deposit account id for the destination account
  depositAccountId: string;
  // include an option for autopay if this is for payment
  autopay?: boolean;
  // is this to make a payment or setup autopay
  mode: 'payment' | 'autopay';
  // the source script to use
  scriptSrc: string;
  // platform is web or mobile
  platform: 'web' | 'mobile';
}) {
  const postCommand =
    platform === 'web'
      ? `window.top.postMessage(JSON.stringify(data))`
      : `window.ReactNativeWebView.postMessage(JSON.stringify(data))`;

  return `
    <!DOCTYPE html>
    <html>
        <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
        />
        <meta name="theme-color" content="#000000" />
        <title>PlacePay Payment</title>
        <script src="${scriptSrc}"></script>
        <script>
        function doPost(data) {
          ${postCommand}
        }
        </script>
        </head>
        <body>
            <div id="payment_form" style="width: 100%; height: 95vh;"></div>
        </body>
            <script>
           Place.pay.open({
              key: "${publicKey}",
              access_token: "${accessToken}",
              amount: ${amount},
              description: "${description}",
              embed: true,
              autopay: ${autopay ? 'true' : 'false'},
              autopay_only: ${mode === 'autopay' ? 'true' : 'false'},
              embed_container: document.getElementById("payment_form"),
              deposit_account_id: "${depositAccountId}",
              on: {
                autopay_changed(data) {
                  doPost({
                    event: 'autopay_changed',
                    data
                  });
                },
                loaded(data) {
                  doPost({
                    event: 'loaded',
                    data
                  });
                },
                processing(data) {
                  doPost({
                    event: 'processing',
                    data
                  });
                },
                paid(data) {
                  doPost({
                    event: 'paid',
                    data
                  });
                },
                declined(data) {
                  doPost({
                    event: 'declined',
                    data
                  });
                },
                cancelled(data) {
                  doPost({
                    event: 'cancelled',
                    data
                  });
                },
                closed(data) {
                  doPost({
                    event: 'closed',
                    data
                  });
                },
                error(data) {
                  doPost({
                    event: 'error',
                    data
                  });
                },
              }
           })
        </script>
    </html>
  `;
}
