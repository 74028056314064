import React, { useState, useContext, useEffect } from 'react';

import { Flex, Icon } from 'design-system-web';
import cx from 'classnames';
import { Button, ErrorMessage, Loading, Well } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { joinChannel } from 'lane-shared/graphql/mutation';
import { pause } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import useIsMounted from 'lane-shared/hooks/useIsComponentMounted';
import { ChannelType } from 'lane-shared/types/ChannelType';

import TextInput from 'components/form/Input';
import { ChannelSearchCircleListView } from 'components/lane';
import { H3, M } from 'components/typography';

import styles from './RequirementsBlock.scss';
import { useLazyQuery } from '@apollo/client';
import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';
import { useDebounce } from 'use-debounce';
import { ChannelsByRelationshipQuery } from 'graphql-query-contracts';

export default function SelectCompanyList({ onSave }: any) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [companySearch, setCompanySearch] = useState('');
  const [company, setCompany] = useState<ChannelType>();
  const isMounted = useIsMounted();

  const { refetch: refetchUser } = useContext(UserDataContext);
  const channelContext = useContext(ChannelsContext);
  const channelId = channelContext.primaryChannel?._id;

  const DEBOUNCE_THROTTLE = 250;
  const [debouncedSearch] = useDebounce(companySearch, DEBOUNCE_THROTTLE);

  function getVariables() {
    const pagination = { start: 0, perPage: 25 };

    const channelSearch = {
      name: companySearch
        ? { type: 'like', value: companySearch.trim() }
        : undefined,
      inviteOnly: false,
      isSub: false,
    };

    const idSearch = {
      _id: channelContext.primaryChannel?._id,
    };

    return {
      pagination,
      search: {
        sortBy: { key: 'name', dir: 'asc' },
        relatedTo: idSearch,
        channel: channelSearch,
      },
    };
  }

  const [
    fetchRelationships,
    searchResults,
  ] = useLazyQuery<ChannelsByRelationshipQuery>(queryChannelsByRelationship, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (channelId && debouncedSearch.length > 2) {
      fetchRelationships({
        variables: getVariables(),
      });
    }
  }, [debouncedSearch]);

  const resultChannels =
    companySearch.length > 2 && !searchResults.loading
      ? searchResults?.data?.channelsByRelationship.items?.map(
          item => item.channel
        )
      : [];

  async function joinCompany() {
    setLoading(true);
    try {
      await pause();
      await getClient().mutate({
        mutation: joinChannel,
        variables: {
          channelId: company!._id,
        },
      });

      await refetchUser();
      if (isMounted()) {
        setLoading(false);
      }
      onSave(company?._id);
    } catch (err) {
      if (isMounted()) {
        setLoading(false);
        setError(err);
      }
    }
  }

  return (
    <div className={styles.requirementForm}>
      <div className={styles.requirementFormBody}>
        <div className={styles.iconContainer}>
          <div className={styles.companyEllipse} />
          <Icon
            className={styles.formIcon}
            name="building"
            set={ICON_SET_FONTAWESOME}
            type="fal"
            size="large"
          />
        </div>

        <div className={styles.formTitleContainer}>
          <H3 className={styles.formTitle}>
            {t(
              'web.admin.channel.content.layout.editor.components.companyForm.title'
            )}
          </H3>
        </div>

        <TextInput
          showClear
          icon="search"
          onChange={parentCompanySearch => {
            setCompanySearch(parentCompanySearch);
          }}
          value={companySearch}
          className={styles.contactFormInput}
          placeholder="Search"
          disabled={Boolean(company)}
          maxLength={50}
        />

        {!company ? (
          <Flex
            align="center"
            direction="column"
            className={styles.companyFormResultsContainer}
          >
            {!searchResults.loading &&
              resultChannels?.map(companyDetails => (
                <Flex
                  align="center"
                  justify="space-between"
                  key={companyDetails._id}
                  className={styles.contactFormContainer}
                >
                  <ChannelSearchCircleListView
                    className={styles.channelResult}
                    key={companyDetails._id}
                    channel={companyDetails}
                    onClick={parentCompany => {
                      setCompany(parentCompany);
                    }}
                  />
                  {companyDetails?.inviteOnly ? (
                    <div className={styles.chipContainer}>
                      <div className={styles.chipText}>
                        {t(
                          'web.admin.channel.content.layout.editor.components.companyForm.inviteOnly'
                        )}
                      </div>
                    </div>
                  ) : null}
                </Flex>
              ))}

            {!searchResults.loading && resultChannels?.length === 0 && (
              <Well className={styles.companyFormPlaceholder}>
                <M mb={2} className={styles.companyFormPlaceholderTitle}>
                  {t(
                    'web.admin.channel.content.layout.editor.components.companyForm.searchResults.placeholder.title'
                  )}
                </M>
                <M level="p">
                  {t(
                    'web.admin.channel.content.layout.editor.components.companyForm.searchResults.placeholder.description'
                  )}
                </M>
              </Well>
            )}
            {searchResults.loading && (
              <Loading className={styles.loading} fullcover />
            )}
          </Flex>
        ) : (
          <div>
            <div
              className={styles.selectedChannelContainer}
              style={{
                marginTop: 24,
              }}
            >
              <ChannelSearchCircleListView
                className={cx([styles.channelResult, styles.selected])}
                key={company._id}
                channel={company}
              />

              <Icon
                name="times"
                onClick={() => {
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
                  setCompany(null);
                }}
                className={styles.checkIcon}
                set={ICON_SET_FONTAWESOME}
              />
            </div>
          </div>
        )}
        <ErrorMessage error={error} />
      </div>
      <div className={styles.requirementFormFooter} style={{ marginTop: 0 }}>
        <Button
          className={styles.saveButton}
          variant="contained"
          loading={loading}
          disabled={Boolean(!company)}
          onClick={() => joinCompany()}
          fullWidth
          dataCy="Save"
        >
          {t(
            'web.admin.channel.content.layout.editor.components.companyForm.save'
          )}
        </Button>
      </div>
    </div>
  );
}
