import React from 'react';

import {
  FlexDirectionEnum,
  FlexJustifyContentEnum,
} from 'lane-shared/types/blocks/Flex';

import ButtonStrip from '../general/ButtonStrip';

const iconSet = 'FontAwesome';

const horizontalButtons = [
  {
    value: FlexJustifyContentEnum.Start,
    icon: 'align-left',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.End,
    icon: 'align-right',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.Center,
    icon: 'align-center',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.SpaceBetween,
    icon: 'line-height',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.SpaceEvenly,
    icon: 'align-justify',
    iconSet,
  },
] as const;

const verticalButtons = [
  {
    value: FlexJustifyContentEnum.Start,
    icon: 'align-top',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.End,
    icon: 'align-bottom',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.Center,
    icon: 'align-middle',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.SpaceBetween,
    icon: 'line-height',
    iconSet,
  },
  {
    value: FlexJustifyContentEnum.SpaceEvenly,
    icon: 'align-justify',
    iconSet,
  },
] as const;

type Props = {
  onChange: (value: string) => void;
  value: string;
  flexDirection: FlexDirectionEnum;
};

export default function FlexJustifyContentInput({
  onChange,
  value,
  flexDirection = FlexDirectionEnum.Horizontal,
}: Props) {
  const buttons =
    flexDirection === FlexDirectionEnum.Horizontal
      ? horizontalButtons
      : verticalButtons;

  return (
    <ButtonStrip buttons={[...buttons]} onClick={onChange} selected={value} />
  );
}
