import * as yup from 'yup';

import { templateBaseLength } from './constants';

export default yup.object().shape({
  __typename: yup.string().strip(true),
  _order: yup.number().min(templateBaseLength._order.min),
  templateName: yup
    .string()
    .trim()
    .min(templateBaseLength.templateName.min)
    .max(templateBaseLength.templateName.max)
    .required(),
  notes: yup.string().trim().max(templateBaseLength.notes.max),
  type: yup.string().trim().required(),
  channel: yup.object().shape({
    __typename: yup.string().strip(true),
    _id: yup.string(),
  }),
  card: yup
    .object()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup
        .string()
        .typeError('a card type is required.')
        .required('a card type is required.'),
    })
    .required('a card type is required.'),
  liveDate: yup.date().nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  unpublishDate: yup.date().nullable(),
  version: yup
    .number()
    .min(templateBaseLength.version.min)
    .max(templateBaseLength.version.max)
    .required(),
});
