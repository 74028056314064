import React, { useState } from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { getValidationMessages } from 'lane-shared/helpers';
import {
  BurstDraftInteractionData,
  ShopifyBurstInteractionType,
} from 'lane-shared/types/integrations/ShopifyBurst';
import { validateBurstName } from 'lane-shared/validation/shopifyBurst';

import { Input } from 'components/form';
import { Box } from 'components/layout';
import { H4, M } from 'components/typography';

import styles from './BurstEditName.scss';

type Props = {
  burstData: ShopifyBurstInteractionType;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  onClose: () => void;
};

export default function BurstEditName({
  onDataUpdated,
  burstData,
  onClose,
}: Props) {
  const [editedBurstName, setBurstNameEdited] = useState(burstData.name);
  const [isDirty, setIsDirty] = useState(false);
  const [validation, setValidation] = useState<ValidationError | null>(null);

  const { t } = useTranslation();

  async function setBurstName(name: string) {
    if (!isDirty) {
      setIsDirty(true);
    }

    setBurstNameEdited(name);

    try {
      await validateBurstName.validate({ name });
      setValidation(null);
      return true;
    } catch (err) {
      setValidation(err);
      return false;
    }
  }

  function handleSave() {
    onDataUpdated({ ...burstData, name: editedBurstName });
    onClose();
  }

  return (
    <Box>
      <Box p={4}>
        <H4 mb={2}>{t('Name your burst')}</H4>
        <M variant="secondary" mb={6}>
          {t(
            'Name your burst something your invitees will recognize. Try using a team name and/or date.'
          )}
        </M>
        <Box mb={6}>
          <Input
            label="Burst name"
            fieldName="name"
            value={editedBurstName}
            onChange={setBurstName}
            showSuccess
            showClear={false}
            success={!!isDirty && !validation}
            error={getValidationMessages(validation, 'name')}
          />
        </Box>
      </Box>
      <hr className={styles.line} />
      <Box p={4}>
        <Button
          disabled={!!validation}
          variant="contained"
          onClick={handleSave}
          fullWidth
        >
          {t('Save')}
        </Button>
      </Box>
    </Box>
  );
}
