export const HARDWARE_STATUSES = {
  INVALID: 'web.admin.hardware.management.hardware.status.invalid',
  ACTIVE: 'web.admin.hardware.management.hardware.status.active',
  DISABLED: 'web.admin.hardware.management.hardware.status.disabled',
};

export const HARDWARE_TYPES = {
  INVALID: 'web.admin.hardware.management.hardware.type.invalid',
  KIOSK: 'web.admin.hardware.management.hardware.type.kiosk',
};

export const ONLINE_THRESHOLD_IN_MINUTES = 10;

const sortOrders = ['asc', 'desc'];
export const [SORT_ASC, SORT_DESC] = sortOrders;

export enum HardwareStatus {
  INVALID = 'HARDWARE_STATUS_INVALID',
  ACTIVE = 'HARDWARE_STATUS_ACTIVE',
  DISABLED = 'HARDWARE_STATUS_DISABLED',
}

export enum HardwareType {
  INVALID = 'HARDWARE_TYPE_INVALID',
  KIOSK = 'HARDWARE_TYPE_KIOSK',
}

export const hardwareStatus = (status: HardwareStatus) => {
  let statusValue: string = HARDWARE_STATUSES.INVALID;

  switch (status) {
    case HardwareStatus.ACTIVE:
      statusValue = HARDWARE_STATUSES.ACTIVE;
      break;
    case HardwareStatus.DISABLED:
      statusValue = HARDWARE_STATUSES.DISABLED;
      break;
  }

  return statusValue;
};

export const hardwareType = (type: HardwareType) => {
  let typeValue: string = HARDWARE_TYPES.INVALID;

  switch (type) {
    case HardwareType.KIOSK:
      typeValue = HARDWARE_TYPES.KIOSK;
      break;
  }

  return typeValue;
};

export enum PinKioskType {
  'KioskTypeOption' = 'kiosk-type option',
  'Intercom' = 'Intercom',
  'PackageRoomEntry' = 'package room entry',
}

export const PinKioskTypeEnum = Object.values(PinKioskType).map(value => ({
  label: value,
  value,
}));
