import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import { TypeContextEnum } from '../../../../types/properties/TypeContextEnum';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    get default(): any {
      return uuid();
    },
  },
  allGroupRoles: {
    type: 'Boolean',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  groupRole: {
    type: 'GroupRole',
    isArray: true,
  },
  dateRange: {
    type: 'DateRange',
    friendlyName: 'Excluded Date Range',
    description: 'Excluded Date Range',
    isArray: false,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class UnavailableDateAvailability extends ObjectTypeBase {
  name = 'UnavailableDateAvailability';

  friendlyName = 'List of unavailable days';

  contexts = [TypeContextEnum.Properties];

  get example(): any {
    return {
      _id: uuid(),
      allGroupRoles: true,
      groupRole: null,
      dateRange: null,
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      allGroupRoles: true,
      groupRole: null,
      dateRange: null,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new UnavailableDateAvailability();

Types.addType(definition);
export default definition;
