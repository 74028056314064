import * as yup from 'yup';

import { BooleanTypeBase } from '../../types/baseTypes/BooleanTypeBase';
import { AllTypeContexts } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

export class BooleanType extends BooleanTypeBase {
  group = TYPE_GROUP_OTHER;

  get example() {
    return true;
  }

  get default(): any {
    return false;
  }

  contexts = AllTypeContexts;

  formatter = (value: any) => (value ? 'Yes' : 'No');

  name = 'Boolean';

  friendlyName = 'Yes / No';

  buildSchema() {
    return yup.boolean().nullable();
  }
}

const definition = new BooleanType();

Types.addBaseType(definition);

export default definition;
