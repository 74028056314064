import React from 'react';

import { Translation } from 'react-i18next';
import urlJoin from 'url-join';

import { colorToHex } from 'lane-shared/helpers/formatters';

import RichTextHtmlRenderer from '../components/RichTextRenderer';
import { routes, colors } from '../config';
import { CONTENT_TYPES } from '../helpers/constants/content';
import { getFriendlyDate } from '../helpers/content';
import {
  isSubtitleDate,
  isSubtitleCustom,
} from '../helpers/content/subtitleHelpers';
import { convertTo62 } from '../helpers/convertId';
import { imageUrl } from '../helpers/formatters';
import i18nStub from '../helpers/i18nStub';
import { WhiteLabelType } from '../types/WhiteLabelType';
import { ContentDataType } from '../types/server/WorkflowTypes';
import { MjmlSection, MjmlColumn, MjmlImage } from './MjmlComponentsV2';
import EmailButton from './components/EmailButton';
import EmailText from './components/EmailText';
import { ContentWorkflowPayloadType } from '../types/ContentWorkflowType';
import { ContentType } from '../types/content/Content';
import { DraftContentType } from '../types/content/DraftContent';

const thumbnailSize = 250;

export type EmailContentNotificationBodyProps = {
  whiteLabel: WhiteLabelType;
  content: ContentDataType | ContentType | DraftContentType;
  timeZone: string;
  // because this component runs server side, we need the i18n passed in
  // from the current running context.
  i18n: any;
  EditableAreaBodyComponent?: React.ReactNode;
  payload?: ContentWorkflowPayloadType;
};

export function EmailContentNotificationBody({
  whiteLabel,
  content,
  timeZone,
  i18n = i18nStub,
  EditableAreaBodyComponent,
  payload,
}: EmailContentNotificationBodyProps) {
  const contentLink = urlJoin(
    whiteLabel.baseUrl,
    routes.content.replace(':id', convertTo62(content._id))
  );

  const sectionBackgroundColor =
    content.type === CONTENT_TYPES.NOTICE
      ? content.backgroundColor
      : colors.gray4;

  const customBody = payload?.body;

  return (
    <MjmlSection
      paddingTop={24}
      paddingLeft={24}
      paddingRight={24}
      paddingBottom={24}
      borderRadius={10}
      backgroundColor={sectionBackgroundColor}
    >
      <MjmlColumn>
        {content.type !== CONTENT_TYPES.NOTICE && content.backgroundImageId && (
          <MjmlImage
            width={thumbnailSize}
            src={imageUrl(content.backgroundImageId)}
          />
        )}
        <EmailText
          backgroundColor={sectionBackgroundColor}
          lineHeight={36}
          fontSize={28}
          fontWeight="bold"
        >
          <h1 style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold' }}>
            {content.name}
          </h1>
        </EmailText>
        {isSubtitleDate(content.subtitle) && content.startDate && (
          <EmailText backgroundColor={sectionBackgroundColor}>
            <h4 style={{ textAlign: 'center', fontSize: 20 }}>
              <Translation i18n={i18n}>
                {t =>
                  getFriendlyDate({
                    content,
                    translator: t,
                    timeZone,
                    showTime: true,
                  })
                }
              </Translation>
            </h4>
          </EmailText>
        )}

        {isSubtitleCustom(content.subtitle) && (
          <EmailText backgroundColor={sectionBackgroundColor}>
            <h4 style={{ textAlign: 'center', fontSize: 20 }}>
              {content.subtitle}
            </h4>
          </EmailText>
        )}
        {EditableAreaBodyComponent && (
          <MjmlSection>
            <MjmlColumn>
              <EmailText>{EditableAreaBodyComponent}</EmailText>
            </MjmlColumn>
          </MjmlSection>
        )}
        {!EditableAreaBodyComponent && customBody && (
          <MjmlSection>
            <MjmlColumn>
              <EmailText>
                <RichTextHtmlRenderer value={customBody} />
              </EmailText>
            </MjmlColumn>
          </MjmlSection>
        )}
        {!EditableAreaBodyComponent && !customBody && (
          <EmailText
            backgroundColor={sectionBackgroundColor}
            paddingBottom={24}
            fontSize={16}
            lineHeight={24}
          >
            {content.description}
          </EmailText>
        )}
        <EmailButton
          width={150}
          href={contentLink}
          color={colorToHex(whiteLabel.profile.backgroundColor)}
        >
           <h3 style={{fontSize: 16, textAlign: 'center'}}>
            {i18n.t('View')}
          </h3>
        </EmailButton>
      </MjmlColumn>
    </MjmlSection>
  );
}
