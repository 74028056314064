import React from 'react';

import { Checkbox, MediaPickerButton } from 'components';
import { useTranslation } from 'react-i18next';

import {
  ActivateEmailTemplate,
  EmailTemplateType,
  VisitorConfiguration,
} from 'lane-shared/domains/visitorManagement/types';
import { getLibraryOptions } from 'lane-shared/helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { H4, H5, S, XS } from 'components/typography';

import { RichTextField } from '../../../..';
import { VisitorManagementEmailTemplatePreview } from './preview';

import styles from './styles.scss';

export function VisitorManagementEmailTemplateTab({
  channel,
  onConfigUpdated,
  config,
}: {
  channel: any;
  onConfigUpdated: (update: VisitorConfiguration) => void;
  config: VisitorConfiguration;
}): JSX.Element {
  const { t } = useTranslation();

  const emailTemplate = config.emailTemplate;

  function onFieldUpdate(update: Partial<ActivateEmailTemplate>) {
    onConfigUpdated({
      emailTemplate: {
        ...update,
        organizationId: convertToUUID(channel._id),
        templateType: EmailTemplateType.INVITE,
      },
    });
  }

  const entryInstructions = emailTemplate?.entryInstructions
    ? JSON.parse(emailTemplate.entryInstructions)
    : RichTextField.defaultValue;

  const policies = emailTemplate?.policies
    ? JSON.parse(emailTemplate.policies)
    : RichTextField.defaultValue;

  return (
    <div className={styles.emailTemplate} data-cy="emailTemplateTab">
      <div className={styles.column}>
        <div className={styles.section}>
          <H4 className={styles.title}>
            {t('web.admin.visitorManagement.configuration.emailTemplate.title')}
          </H4>
          <S>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.description'
            )}
          </S>
        </div>
        <div className={styles.section}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.title'
            )}
          </H5>
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.description'
            )}
          </XS>
          <MediaPickerButton
            libraries={getLibraryOptions({
              channel,
            })}
            media={{ _id: emailTemplate?.backgroundId ?? '' }}
            storageKey={`emailTemplateBackground_${channel?._id}`}
            onMediaSelected={media => {
              onFieldUpdate({ backgroundId: media?._id ?? '' });
            }}
            className={styles.backgroundHelpText}
            emptyText={t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.background'
            )}
            dataCy="emailTemplateBackgroundPicker"
          />
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.background.helpText'
            )}
          </XS>
        </div>
        <div className={styles.section}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.entryInstructions.title'
            )}
          </H5>
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.entryInstructions.description'
            )}
          </XS>
          <RichTextField
            value={entryInstructions}
            onChange={(value: any) => {
              if (
                value !== RichTextField.defaultValue &&
                value !== entryInstructions
              ) {
                onFieldUpdate({ entryInstructions: JSON.stringify(value) });
              }
            }}
            maxCharacters={500}
            dataCy="entryInstructionsRichText"
          />
        </div>
        <div className={styles.section}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.policies.title'
            )}
          </H5>
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.policies.description'
            )}
          </XS>
          <RichTextField
            value={policies}
            onChange={(value: any) => {
              if (value !== RichTextField.defaultValue && value !== policies) {
                onFieldUpdate({ policies: JSON.stringify(value) });
              }
            }}
            maxCharacters={500}
            dataCy="policiesRichText"
          />
        </div>
        <div className={styles.section}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.qrCode.title'
            )}
          </H5>
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.qrCode.description'
            )}
          </XS>
          <Checkbox
            text={t(
              'web.admin.visitorManagement.configuration.emailTemplate.qrCode.label'
            )}
            selected={emailTemplate?.qrEnabled ?? true}
            value={emailTemplate?.qrEnabled ?? true}
            onChange={() => {
              onFieldUpdate({ qrEnabled: !(emailTemplate?.qrEnabled ?? true) });
            }}
            dataCy="qrCodeCheckbox"
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.section}>
          <H4 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.preview'
            )}
          </H4>
          <VisitorManagementEmailTemplatePreview
            channel={channel}
            emailTemplate={emailTemplate}
          />
        </div>
      </div>
    </div>
  );
}
