import { WhiteLabelType } from '../../types/WhiteLabelType';
import {
  Auth0Parameter,
  Auth0Prompt,
  Auth0ScreenHint,
} from '../../types/auth0/Auth0ParameterType';
import colorToHex from '../formatters/colorToHex';
import getMediaUrl from '../formatters/mediaUrl';
import getTextColor from '../getTextColor';

export default function getAuth0Parameter(
  whitelabel: WhiteLabelType,
  email: string,
  isUserExists: boolean,
  invitation?: string,
  searchParamInviteId?: string,
  connection?: string,
  hideAuth0LoginAlertBox?: boolean
) {
  const logo = getMediaUrl(whitelabel.profile.logo);
  const backgroundImage = getMediaUrl(whitelabel.profile?.backgroundImage);
  const pageTitle = whitelabel.profile.name;
  const primaryColor = colorToHex(whitelabel.profile.backgroundColor);
  const primaryButtonColor = getTextColor(whitelabel.profile.backgroundColor);

  const authorizationParams: Auth0Parameter = {
    login_hint: email,
    screen_hint: Auth0ScreenHint.signup,
    organization: whitelabel.auth0OrganizationId || whitelabel.instance,
  };

  if (searchParamInviteId) {
    authorizationParams.aiid = searchParamInviteId;
  }

  if (pageTitle) {
    authorizationParams['ext-page-title'] = pageTitle;
  }

  if (primaryColor) {
    authorizationParams['ext-primary-color'] = primaryColor.replace('#', '');
  }

  if (primaryButtonColor) {
    authorizationParams[
      'ext-primary-button-color'
    ] = primaryButtonColor.replace('#', '');
  }

  if (logo) {
    authorizationParams['ext-background-logo'] = logo;
  }

  if (backgroundImage) {
    authorizationParams['ext-background-image'] = backgroundImage;
  }

  if (isUserExists) {
    authorizationParams.screen_hint = Auth0ScreenHint.login;
  } else if (invitation) {
    authorizationParams.invitation = invitation;
  }

  if (!connection && hideAuth0LoginAlertBox) {
    authorizationParams.prompt = Auth0Prompt.login;
  }

  if (connection && connection === 'email' && hideAuth0LoginAlertBox) {
    authorizationParams.connection = connection;
    authorizationParams.prompt = Auth0Prompt.login;
  }

  return authorizationParams;
}
