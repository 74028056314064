import React, { useState, useEffect } from 'react';

import { DAYS_OF_THE_WEEK } from 'lane-shared/helpers/constants/timeUnits';

import Dropdown from '../form/Dropdown';

export default function DayInput({ value, onChange }: any) {
  const [day, setDay] = useState(DAYS_OF_THE_WEEK.MONDAY.numericValue);

  useEffect(() => {
    setDay(value);
  }, [value]);

  return (
    <Dropdown
      value={day}
      onValueChange={value => {
        onChange(value);
      }}
      items={Object.keys(DAYS_OF_THE_WEEK).map(dayKey => ({
        label: DAYS_OF_THE_WEEK[dayKey].name,
        value: DAYS_OF_THE_WEEK[dayKey].numericValue,
      }))}
    />
  );
}
