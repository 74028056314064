export type Item = {
  label: string;
  value: string;
  provider: string;
  channelId: string;
  isDisabled: boolean;
  image?: string;
};

export enum DataStatus {
  unselected,
  selected,
  mixed,
}

export type Data = {
  label: string;
  value: string;
  provider: string;
  channelId: string;
  status: DataStatus;
  isDisabled: boolean;
  image?: string;
};

export type UserAccessGroupIds = {
  userId: string;
  accessGroupIds: string[];
};
