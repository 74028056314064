import { getClient } from 'lane-shared/apollo';
import addGroupRoleMutation from 'lane-shared/graphql/mutation/addGroupRole';

import { GroupDTO } from '../models/Group';

export type CreateGroupFailed = {
  key: 'createGroupFailed';
  err: Error | string;
};
export type CreatedGroupIdSuccess = {
  key: 'success';
  id: string;
};

export async function sendCreateGroupRoleMutation(
  model: GroupDTO
): Promise<CreatedGroupIdSuccess | CreateGroupFailed> {
  const client = getClient();

  try {
    const { data } = await client.mutate({
      mutation: addGroupRoleMutation,
      variables: { groupRole: model },
    });

    return {
      key: 'success',
      id: data.addGroupRole._id,
    };
  } catch (err) {
    return {
      key: 'createGroupFailed',
      err,
    };
  }
}
