import { useEffect } from 'react';

import { useChannelAdminContext } from 'hooks';

import { useLazyQuery } from '@apollo/client';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { ListProductsResponse, ProductType } from 'graphql-query-contracts';
import { listProductsQuery } from 'graphql-queries';

interface ListProductsParams {
  page: number;
  pageSize: number;
  productTypes?: ProductType[];
  productCategoryId?: String;
}

export function useProductsListQuery({
  page,
  pageSize,
  productTypes,
  productCategoryId = '',
}: ListProductsParams) {
  const { channel } = useChannelAdminContext();

  const [getProductsList, productsListResponse] = useLazyQuery<{
    listProducts: ListProductsResponse;
  }>(listProductsQuery);

  useEffect(() => {
    if (channel?._id) {
      const parentId = channel.parent?._id;
      const parentChannelIdArray =
        (parentId && [convertToUUID(parentId)]) || [];
      const groupIds = [convertToUUID(channel._id), ...parentChannelIdArray];

      getProductsList({
        variables: {
          filter: {
            groupIds,
            productTypes,
            productCategoryId,
          },
          pagination: {
            start: page * pageSize,
            perPage: pageSize,
          },
        },
      });
    }
  }, [
    channel?._id,
    channel?.parent?._id,
    page,
    pageSize,
    getProductsList,
    productCategoryId,
  ]);

  return productsListResponse;
}
