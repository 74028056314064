import { useContext, useMemo } from 'react';

import LaunchDarklyContext from '../contexts/LaunchDarklyContext';

type FlagType<TType> = TType extends boolean
  ? boolean
  : TType extends string
  ? string
  : TType extends number
  ? number
  : TType;

export default function useFlag<T = any>(
  flagName: string,
  defaultValue: T
): FlagType<T> | undefined {
  const { flags } = useContext(LaunchDarklyContext);

  return useMemo(() => {
    if (!flags) {
      return defaultValue;
    }

    return Object.keys(flags).includes(flagName)
      ? flags[flagName]
      : defaultValue;
  }, [flags?.[flagName]]);
}
