import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useModalPosition } from 'hooks';
import { DateTime } from 'luxon';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { SIMPLE_DATE, LONG_TIME } from 'lane-shared/helpers/constants/dates';
import {
  copyTimeToDate,
  parseDate,
  parseDateTime,
} from 'lane-shared/helpers/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import Button from '../../general/Button';
import ModalBackground from '../../general/ModalBackground';
import Calendar from './components/Calendar';

import styles from './DatePickers.scss';

DatePickerButton.defaultProps = {
  value: null,
  includeTime: false,
  existingValue: null,
  minDate: null,
  maxDate: null,
  onChange: () => null,
  onFocusChange: () => null,
  buttonClassName: '',
  timeZone: null,
};

type ButtonComponentArgs = {
  value: boolean | Date;
  onOpen(e: any): void;
};

type Props = {
  loading?: boolean;
  disabled?: boolean;
  value: Date | null | undefined;
  existingValue?: DateRangeType;
  timeZone: string;
  includeTime: boolean;
  onChange: (date: Date) => void;
  onFocusChange: (date: Date) => void;
  onSubmit?: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
  unavailableDateRanges?: DateRangeType[];
  ButtonComponent?: ({ value, onOpen }: ButtonComponentArgs) => React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  buttonClassName?: string;
  disabledWeekDays?: number[];
};

/*
  @deprecated use DatePickerButton in design-system-web instead.  
*/
export default function DatePickerButton({
  loading,
  disabled,
  value,
  existingValue,
  timeZone,
  includeTime,
  onChange,
  onFocusChange,
  onSubmit,
  minDate,
  maxDate,
  unavailableDateRanges = [],
  ButtonComponent,
  className,
  wrapperClassName,
  buttonClassName,
  disabledWeekDays,
}: Props) {
  const {
    buttonRef,
    modalRef,
    childrenRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } = useModalPosition();
  const { t, i18n } = useTranslation();
  const [dateUpdated, setDateUpdated] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);

  const displayFormat = includeTime
    ? `${SIMPLE_DATE} ${LONG_TIME}`
    : SIMPLE_DATE;

  const _value = parseDate(value);

  useEffect(() => {
    if (_value?.getTime?.() !== date?.getTime?.()) {
      setDate(_value);
      setDateUpdated(false);
    }
  }, [_value?.getTime?.()]);

  function onDateClick(inputDay: any) {
    let resolvedDate: Date;

    const day = parseDateTime(inputDay, timeZone) as DateTime;
    const selectedDate = parseDateTime(date, timeZone);

    if (includeTime && selectedDate) {
      // if there is a time widget, see if we have changed days.
      if (day.hasSame(selectedDate, 'day')) {
        // same day, time must have been set.
        resolvedDate = day.toJSDate();
      } else {
        // day changed, so copy time over
        resolvedDate = copyTimeToDate(selectedDate, day);
      }
    } else if (selectedDate) {
      // keep the time of day of the selected date
      resolvedDate = copyTimeToDate(selectedDate, day);
    } else {
      // no time to keep, use the date passed in
      resolvedDate = inputDay;
    }

    setDateUpdated(true);
    setDate(resolvedDate);

    if (onChange) {
      onChange(resolvedDate);
    }
  }

  function dateSelected(date: any) {
    if (onSubmit) {
      onSubmit(date);
    }

    onClose();
  }

  let buttonText = t('Select Date');

  if (_value) {
    buttonText = dateFormatter(_value, displayFormat, timeZone, i18n.language);
  }

  if (dateUpdated && date) {
    buttonText = dateFormatter(date, displayFormat, timeZone, i18n.language);
  }

  return (
    <span ref={buttonRef} className={cx(styles.DatePickerButtons, className)}>
      <span ref={childrenRef} className={wrapperClassName}>
        {ButtonComponent ? (
          ButtonComponent({ value: _value || dateUpdated, onOpen })
        ) : (
          <Button
            dataCy="calPicker"
            loading={loading}
            disabled={disabled}
            onClick={onOpen}
            className={buttonClassName}
            endIcon={<Icon name="calendar" />}
            variant="contained"
            data-cy="calPicker"
          >
            {buttonText}
          </Button>
        )}
      </span>
      <ModalBackground
        className={styles.background}
        onClose={() => {
          // user closed, put the original value back.
          setDate(_value);
          onClose();
        }}
        isOpen={isOpen}
      />
      {isOpen &&
        ReactDOM.createPortal(
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
          <div ref={modalRef} className={styles.container} style={position}>
            <Calendar
              className={styles.calendar}
              onChange={onDateClick}
              onFocusChange={onFocusChange}
              loading={loading}
              onSubmit={() => dateSelected(date)}
              minDate={minDate}
              maxDate={maxDate}
              startDate={dateUpdated ? date : _value}
              endDate={dateUpdated ? date : _value}
              includeTime={includeTime}
              existingValue={existingValue}
              unavailableDateRanges={unavailableDateRanges}
              timeZone={timeZone}
              disabledWeekDays={disabledWeekDays}
            />
          </div>,
          document.body
        )}
    </span>
  );
}
