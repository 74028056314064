import * as yup from 'yup';
import localization from 'localization';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

const i18n = localization.cloneInstance();

export const validateDataValidationRule = (data: PropertiesInterface) => {
  return yup.object().shape({
    fieldId: yup
      .string()
      .required(
        i18n.t(
          'web.admin.content.editor.dataValidationRule.workFlowRuleDataFieldErrorMessage'
        )
      )
      .test(
        'is-dropdown',
        i18n.t(
          'web.admin.content.editor.dataValidationRule.workFlowRuleDataFieldErrorMessage'
        ),
        function (value) {
          return (
            !!value && data[value]?.packagedType === PackagedTypeEnum.Dropdown
          );
        }
      ),
    validator: yup
      .object()
      .required(
        i18n.t(
          'web.admin.content.editor.dataValidationRule.workFlowRuleValueErrorMessage'
        )
      ),
  });
};
