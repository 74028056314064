import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Types from 'lane-shared/properties/Types';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { Input, Dropdown, Toggle } from 'lane-web/src/components';

import { Item } from 'components/form/Dropdown/Dropdown';

import { CustomQuestion } from 'graphql-query-contracts';

import styles from './index.scss';

/**
 * Allows an end user interacting with content to create a list of guests
 * to invite into this interaction.
 *
 */
export function CustomQuestionInput({
  customQuestion,
  key,
  onAnswer,
  className,
  value,
}: {
  customQuestion: PropertyType;
  key: number;
  onAnswer: (customQuestion: CustomQuestion) => void;
  className?: any;
  value?: any;
}) {
  const { t } = useTranslation();
  const baseTypes = Types.getBaseTypes();
  let dropDownOptions: Item<string>[] = [];
  if (customQuestion.type === baseTypes.Option!.name) {
    const validators = customQuestion?.validators?.find(
      validator => validator.name === 'In'
    );
    dropDownOptions = validators?.value?.map((validator: any) => {
      return { label: validator.name, value: validator.value };
    });
  }
  const [answer, setAnswer] = useState<string | boolean>(
    customQuestion.type === 'Boolean' ? value === t`Yes` : value
  );
  useEffect(() => {
    const isBoolean = typeof answer === 'boolean';
    if (isBoolean) {
      onAnswer({
        Question: customQuestion.friendlyName || '',
        Answer: answer ? t`Yes` : t`No`,
      });
    } else {
      onAnswer({
        Question: customQuestion.friendlyName || '',
        Answer: (answer as string) || '',
      });
    }
  }, [answer]);

  const renderInput = () => {
    switch (customQuestion.type) {
      case 'Boolean':
        return (
          <Toggle
            value={answer as boolean}
            onChange={() => setAnswer(!answer)}
          />
        );
      case 'Option':
        return (
          <Dropdown
            value={answer as string}
            items={dropDownOptions}
            onChange={item => setAnswer(item.value)}
          />
        );
      default:
        return <Input value={answer as string} onChange={setAnswer} />;
    }
  };

  return (
    <div
      data-cy="service-reqeust-custom-input"
      className={className || styles.fieldContainer}
      key={key}
    >
      <div className={styles.fieldLabel}>
        <span>{customQuestion.friendlyName}</span>
      </div>
      {renderInput()}
    </div>
  );
}
