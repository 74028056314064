import { getClient } from 'lane-shared/apollo';
import deleteGroupRoleMutation from 'lane-shared/graphql/mutation/deleteGroupRole';

export type DeleteGroupFailed = {
  key: 'deleteGroupFailed';
  err: Error | string;
};
export type DeleteGroupSuccess = {
  key: 'success';
};

export async function sendDeleteGroupRoleMutation(
  groupRoleId: string
): Promise<DeleteGroupSuccess | DeleteGroupFailed> {
  const client = getClient();

  try {
    await client.mutate({
      mutation: deleteGroupRoleMutation,
      variables: { id: groupRoleId },
    });

    return {
      key: 'success',
    };
  } catch (err) {
    return {
      key: 'deleteGroupFailed',
      err,
    };
  }
}
