import { request, Res } from './common';

export interface ListParams {
  work_order_id: string;
}

export type ListResData = WorkOrderComment[];

export interface WorkOrderComment {
  isPrivate: boolean;
  text: string;
}

export async function list(
  token: string,
  params: ListParams
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    path: `/work_orders/${params.work_order_id}/comments`,
  });
}

export async function create(
  token: string,
  params: { work_order_id: string },
  data: WorkOrderComment
): Promise<Res<null>> {
  return request({
    token,
    method: 'post',
    path: `/work_orders/${params.work_order_id}/comments`,
    data,
  });
}
