import React, { useState, useRef, useEffect } from 'react';

import cx from 'classnames';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'lane-shared/domains/visitorManagement/helpers';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { useInputCloseOutside } from 'design-system-web';

import {
  DISTANCE_FROM_PARENT,
  CALENDAR_HEIGHT,
  handleOpenDropdown,
} from './helpers/helpers';

import DateCalendar from './components/DateCalendar';
import useWindowDimensions from 'helpers/getWindowDimensions';

import styles from './DatePickerButton.scss';

export type Props = {
  className?: string;
  style?: React.CSSProperties;
  wrapperClassName?: string;
  buttonClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  value?: Date;
  timeZone?: string;
  // function to be called when element is clicked, returns selected day
  onChange: (date: Date) => void;
  // submit callback when user click on 'set date'
  onSubmit?: ((date: Date) => void) | null;
  // callback when user changes months
  onFocusChange?: (date: Date) => void;
  // JS date object for max date
  maxDate?: Date;
  // JS date object for min date
  minDate?: Date;
  // unavailable ranges
  unavailableDateRanges?: DateRangeType[];
  weekdayOnly?: boolean;
  hideLabel?: boolean;
  // a label for the submit button
  label?: string;
  placeholder?: string;
  // a label for the submit button
  submitLabel?: string;
  // Icon name
  icon?: string;
  // Icon will be placed on the right side
  iconRight?: boolean;
  fixedLabel?: boolean;
};

export default function DatePickerButton({
  className,
  style,
  wrapperClassName,
  buttonClassName,
  loading,
  disabled,
  value,
  timeZone,
  onChange,
  onSubmit,
  onFocusChange,
  maxDate,
  minDate,
  unavailableDateRanges,
  weekdayOnly,
  hideLabel,
  label,
  placeholder,
  submitLabel,
  icon = 'calendar',
  iconRight = false,
  fixedLabel,
}: Props) {
  const { t } = useTranslation();
  const { height: heightOfPage } = useWindowDimensions();

  const [selected, setSelected] = useState<Date | undefined>(value);
  const [submitted, setSubmitted] = useState<Date | undefined>(value);
  const [isOpen, setIsOpen] = useState({ opened: false, openedAbove: false });

  const pickerButtonRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setSubmitted(value);
  }, [value]);

  useEffect(() => {
    if (selected && !onSubmit) {
      setSubmitted(selected);
      onChange(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (isOpen.opened && isOpen.openedAbove) {
      setSelected(submitted);
    }
  }, [isOpen]);

  function handleSubmit() {
    if (onSubmit && selected) {
      setSubmitted(selected);
      onChange(selected);
      onSubmit(selected);
      setIsOpen({ opened: false, openedAbove: false });
    }
  }

  function handleSelect(date: Date | undefined) {
    if (date) {
      setSelected(date);
      if (!onSubmit) {
        setSubmitted(date);
        setIsOpen({ opened: false, openedAbove: false });
      }
    }
  }

  useInputCloseOutside(pickerButtonRef, setIsOpen, buttonRef);

  const labelText = !label
    ? t('web.content.feature.visitorManagement.form.selectADate')
    : label;

  return (
    <div className={cx(styles.datePickerButton, className)} style={style}>
      <span className={wrapperClassName} style={{ display: 'flex' }}>
        <button
          className={cx(styles.button, buttonClassName)}
          ref={buttonRef}
          onClick={e =>
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 5-6 arguments, but got 4.
            handleOpenDropdown(e, heightOfPage, CALENDAR_HEIGHT, setIsOpen)
          }
          data-cy="datePickerBtn"
          disabled={disabled}
        >
          <Input
            value={formatDate(submitted, t, true, true, timeZone)}
            placeholder={
              !placeholder
                ? t('web.content.feature.visitorManagement.form.selectADate')
                : placeholder
            }
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; icon: string; iconRight: t... Remove this comment to see the full error message
            isOpen={isOpen.opened}
            label={!hideLabel ? labelText : undefined}
            onChange={() => null}
            showClear={false}
            disabled={disabled}
            dataCy="datePickerInput"
            icon={icon}
            iconRight={iconRight}
            fixedLabel={fixedLabel}
          />
        </button>
      </span>
      {isOpen.opened && (
        <div
          className={styles.container}
          ref={pickerButtonRef}
          style={
            isOpen.openedAbove
              ? { bottom: DISTANCE_FROM_PARENT }
              : { top: DISTANCE_FROM_PARENT }
          }
          data-cy="datePickerContainer"
        >
          <DateCalendar
            loading={loading}
            disabled={disabled}
            selected={selected}
            onChange={handleSelect}
            onSubmit={onSubmit && handleSubmit}
            onFocusChange={onFocusChange}
            minDate={minDate}
            maxDate={maxDate}
            unavailableDateRanges={unavailableDateRanges}
            weekdayOnly={weekdayOnly}
            submitLabel={submitLabel}
            timeZone={timeZone}
            dataCy="datePicker"
          />
        </div>
      )}
    </div>
  );
}
