import React from 'react';

import { history } from 'helpers';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { routes } from 'lane-shared/config';

type Props = {
  contentId: string;
  channel: any;
};

export function NavigateToPage({ contentId, channel }: Props) {
  const { t } = useTranslation();

  async function navigateToPage() {
    try {
      await window.Alert.confirm({
        title: t(`Navigating away`),
        message: t(
          `Are you sure you want to leave this page? Your progress will not be saved.`
        ),
        confirmText: t('Confirm'),
      });
    } catch (err) {
      // user cancelled.

      if (err) {
        await window.Alert.alert({
          title: t('Error navigating to page'),
          err,
        });
      }

      return err;
    }

    history.push(
      generatePath(routes.channelAdminContent, {
        id: channel.slug,
        contentId,
      })
    );
  }

  return (
    <span role="navigation" onClick={async () => await navigateToPage()}>
      {t(`Navigate to page`)}
    </span>
  );
}
