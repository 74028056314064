import {
  getSubscriberCountPerClient,
  getActiveUsersByGroupRole,
  getSubscriptionsOverTimeForChannel,
} from '../../graphql/portal';

const queries = [
  {
    name: 'Subscriber Count Per Client',
    description: `Get the Unique number of Subscribers for all current major clients. Or, enter a
      Parent ID for a channel to get its sub-channels subscriber count.`,
    category: 'Analytics',
    tags: [],
    slug: 'subscriber-count-per-client',
    queryName: 'getSubscriberCountPerClient',
    queryResponseObjectName: 'subscriberCountPerClient',
    active: true,
    conditions: [
      {
        key: 'parentId',
        label: 'Channel Parent ID of Client',
        // Will be a channel dropdown in the future
        type: 'Text',
        placeholder: 'Enter Channel Long Parent ID',
        required: false,
      },
    ],
    query: getSubscriberCountPerClient,
  },
  {
    name: 'Number of Active Users by Group Role',
    description: `Get the count of the number of users by their group role (Subscriber, Admin, etc.) Provide a channelId for a specific channel, or a Group Role name for a specific role.
     Will accumulate from the date provided.
     ex. If the date is 3 months ago, users active between now and the past 3 months will be counted.`,
    category: 'Metrics',
    tags: [],
    slug: 'active-users-by-role',
    queryName: 'getActiveUsersByGroupRole',
    queryResponseObjectName: 'activeUsersByGroupRole',
    active: true,
    conditions: [
      {
        key: 'beforeDate',
        label: 'Date From',
        type: 'Date',
        placeholder: 'Start Date',
        required: true,
      },
      {
        key: 'channelId',
        label: 'Channel ID',
        type: 'Text',
        placeholder: 'Enter Channel ID',
        required: false,
      },
      {
        key: 'groupRole',
        label: 'Group Role Name',
        type: 'Text',
        placeholder: 'Enter the Group Role Name (ex. "Workplace Member")',
        required: false,
      },
    ],
    query: getActiveUsersByGroupRole,
  },
  {
    name: 'Number Channel Subscribers over Specific DateTime Range',
    description: `Get the count of users who subscribed to a channel over a distinct timeframe`,
    category: 'CS',
    tags: [],
    slug: 'subs-over-time-for-channel',
    queryName: 'getSubscriptionsOverTimeForChannel',
    queryResponseObjectName: 'subscriptionsOverTimeForChannel',
    active: true,
    conditions: [
      {
        key: 'channelId',
        label: 'Channel ID',
        type: 'Text',
        placeholder: 'Enter Channel ID',
        required: true,
      },
      {
        key: 'dateRange',
        label: 'Date Range',
        type: 'DateRange',
        placeholder: 'Date',
        required: true,
        misc: {
          includeTime: true,
        },
      },
    ],
    query: getSubscriptionsOverTimeForChannel,
  },
];

export default queries;
