import { explodeFeatures } from 'lane-shared/helpers/features';

export default function getDependentFeatureWarnings(features: any, t: any) {
  const { menuFeature, paymentFeature } = explodeFeatures(features);

  const warningMessages: string[] = [];

  if (menuFeature && !paymentFeature) {
    warningMessages.push(
      'Payment feature should be enabled with Menu feature.'
    );
  }
  if (paymentFeature?.subscriptionEnabled) {
    const totalCounts = paymentFeature.subscription?.totalCounts;
    const endDateOption = paymentFeature.subscription?.endDateOption;
    if (endDateOption === 'after' && totalCounts! < 1) {
      warningMessages.push(
        t('abp.publishContent.error.subscriptionTotalCountCannotBeLessThanZero')
      );
    }
  }

  return warningMessages;
}
