import React, { useContext } from 'react';

import { Button } from 'components';

import { UserDataContext } from 'lane-shared/contexts';

import { H5, S } from 'components/typography';

import useAuthModal from 'hooks/useAuthModal';

import styles from './RequirementsList.scss';
import { useTranslation } from 'react-i18next';

export type RequirementRowProps = {
  loading?: boolean;
  header: string | undefined;
  description: string | undefined;
  buttonLabel?: string;
  children?: React.ReactNode;
  action?: React.ReactNode;
  emailVerificationCheck?: (() => void | undefined) | null;
};

export default function RequirementRow({
  loading,
  header,
  description,
  buttonLabel,
  children,
  onClick,
  action,
  emailVerificationCheck
}: RequirementRowProps & { onClick?: () => void }) {
  const { isLoggedIn } = useContext(UserDataContext);
  const { showAuthModal } = useAuthModal({});
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (!isLoggedIn) {
      showAuthModal();
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <div className={styles.requirement}>
      <div className={styles.iconContainer}>
        <div className={styles.text}>
          <H5 mb={2}>{header}</H5>
          <S className={styles.description} variant="secondary">
            {description}
            {emailVerificationCheck && (
              <span>
                <br />
                <br />
                {t('web.admin.channel.content.layout.editor.components.verifiedEmail.status.alreadyVerified') },{' '}
                <span
                  className={styles.action}
                  onClick={emailVerificationCheck}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      emailVerificationCheck();
                    }
                  }}
                >
                  {t('web.admin.channel.content.layout.editor.components.verifiedEmail.action.clickHere')}
                </span>
              </span>
            )}
          </S>
        </div>
      </div>
      {!isLoggedIn || !action ? (
        <Button
          loading={loading}
          variant="outlined"
          color="tertiary"
          size="medium"
          onClick={handleButtonClick}
          dataCy={`Add${header && header.split(' ').join('')}`}
        >
          {buttonLabel}
        </Button>
      ) : (
        action
      )}
      {children}
    </div>
  );
}
