import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesRequiredIf } from './ITypeValidatesRequiredIf';

export class RequiredIfValidator
  implements ValidatorInterface<ITypeValidatesRequiredIf> {
  name = 'RequiredIf';

  friendlyName = 'Required Field When';

  arrayOnly = false;

  array = false;

  description = 'shared.properties.validators.requiredIf.description';

  types = null;

  properties = {
    value: {
      type: 'Key',
      default: '',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesRequiredIf) {
    type.addRequiredIfValidator(this);
  }

  constructor(public config?: Validator) {}
}

const RequiredIf = new RequiredIfValidator(undefined);

ValidatorRegistry.register(RequiredIfValidator, RequiredIf.name);

export default RequiredIf;
