import React from 'react';
import { SelectedSubscriptionPaymentMethod } from './SelectedSubscriptionPaymentMethod';
import { Button, M } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { PaymentSource } from 'graphql-query-contracts';

interface Props {
  paymentSource: PaymentSource;
  onSubmit: () => void;
  openAutopayModal: () => void;
}

export function AutopayEnabledDetails({
  paymentSource,
  onSubmit,
  openAutopayModal,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-3">
      <M>
        {t(
          'abp.payment.subscription.paymentMethod.autopayEnabled.headerMessage'
        )}
      </M>
      <div className="flex flex-col gap-y-2">
        <M>
          {t(
            'abp.payment.subscription.paymentMethod.autopayEnabled.paymentMethodTitle'
          )}
        </M>
        <SelectedSubscriptionPaymentMethod
          paymentSource={paymentSource}
          onChangeAutopayMethodClick={() => openAutopayModal()}
        />
      </div>
      <Button onClick={onSubmit} size="large">
        {t('abp.payment.subscription.paymentMethod.autopayEnabled.purchase')}
      </Button>
    </div>
  );
}
