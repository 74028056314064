import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { InteractionDataType } from 'lane-shared/types/server/WorkflowTypes';

import exportBurstDataToCSV from 'lane-web/src/components/integrations/ShopifyBurst/helpers/exportBurstDataToCSV';
import { Action } from 'lane-web/src/components/lds/table/types';

type UCITableAction = Omit<Action, 'onClick'> & {
  onClick: (interaction: InteractionDataType) => void;
};

/**
 * Certain interactions from integration providers can have additional actions in the admin table.
 * You can set them here.
 *
 */
export default function getActionsForUserContentInteractions(
  integrationProvider: IntegrationProviderEnum | null
): UCITableAction[] {
  if (!integrationProvider) {
    return [];
  }

  const mapIntegrationProviderToActions: {
    [key in IntegrationProviderEnum]?: UCITableAction[];
  } = {
    [IntegrationProviderEnum.ShopifyBurst]: [
      {
        label: 'Export to CSV',
        iconName: 'file-export',
        onClick: interaction =>
          exportBurstDataToCSV(
            interaction.data._ShopifyBurst,
            (interaction as any).user
          ),
      },
    ],
  };

  return mapIntegrationProviderToActions[integrationProvider] || [];
}
