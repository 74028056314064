import React from 'react';

import ButtonStrip from '../general/ButtonStrip';

const TextAlignmentValues = [
  {
    value: 'left',
    icon: 'align-left',
  },
  {
    value: 'right',
    icon: 'align-right',
  },
  {
    value: 'center',
    icon: 'align-center',
  },
  {
    value: 'justify',
    icon: 'align-justify',
  },
];

export default function TextAlignmentInput({ onChange, value }: any) {
  return (
    <ButtonStrip
      buttons={TextAlignmentValues}
      onClick={onChange}
      selected={value}
    />
  );
}
