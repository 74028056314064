import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentType } from 'lane-shared/types/content/Content';

import { Button, IconButton } from '../../../general';

import styles from './UserIntegration.scss';

type Props = {
  content: ContentType;
  onLogout: () => void;
};

export default function UserIntegration({ content, onLogout }: Props) {
  const { settings } = content.integration;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  async function logout() {
    try {
      await window.Alert.confirm({
        title: t('Logout of {{name}}?', { name: settings.name }),
        message: t(
          'You are about to logout of {{name}} and un-link your accounts. Are you sure you want to do this?',
          { name: settings.name }
        ),
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: string | undefined; mes... Remove this comment to see the full error message
        translate: t,
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);

    onLogout();

    setLoading(false);
  }

  return (
    <div className={styles.UserIntegration}>
      <Button
        startIcon={
          <IconButton
            style={{ fontSize: 12 }}
            iconSet="FontAwesome"
            icon="sign-out-alt"
          />
        }
        disabled={loading}
        onClick={logout}
        className={styles.button}
      >
        {t('Sign out of {{name}}', { name: settings.name })}
      </Button>
    </div>
  );
}
