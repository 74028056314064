import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { S } from 'components/typography';

import useUserLocale from 'hooks/useUserLocale';

import styles from './TabStrip.scss';

export type TabItem = {
  value: string;
  label: string;
  icon?: string;
  alert?: number;
};

type Props = {
  disabled?: boolean;
  tabs: TabItem[];
  selected: Pick<TabItem, 'value'> | null | undefined;
  onSelectTab: (tab: TabItem) => void;
  className?: string;
  style?: React.CSSProperties;
  color?: string;
  interfaceStyle?: 'light' | 'dark';
  fullWidth?: boolean;
  skipLabelTranslation?: boolean;
};

export default function TabStrip({
  disabled,
  tabs,
  selected,
  onSelectTab,
  className,
  style,
  color,
  interfaceStyle = 'light',
  fullWidth = false,
  skipLabelTranslation = false,
}: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const alertFormat = (alert: number) =>
    alert < 10000 ? new Intl.NumberFormat(locale, {}).format(alert) : t('10k+');

  return (
    <menu className={cx(styles.TabStrip, className)} style={style}>
      {tabs?.map(tab => (
        <button
          key={tab.value}
          data-cy={`tab${
            tab.value.charAt(0).toUpperCase() + tab.value.slice(1)
          }`}
          disabled={disabled}
          data-interface-style={interfaceStyle}
          data-is-selected={selected?.value === tab.value}
          onClick={() => onSelectTab(tab)}
          style={
            selected?.value === tab.value
              ? { color, borderBottom: `2px solid ${color}` }
              : {}
          }
        >
          {tab.icon && <Icon name={tab.icon} />}
          <S>{!skipLabelTranslation ? t(tab.label) : tab.label}</S>
          {tab.alert && (
            <S className={styles.alert}>{alertFormat(tab.alert)}</S>
          )}
        </button>
      ))}
      {fullWidth && <div />}
    </menu>
  );
}
