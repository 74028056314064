import gql from 'graphql-tag';

export const getHardwareAccessLogs = gql`
  query HardwareAccessLogs(
    $channelId: UUID!
    $pagination: PaginationInput!
    $search: HardwareAccessLogsSearch
  ) {
    hardwareAccessLogs(
      channelId: $channelId
      pagination: $pagination
      search: $search
    ) {
      items {
        id
        accessSuccess
        accessedAt
        accessStatusDetail
        accessedByName
        accessHostId
        hardware {
          id
          name
          type
        }
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`;
