import { useMemo, useContext } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import ChannelsContext from '../contexts/ChannelsContext';
import UserDataContext from '../contexts/UserDataContext';
import { StringSearchTypeEnum } from '../types/graphql/search';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
} from '../types/integrations/IntegrationEnums';
import { ChannelProviderType } from './types';

type FocusOnChannels = {
  _id: string;
  integrations: ChannelProviderType[] | null;
};

const queryMyChannelsIntegrations = gql`
  query getMyChannelIntegrations($channelId: UUID!, $search: ChannelSearch) {
    me {
      switchChannel(channelId: $channelId, search: $search) {
        primaryId
        secondaryId
        focusOnChannels {
          _id
          integrations {
            _id
            settings
            channel {
              _id
              name
              profile {
                _id
                name
                logo
              }
            }
            integration {
              _id
              name
              type
              category
            }
          }
        }
      }
    }
  }
`;

/**
 * Returns all access control integrations from all integrations on the
 * currently focused channels. `channelIntegrations` has all the properties
 * but may contain duplicated integration names.  Use `accessControlProviders`
 * for a unique set of the integration names.
 */
export default function useMyChannelsAccessControlIntegrationsQuery() {
  const { user } = useContext(UserDataContext);
  const { primaryId } = useContext(ChannelsContext);

  const { data, loading, error } = useQuery<{
    me: {
      switchChannel: {
        focusOnChannels: FocusOnChannels[];
      };
    };
  }>(queryMyChannelsIntegrations, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    skip: !user || !primaryId,
    variables: {
      channelId: primaryId,
      search: {
        integrations: {
          integration: {
            category: {
              type: StringSearchTypeEnum.Equal,
              value: IntegrationCategoryEnum.AccessControl,
            },
          },
        },
      },
    },
  });

  // get a filtered list of all AccessControl channelIntegrations that
  // are active on the channels the user is focused on
  const channelIntegrations = useMemo<ChannelProviderType[]>(() => {
    if (data?.me?.switchChannel?.focusOnChannels) {
      return data?.me?.switchChannel?.focusOnChannels
        .filter(channel => !!channel.integrations && channel._id === primaryId)
        .map(channel =>
          channel.integrations!.map(integrationProvider =>
            integrationProvider.integration.name ===
            IntegrationProviderEnum.AccessManagement
              ? {
                  ...integrationProvider,
                  isLegacy: false,
                  integration: {
                    ...integrationProvider.integration,
                    name: integrationProvider?.settings?.identityProviderService
                      ?.name as IntegrationProviderEnum.AccessManagement,
                  },
                }
              : { ...integrationProvider, isLegacy: true }
          )
        )
        .flat();
    }

    return [];
  }, [data?.me?.switchChannel?.focusOnChannels]);

  // get a unique set of all access control providers live at these channels.
  const accessControlProviders = useMemo<IntegrationProviderEnum[]>(() => {
    return [
      ...new Set(
        channelIntegrations.map(
          ({ integration }) => integration?.name as IntegrationProviderEnum
        )
      ),
    ].sort();
  }, [channelIntegrations]);

  return {
    channelIntegrations,
    accessControlProviders,
    loading,
    error,
  };
}
