import { useState } from 'react';

import { PaymentProviderEnum } from '../types/payment/PaymentProviderEnum';

export default function usePlacePayModal({
  onClose,
  onPaymentFailed,
  onPaymentSuccess,
}: {
  onClose: () => void;
  onPaymentFailed: () => void;
  onPaymentSuccess: () => void;
}) {
  const [placePayModalOpen, setPlacePayModalOpen] = useState<boolean>(false);

  async function handlePaymentStart(type: PaymentProviderEnum) {
    if (type === PaymentProviderEnum.PlacePay) {
      setPlacePayModalOpen(true);
    }
  }

  function handlePaymentSuccess(_type: PaymentProviderEnum) {
    setPlacePayModalOpen(false);
  }

  function handlePaymentFailed() {
    setPlacePayModalOpen(false);
    if (onPaymentFailed) {
      onPaymentFailed();
    }
  }

  function handleCreateInteractionSuccess() {
    if (onPaymentSuccess) {
      onPaymentSuccess();
    }
    onClose();
  }

  return {
    placePayModalOpen,
    setPlacePayModalOpen,
    handlePaymentStart,
    handlePaymentSuccess,
    handlePaymentFailed,
    handleCreateInteractionSuccess,
  } as const;
}
