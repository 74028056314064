import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import useShopifyRsvpAttendees from 'lane-shared/hooks/integrations/shopify/useShopifyRsvpAttendees';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';
import { ContentType } from 'lane-shared/types/content/Content';
import {
  ShopifyRsvpTabsEnum,
  ShopifyRsvpStatusEnum,
} from 'lane-shared/types/integrations/ShopifyRsvp';

import Button from 'components/general/Button';
import StatusPill from 'components/general/StatusPill';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import CircleListView from 'components/lane/CircleListView';
import { Flex } from 'components/layout';
import { Modal } from 'components/lds';
import { H5, XS } from 'components/typography';

import styles from './BurstAttendeesModal.scss';

type Props = {
  onClose: () => void;
  attendees: PublicUserType[];
  rsvpContent?: ContentType | undefined | null;
};

type Attendee = {
  user: PublicUserType;
  status?: ShopifyRsvpStatusEnum | string | null | undefined;
};

export function BurstAttendeeRow({ user, status }: Attendee) {
  return (
    <CircleListView
      key={user._id}
      className={styles.circleListView}
      image={imageUrl(user.profile?.image)}
      logo={imageUrl(user.profile?.logo)}
      name={getDisplayName(user)}
    >
      <Flex direction="row" align="center" justify="space-between" gap={4}>
        <Flex direction="column">
          <H5 bold>{getDisplayName(user)}</H5>
          <XS variant="secondary">{user.profile?.email}</XS>
        </Flex>
        {status && (
          <StatusPill text={status.toString()} status={status.toString()} />
        )}
      </Flex>
    </CircleListView>
  );
}

export default function BurstAttendeesModal({
  onClose,
  attendees,
  rsvpContent,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<ShopifyRsvpTabsEnum>(
    ShopifyRsvpTabsEnum.Invited
  );
  const { t } = useTranslation();

  const {
    attendeesWithStatus,
    declinedAttendees,
    acceptedAttendees,
  } = useShopifyRsvpAttendees({
    rsvpContent,
    attendees,
  });

  const tabs: TabItem[] = [
    {
      value: ShopifyRsvpTabsEnum.Invited,
      label: t(`Invited ({{amount}})`, { amount: attendees.length }),
    },

    {
      value: ShopifyRsvpTabsEnum.Declined,
      label: t(`No ({{amount}})`, { amount: declinedAttendees?.length }),
    },

    {
      value: ShopifyRsvpTabsEnum.Accepted,
      label: t(`Yes ({{amount}})`, { amount: acceptedAttendees?.length }),
    },
  ];

  return (
    <Modal
      title="Attendees"
      isOpen
      onClose={onClose}
      actions={
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      }
    >
      {rsvpContent && (
        <TabStrip
          tabs={tabs}
          selected={{ value: selectedTab }}
          onSelectTab={tab => setSelectedTab(tab.value as ShopifyRsvpTabsEnum)}
          className={styles.tabStrip}
        />
      )}
      <Flex direction="column" className={styles.userList}>
        <Flex
          p={[1, 2]}
          direction="column"
          gap={4}
          className={styles.shopifolkList}
        >
          {selectedTab === ShopifyRsvpTabsEnum.Invited &&
            attendeesWithStatus.map(attendee => (
              <BurstAttendeeRow
                key={attendee.details._id}
                user={attendee.details}
                status={
                  rsvpContent &&
                  (attendee.status === ShopifyRsvpStatusEnum.Awaiting
                    ? 'Waiting'
                    : attendee.status)
                }
              />
            ))}
          {selectedTab === ShopifyRsvpTabsEnum.Declined &&
            declinedAttendees.map(attendee => (
              <BurstAttendeeRow
                key={attendee.details._id}
                user={attendee.details}
                status={rsvpContent && attendee.status}
              />
            ))}
          {selectedTab === ShopifyRsvpTabsEnum.Accepted &&
            acceptedAttendees.map(attendee => (
              <BurstAttendeeRow
                key={attendee.details._id}
                user={attendee.details}
                status={rsvpContent && attendee?.status}
              />
            ))}
        </Flex>
      </Flex>
    </Modal>
  );
}
