import { LaneType } from 'common-types';

export type TimeRangeType = {
  _id?: LaneType.UUID;
  startTime: string;
  endTime: string;
};

export const DEFAULT_START_TIME = '9:00 AM';
export const DEFAULT_END_TIME = '5:00 PM';
export const START_OF_DAY = '12:00 AM';
export const END_OF_DAY = '11:59 PM';
