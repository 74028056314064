import React, { useContext } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { UserDataContext } from 'lane-shared/contexts';
import ShopifyBurstContextProvider from 'lane-shared/hooks/integrations/shopify/ShopifyBurstContextProvider';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import { ErrorBoundary } from 'components/errors';

import Homepage from './pages/Homepage';

export default function ShopifyBurstIndex({ content }: any) {
  const { path } = useRouteMatch();
  const { user, sessionId } = useContext(UserDataContext);

  return (
    <ErrorBoundary
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message
      user={user}
      sessionId={sessionId}
      integration={IntegrationProviderEnum.ShopifyBurst}
    >
      <Switch>
        <Route exact path={path}>
          <ShopifyBurstContextProvider contentId={content?._id}>
            <Homepage />
          </ShopifyBurstContextProvider>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}
