import gql from 'graphql-tag';

export default gql`
  query getWorkOrderInfo($contentId: UUID!) {
    workOrderTypes(contentId: $contentId) {
      _id
      name
      canCancel
    }

    workOrderRequesterLocations(contentId: $contentId) {
      _id
      name
      floors {
        _id
        name
        suites {
          _id
          name
        }
      }
    }
  }
`;
