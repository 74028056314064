import gql from 'graphql-tag';

import { getClient } from '../../apollo';
import {
  PublicUserFragment,
  ThemeFragment,
  FullContentTemplateFragment,
} from '../../graphql/fragments';

const templateQuery = gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullContentTemplateFragment}

  query fetchTemplate($id: UUID!) {
    contentTemplate(_id: $id) {
      ...FullContentTemplateFragment
    }
  }
`;

export default async function fetchTemplate(id: string): Promise<any> {
  // get the full content piece.
  const response = await getClient().query({
    query: templateQuery,
    variables: {
      id,
    },
  });

  return response.data.contentTemplate;
}
