import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { AppContext, UserDataContext } from 'lane-shared/contexts';

import TransitionPage from 'lane-web/src/pages/user/Signup/TransitionPage';

import Loading from './Loading';

export default function LoadingStates() {
  const { isLoading: isLoadingAuth0Authentication } = useAuth0();
  const { isLoggingIn, isInitialized } = useContext(UserDataContext);
  const { hasWhiteLabelLoaded } = useContext(AppContext);

  if (
    !isInitialized ||
    isLoggingIn ||
    !hasWhiteLabelLoaded ||
    isLoadingAuth0Authentication
  ) {
    return <Loading fullscreen />;
  }

  return <TransitionPage />;
}
