import * as yup from 'yup';

import { SecurityRuleTypeEnum } from '../types/properties/PropertySecurity';

export default yup
  .array()
  .of(
    yup
      .object()
      .shape({
        type: yup
          .string()
          .oneOf(Object.values(SecurityRuleTypeEnum))
          .required(),
        value: yup.mixed().nullable(),
      })
      .required()
  )
  .nullable();
