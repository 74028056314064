import React, { ReactNode } from 'react';

import { Box, Flex } from 'components';

import useVisibleClearedFromDependenciesProperties from 'lane-shared/hooks/properties/useVisibleClearedFromDependenciesProperties';
import { ContentType } from 'lane-shared/types/content/Content';

import PropertyValue from 'components/builder/properties/display/PropertyValue';
import ReviewData from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit/ReviewData';
import { H6, M } from 'components/typography';

import styles from './ReviewInteractionData.scss';

type ReviewDataInteractionProps = {
  interaction: any;
  content: ContentType;
  title: string;
  icon?: ReactNode;
  onClick?: () => void;
};

export default function ReviewInteractionData({
  interaction,
  content,
  title,
  icon,
  onClick,
}: ReviewDataInteractionProps) {
  const properties = useVisibleClearedFromDependenciesProperties({
    data: interaction?.data,
    content,
  });

  return (
    <ReviewData
      title={title}
      titleIcon={icon}
      onClick={onClick}
      isEditable
      summary={
        <Flex gap={2} direction="column">
          {properties.map(([key, property]) => (
            <Box key={key} className={styles.question}>
              <H6>{property.friendlyName || property.name || key}</H6>
              <M variant="secondary">
                <PropertyValue
                  value={interaction?.data?.[key]}
                  field={property}
                />
              </M>
            </Box>
          ))}
        </Flex>
      }
    />
  );
}
