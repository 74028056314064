import { AngusWorkOrderDataType } from '../../../types/integrations/AngusTypes';
import { IntegrationProviderEnum } from '../../../types/integrations/IntegrationEnums';

export default function getAngusWorkOrderFromInteractionData(
  interactionData: any
): AngusWorkOrderDataType | null {
  if (!interactionData) {
    return null;
  }

  return interactionData[
    `_${IntegrationProviderEnum.AngusServiceRequests}`
  ] as AngusWorkOrderDataType;
}
