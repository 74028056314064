import React from 'react';
import {
  AddProductToContentDetails,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { PaymentV2NoProducts } from './PaymentV2NoProducts';

import { useChannelAdminContext } from 'hooks';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import { AddedProductsTable } from './AddedProductsTable';

export { Subscription } from './Subscription';

export function PaymentV2({
  listOfProducts,
  handleProductAddition,
  handleProductRemoval,
  inclusivePricing,
}: {
  listOfProducts: AddProductToContentDetails[];
  handleProductAddition: (product: AddProductToContentDetails) => void;
  handleProductRemoval: (product: AddProductToContentDetails) => void;
  inclusivePricing: boolean;
}) {
  const channelContext = useChannelAdminContext();

  const { currency } = useBillingPaymentSettingsForChannel({
    channelId: channelContext.channel?._id,
  });

  return (
    <div>
      {listOfProducts.length === 0 ? (
        <PaymentV2NoProducts
          handleProductAddition={handleProductAddition}
          currency={currencyToPaymentCurrency(currency)}
          inclusivePricing={inclusivePricing}
        />
      ) : (
        <AddedProductsTable
          handleProductRemoval={handleProductRemoval}
          products={listOfProducts}
          currency={currencyToPaymentCurrency(currency)}
          inclusivePricing={inclusivePricing}
        />
      )}
    </div>
  );
}
