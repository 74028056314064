import React from 'react';

import { Thumbnail } from 'components';

type Props = {
  list: string[];
  limit?: number;
  containerStyles?: React.CSSProperties;
  containerClassname?: string;
};

export function ThumbnailRow({
  list,
  limit,
  containerStyles,
  containerClassname,
}: Props) {
  const listSize = list.length;
  const slicedList = limit ? list.slice(0, limit) : list;
  const remaining = listSize - slicedList.length;

  const appliedContainerStyles = containerStyles ?? {};
  const appliedContainerClassname = containerClassname ?? '';

  return (
    <div
      style={{ display: 'flex', gap: 8, ...appliedContainerStyles }}
      className={appliedContainerClassname}
    >
      {slicedList.map((item, index) => (
        <Thumbnail key={index} name={item} />
      ))}
      {remaining > 0 && <Thumbnail name={`+${remaining}`} text />}
    </div>
  );
}
