import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';

import Types from '../../../../properties/Types';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { PropertiesInterface } from '../../../../types/properties/Property';

export enum ShopifyBurstDinnerTypeEnum {
  AtThePort = 'At The Port',
  Offsite = 'Offsite',
}

const properties: PropertiesInterface = {
  burstDinnerType: {
    type: 'String',
    friendlyName: 'Burst Dinner Type',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: Object.values(ShopifyBurstDinnerTypeEnum),
      },
    ],
  },
  date: {
    type: 'DateTime',
    friendlyName: 'Purpose',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class ShopifyBurstDinnerType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ShopifyBurstDinnerType';

  friendlyName = 'Shopify Burst Dinner';

  superUserOnly = false;

  contexts = [];

  get example(): any {
    return {
      burstDinnerType: ShopifyBurstDinnerTypeEnum.Offsite,
      date: new Date(),
    };
  }

  get default(): any {
    return {
      burstDinnerType: ShopifyBurstDinnerTypeEnum.AtThePort,
      date: new Date(),
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new ShopifyBurstDinnerType();

Types.addType(definition);
export default definition;
