import { CreditCardType } from '../Stripe';

const REQ_FIELD = ['id', 'brand', 'exp_month', 'exp_year', 'last4'];

export default function isStripeCreditCard<T>(
  paymentMethod: T | CreditCardType
): paymentMethod is CreditCardType {
  return (
    !!paymentMethod &&
    REQ_FIELD.every(
      field => (paymentMethod as { [key: string]: any })[field] !== undefined
    )
  );
}
