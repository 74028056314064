export enum FeatureFlag {
  EmailVerificationRequirement = 'emailVerificationRequirement',
  NonInteractiveRequirement = 'nonInteractiveRequirement',

  MaintenanceMode = 'maintenance-mode',

  StagingPermittedDomains = 'activate.stagingPermittedDomains',

  UserAccessPermission = 'squad-mobile.user-has-access-permission',
  MobilePulseLibrary = 'activate-mobile.mobile-pulse',
  MobileAccessHIDV3 = 'mobile-access.hidv3',
  MobileAccessAppleWallet = 'squad-mobile-access.apple-wallet',
  ContentLoadingOverlay = 'squad-mobile-platform.content-loading-overlay',

  PrismMobileHotReleasee = 'pmo-integrations-prism-mobile-hot-release-2023-06',
  PrismWorkOrder = 'pmo-integrations-prism-work-orders',
  PrismVisitorManagement = 'pmo-integrations-prism-visitor-management',

  ProductsAndServices = 'squad-billing-and-payments.products-and-services',
  PaymentFeatureV2 = 'squad-billing-and-payments.payment-feature-v2',
  TransactionHistoryV2 = 'squad-billing-and-payments.transaction-history-v2',
  ChargesInvoice = 'squad-billing-and-payments.charges-invoice-v1',
  PaymentSettings = 'squad-billing-and-payments.payment-settings',
  ChargeWithProducts = 'squad-billing-and-payments.charge-with-products',
  YardiChargeCode = 'squad-billing-and-payments.yardi-charge-code-sync',
  Autopayment = 'squad-billing-and-payments.invoice-autopayment',
  BillingPaymentsEmailNotifiactions = 'squad-billing-and-payments.emails',
  Credits = 'squad-billing-and-payments.credits',
  CreditBundlesInCharges = 'squad-billing-and-payments.credit-bundles-in-charge',
  ProductsInContent = 'squad-billing-and-payments.products-in-content',
  SubscriptionsInContent = 'squad-billing-and-payments.subscriptions-in-content',
  Exceptions = 'squad-billing-and-payments.exceptions',
  InvoiceDisabling = 'squad-billing-and-payments.invoice-disabling',
  RemovePaymentAccounts = 'squad-billing-and-payments.remove-payment-accounts',
  ProductsWithCredits = 'squad-billing-and-payments.products-with-credit-price',
  ChargeBillingReports = 'squad-billing-and-payments.billing-reports',
  EditProducts = 'squad-billing-and-payments.edit-products',
  ApplePay = 'squad-billing-and-payments.apple-pay',
  PriceOnCard = 'squad-billing-and-payments.price-on-card',
  AutopaymentInContent = 'squad-billing-and-payments.autopayment-in-content',
  PaymentRefund = 'squad-billing-and-payments.payment-refund',

  ReservableManagement = 'consumer-experience.reservable-manager',
  ReservableManagementCalendar = 'flex.reservable-manager-calendar',
  ReservableManagementCalendarV2 = 'flex.reservable-manager-calendar-v2',
  QuantityAdvancedRule = 'flex.quantity-advanced-rule',
  VisitorManagement = 'space-invaders.visitor-management',
  VisitorManagementHostSelection = 'space-invaders.host-selection',
  VisitorManagementAllDayPass = 'space-invaders.all-day-pass',
  VisitorManagementDateRange = 'space-invaders.date-range',
  VisitorManagementCustomDates = 'space-invaders.custom-dates',
  VisitorManagementRepeat = 'space-invaders.repeat',
  VisitorManagementModuleSettings = 'space-invaders.visitor-management-organization-settings',
  VisitorManagementGeneralSettings = 'space-invaders.visitor-configuration-general-settings',
  VisitorManagementSchedulingSettings = 'space-invaders.visitor-configuration-scheduling-settings',
  VisitorManagementEmailTemplateLogoSetting = 'space-invaders.visitor-email-template-logo-setting',
  VisitorManagementVisitorNotes = 'space-invaders.visitor-notes',
  NewUserContentInteractionReceipt = 'space-invaders.new-uci-receipt',
  VisitorManagementEditRecurrence = 'space-invaders.edit-recurrence',

  VisitorManagementHostUpdateEmail = 'space-invaders.email-host-update',
  VisitorManagementVisitorUpdateEmail = 'space-invaders.email-visitor-update',
  VisitorManagementHostSubmisssionEmail = 'space-invaders.email-host-submission',
  VisitorManagementHostNotification = 'space-invaders.host-notification',
  VisitorManagementSecurityStaffView = 'space-invaders.security-staff-visit-creation-view',
  VisitorManagementSmsNotification = 'space-invaders.visitor-sms-notification',
  VisitorManagementMultipleNotificationRecipients = 'space-invaders.multiple-notification-recipients-enabled',

  VisitorInviteBulkUpload = 'space-invaders.visitor-invite-bulk-upload',
  /**
   * @deprecated
   */
  VisitorManagementInviteEmail = 'space-invaders.invite-email',
  /**
   * @deprecated
   */
  VisitorManagementCheckInEmail = 'space-invaders.check-in-email',
  /**
   * @deprecated
   */
  VisitorManagementCancelledEmail = 'space-invaders.cancel-email',

  HardwarePinManagement = 'space-invaders.hardware-pin-codes',

  RiseMigrationAutoSetup = 'platform-application-services.rise-migration-option-for-auto-setup',
  Surveys = 'platform-application-services.surveys',
  SendUserIdAsLookerAttribute = 'platform-application-services.send_user_id_as_looker_attribute',
  WithholdChannelListFromLooker = 'platform-application-services.withhold_channel_list_from_looker',
  SourceLookerFromVTS = 'platform-application-services.source_looker_from_vts',
  SourceLookerFromVTSGroup2 = 'platform-application-services.source_looker_from_vts_group_2',
  ChangeParent = 'platform-application-services.change-parent',
  SyncJoinRules = 'platform-application-services.sync-join-rules',
  LookerTimezoneParams = 'platform-application-services.looker_timezone_params',
  LaneLookerTimezoneParams = 'platform-application-services.lane_looker_timezone_params',
  CESAutoSetup = 'platform-application-services.ces-option-for-auto-setup',
  RiseMigrationExportTenants = 'platform-application-services.rise-migration-migrate-tenants',
  RiseMigrationAddRandomSuffix = 'platform-application-services.rise-migration-add-random-suffix',
  EventAnalyticsLookerReport = 'platform-application-services.event-analytics-looker-report',
  TeamsBulkAddUsers = 'platform-application-services.teams-bulk-add-users',

  IMSUserEndPoints = 'astro-cats.ims-user-endpoints',
  DynamicLanguagesMultiLanguageToggle = 'astro-cats.dynamic-languages.channel-multi-language-toggle',
  DynamicLanguagesMultiLanguageForMembersToggle = 'astro-cats.dynamic-languages.channel-multi-language-for-members-toggle',
  MultiLanguageSupportNewWorkflowsUI = 'tenant-engagement.multi-language-support.new-workflows-ui',
  MultiLanguageSupportUserLanguageSettings = 'tenant-engagement.multi-language-support.new-user-language-settings-UI',
  MultiLanguageSupportUpdatedChannelSettings = 'tenant-engagement.multi-language-support.new-channel-settings-UI',
  MultiLanguageSupportSearch = 'tenant-engagement.mls.search',
  MultiLanguageSupportLanguagePreviewSelector = 'tenant-engagement.multi-language-support.language-preview-selector',

  PrivacyPolicyAndTermsEnabled = 'privacyPolicyAndTermsEnabled',
  EnableComplianceContact = 'enable-compliance-contact',

  PropertyOperations = 'space-cadets.property-operations',
  WorkOrders = 'space-cadets.work-orders',
  WorkOrdersWorkplaceEnablement = 'work-order.workplace-enablement',
  WorkOrdersPreventiveMaintenance = 'space-cadets.work-orders.preventive-maintenance',
  WorkOrdersEquipment = 'space-cadets.work-orders.equipment',
  WorkOrdersNotificationEmail = 'work-orders.notification.email',
  WorkOrdersEscalations = 'work-orders-escalations',
  WorkOrdersServiceRequestInvoicing = 'work-orders.service-request-invoicing',
  WorkOrderMobileEquipment = 'work-orders.admin-mobile-equipment',
  DataImporter = 'work-orders.data-importer',
  ImporterConverter = 'work-orders.importer-converter',
  InteractiveStepsCreate = 'work-orders.interactive-steps-create',
  InteractiveStepsDetails = 'work-orders.interactive-steps-details',
  InteractiveStepsExecute = 'work-orders.interactive-steps-execute',
  WorkOrdersConfigurableNotifications = 'work-order-configurable-notifications',
  WorkOrdersOnlyDisplayKnownFilters = 'work-order.only-display-allowed-statuses-on-filter',

  OfficeManagerText = 'squad-integrations-management.hid-office-manager-text',
  RestrictChannelIntegrationsAccess = 'squad-integrations-gateway.restrict-channel-integrations-access',
  AccessControlUserAccess = 'squad-integrations-management.access-control-user-access',
  AccessControlAccessCredentials = 'squad-integrations-management.access-control-access-credentials',
  AccessControlCredentialGroups = 'squad-integrations-management.v5-credential-groups',
  DeleteChannelIntegrationAccess = 'squad-integrations-gateway.delete-integration',
  RemoveBuildingUser = 'squad-integrations-management.remove-building-user',
  InviteAddUserSeparation = 'squad-integrations-management.invite-add-user-separation',
  AccessLaneRefactor = 'squad-integrations-management.access-lane-refactor',

  AuthenticationAuth0 = 'flex.authentication.auth0',

  CSVUploadsAutoSetup = 'activate-insights-migrations.csv-uploads-auto-setup',
  ControlByWebConfigSetting = 'squad-integrations-gateway.controlbyweb-config-setting',
  BrivoConfigSetting = 'squad-integrations-gateway.brivo-config-setting',
  YardiConfigSetting = 'squad-integrations-gateway.yardi-config-setting',
  YardiCommercialConfigSetting = 'squad-integrations-gateway.yardi-commercial-config-setting',
  EntrataConfigSetting = 'squad-integrations-gateway.entrata-config-setting',
  RealPageConfigSetting = 'squad-integrations-gateway.realpage-config-setting',

  SectionContentRework = 'tenant-engagement.section-rework',
  ContentTags = 'tenant-engagement.content-tags',
  ContentTagFilters = 'tenant-engagement.content-tags.member-filters',
  SectionsChannelHierarchyAccess = 'tenant-engagement.sections.channel-hierarchy-access',

  GeneaLocationUdf = 'squad-integrations-gateway.genea-location-udf-setting',
  GeneaVisitorManagement = 'squad-integrations-gateway.genea-visitor-management',
  TenantConfigurationEnhancements = 'squad-access-management.tenantConfigurationEnhancements',
  KastleVisitorManagement = 'squad-integrations-gateway.kastle-visitor-management',
  GenetecVisitorManagement = 'squad-integrations-gateway.genetec-visitor-management',
  GeneaCardFormat = 'squad-integrations-gateway.enable-genea-card-formats-for-config',
  GenetecCardholderGroupsFilter = 'squad-integrations-gateway.genetec-cardholder-groups-filter-enabled',

  UnitsFloors = 'multifamily.unit-floors',
  MultifamilyCreateProperty = 'multifamily.create-property',
  IntuitiveUserManagement = 'multifamily.intuitive-user-management',
  AddManualResidents = 'multifamily.add-manual-residents',

  ShowForcedUpdate = 'squad-mobile-force-update',
  MultifamilyBackendCESCalls = 'multifamily.ces-backend-calls',

  PrivateS3Media = 'tenant-engagement.private-s3-media',
  SectionUI = 'tenant-engagement.section-ui',
  LimitFontSizeScalingOnReactNative = 'tenant-engagement.accessibility.limit-font-size-scaling',
  SectionUIMemberUpdate = 'tenant-engagement.section-ui-member-update',

  ShowSha1AppVersion = 'squad-mobile.show-sha1-app-version',
  BlocksWithDeepClone = 'tenant-engagement.blocks-with-deepClone',

  EnableSaltoSvn = 'squad-integrations-gateway.salto-svn-v2.5',

  MessagingAndMarketplace = 'multifamily.messaging-and-marketplace',
  MediaUploadV2 = 'multifamily.media-upload-v2',
  RelayUI = 'squad-relay-and-beacon.relay-UI',
  PinningSectionContent = 'tenant-engagement.pinning-section-content',
  RecurringEvents = 'tenant-engagement.recurring-events',
  UrgentCommunicationMessage = 'multifamily.urgent-communications-message',
  DraftContentState = 'tenant-engagement.draft-content-state',
  ContentInteractionTableUpdates = 'tenant-engagement.content-interaction-data-table',
  UnderConstructionPageMobile = 'tenant-engagement.onboarding.mobile.under-construction',
  UnderConstructionPageWeb = 'tenant-engagement.onboarding.web.under-construction',

  EnableKastleAppleWallet = 'squad-integrations-gateway.enable-kastle-apple-wallet',
  EnableKastleEmailLogs = 'squad-integrations-gateway.enable-kastle-email-logs',
}
