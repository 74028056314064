import { Primitive } from '../../../../types/blocks/Primitive';

/**
 * @deprecated use PropertyInputBlock instead
 */
const DateRangeInputBlock: Primitive = {
  name: 'DateRangeInputBlock',
  friendlyName: 'Date Range',
  isInput: true,
  isPrimitive: true,
  properties: {
    unavailableDateRanges: {
      type: 'DateRange',
      isArray: true,
      default: [],
    },
    value: {
      type: 'DateRange',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    isValid: {
      type: 'Boolean',
      default: true,
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
    minRangeSize: {
      type: 'Number',
    },
    maxRangeSize: {
      type: 'Number',
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    submit: {
      type: 'Event',
    },
  },
};

export default DateRangeInputBlock;
