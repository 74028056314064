import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { AllTypeContexts } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

export class PasswordType extends StringTypeBase {
  contexts = AllTypeContexts;

  group = TYPE_GROUP_TEXT;

  name = 'Password';

  friendlyName = 'Password';

  get example() {
    return 'Secret_password1!';
  }

  get default(): any {
    return '';
  }

  buildSchema() {
    return yup.string().nullable();
  }
}

const definition = new PasswordType();

Types.addBaseType(definition);
export default definition;
