import React from 'react';

import cx from 'classnames';

import { imageUrl } from 'lane-shared/helpers/formatters';

import styles from './ImageTypeValue.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  value: {
    _id: string;
    name: string;
  };
};

export default function ImageTypeValue({ className, style, value }: Props) {
  return (
    <div
      className={cx(styles.ImageTypeValue, className)}
      style={{ ...style, backgroundImage: `url(${imageUrl(value)})` }}
    />
  );
}
