import { CookieSetOptions } from 'universal-cookie';

export default async function setSharedCookiesForWebView(
  token: string,
  jti: string,
  setCookie: (
    name: 'jti' | 'token',
    value: any,
    options?: CookieSetOptions | undefined
  ) => void
) {
  if (!token || !jti) {
    throw Error('Login response invalid.');
  }
  setCookie('token', token, { path: '/' });
  setCookie('jti', jti, { path: '/' });
}
