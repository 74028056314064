import React, { useState, useEffect, useContext } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Input, ControlMenu, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { getValidationMessages } from 'lane-shared/helpers';
import { validateSignup } from 'lane-shared/validation';

import LoginSSOButtons from 'components/authV2/LoginSSOButtons';
import Button from 'components/general/Button';
import VariantIconButton from 'components/general/variant/VariantIconButton';
import { M, H4, S } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import EmailOptInLegacy from '../EmailOptInLegacy';
import { SignUpOAuthInterstitial } from '../SignUpOAuthInterstitial';
import WhitelabelTermsAndPolicy from '../WhitelabelTermsAndPolicy';

import styles from '../Signup.scss';

type Props = {
  onNext: (options?: { isSSOUsed: boolean }) => void;
  navigateToLogin?: () => void;
};

/**
 *
 * @deprecated Do not add new features to this component as it is being replaced
 * https://viewthespace.atlassian.net/jira/software/c/projects/TM/boards/878?modal=detail&selectedIssue=TM-7061
 */
export default function SignupDetailsLegacy({
  onNext,
  navigateToLogin,
}: Props) {
  const [validation, setValidation] = useState(null);
  const [continueWithEmail, setContinueWithEmail] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const { whitelabel } = useContext(AppContext);
  const {
    name,
    email,
    password,
    updateSignUp,
    inviteError,
    inviteId,
    emailOptIn,
    oAuth,
  } = useSignUpContext();

  const isOAuthSignUp = Boolean(oAuth);

  const hasOAuthAvailable = Boolean(
    whitelabel?.oAuthConfig?.some(({ enabled }) => enabled)
  );

  function handleError(err: Error | null) {
    setError(err);
  }

  useEffect(() => {
    setError(inviteError);
  }, [inviteError]);

  useEffect(() => {
    if (!hasOAuthAvailable) {
      setContinueWithEmail(true);
    }
  }, [hasOAuthAvailable]);

  async function validate() {
    try {
      // After updating the state, see if its valid.
      await validateSignup.validate({ name, email, password });
      setValidation(null);
    } catch (validation) {
      setValidation(validation);
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    onNext();
  }

  function handleOAuthSubmit(options: {
    isSSOUsed: boolean;
    name: string;
    emailOptIn: boolean;
  }) {
    const { isSSOUsed, emailOptIn, name } = options;

    updateSignUp({ emailOptIn, name });
    onNext({ isSSOUsed });
  }

  useEffect(() => {
    validate();
  }, [email, name, password]);

  if (isOAuthSignUp) {
    return (
      <SignUpOAuthInterstitial
        name={name}
        onNext={handleOAuthSubmit}
        whitelabel={whitelabel}
      />
    );
  }

  return (
    <div className={cx(welcomeFormStyles.formContents, styles.formContents)}>
        <M className={welcomeFormStyles.hasAnAccount}>
          {t('Already have an account? ')}
          {navigateToLogin ? (
            <a
              className={welcomeFormStyles.link}
              role="link"
              tabIndex={0}
              onClick={navigateToLogin}
            >
              {t('Log in')}
            </a>
          ) : (
            <Link className={welcomeFormStyles.link} to={routes.login}>
              {t('Log in')}
            </Link>
          )}
        </M>
        {!continueWithEmail ? (
          <>
            <div className={welcomeFormStyles.infoContainer}>
              <H4 mb={4} className={welcomeFormStyles.formTitle} v2>
                {t('Sign up')}
              </H4>
              <S mb={4}>{t('Start improving your workplace experience.')}</S>
            </div>
            <ErrorMessage
              className={welcomeFormStyles.errorMessage}
              error={error}
            />
            <LoginSSOButtons
              mode="SignUp"
              onSuccess={() => onNext({ isSSOUsed: true })}
              onError={handleError}
              oAuthConfig={whitelabel.oAuthConfig}
            />
            <VariantIconButton
              label={t('Continue with email')}
              onClick={() => {
                updateSignUp({ inviteError: null });
                setContinueWithEmail(true);
              }}
              image={<Icon name="envelope" set="FontAwesome" type="far" />}
            />
          </>
        ) : (
          <>
            {hasOAuthAvailable ? (
              <Button
                variant="outlined"
                color="inherit"
                className={styles.backButtonSignUp}
                startIcon={
                  <Icon
                    name="chevron-left"
                    type="far"
                    set="FontAwesome"
                    className={styles.ArrowButton}
                  />
                }
                style={{
                  color: 'inherit',
                  boxShadow: 'inherit',
                }}
                onClick={() => {
                  setContinueWithEmail(
                    prevContinueWithEmail => !prevContinueWithEmail
                  );
                }}
              >
                <S>{t('Back to sign up')}</S>
              </Button>
            ) : null}
            <form onSubmit={handleSubmit}>
              <div>
                <H4 mb={4} className={welcomeFormStyles.formTitle} v2>
                  {t('Create an account')}
                </H4>
                <S mb={6}>
                  {t(
                    'Enter your name, company email, and choose a secure password.'
                  )}
                </S>
              </div>
              <div className={welcomeFormStyles.inputContainer}>
                <Input
                  className={welcomeFormStyles.inputMargin}
                  type="text"
                  label={t('Full Name')}
                  onChange={name =>
                    updateSignUp({
                      name,
                    })
                  }
                  value={name}
                  error={getValidationMessages(validation, 'name')}
                />

                <Input
                  className={welcomeFormStyles.inputMargin}
                  disabled={Boolean(inviteId)}
                  onChange={email =>
                    updateSignUp({
                      email,
                    })
                  }
                  value={email}
                  type="email"
                  label={t('Enter your email')}
                  error={getValidationMessages(validation, 'email')}
                />

                <Input
                  onChange={password =>
                    updateSignUp({
                      password,
                    })
                  }
                  type="password"
                  value={password}
                  label={t('Choose a password')}
                  className={welcomeFormStyles.inputMargin}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | string[] | null' is not assignable t... Remove this comment to see the full error message
                  error={
                    Boolean(password) &&
                    getValidationMessages(validation, 'password')
                  }
                />
                <WhitelabelTermsAndPolicy whitelabel={whitelabel} />
                <ControlMenu className={welcomeFormStyles.menu}>
                  <Button
                    className={cx(
                      welcomeFormStyles.actionButton,
                      styles.agreeAndContinue
                    )}
                    disabled={Boolean(validation)}
                    dataCy="signUpButton"
                    variant="contained"
                    color="inherit"
                    type="submit"
                  >
                    {t('Agree and continue')}
                  </Button>
                </ControlMenu>
              </div>
              <EmailOptInLegacy
                emailOptIn={emailOptIn}
                onChange={() => {
                  updateSignUp({ emailOptIn: !emailOptIn });
                }}
              />
            </form>
          </>
        )}
      </div>
  );
}
