import * as yup from 'yup';

import { frequencies } from '../../renderers/v5/features/Reset';
import validateFeature from '../validateFeature';
import validateProperty from '../validateProperty';

export default yup.object().shape({
  resetPeriod: yup.string().required().oneOf(frequencies),
  lastReset: yup.date().nullable(),
  nextReset: yup.date().nullable(),
  renderer: yup.number().min(0).max(256).required(),
  block: yup.object().nullable(),
  properties: yup
    .object()
    .nullable()
    .test(
      'valid-properties',
      '${path} has invalid property defined.',
      value => {
        if (value) {
          Object.values(value).forEach(property =>
            validateProperty.validateSync(property)
          );
        }

        return true;
      }
    ),
  features: yup
    .array()
    .nullable()
    .when('version', {
      is: 5,
      then: yup.array().of(validateFeature),
      otherwise: yup.array(),
    }),
  // todo: data validation.
  data: yup.object().nullable(),
  // todo: state validation
  state: yup.object().nullable(),
  // todo: settings validation
  settings: yup.object().nullable(),
});
