export default function secureUserSearch(userSearch: any) {
  const securedQuery = {
    ...userSearch,
  };

  delete securedQuery.analytics;
  delete securedQuery.eventSubscriptions;
  delete securedQuery.deviceTokens;
  delete securedQuery.sessions;
  delete securedQuery.stripe;
  delete securedQuery.roles;
  delete securedQuery.logins;
  delete securedQuery.settings;
  delete securedQuery.addresses;

  return securedQuery;
}
