import React from 'react';

import cx from 'classnames';

import { Flex } from 'components/layout';

import styles from './Well.scss';

export const Well = ({
  className = '',
  style = {},
  children,
}: {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => (
  <Flex
    style={style}
    direction="column"
    className={cx(styles.container, className)}
  >
    {children}
  </Flex>
);
