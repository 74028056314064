import React, { useEffect, useState } from 'react';

import { AdminPage, Button, ErrorMessage, Loading } from 'components';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { translateHardwareThermalState } from 'lane-shared/domains/hardwareManagement/types/HardwareThermalState';
import {
  SIMPLE_DATE,
  LONG_DATE_TIME,
} from 'lane-shared/helpers/constants/dates';
import { PERMISSION_ADMIN } from 'lane-shared/helpers/constants/permissions';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { BreadCrumbs } from 'components/lds';
import { H3, H5 } from 'components/typography';

import {
  HardwareActivityStatusSpan,
  HardwareUpdate,
} from '../../../../../components';
import { HardwareRegenerateAPIkey } from '../../../../../components/HardwareRegenerateAPIkey/HardwareRegenerateAPIkey';
import { hardwareType } from '../hardware';

import styles from './styles.scss';

const hardwareQuery = gql`
  query Hardware($id: ID!) {
    hardware(id: $id) {
      id
      name
      createdAt
      type
      apiKey
      status
      location
      notes
      deviceInfo {
        lastActivityAt
        appVersion
        batteryLevel
        model
        osVersion
        thermalState
        updatedAt
      }
    }
  }
`;

export function HardwareDetailPage({
  channel,
  hasAnyPermission,
}: {
  channel: ChannelType;
  hasAnyPermission: (hasAnyPermission: string[]) => Boolean;
}) {
  const { hardwareId } = useParams<{ hardwareId: string }>();
  const { t } = useTranslation();
  const [showUpdateComponent, setShowUpdateComponent] = useState(false);
  const location = useLocation();
  const isAdminOrSuperUser = hasAnyPermission([PERMISSION_ADMIN]);
  const [regenerateAPIkeyError, setRegenerateAPIkeyError] = useState<
    string | null
  >(null);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'goToEdit' does not exist on type '{}' Remove this comment to see the full error message
  const { goToEdit } = location.state || {};

  const handleEditClick = () => {
    setShowUpdateComponent(true);
  };

  useEffect(() => {
    if (goToEdit) {
      handleEditClick();
    }
  }, [goToEdit]);

  const { data, error, loading } = useQuery(hardwareQuery, {
    client: getClient(),
    variables: {
      id: hardwareId,
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading || !data?.hardware) {
    return <Loading fullcover fullscreen />;
  }
  const hardwareData = data?.hardware;

  return (
    <AdminPage className={styles.HardwareDetail}>
      <BreadCrumbs
        links={[
          {
            label: t(
              'web.admin.hardware.management.hardware.profile.breadcrumb.hardwareList'
            ),
            url: `${routes.channelAdminHardwareDevices.replace(
              ':id',
              channel?.slug
            )}?tab=kiosk`,
          },
          { label: `${hardwareData.name}` },
        ]}
      />
      <H3 className={styles.H3}>{hardwareData.name}</H3>

      <div className={styles.header}>
        <H5 className={styles.H5}>
          {t(
            'web.admin.hardware.management.hardware.profile.device.info.title'
          )}
        </H5>
        <Button onClick={handleEditClick} dataCy="editButton">
          {t('web.admin.hardware.management.hardware.profile.edit')}
        </Button>
      </div>

      <div className={styles.section}>
        {showUpdateComponent && (
          <HardwareUpdate
            hardware={hardwareData}
            showUpdateComponent={setShowUpdateComponent}
          />
        )}
        {!showUpdateComponent && (
          <div className={styles.item}>
            <div className={styles.itemName}>
              {t('web.admin.hardware.management.hardware.profile.name:')}
            </div>
            <div className={styles.itemValue}>{hardwareData.name}</div>
          </div>
        )}
        <div
          className={`${styles.item} ${
            showUpdateComponent ? styles.paddingTopZero : ''
          }`}
        >
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.hardware.profile.type:')}
          </div>
          <div className={styles.itemValue}>
            {t(hardwareType(hardwareData.type))}
          </div>
        </div>
        {!showUpdateComponent && (
          <div className={styles.item}>
            <div className={styles.itemName}>
              {t('web.admin.hardware.management.hardware.profile.location:')}
            </div>
            <div className={styles.itemValue}>{hardwareData.location}</div>
          </div>
        )}
        {!showUpdateComponent && (
          <div className={styles.item}>
            <div className={styles.itemName}>
              {t('web.admin.hardware.management.hardware.profile.notes:')}
            </div>
            <div className={styles.itemValue}>{hardwareData.notes}</div>
          </div>
        )}
      </div>

      <H5 className={styles.H5}>
        {t('web.admin.hardware.management.hardware.profile.activities.title')}
      </H5>

      <div className={styles.section}>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.hardware.profile.status:')}
          </div>
          <div className={styles.itemValue}>
            <HardwareActivityStatusSpan
              lastActivityAt={hardwareData.deviceInfo?.lastActivityAt}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.hardware.profile.last.seen:')}
          </div>
          <div className={styles.itemValue}>
            {hardwareData.deviceInfo?.lastActivityAt
              ? dateFormatter(
                  hardwareData.deviceInfo.lastActivityAt,
                  LONG_DATE_TIME
                )
              : '--'}
          </div>
        </div>
      </div>

      <H5 className={styles.H5}>
        {t(
          'web.admin.hardware.management.hardware.profile.device.identification.title'
        )}
      </H5>
      {regenerateAPIkeyError && (
        <ErrorMessage
          className={styles.errorMessage}
          error={regenerateAPIkeyError}
        />
      )}
      <div className={styles.section}>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.hardware.profile.date.added:')}
          </div>
          <div className={styles.itemValue}>
            {dateFormatter(hardwareData.createdAt, SIMPLE_DATE)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.hardware.profile.device.id:')}
          </div>
          <div className={styles.itemValue}>{hardwareData.id}</div>
        </div>
        {isAdminOrSuperUser && (
          <div className={styles.item}>
            <div className={styles.itemName}>
              {t('web.admin.hardware.management.hardware.profile.api.key:')}
            </div>
            <div className={styles.itemValue}>{hardwareData.apiKey}</div>
            <HardwareRegenerateAPIkey
              hardwareId={hardwareId}
              hardwareName={hardwareData.name}
              setRegenerateAPIkeyError={setRegenerateAPIkeyError}
            />
          </div>
        )}

        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.hardware.profile.os.version:')}
          </div>
          <div className={styles.itemValue}>
            {hardwareData?.deviceInfo?.osVersion || '--'}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.hardware.profile.tablet.model:')}
          </div>
          <div className={styles.itemValue}>
            {hardwareData?.deviceInfo?.model || '--'}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.itemName}>
            {t(
              'web.admin.hardware.management.hardware.profile.device.appVersion'
            )}
          </div>
          <div className={styles.itemValue}>
            {hardwareData?.deviceInfo?.appVersion || '--'}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.itemName}>
            {t(
              'web.admin.hardware.management.hardware.profile.device.batteryLevel'
            )}
          </div>
          <div className={styles.itemValue}>
            {hardwareData?.deviceInfo?.batteryLevel
              ? `${hardwareData.deviceInfo.batteryLevel}%`
              : '--'}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.itemName}>
            {t(
              'web.admin.hardware.management.hardware.profile.device.thermalState'
            )}
          </div>
          <div className={styles.itemValue}>
            {hardwareData?.deviceInfo?.thermalState
              ? t(
                  translateHardwareThermalState[
                    hardwareData.deviceInfo
                      .thermalState as keyof typeof translateHardwareThermalState
                  ]
                )
              : '--'}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.itemName}>
            {t(
              'web.admin.hardware.management.hardware.profile.device.lastUpdated'
            )}
          </div>
          <div className={styles.itemValue}>
            {hardwareData?.deviceInfo?.updatedAt
              ? dateFormatter(hardwareData.deviceInfo.updatedAt, LONG_DATE_TIME)
              : '--'}
          </div>
        </div>
      </div>
    </AdminPage>
  );
}
