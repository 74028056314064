import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuantityAdvancedRulesEnabled } from 'lane-shared/hooks/useQuantityAdvancedRulesEnabled';
import { Toggle } from 'components/form';
import { QuantityFeatureProperties } from 'lane-shared/types/features/QuantityFeatureProperties';
import { FeatureRendererPropsType } from './FeatureRendererPropsType';
import QuantityAllocationsType from 'lane-shared/renderers/v5/features/types/QuantityAllocations';
import { QuantityAdvancedRule } from './QuantityAdvancedRule';

type QuantityAdvancedRulesProps = Pick<
  FeatureRendererPropsType<QuantityFeatureProperties>,
  'onFeatureUpdated' | 'contentFeature' | 'feature'
>;

export function QuantityAdvancedRules({
  contentFeature,
  feature,
  onFeatureUpdated,
}: QuantityAdvancedRulesProps) {
  const isQuantityAdvancedRulesEnabled = useQuantityAdvancedRulesEnabled();
  const { t } = useTranslation();

  const handleOnToggleChange = (value: boolean) => {
    if (value === true) {
      onFeatureUpdated({
        quantityAllocations: [QuantityAllocationsType.default],
      });
    } else {
      onFeatureUpdated({
        quantityAllocations: [],
      });
    }
  };

  if (!isQuantityAdvancedRulesEnabled) {
    return null;
  }

  return (
    <>
      <Toggle
        className="mt-2"
        text={t(feature.properties.quantityAllocations.description)}
        onChange={handleOnToggleChange}
        value={!!contentFeature.feature.quantityAllocations?.length}
      />
      {(contentFeature.feature.quantityAllocations || []).map(
        (allocation, index) => {
          return (
            <div
              key={index}
              className="rounded border border-border-subdued mt-4 mb-2 p-2.5"
            >
              <QuantityAdvancedRule
                contentFeature={contentFeature}
                onFeatureUpdated={onFeatureUpdated}
                quantityAllocation={allocation}
              />
            </div>
          );
        }
      )}
    </>
  );
}
