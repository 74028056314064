import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  userId: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  contentId: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  interaction: {
    type: 'JSON',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class DraftUserContentInteractionType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'DraftUserContentInteraction';

  friendlyName = 'Interaction';

  contexts = [TypeContextEnum.Data, TypeContextEnum.Properties];

  superUserOnly = false;

  properties = properties;

  get example() {
    return {
      _id: '000000Be3RPQB9nF9WZlwQ',
      userId: '4GC1pwkPNtGSeqkyzn3sAb',
      contentId: '4q9ZCVPZ0tPfgbkhUybgYJ',
      interaction: {},
    };
  }

  get default(): any {
    return null;
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }
}

const definition = new DraftUserContentInteractionType();

Types.addBaseType(definition);
export default definition;
