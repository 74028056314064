import { useState, useRef, useEffect } from 'react';

import { getClient } from 'lane-shared/apollo';
import setEssensysAutoPay from 'lane-shared/graphql/essensys/setEssensysAutoPay';

export type UpdateAutoPayHook = {
  updating: boolean;
  error: Error | null;
  // The update function to initialize the api call
  update: (data: { autopay: boolean }) => void;
};

export type UpdateAutoPayHookParams = {
  // ID of the payment account to enable auto pay for
  paymentAccountId: string;
  accountId: string | undefined;
};

export type EssensysUpdateAutoPayType = {
  data: {
    essensys: {
      setAutoPay: boolean;
    };
  };
};

type ApolloRequestVariables = {
  // ID of the payment account to enable auto pay for
  paymentAccountId: string;
  // The operate/essensys account id
  accountId: string | undefined;
  // enrollment flag into autopay
  enroll: boolean;
};

/*
 * A hook for encapsulating api call to update auto pay
 *
 * * */
export default function useUpdateAutoPay(
  params: UpdateAutoPayHookParams
): UpdateAutoPayHook {
  const { accountId } = params;

  const [updating, setAutopayUpdating] = useState<boolean>(false);
  const [error, setAutopayError] = useState<Error | null>(null);

  const accountIdRef = useRef<string | undefined>(accountId);

  useEffect(() => {
    if (accountId) {
      accountIdRef.current = accountId;
    }
  }, [accountId]);

  async function update(data: { autopay: boolean }) {
    setAutopayUpdating(true);
    try {
      const variables: ApolloRequestVariables = {
        paymentAccountId: params?.paymentAccountId ?? '',
        enroll: !!data?.autopay,
        accountId: accountIdRef.current,
      };

      await getClient().mutate<EssensysUpdateAutoPayType>({
        refetchQueries: ['EssensysPaymentSettings'],
        mutation: setEssensysAutoPay,
        variables,
      });
      setAutopayUpdating(false);
    } catch (err) {
      setAutopayError(err);
      setAutopayUpdating(false);
    }
  }

  return {
    updating,
    error,
    update,
  };
}
