import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { POLL_INTERVAL } from '../helpers/constants';
import byStartDate from '../helpers/sort/byStartDate';
import { EssensysInvoiceClientType } from '../types/Essensys';
import useCompanyId from './useCompanyId';

const query = gql`
  query getEssensysInvoices($companyId: UUID, $accountId: String) {
    essensys {
      invoices(companyId: $companyId, accountId: $accountId) {
        _id
        number
        accountId
        accountName
        approved
        clientTypeId
        clientTypeName
        currencyCode
        startDate
        description
        emailAddress
        endingBalance
        bfwd
        gross
        net
        endDate
        location
        locationName
        paid
        organizationId
        organizationName
        outstanding
        tax
      }
    }
  }
`;

export default function useEssensysInvoices() {
  const companyId = useCompanyId();
  const { data, error, loading } = useQuery(query, {
    skip: !companyId,
    client: getClient(),
    fetchPolicy: 'no-cache',
    pollInterval: POLL_INTERVAL,
    variables: { companyId },
  });

  const invoices: EssensysInvoiceClientType[] = data?.essensys?.invoices || [];

  invoices.sort(byStartDate);

  return {
    invoices,
    error,
    loading,
  };
}

export function useEssensysInvoicesByAccountId(accountId: string) {
  const { data, error, loading } = useQuery(query, {
    client: getClient(),
    fetchPolicy: 'no-cache',
    pollInterval: POLL_INTERVAL,
    variables: { accountId },
  });

  const invoices: EssensysInvoiceClientType[] = data?.essensys?.invoices || [];

  invoices.sort(byStartDate);

  return {
    invoices,
    error,
    loading,
  };
}
