import * as yup from 'yup';

export default yup.object().shape({
  _order: yup
    .number()
    .min(0)
    .max(2 ** 32)
    .required(),
  content: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required(),
  section: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required(),
});
