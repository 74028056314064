import React from 'react';

import { MjmlSection, MjmlColumn, MjmlSpacer } from '../MjmlComponentsV2';

export function EmailSpacer() {
  return (
    <MjmlSection>
      <MjmlColumn>
        <MjmlSpacer padding="0" height="0" />
      </MjmlColumn>
    </MjmlSection>
  );
}
