import getBlockKey from './getBlockKey';
import parseObject from './parseObject';
import parseValue from './parseValue';

export default function generateHierarchy({ blocks, content }: any) {
  if (!content.block || !content.block.blockId) {
    return null;
  }

  const Block = blocks[getBlockKey(content.block)];

  if (!Block) {
    return null;
  }

  return _generateHierarchy({
    blockDefinition: Block.def,
    blocks,
    content,
    interaction: {},
    instance: content.block,
    dataDefs: content.data,
    key: content._id.substr(content._id.length - 6, 6),
    isTop: true,
  });
}

// @ts-expect-error ts-migrate(7023) FIXME: '_generateHierarchy' implicitly has return type 'a... Remove this comment to see the full error message
function _generateHierarchy({
  blockDefinition,
  blocks,
  instance = {},
  interaction = {},
  state,
  key,
  dataDefs,
  isTop,
}: any) {
  // @ts-expect-error ts-migrate(7023) FIXME: 'renderBlock' implicitly has return type 'any' bec... Remove this comment to see the full error message
  function renderBlock({ top, block, $ }: any) {
    const def = {};

    if (block.displayIf) {
      (def as any).displayIf = true;
    }

    const props = parseObject({
      obj: block.properties,
      props: top.properties,
      propDefs: blockDefinition.properties,
      state: interaction.state,
      data: interaction.data,
      dataDefs,
      editMode: false,
    });

    let children = null;

    if (block.children && block.children._bind) {
      const values = parseValue({
        value: block.children,
        props: top.properties,
        state: interaction.state,
        data: interaction.data,
        propDefs: blockDefinition.properties,
        dataDefs,
        showExamples: false,
        editMode: false,
      });

      if (block.children.block) {
        // this is iterating a block over values that were parsed.
        children =
          (values &&
            values.map((value: any, $: any) =>
              renderBlock({
                top,
                parent: block,
                block: block.children.block,
                $,
              })
            )) ||
          [];
      } else {
        // this returned actual blocks to render.

        children =
          (values &&
            values.map((child: any) =>
              renderBlock({
                top,
                parent: block,
                block: child,
                $,
              })
            )) ||
          [];
      }
    } else if (block.children) {
      children = block.children.map((child: any) =>
        renderBlock({ top, block: child })
      );
    }

    if (block.primitive) {
      return {
        _id: block._id,
        isPrimitive: true,
        hasLink: !!block.link,
        name: block.name || block.primitive,
        editable: block.editable,
        block: block.primitive,
        key: key + $ + block._id.substring(0, 6),
        children: children?.filter((child: any) => child != null),
      };
    }

    const Block = blocks[getBlockKey(block)];

    if (!Block) {
      return null;
    }

    return _generateHierarchy({
      blockDefinition: Block.def,
      blocks,
      state,
      instance: block,
      interaction,
      isTop,
      dataDefs,
      key: key + $ + block._id.substring(0, 6),
      ...props,
    });
  }

  return {
    _id: instance._id,
    key,
    name: instance.name || blockDefinition.name,
    hasLink: !!instance.link,
    blockId: blockDefinition._id,
    blockVersion: blockDefinition.version,
    blockType: blockDefinition.type,
    blockName: blockDefinition.name,
    children: [
      renderBlock({
        top: instance,
        block: blockDefinition.block,
      }),
    ],
  };
}
