import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { getChannelGroupRoles } from 'lane-shared/graphql/query';

import { Item } from 'components/form/Dropdown/Dropdown';

export const usePermissionGroupOptions = (channelId: string) => {
  const [options, setOptions] = useState<Item<string>[]>([]);
  const { loading, error, data } = useQuery(getChannelGroupRoles, {
    variables: {
      id: channelId,
    },
  });

  useEffect(() => {
    if (data?.channel?.groupRoles?.length) {
      const formattedPermissionGroups = data?.channel.groupRoles.map(
        (permissionGroup: any) => ({
          label: permissionGroup.name,
          value: permissionGroup._id,
        })
      );

      setOptions(formattedPermissionGroups);
    }
  }, [data]);

  return {
    loading,
    error,
    options,
  };
};
