import * as yup from 'yup';

import { cloneDeep } from '@apollo/client/utilities';

import {
  AccessControlServiceDefinitions,
  AccessControlServiceEntity,
} from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import {
  IdentityProviderDefinitions,
  IdentityProviderEntity,
  HIDSharedProperties,
  HIDVersionProperty,
} from 'lane-shared/helpers/integrations/AccessManagement/identityProvider';
import { createShapeFromProperties } from 'lane-shared/properties';

const kastleValidateNestedConfig = (
  settings: any,
  acsDefinition: any,
  credentialRangeSchema: any,
  credentialRangeSchemaDefinition: any
) => {
  const { visitorManagementEnabled } = settings.accessControlSettings || {};

  if (!visitorManagementEnabled) {
    delete settings.accessControlSettings.visitorCredentialRange; // remove fields in config when visitorManagementEnabled toggle is off
    delete settings.accessControlSettings.tenantMappings;
    delete settings.accessControlSettings.visitorConnectIssuerSignature;
    delete settings.accessControlSettings.visitorConnectOwnerGuid;
    delete settings.accessControlSettings.visitorConnectPassword;
    delete settings.accessControlSettings.visitorConnectUsername;
    delete settings.accessControlSettings.buildingId;

    delete acsDefinition.buildingId; // skip validations if visitorManagementEnabled toggle is off
    delete acsDefinition.visitorConnectOwnerGuid;
    delete acsDefinition.visitorConnectPassword;
    delete acsDefinition.visitorConnectUsername;
  } else {
    credentialRangeSchema = credentialRangeSchemaDefinition;
  }
  return credentialRangeSchema;
};

export const validateNestedConfig = (settings: any) => {
  let credentialRangeSchema;
  let accessControlGroupsSchema;

  const credentialRangeSchemaDefination = yup.object().shape({
    min: yup
      .string()
      .required(
        'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.min.error'
      ),
    max: yup
      .string()
      .required(
        'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.max.error'
      )
      .test(
        'is-greater',
        'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.max-greater-min.error',
        function (value) {
          const min = this.parent.min;
          if (!min) return true;
          return parseInt(<string>value, 10) > parseInt(min, 10); // Check if max is greater than min
        }
      ),
  });

  let idpDefinition = cloneDeep(
    IdentityProviderDefinitions[
      settings.identityProviderService?.value as IdentityProviderEntity
    ]
  );
  const acsDefinition = cloneDeep(
    AccessControlServiceDefinitions[
      settings.accessControlService?.value as AccessControlServiceEntity
    ]
  );

  if (
    settings.identityProviderSettings &&
    'isAppleWalletEnabled' in settings.identityProviderSettings &&
    !settings.identityProviderSettings.isAppleWalletEnabled
  ) {
    idpDefinition = { ...HIDSharedProperties, ...HIDVersionProperty };
  }

  if (
    settings.accessControlService?.value ===
      AccessControlServiceEntity.SaltoSvn &&
    settings.identityProviderService?.value === IdentityProviderEntity.SaltoSvn
  ) {
    if (
      'partitionIdEnabled' in settings.accessControlSettings &&
      !settings.accessControlSettings.partitionIdEnabled
    ) {
      delete acsDefinition.partitionId;
      delete idpDefinition.partitionId;
    }
  }

  if (!idpDefinition || !acsDefinition) return;
  const idpValidation = yup
    .object()
    .shape(createShapeFromProperties(idpDefinition));

  idpValidation.validateSync(settings.identityProviderSettings, {
    abortEarly: false,
  });

  if (
    settings.accessControlService?.value === AccessControlServiceEntity.CCure
  ) {
    const { partitionIdEnabled, mobileAccessUdf, sendInviteEmailUdf } =
      settings.accessControlSettings || {};

    if (!partitionIdEnabled) {
      delete acsDefinition.partitionId;
    }

    if (!mobileAccessUdf) {
      delete acsDefinition.mobileAccessUdf;
    }

    if (!sendInviteEmailUdf) {
      delete acsDefinition.sendInviteEmailUdf;
    }
  }

  if (
    settings.accessControlService?.value === AccessControlServiceEntity.Genetec
  ) {
    const { visitorManagementEnabled } = settings.accessControlSettings || {};

    if (
      Object.keys(settings.accessControlSettings?.mobAccessUdf || {}).length ===
      0
    ) {
      delete acsDefinition.mobAccessUdf;
    }

    if (!settings.accessControlSettings?.cardholderGroupsFilterEnabled) {
      delete acsDefinition.cardholderGroups;
    }

    if (!visitorManagementEnabled) {
      delete settings.accessControlSettings.visitorCredentialRange;
      delete settings.accessControlSettings.visitorAccessGroupMappings;
      delete settings.accessControlSettings.visitorManagementEnabled;
    }

    if (visitorManagementEnabled) {
      credentialRangeSchema = yup.object().shape({
        min: yup
          .string()
          .required(
            'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.min.error'
          ),
        max: yup
          .string()
          .required(
            'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.max.error'
          )
          .test(
            'is-greater',
            'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.max-greater-min.error',
            function (value) {
              const min = this.parent.min;
              if (!min) return true;
              return parseInt(<string>value, 10) > parseInt(min, 10); // Check if max is greater than min
            }
          ),
      });

      accessControlGroupsSchema = yup.lazy((value: any[] | undefined) => {
        if (!value || value?.length === 0) {
          return yup
            .array()
            .nullable()
            .required(
              'web.admin.channel.integrations.access.access-control-settings.visitor-management.access-control-mapping.access-control-group.error'
            );
        }

        return yup.array().of(
          yup.object().shape({
            accessGroupIDs: yup
              .array()
              .min(
                1,
                'web.admin.channel.integrations.access.access-control-settings.visitor-management.access-control-mapping.access-control-groups.one.error'
              ),
            vtsTenantIDs: yup
              .array()
              .min(
                1,
                'web.admin.channel.integrations.access.access-control-settings.visitor-management.access-control-mapping.apply-to-these-tenants.error'
              ),
          })
        );
      });
    }
  }

  if (
    settings.accessControlService?.value === AccessControlServiceEntity.Maxxess
  ) {
    const { emailType } = settings.accessControlSettings || {};
    if (emailType?.value === 'mobileEmail') {
      delete acsDefinition.userEmailField;
    }
  }

  if (
    settings.accessControlService?.value === AccessControlServiceEntity.Genea
  ) {
    const { locationUdfEnabled, visitorManagementEnabled } =
      settings.accessControlSettings || {};

    if (!locationUdfEnabled) {
      delete acsDefinition.locationUdf;
    }

    if (!visitorManagementEnabled) {
      delete settings.accessControlSettings.visitorCredentialRange;
      delete settings.accessControlSettings.visitorAccessGroupMappings;
      delete settings.accessControlSettings.tenantMappings;
      delete settings.accessControlSettings.visitorManagementEnabled;
    }

    if (visitorManagementEnabled) {
      credentialRangeSchema = yup.object().shape({
        min: yup
          .string()
          .required(
            'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.min.error'
          ),
        max: yup
          .string()
          .required(
            'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.max.error'
          )
          .test(
            'is-greater',
            'web.admin.channel.integrations.access.access-control-settings.visitor-management.credential-range.max-greater-min.error',
            function (value) {
              const min = this.parent.min;
              if (!min) return true;
              return parseInt(<string>value, 10) > parseInt(min, 10); // Check if max is greater than min
            }
          ),
      });

      accessControlGroupsSchema = yup.lazy((value: any[] | undefined) => {
        if (!value || value?.length === 0) {
          return yup
            .array()
            .nullable()
            .required(
              'web.admin.channel.integrations.access.access-control-settings.visitor-management.access-control-mapping.access-control-group.error'
            );
        }

        return yup.array().of(
          yup.object().shape({
            accessGroupIDs: yup
              .array()
              .min(
                1,
                'web.admin.channel.integrations.access.access-control-settings.visitor-management.access-control-mapping.access-control-groups.one.error'
              ),
            geneaTenantIDs: yup
              .array()
              .min(
                1,
                'web.admin.channel.integrations.access.access-control-settings.visitor-management.access-control-mapping.apply-to-these-tenants.error'
              ),
          })
        );
      });
    }
  }

  if (
    settings.accessControlService?.value === AccessControlServiceEntity.Kastle
  ) {
    credentialRangeSchema = kastleValidateNestedConfig(
      settings,
      acsDefinition,
      credentialRangeSchema,
      credentialRangeSchemaDefination
    );
  }

  if (
    settings.accessControlService?.value ===
    AccessControlServiceEntity.Gallagher
  ) {
    if (!('division' in settings.accessControlSettings)) {
      delete acsDefinition.division;
    }
  }

  const acsValidation = yup.object().shape({
    ...createShapeFromProperties(acsDefinition),
    ...(credentialRangeSchema && {
      visitorCredentialRange: credentialRangeSchema,
    }), // Include visitorCredentialRange schema conditionally
    ...(accessControlGroupsSchema && {
      visitorAccessGroupMappings: accessControlGroupsSchema,
    }), // Include visitorCredentialRange schema conditionally
  });

  acsValidation.validateSync(settings.accessControlSettings, {
    abortEarly: false,
  });
};
