import { HTMLImage, MediaImageContentTypeEnum } from 'lane-shared/types/media';

import resizeImage from './resizeImage';

export default function enforceSize({
  maxWidth = 2400,
  maxHeight = 1600,
  quality = 0.9,
  background = 'transparent',
  image,
}: {
  maxWidth?: number;
  maxHeight?: number;
  quality?: number;
  background?: 'transparent';
  image: HTMLImage;
}) {
  // Keep PNGs as PNGs, everything else is now a JPEG.
  const imageType =
    image.type === MediaImageContentTypeEnum.png
      ? MediaImageContentTypeEnum.png
      : MediaImageContentTypeEnum.jpeg;

  return resizeImage({
    maxWidth,
    maxHeight,
    quality,
    background,
    image,
    imageType,
  });
}
