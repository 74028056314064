import React, { useState, useContext } from 'react';

import { useIsAdminView, useChannelAdminContext } from 'hooks';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { RendererContext } from 'lane-shared/contexts';
import { STEP_TEMPLATE } from 'lane-shared/helpers/constants/steps';
import constructContent from 'lane-shared/helpers/content/constructContent';
import { useCreateDraft, useChannelTheme } from 'lane-shared/hooks';
import {
  ContentTypeEnum,
  ContentModalTypeEnum,
} from 'lane-shared/types/content/ContentTypeEnum';
import { stepValidation } from 'lane-shared/validation';

import ModalBackground from 'components/general/ModalBackground';
import ContentTypePickerModal from 'components/lane/DraftContent/ContentTypePickerModal';
import TemplatePickerModal from 'components/lane/DraftContent/TemplatePickerModal';

import DatabaseSelectModal from './DatabaseSelectModal';
import { TemplatePickerModalContext } from 'contexts/TemplatePickerModalContext';

import styles from './DraftContentCreationModal.scss';
import { useDraftContentAnalytics } from 'lane-shared/hooks/analytics';

export const contentTypeRouteMapping = {
  [ContentTypeEnum.Content]: routes.channelAdminCreateContent,
  [ContentTypeEnum.ScheduledContent]: routes.channelAdminCreateContent,
  [ContentTypeEnum.Notice]: routes.channelAdminCreateNotice,
  [ContentTypeEnum.ScheduledNotice]: routes.channelAdminCreateNotice,
  [ContentTypeEnum.Perk]: routes.channelAdminCreatePerk,
  [ContentTypeEnum.Promotion]: routes.channelAdminCreatePromotion,
  [ContentTypeEnum.Static]: routes.channelAdminCreatePage,
  [ContentTypeEnum.VisitorManagement]: routes.channelAdminCreateVisitorForm,
};

export type CreateContentOptions = {
  isContentGenerator?: boolean;
  skipTemplate?: boolean;
  generator?: { datasetSchema: { _id: string } } | null;
};

export default function DraftContentCreationModal() {
  const [isContentGenerator, setIsContentGenerator] = useState(false);
  const { channel } = useChannelAdminContext();
  const [, channelSlug] = useIsAdminView();
  const theme = useChannelTheme(channel);
  const { constructBaseBlock } = useContext(RendererContext);
  const history = useHistory();
  const location = useLocation();
  const { draftContentTracker } = useDraftContentAnalytics();
  const {
    templateType,
    setTemplateType,
    selectedTemplate,
    setModalType,
    modalType,
  } = useContext(TemplatePickerModalContext);

  const { loadTemplate } = useCreateDraft({
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentTypeEnum' is not assignable to type '... Remove this comment to see the full error message
    contentType: templateType,
    stepValidation,
    step: STEP_TEMPLATE,
    channel,
  });

  function goToModal(modalType: ContentModalTypeEnum) {
    setModalType(modalType);
  }

  function onClose() {
    setModalType(null);
    setIsContentGenerator(false);
  }

  async function onCreateContent(
    contentType: ContentTypeEnum,
    {
      isContentGenerator = false,
      skipTemplate = false,
      generator,
    }: CreateContentOptions = {}
  ) {
    let content: any = null;

    if (!skipTemplate && selectedTemplate) {
      content = await loadTemplate(selectedTemplate, {
        isContentGenerator,
        generator,
      });
      draftContentTracker.Create.Start.FromTemplate(content);
    } else {
      content = constructContent({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentTypeEnum' is not assignable to type '... Remove this comment to see the full error message
        contentType,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
        channel,
        baseBlock: constructBaseBlock(),
        isContentGenerator,
        generator,
      });
      draftContentTracker.Create.Start.New(content);
    }

    // not allowing work order contnent type to be created by the modal
    if (!content || contentType === ContentTypeEnum.WorkOrder) {
      window.Alert.alert({
        title: 'Something went wrong during the content creation',
      });
      onClose();
      return;
    }

    const redirectRoute = contentTypeRouteMapping[contentType];
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    history.push(generatePath(redirectRoute, { id: channelSlug }), {
      content,
      from: location.pathname,
    });
    onClose();
  }

  function onStartBatchContentFlow() {
    setTemplateType(ContentTypeEnum.Static);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"templateSelect"' is not assigna... Remove this comment to see the full error message
    goToModal('templateSelect');
    setIsContentGenerator(true);
  }

  function onBackFromTemplateModal() {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"contentSelect"' is not assignab... Remove this comment to see the full error message
    goToModal('contentSelect');
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setTemplateType(null);
  }

  return (
    <ModalBackground
      onClose={onClose}
      isOpen={Boolean(modalType)}
      className={styles.background}
      hasOpaqueBackground
    >
      {modalType === 'contentSelect' && (
        <ContentTypePickerModal
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
          channel={channel}
          onClose={onClose}
          onCreateContent={onCreateContent}
          onStartBatchContentFlow={onStartBatchContentFlow}
          goToModal={goToModal}
        />
      )}
      {modalType === 'templateSelect' && (
        <TemplatePickerModal
          onBack={onBackFromTemplateModal}
          onClose={onClose}
          onCreateContent={onCreateContent}
          isContentGenerator={isContentGenerator}
          goToModal={goToModal}
          channel={channel}
          contentType={templateType}
          theme={theme}
        />
      )}
      {modalType === 'databaseSelect' && (
        <DatabaseSelectModal
          onClose={onClose}
          goToModal={goToModal}
          onCreateContent={onCreateContent}
        />
      )}
    </ModalBackground>
  );
}
