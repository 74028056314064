import React, { useReducer } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { removeTime } from 'lane-shared/domains/visitorManagement/helpers';
import {
  createScheduleReducer,
  ScheduleActionType,
  ScheduleReducerType,
} from 'lane-shared/domains/visitorManagement/reducers';

import Checkbox from 'components/form/Checkbox';
import { S, TimePicker, TimeIntervals } from 'design-system-web';

import DateRangePickerButton from '../DatePickers/DateRangePickerButton';

import styles from './ScheduleDateTimeRange.scss';

export type ScheduleDateTimeRangeHandle = {
  reset: () => void;
};

type Props = {
  className?: string;
  allDayPassEnabled: boolean;
  defaultIsAllDay: boolean;
  defaultStartDate: Date;
  defaultEndDate: Date;
  defaultStartTime?: Date;
  defaultEndTime?: Date;
  timeZone?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: ScheduleReducerType) => void;
  isPreview?: boolean;
};

function ScheduleDateTimeRange({
  className,
  allDayPassEnabled,
  defaultIsAllDay,
  defaultStartDate,
  defaultEndDate,
  defaultStartTime,
  defaultEndTime,
  timeZone,
  minDate,
  maxDate,
  onChange,
  isPreview = false,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const reducerFunction = createScheduleReducer(
    {
      isAllDay: defaultIsAllDay,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      startTime: defaultStartTime ?? defaultStartDate,
      endTime: defaultEndTime ?? defaultEndDate,
    },
    onChange,
    timeZone
  );

  const [state, dispatch] = useReducer<typeof reducerFunction>(
    reducerFunction,
    {
      isAllDay: defaultIsAllDay,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      internalStartDate: removeTime(defaultStartDate, timeZone),
      internalEndDate: removeTime(defaultEndDate, timeZone),
      startTime: defaultStartTime ?? defaultStartDate,
      endTime: defaultEndTime ?? defaultEndDate,
    }
  );

  return (
    <div className={cx(styles.scheduleDateTimeRange, className)}>
      {allDayPassEnabled && (
        <div className={styles.allDayCheckboxContainer}>
          <Checkbox
            selected={state.isAllDay}
            value={state.isAllDay}
            onChange={() => {
              dispatch({
                type: ScheduleActionType.ToggleAllDay,
              });
            }}
            className={styles.allDayCheckbox}
            labelStyles={{ fontSize: 16, paddingLeft: 7 }}
            text={t('web.content.feature.visitorManagement.form.allDay')}
            dataCy="allDayCheckbox"
            disabled={isPreview}
          />
        </div>
      )}
      <div className={styles.dateOptions}>
        <div className={styles.dateContainer}>
          <div className={styles.date}>
            <S>
              {t(
                'web.content.feature.visitorManagement.scheduler.form.selectDateRange'
              )}
            </S>
            <DateRangePickerButton
              startDate={state.internalStartDate}
              endDate={state.internalEndDate}
              className={styles.datePicker}
              buttonClassName={styles.datePickerButton}
              onChange={() => {}}
              hideLabel
              onSubmit={dateRange =>
                dispatch({
                  type: ScheduleActionType.SetDateRange,
                  value: {
                    dateRange,
                  },
                })
              }
              minDate={minDate}
              maxDate={maxDate}
              disabled={isPreview}
              timeZone={timeZone}
            />
          </div>
        </div>
        {(!allDayPassEnabled || !state.isAllDay) && (
          <div className={styles.timesContainer}>
            <div className={styles.times}>
              <div className={styles.group}>
                <S>
                  {t(
                    'web.content.feature.visitorManagement.scheduler.form.startTime'
                  )}
                </S>
                <TimePicker
                  hideLabel
                  className={styles.timePicker}
                  buttonClassName={styles.timePickerButton}
                  value={state.startTime}
                  onChange={date => {
                    dispatch({
                      type: ScheduleActionType.SetStartTime,
                      value: {
                        date: new Date(date),
                      },
                    });
                  }}
                  showClear={false}
                  showIcon={false}
                  timeZone={timeZone}
                  unit={TimeIntervals.QUARTER_HOUR}
                  dataCy="startTime"
                  disabled={isPreview}
                />
              </div>
              <div className={styles.group}>
                <S>
                  {t(
                    'web.content.feature.visitorManagement.scheduler.form.endTime'
                  )}
                </S>
                <TimePicker
                  hideLabel
                  className={styles.timePicker}
                  buttonClassName={styles.timePickerButton}
                  value={state.endTime}
                  onChange={date => {
                    dispatch({
                      type: ScheduleActionType.SetEndTime,
                      value: {
                        date: new Date(date),
                      },
                    });
                  }}
                  showClear={false}
                  showIcon={false}
                  timeZone={timeZone}
                  unit={TimeIntervals.QUARTER_HOUR}
                  dataCy="endTime"
                  disabled={isPreview}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ScheduleDateTimeRange;
