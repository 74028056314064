import React, { FocusEvent, SetStateAction } from 'react';

import cx from 'classnames';

import { useValidatedControl } from 'lane-shared/hooks';

import Input from './Input/index';

import styles from './ValidatedInput.scss';

type Props = {
  /** label to display */
  label?: string;
  /** yup validation schema for this input */
  validation?: {};
  /** Function that returns value and field name  */
  onChange: (value: string) => void;
  /** If exist on pressing enter will execute onSubmit func  */
  onSubmit?: () => void;
  /** input value */
  value: number | string | null | undefined;
  /** Icon name  */
  icon?: string;
  /** input type */
  type?: 'number' | 'text' | 'email' | 'password' | 'tel' | undefined;
  /** placeholder text */
  placeholder: string;
  /** is this control disabled or not? */
  disabled?: boolean;
  /** Flag to indicate if any validation errors should be shown for this input */
  isPristine?: boolean;
  /** maxLength allowed in this input */
  maxLength?: number;
  style?: React.CSSProperties;
  className?: string;
  dataCy?: string;
  showClear?: boolean;
  onBlur?: (value: SetStateAction<string | null>) => void;
  fixedLabel?: boolean;
};

function ValidatedInput({
  onChange,
  className,
  style,
  label,
  value = '',
  type = 'text',
  placeholder = '',
  disabled = false,
  maxLength,
  validation,
  isPristine = true,
  dataCy,
  onBlur,
  showClear = true,
  ...otherProps
}: Props) {
  const [, validationMessages, , error] = useValidatedControl(
    value,
    isPristine,
    validation
  );

  function onInputChange(value: string) {
    onChange(value);
  }

  function onInputBlur(e: FocusEvent<HTMLInputElement>) {
    if (onBlur) {
      onBlur(e.target.value);
    }

    onChange(e.target.value);
  }

  return (
    <div className={cx(styles.ValidatedInput, className)} style={style}>
      <Input
        value={value}
        maxLength={maxLength}
        onChange={value => onInputChange(value)}
        onBlur={onInputBlur}
        label={label}
        type={type}
        placeholder={placeholder}
        {...otherProps}
        disabled={disabled}
        dataCy={dataCy}
        autoComplete={false}
        showClear={showClear}
        error={error ? validationMessages : []}
      />
    </div>
  );
}

export default ValidatedInput;
