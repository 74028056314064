const templateBaseLength = {
  templateName: {
    min: 3,
    max: 75,
  },
  notes: {
    max: 800,
  },
  version: {
    min: 0,
    max: 1024,
  },
  _order: {
    min: 0,
  },
};

export { templateBaseLength };
