import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import Label from 'components/general/Label';

import styles from './Toggle.scss';

type OwnProps = {
  value: boolean | null | undefined;
  disabled?: boolean;
  text?: string;
  description?: string;
  style?: React.CSSProperties;
  className?: string;
  onChange: (value: boolean) => any;
  interfaceStyle?: 'light' | 'dark';
  doTranslate?: boolean;
  dataCy?: string;
  mt?: number;
  mb?: number;
  TooltipComponent?: React.ReactNode;
  valueDataCy?: string;
};

Toggle.defaultProps = {
  disabled: false,
  doTranslate: false,
  interfaceStyle: 'light',
};

type Props = OwnProps & typeof Toggle.defaultProps;

export default function Toggle({
  text,
  description,
  disabled,
  doTranslate,
  value,
  dataCy,
  onChange,
  mt,
  mb,
  className,
  style,
  interfaceStyle,
  TooltipComponent,
  valueDataCy,
}: Props) {
  const { t } = useTranslation();

  return (
    <label
      className={cx(styles.container, className)}
      style={style}
      data-toggle-disabled={disabled}
      data-interface-style={interfaceStyle}
      data-margin-top={mt}
      data-margin-bottom={mb}
      role="button"
      onClick={() => onChange(!value)}
      onKeyDown={e => e.key === Key.Enter && onChange(!value)}
      data-cy={dataCy || text}
      tabIndex={0}
    >
      <div
        data-cy={valueDataCy || 'toggle-value'}
        data-testid="toggle-value-test-id"
        className={styles.switchBackground}
        data-toggle-status={value}
      >
        <span className={styles.switchButton} data-toggle-status={value} />
      </div>
      <div className={styles.textContainer}>
        {text && (
          <Label
            TooltipComponent={TooltipComponent}
            className={styles.text}
            mt={0}
            as="span"
          >
            {doTranslate ? t(text) : text}
          </Label>
        )}
        {description && (
          <span className={styles.description}>
            {doTranslate ? t(description) : description}
          </span>
        )}
      </div>
    </label>
  );
}
