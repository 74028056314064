import { z } from 'zod';

import { EventStatus, RecurrenceEventStatus } from '../types';

export const legalSchema = z.object({
  terms: z.string(),
  privacy: z.string(),
});

export const whitelabelSchema = z.object({
  name: z.string(),
  contactEmail: z.string().optional(),
  addressLineOne: z.string(),
  addressLineTwo: z.string().optional(),
});

export const buildingSchema = z.object({
  name: z.string(),
  addressLineOne: z.string(),
  addressLineTwo: z.string().optional(),
  timezone: z.string(),
  contactEmail: z.string().optional(),
  mapUri: z.string().optional(),

  visitorInstructions: z.string().optional(),
  visitorPolicies: z.string().optional(),

  visitorHeroUri: z.string().optional(),
  logoUri: z.string().optional(),
  primaryColor: z.string().optional(),

  legal: legalSchema.optional(),

  qrEnabled: z.boolean().optional(),
});

export const hostSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().optional(),
  companyName: z.string(),
  floor: z.string().optional(),
});

export const visitorPassSchema = z.object({
  startDate: z.string(),
  endDate: z.string().optional(),
  startTime: z.string(),
  endTime: z.string(),
  status: z.nativeEnum(EventStatus),
  notes: z.string().optional(),
  notesToVisitor: z.string().optional(),
  notesToStaff: z.string().optional(),
  additionalRecipients: z.array(z.string()).optional(),
});

// contains old visitor pass data
export const changeDetailsSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  changeNote: z.string(),
});

export const credentialSchema = z.object({
  data: z.string(), // base64 encoded qrcode
  uri: z.string(),
});

export const visitorSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().optional(),
  phone: z.string().optional(),
  company: z.string().optional(),
});

export const appleWalletSchema = z.object({
  link: z.string(),
});

export const calendarLinksSchema = z.object({
  google: z.string(),
  outlook: z.string(),
  yahoo: z.string(),
});

export const recurringScheduleSchema = z.object({
  scheduleText: z.string(),
  startDate: z.string(),
  startTime: z.string(),
  endDate: z.string(),
  endTime: z.string(),
  status: z.nativeEnum(RecurrenceEventStatus),
  notesToStaff: z.string().optional(),
  notesToVisitor: z.string().optional(),
});

// contains old recurrence schedule data
export const scheduleChangeDetailsSchema = z.object({
  changeNote: z.string(),
  scheduleText: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  notesToStaff: z.string().optional(),
  notesToVisitor: z.string().optional(),
});
