import React, { useContext } from 'react';

import { ThemeContext } from 'lane-shared/contexts';

function BuildingIllustration() {
  const theme = useContext(ThemeContext);

  const { primary: primaryColor } = theme;

  return (
    <svg
      id="b90746aa-ab89-4048-b48f-88833dcede4a"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 888.24409 478.03289"
    >
      <title>quite_town</title>
      <path
        d="M347.878,687.98355h-2a94,94,0,0,0-188,0h-2a96,96,0,0,1,192,0Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M1043.878,687.98355h-2a94,94,0,1,0-188,0h-2a96,96,0,1,1,192,0Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M808.878,458.98355h-2c0-94.8413-77.15918-172-172-172-94.84131,0-172,77.1587-172,172h-2c0-95.94384,78.05616-174,174-174C730.82229,284.98355,808.878,363.03971,808.878,458.98355Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <polygon
        points="153.891 311.384 221.752 230.567 355.171 230.567 436.705 309.983 435.058 311.042 435.646 311.042 435.646 476.227 153.984 476.227 153.984 311.042 153.891 311.384"
        fill="#3f3d56"
      />
      <rect
        x="350.59718"
        y="433.98355"
        width="15.38295"
        height="53.24868"
        transform="translate(560.69936 710.23224) rotate(-180)"
        fill="#3f3d56"
      />
      <polygon
        points="220.694 229.508 304.345 327.581 304.345 476.227 153.984 476.227 153.984 308.924 220.694 229.508"
        fill={primaryColor}
      />
      <rect
        x="254.30616"
        y="347.0438"
        width="30.70751"
        height="31.3983"
        fill="#3f3d56"
      />
      <rect
        x="254.30616"
        y="389.767"
        width="30.70751"
        height="31.39834"
        fill="#3f3d56"
      />
      <rect
        x="254.30616"
        y="347.0438"
        width="30.70751"
        height="31.3983"
        fill="#fff"
      />
      <rect
        x="254.30616"
        y="389.767"
        width="30.70751"
        height="31.39834"
        fill="#fff"
      />
      <rect
        x="211.83775"
        y="347.0438"
        width="30.70751"
        height="31.3983"
        fill="#3f3d56"
      />
      <rect
        x="211.83775"
        y="389.767"
        width="30.70751"
        height="31.39834"
        fill="#3f3d56"
      />
      <rect
        x="211.83775"
        y="347.0438"
        width="30.70751"
        height="31.3983"
        fill="#fff"
      />
      <rect
        x="211.83775"
        y="389.767"
        width="30.70751"
        height="31.39834"
        fill="#fff"
      />
      <rect
        x="169.36934"
        y="347.0438"
        width="30.70751"
        height="31.3983"
        fill="#3f3d56"
      />
      <rect
        x="169.36934"
        y="389.767"
        width="30.70751"
        height="31.39834"
        fill="#3f3d56"
      />
      <rect
        x="169.36934"
        y="347.0438"
        width="30.70751"
        height="31.3983"
        fill="#fff"
      />
      <rect
        x="169.36934"
        y="389.767"
        width="30.70751"
        height="31.39834"
        fill="#fff"
      />
      <rect x="485" y="40" width="14" height="35" fill={primaryColor} />
      <rect x="379" y="72" width="119" height="405" fill={primaryColor} />
      <rect x="498" y="72" width="48" height="405" fill="#3f3d56" />
      <rect x="400.5" y="107" width="18" height="18" fill="#fff" />
      <rect x="429.5" y="107" width="18" height="18" fill="#fff" />
      <rect x="458.5" y="107" width="18" height="18" fill="#fff" />
      <rect x="401" y="148" width="18" height="18" fill="#fff" />
      <rect x="430" y="148" width="18" height="18" fill="#fff" />
      <rect x="459" y="148" width="18" height="18" fill="#fff" />
      <rect x="401.5" y="189" width="18" height="18" fill="#fff" />
      <rect x="430.5" y="189" width="18" height="18" fill="#fff" />
      <rect x="459.5" y="189" width="18" height="18" fill="#fff" />
      <rect x="402" y="230" width="18" height="18" fill="#fff" />
      <rect x="431" y="230" width="18" height="18" fill="#fff" />
      <rect x="460" y="230" width="18" height="18" fill="#fff" />
      <rect x="402.5" y="271" width="18" height="18" fill="#fff" />
      <rect x="431.5" y="271" width="18" height="18" fill="#fff" />
      <rect x="460.5" y="271" width="18" height="18" fill="#fff" />
      <rect x="403" y="312" width="18" height="18" fill="#fff" />
      <rect x="432" y="312" width="18" height="18" fill="#fff" />
      <rect x="461" y="312" width="18" height="18" fill="#fff" />
      <rect x="403.5" y="353" width="18" height="18" fill="#fff" />
      <rect x="432.5" y="353" width="18" height="18" fill="#fff" />
      <rect x="461.5" y="353" width="18" height="18" fill="#fff" />
      <rect x="404" y="394" width="18" height="18" fill="#fff" />
      <rect x="433" y="394" width="18" height="18" fill="#fff" />
      <rect x="433" y="442" width="18" height="34" fill="#fff" />
      <rect x="462" y="394" width="18" height="18" fill="#fff" />
      <rect x="498" y="40" width="8" height="34" fill="#3f3d56" />
      <rect x="635" y="218" width="14" height="20.74371" fill={primaryColor} />
      <rect
        x="529"
        y="236.96568"
        width="119"
        height="240.03432"
        fill={primaryColor}
      />
      <rect
        x="648"
        y="236.96568"
        width="48"
        height="240.03432"
        fill="#3f3d56"
      />
      <rect x="552.5" y="271" width="18" height="18" fill="#fff" />
      <rect x="581.5" y="271" width="18" height="18" fill="#fff" />
      <rect x="610.5" y="271" width="18" height="18" fill="#fff" />
      <rect x="553" y="312" width="18" height="18" fill="#fff" />
      <rect x="582" y="312" width="18" height="18" fill="#fff" />
      <rect x="611" y="312" width="18" height="18" fill="#fff" />
      <rect x="553.5" y="353" width="18" height="18" fill="#fff" />
      <rect x="582.5" y="353" width="18" height="18" fill="#fff" />
      <rect x="611.5" y="353" width="18" height="18" fill="#fff" />
      <rect x="554" y="394" width="18" height="18" fill="#fff" />
      <rect x="583" y="394" width="18" height="18" fill="#fff" />
      <rect x="583" y="442" width="18" height="34" fill="#fff" />
      <rect x="612" y="394" width="18" height="18" fill="#fff" />
      <rect x="648" y="218" width="8" height="20.15103" fill="#3f3d56" />
      <rect
        x="0.24409"
        y="475.79217"
        width="888"
        height="2.24072"
        fill="#3f3d56"
      />
      <circle cx="732.65969" cy="404.04057" r="26.04057" fill={primaryColor} />
      <path
        d="M907.90824,597.63107A26.0426,26.0426,0,0,1,864.517,625.19165a26.04282,26.04282,0,1,0,43.39129-27.56058Z"
        transform="translate(-155.87795 -210.98355)"
        opacity="0.2"
      />
      <polygon
        points="732.731 404.041 732.803 404.041 734.09 477.87 731.372 477.87 732.731 404.041"
        fill="#3f3d56"
      />
      <rect
        x="890.2546"
        y="653.36959"
        width="1.28772"
        height="4.86472"
        transform="translate(900.27893 -649.01245) rotate(62.23413)"
        fill="#3f3d56"
      />
      <circle cx="809.21843" cy="365.59931" r="39.59931" fill={primaryColor} />
      <path
        d="M994.55282,550.13364a39.6024,39.6024,0,0,1-65.98417,41.91075,39.60273,39.60273,0,1,0,65.98417-41.91075Z"
        transform="translate(-155.87795 -210.98355)"
        opacity="0.2"
      />
      <polygon
        points="809.327 365.599 809.436 365.599 811.394 477.87 807.26 477.87 809.327 365.599"
        fill="#3f3d56"
      />
      <rect
        x="967.70733"
        y="634.89394"
        width="1.95821"
        height="7.39767"
        transform="translate(926.60095 -727.03592) rotate(62.23413)"
        fill="#3f3d56"
      />
      <circle cx="26.65969" cy="403.04057" r="26.04057" fill={primaryColor} />
      <path
        d="M201.90824,596.63107A26.0426,26.0426,0,0,1,158.517,624.19165a26.04282,26.04282,0,1,0,43.39129-27.56058Z"
        transform="translate(-155.87795 -210.98355)"
        opacity="0.2"
      />
      <polygon
        points="26.731 403.041 26.803 403.041 28.09 476.87 25.372 476.87 26.731 403.041"
        fill="#3f3d56"
      />
      <rect
        x="184.2546"
        y="652.36959"
        width="1.28772"
        height="4.86472"
        transform="translate(522.29096 -24.83639) rotate(62.23413)"
        fill="#3f3d56"
      />
      <path
        d="M762.29138,248.31933l9.20569-7.36279c-7.15149-.789-10.08991,3.11126-11.29248,6.19836-5.587-2.32-11.66919.72046-11.66919.72046l18.41888,6.68671A13.93789,13.93789,0,0,0,762.29138,248.31933Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M300.29138,285.31933l9.20569-7.36279c-7.15149-.789-10.08991,3.11126-11.29248,6.19836-5.587-2.32-11.66919.72046-11.66919.72046l18.41888,6.68671A13.93789,13.93789,0,0,0,300.29138,285.31933Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M902.29138,323.31933l9.20569-7.36279c-7.15149-.789-10.08991,3.11126-11.29248,6.19836-5.587-2.32-11.66919.72046-11.66919.72046l18.41888,6.68671A13.93789,13.93789,0,0,0,902.29138,323.31933Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M376.29138,265.31933l9.20569-7.36279c-7.15149-.789-10.08991,3.11126-11.29248,6.19836-5.587-2.32-11.66919.72046-11.66919.72046l18.41888,6.68671A13.93789,13.93789,0,0,0,376.29138,265.31933Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M393.29138,345.31933l9.20569-7.36279c-7.15149-.789-10.08991,3.11126-11.29248,6.19836-5.587-2.32-11.66919.72046-11.66919.72046l18.41888,6.68671A13.93789,13.93789,0,0,0,393.29138,345.31933Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M751.29138,387.31933l9.20569-7.36279c-7.15149-.789-10.08991,3.11126-11.29248,6.19836-5.587-2.32-11.66919.72046-11.66919.72046l18.41888,6.68671A13.93789,13.93789,0,0,0,751.29138,387.31933Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <polygon
        points="106 456 44 456 44 458 53.669 458 53.669 477 55.669 477 55.669 458 93.331 458 93.331 477 95.331 477 95.331 458 106 458 106 456"
        fill="#3f3d56"
      />
      <path
        d="M324.878,674.98355a13.91769,13.91769,0,0,0-6.96954,1.86975A14.98176,14.98176,0,0,0,292.878,687.98355h45.94953A13.99046,13.99046,0,0,0,324.878,674.98355Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M561.878,674.98355a13.91769,13.91769,0,0,0-6.96954,1.86975A14.98176,14.98176,0,0,0,529.878,687.98355h45.94953A13.99046,13.99046,0,0,0,561.878,674.98355Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M716.878,674.98355a13.91769,13.91769,0,0,0-6.96954,1.86975A14.98176,14.98176,0,0,0,684.878,687.98355h45.94953A13.99046,13.99046,0,0,0,716.878,674.98355Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M920.878,674.98355a13.91769,13.91769,0,0,0-6.96954,1.86975A14.98176,14.98176,0,0,0,888.878,687.98355h45.94953A13.99046,13.99046,0,0,0,920.878,674.98355Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <path
        d="M1018.878,674.98355a13.91769,13.91769,0,0,0-6.96954,1.86975A14.98176,14.98176,0,0,0,986.878,687.98355h45.94953A13.99046,13.99046,0,0,0,1018.878,674.98355Z"
        transform="translate(-155.87795 -210.98355)"
        fill="#3f3d56"
      />
      <polygon
        points="129 407 113 407 113 423 120.417 423 120.417 476.912 122.417 476.912 122.417 423 129 423 129 407"
        fill="#3f3d56"
      />
      <rect x="44.12205" y="450.01645" width="62" height="2" fill="#3f3d56" />
      <rect x="44.12205" y="445.01645" width="62" height="2" fill="#3f3d56" />
      <rect x="44.12205" y="440.01645" width="62" height="2" fill="#3f3d56" />
    </svg>
  );
}

export default BuildingIllustration;
