import { v4 as uuid } from 'uuid';

import getLink from '../getLink';

export default function YoutubeBlockMigrator(oldBlock: any) {
  return {
    _id: uuid(),
    blockId: '3MySOQd6QCKAv9HiktQe1w',
    blockVersion: 0,
    properties: { text: oldBlock.videoid, header: null, textAlign: 'left' },
    link: getLink(oldBlock.link),
  };
}
