import { useContext, useEffect, useState } from 'react';

import { LaneFeatureType } from 'common-types';
import { AppContext } from '../../contexts';
import mapGuestInvitesToIcsAttendees from '../../helpers/features/mapGuestInvitesToIcsAttendees';
import { IcsPersonType } from '../../helpers/generateIcsData';

export default function useGuestInviteToIcsAttendees(
  guestInviteData: LaneFeatureType.GuestInviteInteraction | undefined | null
): [boolean, IcsPersonType[]] {
  const { whitelabel } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [attendees, setAttendees] = useState<IcsPersonType[]>([]);

  async function mapGuestsToAttendees() {
    if (!guestInviteData?.guests?.length) {
      return;
    }

    setLoading(true);

    const attendees = await mapGuestInvitesToIcsAttendees(
      guestInviteData,
      whitelabel
    );

    setAttendees(attendees);
    setLoading(false);
  }

  useEffect(() => {
    mapGuestsToAttendees();
  }, [guestInviteData]);

  return [loading, attendees];
}
