import * as yup from 'yup';

import Types from '../../../../properties/Types';
// need to make sure these guys are loaded.
import '../../../../properties/baseTypes/UUID';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';

const properties: PropertiesInterface = {
  labelText: {
    type: 'String',
    default: '',
    validators: [],
  },
  placeholderText: {
    type: 'String',
    default: '',
    validators: [],
  },
};

export class UserNotes extends ObjectTypeBase {
  name = 'UserNotes';

  get default(): any {
    return undefined;
  }

  contexts = [];

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new UserNotes();

Types.addType(definition);
export default definition;
