import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { AcceptedCardsHeader } from './AcceptedCardsHeader';

import styles from './PaymentOptionsTitle.scss';

export function PaymentOptionsTitle() {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.paymentOptionsTitleContainer)}>
      <div className={styles.paymentOptionsTitle}>
        <h3 className={cx(styles.paymentOptionsTitleText)}>
          {t('abp.payment.paymentOptions')}
        </h3>
      </div>
      <AcceptedCardsHeader />
    </div>
  );
}
