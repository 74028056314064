import React from 'react';

import { explodeFeatures } from 'lane-shared/helpers/features';
import { EssensysProductInteractionType } from 'lane-shared/types/features/EssensysProductFeatureProperties';

import EssensysOnlinePlanPaymentFeatureConfirmationModal from './EssensysOnlinePlanPaymentFeatureConfirmationModal';
import AuthPurchaseWrapper from './EssensysOnlinePlanPaymentFeatureConfirmationModal/AuthPurchaseWrapper';
import EssensysPosPaymentFeatureConfirmationModal from './EssensysPosPaymentFeatureConfirmationModal';
import LegacyPaymentFeatureConfirmationModal from './LegacyPaymentFeatureConfirmationModal';
import PaymentFeatureConfirmationModalProps from './PaymentFeatureConfirmationModalProps';
import PaymentOrMenuFeatureConfirmation from './PaymentOrMenuFeatureConfirmation';

export default function PaymentFeatureConfirmationModal(
  props: PaymentFeatureConfirmationModalProps
) {
  const {
    essensysProductFeature,
    paymentFeature,
    menuFeature,
  } = explodeFeatures(props.content.features);

  if (
    essensysProductFeature?.productInteractionType ===
    EssensysProductInteractionType.OnlinePlan
  ) {
    return (
      <AuthPurchaseWrapper {...props}>
        <EssensysOnlinePlanPaymentFeatureConfirmationModal {...props} />
      </AuthPurchaseWrapper>
    );
  }
  if (
    essensysProductFeature?.productInteractionType ===
    EssensysProductInteractionType.PointOfSale
  ) {
    return (
      <AuthPurchaseWrapper {...props}>
        <EssensysPosPaymentFeatureConfirmationModal {...props} />
      </AuthPurchaseWrapper>
    );
  }

  if ((paymentFeature || menuFeature) && !essensysProductFeature) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ isOpen: boolean; onClose: () => void; cont... Remove this comment to see the full error message
    return <PaymentOrMenuFeatureConfirmation {...props} />;
  }
  return <LegacyPaymentFeatureConfirmationModal {...props} />;
}
