import React, { CSSProperties, ReactNode } from 'react';

import cx from 'classnames';

import styles from './AuthForm.scss';

type Props = {
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
};

export default function AuthForm({ style, className, children }: Props) {
  return (
    <div className={cx(styles.AuthForm, className)} style={style}>
      {children}
    </div>
  );
}
