import * as yup from 'yup';

import { ContentCategoryEnum } from '../types/content/ContentCategoryEnum';
import { ContentTypeEnum } from '../types/content/ContentTypeEnum';
import { ContentGroupByEnum } from '../types/filters/SearchOptions';
import { SectionTypeEnum } from '../types/sections/SectionTypeEnum';

export default yup.object().shape({
  name: yup.string().trim().min(3).max(44).required(),
  description: yup.string().max(255).trim(),
  type: yup.string().oneOf(Object.values(SectionTypeEnum)),
  query: yup
    .object({
      contentCategories: yup.array().of(
        yup.string().oneOf(Object.values(ContentCategoryEnum))
        // .notOneOf([ContentCategoryEnum.None])
      ),
      contentTypes: yup.array(
        yup.string().oneOf(Object.values(ContentTypeEnum))
      ),
      includeChildren: yup.boolean().default(false),
    })
    .nullable()
    .notRequired(),
  object: yup.string().required(),
  renderType: yup.string().required(),
  tags: yup.array().of(yup.string()).min(0).max(6),
  backgroundImage: yup.string().nullable(),
  logo: yup.string().nullable(),
  color: yup.string(),
  backgroundColor: yup.string(),
  channel: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required(),
  filters: yup.array().of(yup.string()),
  sorts: yup.array().of(yup.string()),
  groupBy: yup.string().nullable().oneOf(Object.values(ContentGroupByEnum)),
  content: yup
    .array()
    .of(
      yup.object().shape({
        _id: yup.string().required(),
      })
    )
    .nullable(),
});
