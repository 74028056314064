import React, { useContext } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { ContentNotificationType } from 'lane-shared/types/content/DraftContent';
import type { PillColor } from 'lane-shared/types/general/pill';

import Pill from 'components/general/Pill';
import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

import IconButton from '../../general/IconButton';

import { Flex } from 'components/layout';
import { Button } from 'design-system-web';

import { H5, M } from 'components/typography';

import styles from './DraftContentExistingNotificationUI.scss';
import { MultiLanguageText } from 'components/general/MultiLanguageText';
import { ChannelType } from 'lane-shared/types/ChannelType';

export enum NotificationStatus {
  SENT,
  SCHEDULED,
}

const TRANSLATION_KEYS = {
  notificationMessage:
    'web.components.lane.DraftContent.DraftContentNotifications.notificationMessage',
  deleteButton: 'web.components.cards.DatePickerCard.deleteButton',
};

type Props = {
  channel: ChannelType | null;
  notification: ContentNotificationType;
  status: NotificationStatus;
  timezone?: string | null;
  removeNotification?: (notification: ContentNotificationType) => void;
};

export function DraftContentExistingNotificationUI({
  channel,
  notification,
  status,
  timezone,
  removeNotification,
}: Props) {
  const { sendAt } = notification;

  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const pillProps: {
    color: PillColor;
    text: string;
    iconName: string;
    iconColor: string;
  } = {
    color: 'green',
    text: '',
    iconName: '',
    iconColor: '',
  };
  if (status === NotificationStatus.SENT) {
    pillProps.color = 'green';
    pillProps.text = t('Sent');
    pillProps.iconName = 'circle';
    pillProps.iconColor = tokens.color.general.green.secondary;
  }
  if (status === NotificationStatus.SCHEDULED) {
    pillProps.color = 'honey';
    pillProps.text = t('Scheduled');
    pillProps.iconName = 'circle';
    pillProps.iconColor = tokens.color.general.yellow.secondary;
  }

  let date = DateTime.fromJSDate(sendAt!);
  if (user?.locale) date = date.setLocale(user.locale);
  if (timezone) date = date.setZone(timezone);

  return (
    <div className={styles.existingNotificationContainer}>
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        className={styles.notificationTitleContainer}
      >
        <Flex direction="row" align="center">
          <IconButton icon="Bell" size="small" className={styles.iconButton} />
          <H5 className={styles.notificationDate}>
            {date.toFormat('MMMM dd, yyyy')}
          </H5>
          <M className={styles.notificationTime}>
            {`${date.toFormat('hh:mm a')} (${date.toFormat('ZZZZ')})`}
          </M>
        </Flex>
        <Pill size="medium" className={styles.customPill} {...pillProps} />
      </Flex>
      <MultiLanguageText model={notification} channel={channel} column="title">
        {({ labelMaker, textMaker }) => {
          return (
            <Flex
              direction="column"
              justify="flex-start"
              className={styles.notificationMessageWrapper}
            >
              {labelMaker({ label: t(TRANSLATION_KEYS.notificationMessage) })}
              {textMaker()}
            </Flex>
          );
        }}
      </MultiLanguageText>
      {status === NotificationStatus.SCHEDULED && (
        <Flex direction="row" justify="flex-start">
          <Button
            dataCy="removeScheduled"
            className={styles.removeButton}
            onClick={() =>
              removeNotification && removeNotification(notification)
            }
          >
            {t(TRANSLATION_KEYS.deleteButton)}
          </Button>
        </Flex>
      )}
    </div>
  );
}
