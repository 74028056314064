import { useEffect } from 'react';

import {
  ApolloError,
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { getClient } from '../apollo';
import { getGroupRole } from '../graphql/query';
import { GroupRole } from '../types/GroupRole';

export default function useGroupRoleQuery({
  groupRoleId,
}: any): {
  refetch: LazyQueryExecFunction<any, OperationVariables>;
  groupRole: GroupRole;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const [fetchGroupRole, { data, loading, error }] = useLazyQuery(
    getGroupRole,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (groupRoleId) {
      fetchGroupRole({
        variables: {
          id: groupRoleId,
        },
      });
    }
  }, [groupRoleId]);

  const groupRole = groupRoleId ? data?.groupRole : null;

  return {
    refetch: fetchGroupRole,
    groupRole,
    loading,
    error,
  };
}
