import React from 'react';

import { useTranslation } from 'react-i18next';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

import { M } from 'components/typography';

import useUserLocale from 'hooks/useUserLocale';

import styles from './StandardContentCard.scss';

type Props = {
  isFree?: boolean;
  totalAmount?: number;
  unitTime: string;
  amount: { currency?: string } | undefined;
};

export default function PriceLabel({
  isFree,
  totalAmount,
  unitTime,
  amount,
}: Props) {
  const locale = useUserLocale();
  const { t } = useTranslation();

  const formatter = amount
    ? currencyFormatter({
        locale,
        currency: amount?.currency,
      })
    : null;

  const unitTimeLabel =
    !unitTime || unitTime === 'unknown' ? '' : `/${unitTime}`;

  return (
    <M className={styles.price}>
      {isFree
        ? t('Free')
        : `${
            formatter && formatter(totalAmount! / CURRENCY_CONVERSION)
          }${unitTimeLabel}`}
    </M>
  );
}
