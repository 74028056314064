import React from 'react';

import Features from 'lane-shared/renderers/v5/features';
import { EssensysProductFeatureProperties } from 'lane-shared/types/features/EssensysProductFeatureProperties';

import { FeatureRendererPropsType } from '../FeatureRendererPropsType';
import PropertyRenderer from '../components/PropertyRenderer';

export default function EssensysPointOfSaleProduct(
  props: FeatureRendererPropsType<EssensysProductFeatureProperties>
) {
  return (
    <PropertyRenderer
      {...props}
      properties={Features.EssensysProduct.properties!}
    />
  );
}
