import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { parseDate, parseDateTime } from 'lane-shared/helpers/dates';
import TimeRange from 'lane-shared/properties/baseTypes/TimeRange';
import UnavailableDateAvailability from 'lane-shared/renderers/v5/features/types/UnavailableDateAvailability';
import WeeklyAvailability from 'lane-shared/renderers/v5/features/types/WeeklyAvailability';
import { FeatureInstance } from 'lane-shared/types/FeatureInstance';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { TimeRangeType } from 'lane-shared/types/baseTypes/TimeRangeType';
import {
  TimeAvailabilityDayRule,
  TimeAvailabilityFeatureProperties,
  UnavailableDateAvailability as UnavailableDateAvailabilityType,
  WeeklyAvailability as WeeklyAvailabilityType,
} from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

function sortUnavailableDateRanges(
  a: UnavailableDateAvailabilityType,
  b: UnavailableDateAvailabilityType
) {
  if (!(a.dateRange.startDate && b.dateRange.startDate)) {
    return -1;
  }

  return (
    parseDate(a.dateRange.startDate)!.getTime() -
    parseDate(b.dateRange.startDate)!.getTime()
  );
}

type Props = {
  contentFeature:
    | FeatureInstance<TimeAvailabilityFeatureProperties>
    | undefined
    | null;
  onFeatureUpdated: (
    feature: Partial<TimeAvailabilityFeatureProperties>
  ) => void;
  timeZone?: string;
};

export default function useTimeAvailabilityInput({
  contentFeature,
  onFeatureUpdated,
  timeZone,
}: Props) {
  const allAvailabilities: WeeklyAvailabilityType[] =
    contentFeature?.feature?.availabilities || [];

  const targetedAvailabilities = allAvailabilities.filter(
    availability => !availability.allGroupRoles
  );

  const unavailableDates: UnavailableDateAvailabilityType[] = (
    contentFeature?.feature?.unavailableDates || []
  ).sort(sortUnavailableDateRanges);

  const defaultAvailability = allAvailabilities.find(
    weeklyAvailability => weeklyAvailability.allGroupRoles
  );

  const hasTargetedAvailability = targetedAvailabilities.length > 0;

  const hasExcludedAvailability = unavailableDates.length > 0;

  function onToggleOpen(
    availabilityId: LaneType.UUID,
    dayKey: string,
    isOpen: boolean
  ) {
    const ix = allAvailabilities.findIndex(
      availability => availability._id === availabilityId
    );

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    allAvailabilities[ix].weekTimeRules[dayKey].isOpen = isOpen;

    onFeatureUpdated({
      availabilities: [...allAvailabilities],
    });
  }

  function onTimeRangeSelect(
    availabilityId: LaneType.UUID,
    dayKey: string,
    timeRangeId: LaneType.UUID,
    timeRange: TimeRangeType
  ) {
    const ix = allAvailabilities.findIndex(
      availability => availability._id === availabilityId
    );

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const timeRangeIx = allAvailabilities[ix].weekTimeRules[
      dayKey
    ].timeRanges.findIndex((timeRange: any) => timeRange._id === timeRangeId);

    Object.assign(
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      allAvailabilities[ix].weekTimeRules[dayKey].timeRanges[timeRangeIx],
      timeRange
    );

    onFeatureUpdated({
      availabilities: [...allAvailabilities],
    });
  }

  function onAddTimeRange(availabilityId: LaneType.UUID, dayKey: string) {
    const ix = allAvailabilities.findIndex(
      availability => availability._id === availabilityId
    );

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    allAvailabilities[ix].weekTimeRules[dayKey].timeRanges.push({
      ...TimeRange.default,
      _id: uuid(),
    });

    onFeatureUpdated({
      availabilities: [...allAvailabilities],
    });
  }

  function onAllDayToggle(availabilityId: LaneType.UUID, dayKey: string) {
    const ix = allAvailabilities.findIndex(
      availability => availability._id === availabilityId
    );

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const dayRule = allAvailabilities[ix].weekTimeRules[
      dayKey
    ] as TimeAvailabilityDayRule;

    dayRule.isAvailableAllDay = !dayRule.isAvailableAllDay;

    if (!dayRule.timeRanges) {
      dayRule.timeRanges = [];
    }

    if (dayRule.timeRanges.length === 0) {
      onAddTimeRange(availabilityId, dayKey);
    } else {
      onFeatureUpdated({
        availabilities: [...allAvailabilities],
      });
    }
  }

  function onRemoveTimeRange(
    availabilityId: LaneType.UUID,
    dayKey: string,
    timeRangeId: LaneType.UUID
  ) {
    const ix = allAvailabilities.findIndex(
      availability => availability._id === availabilityId
    );

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    allAvailabilities[ix].weekTimeRules[dayKey].timeRanges = allAvailabilities[
      ix
    ].weekTimeRules[dayKey].timeRanges.filter(
      (timeRange: any) => timeRange._id !== timeRangeId
    );

    onFeatureUpdated({
      availabilities: [...allAvailabilities],
    });
  }

  function addNewAvailability(
    availability: Partial<WeeklyAvailabilityType> = { allGroupRoles: false }
  ) {
    onFeatureUpdated({
      availabilities: [
        ...allAvailabilities,
        {
          ...((WeeklyAvailability.default as unknown) as WeeklyAvailabilityType),
          ...availability,
        },
      ],
    });
  }

  function addNewUnavailableDate() {
    onFeatureUpdated({
      unavailableDates: [
        ...unavailableDates,
        {
          ...UnavailableDateAvailability.default,
          _id: uuid(),
          dateRange: {
            startDate: parseDateTime(new Date(), timeZone)!
              .startOf('day')
              .toJSDate(),
            endDate: parseDateTime(new Date(), timeZone)!
              .endOf('day')
              .toJSDate(),
          },
          allGroupRoles: true,
        },
      ],
    });
  }

  function removeAvailability(availabilityId: LaneType.UUID) {
    onFeatureUpdated({
      availabilities: allAvailabilities.filter(v => v._id !== availabilityId),
    });
  }

  function removeUnavailableDate(availabilityId: LaneType.UUID) {
    onFeatureUpdated({
      unavailableDates: unavailableDates.filter(v => v._id !== availabilityId),
    });
  }

  function toggleExcludedDates() {
    if (hasExcludedAvailability) {
      onFeatureUpdated({
        unavailableDates: [],
      });
    } else {
      addNewUnavailableDate();
    }
  }

  function toggleTargetedDates() {
    if (hasTargetedAvailability) {
      onFeatureUpdated({
        availabilities: allAvailabilities.filter(
          availability => availability.allGroupRoles
        ),
      });
    } else {
      addNewAvailability();
    }
  }

  function onGroupRoleSelected(
    availabilityId: LaneType.UUID,
    groupRole: LaneType.GroupRole
  ) {
    const ix = allAvailabilities.findIndex(
      availability => availability._id === availabilityId
    );

    allAvailabilities[ix].groupRole = groupRole
      ? { _id: groupRole._id }
      : undefined;

    onFeatureUpdated({
      availabilities: [...allAvailabilities],
    });
  }

  function onAvailabilityAnytimeChange() {
    if (!defaultAvailability) {
      onFeatureUpdated({
        availabilities: [
          ...allAvailabilities,
          (WeeklyAvailability.default as unknown) as WeeklyAvailabilityType,
        ],
      });
    } else {
      defaultAvailability.isAvailableAnytime = !defaultAvailability.isAvailableAnytime;

      onFeatureUpdated({ availabilities: [...allAvailabilities] });
    }
  }

  async function updateDateRange(dateRange: DateRangeType, _id: string) {
    onFeatureUpdated({
      unavailableDates: unavailableDates.map(ud => {
        if (ud._id === _id) {
          return {
            ...ud,
            dateRange: {
              startDate: parseDateTime(dateRange!.startDate, timeZone)!
                .startOf('day')
                .toJSDate(),
              endDate: parseDateTime(dateRange!.endDate, timeZone)!
                .endOf('day')
                .toJSDate(),
            },
          };
        }

        return ud;
      }),
    });
  }

  return {
    hasTargetedAvailability,
    hasExcludedAvailability,
    defaultAvailability,
    allAvailabilities,
    targetedAvailabilities,
    unavailableDates,
    onToggleOpen,
    onTimeRangeSelect,
    onAddTimeRange,
    onAllDayToggle,
    onRemoveTimeRange,
    addNewUnavailableDate,
    addNewAvailability,
    removeAvailability,
    removeUnavailableDate,
    toggleExcludedDates,
    toggleTargetedDates,
    onGroupRoleSelected,
    onAvailabilityAnytimeChange,
    updateDateRange,
  };
}
