import { request, Res } from './common';

export interface ListParams {
  building_id: string;
}

export type ListResData = Floor[];

export interface Floor {
  id: string;
  name: string;
}

export async function list(
  token: string,
  params: ListParams
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    path: `/buildings/${params.building_id}/floors`,
  });
}
