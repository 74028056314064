import React from 'react';

import type { i18n } from 'i18next';
import { z } from 'zod';
import {
  MjmlSection,
  MjmlColumn,
  MjmlText,
} from 'lane-shared/emails/MjmlComponentsV2';

import {
  buildingSchema,
  changeDetailsSchema,
  visitorPassSchema,
} from '../schema';

export function EmailVisitorUpdateSummary({
  i18n,
  visitorPass,
  building,
  oldVisitorPassData,
}: {
  i18n: i18n;
  building: z.infer<typeof buildingSchema>;
  visitorPass: z.infer<typeof visitorPassSchema>;
  oldVisitorPassData: z.infer<typeof changeDetailsSchema>;
}) {
  const isDateUpdated = Boolean(
    oldVisitorPassData?.startDate || oldVisitorPassData?.endDate
  );
  const isTimeUpdated = Boolean(
    oldVisitorPassData?.startTime || oldVisitorPassData?.endTime
  );

  return (
    <>
      <MjmlSection background-color="#FFFFFF" padding="0">
        <MjmlColumn>
          <MjmlText>
            <b>
              {i18n.t(
                'shared.email.visitorManagement.visitorUpdate.title.timeWindow'
              )}
            </b>
            {isTimeUpdated && (
              <span
                style={{
                  color: '#0F121F',
                  borderRadius: '24px',
                  border: '1px solid #AFEEBB',
                  backgroundColor: '#E9FCEE',
                  width: '75px',
                  fontSize: '14px',
                  lineHeight: '28px',
                  marginLeft: '8px',
                  textAlign: 'center',
                  display: 'inline-block',
                }}
              >
                {i18n.t('Updated')}
              </span>
            )}
            <br />
            {visitorPass.startTime} {i18n.t('punctuation.dash')}{' '}
            {visitorPass.endTime} {building.timezone}
            {isTimeUpdated && (
              <>
                <br />
                <span
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  {oldVisitorPassData?.startTime || visitorPass.startTime}{' '}
                  {i18n.t('punctuation.dash')}{' '}
                  {oldVisitorPassData?.endTime || visitorPass.endTime}{' '}
                  {building.timezone}
                </span>
              </>
            )}
          </MjmlText>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection background-color="#FFFFFF" padding="0">
        <MjmlColumn>
          <MjmlText>
            <b>
              {i18n.t(
                'shared.email.visitorManagement.visitorUpdate.title.date'
              )}
            </b>
            {isDateUpdated && (
              <span
                style={{
                  color: '#0F121F',
                  borderRadius: '24px',
                  border: '1px solid #AFEEBB',
                  backgroundColor: '#E9FCEE',
                  width: '75px',
                  fontSize: '14px',
                  lineHeight: '28px',
                  marginLeft: '8px',
                  textAlign: 'center',
                  display: 'inline-block',
                }}
              >
                {i18n.t('Updated')}
              </span>
            )}
            <br />
            {visitorPass.endDate
              ? i18n.t('startdate.to.enddate', {
                  startDate: visitorPass.startDate,
                  endDate: visitorPass.endDate,
                })
              : visitorPass.startDate}
            {isDateUpdated && (
              <>
                <br />
                <span
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  {oldVisitorPassData?.endDate || visitorPass?.endDate
                    ? i18n.t('startdate.to.enddate', {
                        startDate:
                          oldVisitorPassData?.startDate ||
                          visitorPass.startDate,
                        endDate:
                          oldVisitorPassData?.endDate || visitorPass?.endDate,
                      })
                    : oldVisitorPassData?.startDate || visitorPass.startDate}
                </span>
              </>
            )}
          </MjmlText>
        </MjmlColumn>
      </MjmlSection>
    </>
  );
}
