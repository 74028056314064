import gql from 'graphql-tag';

// TODO, Create simpler input type instead of UserContentInteractionInput

export default gql`
  mutation updateRSVPUserContentInteraction(
    $interaction: UserContentInteractionInput!
  ) {
    updateContentInteraction(interaction: $interaction) {
      _id
      _updated
      data
      status
    }
  }
`;
