import React from 'react';

type Props = {
  src: string;
  alt: string;
  style?: React.CSSProperties;
  className?: string;
  otherProps?: any;
};

function Image({ src, alt, style, className, otherProps }: Props) {
  return (
    <img
      src={src}
      alt={alt}
      style={style}
      className={className}
      {...otherProps}
    />
  );
}

export default React.memo(Image);
