import Storage from '../../Storage';
import { COOKIE_TOKEN_NAME } from '../../constants/integrations/BuildingEnginesClassic';

type Data = {
  user: string;
};

export async function getToken() {
  let token;
  try {
    token = (await Storage.getItem(COOKIE_TOKEN_NAME)) as Data;
  } catch (err) {
    return null;
  }

  if (token?.user) {
    const part = token.user.split('.')[1];
    if (part) {
      const info = JSON.parse(atob(part));
      const expires = new Date(info.exp * 1000);
      if (expires > new Date()) {
        return token;
      }
    }

    await Storage.removeItem(COOKIE_TOKEN_NAME);
  }

  return null;
}

export async function storeToken(tokens: any) {
  await Storage.setItem(COOKIE_TOKEN_NAME, tokens);
}

export async function removeToken() {
  await Storage.removeItem(COOKIE_TOKEN_NAME);
}
