import React from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { DatePickerButton, TimePicker } from 'design-system-web';

import styles from './index.scss';
import { useContainerLayoutListener } from '../../../hooks';

type TimeOption = {
  date: Date;
  disabled?: boolean;
};

export type DateRange = {
  startDate: Date;
  endDate: Date;
} | null;

interface Props {
  referenceDate: Date;
  dateRange: DateRange;
  timeZone?: string;
  startTimes: TimeOption[];
  endTimes: TimeOption[];
  onDateChange: (date: Date) => void;
  onTimeChange: (range: DateRange) => void;
  disabledWeekDays?: number[];
  unavailableDateRanges?: DateRangeType[];
  emptyComponent?: React.ReactNode;
  reservableRange?: {
    minDate?: Date;
    maxDate?: Date;
  };
}

const TRANSLATION_KEYS = {
  date: 'shared.common.form.dateRangeInput.date',
  startTime: 'shared.common.form.dateRangeInput.startTime',
  endTime: 'shared.common.form.dateRangeInput.endTime',
};

const MOBILE_WIDTH_PX = 450;

export default function DateRangeInput({
  dateRange,
  referenceDate,
  timeZone = 'UTC',
  startTimes,
  endTimes,
  onDateChange,
  onTimeChange,
  unavailableDateRanges,
  disabledWeekDays,
  emptyComponent,
  reservableRange,
}: Props) {
  const { t } = useTranslation();
  // const [startDate, setStartDate] = useState(dateRange?.startDate);
  // const [endDate, setEndDate] = useState(dateRange?.endDate);

  // useEffect(() => {
  //   setStartDate(dateRange.startDate);
  //   setEndDate(dateRange.endDate);
  // }, [dateRange.startDate, dateRange.endDate]);

  const { containerRef, isBelowThreshold } = useContainerLayoutListener(
    MOBILE_WIDTH_PX
  );
  const handleDateChange = (date: Date) => {
    onDateChange(
      DateTime.fromJSDate(date).setZone(timeZone).startOf('day').toJSDate()
    );
  };

  const handleStartDateChange = (isoDate: string) => {
    if (!dateRange) {
      return;
    }

    const selectedStartDate = new Date(isoDate);

    onTimeChange({
      startDate: selectedStartDate,
      endDate: dateRange.endDate,
    });
  };

  const handleEndDateChange = (isoDate: string) => {
    if (!dateRange) {
      return;
    }

    const selectedEndDate = new Date(isoDate);

    onTimeChange({
      startDate: dateRange.startDate,
      endDate: selectedEndDate,
    });
  };

  return (
    <>
      <div
        ref={containerRef}
        className={isBelowThreshold ? styles.mobileLayout : styles.container}
      >
        <DatePickerButton
          disablePastDays
          dateInputLabel="Date"
          className={styles.date}
          wrapperClassName={styles.fullWidth}
          buttonClassName={styles.fullWidth}
          value={referenceDate}
          timeZone={timeZone}
          onChange={handleDateChange}
          disabledWeekDays={disabledWeekDays}
          unavailableDateRanges={unavailableDateRanges}
          minDate={reservableRange?.minDate}
          maxDate={reservableRange?.maxDate}
        />
        {!emptyComponent && (
          <>
            <TimePicker
              className={styles.startTime}
              buttonClassName={styles.fullWidth}
              value={dateRange?.startDate}
              label={t(TRANSLATION_KEYS.startTime)}
              onChange={handleStartDateChange}
              times={startTimes}
              showClear={false}
              showTimezone={false}
              timeZone={timeZone}
            />
            <TimePicker
              className={styles.endTime}
              buttonClassName={styles.fullWidth}
              label={t(TRANSLATION_KEYS.endTime)}
              value={dateRange?.endDate}
              onChange={handleEndDateChange}
              times={endTimes}
              showClear={false}
              showTimezone={false}
              timeZone={timeZone}
            />
          </>
        )}
      </div>
      {emptyComponent && (
        <div className={styles.emptyComponentContainer}>{emptyComponent}</div>
      )}
    </>
  );
}
