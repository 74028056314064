import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  latitude: {
    friendlyName: 'Latitude',
    type: 'Number',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: -90,
      },
      {
        name: 'Max',
        value: 90,
      },
    ],
  },
  longitude: {
    friendlyName: 'Longitude',
    type: 'Number',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: -180,
      },
      {
        name: 'Max',
        value: 180,
      },
    ],
  },
};

export class LocationType extends ObjectTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'Location';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  superUserOnly = true;

  friendlyName = 'Geo Location';

  get example() {
    return {
      latitude: -90 + Math.random() * 180,
      longitude: -180 + Math.random() * 360,
    };
  }

  get default(): any {
    return {
      latitude: 0,
      longitude: 0,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new LocationType();

Types.addBaseType(definition);
export default definition;
