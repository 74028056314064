import React from 'react';

import { ContentType } from 'lane-shared/types/content/Content';

import Login from '../BuildingEnginesPrism/Login';
import VisitorsContainer from './components/VisitorsContainer';

type Props = {
  content: ContentType;
};

export default function BuildingEnginesPrismVisitors({ content }: Props) {
  return (
    <Login content={content}>
      <VisitorsContainer content={content} />
    </Login>
  );
}
