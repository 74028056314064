import { useEffect } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import getEssensysInvoice from '../graphql/essensys/getEssensysInvoice';
import getEssensysInvoicePdf from '../graphql/essensys/getEssensysInvoicePdf';
import { POLL_INTERVAL } from '../helpers/constants';
import { INVOICE_DATE } from '../helpers/constants/dates';
import { ESSENSYS_DEFAULT_INVOICE } from '../helpers/constants/integrations/Essensys';
import { dateFormatter } from '../helpers/formatters';
import { EssensysInvoiceClientType } from '../types/Essensys';

export default function useEssensysInvoice({
  invoiceId,
}: any): {
  invoice: EssensysInvoiceClientType;
  pdfUrl: string;
  formattedStartDate: string;
  formattedEndDate: string;
  loading: boolean;
  error?: Error;
} {
  const { data, loading, error } = useQuery(getEssensysInvoice, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    pollInterval: POLL_INTERVAL,
    skip: !invoiceId,
    variables: {
      invoiceId,
    },
  });

  const [fetchInvoicePdfUrl, invoicePdfUrlResult] = useLazyQuery(
    getEssensysInvoicePdf,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (invoiceId) {
      fetchInvoicePdfUrl({ variables: { invoiceId } });
    }
  }, [invoiceId]);

  const invoice: EssensysInvoiceClientType =
    data?.essensys?.invoice || ESSENSYS_DEFAULT_INVOICE;

  const pdfUrl = invoicePdfUrlResult?.data?.essensys?.invoicePdfUrl;

  const formattedStartDate = dateFormatter(invoice?.startDate, INVOICE_DATE);
  const formattedEndDate = dateFormatter(invoice?.endDate, INVOICE_DATE);

  return {
    invoice,
    loading,
    error,
    pdfUrl,
    formattedEndDate,
    formattedStartDate,
  };
}
