import { ContentCategoryEnum } from '../types/content/ContentCategoryEnum';
import { PaymentProviderEnum } from '../types/payment/PaymentProviderEnum';
import useMyChannelsPaymentAccountsQuery from './useMyChannelsPaymentAccountsQuery';
import useMyPaymentAccountsQuery from './useMyPaymentAccountsQuery';

type Props = {
  timeZone: string | undefined;
  contentCategory: ContentCategoryEnum;
  paymentDetails: { total: number | undefined };
};

export default function useMyAvailablePaymentAccounts({
  timeZone,
  contentCategory,
  paymentDetails,
}: Props) {
  const {
    myPaymentAccounts,
    loading: loadingMyPaymentAccounts,
    previousPaymentAccountId,
  } = useMyPaymentAccountsQuery();

  const {
    myChannelsPaymentAccounts,
    loading: loadingMyChannelsPaymentAccounts,
  } = useMyChannelsPaymentAccountsQuery({
    paymentProvider: PaymentProviderEnum.Cash,
    timeZone,
    contentCategory,
    purchasePrice: paymentDetails?.total || 0,
  });

  return {
    paymentAccounts: [...myPaymentAccounts, ...myChannelsPaymentAccounts],
    loading: loadingMyPaymentAccounts || loadingMyChannelsPaymentAccounts,
    previousPaymentAccountId,
  };
}
