import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';

import { LaneType } from 'common-types';
import Types from '../../../../properties/Types';
import GeoPolygon from '../../../../properties/baseTypes/GeoPolygon';
import UUID from '../../../../properties/baseTypes/UUID';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { GeoPolygonType } from '../../../../types/baseTypes/GeoTypes';
import { PropertiesInterface } from '../../../../types/properties/Property';

export type FloorMapType = {
  _id: LaneType.UUID;
  _updated: LaneType.DateTime;
  _order: number;
  name: string;
  floorPlan: LaneType.UUID;
  placement: GeoPolygonType;
  sectionIds: LaneType.UUID[];
};

const properties: PropertiesInterface = {
  _id: {
    type: 'String',
  },
  _updated: {
    type: 'DateTime',
    friendlyName: 'Last Update',
  },
  _order: {
    type: 'Number',
    friendlyName: 'Order',
    validators: [
      {
        name: 'Max',
        value: 63,
      },
      {
        name: 'Min',
        value: 0,
      },
    ],
  },
  name: {
    type: 'String',
    friendlyName: 'Floor Name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 64,
      },
      {
        name: 'Min',
        value: 1,
      },
    ],
  },
  floorPlan: {
    type: 'Image',
    friendlyName: 'Floor Plan Image',
    validators: [],
  },
  sectionIds: {
    type: 'String',
    isArray: true,
    default: [],
    friendlyName: 'Section IDs',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 1,
      },
    ],
  },
  placement: {
    type: 'GeoPolygon',
    friendlyName: 'Floor Plan Placement',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class FloorMap extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'FloorMap';

  friendlyName = 'Floor Map';

  superUserOnly = false;

  contexts = [];

  get example(): any {
    return {
      _id: UUID.example,
      _order: 0,
      _updated: new Date(),
      name: 'Example Floor',
      floorPlan: null,
      placement: GeoPolygon.example,
      sectionIds: [],
    };
  }

  get default(): any {
    return {
      _id: UUID.default,
      _order: 0,
      _updated: new Date(),
      name: '',
      floorPlan: null,
      placement: GeoPolygon.default,
      sectionIds: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new FloorMap();

Types.addType(definition);
export default definition;
