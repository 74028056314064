import { Primitive } from '../../../types/blocks/Primitive';

const RequirementsBlock: Primitive = {
  name: 'RequirementsBlock',
  friendlyName: 'Requirements',
  isInput: false,
  isPrimitive: true,
  properties: {},
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default RequirementsBlock;
