import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { RequirementFeatureContentInteractionProperties } from 'lane-shared/types/features/RequirementsFeatureProperties';

import RequirementRow from 'components/renderers/v5/primitives/RequirementsBlock/RequirementRow';

type Props = {
  contentInteractionRequired: RequirementFeatureContentInteractionProperties[];
  requirementsMet: Record<string, boolean>;
};

export default function ContentInteractionRequirementRows({
  contentInteractionRequired,
  requirementsMet,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  if (!contentInteractionRequired.length) {
    return null;
  }

  return (
    <>
      {contentInteractionRequired.map(requirement => {
        if (requirementsMet?.[requirement.content?._id]) {
          return null;
        }
        return (
          <RequirementRow
            header={t(requirement.header)}
            description={t(requirement.description)}
            key={requirement.header}
            onClick={() => {
              if (requirement?.content) {
                history.push({
                  pathname: routes.content.replace(
                    ':id',
                    requirement?.content._id
                  ),
                  state: { from: location.pathname },
                });
              }
            }}
            buttonLabel={t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.add'
            )}
          />
        );
      })}
    </>
  );
}
