// https://viewthespace.atlassian.net/browse/TM-9892
// To Do: Fix the above ticket and uncomment the Frequency.
// import { Frequency } from 'rrule';
import { ValidationError } from 'yup';

import { IssueType } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/issueTypes';
import {
  ContactPerson,
  EmailRecipient,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/visitors';
import { FloorWithSpaces } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/requestHelpers';

export type VisitorType = ContactPerson & {
  deletable?: boolean;
};

export enum visitorStatus {
  Canceled = 'CANCELED',
  Scheduled = 'SCHEDULED',
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
}

export const VISIT_TYPE = 'GUEST';

export const dataOptions = [
  { id: '1', text: 'All Day' },
  { id: '2', text: 'Time Range' },
];

export type VisitType = {
  organizationId: string;
  spaceId: string;
  isTimeRange: boolean;
  startDate: Date | null;
  instructions: string;
  endDate?: Date | null;
  startTime?: string;
  endTime?: string;
  emailRecipients?: Array<EmailRecipient>;
};

export type VisitEditType = {
  organizationId?: string;
  spaceId?: string;
  isTimeRange?: boolean;
  startDate?: Date;
  instructions?: string;
  endDate?: Date;
  startTime?: string;
  endTime?: string;
  frequency?: string;
  isRecurring?: boolean;
  recurringEnds?: string;
  occurance?: number;
  guard_instructions?: string;
  emailRecipients?: Array<EmailRecipient>;
};

export interface SpacesResponseType {
  issue_types: IssueType[];
  floors: FloorWithSpaces[];
}

export interface DropdownValueProp {
  value: string;
  label: string;
}

export type VisitorProps = {
  visitor: VisitorType;
  index: number;
  showRemove: boolean;
  update: (visitor: VisitorType) => void;
  removeVisitor: (visitor: VisitorType) => void;
  hasAttemptedSubmit: boolean;
  isEditableVisitor: boolean;
  validationErrors: ValidationError[];
};

export const STATUS_MAP: { [key: string]: { label: string; color: string } } = {
  SCHEDULED: { label: 'Scheduled', color: 'honey' },
  CHECKED_IN: { label: 'Checked-In', color: 'green' },
  CHECKED_OUT: { label: 'Checked-Out', color: 'gray' },
  CANCELED: { label: 'Canceled', color: 'red' },
};
export enum FrequencyValue {
  // These have been temporarily replaced with their enum values
  // Due to reference issues with rrule_1 in tests
  // https://viewthespace.atlassian.net/browse/TM-9892
  //
  // Ticket link
  // 'Daily' = Frequency.DAILY,
  // 'WorkDays' = Frequency.DAILY,
  // 'Weekly' = Frequency.WEEKLY,
  // 'Monthly' = Frequency.MONTHLY,
  // 'Quarterly' = Frequency.MONTHLY,
  // 'Annual' = Frequency.YEARLY,
  'Daily' = 3,
  'WorkDays' = 3,
  'Weekly' = 2,
  'Monthly' = 1,
  'Quarterly' = 1,
  'Annual' = 0,
}

export interface rRuleOptions {
  freq: number;
  startDate: Date;
  endDate: Date | null;
  occurance: number | null;
  weekDays?: boolean;
  interval?: number;
}

export enum recurringEndsEnum {
  Never = 'never',
  SpecificDate = 'specificDate',
  Occurance = 'occurance',
}

export const recurringEndsValue: DropdownValueProp[] = [
  { label: 'Never', value: 'never' },
  { label: 'On (Specific Date)', value: 'specificDate' },
  { label: 'After (# of occurance)', value: 'occurance' },
];

export const recurringFrequency: DropdownValueProp[] = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Work Days', value: 'WorkDays' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Annual', value: 'Annual' },
];

export const emailRegex = /@.+$/;
