import { useEffect } from 'react';

import { useChannelAdminContext } from 'hooks';

import { useLazyQuery } from '@apollo/client';

import { ProductType } from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
  GroupType,
  ListProductExceptionsResponse,
} from 'graphql-query-contracts';

import { productExceptionsListQuery } from './productsServicesQueries';

interface ListProductExceptionsParams {
  page: number;
  pageSize: number;
  productType?: ProductType;
  productCategoryId?: String;
}

export function useProductExceptionsListQuery({
  page,
  pageSize,
}: ListProductExceptionsParams) {
  const { channel } = useChannelAdminContext();

  const [
    getProductExceptionsList,
    productExceptionsListResponse,
  ] = useLazyQuery<{
    listProductExceptions: ListProductExceptionsResponse;
  }>(productExceptionsListQuery);

  useEffect(() => {
    if (channel?._id) {
      getProductExceptionsList({
        variables: {
          listProductExceptionsRequest: {
            filter: {
              group: {
                id: convertToUUID(channel._id),
                type: GroupType.GroupTypeActivateChannel,
              },
            },
            pagination: {
              start: page * pageSize,
              perPage: pageSize,
            },
          },
        },
      });
    }
  }, [channel, page, pageSize, getProductExceptionsList]);

  return productExceptionsListResponse;
}
