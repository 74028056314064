import { DateTime } from 'luxon';

export function applyTimeStringToDateTime(
  time: string,
  referenceDateTime: DateTime
) {
  const parsedTime = DateTime.fromFormat(time, 'h:mm a', {
    zone: referenceDateTime.zone,
  });

  return referenceDateTime.set({
    hour: parsedTime.hour,
    minute: parsedTime.minute,
    second: parsedTime.second,
    millisecond: parsedTime.millisecond,
  });
}
