import { ChipStyle } from 'lane-web/src/components/ads/chipSelect/types';
import type { ChipListItem } from 'lane-web/src/components/ads/chipSelect/types';

const AccessLogStatusItemSuccess: ChipListItem = {
  label: 'web.admin.hardwareManagement.accessLogStatus.success',
  value: String(true),
  type: ChipStyle.Green,
};

const AccessLogStatusItemFail: ChipListItem = {
  label: 'web.admin.hardwareManagement.accessLogStatus.fail',
  value: String(false),
  type: ChipStyle.Red,
};

export function getAccessLogStatusItem(accessSuccess: boolean): ChipListItem {
  return accessSuccess ? AccessLogStatusItemSuccess : AccessLogStatusItemFail;
}
