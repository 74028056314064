import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';

import { VisitorPassQueryResponse } from 'lane-shared/domains/visitorManagement/types';
import {
  updateVisitorPassStatus,
  getVisitorPassDetailsById,
} from 'lane-shared/graphql/visitorManagement';

const getVisitorName = (pass: VisitorPassQueryResponse) => {
  let visitorName = `${pass.visitor.firstName}`;
  if (pass.visitor.lastName) {
    visitorName += ` ${pass.visitor.lastName}`;
  }

  return visitorName;
};

export const useStatusSelector = () => {
  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const [loadingStates, setLoadingStates] = useState<{
    [passId: string]: boolean;
  }>({});

  const updateLoadingState = (passId: string, isLoading: boolean) => {
    setLoadingStates(existingLoadingStates => {
      return {
        ...existingLoadingStates,
        [passId]: isLoading,
      };
    });
  };

  const handleUpdateStatus = async (
    status: string,
    pass: VisitorPassQueryResponse
  ) => {
    try {
      updateLoadingState(pass.id, true);

      // update mutation
      await apolloClient.mutate({
        mutation: updateVisitorPassStatus,
        variables: {
          visitorPassId: pass.id,
          status,
        },
      });

      // query latest changes
      await apolloClient.query({
        query: getVisitorPassDetailsById,
        variables: {
          id: pass.id,
          channelId: pass.accessGrantBuildingId,
        },
        fetchPolicy: 'network-only', // automatically updates the cache using the typename and id in the result
      });

      updateLoadingState(pass.id, false);
    } catch (error) {
      updateLoadingState(pass.id, false);
      const visitorName = getVisitorName(pass);
      const errorMessage = t(
        `error: status update failed for visitor '${visitorName}'`
      );

      throw new Error(errorMessage);
    }
  };

  return {
    loadingStates,
    updateStatus: handleUpdateStatus,
    updateLoadingState,
  };
};
