import React from 'react';

import cx from 'classnames';
import { Dropdown } from 'components';
import { useTranslation } from 'react-i18next';

import { scheduleInAdvanceOptions } from 'lane-shared/domains/visitorManagement/features/VisitorManagementFeatureValues';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import FeatureOptionToggle from 'components/renderers/features/components/FeatureOptionToggle';
import { H5, S } from 'components/typography';

import styles from './VisitorSchedulerSettings.scss';

export default function VisitorSchedulerSettings({
  feature,
  settings,
  className,
  style,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  settings: VisitorManagementFeatureProperties;
  onFeatureUpdated: (
    feature: Partial<VisitorManagementFeatureProperties>
  ) => void;
  className?: string;
  style?: React.CSSProperties;
}) {
  const { t } = useTranslation();
  const dateRangeEnabled = useFlag(
    FeatureFlag.VisitorManagementDateRange,
    false
  );

  const customDatesEnabled = useFlag(
    FeatureFlag.VisitorManagementCustomDates,
    false
  );

  const repeatEnabled = useFlag(FeatureFlag.VisitorManagementRepeat, false);

  const allDayPassEnabled = useFlag(
    FeatureFlag.VisitorManagementAllDayPass,
    false
  );

  if (!feature.properties) return null;

  const properties = feature.properties;

  const advanceAmount = scheduleInAdvanceOptions
    .find(option => option.value === settings.scheduleInAdvanceAmount)
    ?.label.toLocaleLowerCase();

  return (
    <div
      className={cx(styles.visitorSchedulerSettings, className)}
      style={style}
    >
      {allDayPassEnabled && (
        <FeatureOptionToggle
          title={t(properties.allDayPassEnabled?.friendlyName!)}
          description={t(properties.allDayPassEnabled?.description!)}
          onChange={value =>
            onFeatureUpdated({
              ...settings,
              allDayPassEnabled: value,
            })
          }
          isExpanded={settings.allDayPassEnabled}
          disabled={!allDayPassEnabled}
          dataCy="allDayPass"
        />
      )}
      <FeatureOptionToggle
        title={t(properties.dateRangeEnabled?.friendlyName!)}
        description={t(properties.dateRangeEnabled?.description!)}
        onChange={value => {
          if (dateRangeEnabled) {
            onFeatureUpdated({
              ...settings,
              dateRangeEnabled: value,
            });
          }
        }}
        disabled={!dateRangeEnabled}
        isExpanded={settings.dateRangeEnabled}
        dataCy="dateRange"
      />
      <FeatureOptionToggle
        title={t(properties.customDatesEnabled?.friendlyName!)}
        description={t(properties.customDatesEnabled?.description!)}
        onChange={value => {
          if (customDatesEnabled) {
            onFeatureUpdated({
              ...settings,
              customDatesEnabled: value,
            });
          }
        }}
        isExpanded={settings.customDatesEnabled}
        disabled={!customDatesEnabled}
        dataCy="customDates"
      />
      <FeatureOptionToggle
        title={t(properties.recurringPassesEnabled?.friendlyName!)}
        description={t(properties.recurringPassesEnabled?.description!)}
        onChange={value => {
          if (repeatEnabled) {
            onFeatureUpdated({
              ...settings,
              recurringPassesEnabled: value,
            });
          }
        }}
        isExpanded={settings.recurringPassesEnabled}
        disabled={!repeatEnabled}
        dataCy="recurringPasses"
      />
      <FeatureOptionToggle
        title={t(properties.multiSchedulesEnabled?.friendlyName!)}
        description={t(properties.multiSchedulesEnabled?.description!)}
        onChange={() => {}} // To be implemented
        isExpanded={settings.multiSchedulesEnabled}
        disabled
        dataCy="multiSchedules"
      />
      <div className={styles.inAdvanceDesc}>
        <H5>{t(properties.scheduleInAdvanceAmount?.friendlyName!)}</H5>
        <S>{t(properties.scheduleInAdvanceAmount?.description!)}</S>
      </div>
      <Dropdown
        className={styles.inAdvanceDropdown}
        onValueChange={value => {
          onFeatureUpdated({
            ...settings,
            scheduleInAdvanceAmount: value,
          });
        }}
        items={scheduleInAdvanceOptions}
        value={settings.scheduleInAdvanceAmount}
        dataCy="scheduleInAdvance"
      />
      <S className={styles.inAdvanceSubText}>
        {t('web.content.feature.visitorManagement.scheduler.advance', {
          advanceScheduleMonths: advanceAmount,
        })}
      </S>
    </div>
  );
}
