import { DateTime, Interval } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import parseDateTime from '../dates/parseDateTime';

export default function isRangeUnavailable({
  minTime,
  maxTime,
  unavailableDateRanges,
  start,
  end,
}: {
  minTime: number;
  maxTime: number;
  unavailableDateRanges: DateRangeType[];
  start: DateTime;
  end: DateTime;
}) {
  const interval = Interval.fromDateTimes(start, end);

  const invalidFromRanges = unavailableDateRanges
    .map(range => ({
      startDate: parseDateTime(range.startDate) as DateTime,
      endDate: parseDateTime(range.endDate) as DateTime,
    }))
    .some(range =>
      Interval.fromDateTimes(range.startDate, range.endDate).overlaps(interval)
    );

  // check min and max times
  const startInterval = Interval.fromDateTimes(
    start.startOf('day'),
    start.startOf('day').plus({ minute: minTime })
  );

  const invalidFromStart = interval.overlaps(startInterval);

  const endInterval = Interval.fromDateTimes(
    start.startOf('day').plus({ minute: maxTime }),
    end.endOf('day')
  );

  const invalidFromEnd = interval.overlaps(endInterval);

  return invalidFromRanges || invalidFromStart || invalidFromEnd;
}
