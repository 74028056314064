import * as yup from 'yup';

import { ValidatorInterface } from '../../types/ValidatorInterface';
import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TIME } from '../constants';
import { ITypeValidatesGreaterThan } from '../validators/ITypeValidatesGreaterThan';
import { ITypeValidatesLessThan } from '../validators/ITypeValidatesLessThan';

export class TimeType
  extends StringTypeBase
  implements ITypeValidatesLessThan, ITypeValidatesGreaterThan {
  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
    TypeContextEnum.Metatag,
  ];

  group = TYPE_GROUP_TIME;

  name = 'Time';

  get example() {
    return '01:00 pm';
  }

  get default(): any {
    return '01:00 pm';
  }

  buildSchema() {
    return yup
      .string()
      .test('is-valid-time', '${path} is not a valid time.', () => {
        // if this is a valid time string, the dateFns parse will be successful.
        // todo: need a parser for this string format
        return true;
      });
  }

  addGreaterThanValidator(
    _: ValidatorInterface<ITypeValidatesGreaterThan>
  ): void {
    // TODO: https://linear.app/joinlane/issue/LANE-1468/time-base-type-does-not-validate-greater-than
  }

  addLessThanValidator(_: ValidatorInterface<ITypeValidatesLessThan>): void {
    // TODO: https://linear.app/joinlane/issue/LANE-1467/time-base-type-does-not-validate-less-than
  }
}

const definition = new TimeType();

Types.addBaseType(definition);
export default definition;
