import i18next from 'i18next';
import { DateTime } from 'luxon';

import parseDateTime from './parseDateTime';

/**
 * Measures a date (date) against a another point in time (referenceDate) in words.
 * If no referenceDate is supplied, it defaults to now.
 *
 * Examples,
 * date = 2020-06-01, referenceDate = 2020-06-15 => 'in 14 days'
 * date = 2020-06-15, referenceDate = 2020-06-01 =>'14 days ago'
 * date = 2020-06-15 12:30 AM, referenceDate = 2020-06-15: 12:00 AM  => 'in 30 mins'
 * date = 2020-06-15 12:00 AM, referenceDate = 2020-06-15: 12:30 AM  => '30 mins ago'
 *
 * @param date the date you want to know about
 * @param referenceDate the date to measure against as a reference, defaults to now
 * @param timeZone string the timezone the dates are in
 * @param translator the translator function from i18next to use to translate
 */
export default function friendlyDateDifference(
  date: string | Date | DateTime,
  referenceDate: string | Date | DateTime = DateTime.local(),
  timeZone?: string,
  translator: (key: string, params?: any) => string = i18next.t
): string {
  const _date = parseDateTime(date, timeZone)!;
  const _referenceDate = parseDateTime(referenceDate, timeZone)!;

  const isPast = _date < _referenceDate;

  const differenceInMinutes: number = Math.abs(
    _referenceDate.diff(_date, 'minutes').minutes
  );

  if (differenceInMinutes < 1) {
    return translator(isPast ? 'a min ago' : 'in a min');
  }

  if (differenceInMinutes < 30) {
    return translator(isPast ? '{{minutes} mins ago' : 'in {{minutes}} mins', {
      minutes: Math.round(differenceInMinutes),
    });
  }

  const differenceInHours: number = Math.abs(
    _referenceDate.diff(_date, 'hours').hours
  );

  if (differenceInHours < 24) {
    return translator(isPast ? '{{hours}} hrs ago' : 'in {{hours}} hrs', {
      hours: differenceInHours.toFixed(1),
    });
  }

  const differenceInDays: number = Math.abs(
    _referenceDate.diff(_date, 'days').days
  );

  if (differenceInDays < 28) {
    return translator(isPast ? '{{days}} days ago' : 'in {{days}} days', {
      days: Math.round(differenceInDays),
    });
  }

  const differenceInMonths: number = Math.abs(
    _referenceDate.diff(_date, 'months').months
  );

  if (differenceInMonths < 12) {
    return translator(
      isPast ? '{{months}} months ago' : 'in {{months}} months',
      {
        months: Math.round(differenceInMonths),
      }
    );
  }

  const differenceInYears: number = Math.abs(
    _referenceDate.diff(_date, 'years').years
  );

  return translator(isPast ? '{{years}} yrs ago' : 'in {{years}} yrs', {
    years: Math.round(differenceInYears),
  });
}
