import { useLocation } from 'react-router-dom';

import { config } from 'lane-shared/config';
import { parseUrl } from 'lane-shared/helpers/parseUrl';

export default function useRedirect() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectBaseString = queryParams.get(
    config.redirectUrlQueryParameterName
  );
  queryParams.delete(config.redirectUrlQueryParameterName);
  let queryString = queryParams.toString();
  if (queryString) {
    queryString = `?${queryString}`;
  }

  const redirectUrl = parseUrl(redirectBaseString);

  return {
    redirectString: redirectBaseString
      ? `${redirectBaseString}${queryString}`
      : undefined,
    isUrl: Boolean(redirectUrl),
    isSafeRedirect: isSafeRedirectUrl(redirectUrl) || !redirectUrl,
  };
}

export function isSafeRedirectUrl(url: URL | undefined): Boolean {
  if (url === undefined) return false;
  if (window.location.hostname === url.hostname) return true;
  return (
    url.href.search(new RegExp(`^https:\\/\\/app\\.joinlane\\.com\\/`)) !== -1
  );
}
