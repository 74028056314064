import React, { useEffect } from 'react';

import { Redirect, useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { config as sharedConfig, routes } from 'lane-shared/config';
import { constructFullAuth0SAMLUrl } from 'lane-shared/helpers/integrations/Auth0SAML';
import getContentChannelIntegration from 'lane-shared/helpers/integrations/getContentChannelIntegration';

import useIsAdminView from 'hooks/useIsAdminView';

export default function Auth0SamlWebviewPage({ content }: any) {
  const history = useHistory();
  const [isAdminView] = useIsAdminView();
  const { data, loading } = useQuery(getContentChannelIntegration, {
    variables: { id: content._id },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isAdminView || loading || !data) {
      return;
    }
    const url = constructFullAuth0SAMLUrl(data.me.content.integration._id);
    // open a new tab and go back on this tab
    window.open(url, '_blank');
    history.goBack();
  }, [loading]);

  if (!loading && !data) {
    return (
      <Redirect
        to={{
          pathname: `${routes.login}?${
            sharedConfig.redirectUrlQueryParameterName
          }=${routes.content.replace(':id', content._id)}`,
        }}
      />
    );
  }

  return null;
}
