import useFlag from './useFlag';

const FLAG = 'consumer-experience-onboarding-v2';

function useOnboardingV2Enabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}

export default useOnboardingV2Enabled;
