/**
 * Sorts by distance
 * @param a
 * @param b
 * @returns {number}
 */
export default function byDistance(a: any, b: any) {
  if (a.distance < b.distance) {
    return -1;
  }

  if (a.distance === b.distance) {
    return 0;
  }

  return 1;
}
