import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getChannelSections } from '../graphql/query';
import { POLL_INTERVAL } from '../helpers/constants';
import { useMultiLanguage } from './useMultiLanguage';

type Params = {
  channelId: string | null;
  slug?: string | null;
  pollInterval?: number;
};

export default function useChannelSectionsQuery({
  channelId,
  slug,
  pollInterval = POLL_INTERVAL,
}: Params) {
  const { translate } = useMultiLanguage();
  const [fetchSections, { data, loading, error }] = useLazyQuery(
    getChannelSections,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
      pollInterval,
      variables: {
        id: channelId,
        slug,
      },
    }
  );

  useEffect(() => {
    if (channelId || slug) {
      fetchSections({
        variables: {
          id: channelId,
          slug,
        },
      });
    }
  }, [channelId, slug]);

  const sections = translate({
    model: data?.channel?.sections || [],
    columns: ['name'],
  });

  return {
    sections,
    loading,
    error,
  };
}
