import { DateTime, Interval } from 'luxon';

import {
  PaymentFeatureDayRuleType,
  PaymentFeatureRuleType,
} from '../../types/features/PaymentFeatureProperties';
import { SHORT_TIME } from '../constants/dates';
import { DAY_KEYS_BY_NUMBER } from '../constants/timeUnits';
import parseDateTime from '../dates/parseDateTime';
import getDateTimesFromTimeRange from './getDateTimesFromTimeRange';

const NON_RULE_PRICE_RATIO = 100;

function getPriceRatio(priceRatio: number | string) {
  if (typeof priceRatio !== 'number') {
    // historically some data in the db has '' priceRatio
    return NON_RULE_PRICE_RATIO;
  }

  return priceRatio;
}

export default function calculatePaymentAmount({
  baseAmount,
  rule,
  startDate,
  endDate,
  timeZone,
}: {
  baseAmount: number;
  rule: PaymentFeatureRuleType;
  startDate: Date;
  endDate: Date;
  timeZone: string;
}): number {
  const interactionStartDate = parseDateTime(startDate, timeZone) as DateTime;
  const interactionEndDate = parseDateTime(endDate, timeZone) as DateTime;

  const interactionRange = Interval.fromDateTimes(
    interactionStartDate,
    interactionEndDate
  );

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const dayKey = DAY_KEYS_BY_NUMBER[interactionStartDate.weekday];
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const dayRule: PaymentFeatureDayRuleType = rule.weekTimeRules[dayKey];

  if (!rule.weekTimeRulesEnabled && rule.groupRole._id !== 'any') {
    return baseAmount * (rule.priceRatio / NON_RULE_PRICE_RATIO);
  }

  // No applicable rule for this day.
  if (!dayRule) {
    return baseAmount;
  }

  const { isOpen, priceTimeRanges } = dayRule;

  // no applicable time ranges
  if (!isOpen) {
    return baseAmount;
  }

  // map all the rules to an applicable price
  const prices = priceTimeRanges.map(priceTimeRange => {
    const { start, end } = getDateTimesFromTimeRange({
      referenceDate: interactionStartDate,
      timeRange: {
        startTime: priceTimeRange.startTime,
        endTime: priceTimeRange.endTime,
      },
      includeEndingMillis: true,
      format: SHORT_TIME,
      timeZone,
    });

    const ruleRange = Interval.fromDateTimes(start, end);

    // the time range for this rule does not overlap with the interaction range, so it does not apply
    if (!ruleRange.overlaps(interactionRange)) {
      return null;
    }

    const rulePriceRatio = getPriceRatio(priceTimeRange.priceRatio);

    return baseAmount * (rulePriceRatio / NON_RULE_PRICE_RATIO);
  });

  // filter out null values
  const filteredPrices = prices.filter(price => price !== null) as number[];

  if (filteredPrices.length === 0) return baseAmount;

  // return the lowest applicable price
  return Math.min(...filteredPrices);
}
