/**
 * Takes an input string that is assumed to be a phone number and strips out any extra chars.

 * @param num {string|number}  the phone number to simplify.
 * @returns {string}
 */
export default function simplifyPhoneNumber(
  num: string | null | undefined
): string {
  if (!num) {
    return '';
  }

  return num.toString().replace(/[^0-9+]/g, '');
}
