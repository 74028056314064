import gql from 'graphql-tag';

export default gql`
  query getBuildingTenantChannels($channelId: UUID!) {
    getBuildingTenantChannels(channelId: $channelId) {
      channelId
      name
    }
  }
`;
