import React from 'react';

import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';

import RelatedSpacesCard from 'components/cards/RelatedSpacesCard';
import PaginatedTypeAhead from 'components/form/PaginatedTypeAhead';

import FeatureOptionToggle from '../components/FeatureOptionToggle';
import RelatedSpaceChildDiscrepancyError from './RelatedSpaceChildDiscrepancyError';
import useRelatedSpaces from './hooks/useRelatedSpacesList';

type Props = {
  title: string;
  tooltipDescription: string;
  disabled: boolean;
  onChange: (value: Partial<ReservableFeatureProperties>) => void;
  settings: ReservableFeatureProperties;
  content: any;
};

type RelatedSpace = {
  _id: LaneType.UUID;
  name: string;
};

export default function RelatedSpaces({
  title,
  tooltipDescription,
  disabled,
  onChange,
  settings,
  content,
}: Props) {
  const { t } = useTranslation();

  const handleOnChild = (relatedSpace: RelatedSpace) => {
    onChange({
      relatedSpaces: {
        childrenIds: [
          ...(settings?.relatedSpaces?.childrenIds || []),
          relatedSpace._id,
        ],
      },
    });
  };

  const {
    relatedSpaces,
    isLoading,
    loadNext,
    onSearchChange,
  } = useRelatedSpaces({ limit: 25, contentId: content._id });

  return (
    <FeatureOptionToggle
      title={title}
      disabled={disabled}
      data-testid="toggle-element"
      TooltipComponent={tooltipDescription}
      description={t(
        'web.content.features.reservable.RelatedSpaces.relatedSpaces.description'
      )}
      onChange={(value: boolean) =>
        onChange({ relatedSpaces: value ? {} : null })
      }
      isExpanded={
        settings?.relatedSpaces !== undefined &&
        settings?.relatedSpaces !== null
      }
      isAligned
    >
      {(settings?.relatedSpaces?.childrenIds || []).map(id => (
        <RelatedSpacesCard
          relatedSpaceId={id}
          key={id}
          onDelete={(value: LaneType.UUID) => {
            onChange({
              relatedSpaces: {
                childrenIds: settings?.relatedSpaces?.childrenIds?.filter(
                  childId => childId !== value
                ),
              },
            });
          }}
          Footer={({ content: child }) => {
            return (
              <RelatedSpaceChildDiscrepancyError
                parentContent={content}
                childContent={child}
              />
            );
          }}
        />
      ))}
      <PaginatedTypeAhead
        data={relatedSpaces}
        loadNext={loadNext}
        isLoading={isLoading}
        onSubmit={handleOnChild}
        onChange={onSearchChange}
        getOptionLabel={option => option.name}
        getOptionDisabled={option =>
          !!settings.relatedSpaces?.childrenIds?.includes(option._id)
        }
      />
    </FeatureOptionToggle>
  );
}
