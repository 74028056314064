import React from 'react';

import { OrderSummaryType } from 'lane-shared/domains/billingPayments/types';

import { OrderPreview } from './OrderPreview';
import { OrderSummaryDetails } from './OrderSummaryDetails';
import { OrderSummaryTitle } from './OrderSummaryTitle';

// @ts-ignore - issue with typescript resolving scss paths
import styles from './OrderSummaryContainer.scss';

interface OrderSummaryContainerProps {
  backgroundImage: string;
  orderSummaryData: OrderSummaryType;
}

/**
 * @deprecated 5.190.0 - use OrderSummaryWithProducts.
 */
export function OrderSummaryContainer({
  backgroundImage,
  orderSummaryData,
}: OrderSummaryContainerProps) {
  return (
    <div className={styles.orderSummaryContainer}>
      <OrderSummaryTitle />
      <OrderPreview
        backgroundImage={backgroundImage}
        orderSummary={orderSummaryData}
      />
      <div className={styles.divider} />
      <OrderSummaryDetails orderSummary={orderSummaryData} />
    </div>
  );
}
