import React, { forwardRef } from 'react';
import { useReservableManagementCalendarV2Enabled } from 'lane-shared/hooks/useReservableManagementCalendarV2Enabled';
import FullCalendar from '@fullcalendar/react';
import luxonPlugin from '@fullcalendar/luxon';
import { useHistory } from 'react-router-dom';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useIsAdminView } from 'hooks';
import { config } from 'lane-shared/config';
import useUserLocale from 'hooks/useUserLocale';
import {
  FullCalendarEvent,
  FullCalendarResource,
} from '../hooks/useReservableCalendarView';
import './fullcalendar.override.css';
import {
  ColumnHeader,
  isFullCalendarEvent,
  isFullCalendarEventInfo,
  EventContent,
} from './CalendarViews';
import { calendarSelectAllow } from './calendarSelectAllow';

type Props = {
  events: FullCalendarEvent[];
  resources: FullCalendarResource[];
  timeZone: string;
  refetchReservablesOnChannel: () => void;
};

export const CalendarView = forwardRef<FullCalendar, Props>(function (
  { events, resources, refetchReservablesOnChannel, timeZone },
  ref
) {
  const userLocale = useUserLocale();
  const history = useHistory();
  const isReservableCalendarV2Enabled = useReservableManagementCalendarV2Enabled();
  const [_, channelSlug] = useIsAdminView();

  return (
    <div className="rc-fc">
      <FullCalendar
        ref={ref}
        timeZone={timeZone}
        schedulerLicenseKey={config.fullCalendar.licenseKey}
        plugins={[
          resourceTimeGridPlugin,
          scrollGridPlugin,
          luxonPlugin,
          interactionPlugin,
        ]}
        initialView="resourceTimeGridDay"
        headerToolbar={false}
        resources={resources}
        eventContent={eventInfo => {
          if (!isFullCalendarEventInfo(eventInfo)) {
            return;
          }

          return EventContent({
            isDetailsEnabled: isReservableCalendarV2Enabled,
            eventInfo,
            onStatusUpdate: refetchReservablesOnChannel,
            userLocale,
          });
        }}
        events={events}
        slotDuration="00:15:00"
        slotLabelInterval="01:00:00"
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: true,
        }}
        slotLabelFormat={{
          hour: 'numeric',
          meridiem: true,
        }}
        resourceLabelContent={({ resource }) =>
          ColumnHeader({ resource, channelSlug })
        }
        resourceOrder="-type,title"
        dayMinWidth={190}
        allDaySlot={false}
        slotEventOverlap={false}
        height="auto"
        selectAllow={calendarSelectAllow}
        eventClick={selectInfo => {
          if (!isReservableCalendarV2Enabled) {
            return;
          }

          const event = selectInfo.event;

          if (!isFullCalendarEvent(event)) {
            return;
          }

          const isCombinedEvent = event.extendedProps.count > 1;

          if (isCombinedEvent) {
            const queryParams = new URLSearchParams({
              search: event.extendedProps.contentName,
              startDate: event.start.toISOString(),
              endDate: event.end.toISOString(),
            }).toString();

            history.push(`list?${queryParams}`);
          }
        }}
      />
    </div>
  );
});
