import Storage from 'lane-shared/helpers/Storage';
import { authorizationHeader } from 'lane-shared/helpers/formatters';

import makeFileDownload from './makeFileDownload';

export default async function downloadFile(url: string) {
  const token = await Storage.getItem(Storage.AUTH_TOKEN);
  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({ Authorization: authorizationHeader(token) }),
  });
  const blob = await response.blob();
  const fileType = response.headers.get('content-type');
  const [, fileName] = (
    response.headers.get('content-disposition') || ''
  ).split('filename=');

  makeFileDownload({
    type: fileType || 'text',
    name: fileName || 'download',
    blob,
  });
}
