export type SafetrustDeviceAuthenticationEventType = {
  deviceName: string;
  result: string;
  credentialId: string;
};

export enum SafetrustEventsEnum {
  DeviceAuthenticationEvent = 'deviceAuthenticationEvent',
  CredentialRangingEvent = 'credentialRangingEvent',
  SdkInitialized = 'sdkInitialized',
  UserAuthenticated = 'userAuthenticated',
  UserLoggedOut = 'userLoggedOut',
  AccountActivated = 'accountActivated',
  SessionStarted = 'sessionStarted',
  SessionEnded = 'sessionEnded',
  DeviceRegistered = 'deviceRegistered',
  DeviceActivated = 'deviceActivated',
  OrganizationSet = 'organizationSet',
  InvitationAccepted = 'invitationAccepted',
  CredentialsLoaded = 'credentialsLoaded',
  OrganizationsLoaded = 'organizationsLoaded',
  ScanningStarted = 'scanningStarted',
  ScanningStopped = 'scanningStopped',
  LeashingStarted = 'leashingStarted',
  LeashingStopped = 'leashingStopped',
  FeedbackModeSet = 'feedbackModeSet',
}

// todo: check that these are consistent on iOS
export enum SafetrustOrganizationStatusEnum {
  Normal = 'A',
  PendingInvitation = 'P',
}

// Organization object
export type SafetrustOrganizationType = {
  // The identifier of organization
  id: string;
  // Organization name
  name: string;
  // Organization status
  status: SafetrustOrganizationStatusEnum;
  // Organization avatar url
  image: string;
  // Device limitation flag
  limitDevice: boolean;
};

// Credential ranging information object
export type SafetrustCredentialRangingEventType = {
  // The identifier of credential
  credentialId: string;
  // Credential proximity signal
  credentialProximity: number;
};

export type SafetrustCredentialType = {
  // The identifier of credential
  id: string;
  // The name of user
  username: string;
  // Identity system of credential
  identitySystemName: string;
  // Contact email
  contactEmail: string;
  // Organization name
  organizationName: string;
  // User identifier
  userIdentifier: string;
  // Authentication automatic flag
  autoAuthenticate: string;
  // Credential front image url
  frontImagePath: string;
  // Credential back image url
  backImagePath: string;
  // Apple passkit design support flag
  isSupportPassDesign: boolean;
  // Credential expiry time
  expireTime: number;
  // Credential effective time
  effectiveTime: number;
};

// Error messages that can be sent to the client side and translated.
export enum SafetrustUserErrorMessagesEnum {
  AccountNotActivated = 'Your account is not yet activated, please go to your email to check for activating your account.',
  DeviceNotFound = 'This device is not registered. Please contact your property manager.',
  AccountNotFound = 'This account is not registered. Please contact your property manager.',
  PasswordInvalid = 'Your email or password is incorrect. Please try again or reset your Safetrust password.',
  PinInvalid = 'The activation code you entered is invalid. Please try again.',
  SdkError = 'There is a problem with this Safetrust setup. Please contact your manager.',
  UnknownError = 'An unexpected error has occurred, please try again.',
  RetryError = 'An unexpected error has occurred, please try logging into Safetrust again.',
  LocationServicesRequired = 'Please enable always on location services to use Safetrust.',
  LicenseAgreementRequired = 'You need to accept the license agreement to continue.',
  PasscodeRequired = 'You need to set a passcode on your device to continue.',
  AccountBlocked = 'This account has been blocked, please contact your manager.',
  Timeout = 'Contacting Safetrust took longer than expected, please try again.',
  PasswordCoolDown = 'You have entered an incorrect password too many times, please wait 30 seconds and try again.',
  Success = 'Success!',
  LoginNotSetup = 'This login has not been setup properly.',
  InvitationNotAccepted = 'You need to accept your invitation before continuing.',
  DeviceLimitReached = 'You have reached your Safetrust device limit. Contact your property manager for access.',
}

/**
 * SAFETRUST OVERLOADS ERROR CODE 0.  WE USE THE FOLLOWING ENUMS TO CHECK
 * AGAINST THE RETURN VALUES FROM SAFETRUST SDK AND API.
 *
 * DO NOT CHANGE SafetrustErrorMessagesEnum VALUES.
 * THEY ARE NOT DISPLAYED TO THE END USER
 */
export enum SafetrustErrorMessagesEnum {
  WrongPassword = 'wrong password',
  LoginNotSetup = 'login has not been setup password',
  InvitationNotAccepted = 'user has not accepted invitation',
  AccountNotActivated = 'Account not activated',
  PinNotMatch = 'pin not match',
  SessionInvalid = 'SESSION_INVALID',
  ActivationCodeRemoved = 'The Activation Code was removed since your account had been activated successfully.',
  LoginBlocked = 'login blocked',
  WrongVersion = 'wrong version',
  AccountContextNotFound = 'Not found AccountContext by account id',
  DeviceLimitReached = 'device info have reached limit',
}

export enum SafetrustAccountStatusEnum {
  ACCOUNT_EXISTED = 'AccountExisted',
  DEVICE_NOT_FOUND = 'DeviceNotFound',
  ACCOUNT_NOT_FOUND = 'AccountNotFound',
  ACCOUNT_PENDING_ACTIVATION = 'AccountPendingActivation',
}

export enum SafetrustErrorCodeEnum {
  UNHANDLE = 0,
  SUCCESS = 1,
  FAIL = 2,
  DEVICE_NOT_FOUND = 3,
  DEVICE_INFO_NOT_FOUND = 4,
  ADDRESS_INVALID = 5,
  ACCOUNT_EXISTED = 6,
  ACCOUNT_NOT_FOUND = 7,
  USERS_NOT_EXISTED = 9,
  REQUIRE_SET_PASSCODE = 11,
  REQUIRE_APP_START_FINISHED_BEFORE_DO_BUSINESS = 12,
  ACCOUNT_SESSION_NOT_FOUND = 13,
  COMPANY_LIST_SESSION_NOT_FOUND = 14,
  WRONG_PASSCODE_MANY_TIMES = 15,
  CAN_NOT_GET_VERSION_INFO = 16,
  SDK_UUID_NOT_EXISTED = 17,
  CAN_NOT_LOGIN_AFTER_ACTIVE_DEVICE = 18,
  OLD_SECRET_INVALID = 19,
  NEW_SECRET_INVALID = 20,
  SCANNING_NOT_START = 21,
  ACTIVATION_LINK_INVALID = 22,
  AUTHENTICATE_MANUAL_PROCESSING = 23,
  CREDENTIAL_EXISTED = 24,
  CREDENTIAL_SYSTEM_INVALID = 25,
  CAN_NOT_REQUEST_CREDENTIAL_IN_ONLINE_MODE = 26,
  CONTENT_SIGNED_INVALID = 27,
  CREDENTIAL_CERTIFICATES_NOT_EXIST = 28,
  CREDENTIAL_CERTIFICATES_INVALID = 29,
  CREDENTIAL_REQUEST_DATA_INVALID = 30,
  SWDK_CERTIFICATE_INVALID = 31,
  ROOT_DATA_INVALID = 32,
  CREDENTIAL_NOT_EXISTED = 33,
  NEED_TO_ACCEPT_LICENSE_AGREEMENT = 34,
  CONNECT = 35,
  DISCONNECT = 36,
  PARAMS_INVALID = 37,
  ENABLED_BUT_NEED_ALWAYS_LOCATION_PERMISSION = 38,
  TIMEOUT = 39,
  LOGIN_NOT_FOUND = 40,
  LOGIN_IS_NOT_PHONE_NUMBER = 41,
  ACCOUNT_NOT_ACTIVE = 42,
  WRONG_OTP = 43,
  CONFIG_SERVER_API_ERROR = 44,
  API_VERSION_INVALID = 45,
  CANNOT_RETRIEVE_API_VERSION = 46,
  ACTIVATE_CODE_INVALID = 47,
  CREDENTIAL_CERTIFICATE_NOT_EFFECTIVE = 48,
  PASSCODE_INVALID = 56,
  ACCOUNT_BLOCKED = 64,

  // these are our own error codes meant to keep all of the possible errors
  // in one place.  So we are prefixing them with 10000 to avoid collisions.
  INVITATION_NOT_ACCEPTED = 10001,
  LOGIN_NOT_SETUP = 10002,
  UNKNOWN_SDK_ERROR = 10003,
  DEVICE_LIMIT_REACHED = 10004,
}
