import { ReservableBooking, ContentFeature } from 'graphql-query-contracts';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';

type FullCalendarBooking = {
  startDate: string;
  endDate: string;
  status: string[];
  companies: {
    name: string;
    id: string;
    joinedAt: string;
  }[];
  username: string;
  count: number;
  features: UserContentInteractionFeaturesType;
  contentData: {
    _id: string;
    name: string;
    features: ContentFeature[];
  };
  adminNote: string | undefined;
  interactionId: string | undefined;
};

export function getUniqueTimeBookings(reservableBookings: ReservableBooking[]) {
  const uniqueTimeBookings = Object.values(
    reservableBookings.reduce(
      (acc: { [key: string]: FullCalendarBooking }, cur) => {
        const key = `${cur.startDate}${cur.endDate}`;
        acc[key] = acc[key] || {
          startDate: cur.startDate,
          endDate: cur.endDate,
          status: [],
          companies: cur.companies,
          username: cur.user.name,
          count: 0,
          adminNote: cur.adminNote,
          features: cur.features,
          contentData: cur.contentData,
          interactionId: cur.id,
          user: cur.user,
        };
        acc[key].count += 1;
        acc[key].status = [...acc[key].status, cur.status];

        if (acc[key].count > 1) {
          acc[key].interactionId = undefined;
        }

        return acc;
      },
      {}
    )
  );
  return uniqueTimeBookings;
}
