export enum ChipStyle {
  Pink = 'pink',
  Blue = 'blue',
  Brown = 'brown',
  Green = 'green',
  Grey = 'grey',
  Red = 'red',
  Yellow = 'yellow',
  White = 'white',
  Indigo = 'indigo',
}

export type ChipListItem = {
  label: string;
  value: string;
  type: ChipStyle;
};
