import React, { useMemo } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { slugify } from 'lane-shared/helpers/formatters';
import Types from 'lane-shared/properties/Types';
import { ThemeType } from 'lane-shared/types/Theme';
import { UserType } from 'lane-shared/types/User';
import { PropertyType } from 'lane-shared/types/properties/Property';
import {
  AllTypeContexts,
  TypeContextEnum,
} from 'lane-shared/types/properties/TypeContextEnum';

import Dropdown from 'components/form/Dropdown';
import IconPicker from 'components/form/IconPicker';
import SimpleInput from 'components/form/SimpleInput';
import Toggle from 'components/form/Toggle';

import styles from './FieldType.scss';

const types = Object.entries(Types.getBaseTypes()).map(([key, value]) => ({
  type: key,
  ...value,
}));

type Props = {
  className?: string;
  style?: React.CSSProperties;
  field: PropertyType;
  showKey?: boolean;
  showName?: boolean;
  showPlaceholder?: boolean;
  isArrayAvailable?: boolean;
  user: Pick<UserType, 'isSuperUser'>;
  theme: ThemeType;
  contexts?: TypeContextEnum[];
  onFieldUpdated: (field: Partial<PropertyType>) => void;
};

export default function FieldType({
  className,
  style,
  showKey = true,
  showName = true,
  showPlaceholder = true,
  isArrayAvailable = true,
  user,
  contexts = AllTypeContexts,
  field,
  theme,
  onFieldUpdated = () => {},
}: Props) {
  const typeItems = useMemo(
    () =>
      types
        .filter(
          type =>
            !type.contexts ||
            type.contexts.some(context => contexts.includes(context))
        )
        .filter(type => user.isSuperUser || !type.superUserOnly)
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        .sort((a, b) => (a.friendlyName > b.friendlyName ? 1 : -1))
        .map(type => ({
          value: type.name,
          label: type.friendlyName || type.name,
        })),
    [user, contexts]
  );

  const { t } = useTranslation();

  return (
    <section className={cx(styles.FieldType, className)} style={style}>
      <div className={styles.row}>
        <fieldset className={styles.field}>
          <label>{t('web.admin.content.metatag.edit.fieldType.type')}</label>
          <Dropdown
            placeholder={t(
              'web.admin.content.metatag.edit.fieldType.fieldTypeInputPlaceholder'
            )}
            onValueChange={type =>
              onFieldUpdated({
                type,
              })
            }
            items={typeItems}
            value={field.type}
          />
        </fieldset>

        {isArrayAvailable && (
          <fieldset className={styles.field}>
            <label>{t('web.admin.content.metatag.edit.fieldType.list')}</label>
            <Toggle
              value={!!field.isArray}
              onChange={() => onFieldUpdated({ isArray: !field.isArray })}
            />
          </fieldset>
        )}
      </div>

      <div className={styles.row}>
        {showName && (
          <fieldset className={styles.field}>
            <label>{t('web.admin.content.metatag.edit.fieldType.name')}</label>
            <SimpleInput
              onChange={friendlyName =>
                onFieldUpdated({
                  friendlyName,
                  name: slugify(friendlyName),
                })
              }
              value={field.friendlyName}
            />
          </fieldset>
        )}
        <fieldset className={styles.field}>
          <label>{t('web.admin.content.metatag.edit.fieldType.icon')}</label>
          <IconPicker
            palette={theme?.palette!}
            className={styles.iconPicker}
            icon={(field.icon as any)?.name}
            iconSet={(field.icon as any)?.set}
            iconWeight={(field.icon as any)?.iconWeight}
            onChange={({ icon, iconSet, iconWeight }: any) =>
              onFieldUpdated({
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: any; set: any; iconWeight: any; }' i... Remove this comment to see the full error message
                icon: { name: icon, set: iconSet, iconWeight },
              })
            }
          />
        </fieldset>
      </div>
      {showPlaceholder && (
        <fieldset className={styles.field}>
          <label>
            {t('web.admin.content.metatag.edit.fieldType.placeholderTextLabel')}
          </label>
          <SimpleInput
            placeholder={t(
              'web.admin.content.metatag.edit.fieldType.placeholderTextInput'
            )}
            value={field.placeholder}
            onChange={placeholder =>
              onFieldUpdated({
                placeholder,
              })
            }
          />
        </fieldset>
      )}

      {showKey && (
        <fieldset className={styles.field}>
          <label>
            {t('web.admin.content.metatag.edit.fieldType.uniqueIdentifier')}
          </label>
          <SimpleInput
            onChange={name =>
              onFieldUpdated({
                name,
              })
            }
            value={field.name}
          />
        </fieldset>
      )}
    </section>
  );
}
