import { useMemo } from 'react';

import {
  IPropertyDependency,
  PropertiesInterface,
} from 'lane-shared/types/properties/Property';
import { PropertiesInterfaceDependencies } from 'lane-shared/types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';

export default function useUnsatisfiedDependenciesToFilter(
  data: Record<string, any>,
  definition?: PropertiesInterface | null,
  dependencies: Array<IPropertyDependency> = []
) {
  return useMemo(() => {
    const dataToFilter: string[] = [];
    if (dependencies.length) {
      const propertiesInterfaceDependencies = PropertiesInterfaceDependencies.fromJsonData(
        definition || {},
        dependencies
      );
      const dependencyValues = propertiesInterfaceDependencies?.dependencies?.values();
      for (const dependency of dependencyValues) {
        if (!dependency.isSatisfied(data)) {
          dataToFilter.push(dependency.propertyRef);
        }
      }
    }
    return dataToFilter;
  }, [definition, data, dependencies]);
}
