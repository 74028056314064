import gql from 'graphql-tag';

import { ContentInteractionFragment } from '../fragments';

export default gql`
  ${ContentInteractionFragment}
  query interactionsOnSection(
    $sectionId: UUID!
    $pagination: PaginationInput
    $search: UserContentInteractionSearch
  ) {
    me {
      contentInteractions(
        sectionId: $sectionId
        pagination: $pagination
        search: $search
      ) {
        items {
          ...ContentInteractionFragment
        }
        pageInfo {
          start
          total
          perPage
        }
      }
    }
  }
`;
