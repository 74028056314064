import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { objectToArray } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { Validators } from 'lane-shared/properties/validators';
import {
  Validator,
  ValidatorNameOptions,
  ValidatorValueTypes,
} from 'lane-shared/types/Validator';
import { PropertyType } from 'lane-shared/types/properties/Property';

import Dropdown from '../../../form/Dropdown';
import Property from '../input/Property';

import styles from './ValidatorEdit.scss';

type Args = {
  className?: string;
  style?: any; // TODO: fix type
  definition: any; // TODO: fix type
  field: PropertyType;
  validator: Validator;
  onUpdate: (arg0: Validator) => void;
  onRemove: any; // TODO: fix type
};

function ValidatorEdit({
  className,
  style,
  definition,
  field,
  validator,
  onUpdate,
  onRemove,
}: Args) {
  // this is an arrayOnly validator and the field is an array
  // remove validators that don't apply to this type
  const { t } = useTranslation();
  const items = objectToArray(Validators)
    .filter(
      validator =>
        (!validator.arrayOnly || (validator.arrayOnly && field.isArray)) &&
        (!validator.types || validator.types.includes(field.type))
    )
    .map(validator => ({
      value: validator.name as ValidatorNameOptions,
      label: validator.friendlyName || validator.name,
    }));

  const definitionItems = definition
    .filter((def: any) => def.name !== field.name)
    .map((def: any) => ({
      label: def.friendlyName || def.name,
      value: def.name,
    }));

  const validatorDefinition = Validators[validator.name];
  const properties = objectToArray(validatorDefinition.properties);

  return (
    <div
      className={cx(styles.ValidatorEdit, className)}
      style={style}
      data-cy="validator"
    >
      <div className={styles.row}>
        <Dropdown
          className={styles.dropdown}
          placeholder="Select a validation option…"
          onValueChange={name => {
            const selectedValidator = Validators[name];
            onUpdate({
              name,
              value: selectedValidator?.properties?.value
                ?.default as ValidatorValueTypes,
            });
          }}
          items={items}
          value={validator.name}
        />
        <Icon
          className={styles.deleteButton}
          name="times-circle"
          set={ICON_SET_FONTAWESOME}
          onClick={() => onRemove(validator)}
        />
      </div>

      <p>{t(validatorDefinition.description || '')}</p>

      <div className={styles.row}>
        {properties.map(property => (
          // @ts-expect-error ts-migrate(2739) FIXME: Type '{ className: any; key: string | undefined; p... Remove this comment to see the full error message
          <Property
            className={styles.property}
            key={property.name}
            property={property}
            value={validator.value}
            keys={definitionItems}
            onChange={value => onUpdate({ ...validator, value })}
          />
        ))}
      </div>
    </div>
  );
}

export default ValidatorEdit;
