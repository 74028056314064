import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { FeatureInstance } from 'lane-shared/types/FeatureInstance';
import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';

const TRANSLATION_KEYS = {
  bookingTimeAllDay:
    'web.admin.channel.reservableManagement.table.bookingTime.allDay',
};
export const DEFAULT_TZ = 'America/New_York';

function areDatesOfSameDay(
  startDate: string,
  endDate: string,
  timeZone: string
): boolean {
  const startDateTime = DateTime.fromISO(startDate).setZone(timeZone);
  const endDateTime = DateTime.fromISO(endDate).setZone(timeZone);

  return startDateTime.toISODate() === endDateTime.toISODate();
}

const getStatusesFeature = (features?: FeatureInstance[]) => {
  if (!features) return undefined;

  const { statusesFeature } = explodeFeatures(features);
  return statusesFeature;
};

const isPastBooking = (endDate: string, channelTimeZone: string) => {
  const now = DateTime.local().setZone(channelTimeZone);
  const dateTime = DateTime.fromISO(endDate).setZone(channelTimeZone);

  return dateTime < now;
};

const getCancelableFeature = (features?: FeatureInstance[]) => {
  if (!features) return undefined;

  const { cancelableFeature } = explodeFeatures(features);
  return cancelableFeature;
};

export function formatBookingDate(
  startDate: string,
  endDate: string,
  channelTimeZone: string
) {
  const startDateTime = DateTime.fromISO(startDate).setZone(channelTimeZone);
  const isSameDay = areDatesOfSameDay(startDate, endDate, channelTimeZone);

  if (isSameDay) {
    const formattedDate = startDateTime.toFormat('MMMM d, yyyy');

    return formattedDate;
  }

  const endDateTime = DateTime.fromISO(endDate).setZone(channelTimeZone);
  const formattedStartDate = startDateTime.toFormat('MMMM d');
  const formattedEndDate = endDateTime.toFormat('MMMM d, yyyy');

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export function formatBookingTime(
  translator: (key: string, params?: any) => string,
  {
    startDate,
    endDate,
    channelTimeZone,
    features,
  }: {
    startDate: string;
    endDate: string;
    channelTimeZone: string;
    features?: FeatureInstance[];
  }
) {
  const { reservableFeature } = explodeFeatures(features);

  if (
    reservableFeature &&
    reservableFeature.unitType === ReservableUnitTypesEnum.Days
  ) {
    return translator(TRANSLATION_KEYS.bookingTimeAllDay);
  }

  const startTime = DateTime.fromISO(startDate).setZone(channelTimeZone);
  const endTime = DateTime.fromISO(endDate).setZone(channelTimeZone);

  const formattedStartTime = startTime.toFormat('h:mm a');
  const formattedEndTime = endTime.toFormat('h:mm a');

  return `${formattedStartTime} - ${formattedEndTime}`;
}

export function formatStatus({
  interactionId,
  status,
  features,
  endDate,
  channelTimeZone,
}: {
  interactionId: LaneType.UUID;
  status: string;
  features?: FeatureInstance[];
  endDate: string;
  channelTimeZone: string;
}) {
  return {
    interactionId,
    initialStatus: status,
    statusFeature: getStatusesFeature(features),
    isPastInteraction: isPastBooking(endDate, channelTimeZone),
    cancelableFeature: getCancelableFeature(features),
  };
}
