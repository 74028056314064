import React from 'react';

import { colors } from '../../config';
import { getPaymentDetailsWithQuote } from '../../helpers';
import i18nStub from '../../helpers/i18nStub';
import { MjmlWrapper } from '../MjmlComponentsV2';
import EmailLabelWithValue from './EmailLabelWithValue';

export default function EmailPaymentFeatureQuote({
  i18n = i18nStub,
  userLocale,
  quote,
  cardBrand,
  cardLast4,
}: any) {
  if (!quote) {
    return null;
  }

  const paymentDetails = getPaymentDetailsWithQuote(quote, userLocale);

  const percentageFormat = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  return (
    <MjmlWrapper
      backgroundColor={colors.gray4}
      padding={0}
      borderRadius="20px"
      border="1px solid #eee"
    >
      {paymentDetails.items.map(lineItem => (
        <EmailLabelWithValue
          key={lineItem.key}
          label={<h3>{lineItem.name}</h3>}
          backgroundColor={colors.gray4}
        >
          <h3>{lineItem.amount}</h3>
        </EmailLabelWithValue>
      ))}

      {paymentDetails.taxes.length > 0 &&
        paymentDetails.taxes.map(tax => (
          <EmailLabelWithValue
            key={tax.key}
            label={
              <h3>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                {tax.name} {percentageFormat(tax.rate)}%
              </h3>
            }
            backgroundColor={colors.gray4}
          >
            <h3>{tax.amount}</h3>
          </EmailLabelWithValue>
        ))}
      <EmailLabelWithValue
        label={<h3>{i18n.t('Total')}</h3>}
        backgroundColor={colors.gray4}
      >
        <h3>{paymentDetails.total}</h3>
      </EmailLabelWithValue>
      {/* These values won't exist on Essensys content that use credits */}
      {cardBrand && cardLast4 && (
        <EmailLabelWithValue
          label={i18n.t('Card')}
          backgroundColor={colors.gray4}
        >
          <b>{`${cardBrand} **** **** **** ${cardLast4}`}</b>
        </EmailLabelWithValue>
      )}
    </MjmlWrapper>
  );
}
