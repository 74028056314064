import {
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN,
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE,
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
} from 'lane-shared/helpers/constants/permissions';
import { UserGroupRoleType } from 'lane-shared/types/UserGroupRole';
import hasPermission from 'lane-shared/helpers/hasPermission';
import {
  AccessGroupSelectItem,
  AccessControlGroup,
} from '../types/AccessControl';

export const convertToUserAccessGroupSelectItem = (
  accessGroups: AccessControlGroup[],
  userAccessGroupIds: string[],
  userRoles: UserGroupRoleType[],
  channelId: string,
  selected: AccessControlGroup[],
  integrationChannelId?: string
): AccessGroupSelectItem[] => {
  const canAssign = hasPermission(
    userRoles,
    [PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN],
    channelId,
    true
  );

  const canRevoke = hasPermission(
    userRoles,
    [PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE],
    channelId,
    true
  );

  return accessGroups.map((accessGroup: AccessControlGroup) => {
    const hasCurrentChannelPermissions = selected.includes(accessGroup)
      ? canRevoke
      : canAssign;

    const hasSpecialChannelPermissions =
      accessGroup.channelId !== channelId &&
      accessGroup.channelId !== integrationChannelId
        ? hasPermission(
            userRoles,
            [PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY],
            accessGroup.channelId,
            true
          ) && userAccessGroupIds.includes(accessGroup.id)
        : true;

    const isDisabled =
      !hasCurrentChannelPermissions || !hasSpecialChannelPermissions;

    return {
      label: accessGroup.name,
      value: accessGroup.id,
      provider: accessGroup.provider,
      channelId: accessGroup.channelId,
      isDisabled,
    };
  });
};
