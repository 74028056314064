import * as yup from 'yup';

import { validatePasswordV1, validatePasswordV2 } from './validatePassword';

export const emailError = 'Must be a valid email';

/**
 * Use validateLogin validation schema.
 * @deprecated
 */
export default yup.object().shape({
  email: yup.string().trim().email(emailError).required(),
  password: validatePasswordV1,
});

export const validateLogin = yup
  .object()
  .shape({
    email: yup.string().trim().email(emailError).required(),
    password: validatePasswordV2,
  })
  .required();
