import { useState, useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { channelPublicUsers } from '../graphql/user';

export default function useUserDirectoryQuery({
  perPage,
  channelId,
  pollInterval,
}: any) {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [pageInfo, setPageInfo] = useState({ total: 0, start: 0, perPage });

  function getVariables() {
    const variables = {
      channelId,
      pagination: { start: page * perPage, perPage },
      search: {
        sortBy: { key: 'name', dir: 'asc' },
        profile: {
          isPublic: true,
        },
      },
    };

    if (search?.length > 2) {
      (variables.search as any).name = {
        type: 'like',
        value: search,
      };
    }

    return variables;
  }

  const [
    fetchUsers,
    { data, loading, error, fetchMore, called },
  ] = useLazyQuery(channelPublicUsers, {
    client: getClient(),
    fetchPolicy: 'network-only',
    pollInterval,
  });

  const debouncedFetch = useDebouncedCallback(function doFetch() {
    fetchUsers({
      variables: getVariables(),
    });
  }, 500).callback;

  useEffect(() => {
    if (channelId) {
      setPage(0);
      debouncedFetch();
    }
  }, [channelId, search, perPage]);

  useEffect(() => {
    if (data?.channelPublicUsers?.items) {
      setItems(data.channelPublicUsers.items);
    }

    if (data?.channelPublicUsers?.pageInfo) {
      setPageInfo(data.channelPublicUsers.pageInfo);
    }
  }, [data?.channelPublicUsers]);

  function doFetchMore() {
    if (loading || error) {
      return;
    }

    if (pageInfo.perPage * (page + 1) > pageInfo.total) {
      // No more results.
      return;
    }

    // bug in react-apollo see https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
    try {
      fetchMore({
        variables: {
          ...getVariables(),
          pagination: { start: page * perPage, perPage },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // Don't do anything if there weren't any new items
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            // Concatenate the new feed results after the old ones
            channelPublicUsers: {
              __typename: 'channelPublicUsers',
              pageInfo: fetchMoreResult.channelPublicUsers.pageInfo,
              items: [
                ...prev.channelPublicUsers.items,
                ...fetchMoreResult.channelPublicUsers.items,
              ],
            },
          };
        },
      });
    } catch (err) {
      // swallow error for now
    }
  }

  useEffect(() => {
    if (called && !loading) {
      doFetchMore();
    }
  }, [page]);

  function fetchNextPage() {
    setPage(page => page + 1);
  }

  return {
    items,
    pageInfo,
    search,
    setSearch,
    page,
    setPage,
    loading,
    error,
    fetchNextPage,
  };
}
