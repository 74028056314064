import * as yup from 'yup';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { TimeUnitEnum } from '../../../../types/TimeUnitEnum';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';

const properties: PropertiesInterface = {
  allGroupRoles: {
    friendlyName:
      'web.content.feature.cancelable.rules.allGroupRoles.friendlyName',
    description:
      'web.content.feature.cancelable.rules.allGroupRoles.description',
    type: 'Boolean',
    editable: true,
    default: 'true',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  groupRoles: {
    showControl: {
      _bind: true,
      op: '!props.allGroupRoles',
    },
    friendlyName:
      'web.content.feature.cancelable.rules.groupRoles.friendlyName',
    description: 'web.content.feature.cancelable.rules.groupRoles.description',
    type: 'GroupRole',
    editable: true,
    isArray: true,
    default: [],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  anyTime: {
    friendlyName: 'web.content.feature.cancelable.rules.anyTime.friendlyName',
    default: true,
    description: 'web.content.feature.cancelable.rules.anyTime.description',
    type: 'Boolean',
    editable: true,
  },
  units: {
    friendlyName: 'web.content.feature.cancelable.rules.units.friendlyName',
    description: 'web.content.feature.cancelable.rules.units.description',
    showControl: {
      _bind: true,
      op: '!props.anyTime',
    },
    type: 'String',
    editable: true,
    default: TimeUnitEnum.Hour,
    validators: [
      {
        name: 'In',
        value: Object.values(TimeUnitEnum),
      },
    ],
  },
  unitsBefore: {
    friendlyName:
      'web.content.feature.cancelable.rules.unitsBefore.friendlyName',
    showControl: {
      _bind: true,
      op: '!props.anyTime',
    },
    description: 'web.content.feature.cancelable.rules.unitsBefore.description',
    type: 'Number',
    editable: true,
    default: 0,
    validators: [
      {
        name: 'Min',
        value: 0,
      },
      {
        name: 'Max',
        value: 120,
      },
    ],
  },
};

export class CancelableFeatureRuleType extends ObjectTypeBase {
  name = 'CancelableFeatureRule';

  friendlyName = 'Cancelable Rule';

  contexts = AllTypeContexts;

  get example() {
    return {
      allGroupRoles: true,
      groupRoles: null,
      requiresReason: false,
      units: TimeUnitEnum.Hour,
      unitsBefore: 0,
    };
  }

  get default(): any {
    return {
      allGroupRoles: true,
      groupRoles: null,
      requiresReason: false,
      units: TimeUnitEnum.Hour,
      unitsBefore: 0,
      anyTime: true,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new CancelableFeatureRuleType();

Types.addType(definition);
export default definition;
