/* eslint-disable react/jsx-no-literals */
import React from 'react';
import EmailText from '../../../../emails/components/EmailText';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { MjmlColumn, MjmlSection } from '../../../../emails/MjmlComponentsV2';
import { SHORT_TIME, SIMPLE_DATE } from '../../../constants/dates';
import { CommentAddedWebhookResponse, WorkOrder } from '../types';

type TemplateProps = {
  workOrderDetail: WorkOrder;
  webhookResData: CommentAddedWebhookResponse;
};

export const WoCommentAddedTemplate = ({
  workOrderDetail,
  webhookResData,
}: TemplateProps) => {
  return (
    <>
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <h3>
              {`A new comment has been added to work order ${workOrderDetail.identifier} for ${workOrderDetail.building.name}`}
            </h3>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <p
              data-testid="wo-comment-added-label"
              style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              New comment:
            </p>
          </EmailText>
          <EmailText>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '16px',
                  lineHeight: '1.5',
                  textAlign: 'justify',
                  margin: '0 auto',
                }}
              >
                "{webhookResData.comment}"
              </p>
            </div>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection padding-top="24px">
        <MjmlColumn>
          <EmailText>
            <MjmlColumn>
              <p
                data-testid="wo-comment-added-footer"
                style={{ textAlign: 'center', fontWeight: '400' }}
              >
                {`Added by ${webhookResData.createdBy} on ${dateFormatter(
                  webhookResData.createdDate,
                  SIMPLE_DATE
                )} at ${dateFormatter(webhookResData.createdDate, SHORT_TIME)}`}
              </p>
            </MjmlColumn>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
    </>
  );
};
