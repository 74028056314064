import i18next from 'i18next';
import { DateTime } from 'luxon';

import parseDateTime from '../../dates/parseDateTime';
import dateFormatter from '../dateFormatter';

export default function formatDateRangeMonth({
  _start,
  _end,
  i18n = i18next,
  timeZone,
  locale,
}: {
  _start: DateTime | Date | string;
  _end: DateTime | Date | string;
  i18n?: any;
  timeZone?: string;
  locale?: string;
}): string {
  const start = parseDateTime(_start, timeZone)!;
  const end = parseDateTime(_end, timeZone)!;

  const startFormatted = dateFormatter(start, 'yyyy', timeZone, locale);
  const endFormatted = end ? dateFormatter(end, 'yyyy', timeZone, locale) : '…';
  return i18n.t('shared.helpers.formatters.dateRange.range', {
    startTime: startFormatted,
    endTime: endFormatted,
  });
}
