export enum LinkRouteEnum {
  SectionRoute = 'A Section',
  LiveContentRoute = 'Live Content',
  PageRoute = 'A Page',
  UserRoute = 'A User',
  ChannelRoute = 'A Channel',
  InteractionsOnContentRoute = 'Interactions on a Content',
  TodayFeedOnChannelRoute = 'Today Feed on a Channel',
  SectionsOnChannelRoute = 'Sections on a Channel',
  RetailOnChannelRoute = 'Retail directory on a Channel',
  OfficeOnChannelRoute = 'Office directory on a Channel',
  UserDirectoryOnChannelRoute = 'User directory on a Channel',
  SettingsRoute = 'User Settings',
  WalletRoute = 'User Wallet',
  RecentActivityRoute = 'User Recent Activity',
  SubscriptionsRoute = 'User Subscriptions',
  QRCodeRoute = 'Users QR Code',
  QRCodeScanner = 'QR Code Scanner',
}
