import { Url } from '../../../properties/baseTypes';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';

const MailChimpDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.MailChimp,
  hasCustomUI: true,
  friendlyName: IntegrationProviderEnum.MailChimp,
  description: `Defines the properties required for Mailchimp integration.`,
  category: IntegrationCategoryEnum.Custom,
  platforms: [],
  type: IntegrationTypeEnum.Api,
  allowsMultipleInstances: true,
  settings: {},
  properties: {
    groupId: {
      friendlyName: 'Team',
      type: 'String',
      description: 'Group role',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    apiKey: {
      friendlyName: 'Api key',
      type: 'String',
      description: 'Api key',
      validators: [{ name: 'Required', value: true }],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    serverDomain: {
      friendlyName: 'Server Domain',
      type: Url.name,
      description: 'Mailchimp server domain',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    audienceListId: {
      friendlyName: 'Audience List',
      type: 'String',
      description: 'Audience List',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default MailChimpDefinition;
