import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import {
  getFriendlyTime,
  getName,
} from 'lane-shared/helpers/integrations/ProxyClick';

import Thumbnail from 'components/general/Thumbnail';

import styles from './MeetingCard.scss';

type Props = {
  className?: string;
  meeting: any;
  status: any;
};

export default function MeetingCard({ className, meeting, status }: Props) {
  return (
    <div className={cx(styles.container, className)} data-status={status}>
      {meeting.visitors.map((visitor: any) => (
        <div className={styles.row} key={visitor._id || visitor.id}>
          <Thumbnail src={visitor.picture192} name={visitor.name} />
          <div className={styles.column}>
            <p className={styles.name}>{getName(visitor)}</p>
            <p className={styles.company}>{visitor.companyName}</p>
            <p className={styles.time}>{getFriendlyTime(meeting)}</p>
            <p className={styles.status}>{status}</p>
          </div>
          <Icon name="chevron-right" className={styles.icon} />
        </div>
      ))}
    </div>
  );
}
