import Features from 'lane-shared/renderers/v5/features';

import { FeatureInstance } from '../../types/FeatureInstance';
import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';
import { PaymentFeatureInteractionData } from '../../types/features/PaymentFeatureInteractionData';
import getPaymentTotalWithQuote from '../getPaymentTotalWithQuote';
import byOrder from '../sort/byOrder';
import { ContentFeatureColumnsType } from './types';
import { GuestInviteGuestType } from '../../renderers/v5/features/types/GuestInviteGuestType';

/**
 * Helper function to display the columns associated with what features
 * a content has enabled.  Mostly used for display grid or tables of
 * interaction data on this content.
 */
export default function getContentFeatureColumns(
  content:
    | {
        features?: FeatureInstance[];
      }
    | null
    | undefined,
  opts?: {
    timeZone?: string;
    locale?: string;
  }
): ContentFeatureColumnsType[] {
  if (!content?.features) {
    return [];
  }

  return content.features
    .filter(feature => !!Features[feature.type].interactionData)
    .map(feature => {
      const Feature = Features[feature.type];

      const featureColumns: ContentFeatureColumnsType = {
        feature,
        type: feature.type,
        friendlyName: Feature.friendlyName || Feature.name,
        columns: [],
      };

      // special logic for each feature can be put here.
      if (feature.type === FeatureNameEnum.Payment) {
        featureColumns.columns = [
          {
            name: 'Payment',
            type: 'Any',
            forDisplay: true,
            formatter: (data: PaymentFeatureInteractionData) =>
              getPaymentTotalWithQuote(data.quote, opts?.locale),
            textFormatter: (data: PaymentFeatureInteractionData) =>
              getPaymentTotalWithQuote(data.quote, opts?.locale),
          },
        ];

        return featureColumns;
      }

      if (feature.type === FeatureNameEnum.GuestInvite) {
        featureColumns.columns = [
          {
            name: 'GuestInvite',
            type: 'Any',
            forDisplay: true,
            formatter: (data: { guests: GuestInviteGuestType[] }) => {
              return data.guests.map(item => item.email).join(',');
            },
            textFormatter: (data: { guests: GuestInviteGuestType[] }) => {
              return data.guests.map(item => item.email).join(',');
            },
          },
        ];

        return featureColumns;
      }

      featureColumns.columns = Object.entries(Feature.interactionData || {})
        .filter(([, value]) => value.forDisplay !== false)
        .sort(byOrder)
        .map(([key, value]) => ({
          name: key,
          ...value,
        }));

      return featureColumns;
    });
}
