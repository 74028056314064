import { useEffect } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { PublicUserFragment, ProfileFragment } from '../graphql/fragments';
import { useMultiLanguage } from './useMultiLanguage';

const interactionsQuery = gql`
  ${PublicUserFragment}
  ${ProfileFragment}

  query getContentInteractions($id: UUID!, $search: ContentSearch) {
    me {
      content(_id: $id, search: $search) {
        _id
        isInteractive
        userInteractions {
          pageInfo {
            total
            start
            perPage
          }

          items {
            _id
            _created
            _updated
            _updatedBy {
              ...PublicUserFragment
            }
            user {
              _id
              profile {
                ...ProfileFragment
              }
            }
            startDate
            endDate
            geo
            data
            state
            features
            actions
            contentData
            version
            status
          }
        }
      }
    }
  }
`;

/**

 * A simple hook for requesting user's interactions.
 *
 * @param contentId
 */

export default function useInteractions({ contentId }: any) {
  const [loadContentInteractions, { loading, data, error }] = useLazyQuery(
    interactionsQuery,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
    }
  );
  const { translate } = useMultiLanguage();
  async function getContentInteractions() {
    loadContentInteractions({
      variables: {
        id: contentId,
      },
    });
  }

  useEffect(() => {
    if (contentId) {
      getContentInteractions();
    }
  }, [contentId]);

  const items = (data?.me?.content.userInteractions.items || []).map(
    (item: any) => ({
      ...item,
      contentData: translate({
        model: item.contentData,
        columns: ['name', 'description', 'subtitle'],
      }),
    })
  );

  return {
    items,
    error,
    loading,
  };
}
