import { DateTime, Zone } from 'luxon';

import { SIMPLE_DATE } from '../constants/dates';
import dateFormatter from './dateFormatter';

export default function simpleDate(
  date: Date | DateTime | string | undefined | null,
  timeZone?: string | Zone,
  locale?: string
) {
  return dateFormatter(date, SIMPLE_DATE, timeZone, locale);
}
