import React, { useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  CONTENT_SEARCH_TYPE_CHANNEL,
  CONTENT_SEARCH_TYPES_ITEMS,
} from 'lane-shared/helpers/constants/content';
import { useStoredState } from 'lane-shared/hooks';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import Dropdown from 'components/form/Dropdown';
import { H4 } from 'components/typography';

import ChannelSelectorButton from './ChannelSelectorButton';
import ContentSearchOnChannel from './ContentSearchOnChannel';

import styles from './ContentSelector.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  channelId?: string | null | undefined;
  onContentSelected: (content: DocumentType | null | undefined) => void;
  searchDrafts?: boolean;
  contentQueryType: 'channel' | 'geo';
  contentSearchLocations: (
    | 'Content Center'
    | 'Page Center'
    | 'From a Section'
  )[];
  availableTypes: ContentTypeEnum[];
  storageKey?: string;
  includeGeneratedBatchContent: boolean;
  isOnlyInteractiveContent?: boolean;
};

ContentSelector.defaultProps = {
  onContentSelected: () => {},
  /** places that the user is allowed to pick content from */
  contentSearchLocations: null,
  /** either a geo search, or an on-channel search, if null will allow both */
  contentQueryType: CONTENT_SEARCH_TYPE_CHANNEL,
  /** are we searching for drafts or published content? */
  searchDrafts: false,
  /** what content types are allowed? */
  availableTypes: Object.values(ContentTypeEnum),
  includeGeneratedBatchContent: false,
};

type Props = OwnProps; // & typeof ContentSelector.defaultProps;

export default function ContentSelector({
  className,
  style,
  channelId,
  onContentSelected,
  availableTypes,
  contentSearchLocations,
  contentQueryType,
  searchDrafts,
  storageKey,
  includeGeneratedBatchContent,
  isOnlyInteractiveContent,
}: Props) {
  const { t } = useTranslation();
  const [searchType, setSearchType] = useStoredState(
    `ContentSelectorSearchType${storageKey}`,
    contentQueryType || CONTENT_SEARCH_TYPE_CHANNEL
  );
  const [selectedChannelId, setSelectedChannelId] = useState<
    string | null | undefined
  >(channelId);
  return (
    <div className={cx(styles.ContentSelector, className)} style={style}>
      <H4 mb={2}>{t('web.components.lane.ContentSelector.selectContent')}</H4>
      {!contentQueryType && (
        <Dropdown
          className={styles.dropDown}
          items={CONTENT_SEARCH_TYPES_ITEMS}
          value={searchType}
          onValueChange={setSearchType}
        />
      )}

      {searchType === CONTENT_SEARCH_TYPE_CHANNEL && (
        <ChannelSelectorButton
          className={styles.selectorButton}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
          channelId={selectedChannelId}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(channel: any) => void' is not assignable to... Remove this comment to see the full error message
          onChannelSelected={(channel: any) =>
            setSelectedChannelId((channel && channel._id) || null)
          }
        />
      )}

      {searchType === CONTENT_SEARCH_TYPE_CHANNEL && selectedChannelId && (
        <ContentSearchOnChannel
          className={styles.contentSearch}
          channelId={selectedChannelId}
          onContentSelected={onContentSelected}
          forDrafts={searchDrafts}
          // TODO: remove this -- add new dropdown option probs
          types={[...availableTypes, ContentTypeEnum.WorkOrder, ContentTypeEnum.VisitorManagement]}
          contentSearchLocations={contentSearchLocations}
          includeGeneratedBatchContent={includeGeneratedBatchContent}
          isOnlyInteractiveContent={isOnlyInteractiveContent}
        />
      )}
    </div>
  );
}
