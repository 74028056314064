import { useContext } from 'react';

import { ChannelsContext } from '../contexts';
import { ChannelTypeEnum } from '../types/ChannelType';

export default function useCompanyId(): string | null {
  // NOTE: Using any to avoid TypeScript errors as ChannelsContext is currently untyped
  // no ticket exists to address this, not scheduled.
  const { primaryChannel, secondaryChannel } = useContext<any>(ChannelsContext);

  if (primaryChannel?.type === ChannelTypeEnum.Company) {
    return primaryChannel._id;
  }

  if (secondaryChannel?.type === ChannelTypeEnum.Company) {
    return secondaryChannel._id;
  }

  return null;
}
