import gql from 'graphql-tag';

import { LaneType } from 'common-types';
import {
  MetatagFragment,
  ObjectMetatagFragment,
} from '../../graphql/fragments';
import { SectionType } from './types';

export type GetSectionQueryResult = {
  section: SectionType & {
    searchInfo: {
      _id: LaneType.UUID;
      defaultSearchOptions: {
        filters: {
          type: any; // TODO: fix type
          filter: any; // TODO: fix type
        };
        metatagFilters: {
          _id: LaneType.UUID;
          metatagId: LaneType.UUID;
          metatagType: any; // TODO: fix type
          valueType: string;
          value: any; // TODO: fix type
          values: any; // TODO: fix type
        };
        sorts: any; // TODO: fix type
      };
      channels: any; // TODO: fix type
    };
  };
};

const getSectionQuery = gql`
  ${MetatagFragment}
  ${ObjectMetatagFragment}

  fragment SectionChannelFragment on Channel {
    _id
    name
    slug
    type
    settings {
      multiLanguageEnabled
      channelLanguages
      enabledLanguagesForMembers
    }
    parent {
      _id
    }
    address {
      _id
      name
      street1
      city
      geo
      distance
    }
    profile {
      _id
      name
      image
      logo
    }
  }

  query getSectionQuery($sectionId: UUID!) {
    section(_id: $sectionId) {
      _id
      _created
      _updated
      name
      name_l10n
      description
      type
      object
      query
      renderType
      tags
      backgroundImage
      logo
      icon
      iconSet
      iconWeight
      color
      filters
      sorts
      groupBy
      tagsOnSection {
        id
        name
        name_l10n
        channelId
      }
      backgroundColor
      channel {
        ...SectionChannelFragment
      }
      sectionMetatags {
        ...ObjectMetatagFragment
      }
      searchInfo {
        _id
        defaultSearchOptions {
          filters {
            type
            filter
          }
          metatagFilters {
            _id
            metatagId
            metatagType
            valueType
            value
            values
          }
          sorts
        }
        channels {
          ...SectionChannelFragment
        }
      }
    }
  }
`;

export default getSectionQuery;
