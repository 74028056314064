import React from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './UsersPageCTA.scss';

type Props = {
  linkTo: string;
};

export function UsersPageCTA({ linkTo }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.buttons}>
      <Link to={linkTo}>
        <Button dataCy="inviteNewUserButton" variant="contained">
          {t('web.pages.portal.admin.users.component.inviteNewUserButton')}
        </Button>
      </Link>
    </div>
  );
}
