import { MobilePermissionEnum } from '../../../types/MobilePermissionEnum';
import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';
import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';

const OpenpathDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Openpath,
  hasCustomUI: true,
  friendlyName: 'Openpath Access Control',
  description: `Defines the properties required for an Openpath integration.`,
  category: IntegrationCategoryEnum.AccessControl,
  platforms: [PlatformEnum.Mobile],
  type: IntegrationTypeEnum.SDK,
  settings: {},
  properties: {
    regionalURL: {
      friendlyName: 'Openpath API URL',
      type: 'Option',
      packagedType: PackagedTypeEnum.Dropdown,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: [
            {
              _id: '0',
              _order: 0,
              name: 'https://api.openpath.com/',
              value: 'https://api.openpath.com/',
            },
            {
              _id: '1',
              _order: 1,
              name: 'https://api.eu.openpath.com/',
              value: 'https://api.eu.openpath.com/',
            },
          ],
        },
      ],
    },
    adminUserEmail: {
      friendlyName: 'Openpath Admin user email',
      type: 'Email',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    adminUserPassword: {
      friendlyName: 'Openpath Admin user password',
      type: 'Password',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    organizationId: {
      friendlyName: 'Openpath organization id',
      type: 'Number',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    authToken: {
      hidden: true,
      forDisplay: false,
      friendlyName: 'Auth Token',
      description: 'Auth Token field to control access of the field',
      type: 'String',
      validators: [],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
  },
  mobilePermissions: [
    MobilePermissionEnum.Location,
    MobilePermissionEnum.AlwaysLocation,
    MobilePermissionEnum.Bluetooth,
  ],
  // Google requires us to use specific language requesting background location access before we load the system prompts
  mobilePermissionsRequest:
    'To unlock doors, Workplace Access requires these device settings to be enabled, including accessing your location when the application is not in use.',
};

export default OpenpathDefinition;
