import { useEffect, useMemo, useRef, useState } from 'react';

import { DefaultLocale } from 'localization';

const testNumber = 123456.789;
const PERIOD = '.';
const COMMA = ',';

type Props = {
  min?: number;
  max?: number;
  value: number;
  locale?: string;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
  onChange?: (value: string) => void;
  onValueChange: (value: number) => void;
  allowEmptyInput?: boolean;
};

export default function useFormattedNumberInput({
  locale = DefaultLocale,
  min,
  max,
  value,
  onChange,
  onValueChange,
  minimumIntegerDigits,
  minimumFractionDigits,
  maximumFractionDigits,
  minimumSignificantDigits,
  maximumSignificantDigits,
  allowEmptyInput,
}: Props) {
  const decimalRef = useRef(PERIOD);
  const [maskedValue, setMaskedValue] = useState('0');
  // Whether user has updated the input value
  const [isPristine, setIsPristine] = useState(true);

  const numberFormat = useMemo(() => {
    const _locale = locale.replace('_', '-');

    return new Intl.NumberFormat(_locale, {
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
    }).format;
  }, [
    locale,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
  ]);

  function inputOnChange(value: any) {
    setMaskedValue(value);

    if (isPristine) {
      setIsPristine(false);
    }
  }

  const shouldDisplayEmpty = allowEmptyInput && isPristine && value === 0;

  function inputOnBlur() {
    const regex = new RegExp(`[^0-9\\-${decimalRef.current}]`, 'gi');

    let value = parseFloat(maskedValue.replace(regex, '')) || 0;

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    if (![undefined, null].includes(min)) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      value = Math.max(min, value);
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    if (![undefined, null].includes(max)) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      value = Math.min(max, value);
    }

    if (onValueChange) {
      onValueChange(value);
    }

    const newMaskedValue = numberFormat(value);

    if (onChange) {
      onChange(newMaskedValue);
    }

    setMaskedValue(newMaskedValue);
  }

  useEffect(() => {
    setMaskedValue(numberFormat(value || 0));
  }, [value]);

  useEffect(() => {
    // no easy way to know if we use decimals or commas for numbers other
    // than this test.
    const _locale = locale.replace('_', '-');
    const testString = new Intl.NumberFormat(_locale).format(testNumber);

    // if a period appears before a comma, then commas are used as decimal
    // places and we want to stripe out periods.  or vice versa.
    // i.e. $123.456,79 strip out periods.  $123,456.79 strip out commas
    decimalRef.current =
      testString.indexOf(PERIOD) > testString.indexOf(COMMA) ? PERIOD : COMMA;

    setMaskedValue(numberFormat(value || 0));
  }, [locale]);

  return {
    value,
    maskedValue: shouldDisplayEmpty ? undefined : maskedValue,
    isPristine,
    inputOnChange,
    inputOnBlur,
  };
}
