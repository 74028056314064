import { DateTime } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import isTimeUnavailable from './isTimeUnavailable';

export type TimeSliderOptionType = {
  // the time this slot starts
  startTime: DateTime;
  // the time this slot ends
  endTime: DateTime;
  // the middle of this slot, useful for comparisons
  middleTime: DateTime;
  // is this slot available or not
  unavailable: boolean;
  // the slot size in minutes
  slotSize: number;
  // the slot number
  slot: number;
};

export default function mapOptions({
  date,
  slots,
  slotSize,
  minSlot,
  maxSlot,
  unavailableDateRanges,
}: {
  date: DateTime;
  slots: number;
  slotSize: number;
  minSlot: number;
  maxSlot: number;
  unavailableDateRanges: DateRangeType[];
}): TimeSliderOptionType[] {
  const referenceDate = date.startOf('day');

  return new Array(slots).fill(slotSize).map((slotSize, slot) => {
    const startTime = referenceDate.set({ minute: slot * slotSize });
    const endTime = referenceDate.set({ minute: (slot + 1) * slotSize });
    const middleTime = referenceDate.set({
      minute: slot * slotSize + Math.floor(slotSize / 2),
    });

    const unavailable = isTimeUnavailable({
      time: middleTime,
      minTime: minSlot * slotSize,
      maxTime: maxSlot * slotSize,
      unavailableDateRanges,
    });

    return { startTime, endTime, middleTime, unavailable, slotSize, slot };
  });
}
