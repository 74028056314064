import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'lane-shared/types/integrations/IntegrationEnums';

export type OutlookCalendarSettingsType = {
  clientId: string;
  clientSecret: string;
  tenantId: string;
};

const OutlookCalendarDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.OutlookCalendar,
  hasCustomUI: false,
  friendlyName: 'Outlook Calendar',
  description:
    'Defines the properties required for an integration with Outlook Calendar.',
  category: IntegrationCategoryEnum.RoomBooking,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    clientId: {
      friendlyName: 'Application ID',
      description: 'ID of a registered application in Azure Active Directory',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: '',
        },
      ],
    },
    clientSecret: {
      friendlyName: 'Secret for the client app',
      description: 'Secret key generated for the registered application',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: '',
        },
      ],
    },
    tenantId: {
      friendlyName: 'Tenant ID',
      description: 'Tenant ID in Azure Active Directory',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: '',
        },
      ],
    },
  },
};

export default OutlookCalendarDefinition;
