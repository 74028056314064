import { useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getRelatedSpacesList } from 'lane-shared/graphql/query';

import ChannelAdminContext from 'pages/portal/admin/channel/ChannelAdminContext';

const ELEMENTS_PER_PAGE = 25;

const START_PAGE = 0;

type RelatedSpaceType = {
  _id: LaneType.UUID;
  name: string;
};

type Props = {
  limit?: number;
  contentId: LaneType.UUID | string;
};

type RelatedSpaceQueryType = {
  contentForRelatedSpaces: {
    items: Array<RelatedSpaceType>;
    pageInfo: {
      start: number;
      perPage: number;
      total: number;
    };
  };
};

export default function useRelatedSpacesList({
  limit = ELEMENTS_PER_PAGE,
  contentId,
}: Props) {
  const { channel } = useContext(ChannelAdminContext);

  const [searchText, setSearchText] = useState('');
  const [cursor, setCursor] = useState(START_PAGE);
  const [relatedSpaces, setRelatedSpaces] = useState<Array<RelatedSpaceType>>(
    []
  );

  const [
    getRelatedSpaces,
    { loading: isLoading },
  ] = useLazyQuery<RelatedSpaceQueryType>(getRelatedSpacesList, {
    variables: {
      channelId: channel?._id,
      contentId,
      search: {
        name: {
          type: 'like',
          value: searchText,
        },
      },
      pagination: {
        start: START_PAGE,
        perPage: limit,
      },
    },
    onCompleted: data => {
      setRelatedSpaces(prevRelatedSpaces => [
        ...prevRelatedSpaces,
        ...(data.contentForRelatedSpaces?.items || []),
      ]);

      setCursor(data.contentForRelatedSpaces?.pageInfo?.start);
    },
  });

  useEffect(() => {
    getRelatedSpaces({
      variables: {
        channelId: channel?._id,
        contentId,
        search: {
          name: {
            type: 'like',
            value: searchText,
          },
        },
        pagination: { start: cursor, perPage: limit },
      },
    });
  }, [channel, contentId]);

  function _resetData() {
    setRelatedSpaces([]);
    setCursor(START_PAGE);
  }

  function loadNext() {
    if (!isLoading) {
      getRelatedSpaces({
        variables: {
          channelId: channel?._id,
          contentId,
          search: {
            name: {
              type: 'like',
              value: searchText,
            },
          },
          pagination: { start: cursor + limit, perPage: limit },
        },
      });
    }
  }

  function onSearchChange(searchText: string) {
    _resetData();
    setSearchText(searchText);

    getRelatedSpaces({
      variables: {
        channelId: channel?._id,
        contentId,
        search: {
          name: {
            type: 'like',
            value: searchText,
          },
        },
        pagination: { start: START_PAGE, perPage: limit },
      },
    });
  }

  return {
    relatedSpaces,
    isLoading,
    loadNext,
    onSearchChange,
  };
}
