import { useEffect } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import {
  PublicUserFragment,
  FullProfileWithThemeFragment,
  AddressFragment,
  ObjectMetatagFragment,
  MetatagFragment,
  FullContentForAdminFragment,
  ThemeFragment,
} from '../graphql/fragments';
import { useMultiLanguage } from './useMultiLanguage';

const contentQuery = gql`
  ${PublicUserFragment}
  ${AddressFragment}
  ${MetatagFragment}
  ${ObjectMetatagFragment}
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${FullContentForAdminFragment}

  query getContentForAdmin($id: UUID!) {
    content(_id: $id) {
      ...FullContentForAdminFragment
      placements {
        _id
        type
        channel {
          _id
        }
        content {
          _id
        }
        groupRole {
          _id
        }
        user {
          _id
        }
      }
      sectionContent {
        _id
        section {
          _id
          name
          channel {
            _id
            settings {
              enabledLanguagesForMembers
              multiLanguageEnabled
              channelLanguages
            }
          }
        }
      }
    }
  }
`;

export default function useContentForAdmin({
  contentId,
  disableLocalization = false,
}: {
  contentId: string;
  disableLocalization?: boolean;
}) {
  const [fetchContent, { data, loading, error, refetch }] = useLazyQuery(
    contentQuery,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
    }
  );
  const { translate } = useMultiLanguage();

  useEffect(() => {
    if (contentId) {
      fetchContent({
        variables: {
          id: contentId,
        },
      });
    }
  }, [contentId]);

  const content = disableLocalization
    ? data?.content
    : translate({
        model: data?.content,
      });

  return {
    content,
    loading,
    error,
    refetch,
  };
}
