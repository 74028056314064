export enum ContentMenuTabsEnum {
  Features = 'Features',
  Data = 'Data',
  Layout = 'Layout',
  Workflows = 'Workflows',
}

export const defaultInteractiveMenu = [
  ContentMenuTabsEnum.Features,
  ContentMenuTabsEnum.Data,
  ContentMenuTabsEnum.Layout,
  ContentMenuTabsEnum.Workflows,
];
export const defaultInteractiveTemplateMenu = [
  ContentMenuTabsEnum.Features,
  ContentMenuTabsEnum.Data,
  ContentMenuTabsEnum.Layout,
];
export const defaultNonInteractiveMenu = [
  ContentMenuTabsEnum.Features,
  ContentMenuTabsEnum.Layout,
];

export const contentMenuTabLabels = {
  [ContentMenuTabsEnum.Features]:
    'shared.admin.channel.content.menuTabs.label.features',
  [ContentMenuTabsEnum.Data]:
    'shared.admin.channel.content.menuTabs.label.data',
  [ContentMenuTabsEnum.Layout]:
    'shared.admin.channel.content.menuTabs.label.layout',
  [ContentMenuTabsEnum.Workflows]:
    'shared.admin.channel.content.menuTabs.label.workflows',
};
