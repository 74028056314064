import React, { useState, useEffect, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { useQuery } from '@apollo/client';
import { useChannelProfileQuery } from 'lane-shared/hooks';
import ContentRendererContext from 'lane-shared/contexts/ContentRendererContext';
import {
  WorkOrderModuleSettings,
  ServiceRequestType,
} from 'lane-shared/domains/workOrder';
import { channelModuleByChannelId } from 'lane-shared/graphql/modules';
import { getValidationMessages } from 'lane-shared/helpers';
import {
  AttachmentImageContentTypeEnum,
  AttachmentDocumentContentTypeEnum,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import {
  Input,
  Dropdown,
  TextArea,
  AddAttachment,
} from 'lane-web/src/components';

import { Item } from 'components/form/Dropdown/Dropdown';

import { CustomQuestionInput } from './customQuestionInput';
import { CustomQuestion } from 'graphql-query-contracts';

import styles from './index.scss';

/**
 * Allows an end user interacting with content to create a service request
 *
 */
export function ServiceRequestSubmissionForm({
  className,
  style,
  onChange,
  channelId,
}: {
  className?: string;
  style?: React.CSSProperties;
  onChange: (value: ServiceRequestType) => void;
  channelId: string;
}) {
  const { t } = useTranslation();
  const [_, setIsPristine] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<any>();
  const { submitAttempted } = useContext(ContentRendererContext);
  let categoryOptions: Item<string>[] = [];
  let locationOptions: Item<string>[] = [];
  let issueOptions: Item<string>[] = [];
  const [serviceRequest, setServiceRequest] = useState<ServiceRequestType>({
    customQuestions: [],
    attachmentRefId: uuid(),
  });

  const { channel } = useChannelProfileQuery({
    channelId,
  });

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  const ServiceRequestSchema = yup.object().shape({
    issue: yup.string().required(),
    category: yup.string().required(),
    location: yup.string().required(),
    description: yup.string().required(t`This field is required.`),
    floor: yup.string().optional(),
    suite: yup.string().optional(),
    customQuestions: yup.array(),
  });

  useEffect(() => {
    validate();
  }, [serviceRequest]);

  const onServiceRequestChange = (val: ServiceRequestType) => {
    setIsPristine(false);
    setServiceRequest(val);
    onChange(val);
  };

  async function validate() {
    try {
      setValidationError(null);
      await ServiceRequestSchema.validate(serviceRequest, {
        abortEarly: false,
      });
    } catch (ex) {
      setValidationError(ex);
    }
  }

  const { data: moduleResp } = useQuery(channelModuleByChannelId, {
    variables: {
      channelId: channel?._id,
      contentType: ContentTypeEnum.WorkOrder,
    },
  });
  const settings: WorkOrderModuleSettings =
    moduleResp?.channelModuleByChannelId?.settings;

  const selectedCategory = settings?.categories?.find(
    category => category.firendlyName === serviceRequest?.category
  );
  const onCustomQuestionEdit = (customQuestion: CustomQuestion) => {
    const newCustomQuestions = serviceRequest.customQuestions || [];
    const existingAnswerIndex = newCustomQuestions?.findIndex(
      existingCustomQuestion =>
        existingCustomQuestion.Question === customQuestion.Question
    );
    if (existingAnswerIndex === -1) {
      newCustomQuestions.push(customQuestion);
    } else {
      newCustomQuestions[existingAnswerIndex] = customQuestion;
    }
    onServiceRequestChange({
      ...serviceRequest,
      customQuestions: newCustomQuestions,
    });
  };
  categoryOptions = settings?.categories
    ? settings.categories.map((category: { firendlyName: string }) => ({
        label: category.firendlyName,
        value: category.firendlyName,
      }))
    : [];
  locationOptions = settings?.locations
    ? settings.locations.map((locationOption: string) => ({
        label: locationOption,
        value: locationOption,
      }))
    : [];
  issueOptions = selectedCategory
    ? selectedCategory.issues.map(issue => ({
        label: issue,
        value: issue,
      }))
    : [];
  const setCategoryAssignment = (value: { value: string }) => {
    const category = settings?.categories?.find(
      category => category.firendlyName === value.value
    );
    if (category?.assigneeGroups && category?.assigneeGroups?.length) {
      onServiceRequestChange({
        ...serviceRequest,
        category: value.value,
        assigneeGroups: category.assigneeGroups,
        assignee: '',
      });
    } else if (category?.assignee && category.assignee !== '') {
      onServiceRequestChange({
        ...serviceRequest,
        category: value.value,
        assignee: category.assignee,
        assigneeGroups: [],
      });
    } else {
      onServiceRequestChange({ ...serviceRequest, category: value.value });
    }
  };

  return (
    <div className={cx(className)} style={style}>
      <div
        data-cy="service-request-category-input"
        className={styles.fieldContainer}
      >
        <div className={styles.fieldLabel}>
          <span>{t`web.admin.serviceRequest.form.category`}</span>
          <span className={styles.required}>*</span>
        </div>
        <Dropdown
          items={categoryOptions}
          value={serviceRequest?.category}
          initialValue={serviceRequest?.category}
          onChange={setCategoryAssignment}
        />
      </div>
      {issueOptions.length > 0 && (
        <div
          data-cy="service-request-issue-input"
          className={styles.fieldContainer}
        >
          <div className={styles.fieldLabel}>
            <span>{t`web.admin.serviceRequest.form.issue`}</span>
            <span className={styles.required}>*</span>
          </div>
          <Dropdown
            items={issueOptions}
            value={serviceRequest?.issue}
            onChange={({ value }: any) => {
              onServiceRequestChange({ ...serviceRequest, issue: value });
            }}
          />
        </div>
      )}
      <div
        data-cy="service-reqeust-description-input"
        className={styles.fieldContainer}
      >
        <div className={styles.fieldLabel}>
          <span>{t`web.admin.serviceRequest.form.description`}</span>
          <span className={styles.required}>*</span>
        </div>
        <TextArea
          value={serviceRequest?.description || ''}
          onChange={(value: any) => {
            onServiceRequestChange({ ...serviceRequest, description: value });
          }}
          showLengthIndicator
          minRows={3}
          errors={
            Boolean(serviceRequest?.description || submitAttempted) &&
            getValidationMessages(validationError, 'description')
          }
          placeholder={t`web.admin.serviceRequest.form.description.placeholder`}
        />
      </div>
      <div
        data-cy="service-reqeust-location-input"
        className={cx(styles.fieldContainer, styles.locationInput)}
      >
        <div className={styles.fieldLabel}>
          <span>{t`web.admin.serviceRequest.form.Location`}</span>
          <span className={styles.required}>*</span>
        </div>

        <Dropdown
          items={locationOptions}
          value={serviceRequest?.location}
          interfaceStyle="light"
          onChange={({ value }: any) => {
            onServiceRequestChange({ ...serviceRequest, location: value });
          }}
          isFullWidth
        />
      </div>
      {isPropertyChannel && (
        <>
          <div
            data-cy="service-reqeust-floor-input"
            className={styles.fieldContainer}
          >
            <div className={styles.fieldLabel}>
              <span>{t`web.admin.serviceRequest.form.floor`}</span>
            </div>
            <Input
              placeholder={t`web.admin.serviceRequest.form.floor.hint`}
              value={serviceRequest?.floor}
              onChange={(value: any) => {
                onServiceRequestChange({ ...serviceRequest, floor: value });
              }}
            />
          </div>
          <div
            data-cy="service-reqeust-suite-input"
            className={styles.fieldContainer}
          >
            <div className={styles.fieldLabel}>
              <span>{t`web.admin.serviceRequest.form.suite`}</span>
            </div>
            <Input
              placeholder={t`web.admin.serviceRequest.form.suite.hint`}
              value={serviceRequest?.suite}
              onChange={(value: any) => {
                onServiceRequestChange({ ...serviceRequest, suite: value });
              }}
            />
          </div>
        </>
      )}
      <div className={styles.fieldContainer}>
        <div className={styles.fieldLabel}>
          <span>{t`web.admin.serviceRequest.form.attachment.heading`}</span>
        </div>
        <div className={styles.fieldLabelDisclaimer}>
          <span>{t`web.admin.serviceRequest.form.attachment.disclaimer`}</span>
        </div>
        <div className={styles.fieldAttachments}>
          <AddAttachment
            entityId={serviceRequest.attachmentRefId!}
            entityType={ContentTypeEnum.WorkOrder}
            editMode={false}
            variant={AttachmentVariantEnum.WorkOrder}
            acceptedFileTypes={[
              ...Object.values(AttachmentImageContentTypeEnum),
              AttachmentDocumentContentTypeEnum.pdf,
            ]}
          />
        </div>
      </div>
      {settings?.customQuestions &&
        settings.customQuestions.map(
          (customQuestion: PropertyType, index: number) => (
            <CustomQuestionInput
              customQuestion={customQuestion}
              key={index}
              onAnswer={onCustomQuestionEdit}
            />
          )
        )}
    </div>
  );
}
