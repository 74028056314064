import { useEffect, useCallback, useState } from 'react';
import { getClient } from 'lane-shared/apollo';
import { ApolloError } from '@apollo/client';
import { DateTime } from 'luxon';
import { getReservablesOnChannelQuery } from 'graphql-queries';
import { ReservableEdge } from 'graphql-query-contracts';

type Props = {
  channelId: string;
  referenceDate: Date;
  pageSize: number;
};

export function useReservablesOnChannel({
  channelId,
  pageSize,
  referenceDate,
}: Props) {
  const client = getClient();
  const [error, setError] = useState<ApolloError | null>(null);
  const [reservablesOnChannel, setReservablesOnChannel] = useState<
    ReservableEdge[]
  >([]);

  const fetchReservablesOnChannel = useCallback(async () => {
    try {
      const { data, error } = await client.query<{
        channel: { reservablesOnChannel: { edges: ReservableEdge[] } };
      }>({
        query: getReservablesOnChannelQuery,
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          pageSize,
          reservablesSearch: {
            unpublishDate: {
              value: referenceDate,
            },
          },
          startDate: referenceDate,
          endDate: DateTime.fromJSDate(referenceDate)
            .plus({ hours: 24 })
            .toJSDate(),
        },
      });

      if (error) {
        throw error;
      }

      setReservablesOnChannel(data.channel?.reservablesOnChannel?.edges || []);
    } catch (err) {
      setError(err);
    }
  }, [client, channelId, pageSize, referenceDate]);

  useEffect(() => {
    fetchReservablesOnChannel();
  }, [fetchReservablesOnChannel]);

  return {
    reservablesOnChannel,
    fetchReservablesOnChannel,
    error,
  };
}
