import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const GoogleWorkplaceDefinition: IntegrationDefinition = {
  hasCustomUI: false,
  name: IntegrationProviderEnum.GoogleWorkplace,
  friendlyName: 'Google Workplace',
  description: `Links this Channel to a Google Workplace id so that users can auto-join this channel when they sign up with a Google Workplace OAuth`,
  category: IntegrationCategoryEnum.Authentication,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    tenantId: {
      friendlyName: 'Google Workplace Tenant Id',
      type: 'String',
    },
  },
  mobilePermissions: [],
};

export default GoogleWorkplaceDefinition;
