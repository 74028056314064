import React from 'react';

import cx from 'classnames';

import { IconButton } from '../IconButton/IconButton';
import { H4 } from '../Typography/Typography';

import styles from './ModalHeader.scss';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

type ModalHeaderProps<T> = {
  size: 'large' | 'small';
  title?: string;
  onClose: (...args: any) => void;
  onBack?: (...args: any) => void;
  modalHistory?: T[];
  closeButtonSize?: 'small' | 'medium' | 'large';
  isCloseButtonHidden?: boolean;
};

export const ModalHeader = <T,>({
  title,
  size,
  onBack,
  onClose,
  modalHistory = [],
  closeButtonSize = 'medium',
  isCloseButtonHidden,
}: ModalHeaderProps<T>) => {
  const showNavigation = Boolean(onBack) && modalHistory.length !== 0;

  return (
    <div className={cx(styles.modalHeader, styles[size])}>
      {size === 'small' ? (
        <>
          {showNavigation && (
            <IconButton
              data-cy="backButton"
              className={styles.navigationButton}
              onClick={onBack}
              icon="chevron-left"
            />
          )}
          {title && <H4 className={styles.title}>{title}</H4>}
        </>
      ) : (
        <span className={styles.largeTitle}>
          {showNavigation && (
            <IconButton onClick={onBack} icon="chevron-left" text="Back" />
          )}
          {title && <H4>{title}</H4>}
        </span>
      )}
      {!isCloseButtonHidden && (
        <IconButton
          className={styles.closeButton}
          type={FONT_AWESOME_REGULAR}
          onClick={onClose}
          icon="times"
          data-testid="closeButton"
          data-cy="closeButton"
          size={closeButtonSize}
        />
      )}
    </div>
  );
};
