import { useContext } from 'react';

import TopLevelComponentContext from '../../contexts/TopLevelComponentContext';

export default function useToast() {
  const { showToast, hideToast } = useContext(TopLevelComponentContext);

  return {
    showToast,
    hideToast,
  };
}
