import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getAdminClient } from '../apollo';
import {
  PublicUserFragment,
  ThemeFragment,
  FullProfileWithThemeFragment,
  AddressFragment,
} from '../graphql/fragments';

const query = gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${AddressFragment}

  query getWhiteLabel($whitelabelId: UUID!) {
    whitelabel(_id: $whitelabelId) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      address {
        ...AddressFragment
      }
      profile {
        ...FullProfileWithThemeFragment
      }
      name
      description
      instance
      channels {
        _id
        isPrimary
        channel {
          _id
        }
      }
      restrict
      commonName
      appStoreId
      sha256Fingerprint
      mobileIos
      mobileAndroid
      privacyPolicyUrls
      termsAndConditionsUrls
      disableLocationPrecision
      eula
      hosts
      url
      urlDevelopment
      urlStaging
      typography
      settings
      oAuthConfig {
        provider
        iosClientId
        androidClientId
        iosRedirectUrl
        androidRedirectUrl
        webClientId
        webClientSecret
        webRedirectUrl
        tenantId
        enabled
        buttonLabel
      }
      locale
      operatingName
      shortOperatingName
      is2FAEnabled
      isAppleIDEnabled
      isMagicLinkEnabled
      complianceContact
    }
  }
`;

export default function useWhitelabelQuery({ whitelabelId }: any) {
  const { data, loading, error } = useQuery(query, {
    client: getAdminClient(),
    skip: !whitelabelId,
    variables: {
      whitelabelId,
    },
  });

  const whitelabel = data?.whitelabel;

  return { whitelabel, loading, error };
}
