import gql from 'graphql-tag';

const searchAggregatedSectionsContentQuery = gql`
  query searchCombinedSectionsContentQuery(
    $sectionIds: [UUID!]!
    $searchOptions: SearchOptionsInput
  ) {
    combinedSectionsContent(
      sectionIds: $sectionIds
      searchOptions: $searchOptions
    ) {
      _id
      _created
      _updated
      _order
      content {
        _id
        _created
        _updated
        name
        data
        description
        subtitle
        slug
        type
        tags
        category
        logo
        color
        geo
        isInteractive
        backgroundImage
        icon
        iconSet
        iconWeight
        backgroundColor
        liveDate
        startDate
        endDate
        interactionEndDate
        version
        renderer
        externalUrl {
          _id
          url
          openingMode
        }
        deepLink {
          _id
          deepLinkUrl
          appStoreUrl
          googlePlayStoreUrl
        }
        channel {
          _id
        }
        card {
          _id
        }
        state
        integration {
          _id
          settings
          integration {
            _id
            name
            settings
            platforms
          }
        }
        features {
          _id
          type
          feature
        }
        contentMetatags {
          _id
          value
          metatag {
            _id
          }
        }
        info {
          _id
          rating
          viewed
          details
          hidden
          deleted
          pinned
          starred
          liked
        }
      }
    }
  }
`;

export default searchAggregatedSectionsContentQuery;
