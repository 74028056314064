import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TaxDetails,
  convertTaxAmountTypeEnum,
  Currency,
  convertCurrencyEnum,
  DEFAULT_TAX_DETAILS,
} from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import {
  createBillingPaymentSettings,
  updateBillingPaymentSettings,
  getBillingPaymentSettings,
} from './helpers';

const DEFAULT_CURRENCY = Currency.CURRENCY_USD;
const DEFAULT_DAYS = 30;

export type HookOptions = {
  channelId: string | undefined;
};

export function useBillingPaymentSettingsForChannel({
  channelId,
}: HookOptions) {
  const [error, setError] = useState<Error | null>(null);
  const [settingsUnchanged, setSettingsUnchanged] = useState<boolean>(true);

  const [taxDetails, setTaxDetails] = useState<TaxDetails>(DEFAULT_TAX_DETAILS);
  const [currency, setCurrency] = useState<Currency>(DEFAULT_CURRENCY);
  const [invoiceDueInDays, setInvoiceDueInDays] = useState<number>(
    DEFAULT_DAYS
  );

  const { t } = useTranslation();
  const defaultInvoiceDisplayName = t('abp.invoice.details.heading');

  const [invoiceDisplayName, setInvoiceDisplayName] = useState<string>(
    defaultInvoiceDisplayName
  );

  const [isInvoicingDisabled, setIsInvoicingDisabled] = useState(false);

  function getSettings() {
    return {
      taxDetails,
      currency,
      invoiceDueInDays,
      invoiceDisplayName,
      invoicingDisabled: isInvoicingDisabled,
    };
  }

  async function updatePaymentSettings(): Promise<boolean> {
    if (!channelId) {
      setError(new Error('abp.errors.generic.channelIdMissing'));
      return false;
    }

    let channelUUID;
    try {
      channelUUID = convertToUUID(channelId);
    } catch (err: any) {
      console.error(`BPError - getPaymentSettings convertToUUID error: ${err}`);
      setError(new Error('abp.errors.generic.channelIdInvalid'));
      return false;
    }

    try {
      await updateBillingPaymentSettings(channelUUID, getSettings());

      setSettingsUnchanged(true);
      return true;
    } catch (err: any) {
      console.error(`BPError - updateBillingPaymentSettings error: ${err}`);
      setError(new Error(err.message));
    }
    return false;
  }

  function updateTaxDetails(value: TaxDetails) {
    setTaxDetails(value);
    setSettingsUnchanged(false);
  }

  function updateCurrency(value: Currency) {
    setCurrency(value);
    setSettingsUnchanged(false);
  }

  function updateInvoiceDueInDays(value: number | null) {
    setInvoiceDueInDays(value ?? DEFAULT_DAYS);
    setSettingsUnchanged(false);
  }

  function updateInvoiceDisplayName(value: string) {
    setInvoiceDisplayName(value);
    setSettingsUnchanged(false);
  }

  function updateIsInvoicingDisabled(value: boolean) {
    setIsInvoicingDisabled(value);
    setSettingsUnchanged(false);
  }

  async function getPaymentSettings() {
    let channelUUID;
    try {
      channelUUID = convertToUUID(channelId);
    } catch (err: any) {
      console.error(`BPError - getPaymentSettings convertToUUID error: ${err}`);
      setError(new Error('abp.errors.generic.channelIdInvalid'));
      return;
    }

    try {
      let channelSettings = await getBillingPaymentSettings(channelUUID);
      if (!channelSettings?.groupSettingId) {
        channelSettings = await createBillingPaymentSettings(
          channelUUID,
          getSettings()
        );
      }

      setTaxDetails({
        ...DEFAULT_TAX_DETAILS,
        ...channelSettings?.settings?.taxDetails,
        taxAmountType: convertTaxAmountTypeEnum(
          channelSettings?.settings?.taxDetails?.taxAmountType,
          DEFAULT_TAX_DETAILS.taxAmountType
        ),
      });
      setCurrency(
        convertCurrencyEnum(
          channelSettings?.settings?.currency,
          DEFAULT_CURRENCY
        )
      );
      setInvoiceDueInDays(channelSettings?.settings?.invoiceDueInDays);
      setInvoiceDisplayName(
        channelSettings?.settings?.invoiceDisplayName ||
          defaultInvoiceDisplayName
      );
      setIsInvoicingDisabled(channelSettings?.settings?.invoicingDisabled);
    } catch (err: any) {
      if (err instanceof Error) {
        console.error(`BPError - getBillingPaymentSettings error: ${err}`);
        setError(new Error(err.message));
      } else {
        console.error(
          `BPError - getBillingPaymentSettings non-standard error: ${{
            ...err,
          }}`
        );
        setError(new Error({ ...err }));
      }
    }
  }

  useEffect(() => {
    if (channelId) {
      getPaymentSettings();
    }
  }, [channelId]);

  return {
    paymentSettingsError: error,
    currency,
    updateCurrency,
    taxDetails,
    updateTaxDetails,
    invoiceDueInDays,
    updateInvoiceDueInDays,
    settingsUnchanged,
    updatePaymentSettings,
    invoiceDisplayName,
    updateInvoiceDisplayName,
    isInvoicingDisabled,
    updateIsInvoicingDisabled,
  } as const;
}
