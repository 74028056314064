import { DraftContentType } from '../../types/content/DraftContent';

export const SubtitleOptions = {
  DATE: null,
  NONE: '\t',
  CUSTOM: '',
} as const;

export const deriveSubtitleFromContent = (
  content: Pick<
    DraftContentType,
    'subtitle' | 'startDate' | 'endDate' | 'isEvent'
  >
) => {
  let subtitle;
  if (content.subtitle) subtitle = content.subtitle;
  else if (content.isEvent) subtitle = SubtitleOptions.DATE;
  else subtitle = SubtitleOptions.NONE;
  return subtitle;
};

export const isSubtitleDate = (subtitle: string | null | undefined) =>
  typeof subtitle !== 'string';

export const isSubtitleNone = (subtitle: string | null | undefined) =>
  subtitle === SubtitleOptions.NONE;

export const isSubtitleCustom = (subtitle: string | null | undefined) =>
  !isSubtitleDate(subtitle) && !isSubtitleNone(subtitle);
