import { ReservableBookingItem } from '../hooks/useGetReservableBookings';

export function getEarliestJoinedCompanyOrEmptyString(
  companies: ReservableBookingItem['companies']
) {
  const companiesJoinedAtSinceEpoch = companies.map(c =>
    new Date(c.joinedAt).valueOf()
  );

  const earliestJoinedAtSinceEpoch = Math.min(...companiesJoinedAtSinceEpoch);

  const company = companies.find(bookingCompany => {
    return (
      new Date(bookingCompany.joinedAt).valueOf() === earliestJoinedAtSinceEpoch
    );
  });

  return company?.name || '';
}
