import React, { useMemo, useState } from 'react';

import { Dropdown } from 'components';

import { DataValidationRuleValueProps } from 'components/workflows/dataValidationSchema/type';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useTranslation } from 'react-i18next';
import ValidationMessage from 'components/general/ValidationMessage';

import style from '../DataValidationRule.scss';

export enum DropdownValueDataValidationOperatorEnum {
  ValueEquals = 'Value Equals',
}

const DropdownDataValidationRuleValue: React.FunctionComponent<DataValidationRuleValueProps> = ({
  maybeDraftRule,
  property,
  onRuleValueUpdated,
  editMode,
  validationError,
}: DataValidationRuleValueProps) => {
  const isNewWorkflowsUIEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportNewWorkflowsUI,
    false
  );
  const { t } = useTranslation();
  const [selectedOperator, setSelectedOperator] = useState(
    maybeDraftRule.validator?.name === 'In'
      ? DropdownValueDataValidationOperatorEnum.ValueEquals
      : DropdownValueDataValidationOperatorEnum.ValueEquals
  );

  const inValidatorOptions = useMemo(() => {
    const inValidator = property.validators?.find(
      validator => validator.name === 'In'
    );
    return (inValidator?.value || []) as Array<{
      _id: string;
      name: string;
      value: string;
    }>;
  }, [property]);

  function onOperatorSelected({
    value,
  }: {
    value: DropdownValueDataValidationOperatorEnum;
  }) {
    setSelectedOperator(value);
  }

  function onValidatorValueUpdated(item: any) {
    onRuleValueUpdated({
      name: 'In',
      value: [item.value],
    });
  }

  const isInvalidDataValue =
    isNewWorkflowsUIEnabled &&
    editMode &&
    validationError &&
    validationError.path === 'validator';

  return (
    <>
      <div className={style.row}>
        {!isNewWorkflowsUIEnabled && (
          <div className={style.label}>
            {t(
              'web.admin.content.editor.dataValidationRule.workFlowRuleDropDownOperator'
            )}
          </div>
        )}
        <Dropdown
          className={
            isNewWorkflowsUIEnabled
              ? style.workflowRuleSelectorInput
              : style.dataFieldSelector
          }
          items={Object.values(DropdownValueDataValidationOperatorEnum).map(
            operator => ({
              label: operator,
              value: operator,
            })
          )}
          onChange={onOperatorSelected}
          value={selectedOperator}
          disabled={!editMode}
          fixedLabel={isNewWorkflowsUIEnabled}
          label={
            isNewWorkflowsUIEnabled
              ? t(
                  'web.admin.content.editor.dataValidationRule.workFlowRuleDropDownOperatorUpdated'
                )
              : undefined
          }
        />
      </div>
      <div className={style.row}>
        {!isNewWorkflowsUIEnabled && (
          <div className={style.label}>
            {t(
              'web.admin.content.editor.dataValidationRule.workFlowRuleDropDownValue'
            )}
          </div>
        )}
        <Dropdown
          className={
            isNewWorkflowsUIEnabled
              ? style.workflowRuleSelectorInput
              : style.dataFieldSelector
          }
          items={inValidatorOptions.map(option => ({
            label: option.value,
            value: option,
          }))}
          onChange={onValidatorValueUpdated}
          value={inValidatorOptions.find(
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            option => option._id === maybeDraftRule.validator?.value[0]._id
          )}
          disabled={!editMode}
          fixedLabel={isNewWorkflowsUIEnabled}
          label={
            isNewWorkflowsUIEnabled
              ? t(
                  'web.admin.content.editor.dataValidationRule.workFlowRuleDropDownValueUpdated'
                )
              : undefined
          }
        />
        {isInvalidDataValue && (
          <ValidationMessage
            doTranslation={false}
            errors={[validationError.message]}
          />
        )}
      </div>
    </>
  );
};

export default DropdownDataValidationRuleValue;
