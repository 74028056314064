import { useQuery } from '@apollo/client';
import {
  queryChannelUsersByGroupRole,
  ChannelUsersByGroupRole,
} from 'lane-shared/graphql/query';

export const usePermissionGroupMembers = (groupRoleId: string) => {
  const {
    data: members,
    loading: membersLoading,
    refetch,
  } = useQuery<ChannelUsersByGroupRole>(queryChannelUsersByGroupRole, {
    skip: !groupRoleId,
    variables: {
      groupRoleId,
      skipPagination: true,
    },
  });

  return {
    members,
    membersLoading,
    refetch,
  };
};
