import { getClient } from 'lane-shared/apollo';
import { appleIDClientSecret } from 'lane-shared/graphql/user';

type JWT = string;

export async function getAppleIDClientSecret(clientId: string) {
  const { data } = await getClient().query<{ appleIDClientSecret: JWT }>({
    query: appleIDClientSecret,
    variables: {
      clientId,
    },
  });

  return data.appleIDClientSecret;
}
