import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { LONG_DATE } from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { ShopifyBurstReviewSectionTitle } from 'lane-shared/types/integrations/ShopifyBurst';

import { Flex } from 'components/layout';
import { M } from 'components/typography';

import ReviewData from './ReviewData';

type Props = {
  starts: string | Date;
  ends: string | Date;
  timeZone?: string;
  onClick?: () => void;
};

export default function ReviewDate({ starts, ends, timeZone, onClick }: Props) {
  const { t } = useTranslation();

  return (
    <ReviewData
      title={t(ShopifyBurstReviewSectionTitle.Date)}
      titleIcon={<Icon set="FontAwesome" name="calendar" type="far" />}
      onClick={onClick}
      isEditable
      summary={
        <Flex direction="column">
          <M variant="secondary">
            {t`Starts`}: {dateFormatter(starts, LONG_DATE, timeZone)}
          </M>
          <M variant="secondary">
            {t`Ends`}: {dateFormatter(ends, LONG_DATE, timeZone)}
          </M>
        </Flex>
      }
    />
  );
}
