import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';

type Filter = {
  value: any;
  settings: Record<string, any>;
};

const FILTERS_CONFIG: Record<string, any> = {
  groupRoles: {
    allowedOptions: [SecurityRuleTypeEnum.All, SecurityRuleTypeEnum.GroupRole],
    disableChannelSelection: true,
  },
};

export default function userFriendlyPropertyFilters(
  filters?: Record<string, any>
) {
  if (!filters) {
    return {};
  }
  return Object.keys(filters).reduce((result, key) => {
    if (FILTERS_CONFIG[key]) {
      result[key] = { value: filters[key], settings: FILTERS_CONFIG[key] };
    }

    return result;
  }, {} as Record<string, Filter>);
}
