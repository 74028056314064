import { differenceInCalendarDays, format } from 'date-fns';
import {
  DateFormatter,
  DayOfWeek,
  Formatters,
  isMatch,
  Matcher,
} from 'react-day-picker';
import { Dispatch, SetStateAction } from 'react';

import spacing from 'lane-shared/config/spacing';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

export const CALENDAR_HEIGHT = 385;
export const CALENDAR_MONTH_YEAR_HEIGHT = 280;
export const CALENDAR_WIDTH = 625;
export const DISTANCE_FROM_PARENT = `${spacing[7] + spacing[2]}px`;

function dateRangeToMatcher(dateRange: DateRangeType): Matcher {
  return { from: dateRange.startDate!, to: dateRange.endDate! };
}

function dateRangesToMatcher(dateRanges: DateRangeType[]): Matcher[] {
  return dateRanges.map(dateRange => dateRangeToMatcher(dateRange));
}

function intersectsDisabled(
  dateRange: DateRangeType,
  matcher: Matcher[]
): boolean {
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;

  if (!startDate || !endDate) {
    return false;
  }

  const days = differenceInCalendarDays(endDate, startDate);
  for (let offset = 0; offset < days; offset++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + offset);
    if (isMatch(date, matcher)) {
      return true;
    }
  }

  return false;
}

const weekends: DayOfWeek = { dayOfWeek: [0, 6] };

const formatWeekdayName: DateFormatter = (day, options): string => {
  return format(day, 'ccccc', options);
};

const formatMonthCaption: DateFormatter = (date, options): string => {
  return format(date, 'MMM y', options);
};

const formatters: Partial<Formatters> = {
  formatWeekdayName,
  formatMonthCaption,
  formatCaption: formatMonthCaption,
};

function handleOpenDropdown(
  e: MouseEvent,
  heightOfPage: number,
  dropdownHeight: number,
  handleOpen: Dispatch<
    SetStateAction<{
      opened: boolean;
      openedAbove: boolean;
      openedFromRight?: boolean;
    }>
  >,
  dropdownWidth: number,
  secondInput?: boolean
) {
  const {
    bottom: toBottom,
    height,
    width,
    left,
  } = (e.target as any).getBoundingClientRect();
  const conditionToOpenVertically =
    heightOfPage - height - dropdownHeight < toBottom;
  const conditionToOpenHorizontally = dropdownWidth - left > CALENDAR_WIDTH;
  const conditionToOpenHorizontallyWithSecondInput =
    dropdownWidth - left + width > CALENDAR_WIDTH;
  handleOpen(prev => ({
    opened: !prev.opened,
    openedAbove: conditionToOpenVertically,
    openedFromRight: !secondInput
      ? conditionToOpenHorizontally
      : conditionToOpenHorizontallyWithSecondInput,
  }));
}

export {
  handleOpenDropdown,
  dateRangeToMatcher,
  dateRangesToMatcher,
  intersectsDisabled,
  formatters,
  weekends,
  formatWeekdayName,
  formatMonthCaption,
};
