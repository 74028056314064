import React, { memo } from 'react';

import cx from 'classnames';

import { UserType } from 'lane-shared/types/User';

import UserCircleListView from '../lane/UserCircleListView';
import ContentStatusPill from './ContentStatusPill';

import styles from './TextCard.scss';

type Props = {
  className?: string;
  style?: any;
  header: string;
  subHeader?: string;
  text: string;
  backgroundColor?: string;
  color?: string;
  user?: Pick<UserType, 'role' | 'profile' | 'name' | 'online' | 'lastSeen'>;
  startDate: Date | null;
  endDate: Date | null;
};

function TextCard({
  className,
  style,
  header,
  subHeader,
  text,
  user,
  backgroundColor = '#444',
  color = 'white',
  startDate,
  endDate,
}: Props) {
  return (
    <div
      className={cx(styles.TextCard, className)}
      style={{ ...style, backgroundColor, color }}
    >
      {startDate && endDate && (
        <ContentStatusPill
          startDate={startDate}
          endDate={endDate}
          isPreview={false}
        />
      )}
      <h1>{header}</h1>
      <div className={styles.line} style={{ borderBottomColor: color }} />
      {subHeader && <p className={styles.subHeader}>{subHeader}</p>}

      <p className={styles.body}>{text}</p>
      {user && (
        <UserCircleListView user={user} className={styles.userContainer} />
      )}
    </div>
  );
}

export default memo(TextCard);
