import gql from 'graphql-tag';

import {
  ChannelFragment,
  AddressFragment,
  ProfileFragment,
} from '../fragments';

export default gql`
  ${AddressFragment}
  ${ProfileFragment}
  ${ChannelFragment}

  query ChannelsByRelationship(
    $pagination: PaginationInput
    $search: ChannelRelationshipSearch!
    $skipPagination: Boolean
  ) {
    channelsByRelationship(
      pagination: $pagination
      search: $search
      skipPagination: $skipPagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        channel {
          ...ChannelFragment
        }
        relatedTo {
          ...ChannelFragment
        }
      }
    }
  }
`;
