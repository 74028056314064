import {
  MenuFeatureItemType,
  MenuFeatureItemOptionType,
  MenuFeatureOrderItemType,
} from '../../types/features/MenuFeatureTypes';

/**
 *   Get all selected options from the menu based on the order item.
 * @returns {*}
 */
export default function getMenuFeatureSelectedOptions({
  orderItem,
  item,
}: {
  orderItem: MenuFeatureOrderItemType;
  item: MenuFeatureItemType;
}): {
  name: string;
  options: MenuFeatureItemOptionType[];
  _id: string;
}[] {
  if (!item) {
    return [];
  }

  const options = orderItem.options.map(orderOptionList => {
    // find the original option list from the menu
    const optionList = item.options.find(
      optionList => optionList._id === orderOptionList._id
    );

    if (!optionList) {
      return null;
    }

    // based on the options selected in the order, get just the selected options
    // and map them down to just names.
    const options = optionList.options.filter(option =>
      orderOptionList.options.includes(option._id)
    );

    return {
      _id: optionList._id,
      name: optionList.name,
      options,
    };
  });

  // @ts-expect-error ts-migrate(2322) FIXME: Type '({ _id: string; name: string; options: MenuF... Remove this comment to see the full error message
  return options.filter(Boolean);
}
