import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { DAY_IN_MINUTES } from '../../../../helpers/timeRangeSlider';
import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { ReservableUnitTypesEnum } from '../../../../types/features/ReservableFeatureProperties';
import { PropertiesInterface } from '../../../../types/properties/Property';

const properties: PropertiesInterface = {
  groupRole: {
    type: 'GroupRole',
  },
  maxSlots: {
    friendlyName: 'Max slots allowed',
    description: `This is the maximum number of slots the user can take at one time.  i.e. if you want this to be booked in 30 minute slots for a maximum of 2 hours, enter 4 here.`,
    type: 'Number',
    editable: true,
    default: 4,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 1,
      },
      {
        name: 'Max',
        value: DAY_IN_MINUTES,
      },
    ],
  },
  unitsAfterNowType: {
    friendlyName: 'Time After Now Unit',
    description: `This is the unit type of how many units after now is the earliest a user can book.`,
    type: 'String',
    editable: true,
    default: ReservableUnitTypesEnum.Minutes,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: [
          ReservableUnitTypesEnum.Minutes,
          ReservableUnitTypesEnum.Days,
          ReservableUnitTypesEnum.Weeks,
          ReservableUnitTypesEnum.Months,
        ],
      },
    ],
  },
  unitsAfterNow: {
    friendlyName: 'Time Units After Now',
    description: `How many time units of the given type after now is the earliest a user can book.`,
    type: 'Number',
    editable: true,
    default: 0,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 0,
      },
      {
        name: 'Max',
        value: DAY_IN_MINUTES,
      },
    ],
  },
  unitsBeforeStartType: {
    friendlyName: 'Time Before Unit',
    description: `This is the unit type of how many units before the start date a user can book.`,
    type: 'String',
    editable: true,
    default: ReservableUnitTypesEnum.Days,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: [
          ReservableUnitTypesEnum.Minutes,
          ReservableUnitTypesEnum.Days,
          ReservableUnitTypesEnum.Weeks,
          ReservableUnitTypesEnum.Months,
        ],
      },
    ],
  },
  unitsBeforeStart: {
    friendlyName: 'Time Units Before',
    description: `How many time units of the given type is the end user allowed to book before their reservation start date.`,
    type: 'Number',
    editable: true,
    default: 0,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 0,
      },
      {
        name: 'Max',
        value: DAY_IN_MINUTES,
      },
    ],
  },
};

export class ReservableFeatureRuleType extends ObjectTypeBase {
  name = 'ReservableFeatureRule';

  friendlyName = 'Reservable Feature Rule';

  contexts = [];

  get example() {
    return {
      _id: uuid(),
      groupRole: null,
      maxSlots: 1,
      unitsAfterNowType: ReservableUnitTypesEnum.Days,
      unitsAfterNow: 1,
      unitsBeforeStartType: ReservableUnitTypesEnum.Days,
      unitsBeforeStart: 1,
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      groupRole: null,
      maxSlots: 4,
      unitsAfterNowType: ReservableUnitTypesEnum.Days,
      unitsAfterNow: 0,
      unitsBeforeStartType: ReservableUnitTypesEnum.Days,
      unitsBeforeStart: 0,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties: any;
}

const definition = new ReservableFeatureRuleType();

Types.addType(definition);
export default definition;
