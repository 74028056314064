import React from 'react';

import { i18n } from 'i18next';
import { z } from 'zod';

import { Mjml, MjmlBody } from 'lane-shared/emails/MjmlComponentsV2';
import {
  EmailHorizontalRule,
  EmailSpacer,
} from 'lane-shared/emails/components';

import { EventStatus } from '../types';
import {
  EmailVisitorHeader,
  EmailVisitorFooter,
  EmailVisitorHero,
  EmailVisitorGreeting,
  EmailVisitorBuildingDetails,
  EmailVisitorPass,
  EmailVisitorAlert,
  AlertType,
} from './components';
import { EmailVisitorUpdateSummary } from './components/EmailVisitorUpdateSummary';
import {
  buildingSchema,
  hostSchema,
  visitorPassSchema,
  visitorSchema,
  credentialSchema,
  appleWalletSchema,
  calendarLinksSchema,
  changeDetailsSchema,
  whitelabelSchema,
} from './schema';

const ActivateVisitorUpdateParams = z.object({
  whitelabel: whitelabelSchema.optional(),
  building: buildingSchema,
  host: hostSchema,
  visitorPass: visitorPassSchema,
  visitor: visitorSchema,
  oldVisitorPassData: changeDetailsSchema.optional(),
  credential: credentialSchema,
  appleWallet: appleWalletSchema.optional(),
  calendarLinks: calendarLinksSchema.optional(),
});

export type EmailVisitorUpdateProps = {
  title: string;
  params: z.infer<typeof ActivateVisitorUpdateParams>;
  // because this component runs server side, we need the i18n passed in
  // from the current running context.
  i18n: i18n;
};

export function EmailVisitorInvite({
  title,
  params,
  i18n,
}: EmailVisitorUpdateProps) {
  params = ActivateVisitorUpdateParams.parse(params);

  const passIsCancelled = [
    EventStatus.EVENT_STATUS_INVALID,
    EventStatus.EVENT_STATUS_CANCELLED,
    EventStatus.EVENT_STATUS_WITHDRAWN,
  ].includes(params.visitorPass.status);

  const basicGreeting = (
    <>
      {i18n.t('shared.email.visitorManagement.visitorUpdate.greeting', {
        firstName: params.host.firstName,
      })}{' '}
      <b>{params.building.name}</b>
      {i18n.t('punctuation.period')}
    </>
  );

  const greetingWithCompany = (
    <>
      {i18n.t(
        'shared.email.visitorManagement.visitorUpdate.greeting.company.partOne',
        {
          firstName: params.host.firstName,
        }
      )}{' '}
      <b>{params.host.companyName}</b>{' '}
      {i18n.t(
        'shared.email.visitorManagement.visitorUpdate.greeting.company.partTwo'
      )}{' '}
      <b>{params.building.name}</b>
      {i18n.t('punctuation.period')}{' '}
      {i18n.t(
        'shared.email.visitorManagement.visitorUpdate.greeting.company.partThree'
      )}
    </>
  );

  const greeting =
    params.host.companyName?.length &&
    params.host.companyName !== params.building.name
      ? greetingWithCompany
      : basicGreeting;

  const dateIsUpdated = Boolean(
    params.oldVisitorPassData?.startDate || params.oldVisitorPassData?.endDate
  );
  const timeIsUpdated = Boolean(
    params.oldVisitorPassData?.startTime || params.oldVisitorPassData?.endTime
  );
  const passIsUpdated =
    Boolean(params.oldVisitorPassData?.changeNote) ||
    dateIsUpdated ||
    timeIsUpdated;

  const details = passIsCancelled || passIsUpdated ? undefined : greeting;

  return (
    <Mjml>
      <EmailVisitorHeader
        title={title}
        primaryColor={params.building.primaryColor}
      />
      <MjmlBody background-color="#F5F6F7">
        <EmailSpacer />

        <EmailVisitorHero backgroundUrl={params.building.visitorHeroUri} />

        <EmailVisitorGreeting
          salutation={i18n.t('shared.email.visitorManagement.greeting', {
            firstName: params.visitor.firstName,
          })}
          details={details}
        />

        {passIsCancelled && (
          <EmailVisitorAlert type={AlertType.error}>
            <b style={{ color: '#0F121F' }}>
              {i18n.t('shared.email.visitorManagement.visitorUpdate.cancelled')}
            </b>
          </EmailVisitorAlert>
        )}

        {passIsUpdated && (
          <>
            <EmailVisitorAlert type={AlertType.success}>
              <b style={{ color: '#0F121F' }}>
                {i18n.t(
                  'shared.email.visitorManagement.visitorAlert.pass.updated.visitor.line.one'
                )}
              </b>
              <br />
              <b style={{ color: '#0F121F' }}>
                {i18n.t(
                  'shared.email.visitorManagement.visitorAlert.pass.updated.line.two'
                )}
              </b>
              <span style={{ textTransform: 'capitalize' }}>
                {params.oldVisitorPassData?.changeNote}
              </span>
            </EmailVisitorAlert>
            <EmailVisitorUpdateSummary
              i18n={i18n}
              building={params.building}
              visitorPass={params.visitorPass}
              oldVisitorPassData={params.oldVisitorPassData!}
            />
          </>
        )}

        <EmailHorizontalRule />

        <EmailVisitorBuildingDetails i18n={i18n} building={params.building} />

        <EmailHorizontalRule />

        <EmailVisitorPass
          i18n={i18n}
          building={params.building}
          host={params.host}
          visitor={params.visitor}
          visitorPass={params.visitorPass}
          credential={passIsCancelled ? undefined : params.credential}
          appleWallet={passIsCancelled ? undefined : params.appleWallet}
          calendarLinks={passIsCancelled ? undefined : params.calendarLinks}
          passIsUpdated={passIsUpdated}
        />

        <EmailVisitorFooter
          i18n={i18n}
          primaryColor={params.building.primaryColor}
          building={params.building}
          whitelabel={params.whitelabel}
        />

        <EmailSpacer />
      </MjmlBody>
    </Mjml>
  );
}
