import * as yup from 'yup';

import { CURRENCY_CONVERSION } from '../../helpers/constants';
import { NumberTypeBase } from '../../types/baseTypes/NumberTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_NUMBER } from '../constants';

export class CurrencyType extends NumberTypeBase {
  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  group = TYPE_GROUP_NUMBER;

  name = 'Currency';

  friendlyName = 'Currency';

  get example() {
    return Math.floor(Math.random() * 50000);
  }

  get default(): any {
    return 0;
  }

  buildSchema() {
    return yup
      .number()
      .integer()
      .max(Number.MAX_SAFE_INTEGER)
      .min(-Number.MAX_SAFE_INTEGER)
      .nullable();
  }

  formatter(value: any, opts: any) {
    if (value === undefined || value === null || Number.isNaN(value)) {
      return '';
    }

    if (typeof value === 'string') {
      return value;
    }

    const numberFormat = new Intl.NumberFormat(
      opts?.locale ? [opts?.locale] : [],
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    ).format;

    try {
      return numberFormat(value / CURRENCY_CONVERSION);
    } catch (err) {
      return '';
    }
  }
}

const definition = new CurrencyType();

Types.addBaseType(definition);
export default definition;
