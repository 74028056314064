import { HeadersInit } from 'node-fetch';

import APP_URL from 'lane-shared/config/getAppUrl';

export const ENDPOINT = `${APP_URL}/api/v5/buildingEnginesPrism`;
export const UPLOAD_ENDPOINT = `${APP_URL}/api/v5/upload/buildingEnginesPrism`;

export type StringValues<T> = {
  [Property in keyof T]?: string;
};

interface Req<ParamsT, DataT> {
  path: string;
  method: 'get' | 'post' | 'delete' | 'put' | 'PATCH';
  token: string | null;
  params?: StringValues<ParamsT> | URLSearchParams | string;
  data?: DataT;
}

export interface GetReq<ParamsT> extends Req<ParamsT, undefined> {
  method: 'get';
}

export interface DeleteReq<ParamsT> extends Req<ParamsT, undefined> {
  method: 'delete';
}

export interface PostReq<ParamsT, DataT extends object>
  extends Req<ParamsT, DataT> {
  method: 'post';
}

export interface PutReq<ParamsT, DataT extends object>
  extends Req<ParamsT, DataT> {
  method: 'put';
}

export interface PatchReq<ParamsT, DataT extends object>
  extends Req<ParamsT, DataT> {
  method: 'PATCH';
}

interface IRes<T> {
  status: number;
  data: Promise<T>;
}

export interface ResOk<T> extends IRes<T> {
  ok: true;
}

export interface ResErr<T> extends IRes<T> {
  ok: false;
}

// U = T only for compat, should remove
export type Res<T, U = T> = ResOk<T> | ResErr<U>;

export const constructUrl = ({
  endpoint = ENDPOINT,
  reqPath,
  params,
}: {
  endpoint?: string;
  reqPath: string;
  params?: URLSearchParams;
}): string => {
  let url = endpoint;
  url += reqPath.startsWith('/') ? reqPath : `/${reqPath}`;
  if (params && params.toString() !== '') url += `?${params.toString()}`;

  return url;
};

export async function request<ReqParamsT, ReqDataT extends object>(
  req:
    | GetReq<ReqParamsT>
    | PostReq<ReqParamsT, ReqDataT>
    | DeleteReq<ReqParamsT>
    | PutReq<ReqParamsT, ReqDataT>
    | PatchReq<ReqParamsT, ReqDataT>,
  opts: { raiseOnError: boolean } = { raiseOnError: true }
): Promise<Res<any, any>> {
  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  if (req.token) {
    headers['X-BE-Authorization'] = `Bearer ${req.token}`;
  }

  let params = new URLSearchParams();
  if (req.params instanceof URLSearchParams) {
    params = req.params;
  } else if (typeof req.params === 'string') {
    params = new URLSearchParams(req.params);
  } else if (typeof req.params === 'object') {
    for (const [key, value] of Object.entries(req.params)) {
      if (typeof value !== 'string')
        throw new Error(`TypeError: expected string got ${typeof value}`);
      params.set(key, value);
    }
  }

  const url = constructUrl({ reqPath: req.path, params });

  const res = await fetch(url, {
    method: req.method,
    headers,
    body: req.data && JSON.stringify(req.data),
  });

  if (opts.raiseOnError && !res.ok) {
    const data = await res.json();
    throw new Error(`${res.status.toString()}, ${{ cause: data }.toString()}`);
  }

  return {
    status: res.status,
    ok: res.ok,
    data: req.method === 'delete' ? Promise.resolve() : res.json(),
  };
}
