import React, { ReactNode, useState } from 'react';

import cx from 'classnames';
import { SearchBar } from 'components';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import useWebhookLibrary, {
  SORT_ASC,
  SORT_DESC,
  SORT_BY,
} from 'lane-shared/hooks/useWebhookLibrary';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';

import Dropdown from 'components/form/Dropdown';
import ControlMenu from 'components/general/ControlMenu';
import IconButton from 'components/general/IconButton';
import Loading from 'components/general/Loading';
import Pagination from 'components/general/Pagination';

import Button from '../general/Button';

import styles from './WebhookLibrary.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  userLibraryEnabled?: boolean;
  library: LibraryType | null;
  libraries?: LibraryType[] | null;
  storageKey?: string;
  ItemWrapper?: React.FunctionComponent;
  onWebhookSelected?: (webhook: DocumentType | null) => void;
};

export default function WebhookLibrary({
  className,
  style,
  libraries = null,
  library,
  userLibraryEnabled,
  storageKey,
  onWebhookSelected = () => {},
  ItemWrapper = ({ children }: { children?: ReactNode }) => <>{children}</>,
}: Props) {
  const { t } = useTranslation();
  const {
    webhooks,
    pageInfo,
    loading,
    search,
    updateSearch,
    onChangeLibrary,
    selectedLibrary,
    availableLibraries,
  } = useWebhookLibrary({ libraries, library, storageKey, userLibraryEnabled });
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  const history = useHistory();

  function onWebhookClicked(webhook: any) {
    setSelectedWebhook(webhook);
    onWebhookSelected(webhook);
  }

  if (!selectedLibrary) {
    return <Loading />;
  }

  return (
    <div className={cx(styles.WebhookLibrary, className)} style={style}>
      <ControlMenu mb={4} mt={2}>
        {availableLibraries.length > 1 && (
          <Dropdown
            items={availableLibraries.map(library => ({
              label: library.name,
              value: library._id,
            }))}
            className={styles.libraries}
            placeholder={t('Select a library')}
            value={selectedLibrary && selectedLibrary._id}
            onValueChange={onChangeLibrary}
          />
        )}
        <SearchBar
          className={styles.input}
          searchOptions={search as any}
          onSearchChange={search => updateSearch({ search, page: 0 })}
        />

        <Dropdown
          className={styles.sortBy}
          onValueChange={sortBy => updateSearch({ sortBy, page: 0 })}
          items={SORT_BY}
          value={search.sortBy}
        />

        <IconButton
          className={styles.iconButton}
          inverted
          icon={search.sortOrder === SORT_ASC ? 'chevron-down' : 'chevron-up'}
          onClick={() =>
            updateSearch({
              sortOrder: search.sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC,
              page: 0,
            })
          }
        />

        <IconButton
          className={styles.iconButton}
          icon="times"
          inverted
          onClick={() =>
            updateSearch({
              search: '',
              page: 0,
              sortBy: '_created',
              sortOrder: SORT_DESC,
            })
          }
        />

        <Link to="webhooks/new">
          <Button variant="contained" onClick={() => null}>
            {t('New')}
          </Button>
        </Link>
      </ControlMenu>

      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th>{t('Created')}</th>
              <th>{t('Last Update')}</th>
              <th>{t('Name')}</th>
              <th>{t('Url')}</th>
              <th>{t('Status')}</th>
            </tr>
          </thead>
          <tbody>
            {webhooks.map(webhook => (
              <tr
                key={webhook._id}
                onClick={() => onWebhookClicked(webhook)}
                onDoubleClick={() => {
                  history.push(`webhooks/${webhook._id}/edit`);
                }}
                data-is-selected={webhook._id === (selectedWebhook as any)?._id}
              >
                <td>{simpleDate(webhook._created)}</td>
                <td>{fromNow(webhook._updated)}</td>
                <td>
                  {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string | undefined; webhook: Web... Remove this comment to see the full error message */}
                  <ItemWrapper webhook={webhook}>{webhook.name}</ItemWrapper>
                </td>
                <td>{webhook.url}</td>
                <td>{webhook.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        loading={loading}
        total={pageInfo.total}
        perPage={search.perPage}
        page={search.page}
        onPage={page => updateSearch({ page })}
      />
    </div>
  );
}
