import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';

export function hasFeature(contentFeatures: any, featureName: FeatureNameEnum) {
  if (!contentFeatures?.ContentFeature) {
    return false;
  }

  const targetFeature = contentFeatures.ContentFeature.find(
    (feature: { type: FeatureNameEnum }) => feature.type === featureName
  );

  return !!targetFeature;
}
