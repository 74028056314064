import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from '../content-blocks/AddVisitorsBlock.scss';

const reactCSV = require('react-csv');

const { CSVLink } = reactCSV;

export function DownloadVisitorInfoSample({
  isPreview = false,
}: {
  isPreview?: boolean;
}): React.ReactElement {
  const { t } = useTranslation();

  const csvData = [
    [
      'Visitor Email',
      'Visitor First Name',
      'Visitor Last Name',
      'Visitor Country Code',
      'Visitor Cell Number',
      'Visitor Company',
    ],
  ];

  return isPreview ? (
    <p className={styles.disabledLinkContainer}>
      {t('web.content.feature.visitorManagement.visitorInvite.downloadSample')}
    </p>
  ) : (
    <CSVLink
      data={csvData}
      className={styles.linkContainer}
      filename="VisitorInfo-Template.csv"
    >
      {t('web.content.feature.visitorManagement.visitorInvite.downloadSample')}
    </CSVLink>
  );
}
