// @ts-nocheck FIXME: Ignored due failing CI after React update
import React, { useState, useEffect, ReactNode, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import * as Sentry from '@sentry/browser';

import {
  AnalyticsContext,
  ChannelsContext,
  UserDataContext,
} from 'lane-shared/contexts';
import { emitUserLoggedIn } from 'lane-shared/helpers/analytics/emitIntegrationLogin';
import { validateBEPrismBuildingIdAgainstCurrentUser } from 'lane-shared/helpers/constants/integrations/BuildingEnginesPrismCommon';
import { FORGOT_PASSWORD_URL } from 'lane-shared/helpers/constants/integrations/BuildingEnginesPrismWorkOrders';
import * as BuildingEnginesPrism from 'lane-shared/helpers/integrations/BuildingEnginesPrism';
import {
  getToken,
  storeToken,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/token';
import { ContentType } from 'lane-shared/types/content/Content';

import { history } from '../../../helpers';
import CardContainer from '../../cards/CardContainer';
import ValidatedInput from '../../form/ValidatedInput';
import { Button, ErrorMessage, Loading } from '../../general';
import { BuildingIntegrationFailure } from './BuildingIntegrationFailure';

import styles from './Login.scss';

interface LoginProps {
  content: ContentType;
  children?: ReactNode;
}

const emailValidator = yup.string().email().nullable().required();
const pwdValidator = yup.string().nullable().required();

export default function Login({ content, children }: LoginProps) {
  const { settings } = content.integration;

  const { t } = useTranslation();

  // This should be refactored into a reducer in the future
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [loginLoading, setLoginLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [validation, setValidation] = useState<Error | null>(null);
  const [storedToken, setStoredToken] = useState<string | undefined>(undefined);
  const [
    buildingIntegrationError,
    setBuildingIntegrationError,
  ] = useState<boolean>(false);
  const buildingId: string = content?.integration?.settings?.buildingId;

  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);
  const { primaryChannel } = useContext(ChannelsContext);

  function validate() {
    try {
      emailValidator.validateSync(email);
      pwdValidator.validateSync(password);
      setValidation(null);
    } catch (err) {
      if (err instanceof Error) {
        setValidation(err);
      }
    }
  }

  /**
   * Once the user logs in, we check if the currentUser
   * has access to the buildingId in the integration settings
   * When access isn't available, redirects to the
   * BuildingIntegration error
   */
  async function login() {
    setLoginLoading(true);
    setError(null);

    try {
      const response = await BuildingEnginesPrism.requestHelpers.login(
        email || '',
        password || ''
      );

      if (response.isError) {
        setLoginLoading(false);
        setError(t('You’ve entered an invalid email or password.'));
        return;
      }

      const token = response.token;

      const isIntegrationValid: boolean = await validateBEPrismBuildingIdAgainstCurrentUser(
        { token, buildingId }
      );

      if (!isIntegrationValid) {
        setBuildingIntegrationError(true);
        setLoginLoading(false);

        return;
      }

      await storeToken(token);

      setStoredToken(token);

      if (user && primaryChannel) {
        emitUserLoggedIn({
          userId: user._id,
          channelId: primaryChannel._id,
          integrationId: content.integration._id,
          analytics,
        });
      }

      if (children) {
        setLoginLoading(false);
      } else {
        history.goBack();
      }
    } catch (err) {
      setLoginLoading(false);
      Sentry.captureException(err, {
        contexts: {
          extraData: { contentName: content.name },
        },
      });
      if (err instanceof Error) {
        if (err.message) {
          setError(err);
        } else {
          window.Alert.alert({
            title: t('Whoops something went wrong!'),
            message: t('Please try again later.'),
          });
        }
      }
    }
  }

  useEffect(() => {
    async function getTokenOnLoad() {
      setStoredToken(await getToken());
    }

    getTokenOnLoad().then(() => setLoginLoading(false));
  }, []);

  useEffect(() => {
    validate();
    setError(null);
  }, [email, password]);

  if (storedToken && children) {
    return <>{children}</>;
  }

  if (buildingIntegrationError) {
    return (
      <BuildingIntegrationFailure
        setBuildingIntegrationError={setBuildingIntegrationError}
      />
    );
  }

  if (loginLoading) {
    return <Loading />;
  }

  return (
    <CardContainer size="large" className={styles.container}>
      <h2>{t('Log in')}</h2>
      <p>
        {t(
          'Log in with your {{name}} account to send and receive maintenance requests',
          { name: settings.name }
        )}
      </p>
      <form autoComplete="off">
        <ValidatedInput
          type="email"
          placeholder={t('Enter your {{name}} email', { name: settings.name })}
          onChange={value => {
            setEmail(value);
          }}
          label={t('Email')}
          value={email}
          validation={emailValidator}
          isPristine={typeof email === 'object'}
        />
        <ValidatedInput
          type="password"
          placeholder={t('Enter your {{name}} password', {
            name: settings.name,
          })}
          onChange={value => {
            setPassword(value);
          }}
          label={t('Password')}
          value={password}
          validation={pwdValidator}
          isPristine={typeof password === 'object'}
        />

        <a href={FORGOT_PASSWORD_URL} target="_blank" rel="noopener noreferrer">
          {t('Forgot password?')}
        </a>
        <Button
          disabled={loginLoading || Boolean(validation)}
          className={styles.button}
          variant="contained"
          dataCy="BEloginButton"
          onClick={login}
          loading={loginLoading}
        >
          {t('Log in')}
        </Button>
      </form>
      {settings.supportEmail && (
        <p>
          Don&apos;t have a {settings.name} account?{' '}
          <a href={`mailto:${settings.supportEmail}`}>
            {t('Contact your administrator.')}
          </a>
        </p>
      )}
      {error && <ErrorMessage error={error} />}
    </CardContainer>
  );
}
