import React from 'react';

import cx from 'classnames';

import { toSchema } from 'lane-shared/helpers';
import { ScheduledUnitTypesEnum } from 'lane-shared/renderers/v5/features/Scheduled';
import { ScheduledFeatureProperties } from 'lane-shared/types/features/ScheduledFeatureProperties';

import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';
import Slider from 'components/form/Slider';
import Label from 'components/general/Label';
import { FeatureRendererPropsType } from 'components/renderers/features/FeatureRendererPropsType';

import styles from './Scheduled.scss';

const maxUnits = {
  [ScheduledUnitTypesEnum.Minutes]: 60,
  [ScheduledUnitTypesEnum.Hours]: 24,
  [ScheduledUnitTypesEnum.Days]: 30,
};

export default function ScheduledFeatureRenderer({
  className,
  style,
  feature,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<ScheduledFeatureProperties>) {
  return (
    <div className={cx(styles.Scheduled, className)} style={style}>
      <Label h1>{feature.friendlyName}</Label>

      <p>{feature.description}</p>

      {contentFeature && (
        <section>
          <fieldset>
            <Label TooltipComponent={feature.properties.unitType.description}>
              Time Units
            </Label>
            <Dropdown
              onValueChange={unitType =>
                onFeatureUpdated({ unitType, units: 1 })
              }
              items={Object.values(ScheduledUnitTypesEnum).map(toSchema)}
              initialValue={ScheduledUnitTypesEnum.Minutes}
              isSearchable={false}
              placeholder="Select a Time Unit"
              value={contentFeature.feature.unitType}
            />
          </fieldset>

          <fieldset>
            <Label TooltipComponent={feature.properties.units.description}>
              {contentFeature.feature.unitType}
            </Label>
            <div className={styles.row}>
              <Slider
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                className={styles.slider}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(units: any) => void' is not assignable to t... Remove this comment to see the full error message
                onChange={(units: any) => onFeatureUpdated({ units })}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                min={1}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                max={maxUnits[contentFeature.feature.unitType]}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
                value={Number(contentFeature.feature.units)}
              />
              <Input
                className={styles.input}
                value={contentFeature.feature.units}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
                onChange={units => onFeatureUpdated({ units })}
                input={{
                  min: 1,
                  max: maxUnits[contentFeature.feature.unitType],
                }}
                type="number"
              />
            </div>
          </fieldset>
        </section>
      )}
    </div>
  );
}
