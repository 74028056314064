import React, { useCallback, useState } from 'react';

import { useDurationTimesOptions } from 'lane-shared/hooks/useDurationTimesOptions';
import { useTimeSlotsV2DurationsEnabled } from 'lane-shared/hooks/useTimeSlotsV2DurationsEnabled';

import Chips from '../Chips';

type Props = {
  maxSlots: number;
  slotSize: number;
  onChange?: (duration: number) => void;
};

export function Durations({ maxSlots, slotSize, onChange }: Props) {
  const [selectedDuration, setSelectedDuration] = useState(slotSize);
  const isTimeSlotsDurationEnabled = useTimeSlotsV2DurationsEnabled();
  const durationOptions = useDurationTimesOptions({ slotSize, maxSlots });

  const onCompare = useCallback(
    (value: number) => {
      if (!isTimeSlotsDurationEnabled) {
        return value === slotSize * maxSlots;
      }

      return value === selectedDuration;
    },
    [isTimeSlotsDurationEnabled, selectedDuration, slotSize]
  );

  const handleDurationChange = (value: number) => {
    setSelectedDuration(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Chips
      compare={onCompare}
      options={durationOptions}
      onClick={handleDurationChange}
      gridSize="large"
    />
  );
}
