import { Validator } from '../../types/Validator';
import { ValidatorInterface } from '../../types/ValidatorInterface';
import { ValidatorRegistry } from '../../types/ValidatorRegistry';
import { PropertiesInterface } from '../../types/properties/Property';
import { ITypeValidatesMax } from './ITypeValidatesMax';

export class MaxValidator implements ValidatorInterface<ITypeValidatesMax> {
  name = 'Max';

  friendlyName = 'Maximum Value';

  arrayOnly = false;

  array = false;

  description = 'shared.properties.validators.max.description';

  types = ['String', 'LongText', 'Percentage', 'Currency', 'Number'];

  properties = {
    value: {
      type: 'Number',
      default: 1,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  } as PropertiesInterface;

  attach(type: ITypeValidatesMax) {
    type.addMaxValidator(this);
  }

  constructor(public config?: Validator) {}
}

const Max = new MaxValidator(undefined);

ValidatorRegistry.register(MaxValidator, Max.name);

export default Max;
