import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { convertTo62 } from 'lane-shared/helpers/convertId';

import { RowData } from '../utilities/bookingItemToRowDataMapper';
import useIsAdminView from 'hooks/useIsAdminView';

const TRANSLATION_KEYS = {
  label: 'web.admin.channel.reservableManagement.table.details',
};

export class InvalidChannelName extends Error {}

export function generateInteractionUrl(
  interactionId: string,
  channelName: string
) {
  const convertedInteractionId = convertTo62(interactionId);
  const url = routes.channelAdminInteraction
    .replace(':id', channelName)
    .replace(':interactionId', convertedInteractionId);

  return url;
}

export function useRowActions() {
  const [error, setError] = useState<InvalidChannelName | undefined>();
  const history = useHistory();
  const { t } = useTranslation();

  const [, channelSlug] = useIsAdminView();

  const rowActions = [
    {
      label: t(TRANSLATION_KEYS.label),
      onClick: (row: RowData) => {
        if (!channelSlug) {
          const error = new InvalidChannelName();
          setError(error);
          return;
        }

        const url = generateInteractionUrl(row.id, channelSlug);

        history.push(url);
      },
    },
  ];

  return { rowActions, error };
}
