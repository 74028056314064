import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import { SurveyTypeEnum } from '../../../types/features/SurveysFeatureProperties';

const Surveys: FeatureDefinition = {
  name: FeatureNameEnum.Surveys,
  description: 'web.admin.content.features.surveys.description',
  superUserOnly: false,
  friendlyName: 'web.content.features.surveys.friendlyName',
  requiresInteraction: true,
  properties: {
    _version: {
      friendlyName: 'Feature version',
      type: 'Number',
      editable: false,
      forDisplay: false,
      default: 1,
    },
    type: {
      friendlyName: 'web.admin.content.features.surveys.title',
      description: 'web.admin.content.features.surveys.description',
      type: 'String',
      editable: true,
      default: SurveyTypeEnum.TenantAdminSatisfaction,
      options: [
        {
          _id: SurveyTypeEnum.TenantAdminSatisfaction,
          name: 'web.admin.content.features.surveys.tenantAdminSatisfaction',
        },
      ],
    },
  },
};

export default Surveys;
