import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { H4, M } from 'lane-web/src/components/typography';

import { UsersPageCTA } from '../component/UsersPageCTA';

import styles from './EmptyState.scss';

type Props = {
  channel: ChannelType;
  customButton?: JSX.Element;
  body: string;
};

export function EmptyStateView({ channel, customButton, body }: Props) {
  const { t } = useTranslation();
  const linkToInviteUser = routes.channelAdminUsersInvite.replace(
    ':id',
    channel.slug
  );

  return (
    <div className={styles.channelUsersEmptyState}>
      <div>
        <div className={styles.emptyStateBox}>
          <div className={styles.emptyState}>
            <Icon className={styles.icon} name="User" size="large" />
            <div>
              <H4 className={styles.noUsers}>
                {t('web.pages.portal.admin.users.emptyState.header')}
              </H4>
              <M>{t(body)}</M>
            </div>
            {customButton || <UsersPageCTA linkTo={linkToInviteUser} />}
          </div>
        </div>
      </div>
    </div>
  );
}
