import { commonInteractionQueries } from 'lane-shared/helpers/constants';
import { createUserContentInteraction } from 'lane-shared/helpers/content';
import {
  ContentQueryInteraction,
  getContent,
} from 'lane-shared/hooks/useContentQuery';
import { ContentType } from 'lane-shared/types/content/Content';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';
import * as Sentry from '@sentry/browser';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';

export async function processUciSubmission(
  interaction: ContentQueryInteraction,
  contentId: ContentType['_id'],
  primaryId: string,
  transactionId: string,
  paymentQuote: FeatureQuoteType
) {
  try {
    // fetch the actual content
    const content = await getContent(contentId, false);

    await createUserContentInteraction({
      refetchQueries: commonInteractionQueries,
      content,
      interaction: {
        ...interaction,
        features: {
          ...interaction?.features,
          Payment: {
            ...interaction?.features?.Payment,
            type: PaymentTypeEnum.Cash,
            quote: paymentQuote,
            transactionId,
          },
        },
      },
      meChannelId: primaryId,
    });
  } catch (err) {
    Sentry.captureException(
      'BPError - UCI failed after transaction processed',
      {
        contexts: {
          uciInfo: {
            contentId,
            channelId: primaryId,
            error: err,
          },
        },
      }
    );
    throw new Error(`abp.errors.payment.partialTransaction`);
  }
}
