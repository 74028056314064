import { useContext, useMemo } from 'react';

import AppContext from '../contexts/AppContext';
import mergeThemes from '../helpers/content/mergeThemes';
import { ThemeType } from '../types/Theme';
import { ContentType } from '../types/content/Content';

/**
 * Builds out a theme based on what is set in the content, set on the white
 * label and also based on setting values from defaults for missing values.
 */
export default function useContentTheme(
  content?: ContentType | null,
  otherTheme?: ThemeType
): ThemeType {
  const { whitelabel } = useContext(AppContext);

  return useMemo(
    () =>
      mergeThemes([
        whitelabel?.profile?.theme,
        otherTheme,
        content?.theme,
        content?.block?.theme,
      ]),
    [content, whitelabel, otherTheme]
  );
}
