type AccessControlGroup = {
  label: string;
  value: string;
  provider: string;
  channelId: string;
};

export const isAccessControlGroupsListSame = (
  userAcgs: string[],
  selectedAcgs: AccessControlGroup[]
) => {
  if (userAcgs.length !== selectedAcgs.length) {
    return false;
  }

  for (let idx = 0; idx < userAcgs.length; idx++) {
    if (
      !selectedAcgs.find(selectedAcg => selectedAcg.value === userAcgs[idx])
    ) {
      return false;
    }
  }

  return true;
};
