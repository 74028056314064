import React from 'react';

import {
  MjmlHead,
  MjmlTitle,
  MjmlStyle,
  MjmlAttributes,
  MjmlAll,
  MjmlText,
  MjmlRaw,
} from 'lane-shared/emails/MjmlComponentsV2';

export function EmailVisitorHeader({
  title,
  primaryColor,
}: {
  title: string;
  primaryColor?: string;
}) {
  const linkColor = primaryColor ?? '#4837B9';

  const styles = `
  h1 {
    font-family: Helvetica Neue;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
 }
  h2 {
    //styleName: Typescale/H4;
    font-family: Helvetica Neue;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
  }
  h3 {
    font-family: Helvetica Neue;
    font-size: 19px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
  }
  b {
    font-family: Helvetica Neue;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .small {
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
  }
  .secondary {
    color: #0F121FB2
  }
  .tertiary {
    color: #999DA3
  }
  a {
    color: ${linkColor};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  .bordered-col {
    box-shadow: 0 0 0 1px #E4EBF2;
    border-radius: 12px;
  }
  @media only screen and (max-width: 480px) {
    .host-avatar {
      margin-left: 0px;
      margin-right: 0px;
    }
  }`;

  return (
    <MjmlHead>
      <MjmlTitle>{title}</MjmlTitle>
      <MjmlRaw>
        <style>{`
            @media only screen and (max-width: 480px) {
              .host-avatar {
                width: 48px !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
              }
            }
        `}</style>
      </MjmlRaw>
      <MjmlAttributes>
        <MjmlAll font-family="'Helvetica Neue', Helvetica, Arial, sans-serif" />
        <MjmlText
          font-weight="400"
          font-size="16px"
          color="#000000"
          line-height="24px"
        />
      </MjmlAttributes>
      <MjmlStyle inline>{styles}</MjmlStyle>
    </MjmlHead>
  );
}
