import { FeatureFlag } from '../../types/FeatureFlag';
import useFlag from '../useFlag';

const FLAG = FeatureFlag.AuthenticationAuth0;

/**
 * Generated
 */
export function useAuth0Enabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
