import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DateTime } from 'luxon';

import { LaneIntegrationType } from 'common-types';
import { UserDataContext } from '../../../contexts';
import { ALL_INTERACTIONS } from '../../../helpers/constants/interactions';
import { parseDateTime } from '../../../helpers/dates';
import {
  SHOPIFY_BURST_RSVP_VALID_DAYS,
  ShopifyBurstRsvpEntryTimeValidEnum,
  ShopifyRsvpContentStateType,
  ShopifyRsvpWizardState,
} from '../../../types/integrations/ShopifyRsvp';
import useContentQuery from '../../useContentQuery';
import useInteractionsOnContentQuery from '../../useInteractionsOnContentQuery';
import ShopifyRsvpContext from './ShopifyRsvpContext';

type Props = {
  contentId: string | undefined;
  children: React.ReactChild;
  rsvpSubmitOnBehalfOfUser: {
    _id: string;
  } | null;
};

export default function ShopifyRsvpContextProvider({
  contentId,
  rsvpSubmitOnBehalfOfUser,
  children,
}: Props) {
  const { user } = useContext(UserDataContext);
  // this is used by refetch to avoid fetching same data twice on initial load
  const firstLoadCompleted = useRef(false);
  const interactionUserId = rsvpSubmitOnBehalfOfUser?._id || user?._id;
  // get the shopify rsvp content
  const {
    loading: rsvpContentLoading,
    error: rsvpContentError,
    content: rsvpContent,
  } = useContentQuery({
    contentId,
    forPublic: false,
  });

  const {
    items: rsvpInteractions,
    loading: rsvpInteractionLoading,
    error: rsvpInteractionError,
    refetch: refetchRsvpInteraction,
  } = useInteractionsOnContentQuery({
    contentId,
    interactionState: ALL_INTERACTIONS,
    forUserId: interactionUserId,
  });

  // should refetch interactions on interaction user change because
  // by the presence of interaction (which presence depends on a user)
  // we determine whether to call update UCI, or create UCI query
  useEffect(() => {
    if (interactionUserId && firstLoadCompleted.current) {
      refetchRsvpInteraction();
    } else {
      firstLoadCompleted.current = true;
    }
  }, [interactionUserId]);

  // explode out the values from the response
  const rsvpIntegrationSettings: LaneIntegrationType.ShopifyRsvp =
    rsvpContent?.integration?.settings;

  const initialWizardState = {
    currentStepIndex: 0,
    data: {},
  };

  // ShopifyRsvp content will have Entries feature turned on and only
  // allow 1 entry per user.  So if there is an interaction, then it is
  // their response.
  const rsvpUserContentInteraction = rsvpInteractions[0] || null;

  const burstState:
    | ShopifyRsvpContentStateType
    | undefined = (rsvpContent?.state as
    | {
        _ShopifyBurst: ShopifyRsvpContentStateType;
      }
    | undefined)?._ShopifyBurst;

  // setup the wizard states
  const [wizardState, setWizardState] = useState<ShopifyRsvpWizardState>(
    initialWizardState
  );

  const entryTimeValid = useMemo(() => {
    let interactionEndDate: DateTime;

    if (rsvpContent?.interactionEndDate) {
      interactionEndDate = parseDateTime(rsvpContent.interactionEndDate)!;
    } else if (rsvpContent?.liveDate) {
      interactionEndDate = parseDateTime(rsvpContent.liveDate)!.plus({
        days: SHOPIFY_BURST_RSVP_VALID_DAYS,
      });
    } else {
      return ShopifyBurstRsvpEntryTimeValidEnum.Unknown;
    }

    return interactionEndDate > DateTime.local()
      ? ShopifyBurstRsvpEntryTimeValidEnum.Valid
      : ShopifyBurstRsvpEntryTimeValidEnum.Invalid;
  }, [rsvpContent?.liveDate, rsvpContent?.interactionEndDate]);

  function updateWizardState(newState: Partial<ShopifyRsvpWizardState>) {
    setWizardState(prevState => ({ ...prevState, ...newState }));
  }

  // memoize so we aren't re-rendering on each pass
  const contextValue = useMemo(
    () => ({
      initialWizardState,
      wizardState,
      updateWizardState,
      contentId,
      rsvpContent,
      rsvpContentLoading,
      rsvpContentError,
      rsvpUserContentInteraction,
      rsvpSubmitOnBehalfOfUser,
      rsvpIntegrationSettings,
      burstState,
      entryTimeValid,
      refetchRsvpInteraction,
      loading: rsvpContentLoading || rsvpInteractionLoading,
      error: rsvpContentError || rsvpInteractionError,
    }),
    [
      contentId,
      wizardState,
      updateWizardState,
      rsvpContent,
      rsvpContentLoading,
      rsvpContentError,
      rsvpSubmitOnBehalfOfUser,
      refetchRsvpInteraction,
      rsvpUserContentInteraction,
      rsvpIntegrationSettings,
      burstState,
      entryTimeValid,
    ]
  );

  return (
    // @ts-expect-error ts-migrate(2740) FIXME: Type '{ initialWizardState: { currentStepIndex: nu... Remove this comment to see the full error message
    <ShopifyRsvpContext.Provider value={contextValue}>
      {children}
    </ShopifyRsvpContext.Provider>
  );
}
