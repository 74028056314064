import * as yup from 'yup';

export default yup.object().shape({
  _order: yup.number().max(256).min(0).notRequired(),
  name: yup.string().trim().notRequired(),
  slug: yup.string().trim().max(55).notRequired(),
  icon: yup.string().max(55).trim().notRequired(),
  iconSet: yup.string().max(55).trim().notRequired(),
  label: yup
    .string()
    .matches(/^[^0-9\s]+$/, 'Alpha characters only')
    .max(11)
    .trim()
    .notRequired(),
  content: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .notRequired(),

  channel: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .notRequired(),
});
