import { useMemo } from 'react';

import { DateTime, Interval } from 'luxon';

import { LaneType } from 'common-types';
import useReservableAvailabilityByRange from 'lane-shared/hooks/useReservableAvailabilityByRange';

import { getReservableUnavailableRanges } from '../helpers/getReservableUnavailableRanges';

type Props = {
  contentId: LaneType.UUID;
  referenceDate: Date;
  operatingHours: {
    startDate: Date;
    endDate: Date;
    availableIntervals: Interval[];
  } | null;
  bufferTimeConfig: LaneType.BufferTime | undefined;
};

export function useReservableUnavailableRanges({
  contentId,
  referenceDate,
  operatingHours,
  bufferTimeConfig,
}: Props) {
  const referenceDateTime = DateTime.fromJSDate(referenceDate);
  const endDateTime = referenceDateTime.plus({ hours: 24 });

  const {
    unavailableDateRanges: unavailableRangesDueToReservation,
    loading: isLoading,
  } = useReservableAvailabilityByRange({
    contentId,
    startDate: referenceDate,
    endDate: endDateTime.toJSDate(),
  });

  const unavailableDateRanges = useMemo(() => {
    return getReservableUnavailableRanges({
      reservationDateRanges: unavailableRangesDueToReservation,
      referenceDate,
      operatingHours,
      bufferTimeConfig,
    });
  }, [
    unavailableRangesDueToReservation,
    referenceDate,
    operatingHours,
    bufferTimeConfig,
  ]);

  return {
    unavailableDateRanges,
    isLoading,
  };
}
