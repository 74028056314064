import { AnalyticsInterface } from '../../types/Analytics';
import { EVENT_INTEGRATION_LOGIN } from '../constants/events';

type UserLoggedInEvent = {
  userId: string;
  channelId: string;
  integrationId?: string;
  analytics: AnalyticsInterface;
};

export function emitUserLoggedIn({
  userId,
  channelId,
  integrationId,
  analytics,
}: UserLoggedInEvent) {
  analytics.track(EVENT_INTEGRATION_LOGIN, {
    userId,
    channelId,
    integrationId,
  });
}
