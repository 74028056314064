import React from 'react';

import { Alert, AlertType } from 'components/lds';

import { M } from 'components/typography';

export const WarningMessage = ({ message }: { message: string }) => (
  <Alert type={AlertType.warning}>
    <M>{message}</M>
  </Alert>
);
