import { MobilePermissionEnum } from '../../../types/MobilePermissionEnum';
import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const HIDDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.HID,
  hasCustomUI: true,
  friendlyName: 'HID Access Control',
  description: `Defines the properties required for an HID integration.`,
  category: IntegrationCategoryEnum.AccessControl,
  platforms: [PlatformEnum.Mobile],
  type: IntegrationTypeEnum.SDK,
  settings: {},
  properties: {
    supportPage: {
      friendlyName: 'Support Page',
      type: 'Content',
    },
  },
  mobilePermissions: [
    MobilePermissionEnum.Bluetooth,
    MobilePermissionEnum.SecureDevice,
  ],
  // Google requires us to use specific language requesting background location access before we load the system prompts
  mobilePermissionsRequest:
    'To unlock doors, Workplace Access requires these device settings to be enabled, including accessing your location when the application is not in use:',
};

export default HIDDefinition;
