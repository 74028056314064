import { DateTime } from 'luxon';

export const formatDateWithUserLocale = ({
  date,
  userLocale,
  formattingOptions = {},
}: {
  date: Date;
  userLocale: string | undefined;
  formattingOptions?: any;
}) => {
  const formatted: DateTime = DateTime.fromISO((date as unknown) as string);

  if (userLocale) {
    formatted.setLocale(userLocale);
  }

  return formatted.toLocaleString({
    month: 'long',
    day: '2-digit',
    ...formattingOptions,
  });
};
