/**
 * Gets error codes from a GraphQL error
 *
 * @param error
 * @param forCode the code to look for
 * @returns {Array}
 */
export default function getErrorCodes(error: any, forCode = null) {
  const codes: any[] = [];

  if (!error) {
    return codes;
  }

  if (error.graphQLErrors?.length > 0) {
    error.graphQLErrors.forEach((err: any) => {
      if (err.extensions?.exception) {
        if (err.extensions.exception.inner?.length > 0) {
          err.extensions.exception.inner.forEach((err2: any) =>
            codes.push(err2.code)
          );
        } else if (err.extensions.exception.code) {
          codes.push(err.extensions.exception.code);
        } else {
          codes.push(err.code);
        }
      } else {
        codes.push(err.code);
      }
    });
  } else if (error.inner) {
    error.inner.forEach((err: any) => codes.push(err.code));
  } else {
    codes.push(error.code);
  }

  return codes.filter(code => {
    if (forCode) {
      return code === forCode;
    }

    return !!code;
  });
}
