import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

export function useHandleSaveAutopayment({
  cardName,
  cardDetails,
}: {
  cardName: {
    firstName: string;
    lastName: string;
  };
  cardDetails: StripeCardElement | undefined;
}) {
  const stripe = useStripe();
  return async () => {
    if (stripe && cardDetails) {
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardDetails,
        billing_details: {
          name: `${cardName.firstName} ${cardName.lastName}`,
        },
      });
      if (payload.error) {
        throw payload.error;
      }
      return payload.paymentMethod.id;
    }
    throw new Error('Stripe is not initialized / card details missing');
  };
}
