import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { OAUTH_PROVIDERS } from 'lane-shared/helpers/constants/user';

import OauthBase from './OauthBase';
import { LINKEDIN } from './constants';

import styles from './styles.scss';

export class LinkedIn extends OauthBase {
  static propTypes = {
    className: PropTypes.string,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func,
    onClick: PropTypes.func,
    state: PropTypes.string,
  };

  static defaultProps = {
    className: 'btn-linkedin',
    state: 'fdsf78fyds7fm',
    onFailure: () => {},
    onSuccess: () => {},
    onClick: () => {},
  };

  getUrl = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { state } = this.props;
    // TODO: Check support for IE 11
    const scopeParam = `&scope=${encodeURI(LINKEDIN.scope)}`;
    return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN.clientId}&redirect_uri=${LINKEDIN.redirectUri}${scopeParam}&state=${state}`;
  };

  handleMessage = (data: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFailure' does not exist on type 'Reado... Remove this comment to see the full error message
    const { onFailure, onSuccess } = this.props;
    if (data.errorMessage && data.provider === OAUTH_PROVIDERS.LINKEDIN) {
      if (onFailure) onFailure(data);
      this.closeWindow();
    } else if (data.code && data.provider === OAUTH_PROVIDERS.LINKEDIN) {
      if (onSuccess) onSuccess({ code: data.code });
      this.closeWindow();
    }
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, children, ...other } = this.props;

    if (children) return children;
    return (
      <button
        onClick={this.handleConnectClick}
        className={cx(styles.SocialButton, styles.linkedin, className)}
        {...other}
      >
        <Icon
          className={styles.icon}
          set={ICON_SET_FONTAWESOME}
          name="linkedin"
        />
      </button>
    );
  }
}
export default LinkedIn;
