import { cloneDeep } from 'lodash';

import { BlockRenderInterface } from 'lane-shared/types/blocks/BlockInterface';
import { ContentType } from 'lane-shared/types/content/Content';
import { SurveyTypeEnum } from 'lane-shared/types/features/SurveysFeatureProperties';

import { getSurveysDataBlocks } from './getSurveysDataBlocks';
import { getSurveysDataInputs } from './getSurveysDataInputs';

export function getSurveyContentToAdd({
  content,
  surveyType,
  blocks,
}: {
  content: ContentType;
  surveyType: SurveyTypeEnum;
  blocks: { [key: string]: BlockRenderInterface };
}): ContentType {
  let contentCopy = cloneDeep(content);
  const surveyData = getSurveysDataInputs({
    contentData: content.data,
    surveyType,
  });

  const surveyBlock = getSurveysDataBlocks({
    surveyDataInputs: surveyData,
    content,
    blocks,
    surveyType,
  });

  // Add survey info to content
  contentCopy = {
    ...contentCopy,
    data: { ...(content.data || {}), ...surveyData },
    block: { ...(content.block || {}), ...surveyBlock },
  };

  return contentCopy;
}
