import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { getPagesOnChannelsQuery } from 'lane-shared/graphql/query';

export const getChannelIdsHavingPages = async (
  channelIds: Array<LaneType.UUID>
): Promise<Array<LaneType.UUID>> => {
  if (channelIds.length === 0) {
    throw new Error(
      'Channel IDs is blank. Please provide atleast one channel ID in the input.'
    );
  }

  const { data } = await getClient().query({
    query: getPagesOnChannelsQuery,
    variables: {
      channelIds,
    },
  });
  const pagesOnChannels = data?.me?.pagesOnChannels || [];
  const channelIdsWithPages = pagesOnChannels
    .filter(
      (pageOnChannel: { channelPages: string | any[] }) =>
        pageOnChannel.channelPages?.length
    )
    .map((pageOnChannels: { channelId: any }) => pageOnChannels.channelId);

  return channelIdsWithPages;
};
