import * as yup from 'yup';

import parseDate from '../../helpers/dates/parseDate';
import dateFormatter from '../../helpers/formatters/dateFormatter';
import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TIME } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  startDate: {
    type: 'DateTime',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'LessThan',
        value: 'endDate',
      },
    ],
  },
  endDate: {
    type: 'DateTime',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'GreaterThan',
        value: 'startDate',
      },
    ],
  },
};

export class DateTimeRangeType extends ObjectTypeBase {
  group = TYPE_GROUP_TIME;

  name = 'DateTimeRange';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
    TypeContextEnum.Metatag,
  ];

  friendlyName = 'Date & Time Range';

  get example() {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  get default(): any {
    return {
      startDate: null,
      endDate: null,
    };
  }

  parseValue(value: any) {
    const ret: { startDate: Date | null; endDate: Date | null } = {
      startDate: null,
      endDate: null,
    };

    if (value?.startDate) {
      ret.startDate = parseDate(value.startDate);
    }

    if (value?.endDate) {
      ret.endDate = parseDate(value.endDate);
    }

    return ret;
  }

  formatter(range: any, opts: any) {
    if (!range?.startDate || !range?.endDate) {
      return '';
    }

    return `${dateFormatter(
      range.startDate,
      'yyyy-MM-dd h:mm a',
      opts?.timeZone
    )} to ${dateFormatter(range.endDate, 'yyyy-MM-dd h:mm a', opts?.timeZone)}`;
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new DateTimeRangeType();

Types.addBaseType(definition);
export default definition;
