export enum RecurrenceEventStatus {
  RECURRENCE_EVENT_STATUS_INVALID = 'RECURRENCE_EVENT_STATUS_INVALID',
  RECURRENCE_EVENT_STATUS_APPROVED = 'RECURRENCE_EVENT_STATUS_APPROVED',
  RECURRENCE_EVENT_STATUS_CANCELLED = 'RECURRENCE_EVENT_STATUS_CANCELLED',
}

export type RecurrenceData = {
  schedule: string;
  scheduleType: string;
  scheduleDuration: string;
  recurrenceId: string;
  sourceId?: string;
  visitorIds?: string[];
  visitorNote?: string;
  staffNote?: string;
  status?: string;
  submissionId: string;
  scheduleString: string;
  additionalRecipients?: string[];
};

export type ParsedRecurringSchedule = {
  scheduleText: string;
  scheduleStartDatetime: string;
  scheduleEndDate: string;
  eventEndTime: string;
};

export interface RecurrenceQueryResponse {
  id: string;
  visitorIds: string[];
  schedule: string;
  scheduleDuration: string;
  scheduleType: string;
  status: string;
  sourceId?: string; // Content Id
  submissionId?: string; // UCI Id
  hostUserId: string;
  hostTenantId: string;
  accessGrantBuildingId: string; // Channel / Building Id
  visitorNote?: string;
  staffNote?: string;
  floor?: string;
  createdByUserId: string;
  createdAtDatetime?: string;
  updatedAtDatetime?: string;
  projectedAtDatetime?: string;
  lastUpdatedByUserId?: string;
  currentVersion: number;
}

export type RecurringScheduleChangeDetails = {
  recurrenceId: string;
  scheduleString: string;
  scheduleDuration: string;
  visitorNote: string;
  staffNote: string;
};
