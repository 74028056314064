import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SHORT_TIME } from 'lane-shared/helpers/constants/dates';
import { doDateRangesOverlap } from 'lane-shared/helpers/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import Chips from '../Chips';

import styles from './index.scss';

interface Props {
  options: DateRangeType[];
  onClick: (range: DateRangeType) => void;
  unavailableDateRanges?: DateRangeType[];
  value: DateRangeType;
  timeZone?: string;
}

export function CustomTimeChips({
  options,
  onClick,
  unavailableDateRanges = [],
  value,
  timeZone,
}: Props) {
  const { t } = useTranslation();

  const availableTimes = useMemo(
    () =>
      options
        .filter(
          option =>
            !unavailableDateRanges.some(range =>
              doDateRangesOverlap(option, range)
            )
        )
        .map(option => ({
          label: `${dateFormatter(
            option.startDate,
            SHORT_TIME,
            timeZone
          )} - ${dateFormatter(option.endDate, SHORT_TIME, timeZone)}`,
          value: option,
        })),
    [options, unavailableDateRanges, timeZone]
  );

  const onClickHandler = (selectedValue: DateRangeType) => {
    if (doDateRangesOverlap(selectedValue, value)) {
      onClick({ startDate: null, endDate: null });
    } else {
      onClick(selectedValue);
    }
  };

  if (availableTimes.length === 0) {
    return (
      <p className={styles.noAvailable}>
        {t('web.content.feature.reservable.customTimeSlots.noAvailability')}
      </p>
    );
  }

  return (
    <Chips<DateRangeType>
      onClick={onClickHandler}
      compare={range => doDateRangesOverlap(range, value)}
      options={availableTimes}
      itemName="time"
      gridSize="medium"
    />
  );
}
