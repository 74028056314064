import { useEffect } from 'react';

import { useDebounce } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import {
  getChannelContent,
  getChannelNotices,
  getChannelPerks,
  getChannelPromotions,
} from '../graphql/query';
import { CONTENT_TYPES } from '../helpers/constants/content';
import parseDate from '../helpers/dates/parseDate';
import { byStartAndEndDate } from '../helpers/sort';
import { useMultiLanguage } from './useMultiLanguage';

export default function useContentOnChannelQuery({
  channelId,
  contentType,
  autoHide,
  pollInterval,
  search = {
    tags: {
      not: ['Burst'],
    },
  },
  skip = false,
}: any) {
  const { translate } = useMultiLanguage();

  let query;

  switch (contentType) {
    case CONTENT_TYPES.PROMOTION:
      query = getChannelPromotions;
      break;
    case CONTENT_TYPES.PERK:
      query = getChannelPerks;
      break;
    case CONTENT_TYPES.NOTICE:
      query = getChannelNotices;
      break;
    default:
    case CONTENT_TYPES.CONTENT:
      query = getChannelContent;
      break;
  }

  const [fetchContent, { data, loading, error, called }] = useLazyQuery(query, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    pollInterval,
    variables: {
      id: channelId,
      search,
    },
  });

  let contents;

  switch (contentType) {
    case CONTENT_TYPES.PROMOTION:
      contents = data?.channel?.promotions || [];
      break;
    case CONTENT_TYPES.PERK:
      contents = data?.channel?.perks || [];
      break;
    case CONTENT_TYPES.NOTICE:
      contents = data?.channel?.notices || [];
      break;
    default:
    case CONTENT_TYPES.CONTENT:
      contents = data?.channel?.content || [];
      break;
  }

  // sort contents and remove content that is over
  contents = contents.filter((content: any) => {
    if (!content.unpublishDate) {
      return true;
    }

    return parseDate(content.unpublishDate)! > new Date();
  });

  contents.sort(byStartAndEndDate);

  const translatedContents = translate({
    model: contents,
    columns: ['name', 'description', 'subtitle'],
  });

  useEffect(() => {
    if (channelId && !loading && skip === false) {
      fetchContent({
        variables: {
          id: channelId,
          search,
        },
      });
    }
  }, [channelId, skip]);

  const fastIsHidden =
    (autoHide && !called) || (called && !loading && contents?.length === 0);

  const [isHidden] = useDebounce(fastIsHidden, 100);

  return {
    contents: translatedContents,
    loading,
    error,
    isHidden,
  };
}
