import { doDateRangesOverlap } from 'lane-shared/helpers/dates';
import { WaitListType } from 'lane-shared/hooks/useTimeSlotInput';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { useEffect, useMemo, useState } from 'react';

type Props = {
  timeSlots: DateRangeType[];
  unavailableByRules: DateRangeType[];
  availableTimes: WaitListType[];
  selectedDay?: Date;
};

const hasUnavailableByRules = (
  unavailableByRules: DateRangeType[],
  timeSlot: DateRangeType
): boolean => {
  return (
    unavailableByRules.length > 0 &&
    unavailableByRules.some(range => doDateRangesOverlap(timeSlot, range))
  );
};

export function useTimeSlotLoader({
  timeSlots,
  unavailableByRules,
  availableTimes,
  selectedDay,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [previousSelection, setPreviousSelection] = useState<
    Date | undefined
  >();

  const hasAvailableTime = useMemo(() => {
    return (
      timeSlots.filter(
        timeSlot => !hasUnavailableByRules(unavailableByRules, timeSlot)
      ).length > 0
    );
  }, [timeSlots, unavailableByRules]);

  useEffect(() => {
    if (!hasAvailableTime) {
      setIsLoading(false);
      return;
    }
    if (availableTimes.length > 0) {
      setIsLoading(false);
    }
  }, [hasAvailableTime, availableTimes]);

  useEffect(() => {
    if (previousSelection?.getTime() !== selectedDay?.getTime()) {
      setPreviousSelection(selectedDay);
      setIsLoading(true);
    }
  }, [selectedDay, previousSelection, setIsLoading]);

  return { isLoading };
}
