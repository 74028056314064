import * as yup from 'yup';

import validateGeo from '../validateGeo';

/**
 * General validation for properties on content.
 */
export default yup.object().shape({
  __typename: yup.string().strip(true),
  _order: yup.number().min(0),
  geo: validateGeo.required(),
  // todo: settings validation
  settings: yup.object().nullable(),
  version: yup.number().min(0).max(1024).required(),
});
