import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { Product } from 'lane-shared/domains/billingPayments/types';

export interface ProductActions {
  label: string;
  onSelect: Function;
}

export function useGetActionsForProducts({
  onDelete,
}: {
  onDelete: (product: Product) => void;
}): ProductActions[] {
  const { t } = useTranslation();
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const channel_slug = channel?.slug || '';

  const editProductAction = (product: Product) => {
    history.push(
      routes.channelAdminEditProductForm
        .replace(':id', channel_slug)
        .replace(':productId', product.id)
    );
  };

  const actions = [
    {
      label: t('abp.actions.editProduct'),
      onSelect: (product: Product) => editProductAction(product),
    },
    {
      label: t('abp.actions.deleteProduct'),
      onSelect: (product: Product) => onDelete(product),
    },
  ];
  return actions;
}
