import gql from 'graphql-tag';

export default gql`
  mutation setEssensysAutoPay(
    $paymentAccountId: UUID!
    $accountId: ID!
    $enroll: Boolean! = false
  ) {
    essensys {
      setAutoPay(
        paymentAccountId: $paymentAccountId
        enroll: $enroll
        accountId: $accountId
      )
    }
  }
`;
