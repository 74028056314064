import { routes } from 'lane-shared/config';

export default function getContentLink({
  slug,
  contentId,
  forDrafts,
  isDraftContentStateEnabled = false,
}: {
  slug: string;
  contentId: string;
  forDrafts: boolean;
  isDraftContentStateEnabled?: boolean;
}) {
  if (forDrafts) {
    if (isDraftContentStateEnabled) {
      return routes.channelAdminDraftPreview
        .replace(':id', slug)
        .replace(':draftId', contentId);
    }
    return routes.channelAdminDraft
      .replace(':id', slug)
      .replace(':draftId', contentId);
  }

  return routes.channelAdminContent
    .replace(':id', slug)
    .replace(':contentId', contentId);
}
