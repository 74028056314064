import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import typeOfSpaceLabel from 'lane-shared/helpers/integrations/FloorMaps/typeOfSpaceLabel';
import { FloorMapType } from 'lane-shared/helpers/integrations/FloorMaps/types/FloorMap';

import Chip from 'components/general/Chip';
import DropdownContextMenu from 'components/general/DropdownContextMenu';
import MultiselectContextMenu, {
  MCMenuOptionItem,
} from 'components/general/MultiselectContextMenu';
import { H5 } from 'components/typography';

import styles from './TypeOfSpaceFilter.scss';

type Props = {
  items: MCMenuOptionItem[];
  selectedFloor?: FloorMapType | undefined | null;
  selectedIcon?: string | null;
  onChange: (v: string[]) => void;
};

export default function TypeOfSpaceFilter({
  items,
  selectedFloor,
  selectedIcon,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const [editingValues, setEditingValues] = useState<string[]>([]);
  const [savedValues, setSavedValues] = useState<string[]>([]);
  const [isOpen, setOpen] = useState(false);
  const all = items.map(item => item.value);

  useEffect(() => handleClear(), [selectedFloor]);

  function handleClear() {
    onChange(all);
    setEditingValues([]);
    setSavedValues([]);
    setOpen(false);
  }

  function handleApply() {
    onChange(editingValues.length === 0 ? all : editingValues);
    setSavedValues(editingValues);
    setOpen(false);
  }

  function handleClose() {
    setEditingValues(savedValues);
    setOpen(false);
  }

  const selectedLabels = items.reduce((prev, item) => {
    if (savedValues.includes(item.value)) {
      prev.push(item.label);
    }

    return prev;
  }, [] as string[]);

  return (
    <DropdownContextMenu
      isOpen={isOpen}
      onClose={handleClose}
      title={
        <Chip
          onClick={() => setOpen(!isOpen)}
          selectedIcon={selectedIcon}
          label={t(typeOfSpaceLabel(selectedLabels))}
          selected={Boolean(editingValues.length)}
          deleteIcon={
            <Icon
              name="angle-down"
              set={ICON_SET_FONTAWESOME}
              onClick={() => setOpen(!isOpen)}
            />
          }
        />
      }
      hasRippleEffect
    >
      <MultiselectContextMenu
        items={items}
        onChange={setEditingValues}
        values={editingValues}
        header={
          <H5 className={styles.header} mb={3}>
            {t('Type of space')}
          </H5>
        }
        footer={
          <>
            <hr data-margin-top={3} data-margin-bottom={0} />
            <div className={styles.actions}>
              <Button variant="text" onClick={handleClear}>
                {t('Clear')}
              </Button>
              <Button variant="contained" onClick={handleApply}>
                {t('Apply')}
              </Button>
            </div>
          </>
        }
      />
    </DropdownContextMenu>
  );
}
