import gql from 'graphql-tag';

export default gql`
  query ChannelsByPermission(
    $pagination: PaginationInput!
    $search: ChannelPermissionSearch!
  ) {
    channelsByPermission(pagination: $pagination, search: $search) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        tags
        _id
        name
        slug
        type
        isPublic
        isDiscoverable
        inviteOnly
        parent {
          _id
        }
        address {
          _id
          name
          street1
          street2
          street3
          city
          state
          country
          code
          geo
          distance
        }
        profile {
          _id
          name
          image
          logo
        }
        stats {
          subscribers
        }
        settings {
          multiLanguageEnabled
          channelLanguages
          enabledLanguagesForMembers
        }
      }
    }
  }
`;
