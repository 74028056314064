import * as yup from 'yup';

import {
  prettyPhoneNumber,
  simplifyPhoneNumber,
} from '../../helpers/formatters';
import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

export class PhoneNumberType extends StringTypeBase {
  group = TYPE_GROUP_TEXT;

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  name = 'PhoneNumber';

  friendlyName = 'Phone Number';

  get example() {
    return '8005551234';
  }

  get default(): any {
    return '';
  }

  buildSchema() {
    return yup.string().nullable();
  }

  serialize = (value: any) => simplifyPhoneNumber(value);

  parseValue = (value: any) => simplifyPhoneNumber(value);

  formatter = (value: any) => prettyPhoneNumber(value);
}

const definition = new PhoneNumberType();

Types.addBaseType(definition);
export default definition;
