import { Interval } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import parseDateTime from './parseDateTime';

export default function doDateRangesOverlap(
  rangeA: DateRangeType,
  rangeB: DateRangeType
): boolean {
  try {
    const intervalA = Interval.fromDateTimes(
      parseDateTime(rangeA.startDate)!,
      parseDateTime(rangeA.endDate)!
    );
    const intervalB = Interval.fromDateTimes(
      parseDateTime(rangeB.startDate)!,
      parseDateTime(rangeB.endDate)!
    );

    return intervalA.overlaps(intervalB);
  } catch (err) {
    return false;
  }
}
