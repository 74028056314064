import { isEdgeBrowser } from './isEdgeBrowser';
import { isIE11 } from './isIE11';

type Options = {
  top?: number;
  left?: number;
  behavior?: 'smooth' | 'auto';
};

export default function scrollTo(
  el: Element | null,
  { top, left, behavior = 'smooth' }: Options
) {
  if (!el) {
    return;
  }

  if (isIE11 || isEdgeBrowser) {
    if (top) {
      el.scrollTop = top;
    }

    if (left) {
      el.scrollLeft = left;
    }
  } else {
    el.scrollTo({ top, left, behavior });
  }
}
