import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getEquipmentForExport($channelId: UUID!) {
    getEquipmentForExport(channelId: $channelId) {
      equipment {
        id
        channelId
        name
        status
        location
        category
        make
        model
        asset
        serial
        floor
        suite
        installDate
        warrantyExpirationDate
      }
    }
  }
`);
