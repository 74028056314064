import gql from 'graphql-tag';

export default gql`
  query getEssensysInvoice($invoiceId: String!, $companyId: UUID) {
    essensys {
      invoice(invoiceId: $invoiceId, companyId: $companyId) {
        _id
        number
        accountId
        accountName
        approved
        clientTypeId
        clientTypeName
        currencyCode
        startDate
        description
        emailAddress
        endingBalance
        gross
        net
        endDate
        location
        locationName
        paid
        organizationId
        organizationName
        outstanding
        tax
      }
    }
  }
`;
