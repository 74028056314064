import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { useQuery } from '@apollo/client';

import { getSection } from 'lane-shared/graphql/query';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { DocumentType } from 'lane-shared/types/DocumentType';

import CardContainer from 'components/cards/CardContainer';
import IconButton from 'components/general/IconButton';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import SectionSelector from 'components/lane/SectionSelector';
import { H5 } from 'components/typography';

import styles from './SectionSelectorButton.scss';

type OwnProps = {
  className?: string;
  sectionId: string;
  channelId: string;
  channelSlug: string | undefined;
  isInvalid?: boolean;
  disabled?: boolean;
  onSectionSelected: (section: DocumentType | null) => void;
};

SectionSelectorButton.defaultProps = {
  sectionId: null,
  channelId: null,
  onSectionSelected: () => null,
};

type Props = OwnProps & typeof SectionSelectorButton.defaultProps;

export default function SectionSelectorButton({
  className,
  sectionId,
  channelId,
  channelSlug,
  isInvalid,
  disabled,
  onSectionSelected,
}: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [section, setSection] = useState<any>(undefined);

  useQuery(getSection, {
    fetchPolicy: 'cache-and-network',
    skip: !sectionId,
    variables: {
      id: sectionId,
    },
    onCompleted(data) {
      setSection(data.section);
    },
  });

  return (
    <>
      <div
        className={cx(styles.SectionSelectorButton, className)}
        data-cy="sectionSelector"
        data-disabled={disabled}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => setIsOpen(true)}
      >
        <CardContainer
          data-invalid={!!isInvalid}
          data-disabled={disabled}
          size="small"
          className={styles.card}
        >
          {!section && (
            <>
              <H5 className={styles.noSectionText}>{t('Attach a section')}</H5>
              <Icon
                className={styles.linkIcon}
                name="link"
                set={ICON_SET_FONTAWESOME}
                type="far"
              />
            </>
          )}
          {section && (
            <>
              {section.backgroundImage && (
                <div
                  data-cy="section-background-image"
                  className={styles.previewImage}
                  style={{
                    backgroundImage: `url(${imageUrl(
                      section.backgroundImage
                    )})`,
                  }}
                />
              )}
              {!section.backgroundImage && (
                <div
                  data-cy="section-background-color"
                  className={styles.previewColor}
                  style={{ backgroundColor: section.backgroundColor }}
                />
              )}
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; title: any; className: any;... Remove this comment to see the full error message */}
              <H5 title={section.name} className={styles.sectionName}>
                {section.name}
              </H5>
              <IconButton
                className={styles.removeIconButton}
                iconSet={ICON_SET_FONTAWESOME}
                type="far"
                size="small"
                icon="times"
                onClick={e => {
                  // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
                  onSectionSelected(null);
                  e.stopPropagation();
                }}
              />
            </>
          )}
        </CardContainer>
      </div>

      <ModalBackground
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        className={styles.background}
      >
        {isOpen && (
          <ResizableWindow
            name="SectionSelectorButton"
            className={styles.window}
            defaultPosition={ResizableWindow.fullScreen()}
            onClose={() => setIsOpen(false)}
            showHeader
          >
            <SectionSelector
              channelId={channelId}
              channelSlug={channelSlug}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(section: any) => void' is not assignable to... Remove this comment to see the full error message
              onSectionSelected={(section: any) => {
                // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
                onSectionSelected(section);
                setIsOpen(false);
              }}
            />
          </ResizableWindow>
        )}
      </ModalBackground>
    </>
  );
}
