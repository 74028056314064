import React from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { parseDate } from 'lane-shared/helpers/dates';
import { useFlag } from 'lane-shared/hooks';
import { useReservableTimePickerEnabled } from 'lane-shared/hooks/useReservableTimePickerEnabled';
import { useUserNotesEnabled } from 'lane-shared/hooks/useUserNotesEnabled';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import {
  CONTENT_TYPES_WITH_DATE,
  SCHEDULED_CONTENT_TYPES,
} from 'lane-shared/types/content/ContentTypeEnum';
import {
  ReservableFeatureProperties,
  ReservableUiTypesEnum,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import RelatedSpaces from './RelatedSpaces';
import ReservableBufferTime from './ReservableBufferTime';
import ReservableDateRange from './ReservableDateRange';
import ReservableReservationEnd from './ReservableReservationEnd';
import ReservableReservationStart from './ReservableReservationStart';
import { ReservableUserNotes } from './ReservableUserNotes/ReservableUserNotes';

const TRANSLATION_KEYS = {
  dateRangeTooltip:
    'web.content.features.reservable.ReservableTabAdvanced.dateRange.tooltip',
};

export default function ReservableTabAdvanced({
  feature,
  content,
  settings,
  timeZone,
  onFeatureUpdated,
  isGoogleCalendarSyncEnabled,
  isOutlookCalendarSyncEnabled,
}: any) {
  const { t } = useTranslation();
  const isReservableTimePickerEnabled = useReservableTimePickerEnabled();
  const isUserNotesEnabled = useUserNotesEnabled();

  function getDefaultDateRange(): DateRangeType {
    let startDate: Date | null = null;
    let endDate: Date | null = null;

    if (content.startDate && content.endDate) {
      startDate = parseDate(content.startDate);
      endDate = parseDate(content.endDate);
    } else {
      startDate = DateTime.local()
        .setZone(timeZone)
        .plus({ days: 1 })
        .startOf('day')
        .toJSDate();
      endDate = DateTime.local()
        .setZone(timeZone)
        .plus({ days: 5 })
        .endOf('day')
        .toJSDate();
    }

    return {
      startDate,
      endDate,
    };
  }

  function onDateRangeUpdated() {
    onFeatureUpdated({
      dateRange: settings.dateRange ? null : getDefaultDateRange(),
    });
  }

  function onReservationStartUpdated() {
    const update: Partial<ReservableFeatureProperties> = {
      mainRule: {
        ...settings.mainRule,
        unitsAfterNow: 1,
        unitsAfterNowType: ReservableUnitTypesEnum.Days,
      },
    };

    // set back to 0 to disable
    if (settings.mainRule.unitsAfterNow !== 0) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      update.mainRule.unitsAfterNow = 0;
    }

    onFeatureUpdated(update);
  }

  function onReservationEndUpdated() {
    const update: Partial<ReservableFeatureProperties> = {
      mainRule: {
        ...settings.mainRule,

        unitsBeforeStart: 1,
        unitsBeforeStartType: ReservableUnitTypesEnum.Days,
      },
    };

    // set back to 0 to disable
    if (settings.mainRule.unitsBeforeStart !== 0) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      update.mainRule.unitsBeforeStart = 0;
    }

    onFeatureUpdated(update);
  }

  const onBufferTimeUpdated = (isEnabled: boolean) => {
    if (isEnabled) {
      onFeatureUpdated({
        bufferTime: {
          isEnabled,
        },
        uiType: isReservableTimePickerEnabled
          ? ReservableUiTypesEnum.TimePicker
          : ReservableUiTypesEnum.TimeSlider,
      });
    } else {
      onFeatureUpdated({
        bufferTime: {},
      });
    }
  };

  const getTextOrUndefined = (text?: string) => {
    return text && text.length > 0 ? text : undefined;
  };

  const handleUserNotesUpdate = ({
    isEnabled,
    labelText,
    placeholderText,
  }: {
    isEnabled?: boolean;
    labelText?: string;
    placeholderText?: string;
  }) => {
    if (isEnabled) {
      onFeatureUpdated({
        userNotes: {
          labelText: getTextOrUndefined(labelText),
          placeholderText: getTextOrUndefined(placeholderText),
        },
      });

      return;
    }

    onFeatureUpdated({
      userNotes: undefined,
    });
  };

  const showCollectBufferTime = useFlag('tm-3527-buffer-time', false);

  return (
    <>
      {isUserNotesEnabled && (
        <ReservableUserNotes
          onChange={handleUserNotesUpdate}
          labelText={settings?.userNotes?.labelText}
          placeholderText={settings?.userNotes?.placeholderText}
          options={{
            isEnabled: !!settings?.userNotes,
          }}
          toggle={{
            description: t(feature.properties.userNotes.description),
            title: t(feature.properties.userNotes.friendlyName),
          }}
        />
      )}
      {showCollectBufferTime && (
        <ReservableBufferTime
          settings={settings}
          onChange={onBufferTimeUpdated}
          description={t(feature.properties.bufferTime.description)}
          title={t(feature.properties.bufferTime.friendlyName)}
          onFeatureUpdated={onFeatureUpdated}
          showCalendarInput={
            isGoogleCalendarSyncEnabled || isOutlookCalendarSyncEnabled
          }
        />
      )}
      <RelatedSpaces
        content={content}
        settings={settings}
        onChange={value => onFeatureUpdated({ ...settings, ...value })}
        tooltipDescription={t(feature.properties.relatedSpaces.description)}
        title={t(feature.properties.relatedSpaces.friendlyName)}
        disabled={[
          ...CONTENT_TYPES_WITH_DATE,
          ...SCHEDULED_CONTENT_TYPES,
        ].includes(content.type)}
      />
      <ReservableDateRange
        description={t(feature.properties.dateRange.description)}
        onChange={onDateRangeUpdated}
        settings={settings}
        timeZone={timeZone}
        onFeatureUpdated={onFeatureUpdated}
        TooltipComponent={t(TRANSLATION_KEYS.dateRangeTooltip)}
      />
      <ReservableReservationStart
        settings={settings}
        onChange={onReservationStartUpdated}
        onFeatureUpdated={onFeatureUpdated}
      />
      <ReservableReservationEnd
        settings={settings}
        onFeatureUpdated={onFeatureUpdated}
        onChange={onReservationEndUpdated}
      />
    </>
  );
}
