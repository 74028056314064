import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const BuildingEnginesClassicDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.BuildingEnginesClassic,
  hasCustomUI: true,
  friendlyName: 'Building Engines Classic API',
  description: `Defines the properties required for a BuildingEngines Classic integration.`,
  category: IntegrationCategoryEnum.Maintenance,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'The base URL of the Building Engines Classic API endpoint',
      type: 'Url',
      default:
        'https://848f8cdc-1deb-4122-9b6a-4b21d67aeacc.integration-hook.com',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    baseUrl: {
      friendlyName: 'Base Url',
      description:
        'Override the standard base URL for BuildingEngines Classic?',
      type: 'Url',
    },
    name: {
      friendlyName: 'Display Name',
      type: 'String',
      description: 'The name to display for this BuildingEngines Classic form',
      default: 'Building Engines Classic',
    },
    subtitle: {
      friendlyName: 'Subtitle',
      type: 'String',
      description: 'The subtitle for this BuildingEngines Classic form',
      default: 'Tenant Service Request',
    },
    description: {
      friendlyName: 'Service Description',
      type: 'LongText',
      description:
        'The description to display to the end user for this BuildingEngines Classic form.',
      default: 'Submit your request here.',
    },
    buildingId: {
      friendlyName: 'Building ID',
      type: 'String',
      description: 'Building ID that is stored in BuildingEngines system',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    supportEmail: {
      friendlyName: 'Support Email',
      type: 'Email',
    },
  },
  interactionData: {
    spaceId: {
      friendlyName: 'Floor/Space',
      description: 'The unique id for the location of this request.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    requestTypeId: {
      friendlyName: 'Request Type',
      description: 'The unique id for the request type.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    description: {
      friendlyName: 'Description',
      description: 'The description of this work order.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default BuildingEnginesClassicDefinition;
