import { useState, useRef, useLayoutEffect } from 'react';

import { getBoundingClientRect, getScrollX, getScrollY } from 'helpers';

import useWindowSize from 'lane-shared/hooks/useWindowSize';

export enum ModalPositionEnum {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Dropdown = 'dropdown',
  Bottom = 'bottom',
}

/**
 * Helps position modal popups and drop downs.
 *
 * @param options.isRelativePosition is the menu control position relative from its
 * parent or is it absolute positioned on screen
 *
 * @param options.align where to the position the modal, to the left,
 * right or center of the button.  defaults to right
 */

const MINIMAL_PADDING = 20;

type Options = {
  isRelativePosition?: boolean;
  align?: ModalPositionEnum;
  autoFocus?: boolean;
};

export default function useModalPosition(
  options: Options = {
    isRelativePosition: false,
    align: ModalPositionEnum.Right,
    autoFocus: true,
  }
) {
  const { isRelativePosition, align, autoFocus } = options;

  const childrenRef = useRef(null);
  const buttonRef = useRef<HTMLElement>(null);
  const modalRef = useRef<HTMLElement>(null);
  const [position, setPosition] = useState<{
    top: number;
    left: number;
    height?: number;
    width?: number;
    overflowY?: string;
  } | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onOpen(e: any) {
    if (!buttonRef.current) {
      return;
    }

    // if a click event was passed in, we will check to make sure that the
    // click event originated from the buttonRef. otherwise don't fire the
    // open.
    if (
      e?.nativeEvent?.target &&
      !buttonRef?.current?.contains(e.nativeEvent.target)
    ) {
      return;
    }

    const offsetX = e?.nativeEvent ? 0 : getScrollX();
    const offsetY = e?.nativeEvent ? 0 : getScrollY();
    let x;
    let y;
    let modalHeight: any;
    let overflowY: any;

    const rect = getBoundingClientRect(buttonRef.current);

    if (
      align !== ModalPositionEnum.Dropdown &&
      e?.nativeEvent?.pageX &&
      e?.nativeEvent?.pageY
    ) {
      x = e.nativeEvent.pageX;
      y = e.nativeEvent.pageY;
    } else {
      // find out where this button is on screen.
      x = rect.x;
      y = rect.y + rect.height;
    }

    let left = 0;
    let top = 0;
    let width: any;

    if (!isRelativePosition) {
      top = y + offsetY;
      left = x + offsetX;
    }

    setPosition({ left, top });

    // open the modal or control.
    setIsOpen(() => true);

    setTimeout(() => {
      // find out the size of the control, make sure its positioned in the
      // window bounds.

      if (!modalRef.current) {
        return;
      }

      const modal = getBoundingClientRect(modalRef.current);

      if (align === ModalPositionEnum.Center) {
        left -= modal.width / 2;
      }

      if (align === ModalPositionEnum.Left) {
        left -= modal.width;
      }

      if (align === ModalPositionEnum.Dropdown) {
        const button = getBoundingClientRect(childrenRef.current);
        left = button.left;
      }

      // is the control off the bottom of the page?
      if (
        top - getScrollY() + modal.height + MINIMAL_PADDING * 2 >
        window.innerHeight
      ) {
        // if the control is taller than the entire page, it has to scroll.
        // otherwise we can position it at the bottom of the page.

        if (modal.height > window.innerHeight) {
          overflowY = 'auto';
          top = getScrollY() + MINIMAL_PADDING;
          modalHeight =
            window.innerHeight - (top - getScrollY()) - MINIMAL_PADDING * 2;
        } else {
          top -= modal.height;
        }
      }

      // is the control off the top of the page?
      if (top - getScrollY() < 0) {
        top = getScrollY() + MINIMAL_PADDING;
      }

      if (left - offsetX + modal.width > window.innerWidth) {
        left = window.innerWidth - modal.width;
      }

      setPosition({ top, left, width, height: modalHeight, overflowY });

      // stops the element from flickering on screen if the
      // element has set the visibility css to hidden.
      modalRef.current.style.visibility = 'visible';
    }, 10);

    if (autoFocus) {
      setTimeout(() => {
        if (modalRef.current) {
          modalRef.current.focus();
        }
      }, 25);
    }
  }

  function onClose() {
    setIsOpen(() => false);
  }

  const [width, height] = useWindowSize();

  useLayoutEffect(() => {
    setIsOpen(false);
  }, [width, height]);

  return {
    buttonRef,
    modalRef,
    childrenRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } as const;
}
