/* eslint-disable */
import React from 'react';

import cx from 'classnames';

import getBlockStyle from '../helpers/getBlockStyle';
import hasLink from '../helpers/hasLink';
import onBlockClick from '../helpers/onBlockClick';

import styles from './styles.scss';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function TextBlock({ block, parent }: any) {
  const style = getBlockStyle(block, parent);
  const doesHaveLink = hasLink(block);
  const html = {
    __html: block.text && block.text.en ? block.text.en : block.text,
  };

  return (
    <div
      className={cx(styles.buildBlock, { [styles.hasLink]: doesHaveLink })}
      onClick={e => onBlockClick(e, block)}
      style={style}
      dangerouslySetInnerHTML={html}
    />
  );
}
