import { UrlSearchParams, Row, Dir } from './types';

type SortRowParams = {
  urlSearchParams: UrlSearchParams;
  disabled?: boolean;
};

type SortResult = 1 | -1 | 0;

/**
 * Sorting function used if table sorting is handled client-side.
 * Can be disabled if sorting is done server-side.
 */
export function sortTableRows(
  a: Row,
  b: Row,
  { urlSearchParams, disabled = false }: SortRowParams
): SortResult {
  if (!urlSearchParams.sortKey || disabled) {
    return 0;
  }

  const itemA = a.items.find(item => item.key === urlSearchParams.sortKey)!;
  const itemB = b.items.find(item => item.key === urlSearchParams.sortKey)!;
  const direction = urlSearchParams.dir === Dir.asc ? -1 : 1;

  if (itemA.type === 'custom' || itemB.type === 'custom') {
    return 0;
  }

  if (!itemA.value && !itemB.value) {
    return 0;
  }

  if (itemA.type === 'string') {
    const value = itemA.value < itemB.value ? 1 : -1;
    return (value * direction) as SortResult;
  }

  if (itemA.type === 'date') {
    const dateA = new Date(itemA.value);
    const dateB = new Date(itemB.value as string);

    const value = dateA < dateB ? 1 : -1;
    return (value * direction) as SortResult;
  }

  if (itemA.type === 'number') {
    const regexNotANumber = /[^0-9]/g;
    const numA = Number(String(itemA.value).replace(regexNotANumber, ''));
    const numB = Number(String(itemB.value).replace(regexNotANumber, ''));

    const value = numA < numB ? 1 : -1;
    return (value * direction) as SortResult;
  }

  const _exhaustiveCheck: never = itemA;
  return _exhaustiveCheck;
}
