import * as yup from 'yup';

import longAddress from '../../helpers/formatters/longAddress';
import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';
import LocationType from './Location';

const properties: PropertiesInterface = {
  name: {
    type: 'String',
    friendlyName: 'Name',
    validators: [
      {
        name: 'Max',
        value: 255,
      },
    ],
  },
  street1: {
    type: 'String',
    friendlyName: 'Street',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  street2: {
    friendlyName: 'Street 2',
    type: 'String',
  },
  street3: {
    friendlyName: 'Street 3',
    type: 'String',
  },
  city: {
    friendlyName: 'City',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  state: {
    friendlyName: 'State / Province',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  country: {
    friendlyName: 'Country',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  code: {
    friendlyName: 'Post Code',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  geo: {
    friendlyName: 'Geo coordinates',
    type: 'Location',
  },
};

export class AddressType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Address';

  friendlyName = 'Address';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  superUserOnly = true;

  get example() {
    return {
      name: '',
      street1: '123 Test St',
      street2: '# 500',
      city: 'Chicago',
      state: 'IL',
      country: 'USA',
      code: '110120',
      geo: LocationType.example,
    };
  }

  get default(): any {
    return {
      name: '',
      street1: '',
      street2: '',
      street3: '',
      city: '',
      state: '',
      country: '',
      code: '',
      geo: LocationType.default,
    };
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }

  formatter = longAddress;

  properties = properties;
}

const definition = new AddressType();

Types.addBaseType(definition);

export default definition;
