import React from 'react';

import { DateTime } from 'luxon';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { useTimeSlotInput } from 'lane-shared/hooks/useTimeSlotInput';

import { TimeSlotSelect } from './TimeSlotSelect';
import { TimeSlotLoader } from './TimeSlotLoader';
import { useTimeSlotLoader } from './useTimeSlotLoader';

type TimeSlotInputProps = {
  timeSlots: DateRangeType[];
  onClick: (range: DateRangeType) => void;
  unavailableByRules: DateRangeType[];
  unavailableByOccupied: DateRangeType[];
  selectedTimeSlot: DateRangeType;
  timeZone?: string;
  selectedDay?: Date;
  contentId?: string;
  isFetchingReservable?: boolean;
  maxQuantityPerSlotPerUser: number;
};

export function TimeSlotInput({
  timeSlots,
  onClick,
  timeZone,
  unavailableByRules,
  unavailableByOccupied,
  selectedTimeSlot,
  selectedDay,
  contentId,
  isFetchingReservable,
  maxQuantityPerSlotPerUser,
}: TimeSlotInputProps) {
  const nowWithTimezone = timeZone
    ? DateTime.now().setZone(timeZone)
    : DateTime.now();

  const pastTimeRange: DateRangeType[] = [
    {
      startDate: nowWithTimezone?.startOf('day').toJSDate(),
      endDate: nowWithTimezone?.toJSDate(),
    },
  ];

  const notify = (message: string) => {
    window.Toast.show(message);
  };

  const { availableWaitListTimeSlots } = useTimeSlotInput({
    timeSlots,
    unavailableByRules: [...unavailableByRules, ...pastTimeRange],
    unavailableByOccupied,
    onClick,
    selectedTimeSlot,
    contentId,
    notify,
    selectedDay,
    maxQuantityPerSlotPerUser,
  });

  const { isLoading } = useTimeSlotLoader({
    timeSlots,
    unavailableByRules: [...unavailableByRules, ...pastTimeRange],
    availableTimes: availableWaitListTimeSlots,
    selectedDay,
  });

  if (isLoading || isFetchingReservable) {
    return <TimeSlotLoader />;
  }

  return (
    <TimeSlotSelect
      timeSlots={availableWaitListTimeSlots}
      timeZone={timeZone}
      selectedTimeSlot={selectedTimeSlot}
    />
  );
}
