import React from 'react';

import { getScrollX, getScrollY } from '../../helpers';

import styles from './BlockDropFrame.scss';

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Props = {
  dropAny: boolean;
  dropTop: boolean;
  dropBottom: boolean;
  dropLeft: boolean;
  dropRight: boolean;
  position?: Position;
};

const DEFAULT_POSITION = {
  x: 0,
  y: 0,
  width: 200,
  height: 200,
};

function BlockDropFrame({
  dropAny,
  dropTop,
  dropBottom,
  dropLeft,
  dropRight,
  position = DEFAULT_POSITION,
}: Props) {
  const top = getScrollY() + position.y;
  const left = getScrollX() + position.x;
  const { width } = position;
  const { height } = position;

  return (
    <div
      className={styles.BlockDropFrame}
      style={{
        top,
        left,
        width,
        height,
      }}
    >
      <div className={styles.dropAny} data-is-active={dropAny} />
      <div className={styles.dropTop} data-is-active={dropTop} />
      <div className={styles.dropRight} data-is-active={dropRight} />
      <div className={styles.dropBottom} data-is-active={dropBottom} />
      <div className={styles.dropLeft} data-is-active={dropLeft} />
    </div>
  );
}

export default BlockDropFrame;
