import { matchPath } from 'react-router';

import { useQuery } from '@apollo/client';

import { defaultContext } from 'lane-shared/contexts/ChannelsContext';
import getChannelForAnonymous from 'lane-shared/graphql/query/getChannelForAnonymous';
import getChannelForAnonymousContent from 'lane-shared/graphql/query/getChannelForAnonymousContent';
import getChannelForAnonymousSection from 'lane-shared/graphql/query/getChannelForAnonymousSection';

function getPage() {
  const paths = [
    '/l/tab/:channelSlug', // /l/tab/:channelSlug/:tabSlug
    '/l/post/:postId', // /l/post/:id
    '/l/section/:sectionId', // /l/section/:id
  ];

  const pathname = window.location.pathname;
  const match = matchPath(pathname, { path: paths });

  return match?.params || {};
}

function getChannelQuery() {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'channelSlug' does not exist on type '{}'... Remove this comment to see the full error message
  const { channelSlug, postId, sectionId } = getPage();

  if (channelSlug) {
    return {
      query: getChannelForAnonymous,
      variables: { slug: channelSlug },
      destructure: (data: any) => data?.channel,
    };
  }

  if (postId) {
    return {
      query: getChannelForAnonymousContent,
      variables: { id: postId },
      destructure: (data: any) => data?.content?.channel,
    };
  }

  if (sectionId) {
    return {
      query: getChannelForAnonymousSection,
      variables: { id: sectionId },
      destructure: (data: any) => data?.section?.channel,
    };
  }

  // We're on an unknown page, we don't know how (or need) to get the channel.
  // Due to useQuery, we have to pass in the query regardless, though it will be
  // skipped.
  return {
    query: getChannelForAnonymous,
    variables: { slug: ' ' },
    destructure: (_: any) => null,
    skipQuery: true,
  };
}

export default function useAnonymousChannelsData({
  user,
  isInitialized,
  isLoggingIn,
}: any) {
  const { query, variables, destructure, skipQuery } = getChannelQuery();
  const skip = user || !isInitialized || isLoggingIn || Boolean(skipQuery);

  const { data, loading, error } = useQuery(query, {
    variables,
    skip,
    fetchPolicy: 'no-cache',
  });

  const channel = destructure(data);

  if (!channel) {
    return defaultContext;
  }

  return {
    ...defaultContext,
    channels: [channel],
    channelsInitialized: true,
    focusOnChannels: [channel],
    isReady: true,
    pages: channel?.pages,
    primaryChannel: channel,
    primaryChannelInitialized: true,
    primaryId: channel?._id,
    loading,
    error,
  } as const;
}
