import i18n from 'localization';

import { BaseType } from '../../types/baseTypes/TypeBase';
import { ArrayUniqueValidator } from './ArrayUnique';

function findDuplicatesInPrimitiveArray(arr: (number | string)[]): boolean {
  // TODO: https://linear.app/joinlane/issue/LANE-1475/arrayunique-validation-logic-is-hard-to-read
  // loop through the array of values looking for any value that satisfies
  // the search condition
  return !arr.some((a, i) => {
    // loop through the array again, looking for values that match, excluding
    // the array element we are one right now.
    return arr.some((b, j) => a === b && i !== j);
  });
}

function findDuplicatesInTypedArray(arr: { _id: string }[]): boolean {
  return !arr.some((a, i) =>
    arr.some((b, j) => a && b && a._id === b._id && i !== j)
  );
}

function findDuplicatesInDateArray(arr: Date[]): boolean {
  return !arr.some((a, i) =>
    arr.some((b, j) => a && b && a.getTime() === b.getTime() && i !== j)
  );
}

export function addArrayUniqueValidatorImpl(
  type: BaseType,
  _: ArrayUniqueValidator
) {
  type._arraySchema = type.arraySchema.test(
    'array-unique',
    i18n.t('{{- field}} must have all unique values.', {
      field: type.getDisplayName(),
    }),
    (value: any) => {
      if (!value || value <= 1) {
        return true;
      }

      // TODO: https://linear.app/joinlane/issue/LANE-1469/move-type-specific-logic-from-validator-classes-to-respective-base

      // will need to do some introspection on the values to determine what
      // they are.  find the first non null value.
      const testValue = value.find(
        (item: any) => item !== undefined && item !== null
      );

      const primitiveType = typeof testValue;

      if (['number', 'string', 'boolean'].includes(primitiveType)) {
        return findDuplicatesInPrimitiveArray(value);
      }

      if (testValue?._id) {
        return findDuplicatesInTypedArray(value);
      }

      if (testValue?.constructor === Date) {
        return findDuplicatesInDateArray(value);
      }

      // todo: once 1469 is implemented there will be more types supported
      return false;
    }
  );
}
