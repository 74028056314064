import React from 'react';

import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import FeatureOptionToggle from '../../components/FeatureOptionToggle';

import styles from './ReservableUserNotes.scss';

const TRANSLATION_KEYS = {
  labelInputLabel:
    'web.content.features.reservable.advancedTab.userNotes.labelInput.label',
  labelInputPlaceholder:
    'web.content.features.reservable.advancedTab.userNotes.labelInput.placeholder',
  placeholderInputLabel:
    'web.content.features.reservable.advancedTab.userNotes.placeholderInput.label',
  placeholderInputPlaceholder:
    'web.content.features.reservable.advancedTab.userNotes.placeholderInput.placeholder',
};

type Props = {
  onChange: ({
    isEnabled,
    labelText,
    placeholderText,
  }: {
    isEnabled?: boolean;
    labelText?: string;
    placeholderText?: string;
  }) => void;
  labelText: string;
  placeholderText: string;
  toggle: {
    description: string;
    title: string;
  };
  options: {
    isEnabled: boolean;
  };
};

type UserNotesProps = {
  onChange: ({
    label,
    placeHolder,
  }: {
    label: string;
    placeHolder: string;
  }) => void;
  labelText: string;
  placeholderText: string;
};

function UserNotes({ onChange, labelText, placeholderText }: UserNotesProps) {
  const { t } = useTranslation();

  const handleLabelChange = (text: string) => {
    onChange({ label: text, placeHolder: placeholderText });
  };

  const handlePlaceholderChange = (text: string) => {
    onChange({ label: labelText, placeHolder: text });
  };

  return (
    <div className={styles.userNotesContainer}>
      <Input
        success
        label={t(TRANSLATION_KEYS.labelInputLabel)}
        placeholder={t(TRANSLATION_KEYS.labelInputPlaceholder)}
        fixedLabel
        onChange={handleLabelChange}
        value={labelText}
      />
      <br />
      <Input
        success
        label={t(TRANSLATION_KEYS.placeholderInputLabel)}
        placeholder={t(TRANSLATION_KEYS.placeholderInputPlaceholder)}
        fixedLabel
        onChange={handlePlaceholderChange}
        value={placeholderText}
      />
    </div>
  );
}

export function ReservableUserNotes({
  onChange,
  labelText,
  placeholderText,
  toggle,
  options: { isEnabled = false },
}: Props) {
  const handleToggleChange = (isEnabled: boolean) => {
    onChange({ isEnabled });
  };

  const handleUserNotesChange = ({
    label,
    placeHolder,
  }: {
    label: string;
    placeHolder: string;
  }) => {
    onChange({
      isEnabled,
      labelText: label,
      placeholderText: placeHolder,
    });
  };

  return (
    <FeatureOptionToggle
      title={toggle.title}
      description={toggle.description}
      onChange={handleToggleChange}
      isExpanded={isEnabled}
      isAligned
    >
      <UserNotes
        onChange={handleUserNotesChange}
        labelText={labelText}
        placeholderText={placeholderText}
      />
    </FeatureOptionToggle>
  );
}
