import { useState, useEffect, useRef } from 'react';

const useContainerLayoutListener = (threshold: number) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isBelowThreshold, setIsBelowThreshold] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setIsBelowThreshold(containerRef.current.offsetWidth <= threshold);
      }
    };
    handleResize();
    if (!ResizeObserver) return;
    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return {
    containerRef,
    isBelowThreshold,
  };
};

export default useContainerLayoutListener;
