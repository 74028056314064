import React from 'react';

import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';

import { getContentStatus } from 'lane-shared/helpers/content/getContentStatus';
import { useFlag } from 'lane-shared/hooks';
import type { PillColor } from 'lane-shared/types/general/pill';

import Pill from 'components/general/Pill';

import styles from './ContentStatusPill.scss';

type Props = {
  startDate: Date;
  endDate: Date;
  isPreview: boolean;
};

function ContentStatusPill({ startDate, endDate, isPreview }: Props) {
  const [isAdminView] = useIsAdminView();
  const isEndDateUpdatesEnabled = useFlag(
    'astro-cats.content-status-pills',
    false
  );
  const [text, color] = getContentStatus(startDate, endDate);
  const { t } = useTranslation();

  return (
    <>
      {isAdminView && !isPreview && text && isEndDateUpdatesEnabled && color && (
        <div className={styles.pillContainer}>
          <Pill size="small" color={color as PillColor} text={t(text)} />
        </div>
      )}
    </>
  );
}

export default ContentStatusPill;
