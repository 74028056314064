import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SIMPLE_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import styles from './ServiceRequestCard.scss';

function ServiceRequestCard({ style, className, interaction }: any) {
  const request =
    interaction.data[`_${IntegrationProviderEnum.ServiceRequest}`];
  const { t } = useTranslation();

  return (
    <div className={cx(styles.ServiceRequestCard, className)} style={style}>
      <h1>{t('Service Request')}</h1>
      <p>
        {t('Submitted')}:{' '}
        <span>{dateFormatter(interaction._created, SIMPLE_DATE_TIME)}</span>
      </p>
      <p>
        {t('Request Type')}: <span>{request.type}</span>
      </p>
      <p>
        {t('Description')}: <span>{request.description}</span>
      </p>
    </div>
  );
}

export default ServiceRequestCard;
