/**
 * Packaged are combination of a Lane primitive type plus some
 * validation packaged together to make creating things easier to use
 * for our end users, both on the admin side and on the end user side.
 *
 * For example, a Checkboxes packaged type is a String type, that is an
 * array, with an ArrayMaxLength and ArrayMinLength validator on it.
 *
 * A Slider is a number type with a Min validation and a Max validator on it.
 *
 * These are not meant to be an all encompassing list of possibilities, they
 * are meant to cover the most common uses cases.
 */
export enum PackagedTypeEnum {
  None = 'None',
  Slider = 'Slider',
  Quantity = 'Quantity',
  Dropdown = 'Dropdown',
  Checkboxes = 'Checkboxes',
  Radios = 'Radios',
  MultiSelect = 'MultiSelect',
}
