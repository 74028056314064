import React from 'react';

import cx from 'classnames';

import { MarginType } from 'components/typography';

import styles from './ControlMenu.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  mt?: MarginType;
  mb?: MarginType;
};

export default function ControlMenu({
  className,
  style,
  children,
  mb = 4,
  mt,
}: Props) {
  return (
    <menu
      className={cx(styles.ControlMenu, className)}
      style={style}
      data-margin-top={mt}
      data-margin-bottom={mb}
    >
      {children}
    </menu>
  );
}
