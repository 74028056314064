import React, { useState } from 'react';
import { M, Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import styles from './PaymentV2NoProducts.scss';
import { AddProductPanel } from './AddProductPanel';
import {
  AddProductToContentDetails,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

export function PaymentV2NoProducts({
  handleProductAddition,
  currency,
  inclusivePricing,
}: {
  handleProductAddition: (product: AddProductToContentDetails) => void;
  currency: SupportedPaymentCurrency;
  inclusivePricing: boolean;
}) {
  const { t } = useTranslation();

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const openAddProductsPanel = () => {
    setIsPanelOpen(true);
  };

  const closeAddProductsPanel = () => {
    setIsPanelOpen(false);
  };

  const handleProductSelection = (product: AddProductToContentDetails) => {
    handleProductAddition(product);
  };

  return (
    <>
      <div className={styles.addProductButton}>
        <Button
          size="large"
          onClick={() => {
            openAddProductsPanel();
          }}
        >
          {t('web.content.features.paymentv2.addProducts')}
        </Button>
      </div>
      <div className={styles.wrapper}>
        <M>{t('web.content.features.paymentv2.noProducts')}</M>
      </div>
      <AddProductPanel
        handleProductSelection={handleProductSelection}
        currency={currency}
        isPanelOpen={isPanelOpen}
        onPanelClose={closeAddProductsPanel}
        inclusivePricing={inclusivePricing}
      />
    </>
  );
}
