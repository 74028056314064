import React from 'react';

import { useTranslation } from 'react-i18next';

import { getValidationMessages, toSchema } from 'lane-shared/helpers';
import { CONTENT_CATEGORIES } from 'lane-shared/types/content/ContentCategoryEnum';

import MultiselectField from 'components/form/MultiselectField';

import styles from './ContentSearchability.scss';
import { useQuery } from '@apollo/client';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getClient } from 'lane-shared/apollo';

import { getTags } from 'lane-shared/graphql/content';
import { createTag } from 'lane-shared/graphql/mutation';

export function ContentTags({
  content,
  channel,
  validation,
  onContentUpdated,
}: any) {
  const { t } = useTranslation();

  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);

  const { data, loading: queryLoading, refetch } = useQuery(getTags, {
    variables: {
      channelId: channel?._id,
    },
    skip: !areContentTagsEnabled || !channel,
  });

  const tags = data?.tags || [];
  const selectedTags = content.contentTags?.map((id: any) => ({
    value: id,
  }));

  const createTagMutation = async ({
    name,
    name_l10n,
  }: {
    name: string;
    name_l10n?: Record<string, string>;
  }) => {
    try {
      const { data } = await getClient().mutate({
        mutation: createTag,
        variables: {
          tag: {
            name,
            name_l10n,
            channelId: channel?._id,
          },
        },
      });

      const contentTags = [
        ...(selectedTags?.map((tag: { value: string }) => tag.value) || []),
        data.createTag.id,
      ];

      const tags = [...content.tags, data.createTag.name];

      onContentUpdated({ contentTags, tags });

      refetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={styles.ContentSearchability}>
      <label>
        {t(
          'web.admin.content.draftContent.info.contentSearchability.tags.inputLabel'
        )}
      </label>
      {areContentTagsEnabled ? (
        <MultiselectField
          isFullWidth
          placeholder={t(
            'web.admin.content.draftContent.info.contentSearchability.tags.inputPlaceHolder'
          )}
          doTranslation={false}
          errors={getValidationMessages(validation, 'tags')}
          items={tags}
          schema={{
            label: 'name',
            value: 'id',
          }}
          isLoading={queryLoading}
          value={selectedTags}
          className={styles.multiselect}
          onChange={newTypes => {
            // need to update both tags and contentTags for backward compatibility
            onContentUpdated({
              tags: newTypes.map(tag => tag.name),
              contentTags: newTypes.map(tag => tag.id),
            });
          }}
          onCreateOption={name => {
            createTagMutation({ name });
          }}
          noOptionsMessage="No tags found"
        />
      ) : (
        <MultiselectField
          placeholder={t(
            'web.admin.content.draftContent.info.contentSearchability.tags.inputPlaceHolder'
          )}
          errors={getValidationMessages(validation, 'tags')}
          items={CONTENT_CATEGORIES}
          value={content.tags?.map(toSchema)}
          className={styles.multiselect}
          onChange={newTypes =>
            onContentUpdated({
              tags: newTypes.map(item => item.value),
            })
          }
        />
      )}
    </div>
  );
}
