import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().min(3).trim().required(),
  channel: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required('Channel is required'),
  contactUser: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required('Client Contact is required'),
  salesUser: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required('Account Manager is required'),
  managerUser: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required('Customer Success Manager is required'),
  supportUser: yup
    .object()
    .shape({
      _id: yup.string().required(),
    })
    .required('Customer Success person is required'),
  lineItems: yup.array().required().min(1),
  // todo: yup validation on array with min + of does not seem to work
  // .of(validateCreateChannelContractLineItem),
});
