import { useEffect, useState } from 'react';

import { WorkflowWhenEnum } from 'lane-shared/types/Workflows';

/**
 * Note that we are not guaranteeing delivery of anything on a literally
 * specific time.  We will deliver things AROUND any of these times.  This
 * is because it is incredibly difficult to have this guarantee and also not
 * required based on our use cases.
 *
 * This is why we have split these options into general categories of delivery
 * window and not letting the user select 1.5 minutes or 2 months and 3 days.
 *
 * Also why we don't care about the length of the month specifically, if you
 * select 1 month before or after, the event will be triggered ABOUT 1 month
 * before or after +- 2 days.
 */

export enum TimeUnitEnum {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}

const timeOptions: {
  [key in TimeUnitEnum]: { value: number; label: string }[];
} = {
  [TimeUnitEnum.Minutes]: [
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 45,
      label: '45',
    },
  ],
  [TimeUnitEnum.Hours]: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 11,
      label: '11',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 13,
      label: '13',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 17,
      label: '17',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 19,
      label: '19',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 21,
      label: '21',
    },
    {
      value: 22,
      label: '22',
    },
    {
      value: 23,
      label: '23',
    },
  ],
  [TimeUnitEnum.Days]: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ],
  [TimeUnitEnum.Weeks]: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
  ],
  [TimeUnitEnum.Months]: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
  ],
};

const timeUnitOptions = [
  {
    value: TimeUnitEnum.Minutes,
    label: 'shared.timeUnits.minutes',
  },
  {
    value: TimeUnitEnum.Hours,
    label: 'shared.timeUnits.hours',
  },
  {
    value: TimeUnitEnum.Days,
    label: 'shared.timeUnits.days',
  },
  {
    value: TimeUnitEnum.Weeks,
    label: 'shared.timeUnits.weeks',
  },
  {
    value: TimeUnitEnum.Months,
    label: 'shared.timeUnits.months',
  },
];

const MILLIS_PER_SECOND = 1000;

const millisecondsPer: {
  [key in TimeUnitEnum]: number;
} = {
  [TimeUnitEnum.Minutes]: MILLIS_PER_SECOND * 60,
  [TimeUnitEnum.Hours]: MILLIS_PER_SECOND * 60 * 60,
  [TimeUnitEnum.Days]: MILLIS_PER_SECOND * 60 * 60 * 24,
  [TimeUnitEnum.Weeks]: MILLIS_PER_SECOND * 60 * 60 * 24 * 7,
  // average length of a month
  [TimeUnitEnum.Months]: MILLIS_PER_SECOND * 60 * 60 * 24 * 30.436875,
};

export function getDefaultTime(when: WorkflowWhenEnum): number | undefined {
  switch (when) {
    case WorkflowWhenEnum.After:
      return (
        millisecondsPer[TimeUnitEnum.Minutes] *
        timeOptions[TimeUnitEnum.Minutes][0]!.value
      );
    case WorkflowWhenEnum.Before:
      return -(
        millisecondsPer[TimeUnitEnum.Minutes] *
        timeOptions[TimeUnitEnum.Minutes][0]!.value
      );
    case WorkflowWhenEnum.Immediate:
      return undefined;
  }
}

type Props = {
  when: WorkflowWhenEnum;
  time: number | undefined;
  onTimeUpdated: (time: number | undefined) => void;
};

export default function useTimeUnits({
  when,
  time,
  onTimeUpdated = () => null,
}: Props) {
  const [timeUnit, setTimeUnit] = useState(TimeUnitEnum.Minutes);
  const [timeValue, setTimeValue] = useState(
    millisecondsPer[TimeUnitEnum.Minutes]
  );

  // Before is in negative ms, After is positive ms.
  const multiplier = when === WorkflowWhenEnum.Before ? -1 : 1;

  function updateTimeUnit(newUnit: TimeUnitEnum) {
    setTimeUnit(newUnit);

    // when a time unit is changed, set the value to the smallest one in
    // the list of options to reset it.
    const smallestValue = timeOptions[newUnit][0]!.value;

    setTimeValue(smallestValue);
    onTimeUpdated(multiplier * smallestValue * millisecondsPer[newUnit]);
  }

  function updateTimeUnitValue(value: number) {
    setTimeValue(value);
    onTimeUpdated(multiplier * value * millisecondsPer[timeUnit]);
  }

  // the time in the database is stored in ms for easily calculating times,
  // convert the time to a specific timeUnit and timeValue here for a user
  // friendly interface.
  useEffect(() => {
    if (time === undefined) {
      return;
    }

    let newTimeUnit: TimeUnitEnum | null = null;
    let unitValue: number | null = null;
    // Before is in negative ms, After is positive ms.
    const absTime = Math.abs(Number(time));

    // find the matching time value to the possible values
    for (const timeUnitKey of Object.values(TimeUnitEnum)) {
      const options = timeOptions[timeUnitKey];

      const foundOption = options.find(
        option => option.value * millisecondsPer[timeUnitKey] === absTime
      );

      if (foundOption) {
        newTimeUnit = timeUnitKey;
        unitValue = foundOption.value;
        break;
      }
    }

    if (newTimeUnit === null || unitValue === null) {
      // We will also fit it here to the user displayed values, its possible a
      // different ms value was set thru the API that what we offer in our
      // dropdowns.

      // if the time value is more than the smallest value of a time unit, then
      // it must be in that unit.
      if (absTime >= millisecondsPer[TimeUnitEnum.Months]) {
        newTimeUnit = TimeUnitEnum.Months;
      } else if (absTime >= millisecondsPer[TimeUnitEnum.Weeks]) {
        newTimeUnit = TimeUnitEnum.Weeks;
      } else if (absTime >= millisecondsPer[TimeUnitEnum.Days]) {
        newTimeUnit = TimeUnitEnum.Days;
      } else if (absTime >= millisecondsPer[TimeUnitEnum.Hours]) {
        newTimeUnit = TimeUnitEnum.Hours;
      } else {
        newTimeUnit = TimeUnitEnum.Minutes;
      }

      // convert the time given into a integer value.
      unitValue = Math.round(absTime / millisecondsPer[newTimeUnit]);

      if (!timeOptions[newTimeUnit].some(({ value }) => value === unitValue)) {
        unitValue = timeOptions[newTimeUnit][0]!.value;
      }
    }

    setTimeUnit(newTimeUnit);
    setTimeValue(unitValue);
  }, [time]);

  return {
    timeUnit,
    timeValue,
    timeUnitOptions,
    timeOptions,
    updateTimeUnit,
    updateTimeUnitValue,
  };
}
