import React from 'react';

import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import ShopifyBurstContextProvider from 'lane-shared/hooks/integrations/shopify/ShopifyBurstContextProvider';
import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';
import { ShopifyBurstInteractionType } from 'lane-shared/types/integrations/ShopifyBurst';
import { ShopifyRsvpStatusEnum } from 'lane-shared/types/integrations/ShopifyRsvp';

import BurstReadOnlyView from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit/BurstReadOnlyView';

import BurstInteractionHeader from '../components/BurstInteractionHeader';

import styles from './BurstInteractionView.scss';

type Props = {
  interaction: Partial<UserContentInteractionType>;
};

export default function BurstInteractionView({ interaction }: Props) {
  const history = useHistory();

  if (!interaction?.data?._ShopifyBurst) {
    return null;
  }

  const data = (interaction.data
    ._ShopifyBurst as unknown) as ShopifyBurstInteractionType;

  const rsvpContentId = data?.rsvpContentId;

  return (
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'content' does not exist on type 'Partial... Remove this comment to see the full error message
    <ShopifyBurstContextProvider contentId={interaction.content._id}>
      <>
        <BurstInteractionHeader
          header={data.name}
          title="Created Burst"
          status={(interaction.status as unknown) as ShopifyRsvpStatusEnum}
          onClose={() => history.push(routes.home)}
        />
        <div className={styles.summary}>
          <BurstReadOnlyView
            burstData={data}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string | undefined; }' is not assigna... Remove this comment to see the full error message
            rsvpContent={{ _id: rsvpContentId }}
          />
        </div>
      </>
    </ShopifyBurstContextProvider>
  );
}
