/* eslint-disable */
import React from 'react';

import getBlockStyle from '../helpers/getBlockStyle';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function InputBlock({ block, parent }: any) {
  const style = getBlockStyle(block, parent);

  return <div style={style}>InputBlock</div>;
}
