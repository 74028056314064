import { useContext, useEffect } from 'react';
import useIsAdminView from 'hooks/useIsAdminView';
import {
  UserDataContext,
  ChannelsContext,
  LaunchDarklyContext,
} from 'lane-shared/contexts';
import { useChannelProfileQuery } from 'lane-shared/hooks';
import { LDUserCustom } from 'lane-shared/hooks/useLaunchDarklyUser';

/**
 * LaunchDarkly attributes need to be set depending on the channel in view and not the focused channel.
 * i.e: if a user switches to ChannelA (focused channel) and views ChannelB via the admin portal management or by navigating to the sub channel
 * or parent channel, the feature flags for ChannelB should be used instead of ChannelA
 */
export const useSetChannelLDAttributes = () => {
  const { user } = useContext(UserDataContext);
  const [isAdminView, channelSlug] = useIsAdminView();
  const { primaryChannel } = useContext(ChannelsContext);
  const { client: lDClient } = useContext(LaunchDarklyContext);

  const { channel: adminChannel } = useChannelProfileQuery({
    channelId: channelSlug,
  });

  useEffect(() => {
    const channelInViewId = isAdminView
      ? adminChannel?._id || primaryChannel?._id
      : primaryChannel?._id;

    if (!channelInViewId) {
      return;
    }

    const attributesToSet: Partial<LDUserCustom> = {
      focusedChannelId: channelInViewId,
    };

    if (user?.roles) {
      const groupRolesOfChannelInView =
        user?.roles.filter(
          role => role?.groupRole?.channel?._id === channelInViewId
        ) ?? [];

      attributesToSet.groupRoleIds = groupRolesOfChannelInView.map(
        role => role._id
      );
    }

    lDClient.setAttribute(attributesToSet);
  }, [adminChannel, primaryChannel, user?.roles, lDClient, isAdminView]);
};
