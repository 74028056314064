// Please refer MUI documentation for implementation details
// https://mui.com/material-ui/react-autocomplete/#custom-input
import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { AutocompleteRenderInputParams } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import styles from './MuiAutocompleteCustomInput.scss';

export default function MuiAutocompleteCustomInput({
  params,
  loading,
  onClick,
  onClear,
  isDropdownOpen,
  showClearIcon,
}: {
  loading: boolean;
  onClick: () => void;
  onClear: () => void;
  showClearIcon: boolean;
  isDropdownOpen: boolean;
  params: AutocompleteRenderInputParams;
}) {
  const { t } = useTranslation();

  params.InputProps = {
    ...params.InputProps,
    endAdornment: (
      <>
        {loading ? <CircularProgress color="inherit" size={20} /> : null}
        {params.InputProps.endAdornment}
      </>
    ),
  };

  return (
    <div ref={params.InputProps.ref} className={styles.inputContainer}>
      <input
        type="text"
        {...params.inputProps}
        className={styles.input}
        placeholder={t(
          'web.components.form.MuiAutocompleteCustomInput.selectRelatedSpace'
        )}
        onMouseDown={onClick}
      />
      {showClearIcon && (
        <Icon
          set={ICON_SET_FONTAWESOME}
          name="times"
          onClick={onClear}
          className={styles.closeIcon}
          testID="clear-icon"
        />
      )}
      <Icon
        set={ICON_SET_FONTAWESOME}
        name={isDropdownOpen ? 'chevron-up' : 'chevron-down'}
        onClick={onClick}
        className={styles.caretIcon}
      />
    </div>
  );
}
