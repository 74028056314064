import { NotFoundError, OAuthConfigError } from 'activate-errors';
import Storage, { StorageKeys } from 'lane-shared/helpers/Storage';
import { OAuthProvidersEnum } from 'lane-shared/helpers/constants/user';
import getOAuthIdentity from 'lane-shared/helpers/getOAuthIdentity';
import {
  oAuthConfigScheme,
  OAuthConfigShape,
  MICROSOFT_TENANT,
} from 'lane-shared/helpers/oAuth';
import getMicrosoftGraphClient from 'lane-shared/services/microsoftGraphClient';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { UserLoginProviderEnum } from 'lane-shared/types/UserLogin';
import { OAuthIdentity, AzureADMe } from 'lane-shared/types/oauth';

import OAuthService, { AuthorizeResult } from './OAuth.service';

const NO_ERROR = null;

const DEFAULT_SCOPES = ['openid', 'profile', 'email'];

const AZURE_AD_AUTH_STATE = `${OAuthProvidersEnum.AZURE_AD}:AUTH_STATE`;

function getAzureADOAuthConfig(
  oAuthConfig: OAuthConfigType | any = {},
  scopes: string[]
): OAuthConfigShape {
  const {
    tenantId,
    webClientId: clientId,
    webRedirectUrl: redirectUrl,
  } = oAuthConfig;

  try {
    return oAuthConfigScheme.validateSync({
      ...oAuthConfig,
      issuer: `https://login.microsoftonline.com/${
        tenantId || MICROSOFT_TENANT
      }/v2.0/`,
      clientId,
      redirectUrl,
      scopes,
      provider: UserLoginProviderEnum.AzureAD,
    });
  } catch (err) {
    throw new OAuthConfigError();
  }
}

type GetAzureADAccessTokenProps = {
  oAuthConfig: OAuthConfigType | undefined;
  options?: { scopes?: string[]; skipStorage?: boolean };
};

export async function getAzureADAccessToken({
  oAuthConfig,
  options: { scopes = DEFAULT_SCOPES, skipStorage = false } = {
    scopes: DEFAULT_SCOPES,
    skipStorage: false,
  },
}: GetAzureADAccessTokenProps): Promise<AuthorizeResult> {
  const azureADConfig = getAzureADOAuthConfig(oAuthConfig, scopes);

  const azureADOAuthService = new OAuthService({ config: azureADConfig });

  if (!skipStorage) {
    try {
      const knownState = await Storage.getItem(StorageKeys.AZURE_AD_AUTH_STATE);

      const accessTokenExpirationDate = new Date(
        knownState.accessTokenExpirationDate
      );

      if (new Date() < accessTokenExpirationDate) {
        return knownState;
      }

      if (knownState.refreshToken) {
        const refreshResult = await azureADOAuthService.refresh(
          knownState.refreshToken
        );
        if (refreshResult) {
          Storage.setItem(AZURE_AD_AUTH_STATE, refreshResult);
          return refreshResult;
        }
        // NOTE: Indicates failure to refresh, fallback
        // to `authorize` flow, remove known storage object
        // to prevent repeat events.
        await Storage.removeItem(AZURE_AD_AUTH_STATE);
      }
    } catch (err) {
      // NOTE: Swallow NotFoundError thrown by `getItem`
      // it will be set by `setItem` below.
      if (!(err instanceof NotFoundError)) {
        throw err;
      }
    }
  }

  const authState = await azureADOAuthService.authorize();
  Storage.setItem(AZURE_AD_AUTH_STATE, authState);
  return authState;
}

export async function getAzureOAuthIdentity(
  oAuthConfig: OAuthConfigType | undefined,
  options: { scopes?: string[]; mode: 'LogIn' | 'SignUp' }
): Promise<OAuthIdentity> {
  const tokens = await getAzureADAccessToken({ oAuthConfig, options });
  if (!tokens) {
    throw Error('The Azure AD user could not be verified.');
  }
  return getOAuthIdentity(tokens.idToken);
}

export async function getAzureADProfile(
  oAuthConfig: OAuthConfigType | undefined
): Promise<AzureADMe> {
  const authState: AuthorizeResult = await getAzureADAccessToken({
    oAuthConfig,
  });

  const microsoftGraphClient = getMicrosoftGraphClient(callback => {
    callback(NO_ERROR, authState.accessToken);
  });

  const user: AzureADMe = await microsoftGraphClient.api('/me').get();

  return user;
}
