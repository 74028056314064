import { Primitive } from '../../../types/blocks/Primitive';

const CarouselBlock: Primitive = {
  name: 'CarouselBlock',
  friendlyName: 'Carousel',
  isInput: false,
  isPrimitive: true,
  isAvailableInEditor: true,
  tags: ['Media'],
  properties: {
    images: {
      isArray: true,
      type: 'Image',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
        {
          name: 'ArrayMax',
          value: 16,
        },
      ],
    },
    timing: {
      type: 'Number',
      friendlyName: 'Pause in ms',
      default: 5000,
      validators: [
        {
          name: 'Min',
          value: 1000,
        },
        {
          name: 'Max',
          value: 25000,
        },
      ],
    },
    sizing: {
      type: 'String',
      default: 'cover',
      validators: [
        {
          name: 'In',
          value: ['contain', 'cover'],
        },
      ],
    },
    useGradient: {
      type: 'Boolean',
      default: 'true',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default CarouselBlock;
