import React from 'react';

export enum MODAL_HISTORY_EVENT_TYPES {
  DEFAULT = 'default',
  LINKED_ACCOUNTS = 'linkedAccounts',
  CONFIRM_YOUR_ACCOUNT_INFO = 'confirmYourAccountInfo',
}

export interface ModalHistoryEventPropTypes {
  title: string;
  component: React.ComponentType<any>;
  componentProps?: Record<any, any>;
  actions?: React.ComponentType<any>;
  actionsProps?: Record<any, any>;
}
export type ModalHistoryMapType = {
  [key in MODAL_HISTORY_EVENT_TYPES]: ModalHistoryEventPropTypes;
};
