import React from 'react';

import { useTranslation } from 'react-i18next';

import { M } from 'components/typography';

interface TableColumn {
  title: string;
  dataIndex: string;
  key: string;
}

export function useGetCategoriesTableColumns(): TableColumn[] {
  const { t } = useTranslation();
  const categoryTableColumns: any = [
    {
      key: 'name',
      header: t('abp.categoryList.categoryName'),
      renderCell: renderStringCell,
    },
    {
      key: 'productType',
      header: t('abp.categoryList.productType'),
      renderCell: (cell: string) => renderProductTypeCell(cell, t),
    },
    {
      key: 'description',
      header: t('abp.categoryList.description'),
      renderCell: renderStringCell,
    },
  ];

  return categoryTableColumns;
}

function renderStringCell(cell: string) {
  return renderReactString(cell);
}

function renderProductTypeCell(
  cell: string,
  t: (key: string, opts?: any) => string
) {
  const productTypeWithoutPrefix = cell
    .replace('PRODUCT_TYPE_SERVICE_', '')
    .toLowerCase();

  const translatedString = t(
    `abp.productsServices.createCategory.productServiceType.${productTypeWithoutPrefix}`
  );

  return renderReactString(translatedString);
}

function renderReactString(stringToRender: string) {
  return <M>{stringToRender}</M>;
}
