import { useCallback, useMemo, useState } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import useFlag from '../../useFlag';
import useShopifyGuests from './useShopifyGuests';

type UseShopifyGuestsProps = Parameters<typeof useShopifyGuests>;
type ShopifyGuests = ReturnType<typeof useShopifyGuests>;

type GetTeam = {
  team: {
    users: Array<{
      _id: LaneType.UUID;
      name: string;
      profile: {
        _id: LaneType.UUID;
        email: string;
        phone: string | null;
        name: string;
        image: string | null;
        logo: string | null;
      };
    }>;
  };
};

export const GET_TEAM_QUERY = gql`
  query GetTeam($teamId: UUID!) {
    team(_id: $teamId) {
      users {
        _id
        name
        profile {
          _id
          email
          phone
          name
          image
          logo
        }
      }
    }
  }
`;

export function useM6Approvers(
  ..._props: UseShopifyGuestsProps
): ShopifyGuests {
  const [search, setSearch] = useState('');

  const shopifyM6TeamId = useFlag<LaneType.UUID | ''>(
    'flex.shopify-m6-team-id',
    ''
  );

  const shopifyGuests = useShopifyGuests(..._props);

  const getTeam = useQuery<GetTeam>(GET_TEAM_QUERY, {
    variables: { teamId: shopifyM6TeamId },
    skip: !shopifyM6TeamId,
  });

  const users = useMemo(() => {
    const users = getTeam.data?.team.users;

    if (!users) {
      return [];
    }

    return users.filter(u =>
      u.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, getTeam.data?.team.users]);

  const updateSearch = useCallback(
    (nextSearch: string) => {
      setSearch(nextSearch);
    },
    [setSearch]
  );

  if (shopifyM6TeamId) {
    return {
      updateSearch,
      users,
      search,
    };
  }

  return shopifyGuests;
}
