import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import styles from './BarButton.scss';

type BarButtonProps = {
  title: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  dataCy?: string;
  defaultExpanded?: boolean;
  onClick?: () => void;
};

// TODO: Add an Icon component prop that replaces the current Icon
export function BarButton({
  title,
  className,
  style,
  dataCy,
  onClick,
}: BarButtonProps) {
  return (
    <div className={cx(styles.BarButton, className)} style={style}>
      <div className={styles.wrapper}>
        <button data-cy={dataCy} className={styles.button} onClick={onClick}>
          <span>{title}</span>
          <Icon className={styles.icon} set="Feather" name="ExternalLink" />
        </button>
      </div>
    </div>
  );
}
