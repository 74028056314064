import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { UserDataContext } from 'lane-shared/contexts';
import {
  getTimeZoneByGeoLocation,
  getLibraryOptions,
  getValidationMessages,
} from 'lane-shared/helpers';
import {
  PERMISSION_METATAG_CREATE,
  PERMISSION_METATAG_UPDATE,
} from 'lane-shared/helpers/constants/permissions';
import hasDates from 'lane-shared/helpers/content/hasDates';
import hasPermission from 'lane-shared/helpers/hasPermission';
import { useChannelTheme, useFlag } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import {
  ContentTypeEnum,
  CONTENT_TYPES_WITH_ICON,
} from 'lane-shared/types/content/ContentTypeEnum';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';

import { Input, TextArea } from 'components/form';
import IconPicker from 'components/form/IconPicker';
import Loading from 'components/general/Loading';
import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import IconBatchVariableInfo from 'components/lane/IconBatchVariableInfo';
import ThemePaletteColorSelectorButton from 'components/lane/ThemePaletteColorSelectorButton';
import { Flex } from 'components/layout';
import { H2, H5 } from 'components/typography';

import MediaPickerButton from '../MediaPickerButton';
import CategorySelector from './CategorySelector';
import ContentCardPreview from './components/ContentCardPreview';
import { ContentSearchability } from './components/ContentSearchability';
import { ContentTags } from './components/ContentTags';
import DraftContentEvent from './components/DraftContentEvent';

import { IconSet } from 'lane-shared/helpers/constants/icons';

import styles from './DraftInfo.scss';
import type { StepperTrackerType } from 'lane-shared/hooks/analytics/useDraftContentAnalytics';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

const TRANSLATION_KEYS = {
  generalInfoText: 'web.admin.content.draftContent.info.generalInfo.text',
  translationCopyText:
    'web.admin.content.draftContent.info.generalInfo.translation',
  titleInputLabel:
    'web.admin.content.draftContent.info.generalInfo.title.inputLabel',
  titleInputPlaceholder:
    'web.admin.content.draftContent.info.generalInfo.title.inputPlaceHolder',
  titleTranslationInputPlaceholder:
    'web.admin.content.draftContent.info.generalInfo.title.translation.inputPlaceHolder',
  descriptionInputLabel:
    'web.admin.content.draftContent.info.generalInfo.description.inputLabel',
  descriptionInputPlaceholder:
    'web.admin.content.draftContent.info.generalInfo.description.inputPlaceHolder',
  descriptionTranslationInputPlaceholder:
    'web.admin.content.draftContent.info.generalInfo.description.translation.inputPlaceHolder',
  backgroundImageInputLabel:
    'web.admin.content.draftContent.info.generalInfo.backgroundImage.inputLabel',
  backgroundColorInputLabel:
    'web.admin.content.draftContent.info.generalInfo.backgroundColor.inputLabel',
  iconInputLabel:
    'web.admin.content.draftContent.info.generalInfo.icon.inputLabel',
  iconColorInputLabel:
    'web.admin.content.draftContent.info.generalInfo.iconColor.inputLabel',
  logoInputLabel:
    'web.admin.content.draftContent.info.generalInfo.logo.inputLabel',
  dataCollectionText: 'web.admin.content.draftContent.info.dataCollection.text',
  categoryInputLabel:
    'web.admin.content.draftContent.info.dataCollection.category.inputLabel',
  reportingGroupInputLabel:
    'web.admin.content.draftContent.info.dataCollection.reportingGroup.inputLabel',
};

/**
 * props "timeZone", "onMetatagAdded", "onMetatagRemoved and "onTimeZoneUpdated"
 * are only optional because of template editing
 */
type Props = {
  channel: ChannelType | null;
  content: DraftContentType;
  validation: ValidationError[] | ValidationError | null;
  library?: any;
  timeZone?: string;
  onContentUpdated: (
    content: Partial<DraftContentType>
  ) => Partial<DraftContentType>;
  onMetatagAdded?: (metatag: any) => Promise<void>;
  onMetatagRemoved?: (metatag: any) => Promise<void>;
  onTimeZoneUpdated?: (timeZone: string) => void;
  stepperTracker?: StepperTrackerType;
};

export default function DraftInfo({
  channel,
  content,
  validation,
  library,
  timeZone,
  onContentUpdated = () => null as any,
  onMetatagAdded,
  onMetatagRemoved,
  onTimeZoneUpdated,
  stepperTracker,
}: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const theme = useChannelTheme(channel);
  const isContentTagsFFEnabled = useFlag(FeatureFlag.ContentTags, false);

  const isGenerator = Boolean(content?.generator);

  const hasMetatagsPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_METATAG_UPDATE, PERMISSION_METATAG_CREATE],
      channel?._id
    );

  function backgroundColorChanged(backgroundColor: any) {
    onContentUpdated({
      backgroundColor,
    });
  }

  function colorChanged(color: any) {
    onContentUpdated({
      color,
    });
  }

  if (!content) {
    return <Loading />;
  }

  const channelTimeZone = getTimeZoneByGeoLocation({
    longitude: channel?.address?.geo?.[0]!,
    latitude: channel?.address?.geo?.[1]!,
  });

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const availableTimeZones = [channelTimeZone];

  if (channelTimeZone !== userTimeZone) {
    availableTimeZones.push(userTimeZone);
  }

  return (
    <div className={styles.DraftContentInfo}>
      <div className={styles.top}>
        <div className={styles.panel}>
          <H2 className={styles.header} mb={4}>
            {t(TRANSLATION_KEYS.generalInfoText)}
          </H2>
          <MultiLanguageWrapper channel={channel} model={content} column="name">
            {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => (
              <div className={styles.formField}>
                <H5>
                  {labelMaker({
                    label: t(TRANSLATION_KEYS.titleInputLabel),
                    required: true,
                  })}
                </H5>
                <Flex>
                  <Input
                    value={valueGetter()}
                    maxLength={44}
                    onChange={name => {
                      const updates = onChangeUpdates(name);
                      onContentUpdated(updates);
                    }}
                    error={
                      isPrimary
                        ? getValidationMessages(validation, 'name')
                        : null
                    }
                    dataCy={isPrimary ? 'name' : 'name_l10n'}
                    showClear={false}
                    placeholder={t(TRANSLATION_KEYS.titleInputPlaceholder)}
                  />
                  {isGenerator && <IconBatchVariableInfo />}
                </Flex>
              </div>
            )}
          </MultiLanguageWrapper>
          <MultiLanguageWrapper
            model={content}
            channel={channel}
            column="description"
          >
            {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => (
              <div className={styles.formField}>
                <H5>
                  {labelMaker({
                    label: t(TRANSLATION_KEYS.descriptionInputLabel),
                    required: true,
                  })}
                </H5>
                <Flex>
                  <TextArea
                    value={valueGetter()}
                    maxLength={255}
                    onChange={description => {
                      const updates = onChangeUpdates(description);
                      onContentUpdated(updates);
                    }}
                    errors={
                      isPrimary
                        ? getValidationMessages(validation, 'description')
                        : null
                    }
                    dataCy={isPrimary ? 'description' : 'description_l10n'}
                    showClear={false}
                    placeholder={t(
                      TRANSLATION_KEYS.descriptionInputPlaceholder
                    )}
                    containerClassName={styles.textAreaContainer}
                    minRows={4}
                  />
                  {isGenerator && <IconBatchVariableInfo />}
                </Flex>
              </div>
            )}
          </MultiLanguageWrapper>

          {hasDates([content?.type] as ContentTypeEnum[]) && (
            <DraftContentEvent
              availableTimeZones={availableTimeZones}
              timeZone={timeZone!}
              content={content}
              validation={validation}
              onTimeZoneUpdated={onTimeZoneUpdated!}
              onContentUpdated={onContentUpdated}
              stepperTracker={stepperTracker}
            />
          )}

          <div className={styles.formField}>
            <label>{t(TRANSLATION_KEYS.backgroundImageInputLabel)}</label>
            <MediaPickerButton
              libraries={getLibraryOptions({ channel, library })}
              media={{ _id: content.backgroundImage }}
              storageKey={channel?._id}
              onMediaSelected={media =>
                onContentUpdated({
                  backgroundImage: media ? media?._id : undefined,
                })
              }
              className={styles.imageButton}
            />
          </div>

          <div className={styles.formField}>
            <label>{t(TRANSLATION_KEYS.backgroundColorInputLabel)}</label>
            <ThemePaletteColorSelectorButton
              value={content.backgroundColor}
              palette={theme.palette!}
              onColorSelected={backgroundColorChanged}
              defaultValue={theme.palette?.primary}
            />
          </div>

          {CONTENT_TYPES_WITH_ICON.includes(content.type) && (
            <>
              <div className={styles.formField}>
                <label>{t(TRANSLATION_KEYS.iconInputLabel)}</label>
                <IconPicker
                  palette={theme!.palette!}
                  icon={content.icon!}
                  iconSet={content.iconSet as IconSet}
                  iconWeight={content.iconWeight}
                  onChange={({ icon, iconSet, iconWeight }) =>
                    onContentUpdated({
                      icon,
                      iconSet,
                      iconWeight,
                    })
                  }
                />
              </div>
              <div className={styles.formField}>
                <label>{t(TRANSLATION_KEYS.iconColorInputLabel)}</label>
                <ThemePaletteColorSelectorButton
                  value={content.color}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ThemeColorPaletteType | undefined' is not as... Remove this comment to see the full error message
                  palette={theme?.palette}
                  onColorSelected={colorChanged}
                  defaultValue={theme.palette?.secondary}
                />
              </div>

              <div className={styles.formField}>
                <label>{t(TRANSLATION_KEYS.logoInputLabel)}</label>
                <MediaPickerButton
                  libraries={getLibraryOptions({ channel })}
                  media={{ _id: content.logo }}
                  storageKey={channel?._id}
                  onMediaSelected={media =>
                    onContentUpdated({
                      logo: (media && media._id) || undefined,
                    })
                  }
                  className={styles.imageButton}
                />
              </div>
            </>
          )}

          <H2 className={styles.header} mb={4} mt={6}>
            {t(TRANSLATION_KEYS.dataCollectionText)}
          </H2>
          <div className={styles.formField}>
            <label>{t(TRANSLATION_KEYS.categoryInputLabel)}</label>
            <CategorySelector
              className={styles.dropDown}
              onValueChange={category =>
                onContentUpdated({
                  category,
                })
              }
              value={content.category}
              errors={getValidationMessages(validation, 'category')}
            />
          </div>

          {!isContentTagsFFEnabled && (
            <div className={styles.formField}>
              <label>{t(TRANSLATION_KEYS.reportingGroupInputLabel)}</label>
              <CategorySelector
                className={styles.dropDown}
                onValueChange={reportingGroup =>
                  onContentUpdated({
                    reportingGroup,
                  })
                }
                value={content.reportingGroup}
                errors={getValidationMessages(validation, 'reportingGroup')}
              />
            </div>
          )}

          <ContentTags
            content={content}
            channel={channel}
            validation={validation}
            onContentUpdated={onContentUpdated}
          />

          {hasMetatagsPermission && (
            <ContentSearchability
              content={content}
              channel={channel}
              onContentUpdated={onContentUpdated}
              onMetatagAdded={onMetatagAdded}
              onMetatagRemoved={onMetatagRemoved}
            />
          )}
        </div>
        <ContentCardPreview
          content={content}
          onContentUpdated={onContentUpdated}
          channel={channel}
          validation={validation}
          isGenerator={isGenerator}
        />
      </div>
    </div>
  );
}
