import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from '../../../apollo';
import { getInteractionsOnContent } from '../../../graphql/query';
import { GeoCoordinateType } from '../../../types/baseTypes/GeoTypes';
import { ContentType } from '../../../types/content/Content';
import { ShopifyRsvpStatusEnum } from '../../../types/integrations/ShopifyRsvp';
import { PublicUserType } from '../../useMyPublicUsersQuery';

type ShopifyUser = {
  _id: LaneType.UUID;
  name: string;
  profile: {
    _id: LaneType.UUID;
    name: string;
    email: LaneType.Email | null;
    image: LaneType.UUID | null;
    logo: LaneType.UUID | null;
  };
};

type Props = {
  rsvpContent: ContentType | undefined | null;
  attendees: PublicUserType[];
};

export default function useShopifyRSVPAttendees({
  rsvpContent,
  attendees,
}: Props) {
  const { data: rsvpInteractionsData } = useQuery<{
    interactionsOnContent: {
      pageInfo: {
        total: number;
        start: number;
        perPage: number;
      };
      items: {
        _id: LaneType.UUID;
        _created: string;
        _updated: string;
        _createdBy: {
          _id: LaneType.UUID;
        };
        _updatedBy: {
          _id: LaneType.UUID;
        };
        user: ShopifyUser;
        startDate: string;
        endDate: string;
        geo: GeoCoordinateType;
        data: any;
        state: any;
        // todo: there is a type for this in another PR
        features: any;
        contentData: ContentType;
        version: number;
        status: string;
        lastStatus: string;
      }[];
    };
  }>(getInteractionsOnContent, {
    client: getClient(),
    skip: !rsvpContent,
    variables: {
      contentId: rsvpContent?._id,
      pagination: {
        start: 0,
        perPage: 1000,
      },
    },
    fetchPolicy: 'network-only',
  });

  return useMemo(() => {
    const rsvpInteractions =
      rsvpInteractionsData?.interactionsOnContent.items || [];

    const attendeesWithStatus = attendees?.map(attendee => {
      const result = rsvpInteractions.find(
        rsvpInteraction => rsvpInteraction.user?._id === attendee?._id
      );

      const status = result ? result?.status : ShopifyRsvpStatusEnum.Awaiting;

      return { details: attendee, status };
    });

    const declinedAttendees = attendeesWithStatus?.filter(
      attendee => attendee.status === ShopifyRsvpStatusEnum.Declined
    );

    const acceptedAttendees = attendeesWithStatus?.filter(
      attendee => attendee.status === ShopifyRsvpStatusEnum.Accepted
    );

    return {
      attendeesWithStatus,
      declinedAttendees,
      acceptedAttendees,
    };
  }, [rsvpInteractionsData?.interactionsOnContent.items]);
}
