import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';
import { TypeContextEnum } from '../../../../types/properties/TypeContextEnum';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    get default(): any {
      return uuid();
    },
  },
  isOpen: {
    type: 'Boolean',
    default: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  timeRanges: {
    type: 'TimeRange',
    friendlyName: 'Time Range',
    description: 'Time Range',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  isAvailableAllDay: {
    type: 'Boolean',
    default: false,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class TimeAvailabilityDayRuleType extends ObjectTypeBase {
  name = 'TimeAvailabilityDayRule';

  contexts = [TypeContextEnum.Properties];

  friendlyName = 'Time Availability Day Rule';

  get example(): any {
    return {
      _id: uuid(),
      isOpen: true,
      isAvailableAllDay: true,
      timeRanges: [],
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      isOpen: true,
      isAvailableAllDay: true,
      timeRanges: [],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new TimeAvailabilityDayRuleType();

Types.addType(definition);
export default definition;
