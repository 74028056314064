import jwtDecode from 'jwt-decode';

import {
  OAuthIdentity,
  OAUTH_IDENTITY_OPTIONAL_FIELDS,
  OAUTH_IDENTITY_REQUIRED_FIELDS,
} from 'lane-shared/types/oauth';

export default function getOAuthIdentity(idToken: string): OAuthIdentity {
  const identityMetadata: any = jwtDecode(idToken);

  const extraFieldSet = new Set(Object.keys(identityMetadata));
  OAUTH_IDENTITY_REQUIRED_FIELDS.forEach(field => extraFieldSet.delete(field));
  OAUTH_IDENTITY_OPTIONAL_FIELDS.forEach(field => extraFieldSet.delete(field));
  const extraEntries = Array.from(extraFieldSet).map(field => [
    field,
    identityMetadata[field],
  ]);

  const extra = [...extraEntries].reduce((obj, [key, val]) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    obj[key] = val;
    return obj;
  }, {});

  const missingRequiredFields = OAUTH_IDENTITY_REQUIRED_FIELDS.filter(
    requiredField => !identityMetadata[requiredField]
  );

  // NOTE: All fields must be included according to spec it
  // is reasonable to throw an error if any field is absent.
  if (missingRequiredFields.length !== 0) {
    throw Error(
      `Provided OAuth identity is incomplete. The following fields are missing: ${missingRequiredFields.join(
        ', '
      )}`
    );
  }

  return {
    ...OAUTH_IDENTITY_REQUIRED_FIELDS.reduce(
      (acc: OAuthIdentity, requiredField: string) => ({
        ...acc,
        [requiredField]: identityMetadata[requiredField],
      }),
      {} as OAuthIdentity
    ),
    ...OAUTH_IDENTITY_OPTIONAL_FIELDS.reduce(
      (acc: Partial<OAuthIdentity>, optionalField: string) => ({
        ...acc,
        [optionalField]: identityMetadata[optionalField],
      }),
      {} as Partial<OAuthIdentity>
    ),
    extra,
  };
}
