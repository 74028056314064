import { QRCodeCheckInMode } from '../../hooks/features/qrCodeCheckIn/useQRCodeCheckIn';

export default function getCheckInOutMessages(
  isCancelled: boolean,
  inClosedState: boolean,
  isExpired: boolean,
  mode: any
): {
  title: string;
  description: string;
  actionButtonTitle?: string;
  showAddToCalendar?: boolean;
  successMessage?: string;
} {
  if (isCancelled) {
    return {
      title: 'Booking Cancelled',
      description: 'Details of your booking cancellation are below.',
    };
  }
  if (inClosedState) {
    return {
      title: 'You’ve Checked Out',
      description: 'Thank you for booking with us.',
    };
  }
  if (mode === QRCodeCheckInMode.CHECK_IN) {
    return {
      title: 'Upcoming Booking',
      description:
        'Your booking is coming up. Tap on the button below to check in to your reservation.',
      actionButtonTitle: 'Check In',
      showAddToCalendar: true,
      successMessage: 'You have successfully checked in.',
    };
  }
  if (mode === QRCodeCheckInMode.CHECK_OUT) {
    const title = isExpired
      ? 'Your reservation has ended'
      : 'You’re Checked In';
    return {
      title,
      description:
        'You can always end your reservation early by tapping the check out button.',
      actionButtonTitle: 'Check Out',
      showAddToCalendar: true,
      successMessage: 'You have successfully checked out.',
    };
  }

  return {
    title: '',
    description: '',
  };
}
