import getOAuthIdentity from 'lane-shared/helpers/getOAuthIdentity';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { OAuthIdentity } from 'lane-shared/types/oauth';

import { getAppleAccessToken } from './getAppleAccessToken';

export async function getAppleOAuthIdentity(
  oAuthConfig: OAuthConfigType | undefined,
  options: { scopes?: string[]; mode: 'LogIn' | 'SignUp' }
): Promise<OAuthIdentity> {
  const accessTokenData = await getAppleAccessToken({ oAuthConfig, options });

  const identity = getOAuthIdentity(accessTokenData.idToken);

  return {
    ...identity,
    extra: {
      user: accessTokenData.user,
    },
  };
}
