import { ReservableUnitTypesEnum } from '../../types/features/ReservableFeatureProperties';

export default function getReservableMinutesFromUnits(
  units: number | undefined | null,
  unitType: ReservableUnitTypesEnum
): number {
  const actualUnits = Number(units || 0);

  switch (unitType) {
    case ReservableUnitTypesEnum.Minutes:
      return actualUnits;
    case ReservableUnitTypesEnum.Days:
      return actualUnits * 24 * 60;
    case ReservableUnitTypesEnum.Weeks:
      return actualUnits * 7 * 24 * 60;
    case ReservableUnitTypesEnum.Months:
      // 30 days is a good enough approximation of # of minutes
      // this function is used for sorting.
      return actualUnits * 30 * 24 * 60;
  }
}
