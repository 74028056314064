import { LibraryType, LibraryTypeEnum } from '../types/libraries';

export default function setLibraryOnLibraryItem(
  libraryItem: {
    [key: string]: any;
  },
  selectedLibrary: LibraryType
): { [key: string]: any } {
  // Depending on the selected library we are adding this item to a
  // channel, user, or library Library type.

  switch (selectedLibrary.type) {
    case LibraryTypeEnum.Channel:
      libraryItem.channel = {
        _id: selectedLibrary._id,
      };
      break;
    case LibraryTypeEnum.User:
      libraryItem.user = {
        _id: selectedLibrary._id,
      };
      break;
    case LibraryTypeEnum.Library:
    default:
      libraryItem.library = {
        _id: selectedLibrary._id,
      };
  }

  return libraryItem;
}
