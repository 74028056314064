import React, { useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { SearchBar } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useQuery } from '@apollo/client';

import { Query } from '@apollo/client/react/components';

import { getClient } from 'lane-shared/apollo';
import { deleteTemplate } from 'lane-shared/graphql/mutation';
import { toSchema, pause } from 'lane-shared/helpers';
import {
  AVAILABLE_TEMPLATE_TYPES,
  FRIENDLY_CONTENT_TYPES,
} from 'lane-shared/helpers/constants/content';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';
import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';
import {
  useMultiLanguage,
  useLibraryPattern,
  useFlag,
} from 'lane-shared/hooks';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { CONTENT_CATEGORIES } from 'lane-shared/types/content/ContentCategoryEnum';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';

import ContentCard from 'components/cards/ContentCard';
import Dropdown from 'components/form/Dropdown';
import MultiselectField from 'components/form/MultiselectField';
import ContextMenu from 'components/general/ContextMenu';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import IconButton from 'components/general/IconButton';
import Loading from 'components/general/Loading';
import Pagination from 'components/general/Pagination';
import { Flex } from 'components/layout';

import addContentTemplateToLibraryMutation from './addContentTemplateToLibraryMutation';
import contentTemplatesOnChannelQuery from './contentTemplatesOnChannelQuery';
import contentTemplatesOnLibraryQuery from './contentTemplatesOnLibraryQuery';
import contentTemplatesOnUserQuery from './contentTemplatesOnUserQuery';
import useQueryString from 'hooks/useQueryString';
import { getTags } from 'lane-shared/graphql/content';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import styles from './TemplateLibrary.scss';

const queryTypes = {
  User: {
    query: contentTemplatesOnUserQuery,
    name: 'contentTemplatesOnUser',
  },
  Channel: {
    query: contentTemplatesOnChannelQuery,
    name: 'contentTemplatesOnChannel',
  },
  Library: {
    query: contentTemplatesOnLibraryQuery,
    name: 'contentTemplatesOnLibrary',
  },
};

const views = ['list', 'th-large'];
const [VIEW_LIST, VIEW_GRID] = views;

const sorts = ['_created', '_updated', 'templateName', 'type', 'isInteractive'];
const [
  SORT_CREATED,
  SORT_UPDATED,
  SORT_NAME,
  SORT_TYPE,
  SORT_INTERACTIVE,
] = sorts;

const headers = [
  { label: 'Created', sort: SORT_CREATED },
  { label: 'Last Update', sort: SORT_UPDATED },
  { label: 'Name', sort: SORT_NAME },
  { label: 'Notes' },
  { label: 'Tags' },
  { label: 'Type', sort: SORT_TYPE },
  { label: 'Interactive', sort: SORT_INTERACTIVE },
  { label: 'Actions' },
];

const STEP_TEMPLATE = 'Template';

const PER_PAGE = 25;
const sortOrders = ['asc', 'desc'];
const [SORT_ASC, SORT_DESC] = sortOrders;
const ANY = 'Any';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  allowedTypes: string[];
  additionalMenu: React.ReactNode;
  libraries?: LibraryType[];
  library?: LibraryType;
  TemplateWrapper: React.FunctionComponent<{
    template: DocumentType;
  }>;
  userLibraryEnabled?: boolean;
};

TemplateLibrary.defaultProps = {
  allowedTypes: null,
  library: null,
  libraries: null,
  additionalMenu: null,
  TemplateWrapper: null,
  userLibraryEnabled: false,
};

type Props = OwnProps & typeof TemplateLibrary.defaultProps;

export default function TemplateLibrary({
  className,
  style,
  allowedTypes,
  additionalMenu,
  library,
  libraries,
  userLibraryEnabled,
  TemplateWrapper,
}: Props) {
  const [query, goToUrl, makeUrl] = useQueryString({
    view: VIEW_GRID,
    sort: SORT_NAME,
    order: SORT_ASC,
    step: STEP_TEMPLATE,
  });
  const { translate } = useMultiLanguage();

  const { t } = useTranslation();

  // There is only one type available.
  const onlyType =
    allowedTypes && (allowedTypes as any).length === 1 ? allowedTypes[0] : null;

  const {
    user,
    selectedLibrary,
    availableLibraries,
    setSelectedLibrary,
  } = useLibraryPattern({
    libraries,
    library,
    userLibraryEnabled,
    storageKey: (library as any)?._id,
  });
  const [page, setPage] = useState(0);
  const [type, setType] = useState<string | null>(onlyType || null);
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);

  useEffect(() => {
    setPage(0);
  }, [search]);

  const { data, loading: tagsLoading } = useQuery(getTags, {
    variables: { channelId: selectedLibrary?._id },
    skip: !areContentTagsEnabled || !selectedLibrary?._id,
  });

  const contentTags: string[] = data?.tags?.map(
    ({ name: value }: { name: string }) => ({
      label: value,
      value,
    })
  );

  async function removeTemplate(template: any, refetch: any) {
    try {
      await window.Alert.confirm({
        title: `Delete Template ${template.templateName}`,
        message: `Are you sure you want to delete Template: ${template.templateName}`,
      });
    } catch (err) {
      return;
    }

    window.Alert.loading({
      title: 'Removing…',
    });

    try {
      await pause(1000);

      await getClient().mutate({
        mutation: deleteTemplate,
        variables: {
          id: template._id,
        },
      });

      refetch();

      window.Toast.show(`Template: ${template.templateName} has been removed.`);
      window.Alert.hide();
    } catch (err) {
      window.Alert.hide();
      await window.Alert.alert({
        title: `Wasn't able to remove Template: ${template.templateName}`,
        error: err,
      });
    }
  }

  if (!selectedLibrary) {
    return <Loading />;
  }

  const selectedView = query.view || VIEW_LIST;
  const selectedSort = query.sort || SORT_NAME;
  const selectedOrder = query.order || SORT_ASC;
  const reverseOrder = selectedOrder === SORT_ASC ? SORT_DESC : SORT_ASC;

  async function addToMyLibrary(template: any) {
    const libraryItem = {
      template: {
        _id: template._id,
      },
      user: {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _id: user._id,
      },
    };

    try {
      await getClient().mutate({
        mutation: addContentTemplateToLibraryMutation,
        variables: {
          libraryItem,
        },
      });

      window.Toast.show('Added to your library.');
    } catch (err) {
      window.Alert.alert({
        title: 'Failed',
        message: `I wasn't able to add this to your library`,
        error: err,
      });
    }
  }

  function onChangeLibrary(value: any) {
    const newLibrary = availableLibraries.find(
      library => library._id === value
    );
    setPage(0);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'LibraryType | undefined' is not ... Remove this comment to see the full error message
    setSelectedLibrary(newLibrary);
  }

  const typeChoices = [
    { label: ANY, value: ANY },
    ...Object.values(AVAILABLE_TEMPLATE_TYPES).map(type => ({
      label: FRIENDLY_CONTENT_TYPES[type],
      value: type,
    })),
  ];

  const queryInfo = queryTypes[selectedLibrary.type];

  const variables = {
    pagination: {
      start: page * PER_PAGE,
      perPage: PER_PAGE,
    },
    search: {
      sortBy: {
        key: selectedSort,
        dir: selectedOrder,
      },
    },
  };

  switch (selectedLibrary.type) {
    case 'Library':
      (variables as any).libraryId = selectedLibrary._id;
      break;
    case 'Channel':
      (variables as any).channelId = selectedLibrary._id;
      break;
    case 'User':
      (variables as any).userId = selectedLibrary._id;
      break;
    default:
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      (variables as any).userId = user._id;
      break;
  }

  if (type && type !== ANY) {
    (variables.search as any).type = type;
  }

  if (tags.length > 0) {
    (variables.search as any).tags = { all: tags };
  }

  if (debouncedSearch) {
    (variables.search as any).search = {
      type: 'like',
      value: debouncedSearch,
    };
  }

  return (
    <div className={cx(styles.TemplateLibrary, className)} style={style}>
      <ControlMenu>
        {availableLibraries.length > 1 && (
          <Dropdown
            id="dropdownTemplateLibrary"
            value={selectedLibrary && selectedLibrary._id}
            className={styles.libraryDropDown}
            placeholder="Select a library"
            onValueChange={onChangeLibrary}
            items={availableLibraries.map(library => ({
              label: library.name,
              value: library._id,
            }))}
          />
        )}
        <SearchBar
          className={styles.search}
          searchOptions={{ search }}
          onSearchChange={search => setSearch(search)}
        />

        <MultiselectField
          placeholder={t('Tags')}
          value={tags.map(toSchema)}
          isLoading={areContentTagsEnabled && tagsLoading}
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          onChange={items => setTags(items.map(item => item.value))}
          items={areContentTagsEnabled ? contentTags : CONTENT_CATEGORIES}
        />

        {typeChoices.length > 1 && (
          <Dropdown
            value={type}
            className={styles.dropDown}
            placeholder={t('Select a type')}
            onValueChange={type => setType(type)}
            initialValue={ANY}
            items={typeChoices}
          />
        )}

        {views.map(view => (
          <Link key={view} to={makeUrl({ view })}>
            <IconButton
              icon={view}
              selected={query.view === view}
              onClick={() => goToUrl({ view })}
              inverted
            />
          </Link>
        ))}

        {additionalMenu}
      </ControlMenu>

      {selectedLibrary && (
        <Query
          query={queryInfo.query}
          variables={variables}
          fetchPolicy="network-only"
        >
          {({ data, refetch, loading, error }: any) => {
            if (error) {
              return <ErrorMessage error={error} />;
            }

            const results = (data && data[queryInfo.name]) || {};

            const templates = translate({
              model: (results.items || [])
                .map((item: any) => item.template)
                .filter((item: any) => item != null),
              columns: ['name', 'description', 'subtitle'],
            });

            const total = (results.pageInfo && results.pageInfo.total) || 0;

            return (
              <>
                <Pagination
                  loading={loading}
                  total={total}
                  page={page}
                  onPage={setPage}
                  perPage={PER_PAGE}
                />
                {selectedView === VIEW_LIST && (
                  <table>
                    <thead>
                      <tr>
                        {headers.map(header => (
                          <th key={header.label}>
                            <Flex align="center">
                              <span>{header.label}</span>
                              {header.sort && (
                                <Link
                                  to={makeUrl({
                                    sort: header.sort,
                                    order: reverseOrder,
                                  })}
                                >
                                  <IconButton
                                    inverted
                                    icon={
                                      selectedOrder === SORT_ASC
                                        ? 'sort-amount-down-alt'
                                        : 'sort-amount-up'
                                    }
                                    className={cx(styles.sortButton, {
                                      [styles.selected]:
                                        selectedSort === header.sort,
                                    })}
                                    selected={selectedSort === header.sort}
                                  />
                                </Link>
                              )}
                            </Flex>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {templates.map((template: any) => (
                        <tr key={template._id}>
                          <td>{simpleDate(template._created)}</td>
                          <td>{fromNow(template._updated)}</td>
                          <td>
                            {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'TemplateWrapper' does not have a... Remove this comment to see the full error message */}
                            <TemplateWrapper template={template}>
                              {template.templateName}
                            </TemplateWrapper>
                          </td>
                          <td>
                            <span style={{ fontSize: '0.8em' }}>
                              {template.notes}
                            </span>
                          </td>
                          <td>{template.tags && template.tags.join(', ')}</td>
                          <td>{template.type}</td>
                          <td>
                            {template.isInteractive && <Icon name="check" />}
                          </td>
                          <td>
                            <IconButton
                              inverted
                              icon="trash"
                              className={styles.deleteIcon}
                              onClick={() => removeTemplate(template, refetch)}
                              selected={false}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {selectedView === VIEW_GRID && (
                  <ul>
                    {templates.map((template: any) => (
                      <li key={template._id}>
                        {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'TemplateWrapper' does not have a... Remove this comment to see the full error message */}
                        <TemplateWrapper template={template}>
                          <div className={styles.wrapper}>
                            <ContentCard
                              className={styles.contentCard}
                              content={template}
                            />
                            <div className={styles.info}>
                              <p>{template.templateName}</p>
                            </div>

                            {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
                            {(user.isSuperUser || userLibraryEnabled) && (
                              <ContextMenu
                                className={styles.optionsMenu}
                                items={[
                                  <button
                                    key="context-button"
                                    onClick={() => addToMyLibrary(template)}
                                  >
                                    Add to my library
                                  </button>,
                                ]}
                              >
                                <IconButton
                                  inverted
                                  icon="ellipsis-v"
                                  type={FONT_AWESOME_REGULAR}
                                  className={styles.addToMyLibrary}
                                />
                              </ContextMenu>
                            )}
                          </div>
                        </TemplateWrapper>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            );
          }}
        </Query>
      )}
    </div>
  );
}
