import React from 'react';

import { Icon } from 'design-system-web';

import styles from './EmptyState.scss';

type Props = {
  showIcon?: boolean;
  children: React.ReactNode;
};

export default function EmptyState({ showIcon = true, children }: Props) {
  return (
    <div className={styles.EmptyState}>
      {showIcon && <Icon name="plus-circle" className={styles.icon} />}
      <p>{children}</p>
    </div>
  );
}
