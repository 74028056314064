import {
  EVENT_CONTENT_STARTED,
  EVENT_CONTENT_ENDED,
  EVENT_CONTENT_ENDED_INTERACTION_DATE,
  EVENT_CONTENT_LIVE,
  EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_CONTENT_INTERACTION_UPDATED,
  EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  EVENT_CONTENT_INTERACTION_STARTED,
  EVENT_CONTENT_INTERACTION_ENDED,
} from './events';

export const EVENT_VERBS = {
  [EVENT_CONTENT_STARTED]: 'has started',
  [EVENT_CONTENT_ENDED]: 'has ended',
  [EVENT_CONTENT_ENDED_INTERACTION_DATE]: 'has ended for interaction',
  [EVENT_CONTENT_LIVE]: 'has gone live',
  [EVENT_CONTENT_INTERACTION_CREATED]: 'submitted',
  [EVENT_CONTENT_INTERACTION_UPDATED]: 'updated',
  [EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED]: 'cancelled',
  [EVENT_CONTENT_INTERACTION_STARTED]: 'has started',
  [EVENT_CONTENT_INTERACTION_ENDED]: 'has ended',
};
