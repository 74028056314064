import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';
import { RequiredValidator } from '../validators/Required';

export const OPTION_NAME_MAXLENGTH = 256;
export const OPTION_VALUE_MAXLENGTH = 2048;

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
  },
  _order: {
    type: 'Number',
    default: 0,
  },
  name: {
    friendlyName: 'Option',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: OPTION_NAME_MAXLENGTH,
      },
    ],
  },
  value: {
    friendlyName: 'Option',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: OPTION_VALUE_MAXLENGTH,
      },
    ],
  },
};

export class OptionType extends ObjectTypeBase {
  group = TYPE_GROUP_OTHER;

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  name = 'Option';

  get default(): any {
    return null;
  }

  formatter = (option: any) => option.name;

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;

  addRequiredValidator(validator: RequiredValidator): any {
    if (validator.config?.value) {
      this._schema = this.schema.test(
        'Required',
        `${this.getDisplayName()} is a required field.`,
        value => {
          return (
            value &&
            ['name', 'value'].every(
              key =>
                key in value && value[key] !== null && value[key] !== undefined
            )
          );
        }
      );
    }

    this._schema = this.schema.notRequired();
  }
}

const definition = new OptionType();

Types.addBaseType(definition);
export default definition;
