import { AnalyticsInterface } from '../../types/Analytics';
import {
  EVENT_WORK_ORDER_COMMENT_ADDED,
  EVENT_WORK_ORDER_CREATED,
} from '../constants/events';

type WorkOrderEvent = {
  userId: string;
  channelId: string;
  integrationId?: string;
  analytics: AnalyticsInterface;
};

type WorkOrderCreatedEvent = WorkOrderEvent;
type WorkOrderCommentCreatedEvent = WorkOrderEvent;

export function emitWorkOrderCreated({
  userId,
  channelId,
  integrationId,
  analytics,
}: WorkOrderCreatedEvent) {
  analytics.track(EVENT_WORK_ORDER_CREATED, {
    userId,
    channelId,
    integrationId,
  });
}

export function emitWorkOrderCommentCreated({
  userId,
  channelId,
  integrationId,
  analytics,
}: WorkOrderCommentCreatedEvent) {
  analytics.track(EVENT_WORK_ORDER_COMMENT_ADDED, {
    userId,
    channelId,
    integrationId,
  });
}
