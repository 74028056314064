import React, { useState } from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import {
  ContentWorkflowType,
  DataValidationRuleType,
} from 'lane-shared/types/ContentWorkflowType';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import { MaybeDraftDataValidationRuleType } from 'components/workflows/dataValidationSchema/type';

import DataValidationRule from './DataValidationRule';

import style from './DataValidationSchema.scss';

export type Props = {
  data: PropertiesInterface;
  dataValidationSchema?: ContentWorkflowType['dataValidationSchema'];
  onSchemaUpdated: (
    dataValidationSchema: ContentWorkflowType['dataValidationSchema']
  ) => void;
  editMode?: boolean;
};

export default function DataValidationSchema({
  data,
  dataValidationSchema = [],
  onSchemaUpdated,
}: Props) {
  const { t } = useTranslation();

  const [draftDataValidationRules, setDraftDataValidationRules] = useState<
    Array<MaybeDraftDataValidationRuleType>
  >([]);

  function addDraftDataValidationRule() {
    setDraftDataValidationRules([...draftDataValidationRules, { _id: uuid() }]);
  }

  function onRuleUpdated(updatedRule: DataValidationRuleType) {
    setDraftDataValidationRules(
      draftDataValidationRules.filter(
        draftRule => draftRule._id !== updatedRule._id
      )
    );
    onSchemaUpdated([
      ...dataValidationSchema?.filter(rule => rule._id !== updatedRule._id),
      updatedRule,
    ]);
  }

  function onRuleDeleted(deletedRule: MaybeDraftDataValidationRuleType) {
    setDraftDataValidationRules(
      draftDataValidationRules.filter(
        draftRule => draftRule._id !== deletedRule._id
      )
    );
    onSchemaUpdated(
      dataValidationSchema?.filter(rule => rule._id !== deletedRule._id)
    );
  }

  return (
    <div className={style.container} role="list">
      {dataValidationSchema &&
        dataValidationSchema.map(rule => (
          <div key={rule._id} className={style.ruleContainer} role="listitem">
            <DataValidationRule
              maybeDraftRule={rule}
              data={data}
              onRuleUpdated={onRuleUpdated}
              onRuleDeleted={onRuleDeleted}
            />
          </div>
        ))}
      {draftDataValidationRules.map(draftRule => (
        <div
          key={draftRule._id}
          className={style.ruleContainer}
          role="listitem"
        >
          <DataValidationRule
            maybeDraftRule={draftRule}
            data={data}
            onRuleUpdated={onRuleUpdated}
            onRuleDeleted={onRuleDeleted}
            isDraft
          />
        </div>
      ))}
      <div className={style.actions}>
        <Button
          className={style.addAction}
          onClick={addDraftDataValidationRule}
          dataCy="button-add-rule"
        >
          {t(
            'web.admin.channel.content.workflow.editor.dataValidationSchema.addRule.button'
          )}
        </Button>
      </div>
    </div>
  );
}
