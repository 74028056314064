import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { useUserIntegrationQuery } from 'lane-shared/hooks';

import WelcomeAngus from 'static/img/angus-welcome.png';

import Button from '../../general/Button';
import Image from '../../general/Image';
import Loading from '../../general/Loading';
import OpenRequests from './components/OpenRequests';

import styles from './Welcome.scss';

function Angus({ content }: any) {
  const { t } = useTranslation();
  const {
    userIntegration,
    loading: userIntegrationLoading,
    userIntegrationRefetch,
  } = useUserIntegrationQuery({
    channelIntegrationId: content.integration._id,
    fetchPolicy: 'no-cache',
  });

  function onLogout() {
    userIntegrationRefetch();
  }

  if (!userIntegration && userIntegrationLoading) {
    return (
      <div className={styles.container}>
        <Loading />
      </div>
    );
  }

  const { settings } = content.integration;

  if (userIntegration) {
    return (
      <OpenRequests
        content={content}
        userIntegration={userIntegration}
        onLogout={onLogout}
      />
    );
  }

  return (
    <div className={styles.container}>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'alt' is missing in type '{ src: any; cla... Remove this comment to see the full error message */}
      <Image src={WelcomeAngus} className={styles.image} />
      <h1>{settings.name}</h1>
      <div className={styles.line} />
      <h2>{settings.subtitle}</h2>
      <Link
        to={{
          pathname: routes.contentIntegrationLogin.replace(':id', content._id),
          state: { content },
        }}
      >
        <Button>{t('Submit Request')}</Button>
      </Link>
    </div>
  );
}

export default Angus;
