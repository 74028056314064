import i18next from 'i18next';
import { DateTime } from 'luxon';

import parseDateTime from '../../dates/parseDateTime';
import dateFormatter from '../dateFormatter';

export default function formatDateRangeQuarter({
  _start,
  _end,
  i18n = i18next,
  timeZone,
  locale,
}: {
  _start: DateTime | Date | string;
  _end: DateTime | Date | string;
  i18n?: any;
  timeZone?: string;
  locale?: string;
}): string {
  const start = parseDateTime(_start, timeZone)!;
  const end = parseDateTime(_end, timeZone)!;

  if (start.hasSame(end, 'year')) {
    return i18n.t('shared.helpers.formatters.dateRange.quarterRange', {
      startTime: dateFormatter(start, `Q`, timeZone, locale),
      endTime: dateFormatter(end, 'Q', timeZone, locale),
    });
  }

  const startFormatted = dateFormatter(start, 'Q yyyy', timeZone, locale);
  const endFormatted = end
    ? dateFormatter(end, 'Q yyyy', timeZone, locale)
    : '…';
  return i18n.t('shared.helpers.formatters.dateRange.quarterRange', {
    startTime: startFormatted,
    endTime: endFormatted,
  });
}
