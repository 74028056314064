import { useState } from 'react';

export default function useContentRendererReservableFeatureContext() {
  const [submissionCompletedAt, setSubmissionCompletedAt] = useState<
    Date | undefined
  >();

  return {
    submissionCompletedAt,
    setSubmissionCompletedAt,
  };
}
