import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { useMutation, isApolloError } from '@apollo/client';

import { deleteGroupRole } from 'lane-shared/graphql/mutation';

export function useDeleteGroupRole() {
  const { t } = useTranslation();

  const [deleteGroupRoleMutation] = useMutation(deleteGroupRole, {
    refetchQueries: ['getChannelGroupRolesWithCounts'],
  });

  const handleDelete = async ({
    id,
    groupRoleName,
  }: {
    id: string;
    groupRoleName: string;
  }) => {
    try {
      await window.Alert.confirm({
        title: t('web.pages.portal.admin.channel.team.delete.title'),
        message: (
          <Trans
            i18nKey="web.pages.portal.admin.channel.team.delete.message"
            values={{ groupRoleName }}
          />
        ),
        confirmText: t(
          'web.pages.portal.admin.channel.team.delete.confirmText'
        ),
        cancelText: t('web.pages.portal.admin.channel.team.delete.cancelText'),
      });

      await deleteGroupRoleMutation({ variables: { id } });

      window.Toast.show(
        t('web.pages.portal.admin.channel.team.delete.successToast')
      );
    } catch (err) {
      if (isApolloError(err)) {
        window.Toast.show(
          t('web.pages.portal.admin.channel.team.delete.error')
        );
      } else {
        console.error(`Action cancelled: ${err}`);
      }
    }
  };

  return {
    handleDelete,
  } as const;
}
