import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

export class FileType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'File';

  friendlyName = 'File';

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  get default(): any {
    return {};
  }

  superUserOnly = true;

  buildSchema() {
    return yup.object().nullable().default(null);
  }
}

const definition = new FileType();

Types.addBaseType(definition);
export default definition;
