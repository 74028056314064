import { LibraryType } from '../types/libraries/LibraryType';
import getDisplayName from './getDisplayName';

type Params = {
  channel?: any;
  library?: any;
};

/**
 * Builds a list of available channels using a channel as a input.
 *
 * Assumes the data already has been queried.  This doesn't go back out
 * to the api.
 *
 * This is useful for TemplateLibrary and MediaLibrary.
 */
export default function getLibraryOptions({
  channel,
  library,
}: Params): LibraryType[] {
  const options: LibraryType[] = [];

  if (channel) {
    options.push({
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"Channel"' is not assignable to type 'Librar... Remove this comment to see the full error message
      type: 'Channel',
      _id: channel._id,
      name: getDisplayName(channel),
    });

    if (channel.parent) {
      options.push({
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"Channel"' is not assignable to type 'Librar... Remove this comment to see the full error message
        type: 'Channel',
        _id: channel.parent._id,
        name: getDisplayName(channel.parent),
      });
    }
  }

  if (library) {
    options.push({
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"Library"' is not assignable to type 'Librar... Remove this comment to see the full error message
      type: 'Library',
      _id: library._id,
      name: library.name,
    });
  }

  return options;
}
