import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import DateTime from '../../../../properties/baseTypes/DateTime';
import Email from '../../../../properties/baseTypes/Email';
import Image from '../../../../properties/baseTypes/Image';
import PhoneNumber from '../../../../properties/baseTypes/PhoneNumber';
import UUID from '../../../../properties/baseTypes/UUID';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'String',
  },
  firstName: {
    type: 'String',
  },
  lastName: {
    type: 'String',
  },
  companyName: {
    type: 'String',
  },
  email: {
    type: 'Email',
  },
  phone: {
    type: 'PhoneNumber',
  },
  mobile: {
    type: 'PhoneNumber',
  },
  picture: {
    type: 'String',
  },
  createdAt: {
    type: 'DateTime',
  },
};

export class ProxyClickHostType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ProxyClickHost';

  friendlyName = 'Proxyclick Host';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      _id: UUID.example,
      firstName: 'Lane',
      lastName: 'Doe',
      companyName: 'Lane Technologies Inc.',
      email: Email.example,
      phone: PhoneNumber.example,
      mobile: PhoneNumber.example,
      picture: Image.example,
      createdAt: DateTime.example,
    };
  }

  get default(): any {
    return {
      _id: '',
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: null,
      mobile: null,
      picture: null,
      createdAt: null,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new ProxyClickHostType();

Types.addType(definition);
export default definition;
