import { DateTime } from 'luxon';

import getDisplayTime from './getDisplayTime';
import getMinutesFromSlider from './getMinutesFromSlider';
import getPositionFromTimeRange from './getPositionFromTimeRange';
import isRangeUnavailable from './isRangeUnavailable';
import isTimeUnavailable from './isTimeUnavailable';
import mapOptions from './mapOptions';
import roundToNearestTimeInterval from './roundToNearestTimeInterval';

export type TimeSlotType = {
  time: DateTime;
  timeSlotSize: number;
  timeSlot: number;
};

export const DAY_IN_MINUTES = 24 * 60; // 24 hours * 60 mins.
export const HANDLES = ['slider', 'left', 'right'] as const;
export const [HANDLE_SLIDER, HANDLE_LEFT, HANDLE_RIGHT] = HANDLES;

export {
  getDisplayTime,
  getMinutesFromSlider,
  isTimeUnavailable,
  isRangeUnavailable,
  mapOptions,
  getPositionFromTimeRange,
  roundToNearestTimeInterval,
};
