import { useMemo, useContext } from 'react';

import UserDataContext from '../contexts/UserDataContext';
import { isContentBetweenInteractionTimes } from '../helpers/content';
import canUserInteractWithTimeAvailability from '../helpers/features/canUserInteractWithTimeAvailability';
import explodeFeatures from '../helpers/features/explodeFeatures';
import getTimeZoneByGeoLocation from '../helpers/getTimeZoneByGeoLocation';
import { ContentType } from '../types/content/Content';

/**
 * Will return a boolean to indicate if this content can be interacted with
 * right now by this user, and if not, will also return a list of reasons
 * why they can not interact.
 *
 * //todo: expand this feature out to contemplate Quantity, and Entries.
 *
 */
export default function useIsContentAvailableToInteract({
  content,
  isAdminView,
}: {
  content?: ContentType | null;
  isAdminView?: boolean;
}): {
  isAvailable: boolean;
  error: Error | null;
} {
  const { user } = useContext(UserDataContext);

  return useMemo(() => {
    let isAvailable = false;
    let error = null;

    if (content?._id) {
      const { reservableFeature, timeAvailabilityFeature } = explodeFeatures(
        content?.features
      );

      if (timeAvailabilityFeature) {
        // reservable feature makes timeAvailability work in a different way.
        // the time availability does not refer to the time right now, but rather
        // what times of day that reservable feature is available.
        if (reservableFeature) {
          // because you can book at any time using Reservable.
          isAvailable = true;
        } else {
          const timeZone = getTimeZoneByGeoLocation({
            latitude: content.geo[1],
            longitude: content.geo[0],
          });

          try {
            isAvailable = canUserInteractWithTimeAvailability(
              timeAvailabilityFeature,
              user?.roles.map(userGroupRole => userGroupRole.groupRole?._id),
              timeZone,
              content.name
            );
          } catch (err) {
            error = err as Error;
          }
        }
      } else if (isContentBetweenInteractionTimes(content, isAdminView))
        isAvailable = true;
    }

    return {
      isAvailable,
      error,
    };
  }, [content?._id]);
}
