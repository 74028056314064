/**
 * Some of the MobilePermissionsEnum will map to multiple health statuses on the actual mobile device and some don't.
 * Some map to the same health statuses on the mobile device, so there is overlap.
 *
 * Location requires on the device location is on, and location is granted.
 * LocationAlwaysGranted requires on the device location is on, and location always is granted.
 * Bluetooth requires that device bluetooth is on, and bluetooth is granted
 * BluetoothPeripheral requires that bluetooth is on and bluetooth peripheral is granted
 * SecureDevice just requires that a password is set on the device.
 */
export enum MobilePermissionEnum {
  AlwaysLocation = 'AlwaysLocation',
  BackgroundTask = 'BackgroundTask',
  Bluetooth = 'Bluetooth',
  BluetoothPeripheral = 'BluetoothPeripheral',
  Calendar = 'Calendar',
  Location = 'Location',
  SecureDevice = 'SecureDevice',
}

export enum AccessControlPermissionsHealthEnum {
  BluetoothOn = 'BluetoothOn',
  BluetoothGranted = 'BluetoothGranted',
  LocationOn = 'LocationOn',
  LocationAlwaysGranted = 'LocationAlwaysGranted',
  DeviceSecured = 'DeviceSecured',
  BluetoothGrantedAndroid12Plus = 'BluetoothGrantedAndroid12Plus',
}
