import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import definition from '../definition';
import constructAddress from './constructAddress';
import constructNewRequest from './constructNewRequest';

export { constructAddress, constructNewRequest };

export const STORAGE_KEY = '310SERV';
export const ERROR_MESSAGE =
  'Please check the form to make sure you have entered all information correctly.';
export const validationShape = createShapeFromProperties(
  definition.interactionData
);
export const NOT_SELECTED = ' - Select One - ';
export const RETURN_CHARS = ['\n', '\r'];
