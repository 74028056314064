import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';

import DownloadAndroidApp from '../../static/img/download_android_app.png';
import DownloadIosApp from '../../static/img/download_ios_app.png';
import UndrawFileSync from '../../static/img/undraw_file_sync.png';
import Image from '../general/Image';
import { Text } from '../typography';

import styles from './MobileAccess.scss';

export default function MobileAccess() {
  const { t } = useTranslation();
  const { whitelabel } = useContext(AppContext);
  return (
    <div className={styles.mobileAccessContainer}>
      <div className={styles.content}>
        <div>
          <Image
            src={UndrawFileSync}
            alt="Download the App"
            className={styles.image}
          />
        </div>

        <Text size="large" className={styles.text}>
          {t(
            'Download the {{- appName}} mobile app to start using this feature!',
            { appName: whitelabel.name }
          )}
        </Text>

        <div className={styles.downloadButtonsRow}>
          <a
            href={whitelabel.mobileAndroid}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DownloadAndroidApp}
              alt={t('Download the app on the Play Store')}
              className={styles.downloadButton}
            />
          </a>
          <a
            href={whitelabel.mobileIos}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DownloadIosApp}
              alt={t('Download the app on the Apple Store')}
              className={styles.downloadButton}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
