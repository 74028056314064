import { useContext, useEffect, useMemo, useState } from 'react';

import { getClient } from 'lane-shared/apollo';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import {
  OrderSummaryType,
  OrderSummaryWithProducts,
} from 'lane-shared/domains/billingPayments/types';
import { contentFeaturePaymentQuote } from 'lane-shared/graphql/content';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { formatCurrency } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';
import { ContentType } from 'lane-shared/types/content/Content';
import { SupportedLocaleEnum } from 'localization';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';
import {
  processSubscription,
  processTransaction,
  processUciSubmission,
} from './helpers';
import { removeLocalePrefixOnAmount } from './helpers/removeLocalePrefixOnAmount';
import { PaymentMethod } from 'graphql-query-contracts';

export type HookOptions = {
  content: Pick<
    ContentType,
    | 'backgroundImage'
    | 'backgroundImageId'
    | 'name'
    | 'description'
    | 'features'
    | '_id'
    | 'channel'
  >;
  interaction: UserContentInteractionType | null;
  locale: SupportedLocaleEnum;
};

export function usePaymentV2Feature({
  content,
  interaction,
  locale,
}: HookOptions) {
  const { primaryId } = useContext(ChannelsContext);
  const { paymentFeature } = useMemo(() => explodeFeatures(content?.features), [
    content?._id,
  ]);

  const percentageFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  const [paymentQuote, setPaymentQuote] = useState<FeatureQuoteType | null>(
    null
  );
  const [orderSummary, setOrderSummary] = useState<OrderSummaryType | null>(
    null
  );
  const [
    orderSummaryWithProducts,
    setOrderSummaryWithProducts,
  ] = useState<OrderSummaryWithProducts | null>(null);

  const [error, setError] = useState<Error | null>(null);

  const getQuote = async () => {
    try {
      const startDate =
        interaction?.features?.Reservable?.reservation?.startDate;
      const endDate = interaction?.features?.Reservable?.reservation?.endDate;
      const { data } = await getClient().query({
        query: contentFeaturePaymentQuote,
        fetchPolicy: 'no-cache',
        variables: {
          interactionTimes: { startDate, endDate },
          id: content?._id,
          paymentType: PaymentTypeEnum.Cash,
          quantity: interaction?.features?.Quantity?.quantity || 1,
          userId: interaction?.userId,
        },
      });
      setPaymentQuote(data.contentFeaturePaymentQuote);
      setError(null);
    } catch (err: any) {
      console.error(`getQuote error: ${err}`);
      setError(err);
    }
  };

  async function initiatePaymentAndUciSubmission(
    accountsUserId: string | null,
    selectedPaymentMethod: string | undefined,
    paymentMethodType: PaymentMethod
  ) {
    if (!primaryId) {
      throw new Error('cannot submit uci without primary id');
    }
    if (!interaction) {
      throw new Error('there is no interaction to submit payment/uci for');
    }
    if (!paymentQuote || !paymentQuote.currency) {
      throw new Error('cannot submit payment without payment quote');
    }
    if (!content.channel._id) {
      throw new Error('cannot submit payment without content channel id');
    }
    if (!orderSummary?.items[0]?.name) {
      throw new Error('cannot purchase product without product name');
    }

    const amount =
      paymentQuote.products && paymentQuote.products.length > 0
        ? paymentQuote.cashTotal! * 100
        : paymentQuote.total + paymentQuote.tax;

    const paymentResult = await processTransaction({
      currentChannelId: primaryId,
      contentChannelId: content.channel._id,
      paymentMethod: selectedPaymentMethod,
      paymentMethodType,
      accountsUserId,
      productName: orderSummary.items[0].name,
      currency: paymentQuote.currency,
      amount,
      products: paymentQuote.products || undefined,
      quantity: paymentQuote.quantity,
    });

    await processUciSubmission(
      interaction,
      content._id,
      primaryId,
      paymentResult.transactionId,
      paymentQuote
    );

    return paymentResult;
  }

  useEffect(() => {
    const canGetPaymentQuote = Boolean(paymentFeature) && content?._id;
    if (canGetPaymentQuote) {
      getQuote();
    }
  }, [interaction?.userId, content?._id, paymentFeature]);

  useEffect(() => {
    // TODO: remove the removeLocalePrefixOnAmount function
    // and use currency formatter instead
    // import { currencyFormater } from 'lane-shared/helpers/currencyFormatter';
    if (paymentQuote) {
      const paymentDetails = formatCurrency(paymentQuote, locale);
      const description: string = content.description;
      const orderSummaryData: OrderSummaryType = {
        items: [],
        subTotal: removeLocalePrefixOnAmount(paymentDetails.subTotal || ''),
        taxes: [],
        total: removeLocalePrefixOnAmount(paymentDetails.total.toString()),
        currency: paymentQuote.currency || '',
      };

      let orderSummaryWithProductsData: OrderSummaryWithProducts = {
        products: [],
        cashTotal: 0,
        creditTotal: 0,
        taxTotal: 0,
        currency: paymentQuote.currency || '',
        subscriptionDetails: paymentQuote.subscriptionDetails,
      };

      if (paymentQuote.products) {
        orderSummaryWithProductsData = {
          ...orderSummaryWithProductsData,
          cashTotal: paymentQuote.cashTotal || 0,
          creditTotal: paymentQuote.creditTotal || 0,
          taxTotal: paymentQuote.taxTotal || 0,
          products: paymentQuote.products,
        };
      }

      paymentDetails.items.forEach(item => {
        orderSummaryData.items.push({
          name: item.name || '',
          description,
          quantity: item.quantity || 1,
          dollarAmount: removeLocalePrefixOnAmount(item.amount),
        });
      });

      paymentDetails.taxes.forEach(tax => {
        orderSummaryData.taxes.push({
          key: tax.key,
          name: tax.name || '',
          rate: percentageFormat(parseFloat(tax.rate?.toString() || '0.0')),
          amount: removeLocalePrefixOnAmount(tax.amount),
        });
      });

      setOrderSummary(orderSummaryData);
      setOrderSummaryWithProducts(orderSummaryWithProductsData);
    }
  }, [paymentQuote]);

  async function initiateSubscriptionAndUciSubmission(userId?: string) {
    if (!userId) {
      throw new Error('cannot submit uci without user id');
    }
    if (!primaryId) {
      throw new Error('cannot submit uci without primary id');
    }
    if (!interaction) {
      throw new Error('there is no interaction to submit payment/uci for');
    }
    if (!paymentQuote || !paymentQuote.currency) {
      throw new Error('cannot submit uci without payment quote');
    }
    if (!orderSummaryWithProducts) {
      throw new Error(
        'cannot submit subscription without order summary details'
      );
    }

    const subscriptionId = await processSubscription(
      content as ContentType,
      orderSummaryWithProducts,
      primaryId,
      userId,
      paymentQuote
    );
    await processUciSubmission(
      interaction,
      content._id,
      primaryId,
      subscriptionId,
      paymentQuote
    );
  }

  return {
    orderSummaryData: orderSummary,
    orderSummaryWithProducts,
    paymentV2Error: error,
    initiatePaymentAndUciSubmission,
    initiateSubscriptionAndUciSubmission,
  } as const;
}
