import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';
import QuantityAllocations from './types/QuantityAllocations';

const MAX_ITEMS = 10000;

const Quantity: FeatureDefinition = {
  name: FeatureNameEnum.Quantity,
  friendlyName: 'web.content.features.quantity.friendlyName',
  description: 'web.content.features.quantity.description',
  requiresInteraction: true,
  properties: {
    total: {
      friendlyName: 'Total Available',
      type: 'Number',
      description: 'web.content.features.quantity.properties.total.description',
      default: 100,
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Max',
          value: MAX_ITEMS,
        },
        {
          name: 'Min',
          value: 1,
        },
      ],
    },
    quantity: {
      friendlyName: 'Amount remaining',
      type: 'Number',
      description:
        'web.content.features.quantity.properties.quantity.description',
      default: 100,
      editable: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Max',
          value: MAX_ITEMS,
        },
        {
          name: 'Min',
          value: 0,
        },
      ],
    },
    max: {
      friendlyName: 'Max per User',
      type: 'Number',
      description: 'web.content.features.quantity.properties.max.description',
      default: 1,
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: MAX_ITEMS,
        },
      ],
    },
    useWaitlist: {
      friendlyName: 'web.admin.content.features.quantity.waitlist.friendlyName',
      description: 'web.admin.content.features.quantity.waitlist.description',
      type: 'Boolean',
      default: false,
    },
    quantityAllocations: {
      type: 'QuantityAllocations',
      isArray: true,
      default: [],
      friendlyName: QuantityAllocations.friendlyName,
      description: 'web.admin.content.features.quantity.advancedRules',
    },
  },
  interactionData: {
    quantity: {
      friendlyName: 'Quantity',
      description: 'How many the interaction took.',
      type: 'Number',
      packagedType: PackagedTypeEnum.Quantity,
      default: 1,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: MAX_ITEMS,
        },
      ],
    },
  },
};

export default Quantity;
