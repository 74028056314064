export default function findTarget(element: HTMLElement): string | null {
  if (!element) {
    return null;
  }

  if (element.dataset?.blockId) {
    return element.dataset.blockId;
  }

  if (!element.parentElement) {
    return null;
  }

  return findTarget(element.parentElement);
}
