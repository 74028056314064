import React from 'react';

import cx from 'classnames';

import StripeLegal from 'lane-web/src/components/payments/StripeLegal';

import { PaymentOptionsTitle } from './PaymentOptionsTitle';
import { SubscriptionPaymentOptions } from './SubscriptionPaymentOptions/SubscriptionPaymentOptions';

import styles from './PaymentOptions.scss';
import { CreditCardPaymentOptions } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/CreditCardPaymentOptions/CreditCardPaymentOptions';
import {
  InvoiceAutopaymentDetails,
  PaymentSource,
} from 'graphql-query-contracts';

export type PaymentOptionsProps = {
  updatePaymentMethod: (id: string) => void;
  createUser: () => Promise<string>;
  savePaymentMethod: (paymentMethodId: string) => Promise<void>;
  removePaymentMethod: (paymentMethodId: string) => Promise<void>;
  savedPaymentMethods: PaymentSource[];
  removeAutopaymentMethod: (paymentMethodId: string) => Promise<void>;
  invoiceAutopayment?: InvoiceAutopaymentDetails;
  saveAutopaymentMethod: (paymentMethodId: string) => Promise<void>;
  selectedPaymentMethod: string | undefined;
  isWallet?: boolean;
  loading: boolean;
  onSubmit: () => void;
  isSubscriptionContent?: boolean;
};

export function PaymentOptions({
  updatePaymentMethod,
  createUser,
  savePaymentMethod,
  savedPaymentMethods,
  selectedPaymentMethod,
  removeAutopaymentMethod,
  removePaymentMethod,
  invoiceAutopayment,
  saveAutopaymentMethod,
  isWallet,
  onSubmit,
  loading,
  isSubscriptionContent,
}: PaymentOptionsProps) {
  return (
    <div className={cx(styles.paymentOptionsContainer)}>
      <PaymentOptionsTitle />
      {isSubscriptionContent ? (
        <SubscriptionPaymentOptions
          autopaymentDetails={invoiceAutopayment}
          savedPaymentMethods={savedPaymentMethods}
          saveAutopaymentMethod={saveAutopaymentMethod}
          onSubmit={onSubmit}
        />
      ) : (
        <CreditCardPaymentOptions
          updatePaymentMethod={updatePaymentMethod}
          createUser={createUser}
          savePaymentMethod={savePaymentMethod}
          savedPaymentMethods={savedPaymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          removeAutopaymentMethod={removeAutopaymentMethod}
          removePaymentMethod={removePaymentMethod}
          invoiceAutopayment={invoiceAutopayment}
          isWallet={isWallet}
          onSubmit={onSubmit}
          loading={loading}
        />
      )}

      <StripeLegal className={styles.stripeLegal} />
    </div>
  );
}
