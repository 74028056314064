import * as yup from 'yup';

export default yup.object().shape({
  _id: yup.string().trim().required(),
  name: yup.string().trim().max(256),
  description: yup.string().trim().max(4096),
  tags: yup.array().of(yup.string().required()).max(6).nullable(),
  file: yup.object(),
  thumbnail: yup.object(),
  type: yup.string(),
  contentType: yup.string().required(),
});
