import i18next from 'i18next';
import * as yup from 'yup';

export default function validateWorkorder(
  translator: (key: string, params?: any) => string = i18next.t
) {
  const REQUIRED_MESSAGE = translator(
    'web.admin.serviceRequest.field.required'
  );
  const INVALID_URL_MESSAGE = translator(
    'web.admin.serviceRequest.field.url.invalid'
  );

  const urlValidator = yup
    .string()
    .url(INVALID_URL_MESSAGE)
    .required(REQUIRED_MESSAGE);

  const stringValidator = yup
    .string()
    .test(
      'not-only-whitespace',
      REQUIRED_MESSAGE,
      value => value?.trim() !== ''
    );

  return {
    urlValidator,
    stringValidator,
  };
}
