import { DateTime } from 'luxon';

import { WEEKDAY_IN_WORD } from 'lane-shared/helpers/constants/dates';
import { WeekDay } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

export function getDayNameFromDate(referenceDate: Date) {
  return DateTime.fromJSDate(referenceDate)
    .toFormat(WEEKDAY_IN_WORD)
    .toUpperCase() as WeekDay;
}

export function getDayNameFromDateTime(referenceDate: DateTime) {
  return referenceDate.toFormat(WEEKDAY_IN_WORD).toUpperCase() as WeekDay;
}
