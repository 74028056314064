import { useContext, useEffect, useState } from 'react';

import UserDataContext from '../contexts/UserDataContext';
import { LibraryType } from '../types/libraries/LibraryType';
import useStoredState from './useStoredState';

export default function useLibraryPattern({
  library,
  libraries,
  userLibraryEnabled,
  storageKey,
}: {
  library: LibraryType | null | undefined;
  libraries: LibraryType[] | null | undefined;
  userLibraryEnabled?: boolean;
  storageKey?: string;
}) {
  const { user } = useContext(UserDataContext);

  const userLibrary: LibraryType = {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '"User"' is not assignable to type 'LibraryTy... Remove this comment to see the full error message
    type: 'User',
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    _id: user?._id,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    name: user?.name,
  };

  const [selectedLibrary, _setSelectedLibrary] = useStoredState<
    LibraryType | null | undefined
  >(`useLibraryPattern${storageKey ?? ''}`, null);

  const [availableLibraries, setAvailableLibraries] = useState<LibraryType[]>(
    []
  );

  useEffect(() => {
    if (library) {
      _setSelectedLibrary(library);
      setAvailableLibraries([library]);
    }
  }, [library?._id]);

  useEffect(() => {
    if (!library && libraries && libraries.length > 0) {
      const finalLibs =
        user?.isSuperUser || userLibraryEnabled
          ? [...libraries, userLibrary]
          : libraries;

      if (
        !selectedLibrary ||
        !finalLibs.some(l => l._id === selectedLibrary._id)
      ) {
        _setSelectedLibrary(libraries[0]);
      }

      setAvailableLibraries(finalLibs);
    }
  }, [libraries]);

  function setSelectedLibrary(library: LibraryType | null) {
    _setSelectedLibrary(library);
  }

  return { user, availableLibraries, selectedLibrary, setSelectedLibrary };
}
