import gql from 'graphql-tag';

export const getRecurrenceVisitorPassDetailsById = gql`
  query getRecurrenceVisitorPassDetailsById($id: UUID!) {
    recurrence(id: $id) {
      recurrenceId
      visitorIds
      schedule
      scheduleDuration
      status
      scheduleType
      sourceId
      submissionId
      accessGrantBuildingId
      hostUserId
      hostTenantId
      floor
      visitorNote
      staffNote
    }
  }
`;
