import React from 'react';

import { Icon } from 'design-system-web';

import styles from './tag.scss';

type OwnProps = {
  color?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'green'
    | 'yellow'
    | 'red'
    | 'gray';
  isDismissible?: boolean;
  onClose?: (...args: any[]) => any;
  children: React.ReactElement;
  name?: string;
  style?: any;
  className?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Tag.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'Tag' implicitly has type 'any' because it does no... Remove this comment to see the full error message
const Tag = ({
  color,
  isDismissible,
  children,
  onClose,
  name,
  style,
  className,
}: Props) => (
  <div
    className={`${styles.tag} ${styles[`${color}`]} ${className}`}
    style={style}
  >
    {children}
    {isDismissible && (
      <button
        className={styles.iconWrapper}
        onClick={() => onClose(name)}
        type="button"
      >
        <Icon name="times" className={`${styles[`${color}`]} ${styles.icon}`} />
      </button>
    )}
  </div>
);

Tag.defaultProps = {
  isDismissible: false,
  color: 'primary',
  name: '',
  onClose: () => {},
  style: {},
  className: '',
};

export default React.memo(Tag);
