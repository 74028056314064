import { v4 as uuid } from 'uuid';

import { PropertyType } from 'lane-shared/types/properties/Property';

import Types from '../properties/Types';
import explodeValidators from './properties/explodeValidators';

const examples: Record<string, any> = {};

function generateFieldData(field: PropertyType) {
  const type = Types.getType(field.type);

  // if there is an In validator, lets use one of the values as an example.
  const { inValidator, maxValidator, minValidator } = explodeValidators(
    field.validators
  );

  if (Array.isArray(inValidator?.value) && inValidator?.value?.length) {
    return inValidator.value[
      Math.floor(Math.random() * inValidator.value.length)
    ];
  }

  // if there is a Max, Min validator, lets pick value between those.
  if (maxValidator && minValidator) {
    const max = maxValidator.value as number;
    const min = minValidator.value as number;
    return Math.floor(Math.random() * max - min) + min;
  }

  if (type.example) {
    return type.example;
  }

  if (type.default) {
    return type.default;
  }

  return null;
}

type Args = {
  key: string;
  definition: PropertyType[];
  rows: number;
};

/**
 * generates example data based on the given definition.
 *
 * this modifies the exampleData object in place.  Which should not be a problem
 * because its fake anyways.
 */
export default function generateExampleData({
  key,
  definition,
  rows = 5,
}: Args) {
  // if the example data still exists, use what is there, prevents random data
  // from jumping around on the UI
  const table = examples[key] || new Array(rows).fill(true).map(() => ({}));

  table.forEach((row: any) => {
    if (!row._id) {
      row._id = uuid();
    }

    definition.forEach(field => {
      // leave any existing data
      if (field.name && row[field.name] == null) {
        row[field.name] = generateFieldData(field);
      }
    });
  });

  examples[key] = table;

  return table;
}
