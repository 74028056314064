import { useContext } from 'react';

import { useHistory } from 'react-router';

import { ChannelType } from 'lane-shared/types/ChannelType';

import { ChannelsContext } from '../contexts';

/**
 * Retrieves the channel that the user is currently on, either as an admin or end user
 * NOTE: this function relies on pathname which can be modified by the user - use with caution
 */

export function useCurrentChannel(): ChannelType | null {
  const { channels, primaryChannel } = useContext(ChannelsContext);

  // TM-13081 TODO: find a react-native way to get the current pathName
  // For now, primaryChannel will be returned
  const history = useHistory();

  if (
    (!primaryChannel && !history?.location?.pathname) ||
    channels.length === 0
  ) {
    return null;
  }

  const pathname = history?.location?.pathname || '';

  if (pathname.includes('/l/channel/')) {
    const currentSlug = pathname.split('/')[3];
    const currentChannel = channels.find(c => c.slug === currentSlug);

    if (currentChannel) {
      return currentChannel;
    }
  }

  return primaryChannel;
}
