import React, { useState } from 'react';

import cx from 'classnames';

import { ChannelType } from 'lane-shared/types/ChannelType';

import SectionSearchOnChannel from 'components/lane/SectionSearchOnChannel';
import { H4 } from 'components/typography';

import ChannelSelectorButton from './ChannelSelectorButton';

import styles from './SectionSelector.scss';

SectionSelector.defaultProps = {
  onSectionSelected: () => null,
  channelId: null,
};

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  channelId?: string;
  channelSlug?: string;
  onSectionSelected: (section: any) => void;
};

type Props = OwnProps & typeof SectionSelector.defaultProps;

export default function SectionSelector({
  className,
  style,
  channelId,
  channelSlug,
  onSectionSelected,
}: Props) {
  const [selectedChannel, setSelectedChannel] = useState<ChannelType | null>(
    null
  );

  return (
    <div className={cx(styles.SectionSelector, className)} style={style}>
      <H4>Select Section</H4>
      <ChannelSelectorButton
        className={styles.selectorButton}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
        channelId={selectedChannel?._id || channelId}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(channel: ChannelType | null) => void' is no... Remove this comment to see the full error message
        onChannelSelected={(channel: ChannelType | null) =>
          setSelectedChannel(channel ?? null)
        }
      />
      <SectionSearchOnChannel
        channelId={selectedChannel?._id || channelId}
        slug={selectedChannel?.slug || channelSlug}
        onSectionSelected={onSectionSelected}
      />
    </div>
  );
}
