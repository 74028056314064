import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import DateTime from '../../../../properties/baseTypes/DateTime';
import Email from '../../../../properties/baseTypes/Email';
import Image from '../../../../properties/baseTypes/Image';
import PhoneNumber from '../../../../properties/baseTypes/PhoneNumber';
import UUID from '../../../../properties/baseTypes/UUID';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { PROXYCLICK_VISITOR_STATUS } from '../../../constants/proxyclick';

const properties: PropertiesInterface = {
  id: {
    friendlyName: 'ID',
    type: 'String',
  },
  firstName: {
    friendlyName: 'First Name',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  lastName: {
    friendlyName: 'Last Name',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  companyName: {
    friendlyName: 'Company Name',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  email: {
    friendlyName: 'Visitor Email',
    type: 'Email',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  phone: {
    friendlyName: 'Visitor Phone',
    type: 'PhoneNumber',
  },
  mobile: {
    friendlyName: 'Visitor Mobile',
    type: 'PhoneNumber',
  },
  picture: {
    friendlyName: 'Visitor Picture',
    type: 'String',
  },
  createdAt: {
    friendlyName: 'Created',
    type: 'DateTime',
  },
  status: {
    friendlyName: 'Status',
    type: 'String',
  },
};

const shape = createShapeFromProperties(properties);
const schema = yup
  .object()
  .shape(createShapeFromProperties(properties))
  .nullable();

export class ProxyClickVisitorType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'ProxyClickVisitor';

  friendlyName = 'Proxyclick Visitor';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get example() {
    return {
      id: UUID.example,
      firstName: 'Lane',
      lastName: 'Doe',
      companyName: 'Lane Technologies Inc.',
      email: Email.example,
      phone: PhoneNumber.example,
      mobile: PhoneNumber.example,
      picture: Image.example,
      createdAt: DateTime.example,
      status: PROXYCLICK_VISITOR_STATUS.CREATED,
    };
  }

  get default(): any {
    return {
      id: '',
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: null,
      mobile: null,
      picture: null,
      createdAt: null,
      status: PROXYCLICK_VISITOR_STATUS.CREATED,
    };
  }

  shape = shape;

  buildSchema() {
    return schema;
  }

  properties = properties;
}

const definition = new ProxyClickVisitorType();

Types.addType(definition);
export default definition;
