import React, { PureComponent } from 'react';

import i18next from 'i18next';

import { Alert, AlertType, Modal } from 'components/lds';
import { H3 } from 'components/typography';

import Button from './Button';
import ErrorMessage from './ErrorMessage';
import Loading from './Loading';

import styles from './AlertModal.scss';

declare global {
  interface Window {
    Alert: AlertModal;
  }
}

type Props = {};

type State = {
  title: string;
  subTitle: string;
  message: string | React.ReactElement;
  error: null | Error;
  isOpen: boolean;
  loading: boolean;
  children: null | React.ReactNode;
  type: 'confirm' | 'loading' | 'alert';
  resolve: (() => void) | null;
  reject: (() => void) | null;
  cancelText: string | null;
  confirmText: string | null;
  isCloseButtonHidden: boolean;
  confirmButtonStyle: React.CSSProperties;
};

/**
 * This class wraps the Modal as is intended to be used as a singleton
 * global alert and confirm modal attached to the window object.
 *
 * This is useful to use programatically when it is not always easy to create
 * in modal a standard "React" way.
 */
export class AlertModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: 'Title',
      subTitle: 'Sub Title',
      message: 'Message…',
      error: null,
      isOpen: false,
      loading: false,
      children: null,
      type: 'confirm',
      resolve: () => {},
      reject: () => {},
      cancelText: null,
      confirmText: null,
      isCloseButtonHidden: false,
      confirmButtonStyle: {},
    };
  }

  async show({
    title,
    subTitle,
    message,
    type,
    error,
    children,
    loading,
    cancelText,
    confirmText,
    confirmButtonStyle = {},
  }: any) {
    this.setState({
      title,
      subTitle,
      message,
      type,
      error,
      loading,
      children,
      isOpen: true,
      cancelText,
      confirmText,
      confirmButtonStyle,
    });

    await new Promise((resolve, reject) => {
      this.setState({
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: unknown) => void' is not assignable ... Remove this comment to see the full error message
        resolve,
        reject,
      });
    });

    this._hide();
  }

  async alert({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type 'Props'.
    title,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'subTitle' does not exist on type 'Props'... Remove this comment to see the full error message
    subTitle,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Props'.
    message = '',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Props'.
    error = null,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Props'.
    loading = false,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'children' does not exist on type 'Props'... Remove this comment to see the full error message
    children = null,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmText' does not exist on type 'Pro... Remove this comment to see the full error message
    confirmText = i18next.t('web.components.general.AlertModal.ok'),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'cancelText' does not exist on type 'Prop... Remove this comment to see the full error message
    cancelText = '',
  }: Props) {
    return this.show({
      title,
      subTitle,
      message,
      error,
      loading,
      children,
      type: 'alert',
      confirmText,
      cancelText,
    });
  }

  async loading({ title, message, error, children, subTitle }: any) {
    let resolver = null;

    this.setState({
      title,
      subTitle,
      message,
      error,
      children,
      loading: true,
      type: 'loading',
      isOpen: true,
      isCloseButtonHidden: true,
    });

    // eslint-disable-next-line no-new
    new Promise((resolve, reject) => {
      resolver = resolve;

      this.setState({
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: unknown) => void' is not assignable ... Remove this comment to see the full error message
        resolve,
        reject,
      });
    });

    return resolver;
  }

  async confirm({
    title,
    subTitle,
    message = '',
    error = null,
    loading = false,
    children = null,
    cancelText = i18next.t('web.components.general.AlertModal.cancel'),
    confirmText = i18next.t('web.components.general.AlertModal.confirm'),
    confirmButtonStyle = {},
  }: Partial<State>) {
    return this.show({
      title,
      subTitle,
      message,
      error,
      loading,
      children,
      type: 'confirm',
      cancelText,
      confirmText,
      confirmButtonStyle,
    });
  }

  _hide() {
    this.setState({
      isOpen: false,
      resolve: null,
      reject: null,
      error: null,
      children: null,
      loading: false,
      confirmText: null,
      cancelText: null,
    });
  }

  hide() {
    const { isOpen, reject, resolve, type } = this.state;

    if (!isOpen) {
      return;
    }

    if (type === 'confirm' && reject) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      reject(false);
    } else if (resolve) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      resolve(true);
    }

    this._hide();
  }

  render() {
    const {
      isOpen,
      title,
      subTitle,
      message,
      resolve,
      error,
      loading,
      children,
      confirmText,
      cancelText,
      type,
      isCloseButtonHidden,
      confirmButtonStyle,
    } = this.state;
    const alertActionsAvailable = Boolean(cancelText) || Boolean(confirmText);

    return (
      <Modal
        title={title}
        isOpen={isOpen}
        dataCy="modalAlert"
        onClose={() => this.hide()}
        isCloseButtonHidden={isCloseButtonHidden}
        actions={
          alertActionsAvailable ? (
            <>
              {cancelText && (
                <Button
                  disabled={loading}
                  style={{ width: type === 'alert' ? '90%' : '50%' }}
                  variant="outlined"
                  loading={loading}
                  onClick={() => this.hide()}
                  dataCy="modalButtonCancel"
                >
                  {cancelText}
                </Button>
              )}
              {confirmText && (
                <Button
                  disabled={loading}
                  style={{
                    ...confirmButtonStyle,
                    width: type === 'alert' ? '90%' : '50%',
                  }}
                  variant="contained"
                  // @ts-expect-error ts-migrate(2721) FIXME: Cannot invoke an object which is possibly 'null'.
                  onClick={() => resolve(true)}
                  dataCy="modalButtonConfirm"
                >
                  {confirmText}
                </Button>
              )}
            </>
          ) : null
        }
      >
        <>
          <H3 mb={0} style={{ textAlign: 'left' }}>
            {subTitle}
          </H3>
          {message && (
            <div data-cy="modalAlertText" className={styles.message}>
              {typeof message === 'string'
                ? message
                    .split('\n')
                    .filter(substring => Boolean(substring))
                    .map((substring, idx) =>
                      idx === 0 ? (
                        <p key={idx} data-cy="modalAlertText">
                          {substring}
                        </p>
                      ) : (
                        <Alert
                          className={styles.alert}
                          key={idx}
                          type={AlertType.error}
                        >
                          {substring}
                        </Alert>
                      )
                    )
                : message}
            </div>
          )}
          <ErrorMessage error={error} style={{ marginTop: '1em' }} />
          {children}
          {loading && <Loading className={styles.loading} />}
        </>
      </Modal>
    );
  }
}
