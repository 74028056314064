import { Primitive } from '../../../types/blocks/Primitive';

const EssensysCreditsBlock: Primitive = {
  name: 'EssensysCreditsBlock',
  friendlyName: 'Essensys Credit Block',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Integration'],
  properties: {
    printingCredits: {
      friendlyName: 'Show Printing Credits?',
      type: 'Boolean',
      default: true,
    },
    credits: {
      friendlyName: 'Show Credits?',
      type: 'Boolean',
      default: true,
    },
    autoHide: {
      friendlyName: 'Hide when no results?',
      type: 'Boolean',
      default: true,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default EssensysCreditsBlock;
