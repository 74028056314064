import { explodePresetContentFilters } from '../helpers/content';
import { DateRangeType } from '../types/baseTypes/DateRangeType';
import { PresentContentFilterTimeEnum } from '../types/filters/PresentContentFilterTimeEnum';
import { SearchOptions } from '../types/filters/SearchOptions';

type Props = {
  searchOptions?: SearchOptions;
  onSearchOptionsUpdated: (searchOptions: Partial<SearchOptions>) => void;
};

export default function useSectionSearchOptionsMenu({
  searchOptions,
  onSearchOptionsUpdated,
}: Props) {
  const {
    filterTimeRange,
    filterLocation,
    filterQuantity,
    filterAvailable,
    filterPrice,
    filterByEventDate,
  } = explodePresetContentFilters(searchOptions?.filters);

  const areTimeOptionsEnabled = Boolean(filterAvailable || filterTimeRange);

  const isAnyTimeSelected =
    filterAvailable?.enabled === PresentContentFilterTimeEnum.AnyTime ||
    filterTimeRange?.enabled === PresentContentFilterTimeEnum.AnyTime;

  const isAvailableNowSelected =
    filterAvailable?.enabled === PresentContentFilterTimeEnum.Enabled;

  const isTimeRangeDisabled =
    filterTimeRange?.enabled === PresentContentFilterTimeEnum.AnyTime ||
    filterAvailable?.enabled === PresentContentFilterTimeEnum.AnyTime;

  function distanceChangeHandler(value: any) {
    if (!filterLocation) {
      return;
    }

    filterLocation.distance = value;
    filterLocation.enabled = true;

    onSearchOptionsUpdated({
      areFiltersApplied: true,
      filters: [...searchOptions!.filters],
    });
  }

  function priceChangeHandler({ values }: any) {
    if (!filterPrice) {
      return;
    }

    filterPrice.minPrice = values[0];
    filterPrice.maxPrice = values[1];

    onSearchOptionsUpdated({
      areFiltersApplied: true,
      filters: [...searchOptions!.filters],
    });
  }

  function quantityChangeHandler(value: any) {
    if (!filterQuantity) {
      return;
    }

    filterQuantity.quantity = value;

    onSearchOptionsUpdated({
      areFiltersApplied: true,
      filters: [...searchOptions!.filters],
    });
  }

  function anyTimeChangeHandler() {
    if (filterAvailable) {
      filterAvailable.enabled = isAnyTimeSelected
        ? PresentContentFilterTimeEnum.Enabled
        : PresentContentFilterTimeEnum.AnyTime;
    }

    if (filterTimeRange) {
      filterTimeRange.enabled = isAnyTimeSelected
        ? PresentContentFilterTimeEnum.Enabled
        : PresentContentFilterTimeEnum.AnyTime;
    }

    onSearchOptionsUpdated({
      areFiltersApplied: true,
      filters: [...searchOptions!.filters],
    });
  }

  function availableNowChangeHandler() {
    if (filterTimeRange) {
      filterTimeRange.enabled = isAnyTimeSelected
        ? PresentContentFilterTimeEnum.Enabled
        : PresentContentFilterTimeEnum.AnyTime;
    }

    if (filterAvailable) {
      filterAvailable.enabled = isAvailableNowSelected
        ? PresentContentFilterTimeEnum.AnyTime
        : PresentContentFilterTimeEnum.Enabled;
    }

    onSearchOptionsUpdated({
      areFiltersApplied: true,
      filters: [...searchOptions!.filters],
    });
  }

  function timeRangeChangeHandler(value: { startDate: Date; endDate: Date }) {
    if (!filterTimeRange) {
      return;
    }

    filterTimeRange.startDate = value.startDate;
    filterTimeRange.endDate = value.endDate;

    onSearchOptionsUpdated({
      areFiltersApplied: true,
      filters: [...searchOptions!.filters],
    });
  }

  function dateRangePickerHandler(dateRange: DateRangeType) {
    if (!filterByEventDate || !dateRange?.startDate || !dateRange?.endDate) {
      return;
    }

    filterByEventDate.startDate = dateRange.startDate;
    filterByEventDate.endDate = dateRange.endDate;
    filterByEventDate.enabled = PresentContentFilterTimeEnum.Enabled;

    onSearchOptionsUpdated({
      areFiltersApplied: true,
      filters: [...searchOptions!.filters],
    });
  }

  return {
    timeRangeChangeHandler,
    availableNowChangeHandler,
    anyTimeChangeHandler,
    quantityChangeHandler,
    distanceChangeHandler,
    priceChangeHandler,
    dateRangePickerHandler,
    areTimeOptionsEnabled,
    isAnyTimeSelected,
    isAvailableNowSelected,
    isTimeRangeDisabled,
  };
}
