import { useContext, useEffect } from 'react';

import { AnalyticsContext } from '../../contexts';
import ChannelsContext from '../../contexts/ChannelsContext';
import {
  ANALYTIC_CONTENT_INTERACTION_DETAIL,
  ANALYTIC_CONTENT_INTERACTION_VIEW,
} from '../../helpers/constants/analytics';
import { EVENT_CONTENT_INTERACTION_CREATED } from '../../helpers/constants/events';
import { EnabledFeaturesForAnalyticsType } from '../../types/Analytics';

export type InteractionType = {
  _id?: string;
  channel?: {
    _id: string;
  };
  content?: {
    _id: string;
  };
  enabledFeatures?: EnabledFeaturesForAnalyticsType;
};

type EventType = 'view' | 'details' | 'interaction';

const eventMap = {
  view: ANALYTIC_CONTENT_INTERACTION_VIEW,
  details: ANALYTIC_CONTENT_INTERACTION_DETAIL,
  interaction: EVENT_CONTENT_INTERACTION_CREATED,
};

/**
 * Used for analytics tracking in mobile or web.
 */
export default function useInteractionAnalytics(
  event: EventType,
  interaction: InteractionType
) {
  const analytics = useContext(AnalyticsContext);
  const { primaryId } = useContext(ChannelsContext);

  useEffect(() => {
    if (interaction?._id) {
      analytics.track(eventMap[event], {
        interactionId: interaction?._id,
        contentId: interaction?.content?._id,
        channelId: interaction?.channel?._id,
        meChannelId: primaryId,
        enabledFeatures: interaction.enabledFeatures,
      });
    }
  }, [interaction?._id]);
}
