import React from 'react';

import { Button, ContextMenu } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { ContentType } from 'lane-shared/types/content/Content';

import { H2 } from 'components/typography';

import UserIntegration from '../../BuildingEnginesPrism/components/UserIntegration';

import styles from './Header.scss';

type Props = {
  content: ContentType;
  onLogout: () => void;
};

export default function Header({ content, onLogout }: Props) {
  const { t } = useTranslation();

  const moreActionsButton = (
    <ContextMenu
      id="log-out-menu"
      items={[
        <UserIntegration
          key="logOutButton"
          content={content}
          onLogout={onLogout}
        />,
      ]}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"left"' is not assignable to type 'ModalPosi... Remove this comment to see the full error message
      align="left"
      autoFocus={false}
      autoClose
      hasOpaqueBackground={false}
    >
      <Button className={styles.actionsButton}>{t('More Actions')}</Button>
    </ContextMenu>
  );
  const createVisitButton = (
    <Link
      to={{
        pathname: routes.contentInteractionNew.replace(':id', content._id),
        state: { content },
      }}
    >
      <Button variant="contained" className={styles.createButton}>
        {t('Create a Visit')}
      </Button>
    </Link>
  );

  return (
    <div className={styles.headerBlock}>
      <H2>{t('Visitor Management')}</H2>
      <div className={styles.buttonBlock}>
        <div>{moreActionsButton}</div>
        <div>{createVisitButton}</div>
      </div>
    </div>
  );
}
