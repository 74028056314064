import { DateTime, Interval } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import parseDateTime from '../dates/parseDateTime';

export default function isTimeUnavailable({
  time,
  unavailableDateRanges = [],
  maxTime,
  minTime,
}: {
  time: DateTime;
  unavailableDateRanges: DateRangeType[];
  maxTime: number;
  minTime: number;
}) {
  const _time = parseDateTime(time);

  let unavailable = unavailableDateRanges
    .map(range => ({
      startDate: parseDateTime(range.startDate),
      endDate: parseDateTime(range.endDate),
    }))
    .some(
      range =>
        range.startDate &&
        range.endDate &&
        _time &&
        Interval.fromDateTimes(range.startDate, range.endDate).contains(_time)
    );

  const minutes = time.hour * 60 + time.minute;

  if (maxTime && minutes > maxTime) {
    unavailable = true;
  }

  if (minTime && minutes < minTime) {
    unavailable = true;
  }

  return unavailable;
}
