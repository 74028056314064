import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

const query = gql`
  query PlacePayConfig($paymentAccountId: UUID) {
    payments {
      placePay(paymentAccountId: $paymentAccountId) {
        _id
        publicKey
        accessToken
      }
    }
  }
`;

export default function usePlacePayConfig(paymentAccountId?: string) {
  const { data, loading, error } = useQuery(query, {
    variables: { paymentAccountId },
    fetchPolicy: 'no-cache',
    skip: !paymentAccountId,
  });

  const publicKey = data?.payments?.placePay?.publicKey;
  const accessToken = data?.payments?.placePay?.accessToken;
  const scriptSrc = publicKey?.includes('test_')
    ? 'https://test.placepay.com/plugins/pay.js'
    : 'https://placepay.com/plugins/pay.js';

  return {
    loading,
    error,
    publicKey,
    accessToken,
    scriptSrc,
  };
}
