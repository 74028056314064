import React, { useMemo } from 'react';

import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import useOnboardingV2Enabled from 'lane-shared/hooks/useOnboardingV2Enabled';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { M } from 'components/typography';

import styles from './WhitelabelTermsAndPolicy.scss';

const DEFAULT_AGREEMENT_LINKS_THRESHOLD = 2;

type Props = {
  whitelabel?: WhiteLabelType;
};

function isMissingTermsOrPrivacyURLs(whitelabel: WhiteLabelType) {
  const hasTermsAndConditionsUrls =
    whitelabel.termsAndConditionsUrls?.length > 0;
  const hasPrivacyPolicyUrls = whitelabel.privacyPolicyUrls?.length > 0;

  return !(hasTermsAndConditionsUrls && hasPrivacyPolicyUrls);
}

export default function WhitelabelTermsAndPolicy({ whitelabel }: Props) {
  const isOnboardingV2 = useOnboardingV2Enabled();
  const agreementLinks = useMemo(() => {
    const links: JSX.Element[] = [];

    if (!whitelabel || isMissingTermsOrPrivacyURLs(whitelabel)) {
      return links;
    }

    links.push(createLink(whitelabel.termsAndConditionsUrls[0]));
    links.push(createLink(whitelabel.privacyPolicyUrls[0]));

    if (whitelabel.privacyPolicyUrls.length > 1) {
      links.unshift(createLink(whitelabel.privacyPolicyUrls[1]));
    }

    if (whitelabel.termsAndConditionsUrls.length > 1) {
      links.unshift(createLink(whitelabel.termsAndConditionsUrls[1]));
    }

    return links;
  }, [whitelabel]);

  function createLink(redirectUrl?: string) {
    return (
      <Link
        key={redirectUrl}
        data-testid="link-to-parent"
        target="_blank"
        rel="noopener noreferrer"
        style={
          isOnboardingV2
            ? { textDecoration: 'underline', color: 'inherit' }
            : { color: 'inherit', fontWeight: 'bold', cursor: 'pointer' }
        }
        to={{ pathname: redirectUrl }}
      />
    );
  }

  if (agreementLinks.length === 0) {
    return null;
  }

  const hasCustomerLinks =
    agreementLinks.length > DEFAULT_AGREEMENT_LINKS_THRESHOLD;

  return (
    <M
      variant="secondary"
      mt={isOnboardingV2 ? 0 : 4}
      className={isOnboardingV2 ? styles.information : null}
    >
      <Trans
        i18nKey={
          hasCustomerLinks
            ? 'web.signup.customerAgreements'
            : 'web.signup.defaultAgreements'
        }
        components={agreementLinks}
        values={{
          whiteLabelName: whitelabel?.name,
        }}
      />
    </M>
  );
}
