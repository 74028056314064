import countryCodes from './countryCodes.json';

/**
 * Map the current active countries to their country code
 */
export const mapCountryCodeToCountry: {
  [countryName: string]: string;
} = countryCodes;

/**
 * Like the `mapCountryCodeToCountry` object but maps the country to the code instead
 */
export const mapCountryToCountryCode = Object.keys(countryCodes).reduce(
  (current, key) => {
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    current[mapCountryCodeToCountry[key]] = key;
    return current;
  },
  {}
);
