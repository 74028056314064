/**
 * Shortens a string so it always be, at most, a given length.
 * If the the string is too long, it truncates down to the max length - 3
 * and appends an ellipses ("...") so the final length is exactly the max length
 * @param str {String} The string to shorten
 * @param len {Number = 40} The maximum length of the string
 * @returns {String} The formatted string
 */
export default function shortenString(str: any, len = 40) {
  if (!str) return '';

  return str.length > len ? `${str.substring(0, len - 3)}…` : str;
}
