/* eslint-disable react/forbid-component-props */ // Allows passing component instances as props
/* eslint-disable @nx/enforce-module-boundaries */ // Permits importing from modules outside enforced boundaries
import React, { useState } from 'react';

import { AdminPage } from 'components/layout';

import { routes } from 'lane-shared/config';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { BreadCrumbs, Modal } from 'components/lds';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';

import { H2, H4, P, S, H3 } from 'design-system-web';
import { Button } from 'components';
import { PinInput } from '../../../components/PinInput/PinInput';
import styles from './styles.scss';
import { HardwareDetailEditPinCodes } from './edit';
import { useParams } from 'react-router-dom';

export function HardwareDetailPinCodes({ channel }: { channel: ChannelType }) {
  const { t } = useTranslation();
  const [, channelSlug] = useIsAdminView();
  const [isExpirePinModalOpen, setIsExpirePinModalOpen] = useState(false);
  const [isChangePinOpen, setIsChangePinOpen] = useState(true);
  const [isEditPinDetails, setIsEditPinDetails] = useState(false);

  const [, setPinData] = useState<string>();
  let timezone: string = 'UTC';
  const handleModalExpirePinOnClick = () => {
    setIsExpirePinModalOpen(true);
  };

  const handleModalExpirePinOnCancelClick = () => {
    setIsExpirePinModalOpen(false);
  };

  const handleChangePin = () => {
    setIsChangePinOpen(false);
  };

  const CancelButton = (
    <Button
      key="2"
      variant="contained"
      color="default"
      fullWidth
      dataCy="cancelPassButton"
    >
      {t('web.admin.hardware.management.hardware.pin.breadcrumb.expirePin')}
    </Button>
  );
  const ReturnButton = (
    <Button
      key="1"
      fullWidth
      dataCy="returnToPageButton"
      onClick={handleModalExpirePinOnCancelClick}
    >
      {t(
        'web.admin.hardware.management.hardware.pin.breadcrumb.expirePinModalReturnButton'
      )}
    </Button>
  );

  if (channel?.address) {
    timezone = getTimeZoneByGeoLocation({
      latitude: channel?.address?.geo[1],
      longitude: channel?.address?.geo[0],
    });
  }

  const handleOnChange = (pin: string) => {
    setPinData(pin);
  };

  const onEditCancel = () => {
    setIsChangePinOpen(true);
  };

  const handleEditPinDetails = () => {
    setIsEditPinDetails(true);
  };
  const { pinCodeId } = useParams<{ pinCodeId: string }>();
  if (!pinCodeId || pinCodeId === 'new') {
    return null;
  }

  const pinDetailsData = {
    title: 'PIN Code for [USPS]',
    titleInfo: 'Created on Tuesday, Apr 9, 2024 - 2:19 PM CDT by Rachelle Tai',
    assignTo: 'USPS',
    repeats: 'EveryDay',
    dates: 'January 1, 2024 to March 31, 2024',
    timeWindow: '6:00 AM - 12:00 PM EST',
    notes1: 'Driver - James Gordon',
    notes2: '703-999-0102',
    kioskType: 'Visitor Registration',
    pinHeader: 'Unique 6 digit number for assignee to use at this property.',
    pinPassword: '********',
  };

  return (
    <AdminPage>
      <BreadCrumbs
        links={[
          {
            label: t(
              'web.admin.hardware.management.hardware.pin.breadcrumb.viewPin'
            ),
            url: routes.channelVMPinCodes.replace(':id', channelSlug || ''),
          },
          {
            label: t(
              'web.admin.hardware.management.hardware.pin.breadcrumb.pinDetails'
            ),
          },
        ]}
      />
      <Modal
        isOpen={isExpirePinModalOpen}
        onClose={() => setIsExpirePinModalOpen(false)}
        actions={[CancelButton, ReturnButton]}
        dataCy=""
        size="large"
        className={styles.modal}
      >
        <H3 mb={4}>
          {t(
            'web.admin.hardware.management.hardware.pin.breadcrumb.expirePinModalTitle'
          )}
        </H3>
        <S className={styles.text} mb={4}>
          {t(
            'web.admin.hardware.management.hardware.pin.breadcrumb.expirePinModalMesage'
          )}
        </S>
        <S className={styles.text} mb={4}>
          {t(
            'web.admin.hardware.management.hardware.pin.breadcrumb.expirePinModalWarning'
          )}
        </S>
      </Modal>
      <div className={styles.pinDetailsPageContainer}>
        <div className={styles.title}>
          <div className={styles.titleContainer}>
            <div className={styles.titleItem}>
              <H2>{pinDetailsData.title}</H2>
            </div>
            <Button
              dataCy="changePinButton"
              size="medium"
              onClick={handleModalExpirePinOnClick}
            >
              {t(
                'web.admin.hardware.management.hardware.pin.breadcrumb.expirePin'
              )}
            </Button>
          </div>
        </div>
        <div className={styles.title}>
          <S className={styles.info}>{pinDetailsData.titleInfo}</S>
        </div>

        <div className={styles.details}>
          <div className={styles.titleContainer}>
            <div className={styles.titleItem}>
              <H4>{t('web.admin.hardware.management.hardware.pin.details')}</H4>
            </div>
            {!isEditPinDetails && (
              <Button
                dataCy="changePinButton"
                size="medium"
                onClick={handleEditPinDetails}
              >
                {t(
                  'web.admin.hardware.management.hardware.pin.breadcrumb.editPin'
                )}
              </Button>
            )}
          </div>
          {!isEditPinDetails && (
            <div className={styles.rowsContainer}>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <S mb={1}>
                    {t(
                      'web.admin.hardware.management.hardware.pin.details.assignTo'
                    )}
                  </S>
                  <P>{pinDetailsData.assignTo}</P>
                </div>
                <div className={styles.rowItem}>
                  <S mb={1}>
                    {t(
                      'web.admin.hardware.management.hardware.pin.details.repeats'
                    )}
                  </S>
                  <P>{pinDetailsData.repeats}</P>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <S mb={1}>
                    {t(
                      'web.admin.hardware.management.hardware.pin.details.dates'
                    )}
                  </S>
                  <P>{pinDetailsData.dates}</P>
                </div>
                <div className={styles.rowItem}>
                  <S mb={1}>
                    {t(
                      'web.admin.hardware.management.hardware.pin.details.timeWindow'
                    )}
                  </S>
                  <P>{pinDetailsData.timeWindow}</P>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <S mb={1}>
                    {t(
                      'web.admin.hardware.management.hardware.pin.details.notes'
                    )}
                  </S>
                  <P>{pinDetailsData.notes1}</P>
                  <P>{pinDetailsData.notes2}</P>
                </div>
                <div className={styles.rowItem}>
                  <S mb={1}>
                    {t(
                      'web.admin.hardware.management.hardware.pin.details.kioskType'
                    )}
                  </S>
                  <P>{pinDetailsData.kioskType}</P>
                </div>
              </div>
            </div>
          )}
          {isEditPinDetails && (
            <HardwareDetailEditPinCodes
              setIsEditPinDetails={setIsEditPinDetails}
              timezone={timezone}
            />
          )}
        </div>

        <div className={styles.host}>
          <div className={styles.itemContainer}>
            <div className={styles.item}>
              <H4>
                {t(
                  'web.admin.hardware.management.hardware.pin.breadcrumb.viewPin'
                )}
              </H4>
            </div>
            {isChangePinOpen && (
              <Button
                dataCy="changePinButton"
                size="medium"
                onClick={handleChangePin}
              >
                {t(
                  'web.admin.hardware.management.hardware.pin.breadcrumb.changePin'
                )}
              </Button>
            )}
          </div>

          <div className={styles.itemContainer}>
            <div className={styles.item}>
              <div className={styles.textWrapper}>
                <P>{pinDetailsData.pinHeader}</P>
                {isChangePinOpen && <S>{pinDetailsData.pinPassword}</S>}
                {!isChangePinOpen && (
                  <div>
                    <div className={styles.titleContainer}>
                      <PinInput
                        autoFocus
                        isNumberInput
                        length={6}
                        onPinChange={(pin: string) => handleOnChange(pin)}
                        pinGenerateButton
                        label={t(
                          'web.admin.hardware.management.hardware.pin.form.input.label'
                        )}
                        helper={t(
                          'web.admin.hardware.management.hardware.pin.form.input.helper'
                        )}
                      />
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button dataCy="saveButton" variant="activate-contained">
                        {t('Save')}
                      </Button>
                      <Button onClick={onEditCancel} dataCy="cancelButton">
                        {t('Cancel')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminPage>
  );
}
