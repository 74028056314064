import React, { useState } from 'react';
import { NoAutopaymentOption } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/SubscriptionPaymentOptions/NoAutopaymentOption/NoAutopaymentOption';
import {
  InvoiceAutopaymentDetails,
  PaymentSource,
} from 'graphql-query-contracts';
import { AutopayEnabledDetails } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/SubscriptionPaymentOptions/AutopayEnabledDetails/AutopayEnabledDetails';
import { TurnOnAutopaymentModal } from 'lane-web/src/domains/billingAndPayments/components/InvoiceAutopayment/TurnOnAutopaymentModal';
import { Modal } from 'design-system-web';
import autopayModalStyle from 'lane-web/src/domains/billingAndPayments/components/InvoiceAutopayment/invoiceAutopayment.scss';

interface Props {
  autopaymentDetails?: InvoiceAutopaymentDetails;
  savedPaymentMethods: PaymentSource[];
  saveAutopaymentMethod: (paymentMethodId: string) => Promise<void>;
  onSubmit: () => void;
}

export function SubscriptionPaymentOptions({
  autopaymentDetails,
  savedPaymentMethods,
  saveAutopaymentMethod,
  onSubmit,
}: Props) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const isAutopaySetup =
    autopaymentDetails?.isInvoiceAutopaymentEnabled &&
    autopaymentDetails.paymentSource;

  return (
    <>
      {isAutopaySetup ? (
        <AutopayEnabledDetails
          paymentSource={autopaymentDetails.paymentSource!}
          onSubmit={onSubmit}
          openAutopayModal={() => setIsAddModalOpen(true)}
        />
      ) : (
        <NoAutopaymentOption openAutopayModal={() => setIsAddModalOpen(true)} />
      )}
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        disableCloseOnBackgroundClick
        dataCy="AddAutopaymentModal"
        className={autopayModalStyle.addAutopaymentModal}
      >
        <TurnOnAutopaymentModal
          paymentSources={savedPaymentMethods}
          saveAutopaymentMethod={saveAutopaymentMethod}
          closeModal={() => setIsAddModalOpen(false)}
        />
      </Modal>
    </>
  );
}
