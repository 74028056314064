import React from 'react';

import { Label, RadioGroup } from 'components';
import { useTranslation } from 'react-i18next';

import { PropertyType } from 'lane-shared/types/properties/Property';

import styles from './VisitorFieldRadioGroup.scss';

export default function VisitorFieldRadioGroup({
  fieldProperties,
  value,
  onChange,
}: {
  fieldProperties: PropertyType;
  value: string;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.visitorFieldRadioGroup}>
      <Label className={styles.label}>{t(fieldProperties.friendlyName!)}</Label>
      <div className={styles.radioGroup}>
        <RadioGroup
          className={styles.radio}
          key={fieldProperties.name}
          name={fieldProperties.name}
          schema={{
            id: '_id',
            text: 'text',
            disabled: 'disabled',
          }}
          selected={value || fieldProperties.default}
          items={fieldProperties.options}
          onChange={value => {
            onChange(value);
          }}
        />
      </div>
    </div>
  );
}
