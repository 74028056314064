import gql from 'graphql-tag';

import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';

import { getClient } from '../../../../apollo';
import { PaymentProcessorEnum } from '../../../../types/Essensys';

const CREATE_OPERATE_PAYMENT_ACCOUNT = gql`
  mutation CreateOperatePaymentAccount(
    $name: String!
    $description: String!
    $type: PaymentProviderType!
    $userId: UUID!
    $publicKey: ID
    $organizationId: ID
  ) {
    payments {
      createPaymentAccount(
        paymentAccount: {
          name: $name
          description: $description
          type: $type
          user: { _id: $userId }
          paymentProcessor: { publicKey: $publicKey }
        }
        options: { operateOrganizationId: $organizationId }
      ) {
        _id
        name
        description
        type
        paymentProcessor {
          _id
          publicKey
          type
        }
      }
    }
  }
`;

type CreatePaymentAccountResponse = {
  payments: {
    createPaymentAccount: PaymentAccountType;
  };
};

type CreatePaymentAccountVariables = {
  name: string;
  description?: string;
  type: PaymentProcessorEnum;
  userId: string;
  publicKey?: string;
};

type CreateOperatePaymentAccountOptions = {
  organizationId?: string;
};

export default async function createOperatePaymentAccount(
  name: string,
  paymentProcessor: PaymentProcessorEnum,
  userId: string,
  publicKey: string,
  { organizationId }: CreateOperatePaymentAccountOptions = {}
) {
  const apolloClient = getClient();

  const response = await apolloClient.mutate<
    CreatePaymentAccountResponse,
    CreatePaymentAccountVariables
  >({
    mutation: CREATE_OPERATE_PAYMENT_ACCOUNT,
    variables: {
      name: `${name} via Operate ${paymentProcessor}`,
      description: `Account used to make ${paymentProcessor} payments on Operate`,
      type: paymentProcessor,
      userId,
      publicKey,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; description: string; type: P... Remove this comment to see the full error message
      organizationId,
    },
  });

  if (response.errors) {
    throw new Error(
      `We were unable to generate a ${paymentProcessor} payment account.`
    );
  }

  return response.data!.payments.createPaymentAccount;
}
