import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import getPathLabel from 'lane-shared/helpers/formatters/getPathLabel';

import ContextMenu from 'components/general/ContextMenu';
import { PathType } from 'components/navigation/PathSelector';

import useSimpleDragHelper from 'hooks/useSimpleDragHelper';

import styles from './PathSelector.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  pathStructure: PathType;
  onPathSelected: (path: string) => void;
  onPathCreate: (newPath: string) => void;
  onDrop: (e: React.DragEvent, path: string) => void;
};

export default function PathStructureDropdown({
  className,
  style,
  pathStructure,
  onPathSelected,
  onPathCreate,
  onDrop,
}: Props) {
  const selectedPath = pathStructure.subPaths.find(
    ({ isSelected }) => isSelected
  );

  function _dropHandler(e: React.DragEvent) {
    if (!selectedPath) {
      return;
    }

    onDrop(e, selectedPath!.path);
  }

  const {
    dragLeaveHandler,
    dragEnterHandler,
    dragOverHandler,
    dropHandler,
    isDropping,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: DragEvent<Element>) => void' is not assi... Remove this comment to see the full error message
  } = useSimpleDragHelper({ onDrop: _dropHandler });

  function renderContextMenu() {
    return (
      <ContextMenu
        menuClassName={styles.menu}
        items={[
          ...pathStructure.subPaths.map(subPath => (
            <button
              key={subPath.path}
              onClick={() => onPathSelected(subPath.path)}
            >
              <Icon
                className={styles.menuIcon}
                name="chevron-right"
                type="fal"
                set="FontAwesome"
              />
              {getPathLabel(subPath.path)}
            </button>
          )),
          <hr key="separator" />,
          <button
            className={styles.action}
            key="add-new"
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            onClick={() => onPathCreate(selectedPath.path)}
          >
            <Icon
              className={styles.menuIcon}
              name="plus"
              type="fal"
              set="FontAwesome"
            />
            Add new folder
          </button>,
        ]}
        hasOpaqueBackground={false}
        autoFocus={false}
        autoClose
      >
        {renderButton()}
      </ContextMenu>
    );
  }

  function renderButton() {
    return (
      <button
        className={styles.contextButton}
        onClick={() =>
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          isChildSelected ? onPathSelected(selectedPath.path) : null
        }
        data-dropping={isDropping}
        onDragOver={dragOverHandler}
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={dropHandler}
      >
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {getPathLabel(selectedPath.path)}
        {isChildSelected && (
          <Icon
            className={styles.icon}
            name="chevron-right"
            type="fal"
            set="FontAwesome"
          />
        )}
      </button>
    );
  }

  if (!selectedPath) {
    return null;
  }

  const isChildSelected = selectedPath.subPaths.find(
    ({ isSelected }) => isSelected
  );

  return (
    <>
      <div
        className={cx(styles.PathStructureDropdown, className)}
        style={style}
      >
        {isChildSelected ? renderButton() : renderContextMenu()}
      </div>

      {pathStructure.subPaths.map(subPath => (
        <PathStructureDropdown
          key={subPath.path}
          pathStructure={subPath}
          onPathSelected={onPathSelected}
          onPathCreate={onPathCreate}
          onDrop={onDrop}
        />
      ))}
    </>
  );
}
