import urlJoin from 'url-join';

import { getEssensysBaseUrl } from './getEssensysBaseUrl';

/**
 * Simple helper function to generate a deep link into Essensys for a contact url
 * @param contactId
 */
export default function formatEssensysContactUrl(contactId: string): string {
  return urlJoin(getEssensysBaseUrl(), `/spacecontrol/mod/4/view/${contactId}`);
}
