import { PIN_LAYOUTS } from '../../../helpers/constants/content';
import { Primitive } from '../../../types/blocks/Primitive';

const SectionBlock: Primitive = {
  name: 'SectionBlock',
  friendlyName: 'Section',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Other'],
  properties: {
    section: {
      type: 'Section',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    pinType: {
      type: 'String',
      validators: [
        {
          name: 'In',
          value: PIN_LAYOUTS,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default SectionBlock;
