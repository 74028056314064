function valueToString(value: any) {
  if (value.constructor === Date) {
    return value.toISOString();
  }

  return value.toString();
}

/**
 * Converts object to query string (?key1=data1&key2=data2)
 */
export default function getQueryString(queryObject: any) {
  if (typeof queryObject !== 'object') {
    return '';
  }

  return `?${Object.entries(queryObject)
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    .filter(([, value]) => ![undefined, null].includes(value))
    .map(([key, value]) => `${key}=${encodeURIComponent(valueToString(value))}`)
    .join('&')}`;
}
