import React from 'react';

import { NotesType } from 'lane-shared/domains/visitorManagement/types';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { NotesForm } from 'lane-web/src/domains/visitorManagement/components/NotesForm/NotesForm';

export function AddNotesBlock({
  properties,
  show,
  onChange,
  submissionCompletedAt,
  isPreview = false,
}: {
  properties: VisitorManagementFeatureProperties;
  show: boolean;
  onChange: (value: NotesType) => void;
  submissionCompletedAt?: Date;
  isPreview?: boolean;
}) {
  if (
    !show ||
    !(
      properties.notesToStaffEnabled ||
      properties.notesToVisitorEnabled ||
      properties.additionalRecipientsEnabled
    )
  )
    return null;

  return (
    <NotesForm
      onChange={onChange}
      isPreview={isPreview}
      properties={properties}
      submissionCompletedAt={submissionCompletedAt}
    />
  );
}
