import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getCompleteSignupData } from '../graphql/user';

export default function useCompleteSignupUserQuery({
  guid,
  verify,
  pollInterval,
}: any) {
  const { loading, data, error } = useQuery(getCompleteSignupData, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables: {
      guid,
      verify,
    },
    pollInterval,
  });

  return {
    error,
    loading,
    data: data?.completeSignupData,
  };
}
