import React from 'react';

import { Button, Icon } from 'design-system-web';
import cx from 'classnames';

import { Modal } from 'components/lds';
import { M } from 'components/typography';

import styles from './styles.scss';

type props = {
  title: string;
  description: string;
  buttonTitle: string;
  iconName?: string;
  iconColor?: string;
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
};

export function ErrorModal({
  title,
  description,
  buttonTitle,
  iconName,
  iconColor,
  isOpen,
  onClick,
  onClose,
}: props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className={styles.modal}
    >
      <div className={cx(styles.errorModalDescription)}>
        {iconName && (
          <div data-testid="error-modal-icon">
            <Icon name={iconName} set="Feather" style={{ color: iconColor }} />
          </div>
        )}
        <M style={iconName ? {} : { marginLeft: 0 }}>{description}</M>
      </div>
      <div className={cx(styles.unsavedChangesButtons)}>
        <Button
          variant="primary"
          dataCy="edit-modal-button"
          onClick={onClick}
          size="large"
        >
          {buttonTitle}
        </Button>
      </div>
    </Modal>
  );
}
