import React from 'react';

import urlJoin from 'url-join';

import { colorToHex } from 'lane-shared/helpers/formatters';

import RichTextHtmlRenderer from '../components/RichTextRenderer';
import { colors, routes } from '../config';
import { LONG_DATE_TIME_WITH_TZ } from '../helpers/constants/dates';
import {
  BORDER_INTERACTION_COLORS,
  INTERACTION_COLORS,
} from '../helpers/constants/interactions';
import { convertTo62 } from '../helpers/convertId';
import { explodeFeatures } from '../helpers/features';
import { dateFormatter } from '../helpers/formatters';
import i18nStub from '../helpers/i18nStub';
import getIntegrationDefinition from '../helpers/integrations/getIntegrationDefinition';
import { AddressType } from '../types/AddressType';
import { WhiteLabelType } from '../types/WhiteLabelType';
import { ChannelProfileType } from '../types/server/EmailTypes';
import {
  ChannelDataType,
  InteractionDataType,
  UserDataType,
  WorkflowDataType,
} from '../types/server/WorkflowTypes';
import {
  MjmlSection,
  MjmlColumn,
  MjmlButton,
  MjmlImage,
  MjmlWrapper,
} from './MjmlComponentsV2';
import EmailBody from './components/EmailBody';
import EmailButton from './components/EmailButton';
import EmailFooter from './components/EmailFooter';
import EmailGuestInviteReceiptGuests from './components/EmailGuestInviteReceiptGuests';
import EmailHeader from './components/EmailHeader';
import EmailLabelWithValue from './components/EmailLabelWithValue';
import EmailMenuOrderCart from './components/EmailMenuOrderCart';
import EmailPaymentFeatureQuote from './components/EmailPaymentFeatureQuote';
import EmailReservationSchema from './components/EmailReservationSchema';
import EmailText from './components/EmailText';
import mapPropertyDefinition from './mapPropertyDefinition';

const qrSize = 200;

export type EmailUserContentInteractionReceiptProps = {
  whiteLabel: WhiteLabelType;
  interaction: InteractionDataType & {
    features: { [key: string]: any };
  };
  channel: ChannelDataType;
  profile: ChannelProfileType;
  address?: AddressType;
  user: UserDataType;
  workflow: WorkflowDataType;
  interactionUser: UserDataType;
  timeZone: string;
  title: string;
  forAdmin: boolean;
  unsubscribe?: string | null;
  // because this component runs server side, we need the i18n passed in
  // from the current running context.
  i18n?: any;
  EditableAreaTopComponent?: React.ReactNode;
  EditableAreaBottomComponent?: React.ReactNode;
  EditableAreaBodyComponent?: React.ReactNode;
};

export default function EmailUserContentInteractionReceipt({
  whiteLabel,
  interaction,
  channel,
  address,
  profile,
  user,
  workflow,
  interactionUser,
  timeZone,
  title,
  forAdmin,
  unsubscribe,
  i18n = i18nStub,
  EditableAreaBodyComponent,
  EditableAreaTopComponent,
  EditableAreaBottomComponent,
}: EmailUserContentInteractionReceiptProps) {
  const content = interaction.contentData;
  const definition = content.data;
  const ownerId = interaction.userId;
  const creatorId = interaction.contentData._createdBy;
  const sourceId = interaction.channelId;

  if (!interaction?.data) {
    return null;
  }
  let receiptInfo;
  let integrationInfo;

  if (definition) {
    receiptInfo = mapPropertyDefinition({
      obj: interaction.data,
      definition,
      user,
      creatorId,
      sourceId,
      ownerId,
    });
  }

  const integrationName = (content?.integration as any)?.integration?.name;
  // some integrations don't have special receipts.
  if (integrationName) {
    integrationInfo = mapPropertyDefinition({
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'PropertiesInterface<PropertyOptionType> | un... Remove this comment to see the full error message
      definition: getIntegrationDefinition(integrationName).interactionData,
      obj: interaction.data[`_${integrationName}`],
      user,
      creatorId,
      sourceId,
      ownerId,
    });
  }

  const {
    paymentFeature,
    menuFeature,
    statusesFeature,
    guestInviteFeature,
  } = explodeFeatures(content?.features);

  const qrCodeLink = urlJoin(
    whiteLabel.baseUrl,
    `/api/v5/qr-code/${
      forAdmin ? 'admin-interaction' : 'interaction'
    }/${convertTo62(interaction._id)}.png?size=${qrSize}?slug=${channel.slug}`
  );

  const contentLink = urlJoin(
    whiteLabel.baseUrl,
    forAdmin
      ? routes.channelAdminContent
          .replace(':id', channel.slug)
          .replace(':contentId', convertTo62(interaction.contentData._id))
      : routes.content.replace(':id', convertTo62(interaction.contentData._id))
  );

  const interactionLink = urlJoin(
    whiteLabel.baseUrl,
    forAdmin
      ? routes.channelAdminInteraction
          .replace(':id', channel.slug)
          .replace(':interactionId', convertTo62(interaction._id))
      : routes.interaction.replace(':id', convertTo62(interaction._id))
  );

  const headerComponent =
    !forAdmin && interaction.startDate ? (
      <EmailReservationSchema
        id={interaction._id}
        userName={user.profile.name}
        eventName={interaction.contentData.name}
        timeZone={timeZone}
        startDate={interaction.startDate}
        endDate={interaction.endDate}
        locationName={channel.name}
        qrCodeLink={qrCodeLink}
        contentLink={contentLink}
        interactionLink={interactionLink}
      />
    ) : null;

  const guests = interaction.features.GuestInvite?.guests
    ? interaction.features.GuestInvite?.guests?.filter(guest => !!guest.name)
    : [];

  const intro = workflow?.payload?.intro;
  const outtro = workflow?.payload?.outtro;
  const customBody = workflow?.payload?.body;
  const qrcode =
    workflow.payload?.qrCodeCheckIn === undefined
      ? true
      : workflow.payload?.qrCodeCheckIn;
  const showContentName =
    workflow.payload?.showContentName === undefined
      ? true
      : workflow.payload?.showContentName;
  const showDateTime =
    workflow.payload?.showDateTime === undefined
      ? true
      : workflow.payload?.showDateTime;

  const defaultBody = (
    <>
      {forAdmin && (
        <EmailLabelWithValue label={i18n.t('Name')}>
          {interactionUser.profile.name}
        </EmailLabelWithValue>
      )}
      {receiptInfo}
      {integrationInfo}
      {guestInviteFeature && guests.length > 0 && (
        <EmailGuestInviteReceiptGuests guests={guests} i18n={i18n} />
      )}
      {menuFeature && (
        <>
          <MjmlSection padding={10}>
            <MjmlColumn> </MjmlColumn>
          </MjmlSection>
          <EmailMenuOrderCart
            showSupport
            whiteLabel={whiteLabel}
            userLocale={user.locale}
            paymentFeature={paymentFeature}
            menuFeature={menuFeature}
            order={interaction.features.Menu!.order}
            i18n={i18n}
          />
        </>
      )}

      {paymentFeature && !menuFeature && (
        <EmailPaymentFeatureQuote
          quote={interaction.features.Payment!.quote}
          cardBrand={interaction.features.Payment!.stripeData?.cardBrand}
          cardLast4={interaction.features.Payment!.stripeData?.cardLast4}
          i18n={i18n}
        />
      )}
    </>
  );

  return (
    <EmailBody title={title} HeaderComponent={headerComponent}>
      <EmailHeader whiteLabel={whiteLabel} i18n={i18n} />
      {EditableAreaTopComponent && (
        <MjmlWrapper data-testid="customIntroEnabled">
          {EditableAreaTopComponent}
          <MjmlSection paddingTop={10}>
            <MjmlColumn>
              {qrcode && <MjmlImage width={qrSize} src={qrCodeLink} />}
            </MjmlColumn>
          </MjmlSection>
        </MjmlWrapper>
      )}
      {!EditableAreaTopComponent && intro && (
        <MjmlWrapper>
          <MjmlSection>
            <MjmlColumn>
              <EmailText>
                <RichTextHtmlRenderer value={intro} />
              </EmailText>
            </MjmlColumn>
          </MjmlSection>
          <MjmlSection paddingTop={10}>
            <MjmlColumn>
              {qrcode && <MjmlImage width={qrSize} src={qrCodeLink} />}
            </MjmlColumn>
          </MjmlSection>
        </MjmlWrapper>
      )}
      {!EditableAreaTopComponent && !intro && (
        <>
          <MjmlSection
            borderTop={`1px solid ${colors.charcoal[9]}`}
            borderBottom={`1px solid ${colors.charcoal[9]}`}
          >
            <MjmlColumn>
              <EmailText>
                <h2
                  style={{
                    textAlign: 'center',
                    fontWeight: 'normal',
                  }}
                >
                  {forAdmin ? title : `${i18n.t('Hi')} ${user.profile.name}`}
                </h2>
                {paymentFeature && (
                  <>
                    <br />
                    <h3>
                      {forAdmin
                        ? i18n.t('Here is the info for this transaction')
                        : i18n.t('Here is your transaction info')}
                      ,
                    </h3>
                  </>
                )}
              </EmailText>
              {statusesFeature && (
                <MjmlButton
                  color={colors.gray1}
                  borderRadius={25}
                  border={`2px solid ${
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    BORDER_INTERACTION_COLORS[interaction.status]
                  }`}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  backgroundColor={INTERACTION_COLORS[interaction.status]}
                >
                  {i18n.t(interaction.status)}
                </MjmlButton>
              )}
              {qrcode && <MjmlImage width={qrSize} src={qrCodeLink} />}
            </MjmlColumn>
          </MjmlSection>
        </>
      )}
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            {showContentName && (
              <MjmlWrapper data-testid="showContentName">
                <h3 style={{ textAlign: 'center' }}>
                  {user.isGuest ? (
                    interaction.contentData.name
                  ) : (
                    <a
                      href={contentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {interaction.contentData.name}
                    </a>
                  )}
                </h3>
              </MjmlWrapper>
            )}
            {showDateTime && (
              <MjmlWrapper>
                <h3 style={{ textAlign: 'center', marginTop: 20 }}>
                  {i18n.t('Date and Time')}
                </h3>
                <MjmlColumn>
                  <h3 style={{ textAlign: 'center' }}>
                    {i18n.t('Starts')}
                    {': '}
                    {dateFormatter(
                      interaction.startDate || interaction._created,
                      LONG_DATE_TIME_WITH_TZ,
                      timeZone,
                      user.locale
                    )}
                  </h3>
                  {interaction.endDate && (
                    <h3 style={{ textAlign: 'center' }}>
                      {i18n.t('Ends')}
                      {': '}
                      {dateFormatter(
                        interaction.endDate,
                        LONG_DATE_TIME_WITH_TZ,
                        timeZone,
                        user.locale
                      )}
                    </h3>
                  )}
                </MjmlColumn>
              </MjmlWrapper>
            )}
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      {EditableAreaBodyComponent && <>{EditableAreaBodyComponent}</>}
      {!EditableAreaBodyComponent && customBody && (
        <>
          <MjmlSection>
            <MjmlColumn>
              <EmailText>
                <RichTextHtmlRenderer value={customBody} />
              </EmailText>
            </MjmlColumn>
          </MjmlSection>
        </>
      )}
      {!EditableAreaBodyComponent && !customBody && defaultBody}
      <MjmlSection>
        <MjmlColumn>
          <EmailButton
            width={150}
            href={interactionLink}
            color={colorToHex(whiteLabel.profile.backgroundColor)}
          >
            {i18n.t('View')}
          </EmailButton>
        </MjmlColumn>
      </MjmlSection>

      {EditableAreaBottomComponent && (
        <MjmlWrapper data-testid="customOuttroEnabled">
          <MjmlSection>
            <MjmlColumn>
              <EmailText>{EditableAreaBottomComponent}</EmailText>
            </MjmlColumn>
          </MjmlSection>
        </MjmlWrapper>
      )}

      {!EditableAreaBottomComponent && outtro && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>
              <RichTextHtmlRenderer value={outtro} />
            </EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}

      <EmailFooter
        contactUsEmail={profile?.email || whiteLabel.profile.email}
        channel={channel}
        address={address}
        whiteLabel={whiteLabel}
        unsubscribe={unsubscribe}
        i18n={i18n}
        profile={profile}
      />
    </EmailBody>
  );
}
