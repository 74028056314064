import { MobilePermissionEnum } from '../../../types/MobilePermissionEnum';
import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';

const KastleDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Kastle,
  hasCustomUI: true,
  friendlyName: 'Kastle Access Control',
  description: `Defines the properties required for a Kastle integration.`,
  category: IntegrationCategoryEnum.AccessControl,
  platforms: [PlatformEnum.Mobile],
  type: IntegrationTypeEnum.SDK,
  settings: {},
  properties: {
    supportPage: {
      friendlyName: 'Support Page',
      type: 'Content',
    },
    isAppleWalletEnabled: {
      hidden: false,
      type: 'Boolean',
      friendlyName: 'Enable Apple Wallet',
      default: false,
    },
  },
  mobilePermissions: [MobilePermissionEnum.Bluetooth],
  // Google requires us to use specific language requesting background location access before we load the system prompts
  mobilePermissionsRequest:
    'To unlock doors, Workplace Access requires these device settings to be enabled, including accessing your location when the application is not in use:',
};

export default KastleDefinition;
