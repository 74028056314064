import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getReservableWaitlistEnrollments } from 'graphql-queries';
import type { ReservableWaitlistEnrollment } from 'graphql-resolver-contracts';

import useUserDataContext from '../useUserDataContext';

// Warn: this hook is not unit tested
export const useGetEnrolledUserWaitlist = ({
  contentId,
}: {
  contentId?: string;
}) => {
  const { user } = useUserDataContext();
  const [enrolledUserWaitlists, setEnrolledUserWaitlists] = useState<
    ReservableWaitlistEnrollment[]
  >([]);

  const [fetchEnrolledUserWaitlist] = useLazyQuery(
    getReservableWaitlistEnrollments,
    {
      variables: {
        userId: user?._id,
        contentId,
      },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      if (user?._id && contentId) {
        const result = await fetchEnrolledUserWaitlist();
        if (result.data) {
          setEnrolledUserWaitlists(result.data.reservableWaitlistEnrollments);
        }
      }
    };
    fetchData();
  }, [user?._id, contentId]);

  return {
    enrolledUserWaitlists,
    fetchEnrolledUserWaitlist,
    setEnrolledUserWaitlists,
  };
};
