import useFlag from './useFlag';

const FLAG = 'allow-magic-link-flag';

function useMagicLinksFeatureEnabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}

export default useMagicLinksFeatureEnabled;
