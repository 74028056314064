import { getMatchingMonthIntervalTypes } from 'lane-shared/domains/visitorManagement/helpers';
import { MonthIntervalTypeEnum } from 'lane-shared/domains/visitorManagement/types';
import { parseDateTime } from 'lane-shared/helpers/dates';

type MonthIntervalOptions = {
  label: string;
  value: MonthIntervalTypeEnum;
};

export function getMonthIntervalOptions(
  date: Date,
  translator: (key: string, params?: any) => string,
  timeZone?: string
): MonthIntervalOptions[] {
  const availableOptions = getMatchingMonthIntervalTypes(date, timeZone);

  return availableOptions.map(option => ({
    label: getMonthIntervalTypeLabel(option, translator, date, timeZone),
    value: option,
  }));
}

function getMonthIntervalTypeLabel(
  monthIntervalType: MonthIntervalTypeEnum,
  translator: (key: string, params?: any) => string,
  date: Date,
  timeZone?: string
): string {
  const dateTime = parseDateTime(date, timeZone);

  if (!dateTime) return '';

  switch (monthIntervalType) {
    case MonthIntervalTypeEnum.OnDate:
      return translator(
        'web.content.feature.visitorManagement.form.monthInterval.onDate',
        { date: dateTime.day }
      );
    case MonthIntervalTypeEnum.FirstOfDay:
      return translator(
        'web.content.feature.visitorManagement.form.monthInterval.firstOfDay',
        { dayOfWeek: dateTime.weekdayLong }
      );
    case MonthIntervalTypeEnum.SecondOfDay:
      return translator(
        'web.content.feature.visitorManagement.form.monthInterval.secondOfDay',
        { dayOfWeek: dateTime.weekdayLong }
      );
    case MonthIntervalTypeEnum.ThirdOfDay:
      return translator(
        'web.content.feature.visitorManagement.form.monthInterval.thirdOfDay',
        { dayOfWeek: dateTime.weekdayLong }
      );
    case MonthIntervalTypeEnum.FourthOfDay:
      return translator(
        'web.content.feature.visitorManagement.form.monthInterval.fourthOfDay',
        { dayOfWeek: dateTime.weekdayLong }
      );
    case MonthIntervalTypeEnum.LastOfDay:
      return translator(
        'web.content.feature.visitorManagement.form.monthInterval.lastOfDay',
        { dayOfWeek: dateTime.weekdayLong }
      );
    default:
      return translator(
        'web.content.feature.visitorManagement.form.monthInterval.onDate',
        { date: dateTime.day }
      );
  }
}
