import { useMyPublicUsersQuery } from 'lane-shared/hooks';

export const DEFAULT_DIY_BURST_CAPACITY = 200;
export const DEFAULT_BURST_CAPACITY = 36;
export const USERS_TO_DISPLAY = 25;

type Props = {
  maxCapacity: number;
};

export default function useShopifyGuests({
  maxCapacity = DEFAULT_BURST_CAPACITY,
}: Props) {
  const { users, search, updateSearch } = useMyPublicUsersQuery({
    take: maxCapacity + USERS_TO_DISPLAY,
  });

  return {
    users,
    search,
    updateSearch,
  };
}
