import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import type { UserBooking } from 'graphql-resolver-contracts';
import { getUserBookingsCountForContent } from 'graphql-queries';

import useUserDataContext from '../useUserDataContext';

export const useGetUserBookingsCountForContent = ({
  contentId,
  selectedDate,
}: {
  contentId?: string;
  selectedDate?: Date;
}) => {
  const { user } = useUserDataContext();
  const [userBookingsCount, setUserBookingsCount] = useState<UserBooking[]>([]);

  const [fetchUserBookingCount] = useLazyQuery(getUserBookingsCountForContent, {
    variables: {
      userId: user?._id || null,
      contentId,
      selectedDate,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (user?._id && contentId) {
        try {
          const result = await fetchUserBookingCount();
          if (result.data) {
            setUserBookingsCount(result.data.userBookingsCountForContent);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [user?._id, contentId, selectedDate]);

  return {
    userBookingsCount,
    fetchUserBookingCount,
    setUserBookingsCount,
  };
};
