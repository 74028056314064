import React, { useState } from 'react';
import cx from 'classnames';

import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { ContentType } from 'lane-shared/types/content/Content';
import { InteractionDataType } from 'lane-shared/types/server/WorkflowTypes';
import { useUserDataContext, useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { usePaymentMethodFeature } from 'lane-shared/domains/billingPayments/hooks';
import {
  OrderSummaryType,
  OrderSummaryWithProducts,
} from 'lane-shared/domains/billingPayments/types';

import { ErrorMessage } from 'lane-web/src/components/general';
import { H3 } from 'lane-web/src/components/typography';

import { OrderSummaryContainer } from './OrderSummaryDetails/OrderSummaryContainer';
import { PaymentOptions } from './PaymentOptions/PaymentOptions';

import styles from './OrderSummary.scss';
import { OrderSummaryWithProductsContainer } from './OrderSummaryWithProducts/OrderSummaryWithProductsContainer';
import { Button } from 'design-system-web';

interface OrderSummaryProps {
  orderSummaryData: OrderSummaryType | null;
  orderSummaryWithProducts?: OrderSummaryWithProducts | null;
  content?: ContentType;
  interaction?: InteractionDataType;
  backgroundImage: string;
  loading: boolean;
  error?: any;
  paymentV2Error?: Error | null;
  onSubmit: (
    accountsUserId: string | null,
    selectedPaymentMethod: string | undefined
  ) => void;
}

export function OrderSummary({
  orderSummaryData,
  orderSummaryWithProducts,
  content,
  interaction,
  backgroundImage,
  loading,
  error,
  paymentV2Error,
  onSubmit,
}: OrderSummaryProps) {
  const { t } = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | undefined
  >(undefined);

  const { user } = useUserDataContext();

  const {
    createUser,
    accountsUserId,
    savePaymentMethod,
    removePaymentMethod,
    savedPaymentMethods,
    paymentMethodError,
    removeAutopaymentMethod,
    invoiceAutopayment,
    saveAutopaymentMethod,
  } = usePaymentMethodFeature({ user, setSelectedPaymentMethod });

  const ProductsInContent = useFlag(FeatureFlag.ProductsInContent, false);

  const subscriptionsInContentFlag = useFlag(
    FeatureFlag.SubscriptionsInContent,
    false
  );
  const autopaymentInContentFlag = useFlag(
    FeatureFlag.AutopaymentInContent,
    false
  );

  const isSubscriptionContent = Boolean(
    subscriptionsInContentFlag && orderSummaryWithProducts?.subscriptionDetails
  );
  const isSubscriptionButWithoutAutopayment =
    isSubscriptionContent && !autopaymentInContentFlag;

  return (
    <>
      <Prompt
        when={loading}
        message={t('abp.payment.orderSummary.pleaseDontCloseThisWindow')}
      />
      <div
        className={cx(
          styles.orderSummaryContainer,
          isSubscriptionButWithoutAutopayment && styles.isSubscriptionContent
        )}
      >
        <H3 className={styles.orderSummaryTitle}>
          {t('abp.payment.orderSummary.completeYourPurchase')}
        </H3>
        {ProductsInContent &&
        orderSummaryWithProducts &&
        orderSummaryWithProducts.products?.length > 0 ? (
          <OrderSummaryWithProductsContainer
            content={content}
            interaction={interaction}
            orderSummaryWithProducts={orderSummaryWithProducts}
          />
        ) : (
          orderSummaryData && (
            <OrderSummaryContainer
              backgroundImage={backgroundImage}
              orderSummaryData={orderSummaryData}
            />
          )
        )}
        <div className={styles.paymentOptionsAndPurchaseButton}>
          {isSubscriptionButWithoutAutopayment ? (
            <Button
              className={styles.subscribeButton}
              fullWidth
              variant="primary"
              // This button will be remove with release of autopayment in content so leaving as any for now
              onClick={onSubmit as any}
              loading={loading}
              dataCy="subscribeButton"
            >
              {t('abp.payment.orderSummary.purchaseSubscription')}
            </Button>
          ) : (
            <PaymentOptions
              updatePaymentMethod={setSelectedPaymentMethod}
              createUser={createUser}
              savePaymentMethod={savePaymentMethod}
              savedPaymentMethods={savedPaymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              removePaymentMethod={removePaymentMethod}
              onSubmit={() => onSubmit(accountsUserId, selectedPaymentMethod)}
              loading={loading}
              removeAutopaymentMethod={removeAutopaymentMethod}
              invoiceAutopayment={invoiceAutopayment}
              isSubscriptionContent={isSubscriptionContent}
              saveAutopaymentMethod={saveAutopaymentMethod}
            />
          )}
        </div>
      </div>
      {paymentMethodError && (
        <ErrorMessage error={t(paymentMethodError.message)} />
      )}
      {error && <ErrorMessage error={t(error.message)} />}
      {paymentV2Error && <ErrorMessage error={t(paymentV2Error.message)} />}
    </>
  );
}
