import React from 'react';
import { Icon, S } from 'design-system-web';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { RecurrenceInterval } from 'graphql-query-contracts';
import routes from 'lane-shared/config/routes';

export function SubscriptionReceiptDisclaimer({
  recurrenceInterval,
}: {
  recurrenceInterval: RecurrenceInterval;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const navigateToUserInvoices = () => {
    history.push(routes.meAccountInvoices);
  };

  const translatedFrequencyInterval = t(
    `abp.subscription.recurrenceInterval.${recurrenceInterval}`
  ).toLowerCase();
  return (
    <div className="flex flex-row items-center gap-x-1 border rounded-lg border-border-subdued py-2 px-2 w-full bg-background-disabled">
      <Icon size="small" set={ICON_SET_FONTAWESOME} name="infoCircle" />
      <Trans parent={S} i18nKey="abp.receipt.subscription.disclaimer">
        <span className='font-semibold'>This is not a payment receipt</span> - you will be
        <button className='text-text-link underline' onClick={() => navigateToUserInvoices()}>invoiced</button> for
        payment on a {{ interval: translatedFrequencyInterval }} basis.
      </Trans>
    </div>
  );
}
