import { mapValues } from 'lodash';

import { request, Res } from './common';

export interface EmailRecipient {
  email: string;
}

export interface ContactPerson {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  is_premier?: boolean;
  id?: string;
  checked_in?: string;
  checked_out?: boolean;
  checked_in_by?: any;
  date?: string;
  checked_out_by?: any;
  canceled_at?: string;
  canceled_by?: any;
  status?: string;
  exclude_reason?: any;
  photo?: any;
  thumbnail?: any;
  user?: any;
  visitor_id?: string;
  user_id?: any;
  deletable?: any;
}

export interface VisitReqData {
  building_id: string;
  organization_id: string;
  space_id: string;
  host?: string;
  from: Date;
  schedule: string;
  contact_persons: Array<ContactPerson>;
  type: string;

  to?: Date;
  start_time?: string;
  end_time?: string;
  is_recurring?: boolean;

  company?: string;

  guard_instructions?: string;
  instructions?: string;
  email_recipients?: Array<EmailRecipient>;
  reason_for_visit?: string;
}

export interface VisitPatchData {
  organization_id?: string;
  space_id?: string;
  host?: string;
  from?: Date;
  schedule?: string;
  to?: Date;
  start_time?: string;
  end_time?: string;
  is_recurring?: boolean;
  company?: string;
  guard_instructions?: string | null;
  instructions?: string;
  email_recipients?: Array<EmailRecipient>;
}

export type visitorStatus =
  | 'SCHEDULED'
  | 'CHECKED_IN'
  | 'CHECKED_OUT'
  | 'CANCELED';

export interface VisitorResData {
  id: string;
  type: string;
  building?: any;
  date: string;
  organization?: any;
  requester_user?: any;
  instructions?: string;
  schedule?: string;
  reason_for_visit?: string;
  company?: string;
  vendor_company?: any;
  start_time?: string;
  end_time?: string;
  created_at?: string;
  created_by?: any;
  modified_at?: any;
  modified_by?: any;
  floor?: string;
  host: string;
  space?: any;
  guard_instructions?: string;
  next_run?: string;
  is_recurring?: boolean;
  recurrence_rule?: string;
  recurrence_rule_count?: string;
  recurrence_rule_occurred_count?: number;
  recurrence_rule_future_occurrences_count?: string;
  building_id?: string;
  organization_id?: string;
  requester_user_id?: string;
  vendor_company_id?: any;
  created_by_id?: string;
  space_id?: string;
  email_recipients?: string[];
  contact_persons?: ContactPerson[];
  status: visitorStatus;
  visit_id: string;
  first_name: string;
  last_name: string;
}

export interface VisitResData {
  id: string;
  type: string;
  instructions?: string;
  schedule: string;
  reason_for_visit?: string;
  start_time?: string;
  end_time?: string;
  floor?: string | null;
  host: string;
  next_run?: string | null;
  guard_instructions?: string;
  is_recurring?: boolean;
  recurrence_rule?: string;
  recurrence_rule_count?: string;
  recurrence_rule_occurred_count?: number;
  recurrence_rule_future_occurrences_count?: string;
  building_id?: string;
  organization_id?: string;
  space_id?: string;
  email_recipients?: string[];
  contact_persons?: ContactPerson[];
}

export interface ListResData {
  count: number;
  results: VisitorResData[];
  next: string;
}

/**
 * @see {@link https://api.connect.buildingengines.com/swagger/ | Building Engines Swagger Docs}
 * @see {@link https://connect.buildingengines.com/visits/create | BE Prism Create a Visitor Page}
 */
export async function create(
  token: string,
  data: VisitReqData
): Promise<Res<VisitResData>> {
  return request({
    token,
    method: 'post',
    path: '/visits',
    data,
  });
}

/**
 * The Visitors fetch api supports multiple ordering options.
 * These have been compiled by looking at UI behaviour at
 * https://connect.buildingengines.com/visitors
 *
 * @see {@link https://connect.buildingengines.com/visitors}
 */
export enum FetchVisitsOrderingEnum {
  NameAsc = 'individual_contact_person__first_name,individual_contact_person__last_name',
  NameDesc = '-individual_contact_person__first_name,individual_contact_person__last_name',
  StatusAsc = 'status',
  StatusDesc = '-status',
  DateAsc = 'date',
  DateDesc = '-date',
  VisitStartTimeAsc = 'visit__start_time',
  VisitStartTimeDesc = '-visit__start_time',
  VisitorEmailAsc = 'individual_contact_person__email',
  VisitorEmailDesc = '-individual_contact_person__email',
  VisitorPhoneAsc = 'individual_contact_person__phone',
  VisitorPhoneDesc = '-individual_contact_person__phone',
}

export enum FetchVisitorsFilterValues {
  DateGte = 'date__gte',
  DateGt = 'date__gt',
  DateLte = 'date__lte',
  DateLt = 'date__lt',
  CheckedInGte = 'checked_in__gte',
  CheckedInLte = 'checked_in__lte',
  CheckedOutGte = 'checked_out__gte',
  CheckedOutLte = 'checked_out__lte',
  Status = 'status__in',
  IsPremierVisitor = 'visitor__is_premier__in',
  VisitType = 'visit__type__in',
  Timezone = 'timezone',
  Target = 'target',
}
export interface FetchVisitorsFilters {
  [FetchVisitorsFilterValues.DateGte]?: string;
  [FetchVisitorsFilterValues.DateGt]?: string;
  [FetchVisitorsFilterValues.DateLte]?: string;
  [FetchVisitorsFilterValues.DateLt]?: string;
  [FetchVisitorsFilterValues.CheckedInGte]?: string;
  [FetchVisitorsFilterValues.CheckedInLte]?: string;
  [FetchVisitorsFilterValues.CheckedOutGte]?: string;
  [FetchVisitorsFilterValues.CheckedOutLte]?: string;
  [FetchVisitorsFilterValues.Status]?:
    | 'scheduled'
    | 'checked_in'
    | 'checked_out';
  [FetchVisitorsFilterValues.IsPremierVisitor]?: boolean;
  [FetchVisitorsFilterValues.VisitType]?: 'GUEST' | 'VENDOR';
  [FetchVisitorsFilterValues.Timezone]?: string;
  [FetchVisitorsFilterValues.Target]?: 'today_and_future';
}
export interface ListParams {
  ordering?: FetchVisitsOrderingEnum | '';
  limit?: number;
  offset?: number;
  pagelimit?: number;
  visit__building__in: string;
}

export async function list(
  token: string,
  params: ListParams,
  filters?: FetchVisitorsFilters
): Promise<Res<ListResData>> {
  return request({
    token,
    method: 'get',
    params: mapValues({ ...params, ...filters }, x => String(x)),
    path: '/visitors',
  });
}

export async function get(
  token: string,
  visitId: string
): Promise<Res<VisitResData>> {
  return request({
    token,
    method: 'get',
    path: `/visits/${visitId}`,
  });
}

export async function cancel(
  token: string,
  visitorId: string
): Promise<Res<void>> {
  return request({
    token,
    method: 'post',
    path: `/visitors/bulk_actions`,
    data: { included_ids: visitorId, action: 'cancel' },
  });
}

export async function update(
  token: string,
  visitId: string,
  data: VisitPatchData
): Promise<Res<VisitResData>> {
  return request({
    token,
    method: 'PATCH',
    path: `/visits/${visitId}`,
    data,
  });
}
