import React from 'react';

import { i18n } from 'i18next';
import { z } from 'zod';

import { Mjml, MjmlBody } from 'lane-shared/emails/MjmlComponentsV2';
import {
  EmailHorizontalRule,
  EmailSpacer,
} from 'lane-shared/emails/components';

import {
  EmailVisitorHeader,
  EmailVisitorFooter,
  EmailVisitorHero,
  EmailVisitorGreeting,
  EmailVisitorHostDetails,
  EmailVisitorNotesUpdateSummary,
  EmailRecurringScheduleVisitorDetails,
} from './components';
import { AlertType, EmailVisitorAlert } from './components/EmailVisitorAlert';
import {
  buildingSchema,
  hostSchema,
  recurringScheduleSchema,
  scheduleChangeDetailsSchema,
  visitorSchema,
  whitelabelSchema,
} from './schema';

const ActivateHostRecurrenceUpdateParams = z.object({
  building: buildingSchema,
  host: hostSchema,
  visitors: z.array(visitorSchema),
  organizer: hostSchema.optional(),
  whitelabel: whitelabelSchema.optional(),
  recurringSchedule: recurringScheduleSchema,
  oldRecurrenceScheduleData: scheduleChangeDetailsSchema.optional(),
});

export type EmailHostRecurrenceUpdateProps = {
  title: string;
  params: z.infer<typeof ActivateHostRecurrenceUpdateParams>;
  i18n: i18n;
};

export function EmailHostRecurrenceUpdate({
  title,
  params,
  i18n,
}: EmailHostRecurrenceUpdateProps) {
  try {
    params = ActivateHostRecurrenceUpdateParams.parse(params);
  } catch (error) {
    console.error(error);
    throw error;
  }

  const scheduleIsUpdated = Boolean(
    params.oldRecurrenceScheduleData?.startDate ||
      params.oldRecurrenceScheduleData?.endDate ||
      params.oldRecurrenceScheduleData?.scheduleText
  );
  const timeIsUpdated = Boolean(
    params.oldRecurrenceScheduleData?.startTime ||
      params.oldRecurrenceScheduleData?.endTime
  );

  const recurrenceIsUpdated = Boolean(
    params.oldRecurrenceScheduleData?.changeNote ||
      scheduleIsUpdated ||
      timeIsUpdated
  );

  const targetName = params.organizer?.firstName || params.host.firstName;

  return (
    <Mjml>
      <EmailVisitorHeader
        title={title}
        primaryColor={params.building.primaryColor}
      />
      <MjmlBody background-color="#F5F6F7">
        <EmailSpacer />

        <EmailVisitorHero backgroundUrl={params.building.visitorHeroUri} />

        <EmailVisitorGreeting
          salutation={i18n.t('shared.email.visitorManagement.greeting', {
            firstName: targetName,
          })}
        />

        {recurrenceIsUpdated && (
          <EmailVisitorAlert type={AlertType.success}>
            <b style={{ color: '#0F121F' }}>
              {i18n.t(
                'shared.email.visitorManagement.hostAlert.recurrence.updated.line.one'
              )}
            </b>
            <br />
            <b style={{ color: '#0F121F' }}>
              {i18n.t(
                'shared.email.visitorManagement.visitorAlert.pass.updated.line.two'
              )}
            </b>
            <span>{params.oldRecurrenceScheduleData?.changeNote}</span>
          </EmailVisitorAlert>
        )}

        <EmailHorizontalRule />

        <EmailRecurringScheduleVisitorDetails
          i18n={i18n}
          visitors={params.visitors}
          building={params.building}
          recurringSchedule={params.recurringSchedule}
          oldRecurrenceScheduleData={params.oldRecurrenceScheduleData!}
        />

        <EmailHorizontalRule />

        <EmailVisitorHostDetails i18n={i18n} host={params.host} />

        <EmailHorizontalRule />

        <EmailVisitorNotesUpdateSummary
          i18n={i18n}
          recurringSchedule={params.recurringSchedule}
          oldRecurrenceScheduleData={params.oldRecurrenceScheduleData}
        />

        <EmailVisitorFooter
          i18n={i18n}
          primaryColor={params.building.primaryColor}
          building={params.building}
          whitelabel={params.whitelabel}
        />
        <EmailSpacer />
      </MjmlBody>
    </Mjml>
  );
}
