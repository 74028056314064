import React from 'react';

import { v4 as uuid } from 'uuid';

import { BaseProps, BaseDropdown } from '../Dropdown/Dropdown';

type ItemSchema = {
  label: string;
  value: string;
  isDisabled?: string;
};

type OwnProps = {
  schema?: ItemSchema;
  value?: any[];
  onChange: (items: any[]) => void;
  initialValues?: any[];
  truncateSelectedItems?: boolean;
  hideSelectedOptions?: boolean;
  items?: any[];
};

type Props<T extends unknown> = OwnProps & Omit<BaseProps<T>, 'items'>;

export const Multiselect = <T,>({
  items = [],
  name = `MultiselectField-${uuid()}`,
  value = [],
  onChange,
  placeholder = 'Select Option',
  initialValues = [],
  schema = { label: 'label', value: 'value', isDisabled: 'isDisabled' },
  isSearchable = true,
  errors,
  invalid,
  loadOptions,
  noOptionsMessage,
  onCreateOption,
  ...props
}: Props<T>) => {
  function constructOption(item: any) {
    return {
      label: item[schema.label],
      value: item[schema.value],
      isDisabled: schema?.isDisabled ? item[schema.isDisabled] : undefined,
    };
  }

  function onReturnOption(options: any) {
    if (!options) {
      onChange([]);
      return;
    }

    const selectedOptions = loadOptions
      ? options
      : items.filter(item =>
          options.find(
            (option: any) =>
              item[schema.value as keyof typeof item] === option.value
          )
        );

    onChange(selectedOptions);
  }

  const isInvalid = !!invalid || !!(errors && errors.length > 0);

  return (
    <BaseDropdown
      value={value}
      name={name}
      isMulti
      initialValue={initialValues}
      placeholder={placeholder}
      onChange={onReturnOption}
      closeMenuOnSelect={!!loadOptions}
      isSearchable={isSearchable}
      items={items && items.map(constructOption)}
      errors={errors}
      invalid={isInvalid}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      onCreateOption={onCreateOption}
      {...props}
    />
  );
};
