import * as yup from 'yup';

export default yup.object().shape({
  externalId: yup.string().trim().required(),
  externalType: yup.string().trim().required(),
  permissions: yup.object().shape({
    remove: yup.array().of(yup.string()),
    add: yup.array().of(yup.string()),
    set: yup.array().of(yup.string()),
  }), 
  keys: yup.array().of(yup.string()),
});
