import { LaneType } from 'common-types';
import { InvalidIdError } from 'activate-errors';
import { base62to16 } from './baseConvert';
import {
  UUID,
  UUID_BASE62,
  UUID_NAKED,
  MONGO_ID,
  UUID_PARTS,
  MONGO_ID_BASE62,
} from './constants';
import padLeft from './padLeft';
import unPadLeft from './unPadLeft';

export default function convertToUUID(
  id: string | null | undefined
): LaneType.UUID {
  if (!id) {
    throw new InvalidIdError('Invalid ID: no id provided.');
  }

  let idString = id.toString();

  let pos = 0;

  switch (idString.length) {
    case UUID.length:
      return idString;
    case MONGO_ID_BASE62.length:
    // @ts-expect-error ts-migrate(7029) FIXME: Fallthrough case in switch.
    case UUID_BASE62.length:
      idString = base62to16(unPadLeft(idString));
    // fall through
    // @ts-expect-error ts-migrate(7029) FIXME: Fallthrough case in switch.
    case MONGO_ID.length:
      idString = padLeft(idString, UUID_NAKED.length);
    // fall through
    case UUID_NAKED.length:
      // Blow up and put in separators -
      return UUID_PARTS.map(part =>
        idString.substring(pos, (pos += part.length))
      ).join('-');
    default:
      throw new InvalidIdError(
        `Invalid ID: ${idString} length ${idString.length}`
      );
  }
}
