import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import FileInput from 'components/form/FileInput';
import Button from 'components/general/Button';

import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';

export function ButtonImportGroup({ groupRole, updateGroupRole }: any) {
  const { t } = useTranslation();

  function importFileHandler(uploadedFile: string) {
    try {
      const obj = JSON.parse(uploadedFile);

      updateGroupRole({
        permissions: obj.permissions || groupRole.permissions,
      });

      window.Toast.show('Imported!');
    } catch (err) {
      window.Alert.alert({
        title: 'There was an error with this file.',
        error: err,
      });
    }
  }

  return (
    <FileInput
      accept="application/json"
      type={FileReturnTypeEnum.Text}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(uploadedFile: string) => void' is not assig... Remove this comment to see the full error message
      onFileSelected={importFileHandler}
    >
      <Button startIcon={<Icon name="upload" />}>
        {t('web.components.lane.Team.Header.ButtonImportGroup')}
      </Button>
    </FileInput>
  );
}
