import React, { CSSProperties } from 'react';

import { useIsAdminView } from 'hooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useAdminNotesEnabled } from 'lane-shared/hooks/useAdminNotesEnabled';
import { useUserNotesEnabled } from 'lane-shared/hooks/useUserNotesEnabled';

import { Props as ContentNameCellProps } from '../../ContentNameCell';
import { ReservableStatusDropdownProps } from '../../ReservableStatusDropdown/ReservableStatusDropdown';
import { RowData } from '../../../utilities/bookingItemToRowDataMapper';

const TRANSLATION_KEYS = {
  contentName: 'web.admin.channel.reservableManagement.table.contentName',
  status: 'web.admin.channel.reservableManagement.table.status',
  bookingDate: 'web.admin.channel.reservableManagement.table.bookingDate',
  bookingTime: 'web.admin.channel.reservableManagement.table.bookingTime',
  company: 'web.admin.channel.reservableManagement.table.company',
  reservedBy: 'web.admin.channel.reservableManagement.table.reservedBy',
  primaryEmail: 'web.admin.channel.reservableManagement.table.primaryEmail',
  adminNote: 'web.admin.channel.reservableManagement.table.adminNote',
  userNote: 'web.admin.channel.reservableManagement.table.userNote',
};

export enum ColumnKey {
  contentName = 'content',
  status = 'status',
  bookingDate = 'bookingDate',
  bookingTime = 'bookingTime',
  company = 'company',
  reservedBy = 'reservedBy',
  primaryEmail = 'primaryEmail',
  adminNote = 'adminNote',
  userNote = 'userNote',
}

export type Props = {
  channelTimeZone: string;
  noteStyles?: CSSProperties;
  contentNameCellRenderer: (props: ContentNameCellProps) => JSX.Element;
  statusRenderer: (args: ReservableStatusDropdownProps) => React.ReactNode;
};

export function getTimeZoneAbbreviation(channelTimeZone: string) {
  const today = new Date();
  const isoTodayTimestamp = today.toISOString();
  const dateTime = DateTime.fromISO(isoTodayTimestamp, {
    zone: channelTimeZone,
  });

  const timeZoneAbbreviation = dateTime.toFormat('ZZZZ');

  return timeZoneAbbreviation;
}

export function useColumnsHeaders({
  channelTimeZone,
  contentNameCellRenderer,
  statusRenderer,
  noteStyles,
}: Props) {
  const { t } = useTranslation();
  const [, channelSlug] = useIsAdminView();
  const isUserNotesEnabled = useUserNotesEnabled();
  const isAdminNotesEnabled = useAdminNotesEnabled();

  const columns = [
    {
      header: t(TRANSLATION_KEYS.contentName),
      key: ColumnKey.contentName,
      disableSorting: true,
      renderCell: (content: RowData['content']) =>
        contentNameCellRenderer({ channelSlug, content }),
    },
    {
      header: t(TRANSLATION_KEYS.status),
      key: ColumnKey.status,
      renderCell: (data: ReservableStatusDropdownProps) => statusRenderer(data),
    },
    {
      header: t(TRANSLATION_KEYS.bookingDate),
      key: ColumnKey.bookingDate,
      renderCell: (bookingDate: string) => <div>{bookingDate}</div>,
    },
    {
      header: `${t(TRANSLATION_KEYS.bookingTime, {
        timeZone: getTimeZoneAbbreviation(channelTimeZone),
      })}`,
      key: ColumnKey.bookingTime,
      renderCell: (bookingTime: string) => <div>{bookingTime}</div>,
      disableSorting: true,
    },
    {
      header: t(TRANSLATION_KEYS.company),
      key: ColumnKey.company,
      disableSorting: true,
      renderCell: (companyDisplayName: string) => companyDisplayName,
    },
    {
      header: t(TRANSLATION_KEYS.reservedBy),
      key: ColumnKey.reservedBy,
      disableSorting: true,
      renderCell: (userName: string) => <div>{userName}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.primaryEmail),
      key: ColumnKey.primaryEmail,
      disableSorting: true,
      renderCell: (userEmail: string) => <div>{userEmail}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.adminNote),
      key: ColumnKey.adminNote,
      disableSorting: true,
      renderCell: (adminNote: string) => (
        <div style={noteStyles}>{adminNote}</div>
      ),
    },
    {
      header: t(TRANSLATION_KEYS.userNote),
      key: ColumnKey.userNote,
      disableSorting: true,
      renderCell: (userNote: string) => (
        <div style={noteStyles}>{userNote}</div>
      ),
    },
  ];

  const filteredColumns = columns.filter(column => {
    if (column.key === ColumnKey.userNote && !isUserNotesEnabled) {
      return false;
    }

    if (column.key === ColumnKey.adminNote && !isAdminNotesEnabled) {
      return false;
    }

    return true;
  });

  return { columns: filteredColumns };
}
