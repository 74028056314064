import * as yup from 'yup';

export default yup
  .string()
  .default(null)
  .nullable()
  .test(
    'is-url',
    'The Link Row must have a valid URL e.g. http://www.vts.com',
    (value: any) => {
      const url = new URL(String(value).valueOf());
      return url && !url.protocol.includes('javascript');
    }
  );
