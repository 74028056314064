import { DateTime } from 'luxon';

import snapToGrid from '../snapToGrid';

export default function getPositionFromTimeRange({
  optionWidth,
  startDate,
  endDate,
  slotSize,
  minWidth,
  maxWidth,
  minSlot = 0,
}: {
  optionWidth: number;
  startDate: DateTime;
  endDate: DateTime;
  slotSize: number;
  minWidth: number;
  maxWidth: number;
  minSlot: number;
}) {
  const startMinutes = startDate.hour * 60 + startDate.minute;
  const endMinutes = endDate.hour * 60 + endDate.minute;

  return {
    left: snapToGrid(
      minWidth,
      Math.max(
        0,
        (startMinutes / slotSize) * optionWidth - minSlot * optionWidth
      )
    ),
    width: snapToGrid(
      minWidth,
      Math.min(maxWidth, ((endMinutes - startMinutes) / slotSize) * optionWidth)
    ),
  };
}
