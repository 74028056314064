import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class GroupRoleType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'GroupRole';

  friendlyName = 'Team';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  superUserOnly = false;

  get example() {
    return {
      _id: '6TDGhwIIlpETMUPz8QL3eF',
    };
  }

  get default(): any {
    return {
      _id: null,
    };
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new GroupRoleType();

Types.addBaseType(definition);
export default definition;
