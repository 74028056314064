import { getDisplayName } from 'lane-shared/helpers';
import i18n from 'localization';
import { RouteComponentProps } from 'react-router-dom';

type DisplayProfileType = {
  name?: string;
  profile?: {
    name?: string | null;
  };
};

type RouteHistory = RouteComponentProps['history'];

function getWelcomeMessage(
  channel: DisplayProfileType | null | undefined,
  user: DisplayProfileType | null | undefined
): string {
  return i18n.t('Welcome to {{- displayNameChannel}}, {{- displayNameUser}}!', {
    displayNameChannel: getDisplayName(channel),
    displayNameUser: getDisplayName(user),
  });
}

export default function getHeaderMessage({
  user,
  channels = [],
  primaryChannel,
  history,
}: {
  user: DisplayProfileType | null | undefined;
  primaryChannel: (DisplayProfileType & { slug: string }) | null | undefined;
  channels: Array<DisplayProfileType & { slug: string }>;
  history: RouteHistory;
}): string {
  if (!history.location || !primaryChannel || !user || channels.length === 0) {
    return '';
  }

  const pathname: string = history.location?.pathname || '';
  const headerMessage: string =
    ((history as any).location?.state?.headerMessage as string | undefined) ||
    '';

  if (user && headerMessage) {
    return headerMessage;
  }

  if (pathname.includes('/l/channel/')) {
    const currentSlug = pathname.split('/')[3];
    const currentChannel = channels.find(c => c.slug === currentSlug);

    if (currentChannel) {
      return getWelcomeMessage(currentChannel, user);
    }
  }

  return getWelcomeMessage(primaryChannel, user);
}
