import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import {
  generateMenuFeatureQuote,
  getPaymentFeatureCurrency,
} from 'lane-shared/helpers/features';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import {
  MenuFeatureOrderItemType,
  MenuFeatureOrderType,
  MenuFeatureProperties,
} from 'lane-shared/types/features/MenuFeatureTypes';
import { PaymentFeatureProperties } from 'lane-shared/types/features/PaymentFeatureProperties';
import { PaymentCurrencyEnum } from 'lane-shared/types/payment/PaymentCurrencyEnum';

import useUserLocale from '../../../hooks/useUserLocale';
import Typography from '../../lds/Typography';
import MenuOrderCartItem from './MenuOrderCartItem';

import styles from './MenuOrderCart.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  menuFeature: MenuFeatureProperties;
  paymentFeature?: PaymentFeatureProperties;
  currency: PaymentCurrencyEnum;
  order: MenuFeatureOrderType;
  showSupport?: boolean;
  forReceipt?: boolean;
  onRemoveOrderItem: (orderItem: MenuFeatureOrderItemType) => void;
  onUpdateOrder: (props: any) => void;
  setInitialOrder: (props: any) => void;
};

export default function MenuOrderCart({
  className,
  style,
  title,
  menuFeature,
  paymentFeature,
  currency,
  order,
  showSupport = false,
  forReceipt = false,
  setInitialOrder,
  onUpdateOrder,
}: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const currencyFormat = currencyFormatter({
    locale,
    currency: currency || getPaymentFeatureCurrency(paymentFeature),
  });

  const percentageFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  const quote = generateMenuFeatureQuote({
    order,
    menuFeature,
    paymentFeature,
  });

  /* 
    If a menu cart contains more than one item - 
    the map will iterate through the array of menu 
    items until it finds the original item with a 
    matching id to that of the item. in this case, the
    item variable is the item to be updated after the 
    quantity input has changed.
  */

  function updateList({ quantity, orderItem }: any) {
    const items = order?.items
      .map(originalItem => {
        if (originalItem._id === orderItem._id) {
          if (
            (originalItem.quantity === 1 && quantity === 0) ||
            quantity === 0
          ) {
            return null;
          }
          originalItem.quantity = quantity;
          return originalItem;
        }
        return originalItem;
      })
      .filter(item => Boolean(item));
    setInitialOrder({
      ...order,
      items,
    });
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(MenuFeatureOrderItemType | null)[]' is not ... Remove this comment to see the full error message
    order.items = items;
    onUpdateOrder(order);
  }

  function renderItem(orderItem: any) {
    // get the original item from the menu
    const item = menuFeature.items.find(item => item._id === orderItem.itemId);

    return (
      <MenuOrderCartItem
        key={orderItem._id}
        orderItem={orderItem}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'MenuFeatureItemType | undefined' is not assi... Remove this comment to see the full error message
        item={item}
        forReceipt={forReceipt}
        currencyFormat={currencyFormat}
        onUpdateOrderItem={updateList}
      />
    );
  }

  return (
    <div className={cx(styles.menuOrderCart, className)} style={style}>
      {title && (
        <Typography className={cx(styles.title)} type="h4" mode="primary">
          {title}
        </Typography>
      )}
      <div className={styles.order}>
        {order?.items && order?.items?.length !== 0 ? (
          <>
            <Typography type="h5" mode="primary">
              {t('Order Summary')}
            </Typography>
            {order?.items?.map(renderItem)}
          </>
        ) : (
          <Typography type="h6" mode="primary">
            {t('No items to purchase')}
          </Typography>
        )}
      </div>

      <div className={cx(styles.totals)}>
        <Typography type="m" mode="primary">
          {t('Sub total')}
        </Typography>
        <Typography type="m" mode="primary" className={cx(styles.total)}>
          {currencyFormat(quote.total / CURRENCY_CONVERSION)}
        </Typography>
        {quote.taxes.map(tax => (
          <>
            <Typography type="m" mode="primary">
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              {tax.name} {percentageFormat(tax.rate)}%
            </Typography>
            <Typography type="m" mode="primary" className={cx(styles.total)}>
              {currencyFormat(tax.amount / CURRENCY_CONVERSION)}
            </Typography>
          </>
        ))}
      </div>
      <div className={cx(styles.totals, styles.finalTotal)}>
        <Typography type="h5" mode="primary">
          {t('Total')}
        </Typography>
        <Typography type="h5" mode="primary" className={cx(styles.total)}>
          {currencyFormat((quote.tax + quote.total) / CURRENCY_CONVERSION)}
        </Typography>
      </div>

      {showSupport && menuFeature.supportContent && (
        <div className={styles.support}>
          <h3 className={styles.supportText}>Need help?</h3>
          <Link
            to={routes.content.replace(
              ':id',
              convertTo62((menuFeature.supportContent as any)._id)
            )}
          >
            Contact Support
          </Link>
        </div>
      )}
    </div>
  );
}
