import { PlatformEnum } from '../../../types/PlatformEnum';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from '../../../types/integrations/IntegrationEnums';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';

const HOMDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.HOM,
  hasCustomUI: false,
  friendlyName: 'HOM',
  description: `Defines the properties required for a HOM integration.`,
  category: IntegrationCategoryEnum.Events,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'The base URL of the HOM API endpoint',
      type: 'Url',
      default: 'https://api.kadbu.com/developers/',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {
    xApiKey: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    client_secret: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    buildingId: {
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    accessToken: {
      type: 'String',
      hidden: true,
      editable: false,
      validators: [],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
  },
  interactionData: {},
  mobilePermissions: [],
};

export default HOMDefinition;
