import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { INTERACTION_OPEN_STATES } from 'lane-shared/helpers/constants/interactions';
import { constructWorkflow } from 'lane-shared/helpers/content';
import {
  ContentWorkflowType,
  DataValidationRuleType,
} from 'lane-shared/types/ContentWorkflowType';
import { Validator } from 'lane-shared/types/Validator';
import { PropertyType } from 'lane-shared/types/properties/Property';

type constructDefaultWorkflowToDataInputOptionProps = {
  user?: { _id: LaneType.UUID };
  content?: { _id: LaneType.UUID };
  property: PropertyType;
  option: LaneType.Option;
};
export default function constructDefaultWorkflowToDataInputOption({
  user,
  content,
  property,
  option,
}: constructDefaultWorkflowToDataInputOptionProps): ContentWorkflowType {
  const optionDataValidationSchema: DataValidationRuleType = {
    _id: uuid(),
    fieldId: property.name!,
    validator: { name: 'In', value: [option] } as Validator,
  };

  const defaultWorkflow: ContentWorkflowType = constructWorkflow({
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    userId: user?._id,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    contentId: content?._id,
    dataValidationSchema: [optionDataValidationSchema],
  });

  defaultWorkflow.inStatus = INTERACTION_OPEN_STATES;

  return defaultWorkflow;
}
