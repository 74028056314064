import { useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { interactionsOnSection } from '../graphql/section';
import {
  ALL_INTERACTIONS,
  INTERACTION_OPEN,
} from '../helpers/constants/interactions';
import * as PAGINATION from '../helpers/constants/pagination';
import { SortByEnum, SortDirectionEnum } from '../types/blocks/Sort';
import { useMultiLanguage } from './useMultiLanguage';

type Props = {
  interactionState: string;
  sectionId: LaneType.UUID;
  perPage: number;
  withPagination: boolean;
  autoHide: boolean;
  pollInterval: number;
  sortBy?: {
    key: SortByEnum;
    dir: SortDirectionEnum;
  };
};

const SortDirectionMap = {
  [SortDirectionEnum.Desc]: 'desc',
  [SortDirectionEnum.Asc]: 'asc',
};

export default function useInteractionsOnSectionQuery({
  interactionState,
  sectionId,
  perPage,
  withPagination,
  autoHide,
  pollInterval,
  sortBy,
}: Props) {
  const { translate } = useMultiLanguage();
  const search = {
    sortBy: {
      key: sortBy?.key || '_created',
      dir: sortBy?.dir
        ? SortDirectionMap[sortBy.dir]
        : SortDirectionMap[SortDirectionEnum.Desc],
    },
  };

  if (interactionState !== ALL_INTERACTIONS) {
    (search as any).state = {
      isOpen: interactionState === INTERACTION_OPEN,
      isActive: interactionState === INTERACTION_OPEN,
    };
  }

  const [page, setPage] = useState(0);
  const [
    fetchInteractions,
    { data, loading, error, fetchMore, called },
  ] = useLazyQuery(interactionsOnSection, {
    fetchPolicy: 'network-only',
    pollInterval,
  });

  useEffect(() => {
    // fetchInteractions hasn't been called yet, can't fetch additional pages
    if (!called) {
      return;
    }

    if (withPagination) {
      // bug in apollo-client see https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
      try {
        fetchMore({
          variables: {
            sectionId,
            pagination: {
              perPage: perPage || PAGINATION.perPage,
              start: page * perPage,
            },
            search,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            // Don't do anything if there weren't any new items
            if (!fetchMoreResult) {
              return prev;
            }

            return {
              // Concatenate the new feed results after the old ones
              me: {
                __typename: 'Me',
                contentInteractions: {
                  __typename: 'contentInteractions',
                  pageInfo: {
                    ...fetchMoreResult?.me?.contentInteractions?.pageInfo,
                    total: prev?.me?.contentInteractions?.pageInfo?.total,
                  },
                  items: [
                    ...(prev?.me?.contentInteractions?.items || []),
                    ...(fetchMoreResult?.me?.contentInteractions?.items || []),
                  ],
                },
              },
            };
          },
        });
      } catch (err) {
        // swallow error for now
      }
    }
  }, [page, search?.sortBy?.dir, search?.sortBy?.key]);

  useEffect(() => {
    if (!sectionId) {
      return;
    }

    // always run at least once, only run again on search parameter change when not paginating
    if (!called || !withPagination) {
      fetchInteractions({
        variables: {
          pagination: {
            perPage: perPage || PAGINATION.perPage,
            start: 0,
          },
          sectionId,
          search,
        },
      });
    }
  }, [sectionId, search?.sortBy?.dir, search?.sortBy?.key]);

  const items = (data?.me?.contentInteractions?.items || []).map(
    (item: any) => ({
      ...item,
      contentData: translate({
        model: item?.contentData,
        columns: ['name', 'description', 'subtitle'],
      }),
    })
  );
  const pageInfo = data?.me?.contentInteractions?.pageInfo || {
    total: 0,
    start: 0,
    perPage: 0,
  };

  // prevent some jank
  const fastIsHidden =
    (autoHide && !called) || (called && !loading && items?.length === 0);

  const [isHidden] = useDebounce(fastIsHidden, 100);

  return { items, pageInfo, loading, error, page, setPage, isHidden };
}
