import React from 'react';

import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import usePublicUserQuery from 'lane-shared/hooks/usePublicUserQuery';

type Props = {
  userId: string;
  channelSlug: string;
  className?: string;
};

export function ChannelAdminTeamMemberLink({
  userId,
  className,
  channelSlug,
}: Props) {
  const { user } = usePublicUserQuery({ userId });
  const url = routes.channelAdminTeamMember
    .replace(':id', channelSlug)
    .replace(':userId', userId);

  return (
    <Link className={className} to={url}>
      {user?.name ?? ''}
    </Link>
  );
}
