import { DateTime, Interval } from 'luxon';

import { LaneType } from 'common-types';
import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

import { IntervalRange } from './types';
import { useContentOperatingHours } from './useContentOperatingHours';
import { useReservableIntervals } from './useReservableIntervals';
import { useReservableUnavailableRanges } from './useReservableUnavailableRanges';

interface Props {
  contentId: LaneType.UUID;
  referenceDate: Date;
  intervalMinutes: number;
  userRoleBase62Ids: string[];
  timeAvailabilityFeature: TimeAvailabilityFeatureProperties | undefined;
  bufferTimeConfig: LaneType.BufferTime | undefined;
  reservableDateRange?: {
    minDate: Date | undefined;
    maxDate: Date | undefined;
  };
  timeZone?: string;
}

function useIntervalsWithInReservableDateRange({
  reservableDateRange,
  intervals,
}: {
  intervals: IntervalRange[];
  reservableDateRange: {
    minDate: Date;
    maxDate: Date;
  };
}) {
  const allowedRange = Interval.fromDateTimes(
    reservableDateRange.minDate,
    reservableDateRange.maxDate
  );

  const reservableIntervals = intervals.filter(time =>
    allowedRange.contains(DateTime.fromJSDate(time.startTime.date))
  );

  return reservableIntervals;
}

export function useReservableIntervalRanges({
  contentId,
  referenceDate,
  intervalMinutes,
  userRoleBase62Ids,
  timeAvailabilityFeature,
  bufferTimeConfig,
  timeZone,
  reservableDateRange,
}: Props) {
  const operatingHours = useContentOperatingHours({
    referenceDate,
    timeAvailability: timeAvailabilityFeature,
    userRoleBase62Ids,
    timeZone,
  });

  const { isLoading, unavailableDateRanges } = useReservableUnavailableRanges({
    contentId,
    referenceDate,
    operatingHours,
    bufferTimeConfig,
  });

  const { intervals: intervalsForOperatingHours } = useReservableIntervals({
    startDate: operatingHours?.startDate,
    endDate: operatingHours?.endDate,
    intervalMinutes,
    unavailableTimes: unavailableDateRanges,
  });

  const minDate = reservableDateRange?.minDate || referenceDate;
  const maxDate =
    reservableDateRange?.maxDate ||
    DateTime.fromJSDate(referenceDate).plus({ hour: 24 }).toJSDate();

  const intervals = useIntervalsWithInReservableDateRange({
    intervals: intervalsForOperatingHours,
    reservableDateRange: {
      minDate,
      maxDate,
    },
  });

  return {
    intervals,
    isLoading,
    operatingHours,
  };
}
