import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { AllTypeContexts } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

export class StringType extends StringTypeBase {
  contexts = AllTypeContexts;

  group = TYPE_GROUP_TEXT;

  name = 'String';

  friendlyName = 'Text';

  get example() {
    return 'Lorem ipsum';
  }

  get default(): any {
    return '';
  }

  buildSchema() {
    return yup.string().nullable();
  }
}

const definition = new StringType();

Types.addBaseType(definition);
export default definition;
