import mixpanelWeb from 'mixpanel-browser';

import { ApolloClient } from '@apollo/client';

import config from 'lane-shared/config';
import { AnalyticsHelper } from 'lane-shared/helpers';
import { ANALYTIC_PATH } from 'lane-shared/helpers/constants/analytics';
import {
  AnalyticsInterface,
  AnalyticNameType,
  AnalyticsDataType,
} from 'lane-shared/types/Analytics';

import history from './history';

let analytics: AnalyticsHelper;
let lastLocation: any;

export function analyticsHelper(client: ApolloClient<any>): AnalyticsInterface {
  if (!analytics) {
    analytics = new AnalyticsHelper({ client });
    mixpanelWeb.init(config.mixpanel.token);

    track(ANALYTIC_PATH, {
      path: history.location.pathname,
      category: 'enter',
      value: (history.location as any).query,
    });

    lastLocation = history.location;

    history.listen(location => {
      if (lastLocation) {
        track(ANALYTIC_PATH, {
          path: lastLocation.pathname,
          category: 'leave',
          value: lastLocation.query,
        });
      }

      track(ANALYTIC_PATH, {
        path: location.pathname,
        category: 'enter',
        value: (location as any).query,
      });

      lastLocation = location;
    });
  }

  function track(event: AnalyticNameType, data: AnalyticsDataType) {
    analytics.track(event, { ...data });
    mixpanelWeb.track(event, { ...data });
  }

  function trackMixpanel(event: AnalyticNameType, data: AnalyticsDataType) {
    mixpanelWeb.track(event, { ...data });
  }

  function setMixpanelSessionId(sessionId: string) {
    mixpanelWeb.register({
      sessionId,
    });
  }

  function setChannel(channelId: string, channelName: string) {
    mixpanelWeb.register({ channelId, channelName });
  }

  function identify(userId: string) {
    mixpanelWeb.identify(userId);
  }

  function update(props: any) {
    mixpanelWeb.people.set(props);
  }

  return {
    track,
    trackMixpanel,
    identify,
    update,
    setMixpanelSessionId,
    setChannel,
    onNavigationStateChange: () => {},
  };
}
