import React, { memo, useMemo, useState } from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';

import { objectToArray } from 'lane-shared/helpers';
import { useUserNotesEnabled } from 'lane-shared/hooks/useUserNotesEnabled';
import Features from 'lane-shared/renderers/v5/features';
import getFeatureEditableProperties from 'lane-shared/renderers/v5/getFeatureEditableProperties';
import { isReservableFeatureInstance } from 'lane-shared/type-guards/reservable';
import { ContentType } from 'lane-shared/types/content/Content';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { PropertiesInterfaceDependencies } from 'lane-shared/types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';

import { S } from 'components/typography';

import DataBlockMenuItem, { DragFuncParams } from './DataBlockMenuItem';

import styles from './DataBlockMenu.scss';

type Props = {
  content: ContentType;
  onNewBlockDragStart: (p: DragFuncParams) => void;
  onNewBlockDragEnd?: (p: DragFuncParams) => void;
  onNewBlockAdd?: (p: DragFuncParams) => void;
};

function DataBlockMenu({
  content,
  onNewBlockDragStart,
  onNewBlockAdd,
  onNewBlockDragEnd,
}: Props) {
  const [expanded, setExpanded] = useState(true);
  const dataDefinitions = objectToArray(content.data);
  const { channel } = useChannelAdminContext();
  const isUserNotesEnabled = useUserNotesEnabled();

  const { t } = useTranslation();

  const propertiesInterfaceDependencies = PropertiesInterfaceDependencies.fromJsonData(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PropertiesInterface<PropertyOpti... Remove this comment to see the full error message
    content.data,
    content.propertiesOptions?.dependencies || []
  );

  const inputs = useMemo(() => {
    return dataDefinitions.map(field => {
      if (
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        propertiesInterfaceDependencies.dependencies.has(field.name) &&
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        propertiesInterfaceDependencies.dependencies.get(field.name)?.rules
          ?.size > 0
      ) {
        return null;
      }

      return (
        <DataBlockMenuItem
          key={field.name}
          content={content}
          field={field}
          propertyDependencies={propertiesInterfaceDependencies}
          onNewBlockDragStart={onNewBlockDragStart}
          onNewBlockAdd={onNewBlockAdd}
          onNewBlockDragEnd={onNewBlockDragEnd}
        />
      );
    });
  }, [dataDefinitions, content]);

  const featureMenus = (content.features || []).map(feature => {
    const Feature = Features[feature.type];
    let properties = getFeatureEditableProperties(feature, true);

    if (!properties || properties.length === 0) {
      return null;
    }

    if (
      !channel?.settings.hasVisitorManagementEnabled &&
      feature.type === FeatureNameEnum.VisitorManagement
    ) {
      return null;
    }

    if (isReservableFeatureInstance(feature)) {
      properties = properties.filter(property => {
        if (property.name === 'userNotes') {
          return feature.feature?.userNotes && isUserNotesEnabled;
        }

        return true;
      });
    }

    return (
      <section key={feature.type} className={styles.featureBlockMenuItems}>
        <S mt={4} mb={1} variant="secondary">
          {t(Feature.friendlyName || Feature.name)}
        </S>
        {properties.map(field => (
          <DataBlockMenuItem
            key={field.name}
            content={content}
            field={field}
            propertyDependencies={propertiesInterfaceDependencies}
            feature={feature}
            onNewBlockDragStart={onNewBlockDragStart}
            onNewBlockAdd={onNewBlockAdd}
            onNewBlockDragEnd={onNewBlockDragEnd}
          />
        ))}
      </section>
    );
  });

  if (inputs.length === 0 && featureMenus.length === 0) {
    return null;
  }

  return (
    <menu className={styles.DataBlockMenu}>
      <div className={styles.topBar}>
        <button
          className={styles.collapse}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-controls="data-inputs"
        >
          <span style={{ visibility: 'hidden' }}>
            {t(
              'web.admin.channel.content.layout.editor.dataBlockMenu.collapse'
            )}
          </span>
        </button>
      </div>
      {expanded && (
        <div id="data-inputs">
          {dataDefinitions.length > 0 && (
            <section className={styles.dataBlockMenuItem}>
              <S mt={4} mb={1} variant="secondary">
                {t(
                  'web.admin.channel.content.layout.editor.dataBlockMenu.inputs'
                )}
              </S>

              {inputs}
            </section>
          )}
          {featureMenus}
        </div>
      )}
    </menu>
  );
}

export default memo(DataBlockMenu);
