import { useContext } from 'react';

import gql from 'graphql-tag';

import { ApolloError, useQuery } from '@apollo/client';

import { StudioGroupRoleNameEnum } from 'lane-shared/types/Essensys';

import { UserDataContext } from '../contexts';
import { POLL_INTERVAL } from '../helpers/constants';
import { CURRENCY_USD } from '../helpers/constants/currencyCodes';
import currencyFormatter from '../helpers/formatters/currencyFormatter';
import { SupportedLocaleEnum } from 'localization';
import useCompanyId from './useCompanyId';

const query = gql`
  query getEssensysAccountInfo($companyId: UUID) {
    essensys {
      account(companyId: $companyId) {
        _id
        name
        type
        location
        locationId
        accountsId
        salesTaxExempt
        balance
        clientTypeName
        credit
        creditsRemaining
        creditsUsed
        currencyCode
        printingBlackAndWhiteCredit
        printingColorCredit
        placepayaccountid
        shopperreference
      }
      contact(companyId: $companyId) {
        _id
        name
        accountId
        type
        location
        locationId
        printingPin
      }
    }
  }
`;

type Props = {
  pollInterval?: number;
  isEditMode?: boolean;
};

export default function useEssensysAccount({
  pollInterval = POLL_INTERVAL,
  isEditMode = false,
}: Props = {}): {
  balance: number;
  location: string;
  credit: number;
  creditsRemaining: number;
  creditsUsed: number;
  currencyCode: string;
  totalCredits: number;
  printingBlackAndWhiteCredit: number;
  printingColorCredit: number;
  printingPin: string;
  shopperreference: string;
  placepayaccountid: string;
  error: Error | ApolloError | null | undefined;
  loading: boolean;
  canViewCreditBlock: boolean;
  formatted: {
    balance: string;
    credit: string;
    creditsRemaining: string;
    creditsUsed: string;
    totalCredits: string;
    printingBlackAndWhiteCredit: string;
    printingColorCredit: string;
  };
  refetch: () => void;
} {
  const { user } = useContext(UserDataContext);
  const companyId = useCompanyId();
  const { data, error, loading, refetch } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    skip: isEditMode || !companyId,
    variables: {
      companyId,
    },
    pollInterval: !companyId ? 0 : pollInterval,
  });

  const currencyCode = data?.essensys?.account?.currencyCode || CURRENCY_USD;

  const currencyFormat = currencyFormatter({
    currency: currencyCode,
    locale: user?.locale,
  });

  const numberFormat = new Intl.NumberFormat(
    user?.locale || SupportedLocaleEnum.English,
    { maximumFractionDigits: 0 }
  ).format;

  const credit = data?.essensys?.account?.credit || 0;
  const creditsRemaining = data?.essensys?.account?.creditsRemaining || 0;
  const creditsUsed = data?.essensys?.account?.creditsUsed || 0;
  const printingBlackAndWhiteCredit =
    data?.essensys?.account?.printingBlackAndWhiteCredit || 0;
  const printingColorCredit = data?.essensys?.account?.printingColorCredit || 0;
  const balance = data?.essensys?.account?.balance || 0;
  const placepayaccountid = data?.essensys?.contact?.placepayaccountid || null;
  const shopperreference = data?.essensys?.contact?.shopperreference || null;
  const printingPin = data?.essensys?.contact?.printingPin || '';
  const location = data?.essensys?.account?.location || '';
  const totalCredits = creditsUsed + creditsRemaining;
  const creditTypeValue = (data?.essensys?.account?.clientTypeName ||
    StudioGroupRoleNameEnum.Invalid) as StudioGroupRoleNameEnum;
  const canViewCreditBlock = ![
    StudioGroupRoleNameEnum.Invalid,
    StudioGroupRoleNameEnum.External,
    StudioGroupRoleNameEnum.Tenant,
  ].includes(creditTypeValue);

  const formatted = {
    balance: currencyFormat(balance),
    credit: numberFormat(credit),
    creditsRemaining: numberFormat(creditsRemaining),
    creditsUsed: numberFormat(creditsUsed),
    totalCredits: numberFormat(totalCredits),
    printingBlackAndWhiteCredit: numberFormat(printingBlackAndWhiteCredit),
    printingColorCredit: numberFormat(printingColorCredit),
  };

  return {
    balance,
    location,
    credit,
    creditsRemaining,
    creditsUsed,
    currencyCode,
    totalCredits,
    printingBlackAndWhiteCredit,
    printingColorCredit,
    printingPin,
    shopperreference,
    placepayaccountid,
    error,
    loading,
    formatted,
    refetch,
    canViewCreditBlock,
  } as const;
}
