import React from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { EditorModeEnum } from 'lane-shared/types/EditorModeEnum';

import WhitelabelChannelEdit from 'components/lane/WhitelabelChannelEdit';

const query = gql`
  query whitelabels($channelId: UUID!) {
    channel(_id: $channelId) {
      _id
      whitelabels {
        _id
        whitelabel {
          _id
        }
      }
    }
  }
`;

export default function ChannelWhitelabelSettings({
  channel,
  validation,
}: any) {
  const { data } = useQuery(query, {
    skip: !channel?._id,
    variables: {
      channelId: channel?._id,
    },
  });

  const whitelabels = data?.channel?.whitelabels || [];

  return whitelabels.map((whitelabelChannel: any) => (
    <WhitelabelChannelEdit
      key={whitelabelChannel._id}
      mode={EditorModeEnum.Whitelabel}
      onWhitelabelChannelRemoved={() => null}
      onWhitelabelChannelUpdated={() => null}
      whitelabelChannel={whitelabelChannel}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: any; mode: EditorModeEnum.Whitelabel;... Remove this comment to see the full error message
      validation={validation}
    />
  ));
}
