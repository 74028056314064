import React from 'react';

import cx from 'classnames';

import styles from './ColorTypeValue.scss';

function ColorTypeValue({ className, style, value }: any) {
  return (
    <span
      className={cx(styles.ColorTypeValue, className)}
      style={{
        ...style,
        backgroundColor: value,
      }}
    >
      {value}
    </span>
  );
}

export default ColorTypeValue;
