import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import Types from 'lane-shared/properties/Types';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { H5, M } from 'components/typography';

import IconButton from '../../../general/IconButton';

import styles from './FieldButton.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  onDelete?: (property: PropertyType) => void;
  field: PropertyType;
};

export default function FieldButton({
  className,
  style,
  onClick = () => null,
  onDelete,
  field,
}: Props) {
  const type = Types.getType(field.type);
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.FieldButton, className)}
      style={style}
      role="button"
      tabIndex={0}
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      <div className={styles.info}>
        <H5>{t(field.friendlyName || field.name || '')}</H5>
        <M>{t(type.friendlyName || type.name)}</M>
      </div>
      {onDelete && (
        <IconButton
          className={styles.removeButton}
          icon="times-circle"
          iconSet={ICON_SET_FONTAWESOME}
          onClick={e => {
            onDelete(field);
            e.stopPropagation();
          }}
        />
      )}
    </div>
  );
}
