import React from 'react';

import { useTranslation } from 'react-i18next';

import CardContainer from 'components/cards/CardContainer';
import { Button } from 'components/general';
import { P } from 'components/typography';

import styles from './BuildingIntegrationFailure.scss';

export function BuildingIntegrationFailure({
  setBuildingIntegrationError,
}: {
  setBuildingIntegrationError: Function;
}) {
  const { t } = useTranslation();

  return (
    <CardContainer size="large" className={styles.container}>
      <P className={styles.errorMessage}>
        {t('shared.integrations.bePrism.BuildingIntegrationFailed')}
      </P>
      <div className={styles.tryAgainButtonContainer}>
        <Button
          className={styles.tryAgainButton}
          onClick={() => {
            setBuildingIntegrationError(false);
          }}
        >
          {' '}
          {t('shared.integrations.bePrism.BuildingIntegrationFailed.TryAgain')}
        </Button>
      </div>
    </CardContainer>
  );
}
