import React, { useContext } from 'react';

import cx from 'classnames';

import { WHITELABEL_LANE } from 'lane-shared/config/whitelabels';
import { ThemeContext } from 'lane-shared/contexts';
import { imageUrl } from 'lane-shared/helpers/formatters';

import ActivateLogo from '../activate/ActivateLogo';

import styles from './WhitelabeledLogo.scss';

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export default function WhitelabeledLogo({
  className,
  color,
  size = 87,
}: Props) {
  const theme = useContext(ThemeContext);

  return theme.instance !== WHITELABEL_LANE ? (
    <div
      className={cx(styles.whitelabel, className)}
      style={{
        width: size,
        minHeight: size,
        backgroundImage: `url(${imageUrl(theme.logo)})`,
      }}
    />
  ) : (
    <ActivateLogo color={color} />
  );
}
