import React from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { nameFormatter } from 'lane-shared/helpers/formatters/nameFormatter';

import { S } from 'components/typography';

import Thumbnail from '../general/Thumbnail';

import styles from './UserThumbnail.scss';

export interface UserThumbnailBaseProps {
  className?: string;
  style?: React.CSSProperties;
  labelPosition?: 'right' | 'bottom';
  splitName?: boolean;
  onClick?: (e: any) => void;
}

interface UserThumbnailProps extends UserThumbnailBaseProps {
  user: any;
}

export default function UserThumbnail({
  className,
  labelPosition = 'bottom',
  user,
  splitName = true,
  onClick = () => {},
  style,
}: UserThumbnailProps) {
  const nameLabel = getDisplayName(user);
  const name = splitName ? (
    nameFormatter(user)
      .split('\n')
      .map(el => (
        <span key={el} className={styles.name}>
          {el}
        </span>
      ))
  ) : (
    <span key={getDisplayName(user)} className={styles.name}>
      {getDisplayName(user)}
    </span>
  );
  return (
    <div
      className={cx(styles.UserThumbnail, className)}
      data-label-positon={labelPosition}
      style={style}
      role="button"
      tabIndex={0}
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      <Thumbnail
        className={styles.imageWrapper}
        src={imageUrl(user?.profile?.image) || imageUrl(user?.profile?.logo)}
        name={nameLabel}
      />
      <div className={styles.textWrapper}>
        <S>{name}</S>
      </div>
    </div>
  );
}
