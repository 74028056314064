import React, { Component } from 'react';

/**
 * Component that catches Javascript errors that are thrown in the
 * child component tree. Renders a stack strace in development or
 * a more user-friendly page for end users.
 */

type ErrorComponentProps = {
  error: any;
};

type Props = {
  children: React.ReactNode;
  ErrorComponent: React.FunctionComponent<ErrorComponentProps>;
};

type State = {
  error: Error | null;
  errorInfo: any | null;
};

export default class ComponentErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { ErrorComponent, children } = this.props;
    const { error, errorInfo } = this.state;

    if (error && ErrorComponent) {
      // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'ErrorComponent' does not have an... Remove this comment to see the full error message
      return <ErrorComponent error={error} errorInfo={errorInfo} />;
    }

    if (error) {
      return (
        <div>
          <h1>Component failed</h1>
          <p>{error.message}</p>
        </div>
      );
    }

    return children;
  }
}
