import * as yup from 'yup';

import i18n from 'localization';

export default yup.object().shape({
  __typename: yup.string().strip(true),
  title: yup
    .string()
    .trim()
    .nullable()
    .min(3, i18n.t('Notification message must be at least 3 characters.'))
    .max(255)
    .required(i18n.t('This field is required.')),
  content: yup.object().shape({
    __typename: yup.string().strip(true),
    _id: yup.string().required(),
  }),
  sendAt: yup.date().nullable().required(i18n.t('This field is required.')),
  _new: yup.bool(),
});
