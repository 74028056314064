import React from 'react';

import cx from 'classnames';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'rheo... Remove this comment to see the full error message
import Rheostat from 'rheostat';

import 'rheostat/initialize';

import styles from './MultiSlider.scss';

type OwnProps = {
  onChange?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
  onSliderDragEnd?: (...args: any[]) => any;
  onSliderDragMove?: (...args: any[]) => any;
  onSliderDragStart?: (...args: any[]) => any;
  onValuesUpdated?: (...args: any[]) => any;
  formatLabel?: (...args: any[]) => any;
  getNextHandlePosition?: (...args: any[]) => any;
  pitComponent?: ((...args: any[]) => any) | string;
  pitPoints?: number[];
  values: number[];
  snap?: boolean;
  snapPoints?: number[];
  disabled?: boolean;
  max?: number;
  min?: number;
  className?: string;
};

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof MultiSlider.defaultProps;

function MultiSlider({
  onChange,
  onClick,
  onKeyPress,
  onSliderDragEnd,
  onSliderDragMove,
  onSliderDragStart,
  onValuesUpdated,
  formatLabel,
  getNextHandlePosition,
  pitComponent,
  pitPoints,
  snap,
  snapPoints,
  disabled,
  values,
  max,
  min,
  className,
}: Props) {
  return (
    <div className={cx(styles.MultiSlider, className)}>
      <div className={styles.slider}>
        <Rheostat
          onChange={onChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onSliderDragEnd={onSliderDragEnd}
          onSliderDragMove={onSliderDragMove}
          onSliderDragStart={onSliderDragStart}
          onValuesUpdated={onValuesUpdated}
          getNextHandlePosition={getNextHandlePosition}
          max={max}
          min={min}
          pitComponent={pitComponent}
          pitPoints={pitPoints}
          snap={snap}
          snapPoints={snapPoints}
          disabled={disabled}
          values={values}
        />
      </div>
      <div className={styles.values}>
        {/* @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable. */}
        <h2>{formatLabel(values[0])}</h2>
        <hr />
        {/* @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable. */}
        <h2>{formatLabel(values[1])}</h2>
      </div>
    </div>
  );
}

MultiSlider.defaultProps = {
  onChange: undefined,
  onClick: undefined,
  onKeyPress: undefined,
  onSliderDragEnd: undefined,
  onSliderDragMove: undefined,
  onSliderDragStart: undefined,
  onValuesUpdated: undefined,
  getNextHandlePosition: undefined,
  formatLabel: (value: any) => value,
  pitComponent: undefined,
  pitPoints: undefined,
  snap: true,
  snapPoints: undefined,
  disabled: false,
  max: 100,
  min: 0,
  className: null,
};

export default MultiSlider;
