import { LaneType } from 'common-types';

export enum AttachmentTypeEnum {
  Image = 'Image',
  Document = 'Document',
}

export enum AttachmentImageContentTypeEnum {
  png = 'image/png',
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
  gif = 'image/gif',
}

export enum AttachmentDocumentContentTypeEnum {
  pdf = 'application/pdf',
  csv = 'text/csv',
}

export type Attachment = {
  id: LaneType.UUID;
  entityId: LaneType.UUID;
  entityType: EntityTypeEnum;
  file: LaneType.JSON;
  thumbnail: LaneType.JSON;
  created: Date;
  deletedAt: Date | null;
  s3Bucket: string;
};

// Corresponds to packages/lane-shared/graphql/attachment/createAttachment.ts
export type AttachmentResponse = Attachment & {
  fileSignedUrl: string;
  thumbnailSignedUrl: string | null;
};

// This object refers to draft attachments when default OS selector select the files
export type AttachmentPreview = {
  entityId?: string;
  id: LaneType.UUID;
  fileUrl: string;
  file: LaneType.JSON;
};

export enum EntityTypeEnum {
  Content = 'ContentAttachment',
  Static = 'ContentAttachment',
  Perk = 'ContentAttachment',
  Promotion = 'ContentAttachment',
  WorkOrder = 'WorkOrderAttachment',
  Equipment = 'EquipmentAttachment',
  PMSchedule = 'PMScheduleAttachment',
  PMTask = 'PMTaskAttachment',
  ImporterAttachment = 'ImporterAttachment',
}

export enum AttachmentVariantEnum {
  Receipt = 'receipt',
  WorkOrder = 'workorder',
}

export type AttachmentEntityInput = {
  type: EntityTypeEnum;
  id: LaneType.UUID;
};

export type AttachmentFilePayload = {
  name: string;
  size: number;
  type: string;
};

export type AttachmentThumbnailPayload = {
  name: string;
  size: number;
  width: number;
  height: number;
  type: string;
};

export type AttachmentDataForUpload = {
  attachment: AttachmentFilePayload;
  attachmentBlob: Blob;
  thumbnail?: AttachmentThumbnailPayload;
  thumbnailBlob?: Blob;
  s3Bucket: string;
};

export type AttachmentPreviewMobile = {
  entityId?: string;
  created?: Date; // to determine if its exists in db
  id: LaneType.UUID;
  fileUrl: string;
  // file node adapted from DocumentPickerResponse in lane-mobile
  file: {
    uri: string;
    name: string;
    copyError?: string;
    fileCopyUri: string | null;
    type: string | null;
    size: number | null;
    height?: number;
    width?: number;
    thumbnailUri?: string | null;
  };
};

export type AttachmentField = {
  customGeneratedId: string;
  attachments?: Array<JSON>;
};
