import {
  useMutation,
  isApolloError,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';
import { unitErrorCodeTypes } from 'activate-errors';
import { deleteUserGroupRole } from 'lane-shared/graphql/mutation';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';
import { useTranslation } from 'react-i18next';
import { emitMemberDeletedFromGroupRole } from 'lane-shared/helpers/analytics/emitGroupRole';
import { AnalyticsContext } from 'lane-shared/contexts';
import { useContext } from 'react';

type handleRemoveUserFromPermissionGroupArgs = {
  userGroupRoleId: string;
  permissionGroupName: string;
  permissionGroupId: string;
  userName: string;
  userId: string;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

export function useRemoveUserFromPermissionGroup() {
  const { t } = useTranslation();
  const [deleteUserGroupRoleMutation] = useMutation(deleteUserGroupRole);
  const analytics = useContext(AnalyticsContext);

  const handleRemoveUserFromPermissionGroup = async ({
    userGroupRoleId,
    permissionGroupId,
    permissionGroupName,
    userName,
    refetch,
    userId,
  }: handleRemoveUserFromPermissionGroupArgs) => {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeUser.prompt.title',
          {
            userName,
            userGroup: permissionGroupName,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeUser.prompt.message',
          {
            userName,
            userGroup: permissionGroupName,
          }
        ),
        confirmText: t(
          'web.admin.channel.permissionGroups.detail.modal.cta.removeUser'
        ),
        cancelText: t(
          'web.admin.channel.permissionGroups.detail.modal.cta.returnToPage'
        ),
      });

      await deleteUserGroupRoleMutation({
        variables: { id: userGroupRoleId },
      });

      emitMemberDeletedFromGroupRole({
        userId,
        groupRoleId: permissionGroupId,
        groupRoleName: permissionGroupName,
        analytics,
      });

      await refetch();

      window.Toast.show(
        t('web.admin.channel.permissionGroups.detail.modal.successToast')
      );
    } catch (err) {
      if (isApolloError(err)) {
        const codes = getErrorCodes(err);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          unitErrorCodeTypes,
          t('web.admin.channel.permissionGroups.detail.modal.errorToast')
        );

        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }
    }
  };

  return {
    handleRemoveUserFromPermissionGroup,
  } as const;
}
