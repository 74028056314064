import { Primitive } from '../../../../types/blocks/Primitive';

/**
 * @deprecated use PropertyInputBlock instead
 */
const ImageInputBlock: Primitive = {
  name: 'ImageInputBlock',
  isInput: true,
  isPrimitive: true,
  properties: {
    image: {
      type: 'Image',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    input: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ImageInputBlock;
