import { MergeMethodEnum } from './MergeMethodEnum';

export const allFields = {
  name: true,
  description: true,
  subtitle: true,
  tags: true,
  category: true,
  logo: true,
  color: true,
  backgroundColor: true,
  backgroundImage: true,
  icon: true,
  liveDate: true,
  startDate: true,
  endDate: true,
};

export const ignoreFields = {
  _id: true,
  _created: true,
  _updated: true,
  _createdBy: true,
  _updatedBy: true,
  externalKey: true,
  slug: true,
  isPublished: true,
  isInteractive: true,
  isSyndicateable: true,
  lastReset: true,
  nextReset: true,
  version: true,
  type: true,
  state: true,
  channel: true,
};

export const mergeTypeButtons = [
  {
    value: MergeMethodEnum.Copy,
    name: 'Copy',
  },
  {
    value: MergeMethodEnum.Merge,
    name: 'Merge',
  },
  {
    value: MergeMethodEnum.Ignore,
    name: 'Ignore',
  },
];
