import { SHORT_MONTH } from '../constants/dates';
import dateFormatter from '../formatters/dateFormatter';

type MonthItem = {
  label: string;
  value: number;
};

const monthItems: MonthItem[] = [];

for (let i = 0; i < 12; i++) {
  monthItems.push({
    label: dateFormatter(new Date(2019, i, 1), SHORT_MONTH),
    value: i + 1,
  });
}

export default monthItems;
