export default function currencyFormatter({
  currency = 'USD',
  locale = 'en-US',
  minimumIntegerDigits,
  minimumFractionDigits,
  maximumFractionDigits,
  minimumSignificantDigits,
  maximumSignificantDigits,
  currencyDisplay = 'symbol',
  style = 'currency',
  useGrouping = true,
}: {
  currency?: string;
  locale?: string;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name' | 'none';
  style?: 'decimal' | 'currency' | 'percent' | 'unit';
  useGrouping?: boolean;
}): (value: number) => string {
  // The getUserLocale helper return the locale as 'en_US' instead of en-US.
  const _locale = locale.replace('_', '-');

  const numberFormat = new Intl.NumberFormat(
    _locale,
    currency
      ? {
          currency,
          style,
          currencyDisplay:
            currencyDisplay === 'none' ? 'symbol' : currencyDisplay,
          minimumIntegerDigits,
          minimumFractionDigits,
          maximumFractionDigits,
          minimumSignificantDigits,
          maximumSignificantDigits,
          useGrouping,
        }
      : undefined
  );

  if (currencyDisplay === 'none') {
    return (value: number) => {
      return value.toLocaleString(locale, {
        ...numberFormat.resolvedOptions(),
        style: 'decimal',
      });
    };
  }

  return numberFormat.format;
}
