import secureUserGroupRoleUpdate from './secureUserGroupRoleUpdate';

const securedKeys = [
  '_created',
  '_updated',
  '_updatedBy',
  '_createdBy',
  'guid',
  'isSuperUser',
  'status',
  'expires',
  'lastSeen',
  'lastLogin',
  'lastTimeZone',
  'lastGeo',
  'analytics',
  'whitelabel',
  'info',
  'privateKey',
  'publicKey',
];

/**
 * takes in an update from the client side for a user trying to update thier
 * own user account, and removes anything they should not be allowed to
 * update themselves.
 */
export default function secureUserUpdate(update: any): any {
  // clone the object to not modify the original.

  const ret = {
    ...update,
  };

  if (update?.roles?.length > 0) {
    ret.roles = update.roles.map(secureUserGroupRoleUpdate);
  }

  securedKeys.forEach(key => delete ret[key]);

  return ret;
}
