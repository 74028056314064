import gql from 'graphql-tag';

import {
  useMutation,
  MutationFunctionOptions,
  FetchResult,
} from '@apollo/client';

import { UserLoginProviderEnum } from '../types/UserLogin';

type SignInResponse = { signIn: { jti: string; token: string } };

type SignInVariables = {
  loginKey: string;
  password: string;
  loginProvider: UserLoginProviderEnum;
};

type SignInOAuth = {
  signInOAuth: (
    options?:
      | MutationFunctionOptions<SignInResponse, SignInVariables>
      | undefined
  ) => Promise<FetchResult<SignInResponse>>;
};

export default function useSignInOAuth(): SignInOAuth {
  const [signIn] = useMutation<SignInResponse, SignInVariables>(
    gql`
      mutation SignInForOAuth(
        $loginKey: ID!
        $password: String
        $loginProvider: UserLoginProvider
      ) {
        signIn(
          loginKey: $loginKey
          password: $password
          loginType: OAuth
          loginProvider: $loginProvider
        ) {
          jti
          token
        }
      }
    `
  );

  return {
    signInOAuth: signIn,
  };
}
