import React from 'react';

import { Radio } from 'components';
import { useTranslation } from 'react-i18next';

import styles from '../addItemsToChargeStyles.scss';
import { ProductType } from 'graphql-query-contracts';

const requiredFieldAsteriskSymbol = '*';

export function AddProductRadioGroup({
  productType,
  updateProductType,
}: {
  productType: ProductType;
  updateProductType: (value: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <label className={styles.descriptionLabel}>
        {t('abp.productsServices.createProduct.typeLabel')}{' '}
        <span className={styles.InputLabelRequiredAstrisk}>
          {requiredFieldAsteriskSymbol}
        </span>
      </label>
      <Radio
        dataCy="materialRadioButton"
        text="abp.productsServices.createProduct.type.material"
        value={ProductType.ProductTypeServiceMaterials}
        selected={productType}
        onChange={updateProductType}
      />
      <Radio
        dataCy="laborRadioButton"
        text="abp.productsServices.createProduct.type.labor"
        value={ProductType.ProductTypeServiceLabour}
        selected={productType}
        onChange={updateProductType}
      />
    </div>
  );
}
