/* eslint-disable */
import React from 'react';

import cx from 'classnames';

import getBlockStyle from '../helpers/getBlockStyle';
import hasLink from '../helpers/hasLink';
import onBlockClick from '../helpers/onBlockClick';
import ImageBlock from './ImageBlock';
import InputBlock from './InputBlock';
import TextBlock from './TextBlock';
import YoutubeBlock from './YoutubeBlock';

import styles from './styles.scss';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */
export default function LayoutBlock({ block, parent }: any) {
  const style = getBlockStyle(block, parent);
  const doesHaveLink = hasLink(block);

  const renderBlock = (b: any) => {
    // Make sure the keys are super unique.
    const key = block._mg + b._mg;

    switch (b._type) {
      case 'LayoutBlock':
        return <LayoutBlock key={key} parent={block} block={b} />;
      case 'ImageBlock':
        return <ImageBlock key={key} parent={block} block={b} />;
      case 'YoutubeBlock':
        return <YoutubeBlock key={key} parent={block} block={b} />;
      case 'TextBlock':
        return <TextBlock key={key} parent={block} block={b} />;
      case 'InputBlock':
        return <InputBlock key={key} parent={block} block={b} />;
      default:
        return (
          <div key={block._mg}>// TODO: {b._type} not implemented yet.</div>
        );
    }
  };

  return (
    <div
      className={cx(styles.buildBlock, { [styles.hasLink]: doesHaveLink })}
      style={style}
      onClick={e => onBlockClick(e, block)}
    >
      {block.blocks ? block.blocks.map(renderBlock) : null}
    </div>
  );
}
