import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().min(3).trim().required(),
  units: yup.number().min(1).required(),
  unitType: yup.string().required(),
  pricePerUnit: yup.number().required(),
  currency: yup.string().required(),
  estimatedSubscriptionStartDate: yup.date().required(),
  subscriptionStartDate: yup.date().notRequired().nullable(),
});
