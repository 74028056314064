import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { NewProductDetails } from 'lane-shared/domains/billingPayments/types';
import {
  CreateProductRequest,
  CreateProductResponse,
  GroupType,
  SellingPriceInput,
} from 'graphql-query-contracts';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers/priceInputToComponents';

export async function createProductMutation(
  newProductDetails: NewProductDetails,
  sellingPrice: SellingPriceInput,
  channelId: string
) {
  const {
    name,
    description,
    productCategoryId,
    chargeCodeId,
    creditAmount,
  } = newProductDetails;

  const createProductMutation = gql`
    mutation createProduct($createProductRequest: CreateProductRequest!) {
      createProduct(createProductRequest: $createProductRequest) {
        id
      }
    }
  `;

  const { amount, tax, markup } = priceInputToComponents(newProductDetails);
  return await getClient().mutate<
    { createProduct: CreateProductResponse },
    { createProductRequest: CreateProductRequest }
  >({
    mutation: createProductMutation,
    variables: {
      createProductRequest: {
        name,
        description,
        groupId: convertToUUID(channelId),
        groupType: GroupType.GroupTypeActivateChannel,
        productCategoryId,
        chargeCodeId,
        amount,
        tax,
        markup,
        creditAmount: creditAmount ? parseFloat(creditAmount) : undefined,
        sellingPrice,
      },
    },
  });
}
