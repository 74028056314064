import React from 'react';

import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { sortByCreatedDescending } from 'lane-shared/helpers/sort';

import RequestCard from './RequestCard';
import StatusIndicator from './StatusIndicator';

import styles from './WorkOrderList.scss';

function WorkOrderList({ interactions, sectionTitle }: any) {
  if (!interactions || interactions.length === 0) {
    return null;
  }

  interactions = interactions.sort(sortByCreatedDescending);

  return (
    <div className={styles.container}>
      {!!sectionTitle && (
        <div className={styles.titleContainer}>
          <StatusIndicator status={sectionTitle} />
          <h2>{sectionTitle}</h2>
        </div>
      )}
      <div className={styles.requestContainer}>
        {interactions.map((interaction: any) => (
          <div className={styles.requestWrapper} key={interaction._id}>
            <Link
              to={{
                pathname: routes.interaction.replace(':id', interaction._id),
              }}
            >
              <RequestCard key={interaction._id} interaction={interaction} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorkOrderList;
