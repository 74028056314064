import React, { useMemo } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import toSchema from 'lane-shared/helpers/toSchema';
import { QRCodeCheckinFeatureRuleType } from 'lane-shared/types/features/QRCodeCheckinFeatureProperties';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';

import Dropdown from 'components/form/Dropdown';
import { H5 } from 'components/typography';

import styles from './QRCodeCheckinRuleEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  rule: QRCodeCheckinFeatureRuleType;
  statusesFeature: StatusesFeatureProperties;
  onRuleUpdated: (update: Partial<QRCodeCheckinFeatureRuleType>) => void;
  onRuleDeleted?: (rule: Partial<QRCodeCheckinFeatureRuleType>) => void;
};

const TRANSLATION_KEYS = {
  toStatus: 'web.content.features.qrCodeScanning.toStatus',
  fromStatus: 'web.content.features.qrCodeScanning.fromStatus',
};

export default function QRCodeCheckinRuleEdit({
  className,
  style,
  rule,
  statusesFeature,
  onRuleUpdated,
  onRuleDeleted,
}: Props) {
  const { t } = useTranslation();

  function fromStatusHandler(fromStatus: string) {
    onRuleUpdated({
      _id: rule._id,
      toStatus: undefined,
      fromStatus,
    });
  }

  function toStatusHandler(toStatus: string) {
    onRuleUpdated({
      _id: rule._id,
      toStatus,
    });
  }

  const fromItems = useMemo(
    () =>
      statusesFeature.rules
        .filter(({ nextStatuses }) => nextStatuses?.length > 0)
        .map(rule => toSchema(rule.status)),
    [statusesFeature]
  );

  const toItems = useMemo(() => {
    const applicableRules = statusesFeature.rules.filter(
      ({ status }) => status === rule.fromStatus
    );

    const statuses = applicableRules.reduce(
      (arr, rule) => Array.from(new Set([...arr, ...rule.nextStatuses])),
      [] as string[]
    );

    return statuses.map(toSchema);
  }, [statusesFeature, rule?.fromStatus]);

  return (
    <li className={cx(styles.QRCodeCheckinRuleEdit, className)} style={style}>
      <div className={styles.group}>
        <H5 mb={2}>{t(TRANSLATION_KEYS.fromStatus)}</H5>
        <Dropdown
          className={styles.dropDown}
          onValueChange={fromStatusHandler}
          value={rule.fromStatus}
          items={fromItems}
        />
      </div>

      {rule.fromStatus && (
        <div className={styles.group}>
          <H5 mt={4} mb={2}>
            {t(TRANSLATION_KEYS.toStatus)}
          </H5>
          <div className={styles.row}>
            <Dropdown
              className={styles.dropDown}
              onValueChange={toStatusHandler}
              value={rule.toStatus}
              items={toItems}
            />
          </div>
        </div>
      )}
      {onRuleDeleted && (
        <Icon
          name="times-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.removeIcon}
          onClick={() => onRuleDeleted(rule)}
        />
      )}
    </li>
  );
}
