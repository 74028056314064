import React, { useRef, useState } from 'react';

import cx from 'classnames';
import { useRippleEffect } from 'hooks';

import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { imageUrl } from 'lane-shared/helpers/formatters';
import { ContentForCards } from 'lane-shared/types/content/ContentForCards';

import { S, XS } from 'components/typography';

import styles from './MiniContentCard.scss';

type Props = {
  content: Pick<
    ContentForCards,
    'subtitle' | 'backgroundImage' | 'backgroundColor' | 'name'
  >;
  className?: string;
  style?: React.CSSProperties;
  isSelected?: boolean;
};

export default function MiniContentCard({
  content,
  className,
  style,
  isSelected,
}: Props) {
  const [isHover, setIsHover] = useState(false);
  const backgroundImages = [
    'linear-gradient(to bottom, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.85) 100%)',
  ];

  const containerRef = useRef<HTMLDivElement | null>(null);
  const rippleContainerRef = useRef<HTMLSpanElement | null>(null);
  const isSubtitleExist = content?.subtitle?.trim();

  useRippleEffect(containerRef, rippleContainerRef);

  return (
    <div
      data-cy="miniContentCard"
      className={cx(styles.MiniContentCard, className)}
      style={style}
      ref={containerRef}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      data-is-selected={isSelected}
    >
      <span ref={rippleContainerRef} className={styles.RippleContainer} />
      <div
        data-cy="miniContentCardOverlay"
        className={styles.contentCardOverlay}
        data-is-selected-or-hover={isSelected || isHover}
      >
        <div
          className={styles.hoverCircle}
          data-is-hover={isHover}
          data-is-selected={isSelected}
          data-cy="miniContentCardCheckmark"
        >
          {isSelected && (
            <FontAwesomeIcon icon={faCheck} className={styles.checkIcon} />
          )}
        </div>
      </div>
      <div>
        {content?.backgroundImage ? (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${imageUrl(content?.backgroundImage)})`,
            }}
          />
        ) : (
          <div
            className={styles.image}
            style={{
              ...style,
              backgroundImage: backgroundImages.join(', '),
              backgroundColor: content?.backgroundColor,
              color: '#fff',
            }}
          />
        )}
        <div
          className={styles.detailsWrapper}
          data-line-clamp={isSubtitleExist ? 2 : 3}
        >
          {isSubtitleExist && (
            <div className={styles.subtitle}>
              <XS>{content?.subtitle}</XS>
            </div>
          )}
          <div className={styles.details}>
            <S>{content?.name}</S>
          </div>
        </div>
      </div>
    </div>
  );
}
