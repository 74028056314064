import * as yup from 'yup';

import parseDate from '../../helpers/dates/parseDate';
import dateFormatter from '../../helpers/formatters/dateFormatter';
import { DateTypeBase } from '../../types/baseTypes/DateTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TIME } from '../constants';

export class DateTimeType extends DateTypeBase {
  group = TYPE_GROUP_TIME;

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
    TypeContextEnum.Metatag,
  ];

  name = 'DateTime';

  friendlyName = 'Date & Time';

  formatter = (date: any, opts: any) =>
    dateFormatter(date, 'yyyy-MM-dd h:mm a', opts?.timeZone);

  get example() {
    return new Date();
  }

  get default(): any {
    return new Date();
  }

  buildSchema() {
    return yup.date().nullable();
  }

  parseValue = (value: any) => parseDate(value);
}

const definition = new DateTimeType();

Types.addBaseType(definition);
export default definition;
