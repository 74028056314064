import React from 'react';

import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';
import { ContentType } from 'lane-shared/types/content/Content';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';

import DraftContentTargetingPlacements from './components/DraftContentTargetingPlacements';

import styles from './DraftContentTargetingEdit.scss';
import type { StepperTrackerType } from 'lane-shared/hooks/analytics/useDraftContentAnalytics';

type Props = {
  user: UserType;
  channel: ChannelType;
  content: ContentType;
  liveContent?: ContentType;
  hasChanged: boolean;
  onContentUpdated: (
    content: Partial<DraftContentType>
  ) => Partial<DraftContentType>;
  stepperTracker?: StepperTrackerType;
};

export default function DraftContentTargetingEdit({
  user,
  hasChanged,
  channel,
  content,
  liveContent,
  onContentUpdated,
  stepperTracker,
}: Props) {
  const channelTimeZone = getTimeZoneByGeoLocation({
    longitude: channel?.address?.geo?.[0],
    latitude: channel?.address?.geo?.[1],
  });

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const availableTimeZones = [channelTimeZone];

  if (channelTimeZone !== userTimeZone) {
    availableTimeZones.push(userTimeZone);
  }

  return (
    <div className={styles.DraftContentTargetingEdit}>
      <DraftContentTargetingPlacements
        user={user}
        hasChanged={hasChanged}
        channel={channel}
        content={content}
        liveContent={liveContent}
        onContentUpdated={onContentUpdated}
        stepperTracker={stepperTracker}
      />
    </div>
  );
}
