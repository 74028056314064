import React from 'react';
import { useInteractions, useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { NewUserContentInteractionReceipt } from 'components/lane/NewUserContentInteractionReceipt';
import UserContentInteractionReceipt from 'components/lane/UserContentInteractionReceipt';
import { Loading } from 'components';

export const ViewSubmissionHistory = ({
  contentId,
}: {
  contentId: string | undefined;
}) => {
  const { items, loading } = useInteractions({
    contentId,
  });

  const displayNewReceipt = useFlag(
    FeatureFlag.NewUserContentInteractionReceipt,
    false
  );

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        items?.map((item: any, index: any) => (
          <div key={index}>
            {displayNewReceipt ? (
              <NewUserContentInteractionReceipt interaction={item!} />
            ) : (
              <UserContentInteractionReceipt interaction={item!} />
            )}
          </div>
        ))
      )}
    </div>
  );
};
