import * as yup from 'yup';

import { InvalidTypeValueError } from 'activate-errors';
import { NumberTypeBase } from '../../types/baseTypes/NumberTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_OTHER } from '../constants';

export class EmType extends NumberTypeBase {
  group = TYPE_GROUP_OTHER;

  name = 'Em';

  get example() {
    return 5;
  }

  get default(): any {
    return 0;
  }

  friendlyName = 'Em';

  contexts = [TypeContextEnum.Block];

  superUserOnly = true;

  buildSchema() {
    return yup.number().nullable();
  }

  deserialize = (value: any) => {
    if (value === undefined || value === null) {
      return value;
    }

    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      return Number.parseFloat(value);
    }

    throw new InvalidTypeValueError(`${value} for Em is not valid.`);
  };

  parseValue = (value: any) => `${value}em`;
}

const definition = new EmType();

Types.addBaseType(definition);
export default definition;
