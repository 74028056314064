/* eslint-disable react/forbid-component-props */ // Allows passing component instances as props
/* eslint-disable @nx/enforce-module-boundaries */ // Permits importing from modules outside enforced boundaries

import React, {
  useState,
  useCallback,
  CSSProperties,
  useRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import { Input, H5, TextButton } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import styles from './PinInput.scss';

export interface Props {
  length: number;
  error?: string;
  onPinChange: (pin: string) => any;

  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
  label?: string;
  helper?: string;
  pinGenerateButton?: boolean;

  style?: CSSProperties;
  className?: string;

  inputStyle?: CSSProperties;
  inputClassName?: string;
}

function usePrevious<T>(value?: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function PinInput(props: Props) {
  const {
    length,
    isNumberInput,
    autoFocus,
    label,
    helper,
    error,
    onPinChange,
    pinGenerateButton,
    ...rest
  } = props;
  const { t } = useTranslation();

  const [activeInput, setActiveInput] = useState(0);
  const [pinValues, setPINValues] = useState(Array<string>(length).fill(''));

  const inputRefs = useRef<(HTMLInputElement | null)[]>(
    Array(length).fill(null)
  );
  const prevActiveInput = usePrevious(activeInput);

  // Helper to return PIN from inputs
  const handlePinChange = useCallback(
    (pin: string[]) => {
      const pinValue = pin.join('');
      onPinChange(pinValue);
    },
    [onPinChange]
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      const changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : '';
    },
    [isNumberInput]
  );

  // Change PIN value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedPINValues = [...pinValues];
      updatedPINValues[activeInput] = str[0] || '';
      setPINValues(updatedPINValues);
      handlePinChange(updatedPINValues);
    },
    [activeInput, handlePinChange, pinValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
      if (inputRefs.current[selectedIndex]) {
        inputRefs.current[selectedIndex]?.focus();
      }
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (value: string) => {
      const val = getRightValue(value);
      if (!val) {
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  const onChangeInput = (value: string) => {
    handleOnChange(value);
  };

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          if (pinValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, pinValues]
  );

  const generatePin = () => {
    const newPin = Array.from({ length }, () =>
      Math.floor(Math.random() * 10).toString()
    );
    setPINValues(newPin);
    setActiveInput(length); // Move focus to the end after generation
    onPinChange(newPin.join(''));
  };

  useLayoutEffect(() => {
    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
    }
  }, [activeInput, autoFocus, prevActiveInput]);

  return (
    <div data-testid="pin-input">
      {label && (
        <div className={styles.frame}>
          <H5>{label}</H5>
          {pinGenerateButton && (
            <TextButton variant="primary" onClick={generatePin}>
              {t(
                'web.admin.hardware.management.hardware.pin.form.pin_generate'
              )}
            </TextButton>
          )}
        </div>
      )}
      <div className={styles.pin} {...rest}>
        {Array(length)
          .fill('')
          .map((_, index) => (
            <Input
              key={index}
              type={isNumberInput ? 'number' : 'text'}
              ref={(el: HTMLInputElement | null) =>
                (inputRefs.current[index] = el)
              }
              value={pinValues && pinValues[index]}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={value => onChangeInput(value)}
              onKeyDown={handleOnKeyDown}
              className={styles.pinInputBox}
              inputClassName={styles.pinInputField}
            />
          ))}
      </div>
      {error ? (
        <p className={styles.errorMessage} data-cy="errorMessage">
          {error}
        </p>
      ) : (
        helper && (
          <div className={styles.label}>
            <div className={styles.helperText}>{helper}</div>
          </div>
        )
      )}
    </div>
  );
}
