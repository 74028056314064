import { DateTime, Interval } from 'luxon';

import { LaneType } from 'common-types';

const DAY_FORMAT = 'yyyy-LL-dd';

export default function updateDayMap({
  contentId,
  dateRange,
  minDate,
  maxDate,
  dayMap,
  dayFormat,
}: {
  contentId: LaneType.UUID;
  dateRange: {
    startDate: DateTime;
    endDate: DateTime;
  };
  minDate: DateTime;
  maxDate: DateTime;
  dayMap: Map<string, LaneType.UUID[]>;
  dayFormat?: string;
}): { minDate: DateTime; maxDate: DateTime } {
  const days = Interval.fromDateTimes(
    dateRange.startDate,
    dateRange.endDate
  ).splitBy({ days: 1 });

  days.forEach(dayInterval => {
    const dayKey = dayInterval.start.toFormat(dayFormat || DAY_FORMAT);
    const entry = dayMap.get(dayKey) || [];

    if (!entry.includes(contentId)) {
      entry.push(contentId);
      dayMap.set(dayKey, entry);
    }

    if (minDate > dayInterval.start) {
      minDate = dayInterval.start;
    }

    if (maxDate < dayInterval.start) {
      maxDate = dayInterval.start;
    }
  });

  return {
    minDate,
    maxDate,
  };
}
