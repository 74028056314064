import { Primitive } from '../../../../types/blocks/Primitive';

/**
 * @deprecated use PropertyInputBlock instead
 */
const ToggleInputBlock: Primitive = {
  name: 'ToggleInputBlock',
  isInput: true,
  isPrimitive: true,
  properties: {
    text: {
      type: 'String',
    },
    disabled: {
      type: 'Boolean',
      default: false,
    },
    value: {
      type: 'Boolean',
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    input: {
      type: 'Event',
    },
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ToggleInputBlock;
