import AddHostInformationBlock from './AddHostInformationBlock';
import { AddNotesBlock } from './AddNotesBlock';
import AddScheduleBlock from './AddScheduleBlock';
import AddVisitorsBlock from './AddVisitorsBlock';
import VisitorBlockHandler from './VisitorBlockHandler';

const VisitorManagement = {
  AddVisitorsBlock,
  AddScheduleBlock,
  AddHostInformationBlock,
  AddNotesBlock,
  VisitorBlockHandler,
};

export {
  AddVisitorsBlock,
  AddScheduleBlock,
  AddHostInformationBlock,
  AddNotesBlock,
  VisitorBlockHandler,
};
export default VisitorManagement;
