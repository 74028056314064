import { useEffect } from 'react';

import { history } from 'helpers';
import { matchPath } from 'react-router-dom';

/**
 * Listens for a history event (i.e. route changing) and fires the cb.
 *
 * @param onRoute the cb to execute when a route changes
 * @param path optional path to match
 */
export default function useHistory(onRoute: any, path: any) {
  useEffect(() => {
    const listener = history.listen(location => {
      if (!path) {
        return onRoute(location);
      }

      return onRoute(
        location,
        matchPath(location.pathname, {
          path,
          strict: false,
        })
      );
    });

    // run for the current location as well
    onRoute(
      history.location,
      matchPath(history.location.pathname, {
        path,
        strict: false,
      })
    );

    return () => {
      if (listener) {
        listener();
      }
    };
  }, []);
}
