import { generatePath } from 'react-router-dom';

import routes from 'lane-shared/config/routes';

export function redirectToEditTeamPage(
  history: object,
  channelSlug: string,
  groupRoleId: string,
  redirectTo?: string
) {
  const route = generatePath(redirectTo ?? routes.channelAdminTeamsEdit, {
    id: channelSlug,
    groupRoleId,
  });

  (history as any).replace(route);
}
