import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { getAudience } from '../graphql/query';

export type PublicAudienceType = {
  name: string;
};

export function usePublicAudienceQuery({
  audienceId,
  channelId,
}: {
  audienceId?: LaneType.UUID | null;
  channelId?: LaneType.UUID | null;
}) {
  const { data, loading, error } = useQuery<{ audience: PublicAudienceType }>(
    getAudience,
    {
      fetchPolicy: 'network-only',
      client: getClient(),
      skip: !audienceId || !channelId,
      variables: {
        audienceId,
        channelId,
      },
    }
  );

  const audience = data?.audience;

  return {
    audience,
    loading,
    error,
  };
}
