import * as yup from 'yup';

import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import Types from '../../../../properties/Types';
import { TYPE_GROUP_INTEGRATION } from '../../../../properties/constants';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';

const properties: PropertiesInterface = {
  laneChannel: {
    friendlyName: 'Lane Channel',
    type: 'Channel',
    validators: [],
  },
  ignore: {
    friendlyName: 'Skip this Agility ID',
    type: 'Boolean',
    default: false,
  },
  agilityId: {
    friendlyName: 'Agility ID',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class FCPBotLinkType extends ObjectTypeBase {
  group = TYPE_GROUP_INTEGRATION;

  name = 'FCPBotLink';

  friendlyName = 'FCPBot Lane Channel Link';

  description =
    'A link between a Lane Channel and a store entry in Agility CMS';

  superUserOnly = true;

  contexts = AllTypeContexts;

  get default(): any {
    return {
      channel: null,
      agilityId: null,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new FCPBotLinkType();

Types.addType(definition);

export default definition;
