import React from 'react';
import { M, TextLink } from 'design-system-web';
import { Trans, useTranslation } from 'react-i18next';
import styles from './PaymentV2Description.scss';
import { useChannelAdminContext } from 'hooks';
import { routes } from 'lane-shared/config';

export function PaymentV2Description() {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const channel_slug = channel?.slug || '';
  const pscLink = routes.channelAdminProductsServices.replace(
    ':id',
    channel_slug
  );

  return (
    <div className={styles.descriptionWrapper}>
      <M>
        <Trans
          i18nKey="web.content.features.paymentv2.description"
          components={[
            <TextLink
              key="pscLink"
              href={pscLink}
              target="_blank"
              className={styles.link}
            >
              {t('web.content.features.paymentv2.productsAndServicesLink')}
            </TextLink>,
          ]}
        />
      </M>
    </div>
  );
}
