import * as yup from 'yup';

import { StringTypeBase } from '../../types/baseTypes/StringTypeBase';
import { FlexWrapEnum } from '../../types/blocks/Flex';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';

export class FlexWrapType extends StringTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'FlexWrap';

  friendlyName = 'Wrap';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  superUserOnly = false;

  get example() {
    return FlexWrapEnum.Wrap;
  }

  get default(): any {
    return FlexWrapEnum.NoWrap;
  }

  buildSchema() {
    return yup.string().nullable().oneOf(Object.values(FlexWrapEnum));
  }
}

const definition = new FlexWrapType();

Types.addBaseType(definition);

export default definition;
