import { v4 as uuid } from 'uuid';

import { BlockInstanceType } from 'lane-shared/types/blocks/BlockInterface';

import cloneDeep from 'lodash/cloneDeep';

/**
 * Returns a new Block instance with any _ids replaced with new UUIDS
 * @param obj
 */
export default function cloneBlockWithNewUUIDS(obj: BlockInstanceType): any {
  // clone the top level object
  const ret = cloneDeep(obj);

  if (ret._id) {
    ret._id = uuid();
  }

  if (obj.children && Array.isArray(obj.children)) {
    ret.children = obj.children.map(cloneBlockWithNewUUIDS);
  }

  if (obj.properties?.children && Array.isArray(obj.properties.children)) {
    ret.properties.children = obj.properties.children.map(
      cloneBlockWithNewUUIDS
    );
  }

  return ret;
}
