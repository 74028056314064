import React from 'react';

import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

interface DangerousTranslateProps<
  ValuesType extends Record<string, string | number>
> {
  translationKey: string;
  values: ValuesType;
}

export const DangerousTranslate = <
  ValuesType extends Record<string, string | number>
>({
  translationKey,
  values,
}: DangerousTranslateProps<ValuesType>) => {
  /* eslint react/no-danger: "off" */
  const escapedValues = {} as Record<keyof ValuesType, string>;

  const { t } = useTranslation();

  Object.entries(values).forEach(([key, value]) => {
    escapedValues[key as keyof ValuesType] = DOMPurify.sanitize(value);
  });

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: t(translationKey, {
          interpolation: { escapeValue: false },
          ...escapedValues,
          count: values?.count as number,
        }),
      }}
    />
  );
};
