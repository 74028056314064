/**
 * Sorts an array by the name field.
 * @param a
 * @param b
 * @returns {number}
 */
export default function byOrder(a: any, b: any) {
  if (a._order < b._order) {
    return -1;
  }
  if (a._order > b._order) {
    return 1;
  }
  return 0;
}
