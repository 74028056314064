import { useEffect, useState, useContext } from 'react';

import { useApolloClient } from '@apollo/client';

import { UserDataContext } from 'lane-shared/contexts';

import { getTenantChannelsByUser } from '../../../graphql/visitorManagement';
import { hasPermission, pause } from '../../../helpers';
import { PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL } from '../../../helpers/constants/permissions';
import { convertTo62, convertToUUID } from '../../../helpers/convertId';
import { HostInformationType, TenantType } from '../types';

type Props = {
  onChange: (value: HostInformationType) => void;
  channelId: string;
  value: HostInformationType | null;
};

export default function useHostSelectionForm({
  onChange,
  channelId,
  value,
}: Props) {
  const { user } = useContext(UserDataContext);

  const apolloClient = useApolloClient();
  const [selectedHost, setSelectedHost] = useState<string | undefined>('');
  const [selectedTenant, setSelectedTenant] = useState<string | undefined>('');
  const [floor, setFloor] = useState<string>();

  const [tenants, setTenants] = useState<TenantType[]>([]);
  const [userTenants, setUserTenants] = useState<TenantType[]>([]);

  async function initForm() {
    await pause();

    const initTenants = await fetchTenants(user?._id);

    if (initTenants && initTenants.length > 0) {
      setSelectedHost(user?._id);
      setTenants(initTenants);
      setUserTenants(initTenants);

      const initTenant =
        initTenants.find(t => t.id === channelId)?.id || initTenants[0]?.id;

      setSelectedTenant(initTenant && convertTo62(initTenant));

      onChange({
        hostId: convertToUUID(user?._id),
        hostTenantId: initTenant,
      });
    }
  }

  async function fetchTenants(userId?: string): Promise<TenantType[]> {
    if (!userId) return [];

    try {
      const { data } = await apolloClient.query({
        query: getTenantChannelsByUser,
        variables: {
          userId,
          channelId,
        },
        fetchPolicy: 'network-only',
      });

      return data.tenantChannelsByUser;
    } catch (error) {
      console.log('Failed to load tenants for the selected host', error);
    }

    return [];
  }

  async function intersectTenants(userId: string): Promise<TenantType[]> {
    const hostTenants = await fetchTenants(userId);

    // users with permissions should be able to select any tenant of a user even if they are not members of it
    if (
      user?.isSuperUser ||
      hasPermission(
        user?.roles,
        PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL,
        convertTo62(channelId)
      )
    ) {
      return hostTenants;
    }

    return hostTenants.filter(ht => userTenants.some(ut => ht.id === ut.id));
  }

  async function handleHost(hostValue: string) {
    if (hostValue) {
      const hostId = convertToUUID(hostValue);

      setSelectedHost(hostValue);

      const intersectedTenants = await intersectTenants(hostId);

      setTenants(intersectedTenants);

      const newTenant =
        intersectedTenants.find(t => t.id === value?.hostTenantId)?.id ||
        intersectedTenants[0]?.id;

      setSelectedTenant(newTenant && convertTo62(newTenant));

      onChange({
        hostId,
        hostTenantId: newTenant,
        floor: value?.floor,
      });
    }
  }

  function handleTenant(tenantValue: string) {
    if (tenantValue) {
      setSelectedTenant(tenantValue);

      onChange({
        hostId: selectedHost ? convertToUUID(selectedHost) : undefined,
        hostTenantId: tenantValue ? convertToUUID(tenantValue) : undefined,
        floor,
      });
    }
  }

  function handleFloor(floorValue: string) {
    setFloor(floorValue);

    onChange({
      hostId: selectedHost ? convertToUUID(selectedHost) : undefined,
      hostTenantId: selectedTenant ? convertToUUID(selectedTenant) : undefined,
      floor: floorValue,
    });
  }

  useEffect(() => {
    initForm().catch(console.error);
  }, []);

  useEffect(() => {
    onChange({
      hostId: selectedHost ? convertToUUID(selectedHost) : undefined,
      hostTenantId: selectedTenant ? convertToUUID(selectedTenant) : undefined,
      floor,
    });
  }, [value?.hostId, value?.hostTenantId, value?.floor]);

  return {
    selectedHost,
    selectedTenant,
    floor,
    tenants,
    handleHost,
    handleTenant,
    handleFloor,
  };
}
