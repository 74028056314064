import { DateTime, Zone } from 'luxon';

import parseDateTime from '../dates/parseDateTime';

export default function dateFormatter(
  date: Date | DateTime | string | undefined | null,
  toFormat: string,
  timeZone?: string | Zone,
  locale?: string
): string {
  const dateTime = parseDateTime(date, timeZone);

  if (!dateTime || dateTime.invalidExplanation) {
    return '';
  }

  if (locale != null) {
    try {
      return dateTime.setLocale(locale).toFormat(toFormat);
    } catch (err) {
      console.log(err);
    }
  }

  return dateTime.toFormat(toFormat);
}
