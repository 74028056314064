import React from 'react';

import cx from 'classnames';

import { H5 } from '../typography';

import styles from './ScrollPanel.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  scrollContainerClassName?: string;
  header?: string;
};

export default function ScrollPanel({
  header,
  className,
  scrollContainerClassName,
  children,
}: Props) {
  return (
    <div className={cx(styles.ScrollPanel, className)}>
      {header && <H5 className={styles.header}>{header}</H5>}
      <div className={cx(styles.scrollContainer, scrollContainerClassName)}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
