import { useEffect } from 'react';

import { isEqual } from 'lodash';

type Params = {
  searchParams: any;
  initialTableParams: any;
  tableStorageVariable: string;
};

const usePersistedParams = ({
  searchParams,
  initialTableParams,
  tableStorageVariable,
}: Params) => {
  useEffect(() => {
    if (!isEqual(searchParams, initialTableParams)) {
      window.localStorage.setItem(
        tableStorageVariable,
        JSON.stringify(searchParams)
      );
    }
  }, [searchParams, initialTableParams, tableStorageVariable]);
};

export default usePersistedParams;
