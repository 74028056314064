/**
 * Simple helper to build out an address for this request and set
 * some default values.
 *
 * @param content
 * @param building
 * @returns {{name: *}}
 */
export default function constructAddress({ content, building }: any) {
  // and then set the name of this address to the building name.
  // we do this just incase the end user didn't setup the address
  // with the same name as the building.

  return {
    ...building.address,
    name: building.name,
    geo: {
      latitude: content.geo[1],
      longitude: content.geo[0],
    },
  };
}
