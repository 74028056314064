import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { VisitorMenuEnum } from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';
import toSchema from 'lane-shared/helpers/toSchema';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { Label, TabStrip } from '../../../components/general';
import { FeatureRendererPropsType } from '../../../components/renderers/features/FeatureRendererPropsType';
import { VisitorGeneralTab, VisitorSchedulerTab } from './components';
import useQueryString from 'hooks/useQueryString';

import styles from './styles.scss';

export const VisitorManagementFeatureRenderer = ({
  className,
  style,
  feature,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<VisitorManagementFeatureProperties>) => {
  const [query, goToUrl] = useQueryString({
    visitorMenu: VisitorMenuEnum.General,
  });

  const menuTabs = Object.values(VisitorMenuEnum).map(toSchema);
  const { t } = useTranslation();

  const settings = contentFeature?.feature as VisitorManagementFeatureProperties;

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  function renderTab() {
    switch (query.visitorMenu) {
      case VisitorMenuEnum.General:
        return (
          <VisitorGeneralTab
            feature={feature}
            settings={settings}
            onFeatureUpdated={onFeatureUpdated}
          />
        );
      case VisitorMenuEnum.Scheduling:
        return (
          <VisitorSchedulerTab
            feature={feature}
            settings={settings}
            onFeatureUpdated={onFeatureUpdated}
          />
        );
      default:
    }
  }
  return (
    <div className={cx(styles.visitor, className)} style={style}>
      <Label h1>{t(feature.friendlyName)}</Label>
      <p>{t(feature.description)}</p>
      {settings && (
        <section>
          <TabStrip
            className={styles.tabMenu}
            tabs={menuTabs}
            fullWidth
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'SchemaType<string | number>' is not assignab... Remove this comment to see the full error message
            selected={toSchema(query.visitorMenu)}
            onSelectTab={menu => goToUrl({ visitorMenu: menu.value })}
          />

          {renderTab()}
        </section>
      )}
    </div>
  );
};
