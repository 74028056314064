import React from 'react';
import { extractChannelLanguagesArray } from 'lane-shared/helpers/dynamicLanguages';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { get } from 'lodash';
import styles from './MultiLanguageText.scss';
import { M } from 'components/typography';
import Label from 'components/general/Label';

type MultiLanguageTextProps = {
  children: (
    multiTranslationElements: MultiTranslationElements
  ) => JSX.Element | null;
  channel: ChannelType | null;
  model: any;
  column: string;
  isHidden?: boolean;
};

type MultiTranslationElements = {
  labelMaker: ({
    label,
  }: {
    label: string | JSX.Element;
    required?: boolean;
  }) => JSX.Element;
  textMaker: () => JSX.Element;
  isPrimary: boolean;
  languageCode: string;
  languageFriendlyName: string;
};

type LabelMakerType = (
  isPrimary: boolean,
  friendlyLangName: string,
  shouldSeeMultiLanguage: boolean,
  languageCode: string
) => MultiTranslationElements['labelMaker'];

type TextMakerType = (
  isPrimary: boolean,
  friendlyLangName: string,
  shouldSeeMultiLanguage: boolean,
  languageCode: string
) => MultiTranslationElements['textMaker'];

/**
 * Creates additional components from the original component to allow an admin to provide translations.
 * If no channelLanguages are set on the channel settings, only a primary language component is returned
 */

export const MultiLanguageText = ({
  children,
  channel,
  model,
  column,
  isHidden = false,
}: MultiLanguageTextProps) => {
  if (!model || !column) return null;
  const channelLanguagesArr = extractChannelLanguagesArray(channel);

  const labelMaker: LabelMakerType = (
    _isPrimary,
    friendlyLangName,
    shouldSeeMultiLanguage,
    _languageCode
  ) => ({ label }) => {
    return (
      <Label className={styles.fieldLabel}>
        {label} {shouldSeeMultiLanguage ? ` (${friendlyLangName})` : ''}
      </Label>
    );
  };

  const textMaker: TextMakerType = (
    isPrimary,
    _friendlyLangName,
    _shouldSeeMultiLanguage,
    languageCode
  ) => () => {
    const text = isPrimary
      ? get(model, column)
      : get(model, `${column}_l10n.${languageCode}`);
    return text && <M className={styles.fieldText}>{text}</M>;
  };

  return (
    <>
      {channelLanguagesArr.map(
        (
          { isPrimary, languageCode, friendlyName: friendlyLangName },
          _index,
          array
        ) => {
          const shouldSeeMultiLanguage = array.length > 1;
          const renderedChildren = children({
            labelMaker: labelMaker(
              isPrimary,
              friendlyLangName,
              shouldSeeMultiLanguage,
              languageCode
            ),
            textMaker: textMaker(
              isPrimary,
              friendlyLangName,
              shouldSeeMultiLanguage,
              languageCode
            ),
            isPrimary,
            languageCode,
            languageFriendlyName: friendlyLangName,
          });
          if (!isHidden) {
            return (
              <div className={styles.fieldContainer}>{renderedChildren}</div>
            );
          }
          return null;
        }
      )}
    </>
  );
};
