import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Input from '../form/Input';
import styles from './SearchBar.scss';

type Props = {
  searchOptions: { search?: string | undefined } | null | undefined;
  className?: string;
  searchInputClassName?: string;
  searchInputPlaceholder?: string;
  style?: React.CSSProperties;
  onSearchChange: (search: string) => void;
  disabledSearch?: boolean;
  errors?: undefined | null | string[];
};

export default function SearchBar({
  className,
  searchInputClassName,
  style,
  searchOptions,
  searchInputPlaceholder,
  onSearchChange,
  disabledSearch,
  errors,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.SearchBar, className)} style={style}>
      <div className={styles.searchContainer}>
        <Input
          className={cx(styles.searchInput, searchInputClassName)}
          inputWrapperClassName={styles.searchInputWrapper}
          icon="search"
          error={errors}
          value={searchOptions?.search || ''}
          onChange={search => onSearchChange(search)}
          placeholder={searchInputPlaceholder || t('Search')}
          disabled={disabledSearch}
          showClear
        />
      </div>
    </div>
  );
}
