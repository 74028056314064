import { BaseType } from '../../types/baseTypes/TypeBase';
import { ArrayMaxValidator } from './ArrayMax';

export function addArrayMaxValidatorImpl(
  type: BaseType,
  validator: ArrayMaxValidator
) {
  type._arraySchema = type.arraySchema.max(
    validator.config?.value as number,
    `${type.getDisplayName()} must have max ${
      validator.config?.value
    } value(s).`
  );
}
