import React, { useState } from 'react';

import cx from 'classnames';
import { Button, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { H5 } from 'components/typography';

import { HardwareFormValidator } from '../../validators/HardwareFormValidator';

import styles from './styles.scss';

const HardwareDeviceAddForm = ({
  className,
  onCancel,
  onSubmit,
}: {
  className?: string;
  onCancel: () => void;
  onSubmit: (name: string, location: string, notes: string) => any;
}): React.ReactElement => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [notes, setnotes] = useState<string>('');
  const {
    nameValidator,
    locationValidator,
    notesValidator,
    isFormInvalid,
  } = HardwareFormValidator();

  return (
    <div className={cx(styles.hardwareDeviceAddForm, className)}>
      {/* Device Type is Only KIOSK for now in Design and Needs not input. */}
      <div className={styles.inputContainer}>
        <H5>{t('web.admin.hardware.management.hardware.add.type')}</H5>
        {t('web.admin.hardware.management.hardware.type.kiosk')}
      </div>

      <div className={styles.inputContainer}>
        <Input
          fieldName="name"
          label="web.admin.hardware.management.hardware.add.name"
          value={name}
          placeholder={t(
            'web.admin.hardware.management.hardware.name.placeholder'
          )}
          onChange={name => setName(name)}
          error={nameValidator(name, false)}
        />
      </div>

      <div className={styles.inputContainer}>
        <Input
          fieldName="location"
          label="web.admin.hardware.management.hardware.add.location"
          dataCy="Location"
          value={location}
          placeholder={t(
            'web.admin.hardware.management.hardware.location.placeholder'
          )}
          onChange={location => setLocation(location)}
          error={locationValidator(location)}
        />
      </div>
      <div className={styles.textAreaContainer}>
        <Input
          fieldName="note"
          label="web.admin.hardware.management.hardware.add.notes"
          value={notes}
          placeholder={t('web.admin.hardware.management.hardware.add.notes')}
          onChange={(value: any) => {
            setnotes(value);
          }}
          error={notesValidator(notes)}
          showLengthIndicator
          maxLength={200}
        />
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonRow}>
          <Button className={styles.cancelButton} onClick={onCancel}>
            {t('web.admin.hardware.management.hardware.button.cancel')}
          </Button>
          <Button
            className={styles.submitButton}
            dataCy="saveButton"
            variant="contained"
            disabled={isFormInvalid(name, location, notes)}
            onClick={() => onSubmit(name, location, notes)}
          >
            {t('web.admin.hardware.management.hardware.button.add')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HardwareDeviceAddForm;
