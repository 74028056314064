import { ValidationError } from 'yup';

/**
 * Finds validation messages generated by yup at the given path.
 */
export default function getValidationForPath(
  validation: ValidationError | ValidationError[] | null | undefined,
  path: string,
  subPath?: string
): ValidationError[] | null {
  if (!validation) {
    return null;
  }

  let fullPath = path;

  if (subPath) {
    fullPath = `${path ? `${path}.` : ''}${subPath}`;
  }

  const validations: ValidationError[] = Array.isArray(validation)
    ? validation
    : [validation];

  const found: ValidationError[] = [];

  validations.forEach(validation => {
    if (path && validation.path?.startsWith(fullPath)) {
      found.push(validation);
    }

    if (validation?.inner) {
      validation.inner.forEach(err => {
        if (!path || (path && err.path?.startsWith(fullPath))) {
          found.push(err);
        }
      });
    }
  });

  if (found.length === 0) {
    return null;
  }

  return found;
}
