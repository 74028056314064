/** What contexts can this property be used in? */
export enum TypeContextEnum {
  /** this property can apply as a property for a Block */
  Block = 'Block',
  /** this property can apply as a property for a Content */
  Content = 'Content',
  /** this property can apply as a property for users to enter data */
  Data = 'Data',
  /** this property can apply to define other properties */
  Properties = 'Properties',
  /** this property can apply as a metatag  */
  Metatag = 'Metatag',
}

export const AllTypeContexts = [
  TypeContextEnum.Block,
  TypeContextEnum.Content,
  TypeContextEnum.Data,
  TypeContextEnum.Properties,
  TypeContextEnum.Metatag,
];
