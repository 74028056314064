import * as yup from 'yup';

import { Validators } from 'lane-shared/properties/validators';

import { createShapeFromProperties } from '../properties';
import { SupportedSchema } from '../types/baseTypes/TypeBase';

const validatorNames: string[] = [];
const validatorValidation: Record<string, Record<string, SupportedSchema>> = {};

Object.values(Validators).map(validator => {
  validatorNames.push(validator.name);
  validatorValidation[validator.name] = createShapeFromProperties(
    validator.properties
  );
});

export default yup.object().shape({
  __typename: yup.string().strip(true),
  name: yup.string().required().oneOf(validatorNames),
  value: yup
    .mixed()
    .test('validator-value', '${path} is not valid.', function (value) {
      try {
        validatorValidation[this.parent.name].value.validateSync(value);

        return true;
      } catch (err) {
        return err;
      }
    }),
});
