import React, { useMemo } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getChannelIntegrations } from 'lane-shared/graphql/query';
import getIntegrationDefinition from 'lane-shared/helpers/integrations/getIntegrationDefinition';
import { byName } from 'lane-shared/helpers/sort';
import { useFlag } from 'lane-shared/hooks';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import Dropdown from '../form/Dropdown';

const integrationsQuery = gql`
  query getIntegrationsDropdown($id: UUID!) {
    availableIntegrations(channelId: $id) {
      _id
      name
      properties
    }
  }
`;

type OwnProps = {
  onChange: (integration: any) => void;
  name: string;
  color: 'primary' | 'secondary' | 'invisible';
  schema: {
    label: string;
    value: string;
  };
  initialValue: string;
  placeholder: string;
  value: string;
  channel: any;
};

IntegrationDropdown.defaultProps = {
  color: 'primary',
  schema: {
    label: 'label',
    value: 'value',
  },
  initialValue: null,
  placeholder: 'Select Integration',
  value: null,
};

type Props = OwnProps & typeof IntegrationDropdown.defaultProps;

export default function IntegrationDropdown({
  onChange,
  channel,
  // @ts-expect-error ts-migrate(2700) FIXME: Rest types may only be created from object types.
  ...props
}: Props) {
  const { data: integrationsData } = useQuery(integrationsQuery, {
    variables: { id: (channel as any)?._id },
    fetchPolicy: 'network-only',
  });

  const { data: channelIntegrationsData } = useQuery(getChannelIntegrations, {
    variables: { id: (channel as any)?._id },
    skip: !channel,
  });

  const isYardiFlagEnabled = useFlag(FeatureFlag.YardiConfigSetting, false);
  const isYardiCommercialFlagEnabled = useFlag(
    FeatureFlag.YardiCommercialConfigSetting,
    false
  );
  const isEntrataFlagEnabled = useFlag(FeatureFlag.EntrataConfigSetting, false);
  const isControlByWebFlagEnabled = useFlag(
    FeatureFlag.ControlByWebConfigSetting,
    false
  );
  const isRealPageFlagEnabled = useFlag(
    FeatureFlag.RealPageConfigSetting,
    false
  );
  const isAuth0SAMLEnabled = useAuth0Enabled();

  /* Remove integrations that are already in this channel */
  const integrations = useMemo(() => {
    if (!integrationsData || !channelIntegrationsData) {
      return [];
    }

    return integrationsData.availableIntegrations
      .filter(
        (integration: any) =>
          getIntegrationDefinition(integration.name).allowsMultipleInstances ||
          !channelIntegrationsData?.channel?.integrations?.some(
            (channelIntegration: any) =>
              channelIntegration.integration._id === integration._id
          )
      )
      .sort(byName);
  }, [integrationsData, channelIntegrationsData]);

  const filterIntegrations = (integrations: []) => {
    return integrations.filter((integration: any) => {
      if (
        !isYardiFlagEnabled &&
        integration.name === IntegrationProviderEnum.Yardi
      ) {
        return false;
      }
      if (
        !isYardiCommercialFlagEnabled &&
        integration.name === IntegrationProviderEnum.YardiCommercial
      ) {
        return false;
      }
      if (
        !isEntrataFlagEnabled &&
        integration.name === IntegrationProviderEnum.Entrata
      ) {
        return false;
      }
      if (
        !isRealPageFlagEnabled &&
        integration.name === IntegrationProviderEnum.RealPage
      ) {
        return false;
      }
      if (
        !isAuth0SAMLEnabled &&
        integration.name === IntegrationProviderEnum.Auth0SAML
      ) {
        return false;
      }
      if (
        !isControlByWebFlagEnabled &&
        integration.name === IntegrationProviderEnum.ControlByWeb
      ) {
        return false;
      }
      return true;
    });
  };

  return (
    <Dropdown
      {...props}
      onValueChange={value =>
        // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
        onChange(
          integrations.find((integration: any) => integration._id === value)
        )
      }
      items={filterIntegrations(integrations).map((integration: any) => ({
        label: getIntegrationDefinition(integration.name).friendlyName,
        value: integration._id,
      }))}
    />
  );
}
