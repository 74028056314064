import * as yup from 'yup';

import { validatePasswordV2 } from './validatePassword';

export default yup.object().shape({
  name: yup.string().trim(),
  companyName: yup.string().trim().required('Company Name is a required field'),
  email: yup.string().trim().email().required(),
  password: validatePasswordV2,
});
