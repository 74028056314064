import React, { useMemo, useContext, useState } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import RendererContext from 'lane-shared/contexts/RendererContext';
import { BlockInstanceType } from 'lane-shared/types/blocks/BlockInterface';
import { ContentType } from 'lane-shared/types/content/Content';
import { PropertiesInterfaceDependencies } from 'lane-shared/types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';

import { Tooltip } from 'components/general';

import { findBlock, generateDataBlock } from './helpers';

import styles from './DataBlockMenuItem.scss';

export type DragFuncParams = {
  e: React.DragEvent<HTMLDivElement>;
  block: BlockInstanceType;
  action: 'data';
};

type Props = {
  field: any;
  content: ContentType;
  feature?: any;
  propertyDependencies: PropertiesInterfaceDependencies;
  onNewBlockDragStart: (p: DragFuncParams) => void;
  onNewBlockDragEnd?: (p: DragFuncParams) => void;
  onNewBlockAdd?: (p: DragFuncParams) => void;
};

export default function DataBlockMenuItem({
  field,
  content,
  feature,
  propertyDependencies,
  onNewBlockDragStart,
  onNewBlockDragEnd,
  onNewBlockAdd,
}: Props) {
  const { blocks } = useContext(RendererContext);
  const [hovering, setHovering] = useState(false);
  const [dragging, setDragging] = useState(false);
  const { t } = useTranslation();

  const blockFor = feature ? `${feature.type}.${field.name}` : field.name;

  function onMouseEnter() {
    if (!dragging) {
      setHovering(true);
    }
  }

  function onMouseLeave() {
    setHovering(false);
  }

  function onDragStart(e: any) {
    const block = generateDataBlock({
      field,
      contentFeature: feature,
      blockFor,
      propertyDependencies,
    });

    // @ts-expect-error ts-migrate(2740) FIXME: Type 'BlockInstanceType[]' is missing the followin... Remove this comment to see the full error message
    onNewBlockDragStart({ e, block, action: 'data' });
    setDragging(true);
    setHovering(false);
  }

  function onDragEnd(e: any) {
    setHovering(false);
    setDragging(false);

    const block = generateDataBlock({
      field,
      contentFeature: feature,
      blockFor,
      propertyDependencies,
    });

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onNewBlockDragEnd({ e, block, action: 'data' });
  }

  function onBlockAdd() {
    const block = generateDataBlock({
      field,
      contentFeature: feature,
      blockFor,
      propertyDependencies,
    });

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onNewBlockAdd({ block, action: 'data' });
  }

  // find if this input is already represented in the content.
  const existingBlock = findBlock({
    content,
    blocks,
    key: 'for',
    value: blockFor,
  });

  const hasDependentDataField = useMemo(() => {
    return content?.propertiesOptions?.dependencies?.some(d =>
      d.rules.find(r => r.targetPropertyRef === field.name)
    );
  }, [content, field.name]);

  if (existingBlock) {
    return null;
  }

  return (
    <label
      className={styles.DataBlockMenuItem}
      draggable
      data-is-dragging={dragging}
      data-is-hovering={hovering}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onKeyPress={e => e.key === Key.Enter && onBlockAdd()}
      onDoubleClick={() => onBlockAdd()}
      data-cy="specialDataBlock"
    >
      {t(field.friendlyName || field.name)}
      {hasDependentDataField && (
        <Tooltip
          placement="left"
          TooltipComponent={t(
            'web.admin.channel.content.layout.editor.dataBlockMenu.tooltip'
          )}
        >
          <Icon name="project-diagram" />
        </Tooltip>
      )}
    </label>
  );
}
