import gql from 'graphql-tag';

export const getRelayOutputById = gql`
  query GetRelayOutputById($relayOutputId: UUID!) {
    getRelayOutputById(id: $relayOutputId) {
      id
      buildingId
      name
      description
      pulse
      delay
      relayModule {
        id
        buildingId
        name
        status
        description
        lastSeen
        deviceId
        macAddress
      }
      accessPoint {
        id
        name
      }
    }
  }
`;
