import gql from 'graphql-tag';

/**
 * GQL Update Preventive Maintenance Schedule Mutation
 */

export default gql`
  mutation updatePMScheduleMutation($updatePMSchedule: UpdatePMScheduleDTO!) {
    updatePMSchedule(schedule: $updatePMSchedule) {
      scheduleId
      updatedAt
    }
  }
`;
