import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import * as Captivate from 'lane-shared/helpers/constants/integrations/Captivate';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import Checkbox from '../../../../form/Checkbox';

import styles from './styles.scss';

const dataKey = `_${IntegrationProviderEnum.Captivate}`;

export default function CaptivateTargeting({
  hasChanged,
  content,
  onContentUpdated,
}: any) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const hasIntegration = content.state && content.state[dataKey];

  return (
    <div className={styles.TargetingIntegration}>
      <h1>{t('web.admin.content.draftContent.target.captivateDisplays')}</h1>
      <section className={styles.details}>
        {!hasIntegration && (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: f... Remove this comment to see the full error message
          <Checkbox
            selected={false}
            text={t(
              'web.admin.content.draftContent.target.captivateDisplaysCheckbox'
            )}
            onChange={() => {
              onContentUpdated({
                state: {
                  ...(content.state || {}),
                  [dataKey]: true,
                },
              });
            }}
          />
        )}

        {hasIntegration && (
          <>
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: t... Remove this comment to see the full error message */}
            <Checkbox
              selected
              text={t(
                'web.admin.content.draftContent.target.captivateDisplaysCheckbox'
              )}
              onChange={() => {
                onContentUpdated({
                  state: {
                    ...(content.state || {}),
                    [dataKey]: undefined,
                  },
                });
              }}
            />

            <h2 onClick={() => setIsExpanded(!isExpanded)}>
              <span>
                {t(
                  'web.admin.content.draftContent.target.captivateDisplaysPreview'
                )}
              </span>
              <Icon name="chevron-down" />
            </h2>

            {isExpanded && (
              <div className={styles.preview}>
                {hasChanged && (
                  <p>
                    {t(
                      'web.admin.content.draftContent.target.captivateDisplaysPreviewUpdate'
                    )}
                  </p>
                )}

                {Captivate.CAPTIVATE_RESOLUTIONS.map(resolution => {
                  const src = `/api/v1/lane/clients/captivate/content/${content._id}?preview=true&resolution=${resolution}`;

                  const [width, height] = resolution
                    .split('x')
                    .map(parseFloat)
                    .map(value => value / 3);

                  return (
                    <div className={styles.previewResolution} key={resolution}>
                      <span>{resolution}</span>
                      <a href={src} target="_blank" rel="noopener noreferrer">
                        <img
                          alt={content.name}
                          style={{ width, height }}
                          src={src}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
}
