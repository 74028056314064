import gql from 'graphql-tag';

import { WatchQueryFetchPolicy, useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

import { ContentType } from '../../../types/content/Content';

const searchSectionContent = gql`
  query searchSectionContentForUseSectionContent(
    $id: UUID!
    $searchOptions: SearchOptionsInput
  ) {
    section(_id: $id, searchOptions: $searchOptions) {
      _id
      type
      sectionContent {
        _id
        _created
        _updated
        _order
        content {
          _id
          _created
          _updated
          name
          data
          description
          subtitle
          slug
          type
          tags
          category
          logo
          color
          geo
          isInteractive
          backgroundImage
          icon
          iconSet
          iconWeight
          backgroundColor
          liveDate
          startDate
          endDate
          interactionEndDate
          version
          renderer
          externalUrl {
            _id
            url
            openingMode
          }
          channel {
            _id
            settings {
              enabledLanguagesForMembers
              channelLanguages
              multiLanguageEnabled
            }
          }
          card {
            _id
          }
          state
          integration {
            _id
            settings
            integration {
              _id
              name
              settings
              platforms
            }
          }
          features {
            _id
            type
            feature
          }
          contentMetatags {
            _id
            value
            metatag {
              _id
              name
              properties
            }
          }
          info {
            _id
            rating
            viewed
            details
            hidden
            deleted
            pinned
            starred
            liked
          }
        }
      }
    }
  }
`;

type UseSectionQueryProps = {
  sectionId: string | null | undefined;
  fetchPolicy?: WatchQueryFetchPolicy;
  searchOptions: Partial<SearchOptions>;
  requireChannelId?: boolean;
};

export default function useSectionContentQuery({
  sectionId,
  fetchPolicy = 'cache-and-network',
  searchOptions = {},
  requireChannelId = false,
}: UseSectionQueryProps) {
  const { data, error, loading, refetch } = useQuery(searchSectionContent, {
    client: getClient(),
    fetchPolicy,
    skip: !sectionId || (requireChannelId && !searchOptions.channelId),
    variables: {
      id: sectionId,
      searchOptions: {
        areFiltersApplied: true,
        ...searchOptions,
      },
    },
  });

  return {
    sectionContent: (data?.section?.sectionContent || []) as Array<
      ContentType & { content: any }
    >,
    loading,
    error,
    refetch,
  };
}
