import { AccessControlGroup } from '../types/AccessControl';

interface ACGParams {
  id: string;
  provider: string;
  channelId: string;
}

interface User {
  accessControlGroupIds: string[];
}

export const computeSelectedACGs = (
  accessControlGroups: Map<string, AccessControlGroup>,
  selectedAcgs: { value: string; provider: string; channelId: string }[],
  user: User | null
) => {
  let assignedAccessControlGroups: ACGParams[] = [];
  let revokedAccessControlGroups: ACGParams[] = [];

  if (!user) {
    return {
      assignedAccessControlGroups: [],
      revokedAccessControlGroups: [],
    };
  }

  assignedAccessControlGroups = selectedAcgs
    .filter(acg => !user.accessControlGroupIds.includes(acg.value))
    .map(acg => ({
      id: acg.value,
      provider: acg.provider,
      channelId: acg.channelId,
    }));

  revokedAccessControlGroups = user.accessControlGroupIds
    .filter(acgId => !selectedAcgs.some(selected => selected.value === acgId))
    .map(acgId => {
      const acgInfo = accessControlGroups.get(acgId);
      return acgInfo
        ? {
            id: acgInfo.id,
            provider: acgInfo.provider,
            channelId: acgInfo.channelId,
          }
        : null;
    })
    .filter((acg): acg is ACGParams => acg !== null);

  return {
    assignedAccessControlGroups,
    revokedAccessControlGroups,
  };
};
