import { useTimeSlotsV2DurationsEnabled } from 'lane-shared/hooks/useTimeSlotsV2DurationsEnabled';

import { friendlyMinutes } from '../helpers/formatters';

type Props = {
  maxSlots: number;
  slotSize: number;
};

export function useDurationTimesOptions({ maxSlots, slotSize }: Props) {
  const isTimeSlotsDurationEnabled = useTimeSlotsV2DurationsEnabled();

  if (!isTimeSlotsDurationEnabled) {
    const maxDuration = slotSize * maxSlots;

    return [
      {
        label: friendlyMinutes(maxDuration, 'short'),
        value: maxDuration,
      },
    ];
  }

  const durationOptions = [];

  for (let i = 1; i <= maxSlots; i++) {
    durationOptions.push({
      label: friendlyMinutes(slotSize * i, 'short'),
      value: slotSize * i,
    });
  }

  return durationOptions;
}
