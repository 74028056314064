import ArrayMax from './ArrayMax';
import ArrayMin from './ArrayMin';
import ArrayUnique from './ArrayUnique';
import GreaterThan from './GreaterThan';
import In from './In';
import Includes from './Includes';
import LessThan from './LessThan';
import Max from './Max';
import Min from './Min';
import Required from './Required';
import RequiredIf from './RequiredIf';

export const Validators = {
  ArrayMax,
  ArrayMin,
  ArrayUnique,
  In,
  Includes,
  GreaterThan,
  LessThan,
  Max,
  Min,
  Required,
  RequiredIf,
} as const;
