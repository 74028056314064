import React from 'react';

import { Input, Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import { getValidationMessages } from 'lane-shared/helpers';

export function ConfigureMaxUsers({
  groupRole,
  updateGroupRole,
  validation,
}: any) {
  const { t } = useTranslation();

  return (
    <fieldset>
      <label>{t('web.components.lane.Team.ConfigureMaxUsers.label')}</label>
      <Toggle
        text={t('web.components.lane.Team.ConfigureMaxUsers.toggleText')}
        value={!!groupRole.maxUsers}
        onChange={() =>
          updateGroupRole({
            maxUsers: groupRole.maxUsers ? 0 : 1000,
          })
        }
      />

      {groupRole.maxUsers !== 0 && (
        <Input
          error={getValidationMessages(validation, 'maxUsers')}
          placeholder="1000"
          input={{
            type: 'number',
            min: 0,
            max: 2 ** 32,
          }}
          value={groupRole.maxUsers}
          onChange={maxUsers =>
            updateGroupRole({
              maxUsers: parseInt(maxUsers, 10) || '',
            })
          }
        />
      )}
    </fieldset>
  );
}
