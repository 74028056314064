import { INTERACTION_STATES } from '../constants/interactions';
import byOrder from '../sort/byOrder';

export type InteractionGroup = {
  _order: number;
  title: string;
  data: any[];
};

export default function groupInteractionsByStatus(
  interactions: any[]
): InteractionGroup[] {
  const order = INTERACTION_STATES;

  const statuses = {};

  order.forEach((title, _order) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    statuses[title] = {
      title,
      _order,
      data: [],
    };
  });

  interactions?.forEach(interaction =>
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    statuses[interaction.status]?.data.push(interaction)
  );

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown[]' is not assignable to type 'Intera... Remove this comment to see the full error message
  return Object.values(statuses).sort(byOrder);
}
