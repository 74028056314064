import { useEffect, useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import ChannelsContext from '../../contexts/ChannelsContext';
import ObjectTouchHelper from '../../helpers/ObjectTouchHelper';
import {
  ANALYTIC_CONTENT_DETAILS,
  ANALYTIC_CONTENT_VIEW,
} from '../../helpers/constants/analytics';
import { EVENT_CONTENT_INTERACTION_CREATED } from '../../helpers/constants/events';
import { ContentType } from '../../types/content/Content';

type EventType = 'view' | 'details' | 'interaction';

const eventMap = {
  view: ANALYTIC_CONTENT_VIEW,
  details: ANALYTIC_CONTENT_DETAILS,
  interaction: EVENT_CONTENT_INTERACTION_CREATED,
};

/**
 * Used for analytics tracking in mobile or web.
 */
export default function useContentAnalytics(
  event: EventType,
  content?: ContentType | null
) {
  const analytics = useContext(AnalyticsContext);
  const { primaryId } = useContext(ChannelsContext);

  useEffect(() => {
    if (content?._id) {
      ObjectTouchHelper.touch(
        content,
        ObjectTouchHelper.mapAction(event),
        'Content'
      );

      analytics.track(eventMap[event], {
        contentId: content?._id,
        contentName: content?.name,
        contentCategory: content?.category,
        channelId: content?.channel?._id,
        meChannelId: primaryId,
      });
    }
  }, [content?._id]);
}
