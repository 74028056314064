import React, { useEffect, useState } from 'react';

import { history } from 'helpers';
import { useQueryString } from 'hooks';

import { removeToken } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/token';
import { ContentType } from 'lane-shared/types/content/Content';

import { UrlSearchParamsWithPages } from 'components/lds/table/types';

import TabStrip, { TabItem } from '../../../general/TabStrip';
import Header from './Header';
import VisitorsTable from './VisitorsTable';

import styles from './VisitorsContainer.scss';

interface TabTypes {
  upcoming: TabItem;
  past: TabItem;
}

export const tabs: TabTypes = {
  upcoming: {
    label: 'Upcoming',
    value: 'upcoming',
  },
  past: {
    label: 'Past',
    value: 'past',
  },
};

export type VisitorQueryParams = UrlSearchParamsWithPages & {
  selectedTab: 'upcoming' | 'past';
};

type Props = {
  content: ContentType;
};

export default function VisitorsContainer({ content }: Props) {
  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs.upcoming);
  const [
    urlSearchParams,
    setUrlSearchParams,
  ] = useQueryString<VisitorQueryParams>({
    selectedTab: 'upcoming',
    page: 0,
    perPage: 10,
    total: 0,
    sortKey: '',
    dir: '',
  });

  useEffect(() => {
    setSelectedTab(tabs[urlSearchParams.selectedTab]);
  }, []);

  const setSelectedTabAndUrlParam = (tab: TabItem): void => {
    setUrlSearchParams({ page: 0, selectedTab: tab.value });
    setSelectedTab(tab);
  };

  function onLogout() {
    removeToken();
    history.goBack();
  }

  return (
    <div className={styles.visitorsContainer}>
        <Header content={content} onLogout={onLogout} />

        <TabStrip
          tabs={Object.values(tabs)}
          selected={selectedTab}
          onSelectTab={tab => setSelectedTabAndUrlParam(tab)}
          className={styles.visitorTabs}
        />
        <section>
          <VisitorsTable
            paginationParams={urlSearchParams}
            setUrlSearchParams={setUrlSearchParams}
            content={content}
          />
        </section>
      </div>
  );
}
