import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import ToggleView from './ToggleView';

import styles from './ListToggle.scss';

type ListToggleProps = {
  title: string;
  titleAddon?: React.ReactNode;
  values: {
    key: string;
    description: string;
    disabled?: boolean;
  }[];
  onClick: (key: string) => void;
  selected: any[];
};

export function ListToggle({
  title,
  values,
  onClick,
  selected,
  titleAddon,
}: ListToggleProps) {
  const hasAny = values.some(value => selected.includes(value.key));
  const { t } = useTranslation();

  return (
    <div className={styles.ListToggle}>
      <ToggleView
        title={
          <div className={styles.titleContainer}>
            <span
              style={{
                fontWeight: hasAny ? 'bold' : 'normal',
                textTransform: 'capitalize',
              }}
            >
              {title}
            </span>

            {titleAddon}
          </div>
        }
        className={styles.heading}
        dataCy={title}
      >
        <ul>
          {values.map(value => (
            <button
              key={value.key}
              title={value.key}
              data-cy={value.key}
              onClick={() => onClick(value.key)}
              data-is-selected={selected.includes(value.key)}
              className={value.disabled ? styles.disabled : ''}
              disabled={value.disabled}
            >
              <div className={styles.checkbox}>
                {selected.includes(value.key) && (
                  <Icon
                    name="check"
                    className={styles.icon}
                    style={{ width: '1.4em', height: '1.4em' }}
                  />
                )}
              </div>
              <span>{t(value.description)}</span>
            </button>
          ))}
        </ul>
      </ToggleView>
    </div>
  );
}
