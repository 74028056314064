import { useState, useEffect } from 'react';

import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  PublicUserFragment,
  FullProfileWithThemeFragment,
  AddressFragment,
  SectionFragment,
  ThemeFragment,
  ChannelSettingsRequirementsFragment,
} from 'lane-shared/graphql/fragments';
import { AddressFragmentType } from 'lane-shared/graphql/fragments/AddressFragment';
import { ChannelSettingsRequirementsFragmentType } from 'lane-shared/graphql/fragments/ChannelSettingsRequirementsFragment';
import { FullProfileWithThemeFragmentType } from 'lane-shared/graphql/fragments/FullProfileWithThemeFragment';
import { PublicUserFragmentType } from 'lane-shared/graphql/fragments/PublicUserFragment';
import { SectionFragmentType } from 'lane-shared/graphql/fragments/SectionFragment';
import { isProbablyUUID, getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { useMultiLanguage } from 'lane-shared/hooks';
import {
  ChannelSettingsType,
  ChannelType,
} from 'lane-shared/types/ChannelType';
import { GroupRoleJoinRuleType } from 'lane-shared/types/GroupRoleJoinRuleType';
import { RequirementsSource } from 'lane-shared/types/features/RequirementsFeatureProperties';
import { ModuleDefinition } from 'lane-shared/types/modules/moduleDefinition';
import { Maybe, WhitelabelChannel } from 'graphql-query-contracts';

export type UseChannelForAdminQueryResponse = {
  channel: Pick<
    ChannelType,
    | '_id'
    | '_created'
    | '_updated'
    | 'name'
    | 'website'
    | 'description'
    | 'slug'
    | 'type'
    | 'experienceType'
    | 'isPublic'
    | 'isDiscoverable'
    | 'isCustomer'
    | 'isSub'
    | 'inviteOnly'
    | 'filters'
    | 'stats'
    | 'tags'
    | 'channelModules'
  > & {
    _createdBy: PublicUserFragmentType;
    _updatedBy: PublicUserFragmentType;
    specialPermissions: string[];
    whitelabels: Maybe<Array<WhitelabelChannel>>;
    settings: Pick<
      ChannelSettingsType,
      | 'maxUsers'
      | 'hasPerksEnabled'
      | 'areUserTemplatesAllowed'
      | 'subscribersJoinParent'
      | 'subscribersAreDecoyed'
      | 'hasBillingPaymentsEnabled'
      | 'hasWorkOrdersEnabled'
      | 'hasWorkOrderServiceRequestsEnabled'
      | 'hasWorkOrderPreventiveMaintenanceEnabled'
      | 'hasWorkOrderEquipmentEnabled'
      | 'hasWorkOrderCrossPropertyEnabled'
      | 'hasVisitorManagementEnabled'
      | 'hasHardwareManagementEnabled'
      | 'hasHardwarePinManagementEnabled'
      | 'hasReservableManagementEnabled'
      | 'hasSurveysEnabled'
      | 'hasAccessControlEnabled'
      | 'multiLanguageEnabled'
      | 'channelLanguages'
      | 'enabledLanguagesForMembers'
      | 'hasMessagingAndMarketplaceEnabled'
      | 'hasSmartUserManagementEnabled'
    > & {
      groupRoleJoinRules: Pick<
        GroupRoleJoinRuleType,
        '_id' | 'groupRoleId' | 'joinGroupRoleId' | 'channelId'
      >[];
      requirements: ChannelSettingsRequirementsFragmentType;
      effectiveParentRequirements: ChannelSettingsRequirementsFragmentType & {
        source: Pick<RequirementsSource, 'slug' | 'profileName' | '_id'>;
      };
    };
    address: AddressFragmentType;
    profile: FullProfileWithThemeFragmentType;
    sections: SectionFragmentType[];
    parent: Pick<ChannelType, '_id' | 'name' | 'slug' | 'type'>;
    channelModules: ModuleDefinition[];
  };
};

const channelQuery = gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${AddressFragment}
  ${SectionFragment}
  ${ChannelSettingsRequirementsFragment}

  query getChannelForAdmin($id: UUID, $slug: String) {
    channel(_id: $id, slug: $slug) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }

      name
      website
      description
      slug
      type
      experienceType
      isPublic
      isDiscoverable
      isCustomer
      isSub
      hasCustomOffboarding
      inviteOnly
      filters
      specialPermissions
      tags

      whitelabels {
        whitelabel {
          url
        }
      }

      settings {
        maxUsers
        offboardingDelayDays
        hasPerksEnabled
        areUserTemplatesAllowed
        subscribersJoinParent
        subscribersAreDecoyed
        hasReservableManagementEnabled
        hasSurveysEnabled
        hasWorkOrdersEnabled
        hasWorkOrderServiceRequestsEnabled
        hasWorkOrderPreventiveMaintenanceEnabled
        hasWorkOrderEquipmentEnabled
        hasWorkOrderCrossPropertyEnabled
        hasBillingPaymentsEnabled
        hasVisitorManagementEnabled
        hasHardwareManagementEnabled
        hasHardwarePinManagementEnabled
        hasAccessControlEnabled
        hasMessagingAndMarketplaceEnabled
        hasSmartUserManagementEnabled
        multiLanguageEnabled
        enabledLanguagesForMembers
        channelLanguages
        groupRoleJoinRules {
          _id
          groupRoleId
          joinGroupRoleId
          channelId
        }
        requirements {
          ...ChannelSettingsRequirementsFragment
        }
        effectiveParentRequirements {
          source {
            slug
            profileName
            _id
          }
          ...ChannelSettingsRequirementsFragment
        }
      }

      stats {
        sf
        subscribers
      }

      address {
        ...AddressFragment
      }

      profile {
        ...FullProfileWithThemeFragment
      }

      parent {
        _id
        name
        slug
        type
      }

      sections {
        ...SectionFragment
      }

      channelModules {
        name
        _id
        disabled
        dataDefinition
        settings
      }
    }
  }
`;

export default function useChannelForAdminQuery() {
  const { id: channelId } = useParams<{ id: string }>();
  const { translate } = useMultiLanguage();

  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const [
    fetchChannel,
    channelResults,
  ] = useLazyQuery<UseChannelForAdminQueryResponse>(channelQuery, {
    client: getClient(),
    fetchPolicy: 'no-cache',
  });
  const channel = channelResults?.data?.channel;
  const specialPermissions =
    channelResults?.data?.channel?.specialPermissions || [];

  function refetchChannel() {
    if (channelId) {
      let slug;
      let id;

      // may be a slug or a id passed in
      if (isProbablyUUID(channelId)) {
        id = channelId;
      } else {
        slug = channelId;
      }

      if (!channelResults.loading) {
        fetchChannel({
          variables: { id, slug },
        });
      }
    }
  }

  useEffect(() => {
    refetchChannel();
  }, [channelId]);

  useEffect(() => {
    if (channel?.address?.geo) {
      // set the timeZone to the channel instead of the users time zone.
      const [longitude, latitude] = channel.address.geo;
      setTimeZone(getTimeZoneByGeoLocation({ longitude, latitude }));
    }
  }, [channel?.address]);

  const translatedChannelSections = translate({
    model: channel?.sections?.map(section => ({
      ...section,
      channel: {
        _id: channel?._id,
        settings: {
          ...channel?.settings,
        },
      },
    })),
    columns: ['name'],
  });

  const translatedChannel = channel && {
    ...channel,
    sections: translatedChannelSections,
  };

  return {
    channel: translatedChannel,
    timeZone,
    specialPermissions,
    loading: channelResults.loading,
    error: channelResults.error,
    called: channelResults.called,
    refetchChannel,
  };
}
