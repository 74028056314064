import React from 'react';

import AuthModal from 'components/auth/AuthModal';

import { useModalContext } from '../contexts/ModalContext';

/**
 * Display the auth modal to user
 */

export default function useAuthModal({ onLogin = () => {} }) {
  const { showModal, closeModal } = useModalContext();

  function showAuthModal() {
    showModal(<AuthModal onLogin={onLogin} onClose={closeModal} />);
  }

  return {
    showAuthModal,
  };
}
