import * as yup from 'yup';

import {
  META_TAG_TYPES,
  META_TAG_TYPE_SIMPLE,
} from '../helpers/constants/metatags';
import validateProperty from './validateProperty';

export default yup.object().shape({
  name: yup.string().trim().max(256).required(),
  description: yup.string().trim().max(4096),
  type: yup.string().required().oneOf(META_TAG_TYPES),
  properties: yup.object().when('type', {
    is: META_TAG_TYPE_SIMPLE,
    then: validateProperty,
    otherwise: yup.object(),
  }),
});
