import React, { useMemo } from 'react';

import cx from 'classnames';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { useVisitorInviteForm } from 'lane-shared/hooks';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import VisitorInput from '../VisitorInput/VisitorInput';
import VisitorPhoneInput from '../VisitorPhoneInput/VisitorPhoneInput';

import styles from './VisitorInviteForm.scss';

function InviteForm({
  className,
  properties,
  value,
  defaultValue,
  isEditMode = false,
  visitorToEdit,
  onAdd,
  onEditSave,
  onFieldChange,
  isPreview = false,
}: {
  className?: string;
  properties: VisitorManagementFeatureProperties;
  value?: VisitorType[];
  defaultValue?: VisitorType;
  isEditMode?: boolean;
  visitorToEdit?: VisitorType;
  onAdd?: (visitor: VisitorType) => void;
  onEditSave?: (visitor: VisitorType) => void;
  onFieldChange?: (changed: boolean) => void;
  isPreview?: boolean;
}): React.ReactElement {
  const { t } = useTranslation();

  const {
    email,
    setEmail,
    emailValidator,
    emailRequiredValidator,
    firstName,
    setFirstName,
    firstNameValidator,
    lastName,
    setLastName,
    lastNameValidator,
    company,
    setCompany,
    companyValidator,
    phone,
    setPhone,
    phoneValidator,
    phoneRequiredValidator,
    hasChanged,
    addVisitor,
    resetFields,
    isRequired,
    isHidden,
    updateVisitor,
    isPristine,
  } = useVisitorInviteForm({
    defaultValue,
    properties,
    translator: t,
    isEditMode,
    visitorToEdit,
    onAddSuccess: onAdd,
    onEditSuccess: onEditSave,
  });

  if (onFieldChange) {
    onFieldChange(isPristine);
  }

  const handleOnClick = () => {
    if (isEditMode) {
      updateVisitor();
      return;
    }

    addVisitor();
  };

  const addButtonText = useMemo(() => {
    if (isEditMode)
      return 'web.content.feature.visitorManagement.visitorInvite.form.save';
    return value?.length
      ? 'web.content.feature.visitorManagement.visitorInvite.form.addAnother'
      : 'web.content.feature.visitorManagement.visitorInvite.form.add';
  }, [value?.length, isEditMode]);

  return (
    <div className={cx(styles.visitorInviteForm, className)}>
      <div>
        <VisitorInput
          dataCy="email"
          displayName={t(
            'web.content.feature.visitorManagement.visitorInvite.form.email'
          )}
          value={email}
          placeholder={t(
            'web.content.feature.visitorManagement.visitorInvite.form.placeholder.email'
          )}
          type="email"
          onChange={email => setEmail(email)}
          validation={
            isRequired(properties.emailSetting)
              ? emailRequiredValidator
              : emailValidator
          }
          isPristine={!hasChanged}
          isHidden={isHidden(properties.emailSetting)}
          isRequired={isRequired(properties.emailSetting)}
          isDisabled={isPreview}
        />
        <VisitorInput
          dataCy="firstname"
          displayName={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.firstName'
          )}
          value={firstName}
          placeholder={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.firstName'
          )}
          onChange={firstName => setFirstName(firstName)}
          validation={firstNameValidator}
          isPristine={!hasChanged}
          isRequired={isRequired(properties.nameSetting)}
          isDisabled={isPreview}
        />
        <VisitorInput
          dataCy="lastname"
          displayName={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.lastName'
          )}
          value={lastName}
          placeholder={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.lastName'
          )}
          onChange={lastName => setLastName(lastName)}
          validation={lastNameValidator}
          isPristine={!hasChanged}
          isRequired={isRequired(properties.nameSetting)}
          isDisabled={isPreview}
        />
        <VisitorPhoneInput
          dataCy="phone"
          displayName={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.cellNumber'
          )}
          value={phone}
          placeholder={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.cellNumber'
          )}
          onChange={phone => setPhone(phone)}
          validation={
            isRequired(properties.phoneSetting)
              ? phoneRequiredValidator
              : phoneValidator
          }
          isPristine={!hasChanged}
          isHidden={isHidden(properties.phoneSetting)}
          isRequired={isRequired(properties.phoneSetting)}
          isDisabled={isPreview}
        />
        <VisitorInput
          dataCy="company"
          displayName={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.company'
          )}
          value={company}
          placeholder={t(
            'shared.content.feature.visitorManagement.visitorInvite.form.company'
          )}
          onChange={company => setCompany(company)}
          validation={
            isRequired(properties.companySetting) ? companyValidator : undefined
          }
          isPristine={!hasChanged}
          isHidden={isHidden(properties.companySetting)}
          isRequired={isRequired(properties.companySetting)}
          isDisabled={isPreview}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          dataCy="addVisitorButton"
          variant="contained"
          onClick={handleOnClick}
          size="small"
          disabled={isPreview}
        >
          {t(addButtonText)}
        </Button>
        <Button
          className={styles.clearButton}
          dataCy="clearButton"
          variant="text"
          onClick={resetFields}
          size="small"
          disabled={isPreview}
        >
          {t('web.content.feature.visitorManagement.visitorInvite.form.clear')}
        </Button>
      </div>
    </div>
  );
}

export default InviteForm;
