import { JsonObject, JsonSerializable } from '../../JSONType';
import { IPropertyDependency, PropertiesInterface } from '../Property';
import { PropertyDependencyRule } from './propertyDependencyRule';

export class PropertyDependency implements JsonSerializable {
  constructor(
    public propertyRef: string,
    public rules: Map<string, PropertyDependencyRule>
  ) {}

  static fromJsonData(
    properties: PropertiesInterface,
    propertyDependencyData: IPropertyDependency
  ): PropertyDependency {
    return new PropertyDependency(
      propertyDependencyData.propertyRef,
      new Map(
        propertyDependencyData.rules.map(ruleData => [
          ruleData.targetPropertyRef,
          PropertyDependencyRule.fromJsonData(properties, ruleData),
        ])
      )
    );
  }

  addRule(rule: PropertyDependencyRule) {
    this.rules.set(rule.targetPropertyRef, rule);
  }

  removeRule(ref: string) {
    this.rules.delete(ref);
  }

  isSatisfied(inputData: JsonObject): boolean {
    try {
      const ruleEvalResults = Array.from(this.rules.values()).map(rule =>
        rule.isSatisfied(inputData)
      );
      return ruleEvalResults.every(ruleIsSatisfied => ruleIsSatisfied);
    } catch (e) {
      return false;
    }
  }

  serialize(): IPropertyDependency {
    return {
      propertyRef: this.propertyRef,
      rules: Array.from(this.rules.values()).map(rule => rule.serialize()),
    };
  }
}
