import { UserLoginType, UserLoginTypeEnum } from '../../types/UserLogin';
import getPrimaryLogin from './getPrimaryLogin';

export default function getPrimaryPhone(
  logins: UserLoginType[]
): string | null {
  const login = getPrimaryLogin(logins, UserLoginTypeEnum.Phone);

  return login?.key || null;
}
