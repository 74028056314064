import escapeRegExp from '../escapeRegExp';

export default function filterContent(content: any, filter = '', tags: any) {
  if (new Date(content.endDate) < new Date()) {
    return false;
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  if ([undefined, null].includes(filter)) {
    return true;
  }

  let found = true;

  if (filter) {
    found =
      new RegExp(escapeRegExp(filter), 'gi').test(content.name) ||
      new RegExp(escapeRegExp(filter), 'gi').test(content.description);
  }

  if (tags && tags.length > 0) {
    if (!content.tags || content.tags.length === 0) {
      found = false;
    } else {
      found = found && content.tags.some((tag: any) => tags.includes(tag));
    }
  }

  return found;
}
