import { DateTime, Zone } from 'luxon';

import { SHORT_TIME } from '../constants/dates';
import dateFormatter from './dateFormatter';

export default function shortTime(
  date: Date | DateTime | string | undefined | null,
  timeZone?: string | Zone
) {
  return dateFormatter(date, SHORT_TIME, timeZone);
}
