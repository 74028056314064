import React, { useMemo } from 'react';

import cx from 'classnames';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';

import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { getToSchemaWithTranslate } from 'lane-shared/helpers/toSchema';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  ReservableFeatureProperties,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import Alert from 'components/general/Alert';
import Label from 'components/general/Label';
import TabStrip from 'components/general/TabStrip';
import { FeatureRendererPropsType } from 'components/renderers/features/FeatureRendererPropsType';
import RelatedSpacesAboutParentNodeInfoMessage from 'components/renderers/features/Reservable/RelatedSpacesAboutParentNodeInfoMessage';

import ReservableTabAdvanced from './ReservableTabAdvanced';
import { ReservableTabAppearance } from './ReservableTabAppearance';
import ReservableTabCustomTimeslots from './ReservableTabCustomTimeslots';
import ReservableTabQuantities from './ReservableTabQuantities';
import ReservableTabSetup from './ReservableTabSetup';
import ReservableTabTeamRules from './ReservableTabTeamRules';

import styles from './styles.scss';

enum ReservableMenuEnum {
  Setup = 'Setup',
  Capacity = 'Capacity',
  Advanced = 'Advanced',
  TeamRules = 'Team Rules',
  Appearance = 'Appearance',
  CustomSlots = 'Custom Time Slots',
}

export default function ReservableFeatureRenderer({
  className,
  style,
  feature,
  content,
  channel,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<ReservableFeatureProperties>) {
  const { t } = useTranslation();

  const toSchema = useMemo(() => {
    return getToSchemaWithTranslate(t);
  }, [t]);

  const menuTabs = useMemo(() => {
    return Object.values(ReservableMenuEnum).map(toSchema);
  }, [toSchema]);

  const [query, goToUrl] = useQueryString({
    reservableMenu: ReservableMenuEnum.Setup,
  });

  const timeZone = getTimeZoneByGeoLocation({
    latitude: content.geo?.[1],
    longitude: content.geo?.[0],
  });

  const {
    timeAvailabilityFeature,
    googleCalendarFeature,
    outlookCalendarFeature,
  } = explodeFeatures(content?.features);

  const settings = contentFeature?.feature as ReservableFeatureProperties;

  const maxMaxSlots = useMemo(() => {
    // we should limit the max slots to a reasonable size so the UI doesn't
    // break.  i.e. if they have 2 hour slots there is a max of 12 slots in
    // a day.
    switch (settings?.unitType) {
      case ReservableUnitTypesEnum.Minutes:
        return Math.round((24 * 60) / settings.units);
      case ReservableUnitTypesEnum.Days:
        return Math.round(28 / settings.units);
      case ReservableUnitTypesEnum.Weeks:
        return Math.round(52 / settings.units);
      case ReservableUnitTypesEnum.Months:
        return Math.round(12 / settings.units);
      default:
        return 1;
    }
  }, [settings?.unitType, settings?.units]);

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  function renderTab() {
    switch (query.reservableMenu) {
      case ReservableMenuEnum.Setup:
        return (
          <ReservableTabSetup
            maxMaxSlots={maxMaxSlots}
            feature={feature}
            onFeatureUpdated={onFeatureUpdated}
            settings={settings}
          />
        );
      case ReservableMenuEnum.Capacity:
        return (
          <ReservableTabQuantities
            onFeatureUpdated={onFeatureUpdated}
            feature={feature}
            settings={settings}
          />
        );

      case ReservableMenuEnum.Appearance:
        return (
          <ReservableTabAppearance
            feature={feature}
            onFeatureUpdated={onFeatureUpdated}
            timeZone={timeZone}
            settings={settings}
            content={content}
            timeAvailabilityFeature={timeAvailabilityFeature}
          />
        );

      case ReservableMenuEnum.Advanced:
        return (
          <ReservableTabAdvanced
            timeZone={timeZone}
            feature={feature}
            onFeatureUpdated={onFeatureUpdated}
            content={content}
            settings={settings}
            isGoogleCalendarSyncEnabled={googleCalendarFeature?.resourceId}
            isOutlookCalendarSyncEnabled={outlookCalendarFeature?.resourceId}
          />
        );
      case ReservableMenuEnum.TeamRules:
        return (
          <ReservableTabTeamRules
            channelId={channel?._id}
            settings={settings}
            maxMaxSlots={maxMaxSlots}
            onFeatureUpdated={onFeatureUpdated}
          />
        );
      case ReservableMenuEnum.CustomSlots:
        return (
          <ReservableTabCustomTimeslots
            settings={settings}
            onFeatureUpdated={onFeatureUpdated}
            timeZone={timeZone}
          />
        );
    }
  }

  return (
    <div className={cx(styles.Reservable, className)} style={style}>
      {timeAvailabilityFeature &&
        settings?.unitType === ReservableUnitTypesEnum.Minutes && (
          <div>
            <Alert color="secondary">
              {t('web.content.features.reservable.index.timeAvailabilityCheck')}
            </Alert>
          </div>
        )}

      {settings.relatedSpaces?.parentId ? (
        <RelatedSpacesAboutParentNodeInfoMessage
          relatedSpacesSettings={settings.relatedSpaces}
        />
      ) : null}

      <Label h1>{t(feature.friendlyName)}</Label>

      <p>{t(feature.description)}</p>

      {settings && (
        <section>
          <TabStrip
            skipLabelTranslation
            tabs={menuTabs.filter(tab => {
              if (tab.value === ReservableMenuEnum.CustomSlots) {
                return settings.unitType === ReservableUnitTypesEnum.Minutes;
              }

              return true;
            })}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'SchemaType<string | number>' is not assignab... Remove this comment to see the full error message
            selected={toSchema(query.reservableMenu)}
            onSelectTab={menu => goToUrl({ reservableMenu: menu.value })}
          />

          {renderTab()}
        </section>
      )}
    </div>
  );
}
