import { useTranslation } from 'react-i18next';

import { useFlag } from 'lane-shared/hooks';

import { ExportOption } from 'design-system-web';

export function useExportOptions(
  onExport: () => void
): ExportOption[] | undefined {
  const showExportButton = useFlag(
    'consumer-experience.reservable-manager-csv-export',
    true
  );

  const { t } = useTranslation();

  if (!showExportButton) {
    return undefined;
  }

  return [
    {
      label: t('web.table.export'),
      onClick: () => onExport(),
    },
  ];
}
