import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { getDisplayName } from 'lane-shared/helpers';
import { hasModuleEnabled } from 'lane-shared/helpers/channelModule';
import { useReservableManagementEnabled } from 'lane-shared/hooks/useReservableManagementEnabled';
import { useReservableManagementCalendarEnabled } from 'lane-shared/hooks/useReservableManagementCalendarEnabled';
import { useReservableManagementCalendarV2Enabled } from 'lane-shared/hooks/useReservableManagementCalendarV2Enabled';
import {
  CHILDREN_NAMES,
  OFFICE_TYPES,
  RETAIL_TYPES,
  PROPERTY_TYPES,
} from 'lane-shared/helpers/constants/channel';
import {
  FontAwesomeType,
  ICON_SET_FONTAWESOME,
  IconSet,
} from 'lane-shared/helpers/constants/icons';
import {
  PERMISSION_ADMIN,
  PERMISSION_ANALYTICS,
  PERMISSION_ANALYTICS_ADVANCED,
  PERMISSION_AUDIENCE_CREATE,
  PERMISSION_AUDIENCE_READ,
  PERMISSION_CHANNEL_RELATIONSHIP_READ,
  PERMISSION_CONTENT_CREATE,
  PERMISSION_CONTENT_NOTICE_CREATE,
  PERMISSION_CONTENT_PAGE_CREATE,
  PERMISSION_CONTENT_PERK_CREATE,
  PERMISSION_CONTENT_POST_CREATE,
  PERMISSION_DOCUMENTS_CREATE,
  PERMISSION_DOCUMENTS_UPDATE,
  PERMISSION_CONTENT_PROMOTION_CREATE,
  PERMISSION_INTEGRATION_READ,
  PERMISSION_LIBRARY_CREATE,
  PERMISSION_LIBRARY_UPDATE,
  PERMISSION_MERCHANT_CREATE,
  PERMISSION_MERCHANT_UPDATE,
  PERMISSION_MEDIA_CREATE,
  PERMISSION_MEDIA_UPDATE,
  PERMISSION_METATAG_CREATE,
  PERMISSION_METATAG_DELETE,
  PERMISSION_METATAG_UPDATE,
  PERMISSION_PAYMENT_ACCOUNT_CREATE,
  PERMISSION_PAYMENT_ACCOUNT_UPDATE,
  PERMISSION_PORTAL_ACCESS,
  PERMISSION_PROFILE_UPDATE,
  PERMISSION_PROPERTY_MANAGE_COMPANY,
  PERMISSION_PROPERTY_MANAGE_RETAIL,
  PERMISSION_RESERVATIONS_MODULE_ACCESS,
  PERMISSION_SECTIONS_CREATE,
  PERMISSION_SECTIONS_DELETE,
  PERMISSION_SECTIONS_UPDATE,
  PERMISSION_TAB_CREATE,
  PERMISSION_TEMPLATES_CREATE,
  PERMISSION_TEMPLATES_UPDATE,
  PERMISSION_USERS_READ,
  PERMISSION_WEBHOOK_CREATE,
  PERMISSION_WEBHOOK_UPDATE,
  PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
  PERMISSION_WORK_ORDERS_MODULE_ACCESS,
  PERMISSION_CREDITS_MANAGE,
  PERMISSION_MESSAGING_AND_MARKETPLACE_MANAGE,
} from 'lane-shared/helpers/constants/permissions';
import {
  BILLINGS_AND_PAYMENTS_LINKS_ORDER,
  CHANNEL_SETTINGS_LINKS_ORDER,
  CONTENT_LINKS_ORDER,
  SIDE_BAR_LINKS_ORDER,
  WORKORDER_LINKS_ORDER,
} from 'lane-shared/helpers/constants/sidebarLinksOrder';
import hasPermission from 'lane-shared/helpers/hasPermission';
import { useFlag } from 'lane-shared/hooks';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { ModuleCategoryEnum } from 'lane-shared/types/modules/modulesEnums';

import { accessControlNavigation } from '../domains/accessControl';
import { hardwareManagementNavigation } from '../domains/hardwareManagement';
import { userManagementSidebarConfig } from '../domains/userManagement';
import { visitorManagementNavigation } from '../domains/visitorManagement';

export type SidebarLinkType = {
  exact: boolean;
  route: string;
  name: string;
  icon?: string;
  iconType?: FontAwesomeType;
  headerMessage?: string;
  isCategory?: boolean;
  order: number;
};

export type SidebarCategoryType = {
  name: string;
  icon: string;
  iconSet: IconSet;
  iconType?: FontAwesomeType;
  links: SidebarLinkType[];
  isCategory?: boolean;
  order: number;
};

export default function useChannelSidebarLinks({
  url,
  user,
  channel,
  specialPermissions,
  channelModules,
  serviceRequestFeatures,
  isInvoicingDisabled,
}: any): (SidebarCategoryType | SidebarLinkType)[] {
  const { t } = useTranslation();
  const menu: (SidebarCategoryType | SidebarLinkType)[] = [];

  const adminRestructureFlag = useFlag('space-cadets.admin-view-revamp', false);
  const visitorManagementFlag = useFlag(FeatureFlag.VisitorManagement, false);
  const dataImporterFlag = useFlag(FeatureFlag.DataImporter, true);
  const visitorManagementModuleSettingsFlag = useFlag(
    FeatureFlag.VisitorManagementModuleSettings,
    false
  );
  const isReservableManagementEnabled = useReservableManagementEnabled();
  const isReservableManagementCalendarEnabled = useReservableManagementCalendarEnabled();
  const isReservableManagementCalendarV2Enabled = useReservableManagementCalendarV2Enabled();
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);
  const transactionHistoryV2Flag = useFlag(
    FeatureFlag.TransactionHistoryV2,
    false
  );
  const productsAndServicesFlag = useFlag(
    FeatureFlag.ProductsAndServices,
    false
  );
  const MessagingAndMarketplaceFlag = useFlag(
    FeatureFlag.MessagingAndMarketplace,
    false
  );
  const chargesInvoiceFlag = useFlag(FeatureFlag.ChargesInvoice, false);
  const paymentSettings = useFlag(FeatureFlag.PaymentSettings, false);
  const creditsFlag = useFlag(FeatureFlag.Credits, false);
  const removePaymentAccountsFlag = useFlag(
    FeatureFlag.RemovePaymentAccounts,
    true
  );

  const isMultifamilyFlagEnabled = useFlag(
    'multifamily.create-property',
    false
  );

  const isIntuitiveUserManagementEnabled = useFlag(
    FeatureFlag.IntuitiveUserManagement,
    false
  );

  const workOrdersPreventiveMaintenanceFlag = useFlag(
    FeatureFlag.WorkOrdersPreventiveMaintenance,
    false
  );

  const workOrdersEquipmentFlag = useFlag(
    FeatureFlag.WorkOrdersEquipment,
    false
  );

  const isWorkplaceEnablementEnabled = useFlag(
    FeatureFlag.WorkOrdersWorkplaceEnablement,
    false
  );

  const accessControlUserAccessFlag = useFlag(
    FeatureFlag.AccessControlUserAccess,
    false
  );

  const hardwarePinManagementFlag = useFlag(
    FeatureFlag.HardwarePinManagement,
    false
  );

  const unitsFloorsEnabled = useFlag(FeatureFlag.UnitsFloors, false);

  function hasAnyPermission(
    permissions: string[],
    includeAdmin: boolean = true
  ): boolean {
    // We need a user and a channelId
    if (!user || !channel?._id) {
      return false;
    }

    return (
      user!.isSuperUser ||
      hasPermission(user!.roles, permissions, channel._id, includeAdmin)
    );
  }

  const linkSortFunction = (
    prev: SidebarCategoryType | SidebarLinkType,
    current: SidebarCategoryType | SidebarLinkType
  ) => (prev.order > current.order ? 1 : -1);

  const workOrder: SidebarCategoryType = {
    name: t('web.admin.workOrder.title'),
    icon: 'tools',
    iconSet: ICON_SET_FONTAWESOME,
    links: [],
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.WORK_ORDERS,
  };

  if (!channel || !user) {
    return menu;
  }

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_ANALYTICS,
      PERMISSION_ANALYTICS_ADVANCED,
    ])
  ) {
    menu.push({
      exact: true,
      route: `${url}/dashboard`,
      icon: 'heart-rate',
      name: t('web.admin.sidebar.dashboard.title'),
      headerMessage: t('web.admin.sidebar.dashboard.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      isCategory: false,
      order: SIDE_BAR_LINKS_ORDER.DASHBOARD,
    });
  }

  const content: SidebarCategoryType = {
    name: t('web.admin.sidebar.content.title'),
    icon: 'edit',
    iconSet: ICON_SET_FONTAWESOME,
    links: [],
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.CONTENT,
  };

  const sections: SidebarCategoryType = {
    name: t('web.admin.sidebar.sections.title'),
    icon: 'book-open',
    iconSet: ICON_SET_FONTAWESOME,
    links: [],
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.SECTIONS,
  };

  const settingsLabel =
    isMultifamilyFlagEnabled && PROPERTY_TYPES.includes(channel.type)
      ? t('web.admin.sidebar.propertySettings.title')
      : t('web.admin.sidebar.channelSettings.title');

  const messagingAndMarketplaceSettings: SidebarCategoryType = {
    name: t('web.admin.sidebar.messagingAndMarketplace.headerMessage'),
    icon: 'comment-dots',
    iconSet: ICON_SET_FONTAWESOME,
    links: [],
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.INSTANT_MESSAGING,
  };

  const channelSettings: SidebarCategoryType = {
    name: settingsLabel,
    icon: 'cog',
    iconSet: ICON_SET_FONTAWESOME,
    links: [],
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.CHANNEL_SEETINGS,
  };

  const canManageCompanyChannel =
    OFFICE_TYPES.includes(channel.type) &&
    specialPermissions.includes(PERMISSION_PROPERTY_MANAGE_COMPANY);

  //  Content Links
  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_CONTENT_CREATE,
      PERMISSION_CONTENT_POST_CREATE,
      PERMISSION_CONTENT_NOTICE_CREATE,
    ])
  ) {
    content.links.push({
      exact: true,
      route: `${url}/content-center`,
      name: t('web.admin.sidebar.contentCenter.title'),
      headerMessage: t('web.admin.sidebar.contentCenter.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.CONTENT_CENTER,
    });
  }

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_CONTENT_CREATE,
      PERMISSION_CONTENT_PAGE_CREATE,
      PERMISSION_SECTIONS_CREATE,
    ])
  ) {
    content.links.push({
      exact: true,
      route: `${url}/page-center`,
      name: t('web.admin.sidebar.pageCenter.title'),
      headerMessage: t('web.admin.sidebar.pageCenter.headerMessage', {
        channelType: channel.type,
      }),
      order: CONTENT_LINKS_ORDER.PAGE_CENTER,
    });
  }

  /* if the channel has perks enabled, and this user has rights to create
        content or perks, show the content center. */

  if (
    channel.settings?.hasPerksEnabled &&
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_CONTENT_CREATE,
      PERMISSION_CONTENT_PERK_CREATE,
    ])
  ) {
    content.links.push({
      exact: true,
      route: `${url}/perk-center`,
      name: adminRestructureFlag
        ? t('web.admin.sidebar.perksCenter.title')
        : t('web.admin.sidebar.perkCenter.title'),
      headerMessage: t('web.admin.sidebar.perkCenter.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.PERKS_CENTER,
    });
  }

  // if this is a retail-type channel, we can create promotions.
  if (
    RETAIL_TYPES.includes(channel.type) &&
    (hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_CONTENT_CREATE,
      PERMISSION_CONTENT_PROMOTION_CREATE,
    ]) ||
      specialPermissions.includes(PERMISSION_PROPERTY_MANAGE_RETAIL))
  ) {
    content.links.push({
      exact: true,
      route: `${url}/promotion-center`,
      name: adminRestructureFlag
        ? t('web.admin.sidebar.promotionsCenter.title')
        : t('web.admin.sidebar.promotionCenter.title'),
      headerMessage: t('web.admin.sidebar.promotionCenter.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.PROMOTIONS_CENTER,
    });
  }

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_AUDIENCE_CREATE,
      PERMISSION_AUDIENCE_READ,
    ])
  ) {
    content.links.push({
      exact: true,
      route: `${url}/audiences`,
      name: t('web.admin.sidebar.audiences.title'),
      headerMessage: t('web.admin.sidebar.audiences.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.AUDIENCES,
    });
  }

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_TEMPLATES_UPDATE,
      PERMISSION_TEMPLATES_CREATE,
    ]) &&
    !adminRestructureFlag
  ) {
    content.links.push({
      exact: true,
      route: `${url}/templates`,
      name: t('web.admin.sidebar.templateLibrary.title'),
      headerMessage: t('web.admin.sidebar.templateLibrary.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.LIBRARY,
    });
  }

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_METATAG_UPDATE,
      PERMISSION_METATAG_CREATE,
      PERMISSION_METATAG_DELETE,
    ]) &&
    !areContentTagsEnabled
  ) {
    content.links.push({
      exact: true,
      route: `${url}/meta-tag`,
      name: adminRestructureFlag
        ? t('web.admin.sidebar.filterAndTags.title')
        : t('web.admin.sidebar.filterAndTagLibrary.title'),
      headerMessage: t('web.admin.sidebar.dataLibrary.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.FILTER_AND_TAGS,
    });
  }

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_DOCUMENTS_CREATE,
      PERMISSION_DOCUMENTS_UPDATE,
      PERMISSION_LIBRARY_CREATE,
      PERMISSION_LIBRARY_UPDATE,
      PERMISSION_MEDIA_CREATE,
      PERMISSION_MEDIA_UPDATE,
      PERMISSION_TEMPLATES_CREATE,
      PERMISSION_TEMPLATES_UPDATE,
      PERMISSION_WEBHOOK_CREATE,
      PERMISSION_WEBHOOK_UPDATE,
    ]) &&
    adminRestructureFlag
  ) {
    content.links.push({
      exact: false,
      route: `${url}/library`,
      name: t('web.admin.sidebar.library.title'),
      headerMessage: t('web.admin.sidebar.library.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.LIBRARY,
    });
  }

  // Section Links

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_SECTIONS_CREATE,
      PERMISSION_SECTIONS_DELETE,
      PERMISSION_SECTIONS_UPDATE,
    ])
  ) {
    sections.links = [
      ...(channel.sections || []).map((section: any, index: number) => ({
        exact: true,
        route: `${url}/sections/${section._id}/view`,
        name: section.name,
        headerMessage: `${section.name} - ${getDisplayName(channel)}`,
        order: index,
      })),
      {
        exact: true,
        route: `${url}/sections`,
        name: 'Manage Sections',
        headerMessage: `Manage Sections - ${getDisplayName(channel)}`,
        order: (channel.sections || []).length + 1,
      },
    ];
  }

  // Messaging and Marketplace Settings Links
  if (
    MessagingAndMarketplaceFlag &&
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_MESSAGING_AND_MARKETPLACE_MANAGE,
    ]) &&
    channel.settings.hasMessagingAndMarketplaceEnabled
  ) {
    messagingAndMarketplaceSettings.links.push({
      exact: false,
      route: `${url}/messaging-and-marketplace`,
      name: t('web.admin.sidebar.messagingAndMarketplace.settings'),
      headerMessage: t(
        'web.admin.sidebar.messagingAndMarketplace.headerMessage'
      ),
      order: CONTENT_LINKS_ORDER.INSTANT_MESSAGING,
    });
  }

  // Channel Settings Links
  if (hasAnyPermission([PERMISSION_ADMIN, PERMISSION_PROFILE_UPDATE])) {
    const propertyType =
      isMultifamilyFlagEnabled && PROPERTY_TYPES.includes(channel.type);
    if (propertyType) {
      channelSettings.links.push({
        exact: true,
        route: `${url}/profile`,
        name: t('web.admin.sidebar.propertyDetails.title'),
        headerMessage: t('web.admin.sidebar.propertyDetails.headerMessage', {
          displayName: getDisplayName(channel),
        }),
        order: CHANNEL_SETTINGS_LINKS_ORDER.PROFILE,
      });
    } else {
      channelSettings.links.push({
        exact: true,
        route: `${url}/profile`,
        name: t('web.admin.sidebar.profile.title'),
        headerMessage: t('web.admin.sidebar.profile.headerMessage', {
          displayName: getDisplayName(channel),
        }),
        order: CHANNEL_SETTINGS_LINKS_ORDER.PROFILE,
      });
    }
  }

  if (
    hasAnyPermission([PERMISSION_ADMIN]) &&
    unitsFloorsEnabled &&
    channel.type === ChannelTypeEnum.Property &&
    channel.parent
  ) {
    channelSettings.links.push({
      exact: true,
      route: `${url}/units`,
      name: t('web.admin.sidebar.units.title'),
      headerMessage: t('web.admin.sidebar.units.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CHANNEL_SETTINGS_LINKS_ORDER.UNITS,
    });
    channelSettings.links.push({
      exact: true,
      route: `${url}/floors`,
      name: t('web.admin.sidebar.floors.title'),
      headerMessage: t('web.admin.sidebar.floors.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CHANNEL_SETTINGS_LINKS_ORDER.FLOORS,
    });
  }

  if (dataImporterFlag) {
    channelSettings.links.push({
      exact: true,
      route: `${url}/data-import`,
      name: t('web.admin.sidebar.dataImport.title'),
      headerMessage: t('web.admin.sidebar.dataImport.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CHANNEL_SETTINGS_LINKS_ORDER.DATA_IMPORT,
    });
  }

  if (hasAnyPermission([PERMISSION_ADMIN, PERMISSION_TAB_CREATE])) {
    channelSettings.links.push({
      exact: true,
      route: `${url}/tab-center`,
      name: t('web.admin.sidebar.tabManagement.title'),
      headerMessage: t('web.admin.sidebar.tabManagement.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CHANNEL_SETTINGS_LINKS_ORDER.TAB_MANAGEMENT,
    });
  }

  if (
    !isIntuitiveUserManagementEnabled &&
    (hasAnyPermission([PERMISSION_ADMIN, PERMISSION_USERS_READ]) ||
      canManageCompanyChannel)
  ) {
    channelSettings.links.push({
      exact: true,
      route: `${url}/teams`,
      name: t('web.admin.sidebar.teamManagement.title'),
      headerMessage: t('web.admin.sidebar.teamManagement.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CHANNEL_SETTINGS_LINKS_ORDER.TEAM_MANAGEMENT,
    });
  }

  if (
    hasAnyPermission([PERMISSION_ADMIN, PERMISSION_CHANNEL_RELATIONSHIP_READ])
  ) {
    if (channel.type === ChannelTypeEnum.Property) {
      channelSettings.links.push({
        exact: true,
        route: `${url}/relationships`,
        name: t('web.admin.sidebar.tenantManagement.title'),
        headerMessage: t('web.admin.sidebar.tenantManagement.headerMessage', {
          displayName: getDisplayName(channel),
        }),
        order: CHANNEL_SETTINGS_LINKS_ORDER.TENANT_MANAGEMENT,
      });
    } else {
      channelSettings.links.push({
        exact: true,
        route: `${url}/relationships`,
        name: t('web.admin.sidebar.channelRelationships.title'),
        headerMessage: t(
          'web.admin.sidebar.channelRelationships.headerMessage',
          {
            displayName: getDisplayName(channel),
          }
        ),
        order: CHANNEL_SETTINGS_LINKS_ORDER.CHANNEL_RELATIONSHIPS,
      });
    }
  }

  if (
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_ANALYTICS,
      PERMISSION_CONTENT_CREATE,
    ])
  ) {
    channelSettings.links.push({
      exact: true,
      route: `${url}/sub-channels`,
      name: t('web.admin.sidebar.subChannels.title'),
      headerMessage: t('web.admin.sidebar.subChannels.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CHANNEL_SETTINGS_LINKS_ORDER.SUB_CHANNELS,
    });
  }

  if (
    !channel.parent &&
    hasAnyPermission([PERMISSION_ADMIN, PERMISSION_PORTAL_ACCESS])
  ) {
    channelSettings.links.push({
      exact: true,
      route: `${url}/children`,
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      name: CHILDREN_NAMES[channel.type],
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      headerMessage: CHILDREN_NAMES[channel.type],
      order: CHANNEL_SETTINGS_LINKS_ORDER.CHILD_CHANNEL,
    });
  }

  if (hasAnyPermission([PERMISSION_ADMIN, PERMISSION_INTEGRATION_READ])) {
    channelSettings.links.push({
      exact: true,
      route: `${url}/integrations`,
      name: t('web.admin.sidebar.integrations.title'),
      headerMessage: t('web.admin.sidebar.integrations.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CHANNEL_SETTINGS_LINKS_ORDER.INTEGRATIONS,
    });
  }
  if (!adminRestructureFlag) {
    if (
      hasAnyPermission([
        PERMISSION_ADMIN,
        PERMISSION_MERCHANT_CREATE,
        PERMISSION_MERCHANT_UPDATE,
      ])
    ) {
      channelSettings.links.push({
        exact: true,
        route: `${url}/merchant-accounts`,
        name: t('web.admin.sidebar.merchantAccounts.title'),
        headerMessage: t('web.admin.sidebar.merchantAccounts.headerMessage', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.MERCHANT_ACCOUNTS,
      });
    }

    if (
      hasAnyPermission([
        PERMISSION_ADMIN,
        PERMISSION_PAYMENT_ACCOUNT_CREATE,
        PERMISSION_PAYMENT_ACCOUNT_UPDATE,
      ]) ||
      canManageCompanyChannel
    ) {
      channelSettings.links.push({
        exact: true,
        route: `${url}/payment-accounts`,
        name: t('web.admin.sidebar.paymentAccounts.title'),
        headerMessage: t('web.admin.sidebar.paymentAccounts.headerMessage', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.PAYMENT_ACCOUNTS,
      });
    }
  }

  if (
    channel.parent &&
    (user!.isSuperUser ||
      hasPermission(
        user!.roles,
        [PERMISSION_ADMIN, PERMISSION_PORTAL_ACCESS],
        channel.parent?._id
      ))
  ) {
    channelSettings.links.push({
      exact: true,
      route: `/l/channel/${channel.parent.slug}/admin`,
      name: t('web.admin.sidebar.parentChannel.title'),
      order: CHANNEL_SETTINGS_LINKS_ORDER.PARENT_CHANNEL,
    });
  }

  if (
    adminRestructureFlag &&
    hasAnyPermission([
      PERMISSION_ADMIN,
      PERMISSION_SECTIONS_CREATE,
      PERMISSION_SECTIONS_DELETE,
      PERMISSION_SECTIONS_UPDATE,
    ])
  ) {
    content.links.push({
      exact: true,
      route: `${url}/sections`,
      name: t('web.admin.sidebar.sections.title'),
      headerMessage: t('web.admin.sidebar.sections.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: CONTENT_LINKS_ORDER.SECTIONS,
    });
  }

  if (content.links.length > 0) {
    if (adminRestructureFlag) {
      content.links.sort(linkSortFunction);
    }
    menu.push(content);
  }

  if (sections.links.length > 0 && !adminRestructureFlag) {
    menu.push(sections);
  }

  if (isIntuitiveUserManagementEnabled) {
    const navigation = userManagementSidebarConfig(
      url,
      channel,
      user,
      specialPermissions
    );
    if (navigation.links.length) {
      menu.push(navigation);
    }
  }

  if (
    MessagingAndMarketplaceFlag &&
    channel.settings.hasMessagingAndMarketplaceEnabled
  ) {
    menu.push(messagingAndMarketplaceSettings);
  }

  if (channelSettings.links.length > 0) {
    if (adminRestructureFlag) {
      channelSettings.links.sort(linkSortFunction);
    }
    menu.push(channelSettings);
  }

  if (hasAnyPermission([PERMISSION_ANALYTICS_ADVANCED])) {
    menu.push({
      exact: true,
      route: `${url}/insights`,
      icon: adminRestructureFlag ? 'chart-bar' : 'chart-area',
      iconSet: ICON_SET_FONTAWESOME,
      name: t('web.admin.sidebar.insightsAndReports.title'),
      headerMessage: t('web.admin.sidebar.insightsAndReports.title'),
      isCategory: false,
      order: SIDE_BAR_LINKS_ORDER.INSIGHTS,
    });
  }

  if (user.isSuperUser) {
    menu.push({
      isCategory: false,
      route: routes.portalManagement,
      icon: adminRestructureFlag ? 'building' : 'user-plus',
      headerMessage: t('web.admin.sidebar.portalManagement.title'),
      name: t('web.admin.sidebar.portalManagement.title'),
      exact: true,
      order: SIDE_BAR_LINKS_ORDER.PORTAL_MANAGEMENT,
    });

    menu.push({
      isCategory: false,
      route: routes.portalManagementChannels,
      icon: 'search',
      headerMessage: t('web.admin.sidebar.searchChannels.title'),
      name: t('web.admin.sidebar.searchChannels.title'),
      exact: true,
      order: SIDE_BAR_LINKS_ORDER.SEARCH_CHANNELS,
    });
  }

  // TODO: Delete this deprecated logic as it is replaced by workplace enablement TM-21661
  if (
    !isWorkplaceEnablementEnabled &&
    (hasModuleEnabled(channelModules, ModuleCategoryEnum.WorkOrders) ||
      channel.settings.hasWorkOrderCrossPropertyEnabled) &&
    hasAnyPermission([PERMISSION_WORK_ORDERS_MODULE_ACCESS], false)
  ) {
    workOrder.links.push({
      exact: true,
      route: `${url}/work-orders/service-requests`,
      name: t('web.admin.serviceRequest.title'),
      headerMessage: `${t('web.admin.serviceRequest.title')} - ${getDisplayName(
        channel
      )}`,
      order: WORKORDER_LINKS_ORDER.SERVICE_REQUEST,
    });

    if (
      serviceRequestFeatures.createServiceRequest &&
      workOrdersEquipmentFlag &&
      !channel.settings.hasWorkOrderCrossPropertyEnabled &&
      hasAnyPermission([PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW])
    ) {
      workOrder.links.push({
        exact: true,
        route: `${url}/work-orders/equipment`,
        name: t('web.admin.serviceRequest.equipment.title'),
        headerMessage: `${t(
          'web.admin.serviceRequest.equipment.title'
        )} - ${getDisplayName(channel)}`,
        order: WORKORDER_LINKS_ORDER.EQUIPMENT,
      });
    }

    if (
      serviceRequestFeatures.createServiceRequest &&
      workOrdersPreventiveMaintenanceFlag
    ) {
      workOrder.links.push({
        exact: true,
        route: `${url}/work-orders/preventive-maintenance`,
        name: t('web.admin.workOrder.preventiveMaintenance.title'),
        headerMessage: `${t(
          'web.admin.workOrder.preventiveMaintenance.title'
        )} - ${getDisplayName(channel)}`,
        isCategory: false,
        order: WORKORDER_LINKS_ORDER.PREVENTIVE_MAINTENANCE,
      });
    }

    menu.push(workOrder);
  }

  if (
    isWorkplaceEnablementEnabled &&
    (channel.settings.hasWorkOrderServiceRequestsEnabled ||
      channel.settings.hasWorkOrderEquipmentEnabled ||
      channel.settings.hasWorkOrderPreventiveMaintenanceEnabled)
  ) {
    if (channel.settings.hasWorkOrderServiceRequestsEnabled) {
      workOrder.links.push({
        exact: true,
        route: `${url}/work-orders/service-requests`,
        name: t('web.admin.serviceRequest.title'),
        headerMessage: `${t(
          'web.admin.serviceRequest.title'
        )} - ${getDisplayName(channel)}`,
        order: WORKORDER_LINKS_ORDER.SERVICE_REQUEST,
      });
    }

    if (
      serviceRequestFeatures.createServiceRequest &&
      workOrdersEquipmentFlag &&
      channel.settings.hasWorkOrderEquipmentEnabled &&
      !channel.settings.hasWorkOrderCrossPropertyEnabled &&
      hasAnyPermission([PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW])
    ) {
      workOrder.links.push({
        exact: true,
        route: `${url}/work-orders/equipment`,
        name: t('web.admin.serviceRequest.equipment.title'),
        headerMessage: `${t(
          'web.admin.serviceRequest.equipment.title'
        )} - ${getDisplayName(channel)}`,
        order: WORKORDER_LINKS_ORDER.EQUIPMENT,
      });
    }

    if (
      serviceRequestFeatures.createServiceRequest &&
      workOrdersPreventiveMaintenanceFlag &&
      channel.settings.hasWorkOrderPreventiveMaintenanceEnabled
    ) {
      workOrder.links.push({
        exact: true,
        route: `${url}/work-orders/preventive-maintenance`,
        name: t('web.admin.workOrder.preventiveMaintenance.title'),
        headerMessage: `${t(
          'web.admin.workOrder.preventiveMaintenance.title'
        )} - ${getDisplayName(channel)}`,
        isCategory: false,
        order: WORKORDER_LINKS_ORDER.PREVENTIVE_MAINTENANCE,
      });
    }

    menu.push(workOrder);
  }

  if (channel.settings.hasVisitorManagementEnabled && visitorManagementFlag) {
    const navigation = visitorManagementNavigation(
      url,
      channel._id,
      user,
      visitorManagementModuleSettingsFlag ?? false
    );
    if (navigation.links.length) {
      menu.push(navigation);
    }
  }

  if (channel.settings.hasHardwareManagementEnabled) {
    const navigation = hardwareManagementNavigation(
      url,
      channel,
      hardwarePinManagementFlag,
      user,
      t
    );
    if (navigation.links.length) {
      menu.push(navigation);
    }
  }

  const showReservationsModuleCurrentState = true;
  if (
    (isReservableManagementCalendarV2Enabled
      ? hasAnyPermission([
          PERMISSION_ADMIN,
          PERMISSION_RESERVATIONS_MODULE_ACCESS,
        ])
      : showReservationsModuleCurrentState) &&
    channel.settings.hasReservableManagementEnabled &&
    isReservableManagementEnabled
  ) {
    menu.push({
      isCategory: false,
      route: `${url}/reservable-management/${
        isReservableManagementCalendarEnabled ? 'calendar' : 'list'
      }`,
      icon: 'calendar-check',
      headerMessage: t('web.admin.channel.reservableManagement.menuLink'),
      name: t('web.admin.channel.reservableManagement.menuLink'),
      exact: true,
      order: SIDE_BAR_LINKS_ORDER.RESERVABLE_MANAGER,
    });
  }

  const accessNavigation = accessControlNavigation(url, channel._id, user, {
    userAccessFlag: accessControlUserAccessFlag,
  });
  if (
    channel.settings.hasAccessControlEnabled &&
    accessNavigation.links.length
  ) {
    menu.push(accessNavigation);
  }

  if (channel.settings.hasBillingPaymentsEnabled) {
    const BillingAndPayments: SidebarCategoryType = {
      name: t('web.admin.sidebar.billingsAndPayments.title'),
      icon: 'usd-circle',
      iconSet: ICON_SET_FONTAWESOME,
      links: [],
      isCategory: true,
      order: SIDE_BAR_LINKS_ORDER.BILLING_AND_PAYMENTS,
    };

    if (
      !removePaymentAccountsFlag &&
      (hasAnyPermission([
        PERMISSION_ADMIN,
        PERMISSION_PAYMENT_ACCOUNT_CREATE,
        PERMISSION_PAYMENT_ACCOUNT_UPDATE,
      ]) ||
        canManageCompanyChannel)
    ) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/payment-accounts`,
        name: t('web.admin.sidebar.paymentAccounts.title'),
        headerMessage: t('web.admin.sidebar.paymentAccounts.headerMessage', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.PAYMENT_ACCOUNTS,
      });
    }

    if (productsAndServicesFlag) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/products-services`,
        name: t('web.admin.sidebar.productsAndServices.title'),
        headerMessage: t('web.admin.sidebar.productsAndServices.title', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.PRODUCTS_AND_SERVICES,
      });
    }

    if (
      creditsFlag &&
      hasAnyPermission([PERMISSION_ADMIN, PERMISSION_CREDITS_MANAGE])
    ) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/credit-bundles`,
        name: t('web.admin.sidebar.creditBundles.title'),
        headerMessage: t('web.admin.sidebar.creditBundles.title', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.CREDIT_BUNDLES,
      });
    }

    if (chargesInvoiceFlag) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/charges`,
        name: t('web.admin.sidebar.charges.title'),
        headerMessage: t('web.admin.sidebar.charges.title', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.CHARGES,
      });
    }
    if (chargesInvoiceFlag && !isInvoicingDisabled) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/invoices`,
        name: t('web.admin.sidebar.invoices.title'),
        headerMessage: t('web.admin.sidebar.invoices.title', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.INVOICES,
      });
    }

    BillingAndPayments.links.push({
      exact: true,
      route: `${url}/subscriptions`,
      name: t('web.admin.sidebar.subscriptions.title'),
      headerMessage: t('web.admin.sidebar.subscriptions.headerMessage', {
        displayName: getDisplayName(channel),
      }),
      order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.MEMBERSHIPS,
    });

    if (transactionHistoryV2Flag) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/transactions-reports`,
        name: t('web.admin.sidebar.transactionsAndReports.title'),
        headerMessage: t('web.admin.sidebar.transactionsAndReports.title', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.TRANSACTIONS_AND_REPORTS,
      });
    }

    if (
      hasAnyPermission([
        PERMISSION_ADMIN,
        PERMISSION_MERCHANT_CREATE,
        PERMISSION_MERCHANT_UPDATE,
      ])
    ) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/merchant-accounts`,
        name: t('web.admin.sidebar.merchantAccounts.title'),
        headerMessage: t('web.admin.sidebar.merchantAccounts.headerMessage', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.MERCHANT_ACCOUNTS,
      });
    }

    if (paymentSettings) {
      BillingAndPayments.links.push({
        exact: true,
        route: `${url}/payment-settings`,
        name: t('web.admin.sidebar.billingAndPaymentSettings.title'),
        headerMessage: t('web.admin.sidebar.billingAndPaymentSettings.title', {
          displayName: getDisplayName(channel),
        }),
        order: BILLINGS_AND_PAYMENTS_LINKS_ORDER.BILLING_AND_PAYMENT_SETTINGS,
      });
    }

    if (BillingAndPayments.links.length) {
      menu.push(BillingAndPayments);
    }
  }

  // TODO: sort logic change begind a feature flag AB test, to be removed once the test has been completed
  if (adminRestructureFlag) {
    menu.sort(linkSortFunction);
  }

  return menu;
}
