import routes from '../../config/routes';
import { LinkRouteEnum } from './LinkRouteEnum';

export type LinkRoute = {
  type: LinkRouteEnum;
  route: string;
};

export const linkRouteOptions: LinkRoute[] = [
  {
    type: LinkRouteEnum.SectionRoute,
    route: routes.section,
  },
  {
    type: LinkRouteEnum.LiveContentRoute,
    route: routes.content,
  },
  {
    type: LinkRouteEnum.PageRoute,
    route: routes.content,
  },
  {
    type: LinkRouteEnum.UserRoute,
    route: routes.user,
  },
  {
    type: LinkRouteEnum.ChannelRoute,
    route: routes.channel,
  },
  {
    type: LinkRouteEnum.InteractionsOnContentRoute,
    route: routes.contentInteractions,
  },
  {
    type: LinkRouteEnum.TodayFeedOnChannelRoute,
    route: routes.channelFeed,
  },
  {
    type: LinkRouteEnum.SectionsOnChannelRoute,
    route: routes.channelSections,
  },
  {
    type: LinkRouteEnum.RetailOnChannelRoute,
    route: routes.channelRetail,
  },
  {
    type: LinkRouteEnum.OfficeOnChannelRoute,
    route: routes.channelOffice,
  },
  {
    type: LinkRouteEnum.UserDirectoryOnChannelRoute,
    route: routes.channelMembers,
  },
  {
    type: LinkRouteEnum.SettingsRoute,
    route: routes.meSettings,
  },
  {
    type: LinkRouteEnum.WalletRoute,
    route: routes.meWallet,
  },
  {
    type: LinkRouteEnum.RecentActivityRoute,
    route: routes.meRecentActivity,
  },
  {
    type: LinkRouteEnum.SubscriptionsRoute,
    route: routes.meSubscriptions,
  },
  {
    type: LinkRouteEnum.QRCodeRoute,
    route: routes.meQRCode,
  },
  {
    type: LinkRouteEnum.QRCodeScanner,
    route: routes.qrCodeScanner,
  },
];
